import { Table, TableCell, TableHead, TableRow, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import { formatDollars } from '../../../../../common';

interface Props {
  totalValue: number;
}

export default function PortfolioValue(props: Props): JSX.Element {
  const matches = useMediaQuery('(min-width:1400px)');
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell variant={'body'} style={{ padding: '0' }} />
          <TableCell variant={'body'} colSpan={5}>
            <Typography variant="h3" color={'primary'}>
              Total Portfolio Value
            </Typography>
          </TableCell>
          <TableCell variant={'body'} align="left" colSpan={4} style={{ padding: '15px 15px' }}>
            <Typography variant="h5">{formatDollars(props.totalValue)}</Typography>
          </TableCell>
          <TableCell variant={'body'} align="left" style={{ width: '30px', height: '30px' }}></TableCell>
        </TableRow>
        <TableRow key={1}>
          <TableCell variant={'body'} style={{ padding: '0', width: '0' }} />
          <TableCell
            variant={'body'}
            style={{ width: matches ? '25%' : '200px', minWidth: matches ? '25%' : '200px', maxWidth: matches ? '25%' : '200px' }}
          ></TableCell>
          <TableCell variant={'body'} style={{ minWidth: '90px', width: '7%', maxWidth: '7%' }}></TableCell>
          <TableCell variant={'body'} style={{ minWidth: '162px', width: '10%', maxWidth: '10%' }}></TableCell>
          <TableCell variant={'body'} style={{ minWidth: '162px', width: '10%', maxWidth: '10%' }}></TableCell>
          <TableCell variant={'body'} style={{ minWidth: '110px', width: '10%', maxWidth: '10%' }}></TableCell>
          <TableCell variant={'body'} style={{ minWidth: '92px', width: '7%', maxWidth: '7%' }}></TableCell>
          <TableCell variant={'body'} style={{ minWidth: '92px', width: '7%', maxWidth: '7%' }}></TableCell>
          <TableCell variant={'body'} style={{ minWidth: '92px', width: '7%', maxWidth: '7%' }}></TableCell>
          <TableCell variant={'body'}></TableCell>
          <TableCell variant={'body'} style={{ width: '100%' }}></TableCell>
        </TableRow>
      </TableHead>
    </Table>
  );
}
