import {
  Accordion,
  AccordionSummary,
  FormControlLabel,
  AccordionDetails,
  Typography,
  Grid,
  Autocomplete,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
} from '@mui/material';
import React from 'react';
import { WO2Checkbox, WO2Select, WO2ToggleSwitch } from 'src/common';
import DateRangePicker from 'src/common/components/DateRangePicker';
import { ActionCreatorWithoutPayload, ActionCreatorWithPayload } from '@reduxjs/toolkit';
import {
  ClientReportsParameters,
  GroupByPortfolioValuation,
  HideByPortfolioValuation,
  HidingOptionsRequest,
  MdaReportTypes,
  SortByPortfolioValuation,
} from '../../store/types';
import { ExpandMore } from '@mui/icons-material';
import { DateRange } from 'src/store';
import { ClientPortfolio } from '../../../common/store';

export interface PortfolioValuationProps {
  parameters: ClientReportsParameters;
  clientPortfolios: ClientPortfolio[];
  expandPortfolioValuation: ActionCreatorWithoutPayload<string>;
  setPortfolioValuationSelectedParameter: ActionCreatorWithPayload<boolean, string>;
  setPortfolioValuationSortByParameter: ActionCreatorWithPayload<SortByPortfolioValuation, string>;
  setPortfolioValuationGroupByParameter: ActionCreatorWithPayload<GroupByPortfolioValuation, string>;
  setReportDatePortfolioValuationParameter: ActionCreatorWithPayload<DateRange, string>;
  setPortfolioValuationPortfolioIdParameter: ActionCreatorWithPayload<number, string>;
  setPortfolioValuationHidingOptionParameter: ActionCreatorWithPayload<
    HidingOptionsRequest<HideByPortfolioValuation>,
    string
  >;
  createReportSortRequest: (isAdd: boolean, id: number, text: string) => void;
}

export const PortfolioValuation = ({
  parameters,
  createReportSortRequest,
  setPortfolioValuationSelectedParameter,
  setPortfolioValuationSortByParameter,
  setPortfolioValuationGroupByParameter,
  setReportDatePortfolioValuationParameter,
  setPortfolioValuationHidingOptionParameter,
  setPortfolioValuationPortfolioIdParameter,
  expandPortfolioValuation,
  clientPortfolios,
}: PortfolioValuationProps): JSX.Element => {
  const handleClickPortfolioValuationSelect = () => {
    setPortfolioValuationSelectedParameter(!parameters.selectedReports?.portfolioValuation.selected);
    createReportSortRequest(
      !parameters.selectedReports?.portfolioValuation.selected,
      MdaReportTypes.PortfolioValuation.id,
      MdaReportTypes.PortfolioValuation.displayName
    );
  };

  const handleClickDateForPortfolioValuation = (dates: DateRange) => {
    setReportDatePortfolioValuationParameter(dates);
  };

  const handleSelectPortfolio = (portfolio: ClientPortfolio | null) => {
    if (portfolio && portfolio.investmentProgramId) {
      setPortfolioValuationPortfolioIdParameter(portfolio.investmentProgramId);
    }
  };

  return (
    <Accordion
      onChange={expandPortfolioValuation}
      expanded={parameters.selectedReports?.portfolioValuation.expanded || false}
      elevation={0}
    >
      <AccordionSummary expandIcon={<ExpandMore />}>
        <FormControlLabel
          aria-label="Acknowledge"
          sx={{ flex: 1 }}
          onClick={(event) => event.stopPropagation()}
          onFocus={(event) => event.stopPropagation()}
          control={
            <WO2Checkbox
              color="primary"
              checked={parameters.selectedReports?.portfolioValuation.selected}
              onChangeHandler={handleClickPortfolioValuationSelect}
            />
          }
          label="Portfolio Valuation"
        />
        <div onClick={(e) => e.stopPropagation()}>
          <DateRangePicker
            sx={{ minWidth: 194 }}
            dateRange={{
              dateFrom: '',
              dateTo: parameters?.selectedReports.portfolioValuation?.asAtDate,
              dateRangeType: parameters?.selectedReports.portfolioValuation?.asAtDateType,
            }}
            setDateRange={handleClickDateForPortfolioValuation}
            isDateOnly
          />
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={1} alignItems="center">
          <Grid item flex={1}>
            <FormControl fullWidth>
              <InputLabel id="sort-by-id">Sort by</InputLabel>
              <WO2Select
                labelId="sort-by-id"
                label="sort by"
                onChange={(e) => setPortfolioValuationSortByParameter(e.target.value as SortByPortfolioValuation)}
                value={parameters.selectedReports.portfolioValuation.sortBy}
                defaultValue={SortByPortfolioValuation.SecurityName}
              >
                <MenuItem value={SortByPortfolioValuation.SecurityType}>Security Type</MenuItem>
                <MenuItem value={SortByPortfolioValuation.SecurityName}>Security Name</MenuItem>
                <MenuItem value={SortByPortfolioValuation.Gics}>GICS</MenuItem>
              </WO2Select>
            </FormControl>
          </Grid>
          <Grid item flex={1}>
            <FormControl fullWidth>
              <InputLabel id="group-by-id">Group by</InputLabel>
              <WO2Select
                labelId="group-by-id"
                label="group by"
                onChange={(e) => setPortfolioValuationGroupByParameter(e.target.value as GroupByPortfolioValuation)}
                value={parameters.selectedReports.portfolioValuation.groupBy}
                defaultValue={GroupByPortfolioValuation.AssetClass}
              >
                <MenuItem value={GroupByPortfolioValuation.AssetClass}>Asset Class</MenuItem>
                <MenuItem value={GroupByPortfolioValuation.Gics}>GICS</MenuItem>
                <MenuItem value={GroupByPortfolioValuation.Portfolio}>Portfolio</MenuItem>
                <MenuItem value={GroupByPortfolioValuation.Model}>Model</MenuItem>
              </WO2Select>
            </FormControl>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item container xs={4} alignItems="center">
            <WO2ToggleSwitch
              checked={parameters.selectedReports.portfolioValuation.excludeCostBase}
              onChange={(e) =>
                setPortfolioValuationHidingOptionParameter({
                  value: e.target.checked,
                  option: HideByPortfolioValuation.CostBase,
                })
              }
              color="primary"
              name="hidingOptions"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
            <Typography variant="h4">Exclude Cost Base</Typography>
          </Grid>
          <Grid item container xs={4} alignItems="center">
            <WO2ToggleSwitch
              checked={parameters.selectedReports.portfolioValuation.excludeMarket}
              onChange={(e) =>
                setPortfolioValuationHidingOptionParameter({
                  value: e.target.checked,
                  option: HideByPortfolioValuation.Market,
                })
              }
              color="primary"
              name="hidingOptions"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
            <Typography variant="h4">Exclude Market</Typography>
          </Grid>
          <Grid item container xs={4} alignItems="center">
            <WO2ToggleSwitch
              checked={parameters.selectedReports.portfolioValuation.excludeSecurityType}
              onChange={(e) =>
                setPortfolioValuationHidingOptionParameter({
                  value: e.target.checked,
                  option: HideByPortfolioValuation.SecurityType,
                })
              }
              color="primary"
              name="hidingOptions"
              inputProps={{ 'aria-label': 'primary checkbox' }}
              disabled={parameters.selectedReports.portfolioValuation.sortBy === SortByPortfolioValuation.SecurityType}
            />
            <Typography variant="h4">Exclude Security Type</Typography>
          </Grid>

          <Grid container item xs={4} alignItems="center">
            <WO2ToggleSwitch
              checked={parameters.selectedReports.portfolioValuation.excludeGICS}
              onChange={(e) =>
                setPortfolioValuationHidingOptionParameter({
                  value: e.target.checked,
                  option: HideByPortfolioValuation.Gics,
                })
              }
              color="primary"
              name="hidingOptions"
              inputProps={{ 'aria-label': 'primary checkbox' }}
              disabled={
                parameters.selectedReports.portfolioValuation.sortBy === SortByPortfolioValuation.Gics ||
                parameters.selectedReports.portfolioValuation.groupBy === GroupByPortfolioValuation.Gics
              }
            />
            <Typography variant="h4">Exclude GICS</Typography>
          </Grid>
          <Grid container item xs={4} alignItems="center">
            <WO2ToggleSwitch
              checked={parameters.selectedReports.portfolioValuation.excludeGainsLosses}
              onChange={(e) =>
                setPortfolioValuationHidingOptionParameter({
                  value: e.target.checked,
                  option: HideByPortfolioValuation.GainsLosses,
                })
              }
              color="primary"
              name="hidingOptions"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
            <Typography variant="h4">Exclude Gains/Losses</Typography>
          </Grid>
          <Grid container item xs={4} alignItems="center">
            <WO2ToggleSwitch
              checked={parameters.selectedReports.portfolioValuation.excludeAvgUnitCost}
              onChange={(e) =>
                setPortfolioValuationHidingOptionParameter({
                  value: e.target.checked,
                  option: HideByPortfolioValuation.AvgUnitCost,
                })
              }
              color="primary"
              name="hidingOptions"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
            <Typography variant="h4">Exclude Avg. Unit Cost</Typography>
          </Grid>
          <Grid container item xs={4} alignItems="center">
            <WO2ToggleSwitch
              checked={parameters.selectedReports.portfolioValuation.excludeEstIncomeYield}
              onChange={(e) =>
                setPortfolioValuationHidingOptionParameter({
                  value: e.target.checked,
                  option: HideByPortfolioValuation.EstIncomeAndYield,
                })
              }
              color="primary"
              name="hidingOptions"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
            <Typography variant="h4">Exclude Est. Income & Yield</Typography>
          </Grid>
          <Grid container item xs={4} alignItems="center">
            <WO2ToggleSwitch
              checked={parameters.selectedReports.portfolioValuation.excludeEstGrossIncomeYield}
              onChange={(e) =>
                setPortfolioValuationHidingOptionParameter({
                  value: e.target.checked,
                  option: HideByPortfolioValuation.EstGrossIncomeAndYield,
                })
              }
              color="primary"
              name="hidingOptions"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
            <Typography variant="h4">Exclude Est. Gross Income & Yield</Typography>
          </Grid>
          <Grid container item xs={4} alignItems="center">
            <WO2ToggleSwitch
              checked={parameters.selectedReports.portfolioValuation.excludeFXGainsLosses}
              onChange={(e) =>
                setPortfolioValuationHidingOptionParameter({
                  value: e.target.checked,
                  option: HideByPortfolioValuation.FxGainsLosses,
                })
              }
              color="primary"
              name="hidingOptions"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
            <Typography variant="h4">Exclude FX Gains/Losses</Typography>
          </Grid>
          <Grid container item xs={4} alignItems="center">
            <WO2ToggleSwitch
              checked={parameters.selectedReports.portfolioValuation.excludeLocalPriceValue}
              onChange={(e) =>
                setPortfolioValuationHidingOptionParameter({
                  value: e.target.checked,
                  option: HideByPortfolioValuation.LocalPriceAndValue,
                })
              }
              color="primary"
              name="hidingOptions"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
            <Typography variant="h4">Exclude Local Price & Value</Typography>
          </Grid>
          <Grid container item xs={4} alignItems="center">
            <WO2ToggleSwitch
              checked={parameters.selectedReports.portfolioValuation.applyExclusionList}
              onChange={(e) =>
                setPortfolioValuationHidingOptionParameter({
                  value: e.target.checked,
                  option: HideByPortfolioValuation.ExclusionList,
                })
              }
              color="primary"
              name="hidingOptions"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
            <Typography variant="h4">Apply Exclusion List</Typography>
          </Grid>
        </Grid>
        <Grid item>
          <Autocomplete
            noOptionsText="No items to select"
            id="portfoliovaluation-investmentservice-multiselect"
            fullWidth
            value={clientPortfolios.find(
              (portfolio) => portfolio.investmentProgramId === parameters.selectedReports.assetAllocation.portfolioId
            )}
            options={clientPortfolios}
            getOptionLabel={(option: ClientPortfolio) => option.investmentServiceName}
            renderInput={(params) => (
              <TextField {...params} label="Select a Portfolio" margin="normal" variant="outlined" fullWidth />
            )}
            onChange={(_e: React.SyntheticEvent<Element, Event>, value: ClientPortfolio | null) =>
              handleSelectPortfolio(value)
            }
            isOptionEqualToValue={(option: ClientPortfolio, value: ClientPortfolio) =>
              option.investmentProgramId === value.investmentProgramId
            }
          />
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
