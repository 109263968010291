import { Skeleton, Typography } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import React, { useEffect, useState } from 'react';
import { LoadingProgress } from 'src/common/store/types';
import { WO2Modal } from '../../../../../../../common/components/Modal';
import { ValidationMessage } from '../../store/activation';

export interface Props {
  onClose: () => void;
  activationValidationMessages: ValidationMessage[];
  fetchValidationMessages: () => Promise<void>;
  progress: LoadingProgress;
}

export function ValidationMessagesDialog({ onClose, activationValidationMessages, fetchValidationMessages, progress }: Props): JSX.Element {
  const [hasValidationErrors, setHasValidationErrors] = useState<boolean>(false);
  const [hasValidationWarnings, setHasValidationWarnings] = useState<boolean>(false);

  useEffect(() => {
    setHasValidationErrors(!!activationValidationMessages.find((m) => m.type === 'Error'));
    setHasValidationWarnings(!!activationValidationMessages.find((m) => m.type === 'Warning'));
  }, [activationValidationMessages]);

  return (
    <WO2Modal
      MuiDialogProps={{
        open: true,
        onClose: () => {
          onClose();
        },
        maxWidth: 'md',
      }}
      title={activationValidationMessages.length === 0 ? <>Activate portfolio version</> : <>Validation warnings</>}
      actions={
        !hasValidationErrors
          ? [
              {
                label: 'Close',
                onClickHandler: () => {
                  onClose();
                },
                type: 'outlined',
                disabled: progress.isLoading,
              },
              {
                label: 'Activate',
                onClickHandler: async () => {
                  await fetchValidationMessages();
                },
                type: 'contained',
                disabled: progress.isLoading,
              },
            ]
          : [
              {
                label: 'Close',
                onClickHandler: () => {
                  onClose();
                },
                type: 'outlined',
                disabled: false,
              },
            ]
      }
    >
      <>
        {progress.isLoading && (
          <>
            <Skeleton variant="rectangular" width="100%" height={50} style={{ marginTop: '30px' }} />
            <Skeleton variant="rectangular" width="100%" height={118} style={{ marginTop: '10px' }} />
          </>
        )}
        {!progress.isLoading && activationValidationMessages.length === 0 && (
          <>
            <Typography variant="h4" style={{ fontWeight: 700, paddingTop: '15px' }}>
              Are you sure you wish to activate this portfolio version?
            </Typography>
            <p style={{ marginTop: '16px', marginBottom: '8px' }}>Once active, any rebalances will use this version of the portfolio.</p>
          </>
        )}
        {!progress.isLoading && activationValidationMessages.length > 0 && (
          <>
            <Typography variant="h4" style={{ fontWeight: 700, paddingTop: '15px' }}>
              {hasValidationErrors && hasValidationWarnings && (
                <>
                  There are errors that require attention before you approve this portfolio. Warnings may require your attention, however the portfolio can
                  still be activated.
                </>
              )}
              {hasValidationErrors && !hasValidationWarnings && <>There are errors that require attention before you approve this portfolio.</>}
              {!hasValidationErrors && hasValidationWarnings && (
                <>There are warnings that may require your attention, however the portfolio can still be activated.</>
              )}
            </Typography>
            <br />
            <DataGridPro
              autoHeight={true}
              columns={[
                {
                  headerName: 'PAGE',
                  field: 'page',
                  flex: 0.2,
                },
                {
                  headerName: 'DESCRIPTION',
                  field: 'description',
                  flex: 0.6,
                },
                {
                  headerName: 'TYPE',
                  field: 'type',
                  flex: 0.2,
                },
              ]}
              rows={activationValidationMessages.map((m, index) => ({ id: index, ...m }))}
              hideFooter={true}
            />
          </>
        )}
      </>
    </WO2Modal>
  );
}
