import { TabRoute } from '../../store';
import { ModelAssociationsStateContainer } from './associations/container';
import { ModelCompositionStateContainer } from './composition/container';
import { ModelDashboardStateContainer } from './dashboard/container';
import { ModelDetailsStateContainer } from './details/container';
import { ModelPerformanceStateContainer } from './performance/container';

export enum modelTabRouteLabels {
  DASHBOARD = 'DASHBOARD',
  PERFORMANCE = 'PERFORMANCE',
  COMPOSITION = 'COMPOSITION',
  DETAILS = 'DETAILS',
  ASSOCIATED_CLIENTS = 'ASSOCIATED CLIENTS',
}

export const modelTabRoutes: TabRoute[] = [
  {
    label: modelTabRouteLabels.DASHBOARD,
    order: 0,
    path: '/dashboard',
    component: ModelDashboardStateContainer,
    visible: (isVisible?: boolean): boolean => isVisible || false,
  },
  {
    label: modelTabRouteLabels.PERFORMANCE,
    order: 1,
    path: '/performance',
    component: ModelPerformanceStateContainer,
    visible: (isVisible?: boolean): boolean => isVisible || false,
  },
  {
    label: modelTabRouteLabels.COMPOSITION,
    order: 2,
    path: '/composition',
    component: ModelCompositionStateContainer,
    visible: (isVisible?: boolean): boolean => isVisible || false,
  },
  {
    label: modelTabRouteLabels.DETAILS,
    order: 3,
    path: '/details',
    component: ModelDetailsStateContainer,
    visible: (isVisible?: boolean): boolean => isVisible || false,
  },
  {
    label: modelTabRouteLabels.ASSOCIATED_CLIENTS,
    order: 4,
    path: '/associated-clients',
    component: ModelAssociationsStateContainer,
    visible: (isVisible?: boolean): boolean => isVisible || false,
  },
];
