export enum TieredValidateResultTypes {
  Success,
  NoTieredItem,
  FirstFromValueNotZero,
  ValuesOverlap,
  GapBetweenValues,
}

interface FeeTieredValidation {
  errorType: number;
  errorMessage: string;
}

export const tieredValidations: FeeTieredValidation[] = [
  {
    errorType: TieredValidateResultTypes.NoTieredItem,
    errorMessage: 'At least one tiered item is required.',
  },
  {
    errorType: TieredValidateResultTypes.FirstFromValueNotZero,
    errorMessage: 'The from value of first tiered item must be 0.',
  },
  {
    errorType: TieredValidateResultTypes.ValuesOverlap,
    errorMessage: 'Tier values overlap. Please update the tier values to save.',
  },
  {
    errorType: TieredValidateResultTypes.GapBetweenValues,
    errorMessage: 'The tiers entered are not continuous. Please update the tier values to save.',
  },
];
