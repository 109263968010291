import {
  Accordion,
  AccordionSummary,
  FormControlLabel,
  AccordionDetails,
  Typography,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
} from '@mui/material';
import React from 'react';
import { WO2Checkbox, WO2Select, WO2ToggleSwitch } from 'src/common';
import DateRangePicker from 'src/common/components/DateRangePicker';
import { ActionCreatorWithoutPayload, ActionCreatorWithPayload } from '@reduxjs/toolkit';
import {
  ClientReportsParameters,
  DateTypeIncomeReport,
  GroupByIncomeReport,
  MdaReportTypes,
  SortByIncomeReport,
} from '../../store/types';
import { ExpandMore } from '@mui/icons-material';
import { DateRange } from 'src/store';
import { DateTime } from 'luxon';

export interface IncomeReportProps {
  parameters: ClientReportsParameters;
  expandIncomeReport: ActionCreatorWithoutPayload<string>;
  setIncomeReportSelectedParameter: ActionCreatorWithPayload<boolean, string>;
  setIncomeReportExcludeCashFilterOptionsParameter: ActionCreatorWithPayload<boolean, string>;
  setIncomeReportOutstandingPaymentsOnlyFilterOptionsParameter: ActionCreatorWithPayload<boolean, string>;
  setIncomeReportGroupByParameter: ActionCreatorWithPayload<GroupByIncomeReport, string>;
  setIncomeReportSortByParameter: ActionCreatorWithPayload<SortByIncomeReport, string>;
  setDateRangeIncomeReportParameter: ActionCreatorWithPayload<DateRange, string>;
  setIncomeReportDateTypeParameter: ActionCreatorWithPayload<DateTypeIncomeReport, string>;
  createReportSortRequest: (isAdd: boolean, id: number, text: string) => void;
}

export const IncomeReport = ({
  parameters,
  setIncomeReportSelectedParameter,
  setIncomeReportExcludeCashFilterOptionsParameter,
  setIncomeReportOutstandingPaymentsOnlyFilterOptionsParameter,
  setIncomeReportSortByParameter,
  setIncomeReportDateTypeParameter,
  setIncomeReportGroupByParameter,
  setDateRangeIncomeReportParameter,
  createReportSortRequest,
  expandIncomeReport,
}: IncomeReportProps): JSX.Element => {
  const handleClickIncomeReportSelect = () => {
    setIncomeReportSelectedParameter(!parameters.selectedReports?.incomeReport.selected);
    createReportSortRequest(
      !parameters.selectedReports?.incomeReport.selected,
      MdaReportTypes.IncomeReport.id,
      MdaReportTypes.IncomeReport.displayName
    );
  };

  const handleClickIncomeReportExcludeCashFilterOptions = () => {
    setIncomeReportExcludeCashFilterOptionsParameter(!parameters.selectedReports?.incomeReport.excludeCash);
  };

  const handleClickIncomeReportOutstandingPaymentsOnlyFilterOptions = () => {
    const toggled = parameters.selectedReports?.incomeReport.showOutstandingPaymentOnly;
    setIncomeReportOutstandingPaymentsOnlyFilterOptionsParameter(!toggled);

    if (!toggled) {
      setDateRangeIncomeReportParameter({
        dateTo: DateTime.now().toISODate(),
        dateFrom: parameters.selectedReports?.incomeReport.fromDate,
        dateRangeType: "Today's Date",
      });
    }
  };

  const handleClickReportDateIncomeReport = (dates: DateRange) => {
    setDateRangeIncomeReportParameter(dates);
  };

  return (
    <Accordion
      onChange={expandIncomeReport}
      expanded={parameters.selectedReports?.incomeReport.expanded || false}
      elevation={0}
    >
      <AccordionSummary expandIcon={<ExpandMore />}>
        <FormControlLabel
          sx={{ flex: 1 }}
          aria-label="Acknowledge"
          onClick={(event) => event.stopPropagation()}
          onFocus={(event) => event.stopPropagation()}
          control={
            <WO2Checkbox
              color="primary"
              checked={parameters.selectedReports?.incomeReport.selected}
              onChangeHandler={handleClickIncomeReportSelect}
            />
          }
          label="Income Report"
        />
        <div onClick={(e) => e.stopPropagation()}>
          <DateRangePicker
            dateRange={{
              dateFrom: parameters?.selectedReports.incomeReport?.fromDate,
              dateTo: parameters?.selectedReports.incomeReport?.toDate,
              dateRangeType: parameters?.selectedReports.incomeReport?.dateRangeType,
            }}
            setDateRange={handleClickReportDateIncomeReport}
          />
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container direction="column">
          <Grid item container spacing={1}>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth>
                <InputLabel id="group-by-id">Group by</InputLabel>
                <WO2Select
                  labelId="group-by-id"
                  label="group by"
                  onChange={(e) => setIncomeReportGroupByParameter(e.target.value as GroupByIncomeReport)}
                  value={parameters.selectedReports.incomeReport.groupBy}
                  defaultValue={GroupByIncomeReport.Account}
                >
                  <MenuItem value={GroupByIncomeReport.Account}>Account</MenuItem>
                  <MenuItem value={GroupByIncomeReport.IncomeType}>Income Type</MenuItem>
                </WO2Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth>
                <InputLabel id="sort-by-id">Sort by</InputLabel>
                <WO2Select
                  label="Sort by"
                  labelId="sort-by-id"
                  onChange={(e) => setIncomeReportSortByParameter(e.target.value as SortByIncomeReport)}
                  value={parameters.selectedReports.incomeReport.sortBy}
                  defaultValue={SortByIncomeReport.Security}
                >
                  <MenuItem value={SortByIncomeReport.Security}>Security</MenuItem>
                  <MenuItem value={SortByIncomeReport.PaymentDate}>Payment Date</MenuItem>
                </WO2Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth disabled={parameters.selectedReports.incomeReport.showOutstandingPaymentOnly}>
                <InputLabel id="date-type-id">Date Type</InputLabel>
                <WO2Select
                  label="Date Type"
                  labelId="date-type-id"
                  onChange={(e) => setIncomeReportDateTypeParameter(e.target.value as DateTypeIncomeReport)}
                  value={parameters.selectedReports.incomeReport.dateType}
                  defaultValue={DateTypeIncomeReport.ExDate}
                >
                  <MenuItem value={DateTypeIncomeReport.ExDate}>Ex-Date</MenuItem>
                  <MenuItem value={DateTypeIncomeReport.TaxDate}>Tax Date</MenuItem>
                  <MenuItem value={DateTypeIncomeReport.PaymentDate}>Payment Date</MenuItem>
                </WO2Select>
              </FormControl>
            </Grid>
          </Grid>

          <Grid item container direction="row" alignItems="center" spacing={1} mt={1}>
            <Grid item container alignItems="center" xs={6} flexWrap="nowrap">
              <WO2ToggleSwitch
                checked={parameters.selectedReports.incomeReport.showOutstandingPaymentOnly}
                onChange={handleClickIncomeReportOutstandingPaymentsOnlyFilterOptions}
                color="primary"
                name="hidingOptions"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
              <Typography variant="h4">Show Outstanding Payments Only</Typography>
            </Grid>
            <Grid item container alignItems="center" xs={6} flexWrap="nowrap">
              <WO2ToggleSwitch
                checked={parameters.selectedReports.incomeReport.excludeCash}
                onChange={handleClickIncomeReportExcludeCashFilterOptions}
                color="primary"
                name="hidingOptions"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
              <Typography variant="h4">Exclude Cash</Typography>
            </Grid>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
