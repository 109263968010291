import { Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { Props } from '../container';
import { AccountsTable } from './accountsTable';

export const LinkedAccounts = ({
  clientId,
  investmentServiceVersionId,
  accounts,
  loadingProgress,
  fetchAccounts,
  saveAccounts,
  savingProgress,
}: Props): JSX.Element => {
  useEffect(() => {
    if (!!investmentServiceVersionId && !!clientId) {
      fetchAccounts({ investmentServiceVersionId, clientId });
    }
  }, [fetchAccounts, investmentServiceVersionId, clientId]);

  return (
    <>
      <Typography variant="h3" style={{ paddingBottom: '20px' }}>
        Linked Accounts
      </Typography>
      <Typography variant="h5" style={{ paddingBottom: '30px' }}>
        The accounts below are linked to this portfolio and transactions on this portfolio will be managed by the relevant institution. You can link or unlink
        accounts from this page, however they will remain associated to the client account.
      </Typography>
      <AccountsTable
        items={accounts}
        onSave={async (accountIds: number[]) => {
          if (!!clientId && !!investmentServiceVersionId) {
            await saveAccounts({
              clientId,
              investmentServiceVersionId,
              accountIds,
            });
          }
        }}
        itemLoadingProgress={loadingProgress}
        savingProgress={savingProgress}
      />
    </>
  );
};
