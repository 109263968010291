import { Alert, Box, Typography } from '@mui/material';
import React from 'react';
import { ValidationResult } from '../store/types';
import { ADVISER_CONTACT_EMAIL } from 'src/common/utils/dashContactDetails';

interface Props {
  validationMessages: ValidationResult;
  isSubmitted: boolean;
}

export default function ValidationMessages(props: Props): JSX.Element {
  const { validationMessages, isSubmitted } = props;

  return (
    <Box marginTop={'15px'} marginBottom={'15px'} marginLeft={'10px'} marginRight={'10px'}>
      {isSubmitted && (
        <Alert variant="outlined" severity="success">
          <Typography>
            The bulk order has been successfully submitted. Please go to the rebalance screen on the DASH adviser portal
            for next steps or to check the status.
          </Typography>
        </Alert>
      )}
      {validationMessages && validationMessages.errors && validationMessages.errors.length > 0 && (
        <Alert variant="outlined" severity="error">
          <Typography>
            The following trade(s) require your attention. Please review and update them before submitting again. Please
            contact us on 1300 726 008 or <a href={`mailto: ${ADVISER_CONTACT_EMAIL}`}>{ADVISER_CONTACT_EMAIL}</a> for
            assistance.
          </Typography>
          <ul style={{ textAlign: 'left' }}>
            {validationMessages.errors.map((errors) => (
              <li key={errors}>{errors}</li>
            ))}
          </ul>
        </Alert>
      )}
      {validationMessages && validationMessages.warnings && validationMessages.warnings.length > 0 && (
        <Alert variant="outlined" severity="warning">
          <Typography>The following trade(s) are outside the portfolio&apos;s parameters.</Typography>
          <ul style={{ textAlign: 'left' }}>
            {validationMessages.warnings.map((warning) => (
              <li key={warning}>{warning}</li>
            ))}
          </ul>
        </Alert>
      )}
    </Box>
  );
}
