import { createSelector } from '@reduxjs/toolkit';
import { selectClientState } from '../../../store/selectors';
import { selectDetails } from './../../../details/store/selectors';

export const selectSuperSimplifierDetails = createSelector(selectDetails, (benefitStatement) => benefitStatement.superSimplifierDetails);
export const selectBeneficiaries = createSelector(selectSuperSimplifierDetails, (superSimplifierDetails) => superSimplifierDetails.beneficiaries.beneficiaries);
export const selectSuperMemberNumber = createSelector(selectClientState, (clientState) => clientState.common.superSimplifier?.superMemberNumber);
export const selectClientId = createSelector(selectClientState, (clientState) => clientState.common.clientId);
export const selectAuthorisedContactName = createSelector(
  selectClientState,
  (clientState) => clientState.details.contacts.items.find((x) => x.isPrimary === true)?.name
);
