import { connect, ConnectedProps } from 'react-redux';
import { progressSelectorFactory } from '../../../common';
import { RootState } from '../../../reducers';
import { FxList } from './components/list';
import { selectParameters, selectForexCurrencyPairList } from './store/selectors';
import { fxListSlice } from './store/slice';
import { fetchFxList, FxListActionTypes } from './store/thunks';

const mapStateToProps = (state: RootState) => ({
  parameters: selectParameters(state),
  fxList: selectForexCurrencyPairList(state),
  fxListProgress: progressSelectorFactory([FxListActionTypes.FetchFxList])(state),
});

const mapDispatchToProps = {
  ...fxListSlice.actions,
  fetchFxList
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector>;
export const FxRateListStateContainer = connect(mapStateToProps, mapDispatchToProps)(FxList);
