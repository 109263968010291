import { AnyAction, CombinedState, combineReducers, Dispatch } from '@reduxjs/toolkit';
import { commonSlice } from '../common/store/slice';
import { ClientCommonState } from '../common/store/types';
import { dashboardSlice } from '../dashboard/store/slice';
import { DashboardState } from '../dashboard/store/types';
import { detailsReducer, initialState as detailsInitialState } from '../details/store';
import { DetailsState } from '../details/store/reducer';
import { gainsAndLossesSlice } from '../gainslosses/store/slice';
import {
  initialState as capitalgainslossesInitialState,
  capitalGainsAndLossesReducer,
} from '../capitalgainslosses/store/reducer';
import { GainsLossesState } from '../gainslosses/store/types';
import { CapitalGainsLossesState } from '../capitalgainslosses/store/types';
import { performanceSlice } from '../performance/store/slice';
import { PerformanceState } from '../performance/store/types';
import { initialState as portfolioInitialState, portfolioReducer } from '../portfolio/store/reducer';
import { PortfolioState } from '../portfolio/store/types';
import { reportsSlice } from '../reports/store/slice';
import { ClientReportsState } from '../reports/store/types';
import { transactionsSlice } from '../transactions/store/slice';
import { TransactionsState } from '../transactions/store/types';
import { newReportsSlice } from '../reportsV2/store/slice';
import { ClientNewReportsState } from '../reportsV2/store/types';

export enum ClientReducerActionTypes {
  ResetClientState = '@@client/ResetClientState',
}

export interface ClientState {
  common: ClientCommonState;
  dashboard: DashboardState;
  performance: PerformanceState;
  portfolio: PortfolioState;
  gainsAndLosses: GainsLossesState;
  capitalGainsAndLosses: CapitalGainsLossesState;
  transactions: TransactionsState;
  details: DetailsState;
  reports: ClientReportsState;
  newReports: ClientNewReportsState;
}

export const clientReducer = combineReducers<ClientState>({
  common: commonSlice.reducer,
  dashboard: dashboardSlice.reducer,
  performance: performanceSlice.reducer,
  portfolio: portfolioReducer,
  gainsAndLosses: gainsAndLossesSlice.reducer,
  capitalGainsAndLosses: capitalGainsAndLossesReducer,
  transactions: transactionsSlice.reducer,
  details: detailsReducer,
  reports: reportsSlice.reducer,
  newReports: newReportsSlice.reducer,
});

export const clientRootReducer = (state: ClientState | undefined, action: AnyAction): CombinedState<ClientState> => {
  if (action.type === ClientReducerActionTypes.ResetClientState) {
    if (!!state) {
      state.common = commonSlice.getInitialState();
      state.dashboard = dashboardSlice.getInitialState();
      state.performance = performanceSlice.getInitialState();
      state.portfolio = portfolioInitialState;
      state.capitalGainsAndLosses = capitalgainslossesInitialState;
      state.gainsAndLosses = gainsAndLossesSlice.getInitialState();
      state.transactions = transactionsSlice.getInitialState();
      state.details = detailsInitialState;
      state.reports = reportsSlice.getInitialState();
      state.newReports = newReportsSlice.getInitialState();
    }
  }

  return clientReducer(state, action);
};

export const resetClientStateAction: AnyAction = {
  type: ClientReducerActionTypes.ResetClientState,
};

export function setClientIdParameterActionCreator(clientId: number) {
  return (dispatch: Dispatch): void => {
    dispatch(commonSlice.actions.setClientIdParameter(clientId));
  };
}
