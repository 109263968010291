import { Enumeration } from 'src/common/types';

export class RebalanceTypeEnum extends Enumeration {
  static Monthly = new RebalanceTypeEnum(1, 'Monthly', 'Monthly');
  static Quarterly = new RebalanceTypeEnum(2, 'Quarterly', 'Quarterly');
  static BiYearly = new RebalanceTypeEnum(3, 'BiYearly', 'BiYearly');
  static Annual = new RebalanceTypeEnum(4, 'Annual', 'Annual');

  static getById = (status: number): RebalanceTypeEnum | undefined => {
    return Object.values(RebalanceTypeEnum)
      .filter((t) => t instanceof RebalanceTypeEnum)
      .find((t: RebalanceTypeEnum) => t.id === status);
  };

  static getByName = (name: string): RebalanceTypeEnum | undefined => {
    return Object.values(RebalanceTypeEnum)
      .filter((t) => t instanceof RebalanceTypeEnum)
      .find((t: RebalanceTypeEnum) => t.name.toLowerCase() === name.toLowerCase());
  };
}

export class ConstraintNameEnum extends Enumeration {
  static DoNotBuy = new ConstraintNameEnum(1, 'DoNotBuy', 'Do Not Buy');
  static DoNotSell = new ConstraintNameEnum(2, 'DoNotSell', 'Do Not Sell');

  static getById = (status: number | undefined): ConstraintNameEnum | undefined => {
    return Object.values(ConstraintNameEnum)
      .filter((t) => t instanceof ConstraintNameEnum)
      .find((t: ConstraintNameEnum) => t.id === status);
  };

  static getByName = (name: string): ConstraintNameEnum | undefined => {
    return Object.values(ConstraintNameEnum)
      .filter((t) => t instanceof ConstraintNameEnum)
      .find((t: ConstraintNameEnum) => t.name.toLowerCase() === name.toLowerCase());
  };

  static getAll = (): ConstraintNameEnum[] => {
    return Object.values(ConstraintNameEnum)
      .filter((t) => !!t['name'])
      .map((val) => {
        return { id: val.id, name: val.name, displayName: val.displayName };
      });
  };
}

export class ConstraintTypeEnum extends Enumeration {
  static Substitute = new ConstraintTypeEnum(1, 'Substitute', 'Substitute');
  static ProRata = new ConstraintTypeEnum(2, 'ProRata', 'Pro Rata');

  static getById = (status: number | undefined): ConstraintTypeEnum | undefined => {
    return Object.values(ConstraintTypeEnum)
      .filter((t) => t instanceof ConstraintTypeEnum)
      .find((t: ConstraintTypeEnum) => t.id === status);
  };

  static getByName = (name: string): ConstraintTypeEnum | undefined => {
    return Object.values(ConstraintTypeEnum)
      .filter((t) => t instanceof ConstraintTypeEnum)
      .find((t: ConstraintTypeEnum) => t.name.toLowerCase() === name.toLowerCase());
  };

  static getAll = (): ConstraintTypeEnum[] => {
    return Object.values(ConstraintTypeEnum)
      .filter((t) => !!t['name'])
      .map((val) => {
        return { id: val.id, name: val.name, displayName: val.displayName };
      });
  };
}
