import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ResultWrapper } from '../../benefitStatement/store/types';
import { fetchAccountClients, fetchBeneficiaries } from './thunks';
import { AccountClient, BeneficiariesState, Beneficiary } from './types';

export const initialState: BeneficiariesState = {
  beneficiaries: {
    responseData: null,
  },
  accountClients: {
    responseData: null,
  },
};

export const beneficiariesSlice = createSlice({
  name: 'beneficiariesSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchBeneficiaries.fulfilled,
      (state, action: PayloadAction<ResultWrapper<Beneficiary[] | null>>) => {
        state.beneficiaries = action.payload;
      }
    );
    builder.addCase(
      fetchAccountClients.fulfilled,
      (state, action: PayloadAction<ResultWrapper<AccountClient[] | null>>) => {
        state.accountClients = action.payload;
      }
    );
  },
});
