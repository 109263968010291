import { ArrowBackOutlined } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoIcon from '@mui/icons-material/Info';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Breadcrumbs,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  OutlinedInput,
  Select,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import 'date-fns';
import { default as React, useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { formatDollars, roundDecimalToDigits } from 'src/common';
import { ProgressButton, WO2Modal } from 'src/common/components';
import { WithdrawalAccount } from 'src/common/components/accounts/types';
import { LoadingProgress } from 'src/common/store/types';
import Checkout from 'src/features/orders/rebalance/edit/components/checkout';
import Filters from 'src/features/orders/rebalance/edit/components/filters';
import Trades from 'src/features/orders/rebalance/edit/components/trades';
import ValidationMessages from 'src/features/orders/rebalance/edit/components/validationMessages';
import {
  FetchWithdrawalDetailsPayload,
  GroupedTrades,
  RebalanceKey,
  RebalanceMode,
  WithdrawalDetailsData,
} from 'src/features/orders/rebalance/edit/store/types';
import { theme } from 'src/themes';
import { RebalancePortfolioResponse } from '../../portfolio/store/rebalance';
import { Props } from '../container';

export const RebalanceComponent = (props: Props): JSX.Element => {
  const {
    history,
    clientId,
    accounts,
    fetchAccounts,
    fetchRebalance,
    isDataFromTriumph,
    resetIsPreApproved,
    rebalanceMode,
    rebalance,
    selectedExportTrades,
    groupedByKey,
    filter,
    setGroupedBy,
    setFilter,
    setClearAll,
    rebalanceValidationRequest,
    rebalanceKey,
    summary,
    isPreApproved,
    deleteRebalance,
    validateRebalance,
    setSellAll,
    setRebalanceMode,
    ignoreMinimumTrade,
    setIgnoreMinimumTrade,
    activeInvestmentServiceVersionId,
    setSaleMethod,
    setTargetCash,
    setWithdrawalMethod,
    setWithdrawValue,
    receivingAccount,
    setReceivingAccount,
    fetchWithdrawalDetailsData,
    saleMethod,
    targetCash,
    withdrawalMethod,
    withdrawCash,
    setWithdrawCash,
    withdrawValue,
    withdrawalDetailsData,
    common,
    rebalanceTemplate,
    validationMessages,
    setRebalanceValidated,
    filteredTrades,
    setAmountToTrade,
    setPriceLimit,
    setExpiryDate,
    setPriceType,
    setExpiryType,
    setMethod,
    setTrade,
    setClearTrade,
    deleteAbandonedRebalances,
    hasRebalanceEnhancedTrading,
    hasClientEditAdminOnlyPermission,
    importTrades,
    isImporting,
    importErrors,
    fetchSecurities,
    fetchSecurity,
    resetRebalance,
    addTrade,
    setClearGroupTrades,
    setClearSecuritySearch,
    selectedSecurity,
    securitySearchResults,
    rebalanceValidationWarnings,
    clearRebalance,
    setWithdrawalPercent,
    setWithdrawalAud,
    serviceTypeId,
  } = props;

  interface WithdrawalForm {
    rebalanceMode: RebalanceMode;
    ignoreMinimumTrade: boolean;
    saleMethod: string;
    targetCash: string;
    withdrawalMethod: string;
    withdrawCash: boolean;
    accountId: number | null;
    externalAccountId: number | null;
    withdrawalCashValue: string;
  }

  const [rebalanceTemplateProgress, setRebalanceTemplateProgress] = useState<LoadingProgress>({
    isLoading: false,
    hasErrors: false,
  });

  const [estimatedWithdrawalAUD, setEstimatedWithdrawalAUD] = useState(0);
  const [localWithdrawValue, setLocalWithdrawValue] = useState<number | undefined>(undefined);

  const clearAbandonedRebalances = () => {
    if (clientId && common.investmentServiceId) {
      deleteAbandonedRebalances({ clientId: clientId, investmentServiceId: common.investmentServiceId });
    }
  };

  useEffect(() => {
    resetIsPreApproved();
    return () => {
      if (history.location.pathname !== '/rebalance/checkout') {
        resetRebalance();
        clearAbandonedRebalances();
      }
    };
  }, []);

  useEffect(() => {
    if (clientId && activeInvestmentServiceVersionId) {
      const fetchWithdrawalRequest: FetchWithdrawalDetailsPayload = {
        clientId,
        portfolioVersionId: activeInvestmentServiceVersionId,
        isDataFromTriumph,
      };
      fetchWithdrawalDetailsData(fetchWithdrawalRequest);
    }
  }, [clientId, activeInvestmentServiceVersionId]);

  useEffect(() => {
    if (clientId) {
      fetchAccounts(clientId);
    }
  }, [clientId]);

  useEffect(() => {
    setReceivingAccount(withdrawalDetailsData.externalAccountId);
  }, [withdrawalDetailsData.externalAccountId]);

  const useStyles2 = makeStyles(() => ({
    root: {
      background: 'white',
    },
    helperText: {
      color: 'red !important',
      fontSize: '10px !important',
    },
  }));

  const handleWithdrawValueChange = (val: string) => {
    setLocalWithdrawValue(Number(val));
    setWithdrawValue(val);
  };

  const classes2 = useStyles2();

  const externalAccounts = accounts.externalAccounts.items
    .filter((acc) => acc.isExternal)
    .map((account) => (
      <MenuItem
        key={account.accountId}
        value={account.accountId}
        selected={withdrawalDetailsData.externalAccountId === account.accountId}
      >
        {`${account.accountName} (Acc: ${account.accountNumber}, BSB: ${account.bsb})`}
      </MenuItem>
    ));

  const tradingAccount = () => {
    return (
      accounts.tradingAccounts.items.filter((x) => (x as unknown as WithdrawalAccount).accountType === 2)[0]
        ?.accountId ?? 0
    );
  };

  const isFormInvalid = () => {
    if (rebalanceMode.name === RebalanceMode.SellDownToCash.name) {
      if (withdrawCash) {
        const result = receivingAccount !== null && localWithdrawValue !== undefined && localWithdrawValue > 0;
        if (!result) return false;
      }
      return Number(targetCash) > 0;
    }
    return true;
  };

  const withdrawalForm: WithdrawalForm = {
    rebalanceMode: rebalanceMode,
    ignoreMinimumTrade,
    saleMethod,
    targetCash,
    withdrawalMethod,
    withdrawCash,
    accountId: tradingAccount(),
    externalAccountId: receivingAccount,
    withdrawalCashValue: withdrawValue,
  };

  const handleRebalanceClick = () => {
    if (common.investmentServiceId && !!activeInvestmentServiceVersionId && !!clientId) {
      const investmentServiceId = common.investmentServiceId;
      deleteAbandonedRebalances({ clientId: clientId, investmentServiceId: investmentServiceId })
        .unwrap()
        .then(() => {
          const withdrawalDetailsPayload: WithdrawalDetailsData = {
            clientId: withdrawalDetailsData.clientId,
            clientName: withdrawalDetailsData.clientName,
            portfolioVersionId: activeInvestmentServiceVersionId,
            portfolioId: withdrawalDetailsData.portfolioId,
            portfolioName: withdrawalDetailsData.portfolioName,
            minTradeValue: withdrawalDetailsData.minTradeValue,
            inProcessWithdrawalAmount: withdrawalDetailsData.inProcessWithdrawalAmount,
            isRebalanceWithdrawalInProcess: withdrawalDetailsData.isRebalanceWithdrawalInProcess,
            minimumCma: withdrawalDetailsData.minimumCma,
            currentPortofolioCma: withdrawalDetailsData.currentPortofolioCma,
            avaliableCma: withdrawalDetailsData.avaliableCma,
            totalPortfolioHolding: withdrawalDetailsData.totalPortfolioHolding,
            totalEstimatedValue: withdrawalDetailsData.totalEstimatedValue,
            withdrawalAud: estimatedWithdrawalAUD,
            withdrawalCashAud: estimatedWithdrawalAUD,
            cashToWithdrawal: Number(withdrawalForm.withdrawalCashValue),
            isCashWithdrawal: withdrawalForm.withdrawCash,
            withdrawalOption: withdrawalForm.saleMethod,
            withdrawalPercent: withdrawalDetailsData.withdrawalPercent,
            adviserProceed: withdrawalDetailsData.adviserProceed,
            ignoreTradeParcel: withdrawalForm.ignoreMinimumTrade,
            accountId: withdrawalForm.accountId,
            externalAccountId: withdrawalForm.externalAccountId,
          };

          setRebalanceTemplateProgress({
            isLoading: true,
            hasErrors: false,
          });
          rebalanceTemplate({
            investmentServiceId: investmentServiceId,
            investmentServiceVersionId: activeInvestmentServiceVersionId,
            serviceTypeId: serviceTypeId === undefined ? null : serviceTypeId,
            userId: 0,
            clientId,
            url: '',
            rebalanceIdReplacer: '',
            effectiveDate: null,
            rebalanceType: rebalanceMode.name == RebalanceMode.SellDownToCash.name ? 'Withdrawal' : 'Single',
            withdrawalDetails:
              rebalanceMode.name === RebalanceMode.SellDownToCash.name ? withdrawalDetailsPayload : null,
            isDataFromTriumph: isDataFromTriumph,
          })
            .unwrap()
            .then((initiateResponse: RebalancePortfolioResponse) => {
              if (initiateResponse.validationMessages.length !== 0) {
                setRebalanceTemplateProgress({
                  isLoading: false,
                  hasErrors: false,
                });
              } else {
                setTimeout(() => {
                  const rebalanceKey = {
                    investmentServiceId: common.investmentServiceId,
                    rebalanceId: initiateResponse.rebalanceId,
                  } as RebalanceKey;
                  fetchRebalance(rebalanceKey)
                    .unwrap()
                    .then(() => {
                      setRebalanceTemplateProgress({
                        isLoading: false,
                        hasErrors: false,
                      });
                    });
                }, 10000);
              }
            });
        });
    }
  };

  const filtersProps = {
    groupedByKey,
    setGroupedBy,
    setFilter,
    setClearAll,
    filter,
    selectedExportTrades,
    rebalance,
    rebalanceValidationRequest,
    fetchRebalance,
    rebalanceKey,
    importTrades,
    isImporting,
    importErrors,
    fetchSecurities,
    fetchSecurity,
    addTrade,
    setClearGroupTrades,
    setClearSecuritySearch,
    selectedSecurity,
    securitySearchResults,
  };

  const validationMessagesProps = {
    validationMessages,
    setRebalanceValidated,
    rebalanceValidationRequest,
  };

  const tradesProps = {
    groupedByKey,
    setClearGroupTrades,
    setAmountToTrade,
    setPriceLimit,
    setExpiryDate,
    setPriceType,
    setExpiryType,
    setMethod,
    setTrade,
    setClearTrade,
    hasRebalanceEnhancedTrading,
    hasClientEditAdminOnlyPermission,
  };

  const checkoutProps = {
    summary,
    deleteRebalance,
    validateRebalance,
    setSellAll,
    rebalance,
    isPreApproved,
    rebalanceValidationRequest,
  };

  const targetCashCalc = (targetCashFieldInput: number | undefined, withdrawalMethod: string) => {
    if (targetCashFieldInput === undefined) {
      if (withdrawalMethod === '%') {
        setEstimatedWithdrawalAUD(0);
      }
      return;
    }

    let withdrawal = {
      percent: 0,
      value: 0,
    };
    if (withdrawalMethod === '%') {
      withdrawal = {
        percent: targetCashFieldInput,
        value: roundDecimalToDigits(
          (targetCashFieldInput / 100) * (withdrawalDetailsData?.totalEstimatedValue ?? 0),
          2
        ),
      };
    } else {
      withdrawal = {
        percent: withdrawalDetailsData.totalEstimatedValue ? roundDecimalToDigits(
          (targetCashFieldInput / withdrawalDetailsData.totalEstimatedValue) * 100,
          2
        ) : 0,
        value: targetCashFieldInput
      };
    }
    setWithdrawalMethod(withdrawalMethod);
    setWithdrawalPercent(withdrawal.percent);
    setWithdrawalAud(withdrawal.value);
    setEstimatedWithdrawalAUD(withdrawal.value);
    setTargetCash(targetCashFieldInput + '');
  };

  const getPreApprovalValidationResult = (): string[] => {
    if (!rebalance.preApprovalValidationResult) {
      return [];
    }
    const messages: string[] = [];
    if (rebalance.preApprovalValidationResult.warnings) {
      messages.push(...rebalance.preApprovalValidationResult.warnings);
    }
    if (rebalance.preApprovalValidationResult.errors) {
      messages.push(...rebalance.preApprovalValidationResult.errors);
    }
    if (rebalance.preApprovalValidationResult.information) {
      messages.push(...rebalance.preApprovalValidationResult.information);
    }
    return messages;
  };

  return (
    <>
      <Container>
        <Breadcrumbs aria-label="breadcrumb" style={{ paddingBottom: '20px', paddingTop: '20px' }}>
          <Link
            color="primary"
            onClick={() => {
              history.push(`../portfolio/overview`);
            }}
            style={{ textDecoration: 'none' }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
              }}
            >
              <ArrowBackOutlined />
              <span style={{ paddingLeft: '10px', paddingTop: '1px' }}>Back to portfolio</span>
            </div>
          </Link>
        </Breadcrumbs>
        <Accordion defaultExpanded elevation={2} sx={{ marginTop: '20px', marginBottom: '20px', borderRadius: '10px' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{ flexDirection: 'row-reverse', backgroundColor: '#EFF3F5', paddingLeft: '20px', marginTop: '20px' }}
          >
            <Grid item xs={6}>
              <Typography variant="h3" sx={{ color: '#848484' }}>
                Rebalancing{' '}
                {withdrawalDetailsData.portfolioName !== ''
                  ? withdrawalDetailsData.portfolioName
                  : props.entityDetails?.contactDetails?.name}
              </Typography>
            </Grid>
            <Grid xs={6} container justifyContent="flex-end" alignItems="flex-end">
              <Grid item style={{ paddingRight: '30px' }}>
                <Typography variant="h3" sx={{ color: '#848484' }}>
                  {formatDollars(withdrawalDetailsData?.totalEstimatedValue)}
                </Typography>
                <Typography variant="h6" sx={{ color: '#848484' }}>
                  Available Cash
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h3" sx={{ color: '#848484' }}>
                  {formatDollars(
                    Number(withdrawalDetailsData?.totalEstimatedValue ?? 0) - (withdrawalDetailsData?.minimumCma ?? 0)
                  )}
                </Typography>
                <Typography variant="h6" sx={{ color: '#848484' }}>
                  Max Withdrawal
                </Typography>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container mt="10px" alignItems="center">
              <Grid item container xs={3}>
                <Grid item>
                  <ToggleButtonGroup size="small" exclusive aria-label="small outlined button group">
                    <ToggleButton
                      color={'secondary'}
                      value={rebalanceMode}
                      onClick={() => {
                        setRebalanceMode(RebalanceMode.RebalancePortfolio);
                      }}
                      selected={rebalanceMode.name === RebalanceMode.RebalancePortfolio.name}
                    >
                      Rebalance portfolio
                    </ToggleButton>
                    <ToggleButton
                      value={rebalanceMode}
                      onClick={() => {
                        setRebalanceMode(RebalanceMode.SellDownToCash);
                      }}
                      selected={rebalanceMode.name == RebalanceMode.SellDownToCash.name}
                    >
                      Sell down to cash
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item flex={1}>
                  <Tooltip
                    title={
                      <Box padding="10px">
                        <Typography variant="inherit">
                          Generate a standard rebalance, or free up cash in the portfolio
                        </Typography>
                      </Box>
                    }
                    placement="right"
                    arrow
                  >
                    <InfoIcon
                      style={{
                        width: '18px',
                        height: '18px',
                        margin: '0 0 2px 2px',
                        fill: `${theme.palette.primary.main}`,
                      }}
                    />
                  </Tooltip>
                </Grid>
              </Grid>

              <Grid item xs={3}>
                {rebalanceMode.name === RebalanceMode.SellDownToCash.name && (
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={ignoreMinimumTrade}
                          onClick={() => setIgnoreMinimumTrade(!ignoreMinimumTrade)}
                          sx={{ marginBottom: '4px' }}
                        />
                      }
                      sx={{ color: '#A6A6A6' }}
                      label="IGNORE MINIMUM TRADE"
                    />
                  </FormGroup>
                )}
              </Grid>
              <Grid item xs={5}></Grid>
              <Grid item xs={1} container justifyContent="flex-end">
                <ProgressButton
                  type="submit"
                  hideicon
                  disabled={!isFormInvalid()}
                  onClick={handleRebalanceClick}
                  variant={'contained'}
                  progress={rebalanceTemplateProgress}
                  data-testid={'saveButton'}
                  style={{ minWidth: '160px', height: '43px', marginTop: '-8px' }}
                >
                  {rebalanceMode.name === RebalanceMode.SellDownToCash.name ? 'APPLY' : 'CALCULATE'}
                </ProgressButton>
              </Grid>
              {rebalanceMode.name === RebalanceMode.SellDownToCash.name && (
                <>
                  <Grid container sx={{ paddingBottom: '20px', paddingTop: '20px' }}>
                    <Tooltip
                      title={
                        <Box display="flex" flexDirection="column" gap="10px" padding="10px">
                          <Typography variant="inherit">
                            <strong>&apos;Align to targets&apos;</strong> - After adjusting for target cash, calculates
                            rebalance to portfolio targets.
                          </Typography>
                          <Typography variant="inherit">
                            <strong>&apos;Maintain current weights&apos;</strong> - To meet target cash, calculates sell
                            orders in proportion to current portfolio weights.
                          </Typography>
                        </Box>
                      }
                      placement="right"
                      arrow
                    >
                      <Typography
                        variant="h4"
                        sx={{
                          paddingLeft: '10px',
                          paddingTop: '10px',
                          width: '200px',
                        }}
                      >
                        Sell down settings
                        <InfoIcon
                          style={{
                            width: '18px',
                            height: '18px',
                            margin: '0 0 2px 2px',
                            fill: `${theme.palette.primary.main}`,
                          }}
                        />
                      </Typography>
                    </Tooltip>
                    <Grid item xs={10}></Grid>
                  </Grid>

                  <Grid container>
                    <Grid item xs={2}>
                      <FormControl variant="outlined" sx={{ width: '200px' }}>
                        <InputLabel id="saleMethodLabel" className={classes2.root}>
                          CALCULATION METHOD
                        </InputLabel>
                        <Select
                          labelId="saleMethodLabel"
                          id="saleMethodId"
                          value={saleMethod}
                          onChange={(e) => setSaleMethod(e.target.value)}
                        >
                          <MenuItem selected key={1} value="WithdrawalRebalance">
                            Align to target weights
                          </MenuItem>
                          <MenuItem key={2} value="ProRata">
                            Maintain current weights
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={2} container direction="column" alignItems="flex-end">
                      <NumberFormat
                        placeholder="Target Cash"
                        value={targetCash}
                        customInput={OutlinedInput}
                        type="text"
                        data-id={'targetCash'}
                        thousandSeparator
                        fixedDecimalScale
                        allowNegative={false}
                        decimalScale={2}
                        isAllowed={(values) => {
                          if (!values.floatValue) return true;
                          else if (
                            withdrawalMethod === '$' &&
                            values.floatValue <= (withdrawalDetailsData?.totalEstimatedValue ?? 0)
                          ) {
                            return true;
                          }
                          return withdrawalMethod === '%' && values.floatValue <= 100;
                        }}
                        onValueChange={(values) => {
                          targetCashCalc(values.floatValue, withdrawalMethod);
                        }}
                      />
                      {withdrawalMethod === '%' && (
                        <Typography variant="h5" sx={{ color: '#848484' }}>
                          {formatDollars(estimatedWithdrawalAUD)}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={1} sx={{ paddingLeft: '20px' }}>
                      <FormControl variant="outlined" sx={{ width: '100px' }}>
                        <Select
                          id="withdrawalMethod"
                          value={withdrawalMethod}
                          onChange={(e) => {
                            setWithdrawalMethod(e.target.value);
                            setTargetCash('0');
                            setEstimatedWithdrawalAUD(0);
                            handleWithdrawValueChange(0 + '');
                          }}
                        >
                          <MenuItem key={1} value={'%'}>
                            %
                          </MenuItem>
                          <MenuItem key={2} value={'$'}>
                            $
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                      <FormGroup sx={{ paddingTop: '6px' }}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={withdrawCash}
                              onClick={() => {
                                setWithdrawCash(!withdrawCash);
                              }}
                              sx={{ marginBottom: '3px' }}
                            />
                          }
                          sx={{ color: '#A6A6A6' }}
                          label="WITHDRAW CASH"
                        />
                      </FormGroup>
                    </Grid>
                    <Grid item xs={3}>
                      {withdrawCash && (
                        <FormControl variant="outlined" style={{ minWidth: '100%' }}>
                          <InputLabel id="receiving-account-label" className={classes2.root}>
                            RECEIVING ACCOUNT
                          </InputLabel>
                          <Select
                            labelId="receiving-account-label"
                            id="receiving-account"
                            sx={{ minWidth: '160px' }}
                            value={receivingAccount}
                            onChange={(e) => setReceivingAccount(e.target.value as number | null)}
                          >
                            {externalAccounts}
                          </Select>
                        </FormControl>
                      )}
                    </Grid>
                    <Grid item xs={2}>
                      {withdrawCash && (
                        <NumberFormat
                          placeholder={'Withdrawal Cash Value'}
                          value={localWithdrawValue}
                          style={{ marginLeft: '20px' }}
                          customInput={OutlinedInput}
                          type="text"
                          data-id={'withdrawalCashValue'}
                          thousandSeparator
                          fixedDecimalScale
                          allowNegative={false}
                          decimalScale={2}
                          isAllowed={(values) => {
                            return !values.floatValue
                              ? true
                              : withdrawalMethod === '$' && values.floatValue <= Number(targetCash)
                              ? true
                              : withdrawalMethod === '%' &&
                                values.floatValue <=
                                  Number(withdrawalDetailsData?.totalEstimatedValue ?? 0) -
                                    (withdrawalDetailsData?.minimumCma ?? 0)
                              ? true
                              : false;
                          }}
                          onValueChange={(values) => {
                            handleWithdrawValueChange(values.value);
                          }}
                        />
                      )}
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Container>

      <Container sx={{ marginTop: '20px' }}>
        <Filters {...filtersProps} selectedExportTrades={selectedExportTrades} />
        <ValidationMessages {...validationMessagesProps} />
        {filteredTrades &&
          rebalance.preApprovalValidationResult &&
          filteredTrades.map(
            (groupedTrades: GroupedTrades, index: number) =>
              groupedTrades.trades.length > 0 && (
                <Trades
                  {...tradesProps}
                  key={index}
                  groupedTrades={groupedTrades}
                  validationResults={getPreApprovalValidationResult()}
                />
              )
          )}
        <Checkout {...checkoutProps} totalValue={rebalance?.totalPortfolioValue ?? 0} />
        <WO2Modal
          MuiDialogProps={{
            open: rebalanceValidationWarnings.length > 0,
            onClose: () => {
              clearRebalance();
            },
          }}
          title={<>Rebalance failed</>}
          actions={[
            {
              label: 'Cancel',
              onClickHandler: () => {
                clearRebalance();
              },
              type: 'outlined',
            },
          ]}
        >
          <>
            <p>Rebalance is unable to initialise due to the following reason.</p>
            <ul>
              {rebalanceValidationWarnings.map((m, idx) => (
                <li key={idx}>{m}</li>
              ))}
            </ul>
          </>
        </WO2Modal>
      </Container>
    </>
  );
};
