import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  Account,
  AccountType,
  AttachableCmaAccounts,
  ExternalInstitution,
  PendingAccount,
} from 'src/common/components/accounts/types';
import {
  fetchAccounts,
  fetchAccountTypes,
  fetchAttachableCmaAccounts,
  fetchBPAYDetails,
  fetchClientContacts,
  fetchClientNewMacquarieAccountDetailByClientId,
  fetchExternalAccountForEdit,
  fetchExternalInstitutions,
  fetchPendingAccounts,
  fetchPendingSuperAccount,
  fetchTradingAccountForEdit,
} from './thunks';
import { AccountDetails, AccountsState, BPAYAccountDetails, ClientNewAccountDetail } from './types';
import { ContactRoles } from '../../superSimplifier/insuranceDetail/store/types';

export const initialState: AccountsState = {
  tradingAccounts: { items: [] },
  pendingAccounts: [],
  contactRoles: [],
  pendingSuperAccount: {
    bsb: '',
    errors: [],
    id: null,
    institution: null,
    institutionId: 0,
    isDataStatusReady: false,
    name: '',
    number: '',
    state: '',
    statusId: 0,
    statusMessage: '',
  },
  externalAccounts: { items: [] },
  existingAccounts: [],
  bPayAccountDetails: {
    bpayAccountDetails: []
  },
  attachableCmaAccounts: { attachableCmaAccounts: [] },
  newAccounts: {
    institutionIds: [],
    accountInfo: {
      isHinTransfer: null,
      hin: null,
      pid: null,
    },
  },
  accountTypes: [],
  externalInstitutions: [],
  macquarieCashOnlinePortal: {
    clientNewAccountDetails: [],
  },
};

export const accountsSlice = createSlice({
  name: 'accounts',
  initialState,
  reducers: {
    setTradingAccountAddMode: (state) => {
      state.tradingAccounts.edit = null;
    },
    cancelTradingAccountAddEditMode: (state) => {
      state.tradingAccounts.edit = undefined;
    },
    setExternalAccountAddMode: (state) => {
      state.externalAccounts.edit = null;
    },
    cancelExternalAccountAddEditMode: (state) => {
      state.externalAccounts.edit = undefined;
    },
    setAccountCreateTypeParameter: () => {
      // to do
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAccounts.fulfilled, (state, action: PayloadAction<Account[]>) => {
      state.tradingAccounts.items = action.payload.filter((a) => !a.isExternal);
      state.externalAccounts.items = action.payload.filter((a) => a.isExternal);
    });
    builder.addCase(fetchBPAYDetails.fulfilled, (state, action: PayloadAction<BPAYAccountDetails>) => {
      state.bPayAccountDetails = action.payload;
    });
    // If an error arises, the table should still be cleared and updated to reflect that there is
    // no attainable data for the user
    builder.addCase(fetchBPAYDetails.rejected, (state) => {
      state.bPayAccountDetails = {
        bpayAccountDetails: []
      };
    });
    builder.addCase(fetchPendingAccounts.fulfilled, (state, action: PayloadAction<PendingAccount[]>) => {
      state.pendingAccounts = action.payload;
    });
    builder.addCase(fetchPendingSuperAccount.fulfilled, (state, action: PayloadAction<PendingAccount>) => {
      state.pendingSuperAccount = action.payload;
    });
    builder.addCase(fetchAttachableCmaAccounts.fulfilled, (state, action: PayloadAction<AttachableCmaAccounts>) => {
      state.attachableCmaAccounts = action.payload;
    });
    builder.addCase(fetchExternalInstitutions.fulfilled, (state, action: PayloadAction<ExternalInstitution[]>) => {
      state.externalInstitutions = action.payload;
    });
    builder.addCase(fetchTradingAccountForEdit.pending, (state) => {
      state.tradingAccounts.edit = null;
    });
    builder.addCase(fetchTradingAccountForEdit.fulfilled, (state, action: PayloadAction<AccountDetails>) => {
      state.tradingAccounts.edit = action.payload;
    });
    builder.addCase(fetchExternalAccountForEdit.pending, (state) => {
      state.externalAccounts.edit = null;
    });
    builder.addCase(fetchExternalAccountForEdit.fulfilled, (state, action: PayloadAction<AccountDetails>) => {
      state.externalAccounts.edit = action.payload;
    });
    builder.addCase(fetchAccountTypes.fulfilled, (state, action: PayloadAction<AccountType[]>) => {
      state.accountTypes = action.payload;
    });
    builder.addCase(fetchClientContacts.fulfilled, (state, action: PayloadAction<ContactRoles[]>) => {
      state.contactRoles = action.payload;
    });
    builder.addCase(
      fetchClientNewMacquarieAccountDetailByClientId.fulfilled,
      (state, action: PayloadAction<ClientNewAccountDetail>) => {
        state.macquarieCashOnlinePortal = action.payload;
      }
    );
  },
});
