import { moment } from 'src/common/types';
import { ResultWrapper } from '../../benefitStatement/store/types';

export interface BeneficiariesState {
  beneficiaries: ResultWrapper<Beneficiary[] | null>;
  accountClients: ResultWrapper<AccountClient[] | null>;
}

export interface Beneficiary {
  id: number;
  name: string;
  dateOfBirth: moment;
  percentage: number;
  relationship: string;
  bindingFlag: string;
  effectiveFrom: moment;
  startDate: moment;
  endDate: moment;
  isNonLapsing?: string;
}

export interface AccountClient {
  accountId: string;
  clientId: string;
  effectiveFrom: moment;
  bindingFlag: string;
  name: string;
}

export interface BeneficiaryViewModel {
  name: string;
  relationship: string;
  type: string;
  percentage: string;
  startDate: moment | undefined;
  endDate: moment | undefined;
}

export const activeBindingTypes: string[] = [
  'Binding (Non-Lapsing)',
  'Binding (Lapsing)',
  'Binding',
  'Non Binding',
  'Reversionary',
];

export const inactiveBindingTypes: string[] = [
  'Binding Requested',
  'Binding Cancelled',
  'Expired',
  'Non-Binding Request Cancelled',
  'Non Binding Requested',
  'Revoked',
  'Revocation Request Cancelled',
  'Revocation Requested',
];

export interface PendingBeneficiariesPayload {
  superMemberNumber: string;
  pendingBeneficiaryDetails: PendingBeneficiaryDetails[];
}

export interface PendingBeneficiaryDetails {
  id?: number;
  firstName: string;
  lastName: string;
  dateOfBirth: moment;
  relationship: string;
  percentage: number;
  nominationType: string;
}