import { DeleteOutlined, Edit, Link as AttachContactIcon, PersonAdd } from '@mui/icons-material';
import { Avatar, Grid, IconButton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useConfirmation } from 'src/common/components/dialogs';
import { useDebounce } from 'src/common/hooks';
import { AttachContact, AttachContactDialogPayload } from 'src/features/clients/client/common/components/contacts';
import { RoleDefinition } from 'src/features/clients/common/enums';
import { getAvatarText } from '../../../../../../common';
import { ClientSideDataTable } from '../../../../../../common/components/dataTable/clientSide';
import { DatatableColumn, FilterDataType } from '../../../../../../common/components/dataTable/types';
import history from '../../../../../../history';
import { theme } from '../../../../../../themes';
import { useStyles } from '../../../../../../themes/index';
import { PageBackNextButtons } from '../../../components/common/pageBackNextButtons';
import { Props } from '../../container';

export const List = (props: Props): JSX.Element => {
  const confirm = useConfirmation();
  const classes = useStyles();

  const [contactSearchText, setContactSearchText] = useState<string>('');
  const onContactSearchTextChange = useDebounce<string>(contactSearchText, 500);
  const [attachContactOpen, setAttachContactOpen] = useState<boolean>(false);

  const {
    clientId,
    contacts,
    contactsToAttach,
    loadingContactsToAttach,
    prevNextRoutePaths,
    roles,
    additionalContactsStartIndex,
    saveProgress,
    accountTypeValues,
    fetchRoles,
    fetchContactsToAttach,
    fetchExistingContact,
    deleteContact,
    setContactEditIndex,
    contactsEditIndex,
  } = props;

  useEffect(() => {
    if (onContactSearchTextChange && onContactSearchTextChange.length > 2 && !!accountTypeValues.advisor?.advisorId) {
      fetchContactsToAttach({ searchText: onContactSearchTextChange, adviserId: accountTypeValues.advisor.advisorId });
    }
  }, [onContactSearchTextChange, fetchContactsToAttach]);

  useEffect(() => {
    // redirect to edit page if contactsEditIndex is null (new) or has value
    if (contactsEditIndex !== undefined) {
      history.push(`contacts/contact?id=${clientId}`);
    }
  }, [contactsEditIndex]);

  const nameColumn = (dataIndex: number): React.ReactNode => (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        marginRight: '10px',
      }}
    >
      <Avatar style={{ backgroundColor: theme.palette.primary.main, marginRight: '10px' }}>
        <Typography variant="h5"> {getAvatarText(`${contacts[dataIndex]?.details.firstName} ${contacts[dataIndex]?.details.lastName}`)}</Typography>
      </Avatar>
      <Typography variant="h5">{`${contacts[dataIndex]?.details.firstName} ${contacts[dataIndex]?.details.lastName}`}</Typography>
    </div>
  );

  const rolesColumn = (dataIndex: number): React.ReactNode => {
    return (
      <>
        <Typography variant="h5" style={{ fontWeight: 600 }}>
          {contacts[dataIndex]?.details.clientRoles.map((r) => RoleDefinition.getById(r.roleId)?.name).join(', ')}
        </Typography>
        <Typography variant="h5">{contacts[dataIndex]?.details.trusteeRoles.map((r) => RoleDefinition.getById(r.roleId)?.name).join(', ')}</Typography>
      </>
    );
  };

  const actionsColumn = (dataIndex: number): React.ReactNode => {
    return (
      <>
        {!contacts[dataIndex]?.contactId && (
          <IconButton
            aria-label="save"
            color="primary"
            data-testid={`editButton:${dataIndex}`}
            onClick={() => {
              setContactEditIndex(dataIndex + additionalContactsStartIndex);
            }}
          >
            <Edit></Edit>
          </IconButton>
        )}

        <IconButton
          aria-label="save"
          color="primary"
          data-testid={`removeButton:${dataIndex}`}
          onClick={() => {
            confirm({
              title: 'Remove contact',
              description: 'Are you sure you we to remove this contact?',
              description2: 'This action is permanent, please ensure you have selected the correct contact before proceeding.',
            }).then(() => {
              deleteContact(dataIndex + additionalContactsStartIndex);
            });
          }}
        >
          <DeleteOutlined />
        </IconButton>
      </>
    );
  };

  const columns: DatatableColumn[] = [
    {
      filterDataType: FilterDataType.string,
      name: 'name',
      label: 'NAME',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex): React.ReactNode => nameColumn(dataIndex),
      },
    },
    {
      name: 'roles',
      label: 'ROLES',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex): React.ReactNode => rolesColumn(dataIndex),
      },
    },
    {
      name: 'actions',
      label: ' ',
      textAlign: 'right',
      options: {
        filter: false,
        sort: false,
        viewColumns: false,
        customBodyRenderLite: (dataIndex): React.ReactNode => actionsColumn(dataIndex),
      },
    },
  ];

  const addButtons = (): React.ReactNode => {
    return (
      <>
        <IconButton
          className={classes.addToTable}
          disableFocusRipple
          disableRipple
          onClick={() => {
            setContactEditIndex(null);
          }}
        >
          <PersonAdd />
        </IconButton>
        <IconButton
          data-testid="attachContactButton"
          className={classes.addToTable}
          onClick={() => {
            setAttachContactOpen(true);
          }}
        >
          <AttachContactIcon />
        </IconButton>
      </>
    );
  };

  const onFetchContactsToAttach = (searchText: string) => {
    setContactSearchText(searchText);
  };

  const onAttachContact = (payload: AttachContactDialogPayload) => {
    if (!!accountTypeValues.advisor?.advisorId) {
      fetchExistingContact({ index: contacts.length + 1, contactId: payload.contactId, adviserId: accountTypeValues.advisor.advisorId, roles: payload.roles });
      setAttachContactOpen(false);
    }
  };

  const onBackButtonClick = () => {
    const { prevRoutePath } = prevNextRoutePaths;
    if (prevRoutePath) {
      history.push(prevRoutePath + (!!clientId ? `?id=${clientId}` : ''));
    }
  };

  const onNextButtonClick = () => {
    const { nextRoutePath } = prevNextRoutePaths;
    if (nextRoutePath) {
      history.push(nextRoutePath + (!!clientId ? `?id=${clientId}` : ''));
    }
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h4" gutterBottom>
          Contacts
        </Typography>
        <ClientSideDataTable
          loadingProgress={{ isLoading: false, hasErrors: false }}
          columns={columns}
          data={contacts}
          options={{
            filter: false,
            pagination: true,
            customToolbar: () => addButtons(),
          }}
        ></ClientSideDataTable>
        <AttachContact
          contacts={contactsToAttach}
          contactsProgress={loadingContactsToAttach}
          roles={roles}
          loadingRoles={{ isLoading: false, hasErrors: false }}
          isOpen={attachContactOpen}
          handleCloseModal={() => setAttachContactOpen(false)}
          onSave={(payload: AttachContactDialogPayload) => onAttachContact(payload)}
          fetchRoles={fetchRoles}
          fetchContactsToAttach={onFetchContactsToAttach}
          hideIsPrimarySwitch={true}
        ></AttachContact>
        <PageBackNextButtons<unknown>
          onNextButtonClick={onNextButtonClick}
          onBackButtonClick={onBackButtonClick}
          onQuitButtonClick={async () => {
            history.push('/client/list?mode=onboard');
          }}
          progress={saveProgress}
        />
      </Grid>
    </Grid>
  );
};
