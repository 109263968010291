import { createSelector } from '@reduxjs/toolkit';
import { hasErrorsSelectorFactory, isLoadingSelectorFactory } from '.';
import { LoadingProgress } from '../types';
import { Selector } from './../types/redux';

export const progressSelectorFactory = (actionTypes: string[]): Selector<LoadingProgress> => {
  return createSelector(isLoadingSelectorFactory(actionTypes), hasErrorsSelectorFactory(actionTypes), (isLoading, hasErrors) => {
    const ret: LoadingProgress = { isLoading, hasErrors };
    return ret;
  });
};
