import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { progressSelectorFactory } from '../../../../../common/store/selectors';
import { RootState } from '../../../../../reducers';
import { selectClientId } from '../../common/store/selectors';
import { CapitalGainsLosses } from './components/CapitalGainsLosses';
import { filteredRealisedResults, filteredUnrealisedResults, selectParameters } from './store/selectors';
import { selectEntityDetails } from '../../common/store/selectors';
import { overviewSlice } from './store/slice';
import {
  CGTOverviewActionTypes,
  fetchRealisedCapitalGainsAndLosses,
  fetchUnrealisedCapitalGainsAndLosses,
} from './store/thunks';

const mapStateToProps = (state: RootState) => ({
  clientId: selectClientId(state),
  entityDetails: selectEntityDetails(state),
  parameters: selectParameters(state),
  unrealisedResults: filteredUnrealisedResults(state),
  realisedResults: filteredRealisedResults(state),
  loadingRealisedProgress: progressSelectorFactory([CGTOverviewActionTypes.FetchRealisedCapitalGainsAndLosses])(state),
  loadingUnrealisedProgress: progressSelectorFactory([CGTOverviewActionTypes.FetchUnrealisedCapitalGainsAndLosses])(
    state
  ),
});

const mapDispatchToProps = {
  fetchUnrealisedCapitalGainsAndLosses,
  fetchRealisedCapitalGainsAndLosses,
  setDateParameter: overviewSlice.actions.setDateParameter,
  setGainTypeParameter: overviewSlice.actions.setGainTypeParameter,
  setFilterParameter: overviewSlice.actions.setFilterParameter,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector> & RouteComponentProps;
export const CapitalGainsLossesContainer = connect(mapStateToProps, mapDispatchToProps)(CapitalGainsLosses);
