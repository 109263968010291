import { Box, FormControlLabel } from '@mui/material';
import { WO2Checkbox } from 'src/common';
import { ClientReportsParameters, MdaReportTypes } from '../../store/types';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import React from 'react';
import { DateRange } from 'src/store';
import DateRangePicker from 'src/common/components/DateRangePicker';

export interface InvestmentIncomeTaxProps {
  parameters: ClientReportsParameters;
  setInvestmentIncomeTaxSelectedParameter: ActionCreatorWithPayload<boolean, string>;
  setDateRangeInvestmentIncomeTaxParameter: ActionCreatorWithPayload<DateRange>;
  createReportSortRequest: (isAdd: boolean, id: number, text: string) => void;
}

export const InvestmentIncomeTax = ({
  parameters,
  setInvestmentIncomeTaxSelectedParameter,
  setDateRangeInvestmentIncomeTaxParameter,
  createReportSortRequest,
}: InvestmentIncomeTaxProps) => {
  const handleClickInvestmentIncomeTaxSelect = () => {
    setInvestmentIncomeTaxSelectedParameter(!parameters.selectedReports?.investmentIncomeTaxComponent.selected);
    createReportSortRequest(
      !parameters.selectedReports?.holdingsVsTargetVariance.selected,
      MdaReportTypes.InvestmentIncomeTax.id,
      MdaReportTypes.InvestmentIncomeTax.displayName
    );
  };

  const handleClickDateForInvestmentIncomeTax = (dates: DateRange) => {
    setDateRangeInvestmentIncomeTaxParameter(dates);
  };
  return (
    <Box ml={6} display="flex" justifyContent="space-between" alignItems="center" mt={1} mb={1}>
      <FormControlLabel
        aria-label="Acknowledge"
        onClick={(event) => event.stopPropagation()}
        onFocus={(event) => event.stopPropagation()}
        control={
          <WO2Checkbox
            color="primary"
            checked={parameters.selectedReports?.investmentIncomeTaxComponent.selected}
            onChangeHandler={handleClickInvestmentIncomeTaxSelect}
          />
        }
        label="Investment Income Tax Component Report"
      />
      <DateRangePicker
        dateRange={{
          dateFrom: parameters?.selectedReports.investmentIncomeTaxComponent?.fromDate,
          dateTo: parameters?.selectedReports.investmentIncomeTaxComponent?.toDate,
          dateRangeType: parameters?.selectedReports.investmentIncomeTaxComponent?.dateRangeType,
        }}
        setDateRange={handleClickDateForInvestmentIncomeTax}
        inceptionDate={parameters.inceptionDate}
      />
    </Box>
  );
};
