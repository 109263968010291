import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionSummary, FormControlLabel, AccordionDetails, Grid, Typography } from '@mui/material';
import React from 'react';
import { WO2Checkbox, WO2ToggleSwitch } from 'src/common';
import { DateRange } from 'src/store';
import DateRangePicker from 'src/common/components/DateRangePicker';
import { ActionCreatorWithoutPayload, ActionCreatorWithPayload } from '@reduxjs/toolkit';
import {
  ClientReportsParameters,
  HideByAssetClassPerformance,
  HidingOptionsRequest,
  MdaReportTypes,
} from '../../store/types';

export interface AssetClassPerformanceProps {
  parameters: ClientReportsParameters;
  setAssetClassPerformanceHidingOptionParameter: ActionCreatorWithPayload<
    HidingOptionsRequest<HideByAssetClassPerformance>,
    string
  >;
  expandAssetClassPerformance: ActionCreatorWithoutPayload<string>;
  isDatesLessThanFullYear: (dates: DateRange) => boolean;
  setAssetClassPerformanceSelectedParameter: ActionCreatorWithPayload<boolean, string>;
  setDateRangeAssetClassPerformanceParameter: ActionCreatorWithPayload<DateRange, string>;
  createReportSortRequest: (isAdd: boolean, id: number, text: string) => void;
}

export const AssetClassPerformance = ({
  setAssetClassPerformanceHidingOptionParameter,
  setDateRangeAssetClassPerformanceParameter,
  setAssetClassPerformanceSelectedParameter,
  expandAssetClassPerformance,
  isDatesLessThanFullYear,
  createReportSortRequest,
  parameters,
}: AssetClassPerformanceProps) => {
  const handleClickDateForAssetClassPerformance = (dates: DateRange) => {
    setDateRangeAssetClassPerformanceParameter(dates);
    if (isDatesLessThanFullYear(dates)) {
      setAssetClassPerformanceHidingOptionParameter({
        option: HideByAssetClassPerformance.AnnualisedReturns,
        value: false,
      });
    }
  };

  const handleClickAssetClassPerformanceSelect = () => {
    setAssetClassPerformanceSelectedParameter(!parameters.selectedReports?.assetClassPerformance.selected);
    createReportSortRequest(
      !parameters.selectedReports?.assetClassPerformance.selected,
      MdaReportTypes.AssetClassPerformance.id,
      MdaReportTypes.AssetClassPerformance.displayName
    );
  };

  const dateRange: DateRange = {
    dateFrom: parameters?.selectedReports?.assetClassPerformance?.fromDate,
    dateTo: parameters?.selectedReports?.assetClassPerformance?.toDate,
    dateRangeType: parameters?.selectedReports?.assetClassPerformance?.dateRangeType,
  };

  return (
    <Accordion
      onChange={expandAssetClassPerformance}
      expanded={parameters.selectedReports?.assetClassPerformance.expanded || false}
      elevation={0}
    >
      <AccordionSummary expandIcon={<ExpandMore />}>
        <FormControlLabel
          aria-label="Acknowledge"
          sx={{ flex: 1 }}
          onClick={(event) => event.stopPropagation()}
          onFocus={(event) => event.stopPropagation()}
          control={
            <WO2Checkbox
              color="primary"
              checked={parameters.selectedReports?.assetClassPerformance.selected}
              onChangeHandler={handleClickAssetClassPerformanceSelect}
            />
          }
          label="Asset Class Performance"
        />
        <div onClick={(e) => e.stopPropagation()}>
          <DateRangePicker dateRange={dateRange} setDateRange={handleClickDateForAssetClassPerformance} />
        </div>
      </AccordionSummary>

      <AccordionDetails>
        <Grid container direction="column">
          <Grid item container>
            <Grid item container xs={4} alignItems="center">
              <WO2ToggleSwitch
                onChange={(e) =>
                  setAssetClassPerformanceHidingOptionParameter({
                    value: e.target.checked,
                    option: HideByAssetClassPerformance.FrankingCredits,
                  })
                }
                checked={parameters.selectedReports.assetClassPerformance.excludeFrankingCredits}
                color="primary"
              />
              <Typography variant="h4">Exclude Franking Credits</Typography>
            </Grid>
            <Grid item container xs={4} alignItems="center">
              <WO2ToggleSwitch
                onChange={(e) =>
                  setAssetClassPerformanceHidingOptionParameter({
                    value: e.target.checked,
                    option: HideByAssetClassPerformance.AnnualisedReturns,
                  })
                }
                checked={parameters.selectedReports.assetClassPerformance.showAnnualisedReturns}
                color="primary"
                disabled={isDatesLessThanFullYear(dateRange)}
              />
              <Typography variant="h4">Show Annualised Returns</Typography>
            </Grid>
            <Grid item container xs={4} alignItems="center">
              <WO2ToggleSwitch
                onChange={(e) =>
                  setAssetClassPerformanceHidingOptionParameter({
                    value: e.target.checked,
                    option: HideByAssetClassPerformance.ExclusionList,
                  })
                }
                checked={parameters.selectedReports.assetClassPerformance.applyExclusionList}
                color="primary"
              />
              <Typography variant="h4">Apply Exclusion List</Typography>
            </Grid>
            <Grid item container xs={4} alignItems="center">
              <WO2ToggleSwitch
                onChange={(e) =>
                  setAssetClassPerformanceHidingOptionParameter({
                    value: e.target.checked,
                    option: HideByAssetClassPerformance.CapitalIncomeReturns,
                  })
                }
                checked={parameters.selectedReports.assetClassPerformance.excludeCapitalIncomeReturns}
                color="primary"
              />
              <Typography variant="h4">Exclude Capital/Income Returns</Typography>
            </Grid>
            <Grid item container xs={4} alignItems="center">
              <WO2ToggleSwitch
                onChange={(e) =>
                  setAssetClassPerformanceHidingOptionParameter({
                    value: e.target.checked,
                    option: HideByAssetClassPerformance.AllocationChange,
                  })
                }
                checked={parameters.selectedReports.assetClassPerformance.includeAllocationChange}
                color="primary"
              />
              <Typography variant="h4">Include Allocation Charge</Typography>
            </Grid>
            <Grid item container xs={4} alignItems="center">
              <WO2ToggleSwitch
                onChange={(e) =>
                  setAssetClassPerformanceHidingOptionParameter({
                    value: e.target.checked,
                    option: HideByAssetClassPerformance.ReturnsSummary,
                  })
                }
                checked={parameters.selectedReports.assetClassPerformance.includeReturnsSummary}
                color="primary"
              />
              <Typography variant="h4">Include Returns Summary</Typography>
            </Grid>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
