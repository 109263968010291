import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'src/app/api';
import { buildEncodedQueryString } from 'src/common/utils/stringFunctions';
import { RootState } from 'src/store';
import history from '../../../../history';
import { selectSaveTemplatePayload } from './selectors';
import { ApprovedProduct, BenchmarkItem, FetchApprovedProductsPayload, FetchTemplateRequest, FetchTemplateResponse, UpdateTemplateResponse } from './types';

export enum TemplateEditActionTypes {
  FetchTemplate = '@@client/templates/edit/GetTemplate',
  UpdateTemplate = '@@client/templates/edit/SaveTemplate',
  FetchApprovedProducts = '@@client/templates/edit/FetchApprovedProducts',
  FetchSecurities = '@@client/templates/edit/FetchSecurities',
  RebalanceTemplate = '@@client/templates/edit/RebalanceTemplate',
  FetchBenchmarks = '@@client/investmentServices/details/Benchmarks/Fetch',
}

export enum TemplateEditApiEndpoints {
  FetchTemplate = '/investmentservices/GetTemplate',
  UpdateTemplate = '/investmentservices/UpdateTemplate',
  FetchApprovedProducts = '/investmentservices/GetApprovedProductListByAfsl',
  FetchSecurities = '/securities/GetSecuritiesBySearchTerm',
  RebalanceTemplate = '/investmentservices/RebalanceTemplate',
  FetchBenchmarks = '/strategies/GetBenchmarksForAfsl',
}

export const fetchTemplate = createAsyncThunk<FetchTemplateResponse, FetchTemplateRequest>(
  TemplateEditActionTypes.FetchTemplate,
  async ({ afslId, investmentServiceTemplateId }: FetchTemplateRequest) => {
    const queryString = buildEncodedQueryString({
      afslId,
      investmentServiceTemplateId,
    });

    const response = await api.get<FetchTemplateResponse>(`${TemplateEditApiEndpoints.FetchTemplate}${queryString}`);
    return response.data;
  }
);

export const updateTemplate = createAsyncThunk<UpdateTemplateResponse, void>(TemplateEditActionTypes.UpdateTemplate, async (_payload, thunkApi) => {
  const saveTemplatePayload = selectSaveTemplatePayload(thunkApi.getState() as RootState);

  return api
    .post<UpdateTemplateResponse>(TemplateEditApiEndpoints.UpdateTemplate, saveTemplatePayload)
    .then((response) => {
      history.push(`${response.data.investmentServiceTemplateId}`);
      return { message: 'Configurations saved', ...response.data };
    })
    .catch(() => {
      return thunkApi.rejectWithValue({ message: 'Could not save configurations at this time.', variant: 'error' });
    });
});

export const fetchApprovedProducts = createAsyncThunk(TemplateEditActionTypes.FetchApprovedProducts, async (payload: FetchApprovedProductsPayload) => {
  const queryString = buildEncodedQueryString({
    afslId: payload.afslId,
  });

  const response = await api.get<ApprovedProduct[]>(`${TemplateEditApiEndpoints.FetchApprovedProducts}${queryString}`);
  return response.data;
});

export const fetchBenchmarks = createAsyncThunk(TemplateEditActionTypes.FetchBenchmarks, async (afslId: number) => {
  const queryString = buildEncodedQueryString({
    afslId,
  });

  const response = await api.get<BenchmarkItem[]>(`${TemplateEditApiEndpoints.FetchBenchmarks}${queryString}`);
  return response.data;
});
