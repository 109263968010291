import { Edit, Delete, Download } from '@mui/icons-material';
import { GridActionsCellItem, GridColumns, GridRowParams } from '@mui/x-data-grid-pro';
import React from 'react';
import { DateTimeFormat, getLocalDateTime } from 'src/common';
import { ReportTemplate } from '../store';
import { Tooltip } from '@mui/material';

interface Props {
  setEditRow: (row: ReportTemplate) => void;
  setDeleteRow: (row: ReportTemplate) => void;
  downloadTemplate: (row: ReportTemplate) => void;
}

export const ReportTemplateColumns = ({ setEditRow, downloadTemplate }: Props): GridColumns => {
  return [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      hideable: false,
    },
    {
      field: 'binderTemplateId',
      headerName: 'Binder Template Id',
      flex: 1,
      hideable: false,
    },
    {
      field: 'createdOn',
      headerName: 'Created on',
      flex: 1,
      hideable: false,
      valueFormatter: (params) => getLocalDateTime(params.value, DateTimeFormat.DateTime),
    },
    {
      field: 'updatedOn',
      headerName: 'Updated on',
      flex: 1,
      hideable: false,
      valueFormatter: (params) => getLocalDateTime(params.value, DateTimeFormat.DateTime),
    },
    {
      field: 'actions',
      type: 'actions',
      sortable: false,
      width: 120,
      getActions: (params: GridRowParams<ReportTemplate>) => [
        <GridActionsCellItem
          key="download"
          icon={
            <Tooltip title={`Download ${params.row.name}`}>
              <Download />
            </Tooltip>
          }
          label="Download"
          color="primary"
          onClick={() => {
            downloadTemplate(params.row);
          }}
        />,
        <GridActionsCellItem
          key="edit"
          icon={
            <Tooltip title={`Edit ${params.row.name}`}>
              <Edit />
            </Tooltip>
          }
          label="Edit"
          color="primary"
          onClick={() => setEditRow(params.row)}
        />,

        <GridActionsCellItem
          key="delete"
          disabled
          icon={
            <Tooltip title={`Delete ${params.row.name}`}>
              <Delete />
            </Tooltip>
          }
          label="Delete"
          color="primary"
          onClick={() => {
            //setDeleteRow(params.row);
          }}
        />,
      ],
    },
  ];
};
