import { ArrowBackOutlined } from '@mui/icons-material';
import { Breadcrumbs, Container, Grid, Link, Paper } from '@mui/material';
import React from 'react';
import { Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import ProtectedRoute from 'src/ProtectedRoute';
import { Props } from '../container';
import { cgtConfigurationRoutes } from '../routes';
import { LeftMenu } from './components';

export const CGTConfiguration = ({ history }: Props): JSX.Element => {
  const { path } = useRouteMatch();
  const { pathname } = useLocation();

  return (
    <>
      <Container style={{ paddingTop: '20px' }}>
        <Grid container direction="row" spacing={3}>
          <Grid item={true} xs={3}>
            <Breadcrumbs aria-label="breadcrumb" style={{ paddingBottom: '20px' }}>
              <Link
                color="primary"
                onClick={() => {
                  history.push(`../overview`);
                }}
                style={{ textDecoration: 'none' }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                  }}
                >
                  <ArrowBackOutlined />
                  <span style={{ paddingLeft: '10px', paddingTop: '1px' }}>Back to dashboard</span>
                </div>
              </Link>
            </Breadcrumbs>
            <Paper elevation={0}>
              <LeftMenu routes={cgtConfigurationRoutes} />
            </Paper>
          </Grid>
          <Grid item={true} xs={9} style={{ paddingBottom: '20px' }}>
            <Grid container style={{ paddingBottom: '10px' }} spacing={3}>
              <Grid item={true} xs={12} container justifyContent="flex-end"></Grid>
              <Grid item={true} xs={12} container justifyContent="flex-end" style={{ paddingTop: '0px' }}></Grid>
            </Grid>
            <Paper elevation={0} style={{ padding: '20px', paddingTop: '0px' }}>
              <Switch>
                <Route exact path={`${path}/`} render={() => <Redirect to={`${pathname}/details`} />} />
                {cgtConfigurationRoutes.map((r) => (
                  <ProtectedRoute key={r.menuIndex} path={`${path}/${r.path}`} component={r.component} />
                ))}
              </Switch>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
