import { createSelector } from '@reduxjs/toolkit';
import { selectHasClientEditAdminOnly } from 'src/store/selectors';
import { RootState } from '../../../../store/reducer';
import { onboardRoutes } from '../components/onboardRoutes';
import { selectClient } from './selectors';

export const selectPrevNextRoutePaths = createSelector(
  selectClient,
  (_: RootState, pathname: string) => ({
    path: pathname,
    isAdmin: selectHasClientEditAdminOnly(_),
  }),
  (client, data) => {
    const allAvailableRoutes = onboardRoutes
      .filter((route) => route.visible(client, data.isAdmin) && !!route.path)
      .sort((a, b) => (a.menuIndex || 0) - (b.menuIndex || 0))
      .map((route) => '/' + (route.url || route.path || ''));

    //need logic here to find the current route - and handle a parameter on the end
    const currentTab = data.path.replace('/client/onboard', '');

    let currentPathIndex = allAvailableRoutes.indexOf(currentTab);
    if (currentPathIndex === -1) {
      currentPathIndex = 0; // default to first route
    }

    if (currentPathIndex === 0 && (currentTab === '/contacts/contact' || currentTab.startsWith('/contacts/contact/'))) {
      return {
        prevRoutePath: '/client/onboard/contacts',
        nextRoutePath: '/client/onboard/contacts',
      };
    }

    return {
      prevRoutePath: currentPathIndex > 0 ? '/client/onboard' + allAvailableRoutes[currentPathIndex - 1] : undefined,
      nextRoutePath:
        currentPathIndex >= 0 && currentPathIndex < allAvailableRoutes.length - 1
          ? '/client/onboard' + allAvailableRoutes[currentPathIndex + 1]
          : undefined,
    };
  }
);
