import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../../app/api';
import { buildEncodedQueryString } from '../../../../common';
import {
  BenchmarkPerformanceThunkParameters,
  DailyBenchmarkPerformance,
  DailyPerformanceChart,
  MonthlyBenchmarkPerformance,
  MonthlyPerformanceChart,
  PerformanceChartThunkParameters,
  SecurityBenchmark,
  SecurityBenchmarksThunkParameters,
  SecurityPeriodicSummary,
  SecurityPeriodicSummaryThunkParameters,
} from './types';

export enum SecurityPerformanceActionTypes {
  FetchMonthlyPerformanceChart = '@@security/performance/FetchMonthlyPerformanceChart',
  FetchDailyPerformanceChart = '@@security/performance/FetchDailyPerformanceChart',
  FetchSecurityPeriodicSummary = '@@security/performance/FetchSecurityPeriodicSummary',
  FetchSecurityBenchmarks = '@@security/performance/FetchSecurityBenchmarks',
  FetchMonthlyBenchmarkPerformance = '@@security/performance/FetchMonthlyBenchmarkPerformance',
  FetchDailyBenchmarkPerformance = '@@security/performance/FetchDailyBenchmarkPerformance',
}

export enum SecurityPerformanceApiEndpoints {
  fetchMonthlyPerformanceChart = '/api/performances/security/GetMonthlyPerformanceChart',
  fetchDailyPerformanceChart = '/performances/security/GetDailyPerformanceChart',
  fetchSecurityPeriodicSummary = '/performances/security/GetSecurityPeriodicSummary',
  fetchSecurityBenchmarks = '/bff/GetSecurityBenchmarks',
  fetchMonthlyBenchmarkPerformance = '/performances/strategy/GetMonthlyBenchmarkPerformance',
  fetchDailyBenchmarkPerformance = '/performances/strategy/GetDailyBenchmarkPerformance',
}

export const fetchMonthlyPerformanceChart = createAsyncThunk(
  SecurityPerformanceActionTypes.FetchMonthlyPerformanceChart,
  async (wrapper: PerformanceChartThunkParameters) => {
    const queryString = buildEncodedQueryString({
      securityId: wrapper.securityId,
      startDate: wrapper.fromDate,
      endDate: wrapper.toDate,
    });
    const response = await api.get<MonthlyPerformanceChart>(`${SecurityPerformanceApiEndpoints.fetchMonthlyPerformanceChart}${queryString}`);
    return response.data;
  }
);

export const fetchDailyPerformanceChart = createAsyncThunk(
  SecurityPerformanceActionTypes.FetchDailyPerformanceChart,
  async (wrapper: PerformanceChartThunkParameters) => {
    const queryString = buildEncodedQueryString({
      securityId: wrapper.securityId,
      startDate: wrapper.fromDate,
      endDate: wrapper.toDate,
    });
    const response = await api.get<DailyPerformanceChart>(`${SecurityPerformanceApiEndpoints.fetchDailyPerformanceChart}${queryString}`);
    return response.data;
  }
);

export const fetchSecurityPeriodicSummary = createAsyncThunk(
  SecurityPerformanceActionTypes.FetchSecurityPeriodicSummary,
  async (wrapper: SecurityPeriodicSummaryThunkParameters) => {
    const queryString = buildEncodedQueryString({
      securityId: wrapper.securityId,
      strategyId: wrapper.strategyId,
      endDate: wrapper.endDate,
    });
    const response = await api.get<SecurityPeriodicSummary>(`${SecurityPerformanceApiEndpoints.fetchSecurityPeriodicSummary}${queryString}`);
    return response.data;
  }
);

export const fetchSecurityBenchmarks = createAsyncThunk(
  SecurityPerformanceActionTypes.FetchSecurityBenchmarks,
  async (wrapper: SecurityBenchmarksThunkParameters) => {
    const queryString = buildEncodedQueryString({
      securityId: wrapper.securityId,
    });
    const response = await api.get<SecurityBenchmark[]>(`${SecurityPerformanceApiEndpoints.fetchSecurityBenchmarks}${queryString}`);
    return response.data;
  }
);

export const fetchMonthlyBenchmarkPerformance = createAsyncThunk(
  SecurityPerformanceActionTypes.FetchMonthlyBenchmarkPerformance,
  async (wrapper: BenchmarkPerformanceThunkParameters) => {
    const queryString = buildEncodedQueryString({
      strategyId: wrapper.strategyId,
      startDate: wrapper.startDate,
      endDate: wrapper.endDate,
    });
    const response = await api.get<MonthlyBenchmarkPerformance>(`${SecurityPerformanceApiEndpoints.fetchMonthlyBenchmarkPerformance}${queryString}`);
    return response.data;
  }
);

export const fetchDailyBenchmarkPerformance = createAsyncThunk(
  SecurityPerformanceActionTypes.FetchDailyBenchmarkPerformance,
  async (wrapper: BenchmarkPerformanceThunkParameters) => {
    const queryString = buildEncodedQueryString({
      strategyId: wrapper.strategyId,
      startDate: wrapper.startDate,
      endDate: wrapper.endDate,
    });
    const response = await api.get<DailyBenchmarkPerformance>(`${SecurityPerformanceApiEndpoints.fetchDailyBenchmarkPerformance}${queryString}`);
    return response.data;
  }
);
