import { Alert, Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ProgressButton } from 'src/common';
import {
  ClientReportsParameters,
  InitiateReportRequest,
  MdaReportType,
  ReportGeneration,
  ReportingParameter,
  ReportToSort,
  SavedParameter,
  SelectedReport,
} from '../store/types';
import ReportingParameterSelect from './ReportingParameterSelect';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { LoadingProgress } from 'src/common/store/types';
import { SaveReportingParameters } from './SaveReportingParameters';
import ItemOrdering from 'src/common/components/ItemOrdering';
import { MdaReportTypes } from 'src/common/types';
import { initiateReportGeneration, saveReportParameters } from '../store/thunks';
import { Cached } from '@mui/icons-material';
import { useDispatch } from 'react-redux';

export interface ReportToolbarProps {
  savedParametersList: SavedParameter[];
  reportGeneration: ReportGeneration;
  generateProgress: LoadingProgress;
  parameters: ClientReportsParameters;
  setSelectedReportingParameter: ActionCreatorWithPayload<SavedParameter | null, string>;
  setReportingParameter: ActionCreatorWithPayload<ReportingParameter, string>;
  generateProgressReportParameters: LoadingProgress;
  setShowItemOderingModalParameter: ActionCreatorWithPayload<boolean, string>;
  setSelectedReportOrderParameter: ActionCreatorWithPayload<ReportToSort[], string>;
  handleClickMergeReports: () => void;
  setReportGeneration: ActionCreatorWithPayload<ReportGeneration, string>;
  isDataFromTriumph: boolean;
  clientId?: string;
}

export const ReportingToolbar = ({
  reportGeneration,
  generateProgress,
  savedParametersList,
  parameters,
  setSelectedReportingParameter,
  generateProgressReportParameters,
  setReportingParameter,
  setReportGeneration,
  setShowItemOderingModalParameter,
  setSelectedReportOrderParameter,
  isDataFromTriumph,
  handleClickMergeReports,
  clientId,
}: ReportToolbarProps): JSX.Element => {
  const dispatch = useDispatch();

  const [isSaveParametersOpen, setIsSaveParametersOpen] = useState(false);
  const [isDuplicateParameterName, setIsDuplicateParameterName] = useState(false);
  const [reportPayload, setReportPayload] = useState<InitiateReportRequest | null>(null);

  const { reportingParameter } = parameters;

  const mergeReportId = MdaReportTypes.MergeReport.id;

  const getDateRangeType = (dateRangeType: string | null | undefined, isDateOnly: boolean) => {
    const defaultText = `Pre Defined ${isDateOnly ? 'Date' : 'Dates'}`;
    if (dateRangeType === null || dateRangeType === undefined || dateRangeType === '') return defaultText;
    return dateRangeType;
  };

  const checkDuplicateParameterName = (parameterName: string) => {
    const trimmedParameter = parameterName.toLocaleLowerCase().trim();
    return savedParametersList.filter((item) => item.name?.toLowerCase().trim() === trimmedParameter).length > 0;
  };

  const handleSaveReportingParameters = (parameterName: string, visibleToAfsl: boolean) => {
    const isDuplicate = checkDuplicateParameterName(parameterName);
    if (!!isDuplicate) {
      setIsDuplicateParameterName(true);
      return;
    }
    setIsDuplicateParameterName(false);
    setIsSaveParametersOpen(false);
    setReportingParameter({
      isSaveReportParameters: true,
      parameterName: parameterName,
      visibleToAfsl: visibleToAfsl,
    });
  };

  const generateReportParameters = () => {
    if (!clientId) return;

    setShowItemOderingModalParameter(false);

    let reportName = '';

    function getReportRequest<T>(
      type: SelectedReport<T>,
      name: string
    ): Omit<SelectedReport<T>, 'expanded' | 'selected'> | undefined {
      if (!type.selected) {
        return undefined;
      }
      reportName = name;
      const { ...rest } = type;
      delete rest.expanded;
      delete rest.selected;
      return rest;
    }

    const payload: InitiateReportRequest = {
      isDataFromTriumph: isDataFromTriumph,
      clientId: Number(clientId),
      fromDate: parameters.dates.dateFrom,
      toDate: parameters.dates.dateTo,
      dateRangeType: getDateRangeType(parameters.dates.dateRangeType, false),
      includeLogo: parameters.includeLogo,
      mergeReportOrder: [],
      additionsAndWithdrawals: getReportRequest(
        parameters.selectedReports.additionsAndWithdrawals,
        MdaReportType.AdditionsAndWithdrawals
      ),
      assetClassPerformance: getReportRequest(
        parameters.selectedReports.assetClassPerformance,
        MdaReportType.AssetClassPerformance
      ),
      holdingsVsTargetVariance: getReportRequest(
        parameters.selectedReports.holdingsVsTargetVariance,
        MdaReportType.HoldingsVsTargetVariance
      ),
      cashTransactions: getReportRequest(parameters.selectedReports.cashTransactions, MdaReportType.CashTransactions),
      unrealisedCapitalGainsAndLosses: getReportRequest(
        parameters.selectedReports.unrealisedCapitalGainsAndLosses,
        MdaReportType.UnrealisedCapitalGainsAndLosses
      ),
      realisedCapitalGainsAndLosses: getReportRequest(
        parameters.selectedReports.realisedCapitalGainsAndLosses,
        MdaReportType.RealisedCapitalGainsAndLosses
      ),
      securityPerformance: getReportRequest(
        parameters.selectedReports.securityPerformance,
        MdaReportType.SecurityPerformance
      ),
      expenses: getReportRequest(parameters.selectedReports.expenses, MdaReportType.Expenses),
      incomeReport: getReportRequest(parameters.selectedReports.incomeReport, MdaReportType.IncomeReport),
      portfolioSnapshot: getReportRequest(
        parameters.selectedReports.portfolioSnapshot,
        MdaReportType.PortfolioSnapshot
      ),
      portfolioValuation: getReportRequest(
        parameters.selectedReports.portfolioValuation,
        MdaReportType.PortfolioValuation
      ),
      portfolioPerformance: getReportRequest(
        parameters.selectedReports.portfolioPerformance,
        MdaReportType.PortfolioPerformance
      ),
      securityContribution: getReportRequest(
        parameters.selectedReports.securityContribution,
        MdaReportType.SecurityContribution
      ),
      assetAllocation: getReportRequest(parameters.selectedReports.assetAllocation, MdaReportType.AssetAllocation),
      taxSummary: getReportRequest(parameters.selectedReports.taxSummary, MdaReportType.TaxSummary),
      taxInvoice: getReportRequest(parameters.selectedReports.taxInvoice, MdaReportType.TaxInvoice),
      unrealisedNonCGT: getReportRequest(parameters.selectedReports.unrealisedNonCGT, MdaReportType.UnrealisedNonCGT),
      transactionHistory: getReportRequest(
        parameters.selectedReports.transactionHistory,
        MdaReportType.TransactionHistory
      ),
      includeCoverLetter: parameters.includeCoverLetter,
      includeAppendix: parameters.includeAppendix,
      coverLetter: parameters.coverLetter,
      appendix: parameters.appendix,
    };

    if (parameters.mergeReports && parameters.reportsToSort.length > 1) {
      payload.mergeReportOrder = [mergeReportId].concat(parameters.reportsToSort.map((report) => report.id));
    } else if (!parameters.mergeReports && parameters.reportsToSort.length == 1) {
      payload.mergeReportOrder = parameters.reportsToSort.map((report) => report.id);
    } else if (parameters.mergeReports && parameters.reportsToSort.length == 1) {
      payload.mergeReportOrder = parameters.reportsToSort.map((report) => report.id);
      handleClickMergeReports();
    }

    setReportGeneration({
      ...reportGeneration,
      isDataFromTriumph: payload.isDataFromTriumph,
      isMergeReport: payload.mergeReportOrder.findIndex((x) => x == MdaReportTypes.MergeReport.id) > -1,
      clientId: payload.clientId,
      clientName: parameters.clientName,
      mergeReportOrder: payload.mergeReportOrder,
      dateRange: payload.fromDate,
      reportName: reportName,
    });
    setReportPayload(payload);
  };

  const handleClickGenerate = () => {
    if (parameters.mergeReports && parameters.reportsToSort.length > 1) {
      setShowItemOderingModalParameter(true);
    } else {
      setShowItemOderingModalParameter(false);
      generateReportParameters();
    }
  };

  let saveReportParametersButtonDisabled = true;
  let generateButtonDisabled = true;

  const anyReportSelected = Object.values(parameters.selectedReports).some((report) => report.selected);
  if (anyReportSelected) {
    saveReportParametersButtonDisabled = false;
    generateButtonDisabled = false;
  }

  if (parameters.selectedReports.assetAllocation.selected) {
    saveReportParametersButtonDisabled = false;
  }

  useEffect(() => {
    if (reportingParameter?.isSaveReportParameters) {
      handleClickGenerate();
    }
  }, [reportingParameter]);

  useEffect(() => {
    if (!reportPayload || !reportingParameter || reportPayload.clientId === 0) return;

    setShowItemOderingModalParameter(false);

    if (reportingParameter.isSaveReportParameters) {
      dispatch(
        saveReportParameters({
          ...reportPayload,
          visibleToAfsl: reportingParameter.visibleToAfsl,
          parameterName: reportingParameter.parameterName,
        })
      );
    } else {
      dispatch(initiateReportGeneration(reportPayload));
    }

    setReportingParameter({
      ...reportingParameter,
      isSaveReportParameters: false,
    });
  }, [reportPayload]);

  return (
    <>
      <ItemOrdering
        itemsToSort={parameters.reportsToSort}
        setSelectedReport={setSelectedReportOrderParameter}
        setShowItemOrderingModal={setShowItemOderingModalParameter}
        generateReport={generateReportParameters}
        isOpen={parameters.showItemOrdering}
      />
      <Box display="flex" justifyContent={'space-between'}>
        <Box paddingRight={'10px'}>
          <Typography variant="h2" style={{ paddingLeft: '10px' }}>
            Reports
          </Typography>
        </Box>
        <Box display="flex" justifyContent={'space-between'}>
          {reportGeneration.isGenerating && <Alert severity="success">Report in progress</Alert>}
        </Box>
        <Box display="flex" justifyContent={'space-between'}>
          <Box paddingRight={'10px'} paddingTop={'15px'}>
            <Typography>Load From </Typography>
          </Box>
          <Box paddingRight={'10px'}>
            <ReportingParameterSelect
              items={savedParametersList}
              selectedParameter={parameters?.selectedParameter ?? null}
              setParameter={setSelectedReportingParameter}
            />
          </Box>
          <Box paddingRight={'10px'}>
            <ProgressButton
              color={'primary'}
              style={{ borderRadius: 24, height: 48 }}
              disableElevation
              disabled={saveReportParametersButtonDisabled}
              progress={generateProgressReportParameters}
              onClick={() => setIsSaveParametersOpen(true)}
              variant={'contained'}
              value="SaveReportingParameters"
            >
              Save Reporting Parameters
            </ProgressButton>
          </Box>
          <Box paddingRight={'10px'}>
            <ProgressButton
              progress={generateProgress}
              startIcon={Cached}
              disabled={generateButtonDisabled}
              style={{ borderRadius: 24, height: 48, width: 132 }}
              color={'primary'}
              onClick={() => {
                setReportingParameter({
                  ...reportingParameter,
                  isSaveReportParameters: false,
                });
                handleClickGenerate();
              }}
              variant="contained"
              id="search_button"
            >
              Generate
            </ProgressButton>
          </Box>
        </Box>
      </Box>
      <SaveReportingParameters
        isOpen={isSaveParametersOpen}
        handleSave={handleSaveReportingParameters}
        handleClose={() => {
          setIsSaveParametersOpen(false);
        }}
        isDuplicate={isDuplicateParameterName}
      />
    </>
  );
};
