import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../../app/api';
import { AfslViewModel, Client, EmailTemplate, Parameter } from './types';

export enum CommonActionTypes {
  FetchAfsls = '@@bulk/common/FetchAfsls',
  FetchParameters = '@@bulk/common/FetchParameters',
  FetchEmailTemplates = '@@bulk/common/FetchEmailTemplates',
  FetchClients = '@@bulk/common/FetchClients',
  FetchSecurities = '@@bulk/common/FetchSecurities',
}

export enum CommonApi {
  FetchAfsls = '/bff/GetAfslsPracticesAdvisersForUser',
  FetchParameters = '/parameters',
  FetchEmailTemplates = '/emailTemplates',
  FetchClients = '/clients',
  FetchSecurities = '/securities/GetAllSimpleSecurityList',
}

export const fetchAfsls = createAsyncThunk(CommonActionTypes.FetchAfsls, async () => {
  const response = await api.get<AfslViewModel>(`${CommonApi.FetchAfsls}`);
  return response.data;
});

export const fetchParameters = createAsyncThunk(CommonActionTypes.FetchParameters, async () => {
  const response = await api.get<Parameter[]>(`${CommonApi.FetchParameters}`);
  return response.data;
});

export const fetchSecurities = createAsyncThunk(CommonActionTypes.FetchSecurities, async () => {
  const response = await api.get(`${CommonApi.FetchSecurities}`);
  return response.data;
});

export const fetchEmailTemplates = createAsyncThunk(CommonActionTypes.FetchEmailTemplates, async () => {
  const response = await api.get<EmailTemplate[]>(`${CommonApi.FetchEmailTemplates}`);
  return response.data;
});

export const fetchClients = createAsyncThunk(CommonActionTypes.FetchClients, async () => {
  const response = await api.get<Client[]>(`${CommonApi.FetchClients}`);
  return response.data;
});
