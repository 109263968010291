import { Card, CardContent, Grid, List, ListItem, Typography } from '@mui/material';
import React from 'react';
import { WO2Modal } from '../../Modal';
import { Errors } from '../types';

export interface Props {
  onClose: () => void;
  errors: Errors[];
}

export function MissingInformation({ onClose, errors }: Props): JSX.Element {
  const tableRows = errors.map((row: Errors, index: number) => {
    return (
      <ListItem key={index} divider>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant={'h5'}>{row.message}</Typography>
          </Grid>
        </Grid>
      </ListItem>
    );
  });

  return (
    <WO2Modal
      MuiDialogProps={{
        open: true,
        onClose: () => {
          onClose();
        },
        maxWidth: 'sm',
      }}
      title={<b>Missing information</b>}
      actions={[
        {
          label: 'Close',
          onClickHandler: () => {
            onClose();
          },
          type: 'contained',
          disabled: false,
        },
      ]}
    >
      <>
        <p>The below information is mandatory to set up the account. Once provided the account application can be submitted.</p>
        <Card elevation={0}>
          <CardContent>
            <Grid>Description</Grid>
            <List>{tableRows}</List>
          </CardContent>
        </Card>
      </>
    </WO2Modal>
  );
}
