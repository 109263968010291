import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { selectAccounts } from 'src/features/clients/client/details/accounts/store/selectors';
import {
  selectActiveInvestmentVersionId,
  selectServiceTypeId,
} from 'src/features/clients/client/portfolio/store/common/selectors';
import { selectCommon } from 'src/features/clients/client/portfolio/store/selectors';
import { importTradesAction } from 'src/features/orders/rebalance/edit/store/sagas/importSaga';
import {
  selectExportTrades,
  selectFilter,
  selectGroupedByKey,
  selectGroupedFilteredTrades,
  selectIgnoreMinimumTrade,
  selectImportErrors,
  selectIsPreApproved,
  selectRebalance,
  selectRebalanceKey,
  selectRebalanceMode,
  selectRebalanceValidationRequest,
  selectReceivingAccount,
  selectSaleMethod,
  selectSecurity,
  selectSecuritySearchResults,
  selectSummary,
  selectTargetCash,
  selectValidationMessages,
  selectWithdrawalDetailsData,
  selectWithdrawalMethod,
  selectWithdrawCash,
  selectWithdrawValue,
} from 'src/features/orders/rebalance/edit/store/selectors';
import { rebalanceEditSlice } from 'src/features/orders/rebalance/edit/store/slice';
import {
  selectHasClientEditAdminOnly,
  selectHasRebalanceEnhancedTrading,
  selectIsRebalanceDataSourceTriumph,
} from 'src/store/selectors';
import { progressSelectorFactory } from '../../../../common';
import { isLoadingSelectorFactory } from '../../../../common/store/selectors/isLoadingSelectorFactory';
import { RootState } from '../../../../reducers';
import { selectAfslId, selectClientId, selectEntityDetails, selectPortfolios } from '../common/store/selectors';
import { RebalanceComponent } from './components/rebalanceComponent';
import {
  selectBenchmarkPerformanceResults,
  selectBenchmarks,
  selectCurrentTab,
  selectMonthlyPerformanceTableResults,
  selectParameters,
  selectPerformanceChartResults,
  selectPeriodicSummaryTableResults,
  selectPortfolioSecurityPerformanceResults,
} from './store/selectors';

import { fetchAccounts } from 'src/features/clients/client/details/accounts/store/thunks';
import { deleteAbandonedRebalances, RebalanceActionTypes, rebalanceTemplate } from 'src/features/clients/client/portfolio/store/rebalance/thunks';
import {
  deleteRebalance,
  fetchRebalance,
  fetchSecurities,
  fetchSecurity,
  fetchWithdrawalDetailsData,
  validateRebalance,
} from 'src/features/orders/rebalance/edit/store/thunks';
import { rebalanceSlice, selectValidationWarnings } from '../portfolio/store/rebalance';
import {
  fetchBenchmarkPerformance,
  fetchBenchmarks,
  fetchMonthlyPortfolioPerformance,
  fetchPerformanceChart,
  fetchPeriodicSummary,
  fetchPortfolioSecurityPerformance,
  PerformanceActionTypes,
} from './store/thunks';

const mapStateToProps = (state: RootState) => ({
  rebalanceMode: selectRebalanceMode(state),
  rebalance: selectRebalance(state),
  selectedExportTrades: selectExportTrades(state),
  groupedByKey: selectGroupedByKey(state),
  filter: selectFilter(state),
  rebalanceValidationRequest: selectRebalanceValidationRequest(state),
  rebalanceKey: selectRebalanceKey(state),
  isImporting: isLoadingSelectorFactory([importTradesAction.actionName])(state),
  importErrors: selectImportErrors(state),
  selectedSecurity: selectSecurity(state),
  securitySearchResults: selectSecuritySearchResults(state),
  validationMessages: selectValidationMessages(state),
  filteredTrades: selectGroupedFilteredTrades(state),
  hasRebalanceEnhancedTrading: selectHasRebalanceEnhancedTrading(state),
  hasClientEditAdminOnlyPermission: selectHasClientEditAdminOnly(state),
  summary: selectSummary(state),
  isPreApproved: selectIsPreApproved(state),
  common: selectCommon(state),
  serviceTypeId: selectServiceTypeId(state),
  ignoreMinimumTrade: selectIgnoreMinimumTrade(state),
  activeInvestmentServiceVersionId: selectActiveInvestmentVersionId(state),
  saleMethod: selectSaleMethod(state),
  targetCash: selectTargetCash(state),
  withdrawalMethod: selectWithdrawalMethod(state),
  withdrawCash: selectWithdrawCash(state),
  receivingAccount: selectReceivingAccount(state),
  withdrawValue: selectWithdrawValue(state),
  withdrawalDetailsData: selectWithdrawalDetailsData(state),
  accounts: selectAccounts(state),
  clientId: selectClientId(state),
  entityDetails: selectEntityDetails(state),
  afslId: selectAfslId(state),
  clientPortfolios: selectPortfolios(state),
  parameters: selectParameters(state),
  currentTab: selectCurrentTab(state),
  benchmarks: selectBenchmarks(state),
  isDataFromTriumph: selectIsRebalanceDataSourceTriumph(state),
  monthlyPerformanceTableResults: selectMonthlyPerformanceTableResults(state),
  performanceChartResults: selectPerformanceChartResults(state),
  benchmarkPerformanceResults: selectBenchmarkPerformanceResults(state),
  periodicSummaryTableResults: selectPeriodicSummaryTableResults(state),
  portfolioSecurityPerformanceResults: selectPortfolioSecurityPerformanceResults(state),
  rebalanceTemplateProgress: progressSelectorFactory([RebalanceActionTypes.RebalanceTemplate])(state),
  performanceChartProgress: progressSelectorFactory([PerformanceActionTypes.FetchPerformanceChart])(state),
  deleteAbandonedRebalances: progressSelectorFactory([RebalanceActionTypes.DeleteAbandonedRebalances])(state),
  monthlyPerformanceTableProgress: progressSelectorFactory([PerformanceActionTypes.FetchMonthlyPerformanceTable])(
    state
  ),
  periodicSummaryTableProgress: progressSelectorFactory([PerformanceActionTypes.FetchPeriodicSummaryTable])(state),
  portfolioSecurityPerformanceProgress: progressSelectorFactory([
    PerformanceActionTypes.FetchPortfolioSecurityPerformanceTable,
  ])(state),
  rebalanceValidationWarnings: selectValidationWarnings(state),
});

const importTrades = importTradesAction.pending;
const mapDispatchToProps = {
  ...rebalanceEditSlice.actions,
  ...rebalanceSlice.actions,
  fetchBenchmarks,
  fetchBenchmarkPerformance,
  fetchMonthlyPortfolioPerformance,
  fetchPeriodicSummary,
  fetchPortfolioSecurityPerformance,
  fetchPerformanceChart,
  fetchWithdrawalDetailsData,
  fetchAccounts,
  fetchRebalance,
  fetchSecurities,
  fetchSecurity,
  rebalanceTemplate,
  deleteAbandonedRebalances,
  importTrades,
  deleteRebalance,
  validateRebalance,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector> & RouteComponentProps;
export const RebalanceContainer = connect(mapStateToProps, mapDispatchToProps)(RebalanceComponent);
