import FileSaver from 'file-saver';
import { unparse } from 'papaparse';
import { DateTimeFormat, getLocalDateTimeNow } from './dateFunctions';

export type CSVDataType = Record<string, string | Date | number | null | unknown>;

export function downloadCsv(data: CSVDataType[], filenamePrefix: string): void {
  const csv = unparse(data);
  const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  FileSaver.saveAs(csvData, `${filenamePrefix} ${getLocalDateTimeNow(DateTimeFormat.Filename)}.csv`);
}

export function convertToCSVFormat<T>(data: T[]): CSVDataType[] {
  const csvData: CSVDataType[] = [];

  data.forEach((item: T) => {
    const converted: CSVDataType = {};

    for (const [key, value] of Object.entries(item)) {
      converted[key] = value;
    }

    csvData.push(converted);
  });

  return csvData;
}

export function downloadFile(fileData: Blob, fileName: string, fileType: string): void {
  FileSaver.saveAs(fileData, `${fileName}_${getLocalDateTimeNow(DateTimeFormat.Filename)}.${fileType}`);
}

export const extractFilenameFromContentDisposition = (contentDisposition: string): string | undefined => {
  if (!!contentDisposition) {
    try {
      let startIndex = contentDisposition.indexOf('filename="');
      let endIndex = contentDisposition.indexOf('"; filename*');
      if (startIndex > 0 && endIndex > 0) {
        return contentDisposition.substring(startIndex + 'filename="'.length, endIndex);
      }

      startIndex = contentDisposition.indexOf('filename=');
      endIndex = contentDisposition.indexOf('; filename*');
      if (startIndex > 0 && endIndex > 0) {
        return contentDisposition.substring(startIndex + 'filename='.length, endIndex);
      }
    } catch {
      return undefined;
    }
  }
  return undefined;
};

export function convertBase64StringToFile(base64String: string, fileName: string, fileType: string): File {
  const binStr = atob(base64String);
  const len = binStr.length;
  const arr = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    arr[i] = binStr.charCodeAt(i);
  }

  return new File([arr], fileName, { type: fileType });
}
