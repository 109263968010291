import { createAsyncThunk } from '@reduxjs/toolkit';
import { submitBulkOrderService } from './services';
import { InitiateBulkOrderPadRequest, ValidationResult } from './types';

export enum BulkOrderCheckoutActionTypes {
  ConfirmBulkOrder = '@@bulk/order/checkout/confirmBulkOrder',
}

export const confirmBulkOrder = createAsyncThunk<string, InitiateBulkOrderPadRequest, { rejectValue: ValidationResult }>(
  BulkOrderCheckoutActionTypes.ConfirmBulkOrder,
  async (payload: InitiateBulkOrderPadRequest, thunkApi) => {
    try {
      return await submitBulkOrderService(payload);
    } catch (e) {
      const result: ValidationResult = { errors: [`${e.status} - ${e.statusText}`], warnings: [], information: [] };
      return thunkApi.rejectWithValue(result);
    }
  }
);
