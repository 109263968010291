import { Container, Typography } from '@mui/material';
import React from 'react';
import { Props } from '../container';
import { Parameters } from './parameters';
import { Results } from './results';

export const Report = (props: Props): JSX.Element => {
  const styles: { [key: string]: React.CSSProperties } = {
    contentContainer: {
      display: 'flex',
      flexDirection: 'row',
      padding: 0,
      marginBottom: 35,
      marginTop: 20,
    },
  };

  return (
    <Container style={{ paddingTop: 30, paddingBottom: 30 }}>
      <Typography variant="h2">New Report</Typography>
      <Container maxWidth="xl" style={styles.contentContainer}>
        <Parameters {...props} />
        <Results {...props} />
      </Container>
    </Container>
  );
};
