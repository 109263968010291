import * as React from 'react';
import { useState } from 'react';
import { DialogActionButton, WO2Modal } from 'src/common';

export interface ConfirmationOptions {
  catchOnCancel?: boolean;
  title: string;
  description: string;
  description2?: string;
}

interface ConfirmationDialogProps extends ConfirmationOptions {
  open: boolean;
  onConfirm?: () => void;
  onCancel?: () => void;
}

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({ open, title, description, description2, onConfirm, onCancel }) => {
  const [actions, setActions] = useState<DialogActionButton[]>([]);

  React.useEffect(() => {
    const actionsArray: DialogActionButton[] = [];

    !!onCancel &&
      actionsArray.push({
        label: 'Cancel',
        onClickHandler: () => {
          !!onCancel && onCancel();
        },
        type: 'outlined',
      });

    !!onConfirm &&
      actionsArray.push({
        label: 'Confirm',
        onClickHandler: () => {
          !!onConfirm && onConfirm();
        },
        type: 'contained',
      });

    setActions(actionsArray);
  }, [onConfirm, onCancel]);

  return (
    <WO2Modal
      MuiDialogProps={{
        open,
        onClose: () => {
          !!onCancel && onCancel();
        },
      }}
      title={<b>{title}</b>}
      actions={actions}
    >
      <>
        <p>{description}</p>
        {!!description2 && <p>{description2}</p>}
      </>
    </WO2Modal>
  );
};
