import {
  CodeOutlined,
  DescriptionOutlined,
  FolderOutlined,
  GridOnOutlined,
  HeadsetOutlined,
  HttpOutlined,
  InsertDriveFileOutlined,
  InsertPhotoOutlined,
  PictureAsPdfOutlined,
  ShowChartOutlined,
  SubjectOutlined,
  SvgIconComponent,
  TheatersOutlined,
} from '@mui/icons-material';
import { Enumeration } from '../../../../../common/types';

export enum ContactRole {
  Adviser = 1,
  AuthorisedSignatory = 9,
}

export class AttachmentExtension extends Enumeration {
  static Other = new AttachmentExtension(1, 'Other', 'other', DescriptionOutlined);
  static Word = new AttachmentExtension(2, 'Word', 'word', InsertDriveFileOutlined);
  static Excel = new AttachmentExtension(3, 'Excel', 'excel', GridOnOutlined);
  static Pdf = new AttachmentExtension(5, 'Pdf', 'pdf', PictureAsPdfOutlined);
  static Text = new AttachmentExtension(6, 'Text', 'text', SubjectOutlined);
  static Image = new AttachmentExtension(7, 'Image', 'image', InsertPhotoOutlined);
  static Movie = new AttachmentExtension(8, 'Movie', 'movie', TheatersOutlined);
  static Audio = new AttachmentExtension(9, 'Audio', 'audio', HeadsetOutlined);
  static Html = new AttachmentExtension(10, 'Html', 'html', HttpOutlined);
  static CSV = new AttachmentExtension(11, 'CSV', 'CSV', GridOnOutlined);
  static Zip = new AttachmentExtension(12, 'Zip', 'zip', FolderOutlined);
  static XML = new AttachmentExtension(13, 'XML', 'XML', CodeOutlined);
  static PowerPoint = new AttachmentExtension(14, 'powerPoint', 'PowerPoint', ShowChartOutlined);

  icon: SvgIconComponent;

  constructor(id: number, name: string, displayName: string, icon: SvgIconComponent) {
    super(id, name, displayName);
    this.icon = icon;
  }

  static getById = (status: number): AttachmentExtension | undefined => {
    const extension = Object.values(AttachmentExtension)
      .filter((t) => t instanceof AttachmentExtension)
      .find((t: AttachmentExtension) => t.id === status);

    return extension || AttachmentExtension.Other;
  };
}

export enum FileType {
  Word = 'Word',
  PDF = 'PDF',
  Email = 'Email',
  Other = 'Other',
}

export class AddressType extends Enumeration {
  static Physical = new AddressType(1, 'Physical / Home / Business', 'Physical');
  static Mailing = new AddressType(2, 'Mailing', 'Mailing');
  static Asic = new AddressType(3, 'Registered (ASIC)', 'ASIC');
  static Chess = new AddressType(8, 'Registered (CHESS)', 'Chess');

  static getArray = (): AddressType[] => [
    AddressType.Mailing,
    AddressType.Physical,
    AddressType.Asic,
    AddressType.Chess,
  ];

  static getById = (id: number): AddressType | undefined => {
    return Object.values(AddressType)
      .filter((t) => t instanceof AddressType)
      .find((t: AddressType) => t.id === id);
  };

  static getByName = (name: string): AddressType | undefined => {
    return Object.values(AddressType)
      .filter((t) => t instanceof AddressType)
      .find((t: AddressType) => t.name.toLowerCase() === name.toLowerCase());
  };
}

export class PhoneNumberType extends Enumeration {
  static Business = new PhoneNumberType(1, 'Business', 'Business');
  static Home = new PhoneNumberType(2, 'Home', 'Home');
  static Mobile = new PhoneNumberType(3, 'Mobile', 'Mobile');
  static Fax = new PhoneNumberType(4, 'Fax', 'Fax');

  static getById = (status: number): PhoneNumberType | undefined => {
    const extension = Object.values(PhoneNumberType)
      .filter((t) => t instanceof PhoneNumberType)
      .find((t: PhoneNumberType) => t.id === status);

    return extension;
  };

  static getByName = (name: string): PhoneNumberType | undefined => {
    return Object.values(PhoneNumberType)
      .filter((t) => t instanceof PhoneNumberType)
      .find((t: PhoneNumberType) => t.name.toLowerCase() === name.toLowerCase());
  };
}

export class EmailType extends Enumeration {
  static Work = new EmailType(5, 'Work', 'Work');
  static Home = new EmailType(6, 'Home', 'Home');
  static UXCorrespondence = new EmailType(7, 'UXCorrespondence', 'UX Correspondence');

  static getById = (status: number | null): EmailType | undefined => {
    const extension = Object.values(EmailType)
      .filter((t) => t instanceof EmailType)
      .find((t: EmailType) => t.id === status);

    return extension;
  };

  static getByName = (name: string): EmailType | undefined => {
    return Object.values(EmailType)
      .filter((t) => t instanceof EmailType)
      .find((t: EmailType) => {
        return t.name.toLowerCase() === name.toLowerCase();
      });
  };
}

export class PaymentFrequency extends Enumeration {
  static Monthly = new PaymentFrequency(1, 'Monthly', 'Monthly');
  static Quarterly = new PaymentFrequency(2, 'Quarterly', 'Quarterly');
  static HalfYearly = new PaymentFrequency(3, 'HalfYearly', 'Half Yearly');
  static Yearly = new PaymentFrequency(4, 'Yearly', 'Yearly');
  static Fortnightly = new PaymentFrequency(5, 'Fortnightly', 'Fortnightly');

  static getArray = (): PaymentFrequency[] => [
    PaymentFrequency.Fortnightly,
    PaymentFrequency.Monthly,
    PaymentFrequency.Quarterly,
    PaymentFrequency.HalfYearly,
    PaymentFrequency.Yearly,
  ];
}

export class PaymentLimitType extends Enumeration {
  static Minimum = new PaymentLimitType(1, 'Minimum', 'Minimum');
  static Maximum = new PaymentLimitType(2, 'Maximum', 'Maximum');
  static Other = new PaymentLimitType(3, 'Other', 'Other');

  static getArray = (): PaymentLimitType[] => [
    PaymentLimitType.Minimum,
    PaymentLimitType.Maximum,
    PaymentLimitType.Other,
  ];
}

export class PaymentType extends Enumeration {
  static ElectronicTransfer = new PaymentType(1, 'ElectronicTransfer', 'Electronic Transfer');
  static DirectDebit = new PaymentType(2, 'DirectDebit', 'Direct Debit');
  static Neither = new PaymentType(-1, 'Neither', 'No regular contributions');

  static getArray = (): PaymentType[] => [PaymentType.Neither, PaymentType.ElectronicTransfer, PaymentType.DirectDebit];
}

export class IdentificationDocType extends Enumeration {
  static AustralianCitizenshipCertificate = new IdentificationDocType(
    1,
    'AustralianCitizenshipCertificate',
    'Australian Citizenship Certificate'
  );
  static AustralianPassport = new IdentificationDocType(2, 'AustralianPassport', 'Australian Passport');
  static AustralianBirthCertificate = new IdentificationDocType(
    3,
    'AustralianBirthCertificate',
    'Australian Birth Certificate'
  );
  static CentrelinkConcessionCard = new IdentificationDocType(
    4,
    'CentrelinkConcessionCard',
    'Centrelink Concession Card'
  );
  static MedicareCard = new IdentificationDocType(5, 'MedicareCard', 'Medicare Card');
  static ForeignPassport = new IdentificationDocType(6, 'ForeignPassport', 'Foreign Passport');
  static AustralianDriverLicence = new IdentificationDocType(7, 'AustralianDriverLicence', 'Australian Driver Licence');
  static IdentityVerificationCertificate = new IdentificationDocType(8, 'IdentityVerificationCertificate', 'Identity Verification Certificate');

  static getById = (status: number | null): IdentificationDocType | undefined => {
    const extension = Object.values(IdentificationDocType)
      .filter((t) => t instanceof IdentificationDocType)
      .find((t: IdentificationDocType) => t.id === status);
    return extension;
  };

  static getByName = (name: string): IdentificationDocType | undefined => {
    return Object.values(IdentificationDocType)
      .filter((t) => t instanceof IdentificationDocType)
      .find((t: IdentificationDocType) => {
        return t.name.toLowerCase() === name.toLowerCase();
      });
  };
}

export class AustralianState extends Enumeration {
  static ACT = new AustralianState(1, 'ACT', 'ACT');
  static NSW = new AustralianState(2, 'NSW', 'NSW');
  static NT = new AustralianState(3, 'NT', 'NT');
  static SA = new AustralianState(4, 'SA', 'SA');
  static QLD = new AustralianState(5, 'QLD', 'QLD');
  static TAS = new AustralianState(6, 'TAS', 'TAS');
  static VIC = new AustralianState(7, 'VIC', 'VIC');
  static WA = new AustralianState(8, 'WA', 'WA');

  static getById = (status: number): AustralianState | undefined => {
    const extension = Object.values(AustralianState)
      .filter((t) => t instanceof AustralianState)
      .find((t: AustralianState) => t.id === status);

    return extension;
  };

  static getByName = (name: string): AustralianState | undefined => {
    return name ? Object.values(AustralianState)
      .filter((t) => t instanceof AustralianState)
      .find((t: AustralianState) => t.name.toLowerCase() === name.toLowerCase()) : undefined;
  };
}

export class CentrelinkCardType extends Enumeration {
  static PCC = new CentrelinkCardType(1, 'PCC', 'Pensioner concession card');
  static HCC = new CentrelinkCardType(2, 'HCC', 'Health care card');
  static SHC = new CentrelinkCardType(3, 'SHC', 'Seniors health card');

  static getById = (status: number): CentrelinkCardType | undefined => {
    const extension = Object.values(CentrelinkCardType)
      .filter((t) => t instanceof CentrelinkCardType)
      .find((t: CentrelinkCardType) => t.id === status);

    return extension;
  };

  static getByName = (name: string): CentrelinkCardType | undefined => {
    return Object.values(CentrelinkCardType)
      .filter((t) => t instanceof CentrelinkCardType)
      .find((t: CentrelinkCardType) => t.name.toLowerCase() === name.toLowerCase());
  };
}

export class MedicareCardColour extends Enumeration {
  static G = new MedicareCardColour(1, 'G', 'Green');
  static B = new MedicareCardColour(2, 'B', 'Blue');
  static Y = new MedicareCardColour(3, 'Y', 'Yellow');

  static getById = (status: number): MedicareCardColour | undefined => {
    const extension = Object.values(MedicareCardColour)
      .filter((t) => t instanceof MedicareCardColour)
      .find((t: MedicareCardColour) => t.id === status);

    return extension;
  };

  static getByName = (name: string): MedicareCardColour | undefined => {
    return Object.values(MedicareCardColour)
      .filter((t) => t instanceof MedicareCardColour)
      .find((t: MedicareCardColour) => t.name.toLowerCase() === name.toLowerCase());
  };
}

export class Countries extends Enumeration {
  static AFG = new Countries(4, 'AFG', 'Afghanistan');
  static ALB = new Countries(8, 'ALB', 'Albania');
  static DZA = new Countries(12, 'DZA', 'Algeria');
  static ASM = new Countries(16, 'ASM', 'American Samoa');
  static AND = new Countries(20, 'AND', 'Andorra');
  static AGO = new Countries(24, 'AGO', 'Angola');
  static AIA = new Countries(660, 'AIA', 'Anguilla');
  static ATA = new Countries(10, 'ATA', 'Antarctica');
  static ATG = new Countries(28, 'ATG', 'Antigua and Barbuda');
  static ARG = new Countries(32, 'ARG', 'Argentina');
  static ARM = new Countries(51, 'ARM', 'Armenia');
  static ABW = new Countries(533, 'ABW', 'Aruba');
  static AUS = new Countries(36, 'AUS', 'Australia');
  static AUT = new Countries(40, 'AUT', 'Austria');
  static AZE = new Countries(31, 'AZE', 'Azerbaijan');
  static BHS = new Countries(44, 'BHS', 'Bahamas (the)');
  static BHR = new Countries(48, 'BHR', 'Bahrain');
  static BGD = new Countries(50, 'BGD', 'Bangladesh');
  static BRB = new Countries(52, 'BRB', 'Barbados');
  static BLR = new Countries(112, 'BLR', 'Belarus');
  static BEL = new Countries(56, 'BEL', 'Belgium');
  static BLZ = new Countries(84, 'BLZ', 'Belize');
  static BEN = new Countries(204, 'BEN', 'Benin');
  static BMU = new Countries(60, 'BMU', 'Bermuda');
  static BTN = new Countries(64, 'BTN', 'Bhutan');
  static BOL = new Countries(68, 'BOL', 'Bolivia (Plurinational State of)');
  static BES = new Countries(535, 'BES', 'Bonaire, Sint Eustatius and Saba');
  static BIH = new Countries(70, 'BIH', 'Bosnia and Herzegovina');
  static BWA = new Countries(72, 'BWA', 'Botswana');
  static BVT = new Countries(74, 'BVT', 'Bouvet Island');
  static BRA = new Countries(76, 'BRA', 'Brazil');
  static IOT = new Countries(86, 'IOT', 'British Indian Ocean Territory (the)');
  static BRN = new Countries(96, 'BRN', 'Brunei Darussalam');
  static BGR = new Countries(100, 'BGR', 'Bulgaria');
  static BFA = new Countries(854, 'BFA', 'Burkina Faso');
  static BDI = new Countries(108, 'BDI', 'Burundi');
  static CPV = new Countries(132, 'CPV', 'Cabo Verde');
  static KHM = new Countries(116, 'KHM', 'Cambodia');
  static CMR = new Countries(120, 'CMR', 'Cameroon');
  static CAN = new Countries(124, 'CAN', 'Canada');
  static CYM = new Countries(136, 'CYM', 'Cayman Islands (the)');
  static CAF = new Countries(140, 'CAF', 'Central African Republic (the)');
  static TCD = new Countries(148, 'TCD', 'Chad');
  static CHL = new Countries(152, 'CHL', 'Chile');
  static CHN = new Countries(156, 'CHN', 'China');
  static CXR = new Countries(162, 'CXR', 'Christmas Island');
  static CCK = new Countries(166, 'CCK', 'Cocos (Keeling) Islands (the)');
  static COL = new Countries(170, 'COL', 'Colombia');
  static COM = new Countries(174, 'COM', 'Comoros (the)');
  static COD = new Countries(180, 'COD', 'Congo (the Democratic Republic of the)');
  static COG = new Countries(178, 'COG', 'Congo (the)');
  static COK = new Countries(184, 'COK', 'Cook Islands (the)');
  static CRI = new Countries(188, 'CRI', 'Costa Rica');
  static HRV = new Countries(191, 'HRV', 'Croatia');
  static CUB = new Countries(192, 'CUB', 'Cuba');
  static CUW = new Countries(531, 'CUW', 'Curaçao');
  static CYP = new Countries(196, 'CYP', 'Cyprus');
  static CZE = new Countries(203, 'CZE', 'Czechia');
  static CIV = new Countries(384, 'CIV', "Cote d'Ivoire'o");
  static DNK = new Countries(208, 'DNK', 'Denmark');
  static DJI = new Countries(262, 'DJI', 'Djibouti');
  static DMA = new Countries(212, 'DMA', 'Dominica');
  static DOM = new Countries(214, 'DOM', 'Dominican Republic (the)');
  static ECU = new Countries(218, 'ECU', 'Ecuador');
  static EGY = new Countries(818, 'EGY', 'Egypt');
  static SLV = new Countries(222, 'SLV', 'El Salvador');
  static GNQ = new Countries(226, 'GNQ', 'Equatorial Guinea');
  static ERI = new Countries(232, 'ERI', 'Eritrea');
  static EST = new Countries(233, 'EST', 'Estonia');
  static SWZ = new Countries(748, 'SWZ', 'Eswatini');
  static ETH = new Countries(231, 'ETH', 'Ethiopia');
  static FLK = new Countries(238, 'FLK', 'Falkland Islands (the) [Malvinas]');
  static FRO = new Countries(234, 'FRO', 'Faroe Islands (the)');
  static FJI = new Countries(242, 'FJI', 'Fiji');
  static FIN = new Countries(246, 'FIN', 'Finland');
  static FRA = new Countries(250, 'FRA', 'France');
  static GUF = new Countries(254, 'GUF', 'French Guiana');
  static PYF = new Countries(258, 'PYF', 'French Polynesia');
  static ATF = new Countries(260, 'ATF', 'French Southern Territories (the)');
  static GAB = new Countries(266, 'GAB', 'Gabon');
  static GMB = new Countries(270, 'GMB', 'Gambia (the)');
  static GEO = new Countries(268, 'GEO', 'Georgia');
  static DEU = new Countries(276, 'DEU', 'Germany');
  static GHA = new Countries(288, 'GHA', 'Ghana');
  static GIB = new Countries(292, 'GIB', 'Gibraltar');
  static GRC = new Countries(300, 'GRC', 'Greece');
  static GRL = new Countries(304, 'GRL', 'Greenland');
  static GRD = new Countries(308, 'GRD', 'Grenada');
  static GLP = new Countries(312, 'GLP', 'Guadeloupe');
  static GUM = new Countries(316, 'GUM', 'Guam');
  static GTM = new Countries(320, 'GTM', 'Guatemala');
  static GGY = new Countries(831, 'GGY', 'Guernsey');
  static GIN = new Countries(324, 'GIN', 'Guinea');
  static GNB = new Countries(624, 'GNB', 'Guinea-Bissau');
  static GUY = new Countries(328, 'GUY', 'Guyana');
  static HTI = new Countries(332, 'HTI', 'Haiti');
  static HMD = new Countries(334, 'HMD', 'Heard Island and McDonald Islands');
  static VAT = new Countries(336, 'VAT', 'Holy See (the)');
  static HND = new Countries(340, 'HND', 'Honduras');
  static HKG = new Countries(344, 'HKG', 'Hong Kong');
  static HUN = new Countries(348, 'HUN', 'Hungary');
  static ISL = new Countries(352, 'ISL', 'Iceland');
  static IND = new Countries(356, 'IND', 'India');
  static IDN = new Countries(360, 'IDN', 'Indonesia');
  static IRN = new Countries(364, 'IRN', 'Iran (Islamic Republic of)');
  static IRQ = new Countries(368, 'IRQ', 'Iraq');
  static IRL = new Countries(372, 'IRL', 'Ireland');
  static IMN = new Countries(833, 'IMN', 'Isle of Man');
  static ISR = new Countries(376, 'ISR', 'Israel');
  static ITA = new Countries(380, 'ITA', 'Italy');
  static JAM = new Countries(388, 'JAM', 'Jamaica');
  static JPN = new Countries(392, 'JPN', 'Japan');
  static JEY = new Countries(832, 'JEY', 'Jersey');
  static JOR = new Countries(400, 'JOR', 'Jordan');
  static KAZ = new Countries(398, 'KAZ', 'Kazakhstan');
  static KEN = new Countries(404, 'KEN', 'Kenya');
  static KIR = new Countries(296, 'KIR', 'Kiribati');
  static PRK = new Countries(408, 'PRK', "Korea (the Democratic People's Republic of)");
  static KOR = new Countries(410, 'KOR', 'Korea (the Republic of)');
  static KWT = new Countries(414, 'KWT', 'Kuwait');
  static KGZ = new Countries(417, 'KGZ', 'Kyrgyzstan');
  static LAO = new Countries(418, 'LAO', "Lao People's Democratic Republic (the)");
  static LVA = new Countries(428, 'LVA', 'Latvia');
  static LBN = new Countries(422, 'LBN', 'Lebanon');
  static LSO = new Countries(426, 'LSO', 'Lesotho');
  static LBR = new Countries(430, 'LBR', 'Liberia');
  static LBY = new Countries(434, 'LBY', 'Libya');
  static LIE = new Countries(438, 'LIE', 'Liechtenstein');
  static LTU = new Countries(440, 'LTU', 'Lithuania');
  static LUX = new Countries(442, 'LUX', 'Luxembourg');
  static MAC = new Countries(446, 'MAC', 'Macao');
  static MDG = new Countries(450, 'MDG', 'Madagascar');
  static MWI = new Countries(454, 'MWI', 'Malawi');
  static MYS = new Countries(458, 'MYS', 'Malaysia');
  static MDV = new Countries(462, 'MDV', 'Maldives');
  static MLI = new Countries(466, 'MLI', 'Mali');
  static MLT = new Countries(470, 'MLT', 'Malta');
  static MHL = new Countries(584, 'MHL', 'Marshall Islands (the)');
  static MTQ = new Countries(474, 'MTQ', 'Martinique');
  static MRT = new Countries(478, 'MRT', 'Mauritania');
  static MUS = new Countries(480, 'MUS', 'Mauritius');
  static MYT = new Countries(175, 'MYT', 'Mayotte');
  static MEX = new Countries(484, 'MEX', 'Mexico');
  static FSM = new Countries(583, 'FSM', 'Micronesia (Federated States of)');
  static MDA = new Countries(498, 'MDA', 'Moldova (the Republic of)');
  static MCO = new Countries(492, 'MCO', 'Monaco');
  static MNG = new Countries(496, 'MNG', 'Mongolia');
  static MNE = new Countries(499, 'MNE', 'Montenegro');
  static MSR = new Countries(500, 'MSR', 'Montserrat');
  static MAR = new Countries(504, 'MAR', 'Morocco');
  static MOZ = new Countries(508, 'MOZ', 'Mozambique');
  static MMR = new Countries(104, 'MMR', 'Myanmar');
  static NAM = new Countries(516, 'NAM', 'Namibia');
  static NRU = new Countries(520, 'NRU', 'Nauru');
  static NPL = new Countries(524, 'NPL', 'Nepal');
  static NLD = new Countries(528, 'NLD', 'Netherlands (Kingdom of the)');
  static NCL = new Countries(540, 'NCL', 'New Caledonia');
  static NZL = new Countries(554, 'NZL', 'New Zealand');
  static NIC = new Countries(558, 'NIC', 'Nicaragua');
  static NER = new Countries(562, 'NER', 'Niger (the)');
  static NGA = new Countries(566, 'NGA', 'Nigeria');
  static NIU = new Countries(570, 'NIU', 'Niue');
  static NFK = new Countries(574, 'NFK', 'Norfolk Island');
  static MKD = new Countries(807, 'MKD', 'North Macedonia');
  static MNP = new Countries(580, 'MNP', 'Northern Mariana Islands (the)');
  static NOR = new Countries(578, 'NOR', 'Norway');
  static OMN = new Countries(512, 'OMN', 'Oman');
  static PAK = new Countries(586, 'PAK', 'Pakistan');
  static PLW = new Countries(585, 'PLW', 'Palau');
  static PSE = new Countries(275, 'PSE', 'Palestine, State of');
  static PAN = new Countries(591, 'PAN', 'Panama');
  static PNG = new Countries(598, 'PNG', 'Papua New Guinea');
  static PRY = new Countries(600, 'PRY', 'Paraguay');
  static PER = new Countries(604, 'PER', 'Peru');
  static PHL = new Countries(608, 'PHL', 'Philippines (the)');
  static PCN = new Countries(612, 'PCN', 'Pitcairn');
  static POL = new Countries(616, 'POL', 'Poland');
  static PRT = new Countries(620, 'PRT', 'Portugal');
  static PRI = new Countries(630, 'PRI', 'Puerto Rico');
  static QAT = new Countries(634, 'QAT', 'Qatar');
  static ROU = new Countries(642, 'ROU', 'Romania');
  static RUS = new Countries(643, 'RUS', 'Russian Federation (the)');
  static RWA = new Countries(646, 'RWA', 'Rwanda');
  static REU = new Countries(638, 'REU', 'Reunion');
  static BLM = new Countries(652, 'BLM', 'Saint Barthélemy');
  static SHN = new Countries(654, 'SHN', 'Saint Helena, Ascension and Tristan da Cunha');
  static KNA = new Countries(659, 'KNA', 'Saint Kitts and Nevis');
  static LCA = new Countries(662, 'LCA', 'Saint Lucia');
  static MAF = new Countries(663, 'MAF', 'Saint Martin (French part)');
  static SPM = new Countries(666, 'SPM', 'Saint Pierre and Miquelon');
  static VCT = new Countries(670, 'VCT', 'Saint Vincent and the Grenadines');
  static WSM = new Countries(882, 'WSM', 'Samoa');
  static SMR = new Countries(674, 'SMR', 'San Marino');
  static STP = new Countries(678, 'STP', 'Sao Tome and Principe');
  static SAU = new Countries(682, 'SAU', 'Saudi Arabia');
  static SEN = new Countries(686, 'SEN', 'Senegal');
  static SRB = new Countries(688, 'SRB', 'Serbia');
  static SYC = new Countries(690, 'SYC', 'Seychelles');
  static SLE = new Countries(694, 'SLE', 'Sierra Leone');
  static SGP = new Countries(702, 'SGP', 'Singapore');
  static SXM = new Countries(534, 'SXM', 'Sint Maarten (Dutch part)');
  static SVK = new Countries(703, 'SVK', 'Slovakia');
  static SVN = new Countries(705, 'SVN', 'Slovenia');
  static SLB = new Countries(90, 'SLB', 'Solomon Islands');
  static SOM = new Countries(706, 'SOM', 'Somalia');
  static ZAF = new Countries(710, 'ZAF', 'South Africa');
  static SGS = new Countries(239, 'SGS', 'South Georgia and the South Sandwich Islands');
  static SSD = new Countries(728, 'SSD', 'South Sudan');
  static ESP = new Countries(724, 'ESP', 'Spain');
  static LKA = new Countries(144, 'LKA', 'Sri Lanka');
  static SDN = new Countries(729, 'SDN', 'Sudan (the)');
  static SUR = new Countries(740, 'SUR', 'Suriname');
  static SJM = new Countries(744, 'SJM', 'Svalbard and Jan Mayen');
  static SWE = new Countries(752, 'SWE', 'Sweden');
  static CHE = new Countries(756, 'CHE', 'Switzerland');
  static SYR = new Countries(760, 'SYR', 'Syrian Arab Republic (the)');
  static TWN = new Countries(158, 'TWN', 'Taiwan (Province of China)');
  static TJK = new Countries(762, 'TJK', 'Tajikistan');
  static TZA = new Countries(834, 'TZA', 'Tanzania, the United Republic of');
  static THA = new Countries(764, 'THA', 'Thailand');
  static TLS = new Countries(626, 'TLS', 'Timor-Leste');
  static TGO = new Countries(768, 'TGO', 'Togo');
  static TKL = new Countries(772, 'TKL', 'Tokelau');
  static TON = new Countries(776, 'TON', 'Tonga');
  static TTO = new Countries(780, 'TTO', 'Trinidad and Tobago');
  static TUN = new Countries(788, 'TUN', 'Tunisia');
  static TKM = new Countries(795, 'TKM', 'Turkmenistan');
  static TCA = new Countries(796, 'TCA', 'Turks and Caicos Islands (the)');
  static TUV = new Countries(798, 'TUV', 'Tuvalu');
  static TUR = new Countries(792, 'TUR', 'Turkiye');
  static UGA = new Countries(800, 'UGA', 'Uganda');
  static UKR = new Countries(804, 'UKR', 'Ukraine');
  static ARE = new Countries(784, 'ARE', 'United Arab Emirates (the)');
  static GBR = new Countries(826, 'GBR', 'United Kingdom of Great Britain and Northern Ireland (the)');
  static UMI = new Countries(581, 'UMI', 'United States Minor Outlying Islands (the)');
  static USA = new Countries(840, 'USA', 'United States of America (the)');
  static URY = new Countries(858, 'URY', 'Uruguay');
  static UZB = new Countries(860, 'UZB', 'Uzbekistan');
  static VUT = new Countries(548, 'VUT', 'Vanuatu');
  static VEN = new Countries(862, 'VEN', 'Venezuela (Bolivarian Republic of)');
  static VNM = new Countries(704, 'VNM', 'Viet Nam');
  static VGB = new Countries(92, 'VGB', 'Virgin Islands (British)');
  static VIR = new Countries(850, 'VIR', 'Virgin Islands (U.S.)');
  static WLF = new Countries(876, 'WLF', 'Wallis and Futuna');
  static ESH = new Countries(732, 'ESH', 'Western Sahara*');
  static YEM = new Countries(887, 'YEM', 'Yemen');
  static ZMB = new Countries(894, 'ZMB', 'Zambia');
  static ZWE = new Countries(716, 'ZWE', 'Zimbabwe');
  static ALA = new Countries(248, 'ALA', 'Aland Islands');

  static getById = (status: number): Countries | undefined => {
    const extension = Object.values(Countries)
      .filter((t) => t instanceof Countries)
      .find((t: Countries) => t.id === status);

    return extension;
  };

  static getByName = (name: string): Countries | undefined => {
    return name ? Object.values(Countries)
      .filter((t) => t instanceof Countries)
      .find((t: Countries) => t.name.toLowerCase() === name.toLowerCase()) : undefined;
  };
}

export class GreenIdStatusEnum extends Enumeration {
  // id 8 is a workaround for id=0. Id Zero cannot be selected in dropdown. We swapping 8 to null before endpoint call in thunks file.
  static UNVERIFIED = new GreenIdStatusEnum(8, 'UNVERIFIED', 'Unverified');
  // when passing GreenIdStatus = 0 endpoint return 500 error:
  // "status": 500,
  // "detail": "GreenIdStatus is incorrect",
  // -----
  // EnumerationClasses/GreenIdStatusType.cs
  // line 16
  // public static readonly GreenIdStatusType UNVERIFIED = new GreenIdStatusType(0, nameof(UNVERIFIED), "UnVerified");
  static VERIFIED = new GreenIdStatusEnum(1, 'VERIFIED', 'Verified');
  static VERIFIED_ADMIN = new GreenIdStatusEnum(2, 'VERIFIED_ADMIN', 'Verified (by admin)');
  static VERIFIED_WITH_CHANGES = new GreenIdStatusEnum(3, 'VERIFIED_WITH_CHANGES', 'Verified with changes');
  static IN_PROGRESS = new GreenIdStatusEnum(4, 'IN_PROGRESS', 'In progress');
  static PENDING = new GreenIdStatusEnum(5, 'PENDING', 'Pending review');
  static LOCKED_OUT = new GreenIdStatusEnum(6, 'LOCKED_OUT', 'Locked out');
  static VERIFIED_EXTERNALLY = new GreenIdStatusEnum(7, 'VERIFIED_EXTERNALLY', 'Verified Externally');
  
  static getById = (status: number): GreenIdStatusEnum | undefined => {
    const extension = Object.values(GreenIdStatusEnum)
      .filter((t) => t instanceof GreenIdStatusEnum)
      .find((t: GreenIdStatusEnum) => t.id === status);

    return extension;
  };

  static getByName = (name: string): GreenIdStatusEnum | undefined => {
    return Object.values(GreenIdStatusEnum)
      .filter((t) => t instanceof GreenIdStatusEnum)
      .find((t: GreenIdStatusEnum) => t.name.toLowerCase() === name.toLowerCase());
  };
}
