import CloseOutlined from '@mui/icons-material/CloseOutlined';
import { IconButton, Snackbar, SnackbarProps } from '@mui/material';
import Alert, { AlertColor } from '@mui/material/Alert';
import React from 'react';

export interface WO2SnackbarProps {
  MUISnackbarProps: SnackbarProps;
  showCloseButton?: boolean;
  severity?: string;
}

export const WO2Snackbar = ({ MUISnackbarProps, showCloseButton, severity }: WO2SnackbarProps): JSX.Element => {
  return (
    <Snackbar
      autoHideDuration={6000}
      {...MUISnackbarProps}
      action={
        !!showCloseButton ? (
          <IconButton
            size="small"
            aria-label="close snackbar notification"
            color="inherit"
            onClick={(event) => {
              if (MUISnackbarProps.onClose) {
                MUISnackbarProps.onClose(event, 'clickaway');
              }
            }}
          >
            <CloseOutlined fontSize="small" />
          </IconButton>
        ) : (
          <></>
        )
      }
    >
      <Alert severity={severity as AlertColor}>{MUISnackbarProps.message}</Alert>
    </Snackbar>
  );
};
