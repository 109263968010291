import { Container, Tab, Tabs } from '@mui/material';
import React from 'react';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { Props } from '../container';
import { EditContainer } from '../edit/container';
import { PortfoliosContainer } from '../portfolios/container';
import { ReportsContainer } from '../reports/container';

export const Templates = ({ hasDashEmail }: Props): JSX.Element => {
  const { pathname } = useLocation();
  const history = useHistory();

  const getValue = (): number => (pathname.startsWith('/templates/reports') ? 2 : 0);

  return (
    <Container style={{ padding: '20px' }}>
      <Tabs
        value={getValue().toString()}
        TabIndicatorProps={{ style: { height: '4px' } }}
        style={{ padding: '0 24px 10px' }}
      >
        <Tab onClick={() => history.replace(`/templates/portfolios`)} value="0" label="PORTFOLIO" />
        <Tab value="1" label="EMAIL" disabled={true} />
        {hasDashEmail && <Tab onClick={() => history.replace(`/templates/reports`)} value="2" label="REPORTS" />}
      </Tabs>
      <Switch>
        <Route exact path={`/templates`} render={() => <Redirect to={`templates/portfolios`} />} />
        <Route exact path={`/templates/portfolios/:afslId?`} component={PortfoliosContainer} />
        <Route path={`/templates/portfolios/:afslId/:id`} component={EditContainer} />

        {hasDashEmail && <Route path="/templates/reports" component={ReportsContainer} />}
      </Switch>
    </Container>
  );
};
