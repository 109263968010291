import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { progressSelectorFactory } from 'src/common/store/selectors/loadingProgressSelector';
import { RootState } from '../../../../reducers';
import { selectPrevNextRoutePaths } from '../store/routeSelectors';
import { selectId, selectSuperSimplifierDetailsValues } from '../store/selectors';
import { OnboardActionTypes, saveSuperSimplifierDetailsValues } from '../store/thunks';
import { SuperSimplifier } from './components/superSimplifier';

const mapStateToProps = (state: RootState, routeComponentProps: RouteComponentProps) => ({
  id: selectId(state),
  prevNextRoutePaths: selectPrevNextRoutePaths(state, routeComponentProps.location.pathname),
  superSimplifierDetailsValues: selectSuperSimplifierDetailsValues(state),
  saveProgress: progressSelectorFactory([OnboardActionTypes.SaveOnboarding])(state),
});

const mapDispatchToProps = {
  saveSuperSimplifierDetailsValues,
};

interface MatchParams {
  id?: string;
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector> & RouteComponentProps<MatchParams>;
export const SuperSimplifierContainer = connect(mapStateToProps, mapDispatchToProps)(SuperSimplifier);
