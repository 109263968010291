import { connect, ConnectedProps } from 'react-redux';
import { progressSelectorFactory } from '../../common';
import { RootState } from '../../reducers';
import { selectIsDataFromTriumph } from '../../store/selectors';
import { Holdings } from './components/holdings';
import {
  filteredResults,
  leftTable,
  rightTable,
  selectAfsls,
  selectConsolidatedSecurityHoldings,
  selectParameters,
  selectSecurities,
  totalValue,
} from './store/selectors';
import { holdingsSlice } from './store/slice';
import {
  fetchAfsls,
  FetchConsolidatedHoldings,
  FetchConsolidatedSecurityHoldings,
  fetchSecurities,
  HoldingsActionTypes,
} from './store/thunks';

const exportActionTypes = [HoldingsActionTypes.FetchAfsls, HoldingsActionTypes.FetchSecurities];

const mapStateToProps = (state: RootState) => ({
  parameters: selectParameters(state),
  selectConsolidatedSecurityHoldings: selectConsolidatedSecurityHoldings(state),
  leftTable: leftTable(state),
  rightTable: rightTable(state),
  filteredResults: filteredResults(state),
  totalValue: totalValue(state),
  loadingProgress: progressSelectorFactory([HoldingsActionTypes.FetchConsolidatedSecurityHoldings])(state),
  exportLoadingProgress: progressSelectorFactory(exportActionTypes)(state),
  exportingProcess: progressSelectorFactory([HoldingsActionTypes.FetchConsolidatedHoldings])(state),
  isDataFromTriumph: selectIsDataFromTriumph(state),
  afslDetails: selectAfsls(state),
  securityDetails: selectSecurities(state),
});

const mapDispatchToProps = {
  ...holdingsSlice.actions,
  FetchConsolidatedSecurityHoldings,
  FetchConsolidatedHoldings,
  fetchAfsls,
  fetchSecurities,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector>;
export const HoldingsContainer = connect(mapStateToProps, mapDispatchToProps)(Holdings);
