import { DateTime } from 'luxon';
import { AnyAction } from 'redux';
import { DISMISS_MESSAGE } from './actions';
import { Message } from './types';

export interface MessageState {
  [index: string]: Message | null;
}

const messagesReducer = (state: MessageState = {}, action: AnyAction): MessageState => {
  const { type } = action;

  switch (action.type) {
    case DISMISS_MESSAGE: {
      Object.entries(state).forEach((e) => {
        if (!!e[1] && (e[1] as Message).key === action.key) {
          (e[1] as Message).dismissed = true;
        }
      });
      return {
        ...state,
      };
    }
    default: {
      const matches = /(.*)\/(Request|pending|Failed|rejected|fulfilled)/.exec(type);

      if (!matches) return state;

      const [, requestName, requestState] = matches;

      switch (requestState) {
        case 'Request':
        case 'pending':
          return {
            ...state,
            [requestName]: null,
          };

        case 'rejected':
        case 'Failed':
          return {
            ...state,
            [requestName]: action.payload ?? { variant: 'error', ...action.error, key: DateTime.now().toMillis() + Math.random(), dismissed: false },
          };

        case 'fulfilled':
          if (action?.payload?.message) {
            return {
              ...state,
              [requestName]: action.payload.message
                ? { variant: 'success', ...action.payload, key: DateTime.now().toMillis() + Math.random(), dismissed: false }
                : action.payload,
            };
          }
          return state;

        default:
          return state;
      }
    }
  }
};

export default messagesReducer;
