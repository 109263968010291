import { LoadingProgress } from "src/common/store/types";
import { PendingBeneficiaryDetails } from "../store/types";
import { useCallback, useEffect, useState } from "react";
import React from "react";
import { DataGridPro, GridActionsCellItem, GridColumns, gridEditRowsStateSelector, GridPreProcessEditCellProps, GridRenderCellParams, GridRenderEditCellParams, GridRowModes, GridRowParams, GridToolbarContainer, GridValueFormatterParams, GridValueGetterParams, MuiBaseEvent, MuiEvent, useGridApiRef } from "@mui/x-data-grid-pro";
import { moment } from "src/common/types";
import { Box, IconButton, LinearProgress, Paper, Stack, styled, TextField, Typography } from "@mui/material";
import { PendingBeneficiariesNominationTypeEnum } from "../enums/pendingBeneficiariesNominationTypeEnum";
import { RelationshipTypeEnum } from "../enums/relationshipType";
import { Delete as DeleteIcon, Save as SaveIcon, Edit as EditIcon, Add as AddIcon, Cancel as CancelIcon } from "@mui/icons-material";
import PulseLoader from "react-spinners/PulseLoader";
import { theme } from '../../../../../../../themes';
import * as yup from 'yup';
import { DateTimeFormat, getLocalDateTime } from 'src/common/utils/dateFunctions';
import { roundDecimalToDigits } from 'src/common/utils/numberFunctions';
import { AllocationInput } from "src/features/templates/edit/components/allocationInput";
import { DatePicker } from "@mui/lab";
import { DateTime } from "luxon";

const StyledBox = styled(Box)(() => ({
    width: '100%',
    '& .MuiDataGrid-cell--editing': {
      backgroundColor: 'rgb(255,215,115, 0.19)',
      color: '#1a3e72',
      '& .MuiInputBase-root': {
        height: '100%',
      },
    },
    '& .Mui-error': {
      backgroundColor: `#FFCCCC`,
      color: 'red',
      borderRadius: '4px',
      borderColor: 'red',
      borderStyle: 'solid',
      borderWidth: '1px',
    },
    '& .MuiDataGrid-editInputCell': {
      borderWidth: '2px',
    },
    '& input[type=number]': {
      textAlign: 'right',
      paddingRight: '0px',
    },
    '& .MuiDataGrid-toolbarContainer': {
      justifyContent: 'flex-end',
    },
  }));


export interface PendingBeneficiariesTableProps {
    items: PendingBeneficiaryDetails[];
    itemLoadingProgress: LoadingProgress;
    onSave?: (pendingBfDetailsToSave: PendingBeneficiaryDetails[]) => Promise<void>;
    savingProgress: LoadingProgress;
    hideEditControls?: boolean;
}

interface DataRow {
    id?: string
    pendingBeneficiaryId?: number;
    name: string;
    dateOfBirth: moment;
    relationship: number | undefined;
    percentage: number;
    nominationType: number | undefined;
}
  
interface UpdatedRow {
    [key: string]: DataRow;
}

// Disallow any characters that are not alphabetical (excluding spaces)
const disallowedCharacterRange = /[^A-Za-z ]/g;

export interface NameStructure {
  firstName: string;
  lastName: string;
}

export const getNameStructure = (fullName: string) => {
  const nameStructure: NameStructure = {
    firstName: '',
    lastName: ''
  };

  // Assume space means split between given name(s) and surname
  const names = fullName.trim().split(' ');

  if (names.length > 1) {
    nameStructure.firstName = names.slice(0, names.length - 1).join(' ');
    nameStructure.lastName = names[names.length - 1];
  }
  else {
    nameStructure.firstName = fullName;
  }

  return nameStructure;
};

export const santiseInputToAlphabeticString = (input: string) => {
  return input.replaceAll(disallowedCharacterRange, '');
};

export const PendingBeneficiariesTable = (props: PendingBeneficiariesTableProps): JSX.Element => { 
    const {
        items,
        itemLoadingProgress,
        onSave,
        savingProgress,
        hideEditControls
    } = props;

    const [editMode, setEditMode] = useState<boolean>(false);
    const [dataRows, setDataRows] = useState<DataRow[]>([]);
    const [validationMessage, setValidationMessage] = useState<string>('');

    const rowsWithUpdatedValues = React.useRef<UpdatedRow>({});
    const apiRef = useGridApiRef();

    const deleteRow = React.useCallback(
        (id: string) => () => {
          delete rowsWithUpdatedValues.current[id];
          apiRef.current.setRowMode(id, GridRowModes.View);
          apiRef.current.updateRows([{ id, _action: 'delete' }]);
        },
        [apiRef, dataRows]
    );

    const handleAddClick = React.useCallback(async () => {
        const id = apiRef.current.getAllRowIds().length.toString();
    
        const newRow: DataRow = {
            id: id,
            name: '',
            dateOfBirth: '2000-01-01',
            relationship: RelationshipTypeEnum.child.id,
            percentage: 0,
            nominationType: PendingBeneficiariesNominationTypeEnum.NonbindingNonLapsing.id
        };

        rowsWithUpdatedValues.current[id] = newRow;
        apiRef.current.updateRows([{ ...newRow, id, isNew: true }]);
        apiRef.current.setRowMode(id, GridRowModes.Edit);

        await apiRef.current.setEditCellValue({ id: id.toString(), field: 'relationship', value: newRow.relationship });
        await apiRef.current.setEditCellValue({ id: id.toString(), field: 'nominationType', value: newRow.nominationType });
        await apiRef.current.setEditCellValue({ id: id.toString(), field: 'percentage', value: newRow.percentage });
        await apiRef.current.setEditCellValue({ id: id.toString(), field: 'dateOfBirth', value: newRow.dateOfBirth });
        await apiRef.current.setEditCellValue({ id: id.toString(), field: 'name', value: newRow.name });
    
        // Wait for the grid to render with the new row
        setTimeout(() => {
          apiRef.current.scrollToIndexes({
            rowIndex: apiRef.current.getRowsCount() - 1,
          });
        });
      }, [apiRef]);

    const handleEditClick = React.useCallback(() => {
      setEditMode(true);
      apiRef.current.getAllRowIds().forEach((id) => {
        apiRef.current.setRowMode(id, GridRowModes.Edit);
      });
    }, [apiRef, rowsWithUpdatedValues]);
    
    useEffect(() => {
      resetDataRows();
    }, [items]);

    const resetDataRows = useCallback(() => {
      setDataRows([]);
      const itemsToProcess = items
        .map((i, index) => ({
          id: index.toString(),
          pendingBeneficiaryId: i.id,
          name: santiseInputToAlphabeticString(`${i.firstName} ${i.lastName}`),
          dateOfBirth: i.dateOfBirth,
          relationship: RelationshipTypeEnum.getFirstEnumMatchingDesc(i.relationship ?? '')?.id ?? RelationshipTypeEnum.other.id,
          percentage: i.percentage,
          nominationType: PendingBeneficiariesNominationTypeEnum.getByName(i.nominationType)?.id ?? PendingBeneficiariesNominationTypeEnum.NonbindingNonLapsing.id
        } as DataRow));

        rowsWithUpdatedValues.current = {}; // Clear out old updated rows after saving or resetting

        items.forEach((i, index) => {
          rowsWithUpdatedValues.current[index.toString()] = {
            id: index.toString(),
            pendingBeneficiaryId: i.id,
            name: santiseInputToAlphabeticString(`${i.firstName} ${i.lastName}`),
            dateOfBirth: i.dateOfBirth,
            relationship: RelationshipTypeEnum.getFirstEnumMatchingDesc(i.relationship ?? '')?.id,
            percentage: i.percentage,
            nominationType: PendingBeneficiariesNominationTypeEnum.getByName(i.nominationType)?.id
          };
        });

      setDataRows([...itemsToProcess]);
    }, [items, dataRows]);

    const handleCancelClick = React.useCallback(() => {
      setEditMode(false);
      apiRef.current.getAllRowIds().forEach((id) => {
        const rowMode = apiRef.current.getRowMode(id);
        if (rowMode === 'edit') {
          apiRef.current.setRowMode(id, GridRowModes.View);
        }
        const row = apiRef.current.getRow(id);
        if (!!row && row.isNew) {
          apiRef.current.updateRows([{ id, _action: 'delete' }]);
        }
      });
  
      resetDataRows();
    }, [apiRef, items]);

    const handleSave = useCallback(async () => {
      if (!!onSave) {
        const editModels = gridEditRowsStateSelector(apiRef.current.state);
        handleCancelClick();
        await onSave(
          Object.entries(editModels).map((m) => ({
            id: m[1].pendingBeneficiaryId?.value ? Number(m[1].pendingBeneficiaryId.value) : undefined,
            firstName: getNameStructure(m[1].name.value).firstName,
            lastName: getNameStructure(m[1].name.value).lastName,
            dateOfBirth: m[1].dateOfBirth.value,
            relationship: RelationshipTypeEnum.getById(m[1].relationship.value)?.name ?? RelationshipTypeEnum.other.name,
            percentage: Number(m[1].percentage.value),
            nominationType: PendingBeneficiariesNominationTypeEnum.getById(m[1].nominationType.value)?.name
                              ?? PendingBeneficiariesNominationTypeEnum.NonbindingNonLapsing.name
          }))
        );
      }
    }, [onSave, apiRef.current]);

  const validationSchema = yup.object({
    pendingBeneficiaryRecords: yup.array().of(
      yup.object().shape({
        id: yup.string(),
        pendingBeneficiaryId: yup.number().nullable().notRequired(),
        name: yup.string().test(
          'empty-name-check', 
          'Name must not be empty or have non-alphabetic characters (spaces allowed)',
          (fullName) => {
            const name = fullName ?? ''
            const nonAlphabeticMatches = name.match(disallowedCharacterRange) ?? [];
            return name.length > 0 && nonAlphabeticMatches.length === 0;
          }
        ),
        dateOfBirth: yup.date().typeError('Must enter a valid date in the format: dd/MM/yyyy').required('Must enter DOB for beneficiary'),
        relationship: yup.string().required('Relationship of Beneficiary must be specified'),
        nominationType: yup.string().required('Must choose a nomination type for the beneficiary'),
        percentage: yup.number().moreThan(0, 'Percentage assigned to beneficiary must be more than 0')
          .max(100, 'Percentage must not exceed 100 for a beneficiary').required('Must assign a percentage to a beneficiary')
      })
    ),
  });

  const validatePendingBeneficiaries = React.useCallback(() => {
    const pendingBeneficiaryRecords: DataRow[] = Object.entries(gridEditRowsStateSelector(apiRef.current.state))
      .map((r) => r[1])
      .map((r, index) => ({
        id: index.toString(),
        pendingBeneficiaryId: r.pendingBeneficiaryId?.value ?? undefined,
        name: r.name.value ?? '',
        dateOfBirth: Number(r.relationship.value ?? '0') !== RelationshipTypeEnum.lpr.id 
                      ? r.dateOfBirth?.value ?? '' : '1970-01-01', // Dummy date to pass validation for LPR beneficiaries
        relationship: r.relationship?.value ?? '',
        percentage: r.percentage?.value ?? '',
        nominationType: r.nominationType?.value ?? ''
      }));

    validationSchema
      .validate({ pendingBeneficiaryRecords: pendingBeneficiaryRecords })
      .then(() => {
        const totalPercentage = calcTotalPercentage();
        const rowCount = apiRef.current.getRowsCount();
        if (totalPercentage != 100 && rowCount > 0) {
          setValidationMessage('Percentage sum of all beneficiaries must equal 100%');
        }
        else if (rowCount > 10) {
          setValidationMessage('Number of beneficiaries must not exceed 10');
        }
        else if (items.length === 0 && rowCount < 1) {
          setValidationMessage('Must enter at least one beneficiary');
        }
        else {
          setValidationMessage('');
        }
      })
      .catch((e) => {
        setValidationMessage(e.message);
      });
  }, [apiRef.current.state]);

  const columns: GridColumns = 
  [
    {
      field: 'relationship',
      headerName: 'RELATIONSHIP',
      type: 'singleSelect',
      flex: 1,
      editable: true,
      sortable: false,
      renderCell: (params: GridRenderCellParams<number>) => {
        return <div>{RelationshipTypeEnum.getById(params.value)?.displayName}</div>;
      },
      valueOptions: () => {
        return [...RelationshipTypeEnum.getAll()]
            .map((c) => ({ value: c.id, label: c.displayName }))
            .sort((a, b) => a.label.localeCompare(b.label));
      },
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        return { ...params.props, error: params.props.value === '' };
      },
      valueGetter: (params: GridValueGetterParams) => {
        return params.value !== null ? params.value : '';
      }
  },
  {
      field: 'name',
      headerName: 'NAME',
      type: 'string',
      flex: 1,
      editable: true,
      sortable: false,
  },
  {
      field: 'dateOfBirth',
      headerName: 'DATE OF BIRTH',
      type: 'date',
      flex: 1,
      width: 220,
      editable: true,
      sortable: false,
      renderCell: (params: GridRenderCellParams<moment>) => {
        return <div>{getLocalDateTime(params.value, DateTimeFormat.Short)}</div>
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        const newRowState = gridEditRowsStateSelector(apiRef.current.state);
        const isLpr = newRowState[params.id].relationship.value === RelationshipTypeEnum.lpr.id;

        if (isLpr) {
          newRowState[params.id].dateOfBirth.value = '';
        }

        return (
          <DatePicker
            value={!isLpr ? params.value : null}
            inputFormat={DateTimeFormat.Short.displayName}
            renderInput={(params) => <TextField fullWidth={true} {...params} />}
            onChange={(newDate) => {
              apiRef.current.setEditCellValue(
              { 
                id: params.id, 
                field: params.field, 
                value: !isLpr ? (newDate as DateTime)?.toISODate() ?? '' : ''
              });
            }}
            disabled={isLpr}
            disableFuture={true}
        />
        )
      }
  },
  {
      field: 'nominationType',
      headerName: 'TYPE',
      type: 'singleSelect',
      flex: 1,
      editable: true,
      sortable: false,
      renderCell: (params: GridRenderCellParams<number>) => {
        return <div>{PendingBeneficiariesNominationTypeEnum.getById(params.value)?.displayName}</div>;
      },
      valueOptions: () => {
        return [...PendingBeneficiariesNominationTypeEnum.getAll()]
          .map((c) => ({ value: c.id, label: c.displayName }))
          .sort((a, b) => a.label.localeCompare(b.label));
      },
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        return { ...params.props, error: params.props.value === '' };
      },
      valueGetter: (params: GridValueGetterParams) => {
        return params.value !== null ? params.value : '';
      },
    },
    {
      field: 'percentage',
      headerName: 'PERCENTAGE (%)',
      type: 'number',
      flex: 1,
      editable: true,
      sortable: false,
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        rowsWithUpdatedValues.current[params.id].percentage = params.props.value;

        return { ...params.props };
      },
      valueGetter: (params: GridValueGetterParams) => {
        return `${rowsWithUpdatedValues.current[Number(params.id)]?.percentage || '0.00'}`;
      },
      valueFormatter: (params: GridValueFormatterParams<number>) => {
        return Number(params.value).toFixed(2);
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        return <AllocationInput {...params} value={params.value} />;
      },
    },
    {
      field: 'actions',
      type: 'actions',
      sortable: false,
      width: 80,
      getActions: (params) => {
        return [
          <GridActionsCellItem key="1" icon={<DeleteIcon />} label="Delete" onClick={deleteRow(params.id.toString())} disabled={savingProgress.isLoading} />,
        ];
      },
    },
    {
      field: 'pendingBeneficiaryId',
      type: 'number',
      editable: true,
      sortable: false,
      width: 0,
      maxWidth: 0,
      minWidth: 0
    }
  ];

  const calcTotalPercentage = () => {
    return Object.entries(rowsWithUpdatedValues.current)
      .map((e) => Number(e[1].percentage))
      .map((w) => {
        return isNaN(w) ? 0 : +w;
      })
      .reduce((partialSum, a) => roundDecimalToDigits(partialSum + a, 2), 0); // Weight fields input restricted to 2 decimal places
  };

  return (
      <>
        <Typography variant="h3" style={{ paddingBottom: '10px', paddingTop: '10px' }}>
            Pending Beneficiaries
        </Typography>
        <Paper elevation={3}>
            <StyledBox>
                <DataGridPro 
                    editMode="row"
                    apiRef={apiRef}
                    rows={savingProgress.isLoading ? [] : dataRows}
                    columns={columns}
                    columnVisibilityModel={{
                      actions: editMode,
                    }}
                    pageSize={items.length}
                    disableColumnMenu
                    disableColumnReorder={true}
                    style={{ height: '400px' }}
                    components={{
                      LoadingOverlay: LinearProgress,
                      Toolbar: () => {
                        validatePendingBeneficiaries();
        
                        return (
                          <GridToolbarContainer>
                            <Stack direction="row" alignItems="center">
                              {savingProgress.isLoading && (
                                <div className="LoadingIndicator" style={{ padding: '7px' }}>
                                  <PulseLoader size="9px" margin="5px" color={theme.palette.grey[400]} />
                                </div>
                              )}
                              {!hideEditControls && editMode && <Typography color={'error'}>{validationMessage}</Typography>}
                              {!hideEditControls && editMode && !savingProgress.isLoading && (
                                <IconButton disableFocusRipple disableRipple data-testid="addButton" onClick={handleAddClick} color={'primary'}>
                                  <AddIcon style={{ height: '24px' }} />
                                </IconButton>
                              )}
                              {!hideEditControls && !editMode && (
                                <IconButton disableFocusRipple disableRipple data-testid="editButton" onClick={handleEditClick} color={'primary'}>
                                  <EditIcon style={{ height: '24px' }} />
                                </IconButton>
                              )}
                              {!hideEditControls && editMode && !savingProgress.isLoading && (
                                <IconButton disableFocusRipple disableRipple data-testid="cancelButton" onClick={handleCancelClick} color={'primary'}>
                                  <CancelIcon style={{ height: '24px' }} />
                                </IconButton>
                              )}
                              {!hideEditControls && editMode && !savingProgress.isLoading && (
                                <IconButton
                                  disableFocusRipple
                                  disableRipple
                                  data-testid="saveButton"
                                  onClick={handleSave}
                                  color={'primary'}
                                  disabled={validationMessage !== ''}
                                >
                                  <SaveIcon style={{ height: '24px' }} />
                                </IconButton>
                              )}
                            </Stack>
                          </GridToolbarContainer>
                        );
                      },
                      Footer: () => {
                        const percentageSum = calcTotalPercentage();

                        return (
                          <Typography
                            variant="h4"
                            style={{
                              color: percentageSum !== 100 ? 'red' : 'darkgray',
                              display: 'flex',
                              justifyContent: 'right',
                              padding: '20px',
                              fontWeight: 'bold',
                            }}
                          >
                            Total: {percentageSum}%
                          </Typography>
                        )
                      },
                    }}
                    componentsProps={{
                      footer: { 
                        total: items.reduce((partialSum, a) => partialSum + a.percentage, 0) 
                      }
                    }}
                    loading={itemLoadingProgress.isLoading || savingProgress.isLoading}
                    onRowEditStop={(_params: GridRowParams, event: MuiEvent<MuiBaseEvent>) => {
                      // we don't want to end editting unless they click save
                      event.defaultMuiPrevented = true;
                    }}
                    onRowEditStart={(_params: GridRowParams, event: MuiEvent<MuiBaseEvent>) => {
                      // we don't want to end editting unless they click edit
                      event.defaultMuiPrevented = true;
                    }}
                />
            </StyledBox>
        </Paper>
      </>
  )
}