import { Search as SearchIcon } from '@mui/icons-material';
import { Box, Container, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import React, { useCallback, useEffect, useState } from 'react';
import WO2Button from '../../../../common/components/button/Button';
import history from '../../../../history';
import { PagedRequest } from '../../../../store';
import { theme } from '../../../../themes';
import { Props } from '../container';
import { ModelOrStrategy } from '../store/types';
import { WO2TextInput } from './../../../../common';
import { DetailedList } from './detailedList';
import { ModelListTable } from './modelListTable';
import { StrategyListTable } from './strategyListTable';
import { searchStyle, useStyles } from './themes/modelListStyles';

export const ModelList = (props: Props): JSX.Element => {
  const classes = useStyles();

  const [gridView, SetGridView] = useState(true);

  const {
    parameters,
    strategyParameters,
    setSearchStringParameter,
    setStrategySearchStringParameter,
    setModelOrStrategyParameter,
    modelList,
    strategyList,
    isLoadingModelList,
    hasErrorsModelList,
    isLoadingStrategyList,
    hasErrorsStrategyList,
    fetchModelList,
    fetchStrategyList,
  } = props;

  const fetch = useCallback(() => {
    fetchModelList({
      modelOrStrategy: ModelOrStrategy.Model,
      codeOrNameSearch: '',
      pagination: {
        pageNumber: 1,
        pageSize: 25,
        queryFields: [
          {
            fieldName: 'name',
            descendingSortDirection: false,
            operator: '',
            hasSearchTerm: false,
            isSortTerm: true,
            searchTerm: '',
          },
        ],
      },
    });
  }, [fetchModelList]);

  const fetchStrategies = useCallback(() => {
    fetchStrategyList(strategyParameters);
  }, [fetchStrategyList, strategyParameters]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  useEffect(() => {
    fetchStrategies();
  }, [fetchStrategies]);

  const handleSearchClick = () => {
    fetchModelList(parameters);
  };

  const handleStrategySearchClick = () => {
    fetchStrategyList(strategyParameters);
  };

  const handleGridActions = (pagedRequest: PagedRequest) => {
    fetchModelList({
      modelOrStrategy: ModelOrStrategy.Model,
      pagination: pagedRequest,
      codeOrNameSearch: parameters.codeOrNameSearch,
    });
  };

  const handleStrategyGridActions = (pagedRequest: PagedRequest) => {
    fetchStrategyList({
      pagination: pagedRequest,
      codeOrNameSearch: strategyParameters.codeOrNameSearch,
    });
  };

  const handleToggleView = () => {
    SetGridView(!gridView);
  };

  const handleModelClick = (modelId: number, modelVersionId: number) => {
    history.push(`/model/${modelId}/${modelVersionId}/dashboard`);
  };

  const handleStrategyClick = (strategyId: number, strategyVersionId: number) => {
    history.push(`/strategy/${strategyId}/${strategyVersionId}/dashboard`);
  };

  const [selectedTab, setValue] = React.useState(0);

  const handleTabChange = (event: unknown, newValue: number) => {
    if (!newValue) {
      setModelOrStrategyParameter(ModelOrStrategy.Model);
    } else {
      setModelOrStrategyParameter(ModelOrStrategy.Strategy);
    }
    setValue(newValue);
  };

  return (
    <Container style={{ paddingTop: '20px', paddingBottom: '20px' }}>
      <Tabs
        value={selectedTab}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleTabChange}
        style={{ paddingBottom: '30px' }}
        TabIndicatorProps={{ style: { height: '4px' } }}
      >
        <Tab label="Models" />
        <Tab label="Strategies" />
      </Tabs>

      {!selectedTab && (
        <Container>
          <Box display={'flex'} justifyContent={'space-between'} style={{ paddingBottom: '20px' }}>
            <Grid>
              <Typography
                style={{
                  height: '48px',
                  color: '#000a12',
                  fontFamily: theme.typography.fontFamily,
                  fontSize: '36px',
                  fontWeight: 400,
                  lineHeight: '48px',
                  paddingRight: '20px',
                  marginLeft: '11px',
                }}
              >
                Models
              </Typography>
            </Grid>
            <Box display={'flex'} justifyContent={'space-between'} style={{ padding: '10px 0' }}>
              <Box display={'flex'} justifyContent={'space-between'}>
                <article className={classes.search} style={searchStyle}>
                  <div className={classes.searchIcon}>
                    <SearchIcon color="primary" />
                  </div>
                  <WO2TextInput
                    className={classes.searchInput}
                    placeholder={'Model Search'}
                    inputProps={{ 'aria-label': 'search' }}
                    onChange={(event) => {
                      setSearchStringParameter(event.target.value);
                    }}
                    value={props.parameters.codeOrNameSearch}
                  />
                </article>
                <Box paddingRight={'10px'}></Box>
                <Box>
                  <WO2Button
                    color="primary"
                    disableElevation
                    style={{ fontFamily: theme.typography.fontFamily }}
                    onClick={handleSearchClick}
                    variant="contained"
                    value="Search"
                  >
                    Search
                  </WO2Button>
                </Box>
                <Box>
                  <WO2Button
                    color="primary"
                    variant="contained"
                    onClick={handleToggleView}
                    style={{ marginLeft: '10px', fontFamily: theme.typography.fontFamily }}
                  >
                    toggle view
                  </WO2Button>
                </Box>
              </Box>
            </Box>
          </Box>
          <Grid container spacing={2}>
            {gridView && (
              <ModelListTable
                pagedRequest={parameters.pagination}
                pagedResults={modelList || undefined}
                isLoading={isLoadingModelList}
                hasErrors={hasErrorsModelList}
                handlePagedRequest={handleGridActions}
                handleRowClick={handleModelClick}
              ></ModelListTable>
            )}
            {!gridView && !!modelList && (
              <DetailedList
                pagedRequest={parameters.pagination}
                pagedResult={modelList}
                handlePagedRequest={handleGridActions}
                handleModelClick={handleModelClick}
              ></DetailedList>
            )}
          </Grid>
        </Container>
      )}
      {!!selectedTab && (
        <Container>
          <Box display={'flex'} justifyContent={'space-between'} style={{ paddingBottom: '20px' }}>
            <Grid>
              <Typography
                style={{
                  height: '48px',
                  color: '#000a12',
                  fontFamily: theme.typography.fontFamily,
                  fontSize: '36px',
                  fontWeight: 400,
                  lineHeight: '48px',
                  paddingRight: '20px',
                  marginLeft: '11px',
                }}
              >
                Strategies
              </Typography>
            </Grid>
            <Box display={'flex'} justifyContent={'space-between'} style={{ padding: '10px 0' }}>
              <Box display={'flex'} justifyContent={'space-between'}>
                <article className={classes.search} style={searchStyle}>
                  <div className={classes.searchIcon}>
                    <SearchIcon color="primary" />
                  </div>
                  <WO2TextInput
                    className={classes.searchInput}
                    placeholder={'Strategy Search'}
                    inputProps={{ 'aria-label': 'search' }}
                    onChange={(event) => {
                      setStrategySearchStringParameter(event.target.value);
                    }}
                    value={props.strategyParameters.codeOrNameSearch}
                  />
                </article>
                <Box paddingRight={'10px'}></Box>
                <Box>
                  <WO2Button
                    color="primary"
                    disableElevation
                    style={{ fontFamily: theme.typography.fontFamily }}
                    onClick={handleStrategySearchClick}
                    variant="contained"
                    value="Search"
                  >
                    Search
                  </WO2Button>
                </Box>
              </Box>
            </Box>
          </Box>
          <Grid container spacing={2}>
            {gridView && (
              <StrategyListTable
                pagedRequest={strategyParameters.pagination}
                pagedResults={strategyList || undefined}
                isLoading={isLoadingStrategyList}
                hasErrors={hasErrorsStrategyList}
                handlePagedRequest={handleStrategyGridActions}
                handleRowClick={handleStrategyClick}
              ></StrategyListTable>
            )}
          </Grid>
        </Container>
      )}
    </Container>
  );
};
