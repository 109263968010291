import { FormControlLabel, Box } from '@mui/material';
import React from 'react';
import { WO2Checkbox } from 'src/common';
import DateRangePicker from 'src/common/components/DateRangePicker';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { ClientReportsParameters, MdaReportTypes } from '../../store/types';
import { DateRange } from 'src/store';

export interface UnrealisedCapitalGainsAndLossesProps {
  parameters: ClientReportsParameters;
  setGainsLossesUnrealisedSelectedParameter: ActionCreatorWithPayload<boolean, string>;
  setAsAtDateGainsLossesUnrealisedParameter: ActionCreatorWithPayload<DateRange, string>;
  createReportSortRequest: (isAdd: boolean, id: number, text: string) => void;
}

export const UnrealisedCapitalGainsAndLosses = ({
  parameters,
  createReportSortRequest,
  setGainsLossesUnrealisedSelectedParameter,
  setAsAtDateGainsLossesUnrealisedParameter,
}: UnrealisedCapitalGainsAndLossesProps): JSX.Element => {
  const handleClickGainsLossesUnrealisedSelect = () => {
    setGainsLossesUnrealisedSelectedParameter(!parameters.selectedReports?.unrealisedCapitalGainsAndLosses.selected);
    createReportSortRequest(
      !parameters.selectedReports?.unrealisedCapitalGainsAndLosses.selected,
      MdaReportTypes.UnrealisedCapitalGainsAndLosses.id,
      MdaReportTypes.UnrealisedCapitalGainsAndLosses.displayName
    );
  };

  const handleClickDateForGainsLossesUnrealised = (dates: DateRange) => {
    setAsAtDateGainsLossesUnrealisedParameter(dates);
  };

  return (
    <Box ml={6} justifyContent="space-between" display="flex" alignItems="center" mt={1} mb={1}>
      <FormControlLabel
        aria-label="Acknowledge"
        onClick={(event) => event.stopPropagation()}
        onFocus={(event) => event.stopPropagation()}
        control={
          <WO2Checkbox
            color="primary"
            checked={parameters.selectedReports?.unrealisedCapitalGainsAndLosses.selected}
            onChangeHandler={handleClickGainsLossesUnrealisedSelect}
          />
        }
        label="Unrealised Capital Gains and Losses"
      />
      <DateRangePicker
        sx={{ minWidth: 194 }}
        dateRange={{
          dateFrom: '',
          dateTo: parameters?.selectedReports.unrealisedCapitalGainsAndLosses?.asAtDate,
          dateRangeType: parameters?.selectedReports.unrealisedCapitalGainsAndLosses?.asAtDateType,
        }}
        setDateRange={handleClickDateForGainsLossesUnrealised}
        isDateOnly={true}
      />
    </Box>
  );
};
