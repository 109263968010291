import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';
import { fetchModelDetail, fetchPerformanceDetails } from './thunks';
import { ModelDashboardState, ModelDetail, PerformanceChartInterval, PerformanceChartStartDateParameter, PerformanceDetails } from './types';

export const initialState: ModelDashboardState = {
  parameters: {
    performanceChartStartDate: {
      startDate: DateTime.now().minus({ months: 12 }).toISODate(),
      interval: PerformanceChartInterval.OneYear,
    },
    selectedGroup: 'ALL',
  },
  modelDetail: null,
  performanceDetails: null,
};

export const modelDashboardSlice = createSlice({
  name: '@@model/dashboard',
  initialState,
  reducers: {
    setChartDateParameter: (state, action: PayloadAction<PerformanceChartStartDateParameter>) => {
      state.parameters.performanceChartStartDate = action.payload;
    },
    setSelectedGroupParameter: (state, action: PayloadAction<string>) => {
      state.parameters.selectedGroup = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchModelDetail.fulfilled, (state, action: PayloadAction<ModelDetail>) => {
      state.modelDetail = action.payload;
    });
    builder.addCase(fetchPerformanceDetails.fulfilled, (state, action: PayloadAction<PerformanceDetails>) => {
      state.performanceDetails = action.payload;
    });
  },
});
