import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { progressSelectorFactory } from '../../../common';
import { RootState } from '../../../reducers';
import { PerformanceComponent } from './components/PerformanceComponent';
import { selectBenchmarks, selectModelDetail, selectModelPerformancechartWithBenchmark, selectModelPeriodicSummary, selectParameters } from './store/selectors';
import { modelPerformanceSlice } from './store/slice';
import { fetchModelDetail, fetchModelPerformanceChartWithBenchmark, fetchModelPeriodicSummary, ModelPerformanceActionTypes } from './store/thunks';

const mapStateToProps = (state: RootState) => ({
  parameters: selectParameters(state),
  benchmarks: selectBenchmarks(state),
  modelDetail: selectModelDetail(state),
  modelPeriodicSummary: selectModelPeriodicSummary(state),
  modelPerformancechartWithBenchmark: selectModelPerformancechartWithBenchmark(state),
  loadingProgresModelPeriodicSummary: progressSelectorFactory([ModelPerformanceActionTypes.FetchModelPeriodicSummary])(state),
  loadingProgresModelPerformancechartWithBenchmark: progressSelectorFactory([ModelPerformanceActionTypes.FetchModelPerformanceChartWithBenchmark])(state),
});

const mapDispatchToProps = {
  ...modelPerformanceSlice.actions,
  fetchModelPerformanceChartWithBenchmark,
  fetchModelPeriodicSummary,
  fetchModelDetail,
};

interface MatchParams {
  modelId?: string;
  modelVersionId?: string;
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector> & RouteComponentProps<MatchParams>;
export const ModelPerformanceStateContainer = connect(mapStateToProps, mapDispatchToProps)(PerformanceComponent);
