import { Enumeration } from 'src/common/types';

export class RebalanceTypeEnum extends Enumeration {
  static Monthly = new RebalanceTypeEnum(1, 'Monthly', 'Monthly');
  static Quarterly = new RebalanceTypeEnum(2, 'Quarterly', 'Quarterly');
  static BiYearly = new RebalanceTypeEnum(3, 'BiYearly', 'BiYearly');
  static Annual = new RebalanceTypeEnum(4, 'Annual', 'Annual');

  static getById = (status: number): RebalanceTypeEnum | undefined => {
    return Object.values(RebalanceTypeEnum)
      .filter((t) => t instanceof RebalanceTypeEnum)
      .find((t: RebalanceTypeEnum) => t.id === status);
  };

  static getByName = (name: string): RebalanceTypeEnum | undefined => {
    return Object.values(RebalanceTypeEnum)
      .filter((t) => t instanceof RebalanceTypeEnum)
      .find((t: RebalanceTypeEnum) => t.name.toLowerCase() === name.toLowerCase());
  };
}
