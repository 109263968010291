import { Enumeration } from 'src/common/types';
import { OccupationCategoryItem } from './types';

export class ServiceType extends Enumeration {
  static Mda = new ServiceType(1, 'Mda', 'MDA');
  static Roa = new ServiceType(8, 'Roa', 'ROA'); // TODO: needs to confirm ROA value
  static Wholesale = new ServiceType(9, 'Wholesale', 'Wholesale');

  static getArray = (): ServiceType[] => [ServiceType.Mda, ServiceType.Roa, ServiceType.Wholesale];

  static getById = (id: number): ServiceType | undefined => {
    return Object.values(ServiceType)
      .filter((t) => t instanceof ServiceType)
      .find((t: ServiceType) => t.id === id);
  };
}

export class SourceOfFunds extends Enumeration {
  static BusinessIncome = new SourceOfFunds(1, 'BusinessIncome', 'Business income/earnings');
  static CompensationPayment = new SourceOfFunds(2, 'CompensationPayment', 'Compensation payment');
  static Gift = new SourceOfFunds(3, 'Gift', 'Gift/Donation');
  static GovernmentBenefits = new SourceOfFunds(4, 'Gov', 'Government benefits');
  static Inheritance = new SourceOfFunds(5, 'Inheritance', 'Inheritance');
  static InvestmentIncome = new SourceOfFunds(6, 'InvestmentIncome', 'Investment income/earnings');
  static RentalIncome = new SourceOfFunds(7, 'RentalIncome', 'Rental income');
  static SalaryWages = new SourceOfFunds(8, 'SalaryWages', 'Salary/Wages');
  static SaleOfAssets = new SourceOfFunds(9, 'SaleOfAssets', 'Sale of assets');
  static SuperannuationPension = new SourceOfFunds(10, 'SuperannuationPension', 'Superannuation/pension');
  static Windfall = new SourceOfFunds(11, 'Windfall', 'Windfall');

  static getArray = (): SourceOfFunds[] => [
    SourceOfFunds.BusinessIncome,
    SourceOfFunds.CompensationPayment,
    SourceOfFunds.Gift,
    SourceOfFunds.GovernmentBenefits,
    SourceOfFunds.Inheritance,
    SourceOfFunds.InvestmentIncome,
    SourceOfFunds.RentalIncome,
    SourceOfFunds.SalaryWages,
    SourceOfFunds.SaleOfAssets,
    SourceOfFunds.SuperannuationPension,
    SourceOfFunds.Windfall,
  ];
}

export class OccupationCategoryType extends Enumeration {
  static getAllCategoryItems = (): OccupationCategoryType[] => {
    return Object.values(OccupationCategoryType).filter((categoryItem) => categoryItem instanceof OccupationCategoryType);
  };
}
export class OccupationCategoryBusinessOwnerType extends OccupationCategoryType {
  static AccommodationAndFoodServices = new OccupationCategoryBusinessOwnerType(1, 'Accommodation and Food Services', 'Accommodation and Food Services');

  static AdministrativeAndSupportServices = new OccupationCategoryBusinessOwnerType(
    2,
    'Administrative and Support Services',
    'Administrative and Support Services'
  );
  static ArmsOrWeaponsManufactureOrDistribution = new OccupationCategoryBusinessOwnerType(
    3,
    'Arms or Weapons Manufacture or Distribution',
    'Arms or Weapons Manufacture or Distribution'
  );
  static ArtsAndRecreationServices = new OccupationCategoryBusinessOwnerType(4, 'Arts and Recreation Services', 'Arts and Recreation Services');

  static BarOrLicensedClub = new OccupationCategoryBusinessOwnerType(5, 'Bar or Licensed Club', 'Bar or Licensed Club');

  static BettingBookmakingGamblingAndGaming = new OccupationCategoryBusinessOwnerType(
    6,
    'Betting, Bookmaking, Gambling and Gaming',
    'Betting, Bookmaking, Gambling and Gaming'
  );

  static CafeAndRestaurant = new OccupationCategoryBusinessOwnerType(7, 'Cafe and Restaurant', 'Cafe and Restaurant');

  static CharityCommunityOrSocialServices = new OccupationCategoryBusinessOwnerType(
    8,
    'Charity Community or Social Services',
    'Charity Community or Social Services'
  );

  static Construction = new OccupationCategoryBusinessOwnerType(9, 'Construction', 'Construction');

  static DigitalCurrencyTraders = new OccupationCategoryBusinessOwnerType(10, 'Digital Currency Traders', 'Digital Currency Traders');

  static EducationAndTraining = new OccupationCategoryBusinessOwnerType(11, 'Education and Training', 'Education and Training');

  static ElectricityGasWaterAndWasteServices = new OccupationCategoryBusinessOwnerType(
    12,
    'Electricity, Gas, Water and Waste Services',
    'Electricity, Gas, Water and Waste Services'
  );

  static FarmingAndAgriculture = new OccupationCategoryBusinessOwnerType(13, 'Farming and Agriculture', 'Farming and Agriculture');

  static FinancialAndInsuranceServices = new OccupationCategoryBusinessOwnerType(14, 'Financial and Insurance Services', 'Financial and Insurance Services');

  static HealthCareAndSocialAssistance = new OccupationCategoryBusinessOwnerType(15, 'Health Care and Social Assistance', 'Health Care and Social Assistance');

  static HotelAndMotel = new OccupationCategoryBusinessOwnerType(16, 'Hotel and Motel', 'Hotel and Motel');

  static InformationMediaAndTelecommunications = new OccupationCategoryBusinessOwnerType(
    17,
    'Information Media and Telecommunications',
    'Information Media and Telecommunications'
  );

  static JewelGemAndPreciousMetals = new OccupationCategoryBusinessOwnerType(18, 'Jewel, Gem and Precious Metals', 'Jewel, Gem and Precious Metals');

  static MiningGasOilAndPetroleum = new OccupationCategoryBusinessOwnerType(19, 'Mining, Gas, Oil and Petroleum', 'Mining, Gas, Oil and Petroleum');

  static MoneyExchangeOrForeignFXServices = new OccupationCategoryBusinessOwnerType(
    20,
    'Money Exchange or Foreign FX Services',
    'Money Exchange or Foreign FX Services'
  );

  static PawnBrokers = new OccupationCategoryBusinessOwnerType(21, 'Pawn Brokers', 'Pawn Brokers');

  static ProfessionalScientificAndTechnicalServices = new OccupationCategoryBusinessOwnerType(
    22,
    'Professional, Scientific and Technical Services',
    'Professional, Scientific and Technical Services'
  );

  static PublicAdministrationAndSafety = new OccupationCategoryBusinessOwnerType(23, 'Public Administration and Safety', 'Public Administration and Safety');

  static RealEstateAgent = new OccupationCategoryBusinessOwnerType(24, 'Real Estate Agent', 'Real Estate Agent');

  static RentalHiringAndRealEstateServices = new OccupationCategoryBusinessOwnerType(
    25,
    'Rental, Hiring and Real Estate Services',
    'Rental, Hiring and Real Estate Services'
  );

  static RetailTrade = new OccupationCategoryBusinessOwnerType(26, 'Retail Trade', 'Retail Trade');

  static TransportPostalAndWarehousing = new OccupationCategoryBusinessOwnerType(27, 'Transport, Postal and Warehousing', 'Transport, Postal and Warehousing');

  static WholesaleTrade = new OccupationCategoryBusinessOwnerType(28, 'Wholesale Trade', 'Wholesale Trade');
}

export class OccupationCategoryChiefExecutivesManagersLegislatorsType extends OccupationCategoryType {
  static AccommodationAndFoodServices = new OccupationCategoryChiefExecutivesManagersLegislatorsType(
    1,
    'Accommodation and Food Services',
    'Accommodation and Food Services'
  );

  static AdministrativeAndSupportServices = new OccupationCategoryChiefExecutivesManagersLegislatorsType(
    2,
    'Administrative and Support Services',
    'Administrative and Support Services'
  );
  static ArmsOrWeaponsManufactureOrDistribution = new OccupationCategoryChiefExecutivesManagersLegislatorsType(
    3,
    'Arms or Weapons Manufacture or Distribution',
    'Arms or Weapons Manufacture or Distribution'
  );

  static ArtsAndRecreationServices = new OccupationCategoryChiefExecutivesManagersLegislatorsType(
    4,
    'Arts and Recreation Services',
    'Arts and Recreation Services'
  );

  static BarOrLicensedClub = new OccupationCategoryChiefExecutivesManagersLegislatorsType(5, 'Bar or Licensed Club', 'Bar or Licensed Club');

  static BettingBookmakingGamblingAndGaming = new OccupationCategoryChiefExecutivesManagersLegislatorsType(
    6,
    'Betting, Bookmaking, Gambling and Gaming',
    'Betting, Bookmaking, Gambling and Gaming'
  );
  static CafeAndRestaurant = new OccupationCategoryChiefExecutivesManagersLegislatorsType(7, 'Cafe and Restaurant', 'Cafe and Restaurant');

  static CharityCommunityOrSocialServices = new OccupationCategoryChiefExecutivesManagersLegislatorsType(
    8,
    'Charity Community or Social Services',
    'Charity Community or Social Services'
  );

  static Construction = new OccupationCategoryChiefExecutivesManagersLegislatorsType(9, 'Construction', 'Construction');

  static DigitalCurrencyTraders = new OccupationCategoryChiefExecutivesManagersLegislatorsType(10, 'Digital Currency Traders', 'Digital Currency Traders');

  static EducationAndTraining = new OccupationCategoryChiefExecutivesManagersLegislatorsType(11, 'Education and Training', 'Education and Training');

  static ElectricityGasWaterAndWasteServices = new OccupationCategoryChiefExecutivesManagersLegislatorsType(
    12,
    'Electricity, Gas, Water and Waste Services',
    'Electricity, Gas, Water and Waste Services'
  );
  static FarmingAndAgriculture = new OccupationCategoryChiefExecutivesManagersLegislatorsType(13, 'Farming and Agriculture', 'Farming and Agriculture');
  static FinancialAndInsuranceServices = new OccupationCategoryChiefExecutivesManagersLegislatorsType(
    14,
    'Financial and Insurance Services',
    'Financial and Insurance Services'
  );
  static HealthCareAndSocialAssistance = new OccupationCategoryChiefExecutivesManagersLegislatorsType(
    15,
    'Health Care and Social Assistance',
    'Health Care and Social Assistance'
  );
  static HotelAndMotel = new OccupationCategoryChiefExecutivesManagersLegislatorsType(16, 'Hotel and Motel', 'Hotel and Motel');
  static InformationMediaAndTelecommunications = new OccupationCategoryChiefExecutivesManagersLegislatorsType(
    17,
    'Information Media and Telecommunications',
    'Information Media and Telecommunications'
  );
  static JewelGemAndPreciousMetals = new OccupationCategoryChiefExecutivesManagersLegislatorsType(
    18,
    'Jewel, Gem and Precious Metals',
    'Jewel, Gem and Precious Metals'
  );
  static MiningGasOilAndPetroleum = new OccupationCategoryChiefExecutivesManagersLegislatorsType(
    19,
    'Mining, Gas, Oil and Petroleum',
    'Mining, Gas, Oil and Petroleum'
  );

  static MoneyExchangeOrForeignFXServices = new OccupationCategoryChiefExecutivesManagersLegislatorsType(
    20,
    'Money Exchange or Foreign FX Services',
    'Money Exchange or Foreign FX Services'
  );

  static PawnBrokers = new OccupationCategoryChiefExecutivesManagersLegislatorsType(21, 'Pawn Brokers', 'Pawn Brokers');

  static ProfessionalScientificAndTechnicalServices = new OccupationCategoryChiefExecutivesManagersLegislatorsType(
    22,
    'Professional, Scientific and Technical Services',
    'Professional, Scientific and Technical Services'
  );

  static PublicAdministrationAndSafety = new OccupationCategoryChiefExecutivesManagersLegislatorsType(
    23,
    'Public Administration and Safety',
    'Public Administration and Safety'
  );

  static RealEstateAgent = new OccupationCategoryChiefExecutivesManagersLegislatorsType(24, 'Real Estate Agent', 'Real Estate Agent');

  static RentalHiringAndRealEstateServices = new OccupationCategoryChiefExecutivesManagersLegislatorsType(
    25,
    'Rental, Hiring and Real Estate Services',
    'Rental, Hiring and Real Estate Services'
  );

  static RetailTrade = new OccupationCategoryChiefExecutivesManagersLegislatorsType(26, 'Retail Trade', 'Retail Trade');

  static TransportPostalAndWarehousing = new OccupationCategoryChiefExecutivesManagersLegislatorsType(
    27,
    'Transport, Postal and Warehousing',
    'Transport, Postal and Warehousing'
  );

  static WholesaleTrade = new OccupationCategoryChiefExecutivesManagersLegislatorsType(28, 'Wholesale Trade', 'Wholesale Trade');
}

export class OccupationCategoryClericalAdministrativeWorkersType extends OccupationCategoryType {
  static AccommodationAndFoodServices = new OccupationCategoryClericalAdministrativeWorkersType(
    1,
    'Clerical and Administrative Workers',
    'Clerical and Administrative Workers'
  );
  static ClericalAndOfficeSupportWorkers = new OccupationCategoryClericalAdministrativeWorkersType(
    2,
    'Clerical and Office Support Workers',
    'Clerical and Office Support Workers'
  );
  static GeneralClericalWorkers = new OccupationCategoryClericalAdministrativeWorkersType(3, 'General Clerical Workers', 'General Clerical Workers');

  static InquiryClerksAndReceptionists = new OccupationCategoryClericalAdministrativeWorkersType(
    4,
    'Inquiry Clerks and Receptionists',
    'Inquiry Clerks and Receptionists'
  );

  static NumericalClerks = new OccupationCategoryClericalAdministrativeWorkersType(5, 'Numerical Clerks', 'Numerical Clerks');

  static OfficeManagersAndProgramAdministrators = new OccupationCategoryClericalAdministrativeWorkersType(
    6,
    'Office Managers and Program Administrators',
    'Office Managers and Program Administrators'
  );
  static PersonalAssistantsAndSecretaries = new OccupationCategoryClericalAdministrativeWorkersType(
    7,
    'Personal Assistants and Secretaries',
    'Personal Assistants and Secretaries'
  );
}

export class OccupationCategoryCommunityPersonalServiceWorkersType extends OccupationCategoryType {
  static CarersAndAides = new OccupationCategoryCommunityPersonalServiceWorkersType(1, 'Carers and Aides', 'Carers and Aides');

  static HealthAndWelfareSupportWorkers = new OccupationCategoryCommunityPersonalServiceWorkersType(
    2,
    'Health and Welfare Support Workers',
    'Health and Welfare Support Workers'
  );

  static HospitalityWorkers = new OccupationCategoryCommunityPersonalServiceWorkersType(3, 'Hospitality Workers', 'Hospitality Workers');

  static ProtectiveServiceWorkers = new OccupationCategoryCommunityPersonalServiceWorkersType(4, 'Protective Service Workers', 'Protective Service Workers');

  static SportsAndPersonalServiceWorkers = new OccupationCategoryCommunityPersonalServiceWorkersType(
    5,
    'Sports and Personal Service Workers',
    'Sports and Personal Service Workers'
  );
}

export class OccupationCategoryEmployeesType extends OccupationCategoryType {
  static AdvertisingPublicRelationsAndSales = new OccupationCategoryEmployeesType(
    1,
    'Advertising, Public Relations and Sales',
    'Advertising, Public Relations and Sales'
  );

  static ConstructionDistributionAndProduction = new OccupationCategoryEmployeesType(
    2,
    'Construction, Distribution and Production',
    'Construction, Distribution and Production'
  );

  static EducationHealthAndWelfareServices = new OccupationCategoryEmployeesType(
    3,
    'Education, Health and Welfare Services',
    'Education, Health and Welfare Services'
  );

  static FarmersAndFarm = new OccupationCategoryEmployeesType(4, 'Farmers and Farm', 'Farmers and Farm');

  static HospitalityRetailAndService = new OccupationCategoryEmployeesType(5, 'Hospitality, Retail and Service', 'Hospitality, Retail and Service');

  static InformationCommunicationTechnology = new OccupationCategoryEmployeesType(
    6,
    'Information & Communication Technology',
    'Information & Communication Technology'
  );
}

export class OccupationCategoryHomemakerType extends OccupationCategoryType {
  static Homemaker = new OccupationCategoryHomemakerType(1, 'Homemaker', 'Homemaker');
}

export class OccupationCategoryLabourersType extends OccupationCategoryType {
  static CleanersAndLaundryWorkers = new OccupationCategoryLabourersType(1, 'Cleaners and Laundry Workers', 'Cleaners and Laundry Workers');

  static ConstructionAndMiningLabourers = new OccupationCategoryLabourersType(2, 'Construction and Mining Labourers', 'Construction and Mining Labourers');

  static FactoryProcessWorkers = new OccupationCategoryLabourersType(3, 'Factory Process Workers', 'Factory Process Workers');

  static FarmForestryAndGardenWorkers = new OccupationCategoryLabourersType(4, 'Farm, Forestry and Garden Workers', 'Farm, Forestry and Garden Workers');

  static FoodPreparationAssistants = new OccupationCategoryLabourersType(5, 'Food Preparation Assistants', 'Food Preparation Assistants');

  static Labourers = new OccupationCategoryLabourersType(6, 'Labourers', 'Labourers');
}

export class OccupationCategoryMachineryOperatorsAndDriversType extends OccupationCategoryType {
  static Machinery = new OccupationCategoryMachineryOperatorsAndDriversType(1, 'Machinery', 'Machinery');

  static MobilePlantOperators = new OccupationCategoryMachineryOperatorsAndDriversType(2, 'Mobile Plant Operators', 'Mobile Plant Operators');

  static RoadAndRailDrivers = new OccupationCategoryMachineryOperatorsAndDriversType(3, 'Road and Rail Drivers', 'Road and Rail Drivers');

  static Storepersons = new OccupationCategoryMachineryOperatorsAndDriversType(4, 'Storepersons', 'Storepersons');
}

export class OccupationCategoryMilitaryType extends OccupationCategoryType {
  static MilitaryEnlisted = new OccupationCategoryMilitaryType(1, 'Military enlisted', 'Military enlisted');

  static MilitaryOfficer = new OccupationCategoryMilitaryType(2, 'Military officer', 'Military officer');
}

export class OccupationCategoryProfessionalsType extends OccupationCategoryType {
  static AccountantsAuditorsAndCompanySecretaries = new OccupationCategoryProfessionalsType(
    1,
    'Accountants, Auditors and Company Secretaries',
    'Accountants, Auditors and Company Secretaries'
  );

  static ArtsAndMediaProfessionals = new OccupationCategoryProfessionalsType(2, 'Arts and Media Professionals', 'Arts and Media Professionals');

  static Aviation = new OccupationCategoryProfessionalsType(3, 'Aviation', 'Aviation');
  static BusinessHumanResourceAndMarketingProfessionals = new OccupationCategoryProfessionalsType(
    4,
    'Business, Human Resource and Marketing Professionals',
    'Business, Human Resource and Marketing Professionals'
  );
  static DesignEngineeringScienceAndTransportProfessionals = new OccupationCategoryProfessionalsType(
    5,
    'Design, Engineering, Science and Transport Professionals',
    'Design, Engineering, Science and Transport Professionals'
  );
  static DoctorVeterinarianHealthProfessionals = new OccupationCategoryProfessionalsType(
    6,
    'Doctor, Veterinarian, Health Professionals',
    'Doctor, Veterinarian, Health Professionals'
  );
  static EducationProfessionals = new OccupationCategoryProfessionalsType(7, 'Education Professionals', 'Education Professionals');
  static FinancialBrokersAndDealersAndInvestmentAdvisers = new OccupationCategoryProfessionalsType(
    8,
    'Financial Brokers and Dealers, and Investment Advisers',
    'Financial Brokers and Dealers, and Investment Advisers'
  );
  static InformationCommunicationTechnologyProfessionals = new OccupationCategoryProfessionalsType(
    9,
    'Information & Communication Technology Professionals',
    'Information & Communication Technology Professionals'
  );
  static LegalSocialAndWelfareProfessionals = new OccupationCategoryProfessionalsType(
    10,
    'Legal, Social and Welfare Professionals',
    'Legal, Social and Welfare Professionals'
  );
  static RealEstateAgent = new OccupationCategoryProfessionalsType(11, 'Real Estate Agent', 'Real Estate Agent');
}

export class OccupationCategoryRetiredType extends OccupationCategoryType {
  static Retired = new OccupationCategoryRetiredType(1, 'Retired', 'Retired');
}

export class OccupationCategorySalesWorkersType extends OccupationCategoryType {
  static SalesAssistantsAndSalespersons = new OccupationCategorySalesWorkersType(1, 'Sales Assistants and Salespersons', 'Sales Assistants and Salespersons');

  static SalesRepresentativesAndAgents = new OccupationCategorySalesWorkersType(2, 'Sales Representatives and Agents', 'Sales Representatives and Agents');

  static SalesSupportWorkers = new OccupationCategorySalesWorkersType(3, 'Sales Support Workers', 'Sales Support Workers');
}

export class OccupationCategoryStudentType extends OccupationCategoryType {
  static Student = new OccupationCategoryStudentType(1, 'Student', 'Student');
}

export class OccupationCategoryTechniciansAndTradesWorkersType extends OccupationCategoryType {
  static AutomotiveAndEngineeringTradesWorkers = new OccupationCategoryTechniciansAndTradesWorkersType(
    1,
    'Automotive and Engineering Trades Workers',
    'Automotive and Engineering Trades Workers'
  );

  static ConstructionTradesWorkers = new OccupationCategoryTechniciansAndTradesWorkersType(2, 'Construction Trades Workers', 'Construction Trades Workers');
  static ElectrotechnologyAndTelecommunicationsTradesWorkers = new OccupationCategoryTechniciansAndTradesWorkersType(
    3,
    'Electrotechnology and Telecommunications Trades Workers',
    'Electrotechnology and Telecommunications Trades Workers'
  );
  static EngineeringAndScienceTechnicians = new OccupationCategoryTechniciansAndTradesWorkersType(
    4,
    'Engineering and Science Technicians',
    'Engineering and Science Technicians'
  );
  static FoodTradesWorkers = new OccupationCategoryTechniciansAndTradesWorkersType(5, 'Food Trades Workers', 'Food Trades Workers');
  static InformationCommunicationTechnologyTechnicians = new OccupationCategoryTechniciansAndTradesWorkersType(
    6,
    'Information & Communication Technology Technicians',
    'Information & Communication Technology Technicians'
  );
  static OtherTechniciansAndTradesWorkers = new OccupationCategoryTechniciansAndTradesWorkersType(
    7,
    'Other Technicians and Trades Workers',
    'Other Technicians and Trades Workers'
  );
  static SkilledAnimalAndHorticulturalWorkers = new OccupationCategoryTechniciansAndTradesWorkersType(
    8,
    'Skilled Animal and Horticultural Workers',
    'Skilled Animal and Horticultural Workers'
  );
}

export class OccupationType extends Enumeration {
  static BusinessOwner = new OccupationType(1, 'Business Owner', 'Business Owner');
  static ChiefExecutivesManagersLegislators = new OccupationType(
    2,
    'Chief Executives, General Managers and Legislators',
    'Chief Executives, General Managers and Legislators'
  );
  static ClericalAdministrativeWorkers = new OccupationType(3, 'Clerical and administrative workers', 'Clerical and administrative workers');
  static CommunityAndPersonalServiceWorkers = new OccupationType(4, 'Community and personal service workers', 'Community and personal service workers');
  static Employees = new OccupationType(5, 'Employees', 'Employees');
  static Homemaker = new OccupationType(6, 'Homemaker', 'Homemaker');
  static Labourers = new OccupationType(7, 'Labourers', 'Labourers');
  static MachineryOperatorsDrivers = new OccupationType(8, 'Machinery Operators Drivers', 'Machinery operators and drivers');
  static Military = new OccupationType(9, 'Military', 'Military');
  static Professionals = new OccupationType(10, 'Professionals', 'Professionals');
  static Retired = new OccupationType(11, 'Retired', 'Retired');
  static SalesWorkers = new OccupationType(12, 'Sales workers', 'Sales workers');
  static Student = new OccupationType(13, 'Student', 'Student');
  static TechniciansTradesWorkers = new OccupationType(14, 'Technicians and trades workers', 'Technicians and trades workers');

  static allOccupationCategoryItems: OccupationCategoryItem[] = [
    {
      name: OccupationType.BusinessOwner.name,
      type: OccupationCategoryBusinessOwnerType,
    },
    {
      name: OccupationType.ChiefExecutivesManagersLegislators.name,
      type: OccupationCategoryChiefExecutivesManagersLegislatorsType,
    },
    {
      name: OccupationType.ClericalAdministrativeWorkers.name,
      type: OccupationCategoryClericalAdministrativeWorkersType,
    },
    {
      name: OccupationType.CommunityAndPersonalServiceWorkers.name,
      type: OccupationCategoryCommunityPersonalServiceWorkersType,
    },
    {
      name: OccupationType.Employees.name,
      type: OccupationCategoryEmployeesType,
    },
    {
      name: OccupationType.Homemaker.name,
      type: OccupationCategoryHomemakerType,
    },
    {
      name: OccupationType.Labourers.name,
      type: OccupationCategoryLabourersType,
    },
    {
      name: OccupationType.MachineryOperatorsDrivers.name,
      type: OccupationCategoryMachineryOperatorsAndDriversType,
    },
    {
      name: OccupationType.Military.name,
      type: OccupationCategoryMilitaryType,
    },
    {
      name: OccupationType.Professionals.name,
      type: OccupationCategoryProfessionalsType,
    },
    {
      name: OccupationType.Retired.name,
      type: OccupationCategoryRetiredType,
    },
    {
      name: OccupationType.SalesWorkers.name,
      type: OccupationCategorySalesWorkersType,
    },
    {
      name: OccupationType.Student.name,
      type: OccupationCategoryStudentType,
    },
    {
      name: OccupationType.TechniciansTradesWorkers.name,
      type: OccupationCategoryTechniciansAndTradesWorkersType,
    },
  ];

  static getById = (id: number): OccupationType | undefined => {
    return Object.values(OccupationType)
      .filter((occupation) => occupation instanceof OccupationType)
      .find((occupation: OccupationType) => occupation.id === id);
  };

  static getCategoryByName = (name: string): OccupationCategoryItem | undefined => {
    return OccupationType.allOccupationCategoryItems.find((item) => item.name.toLowerCase() === name.toLowerCase());
  };
}
