import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../../app/api';
import { fetchStrategyCompositionPayload, StrategyComposition } from './types';

export enum StrategyCompositionActionTypes {
  FetchStrategyComposition = '@@strategy/GetStrategyComposition',
}

export enum StrategyCompositionApiEndpoints {
  fetchStrategyComposition = 'strategies/GetStrategyVersionComposition',
}

export const fetchStrategyComposition = createAsyncThunk(
  StrategyCompositionActionTypes.FetchStrategyComposition,
  async (wrapper: fetchStrategyCompositionPayload) => {
    const response = await api.get<StrategyComposition>(`${StrategyCompositionApiEndpoints.fetchStrategyComposition}?strategyId=${wrapper.strategyId}`);
    return response.data;
  }
);
