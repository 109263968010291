export const formatPercentage = (
  value?: number | null,
  nullReplacer = '0.00%',
  decimalPlace = 2,
  sign = true
): string => {
  let result = nullReplacer;
  if (value && Number.isInteger(decimalPlace) && decimalPlace > 0) {
    value = +(value * 100).toFixed(decimalPlace);
    // fix -0.00 to become 0.00 by adding 0
    if (sign === false) {
      result = (value + 0).toFixed(decimalPlace);
    } else {
      result = (value + 0).toFixed(decimalPlace) + '%';
    }
  }
  return result;
};
