import { connect, ConnectedProps } from 'react-redux';
import { progressSelectorFactory } from '../../../../common';
import { RootState } from '../../../../reducers';
import { selectModelDetail, selectParameters, selectPerformanceDetails } from '../store/selectors';
import { modelDashboardSlice } from '../store/slice';
import { fetchPerformanceDetails, ModelDashboardActionTypes } from '../store/thunks';
import { MonthlyPerformanceChartComponent } from './MonthlyPerformanceChartComponent';

const actionTypes = [ModelDashboardActionTypes.FetchPerformanceDetails];

const mapStateToProps = (state: RootState) => ({
  loadingProgress: progressSelectorFactory(actionTypes)(state),
  parameters: selectParameters(state),
  modelDetail: selectModelDetail(state),
  performanceDetails: selectPerformanceDetails(state),
});

const mapDispatchToProps = {
  fetchPerformanceDetails,
  setChartDateParameter: modelDashboardSlice.actions.setChartDateParameter,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector>;

export const MonthlyPerformanceChartContainer = connect(mapStateToProps, mapDispatchToProps)(MonthlyPerformanceChartComponent);
