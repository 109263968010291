import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React from 'react';
import { DateTimeFormat, LoadingIndicator, formatDollars, getLocalDateTime } from 'src/common';
import { CGTFinancialYearRecord } from '../../store';
import { Props } from '../container';

export const CarriedForwardLosses = ({
  clientCgtRecords,
  fetchFinancialRecordsForClientLoadingProgress,
}: Props): JSX.Element => {
  return (
    <>
      <Typography variant="h3" style={{ paddingBottom: '20px' }}>
        Capital Gains - Carry Forward Losses
      </Typography>
      <Typography variant="h5" style={{ paddingBottom: '30px' }}>
        View carry forward capital losses from prior financial years.
      </Typography>
      <Paper elevation={3} style={{}}>
        <LoadingIndicator progress={fetchFinancialRecordsForClientLoadingProgress}>
          <TableContainer component={Paper} elevation={0}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ backgroundColor: '#eff3f5' }}>
                    <Typography variant="h5" style={{ color: '#7D7D7D' }}>
                      FINANCIAL YEAR ENDING
                    </Typography>
                  </TableCell>
                  <TableCell style={{ backgroundColor: '#eff3f5' }}>
                    <Typography variant="h5" style={{ color: '#7D7D7D' }}>
                      PLATFORM CARRY FORWARD LOSS
                    </Typography>
                  </TableCell>
                  {/* <TableCell style={{ backgroundColor: '#eff3f5' }}>
                    <Typography variant="h5" style={{ color: '#7D7D7D', textAlign: 'right' }}>
                      EXTERNAL CARRY FORWARD LOSS
                    </Typography>
                  </TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {clientCgtRecords.length > 0 &&
                  clientCgtRecords.map((record: CGTFinancialYearRecord, idx: number) => (
                    <TableRow
                      style={{ borderBottom: '1px solid rgb(226, 226, 226)', padding: '20px', paddingBottom: '15px' }}
                      key={idx}
                    >
                      <TableCell>
                        <Typography variant="h5">{`${getLocalDateTime(
                          record?.fyEnd,
                          DateTimeFormat.FinancialDate
                        )}`}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>{formatDollars(record.carryForwardLoss)}</Typography>
                      </TableCell>
                      {/* <TableCell style={{ textAlign: 'right' }}>
                        <Typography>{formatDollars(record.externalLoss)}</Typography>
                      </TableCell> */}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </LoadingIndicator>
      </Paper>
    </>
  );
};
