import { moment } from 'src/common/types';
import { DateRange } from '../../../../store';

export enum Frequency {
  Daily = 'Daily',
  Monthly = 'Monthly',
}

export interface SecurityBenchmark {
  id: number;
  code: string;
  name: string;
}

export interface SecurityPerformanceParameters {
  dates: DateRange;
  frequency: Frequency;
  securityBenchmarks: SecurityBenchmark[] | null;
  securityBenchmarkSelected: SecurityBenchmark | null;
}

export interface PerformanceDataItem {
  date: moment;
  return: number;
  cumulativeReturn: number;
}

export enum SecurityPeriodicSummaryLableNames {
  oneMonth = '1 month',
  threeMonth = '3 months',
  sixMonth = '6 months',
  oneYear = '1 year',
  threeYears = '3 years',
  fiveYears = '5 years',
  inception = 'Inception',
}

export interface SecurityPeriodicSummaryDataItem {
  date: moment;
  label: SecurityPeriodicSummaryLableNames;
  incomeReturn: number;
  capitalReturn: number;
  totalReturn: number;
  benchmarkIncomeReturn: number;
  benchmarkCapitalReturn: number;
  benchmarkTotalReturn: number;
  securityVsBenchmarkIncomeReturn: number;
  securityVsBenchmarkCapitalReturn: number;
  securityVsBenchmarkTotalReturn: number;
}

export interface MonthlyPerformanceChart {
  securityId: number;
  capitalReturn: number;
  incomeReturn: number;
  totalReturn: number;
  bestMonthReturn: number;
  bestMonthDate: moment;
  worstMonthReturn: number;
  worstMonthDate: moment;
  annualVolatility: number;
  seriesData: PerformanceDataItem[];
}

export interface DailyPerformanceChart {
  securityId: number;
  capitalReturn: number;
  incomeReturn: number;
  totalReturn: number;
  bestDayReturn: number;
  bestDayDate: moment;
  worstDayReturn: number;
  worstDayDate: moment;
  annualVolatility: number;
  seriesData: PerformanceDataItem[];
}

export interface SecurityPeriodicSummary {
  securityId: number;
  strategyId: number;
  seriesData: SecurityPeriodicSummaryDataItem[];
}

export interface BenchmarkPerformanceDataItem {
  date: moment;
  cumulativeReturn: number;
}

export interface MonthlyBenchmarkPerformance {
  strategyId: number;
  seriesData: BenchmarkPerformanceDataItem[];
}

export interface DailyBenchmarkPerformance {
  strategyId: number;
  seriesData: BenchmarkPerformanceDataItem[];
}

export interface SecurityPerformanceState {
  parameters: SecurityPerformanceParameters;
  monthlyPerformanceChart: MonthlyPerformanceChart | null;
  dailyPerformanceChart: DailyPerformanceChart | null;
  securityPeriodicSummary: SecurityPeriodicSummary | null;
  monthlyBenchmarkPerformance: MonthlyBenchmarkPerformance | null;
  dailyBenchmarkPerformance: DailyBenchmarkPerformance | null;
}

export interface PerformanceChartThunkParameters {
  securityId: number;
  fromDate: string;
  toDate: string;
}

export interface SecurityPeriodicSummaryThunkParameters {
  securityId: number;
  strategyId: number | null;
  endDate: string;
}

export interface SecurityBenchmarksThunkParameters {
  securityId: number;
}

export interface BenchmarkPerformanceThunkParameters {
  strategyId: number;
  startDate: string;
  endDate: string;
}
