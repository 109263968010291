import React from 'react'; // eslint-disable-line
import { datePickerDateToLuxonDate } from 'src/common/utils';
import {
  TypesItem,
  LicenceCardNumberLength,
  SaveIdentificationDetails,
  GreenidStatus,
  HelpDoc,
} from '../../store/types';
import {
  IdentificationDocType,
  AustralianState,
  Countries,
  GreenIdStatusEnum,
  MedicareCardColour,
  CentrelinkCardType,
} from '../../store/enums';
import dl_nsw from './images/nsw_driver_licence.svg';
import dl_vic from './images/vic_driver_licence_front_verify_identity.svg';
import dl_vic_back from './images/vic_driver_licence_back_verify_identity.svg';
import dl_wa from './images/dl_wa.svg';
import dl_wa_back from './images/dl_wa_back.svg';
import dl_tas from './images/dl_tas.svg';
import dl_tas_back from './images/dl_tas_back.svg';
import dl_sa from './images/dl_sa.svg';
import dl_sa_back from './images/dl_sa_back.svg';
import dl_qld from './images/dl_qld.svg';
import dl_qld_back from './images/dl_qld_back.svg';
import dl_nt from './images/dl_nt.svg';
import dl_nt_back from './images/dl_nt_back.svg';
import dl_act from './images/dl_act.svg';
import australia_passport from './images/australia_passport.svg';
import medicare_green from './images/medicare_green_card_2_irns.svg';
import medicare_blue from './images/medicare_blue_card_2_irns.svg';
import medicare_yellow from './images/medicare_yellow_card_2_irns.svg';
import citizenship_cert_pre_Jan_2020 from './images/sample_citizenship_certificate_pre_January_2020.png';
import citizenship_cert_pre_Jan_2020_back from './images/sample_citizenship_certificate_pre_January_2020_back.png';
import citizenship_cert_post_Jan_2020 from './images/sample_citizenship_certificate_post_January_2020.png';
import citizenship_cert_post_Jan_2020_back from './images/sample_citizenship_certificate_post_January_2020_back.png';
import birth_cert_act from './images/act_birth_certificate_sample.png';
import birth_cert_nt from './images/northern_territory_birth_certificate_sample.png';
import birth_cert_nsw from './images/nsw_birth_certificate_sample.png';
import birth_cert_nsw_back from './images/nsw_birth_certificate_back_sample.png';
import birth_cert_qld from './images/qld_birth_certificate_sample.png';
import birth_cert_sa from './images/sa_birth_certificate_sample.png';
import birth_cert_tas from './images/tas_birth_certificate_sample.png';
import birth_cert_vic from './images/vic_birth_certificate_sample.png';
import birth_cert_vic_back from './images/vic_birth_certificate_back_sample.png';
import birth_cert_wa from './images/wa_birth_certificate_sample.png';
import health_card_pensioner from './images/pensioner_concession_card.svg';
import health_card_health_care_card from './images/health_care_card.svg';
import health_card_commonwealth_senior from './images/commonwealth_senior_health_card.svg';

export const identificationTypes: TypesItem[] = [
  {
    uiTypeId: 1,
    apiTypeId: 5,
    uiTypeName: 'Australian Citizenship Certificate',
    apiTypeName: 'Australian Citizenship Certificate ',
  },
  {
    uiTypeId: 2,
    apiTypeId: 3,
    uiTypeName: 'Australian Passport',
    apiTypeName: 'Australian Passport',
  },
  {
    uiTypeId: 3,
    apiTypeId: 7,
    uiTypeName: 'Australian Birth Certificate',
    apiTypeName: 'Birth Certificate Australian or Foreign',
  },
  {
    uiTypeId: 4,
    apiTypeId: 9,
    uiTypeName: 'Centrelink Concession Card',
    apiTypeName: 'Centrelink Pension Card ',
  },
  {
    uiTypeId: 5,
    apiTypeId: 8,
    uiTypeName: 'Medicare Card',
    apiTypeName: 'Medicare Card',
  },
  {
    uiTypeId: 6,
    apiTypeId: 12,
    uiTypeName: 'Foreign Passport',
    apiTypeName: 'Current Foreign Passport ',
  },
  {
    uiTypeId: 7,
    apiTypeId: 10,
    uiTypeName: 'Australian Driver Licence',
    apiTypeName: 'Current Australian Drivers Licence ',
  },
  {
    uiTypeId: 8,
    apiTypeId: 72,
    uiTypeName: 'Identity Verification Certificate',
    apiTypeName: 'Identity Verification Certificate ',
  },
];

export const isNumericVal = (input: string) => {
  if (typeof input != 'string') return false;
  return !isNaN(Number(input)) &&
    !isNaN(parseFloat(input));
};

export const isNumeric = (input: string) => {
  if (!/^[0_9]+$/.test(input)) {
    return false;
  } else {
    return true;
  }
};

export const licenceCardNumberLength = (state: number) => {
  let returnVal: LicenceCardNumberLength = {
    min: 10,
    max: 10,
    error: 'Enter a driver licence card number',
  };

  switch (state) {
    case AustralianState.getByName('ACT')?.id ?? 1:
      returnVal = {
        min: 10,
        max: 10,
        error: 'Enter a Driver Licence Card Number equal to 10 alphanumeric characters',
        isValid: (value) => !isSpecialCharacters(value ?? '') && isAlphaNumeric(value ?? ''),
      };
      break;
    case AustralianState.getByName('NSW')?.id ?? 2:
      returnVal = {
        min: 10,
        max: 10,
        error: 'Enter a Driver Licence Card Number equal to 10 digits',
        isValid: (value) => !isSpecialCharacters(value ?? '') && isNumericVal(value ?? ''),
      };
      break;
    case AustralianState.getByName('NT')?.id ?? 3:
      returnVal = {
        min: 6,
        max: 8,
        error: 'Enter a Driver Licence Card Number that is between 6 and 8 digits',
        isValid: (value) => !isSpecialCharacters(value ?? '') && isNumericVal(value ?? ''),
      };
      break;
    case AustralianState.getByName('SA')?.id ?? 4:
      returnVal = {
        min: 9,
        max: 9,
        error: 'Enter a Driver Licence Card number equal to 9 alphanumeric characters',
        isValid: (value) => !isSpecialCharacters(value ?? '') && isAlphaNumeric(value ?? ''),
      };
      break;
    case AustralianState.getByName('QLD')?.id ?? 5:
      returnVal = {
        min: 10,
        max: 10,
        error: 'Enter a Driver Licence Card Number equal to 10 alphanumeric characters',
        isValid: (value) => !isSpecialCharacters(value ?? '') && isAlphaNumeric(value ?? ''),
      };
      break;
    case AustralianState.getByName('TAS')?.id ?? 6:
      returnVal = {
        min: 9,
        max: 9,
        error: 'Enter a Driver Licence Card number equal to 9 alphanumeric characters',
        isValid: (value) => !isSpecialCharacters(value ?? '') && isAlphaNumeric(value ?? ''),
      };
      break;
    case AustralianState.getByName('VIC')?.id ?? 7:
      returnVal = {
        min: 8,
        max: 8,
        error: 'Enter a Driver Licence Card number equal to 8 alphanumeric characters',
        isValid: (value) => !isSpecialCharacters(value ?? '') && isAlphaNumeric(value ?? ''),
      };
      break;
    case AustralianState.getByName('WA')?.id ?? 8:
      returnVal = {
        min: 8,
        max: 10,
        error: 'Enter a Driver Licence Card Number that is between 8 and 10 alphanumeric characters',
        isValid: (value) => !isSpecialCharacters(value ?? '') && isAlphaNumeric(value ?? ''),
      };
      break;
    default:
      returnVal = {
        min: 10,
        max: 10,
        error: 'Enter a driver licence card number',
      };
      break;
  }

  return returnVal;
};

export const certificateNumberRequired = (selectedState: number, birthCertificatePrintedDate: string) => {
  const nswBeginingDate = datePickerDateToLuxonDate('2010_07_01T00:00');
  const saBeginingDate = datePickerDateToLuxonDate('1999_11_01T00:00');
  if (selectedState === AustralianState.getByName('NSW')?.id) {
    return datePickerDateToLuxonDate(birthCertificatePrintedDate).startOf('day') >= nswBeginingDate.startOf('day');
  } else if (selectedState === AustralianState.getByName('SA')?.id) {
    return datePickerDateToLuxonDate(birthCertificatePrintedDate).startOf('day') >= saBeginingDate.startOf('day');
  } else {
    return false;
  }
};

export const certificateNumberValidate = (
  identificationDocType: number,
  registrationState: number,
  certificatePrintedDate?: string
) => {
  if (identificationDocType === IdentificationDocType.getByName('AustralianBirthCertificate')?.id) {
    if (
      registrationState === AustralianState.getByName('ACT')?.id ||
      registrationState === AustralianState.getByName('NT')?.id
    ) {
      return true;
    } else if (!certificatePrintedDate || certificatePrintedDate === 'Invalid Date') {
      return true;
    } else if (registrationState === AustralianState.getByName('NSW')?.id) {
      if (!!certificatePrintedDate && certificateNumberRequired(registrationState, certificatePrintedDate)) {
        return true;
      } else {
        return false;
      }
    } else if (registrationState === AustralianState.getByName('SA')?.id) {
      if (!!certificatePrintedDate && certificateNumberRequired(registrationState, certificatePrintedDate)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
};

export const uiVerifiedOnDate = (typeId: number, document: SaveIdentificationDetails) => {
  if (getUiDocTypeId(typeId as number) === IdentificationDocType.getByName('IdentityVerificationCertificate')?.id) {
    return document.verifiedOn;
  }
}

export const getUiDocTypeId = (apiTypeId: number) => {
  const uiTypeId = identificationTypes.find((typeCollection) => typeCollection.apiTypeId === apiTypeId)?.uiTypeId;
  return uiTypeId ?? null;
};

export const uiIssueDate = (typeId: number, document: SaveIdentificationDetails) => {
  if (getUiDocTypeId(typeId as number) === IdentificationDocType.getByName('AustralianCitizenshipCertificate')?.id) {
    return document.issueDate;
  }
  if (getUiDocTypeId(typeId as number) === IdentificationDocType.getByName('AustralianPassport')?.id) {
    return document.issueDate;
  }
  if (getUiDocTypeId(typeId as number) === IdentificationDocType.getByName('AustralianBirthCertificate')?.id) {
    return document.issueDate;
  }
  if (getUiDocTypeId(typeId as number) === IdentificationDocType.getByName('ForeignPassport')?.id) {
    return document.issueDate;
  }
  return '';
};

export const uiExpiryDate = (typeId: number, document: SaveIdentificationDetails) => {
  if (getUiDocTypeId(typeId as number) === IdentificationDocType.getByName('AustralianBirthCertificate')?.id) {
    return document.expiryDate;
  }
  if (getUiDocTypeId(typeId as number) === IdentificationDocType.getByName('AustralianPassport')?.id) {
    return document.expiryDate;
  }
  if (getUiDocTypeId(typeId as number) === IdentificationDocType.getByName('CentrelinkConcessionCard')?.id) {
    return document.expiryDate;
  }
  if (getUiDocTypeId(typeId as number) === IdentificationDocType.getByName('MedicareCard')?.id) {
    return document.expiryDate;
  }
  if (getUiDocTypeId(typeId as number) === IdentificationDocType.getByName('AustralianDriverLicence')?.id) {
    return document.expiryDate;
  }
  if (getUiDocTypeId(typeId as number) === IdentificationDocType.getByName('ForeignPassport')?.id) {
    return document.expiryDate;
  }
  return '';
};

export const uiPlaceOfIssue = (typeId: number | null, document: SaveIdentificationDetails) => {
  if (getUiDocTypeId(typeId as number) === IdentificationDocType.getByName('AustralianBirthCertificate')?.id) {
    return AustralianState.getByName(document.placeOfIssue)?.id;
  }
  if (getUiDocTypeId(typeId as number) === IdentificationDocType.getByName('ForeignPassport')?.id) {
    return Countries.getByName(document.placeOfIssue)?.id ?? null;
  }
  return null;
};

export const uiNumber = (typeId: number, document: SaveIdentificationDetails) => {
  if (getUiDocTypeId(typeId as number) === IdentificationDocType.getByName('AustralianBirthCertificate')?.id) {
    return document.registrationNumber;
  }
  return '';
};

export const uiBirthCertificateNumber = (typeId: number, document: SaveIdentificationDetails) => {
  if (getUiDocTypeId(typeId as number) === IdentificationDocType.getByName('AustralianBirthCertificate')?.id) {
    if (document.birthCertificateNumber) {
      return document.birthCertificateNumber;
    } else if (document.birthCertificate) {
      return document.birthCertificate;
    } else if (document.certificateNumber) {
      return document.certificateNumber;
    }
  }
  return '';
};

export const uiRegistrationNumber = (typeId: number, document: SaveIdentificationDetails) => {
  if (getUiDocTypeId(typeId as number) === IdentificationDocType.getByName('AustralianBirthCertificate')?.id) {
    return document.number;
  }
  return '';
};

export const isAlphaNumeric = (str: string) => {
  let code, i, len;

  for (i = 0, len = str.length; i < len; i++) {
    code = str.charCodeAt(i);
    if (!(code > 47 && code < 58) && // numeric (0-9)
        !(code > 64 && code < 91) && // upper alpha (A-Z)
        !(code > 96 && code < 123)) { // lower alpha (a-z)
      return false;
    }
  }
  return true;
};

export const isSpecialCharacters = (input: string) => {
  if (!/[ `!@#$%^&*()_+\_=\[\]{};':"\\|,.<>\/?~]/.test(input)) {
    return false;
  } else {
    return true;
  }
};

export const isAlphaCharacters = (str: string) => {
  let code, i, len;

  for (i = 0, len = str.length; i < len; i++) {
    code = str.charCodeAt(i);
    if (!(code > 64 && code < 91) && // upper alpha (A-Z)
        !(code > 96 && code < 123)) { // lower alpha (a-z)
      return false;
    }
  }
  return true;
};

export const isValidAusPassportNo = (input: string) => {
  if ((input.length === 8 || input.length === 9) && isNumericVal(input.substring(input.length - 7))) {
    if (isAlphaCharacters(input.substring(0, 1)) && isNumericVal(input.substring(2, 3))) {
      return true;
    } else if (isAlphaCharacters(input.substring(0, 2))) {
      return true;
    } else {
      return false;
    }
  }
  return false;
};

export const isValidCentrelinkRefNum = (input: string) => {
  if (input.length === 10 && isAlphaNumeric(input)) {
    if (isAlphaCharacters(input.substring(9, 10)) && isNumericVal(input.substring(0, 9))) {
      return true;
    } else {
      return false;
    }
  }
  return false;
};

export const isSubmitToDvsDisabled = (values: GreenidStatus) => {
  if (
    values.dvsConsentReceived ||
    (values.greenIdVerificationStatus === GreenIdStatusEnum.getByName('UNVERIFIED')?.id ||
      values.greenIdVerificationStatus === GreenIdStatusEnum.getByName('IN_PROGRESS')?.id ||
      values.greenIdVerificationStatus === GreenIdStatusEnum.getByName('PENDING')?.id ||
      values.greenIdVerificationStatus === GreenIdStatusEnum.getByName('LOCKED_OUT')?.id)
  ) {
    return false;
  } else {
    return true;
  }
};

export const tool_tip_reference = (docType: number | null, docSubtype: number | null) => {
  let helpDoc: HelpDoc;
  switch (docType) {
    case IdentificationDocType.getByName('AustralianDriverLicence')?.id:
      switch (docSubtype) {
        case AustralianState.getByName('NSW')?.id:
          helpDoc = {
            img1: dl_nsw,
            img2: null,
            img3: null,
            img4: null,
            maxWidth: 'md',
          };
          break;
        case AustralianState.getByName('VIC')?.id:
          helpDoc = {
            img1: dl_vic,
            img2: dl_vic_back,
            img3: null,
            img4: null,
            maxWidth: 'sm',
          };
          break;
        case AustralianState.getByName('WA')?.id:
          helpDoc = {
            img1: dl_wa,
            img2: dl_wa_back,
            img3: null,
            img4: null,
            maxWidth: 'md',
          };
          break;
        case AustralianState.getByName('TAS')?.id:
          helpDoc = {
            img1: dl_tas,
            img2: dl_tas_back,
            img3: null,
            img4: null,
            maxWidth: 'md',
          };
          break;
        case AustralianState.getByName('SA')?.id:
          helpDoc = {
            img1: dl_sa,
            img2: dl_sa_back,
            img3: null,
            img4: null,
            maxWidth: 'md',
          };
          break;
        case AustralianState.getByName('QLD')?.id:
          helpDoc = {
            img1: dl_qld,
            img2: dl_qld_back,
            img3: null,
            img4: null,
            maxWidth: 'md',
          };
          break;
        case AustralianState.getByName('NT')?.id:
          helpDoc = {
            img1: dl_nt,
            img2: dl_nt_back,
            img3: null,
            img4: null,
            maxWidth: 'md',
          };
          break;
        case AustralianState.getByName('ACT')?.id:
          helpDoc = {
            img1: dl_act,
            img2: null,
            img3: null,
            img4: null,
            maxWidth: 'md',
          };
          break;
        default:
          helpDoc = {
            img1: dl_nsw,
            img2: null,
            img3: null,
            img4: null,
            maxWidth: 'md',
          };
      }
      break;

    case IdentificationDocType.getByName('MedicareCard')?.id:
      switch (docSubtype) {
        case MedicareCardColour.getByName('G')?.id:
          helpDoc = {
            img1: medicare_green,
            img2: null,
            img3: null,
            img4: null,
            maxWidth: 'md',
          };
          break;
        case MedicareCardColour.getByName('Y')?.id:
          helpDoc = {
            img1: medicare_yellow,
            img2: null,
            img3: null,
            img4: null,
            maxWidth: 'md',
          };
          break;
        case MedicareCardColour.getByName('B')?.id:
          helpDoc = {
            img1: medicare_blue,
            img2: null,
            img3: null,
            img4: null,
            maxWidth: 'md',
          };
          break;
        default:
          helpDoc = {
            img1: medicare_green,
            img2: null,
            img3: null,
            img4: null,
            maxWidth: 'md',
          };
      }
      break;

    case IdentificationDocType.getByName('AustralianPassport')?.id:
      helpDoc = {
        img1: australia_passport,
        img2: null,
        img3: null,
        img4: null,
        maxWidth: 'md',
      };
      break;
    default:
      helpDoc = {
        img1: australia_passport,
        img2: null,
        img3: null,
        img4: null,
        maxWidth: 'md',
      };
      break;
    case IdentificationDocType.getByName('AustralianCitizenshipCertificate')?.id:
      switch (docSubtype) {
        case MedicareCardColour.getByName('G')?.id:
          helpDoc = {
            img1: citizenship_cert_pre_Jan_2020,
            img2: citizenship_cert_pre_Jan_2020_back,
            img3: citizenship_cert_post_Jan_2020,
            img4: citizenship_cert_post_Jan_2020_back,
            maxWidth: 'xl',
          };
          break;
        default:
          helpDoc = {
            img1: citizenship_cert_pre_Jan_2020,
            img2: citizenship_cert_pre_Jan_2020_back,
            img3: citizenship_cert_post_Jan_2020,
            img4: citizenship_cert_post_Jan_2020_back,
            maxWidth: 'xl',
          };
      }
      break;
    case IdentificationDocType.getByName('AustralianBirthCertificate')?.id:
      switch (docSubtype) {
        case AustralianState.getByName('NSW')?.id:
          helpDoc = {
            img1: birth_cert_nsw,
            img2: birth_cert_nsw_back,
            img3: null,
            img4: null,
            maxWidth: 'xl',
          };
          break;
        case AustralianState.getByName('ACT')?.id:
          helpDoc = {
            img1: birth_cert_act,
            img2: null,
            img3: null,
            img4: null,
            maxWidth: 'xl',
          };
          break;
        case AustralianState.getByName('NT')?.id:
          helpDoc = {
            img1: birth_cert_nt,
            img2: null,
            img3: null,
            img4: null,
            maxWidth: 'xl',
          };
          break;
        case AustralianState.getByName('QLD')?.id:
          helpDoc = {
            img1: birth_cert_qld,
            img2: null,
            img3: null,
            img4: null,
            maxWidth: 'xl',
          };
          break;
        case AustralianState.getByName('SA')?.id:
          helpDoc = {
            img1: birth_cert_sa,
            img2: null,
            img3: null,
            img4: null,
            maxWidth: 'xl',
          };
          break;
        case AustralianState.getByName('TAS')?.id:
          helpDoc = {
            img1: birth_cert_tas,
            img2: null,
            img3: null,
            img4: null,
            maxWidth: 'xl',
          };
          break;
        case AustralianState.getByName('VIC')?.id:
          helpDoc = {
            img1: birth_cert_vic,
            img2: birth_cert_vic_back,
            img3: null,
            img4: null,
            maxWidth: 'xl',
          };
          break;
        case AustralianState.getByName('WA')?.id:
          helpDoc = {
            img1: birth_cert_wa,
            img2: null,
            img3: null,
            img4: null,
            maxWidth: 'xl',
          };
          break;

        default:
          helpDoc = {
            img1: birth_cert_nsw,
            img2: birth_cert_nsw_back,
            img3: null,
            img4: null,
            maxWidth: 'xl',
          };
      }
      break;
    case IdentificationDocType.getByName('CentrelinkConcessionCard')?.id: {
      switch (docSubtype) {
        case CentrelinkCardType.getByName('PCC')?.id:
          helpDoc = {
            img1: health_card_pensioner,
            img2: null,
            img3: null,
            img4: null,
            maxWidth: 'md',
          };
          break;
        case CentrelinkCardType.getByName('HCC')?.id:
          helpDoc = {
            img1: health_card_health_care_card,
            img2: null,
            img3: null,
            img4: null,
            maxWidth: 'md',
          };
          break;
        case CentrelinkCardType.getByName('SHC')?.id:
          helpDoc = {
            img1: health_card_commonwealth_senior,
            img2: null,
            img3: null,
            img4: null,
            maxWidth: 'md',
          };
          break;

        default:
          helpDoc = {
            img1: null,
            img2: null,
            img3: null,
            img4: null,
            maxWidth: 'md',
          };
      }
      break;
    }
  }
  return helpDoc;
};

export const getSubtype = (values: SaveIdentificationDetails) => {
  let returnSubtype: number | null = null;
  switch (values.identificationDocType) {
    case IdentificationDocType.getByName('AustralianDriverLicence')?.id:
      returnSubtype = values.issueStateOrTerritoryId ?? 1;
      break;
    case IdentificationDocType.getByName('MedicareCard')?.id:
      returnSubtype = values.medicareCardColourTypeId ?? 1;
      break;
    case IdentificationDocType.getByName('AustralianPassport')?.id:
      returnSubtype = null;
      break;
    case IdentificationDocType.getByName('AustralianCitizenshipCertificate')?.id: {
      returnSubtype = null;
      break;
    }
    case IdentificationDocType.getByName('AustralianBirthCertificate')?.id: {
      returnSubtype = values.registrationState ?? 1;
      break;
    }
    case IdentificationDocType.getByName('CentrelinkConcessionCard')?.id: {
      returnSubtype = values.centrelinkCardTypeId;
      break;
    }
  }
  return returnSubtype;
};

export const viewSampleDisabled = (values: SaveIdentificationDetails) => {
  if (!values.identificationDocType) {
    return true;
  }
  if (
    values.identificationDocType === IdentificationDocType.getByName('AustralianDriverLicence')?.id &&
    !values.issueStateOrTerritoryId
  ) {
    return true;
  }
  if (
    values.identificationDocType === IdentificationDocType.getByName('AustralianBirthCertificate')?.id &&
    !values.registrationState
  ) {
    return true;
  }
  if (
    values.identificationDocType === IdentificationDocType.getByName('MedicareCard')?.id &&
    !values.medicareCardColourTypeId
  ) {
    return true;
  }
  if (values.identificationDocType === IdentificationDocType.getByName('ForeignPassport')?.id) {
    return true;
  }
  if (values.identificationDocType === IdentificationDocType.getByName('IdentityVerificationCertificate')?.id) {
    return true;
  }
  return false;
};
