import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AttachableContact, ContactRoles } from 'src/features/clients/common/types';
import { FetchPagedResults } from '../../../../../../store';
import { EmailType, PhoneNumberType } from '../../../common/store/enums';
import {
  AddressDetails,
  ContactDetail,
  ContactDetails,
  ContactsState,
  DocumentDetails,
  DocumentsParameters,
  EmailAddress,
  PhoneNumber,
} from '../../../common/store/types';
import {
  fetchAddresses,
  fetchContact,
  fetchContactDetails,
  fetchContacts,
  fetchContactsToAttach,
  fetchDocumentForEdit,
  fetchDocuments,
  fetchIdentificationForEdit,
  fetchIdentifications,
  saveAddress,
} from './thunks';

export const initialState: ContactsState = {
  items: [],
  edit: {
    contact: { edit: undefined, attachContactList: [] },
    addresses: {
      items: [],
      edit: undefined,
    },
    documents: {
      parameters: {
        pagination: {
          pageNumber: 1,
          pageSize: 2,
          queryFields: [],
        },
      },
      results: {
        items: {
          pageNumber: 1,
          pageSize: 2,
          totalNumberOfPages: 1,
          totalNumberOfRecords: 0,
          results: [],
        },
        edit: undefined,
      },
    },
    identifications: {
      parameters: {
        pagination: {
          pageNumber: 1,
          pageSize: 2,
          queryFields: [],
        },
      },
      results: {
        items: {
          pageNumber: 1,
          pageSize: 2,
          totalNumberOfPages: 1,
          totalNumberOfRecords: 0,
          results: [],
        },
        edit: undefined,
      },
    },
    phoneNumbers: {
      items: [],
      edit: undefined,
    },
    emailAddresses: {
      items: [],
      edit: undefined,
    },
  },
};

export const contactsSlice = createSlice({
  name: '@clients/client/details/contacts',
  initialState,
  reducers: {
    setEdit: (state: ContactsState, action: PayloadAction<ContactDetails>) => {
      state.edit.contact.edit = action.payload;
    },
    clearEdit: (state: ContactsState) => {
      state.edit = initialState.edit;
    },
    setAddressEdit: (state, action: PayloadAction<number | null>) => {
      state.edit.addresses.edit = action.payload;
    },
    clearAddressEdit: (state) => {
      state.edit.addresses.edit = undefined;
    },

    setEditDocumentsParameters: (state: ContactsState, action: PayloadAction<DocumentsParameters>) => {
      state.edit.documents = { parameters: action.payload, results: initialState.edit.documents.results };
    },
    setDocumentAddMode: (state) => {
      state.edit.documents.results.edit = null;
    },
    cancelDocumentAddEditMode: (state) => {
      state.edit.documents.results.edit = undefined;
    },

    setEditIdentificationsParameters: (state: ContactsState, action: PayloadAction<DocumentsParameters>) => {
      state.edit.identifications = { parameters: action.payload, results: initialState.edit.identifications.results };
    },
    setIdentificationAddMode: (state) => {
      state.edit.identifications.results.edit = null;
    },
    cancelIdentificationAddEditMode: (state) => {
      state.edit.identifications.results.edit = undefined;
    },

    setPhoneNumberEditId: (state: ContactsState, action: PayloadAction<number | null | undefined>) => {
      state.edit.phoneNumbers.edit = action.payload;
      // if we have a 'Create new item' row (id = null), then remove it.
      state.edit.phoneNumbers.items = state.edit.phoneNumbers.items.filter((p) => !!p.id);
    },
    setPhoneNumberAdd: (state: ContactsState, action: PayloadAction<PhoneNumber>) => {
      state.edit.phoneNumbers.edit = action.payload.id;
      state.edit.phoneNumbers.items = [action.payload, ...state.edit.phoneNumbers.items];
    },
    setEmailAddressEditId: (state: ContactsState, action: PayloadAction<number | null | undefined>) => {
      state.edit.emailAddresses.edit = action.payload;
      // if we have a 'Create new item' row (id = null), then remove it.
      state.edit.emailAddresses.items = state.edit.emailAddresses.items.filter((p) => !!p.id);
    },
    setEmailAddressAdd: (state: ContactsState, action: PayloadAction<EmailAddress>) => {
      state.edit.emailAddresses.edit = action.payload.id;
      state.edit.emailAddresses.items = [action.payload, ...state.edit.emailAddresses.items];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchContacts.fulfilled, (state, action: PayloadAction<ContactRoles[]>) => {
      state.items = action.payload;
      state.edit = initialState.edit;
    });
    builder.addCase(fetchContact.pending, (state) => {
      state.edit = initialState.edit;
    });
    builder.addCase(fetchContact.fulfilled, (state, action: PayloadAction<ContactDetails>) => {
      if (!!state.edit) {
        state.edit.contact.edit = action.payload;
      }
    });
    builder.addCase(fetchContactsToAttach.fulfilled, (state, action: PayloadAction<AttachableContact[]>) => {
      state.edit.contact.attachContactList = action.payload;
    });
    builder.addCase(fetchAddresses.fulfilled, (state, action: PayloadAction<AddressDetails[]>) => {
      if (!!state.edit) {
        state.edit.addresses = { items: action.payload };
      }
    });
    builder.addCase(fetchDocuments.fulfilled, (state, action: PayloadAction<FetchPagedResults<DocumentDetails>>) => {
      if (!!state.edit.documents) {
        state.edit.documents.parameters.pagination = action.payload.pagination;
        state.edit.documents.results.items = action.payload.results;
      }
    });
    builder.addCase(fetchDocumentForEdit.fulfilled, (state, action: PayloadAction<DocumentDetails>) => {
      state.edit.documents.results.edit = action.payload;
    });
    builder.addCase(
      fetchIdentifications.fulfilled,
      (state, action: PayloadAction<FetchPagedResults<DocumentDetails>>) => {
        if (!!state.edit.identifications) {
          state.edit.identifications.parameters.pagination = action.payload.pagination;
          state.edit.identifications.results.items = action.payload.results;
        }
      }
    );
    builder.addCase(fetchIdentificationForEdit.fulfilled, (state, action: PayloadAction<DocumentDetails>) => {
      state.edit.identifications.results.edit = action.payload;
    });
    builder.addCase(fetchContactDetails.fulfilled, (state, action: PayloadAction<ContactDetail[]>) => {
      state.edit.phoneNumbers.items = action.payload
        .filter((i) => !!PhoneNumberType.getById(i.contactDetailTypeId))
        .map((i) => {
          return {
            id: i.id,
            typeId: i.contactDetailTypeId,
            countryCode: i.countryCode,
            areaCode: i.areaCode,
            phoneNumber: i.phoneEmail,
            preferred: i.preferred,
          };
        });

      state.edit.emailAddresses.items = action.payload
        .filter((i) => !!EmailType.getById(i.contactDetailTypeId))
        .map((i) => {
          return {
            id: i.id,
            typeId: i.contactDetailTypeId,
            emailAddress: i.phoneEmail,
            preferred: i.preferred,
            clientPortalAccess: i.clientPortalAccess ?? false,
            hasMoneysoftAccess: !i.clientPortalAccess ? false : i.hasMoneysoftAccess ?? false,
          };
        });
    });
    builder.addCase(saveAddress.fulfilled, (state) => {
      state.edit.addresses.edit = undefined;
    });
  },
});
