import AttachmentIcon from '@mui/icons-material/Attachment';
import {
  Backdrop,
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Fade,
  Grid,
  IconButton,
  Modal,
  Paper
} from '@mui/material';

import ImageIcon from '@mui/icons-material/Image';
import CloseIcon from '@mui/icons-material/Close';
import { Field, Form, Formik, FormikProps } from 'formik';

import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import { LoadingProgress } from 'src/common/store/types';
import * as yup from 'yup';
import { TestContext } from 'yup';
import {
  EditCancelSaveButtons,
  FormikEnumerationSelect,
  FormikKeyboardDatePicker,
  FormikTextField,
  Mode,
} from '../../../../../../common/components/formik';
import { toBase64 } from '../../../../../../common/utils';
import { useStyles } from '../../../../../../themes/index';

import {
  DocumentType,
  SaveIdentificationDetails,
  LicenceCardNumberLength,
  GreenidStatus,
} from '../../store/types';
import { IdentificationDocType, AustralianState, CentrelinkCardType, MedicareCardColour, Countries } from '../../store/enums';
import {
  licenceCardNumberLength,
  certificateNumberValidate,
  getUiDocTypeId,
  uiIssueDate,
  uiExpiryDate,
  uiNumber,
  uiBirthCertificateNumber,
  uiRegistrationNumber,
  isSpecialCharacters,
  isValidAusPassportNo,
  isValidCentrelinkRefNum,
  isNumericVal,
  isAlphaNumeric,
  isSubmitToDvsDisabled,
  tool_tip_reference,
  getSubtype,
  viewSampleDisabled,
  uiVerifiedOnDate
} from 'src/features/clients/client/common/components/identifications/common';

export interface DocumentProps {
  greenidStatus: GreenidStatus;
  type: 'Identifications';
  documentTypes: DocumentType[];
  document: SaveIdentificationDetails;
  saveProgress: LoadingProgress;
  showRequiredAsterisk?: boolean;
  hideDocumentUpload?: boolean;
  onSave: (saveIdentificationPayload: SaveIdentificationDetails) => void;
  handleCloseModal: () => void;
  fetchDocumentTypes: () => void;
}

export function Identification({
  greenidStatus,
  document,
  saveProgress,
  hideDocumentUpload,
  onSave,
  handleCloseModal,
  fetchDocumentTypes,
}: DocumentProps): JSX.Element {

  const initialFormValues: SaveIdentificationDetails = {
    id: null,
    typeId: null,
    subType: 2,
    name: '',
    file: null,
    fileName: '',
    base64Content: null,
    identificationDocType: getUiDocTypeId(document.typeId as number) ?? null,
    issueDate: '',
    number: '',
    stockNumber: '',
    documentNo: '',
    centrelinkCardType: '',
    centrelinkCardTypeId: CentrelinkCardType.getByName('Pensioner concession card')?.id ?? 1,
    expiryDate: '',
    centrelinkExpiryDate: '',
    centrelinkReferenceNumber: '',
    medicareNumber: '',
    medicareExpiryDate: '',
    medicareCardColourTypeId: null,
    medicareIrn: '',
    registrationState: null,
    registrationNumber: '',
    certificateNumber: '',
    registrationDate: '',
    licenceCardNumber: '',
    licenceNumber: '',
    licenceExpiryDate: '',
    issueStateOrTerritoryId: null,
    licenceIssuePlace: '',
    foreignPassportNumber: '',
    foreignPassportDateOfIssue: '',
    placeOfIssue: '',
    foreignPassportCountry: null,
    foreignPassportDateOfExpiry: '',
    certificatePrintedDate: '',
    dateOfIssue: '',
    verifiedOn: '',
    dateOfExpiry: '',
    isEditable: true
  } as SaveIdentificationDetails;

  const classes = useStyles();
  const [identificationPayload, setIdentificationPayload] = useState<SaveIdentificationDetails>(initialFormValues);
  const [existingFileName, setExistingFileName] = useState<string | null>(null);
  const [showDialogue, setShowDialogue] = useState(false);

  const closeDialogue = () => {
    setShowDialogue(false);
  };

  useEffect(() => {
    fetchDocumentTypes();
  }, [fetchDocumentTypes]);

  const isUpdate = !!getUiDocTypeId(document.typeId as number);

  useEffect(() => {
    setExistingFileName(document.fileName);
    setIdentificationPayload({
      ...document,
      file: null,
      name: document.name ?? '',
      base64Content: null,
      fileName: document.fileName ?? '',
      stockNumber: document.number,
      documentNo: document.number,
      identificationDocType: getUiDocTypeId(document.typeId as number) ?? null,
      issueDate: uiIssueDate(document.typeId as number, document),
      number: uiNumber(document.typeId as number, document),
      registrationNumber: uiRegistrationNumber(document.typeId as number, document),
      certificateNumber: uiBirthCertificateNumber(document.typeId as number, document),
      centrelinkCardTypeId: CentrelinkCardType.getByName(document.centrelinkCardType ?? '')?.id ?? 1,
      centrelinkReferenceNumber: document.number,
      centrelinkExpiryDate: uiExpiryDate(document.typeId as number, document),
      medicareNumber: document.number,
      medicareExpiryDate: uiExpiryDate(document.typeId as number, document),
      medicareCardColourTypeId: MedicareCardColour.getByName(document.medicareCardColour ?? '')?.id,
      medicareIrn: document.medicareIrn ?? '',
      expiryDate: '',
      registrationDate: uiIssueDate(document.typeId as number, document),
      certificatePrintedDate: uiExpiryDate(document.typeId as number, document),
      licenceCardNumber: document.licenceCardNumber ?? '',
      licenceNumber: document.number,
      licenceExpiryDate: uiExpiryDate(document.typeId as number, document),
      issueStateOrTerritoryId: AustralianState.getByName(document.placeOfIssue)?.id,
      foreignPassportNumber: document.number,
      foreignPassportDateOfIssue: uiIssueDate(document.typeId as number, document),
      foreignPassportCountry: Countries.getByName(document.placeOfIssue)?.id,
      foreignPassportDateOfExpiry: uiExpiryDate(document.typeId as number, document),
      subType: 2,
      dateOfIssue: uiIssueDate(document.typeId as number, document),
      verifiedOn: uiVerifiedOnDate(document.typeId as number, document),
      dateOfExpiry: uiExpiryDate(document.typeId as number, document),
      registrationState: AustralianState.getByName(document.placeOfIssue)?.id,
      birthCertificateNumber: uiBirthCertificateNumber(document.typeId as number, document),
      birthCertificate: uiBirthCertificateNumber(document.typeId as number, document)
    } as SaveIdentificationDetails);
  }, [document as SaveIdentificationDetails]);

  const registrationNumberErrorMsg = 'Enter a Registration Number between 1 and 10 characters';
  const certificateNumberErrorMsg = 'Enter a Certificate Number between 1 and 11 characters';
  const stockNumberErrorMsg = 'Enter a Stock Number between 4 and 11 characters';
  const driverLicenceNumberErrorMsg = "Enter a Driver Licence Number that is between 1 and 10 alphanumeric characters";
  const foreignPassportNumberErrorMsg = "Enter a Passport Number that is between 1 and 14 alphanumeric characters with no spaces or special characters";
  const medicareIrnErrorMsg = "Enter an Individual Reference Number 1 digit long"
  const medicareNumberErrorMsg = "Enter a Medicare Number that is 10 digits long";
  const centrelinkReferenceNumberErrorMsg = "Enter a CRN that is 9 digits followed by 1 letter"
  const ausPassportErrorMsg = "Enter a Document No. between 8 and 9 characters, must be one or two alphas followed by 7 digits";

  const [cardNumLength, setCardNumLength] = useState<LicenceCardNumberLength>({
    min: 10,
    max: 10,
    error: "Enter a Driver Licence Card Number"
  });

  const validationSchema = yup.object({
    identificationDocType: yup.number().typeError('Select identification type').required('Select identification type'),

    issueDate: yup.date().when('identificationDocType', {
      is: (docType: number) => {
        return docType === IdentificationDocType.getByName('AustralianCitizenshipCertificate')?.id;
      },
      then: yup.date().typeError('Date must be formatted as DD/MM/YYYY').nullable().required('Acquisition Date required'),
    }),

    stockNumber: yup.string().when('identificationDocType', {
      is: (docType: number) => {
        return docType === IdentificationDocType.getByName('AustralianCitizenshipCertificate')?.id;
      },
      then: yup
        .string()
        .required('Stock Number required')
        .min(4, stockNumberErrorMsg)
        .max(11, stockNumberErrorMsg)
        .test("citizenship-cert-stock-number", stockNumberErrorMsg, (value) => (!isSpecialCharacters(value ?? ''))),
    }),

    registrationState: yup.number().when('identificationDocType', {
      is: (docType: number) => {
        return docType === IdentificationDocType.getByName('AustralianBirthCertificate')?.id;
      },
      then: yup
        .number()
        .required('Select a Registration State')
    }),

    registrationDate: yup.date().when('identificationDocType', {
      is: (docType: number) => {
        return docType === IdentificationDocType.getByName('AustralianBirthCertificate')?.id;
      },
      then: yup.date().typeError('Date must be formatted as DD/MM/YYYY').required('Registration Date required'),
    }),

    certificatePrintedDate: yup.date().when('identificationDocType', {
      is: (docType: number) => {
        return docType === IdentificationDocType.getByName('AustralianBirthCertificate')?.id;
      },
      then: yup.date().typeError('Date must be formatted as DD/MM/YYYY').required('Certificate Printed Date required'),
    }),
    registrationNumber: yup.string().when('identificationDocType', {
      is: (docType: number) => docType === IdentificationDocType.getByName('AustralianBirthCertificate')?.id,
      then: yup
        .string()
        .required(registrationNumberErrorMsg)
        .min(1, registrationNumberErrorMsg)
        .max(10, registrationNumberErrorMsg)
        .test("aus-birth-cert-registration-number", registrationNumberErrorMsg, (value) => (!isSpecialCharacters(value ?? ''))),
    }),

    certificateNumber: yup.string().when(['identificationDocType', 'registrationState', 'certificatePrintedDate'], {
      is: (identificationDocType, registrationState, certificatePrintedDate) => {
        return identificationDocType === IdentificationDocType.getByName('AustralianBirthCertificate')?.id
          && certificateNumberValidate(identificationDocType, registrationState, certificatePrintedDate);
      },
      then: yup
        .string()
        .required(certificateNumberErrorMsg)
        .max(11, certificateNumberErrorMsg)
        .test("aus-birth-cert-number", certificateNumberErrorMsg, (value) => (!isSpecialCharacters(value ?? ''))),
    }),
    dateOfIssue: yup.date().when('identificationDocType', {
      is: (docType: number) => {
        return docType === IdentificationDocType.getByName('AustralianPassport')?.id;
      },
      then: yup.date().typeError('Date must be formatted as DD/MM/YYYY').required('Date of Issue required'),
    }),

    dateOfExpiry: yup.date().when('identificationDocType', {
      is: (docType: number) => {
        return docType === IdentificationDocType.getByName('AustralianPassport')?.id;
      },
      then: yup.date().typeError('Date must be formatted as DD/MM/YYYY').required('Date of Expiry required'),
    }),

    documentNo: yup.string().when('identificationDocType', {
      is: (docType: number) => {
        return docType === IdentificationDocType.getByName('AustralianPassport')?.id;
      },
      then: yup
        .string()
        .required('Enter a Document No.')
        .min(8, ausPassportErrorMsg)
        .max(9, ausPassportErrorMsg)
        .test("auspassport-documentNo", ausPassportErrorMsg, (value) => (!isSpecialCharacters(value ?? '') && isValidAusPassportNo(value ?? ''))),
    }),

    centrelinkReferenceNumber: yup.string().when('identificationDocType', {
      is: (docType: number) => {
        return docType === IdentificationDocType.getByName('CentrelinkConcessionCard')?.id;
      },
      then: yup
        .string()
        .required(centrelinkReferenceNumberErrorMsg)
        .min(10, centrelinkReferenceNumberErrorMsg)
        .max(10, centrelinkReferenceNumberErrorMsg)
        .test("ref-num-centrelink", centrelinkReferenceNumberErrorMsg, (value) => (!isSpecialCharacters(value ?? '') && isValidCentrelinkRefNum(value ?? ''))),
    }),

    centrelinkExpiryDate: yup.date().when('identificationDocType', {
      is: (docType: number) => {
        return docType === IdentificationDocType.getByName('CentrelinkConcessionCard')?.id;
      },
      then: yup.date().typeError('Date must be formatted as DD/MM/YYYY').required('Expiry Date required'),
    }),

    centrelinkCardTypeId: yup.number().when('identificationDocType', {
      is: (docType: number) => {
        return docType === IdentificationDocType.getByName('CentrelinkConcessionCard')?.id;
      },
      then: yup
        .number()
        .required('Select a Card Type')
    }),

    medicareCardColourTypeId: yup.number().when('identificationDocType', {
      is: (docType: number) => {
        return docType === IdentificationDocType.getByName('MedicareCard')?.id;
      },
      then: yup
        .number()
        .required('Select a Medicare Card Colour type')
    }),

    medicareNumber: yup.string().when('identificationDocType', {
      is: (docType: number) => {
        return docType === IdentificationDocType.getByName('MedicareCard')?.id;
      },
      then: yup
        .string()
        .required(medicareNumberErrorMsg)
        .min(10, medicareNumberErrorMsg)
        .max(10, medicareNumberErrorMsg)
        .test("medicare-number", medicareNumberErrorMsg, (value) => (!isSpecialCharacters(value ?? '') && isNumericVal(value ?? ''))),
    }),

    medicareIrn: yup.string().when('identificationDocType', {
      is: (docType: number) => {
        return docType === IdentificationDocType.getByName('MedicareCard')?.id;
      },
      then: yup
        .string()
        .required(medicareIrnErrorMsg)
        .min(1, medicareIrnErrorMsg)
        .max(1, medicareIrnErrorMsg)
        .test("medicare-irn", medicareIrnErrorMsg, (value) => (!isSpecialCharacters(value ?? '') && isNumericVal(value ?? '') && value !== "0")),
    }),

    medicareExpiryDate: yup.date().when('identificationDocType', {
      is: (docType: number) => {
        return docType === IdentificationDocType.getByName('MedicareCard')?.id;
      },
      then: yup.date().typeError('Date must be formatted as DD/MM/YYYY').required('Expiry Date required'),
    }),

    licenceExpiryDate: yup.date().when('identificationDocType', {
      is: (docType: number) => {
        return docType === IdentificationDocType.getByName('AustralianDriverLicence')?.id;
      },
      then: yup.date().typeError('Date must be formatted as DD/MM/YYYY').required('Expiry Date required'),
    }),

    issueStateOrTerritoryId: yup.number().when('identificationDocType', {
      is: (docType: number) => {
        return docType === IdentificationDocType.getByName('AustralianDriverLicence')?.id;
      },
      then: yup
        .number()
        .typeError('Select an Issue State or Territory')
        .required('Select an Issue State or Territory')
    }),

    licenceNumber: yup.string().when('identificationDocType', {
      is: (docType: number) => {
        return docType === IdentificationDocType.getByName('AustralianDriverLicence')?.id;
      },
      then: yup
        .string()
        .required(driverLicenceNumberErrorMsg)
        .min(1, driverLicenceNumberErrorMsg)
        .max(10, driverLicenceNumberErrorMsg)
        .test("driver-licence-mumber", driverLicenceNumberErrorMsg, (value) => (!isSpecialCharacters(value ?? '') && isAlphaNumeric(value ?? ''))),
    }),
    licenceCardNumber: yup.string().when(['identificationDocType', 'issueStateOrTerritoryId'], {
      is: (docType: number, state: number) => {
        setCardNumLength(licenceCardNumberLength(state));
        return docType === IdentificationDocType.getByName('AustralianDriverLicence')?.id;
      },
      then: yup
        .string()
        .required(cardNumLength.error)
        .min(cardNumLength.min, cardNumLength.error)
        .max(cardNumLength.max, cardNumLength.error)
        .test("driver-licence-card-number", cardNumLength.error, (value) => (!isSpecialCharacters(value ?? '') && isAlphaNumeric(value ?? '')))
        .test("driver-licence-card-number2", cardNumLength.error, (value) => (cardNumLength.isValid ? cardNumLength.isValid(value ?? '') : true)),
    }),

    foreignPassportDateOfIssue: yup.date().when('identificationDocType', {
      is: (docType: number) => {
        return docType === IdentificationDocType.getByName('ForeignPassport')?.id;
      },
      then: yup.date().typeError('Date must be formatted in DD/MM/YYYY').required('Date of Issue required'),
    }),

    foreignPassportDateOfExpiry: yup.date().when('identificationDocType', {
      is: (docType: number) => {
        return docType === IdentificationDocType.getByName('ForeignPassport')?.id;
      },
      then: yup.date().typeError('Date must be formatted in DD/MM/YYYY').required('Date of Expiry required'),
    }),

    foreignPassportNumber: yup.string().when('identificationDocType', {
      is: (docType: number) => {
        return docType === IdentificationDocType.getByName('ForeignPassport')?.id;
      },
      then: yup
        .string()
        .required(foreignPassportNumberErrorMsg)
        .min(1, foreignPassportNumberErrorMsg)
        .max(14, foreignPassportNumberErrorMsg)
        .test("foreign-passport-number", foreignPassportNumberErrorMsg, (value) => (!isSpecialCharacters(value ?? '') && isAlphaNumeric(value ?? '') && value !== "0")),
    }),

    foreignPassportCountry: yup.number().when('identificationDocType', {
      is: (docType: number) => {
        return docType === IdentificationDocType.getByName('ForeignPassport')?.id;
      },
      then: yup
        .number()
        .required('Select a Country of Issue')
    }),

    verifiedOn: yup.date().when('identificationDocType', {
      is: (docType: number) => {
        return docType === IdentificationDocType.getByName('IdentityVerificationCertificate')?.id;
      },
      then: yup.date().nullable().typeError('Date must be formatted as DD/MM/YYYY').required('Date must be formatted as DD/MM/YYYY'),
    }),

    file: !!hideDocumentUpload
      ? yup.mixed().nullable()
      : yup.mixed().test('file test', 'File required', function (this: TestContext, file: unknown): boolean {
        const id = this.parent.id;
        if (!!id) {
          // we allow user to not select a file - that would keep existing file
          return !!existingFileName;
        } else {
          // new document - must have a file
          return !!file;
        }
      }),
  });

  return (
    <>
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={true}
      onClose={() => handleCloseModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={true}>
        <Paper
          elevation={0}
          style={{
            width: '800px',
            minHeight: '300px',
            padding: '40px 40px',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <Formik<SaveIdentificationDetails>
            initialValues={identificationPayload}
            onSubmit={async (details) => {
              onSave({ ...details });
            }}
            enableReinitialize={true}
            validationSchema={validationSchema}
          >
            {(props: FormikProps<SaveIdentificationDetails>) => (
              <Form>
                <fieldset style={{ border: 'none', padding: '0' }}>
                  <Grid container>
                    <Grid item xs={6} style={{ paddingRight: '20px', paddingTop: '20px' }}>
                      <Grid item xs={12} style={{ minHeight: '90px' }}>
                          <Field
                            component={FormikEnumerationSelect}
                            disabled={isSubmitToDvsDisabled(greenidStatus) || isUpdate}
                            type={IdentificationDocType}
                            showNone={true}
                            name="identificationDocType"
                            valueIsId={true}
                            label="IDENTIFICATION TYPE"
                            fullWidth
                            onChange={() => {
                              return;
                            }}
                            showRequiredAsterisk
                          />
                      </Grid>
                    </Grid>
                    <Grid item xs={6} style={{ paddingRight: '20px', paddingTop: '20px' }}>
                      <Grid item xs={12} style={{ minHeight: '90px' }}>
                          <Button
                            component="label"
                            role={undefined}
                            disabled={viewSampleDisabled(props.values)}
                            variant="outlined"
                            tabIndex={-1}
                            startIcon={<ImageIcon />}
                            onClick={() => setShowDialogue(true)}
                          >
                            View Sample
                          </Button>
                      </Grid>
                    </Grid>

                    {parseInt(JSON.stringify(props.values.identificationDocType)) ===
                      IdentificationDocType.getByName('AustralianCitizenshipCertificate')?.id && (
                      <>
                        <Grid item xs={6} style={{ paddingRight: '20px' }}>
                          <Grid item xs={12} style={{ paddingBottom: '20px', minHeight: '90px' }}>
                            <Field
                              disabled={isSubmitToDvsDisabled(greenidStatus)}
                              component={FormikKeyboardDatePicker}
                              name="issueDate"
                              label="ACQUISITION DATE"
                              maxDate={DateTime.now()}
                              showRequiredAsterisk
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                        <Grid item xs={6}>
                          <Grid item xs={12} style={{ paddingBottom: '20px', minHeight: '90px' }}>
                            <Field
                              disabled={isSubmitToDvsDisabled(greenidStatus)}
                              name="stockNumber"
                              component={FormikTextField}
                              label="STOCK NUMBER"
                              showRequiredAsterisk
                              fullWidth
                            ></Field>
                          </Grid>
                        </Grid>
                        </>
                      )}

                    {parseInt(JSON.stringify(props.values.identificationDocType)) ===
                      IdentificationDocType.getByName('IdentityVerificationCertificate')?.id && (
                        <>
                          <Grid item xs={6} style={{ paddingRight: '20px' }}>
                            <Grid item xs={12} style={{ paddingBottom: '20px', minHeight: '90px' }}>
                              <Field
                                disabled={!document.isEditable}
                                component={FormikKeyboardDatePicker}
                                name="verifiedOn"
                                label="VERIFIED ON"
                                maxDate={DateTime.now()}
                                showRequiredAsterisk
                                fullWidth
                              ></Field>
                            </Grid>
                          </Grid>
                        </>
                      )}

                    {parseInt(JSON.stringify(props.values.identificationDocType)) ===
                      IdentificationDocType.getByName('AustralianBirthCertificate')?.id && (
                      <>
                        <Grid item xs={6} style={{ paddingRight: '20px' }}>
                          <Grid item xs={12} style={{ paddingBottom: '20px', minHeight: '90px' }}>
                            <Field
                              disabled={isSubmitToDvsDisabled(greenidStatus)}
                              component={FormikEnumerationSelect}
                              type={AustralianState}
                              showNone={false}
                              name="registrationState"
                              valueIsId={true}
                              label="REGISTRATION STATE"
                              fullWidth
                              onChange={() => {
                                return;
                              }}
                              showRequiredAsterisk
                            />
                          </Grid>
                        </Grid>
                        <Grid item xs={6}>
                          <Grid item xs={12} style={{ paddingBottom: '20px', minHeight: '90px' }}>
                            <Field
                              disabled={isSubmitToDvsDisabled(greenidStatus)}
                              name="registrationNumber"
                              component={FormikTextField}
                              label="REGISTRATION NUMBER"
                              showRequiredAsterisk
                              fullWidth
                            ></Field>
                          </Grid>
                        </Grid>
                        <Grid item xs={6} style={{ paddingRight: '20px' }}>
                          <Grid item xs={12} style={{ paddingBottom: '20px', minHeight: '90px' }}>
                            <Field
                              disabled={isSubmitToDvsDisabled(greenidStatus)}
                              component={FormikKeyboardDatePicker}
                              name="registrationDate"
                              label="REGISTRATION DATE"
                              maxDate={DateTime.now()}
                              showRequiredAsterisk
                              fullWidth
                            ></Field>
                          </Grid>
                        </Grid>
                        <Grid item xs={6}>
                          <Grid item xs={12} style={{ paddingBottom: '20px', minHeight: '90px' }}>
                            <Field
                              disabled={isSubmitToDvsDisabled(greenidStatus)}
                              name="certificateNumber"
                              component={FormikTextField}
                              label="CERTIFICATE NUMBER"
                              showRequiredAsterisk
                              fullWidth
                            ></Field>
                          </Grid>
                        </Grid>
                        <Grid item xs={6} style={{ paddingRight: '20px' }}>
                          <Grid item xs={12} style={{ paddingBottom: '20px', minHeight: '90px' }}>
                            <Field
                              disabled={isSubmitToDvsDisabled(greenidStatus)}
                              component={FormikKeyboardDatePicker}
                              name="certificatePrintedDate"
                              label="CERTIFICATE PRINTED DATE"
                              maxDate={DateTime.now()}
                              showRequiredAsterisk
                              fullWidth
                            ></Field>
                          </Grid>
                          <Grid item xs={6} style={{ paddingTop: '20px' }}>
                            <Grid item xs={12} style={{ paddingBottom: '20px', minHeight: '90px' }}></Grid>
                          </Grid>
                        </Grid>
                        </>
                      )}

                    {parseInt(JSON.stringify(props.values.identificationDocType)) ===
                      IdentificationDocType.getByName('AustralianPassport')?.id && (
                      <>
                        <Grid item xs={6} style={{ paddingRight: '20px' }}>
                          <Grid item xs={12} style={{ paddingBottom: '20px', minHeight: '90px' }}>
                            <Field
                              disabled={isSubmitToDvsDisabled(greenidStatus)}
                              component={FormikKeyboardDatePicker}
                              name="dateOfIssue"
                              label="DATE OF ISSUE"
                              maxDate={DateTime.now()}
                              showRequiredAsterisk
                              fullWidth
                            ></Field>
                          </Grid>
                        </Grid>
                        <Grid item xs={6}>
                          <Grid item xs={12} style={{ paddingBottom: '20px', minHeight: '90px' }}>
                            <Field
                              disabled={isSubmitToDvsDisabled(greenidStatus)}
                              name="documentNo"
                              component={FormikTextField}
                              label="DOCUMENT NO."
                              showRequiredAsterisk
                              fullWidth
                            ></Field>
                          </Grid>
                        </Grid>
                        <Grid item xs={6} style={{ paddingRight: '20px' }}>
                          <Grid item xs={12} style={{ paddingBottom: '20px', minHeight: '90px' }}>
                            <Field
                              disabled={isSubmitToDvsDisabled(greenidStatus)}
                              component={FormikKeyboardDatePicker}
                              name="dateOfExpiry"
                              label="DATE OF EXPIRY"
                              showRequiredAsterisk
                              fullWidth
                            ></Field>
                          </Grid>
                          <Grid item xs={6}>
                            <Grid item xs={12} style={{ paddingBottom: '20px', minHeight: '90px' }}>
                            </Grid>
                          </Grid>
                          </Grid>
                        </>
                      )}

                    {parseInt(JSON.stringify(props.values.identificationDocType)) ===
                      IdentificationDocType.getByName('CentrelinkConcessionCard')?.id && (
                        <>
                          <Grid item xs={6} style={{ paddingRight: '20px' }}>
                            <Grid item xs={12} style={{ paddingBottom: '20px', minHeight: '90px' }}>
                              <Field
                                disabled={isSubmitToDvsDisabled(greenidStatus)}
                                component={FormikEnumerationSelect}
                                type={CentrelinkCardType}
                                showNone={false}
                                name="centrelinkCardTypeId"
                                valueIsId={true}
                                label="CARD TYPE"
                                fullWidth
                                onChange={() => {
                                  return;
                                }}
                                showRequiredAsterisk
                              />
                            </Grid>
                          </Grid>
                          <Grid item xs={6}>
                            <Grid item xs={12} style={{ paddingBottom: '20px', minHeight: '90px' }}>
                              <Field
                                disabled={isSubmitToDvsDisabled(greenidStatus)}
                                name="centrelinkReferenceNumber"
                                component={FormikTextField}
                                label="CENTRELINK REFERENCE NUMBER"
                                showRequiredAsterisk
                                fullWidth
                              ></Field>
                            </Grid>
                          </Grid>

                          <Grid item xs={6} style={{ paddingRight: '20px' }}>
                            <Grid item xs={12} style={{ paddingBottom: '20px', minHeight: '90px' }}>
                              <Field
                                disabled={isSubmitToDvsDisabled(greenidStatus)}
                                component={FormikKeyboardDatePicker}
                                name="centrelinkExpiryDate"
                                label="EXPIRY DATE"
                                showRequiredAsterisk
                                fullWidth
                              ></Field>
                            </Grid>
                          </Grid>
                          <Grid item xs={6}>
                            <Grid item xs={12} style={{ paddingBottom: '20px', minHeight: '90px' }}>

                            </Grid>
                          </Grid>
                        </>
                      )}

                    {parseInt(JSON.stringify(props.values.identificationDocType)) ===
                      IdentificationDocType.getByName('MedicareCard')?.id && (
                        <>
                          <Grid item xs={6} style={{ paddingRight: '20px' }}>
                            <Grid item xs={12} style={{ paddingBottom: '20px', minHeight: '90px' }}>
                              <Field
                                disabled={isSubmitToDvsDisabled(greenidStatus)}
                                component={FormikEnumerationSelect}
                                type={MedicareCardColour}
                                showNone={false}
                                name="medicareCardColourTypeId"
                                valueIsId={true}
                                label="MEDICARE CARD COLOUR"
                                fullWidth
                                onChange={() => {
                                  return;
                                }}
                                showRequiredAsterisk
                              />
                            </Grid>
                          </Grid>
                          <Grid item xs={6}>
                            <Grid item xs={12} style={{ paddingBottom: '20px', minHeight: '90px' }}>
                              <Field
                                disabled={isSubmitToDvsDisabled(greenidStatus)}
                                name="medicareNumber"
                                component={FormikTextField}
                                label="MEDICARE NUMBER"
                                showRequiredAsterisk
                                fullWidth
                              ></Field>
                            </Grid>
                          </Grid>

                          <Grid item xs={6} style={{ paddingRight: '20px' }}>
                            <Grid item xs={12} style={{ paddingBottom: '20px', minHeight: '90px' }}>
                              <Field
                                disabled={isSubmitToDvsDisabled(greenidStatus)}
                                component={FormikKeyboardDatePicker}
                                name="medicareExpiryDate"
                                label="EXPIRY DATE"
                                showRequiredAsterisk
                                fullWidth
                              ></Field>
                            </Grid>
                          </Grid>
                          <Grid item xs={6}>
                            <Grid item xs={12} style={{ paddingBottom: '20px', minHeight: '90px' }}>
                            <Field
                                disabled={isSubmitToDvsDisabled(greenidStatus)}
                                name="medicareIrn"
                                component={FormikTextField}
                                label="INDIVIDUAL REFERENCE NUMBER (IRN)"
                                showRequiredAsterisk
                                fullWidth
                              ></Field>
                            </Grid>
                          </Grid>
                        </>
                      )
                    }

                    {parseInt(JSON.stringify(props.values.identificationDocType)) ===
                      IdentificationDocType.getByName('AustralianDriverLicence')?.id && (
                        <>
                          <Grid item xs={6} style={{ paddingRight: '20px' }}>
                            <Grid item xs={12} style={{ paddingBottom: '20px', minHeight: '90px' }}>
                              <Field
                                disabled={isSubmitToDvsDisabled(greenidStatus)}
                                component={FormikEnumerationSelect}
                                type={AustralianState}
                                showNone={true}
                                name="issueStateOrTerritoryId"
                                valueIsId={true}
                                label="ISSUE STATE OR TERRITORY"
                                fullWidth
                                onChange={() => {
                                  return;
                                }}
                                showRequiredAsterisk
                              />

                            </Grid>
                          </Grid>
                          <Grid item xs={6}>
                            <Grid item xs={12} style={{ paddingBottom: '20px', minHeight: '90px' }}>
                              <Field
                                disabled={isSubmitToDvsDisabled(greenidStatus)}
                                name="licenceNumber"
                                component={FormikTextField}
                                label="LICENCE NUMBER"
                                showRequiredAsterisk
                                fullWidth
                              ></Field>
                            </Grid>
                          </Grid>

                          <Grid item xs={6} style={{ paddingRight: '20px' }}>
                            <Grid item xs={12} style={{ paddingBottom: '20px', minHeight: '90px' }}>
                              <Field
                                disabled={isSubmitToDvsDisabled(greenidStatus)}
                                component={FormikKeyboardDatePicker}
                                name="licenceExpiryDate"
                                label="EXPIRY DATE"
                                showRequiredAsterisk
                                fullWidth
                              ></Field>
                            </Grid>
                          </Grid>
                          <Grid item xs={6}>
                            <Grid item xs={12} style={{ paddingBottom: '20px', minHeight: '90px' }}>
                            <Field
                                disabled={isSubmitToDvsDisabled(greenidStatus)}
                                name="licenceCardNumber"
                                component={FormikTextField}
                                label="LICENCE CARD NUMBER"
                                showRequiredAsterisk
                                fullWidth
                              ></Field>
                            </Grid>
                          </Grid>
                        </>
                      )
                    }

                    {parseInt(JSON.stringify(props.values.identificationDocType)) ===
                      IdentificationDocType.getByName('ForeignPassport')?.id && (
                        <>
                          <Grid item xs={6} style={{ paddingRight: '20px' }}>
                            <Grid item xs={12} style={{ paddingBottom: '20px', minHeight: '90px' }}>
                              <Field
                                disabled={isSubmitToDvsDisabled(greenidStatus)}
                                component={FormikKeyboardDatePicker}
                                name="foreignPassportDateOfIssue"
                                label="DATE OF ISSUE"
                                maxDate={DateTime.now()}
                                showRequiredAsterisk
                                fullWidth
                              ></Field>
                            </Grid>
                          </Grid>
                          <Grid item xs={6}>
                            <Grid item xs={12} style={{ paddingBottom: '20px', minHeight: '90px' }}>
                            <Field
                                disabled={isSubmitToDvsDisabled(greenidStatus)}
                                name="foreignPassportNumber"
                                component={FormikTextField}
                                label="PASSPORT NUMBER"
                                showRequiredAsterisk
                                fullWidth
                              ></Field>
                            </Grid>
                          </Grid>

                          <Grid item xs={6} style={{ paddingRight: '20px' }}>
                            <Grid item xs={12} style={{ paddingBottom: '20px', minHeight: '90px' }}>
                              <Field
                                disabled={isSubmitToDvsDisabled(greenidStatus)}
                                component={FormikKeyboardDatePicker}
                                name="foreignPassportDateOfExpiry"
                                label="DATE OF EXPIRY"
                                showRequiredAsterisk
                                fullWidth
                              ></Field>
                            </Grid>
                          </Grid>
                          <Grid item xs={6}>
                            <Grid item xs={12} style={{ paddingBottom: '20px', minHeight: '90px' }}>
                              <Field
                                component={FormikEnumerationSelect}
                                disabled={isSubmitToDvsDisabled(greenidStatus)}
                                type={Countries}
                                showNone={true}
                                name="foreignPassportCountry"
                                valueIsId={true}
                                label="COUNTRY OF ISSUE"
                                fullWidth
                                onChange={() => {
                                  return;
                                }}
                                showRequiredAsterisk
                              />
                            </Grid>
                          </Grid>
                        </>
                      )
                    }

                    {!hideDocumentUpload && (
                      <Grid item xs={12}>
                        <Dropzone
                          disabled={isSubmitToDvsDisabled(greenidStatus)}
                          onDrop={async (files) => {
                            // reset the name to the filename and set the file
                            if (!!files && !!files[0]) {
                              const file = files[0];

                              await toBase64(file).then((base64Content) => {
                                setExistingFileName(file.name);
                                props.setValues({
                                  ...props.values,
                                  file,
                                  name: file.name,
                                  // stockNumber: file.name,
                                  fileName: file.name,
                                  base64Content,
                                });
                                props.validateForm();
                              });
                            }
                          }}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div
                              data-testid="dropzone"
                              {...getRootProps({ className: classes.dropzone })}
                              style={{
                                borderColor: !!props.errors.file ? 'red' : 'gray',
                                marginTop: 10,
                                marginBottom: 20,
                                marginLeft: 0,
                                marginRight: 0,
                                minHeight: 80,
                              }}
                            >
                              <input
                                {...getInputProps({
                                  // accept: '.*',
                                  type: 'file',
                                })}
                              />
                              {(!!props.values.file || existingFileName) && (
                                <Chip
                                  disabled={isSubmitToDvsDisabled(greenidStatus)}
                                  label={props.values.file?.name || document.fileName}
                                  onDelete={() => {
                                    setExistingFileName(null); // if chip is showing existing fileName, set it to null
                                    props.setValues({ ...props.values, file: null });
                                  }}
                                  icon={<AttachmentIcon />}
                                />
                              )}
                              {!props.values.file && !existingFileName && (
                                <p style={{ color: !!props.errors.file ? 'red' : 'gray' }}>
                                  {'Drag and drop your file here, or click to select file'}
                                </p>
                              )}
                            </div>
                          )}
                        </Dropzone>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                        <EditCancelSaveButtons
                          mode={Mode.EditCancelSave}
                          handleCancelClick={handleCloseModal}
                          saveButtonTestID='saveIdentificationDocumentButton'
                          saveProgress={saveProgress}
                        ></EditCancelSaveButtons>
                    </Grid>
                  </Grid>
                </fieldset>
                <Dialog
                  aria-labelledby="customized-dialog-title"
                  open={showDialogue}
                  onClose={closeDialogue}
                  maxWidth={ tool_tip_reference(props.values.identificationDocType, getSubtype(props.values)).maxWidth ?? 'md' }
                >
                  <Box style={{ position: 'relative', height: '20px' }}>
                    <IconButton
                      aria-label="close"
                      onClick={closeDialogue}
                      style={{ position: 'absolute', right: '9px', top: '6px' }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Box>
                  <DialogTitle>
                  </DialogTitle>
                  <DialogContent dividers>
                    <Grid container style={{ paddingBottom: '20px' }}>
                        <Grid item xs={12} style={{ minHeight: '90px' }}>
                          <img
                            src={tool_tip_reference(props.values.identificationDocType, getSubtype(props.values)).img1 ?? ''}
                            alt="Documentation Reference"
                          />
                        </Grid>
                      { tool_tip_reference(props.values.identificationDocType, getSubtype(props.values)).img2 &&  
                        <Grid item xs={12} style={{ minHeight: '90px' }}>
                          <img
                            src={tool_tip_reference(props.values.identificationDocType, getSubtype(props.values)).img2 ?? ''}
                            alt="Documentation Reference"
                          />
                        </Grid>
                        }
                        { tool_tip_reference(props.values.identificationDocType, getSubtype(props.values)).img3 &&  
                          <Grid item xs={12} style={{ minHeight: '90px' }}>
                            <img
                              src={tool_tip_reference(props.values.identificationDocType, getSubtype(props.values)).img3 ?? ''}
                              alt="Documentation Reference"
                            />
                          </Grid>
                        }
                        { tool_tip_reference(props.values.identificationDocType, getSubtype(props.values)).img4 &&  
                          <Grid item xs={12} style={{ minHeight: '90px' }}>
                            <img
                              src={tool_tip_reference(props.values.identificationDocType, getSubtype(props.values)).img4 ?? ''}
                              alt="Documentation Reference"
                            />
                          </Grid>
                        }
                    </Grid>
                  </DialogContent>
                </Dialog>
              </Form>
            )}
          </Formik>
        </Paper>
      </Fade>
    </Modal>
    </>
  );
}
