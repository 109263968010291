import { MUIDataTableColumn } from 'mui-datatables';
import { Enumeration } from 'src/common/types/enumeration';

export enum FilterDataType {
  numeric,
  string,
  date,
  enumeration,
}

export interface DatatableColumn extends MUIDataTableColumn {
  filterDataType?: FilterDataType;
  enumerationType?: typeof Enumeration;
  renderFilterChipValue?: (value: string) => string;
  verticalAlign?: 'top' | 'middle' | 'bottom' | 'inherit';
  textAlign?: 'left' | 'right' | 'center';
}
