import { moment } from 'src/common/types';
import { DateRange, PagedRequest, PagedResult } from '../../../../../store';
import { ClientPortfolio } from '../../common/store/types';

export interface PerformanceState {
  parameters: PerformanceParameters;
  currentTab: CurrentTabType;
  benchmarks?: BenchmarkItem[];
  performanceChartResult?: PerformanceChartResult;
  benchmarkPerformanceResult?: BenchmarkPerformanceResult | null;
  monthlyPerformanceTableResult?: MonthlyPerformanceTableResult;
  periodicSummaryTableResult?: PeriodicSummaryTableResult;
  portfolioSecurityPerformanceTableResult?: PortfolioSecurityPerformanceTableResult;
}

export interface PerformanceParameters {
  selectedPortfolio: ClientPortfolio | null;
  selectedBenchmark?: BenchmarkItem | null;
  dates: DateRange;
  frequency: Frequency;
  groupingType: GroupingType;
}

export interface ThunkParameters {
  clientId: number;
  parameters: PerformanceParameters;
  pagedRequest?: PagedRequest;
  isDataFromTriumph?: boolean;
}

export interface BenchmarkItem {
  id: number;
  code: string;
  name: string;
}

export enum CurrentTabType {
  MonthlySummary = 'Monthly Summary',
  PeriodicSummary = 'Periodic Summary',
  Detail = 'Detail',
}

export enum GroupingType {
  AssetClass = 'AssetClass',
  Model = 'Model',
  Account = 'Account',
}

// Performance Chart
export enum Frequency {
  Daily = 'Daily',
  Monthly = 'Monthly',
}

export interface PerformanceChartResult {
  clientId: number;
  investmentId: number | undefined;
  portfolioValue: number;

  changeInValue: number;
  changeInValuePercentage: number;
  changeInCapitalValue: number;
  changeInIncomeValue: number;
  changeInIncomeValuePercentage: number;
  changeInCapitalValuePercentage: number;

  standardDeviation: number;
  annualVolatility: number;
  seriesData: PerformanceChartSeriesItem[];
}

export interface PerformanceChartSeriesItem {
  date: moment;
  return: number;
  cumulativeReturn: number;
}

// Benchmark Performance Chart
export interface BenchmarkPerformanceResult {
  strategyId: number;
  seriesData: BenchmarkChartSeriesItem[];
}

export interface BenchmarkChartSeriesItem {
  date: moment;
  cumulativeReturn: number;
}

// Monthly Performance Table
export type MonthlyPerformanceTableResult = PagedResult<MonthlyPerformanceTableResultItem>;

export interface MonthlyPerformanceTableResultItem {
  perfDate: moment;
  openingBalance: number;
  capitalMovement: number;
  aquisitions: number;
  disposals: number;
  investment: number;
  expense: number;
  difference: number;
  other: number;
  tax: number;
  income: number;
  growth: number;
  total: number;
  incomeReturn: number;
  growthReturn: number;
  totalReturn: number;
  closingBalance: number;
}

// Periodic Summary Table
export interface PeriodicSummaryTableResult {
  clientId: number;
  investmentServiceId: number;
  strategyId: number;
  seriesData: PeriodicSummaryTableSeriesItem[];
}

export interface PeriodicSummaryTableSeriesItem {
  date: string;
  label: string;
  incomeReturn: number;
  capitalReturn: number;
  totalReturn: number;
  benchmarkIncomeReturn: number;
  benchmarkCapitalReturn: number;
  benchmarkTotalReturn: number;
  portfolioVsBenchmarkIncomeReturn: number;
  portfolioVsBenchmarkCapitalReturn: number;
  portfolioVsBenchmarkTotalReturn: number;
}

// Portfolio Security Performance Table
export interface PortfolioSecurityPerformanceTableResult {
  clientId: number;
  investmentServiceId: number;
  groupItems: PortfolioSecurityPerformanceTableGroupItem[];
}

export interface PortfolioSecurityPerformanceTableGroupItem {
  groupName: string;
  securityCode: string;
  securityName: string;
  openingBalance: number;
  aquisitions: number;
  disposals: number;
  capitalMovement: number;
  other: number;
  closingBalance: number;
  income: number;
  growth: number;
  total: number;
  incomeReturn: number;
  growthReturn: number;
  totalReturn: number;
  children: PortfolioSecurityPerformanceTableGroupItem[];
}
