import { FormControl, InputLabel, MenuItem, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import { DateTime } from 'luxon';
import React, { useEffect, useRef } from 'react';
import { SystemMinimumDate } from '../utils';
import { WO2Select } from './../';

interface Props {
  dateFrom: DateTime | null;
  dateTo: DateTime | null;
  inceptionDate?: string;
  isDateOnly: boolean;
  setDateFrom: (date: DateTime | null) => void;
  setDateTo: (date: DateTime | null) => void;
  setDateRangeType: (dateRangeType: string | null) => void;
}

interface PreDefinedDateRange {
  name: string;
  dateFrom: DateTime;
  dateTo: DateTime;
}

interface DateRangeItem {
  name: string;
  types: DatePickerTypes[];
  dateFrom: DateTime;
  dateTo: DateTime;
}

export enum DatePickerTypes {
  DateOnly = 'DateOnly',
  DateRange = 'DateRange',
}

export enum DatePickerNames {
  Today = "Today's Date",
  InceptionToDate = 'Inception To Date',
  ThisQuarter = 'This Quarter',
  LastQuarter = 'Last Quarter',
  ThisFinancialYear = 'This Financial Year',
  LastFinancialYear = 'Last Financial Year',
  ThisYear = 'This Year',
  LastYear = 'Last Year',
  Last3Months = 'Last 3 Months',
  Last6Months = 'Last 6 Months',
  Last12Months = 'Last 12 Months',
}

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    marginLeft: 0,
  },
  select: {
    height: 50,
    width: 212,
  },
}));

function PreDefinedDatesSelect(props: Props): JSX.Element {
  const classes = useStyles();
  const inputLabel: React.MutableRefObject<HTMLLabelElement | null> = useRef(null);
  const { inceptionDate, isDateOnly } = props;
  const inputLabelText = `Pre Defined ${isDateOnly ? 'Date' : 'Dates'}`;
  const predefinedDateRanges = getPredefinedDateRanges(isDateOnly, inceptionDate); // the full list of predefined date ranges
  const matchedItem =
    props.dateFrom && props.dateTo
      ? _.find<PreDefinedDateRange>(
          predefinedDateRanges,
          (i) =>
            i.dateFrom.toISODate() === props.dateFrom?.toISODate() && i.dateTo.toISODate() === props.dateTo?.toISODate()
        )
      : null;

  function setPreDefinedDates(name: string) {
    // find rangeName dates
    const selectedRange = _.find(predefinedDateRanges, { name: name });
    props.setDateFrom(selectedRange?.dateFrom ?? DateTime.now());
    props.setDateTo(selectedRange?.dateTo ?? DateTime.now());
    props.setDateRangeType(name.length === 0 ? inputLabelText : name);
  }
  useEffect(() => {
    if (matchedItem === null || matchedItem === undefined) {
      props.setDateRangeType(inputLabelText);
      return;
    }
    props.setDateRangeType(matchedItem.name);
  }, [matchedItem]);

  return (
    <div>
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel ref={inputLabel} id="pre-defined-dates-label">
          {inputLabelText}
        </InputLabel>
        <WO2Select
          className={classes.select}
          labelId="pre-defined-dates-label"
          id="pre-defined-dates"
          value={matchedItem ? matchedItem.name : ''}
          onChange={(event) => {
            setPreDefinedDates(event.target.value as string);
          }}
          label={inputLabelText}
        >
          {_.map(predefinedDateRanges, (item) => (
            <MenuItem key={item.name} value={item.name}>
              {item.name}
            </MenuItem>
          ))}
        </WO2Select>
      </FormControl>
    </div>
  );
}

function getPredefinedDateRanges(isDateOnly: boolean, inceptionDate = SystemMinimumDate) {
  const currentDate = DateTime.now();
  let dateRange: PreDefinedDateRange[] = [];
  const thisFinancialYearStart =
    currentDate.month >= 7
      ? currentDate.set({ month: 7 }).startOf('month')
      : currentDate.minus({ year: 1 }).set({ month: 7 }).startOf('month');

  const lastFinancialYearStart =
    currentDate.month >= 7
      ? currentDate.minus({ year: 1 }).set({ month: 7 }).startOf('month')
      : currentDate.minus({ year: 2 }).set({ month: 7 }).startOf('month');

  const dateRangeItems: DateRangeItem[] = [
    {
      name: isDateOnly ? DatePickerNames.Today : DatePickerNames.InceptionToDate,
      types: [DatePickerTypes.DateOnly, DatePickerTypes.DateRange],
      dateFrom: DateTime.fromISO(inceptionDate).startOf('day'),
      dateTo: currentDate,
    },
    {
      name: DatePickerNames.ThisQuarter,
      types: [DatePickerTypes.DateRange],
      dateFrom: currentDate.startOf('quarter').startOf('day'),
      dateTo: currentDate,
    },
    {
      name: DatePickerNames.LastQuarter,
      types: [DatePickerTypes.DateOnly, DatePickerTypes.DateRange],
      dateFrom: currentDate.minus({ quarter: 1 }).startOf('quarter'),
      dateTo: currentDate.minus({ quarter: 1 }).endOf('quarter'),
    },
    {
      name: DatePickerNames.ThisFinancialYear,
      types: [DatePickerTypes.DateRange],
      dateFrom: thisFinancialYearStart,
      dateTo: currentDate,
    },
    {
      name: DatePickerNames.LastFinancialYear,
      types: [DatePickerTypes.DateOnly, DatePickerTypes.DateRange],
      dateFrom: lastFinancialYearStart,
      dateTo: lastFinancialYearStart.plus({ year: 1 }).set({ month: 6 }).endOf('month'),
    },
    {
      name: DatePickerNames.ThisYear,
      types: [DatePickerTypes.DateRange],
      dateFrom: currentDate.startOf('year'),
      dateTo: currentDate,
    },
    {
      name: DatePickerNames.LastYear,
      types: [DatePickerTypes.DateOnly, DatePickerTypes.DateRange],
      dateFrom: currentDate.minus({ year: 1 }).startOf('year').startOf('month'),
      dateTo: currentDate.minus({ year: 1 }).endOf('year').endOf('month'),
    },
    {
      name: DatePickerNames.Last3Months,
      types: [DatePickerTypes.DateRange],
      dateFrom: currentDate.minus({ month: 3 }),
      dateTo: currentDate,
    },
    {
      name: DatePickerNames.Last6Months,
      types: [DatePickerTypes.DateRange],
      dateFrom: currentDate.minus({ month: 6 }),
      dateTo: currentDate,
    },
    {
      name: DatePickerNames.Last12Months,
      types: [DatePickerTypes.DateRange],
      dateFrom: currentDate.minus({ month: 12 }),
      dateTo: currentDate,
    },
  ];

  dateRange = dateRangeItems.filter(
    (item) => item.types && item.types.includes(isDateOnly ? DatePickerTypes.DateOnly : DatePickerTypes.DateRange)
  );

  return dateRange;
}

export default PreDefinedDatesSelect;
