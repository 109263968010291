import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { progressSelectorFactory } from 'src/common/store/selectors/loadingProgressSelector';
import { RootState } from '../../../../reducers';
import { selectPrevNextRoutePaths } from '../store/routeSelectors';
import { selectAddressItems, selectAvailableAddressTypes, selectClient, selectEditAddress, selectId } from '../store/selectors';
import { onboardSlice } from '../store/slice';
import { deleteAddress, OnboardActionTypes, saveAddress, saveOnboarding } from '../store/thunks';
import { Addresses } from './components/addresses';

const mapStateToProps = (state: RootState, routeComponentProps: RouteComponentProps) => ({
  id: selectId(state),
  client: selectClient(state),
  prevNextRoutePaths: selectPrevNextRoutePaths(state, routeComponentProps.location.pathname),
  addresses: selectAddressItems(state),
  editAddress: selectEditAddress(state),
  selectedAddress: selectEditAddress(state),
  availableAddressTypes: selectAvailableAddressTypes(state),
  saveProgress: progressSelectorFactory([OnboardActionTypes.SaveOnboarding])(state),
});

const mapDispatchToProps = {
  setAddressEdit: onboardSlice.actions.setAddressEdit,
  saveAddress: saveAddress,
  removeAddress: deleteAddress,
  saveOnboarding,
};

interface MatchParams {
  id?: string;
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector> & RouteComponentProps<MatchParams>;
export const AddressesContainer = connect(mapStateToProps, mapDispatchToProps)(Addresses);
