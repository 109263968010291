import { FormControl, InputLabel, MenuItem } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { FilterOperator, getFilterOperators, getOperator } from '..';
import { useStyles } from '../../../themes/dataTableStyles';
import { FilterDataType } from '../../types';
import { WO2Select } from './../../../../';
import { filterOperator } from './../filterOperators';

interface Props {
  operator: FilterOperator;
  dataType: FilterDataType;
  onChange: (operator: FilterOperator) => void;
}

export const OperatorSelect = (props: Props): JSX.Element => {
  const classes = useStyles();
  const [operators, setOperators] = useState<FilterOperator[]>([]);
  const inputLabel: React.RefObject<HTMLLabelElement> | null | undefined = useRef(null);

  useEffect(() => {
    switch (props.dataType) {
      case FilterDataType.numeric:
      case FilterDataType.date:
        setOperators(
          getFilterOperators().filter((f) =>
            [
              filterOperator.isEqual,
              filterOperator.notEqual,
              filterOperator.greaterThan,
              filterOperator.greaterThanOrEqualTo,
              filterOperator.lessThan,
              filterOperator.lessOrEqualTo,
            ].find((v) => v === f.name)
          )
        );
        break;
      case FilterDataType.string:
        setOperators(
          getFilterOperators().filter((f) =>
            [filterOperator.isEqual, filterOperator.notEqual, filterOperator.contains, filterOperator.doesNotContain].find((v) => v === f.name)
          )
        );
        break;

      case FilterDataType.enumeration:
        setOperators(getFilterOperators().filter((f) => [filterOperator.contains, filterOperator.doesNotContain].find((v) => v === f.name)));
        break;
    }
  }, [props.dataType]);

  return (
    <div>
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel ref={inputLabel} id="account-select-label">
          Type
        </InputLabel>
        <WO2Select
          className={classes.select}
          id="operator-select"
          value={operators.length > 0 ? props.operator.name : ''}
          onChange={(event) => {
            const operator = getOperator(event.target.value as string);
            props.onChange(operator);
          }}
          label={'Operator'}
        >
          {operators
            .sort((o) => o.sortIndex)
            .map((operator) => (
              <MenuItem key={operator.name} value={operator.name}>
                {operator.label}
              </MenuItem>
            ))}
        </WO2Select>
      </FormControl>
    </div>
  );
};
