import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './app/App';
//import './index.scss';
import Root from './Root';
//import './themes/variables.scss';

ReactDOM.render(
  <Root>
    <Sentry.ErrorBoundary>
      <App />
    </Sentry.ErrorBoundary>
  </Root>,
  document.querySelector('#root')
);
