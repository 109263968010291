import { TabContext, TabList, TabPanel } from '@mui/lab';
import { FeeAndAdjustmentDetails, FeeDetailsRow, TransactionDetails as TransactionDetailsType } from '../store/types';
import { Container, FormControlLabel, FormGroup, Grid, Switch, Tab, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { formatAmountNoSymbol, formatDollars } from 'src/common/utils/currencyFunctions';
import { DateTimeFormat, getLocalDateTime } from 'src/common/utils/dateFunctions';
import { formatNumberCommaSeparated } from 'src/common/utils/numberFunctions';

export interface Props {
  transaction: TransactionDetailsType;
  testId?: string;
}

export const TransactionDetailsTabs = (props: Props) : JSX.Element => {
  
  const itemSpacing = 1.5;

  const [showNativeValues, setShowNativeValues] = useState(false);
  const [selectedTab, setSelectedTab] = useState(TransactionDetailsTabName.Details);
  const handleTabChange = (_event: ChangeEvent<unknown>, newTab: number) => {
    setSelectedTab(newTab);
  };
  
  const isInternational: boolean = 
    (props.transaction?.currencyNative !== null)  
    && (props.transaction?.currencyNative !== undefined)
    && (props.transaction?.currencyNative !== 'AUD');

  const hasFees: boolean = ((props.transaction?.totalFeesAndCharges !== null 
        && props.transaction?.totalFeesAndCharges !== 0)
      || (props.transaction?.totalFeesAndChargesNative !== null 
        && props.transaction?.totalFeesAndChargesNative !== 0))
    && props.transaction.feeAndAdjustmentDetails?.length > 0;

  // To ensure tab panels are of consistent height (so no jumping around of elements when switching tabs),
  // we need to record height of Details (default) tab and ensure Fees tab, if present, is bound to this
  const tabRef = useRef<HTMLDivElement>(null)
  const [tabHeight, setTabHeight] = useState(0);
  useEffect(() => {
    setTabHeight(tabRef?.current?.getBoundingClientRect().height ?? 0)
  }, [tabRef]);
  
  const getNativeFeeRow = (fd: FeeAndAdjustmentDetails) : FeeDetailsRow => {
    return ({type: fd.type, 
      amount: formatAmountNoSymbol(fd.nativeFee), 
      gst: formatAmountNoSymbol(fd.nativeGst),
      total: formatAmountNoSymbol(fd.nativeTotal)
    });
  }

  const getReportingFeeRow = (fd: FeeAndAdjustmentDetails) : FeeDetailsRow => {
    return ({type: fd.type, 
      amount: formatDollars(fd.fee), 
      gst: formatDollars(fd.gst),
      total: formatDollars(fd.total)
    });
  }
  
  const getFeeReportingDetails = (transaction: TransactionDetailsType, showNativeValues: boolean) : FeeReportingDetails => {
    if (transaction?.feeAndAdjustmentDetails?.length > 0) {
      const feesRows = showNativeValues
                        ? props.transaction?.feeAndAdjustmentDetails
                          .filter(fd => fd.nativeTotal !== null && fd.nativeTotal !== 0)  
                          .map(fd => getNativeFeeRow(fd))
                        : props.transaction?.feeAndAdjustmentDetails
                          .filter(fd => fd.total !== null && fd.total !== 0)  
                          .map(fd => getReportingFeeRow(fd));
      const totalAmount = showNativeValues
        ? formatAmountNoSymbol(props.transaction?.totalFeeAmountNative)
        : formatDollars(props.transaction?.totalFeeAmount);
      const totalGst = showNativeValues
        ? formatAmountNoSymbol(props.transaction?.totalFeeGstNative)
        : formatDollars(props.transaction?.totalFeeGst);
      const totalCharges = showNativeValues
        ? formatAmountNoSymbol(props.transaction?.totalFeesAndChargesNative)
        : formatDollars(props.transaction?.totalFeesAndCharges);
        return {feesRows, totalAmount, totalGst, totalCharges};
    }
    else {
      return {feesRows: null, totalAmount: '', totalGst: '', totalCharges: ''};
    }
  }
  
  const feeReportingDetails = getFeeReportingDetails(props.transaction, showNativeValues);
  
  const gridItemPair = (label: string, value: string, rightAlignValue = false) => {
    return (
      <>
      <Grid item xs={3}>
        <Typography variant="h5" align={'left'}>
          {label}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="h5" align={rightAlignValue ? 'right' : 'inherit'}>
          {value}
        </Typography>
      </Grid>
      </>
    );
  };

  const currencyInfo = (transaction: TransactionDetailsType, testId?: string) => {
    return (
      <Grid container 
        spacing={itemSpacing}
        data-testid={`currencyInfo_${testId ?? ''}`}>
        <Grid item xs={8}>
          <Typography variant="h5" align={'left'}>
            Native currency:
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h5" align={'right'}>{transaction.currencyNative}</Typography>
        </Grid>

        <Grid item xs={8}>
          <Typography variant="h5" align={'left'}>
            {`Exchange rate (${transaction.currencyNative}/${transaction.currencyQuote ?? '???'}):`}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h5" align={'right'}>{transaction.fxRate?.toFixed(4)}</Typography>
        </Grid>

        <Grid item xs={8}>
          <Typography variant="h5" align={'left'}>
            Exchange rate type:
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h5" align={'right'}>{transaction.fxRateType}</Typography>
        </Grid>
      </Grid>
    );
  };

  return (
    <Container style={{ padding: '0' }}>
      {!!isInternational && <FormGroup 
        data-testid={`showNativeToggle_${props.testId ?? ''}`}> 
        <FormControlLabel
          control={
          <Switch checked={showNativeValues}
            onClick={() =>
              setShowNativeValues(!showNativeValues)
            }/>
          }
          label="Show native values"
        />
      </FormGroup>
      }
      <TabContext value={selectedTab.toString()}>
        <TabList onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          TabIndicatorProps={{ style: { height: '4px' } }}
          sx={{
            '& .MuiTab-root': {
              padding: "6px 8px"
            }
          }}
          >
          <Tab label="DETAILS" value={TransactionDetailsTabName.Details.toString()}/>
          {!!hasFees && <Tab label="FEES AND CHARGES" value={TransactionDetailsTabName.FeesAndCharges.toString()}/>}
        </TabList>
        <TabPanel value={TransactionDetailsTabName.Details.toString()}
          ref={tabRef}
          style={{ padding: '20px 0 0 0' }}>
          <Grid container spacing={itemSpacing} data-testid={`detailsGrid_${props.testId ?? ''}`}>
            {/* first row */}
            {gridItemPair("Account:", props.transaction?.accountNumber)}
            {gridItemPair("Units:", formatNumberCommaSeparated(props.transaction?.units), true)}
            {/* second row */}
            {gridItemPair("Transaction type:", props.transaction?.systemTransactionType)}
            {gridItemPair("Price:", showNativeValues
              ? formatAmountNoSymbol(props.transaction?.unitPriceNative)
              : formatDollars(props.transaction?.unitPrice), true)}
            {/* third row */}
            {gridItemPair("Transaction date:", getLocalDateTime(props.transaction?.tradeDate, DateTimeFormat.Short))}
            {gridItemPair("Transaction value:", showNativeValues
              ? formatAmountNoSymbol(props.transaction?.transactionValueBeforeFeesNative)
              : formatDollars(props.transaction?.transactionValueBeforeFees), true)}
            {/* fourth row */}
            {gridItemPair("Settlement date:", getLocalDateTime(props.transaction?.settlementDate, DateTimeFormat.Short))}
            {gridItemPair("Fees and charges:", showNativeValues
              ? formatAmountNoSymbol(props.transaction?.totalFeesAndChargesNative)
              : formatDollars(props.transaction?.totalFeesAndCharges), true)}
            {/* fifth */}
            {gridItemPair("Tax date:", getLocalDateTime(props.transaction?.taxDate, DateTimeFormat.Short))}
            {gridItemPair("Transaction value (after fees and charges):", showNativeValues
                ? formatAmountNoSymbol(props.transaction?.transactionValueNative)
                : formatDollars(props.transaction?.transactionValue), true)}
            {/* sixth - currency info (if international) on left and cost base override (if provided) on right*/}
            <Grid item xs={6}>
              {!!isInternational && currencyInfo(props.transaction, props.testId)}
            </Grid>
            {!!props.transaction?.costBaseOverride 
              && gridItemPair("Cost base override:", formatDollars(props.transaction?.costBaseOverride), true)}
          </Grid>
        </TabPanel>
        <TabPanel value={TransactionDetailsTabName.FeesAndCharges.toString()}
          style={{ padding: '12px 0 0 0' }}
          sx={{ minHeight: tabHeight, height: tabHeight }}>
          <FeesAndChargesTable {...feeReportingDetails} ></FeesAndChargesTable>
        </TabPanel>
      </TabContext>
    </Container>
  );
}

const enum TransactionDetailsTabName {
  Details = 0,
  FeesAndCharges = 1,
}

interface FeeReportingDetails {
  feesRows: FeeDetailsRow[] | null;
  totalAmount: string;
  totalGst: string;
  totalCharges: string;
}

const FeesAndChargesTable = (props: FeeReportingDetails) : JSX.Element => {

  const feeFooterStyle = { fontWeight: 'bold' };

  return (
    <Table style={{ padding: '0' }} 
      sx={{
        '& .MuiTableRow-root': {
          borderBottom: "none"
        },
        '& .MuiTableCell-root': {
          padding: "8px"
        }
      }}>
      <TableBody>
        <TableRow>
          <TableCell padding='none'>
            <Typography variant="h5" align={'left'}>
              FEE TYPE
            </Typography>
          </TableCell>
          <TableCell padding='none'>
            <Typography variant="h5" align={'right'}>
              AMOUNT
            </Typography>
          </TableCell>
          <TableCell padding='none'>
            <Typography variant="h5" align={'right'}>
              GST
            </Typography>
          </TableCell>
          <TableCell padding='none'>
            <Typography variant="h5" align={'right'}>
              TOTAL
            </Typography>
          </TableCell>
        </TableRow>
        {props.feesRows?.map((row: FeeDetailsRow) => (
          <TableRow key={row.type}>
            <TableCell>{row.type}</TableCell>
            <TableCell align="right">{row.amount}</TableCell>
            <TableCell align="right">{row.gst}</TableCell>
            <TableCell align="right">{row.total}</TableCell>
          </TableRow>
        ))}
        <TableRow>
          <TableCell>
            <Typography variant="h5" align={'left'} style={feeFooterStyle}>
              Total Fees and Charges
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="h5" align={'right'} style={feeFooterStyle}>
              {props.totalAmount}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="h5" align={'right'} style={feeFooterStyle}>
              {props.totalGst}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="h5" align={'right'} style={feeFooterStyle}>
              {props.totalCharges}
            </Typography>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
};

