export enum MarketCode {
  ADI = 'ADI',
  OffMarket = 'OffMarket',
  ADM = 'ADM',
  ASX = 'ASX',
}

export enum SecurityCategory {
  CMA = 'CMA',
}

export enum GroupedByKey {
  AssetClass = 'assetClass',
  Model = 'modelName',
}

export enum TradeAction {
  Buy = 'Buy',
  Sell = 'Sell',
  All = 'All',
}

export interface ValidationResult {
  errors: string[];
  information: string[];
  warnings: string[];
}

export interface RebalanceValidationRequest {
  investmentServiceId: number;
  rebalanceId: string;
  clientName: string;
  isClientApprovalRequired: boolean;
  preApprovalTradeItems: PreApprovalTrade[];
}

export interface PreApprovalTrade {
  marketCode: string;
  targetValue: number;
  targetPercent: number;
  securityType: string;
  securityToleranceBand: string;
  securityName: string;
  securityId: number;
  securityCode: string;
  securityCategory: string;
  productCode: string;
  productName: string;
  modelCode: string;
  modelName: string;
  priceLimit: number;
  priceType: string;
  unitPrice: number;
  id: string;
  calculatedTradeValue: number;
  calculatedTradeUnits: number;
  currentPercent: number;
  currentUnitPrice: number;
  currentUnitPriceTime: string;
  currentUnits: number;
  currentValue: number;
  expiryDate: string;
  expiryType: string;
  comment: string;
  assetClass: string;
  assetClassId: number;
  tradeAction: TradeAction;
}

export interface PreApprovalRebalance {
  afslId: number;
  availableCMA: number;
  canRebalanceByModel: boolean;
  clientName: string;
  comments: string;
  investmentServiceId: number;
  isClientApprovalRequired: boolean;
  rebalanceId: string;
  serviceType: string;
  totalPortfolioValue: number;
  preApprovalTradeItems: PreApprovalTrade[];
  validationResult: ValidationResult;
}

export interface ValidateRebalanceSuccessPayload {
  rebalanceValidationRequest: RebalanceValidationRequest;
  validationResult: ValidationResult;
}
