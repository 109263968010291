import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../../app/api';
import { FxDetailParameters, FxDetails } from './types';

export enum CommonActionTypes {
  FetchFxDetails = '@@forexrates/common/GetForexCurrencyPairDetails',
}

export enum CommonApi {
  FetchFxDetails = '/securities/GetForexCurrencyPairDetails',
}

export const fetchFxDetails = createAsyncThunk(CommonActionTypes.FetchFxDetails, async (request: FxDetailParameters) => {
  const response = await api.get<FxDetails>(`${CommonApi.FetchFxDetails}?baseCurrencyId=${request.baesCurrencyId}&quoteCurrencyId=${request.quoteCurrencyId}`);
  return response.data;
});
