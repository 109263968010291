import React from 'react';
import { useParams } from 'react-router-dom';
import { Edit, EditProps } from '../../../../common/components/contacts';

export interface EditContactRouteParams {
  id?: string;
  trusteeId?: string;
  contactId?: string;
}

export const ContactEdit = (props: EditProps): JSX.Element => {
  const routeParams = useParams<EditContactRouteParams>();
  const clientId = !!routeParams.id ? parseInt(routeParams.id, 10) : null;

  return <Edit {...{ ...props, parentId: clientId }} />;
};
