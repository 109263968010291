import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../../../../app/api';
import {
  RealisedCapitalGainsLossesResult,
  RealisedGainsAndLossesRequest,
  UnrealisedCapitalGainsLossesResult,
  UnrealisedGainsAndLossesRequest,
} from './types';

export enum CGTOverviewActionTypes {
  FetchUnrealisedCapitalGainsAndLosses = '@@client/capitalGainsAndLosses/Overview/UnrealisedCapitalGainsAndLosses/Fetch',
  FetchRealisedCapitalGainsAndLosses = '@@client/capitalGainsAndLosses/Overview/RealisedCapitalGainsAndLosses/Fetch',
}

export enum CGTOverviewApiEndpoints {
  FetchUnrealisedCapitalGainsAndLosses = '/unrealised/GetUnrealisedCapitalGainsAndLosses',
  FetchRealisedCapitalGainsAndLosses = '/realised/GetRealisedCapitalGainsAndLosses',
}

export const fetchUnrealisedCapitalGainsAndLosses = createAsyncThunk(
  CGTOverviewActionTypes.FetchUnrealisedCapitalGainsAndLosses,
  async (payload: UnrealisedGainsAndLossesRequest) => {
    const queryString = `?clientId=${payload.clientId}&DateTo=${payload.dateTo}`;

    const response = await api.get<UnrealisedCapitalGainsLossesResult>(
      `${CGTOverviewApiEndpoints.FetchUnrealisedCapitalGainsAndLosses}${queryString}`
    );
    return response.data;
  }
);

export const fetchRealisedCapitalGainsAndLosses = createAsyncThunk(
  CGTOverviewActionTypes.FetchRealisedCapitalGainsAndLosses,
  async (payload: RealisedGainsAndLossesRequest) => {
    const queryString = `?clientId=${payload.clientId}&DateFrom=${payload.dateFrom}&DateTo=${payload.dateTo}`;
    const response = await api.get<RealisedCapitalGainsLossesResult>(
      `${CGTOverviewApiEndpoints.FetchRealisedCapitalGainsAndLosses}${queryString}`
    );
    return response.data;
  }
);
