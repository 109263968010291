import { Box, Card, CardContent, IconButton, Tooltip, Typography, useTheme, TextField } from '@mui/material';
import { WO2ToggleSwitch } from 'src/common';
import { AdviceServiceModel } from 'src/features/clients/client/details/adviceFees/store/types';
import { Edit, Save } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import * as yup from 'yup';

export interface AdviceFeesConsentProps {
  adviceServicesList: AdviceServiceModel[];
  isEditingAdviceServiceList: boolean;
  clientId: number | null;
  toggleAdviceServiceList: () => void;
  handleClickSaveAdviceServiceList: (adviceServices: string, adviceServicesOtherText?: string, saveQuietly?: boolean) => void;
  handleAdviceFeeConsentToggle: (id: number) => void;
  hasPendingFees: boolean;
}

export const AdviceFeesConsent = ({
  hasPendingFees,
  adviceServicesList,
  handleAdviceFeeConsentToggle,
  isEditingAdviceServiceList,
  toggleAdviceServiceList,
  handleClickSaveAdviceServiceList,
  clientId,
}: AdviceFeesConsentProps): JSX.Element | null => {
  const theme = useTheme();

  const [otherText, setOtherText] = useState<string>('');
  const [validationMessage, setValidationMessage] = useState<string>('');

  const validationSchema = yup.object({
    otherText: yup.string().max(255, 'Description cannot exceed 255 characters')
  });

  const validateInput = (input: string) => {
    setValidationMessage('');

    validationSchema
      .validate({ otherText: input })
      .catch((e) => {
        setValidationMessage(e.message);
      });
  };

  const getSelectedAdviceServices = () => {
    const selected = adviceServicesList.filter((advice) => advice.isSelected).map((advice) => advice.id);
    let adviceServices = selected.join(',');
    if (adviceServices.length === 0) {
      adviceServices = hasPendingFees ? "" : "-1";
    }
    return adviceServices;
  }

  const handleEditOrSaveClick = () => {
    if (clientId && isEditingAdviceServiceList) {
      const services = getSelectedAdviceServices();

      const otherSelected = adviceServicesList
        .filter((advice) => advice.name === 'Othr' && advice.isSelected).length > 0;

      if (!otherSelected) {
        setOtherText('');
      }

      if (validationMessage === '') {
        handleClickSaveAdviceServiceList(services, otherText !== null ? otherText : undefined);
      }
    }
    toggleAdviceServiceList();
  };

  useEffect(() => {
    if (adviceServicesList.length > 0) {
      const services = getSelectedAdviceServices();
      if (services === '-1' || services === "") {
        handleClickSaveAdviceServiceList(services, '', true);
      }
      else {
        const otherAdviceService = adviceServicesList.filter((advice) => advice.name === 'Othr' && advice.isSelected);
        if (otherAdviceService.length != 0) {
          setOtherText(otherAdviceService[0].serviceDetails ?? '');
        }
      }
    }
  }, [adviceServicesList.length, hasPendingFees, !hasPendingFees])

  if (adviceServicesList.length === 0) {
    return null;
  }

  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="h2" style={{ fontSize: '18px' }} gutterBottom>
        Your advice services
      </Typography>
      <Card elevation={4} variant="elevation" style={{ textAlign: 'left' }}>
        <CardContent style={{ padding: '12px 16px' }}>
          <Box display="flex" flexDirection="column">
            <Box display="flex" justifyContent="space-between" mb={2} gap={5} alignItems="center">
              <Typography color={theme.palette.grey[400]} variant="h5" component="h2">
                Member ongoing fee consent is required to include information about the services that the member will be
                entitled to receive during the period of any fee arrangement. Please select the services the member is
                entitled to which will pre populate the ongoing fee consent document as part of the initial application
                form or the ongoing fee consent renewal.
              </Typography>
              <Tooltip title={isEditingAdviceServiceList ? 'Save' : 'Edit'}>
                <IconButton onClick={handleEditOrSaveClick} color={'primary'} disabled={validationMessage !== ''}>
                  {isEditingAdviceServiceList ? <Save /> : <Edit />}
                </IconButton>
              </Tooltip>
            </Box>
            {adviceServicesList.map((item, index) => (
              <Box key={index}>
                <Box display="flex" gap={2} alignItems="center">
                  <WO2ToggleSwitch
                    disabled={!isEditingAdviceServiceList}
                    onChange={() => handleAdviceFeeConsentToggle(item.id)}
                    key={index}
                    value={item.isSelected}
                    name={item.name}
                    checked={item.isSelected}
                  />
                  <Typography>{item.description}</Typography>
                </Box>
                {
                  (item.name === 'Othr' && item.isSelected) && (
                    <Box>
                      <Box display="flex" sx={{marginTop: 0.5}}>
                        <TextField 
                          fullWidth 
                          multiline 
                          rows={4}
                          value={otherText}
                          onChange={(e) => {
                            const input =  e.currentTarget.value ?? '';
                            setOtherText(input);
                            validateInput(input);
                          }}
                          disabled={!isEditingAdviceServiceList}
                        />
                      </Box>
                      {
                        validationMessage !== '' && (
                          <Typography color={theme.palette.error.main} variant="h5" sx={{marginTop: 1.5, marginLeft: 2}}>
                            {validationMessage}
                          </Typography>
                        )
                      }
                      <Typography color={theme.palette.grey[400]} variant="h5" sx={{marginTop: 1.5, marginLeft: 2}}>
                        * Enter up to 255 characters
                      </Typography>
                    </Box>
                  )
                }
              </Box>
            ))}
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};
