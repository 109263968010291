import { AddOutlined, Info, LinkOffOutlined, MoreVertOutlined } from '@mui/icons-material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Box, IconButton, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { remove } from 'lodash';
import React, { useEffect } from 'react';
import { LoadingProgress } from '../../store/types';
import { DialogActionButton, WO2Modal } from '../Modal';
import { ClientSideDataTable } from '../dataTable/clientSide';
import { DatatableColumn, FilterDataType } from '../dataTable/types';
import { theme } from './../../../themes';
import { StatusType } from './../../types';
import { WO2Menu } from './../Menu';
import { AccountDetail } from './AccountDetail';
import { WO2AccountView } from './accountView';
import { WO2ExternalAccount } from './forms/externalAccount';
import { MissingInformation } from './forms/missingInformation';
import { WO2UnlistedAccount } from './forms/unlistedAccount';
import {
  Account,
  AccountInstitutionType,
  AccountType,
  DetachAccountPayload,
  Errors,
  ExternalAccount,
  ExternalInstitution,
  OtherAccountTypes,
  SecurityStatus,
  UnlistedAccount,
  WO2AccountActionType,
} from './types';
import { BPAYAccountDetail } from 'src/features/clients/client/details/accounts/store/types';
import InfoIcon from '@mui/icons-material/Info';

export interface AccountProps {
  accounts: Account[];
  bPayAccDetails?: BPAYAccountDetail[] | null | undefined;
  accountTypes?: AccountType[];
  actions?: WO2AccountActionType[];
  superSimplifierAccountStatus?: number;
  superSimplifierAccountString?: string | null;
  clientId?: number | null;
  fetchAccountTypes?: () => void;
  externalInstitutions?: ExternalInstitution[];
  hideAddButton?: boolean;
  hideAllActions?: boolean;
  infoMessage?: string;
  id?: string;
  isExternal: boolean;
  isReadOnly: boolean;
  isSuper?: boolean;
  loadingProgress: LoadingProgress;
  loadingTypesProgress?: LoadingProgress;
  onAddEdit?: (id: number | null | undefined) => void;
  onDetach?: (payload: DetachAccountPayload) => void;
  onSaveUnlistedForm?: (account: UnlistedAccount) => void;
  onSaveExternal?: (account: ExternalAccount) => void;
  saveExternalAccountProgress?: LoadingProgress;
  saveUnlistedAccountProgress?: LoadingProgress;
  selectedAccount?: Account | null;
  missingInfoAccountId?: number | null;
  onCloseMissingInfo?: () => void;
  errors: Errors[] | null;
  fetchExternalInstitutions: () => void;
  entityDetailsType?: string;
  entityDetailsSubTypeId?: number | null;
  feeConsentReferenceDateErrMsg?: string | null;
}
export const useStyles = makeStyles(() => ({
  addToTable: {
    backgroundColor: theme.palette.primary.main,
    fontSize: '1.25rem !important',
    padding: '4px',
    margin: '12px',
    color: theme.palette.common.white,
    '& > span > svg': {
      fontSize: '1.25rem !important',
    },
  }
}));
export function WO2Accounts({
  accounts,
  bPayAccDetails,
  accountTypes,
  actions,
  superSimplifierAccountStatus,
  superSimplifierAccountString,
  clientId,
  externalInstitutions,
  fetchAccountTypes,
  hideAddButton,
  hideAllActions,
  infoMessage,
  id,
  isExternal,
  isReadOnly,
  isSuper,
  loadingProgress,
  loadingTypesProgress,
  onAddEdit,
  onDetach,
  onSaveExternal,
  onSaveUnlistedForm,
  saveExternalAccountProgress,
  saveUnlistedAccountProgress,
  selectedAccount,
  missingInfoAccountId,
  onCloseMissingInfo,
  errors,
  fetchExternalInstitutions,
  entityDetailsType,
  entityDetailsSubTypeId,
  feeConsentReferenceDateErrMsg
}: AccountProps): JSX.Element {
  const classes = useStyles();

  // Elements
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectAccount, setSelectedAccount] = React.useState<Account | null>(null);

  // Modals/Menus
  const [detachAccountModal, setDetachAccountModal] = React.useState<boolean>(false);

  // filtered accounts
  const [filteredAccountTypes, setFilteredAccountTypes] = React.useState<AccountInstitutionType[]>([]);
  const [selectedCreateFormState, selectCreateForm] = React.useState<number | string | null>(null);
  const filterAllowedAccountTypes = (allAccounts: Account[]): AccountInstitutionType[] => {
    const allowed = AccountInstitutionType.getArray();
    allAccounts
      .filter((account) => account.institutionId !== AccountInstitutionType.Unlisted.id)
      .forEach((account) => {
        for (let j = 0; j < allowed.length; j++) {
          if (account.institutionId === allowed[j].id) {
            remove(allowed, allowed[j]);
          }
        }
      });

    return allowed;
  };
  useEffect(() => {
    const allFilteredAccounts = filterAllowedAccountTypes(accounts);
    setFilteredAccountTypes(allFilteredAccounts);
  }, [accounts]);
  // ui components
  const dialogActionButtons: DialogActionButton[] = [
    {
      label: 'Cancel',
      onClickHandler: () => {
        setDetachAccountModal(false);
      },
      type: 'outlined',
    },
    {
      label: 'Detach',
      onClickHandler: async () => {
        if (!!clientId && selectAccount !== null && !!onDetach) {
          onDetach({ clientId, accountId: selectAccount.accountId });
          setDetachAccountModal(false);
        }
      },
      type: 'contained',
    },
  ];

  // columns
  const detailsColumn = (dataIndex: number): React.ReactNode => {
    return accounts.length > 0 && !!accounts[dataIndex] ? (
      <AccountDetail 
        isSuperSimplifier={!!superSimplifierAccountStatus}
        account={accounts[dataIndex]}
        entityDetailsType={entityDetailsType}
        entityDetailsSubTypeId={entityDetailsSubTypeId} />
    ) : (
      <></>
    );
  };

  const getStatus = (dataIndex: number): React.ReactNode => {
    let statusText = !!superSimplifierAccountString ? superSimplifierAccountString : undefined;
    if (statusText !== undefined) return <Typography>{statusText}</Typography>;

    statusText = !!superSimplifierAccountStatus
      ? StatusType.getById(superSimplifierAccountStatus)?.displayName
      : statusColumn(dataIndex);

    let statusEl: JSX.Element = (
      <Typography>{statusText}</Typography>
    );

    if (feeConsentReferenceDateErrMsg) {
      statusEl = (
        <Box>
          <Box style={{textAlign: 'left'}} display={'flex'} justifyContent={'center'}>
            <InfoIcon style={
                { 
                  width: '20px', 
                  height: '20px',
                  marginRight: '10px',
                  marginTop: '10px',
                  fill: `${theme.palette.primary.main}` 
                }
              } />
            <Typography data-testid={'span_feeConsentReferenceDateErrMsg'}>
              {feeConsentReferenceDateErrMsg}
            </Typography>
          </Box>
        </Box>
      );
    }

    return statusEl;
  };

  const statusColumn = (dataIndex: number): string => {
    let statusText = '';
    if (!!accounts[dataIndex] && accounts[dataIndex].securityStatus !== null) {
      const securityStatusId = accounts[dataIndex].securityStatus;
      statusText = !!securityStatusId ? SecurityStatus.getById(securityStatusId)?.displayName || '' : '';
    }
    return statusText;
  };

  const accountIdColumn = (dataIndex: number): React.ReactNode => {
    let accountId = 0;
    if (!!accounts[dataIndex] && accounts[dataIndex].accountId !== null) {
      accountId = accounts[dataIndex].accountId;
    }
    return (
      <Typography align="right" style={{ paddingRight: '10px' }}>
        {accountId === -1 ? '' : accountId ?? ''}
      </Typography>
    );
  };

  const formatBpayNumber = (bpayNum: string) => {
    let formattedNumber = bpayNum ?? '';
    if (bpayNum) {
      if (bpayNum.length > 3) {
        const groups = bpayNum.match(/.{1,3}/g);
        if (groups && groups.length > 0) {
          formattedNumber = groups.join(' ');
        }
      }
    }
    return formattedNumber;
  }

  const bPayBillerCodeColumn = (dataIndex: number): React.ReactNode => {
    let bPayBillerCode = "";
    if (!!bPayAccDetails && !!bPayAccDetails[dataIndex] && bPayAccDetails[dataIndex].bPayBillerCode !== undefined) {
      bPayBillerCode = bPayAccDetails[dataIndex].bPayBillerCode;
    }
    return (
      <Typography 
        align="right" 
        style={{ paddingRight: '10px', fontWeight: 'bold' }}
        data-testid={`accountBPAYBillCode_${dataIndex}`}>
        {formatBpayNumber(bPayBillerCode)}
      </Typography>
    );
  };

  const getCustomerRefNumber = (dataIndex: number): string => {
    let customerReferenceNumber = "";
    if (!!bPayAccDetails && !!bPayAccDetails[dataIndex] && bPayAccDetails[dataIndex].customerReferenceNumber !== undefined) {
      customerReferenceNumber = bPayAccDetails[dataIndex].customerReferenceNumber;
    }
    return customerReferenceNumber;
  }

  const bPayCustomerRefColumn = (dataIndex: number): React.ReactNode => {
    const customerReferenceNumber = getCustomerRefNumber(dataIndex);
    return (
      <Typography 
        align="right" 
        style={{ 
          paddingRight: '10px', 
          fontWeight: 'bold'
        }}
        data-testid={`customRefTxt_${dataIndex}`}>
        {formatBpayNumber(customerReferenceNumber)}
      </Typography>
    );
  };

  const actionsColumn = (dataIndex: number): React.ReactNode => {
    const actionsList = !!actions
      ? actions.map((action: WO2AccountActionType) => ({
          icon: action.icon,
          label: action.label,
          onClick: () => {
            const account: Account = accounts[dataIndex];
            const property: keyof Account = action.name;

            if (account !== null && property !== null) {
              action.clickHandler(account[property] as number);
            }
          },
          testId: `accountAction_${action.label.replace(/\s/g, '')}_${dataIndex}`,
          disabled: action.disabled,
        }))
      : [];

    if (!!onDetach) {
      actionsList.push({
        icon: <LinkOffOutlined color="primary" />,
        label: 'Detach Account',
        onClick: () => {
          const account: Account = accounts[dataIndex];
          if (account !== null) {
            setSelectedAccount(account);
            setDetachAccountModal(true);
          }
        },
        testId: `accountAction_detach_${dataIndex}`,
        disabled: undefined,
      });
    }

    let actionMenuEl: JSX.Element = (
      <WO2Menu
        testId={`accountActionsButton_${!!id ? id + '_' : ''}${dataIndex}`}
        buttonTitle="Client Account Actions"
        buttonIcon={<MoreVertOutlined color="primary" />}
        items={actionsList}
      />
    );

    if (feeConsentReferenceDateErrMsg) {
      actionMenuEl = (
        <div></div>
      );
    }

    return actionMenuEl;
  };

  const infoColumn = (dataIndex: number): React.ReactNode => {
    return (
      <Box style={{ padding: '8px' }} data-testid={`accountsInfoMessage_${dataIndex}`}>
        <Tooltip title={infoMessage ?? ''} placement={'top'} arrow>
          <Info style={{ width: '18px', height: '18px', marginLeft: '4px', fill: `${theme.palette.primary.main}` }} />
        </Tooltip>
      </Box>
    );
  };

  const openAddMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleCloseAddMenu = () => {
    setMenuAnchorEl(null);
  };
  const handleCreateForm = (name: string) => {
    setMenuAnchorEl(null);
    selectCreateForm(name);
  };

  const onSaveUnlisted = (account: UnlistedAccount) => {
    selectCreateForm('CREATED');
    if (!!onSaveUnlistedForm) {
      onSaveUnlistedForm(account);
    }
  };

  const onSaveExternalAccount = (account: ExternalAccount) => {
    selectCreateForm('CREATED');
    if (!!onSaveExternal) {
      onSaveExternal(account);
    }
  };

  const addButton = (): React.ReactNode => (
    <>
      <Tooltip title="Add Account">
        <IconButton
          className={classes.addToTable}
          disableFocusRipple
          disableRipple
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            openAddMenu(event);
          }}
          data-testid={`add${isExternal ? 'External' : 'Portfolio'}AccountButton`}
        >
          <AddOutlined />
        </IconButton>
      </Tooltip>
      <Menu anchorEl={menuAnchorEl} keepMounted open={Boolean(menuAnchorEl)} onClose={handleCloseAddMenu}>
        {!isExternal &&
          !!filteredAccountTypes.length &&
          filteredAccountTypes
            .filter((accountType) => accountType.name != AccountInstitutionType.ManagedFunds.name)
            .map((accountType, index: number) => (
              <MenuItem
                key={index}
                onClick={() => {
                  handleCreateForm(accountType.name);
                }}
                data-testid={`addAccountButton_${accountType.name}`}
              >
                Add {accountType.displayName} Account
              </MenuItem>
            ))}
        {isExternal && (
          <MenuItem
            onClick={() => {
              handleCreateForm(OtherAccountTypes.External);
            }}
            data-testid={`addAccountButtonExternal`}
          >
            &nbsp; Add External Account
          </MenuItem>
        )}
      </Menu>
    </>
  );

  const copyToClipboard = (refNum: string) => {
    navigator.clipboard.writeText(refNum);
  }

  const copyButton = (refNumber: string): React.ReactNode => (
    <>
      <Tooltip title='Copy CRN to Clipboard' style={{padding: '0px 10px 0px 0px'}}>
        <IconButton
          data-testid={'copyCRNButton'}
          onClick={() => {copyToClipboard(refNumber)}}>

          <ContentCopyIcon />    
        </IconButton>
      </Tooltip>
    </>
  );

  const columns: DatatableColumn[] = [
    {
      filterDataType: FilterDataType.string,
      name: 'accountName',
      label: 'ACCOUNT DETAILS',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: function customBodyRenderLite(dataIndex: number): React.ReactNode {
          return (
            <div style={{ paddingRight: '10px' }}>
              <Typography variant={'h5'} align="right">
                {detailsColumn(dataIndex)}
              </Typography>
            </div>
          );
        },
        customHeadLabelRender: function customHeadLabelRender(): React.ReactNode {
          return (
            <Typography
              variant="h6"
              align="right"
              style={{
                textAlign: 'right',
                textTransform: 'uppercase',
                paddingLeft: '50px',
                color: '#7d7d7d',
                fontSize: '11px',
              }}
            >
              ACCOUNT DETAILS
            </Typography>
          );
        },
      },
    },
    {
      filterDataType: FilterDataType.string,
      name: 'bPayBillerCode',
      label: 'BPAY BILLER CODE',
      options: {
        filter: true,
        sort: true,
        display: !!isSuper,
        customBodyRenderLite: function customBodyRenderLite(dataIndex: number): React.ReactNode {
          return (
            <div 
              style={{ paddingRight: '10px', float: 'left', paddingLeft: '55px'}} 
              data-testid={`accountBPAYBillCode_${dataIndex}`}>
              <Typography variant={'h5'} align="right">
                {bPayBillerCodeColumn(dataIndex)}
              </Typography>
            </div>
          );
        },
        customHeadLabelRender: function customHeadLabelRender(): React.ReactNode {
          return (
            <Typography
              variant="h6"
              align="right"
              style={{
                textAlign: 'right',
                textTransform: 'uppercase',
                paddingLeft: '50px',
                color: '#7d7d7d',
                fontSize: '11px',
              }}
            >
              BPAY BILLER CODE
            </Typography>
          );
        },
      },
    },
    {
      filterDataType: FilterDataType.string,
      name: 'bPayCustomerRef',
      label: 'BPAY CUSTOMER REFERENCE',
      options: {
        filter: true,
        sort: true,
        display: !!isSuper,
        customBodyRenderLite: function customBodyRenderLite(dataIndex: number): React.ReactNode {
          const accountCrn = getCustomerRefNumber(dataIndex);
          return ( 
            <div 
              style={{display: 'flex', flexDirection: 'column', float: 'left', paddingLeft: '60px'}}
              data-testid={`accountBPAYCustRef_${dataIndex}`}>
              {!!accountCrn && accountCrn.length > 0 && (
                <>
                  <div style={{ display: 'flex', float: 'right'}}>
                    {copyButton(accountCrn)}
                    <Typography variant={'h5'} align="right">
                      {bPayCustomerRefColumn(dataIndex)}
                    </Typography>
                  </div>
                  <Typography align='left' noWrap={false}>
                    For non-concessional contributions
                  </Typography>
                </>
              )}
            </div>
          );
        },
        customHeadLabelRender: function customHeadLabelRender(): React.ReactNode {
          return (
            <Typography
              variant="h6"
              align="right"
              style={{
                textAlign: 'right',
                textTransform: 'uppercase',
                paddingLeft: '50px',
                color: '#7d7d7d',
                fontSize: '11px',
              }}
            >
              BPAY CUSTOMER REFERENCE
            </Typography>
          );
        },
      },
    },
    {
      filterDataType: FilterDataType.string,
      name: 'accountId',
      label: 'Account ID',
      options: {
        filter: true,
        sort: true,
        display: !superSimplifierAccountStatus,
        customBodyRenderLite: function customBodyRenderLite(dataIndex: number): React.ReactNode {
          return (
            <Typography variant={'h5'} align="right" style={{ width: '100px' }}>
              {accountIdColumn(dataIndex)}
            </Typography>
          );
        },
        customHeadLabelRender: function customHeadLabelRender(): React.ReactNode {
          return (
            <Typography
              variant="h6"
              align="right"
              style={{
                textAlign: 'right',
                textTransform: 'uppercase',
                color: '#7d7d7d',
                fontSize: '11px',
                width: '100px',
                paddingRight: '10px',
              }}
            >
              Account ID
            </Typography>
          );
        },
      },
    },
    {
      name: 'securityStatus',
      label: 'STATUS',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: function customBodyRenderLite(dataIndex: number): React.ReactNode {
          return (
            <div>
              <Typography variant={'h5'} align="right">
                {getStatus(dataIndex)}
              </Typography>
            </div>
          );
        },
        customHeadLabelRender: function customHeadLabelRender(): React.ReactNode {
          return (
            <Typography
              variant="h6"
              align={feeConsentReferenceDateErrMsg ? "center" : "right"}
              style={{
                textTransform: 'uppercase',
                paddingLeft: '50px',
                color: '#7d7d7d',
                fontSize: '11px',
              }}
            >
              STATUS
            </Typography>
          );
        },
      },
    },
    {
      name: 'actions',
      label: ' ',
      textAlign: 'right',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex: number): React.ReactNode => actionsColumn(dataIndex),
      },
    },
    {
      name: 'info',
      label: ' ',
      textAlign: 'right',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex: number): React.ReactNode => infoColumn(dataIndex),
      },
    },
  ];

  // TODO: Impelment fix for correct BSB for #6560
  const currentAccount = !!accounts && accounts.find((a) => a.accountId === selectedAccount?.accountId);
  const bsbNumber = !!currentAccount ? currentAccount.bsb : '';

  return (
    <>
      <ClientSideDataTable
        loadingProgress={loadingProgress}
        columns={columns.filter(
          (c) => (hideAllActions === false || c.name !== 'actions') && (!!infoMessage || c.name !== 'info')
        )}
        data={accounts}
        options={{
          filter: false,
          pagination: true,
          viewColumns: false,
          customToolbar: () => !hideAddButton && addButton(),
        }}
        id={`${id}-accounts-datatable`}
      ></ClientSideDataTable>
      {!!externalInstitutions && externalInstitutions.length > 0 && (
        <WO2ExternalAccount
          saveProgress={saveExternalAccountProgress}
          onSave={onSaveExternalAccount}
          handleCloseModal={() => {
            selectCreateForm(null);
          }}
          externalInstitutions={externalInstitutions}
          isOpen={selectedCreateFormState === OtherAccountTypes.External}
        />
      )}
      {clientId && (
        <WO2UnlistedAccount
          saveProgress={saveUnlistedAccountProgress}
          onSave={onSaveUnlisted}
          handleCloseModal={() => {
            selectCreateForm(null);
          }}
          isOpen={selectedCreateFormState === AccountInstitutionType.Unlisted.name}
        />
      )}
      {selectedAccount !== undefined &&
        !!isReadOnly &&
        !!onAddEdit &&
        !!accountTypes &&
        !!loadingTypesProgress &&
        !!fetchAccountTypes &&
        !!externalInstitutions &&
        !!fetchExternalInstitutions && (
          <WO2AccountView
            isReadOnly={isReadOnly}
            isExternal={isExternal}
            bsb={bsbNumber}
            accountTypes={accountTypes}
            handleCloseModal={() => onAddEdit(undefined)}
            account={selectedAccount}
            fetchAccountTypes={fetchAccountTypes}
            loadingProgress={loadingProgress}
            loadingTypesProgress={loadingTypesProgress}
            externalInstitutions={externalInstitutions}
            fetchExternalInstitutions={fetchExternalInstitutions}
          />
        )}

      {/* <WO2Modal
        MuiDialogProps={{
          open: !!missingInfoAccountId,
          onClose: () => {
            !!onCloseMissingInfo && onCloseMissingInfo();
          },
        }}
        title={<>MISSING INFORMATION</>}
        actions={missingInfoDialogActionButtons}
      >
        <p>The below information is mandatory to set up the account. Once provided the account application can be submitted. </p>
      </WO2Modal> */}

      <WO2Modal
        MuiDialogProps={{
          open: detachAccountModal,
          onClose: () => {
            setDetachAccountModal(false);
          },
        }}
        title={<>DETACH ACCOUNT</>}
        actions={dialogActionButtons}
      >
        <p>Are you sure you want to detach this account?</p>
      </WO2Modal>

      {!!missingInfoAccountId && errors !== undefined && errors !== null && errors?.length !== 0 && (
        <MissingInformation
          errors={errors ?? []}
          onClose={() => {
            !!onCloseMissingInfo && onCloseMissingInfo();
          }}
        ></MissingInformation>
      )}
    </>
  );
}
