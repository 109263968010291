import SearchIcon from '@mui/icons-material/Search';
import { Autocomplete as MuiAutocomplete, InputAdornment, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDebounce } from 'src/common/hooks';

interface Props<T> {
  label?: string;
  value: T | null;
  options: T[];
  optionLabel: (item: T) => string;
  onOptionChange: (value: T | null) => void;
  onSearchChange?: (value: string) => void;
  getNewOptions?: (value: string) => void;
}

export function AsyncAutocomplete<T>(props: Props<T>): JSX.Element {
  const { label, value, options, optionLabel, onOptionChange, onSearchChange, getNewOptions, ...rest } = props;
  const [searchTerm, setSearchTerm] = useState<string>('');
  const onSearchTermChange = useDebounce<string>(searchTerm, 500);

  useEffect(() => {
    if (onSearchTermChange && onSearchChange && getNewOptions && onSearchTermChange.length > 2) {
      onSearchChange(onSearchTermChange);
      getNewOptions(onSearchTermChange);
    }
  }, [onSearchTermChange, getNewOptions, onSearchChange]);

  return (
    <MuiAutocomplete
      {...rest}
      options={options ?? []}
      value={value}
      fullWidth
      getOptionLabel={optionLabel}
      onChange={(event: unknown, value: T | null) => onOptionChange(value)}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          fullWidth
          onChange={(event) => {
            setSearchTerm && setSearchTerm(event.target.value);
          }}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon color="primary" fontSize="small" />
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
}
