import { createSelector } from '@reduxjs/toolkit';
import { selectSearchResults, selectSearchSelectedResults } from '../../search/store/selectors';
import { OrderSearchResult } from '../../search/store/types';
import { selectOrder } from '../../selectors';
import { BulkOrder, PreApprovalInvestmentService, PreApprovalTradeItem } from '../../types';
import { Order, OrderEditResult, PortfolioSecurityHoldingItem, TradeAction, WeightsType, PriceType } from './types';
import { selectBulk } from 'src/features/bulk/selectors';
import { BulkState } from 'src/features/bulk/types';

export const selectCommon = createSelector(selectBulk, (bulkState: BulkState) => bulkState.common);
export const selectEdit = createSelector(selectOrder, (orderState) => orderState.edit);
export const selectParameters = createSelector(selectEdit, (editState) => editState.parameters);
export const selectSecurities = createSelector(selectCommon, (commonState) => commonState.securities);
export const selectInvestmentServicesLockedByOrders = createSelector(
  selectEdit,
  (editState) => editState.investmentServicesLockedByOrders
);
export const selectValidationMessages = createSelector(selectEdit, (editState) => editState.validationMessages);
export const selectSecurityHoldings = createSelector(selectEdit, (editState) => editState.securityHoldings);
export const selectIsPreApproved = createSelector(selectEdit, (editState) => editState.isPreApproved);
export const selectEditResults = createSelector(
  selectSearchResults,
  selectSearchSelectedResults,
  selectSecurityHoldings,
  selectParameters,
  (searchResults, selectedSearchResults, securityHoldings, parameters) => {
    const selected: OrderSearchResult[] = [];
    const unitPrice =
      parameters.priceType === PriceType.Market || parameters.priceType === null
        ? securityHoldings?.unitPrice ?? 0
        : parameters?.priceLimit || 0;
    selectedSearchResults.forEach((i) => selected.push(searchResults[i]));

    const result: OrderEditResult[] = selected.map((r) => {
      const holding = securityHoldings?.holdings.find(
        (i: PortfolioSecurityHoldingItem) => i.portfolioId === r.portfolioId
      );
      const marketValue = holding?.portfolioValue || r.marketValue;

      const proposedValue =
        holding && securityHoldings
          ? securityHoldings.tradeAction === TradeAction.Buy.name
            ? (holding.units + holding.newUnits) * unitPrice
            : (holding.units - holding.newUnits) * unitPrice
          : 0;
      const proposedCash =
        holding && securityHoldings
          ? securityHoldings.tradeAction === TradeAction.Buy.name
            ? r.cashBalance - holding.newUnits * unitPrice
            : r.cashBalance + holding.newUnits * unitPrice
          : 0;
      const tradeValue = (holding?.newUnits || 0) * unitPrice;
      const proposedCashAssetClassTotal =
        securityHoldings?.tradeAction === TradeAction.Buy.name
          ? r.cashAssetAllocationTotal - tradeValue
          : r.cashAssetAllocationTotal + tradeValue;
      const proposedValueAssetClassTotal =
        securityHoldings?.tradeAction === TradeAction.Buy.name
          ? (holding?.assetClassTotal || 0) + tradeValue
          : (holding?.assetClassTotal || 0) - tradeValue;
      const currentPercentage =
        parameters.weightsType === WeightsType.Portfolio
          ? holding?.securityCurrentPercentagePortfolio || 0
          : holding?.securityCurrentPercentageAssetClass || 0;
      return {
        clientId: r.clientId,
        clientName: r.clientName,
        portfolioId: r.portfolioId,
        portfolioName: r.portfolioName,
        marketValue,
        amount: holding?.amount || 0,
        tradeValue,
        tradeUnits: holding?.newUnits || 0,
        currentValue: (holding?.units || 0) * (securityHoldings?.unitPrice ?? 0),
        currentUnits: holding?.units || 0,
        proposedValue,
        cashBalance: r.cashBalance,
        proposedCash,
        targetPercentage: holding ? holding.targetPercentage : 0,
        currentPercentage: currentPercentage,
        proposedPercentage:
          parameters.weightsType === WeightsType.Portfolio
            ? proposedValue / marketValue
            : proposedValue / proposedValueAssetClassTotal,
        cashPercentage:
          parameters.weightsType === WeightsType.Portfolio ? r.portfolioPercentage : r.assetClassPercentage,
        proposedCashPercentage:
          parameters.weightsType === WeightsType.Portfolio
            ? proposedCash / marketValue
            : proposedCash / proposedCashAssetClassTotal,
      };
    });
    return result;
  }
);

export const selectBulkOrder = createSelector(
  selectSearchResults,
  selectSearchSelectedResults,
  selectSecurityHoldings,
  selectEdit,
  (searchResults, selectedSearchResults, securityHoldings, edit) => {
    if (securityHoldings) {
      const orders: Order[] = [];

      selectedSearchResults.forEach((i) => {
        const order = searchResults[i];
        const holding = securityHoldings.holdings.find((h) => h.portfolioId === order.portfolioId);
        if (holding && holding.newUnits !== 0) {
          orders.push({
            portfolioId: holding.portfolioId,
            units: holding.newUnits,
          });
        }
      });

      const bulkOrder: BulkOrder = {
        security: {
          code: securityHoldings.code,
          name: securityHoldings.name,
          id: securityHoldings.securityId,
          unitPrice: securityHoldings.unitPrice,
          priceDate: securityHoldings.priceDate,
          priceType: edit.parameters.priceType,
          priceLimit: edit.parameters.priceLimit,
          expiryType: edit.parameters.expiryType,
          expiryDate: edit.parameters.expiryDate,
          assetClass: securityHoldings?.holdings.length > 0 ? securityHoldings?.holdings[0]?.assetClass : '',
          assetClassId: securityHoldings?.holdings.length > 0 ? securityHoldings?.holdings[0]?.assetClassId : 0,
        },
        action: securityHoldings.tradeAction,
        orders: orders,
        comments: '',
      };

      return orders.length > 0 ? bulkOrder : null;
    } else return null;
  }
);

export const selectPreApprovalInvestmentServices = createSelector(
  selectBulkOrder,
  selectEditResults,
  (orderState, editResults) => {
    const preApprovalInvestmentServices: PreApprovalInvestmentService[] = [];

    editResults.forEach((editResult) => {
      const preApprovalTradeItems: PreApprovalTradeItem[] = [];
      orderState?.orders.forEach((order) => {
        if (order.portfolioId === editResult.portfolioId) {
          const preApprovalTradeItem: PreApprovalTradeItem = {
            calculatedTradeUnits: order.units,
            securityId: orderState.security.id,
            tradeAction: TradeAction.SellAll.name === orderState.action ? TradeAction.Sell.name : orderState.action,
            unitPrice: orderState.security.unitPrice,
            priceType: orderState.security.priceType,
            priceLimit: orderState.security.priceLimit,
            expiryType: orderState.security.expiryType,
            expiryDate: orderState.security.expiryDate,
            assetClass: orderState.security.assetClass,
            assetClassId: orderState.security?.assetClassId == null ? 0 : orderState.security.assetClassId,
          };

          preApprovalTradeItems.push(preApprovalTradeItem);
        }
      });

      if (preApprovalTradeItems.length > 0) {
        const preApprovalInvestmentService: PreApprovalInvestmentService = {
          clientId: editResult.clientId,
          clientName: editResult.clientName,
          investmentServiceId: editResult.portfolioId,
          InvestmentServiceName: editResult.portfolioName,
          preApprovalTradeItems: preApprovalTradeItems,
        };

        preApprovalInvestmentServices.push(preApprovalInvestmentService);
      }
    });
    return preApprovalInvestmentServices;
  }
);

export const selectPortfolioIds = createSelector(
  selectEditResults,
  (editResults) => editResults?.map((i) => i.portfolioId) ?? []
);
