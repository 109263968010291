import '@mui/lab/themeAugmentation';
import { createTheme } from '@mui/material/styles';
import { ComponentsOverrides } from '@mui/material/styles/overrides';
import { makeStyles } from '@mui/styles';

declare module '@mui/material/styles' {
  interface Components {
    MUIDataTable?: {
      styleOverrides?: ComponentsOverrides['MUIDataTable'];
    };

    MUIDataTableBody?: {
      styleOverrides?: ComponentsOverrides['MUIDataTableBody'];
    };

    MUIDataTableBodyCell?: {
      styleOverrides?: ComponentsOverrides['MUIDataTableBodyCell'];
    };

    MUIDataTableBodyRow?: {
      styleOverrides?: ComponentsOverrides['MUIDataTableBodyRow'];
    };

    MUIDataTableFilter?: {
      styleOverrides?: ComponentsOverrides['MUIDataTableFilter'];
    };

    MUIDataTableFilterList?: {
      styleOverrides?: ComponentsOverrides['MUIDataTableFilterList'];
    };

    MUIDataTableFooter?: {
      styleOverrides?: ComponentsOverrides['MUIDataTableFooter'];
    };

    MUIDataTableHead?: {
      styleOverrides?: ComponentsOverrides['MUIDataTableHead'];
    };

    MUIDataTableHeadCell?: {
      styleOverrides?: ComponentsOverrides['MUIDataTableHeadCell'];
    };

    MUIDataTableHeadRow?: {
      styleOverrides?: ComponentsOverrides['MUIDataTableHeadRow'];
    };

    MUIDataTableJumpToPage?: {
      styleOverrides?: ComponentsOverrides['MUIDataTableJumpToPage'];
    };

    MUIDataTablePagination?: {
      styleOverrides?: ComponentsOverrides['MUIDataTablePagination'];
    };

    MUIDataTableResize?: {
      styleOverrides?: ComponentsOverrides['MUIDataTableResize'];
    };

    MUIDataTableSearch?: {
      styleOverrides?: ComponentsOverrides['MUIDataTableSearch'];
    };

    MUIDataTableSelectCell?: {
      styleOverrides?: ComponentsOverrides['MUIDataTableSelectCell'];
    };

    MUIDataTableToolbar?: {
      styleOverrides?: ComponentsOverrides['MUIDataTableToolbar'];
    };

    MUIDataTableToolbarSelect?: {
      styleOverrides?: ComponentsOverrides['MUIDataTableToolbarSelect'];
    };

    MUIDataTableViewCol?: {
      styleOverrides?: ComponentsOverrides['MUIDataTableViewCol'];
    };
  }
}

declare module '@mui/material/styles/overrides' {
  interface ComponentNameToClassKey {
    MUIDataTable: 'root' | 'caption' | 'liveAnnounce' | 'paper' | 'responsiveScroll' | 'tableRoot';

    MUIDataTableBody: 'root' | 'emptyTitle' | 'lastSimpleCell' | 'lastStackedCell';

    MUIDataTableBodyCell:
      | 'root'
      | 'cellHide'
      | 'cellStackedSmall'
      | 'responsiveStackedSmall'
      | 'responsiveStackedSmallParent'
      | 'simpleCell'
      | 'simpleHeader'
      | 'stackedCommon'
      | 'stackedCommonAlways'
      | 'stackedHeader'
      | 'stackedParent'
      | 'stackedParentAlways';

    MUIDataTableBodyRow: 'root' | 'hoverCursor' | 'responsiveSimple' | 'responsiveStacked';

    MUIDataTableFilter:
      | 'root'
      | 'checkbox'
      | 'checkboxFormControl'
      | 'checkboxFormControlLabel'
      | 'checkboxFormGroup'
      | 'checkboxIcon'
      | 'checkboxListTitle'
      | 'checked'
      | 'filtersSelected'
      | 'gridListTile'
      | 'header'
      | 'noMargin'
      | 'reset'
      | 'resetLink'
      | 'title';

    MUIDataTableFilterList: 'root' | 'chip';

    MUIDataTableFooter: 'root';

    MUIDataTableHead: 'main' | 'responsiveSimple' | 'responsiveStacked' | 'responsiveStackedAlways';

    MUIDataTableHeadCell:
      | 'root'
      | 'contentWrapper'
      | 'data'
      | 'dragCursor'
      | 'fixedHeader'
      | 'hintIconAlone'
      | 'hintIconWithSortIcon'
      | 'mypopper'
      | 'sortAction'
      | 'sortActive'
      | 'sortLabelRoot'
      | 'toolButton'
      | 'tooltip';

    MUIDataTableHeadRow: 'root';

    MUIDataTableJumpToPage: 'root' | 'caption' | 'input' | 'select' | 'selectIcon' | 'selectRoot';

    MUIDataTablePagination: 'root' | '@media screen and (max-width: 400px)' | 'navContainer' | 'selectRoot' | 'tableCellContainer' | 'toolbar';

    MUIDataTableResize: 'root' | 'resizer';

    MUIDataTableSearch: 'clearIcon' | 'main' | 'searchIcon' | 'searchText';

    MUIDataTableSelectCell:
      | 'root'
      | 'checkboxRoot'
      | 'checked'
      | 'disabled'
      | 'expandDisabled'
      | 'expanded'
      | 'fixedHeader'
      | 'fixedLeft'
      | 'headerCell'
      | 'hide'
      | 'icon';

    MUIDataTableToolbar:
      | 'root'
      | '@media screen and (max-width: 480px)'
      | "[theme.breakpoints.down('sm')]"
      | "[theme.breakpoints.down('xs')]"
      | 'actions'
      | 'filterCloseIcon'
      | 'filterPaper'
      | 'fullWidthActions'
      | 'fullWidthLeft'
      | 'fullWidthRoot'
      | 'fullWidthTitleText'
      | 'icon'
      | 'iconActive'
      | 'left'
      | 'searchIcon'
      | 'titleRoot'
      | 'titleText';

    MUIDataTableToolbarSelect: 'root' | 'deleteIcon' | 'iconButton' | 'title';

    MUIDataTableViewCol: 'root' | 'checkbox' | 'checkboxRoot' | 'checked' | 'formControl' | 'formGroup' | 'label' | 'title';
  }
}

declare module '@mui/material/styles' {
  interface Palette {
    gradient1?: Palette['primary'];
    gradient2?: Palette['primary'];
    gradientHero?: Palette['primary'];
    graphBackground?: Palette['primary'];
    lightBlue?: Palette['primary'];
    green: Palette['primary'];
    orange: Palette['primary'];
    lightGrey: Palette['primary'];
    infoAlertBackground: Palette['primary'];
  }
  interface PaletteOptions {
    gradient1?: PaletteOptions['primary'];
    gradient2?: PaletteOptions['primary'];
    gradientHero?: PaletteOptions['primary'];
    graphBackground?: PaletteOptions['primary'];
    lightBlue?: PaletteOptions['primary'];
    green: PaletteOptions['primary'];
    orange: PaletteOptions['primary'];
    lightGrey: Palette['primary'];
    infoAlertBackground: Palette['primary'];
  }

  interface PaletteColor {
    darker?: string;
  }
  interface SimplePaletteColorOptions {
    darker?: string;
  }
  //interface ThemeOptions {
  // status: {
  //   danger: React.CSSProperties['color'];
  // };
  //}
}

declare module '@mui/material/styles' {
  interface TypographyVariants {
    h7: React.CSSProperties;
    h8: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    h7?: React.CSSProperties;
    h8?: React.CSSProperties;
  }

  interface ButtonVariants {
    group: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface ButtonVariantsOptions {
    group?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    h7: true;
    h8: true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    group: true;
  }
}

// declare module '@material-ui/core/styles/createPalette' {
//   interface Palette {
//     gradient1?: Palette['primary'];
//     gradient2?: Palette['primary'];
//     gradientHero?: Palette['primary'];
//     graphBackground?: Palette['primary'];
//     lightBlue?: Palette['primary'];
//     green: Palette['primary'];
//     orange: Palette['primary'];
//     lightGrey: Palette['primary'];
//     infoAlertBackground: Palette['primary'];
//   }
//   interface PaletteOptions {
//     gradient1?: PaletteOptions['primary'];
//     gradient2?: PaletteOptions['primary'];
//     gradientHero?: PaletteOptions['primary'];
//     graphBackground?: PaletteOptions['primary'];
//     lightBlue?: PaletteOptions['primary'];
//     green: PaletteOptions['primary'];
//     orange: PaletteOptions['primary'];
//     lightGrey: Palette['primary'];
//     infoAlertBackground: Palette['primary'];
//   }
// }

export const theme = createTheme({
  palette: {
    primary: {
      main: '#4306fa',
      light: '#F2F8FF',
    },
    secondary: {
      main: '#31d768',
      light: '#eff3f5',
    },
    error: {
      main: '#cd1604',
    },
    text: {
      secondary: '#a6a6a6',
    },
    gradient1: {
      main: 'linear-gradient(226deg, #28d4ff 0%, #5700ff 50%, #1223ab 100%)',
    },
    gradient2: {
      main: 'linear-gradient(38deg, #2e86ff 0%, #00d8bf 35%, #3ff9f7 68%, #7afff0 100%)',
    },
    gradientHero: {
      main: 'linear-gradient(126deg, #00e9ff 0%, #00e9ff 9%, #02e4ff 19%, #5700ff 100%)',
    },
    graphBackground: {
      main: '#f8fafa',
    },
    lightBlue: {
      main: '#42d1ff',
    },
    green: {
      main: '#90EE90',
    },
    orange: {
      main: '#f5a623',
    },
    lightGrey: {
      main: '#f2f2f2',
      contrastText: '#f2f2f2',
      light: '#f2f2f2',
      dark: '#f2f2f2',
    },
    infoAlertBackground: {
      main: 'rgb(241, 245, 255)',
      contrastText: 'rgb(241, 245, 255)',
      light: 'rgb(241, 245, 255)',
      dark: 'rgb(241, 245, 255)',
    },
  },
  typography: {
    fontFamily: 'AvenirBook, AvenirBlack, AvenirHeavy, AvenirMedium, AvenirLight',
    h7: {
      fontSize: '26px',
      fontWeight: 500,
      lineHeight: 1.3,
      fontFamily: 'AvenirMedium',
    },
    h8: {
      fontSize: '20px',
      fontWeight: 400,
      lineHeight: 1.3,
      fontFamily: 'AvenirMedium',
      display: 'block',
      marginBlockStart: '0.83em',
      marginBlockEnd: '0.83em',
      marginInlineStart: '0px',
      marginInlineEnd: '0px',
    },
  },
});

theme.components = {
  ...theme.components,
  MUIDataTableFilterList: {
    styleOverrides: {
      root: {
        marginBottom: '10px',
      },
    },
  },
  MUIDataTableToolbar: {
    styleOverrides: {
      filterPaper: {
        maxWidth: '50% !important',
      },
    },
  },
  MUIDataTableFilter: {
    styleOverrides: {
      resetLink: {
        borderColor: `${theme.palette.primary.main} !important`,
        backgroundColor: `${theme.palette.primary.main} !important`,
        color: theme.palette.common.white,
        marginLeft: '10px  !important',
      },
      root: {
        width: '593px',
      },
      gridListTile: '180px',
    },
  },
  MUIDataTableHeadCell: {
    styleOverrides: {
      toolButton: {
        '&:hover': {
          borderColor: 'inherit !important',
          backgroundColor: 'inherit !important',
          color: 'inherit !important',
        },
        '&.active': {
          borderColor: 'inherit !important',
          backgroundColor: 'inherit !important',
          color: 'inherit !important',
        },
        '&.Mui-selected': {
          borderColor: 'inherit !important',
          backgroundColor: 'inherit !important',
          color: 'inherit !important',
        },
      },
    },
  },
  MuiTypography: {
    styleOverrides: {
      gutterBottom: {
        marginBottom: '1.25em',
      },
      h1: {
        fontSize: '40px',
        fontWeight: 700,
        fontStretch: 'normal',
        fontStyle: 'normal',
        fontFamily: 'AvenirMedium',
        lineHeight: 0.75,
        letterSpacing: 'normal',
      },
      h2: {
        fontSize: '36px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        fontFamily: 'AvenirMedium',
      },
      h3: {
        fontSize: '22px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        fontFamily: 'AvenirMedium',
      },
      h4: {
        fontSize: '16px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        fontFamily: 'AvenirMedium',
      },
      h5: {
        fontSize: '14px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        fontFamily: 'AvenirMedium',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
      h6: {
        fontSize: '10px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        fontFamily: 'AvenirMedium',
        lineHeight: 1.6,
        letterSpacing: '1.5px',
      },
      button: {
        fontSize: '14px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '1.29',
        letterSpacing: 'normal',
      },
      caption: {
        fontSize: '18px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: '0.23px',
      },
      subtitle1: {
        fontSize: '12px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        fontFamily: 'AvenirMedium',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
      body1: {
        fontSize: '0.825rem',
        fontFamily: 'AvenirMedium',
        fontWeight: 400,
        lineHeight: 1.5,
        letterSpacing: '0.00938em',
      },
      body2: {
        fontSize: '14px',
        fontFamily: 'AvenirMedium',
        fontWeight: 400,
        lineHeight: 1.5,
        letterSpacing: '0.00938em',
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        minWidth: '0px !important',
        fontFamily: 'AvenirMedium',
      },
    },
  },
  MuiAppBar: {
    styleOverrides: {
      root: {
        backgroundColor: theme.palette.common.white,
        borderBottom: `1px solid ${theme.palette.text.secondary}`,
      },
      colorDefault: {
        backgroundColor: theme.palette.common.white,
      },
    },
  },
  MuiButtonGroup: {
    styleOverrides: {
      groupedText: {
        color: theme.palette.common.white,
        '&.Mui-disabled': {
          backgroundColor: theme.palette.common.white,
          color: theme.palette.primary.main,
        },
      },
      groupedTextHorizontal: {
        borderRadius: '24px !important',
        textTransform: 'uppercase',
        '&:not(:last-child)': {
          borderRight: 'none',
        },
      },
    },
  },
  MuiAlert: {
    styleOverrides: {
      standardInfo: {
        backgroundColor: theme.palette.infoAlertBackground.main,
        '& > .MuiAlert-icon': {
          color: theme.palette.primary.main,
        },
      },
    },
  },
  MuiButton: {
    variants: [
      {
        props: { variant: 'group' },
        style: {
          height: '48px',
          borderRadius: '24px',
          fontFamily: 'AvenirMedium',
          textTransform: 'none',
          boxShadow: 'none',

          '&.ShowMore': {
            color: theme.palette.text.secondary,
            textTransform: 'uppercase',
          },
          '&.Mui-disabled': {
            borderColor: '#848cbc !important',
            backgroundColor: '#848cbc !important',
            color: theme.palette.common.white,
          },
          flex: 'none',

          '&:hover': {
            borderColor: '#7f87ff',
            backgroundColor: '#7f87ff',
            color: theme.palette.common.white,
          },
          '&:active': {
            borderColor: '#3201C8',
            backgroundColor: '#3201C8',
            color: theme.palette.common.white,
          },
          '&.selected': {
            borderColor: '#3201C8',
            backgroundColor: '#3201C8',
            color: theme.palette.common.white,
          },

          '&.SemiTransparent': {
            backgroundColor: `transparent`,

            '&:hover': {
              backgroundColor: `rgb(255,255,255,0.5) !important`,
              color: `${theme.palette.primary.main} !important`,
            },
            '&:active': {
              backgroundColor: `rgb(255,255,255,0.5) !important`,
              color: `${theme.palette.primary.main} !important`,
            },
            '&:focus': {
              backgroundColor: `rgb(255,255,255,0.5) !important`,
              color: `${theme.palette.primary.main} !important`,
            },

            '&.Mui-disabled': {
              backgroundColor: `${theme.palette.common.white} !important`,

              '&:hover': {
                backgroundColor: `rgb(255,255,255,0.5) !important`,
                color: `${theme.palette.primary.main} !important`,
              },
              '&:active': {
                backgroundColor: `#ffffff !important`,
                color: `${theme.palette.primary.main} !important`,
              },
              '&:focus': {
                backgroundColor: `#ffffff !important`,
                color: `${theme.palette.primary.main} !important`,
              },
            },
          },
          color: theme.palette.common.white,
          '&.Mui-selected': {
            backgroundColor: theme.palette.common.white,
            color: theme.palette.primary,
            borderRadius: '24px',
          },
        },
      },
    ],
    styleOverrides: {
      contained: {
        height: '48px',
        borderRadius: '25px',
      },
      outlined: {
        height: '48px',
        borderRadius: '25px',
        fontFamily: 'AvenirMedium',
        textTransform: 'none',
        boxShadow: 'none',

        '&.ShowMore': {
          color: theme.palette.text.secondary,
          textTransform: 'uppercase',
        },
        '&.Mui-disabled': {
          borderColor: '#848cbc !important',
          backgroundColor: '#848cbc !important',
          color: theme.palette.common.white,
        },
        flex: 'none',

        '&:hover': {
          borderColor: '#7f87ff',
          backgroundColor: '#7f87ff',
          color: theme.palette.common.white,
        },
        '&:active': {
          borderColor: '#3201C8',
          backgroundColor: '#3201C8',
          color: theme.palette.common.white,
        },
        '&.selected': {
          borderColor: '#3201C8',
          backgroundColor: '#3201C8',
          color: theme.palette.common.white,
        },

        '&.SemiTransparent': {
          backgroundColor: `transparent`,

          '&:hover': {
            backgroundColor: `rgb(255,255,255,0.5) !important`,
            color: `${theme.palette.primary.main} !important`,
          },
          '&:active': {
            backgroundColor: `rgb(255,255,255,0.5) !important`,
            color: `${theme.palette.primary.main} !important`,
          },
          '&:focus': {
            backgroundColor: `rgb(255,255,255,0.5) !important`,
            color: `${theme.palette.primary.main} !important`,
          },

          '&.Mui-disabled': {
            backgroundColor: `${theme.palette.common.white} !important`,

            '&:hover': {
              backgroundColor: `rgb(255,255,255,0.5) !important`,
              color: `${theme.palette.primary.main} !important`,
            },
            '&:active': {
              backgroundColor: `#ffffff !important`,
              color: `${theme.palette.primary.main} !important`,
            },
            '&:focus': {
              backgroundColor: `#ffffff !important`,
              color: `${theme.palette.primary.main} !important`,
            },
          },
        },
        backgroundColor: theme.palette.common.white,
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        backgroundColor: theme.palette.common.white,
        height: '48px',
        borderRadius: '8px',
        fontSize: '14px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        fontFamily: 'AvenirMedium',

        // overrides
        '&:hover .MuiOutlinedInput-notchedOutline': {
          border: `1px solid ${theme.palette.primary.main} !important`,
        },
        '&:active .MuiOutlinedInput-notchedOutline': {
          border: `1px solid ${theme.palette.primary.main}`,
        },
        '&:focus .MuiOutlinedInput-notchedOutline': {
          border: `1px solid ${theme.palette.primary.main}`,
        },

        // Address MUI focused, disabled states
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          border: `1px solid ${theme.palette.primary.main} !important`,

          '&:hover': {
            borderColor: `${theme.palette.primary.main}`,
          },
        },

        '&.Mui-disabled': {
          backgroundColor: '#ffffff',

          '& .MuiOutlinedInput-notchedOutline': {
            color: `#f2f2f2 !important`,
            borderColor: `#e2e2e2 !important`,
          },

          '&:hover .MuiOutlinedInput-notchedOutline': {
            color: `#f2f2f2 !important`,
            borderColor: `#e2e2e2 !important`,
          },
          '&:active .MuiOutlinedInput-notchedOutline': {
            color: `#f2f2f2 !important`,
            borderColor: `#e2e2e2`,
          },
          '&:focus .MuiOutlinedInput-notchedOutline': {
            color: `#f2f2f2 !important`,
            borderColor: `#e2e2e2`,
          },
        },

        // Overrides for TextArea
        '&.MuiInputBase-multiline': {
          height: 'auto',
        },

        // Custom Style
        '&.SemiTransparent': {
          backgroundColor: 'rgba(0, 0, 0, 0.05)',
          color: theme.palette.common.white,
          borderColor: 'transparent',
          textIndent: '12px',

          '&:hover': {
            borderColor: theme.palette.common.white,
          },
        },
      },
      adornedStart: {
        '&:hover .MuiSvgIcon-root': {
          color: theme.palette.primary.main,
        },
        '&:active .MuiSvgIcon-root': {
          color: theme.palette.primary.main,
        },
        '&.Mui-focused .MuiSvgIcon-root': {
          color: theme.palette.primary.main,
        },
      },
      inputAdornedStart: {
        textIndent: '0',
      },
      input: {},
      multiline: {
        height: 'auto',
      },
    },
  },
  MuiSelect: {
    styleOverrides: {
      icon: {
        color: 'inherit',
      },
      select: {
        paddingLeft: '16px',
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      outlined: {
        fontStyle: 'normal',
        fontFamily: 'AvenirMedium',
        fontWeight: 'normal',
        fontStretch: 'normal',
        letterSpacing: '0.00938em',
        fontSize: '1em',
      },
      root: {
        '&.Mui-disabled': {
          '&:hover': {
            color: '#f2f2f2 !important',
          },
        },
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        backgroundColor: theme.palette.common.white,
        '&:focus': {
          outline: 'none',
        },
      },
      rounded: {
        borderRadius: '8px',
      },
      elevation1: {
        boxShadow: '0 0 20px 0 ' + theme.palette.text.primary,
      },
    },
  },
  MuiCard: {
    styleOverrides: {
      root: {
        '&.Card': {
          color: theme.palette.common.white,
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '160px',
        },
        '&.Overflow': {
          overflow: 'visible',
        },
      },
    },
  },
  MuiCardHeader: {
    styleOverrides: {
      root: {
        '&.Card': {
          display: 'flex',
          paddingTop: '30px',
          paddingRight: '30px',
        },
      },
    },
  },
  MuiCardContent: {
    styleOverrides: {
      root: {
        '&.Card': {
          alignSelf: 'flex-start',
          paddingBottom: '0px',
          '&:last-child': {
            paddingBottom: '0px',
          },
        },
      },
    },
  },
  MuiListItemIcon: {
    styleOverrides: {
      root: {
        color: theme.palette.primary.main,

        '&.IsSelected svg': {
          color: theme.palette.primary.main,
        },
      },
    },
  },
  MuiListItem: {
    styleOverrides: {
      button: {
        '&:hover': {
          backgroundColor: theme.palette.primary.light,
          color: theme.palette.primary.main,
        },
      },
      root: {
        paddingTop: '15px',
        paddingBottom: '15px',
        '&.LeftMenu.Mui-selected': {
          paddingLeft: '25px !important',
          borderLeft: '5px solid rgb(67, 6, 250)',
          backgroundColor: theme.palette.primary.light,
          color: theme.palette.primary.main,
        },
        '&.Mui-selected > .MuiListItemText-root > span': {
          color: theme.palette.text.primary,
        },
        '&:last-child': {
          borderBottom: '1px solid transparent',
        },
      },
      selected: {
        '& > div > span': {
          color: theme.palette.text.primary,
        },
      },
    },
  },
  MuiContainer: {
    styleOverrides: {
      root: {
        paddingLeft: '20px',
        paddingRight: '20px',
      },
      fixed: {
        maxWidth: '1182px !important',
        minWidth: '1182px !important',
        '&.MuiContainer-maxWidthLg': {
          maxWidth: '1182px !important',
          '@media(min-width:1400px)': {
            maxWidth: '1182px !important',
          },
        },
      },
      maxWidthLg: {
        maxWidth: '1182px !important',
        '@media(min-width:1400px)': {
          maxWidth: 'none !important',
        },
      },
    },
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        maxWidth: '500',
        color: theme.palette.common.white,
      },
      tooltipPlacementRight: {
        margin: 0,
        padding: 0,
      },
    },
  },
  MuiLink: {
    styleOverrides: {
      root: {
        '&:hover': {
          cursor: 'pointer',
        },
      },
    },
  },
  MuiAccordionSummary: {
    styleOverrides: {
      root: {
        padding: 0,
      },
      expandIconWrapper: {
        order: -1,
        padding: 0,
        marginRight: '20px',
        marginLeft: '4px',
        transform: 'rotate(270deg)',
        '&.Mui-expanded': {
          transform: 'rotate(360deg)',
        },
      },
    },
  },
  MuiFormControl: {
    styleOverrides: {
      root: {
        borderRadius: '4px',
        fontSize: '14px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        fontFamily: 'AvenirMedium',
        '&.SemiTransparent': {
          backgroundColor: 'rgba(0, 0, 0, 0.05)',
          color: theme.palette.common.white,
        },

        // Labels
        '&:hover .MuiInputLabel-outlined': {
          color: theme.palette.primary.main,
        },
        '&:active .MuiInputLabel-outlined': {
          color: theme.palette.primary.main,
        },
        '&:focus .MuiInputLabel-outlined': {
          color: theme.palette.primary.main,
        },
        // Address MUI focused, disabled states
        '&.Mui-focused .MuiInputLabel-outlined': {
          color: theme.palette.primary.main,

          '&:hover': {
            color: theme.palette.primary.main,
          },
        },

        // Disabled
        '&:hover .MuiInputLabel-outlined.Mui-disabled': {
          color: 'rgba(0,0,0,0.38)',
        },
        '&:active .MuiInputLabel-outlined.Mui-disabled': {
          color: 'rgba(0,0,0,0.38)',
        },
        '&:focus .MuiInputLabel-outlined.Mui-disabled': {
          color: 'rgba(0,0,0,0.38)',
        },

        '& .MuiOutlinedInput-root': {},
      },
    },
  },
  MuiFab: {
    styleOverrides: {
      root: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        fontFamily: 'AvenirMedium',
        boxShadow: '0 0 0 transparent',
        padding: '8px',

        '&:hover': {
          borderColor: '#7f87ff !important',
          backgroundColor: '#7f87ff !important',
          color: theme.palette.common.white,
        },
        '&:active': {
          borderColor: '#3201C8 !important',
          backgroundColor: '#3201C8 !important',
          color: theme.palette.common.white,
        },

        '&.MuiFab-extended': {
          border: `2px solid ${theme.palette.primary.main}`,
          color: theme.palette.primary.main,
          backgroundColor: '#ffffff',
          fontFamily: 'AvenirMedium',
          boxShadow: '0 0 0 transparent',
          padding: '8px 2rem',
        },
      },
    },
  },
  MuiFormControlLabel: {
    styleOverrides: {
      label: {
        fontSize: '14px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        fontFamily: 'AvenirMedium',
      },
    },
  },
  MuiTable: {
    styleOverrides: {
      root: {
        position: 'relative',
        width: `100%`,
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      head: {
        border: 'none',
        backgroundColor: '#eff3f5',
        color: '#7d7d7d',
        fontSize: '0.7rem',

        '& h6': {
          color: '#848484',
        },
      },
      body: {
        border: 'none',
      },
      root: {
        lineHeight: '1',
        padding: '1rem 10px',
        fontFamily: 'AvenirMedium',

        '&.Selected': {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.common.white,
        },
      },
      footer: {
        borderBottom: 'none',
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        paddingTop: '10px',
        paddingBottom: '10px',
        color: theme.palette.text.secondary,
        '&.Mui-selected': {
          backgroundColor: theme.palette.common.white,
          color: theme.palette.text.primary,
        },
      },
    },
  },
  MuiTableRow: {
    styleOverrides: {
      root: {
        borderBottom: '1px solid #e2e2e2',

        position: 'relative',
        '&:not(:first-of-type)&::after': {
          position: 'absolute',
          content: "''",
          left: '15px',
          right: '15px',
        },

        '&:last-child': {
          borderBottom: '2px solid transparent',
        },
      },
      head: {
        borderBottom: '2px solid #e2e2e2',

        '&:not(:first-of-type)&::after': {
          position: 'absolute',
          borderTop: 'none',
        },
      },
    },
  },
  MuiIcon: {
    styleOverrides: {
      fontSizeLarge: {
        fontSize: '1.87rem',
      },
    },
  },
  MuiSvgIcon: {
    styleOverrides: {
      fontSizeLarge: {
        fontSize: '1.87rem',
      },
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      error: {
        fontSize: '12px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        fontFamily: 'AvenirMedium',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        lineHeight: 1.334,
      },
      contained: {
        marginLeft: 0,
        marginRight: 0,
      },
    },
  },
  MuiToggleButton: {
    styleOverrides: {
      root: {
        textTransform: 'none',
        fontFamily: 'AvenirMedium',
        minWidth: '48px',
        minHeight: '48px',
        color: 'inherit',

        '&:hover': {
          borderColor: '#e2e2e2',
          backgroundColor: '#e2e2e2',
        },
        '&:active': {
          borderColor: '#e2e2e2',
          backgroundColor: '#e2e2e2',
        },

        '&.Mui-selected': {
          borderColor: '#4306fa',
          backgroundColor: '#4306fa',
          color: theme.palette.common.white,

          '&:hover': {
            borderColor: '#4306fa',
            backgroundColor: '#4306fa',
            color: theme.palette.common.white,
          },
        },
        '&.Mui-selected&.Green': {
          borderColor: '#31d768',
          backgroundColor: '#31d768',
          color: 'white',
        },
        '&.Mui-selected&.Red': {
          borderColor: '#cd1604',
          backgroundColor: '#cd1604',
          color: 'white',
        },
        '&.Mui-fullFlexBasis': {
          flex: '0 1 100%',
        },
        '&.Mui-disabled.Mui-selected': {
          borderColor: '#848cbc !important',
          backgroundColor: '#848cbc !important',
          color: theme.palette.common.white,

          '&:hover': {
            borderColor: '#848cbc !important',
            backgroundColor: '#848cbc !important',
            color: theme.palette.common.white,
          },
        },
        flex: 'none',
      },
    },
  },
  MuiAutocomplete: {
    styleOverrides: {
      input: {
        padding: '0px !important',
        paddingLeft: '9px !important',
      },
      inputRoot: {
        height: 'auto',
        minHeight: 48,
      },
    },
  },
  MuiToggleButtonGroup: {
    styleOverrides: {
      root: {
        '&.Mui-flexInitial': {
          display: 'flex',
        },
      },
    },
  },
};

export const useStyles = makeStyles(() => ({
  dropzone: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: '2px',
    borderRadius: '2px',
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    margin: '20px',
  },
  contentEmail: {
    minHeight: 300,
  },
  addToTable: {
    backgroundColor: theme.palette.primary.main,
    fontSize: '1.25rem !important',
    padding: '4px',
    margin: '12px',
    color: theme.palette.common.white,
    '& > span > svg': {
      fontSize: '1.25rem !important',
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  MuiError: {
    color: '#cd1604',
  },
}));
