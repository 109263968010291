import { MarketCode, SecurityCategory, Trade } from '../store/types';

export default function isCashTrade(trade: Trade): boolean {
  return (
    trade.modelName !== null &&
    trade.modelName.match(trade.assetClass) !== null &&
    trade.marketCode !== null &&
    trade.marketCode.match(MarketCode.ADI) !== null &&
    trade.securityCategory !== null &&
    trade.securityCategory.match(SecurityCategory.CMA) !== null
  );
}
