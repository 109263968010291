import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../../app/api';
import { buildEncodedQueryString, convertToCSVFormat, CSVDataType, downloadCsv } from '../../../../common';
import { PagedResult } from '../../../../store';
import {
  ConsolidatedSecurityHoldingsSummary,
  ConsolidatedSecurityHoldingsSummaryThunkParameters,
  DownloadSecurityExposurePayload,
  SecurityExposureItem,
  SecurityExposureThunkParameters,
} from './types';

export enum SecurityAssociatedClientsActionTypes {
  FetchConsolidatedSecurityHoldingsSummary = '@@security/AssociatedClients/FetchConsolidatedSecurityHoldingsSummary',
  FetchSecurityExposure = '@@security/AssociatedClients/FetchSecurityExposure',
  DownloadSecurityExposure = '@@security/AssociatedClients/DownloadSecurityExposure',
}

export enum SecurityAssociatedClientsApiEndpoints {
  fetchConsolidatedSecurityHoldingsSummary = '/bff/GetConsolidatedSecurityHoldingsSummary',
  fetchSecurityExposure = '/bff/GetSecurityExposure',
}

export const fetchConsolidatedSecurityHoldingsSummary = createAsyncThunk(
  SecurityAssociatedClientsActionTypes.FetchConsolidatedSecurityHoldingsSummary,
  async (wrapper: ConsolidatedSecurityHoldingsSummaryThunkParameters) => {
    const response = await api.get<ConsolidatedSecurityHoldingsSummary>(
      `${SecurityAssociatedClientsApiEndpoints.fetchConsolidatedSecurityHoldingsSummary}?securityId=${wrapper.securityId}&dateTime=${wrapper.dateTime}&isDataFromTriumph=${wrapper.isDataFromTriumph}`
    );
    return response.data;
  }
);

const getPagedSecurityExposure = async (request: SecurityExposureThunkParameters) => {
  const queryString = buildEncodedQueryString({
    pagedRequest: request.pagination,
    securityId: request.securityId,
    date: request.toDate,
    isDataFromTriumph: request.isDataFromTriumph,
  });
  return await api.get<PagedResult<SecurityExposureItem>>(
    `${SecurityAssociatedClientsApiEndpoints.fetchSecurityExposure}${queryString}`
  );
};

export const fetchSecurityExposure = createAsyncThunk(
  SecurityAssociatedClientsActionTypes.FetchSecurityExposure,
  async (wrapper: SecurityExposureThunkParameters) => {
    const response = await getPagedSecurityExposure(wrapper);
    return {
      results: response.data,
      pagination: wrapper.pagination,
    };
  }
);

export const downloadSecurityExposure = createAsyncThunk(
  SecurityAssociatedClientsActionTypes.DownloadSecurityExposure,
  async (wrapper: DownloadSecurityExposurePayload) => {
    const response = await getPagedSecurityExposure({
      ...wrapper,
      pagination: { ...wrapper.pagination, pageNumber: 1, pageSize: 9999999 },
    });
    const data: CSVDataType[] = convertToCSVFormat(response.data.results);

    downloadCsv(data, `SecurityExposure_`);
    return { message: 'CSV downloaded' };
  }
);
