import { Container, Grid, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Field, Form, Formik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { LoadingIndicator } from 'src/common/components/LoadingIndicator';
import { formatDollars, formatPercentage } from '../../../../common';
import CardComponent from '../../../../common/components/CardComponent';
import { FormikKeyboardDatePicker, FormikSwitch, FormikTextField } from '../../../../common/components/formik';
import { theme } from '../../../../themes';
import { Props } from '../container';
import { Afsl, ModelDetails } from '../store/types';

interface FormValues extends Partial<ModelDetails> {
  targetRateOfReturnFormatted: string;
  ownerAfslsNames: string;
  accessAfslsName: string;
  primaryBenchmarkFull: string;
  secondaryBenchmarkFull: string;
}

export const DetailsComponent = ({ loadingProgress, fetchModelDetails, modelDetails, modelVersionId, isDataFromTriumph }: Props): JSX.Element => {
  const fetch = useCallback(() => {
    if (modelVersionId !== null) {
      fetchModelDetails({ modelVersionId: modelVersionId, isDataFromTriumph: isDataFromTriumph });
    }
  }, [fetchModelDetails, modelVersionId, isDataFromTriumph]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const initialFormValues: FormValues = {
    targetRateOfReturnFormatted: '',
    ownerAfslsNames: '',
    accessAfslsName: '',
    primaryBenchmarkFull: '',
    secondaryBenchmarkFull: '',
  };

  const arrayToString = (arr: Afsl[]) => {
    const newArr: string[] = [];
    arr.forEach((item: Afsl) => {
      newArr.push(item.name);
    });
    return newArr.toString();
  };

  const fullName = (code: string, name: string) => {
    if (name) {
      return code + ' - ' + name;
    } else {
      return name;
    }
  };

  useEffect(() => {
    setFormValues({
      ...modelDetails,
      targetRateOfReturnFormatted: formatPercentage(modelDetails?.targetRateOfReturn || 0),
      ownerAfslsNames: arrayToString(modelDetails?.ownerAfsls || []),
      accessAfslsName: arrayToString(modelDetails?.accessAfsls || []),
      primaryBenchmarkFull: fullName(modelDetails?.primaryBenchmark?.code || '', modelDetails?.primaryBenchmark?.name || ''),
      secondaryBenchmarkFull: fullName(modelDetails?.secondaryBenchmark?.code || '', modelDetails?.secondaryBenchmark?.name || ''),
    });
  }, [modelDetails]);

  const readonly = true;

  const [formValues, setFormValues] = useState<FormValues>(initialFormValues);

  return (
    <Container style={{ paddingTop: '20px', paddingBottom: '20px' }}>
      <LoadingIndicator progress={loadingProgress}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <CardComponent progress={loadingProgress} background={theme.palette.gradient1?.main} value={formatDollars(modelDetails?.fua)} subText={'FUA'} />
          </Grid>
          <Grid item xs={6}>
            <CardComponent progress={loadingProgress} background={theme.palette.gradient2?.main} value={modelDetails?.portfolioCount} subText={'Portfolios'} />
          </Grid>
        </Grid>

        <Card style={{ marginTop: '25px' }} elevation={0}>
          <CardContent>
            <Formik<FormValues>
              initialValues={formValues}
              enableReinitialize={true}
              onSubmit={() => {
                return;
              }}
            >
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={6} container>
                    <Grid item xs={12}>
                      <Field disabled={readonly} component={FormikTextField} name={'name'} label="MODEL NAME" fullWidth />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: '20px' }}>
                      <Field disabled={readonly} component={FormikTextField} name={'type'} label="MODEL TYPE" fullWidth />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: '20px' }}>
                      <Field disabled={readonly} component={FormikTextField} name={'primaryBenchmarkFull'} label="PRIMARY BENCHMARK" fullWidth />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: '20px' }}>
                      <Field disabled={readonly} component={FormikTextField} name={'primaryPriceType'} label="PRIMARY PRICE TYPE" fullWidth />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: '20px' }}>
                      <Field disabled={readonly} component={FormikTextField} name={'allocationType'} label="ALLOCATION TYPE" fullWidth />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: '20px' }}>
                      <Field disabled={readonly} multiline component={FormikTextField} name={'description'} label="DESCRIPTION" rows="3" fullWidth />
                    </Grid>
                  </Grid>
                  <Grid item xs={6} container>
                    <Grid item xs={12}>
                      <Field disabled={readonly} component={FormikTextField} name={'code'} label="MODEL CODE" fullWidth />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: '20px' }}>
                      <Field
                        disabled={readonly}
                        component={FormikKeyboardDatePicker}
                        name="inceptionDate"
                        label="INCEPTION DATE"
                        maxDateMessage="From Date should not be after To Date"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: '20px' }}>
                      <Field
                        disabled={readonly}
                        component={FormikKeyboardDatePicker}
                        name="analyticsStartDate"
                        label="ANALYTICS START DATE"
                        maxDateMessage="From Date should not be after To Date"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: '20px' }}>
                      <Field disabled={readonly} component={FormikTextField} name={'secondaryBenchmarkFull'} label="SECONDARY BENCHMARK" fullWidth />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: '20px' }}>
                      <Field disabled={readonly} component={FormikTextField} name={'targetRateOfReturnFormatted'} label="TARGET RATE OF RETURN" fullWidth />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: '20px' }}>
                      <Field disabled={readonly} multiline component={FormikTextField} name={'ownerAfslsNames'} label="OWNER AFSL" rows="3" fullWidth />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: '20px' }}>
                      <Field disabled={readonly} name={'isSuperSimplifierModel'} component={FormikSwitch} label="Is this a Super Simplifier Model?"></Field>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            </Formik>
          </CardContent>
        </Card>

        <Card style={{ marginTop: '25px' }} elevation={0}>
          <CardContent>
            <Grid container>
              <Grid item xs={4}>
                <Typography variant="h6" style={{ paddingLeft: '15px' }}>
                  SECURITY ICR
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="h6" style={{ paddingLeft: '8px' }}>
                  MODEL MER
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="h6" style={{ paddingRight: '10px' }}>
                  MODEL ICR
                </Typography>
              </Grid>
            </Grid>
            <Grid container style={{ paddingTop: '20px' }}>
              <Grid item xs={4}>
                <Typography variant="h6" style={{ paddingLeft: '15px' }}>
                  {modelDetails?.securityIcr}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="h6" style={{ paddingLeft: '8px' }}>
                  {modelDetails?.modelMer}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="h6" style={{ paddingRight: '10px' }}>
                  {modelDetails?.modelIcr}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </LoadingIndicator>
    </Container>
  );
};
