import React from 'react';
import { ReportTemplate } from '../store';
import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';

interface Props {
  row: ReportTemplate | null;
  handleClose: () => void;
  isOpen: boolean;
}

export const ReportTemplateDeleteModal = ({ row, handleClose, isOpen }: Props): JSX.Element => {
  return (
    <Dialog maxWidth="xs" open={isOpen && row !== null} onClose={handleClose}>
      <DialogTitle sx={{ fontSize: 18 }}>Are you sure you want to delete {row?.name}? </DialogTitle>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          No
        </Button>
        <Button variant="outlined">Yes</Button>
      </DialogActions>
    </Dialog>
  );
};
