import React, { forwardRef, SyntheticEvent } from 'react';
import {
  Autocomplete,
  autocompleteClasses,
  createFilterOptions,
  InputAdornment,
  Popper,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import {
  LIST_BOX_FILTERED_ROW_LIMIT,
  LIST_BOX_PADDING,
  LIST_BOX_RELATIVE_WIDTH,
  OPTIONS_LIST_MAX_ROWS,
  OVERSCAN_COUNT,
  ROW_HEIGHT,} from './constants';
import { ListChildComponentProps, VariableSizeList } from 'react-window';
import SearchIcon from '@mui/icons-material/Search';

const ListBoxComponent = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement>>((props, ref) => {
  const { children, ...other } = props;
  const securities: React.ReactElement[] = [];

  (children as React.ReactElement[]).forEach((item: React.ReactElement) => {
    securities.push(item);
  });
  const OuterElementContext = React.createContext({});

  const useResetCache = (data: number) => {
    const ref = React.useRef<VariableSizeList>(null);
    React.useEffect(() => {
      if (ref.current != null) {
        //don't reset indices till after parent component issues a state update
        ref.current.resetAfterIndex(0, false);
      }
    }, [data]);
    return ref;
  };

  const SecurityRowContainer = forwardRef<HTMLDivElement>((props, ref) => {
    const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
  });

  const renderRow = (props: ListChildComponentProps) => {
    const { data, index, style } = props;
    const dataSet = data[index];

    const parent = {
      ...style,
      display: 'flex',
      flexFlow: 'column nowrap',
      gap: '10px',
      height: ROW_HEIGHT,
      width: '100%',
      borderBottom: '1px solid rgb(128 128 128 / 20%)',
      justifyContent: 'center',
    };

    const child = {
      width: '100%',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    } as React.CSSProperties; //don't ask

    return (
      <Typography component="div" {...dataSet[0]} style={parent}>
        <Typography component="div" style={child}>
          {dataSet[1].securityCode}
        </Typography>
        <Typography component="div" style={{ ...child, fontSize: '12px' }}>
          {dataSet[1].securityName}
        </Typography>
      </Typography>
    );
  };

  const securityCount = securities.length;

  const getHeight = () => {
    if (securityCount > OPTIONS_LIST_MAX_ROWS) {
      return OPTIONS_LIST_MAX_ROWS * ROW_HEIGHT;
    }
    return securityCount * ROW_HEIGHT;
  };

  const gridRef = useResetCache(securityCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={securities}
          height={getHeight() + LIST_BOX_PADDING}
          width={LIST_BOX_RELATIVE_WIDTH}
          ref={gridRef}
          outerElementType={SecurityRowContainer}
          innerElementType="div"
          itemSize={() => ROW_HEIGHT}
          overscanCount={OVERSCAN_COUNT}
          itemCount={securityCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

interface Props<T> {
  textFieldLabel?: string;
  selectedValue: T | null;
  options: T[];
  filterOptionStringLogic: (item: T) => string;
  selectedOptionRenderedLabel: (item: T) => string;
  onOptionChange: (value: T) => void;
}

export const VirtualizedAutocomplete = <T,>({
  textFieldLabel,
  onOptionChange,
  filterOptionStringLogic,
  options,
  selectedValue,
  selectedOptionRenderedLabel,
}: Props<T>) => {
  const StyledPopper = styled(Popper)({
    [`& .${autocompleteClasses.listbox}`]: {
      boxSizing: 'border-box',
      '& ul': {
        padding: 0,
        margin: 0,
      },
    },
  });

  const filterOptions = createFilterOptions<T>({
    stringify: filterOptionStringLogic,
    limit: LIST_BOX_FILTERED_ROW_LIMIT,
  });

  return (
    <>
      <Autocomplete
        disableListWrap
        PopperComponent={StyledPopper}
        ListboxComponent={ListBoxComponent}
        options={options}
        value={selectedValue}
        fullWidth
        onChange={(event: SyntheticEvent<Element, Event>, value: T | null) => onOptionChange(value as T)}
        renderInput={(params) => (
          <TextField
            {...params}
            label={textFieldLabel}
            margin={textFieldLabel ? 'normal' : 'none'}
            variant="outlined"
            fullWidth
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon color="primary" fontSize="small" />
                </InputAdornment>
              ),
            }}
          />
        )}
        renderOption={(props, option) => [props, option] as React.ReactNode}
        getOptionLabel={selectedOptionRenderedLabel}
        filterOptions={filterOptions}
      />
    </>
  );
};
