import { Paper } from '@mui/material';
import React from 'react';
import { DateTimeFormat, formatDollars, formatNumberCommaSeparated, getLocalDateTime } from '../../../../../common';
import { filterOperator } from '../../../../../common/components/dataTable/common/';
import { ServerSideDataTable } from '../../../../../common/components/dataTable/serverSide';
import { DatatableColumn, FilterDataType } from '../../../../../common/components/dataTable/types';
import { LoadingProgress } from '../../../../../common/store/types';
import { PagedRequest, PagedResult } from '../../../../../store';
import { Transaction, TransactionCsvRow } from '../store/types';

export interface Props {
  pagedResults?: PagedResult<Transaction>;
  pagedRequest: PagedRequest;
  handlePagedRequest: (pagedRequest: PagedRequest) => void;
  handleSelectTransaction: (transactionId: number) => void;
  handleDownload: (pagedRequest: PagedRequest, mapper: (transactions: Transaction[]) => TransactionCsvRow[]) => void;
  progressTransactions: LoadingProgress;
}

export function TransactionsTable(props: Props): JSX.Element {
  const { pagedResults, pagedRequest, handlePagedRequest, handleSelectTransaction, handleDownload, progressTransactions } = props;

  const columns: DatatableColumn[] = [
    {
      name: 'tradeDate',
      label: 'DATE',
      options: {
        filter: false,
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          if ((pagedResults?.results || [])[dataIndex] == null) return '';
          return getLocalDateTime(pagedResults?.results[dataIndex].tradeDate, DateTimeFormat.Short);
        },
      },
    },
    {
      filterDataType: FilterDataType.string,
      name: 'accountNumber',
      label: 'ACCOUNT',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      filterDataType: FilterDataType.string,
      name: 'systemTransactionType',
      label: 'TRANSACTION TYPE',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      filterDataType: FilterDataType.string,
      name: 'securityCode',
      label: 'SECURITY CODE',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      filterDataType: FilterDataType.numeric,
      textAlign: 'right',
      name: 'units',
      label: 'UNITS',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          if ((pagedResults?.results || [])[dataIndex] == null) return '';

          const units = pagedResults?.results[dataIndex].units;
          return !!units ? formatNumberCommaSeparated(Math.round(units)) : '';
        },
      },
    },
    {
      filterDataType: FilterDataType.numeric,
      textAlign: 'right',
      name: 'unitPrice',
      label: 'PRICE',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          if ((pagedResults?.results || [])[dataIndex] == null) return '';

          const unitPrice = pagedResults?.results[dataIndex].unitPrice;
          return !!unitPrice ? formatDollars(unitPrice) : '';
        },
      },
    },
    {
      textAlign: 'right',
      name: 'transactionValue',
      label: 'VALUE',
      filterDataType: FilterDataType.numeric,
      options: {
        filter: false,
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          if ((pagedResults?.results || [])[dataIndex] == null) return '';

          const transactionValue = pagedResults?.results[dataIndex].transactionValue;
          return !!transactionValue ? formatDollars(transactionValue) : '';
        },
      },
    },
    {
      filterDataType: FilterDataType.string,
      name: 'comments',
      label: 'DESCRIPTION',
      options: {
        filter: true,
        sort: true,
      },
    },
  ];

  return (
    <Paper elevation={0} style={{ marginBottom: '40px' }}>
      <ServerSideDataTable
        columns={columns}
        loadingProgress={progressTransactions}
        pagedRequest={pagedRequest}
        pagedResult={pagedResults}
        csvDownload={() =>
          handleDownload(pagedRequest, (transactions) =>
            transactions.map((t) => {
              return {
                date: getLocalDateTime(t.tradeDate, DateTimeFormat.Short),
                account: t.accountNumber,
                transactionType: t.systemTransactionType,
                securityCode: t.securityCode,
                units: t.units,
                price: t.unitPrice,
                value: t.transactionValue,
                description: t.comments,
              };
            })
          )
        }
        defaultStringFilterOperator={filterOperator.contains}
        defaultNumericFilterOperator={filterOperator.isEqual}
        options={{
          sort: true,
        }}
        handlePagedRequest={handlePagedRequest}
        handleRowClick={(rowData: string[], rowMeta: { dataIndex: number; rowIndex: number }) => {
          const transactionId = pagedResults?.results[rowMeta.rowIndex].id;
          if (!!transactionId) {
            handleSelectTransaction(transactionId);
          }
        }}
      ></ServerSideDataTable>
    </Paper>
  );
}
