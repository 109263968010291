import { validateTfn } from '../../../../common/utils';

export const yupValidateTfn = (value: string | null | undefined): boolean => {
  const rawValue = (value || '').replace(/[\s-_]/g, '');

  if (!!rawValue) {
    return validateTfn(rawValue);
  }
  return true;
};
