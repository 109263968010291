import { AddCircle, Settings } from '@mui/icons-material';
import ArrowDropDownSharpIcon from '@mui/icons-material/ArrowDropDownSharp';
import GridOnIcon from '@mui/icons-material/GridOn';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import { Box, Container, FormControlLabel, FormGroup, Grid, Stack, Switch, Tooltip, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { usePDF } from 'react-to-pdf';
import { CSVDataType, convertToCSVFormat, downloadCsv } from 'src/common';
import { WO2Menu } from 'src/common/components/Menu';
import WO2Button from 'src/common/components/button/Button';
import { ServiceTypesEnum } from 'src/common/types';
import DateRangePicker from '../../../../../../common/components/DateRangePicker';
import PdfFooter from '../../../common/components/PdfFooter';
import PdfHeader from '../../../common/components/PdfHeader';
import { PortfolioVersionSelect } from '../../components/PortfolioVersionSelect';
import { Props } from '../container';
import { AssetGroupItem, CsvRow, ProcessCalculateHoldingsAndPerformanceRequest } from '../store/types';
import AvailableCashCard from './AvailableCashCard';
import CostBaseCard from './CostBaseCard';
import { GroupingSelect } from './GroupingSelect';
import MarketValueCard from './MarketValueCard';
import { PortfolioDetails } from './PortfolioDetails';
import UnrealisedGainsLossCard from './UnrealisedGainsLossCard';
import { CalculateHoldingsAndPerformanceDialog } from './calculateHoldingsAndPerformance/CalculateHoldingsAndPerformanceDialog';
import { NewPortfolioDialog, NewPortfolioDialogResponse } from './newPortfolio/newPortfolioDialog';

export const Overview = ({
  history,
  clientId,
  entityDetails,
  parameters,
  fetchPortfolioDetail,
  setParameters,
  portfolioAssetGroups,
  gainsLossesItems,
  marketValue,
  fetchPortfolioDetailProgress,
  fetchTemplates,
  templates,
  fetchTemplatesProgress,
  createAndGetNewInvestmentService,
  createAndGetNewInvestmentServiceProgress,
  investmentServicesWithVersionsGrouped,
  common,
  setInvestmentServiceVersion,
  afslId,
  selectedInvestmentService,
  isDataFromTriumph,
  hasClientEditAdminOnlyPermission,
  processCalculateHoldingsAndPerformance,
  processCalculateHoldingsAndPerformanceProgress,
}: Props): JSX.Element => {
  const [showTriumphTasks, setShowTriumphTasks] = useState<boolean>(false);
  const [newPortfolioDialogOpen, setNewPortfolioDialogOpen] = useState<boolean>(false);
  const [showPdfFooterAndHeader, setShowPdfFooterAndHeader] = useState(false);
  const { toPDF, targetRef } = usePDF({ filename: entityDetails?.name + '_Portfolio' });

  useEffect(() => {
    if (showPdfFooterAndHeader) {
      toPDF();
      setShowPdfFooterAndHeader(false);
    }
  }, [showPdfFooterAndHeader]);

  const generatePdf = () => {
    setShowPdfFooterAndHeader(true);
  };

  function generateCsv() {
    let csvRow: CsvRow[] = [];

    portfolioAssetGroups.forEach((assetGroup) => {
      if (assetGroup.items.length && assetGroup.groupName !== 'All Assets') {
        assetGroup.items.forEach((asset: AssetGroupItem) => {
          csvRow.push({
            Asset_Class: asset.assetClass as string,
            Security_Name: asset.securityName as string,
            Security_Code: asset.securityCode,
            Units: asset.units,
            Price: asset.unitPrice,
            Price_Date: DateTime.fromISO(asset.priceDate ?? '').toLocaleString(DateTime.DATE_SHORT),
            Value: asset.currentValue,
            Weight: asset.currentWeightPercentage,
            Target_Value: asset.targetValue,
            Target_Weight: asset.targetWeightPercentage,
            Value_Difference: asset.differenceValue,
            Weight_Difference: asset.differenceWeightPercentage,
            Unsettled_Units: asset.unsettledUnits,
            Unsettled_Value: asset.unsettledValue,
          });
        });
      }
    });

    csvRow = csvRow
      .sort((a, b) => a.Security_Name.localeCompare(b.Security_Name))
      .sort((a, b) => a.Asset_Class.localeCompare(b.Asset_Class));

    const data: CSVDataType[] = convertToCSVFormat(csvRow);

    downloadCsv(data, entityDetails?.name + '_Portfolio');
  }

  const exportButtons = [
    {
      icon: <PictureAsPdfOutlinedIcon />,
      label: 'Export to PDF',
      onClick: generatePdf,
      testId: `generatePdf`,
    },
    {
      icon: <GridOnIcon />,
      label: 'Export to CSV',
      onClick: generateCsv,
      testId: `generateCsv`,
    },
  ];

  useEffect(() => {
    if (!!clientId) {
      fetchPortfolioDetail({
        clientId,
        parameters,
        investmentServiceId: common.investmentServiceId,
        isDataFromTriumph: isDataFromTriumph,
      });
    }
  }, [clientId, parameters, common, fetchPortfolioDetail, isDataFromTriumph]);

  return (
    <Container style={{ paddingTop: '20px', paddingBottom: '20px' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Stack direction="row">
            <Box style={{ display: 'flex', flex: 1, justifyContent: 'flex-start' }}>
              <PortfolioVersionSelect
                items={investmentServicesWithVersionsGrouped}
                selectedInvestmentServiceVersion={
                  investmentServicesWithVersionsGrouped.find(
                    (v) => v.investmentServiceId === common.investmentServiceId
                  ) || null
                }
                setInvestmentServiceId={(investmentServiceId, investmentServiceVersionId) => {
                  if (common.investmentServiceId !== investmentServiceId) {
                    setInvestmentServiceVersion({ investmentServiceId, investmentServiceVersionId });
                  }
                }}
                optionForAll={true}
                hasClientEditAdminOnlyPermission={hasClientEditAdminOnlyPermission}
              />
              <WO2Button
                color={'inherit'}
                style={{ color: 'rgba(0,0,0,0.54)' }}
                type="button"
                data-testid={`addPortfolioButton`}
                disableFocusRipple
                disableRipple
                onClick={() => {
                  setNewPortfolioDialogOpen(true);
                }}
              >
                <AddCircle></AddCircle>
                <Typography variant="inherit" style={{ marginLeft: '10px' }}>
                  ADD NEW
                </Typography>
              </WO2Button>
              <WO2Button
                color={'inherit'}
                style={
                  investmentServicesWithVersionsGrouped && investmentServicesWithVersionsGrouped.length !== 0
                    ? { color: 'rgba(0,0,0,0.54)' }
                    : {}
                }
                type="button"
                data-testid={`investmentServicesButton`}
                onClick={() => {
                  history.push(`./configuration`);
                }}
                disabled={!investmentServicesWithVersionsGrouped || investmentServicesWithVersionsGrouped.length === 0}
              >
                <Settings></Settings>
                <Typography variant="inherit" style={{ marginLeft: '10px' }}>
                  CONFIGURE
                </Typography>
              </WO2Button>
            </Box>
            <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Tooltip
                arrow
                title={
                  selectedInvestmentService?.currentVersionStatus !== 'Active'
                    ? 'Portfolio must be active to rebalance'
                    : selectedInvestmentService?.notAvailableToTrade === true
                    ? 'Placing orders for this client is not currently available as part of the trade freeze'
                    : ''
                }
                placement="top"
              >
                <span>
                  {hasClientEditAdminOnlyPermission && (
                    <WO2Button
                      color={'primary'}
                      disableElevation
                      onClick={() => setShowTriumphTasks(true)}
                      variant={'contained'}
                      value="Continue"
                      sx={{ marginBottom: '10px' }}
                    >
                      Calculate Holdings & Performance
                    </WO2Button>
                  )}
                  <WO2Menu
                    testId="export_button"
                    buttonTitle="Export"
                    buttonIcon={
                      <div
                        style={{
                          paddingLeft: '10px',
                          display: 'flex',
                          alignItems: 'center',
                          flexWrap: 'wrap',
                          backgroundColor: 'blue',
                          borderRadius: '25px',
                          padding: '10px 10px 10px 20px',
                          marginTop: '-7px',
                        }}
                      >
                        <span style={{ color: '#ffffff', fontSize: '0.875rem' }}>EXPORT</span>
                        <ArrowDropDownSharpIcon sx={{ color: '#ffffff' }} />
                      </div>
                    }
                    items={exportButtons}
                  />
                  {investmentServicesWithVersionsGrouped.find(
                    (v) => v.investmentServiceId === common.investmentServiceId
                  )?.serviceTypeId !== ServiceTypesEnum.SMA.id && (
                    <WO2Button
                      color={'primary'}
                      disableElevation
                      onClick={() => history.push(`/client/${clientId}/portfolio/rebalance`)}
                      variant={'contained'}
                      value="Continue"
                      sx={{ marginBottom: '10px' }}
                    >
                      Rebalance
                    </WO2Button>
                  )}
                </span>
              </Tooltip>
            </Box>
          </Stack>
          {showTriumphTasks && (
            <CalculateHoldingsAndPerformanceDialog
              onClose={() => {
                setShowTriumphTasks(false);
              }}
              submitTriumphRequest={(request: ProcessCalculateHoldingsAndPerformanceRequest) => {
                processCalculateHoldingsAndPerformance(request);
              }}
              submitTriumphProgress={processCalculateHoldingsAndPerformanceProgress}
              clientId={clientId}
            />
          )}

          {newPortfolioDialogOpen && (
            <NewPortfolioDialog
              fetchTemplates={() => {
                !!afslId && fetchTemplates({ afslId });
              }}
              fetchTemplatesProgress={fetchTemplatesProgress}
              onClose={() => {
                if (!!afslId) {
                  fetchTemplates({ afslId });
                  setNewPortfolioDialogOpen(false);
                }
              }}
              onCreate={async (payload: NewPortfolioDialogResponse) => {
                if (!!clientId && payload.serviceTypeId) {
                  await createAndGetNewInvestmentService({
                    clientId,
                    investmentServiceTemplateVersionId: payload.template?.investmentServiceTemplateVersionId || null,
                    serviceTypeId: payload.serviceTypeId,
                  });
                  history.push(`./configuration`);
                }
              }}
              templates={templates}
              createProgress={createAndGetNewInvestmentServiceProgress}
            />
          )}
        </Grid>

        <div ref={targetRef}>
          {showPdfFooterAndHeader && <PdfHeader clientName={entityDetails?.name + ' - Portfolio'} />}
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <CostBaseCard />
            </Grid>
            <Grid item xs={3}>
              <UnrealisedGainsLossCard />
            </Grid>
            <Grid item xs={3}>
              <MarketValueCard />
            </Grid>
            <Grid item xs={3}>
              <AvailableCashCard />
            </Grid>
            <Grid item xs={12}>
              <Box width={'100%'} display={'inline-flex'} justifyContent={'flex-end'}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={parameters?.showPositionsNotHeld ?? true}
                        onClick={() =>
                          setParameters({ ...parameters, showPositionsNotHeld: !parameters?.showPositionsNotHeld })
                        }
                      />
                    }
                    label="Show positions not held"
                  />
                </FormGroup>
                <DateRangePicker
                  dateRange={parameters?.dates}
                  setDateRange={(dates) => {
                    setParameters({ ...parameters, dates });
                  }}
                  isDateOnly
                />
                <GroupingSelect
                  selectedItem={parameters?.groupingType}
                  setSelectedItem={(groupingType) => {
                    setParameters({ ...parameters, groupingType });
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <PortfolioDetails
                clientId={clientId}
                parameters={parameters}
                portfolioAssetGroups={portfolioAssetGroups}
                gainsLossesItems={gainsLossesItems}
                totalValue={marketValue}
                progress={fetchPortfolioDetailProgress}
                viewFullPortfolio={() => {
                  return;
                }}
              />
            </Grid>
          </Grid>
          {showPdfFooterAndHeader && <PdfFooter />}
        </div>
      </Grid>
    </Container>
  );
};
