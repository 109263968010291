import { createSelector } from '@reduxjs/toolkit';
import { selectSecurity } from '../../store/selectors';

export const selectSecurityPerformanceState = createSelector(selectSecurity, (performanceState) => performanceState.performance);
export const selectParameters = createSelector(selectSecurityPerformanceState, (performance) => performance.parameters);
export const selectMonthlyPerformanceChart = createSelector(selectSecurityPerformanceState, (performance) => performance.monthlyPerformanceChart);
export const selectDailyPerformanceChart = createSelector(selectSecurityPerformanceState, (performance) => performance.dailyPerformanceChart);
export const selectSecurityPeriodicSummary = createSelector(selectSecurityPerformanceState, (performance) => performance.securityPeriodicSummary);
export const selectMonthlyBenchmarkPerformance = createSelector(selectSecurityPerformanceState, (performance) => performance.monthlyBenchmarkPerformance);
export const selectDailyBenchmarkPerformance = createSelector(selectSecurityPerformanceState, (performance) => performance.dailyBenchmarkPerformance);
