import {
  AssignmentTurnedInRounded,
  AttachEmailRounded,
  Delete,
  FileDownloadOutlined,
  FileUploadOutlined,
  Info,
  OpenInNew,
  SendOutlined,
  SendRounded,
  VisibilityOutlined,
} from '@mui/icons-material';
import AddSharpIcon from '@mui/icons-material/AddSharp';
import LinkIcon from '@mui/icons-material/Link';
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useCallback, useEffect, useState } from 'react';
import { LoadingIndicator, WO2Accounts } from 'src/common';
import { WO2PendingAccounts } from 'src/common/components/accounts/PendingAccounts';
import { WO2LinkExistingAccount } from 'src/common/components/accounts/forms/linkExistingAccount';
import { WO2OpenNewAccount } from 'src/common/components/accounts/forms/openNewAccount';
import {
  AccountInstitutionType,
  DetachAccountPayload,
  ExternalAccount,
  PendingAccount,
  PendingAccountStatus,
  PendingAccountWithActions,
  UnlistedAccount,
  WO2AccountActionType,
  WO2PendingAccountActionType,
} from 'src/common/components/accounts/types';
import { AttachmentType, ClientAccountSubType, StatusType, ClientAccountType } from 'src/common/types';
import WO2Button from '../../../../../../common/components/button/Button';
import { feeConsentDocumentTypes } from '../../../common/store/types';
import { UploadFeeConsent } from '../../adviceFees/components/uploadFeeConsent';
import { FeeConsentMethod, PendingFeesModel, UploadFeeConsentValues } from '../../adviceFees/store/types';
import { Props } from '../container';
import { PendingAccountType } from '../store/enum';
import {
  ActionApplicationSubmissionPayload,
  CreatePendingAccountPayload,
  InitiateAccountApplicationPayload,
  SendFormPayload,
  SuperSimplifierUploadDocument,
  UploadSuperSimplifierApplicationFormPayload,
} from '../store/types';
import { Acknowledgement } from './acknowledgement';
import { UploadApplicationForm } from './uploadApplicationForm';
import { Account } from '../../../../../../common/components/accounts/types';
import CloseIcon from '@mui/icons-material/Close';
import { GreenIdStatusEnum } from '../../../common/store/enums';
import { DateTime } from 'luxon';

const useStyles = makeStyles(() => ({
  datatable: {
    '& .MuiToolbar-root': {
      minHeight: '0',
    },
  },
}));

export const Accounts = ({
  accountTypes,
  clientId,
  cancelExternalAccountAddEditMode,
  cancelTradingAccountAddEditMode,
  clientContactDetails,
  clientDetails,
  createUnlistedAccount,
  createExternalAccount,
  documentTypes,
  downloadSuperSimplifierOngoingFeeConsentRenewal,
  downloadSuperSimplifierApplicationLoadingProgress,
  downloadConsentFeeRenewalLoadingProgress,
  externalAccounts,
  attachableCmaAccounts,
  existingAccounts,
  bPayAccountDetails,
  newAccounts,
  externalInstitutions,
  fetchAccounts,
  fetchBPAYDetails,
  fetchAccountTypes,
  fetchClientAccount,
  fetchDocumentTypes,
  fetchExternalAccountForEdit,
  fetchExternalInstitutions,
  fetchTradingAccountForEdit,
  fetchPendingSuperAccount,
  uploadSuperSimplifierOngoingFeeConsentRenewalForm,
  hasClientEditPermission,
  isSuperSimplifierClient,
  loadingProgress,
  loadingTypesProgress,
  loadingPendingProgress,
  onDetachAccount,
  submitOpenMarkets,
  submitAMM,
  submitDesktopBroker,
  selectedExternalAccount,
  saveExternalAccountLoadingProgress,
  saveUnlistedAccountProgress,
  selectedTradingAccount,
  superSimplifierDetails,
  superSimplifierLoadingProgress,
  sendApplicationForm,
  sendApplicationLoadingProgress,
  fetchPendingSuperAccountLoadingProgress,
  setExternalAccountAddMode,
  setTradingAccountAddMode,
  tradingAccounts,
  uploadSuperSimplifierFormLoadingProgress,
  uploadSuperSimplifierApplicationForm,
  fetchEntityDetails,
  pendingAccounts,
  fetchPendingAccounts,
  fetchAttachableCmaAccounts,
  createPendingAccount,
  savePendingAccountsLoadingProgress,
  linkExsistingPendingAccountLoadingProgress,
  initiateExistingMacquarieAccountApplication,
  initiateNewMacquarieAccountApplication,
  initiateUxchangeworkflowAccountApplicationWorkflow,
  fetchClientNewMacquarieAccountDetailByClientId,
  macquarieCashOnlinePortal,
  deletePendingAccount,
  updatePendingAccountStatus,
  hasClientEditAdminOnlyPermission,
  pendingFeesChangeSet,
  activateFees,
  activateFeesDocusign,
  activateFeesUpload,
  fetchPendingAdviceFees,
  actionApplicationSubmission,
  pendingSuperAccount,
  downloadSuperSimplifierApplication,
  sendFeeConsentRenewal,
  fetchClientContacts,
  contactRoles,
  entityDetailsType,
  entityDetailsSubTypeId
}: Props): JSX.Element => {
  const [shouldOpenSuperSimplifierOngoingFeeConsentForm, setSuperSimplifierOngoingFeeConsentForm] =
    useState<boolean>(false);
  const [shouldOpenSuperSimplifierApplication, setSuperSimplifierApplication] = useState<boolean>(false);
  const [hideSendApplicationAcknowledgement, setHideSendApplicationAcknowledgement] = useState<boolean>(true);
  const [sendApplicationAcknowledgement, setSendApplicationAcknowledgement] = useState<boolean>(false);
  const [selectedAccountsDialogType, setSelectedAccountsDialogType] = useState<number | null>(null);
  const [submitAccountId, setSubmitAccountId] = React.useState<number | null>(null);
  const [deleteAccountId, setDeleteAccountId] = React.useState<number | null>(null);
  const [feeConsentFormOpen, setFeeConsentFormOpen] = useState<boolean>(false);
  const [showSuperSimplifierMissingInfo, setShowSuperSimplifierMissingInfo] = useState<number | null>(null);
  const [superSimplifierStatus, setSuperSimplifierStatus] = useState<string>('');
  const [showDialogue, setShowDialogue] = useState<boolean>(false);

  useEffect(() => {
    if (clientId !== null && !!sendApplicationAcknowledgement) {
      sendApplicationForm({
        clientId,
        anchorString: 'sign here',
        isIndividualPension: clientDetails?.subTypeId === ClientAccountSubType.Pension.id,
        isIndividualSuper: clientDetails?.subTypeId === ClientAccountSubType.Super.id,
      });
      setSendApplicationAcknowledgement(false);
      setHideSendApplicationAcknowledgement(true);
    }
  }, [
    setSendApplicationAcknowledgement,
    setHideSendApplicationAcknowledgement,
    sendApplicationAcknowledgement,
    hideSendApplicationAcknowledgement,
    sendApplicationForm,
    clientDetails,
    clientId,
  ]);

  useEffect(() => {
    if (isSuperSimplifierPendingClient) {
      setSuperSimplifierStatus(pendingSuperAccount?.statusMessage ?? '');
    }
    else if (isSuperSimplifierWaitingApprovalClient) {
      setSuperSimplifierStatus(StatusType.WaitingForApproval.displayName);
    }
  }, [pendingSuperAccount, clientDetails]);

  useEffect(() => {
    if (!!clientId) {
      fetchAccounts(clientId);
      fetchPendingAccounts(clientId);
      fetchAttachableCmaAccounts(clientId);
      fetchEntityDetails(clientId);
      fetchPendingAdviceFees({ clientId });
      fetchClientContacts(clientId);

      // We need to know if this account is an active super account during the execution of this function to avoid an
      // asynchronous timing issue when loading data so that the table can be updated accordingly
      const isSuperAcc = !!clientDetails ? ClientAccountSubType.Super.id == clientDetails.subTypeId : undefined
      const activeSscAcc = clientDetails !== null && !!clientDetails?.status && clientDetails?.status === StatusType.Active.id;

      // Call is redudant for clients who are not SSC Super clients or who
      // are not active SSC clients as only active SSC clients would be
      // setup with the BlueDoor service
      fetchBPAYDetails({
        accountId: superSimplifierDetails?.superMemberNumber ?? '',
        isSuper: !!isSuperAcc,
        isActiveSsc: activeSscAcc
      });
    }
  }, [fetchAccounts, fetchPendingAccounts, clientId, fetchEntityDetails, fetchClientContacts, fetchBPAYDetails, superSimplifierDetails,
    clientDetails
  ]);

  useEffect(() => {
    if (!!clientId && isSuperSimplifierClient) {
      fetchPendingSuperAccount(clientId);
    }
  }, [clientId, isSuperSimplifierClient, fetchEntityDetails]);

  const classes = useStyles();

  const init = useCallback(() => {
    fetchExternalInstitutions();
    if (clientId !== null) {
      fetchClientAccount(clientId);
    }
    fetchDocumentTypes();
  }, [fetchExternalInstitutions, fetchDocumentTypes, fetchClientAccount, clientId]);

  useEffect(() => {
    init();
  }, [init]);

  const handleLinkExistingAccountButtonClick = useCallback(() => {
    setSelectedAccountsDialogType(PendingAccountType.LinkExistingAccounts);
  }, [setSelectedAccountsDialogType]);

  const handleOpenNewAccountButtonClick = useCallback(() => {
    setSelectedAccountsDialogType(PendingAccountType.OpenNewAccounts);
  }, [setSelectedAccountsDialogType]);

  const onSaveLinkExistingAccount = (accountData: CreatePendingAccountPayload) => {
    createPendingAccount(accountData).then(() => setSelectedAccountsDialogType(null));
  };

  const onSaveNewAccount = (accountData: CreatePendingAccountPayload) => {
    createPendingAccount(accountData).then(() => setSelectedAccountsDialogType(null));
  };

  const closeDialogue = () => {
    setShowDialogue(false);
  };

  const getFeeChangeSetId = (pendingFeesChangeSet: PendingFeesModel | undefined): number => {
    if (pendingFeesChangeSet === undefined) return clientId ?? 0;
    return pendingFeesChangeSet === null || pendingFeesChangeSet.changeSetId === 0
      ? clientId ?? 0
      : pendingFeesChangeSet.changeSetId;
  };
  const hasPendingAccountValidationErrors = () => {
    if (clientDetails?.status !== StatusType.Pending.id) return false;

    return !!pendingSuperAccount && !!pendingSuperAccount.errors && pendingSuperAccount?.errors.length > 0;
  };

  // Super Simplifier
  let pendingClientAttachmentTypeId: number | null = null;
  const superSimplifierActionItems: WO2AccountActionType[] = [];

  const isSuperSimplifierPendingClient =
    clientDetails !== null && !!clientDetails?.status && clientDetails?.status === StatusType.Pending.id;
  const isSuperSimplifierActiveClient =
    clientDetails !== null && !!clientDetails?.status && clientDetails?.status === StatusType.Active.id;
  const isSuperSimplifierWaitingApprovalClient =
    clientDetails !== null && !!clientDetails?.status && clientDetails?.status === StatusType.WaitingForApproval.id;
  const isSuperOrPension = !!clientDetails
    ? [ClientAccountSubType.Super.id, ClientAccountSubType.Pension.id].includes(clientDetails.subTypeId ?? 0)
    : undefined;
  const isSuper = !!clientDetails ? ClientAccountSubType.Super.id == clientDetails.subTypeId : undefined;

  // Function to check if any item has verified name
  const checkContactIsVerified = (items: { greenIdVerificationStatus: { id: number } }[]): boolean => {
    return items.some(
      (item) =>
        item.greenIdVerificationStatus.id === GreenIdStatusEnum.VERIFIED.id ||
        item.greenIdVerificationStatus.id === GreenIdStatusEnum.VERIFIED_ADMIN.id ||
        item.greenIdVerificationStatus.id === GreenIdStatusEnum.VERIFIED_WITH_CHANGES.id ||
        item.greenIdVerificationStatus.id === GreenIdStatusEnum.VERIFIED_EXTERNALLY.id
    );
  };
  // Check if any item has verified name
  const hasVerified = checkContactIsVerified(contactRoles);

  const evaluateFeeConsentErrMsg = () => {
    let errMsg = '';

    const currentDate = DateTime.local();

    const referenceDate = superSimplifierDetails?.feeAnniversaryDate ?
      DateTime.fromISO(superSimplifierDetails?.feeAnniversaryDate) : DateTime.local();
      
    // No point enforcing this rule if an anniversary date isn't specified or if client is not active
    if (superSimplifierDetails?.feeAnniversaryDate && clientDetails?.status === 2) {
      
      // Current date < 60 days - (reference date + 365 days)
      if (currentDate < referenceDate.plus({days: 305})) {
        errMsg = "'Ongoing Fee Consent Renewals' must be signed between 60 days before and up to 150 days after the reference date";
      }
      // Current date > 150 days + (reference date + 365 days)
      else if (currentDate > referenceDate.plus({days: 515})) {
        errMsg = "'Fee consent has expired. Renewals cannot be generated beyond 150 days after the reference date'. A new fee consent is required.";
      }
    }

    return errMsg;
  };

  const feeConsentErrMsg = evaluateFeeConsentErrMsg();

  if (isSuperSimplifierActiveClient) {
    superSimplifierActionItems.push(
      {
        label: 'Download Ongoing Fee Consent Renewal',
        clickHandler: () => {
          if (clientId !== null) {
            downloadSuperSimplifierOngoingFeeConsentRenewal({ clientId });
          }
        },
        icon: <FileDownloadOutlined color="primary" />,
        name: 'accountId',
      },
      {
        label: 'Upload Signed Ongoing Fee Consent',
        clickHandler: () => {
          if (clientId !== null) {
            setSuperSimplifierOngoingFeeConsentForm(true);
          }
        },
        icon: <FileUploadOutlined color="primary" />,
        name: 'accountId',
      },
      {
        label: 'Send Fee Consent Renewal',
        clickHandler: () => {
          if (clientId !== null) {
            sendFeeConsentRenewal({
              clientId,
              anchorString: 'sign here',
            });
          }
        },
        icon: <SendOutlined color="primary" />,
        name: 'accountId',
      }
    );
  } 
  else if (hasClientEditAdminOnlyPermission && isSuperSimplifierWaitingApprovalClient) {
    superSimplifierActionItems.push({
      label: 'Send to DDHG',
      clickHandler: () => {
        if (clientId !== null && hasVerified) {
          const payload: ActionApplicationSubmissionPayload = { clientId: clientId };
          actionApplicationSubmission(payload);
        }
        if (!hasVerified) {
          setShowDialogue(true);
        }
      },
      icon: hasVerified ? <SendOutlined color="primary" /> : <SendOutlined color="disabled" />,
      name: 'accountId',
    });
  } else if (hasPendingAccountValidationErrors()) {
    if (isSuperOrPension) {
      superSimplifierActionItems.push({
        label: 'Download Document',
        clickHandler: () => {
          if (clientId !== null) {
            downloadSuperSimplifierApplication({ clientId: clientId });
          }
        },
        icon: <FileDownloadOutlined color="primary" />,
        name: 'accountId',
      });
    }
    superSimplifierActionItems.push({
      label: 'View missing info',
      clickHandler: () => {
        setShowSuperSimplifierMissingInfo(clientId);
      },
      icon: <VisibilityOutlined color="primary" />,
      name: 'accountId',
    });
  } else if (!hasPendingAccountValidationErrors()) {
    if (clientDetails?.subTypeId === ClientAccountSubType.Pension.id) {
      pendingClientAttachmentTypeId = AttachmentType.SuperSimplifierPension.id;
    } else if (clientDetails?.subTypeId === ClientAccountSubType.Super.id) {
      pendingClientAttachmentTypeId = AttachmentType.SuperSimplifierSuper.id;
    }
    if (!!clientId) {
      if (pendingSuperAccount?.errors === null || pendingSuperAccount?.errors?.length === 0) {
        superSimplifierActionItems.push(
          {
            label: 'Download Document',
            clickHandler: () => {
              if (clientId !== null) {
                downloadSuperSimplifierApplication({ clientId: clientId });
              }
            },
            icon: <FileDownloadOutlined color="primary" />,
            name: 'accountId',
          },
          {
            label: 'Upload Signed Application Form',
            clickHandler: () => {
              if (clientId !== null) {
                setSuperSimplifierApplication(true);
              }
            },
            icon: <FileUploadOutlined color="primary" />,
            name: 'accountId',
          },
          {
            label: 'Send Application',
            clickHandler: () => {
              if (clientId !== null) {
                const request: SendFormPayload = {
                  anchorString: 'sign here',
                  clientId: clientId,
                  isIndividualPension: clientDetails?.subTypeId === ClientAccountSubType.Pension.id,
                  isIndividualSuper: clientDetails?.subTypeId === ClientAccountSubType.Super.id,
                };
                sendApplicationForm(request);
              }
            },
            icon: <SendOutlined color="primary" />,
            name: 'accountId',
          }
        );
      }
    }
  }

  const handleActivatePendingFeesClick = async (uploadFeeConsentValues: UploadFeeConsentValues) => {
    if (
      !!clientId &&
      !!pendingFeesChangeSet &&
      !!pendingFeesChangeSet.pendingFees &&
      pendingFeesChangeSet.pendingFees.length > 0
    ) {
      if (!uploadFeeConsentValues.isSuperOrPension) {
        await activateFees({
          clientId: clientId,
          feeChangeSetId: pendingFeesChangeSet?.changeSetId ?? 0,
          message: 'Pending fees activated',
        });
      } else if (uploadFeeConsentValues.feeConsentTypeId === FeeConsentMethod.SendViaDocusign.id) {
        await activateFeesDocusign({ clientId: clientId, message: 'Pending fees activation process started' });
      } else if (uploadFeeConsentValues.feeConsentTypeId === FeeConsentMethod.UploadDocument.id) {
        await activateFeesUpload({
          clientId: clientId,
          feeChangeSetId: pendingFeesChangeSet?.changeSetId ?? 0,
          uploadFeeConsentValues: uploadFeeConsentValues,
          message: 'Pending fees activated',
        });
      }
    }
  };

  let portfolioAccounts: Account[] = tradingAccounts;

  if (
    (clientDetails?.subTypeId === ClientAccountSubType.getByName('Super')?.id ||
      clientDetails?.subTypeId === ClientAccountSubType.getByName('Pension')?.id) &&
    clientDetails?.accountTypeId === ClientAccountType.getByName('Individual')?.id
  ) {
    portfolioAccounts = [];
    tradingAccounts.map((acc) => {
      const currentAccount = {
        ...acc,
        institution: 'DASH Custodial Managed Funds',
      };
      portfolioAccounts.push(currentAccount);
    });
  }

  return (
    <>
      {!(
        (clientDetails?.subTypeId === ClientAccountSubType.Super.id ||
          clientDetails?.subTypeId === ClientAccountSubType.Pension.id) &&
        clientDetails?.status !== 2
      ) && (
          <Box marginTop="60px" display="flex" justifyContent="flex-end">
            {!isSuperOrPension && (
              <WO2Button
                color="primary"
                style={{ marginLeft: '20px', padding: '15px 28px' }}
                disableElevation
                variant="contained"
                value="Link Existing Account"
                data-testid="linkExistingAccountButton"
                onClick={handleLinkExistingAccountButtonClick}
              >
                <LinkIcon fontSize="medium" />
                <Typography variant="inherit" style={{ marginLeft: '10px' }}>
                  Link Existing Account
                </Typography>
              </WO2Button>
            )}
            <WO2Button
              color="primary"
              style={{ marginLeft: '20px', padding: '15px 28px' }}
              disableElevation
              variant="contained"
              value="Open New Account"
              data-testid="openNewAccountButton"
              onClick={handleOpenNewAccountButtonClick}
            >
              <AddSharpIcon fontSize="small" />
              <Typography variant="inherit" style={{ marginLeft: '10px' }}>
                Open New Account
              </Typography>
            </WO2Button>
            {!isSuperOrPension && (
              <WO2LinkExistingAccount
                existingAccounts={existingAccounts}
                clientDetails={clientDetails}
                isOpen={selectedAccountsDialogType === PendingAccountType.LinkExistingAccounts}
                saveProgress={linkExsistingPendingAccountLoadingProgress}
                onSave={onSaveLinkExistingAccount}
                clientId={clientId}
                handleCloseModal={() => {
                  setSelectedAccountsDialogType(null);
                }}
              />
            )}
            <WO2OpenNewAccount
              newAccounts={newAccounts}
              clientDetails={clientDetails}
              isOpen={selectedAccountsDialogType === PendingAccountType.OpenNewAccounts}
              saveProgress={savePendingAccountsLoadingProgress}
              onSave={onSaveNewAccount}
              clientId={clientId}
              handleCloseModal={() => {
                setSelectedAccountsDialogType(null);
              }}
            />
          </Box>
        )}
      {!!pendingAccounts?.length &&
        !(
          (clientDetails?.subTypeId === ClientAccountSubType.Super.id ||
            clientDetails?.subTypeId === ClientAccountSubType.Pension.id) &&
          clientDetails?.status !== 2
        ) && (
          <>
            <Typography variant="h4" gutterBottom style={{ marginTop: '40px' }}>
              Pending accounts
            </Typography>
            <WO2PendingAccounts
              clientId={clientId}
              attachableCmaAccounts={attachableCmaAccounts.attachableCmaAccounts}
              accounts={pendingAccounts.map((account: PendingAccount): PendingAccountWithActions => {
                const actions: WO2PendingAccountActionType[] = [];

                if (
                  (account.institutionId === AccountInstitutionType.DesktopBroker.id ||
                    account.institutionId === AccountInstitutionType.Openmarkets.id ||
                    account.institutionId === AccountInstitutionType.AMM.id) &&
                  account.state === 'Existing'
                ) {
                  actions.push({
                    label: 'Submit instructions',
                    clickHandler: (accountId: number | null | undefined) => {
                      setSubmitAccountId(accountId || null);
                    },
                    icon: <Info color="primary" />,
                    name: 'id',
                  });
                }
                if (
                  clientDetails?.subTypeId !== ClientAccountSubType.Pension.id &&
                  clientDetails?.subTypeId !== ClientAccountSubType.Super.id &&
                  account.institutionId === AccountInstitutionType.AMM.id &&
                  account.state === 'New' &&
                  account.isDataStatusReady &&
                  account.statusId === PendingAccountStatus.getByName('Proposed')?.id
                ) {
                  actions.push({
                    label: 'Submit application',
                    clickHandler: (accountId: number | null | undefined) => {
                      setSubmitAccountId(accountId || null);
                    },
                    icon: <SendRounded color="primary" />,
                    name: 'id',
                  });
                }

                if (account.errors?.length) {
                  actions.push({
                    label: 'View missing info',
                    clickHandler: (accountId: number | null | undefined) => {
                      setSubmitAccountId(accountId || null);
                    },
                    icon: <VisibilityOutlined color="primary" />,
                    name: 'id',
                  });
                }
                if (
                  account.institutionId === AccountInstitutionType.MacquarieCma.id &&
                  account.state === 'Existing' &&
                  account.statusId === PendingAccountStatus.getByName('Proposed')?.id
                ) {
                  actions.push({
                    label: 'Generate form',
                    clickHandler: (accountId: number | null | undefined) => {
                      setSubmitAccountId(accountId || null);
                    },
                    icon: <AttachEmailRounded color="primary" />,
                    name: 'id',
                  });
                }
                if (
                  account.institutionId === AccountInstitutionType.MacquarieCma.id &&
                  account.state === 'New' &&
                  account.isDataStatusReady &&
                  account.statusId === PendingAccountStatus.getByName('Proposed')?.id
                ) {
                  actions.push({
                    label: 'Submit application',
                    clickHandler: (accountId: number | null | undefined) => {
                      setSubmitAccountId(accountId || null);
                    },
                    icon: <SendRounded color="primary" />,
                    name: 'id',
                  });
                }

                if (
                  account.institutionId === AccountInstitutionType.MacquarieCma.id &&
                  account.state === 'New' &&
                  account.statusId === PendingAccountStatus.getByName('Submitted')?.id
                ) {
                  actions.push({
                    label: 'Access application',
                    clickHandler: (accountId: number | null | undefined) => {
                      setSubmitAccountId(accountId || null);
                    },
                    icon: <OpenInNew color="primary" />,
                    name: 'id',
                  });
                }
                if (
                  account.institutionId === AccountInstitutionType.ManagedFunds.id &&
                  account.state === 'New' &&
                  account.isDataStatusReady &&
                  account.statusId === PendingAccountStatus.getByName('Proposed')?.id
                ) {
                  actions.push({
                    label: 'Submit application',
                    clickHandler: (accountId: number | null | undefined) => {
                      setSubmitAccountId(accountId || null);
                    },
                    icon: <SendRounded color="primary" />,
                    name: 'id',
                  });
                }
                if (
                  account.institutionId === AccountInstitutionType.DesktopBroker.id &&
                  account.state === 'New' &&
                  account.isDataStatusReady &&
                  account.statusId === PendingAccountStatus.getByName('Proposed')?.id
                ) {
                  actions.push({
                    label: 'Submit application',
                    clickHandler: (accountId: number | null | undefined) => {
                      setSubmitAccountId(accountId || null);
                    },
                    icon: <SendRounded color="primary" />,
                    name: 'id',
                  });
                }

                if (
                  account.institutionId === AccountInstitutionType.Openmarkets.id &&
                  account.state === 'New' &&
                  account.isDataStatusReady &&
                  account.statusId === PendingAccountStatus.getByName('Proposed')?.id
                ) {
                  actions.push({
                    label: 'Submit application',
                    clickHandler: (accountId: number | null | undefined) => {
                      setSubmitAccountId(accountId || null);
                    },
                    icon: <SendRounded color="primary" />,
                    name: 'id',
                  });
                }
                if (
                  hasClientEditAdminOnlyPermission &&
                  (account.statusId === PendingAccountStatus.getByName('Submitted')?.id ||
                    account.statusId === PendingAccountStatus.getByName('Error')?.id ||
                    (account.institutionId === AccountInstitutionType.AMM.id &&
                      (clientDetails?.subTypeId === ClientAccountSubType.Pension.id ||
                        clientDetails?.subTypeId === ClientAccountSubType.Super.id)))
                ) {
                  actions.push({
                    label: 'Mark as complete',
                    clickHandler: (accountId: number | null | undefined) => {
                      if (clientId && accountId) {
                        updatePendingAccountStatus({
                          clientId: clientId,
                          pendingAccountId: accountId.toString(),
                          status: 'Completed',
                          statusMessage: 'Marked as complete by DASH',
                        });
                      }
                    },
                    icon: <AssignmentTurnedInRounded color="primary" />,
                    name: 'id',
                  });
                }
                if (account.statusId === 1) {
                  actions.push({
                    label: 'Delete account',
                    clickHandler: (accountId: number | null | undefined) => {
                      setDeleteAccountId(accountId || null);
                    },
                    icon: <Delete color="primary" />,
                    name: 'id',
                  });
                }

                const accountWithActions: PendingAccountWithActions = {
                  ...account,
                  actions,
                };
                return accountWithActions;
              })}
              hideAddButton={false}
              submitAccountId={submitAccountId}
              onOpenMarketsSubmit={(cmaAccount) => {
                if (submitAccountId && clientId) {
                  submitOpenMarkets({
                    cmaName: cmaAccount.name,
                    cmaNumber: cmaAccount.number,
                    cmaSubNumber: cmaAccount.bsb,
                    forceRoa: cmaAccount.forceRoa,
                    clientId: clientId,
                    pendingAccountId: submitAccountId.toString(),
                  }).then(() => {
                    setSubmitAccountId(null);
                  });
                }
              }}
              onAMMSubmit={(cmaAccount) => {
                if (submitAccountId && clientId) {
                  submitAMM({
                    cmaName: cmaAccount.name,
                    cmaNumber: cmaAccount.number,
                    cmaSubNumber: cmaAccount.bsb,
                    forceRoa: cmaAccount.forceRoa,
                    clientId: clientId,
                    pendingAccountId: submitAccountId.toString(),
                  }).then(() => {
                    setSubmitAccountId(null);
                  });
                }
              }}
              onDesktopBrokerSubmit={(cmaAccount) => {
                if (submitAccountId && clientId) {
                  submitDesktopBroker({
                    cmaName: cmaAccount.name,
                    cmaNumber: cmaAccount.number,
                    cmaSubNumber: cmaAccount.bsb,
                    forceRoa: cmaAccount.forceRoa,
                    clientId: clientId,
                    pendingAccountId: submitAccountId.toString(),
                  }).then(() => {
                    setSubmitAccountId(null);
                  });
                }
              }}
              onUXchangeSubmit={(selectedCmaAccount: InitiateAccountApplicationPayload) => {
                if (submitAccountId && clientId) {
                  initiateUxchangeworkflowAccountApplicationWorkflow({
                    cmaName: selectedCmaAccount.cmaName,
                    cmaNumber: selectedCmaAccount.cmaNumber,
                    cmaSubNumber: selectedCmaAccount.cmaSubNumber,
                    forceRoa: selectedCmaAccount.forceRoa,
                    pendingAccountId: submitAccountId.toString(),
                    clientId,
                  }).then(() => {
                    setSubmitAccountId(null);
                  });
                }
              }}
              deleteAccountId={deleteAccountId}
              onDeleteAccount={() => {
                if (deleteAccountId && clientId) {
                  setDeleteAccountId(null);
                }
              }}
              deletePendingAccount={deletePendingAccount}
              onCloseSubmit={() => setSubmitAccountId(null)}
              onCloseDelete={() => setDeleteAccountId(null)}
              accountTypes={accountTypes}
              fetchAccountTypes={fetchAccountTypes}
              id="client-accounts-trading"
              isReadOnly={true}
              loadingProgress={loadingPendingProgress}
              fetchPendingAccounts={fetchPendingAccounts}
              loadingTypesProgress={loadingTypesProgress}
              onAddEdit={(accountId: number | null | undefined) => {
                if (!!accountId && !!clientId) {
                  // load document from backend
                  fetchTradingAccountForEdit({ clientId, accountId });
                } else if (accountId === null && clientId === null) {
                  setTradingAccountAddMode();
                } else {
                  cancelTradingAccountAddEditMode();
                }
              }}
              saveUnlistedAccountProgress={saveUnlistedAccountProgress}
              onSaveUnlistedForm={(account: UnlistedAccount) => {
                if (clientId !== null) {
                  createUnlistedAccount({ ...account, clientId });
                }
              }}
              hideAllActions={!hasClientEditPermission}
              initiateExistingMacquarieAccountApplication={initiateExistingMacquarieAccountApplication}
              initiateNewMacquarieAccountApplication={initiateNewMacquarieAccountApplication}
              initiateUxchangeworkflowAccountApplicationWorkflow={initiateUxchangeworkflowAccountApplicationWorkflow}
              fetchClientNewMacquarieAccountDetailByClientId={fetchClientNewMacquarieAccountDetailByClientId}
              macquarieCashOnlinePortal={macquarieCashOnlinePortal}
            />
          </>
        )}

      {isSuperSimplifierClient && (
        <Box className={classes.datatable}>
          <LoadingIndicator
            progress={{
              isLoading:
                downloadConsentFeeRenewalLoadingProgress.isLoading ||
                downloadSuperSimplifierApplicationLoadingProgress.isLoading ||
                sendApplicationLoadingProgress.isLoading ||
                fetchPendingSuperAccountLoadingProgress.isLoading ||
                superSimplifierDetails === null ||
                !clientDetails,
              hasErrors: false,
            }}
          >
            {superSimplifierDetails !== null && !!clientDetails && (
              <>
                {isSuperSimplifierPendingClient && (
                  <Typography
                    data-testid="accountsSuperSimpliferPendingMessage"
                    variant="inherit"
                    gutterBottom
                    style={{ marginTop: '40px' }}
                  >
                    Please complete the Super Simplifier application before opening third-party accounts.
                  </Typography>
                )}
                <Typography variant="h4" gutterBottom style={{ marginTop: '40px' }}>
                  Super Simplifier account
                </Typography>
                <WO2Accounts
                  clientId={clientId}
                  accounts={[
                    {
                      accountId: -1,
                      accountTypeId: clientDetails.accountTypeId,
                      accountName: clientDetails.name,
                      accountNumber: superSimplifierDetails.superMemberNumber,
                      bsb: '',
                      institutionId: null,
                      institution: '',
                      securityStatus: null,
                      isExternal: false,
                      brokerId: null,
                    },
                  ]}
                  bPayAccDetails={bPayAccountDetails.bpayAccountDetails}
                  superSimplifierAccountStatus={clientDetails.status}
                  superSimplifierAccountString={superSimplifierStatus}
                  actions={superSimplifierActionItems}
                  isReadOnly={true}
                  isExternal={false}
                  isSuper={isSuper}
                  loadingProgress={superSimplifierLoadingProgress}
                  hideAddButton={true} 
                  hideAllActions={isSuperSimplifierActiveClient}
                  infoMessage={isSuperSimplifierActiveClient? "Manage client fee consent from the 'Advice Fees' page" : undefined}
                  id="SuperSimplifier"
                  onCloseMissingInfo={() => setShowSuperSimplifierMissingInfo(null)}
                  errors={pendingSuperAccount.errors}
                  missingInfoAccountId={showSuperSimplifierMissingInfo}
                  externalInstitutions={externalInstitutions}
                  fetchExternalInstitutions={fetchExternalInstitutions}
                  feeConsentReferenceDateErrMsg={feeConsentErrMsg}
                />
              </>
            )}
          </LoadingIndicator>
        </Box>
      )}

      <Typography
        variant="h4"
        gutterBottom
        style={{ paddingTop: '20px', marginTop: isSuperSimplifierClient ? '40px' : '0' }}
      >
        Portfolio accounts
      </Typography>
      <WO2Accounts
        clientId={clientId}
        accounts={portfolioAccounts}
        entityDetailsType={entityDetailsType}
        entityDetailsSubTypeId={entityDetailsSubTypeId}
        actions={[
          {
            label: 'Visibility',
            clickHandler: (accountId: number | null | undefined) => {
              if (!!accountId && clientId !== null) {
                // load document from backend
                fetchTradingAccountForEdit({ clientId, accountId });
              } else if (accountId === null && clientId === null) {
                setTradingAccountAddMode();
              } else {
                cancelTradingAccountAddEditMode();
              }
            },
            icon: <VisibilityOutlined color="primary" />,
            name: 'accountId',
          },
        ]}
        onDetach={(payload: DetachAccountPayload) => {
          onDetachAccount(payload);
        }}
        accountTypes={accountTypes}
        fetchAccountTypes={fetchAccountTypes}
        id="client-accounts-trading"
        isReadOnly={true}
        isExternal={false}
        loadingProgress={loadingProgress}
        loadingTypesProgress={loadingTypesProgress}
        onAddEdit={(accountId: number | null | undefined) => {
          if (!!accountId && !!clientId) {
            // load document from backend
            fetchTradingAccountForEdit({ clientId, accountId });
          } else if (accountId === null && clientId === null) {
            setTradingAccountAddMode();
          } else {
            cancelTradingAccountAddEditMode();
          }
        }}
        saveUnlistedAccountProgress={saveUnlistedAccountProgress}
        onSaveUnlistedForm={(account: UnlistedAccount) => {
          if (clientId !== null) {
            createUnlistedAccount({ ...account, clientId });
          }
        }}
        selectedAccount={
          selectedTradingAccount === null ||
            tradingAccounts.find((a) => a.accountId === selectedTradingAccount?.accountId)
            ? selectedTradingAccount
            : undefined
        }
        hideAllActions={!hasClientEditPermission}
        errors={null}
        fetchExternalInstitutions={fetchExternalInstitutions}
        externalInstitutions={externalInstitutions}
      />
      <Typography variant="h4" gutterBottom style={{ marginTop: '40px' }}>
        External accounts
      </Typography>
      <WO2Accounts
        clientId={clientId}
        accounts={externalAccounts}
        actions={[
          {
            label: 'Visibility',
            clickHandler: (accountId: number | null | undefined) => {
              if (!!accountId && !!clientId) {
                // load document from backend
                fetchExternalAccountForEdit({ clientId, accountId });
              } else if (accountId === null && clientId === null) {
                setExternalAccountAddMode();
              } else {
                cancelExternalAccountAddEditMode();
              }
            },
            icon: <VisibilityOutlined color={'primary'} />,
            name: 'accountId',
          },
        ]}
        onDetach={(payload: DetachAccountPayload) => {
          onDetachAccount(payload);
        }}
        accountTypes={accountTypes}
        fetchAccountTypes={fetchAccountTypes}
        isReadOnly={true}
        isExternal={true}
        id="client-accounts-trading"
        loadingProgress={loadingProgress}
        loadingTypesProgress={loadingTypesProgress}
        onAddEdit={(accountId: number | null | undefined) => {
          if (!!accountId && !!clientId) {
            // load document from backend
            fetchExternalAccountForEdit({ clientId, accountId });
          } else if (accountId === null && clientId === null) {
            setExternalAccountAddMode();
          } else {
            cancelExternalAccountAddEditMode();
          }
        }}
        onSaveExternal={(payload: ExternalAccount) => {
          if (!!clientId) {
            createExternalAccount({ ...payload, clientId });
          }
        }}
        saveExternalAccountProgress={saveExternalAccountLoadingProgress}
        externalInstitutions={externalInstitutions}
        selectedAccount={
          selectedExternalAccount === null ||
            externalAccounts.find((a) => a.accountId === selectedExternalAccount?.accountId)
            ? selectedExternalAccount
            : undefined
        }
        hideAllActions={!hasClientEditPermission}
        errors={null}
        fetchExternalInstitutions={fetchExternalInstitutions}
      />
      {pendingClientAttachmentTypeId !== null ? (
        <UploadApplicationForm
          attachmentTypeId={pendingClientAttachmentTypeId}
          clientEmail={clientContactDetails?.email}
          documentTypes={documentTypes}
          onClose={() => {
            setSuperSimplifierApplication(false);
          }}
          onSave={(values: SuperSimplifierUploadDocument) => {
            if (clientId !== null && pendingClientAttachmentTypeId !== null) {
              const request: UploadSuperSimplifierApplicationFormPayload = {
                signedApprovalDocument: values.base64Content,
                clientId: clientId,
                feeChangeSetId: getFeeChangeSetId(pendingFeesChangeSet),
                isIndividualPension: clientDetails?.subTypeId === ClientAccountSubType.Pension.id,
                isIndividualSuper: clientDetails?.subTypeId === ClientAccountSubType.Super.id,
              };
              uploadSuperSimplifierApplicationForm(request).then(() => setSuperSimplifierApplication(false));
            }
          }}
          saveProgress={uploadSuperSimplifierFormLoadingProgress}
          shouldOpenForm={shouldOpenSuperSimplifierApplication}
          title="Upload Signed Application Form"
          data-testid="accountUploadApplicationForm"
        />
      ) : (
        <UploadApplicationForm
          attachmentTypeId={AttachmentType.FeeConsent.id}
          clientEmail={clientContactDetails?.email}
          documentTypes={documentTypes}
          onClose={() => {
            setSuperSimplifierOngoingFeeConsentForm(false);
          }}
          onSave={(values: SuperSimplifierUploadDocument) => {
            if (clientId !== null) {
              uploadSuperSimplifierOngoingFeeConsentRenewalForm({
                fileName: values.fileName,
                base64Content: values.base64Content,
                entityId: clientId,
                attachmentTypeId: AttachmentType.FeeConsent.id,
              });
              setSuperSimplifierOngoingFeeConsentForm(false);
            }
          }}
          shouldOpenForm={shouldOpenSuperSimplifierOngoingFeeConsentForm}
          saveProgress={uploadSuperSimplifierFormLoadingProgress}
          title="Upload Ongoing Fee Consent Form"
          shouldHideAcknowledgement={true}
        />
      )}
      {hasClientEditAdminOnlyPermission && feeConsentFormOpen && (
        <UploadFeeConsent
          isViewMode={false}
          isSuperOrPension={isSuperOrPension === true}
          documentTypes={documentTypes.filter((documentType) => feeConsentDocumentTypes.includes(documentType.id))}
          onSave={handleActivatePendingFeesClick}
          handleCloseModal={() => {
            setFeeConsentFormOpen(false);
          }}
          downloadFeeConsent={() => {
            if (!!clientId) {
              downloadSuperSimplifierOngoingFeeConsentRenewal({ clientId });
            }
          }}
        />
      )}
      <Acknowledgement
        shouldOpenForm={!hideSendApplicationAcknowledgement}
        hasAcknowledged={sendApplicationAcknowledgement}
        setAcknowledgement={setSendApplicationAcknowledgement}
        clientEmail={clientContactDetails?.email}
        onClose={() => {
          setHideSendApplicationAcknowledgement(true);
        }}
      />
      {showDialogue && (
        <>
          <div>
            <Dialog
              aria-labelledby="customized-dialog-title"
              open={showDialogue}
              onClose={closeDialogue}
              maxWidth={'md'}
            >
              <Box style={{ position: 'relative', height: '20px' }}>
                <IconButton
                  aria-label="close"
                  onClick={closeDialogue}
                  style={{ position: 'absolute', right: '9px', top: '0px' }}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
              <DialogTitle
                style={{ fontWeight: 700, marginBottom: '10px', color: 'rgb(67, 6, 250)', fontSize: '0.9rem' }}
              >
                {'Error while Occured while sending to DDHG?'}
              </DialogTitle>
              <DialogContent dividers>
                <Typography gutterBottom variant="h5" style={{ fontWeight: 600, marginBottom: '10px' }}>
                  Primary contact needs to be verified in GreenID or externally verified before sending to DDHG.
                </Typography>
              </DialogContent>
            </Dialog>
          </div>
        </>
      )}
    </>
  );
};
