import { createSelector } from '@reduxjs/toolkit';
import { selectCapitalGainsLosses } from '../../store/selector';
import { RealisedCapitalGainsLossesResult, UnrealisedCapitalGainsLossesResult } from './types';

export const selectOverview = createSelector(
  selectCapitalGainsLosses,
  (gainsAndLossesState) => gainsAndLossesState.overviewState
);

export const selectParameters = createSelector(selectOverview, (overview) => overview.parameters);

export const selectUnrealisedResults = createSelector(
  selectOverview,
  (overview) => overview.unrealisedResult as UnrealisedCapitalGainsLossesResult
);

export const selectRealisedResults = createSelector(
  selectOverview,
  (overview) => overview.realisedResult as RealisedCapitalGainsLossesResult
);

export const filteredRealisedResults = createSelector(
  selectRealisedResults,
  selectParameters,
  (results, parameters) => {
    const filteredSecurities = results?.securityDetails.filter(
      (i) =>
        parameters.filter.trim() === '' ||
        i.securityTotals.securityName.toLowerCase().includes(parameters.filter.toLowerCase()) ||
        i.securityTotals.securityCode.toLowerCase().includes(parameters.filter.toLowerCase())
    );
    if (!!results?.summary && !!results?.securityDetails && results.securityDetails.length > 0) {
      const result: RealisedCapitalGainsLossesResult = {
        summary: results.summary,
        total: results.total,
        securityDetails: filteredSecurities,
      };
      return result;
    }
  }
);

export const filteredUnrealisedResults = createSelector(
  selectUnrealisedResults,
  selectParameters,
  (results, parameters) => {
    const filteredSecurities = results?.securityDetails.filter(
      (i) =>
        parameters.filter.trim() === '' ||
        i.securityTotals.securityName.toLowerCase().includes(parameters.filter.toLowerCase()) ||
        i.securityTotals.securityCode.toLowerCase().includes(parameters.filter.toLowerCase())
    );
    if (!!results?.summary && !!results?.securityDetails && results.securityDetails.length > 0) {
      const result: UnrealisedCapitalGainsLossesResult = {
        summary: results.summary,
        total: results.total,
        securityDetails: filteredSecurities,
      };
      return result;
    }
  }
);
