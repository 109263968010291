import { connect, ConnectedProps } from 'react-redux';
import { fetchDataFeeds, selectDataFeeds } from 'src/store/userSettings';
import { RootState } from '../../../../reducers';
import { selectHasClientEditAdminOnly } from '../../../../store/selectors';
import Navigation from './Navigation';

const mapStateToProps = (state: RootState) => ({
  hasClientEditAdminOnlyPermission: selectHasClientEditAdminOnly(state),
  dataFeeds: selectDataFeeds(state),
});

const mapDispatchToProps = {
  fetchDataFeeds,
};

interface Props {
  children: React.ReactElement;
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export type NavigationStateProps = ConnectedProps<typeof connector> & Props;
export default connector(Navigation);
