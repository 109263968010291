import { Container, Draggable } from '@dash/react-smooth-dnd';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import arrayMove from 'array-move';
import React from 'react';
import { ReportToSort } from 'src/features/clients/client/reports/store/types';
import WO2Button from './button/Button';

interface Props {
  itemsToSort: ReportToSort[];
  continueReportProcess: () => void;
  setReportOrder: (reportOrder: ReportToSort[]) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalPaperItemOrdering: {
    backgroundColor: theme.palette.background.paper,
    backgroundRepeat: 'no-repeat',
    boxShadow: theme.shadows[5],
  },
  buttonContainer: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'space-between',
    verticalAlign: 'top',
    paddingTop: '30px',
    width: '100%',
  },
  pageTitle: {
    fontweight: 'bold',
  },
}));

export default function ItemOrderingModal(props: Props): JSX.Element {
  const classes = useStyles();
  const { itemsToSort, continueReportProcess, setReportOrder } = props;

  const UpdateSortOrder = (removedIndex: number, addedIndex: number) => {
    const inputs = Object.assign([], itemsToSort);
    const result = arrayMove(inputs, removedIndex, addedIndex);
    setReportOrder(result);
  };

  return (
    <div>
      <div className={classes.modalPaperItemOrdering} style={{ width: '450px' }}>
        <div style={{ padding: '20px' }}>
          <h3 className={classes.pageTitle}>Report Order</h3>
          <List>
            <Container
              dragHandleSelector=".drag-handle"
              lockAxis="y"
              onDrop={(dropResult) => {
                const { removedIndex, addedIndex } = dropResult;
                UpdateSortOrder(Number(removedIndex), Number(addedIndex));
              }}
            >
              {itemsToSort.map(({ id, text }) => (
                <Draggable key={id}>
                  <ListItem>
                    <ListItemText primary={text} />
                    <ListItemSecondaryAction>
                      <ListItemIcon className="drag-handle">
                        <DragHandleIcon />
                      </ListItemIcon>
                    </ListItemSecondaryAction>
                  </ListItem>
                </Draggable>
              ))}
            </Container>
          </List>
          {
            <WO2Button color={'primary'} disableElevation onClick={() => continueReportProcess()} variant={'contained'} value="Continue">
              Continue
            </WO2Button>
          }
        </div>
      </div>
    </div>
  );
}
