import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchFxDetails } from './thunks';
import { FxCommonState, FxDetails } from './types';

const initialState: FxCommonState = {
  baseCurrencyId: 5,
  quoteCurrencyId: 5,
  fxDetails: {
    baseCurrencyId: 5,
    baseCurrencyCode: 'AUD',
    baseCurrencyName: 'Australia',
    quoteCurrencyId: 5,
    quoteCurrencyCode: 'AUD',
    quoteCurrencyName: 'Australia',
    currencyPair: 'AUD/AUD'
  },
};

export const commonSlice = createSlice({
  name: '@@forexrates/common',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchFxDetails.fulfilled, (state, action: PayloadAction<FxDetails>) => {
      state.fxDetails = action.payload;
    });
  },
});
