import GetAppIcon from '@mui/icons-material/GetApp';
import React, { useEffect, useState } from 'react';
import { FabMenu, FabMenuItem, WO2Snackbar } from 'src/common/components';
import Importer from 'src/common/components/importer';
import { CompositionDetail, FetchModelCompositionCSVPayload, ImportRequestPayload,
    MenuOptions, ModelCompositionMethod } from '../store/types'; // SMA // // , ModelCompositionType } from '../store/types';

export interface Props {
  model: CompositionDetail;
  isImporting: boolean;
  isModelVersionActive: boolean;
  modelUploadSuccess: boolean;
  importErrors: string[];
  importAllocations: (payload: ImportRequestPayload) => void;
  fetchModelCompositionCSV: (arg: FetchModelCompositionCSVPayload) => void;
  onUploadSuccessHandler: () => void;
  isMenuOpen: boolean;
  setMenuState: (isMenuOpen: boolean) => void;
  hasEditModelAccess: boolean;
}

export default function ActionsMenu({
  fetchModelCompositionCSV,
  hasEditModelAccess,
  importAllocations,
  importErrors,
  isImporting,
  isMenuOpen,
  isModelVersionActive,
  model,
  modelUploadSuccess,
  onUploadSuccessHandler,
  setMenuState,
}: Props): JSX.Element {
  const [displaySnackbar, setSnackbarState] = useState<boolean>(false);
  const [displayMenuOption, setMenuOption] = useState<MenuOptions>(MenuOptions.None);

  const onClose = () => {
    setMenuOption(MenuOptions.None);
  };
  const onImport = (file: File) => {
    importAllocations({ file });
  };

  useEffect(() => {
    const hasCompletedUploadSuccessfully = !isImporting && importErrors.length === 0 && modelUploadSuccess === true;

    if (hasCompletedUploadSuccessfully) {
      onClose();
      setSnackbarState(true);
      onUploadSuccessHandler();
    }
  }, [isImporting, importErrors, modelUploadSuccess, setSnackbarState, onUploadSuccessHandler]);

  const checkAuthorisationAndModelType = (): FabMenuItem[] => {
    const menuItems: FabMenuItem[] = [];

    const exportButton = {
      label: 'Export',
      onClick: () => {
        setMenuOption(MenuOptions.Export);
        fetchModelCompositionCSV({ modelVersionId: model.modelVersionId, isModelVersionActive });
        setMenuOption(MenuOptions.None);
      },
      icon: <GetAppIcon style={{ transform: 'rotate(180deg)' }} />,
    };

    menuItems.push(exportButton);

    if ((hasEditModelAccess && isModelVersionActive) === true
        && (model.method === ModelCompositionMethod.Fixed)) { // SMA // // || model.type === ModelCompositionType.SMA)) {
      menuItems.push({
        label: 'Import',
        onClick: () => {
          setMenuOption(MenuOptions.Import);
        },
        icon: <GetAppIcon />,
      });
    }

    return menuItems;
  };

  const menuItems = checkAuthorisationAndModelType();

  if (menuItems.length === 0) {
    return <></>;
  }

  // TODO: 6373 - Replace Snackbar with that in store
  return (
    <>
      <WO2Snackbar
        MUISnackbarProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
          open: displaySnackbar,
          onClose: () => {
            setSnackbarState(false);
          },
          message: 'Updated model weights successfully imported.',
        }}
      />
      <FabMenu
        isMenuOpen={isMenuOpen}
        name=""
        toggleMenu={() => {
          setMenuState(!isMenuOpen);
        }}
        menuItems={menuItems}
      />
      <Importer
        fileType=".csv"
        isOpen={displayMenuOption === MenuOptions.Import}
        isImporting={isImporting}
        errors={importErrors}
        handleClose={onClose}
        handleImport={onImport}
      />
    </>
  );
}
