import Container from '@mui/material/Container';
import { User } from 'oidc-client';
import queryString from 'query-string';
import React, { useEffect } from 'react';
import history from '../../../../../history';
import { userManager } from '../../../../../userManager';
import { Props } from '../container';
import { GroupedTrades } from '../store/types';
import Checkout from './checkout';
import Filters from './filters';
import Trades from './trades';
import ValidationMessages from './validationMessages';

export default function Edit(props: Props): JSX.Element {
  const {
    rebalanceKey,
    rebalance,
    filteredTrades,
    isPreApproved,
    resetIsPreApproved,
    setRebalanceKey,
    fetchRebalance,
    selectedExportTrades,
    fetchInvestmentServiceVersionsByClient,
  } = props;

  const clientId = rebalance.investmentServiceId;

  useEffect(() => {
    document.title = 'DASH | Rebalance';

    const message = 'Warning!\n\nNavigating away from this page will delete your rebalance.';

    window.addEventListener('beforeunload', (e: BeforeUnloadEvent) => {
      e.returnValue = message;

      return message;
    });

    return () => {
      window.removeEventListener('beforeunload', (e: BeforeUnloadEvent) => {
        e.returnValue = message;
        return message;
      });
    };
  }, []);

  useEffect(() => {
    userManager.getUser().then((user: User | null) => {
      if (
        !rebalanceKey.investmentServiceId &&
        !rebalanceKey.rebalanceId &&
        ((user && !user.expired) || process.env.REACT_APP_AUTH_DISABLED === 'true')
      ) {
        const values = queryString.parse(history.location.search);
        if (values.investmentServiceId && values.rebalanceId) {
          setRebalanceKey({
            investmentServiceId: Number(values.investmentServiceId),
            rebalanceId: values.rebalanceId.toString(),
          });
        }
      } else if (rebalanceKey.investmentServiceId && rebalanceKey.rebalanceId && !history.location.search) {
        const location = Object.assign({}, history.location);
        location.search = queryString.stringify({
          rebalanceId: rebalanceKey.rebalanceId,
          investmentServiceId: rebalanceKey.rebalanceId,
        });
        history.push(location);
      }
    });
  }, [rebalanceKey, setRebalanceKey]);

  useEffect(() => {
    if (
      rebalanceKey &&
      rebalanceKey.investmentServiceId &&
      rebalanceKey.rebalanceId &&
      !isPreApproved &&
      !rebalance.rebalanceId
    ) {
      fetchRebalance(rebalanceKey);
    }
  }, [isPreApproved, rebalanceKey, fetchRebalance, rebalance.rebalanceId]);

  useEffect(() => {
    if (isPreApproved) {
      resetIsPreApproved();
    }
  }, [isPreApproved, resetIsPreApproved]);

  useEffect(() => {
    if (clientId) {
      fetchInvestmentServiceVersionsByClient({ clientId: clientId, showLatestVersionOnly: false });
    }
  }, [clientId]);

  return (
    <Container>
      <Filters {...props} selectedExportTrades={selectedExportTrades} />
      <ValidationMessages {...props} />
      {filteredTrades &&
        filteredTrades.map(
          (groupedTrades: GroupedTrades, index: number) =>
            groupedTrades.trades.length > 0 && (
              <Trades
                {...props}
                key={index}
                groupedTrades={groupedTrades}
                validationResults={[
                  ...rebalance.validationResult.errors,
                  ...rebalance.validationResult.warnings,
                  ...rebalance.validationResult.information,
                ]}
              />
            )
        )}

      <Checkout {...props} totalValue={rebalance?.totalPortfolioValue ?? 0} />
    </Container>
  );
}
