import { Route } from '../../store';
import { CommonContainer } from './common/container';
import { SecurityListStateContainer } from './list/container';

export const securityRoutes: Route[] = [
  {
    path: '/security/list',
    component: SecurityListStateContainer,
    protected: true,
    exact: true,
  },
  {
    path: '/security/:securityId/:securityCode',
    component: CommonContainer,
  },
];
