import { Box, Container, Paper } from '@mui/material';
import 'date-fns';
import React, { useEffect } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { LeftMenu } from '.';
import { Props } from '../container';
import { detailsRoutes } from '../detailsRoutes';

export const Details = (props: Props): JSX.Element => {
  const { hasClientEditAdminOnlyPermission, isSuperSimplifierClient, clientId, mainDetails, fetchClientAccount } = props;
  const { path } = useRouteMatch();

  useEffect(() => {
    if (!!clientId) {
      fetchClientAccount(clientId);
    }
  }, [fetchClientAccount, clientId]);

  return (
    <Container style={{ paddingTop: '40px', paddingBottom: '40px', display: 'flex', flexDirection: 'row' }}>
      <Box marginRight={'20px'} width="25%" minWidth="300px">
        <Paper elevation={0}>
          {!!mainDetails && (
            <LeftMenu
              hasClientEditAdminOnlyPermission={hasClientEditAdminOnlyPermission}
              isSuperSimplifierClient={isSuperSimplifierClient}
              accountTypeId={mainDetails.accountTypeId}
              accountSubTypeId={mainDetails.subTypeId}
              trusteeTypeId={mainDetails.trusteeTypeId}
            ></LeftMenu>
          )}
        </Paper>
      </Box>
      <Box marginLeft={'20px'} width={'100%'}>
        <Switch>
          {detailsRoutes.map((r) => (
            <Route key={r.menuIndex} path={`${path}/${r.path}`} component={r.component}></Route>
          ))}
          <Route path="/" component={detailsRoutes[0].component}></Route>
        </Switch>
      </Box>
    </Container>
  );
};
