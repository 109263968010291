import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { selectIsDataFromTriumph } from 'src/store/selectors';
import { progressSelectorFactory } from '../../../common';
import { RootState } from '../../../reducers';
import { selectCommonModelVersionId } from '../common/store/selectors';
import { DashboardComponent } from './components/DashboardComponent';
import {
  selectModelDetail,
  selectModelGroupItems,
  selectParameters,
  selectPerformanceDetails,
} from './store/selectors';
import { modelDashboardSlice } from './store/slice';
import { fetchModelDetail, fetchPerformanceDetails, ModelDashboardActionTypes } from './store/thunks';

const actionTypes = [ModelDashboardActionTypes.FetchModelDetail];

const mapStateToProps = (state: RootState) => ({
  parameters: selectParameters(state),
  modelVersionId: selectCommonModelVersionId(state),
  modelDetail: selectModelDetail(state),
  performanceDetails: selectPerformanceDetails(state),
  modelGroupItems: selectModelGroupItems(state),
  loadingProgress: progressSelectorFactory(actionTypes)(state),
  isDataFromTriumph: selectIsDataFromTriumph(state),
});

const mapDispatchToProps = {
  ...modelDashboardSlice.actions,
  fetchModelDetail,
  fetchPerformanceDetails,
};

interface MatchParams {
  modelId?: string;
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector> & RouteComponentProps<MatchParams>;
export const ModelDashboardStateContainer = connect(mapStateToProps, mapDispatchToProps)(DashboardComponent);
