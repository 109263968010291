import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ResultWrapper } from '../../benefitStatement/store/types';
import { fetchPensionBankDetailByClientId, fetchPensionDetailByAccountId } from './thunks';
import { PensionBankAccountDetails, PensionDetail, PensionDetailStatement } from './types';

export const initialState: PensionDetailStatement = {
  details: {
    responseData: {
      accountId: '',
      frequency: '',
      maximumPension: 0.0,
      minimumPension: 0.0,
      nextPaymentDate: '',
      paymentAmount: 0,
      pensionType: '',
      reviewOption: '',
      totalPeriodicPension: 0,
    },
  },
  bankDetails: {
    responseData: {
      accountName: '',
      accountNo: '',
      bsb: '',
    },
  },
};

export const pensionDetailSlice = createSlice({
  name: 'pensionDetail',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPensionDetailByAccountId.fulfilled, (state, action: PayloadAction<ResultWrapper<PensionDetail | null>>) => {
      state.details = action.payload;
    });
    builder.addCase(fetchPensionBankDetailByClientId.fulfilled, (state, action: PayloadAction<ResultWrapper<PensionBankAccountDetails | null>>) => {
      state.bankDetails = action.payload;
    });
  },
});
