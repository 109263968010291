import { AnyAction } from 'redux';
import { FeatureFlags } from './types';

export enum FeatureFlagsActionTypes {
  FetchFeatureFlagsRequest = '@@common/FetchFeatureFlags/Request',
  FetchFeatureFlagsSuccess = '@@common/FetchFeatureFlags/Success',
  FetchFeatureFlagsFailed = '@@common/FetchFeatureFlags/Failed',
}

export type FeatureFlagsActions = FetchFeatureFlagsRequestAction | FetchFeatureFlagsSuccessAction; // | FetchFeatureFlagsFailedAction;

// Fetch Feature Flags
export interface FetchFeatureFlagsRequestAction extends AnyAction {
  type: typeof FeatureFlagsActionTypes.FetchFeatureFlagsRequest;
}

export interface FetchFeatureFlagsSuccessAction extends AnyAction {
  type: typeof FeatureFlagsActionTypes.FetchFeatureFlagsSuccess;
  payload: FeatureFlags;
}

// export interface FetchFeatureFlagsFailedAction extends AnyAction {
//   type: typeof FeatureFlagsActionTypes.FetchFeatureFlagsFailed;
//   payload: any;
// }
