import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchStrategyAssociations } from './thunks';
import { Associations, StrategyAssociations } from './types';

export const initialState: StrategyAssociations = {
  associations: null,
};

export const strategyAssociationsSlice = createSlice({
  name: '@@strategy/associations',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchStrategyAssociations.fulfilled, (state, action: PayloadAction<Associations>) => {
      state.associations = action.payload;
    });
  },
});
