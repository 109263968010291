import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'src/app/api';
import { ResultWrapper } from '../../benefitStatement/store/types';
import { ContributionDetails, FetchContributionDetailsRequest } from './types';

export enum ContributionDetailsActionTypes {
  FetchContributionDetails = '@@client/details/superSimplifier/contributions/FetchContributionDetail',
}

export enum ContributionDetailsApiEndpoints {
  FetchContributionDetails = 'ssc/GetContributionDetails',
}

export const fetchContributionDetails = createAsyncThunk(
  ContributionDetailsActionTypes.FetchContributionDetails,
  async (request: FetchContributionDetailsRequest) => {
    const queryString = `?accountId=${request.accountId}&financialYear=${request.financialYear}`;
    const result = await api
      .get<ContributionDetails>(`${ContributionDetailsApiEndpoints.FetchContributionDetails}${queryString}`)
      .then((data) => {
        const response: ResultWrapper<ContributionDetails> = {
          responseData: data.data,
          status: data.status,
          statusText: data.statusText,
        };
        return response;
      })
      .catch((error) => {
        const response: ResultWrapper<null> = {
          responseData: error.data,
          status: error.status,
          statusText: error.statusText,
        };
        return response;
      });
    return result;
  }
);
