import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { MenuItem } from '@mui/material';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { LoadingProgress } from 'src/common/store/types';
import { ClientPortfolioSelectModel } from '../store/types';
import { WO2Select } from './../../../../../common';

interface Props {
  loadingProgress: LoadingProgress;
  selectedPortfolio?: ClientPortfolioSelectModel | null;
  clientPortfolios?: ClientPortfolioSelectModel[];
  setSelectedPortfolio: (portfolio: ClientPortfolioSelectModel) => void;
}

export const PortfolioSelectorComponent = (props: Props): JSX.Element => {
  const portfolios = props.clientPortfolios || [];
  const { setSelectedPortfolio, clientPortfolios, selectedPortfolio } = props;

  useEffect(() => {
    if ((!selectedPortfolio || selectedPortfolio.accountIdList.length === 0) && clientPortfolios) {
      setSelectedPortfolio(clientPortfolios[0]);
    }
  }, [selectedPortfolio, clientPortfolios, setSelectedPortfolio]);

  const setPortfolio = (id: number | null) => {
    const portfolio = _.find(portfolios, { investmentProgramId: id }) ?? _.find(portfolios, { investmentProgramId: null });
    if (portfolio) {
      props.setSelectedPortfolio(portfolio);
    }
  };

  return (
    <div>
      <WO2Select
        style={{ position: 'absolute', width: '300px' }}
        className={'SemiTransparent'}
        value={portfolios && portfolios.length > 0 ? selectedPortfolio?.investmentProgramId ?? '' : ''}
        displayEmpty
        IconComponent={ExpandMoreIcon}
        onChange={(event) => (event.target.value === '' ? setPortfolio(null) : setPortfolio(Number(event.target.value)))}
      >
        {portfolios.length > 0 &&
          portfolios.map((item, i) => (
            <MenuItem key={i} value={item.investmentProgramId ?? ''}>
              {item.investmentServiceName}
            </MenuItem>
          ))}
      </WO2Select>
    </div>
  );
};
