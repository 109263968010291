import { DateRange } from '../../../../store';

export enum Frequency {
  Daily = 'Daily',
  Monthly = 'Monthly',
}

export interface PrimaryBenchmark {
  id: number;
  code: string;
  name: string;
}

export interface SecondaryBenchmark {
  id: number;
  code: string;
  name: string;
}

export interface Afsl {
  id: number;
  name: string;
}

export interface SeriesData {
  date: string;
  return: number;
  cumulativeReturn: number;
}

export interface DailyPerformanceChart {
  strategyId: number;
  benchmarkStrategyId: number;
  capitalReturn: number;
  incomeReturn: number;
  totalReturn: number;
  bestDayReturn: number;
  bestDayDate: Date;
  worstDayReturn: number;
  worstDayDate: Date;
  annualVolatility: number;
  seriesData: SeriesData[];
  benchmarkSeriesData: SeriesData[];
}

export interface MonthlyPerformanceChart {
  strategyId: number;
  benchmarkStrategyId: number;
  capitalReturn: number;
  incomeReturn: number;
  totalReturn: number;
  bestMonthReturn: number;
  bestMonthDate: Date;
  worstMonthReturn: number;
  worstMonthDate: Date;
  annualVolatility: number;
  seriesData: SeriesData[];
  benchmarkSeriesData: SeriesData[];
}

export interface PeriodicSummarySeriesData {
  date: Date;
  label: string;
  incomeReturn: number;
  capitalReturn: number;
  totalReturn: number;
  benchmarkIncomeReturn: number;
  benchmarkCapitalReturn: number;
  benchmarkTotalReturn: number;
  strategyVsBenchmarkIncomeReturn: number;
  strategyVsBenchmarkCapitalReturn: number;
  strategyVsBenchmarkTotalReturn: number;
}

export interface PeriodicSummary {
  strategyId: number;
  benchmarkStrategyId: number;
  seriesData: PeriodicSummarySeriesData[];
}

export interface StrategyVersionCompositionItem {
  strategyVersionId: number;
  securityId: number;
  securityCode: string;
  securityName: string;
  securityType: string;
  allocation: number;
  isSecurityList: boolean;
  securityListId: number;
}

export interface StrategyDashboard {
  strategyId: number;
  strategyName: string;
  assetClass: string;
  strategyVersionId: number;
  fua: number;
  portfolioCount: number;
  oneMonthReturn: number;
  inceptionReturn: number;
  strategyVersionCompositionItems: StrategyVersionCompositionItem[];
}

export interface StrategyDetails {
  strategyId: number;
  strategyVersionId: number;
  name: string;
  code: string;
  type: string;
  inceptionDate: Date;
  analyticsStartDate: Date;
  assetClass: string;
  description: string;
  isSuperSimplifier: boolean;
  targetReturnRate: number | null;
  fua: number;
  portfolioCount: number;
  accessAfsls: Afsl[];
  ownerAfsls: Afsl[];
  primaryBenchmark: PrimaryBenchmark;
  secondaryBenchmark: SecondaryBenchmark;
}

export interface StrategyBenchmark {
  id: number;
  code: string;
  name: string;
}

export interface StrategyCompositionParameters {
  dates: DateRange;
  frequency: Frequency;
  strategyBenchmarks: StrategyBenchmark[] | null;
  strategyBenchmarkSelected: StrategyBenchmark;
}

export interface StrategyPerformanceState {
  parameters: StrategyCompositionParameters;
  strategyDashboard: StrategyDashboard | null;
  strategyDetails: StrategyDetails | null;
  dailyPerformanceChart: DailyPerformanceChart | null;
  monthlyPerformanceChart: MonthlyPerformanceChart | null;
  periodicSummary: PeriodicSummary | null;
}

export interface FetchStrategyDashboardPayload {
  strategyId: number;
}

export interface FetchStrategyDetailsPayload {
  strategyId: number;
}

export interface FetchDailyPerformanceChartPayload {
  strategyId: number;
  fromDate: string;
  toDate: string;
}

export interface FetchMonthlyPerformanceChartPayload {
  strategyId: number;
  fromDate: string;
  toDate: string;
}

export interface FetchPeriodicSummaryPayload {
  strategyId: number;
  benchmarkStrategyId: number;
  endDate: string;
}
