import { Autocomplete, Backdrop, Box, Fade, Grid, Modal, Paper, TextField, Typography } from '@mui/material';
import { Form, Formik, FormikProps } from 'formik';
import React, { useEffect, useState } from 'react';
import { EditCancelSaveButtons, FormikErrorMessage, Mode } from 'src/common/components/formik';
import { LoadingProgress } from 'src/common/store/types';
import {
  BeneficialOwnerRoleId,
  BeneficiaryRoleId,
  DirectorCompanyOfficerRoleId,
  Role,
  RolePercentageModel,
} from 'src/features/clients/client/common/store/types';
import { AttachableContact } from 'src/features/clients/common/types';
import * as yup from 'yup';
import { RoleSelector } from '../edit/roleSelector';

interface AttachContactFormValues {
  contactId: number | null;
  roleIds: number[];
  isPrimary: boolean;

  beneficialOwnerPercentage: number | null;
  beneficiaryPercentage: number | null;
  beneficiaryRelationship: string;
  directorCompanyOfficerPercentage: number | null;
}

export interface AttachContactDialogPayload {
  contactId: number;
  roles: RolePercentageModel[];
  isPrimary: boolean;
}

export interface AttachContactProps {
  contacts: AttachableContact[];
  contactsProgress: LoadingProgress;
  roles: Role[];
  loadingRoles: LoadingProgress;
  isOpen: boolean;
  showRoles?: boolean;
  hideIsPrimarySwitch?: boolean;
  onSave: (payload: AttachContactDialogPayload) => void;
  handleCloseModal: () => void;
  fetchContactsToAttach: (searchText: string) => void;
  fetchRoles: () => void;
}

export const AttachContact = ({
  contacts,
  roles,
  isOpen,
  showRoles = true,
  onSave,
  handleCloseModal,
  contactsProgress,
  loadingRoles,
  fetchContactsToAttach,
  fetchRoles,
  hideIsPrimarySwitch,
}: AttachContactProps): JSX.Element => {
  const formValues: AttachContactFormValues = {
    contactId: null,
    roleIds: [],
    beneficialOwnerPercentage: null,
    beneficiaryPercentage: null,
    beneficiaryRelationship: '',
    directorCompanyOfficerPercentage: null,
    isPrimary: false,
  };

  const [hasFetched, setHasFetched] = useState<boolean>(false);

  useEffect(() => {
    fetchRoles();
  }, [fetchRoles]);

  const getName = (contact: AttachableContact) => `${contact.firstName} ${contact.lastName}`.trimStart();

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={isOpen}
      onClose={handleCloseModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
      data-testid="attachContactModal"
    >
      <Fade in={isOpen}>
        <Paper
          elevation={0}
          style={{
            width: '800px',
            minHeight: '400px',
            maxHeight: '600px',
            padding: '40px 40px',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <Formik<AttachContactFormValues>
            initialValues={formValues}
            enableReinitialize={true}
            onSubmit={async (details: AttachContactFormValues) => {
              if (!!details.contactId) {
                const payload: AttachContactDialogPayload = {
                  contactId: details.contactId,
                  isPrimary: details.isPrimary,
                  roles: details.roleIds.map((id) => {
                    let percent: number | null;
                    let relationship: string | null = null;

                    switch (id) {
                      case BeneficialOwnerRoleId:
                        percent = details.beneficialOwnerPercentage;
                        break;
                      case BeneficiaryRoleId:
                        percent = details.beneficiaryPercentage;
                        relationship = details.beneficiaryRelationship;
                        break;
                      case DirectorCompanyOfficerRoleId:
                        percent = details.directorCompanyOfficerPercentage;
                        break;
                      default:
                        percent = null;
                    }
                    return {
                      roleId: id,
                      percent,
                      relationship,
                    };
                  }),
                };
                onSave(payload);
              }
            }}
            validationSchema={yup.object({
              contactId: yup.number().required(),
              roleIds: showRoles ? yup.array().of(yup.number()).required('At least one role is required') : yup.array().of(yup.number()),

              isPrimary: yup.boolean(),
              beneficialOwnerPercentage: yup
                .number()
                .nullable()
                .when('roleIds', {
                  is: (roleIds: number[]) => roleIds.find((r) => r === BeneficialOwnerRoleId),
                  then: yup.number().max(100, 'Percentage cannot exceed 100%').required('Required'),
                }),
              beneficiaryPercentage: yup
                .number()
                .nullable()
                .when('roleIds', {
                  is: (roleIds: number[]) => roleIds.find((r) => r === BeneficiaryRoleId),
                  then: yup.number().max(100, 'Percentage cannot exceed 100%').required('Required'),
                }),
              directorCompanyOfficerPercentage: yup
                .number()
                .nullable()
                .when('roleIds', {
                  is: (roleIds: number[]) => roleIds.find((r) => r === DirectorCompanyOfficerRoleId),
                  then: yup.number().max(100, 'Percentage cannot exceed 100%').required('Required'),
                }),
            })}
          >
            {(formikProps: FormikProps<AttachContactFormValues>) => (
              <Form>
                <fieldset style={{ border: 'none', padding: '0' }}>
                  <Typography variant="h3" style={{ padding: '20px 0 40px 0' }}>
                    Attach Contact
                  </Typography>
                  <Grid container>
                    <Grid item xs={12} style={{ paddingRight: '20px' }}>
                      <Grid item xs={12} style={{ paddingBottom: '20px' }}>
                        <Autocomplete<AttachableContact>
                          data-testid="contactAutocomplete"
                          loading={contactsProgress.isLoading}
                          noOptionsText={!hasFetched ? 'Start typing contact name...' : 'No contacts returned'}
                          fullWidth
                          options={contacts}
                          isOptionEqualToValue={(option, value) => option.contactId === value.contactId}
                          getOptionLabel={(contact: AttachableContact) => getName(contact)}
                          onChange={(e: React.SyntheticEvent<Element, Event>, contact: AttachableContact | null) => {
                            formikProps.setFieldValue('contactId', contact?.contactId || -1);
                          }}
                          onInputChange={(e, newInputValue) => {
                            fetchContactsToAttach(newInputValue);
                            setHasFetched(true);
                          }}
                          renderInput={(params) => <TextField {...params} label="CONTACT" margin="normal" variant="outlined" fullWidth />}
                        />
                        <FormikErrorMessage name="contactId"></FormikErrorMessage>
                      </Grid>
                      {showRoles && (
                        <>
                          <Grid item xs={12} style={{ paddingBottom: '20px' }}>
                            <RoleSelector
                              label="ROLES"
                              roles={roles}
                              rolesLoadingProgress={loadingRoles}
                              contactId={undefined}
                              primaryContactId={undefined}
                              roleIds={{ inputProps: formikProps.getFieldProps('roleIds'), helperProps: formikProps.getFieldHelpers('roleIds') }}
                              beneficialOwnerPercentage={{ inputProps: formikProps.getFieldProps('beneficialOwnerPercentage') }}
                              beneficiaryPercentage={{ inputProps: formikProps.getFieldProps('beneficiaryPercentage') }}
                              directorCompanyOfficerPercentage={{ inputProps: formikProps.getFieldProps('directorCompanyOfficerPercentage') }}
                              isPrimary={{ inputProps: formikProps.getFieldProps('isPrimary') }}
                              beneficiaryRelationship={{ inputProps: formikProps.getFieldProps('beneficiaryRelationship') }}
                              requiresAuthorisedSignatory={false}
                              hideIsPrimarySwitch={hideIsPrimarySwitch}
                            ></RoleSelector>
                          </Grid>
                        </>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <Box display={'flex'} justifyContent={'flex-end'} paddingTop={'10px'} paddingLeft={'10px'}>
                        <EditCancelSaveButtons mode={Mode.CancelSave} handleCancelClick={handleCloseModal}></EditCancelSaveButtons>
                      </Box>
                    </Grid>
                  </Grid>
                </fieldset>
              </Form>
            )}
          </Formik>
        </Paper>
      </Fade>
    </Modal>
  );
};
