import { Grid, Paper, Typography } from '@mui/material';
import { Field, Formik, FormikProps } from 'formik';
import { DateTime } from 'luxon';
import React, { useRef } from 'react';
import { FormikKeyboardDatePicker } from 'src/common/components/formik';
import { DateRange } from '../../../../../../../../../client/src/store/types';
import { FetchContributionDetailsRequest } from '../store/types';
import { DateTimeFormat } from 'src/common/utils';

export interface Props {
  minMaxDateRange: DateRange;
  financialYearDate: string;
  setDate(stringDate: string): void;
}

export const ContributionDetailsDatePicker = (props: Props): JSX.Element => {
  const formRef = useRef<FormikProps<FetchContributionDetailsRequest>>(null);

  const request: FetchContributionDetailsRequest = {
    accountId: '',
    financialYear: props.financialYearDate,
  };

  const generateDateFromString = (dateString: string): DateTime => {
    const [year, month, day] = dateString.split('-');
    const calculatedMonth = +month;
    return DateTime.local(+year, calculatedMonth, +day, 0, 0, 0, 0);
  };

  return (
    <>
      <Paper elevation={5} style={{ marginBottom: '30px' }}>
        <Typography variant="h4" style={{ padding: '20px' }}>
          <b>Financial Year Ending</b>
        </Typography>

        <div style={{ padding: '20px' }}>
          <Formik<FetchContributionDetailsRequest>
            data-testid="fetchContributionDetailsForm"
            enableReinitialize={true}
            initialValues={request}
            innerRef={formRef}
            onSubmit={() => {
              /* VOID */
            }}
          >
            <Grid data-testid="financialYearGrid" item xs={12} style={{ marginBottom: '16px', minHeight: '50px' }}>
              <Field
                data-testid="financialYearInput"
                component={FormikKeyboardDatePicker}
                name="financialYear"
                label="Financial Year"
                onChange={(value: string) => {
                  props.setDate(value);
                }}
                style={{ width: '120px' }}
                views={['year']}
                inputFormat={DateTimeFormat.Year.displayName}
                maxDate={generateDateFromString(props.minMaxDateRange.dateTo)}
                minDate={generateDateFromString(props.minMaxDateRange.dateFrom)}
              />
            </Grid>
          </Formik>
        </div>
      </Paper>
    </>
  );
};
