import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { ReactElement, useState } from 'react';
import { ProgressButton } from 'src/common/components/progressButton';
import { PageBackNextButtonsProps } from '../../store/types';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    paddingTop: '30px',
    height: '66px',
    '& button': {
      minWidth: '160px',
      height: '36px',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
      },
    },
  },
}));

export function PageBackNextButtons<T>(props: PageBackNextButtonsProps<T>): ReactElement {
  const {
    onBackButtonClick,
    onQuitButtonClick,
    onNextButtonClick,
    formikProps,
    nextButtonText,
    backButtonText,
    progress,
  } = props;

  const classes = useStyles();
  const [clickedButton, setClickedButton] = useState<'next' | 'back' | 'quit' | undefined>(undefined);

  const handleBackButtonClick = (): void => {
    setClickedButton('back');
    if (!!formikProps && Object.keys(formikProps.errors).length > 0) {
      // output validation error to console
    }

    if (onBackButtonClick) {
      onBackButtonClick();
    }
  };

  const handleNextButtonClick = () => {
    setClickedButton('next');
    formikProps?.validateForm();
    if (!!formikProps && Object.keys(formikProps.errors).length > 0) {
      // output validation error to console
    }

    if (onNextButtonClick) {
      onNextButtonClick();
    }
  };

  const handleQuitButtonClick = (): void => {
    setClickedButton('quit');
    if (!!formikProps && Object.keys(formikProps.errors).length > 0) {
      // output validation error to console
    }

    if (onQuitButtonClick) {
      onQuitButtonClick();
    }
  };

  return (
    <div className={classes.container}>
      {!!onQuitButtonClick && (
        <ProgressButton
          data-testid="quitButton"
          type={'button'}
          color={'primary'}
          variant={'outlined'}
          onClick={handleQuitButtonClick}
          progress={clickedButton === 'quit' ? progress : { isLoading: false, hasErrors: false }}
        >
          Save and Exit
        </ProgressButton>
      )}
      <div style={{ marginLeft: 'auto' }}></div>
      {!!onBackButtonClick && (
        <ProgressButton
          hideicon={true}
          data-testid="backButton"
          type="button"
          color={'primary'}
          variant={'outlined'}
          onClick={() => handleBackButtonClick()}
          value="Back"
          progress={clickedButton === 'back' ? progress : { isLoading: false, hasErrors: false }}
        >
          {backButtonText || 'Back'}
        </ProgressButton>
      )}
      {!!onNextButtonClick && (
        <ProgressButton
          data-testid="nextButton"
          style={{ marginLeft: '20px', marginRight: '0px' }}
          type="button"
          color={'primary'}
          variant={'contained'}
          onClick={() => handleNextButtonClick()}
          value={'Next'}
          progress={clickedButton === 'next' ? progress : { isLoading: false, hasErrors: false }}
          hideicon={!nextButtonText}
        >
          {nextButtonText || 'Next'}
        </ProgressButton>
      )}
    </div>
  );
}
