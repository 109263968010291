import React, { useEffect, useState } from 'react';
import { LoadingIndicator } from 'src/common/components/LoadingIndicator';
import { useStyles } from './themes/light/loadingStyles';

export function Loading(): JSX.Element {
  const classes = useStyles();
  const [hasErrors, setHasErrors] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    document.title = 'DASH | Loading Rebalance';
  }, []);

  setTimeout(() => {
    setHasErrors(true);
    setIsLoading(false);
  }, 120000);

  return (
    <LoadingIndicator progress={{ isLoading, hasErrors }}>
      <div className={classes.body}>
        {hasErrors && (
          <div className={classes.error}>
            Unable to load rebalance, please close this tab and try again from MDA Operator.
          </div>
        )}
      </div>
    </LoadingIndicator>
  );
}
