import { Autocomplete, Chip, Grid, TextField, Typography } from '@mui/material';
import { Field, FieldHelperProps, FieldInputProps, FieldProps } from 'formik';
import React from 'react';
import { FormikErrorMessage, FormikNumberFormat, FormikSwitch, FormikTextField } from '../../../../../../../common/components/formik';
import { LoadingProgress } from '../../../../../../../common/store/types';
import { ContactRole } from '../../../../common/store/enums';
import { AuthorisedSignatoryRoleId, BeneficialOwnerRoleId, BeneficiaryRoleId, DirectorCompanyOfficerRoleId, Role } from '../../../store/types';

export interface Props {
  label?: string;
  roles: Role[];
  rolesLoadingProgress: LoadingProgress;
  contactId?: number;
  primaryContactId?: number;

  roleIds: { inputProps: FieldInputProps<number[]>; helperProps: FieldHelperProps<number[]> };

  beneficialOwnerPercentage: { inputProps: FieldInputProps<number> };
  beneficiaryPercentage: { inputProps: FieldInputProps<number> };
  beneficiaryRelationship: { inputProps: FieldInputProps<string> };
  directorCompanyOfficerPercentage: { inputProps: FieldInputProps<number> };
  isPrimary: { inputProps: FieldInputProps<boolean> };
  hideIsPrimarySwitch?: boolean;

  requiresAuthorisedSignatory: boolean;
}

export const RoleSelector = ({
  roles,
  rolesLoadingProgress,
  contactId,
  primaryContactId,
  roleIds,
  beneficialOwnerPercentage,
  beneficiaryPercentage,
  directorCompanyOfficerPercentage,
  isPrimary,
  beneficiaryRelationship,
  hideIsPrimarySwitch,
  label,
  requiresAuthorisedSignatory,
}: Props): JSX.Element => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Autocomplete<Role, true, true>
          id={'rolesAutocomplete'}
          multiple
          disableClearable
          value={(roles || []).filter((r) => {
            return !!(roleIds.inputProps.value || []).find((cr) => cr === r.id);
          })}
          loading={rolesLoadingProgress.isLoading}
          noOptionsText="No roles to select"
          fullWidth
          options={(roles || []).filter((r) => r.id !== ContactRole.Adviser)}
          getOptionLabel={(role: Role) => role.name}
          onChange={(e: React.SyntheticEvent<Element, Event>, updatedRoles) => {
            roleIds.helperProps.setValue((updatedRoles as Role[]).map((r) => r.id));
          }}
          renderInput={(params) => <TextField {...params} label={label} margin="normal" variant="outlined" fullWidth />}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <Chip
                label={option.name}
                {...getTagProps({ index })}
                key={index}
                disabled={option.id === ContactRole.Adviser || (requiresAuthorisedSignatory && option.id === ContactRole.AuthorisedSignatory)}
              />
            ))
          }
        />
        <FormikErrorMessage name={roleIds.inputProps.name}></FormikErrorMessage>
      </Grid>
      {!!(roleIds || []).inputProps.value.find((r) => r === BeneficialOwnerRoleId) && (
        <Grid item xs={12} style={{ paddingBottom: '10px' }}>
          <Field name={beneficialOwnerPercentage.inputProps.name} fullWidth>
            {(fieldProps: FieldProps) => {
              return (
                <FormikNumberFormat
                  dataTestid="beneficialOwnerPercentage"
                  formikFieldProps={fieldProps}
                  numberFormatProps={{
                    placeholder: '%',
                    isNumericString: true,
                    allowNegative: false,
                    decimalScale: 2,
                    thousandSeparator: true,
                    suffix: '%',
                    name: fieldProps.field.name,
                    label: 'BENEFICIAL OWNER %',
                  }}
                  isFloatValue={true}
                  fullWidth={true}
                />
              );
            }}
          </Field>
        </Grid>
      )}
      {!!roleIds.inputProps.value.find((r) => r === BeneficiaryRoleId) && (
        <>
          <Grid item xs={12} style={{ paddingBottom: '10px' }}>
            <Field name={beneficiaryPercentage.inputProps.name} fullWidth>
              {(fieldProps: FieldProps) => {
                return (
                  <FormikNumberFormat
                    dataTestid="beneficiaryPercentage"
                    formikFieldProps={fieldProps}
                    numberFormatProps={{
                      placeholder: '%',
                      isNumericString: true,
                      allowNegative: false,
                      decimalScale: 2,
                      thousandSeparator: true,
                      suffix: '%',
                      name: fieldProps.field.name,
                      label: 'BENEFICIARY %',
                    }}
                    isFloatValue={true}
                    fullWidth={true}
                  />
                );
              }}
            </Field>
          </Grid>
          <Grid item xs={12} style={{ paddingBottom: '10px' }}>
            <Field component={FormikTextField} name={beneficiaryRelationship.inputProps.name} label="RELATIONSHIP" fullWidth />
          </Grid>
        </>
      )}
      {!!roleIds.inputProps.value.find((r) => r === DirectorCompanyOfficerRoleId) && (
        <Grid item xs={12} style={{ paddingBottom: '10px' }}>
          <Field name={directorCompanyOfficerPercentage.inputProps.name} fullWidth>
            {(fieldProps: FieldProps) => {
              return (
                <FormikNumberFormat
                  dataTestid="directorCompanyOfficerPercentage"
                  formikFieldProps={fieldProps}
                  numberFormatProps={{
                    placeholder: '%',
                    isNumericString: true,
                    allowNegative: false,
                    decimalScale: 2,
                    thousandSeparator: true,
                    suffix: '%',
                    name: fieldProps.field.name,
                    label: 'DIRECTOR/COMPANY OFFICER %',
                  }}
                  isFloatValue={true}
                  fullWidth={true}
                />
              );
            }}
          </Field>
        </Grid>
      )}

      {!hideIsPrimarySwitch && !!roleIds.inputProps.value.find((r) => r === AuthorisedSignatoryRoleId) && (
        <Grid item xs={12} style={{ paddingBottom: '10px' }}>
          <Field
            name={isPrimary.inputProps.name}
            component={FormikSwitch}
            disabled={!!primaryContactId && primaryContactId === contactId}
            label="IS PRIMARY CONTACT"
            id="isPrimary"
          ></Field>
          {!!primaryContactId && primaryContactId === contactId && isPrimary.inputProps.value && (
            <Typography id="alreadyPrimaryMessage">A new primary contact can be selected from within another authorised signatory</Typography>
          )}
          {!!primaryContactId && primaryContactId !== contactId && isPrimary.inputProps.value && (
            <Typography id="settingPrimaryMessage">
              This contact will be set as the primary contact and the existing primary contact will be removed from that role
            </Typography>
          )}
        </Grid>
      )}
    </Grid>
  );
};
