import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../../app/api';
import { FetchStrategyCommonPayload, StrategyDashboard } from './types';

export enum StrategyCommonActionTypes {
  FetchStrategyDashboard = '@@strategy/common/FetchStrategyDashboard',
}

export enum StrategyCommonApiEndpoints {
  fetchStrategyDashboard = '/bff/GetStrategyDashboard',
}

export const fetchStrategyDashboard = createAsyncThunk(StrategyCommonActionTypes.FetchStrategyDashboard, async (wrapper: FetchStrategyCommonPayload) => {
  const response = await api.get<StrategyDashboard>(`${StrategyCommonApiEndpoints.fetchStrategyDashboard}?strategyId=${wrapper.strategyId}`);

  return response.data;
});
