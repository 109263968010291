import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../../app/api';
import { fetchModelAssociationsPayload, ModelAssociations } from './types';

export enum ModelAssociationsActionTypes {
  FetchModelAssociations = '@@model/details/FetchModelDetails',
}

export enum ModelAssociationsApiEndpoints {
  fetchModelAssociations = '/bff/GetModelExposure',
}

export const fetchModelAssociations = createAsyncThunk(
  ModelAssociationsActionTypes.FetchModelAssociations,
  async (wrapper: fetchModelAssociationsPayload) => {
    const response = await api.get<ModelAssociations>(
      `${ModelAssociationsApiEndpoints.fetchModelAssociations}?modelVersionId=${wrapper.modelVersionId}&isDataFromTriumph=${wrapper.isDataFromTriumph}`
    );
    return response.data;
  }
);
