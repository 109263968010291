import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { superSimplifierRoutes } from '../superSimplifierRoutes';
import { SuperSimplifierSummaryContainer } from '../superSimplifierSummary/container';

export const SuperSimplifier = (): JSX.Element => {
  const { path } = useRouteMatch();

  return (
    <>
      <Switch>
        {superSimplifierRoutes?.map((r) => (
          <Route key={r.menuIndex} path={`${path}/${r.path}`} component={r.component}></Route>
        ))}
        <Route key={'defaultSuperSimplifierRoute'} path={`${path}`} component={SuperSimplifierSummaryContainer}></Route>
      </Switch>
    </>
  );
};
