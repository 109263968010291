import SearchIcon from '@mui/icons-material/Search';
import { Autocomplete as MuiAutocomplete, InputAdornment, TextField } from '@mui/material';
import { default as React } from 'react';

interface Props<T> {
  label?: string;
  value: T | null;
  options: T[];
  loading: boolean;
  optionLabel: (item: T) => string;
  onOptionChange: (value: T | null) => void;
  onSearchChange?: (value: string) => void;
}

export function Autocomplete<T>(props: Props<T>): JSX.Element {
  const { label, value, options, loading, optionLabel, onOptionChange, onSearchChange, ...rest } = props;

  return (
    <MuiAutocomplete
      {...rest}
      loading={loading}
      options={options ?? []}
      value={value}
      getOptionLabel={optionLabel}
      onChange={(event: unknown, value: T | null) => onOptionChange(value)}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          margin={label ? 'normal' : 'none'}
          variant="outlined"
          fullWidth
          onChange={(event) => {
            onSearchChange && onSearchChange(event.target.value);
          }}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon color="primary" fontSize="small" />
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
}
