import { Route } from '../../store';
import { CommonContainer } from './common/container';
import { ModelListStateContainer } from './list/container';

export const modelRoutes: Route[] = [
  {
    path: '/model/list',
    component: ModelListStateContainer,
    protected: true,
    exact: true,
  },
  {
    path: '/model/:modelId/:modelVersionId',
    component: CommonContainer,
  },
];
