import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { MUIDataTableOptions } from 'mui-datatables';
import React from 'react';
import { formatDollars, formatNumberCommaSeparated, formatPercentage } from '../../../common';
import { DatatableColumn, FilterDataType } from '../../../common/components/dataTable/types';
import { LoadingProgress } from '../../../common/store/types';
import history from '../../../history';
import { theme } from '../../../themes';
import { ClientSideDataTable } from './../../../common/components/dataTable/clientSide/clientSideDataTable';
import { HoldingItem } from './../store/types';

export interface Props {
  progress: LoadingProgress;
  data: HoldingItem[];
  assetClassTotal: string;
  assetClassTitle: string | null;
  assetClassColor: string;
  assetClassPercentage: string;
}

export const useStyles = makeStyles(() => ({
  datatable: {
    '& .MuiTable-root': {
      marginTop: '0 !important',
    },

    '& .MuiToolbar-root': {
      '& > div:first-of-type': {
        display: 'none',
      },
    },
  },
}));

const handleRowClickCode = (securityId: number, securityCode: string) => {
  history.push(`/security/${securityId}/${securityCode}/dashboard`);
};

const handleRowClickClients = (securityId: number, securityCode: string) => {
  history.push(`/security/${securityId}/${securityCode}/associatedclients`);
};

export function HoldingsTable({ progress, data, assetClassPercentage, assetClassTotal, assetClassTitle, assetClassColor }: Props): JSX.Element {
  const classes = useStyles();
  const customTableHeaderOptions = {
    sort: true,
  };
  const columns: DatatableColumn[] = [
    {
      filterDataType: FilterDataType.string,
      label: 'ASSET',
      name: 'securityCode',
      options: {
        ...customTableHeaderOptions,
        customBodyRenderLite: function customBodyRenderLite(dataIndex: number): React.ReactNode {
          return (
            <>
              <Typography
                variant={'h5'}
                color={'primary'}
                onClick={() => {
                  handleRowClickCode(data[dataIndex].securityId, data[dataIndex]?.securityCode || '');
                }}
                style={{
                  color: theme.palette.primary.main,
                  fontWeight: 'bold',
                  letterSpacing: '1px',
                  cursor: 'pointer',
                }}
              >
                {data[dataIndex]?.securityCode}
              </Typography>
              <Typography color={'textSecondary'} variant={'h6'}>
                {data[dataIndex]?.securityName}
              </Typography>
            </>
          );
        },
        setCellHeaderProps: () => ({
          style: {
            maxWidth: '100%',
            textAlign: 'right',
          },
        }),
      },
    },
    {
      filterDataType: FilterDataType.string,
      name: 'units',
      label: 'UNITS / PRICE',
      textAlign: 'right',
      options: {
        ...customTableHeaderOptions,
        customBodyRenderLite: function customBodyRenderLite(dataIndex: number): React.ReactNode {
          return (
            <>
              <Typography variant={'h5'} align="right">
                {formatNumberCommaSeparated(data[dataIndex]?.units)}
              </Typography>
              <Typography color={'textSecondary'} variant={'h6'} align="right">
                {formatDollars(data[dataIndex]?.securityPrice)}
              </Typography>
            </>
          );
        },
        customHeadLabelRender: function customHeadLabelRender(): React.ReactNode {
          return (
            <Typography
              variant="h6"
              align="right"
              style={{
                textAlign: 'right',
                textTransform: 'uppercase',
                color: '#7d7d7d',
                fontSize: '11px',
              }}
            >
              UNITS / PRICE
            </Typography>
          );
        },
      },
    },
    {
      filterDataType: FilterDataType.string,
      textAlign: 'right',
      name: 'weight',
      label: 'WEIGHT',
      options: {
        ...customTableHeaderOptions,
        customBodyRenderLite: (dataIndex: number) => formatPercentage(data[dataIndex]?.weight),
        customHeadLabelRender: function customHeadLabelRender(): React.ReactNode {
          return (
            <Typography
              variant="h6"
              align="right"
              style={{
                textAlign: 'right',
                textTransform: 'uppercase',
                color: '#7d7d7d',
                fontSize: '11px',
              }}
            >
              WEIGHT
            </Typography>
          );
        },
      },
    },
    {
      filterDataType: FilterDataType.string,
      textAlign: 'right',
      name: 'value',
      label: 'VALUE',
      options: {
        ...customTableHeaderOptions,
        customBodyRenderLite: (dataIndex: number) => formatDollars(data[dataIndex]?.value),
        customHeadLabelRender: function customHeadLabelRender(): React.ReactNode {
          return (
            <Typography
              variant="h6"
              align="right"
              style={{
                textAlign: 'right',
                textTransform: 'uppercase',
                color: '#7d7d7d',
                fontSize: '11px',
              }}
            >
              VALUE
            </Typography>
          );
        },
      },
    },
    {
      filterDataType: FilterDataType.numeric,
      textAlign: 'right',
      name: 'numberOfClients',
      label: 'CLIENTS HELD',
      options: {
        ...customTableHeaderOptions,
        customBodyRenderLite: function customBodyRenderLite(dataIndex: number): React.ReactNode {
          return (
            <>
              <Typography
                variant={'h5'}
                color={'primary'}
                onClick={() => {
                  handleRowClickClients(data[dataIndex].securityId, data[dataIndex]?.securityCode || '');
                }}
                style={{
                  color: theme.palette.primary.main,
                  fontWeight: 'bold',
                  letterSpacing: '1px',
                  cursor: 'pointer',
                }}
              >
                {data[dataIndex]?.numberOfClients}
              </Typography>
            </>
          );
        },
        customHeadLabelRender: function customHeadLabelRender(): React.ReactNode {
          return (
            <Typography
              variant="h6"
              align="right"
              style={{
                textAlign: 'right',
                textTransform: 'uppercase',
                color: '#7d7d7d',
                fontSize: '11px',
              }}
            >
              CLIENTS HELD
            </Typography>
          );
        },
      },
    },
  ];

  const options: MUIDataTableOptions = {
    download: false,
    filter: false,
    pagination: false,
    print: false,
    search: false,
    selectableRows: 'none',
    selectToolbarPlacement: 'none',
    setTableProps: () => ({
      style: {
        marginTop: theme.spacing(3), // factor 4 spacing: 3 * 4 = 12px
      },
    }),
    customToolbar: function customToolbar() {
      return (
        <div style={{ textAlign: 'left', display: 'table-cell' }}>
          <Box
            style={{
              backgroundColor: assetClassColor,
              display: 'inline-flex',
              verticalAlign: 'middle',
              marginRight: '12px',
              borderRadius: '15px',
              width: '20px',
              height: '20px',
            }}
          />
          <Typography
            variant={'h5'}
            style={{
              fontWeight: 'bold',
              letterSpacing: '1px',
              display: 'inline-flex',
              marginRight: '16px',
            }}
          >
            {assetClassTitle}
          </Typography>
          <span
            style={{
              color: theme.palette.text.secondary,
              display: 'inline-block',
              marginRight: '16px',
              fontWeight: 'normal',
            }}
          >
            |
          </span>
          <Typography
            variant={'body1'}
            style={{
              display: 'inline-flex',
              marginRight: '16px',
              fontWeight: 700,
            }}
          >
            {assetClassPercentage}
          </Typography>
          <span
            style={{
              color: theme.palette.text.secondary,
              display: 'inline-block',
              marginRight: '16px',
              fontWeight: 'normal',
            }}
          >
            |
          </span>
          <Typography
            variant={'body1'}
            align="right"
            style={{
              display: 'inline-flex',
              fontWeight: 700,
            }}
          >
            {assetClassTotal}
          </Typography>
        </div>
      );
    },
    viewColumns: false,
  };

  return (
    <ClientSideDataTable data={data} columns={columns} options={options} loadingProgress={progress} className={classes.datatable} id="holdings-datatable" />
  );
}
