import AddIcon from '@mui/icons-material/Add';
import { Box, Container, Grid } from '@mui/material';
import { DateTime } from 'luxon';
import React, { useCallback, useEffect } from 'react';
import WO2Button from 'src/common/components/button/Button';
import SearchButton from 'src/features/clients/client/common/components/SearchButton';
import { Props } from '../container';
import {
  DeleteFxDailyRateItem,
  FxDailyListParameters,
  FxDailyRateCsvRow,
  FxDailyRateItem,
  FxDailyRateRequest,
  PostFxDailyRateItem,
  PutFxDailyRateItem,
} from '../store/types';
import { FxRateListTable } from './fxrateListTable';
import { useConfirmation } from 'src/common/components/dialogs';
import { PagedRequest } from 'src/store';
import DateRangePicker from 'src/common/components/DateRangePicker';

export const RateComponent = (props: Props): JSX.Element => {
  const {
    parameters,
    fxDailyRates,
    editId,
    fetchDailyRate,
    setDateParameter,
    fetchFxDailyRates,
    fetchFxDailyRatesExport,
    fxDailyProgress,
    downloadFxDailyRates,
    setEditId,
    setAddRate,
    updateRate,
    deleteRate,
    createRate,
    hasClientEditAdminOnlyPermission,
    match: { params },
  } = props;

  const confirm = useConfirmation();

  const fetch = useCallback(() => {
    if (params.baseCurrencyId && params.quoteCurrencyId) {
      const requestPayload2: FxDailyListParameters = {
        pagination: {
          pageNumber: 1,
          pageSize: 25,
          queryFields: [
            {
              fieldName: 'date',
              descendingSortDirection: true,
              operator: '',
              hasSearchTerm: false,
              isSortTerm: true,
              searchTerm: '',
            },
          ],
        },
        baseCurrencyId: parseInt(params.baseCurrencyId),
        quoteCurrencyId: parseInt(params.quoteCurrencyId),
        dates: { dateFrom: DateTime.now().minus({ years: 1 }).toISODate(), dateTo: DateTime.now().toISODate() },
      };

      fetchFxDailyRates(requestPayload2);
    }
  }, [fetchFxDailyRates, params.baseCurrencyId, params.quoteCurrencyId]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  function onSearchClicked() {
    if (params.baseCurrencyId && params.quoteCurrencyId) {
      const requestPayload: FxDailyListParameters = {
        pagination: {
          pageNumber: 1,
          pageSize: 25,
          queryFields: [
            {
              fieldName: 'date',
              descendingSortDirection: true,
              operator: '',
              hasSearchTerm: false,
              isSortTerm: true,
              searchTerm: '',
            },
          ],
        },
        baseCurrencyId: parseInt(params.baseCurrencyId),
        quoteCurrencyId: parseInt(params.quoteCurrencyId),
        dates: {
          dateFrom: parameters?.dates.dateFrom,
          dateTo: parameters?.dates.dateTo,
        },
      };
      fetchFxDailyRates(requestPayload);
    }
  }

  const handleGridActions = (pagedRequest: PagedRequest) => {
    if (params.baseCurrencyId && params.quoteCurrencyId) {
      fetchFxDailyRates({
        pagination: pagedRequest,
        baseCurrencyId: parseInt(params.baseCurrencyId),
        quoteCurrencyId: parseInt(params.quoteCurrencyId),
        dates: {
          dateFrom: parameters?.dates.dateFrom,
          dateTo: parameters?.dates.dateTo,
        },
      });
    }
  };

  const exportCSV = () => {
    const paginationExport = {
      pageNumber: 1,
      pageSize: 25000000,
      queryFields: [
        {
          fieldName: 'date',
          descendingSortDirection: true,
          operator: '',
          hasSearchTerm: false,
          isSortTerm: true,
          searchTerm: '',
        },
      ],
    };
    if (params.baseCurrencyId && params.quoteCurrencyId) {
      fetchFxDailyRatesExport({
        pagination: paginationExport,
        baseCurrencyId: parseInt(params.baseCurrencyId),
        quoteCurrencyId: parseInt(params.quoteCurrencyId),
        dates: {
          dateFrom: parameters?.dates.dateFrom,
          dateTo: parameters?.dates.dateTo,
        },
      });
    }
  };

  const onStartAddItem = (item: FxDailyRateItem) => {
    item.baseCurrencyId = Number(params.baseCurrencyId ?? 0);
    item.quoteCurrencyId = Number(params.quoteCurrencyId ?? 0);
    setAddRate(item);
  };

  const checkChangeRatio = (currentItem: FxDailyRateItem, fieldRateItem: FxDailyRateItem): boolean => {
    const allowChangeRatio = 0.05;

    const existingRate = currentItem.currencyRate || 0;
    let changeRatio = (fieldRateItem.currencyRate ?? 0) / existingRate;

    if (changeRatio >= 1) {
      changeRatio = changeRatio - 1;
    }
    return changeRatio >= allowChangeRatio;
  };

  const checkChangeRatioAndUpsert = (
    currentItem: FxDailyRateItem,
    fieldRateItem: FxDailyRateItem,
    callback: (item: FxDailyRateItem) => void
  ) => {
    if (checkChangeRatio(currentItem, fieldRateItem)) {
      confirm({
        title: 'Warning - Percentage Change greater than 5.0%',
        description: 'Note that Percentage changed is greater or equal to 5.0%.',
        description2: 'Do you still want to save this change?',
      }).then(() => callback(fieldRateItem));
    } else {
      callback(fieldRateItem);
    }
  };

  const onSaveDailyDetails = async (fxDailyRateItem: FxDailyRateItem) => {
    if (!fxDailyRateItem) return;

    const dayBefore = new Date(fxDailyRateItem.date);

    dayBefore.setDate(dayBefore.getDate() - 1);

    const fetchRateRequest: FxDailyRateRequest = {
      quoteCurrencyId: Number(params.quoteCurrencyId || '0'),
      baseCurrencyId: Number(params.baseCurrencyId || '0'),
      date: dayBefore.toISOString().split('T')[0],
    };

    try {
      const dailyRate = await fetchDailyRate(fetchRateRequest);
      checkChangeRatioAndUpsert(dailyRate.payload as FxDailyRateItem, fxDailyRateItem, (item: FxDailyRateItem) =>
        editId != 0 ? processUpdate(item) : processCreate(item)
      );
    } catch (e) {
      editId != 0 ? processUpdate(fxDailyRateItem) : processCreate(fxDailyRateItem);
    }
  };

  function processUpdate(obj: FxDailyRateItem) {
    const item: PutFxDailyRateItem = {
      id: obj.id,
      currencyRate: obj.currencyRate,
      manuallyOverriden: obj.manuallyOverriden,
      importSource: null,
    };
    updateRate(item).then(() => {
      onSearchClicked();
    });
  }

  function processCreate(obj: FxDailyRateItem) {
    const item: PostFxDailyRateItem = {
      date: obj.date,
      currencyRate: obj.currencyRate,
      importSource: null,
      manuallyOverriden: obj.manuallyOverriden,
      baseCurrencyId: Number(params.baseCurrencyId ?? 0),
      quoteCurrencyId: Number(params.quoteCurrencyId ?? 0),
    };
    createRate(item).then(() => {
      onSearchClicked();
    });
  }

  const onDeleteItem = (id: number) => {
    const item: DeleteFxDailyRateItem = {
      id: id,
    };
    if (item) {
      deleteRate(item).then(() => {
        onSearchClicked();
      });
    }
  };

  return (
    <Container style={{ paddingTop: '20px', paddingBottom: '20px' }}>
      <Box display={'flex'} justifyContent={'space-between'} style={{ padding: '10px 0' }}>
        <Box paddingRight={'10px'}></Box>
        <Box display={'flex'} justifyContent={'space-between'}>
          <DateRangePicker dateRange={parameters.dates} setDateRange={setDateParameter} />
          <Box paddingRight={'10px'}></Box>
          <Box>
            <SearchButton onClick={onSearchClicked} />
          </Box>
          <Box>
            <WO2Button
              type="button"
              data-testid="exportPriceButton"
              variant={'contained'}
              startIcon={<AddIcon />}
              onClick={() => {
                exportCSV();
              }}
            >
              Export
            </WO2Button>
          </Box>
        </Box>
      </Box>
      <Grid container spacing={2} style={{ paddingTop: '15px', paddingLeft: '10px' }}>
        <FxRateListTable
          pagedRequest={parameters.pagination}
          pagedResults={fxDailyRates || undefined}
          handlePagedRequest={handleGridActions}
          onSelectEditId={setEditId}
          editId={editId}
          loadingProgress={fxDailyProgress}
          onStartAddItem={onStartAddItem}
          onSave={onSaveDailyDetails}
          onDelete={onDeleteItem}
          isViewMode={!hasClientEditAdminOnlyPermission}
          handleDownload={(
            pagedRequest: PagedRequest,
            mapper: (fxDailyRateIteItems: FxDailyRateItem[]) => FxDailyRateCsvRow[]
          ) =>
            downloadFxDailyRates({
              pagination: pagedRequest,
              baseCurrencyId: parseInt(params.baseCurrencyId ?? ''),
              quoteCurrencyId: parseInt(params.quoteCurrencyId ?? ''),
              dates: {
                dateFrom: parameters?.dates.dateFrom,
                dateTo: parameters?.dates.dateTo,
              },
              mapper,
            })
          }
        ></FxRateListTable>
      </Grid>
    </Container>
  );
};
