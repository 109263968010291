import { Add as AddIcon, Cancel as CancelIcon, Delete as DeleteIcon, Edit as EditIcon, Save as SaveIcon } from '@mui/icons-material';
import { Autocomplete, Box, IconButton, LinearProgress, Paper, Stack, styled, TextField, Typography } from '@mui/material';
import { GridToolbarContainer } from '@mui/x-data-grid';
import {
  DataGridPro,
  GridActionsCellItem,
  GridColumns,
  gridEditRowsStateSelector,
  GridPreProcessEditCellProps,
  GridRenderCellParams,
  GridRenderEditCellParams,
  GridRowId,
  GridRowModes,
  GridRowParams,
  GridValueGetterParams,
  MuiBaseEvent,
  MuiEvent,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import React, { useCallback, useEffect, useState } from 'react';
import PulseLoader from 'react-spinners/PulseLoader';
import { AssetClassEnum } from 'src/common/enums/assetClasses';
import { useDebounce } from 'src/common/hooks';
import { LoadingProgress } from 'src/common/store/types';
import { fetchSecuritySearchService } from 'src/features/orders/rebalance/edit/store/services';
import * as yup from 'yup';
import { theme } from '../../../../../../../themes';
import { CreateDefaultSecurity, DefaultSecurity, SecuritySearchResult } from '../store';

const StyledBox = styled(Box)(() => ({
  width: '100%',
  '& .MuiDataGrid-cell--editing': {
    backgroundColor: 'rgb(255,215,115, 0.19)',
    color: '#1a3e72',
    '& .MuiInputBase-root': {
      height: '100%',
    },
  },
  '& .Mui-error': {
    backgroundColor: `#FFCCCC`,
    color: 'red',
    borderRadius: '4px',
    borderColor: 'red',
    borderStyle: 'solid',
    borderWidth: '1px',
  },
  '& .MuiDataGrid-editInputCell': {
    borderWidth: '2px',
  },
  '& input[type=number]': {
    textAlign: 'right',
    paddingRight: '0px',
  },
  '& .MuiDataGrid-toolbarContainer': {
    justifyContent: 'flex-end',
  },
}));

export interface DefaultSecuritiesTableProps {
  items: DefaultSecurity[];
  itemLoadingProgress: LoadingProgress;
  onSave?: (defaultSecurityItems: CreateDefaultSecurity[]) => Promise<void>;
  savingProgress: LoadingProgress;
  hideEditControls?: boolean;
  afslId: number | undefined;
}

interface SecurityResult {
  securityCode: string;
  securityId: number;
  securityName: string;
}

interface DataRow {
  id: string;
  assetClassId: number | null | undefined;
  security: SecurityResult | null | undefined;
}

export const DefaultSecuritiesTable = (props: DefaultSecuritiesTableProps): JSX.Element => {
  const { items, itemLoadingProgress, onSave, savingProgress, hideEditControls, afslId } = props;
  const [dataRows, setDataRows] = useState<DataRow[]>([]);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [validationMessage, setValidationMessage] = useState<string>('');

  const apiRef = useGridApiRef();

  const [securitySearchResults, setSecuritySearchResults] = useState<SecurityResult[]>([]);
  const [securitySearch, setSecuritySearch] = useState<string>('');
  const onSecuritySearchChange = useDebounce<string>(securitySearch, 500);

  useEffect(() => {
    new Promise<void>(async () => {
      let searchResults: SecurityResult[] = [];
      if (afslId && onSecuritySearchChange && onSecuritySearchChange.length > 2) {
        const results = await fetchSecuritySearchService(afslId, onSecuritySearchChange);
        searchResults = results.results.map((r) => ({ securityCode: r.securityCode, securityId: r.securityId, securityName: r.securityName }));
      }
      const allResults = [
        ...searchResults,
        ...items.reduce(
          (prev, curr): SecurityResult[] =>
            !!searchResults.find((sr) => sr.securityId === curr.securityId)
              ? prev
              : [
                  ...prev,
                  {
                    securityCode: curr.securityCode,
                    securityId: curr.securityId,
                    securityName: curr.securityName,
                  },
                ],
          [] as SecurityResult[]
        ),
      ];
      setSecuritySearchResults(allResults);
    });
  }, [onSecuritySearchChange, afslId, items]);

  const validationSchema = yup.object({
    defaultSecurities: yup.array().of(
      yup.object().shape({
        id: yup.string(),
        assetClassId: yup.number().nullable().required('Asset class is required'),
        security: yup.object().nullable().required('Default security is required'),
      })
    ),
  });

  const validateDefaultSecurties = React.useCallback(() => {
    const defaultSecurities: DataRow[] = Object.entries(gridEditRowsStateSelector(apiRef.current.state))
      .map((r) => r[1])
      .map((r, index) => ({
        id: index.toString(),
        assetClassId: r.assetClassId.value !== '' ? r.assetClassId.value : null,
        security: r.security.value,
      }));

    validationSchema
      .validate({ defaultSecurities: defaultSecurities })
      .then(() => {
        setValidationMessage('');
      })
      .catch((e) => {
        setValidationMessage(e.message);
      });
  }, [apiRef.current.state]);

  useEffect(() => {
    resetDataRows();
  }, [items]);

  const resetDataRows = useCallback(() => {
    const itemsToProcess = items
      .map((i, index) => ({
        id: (index + 1).toString(),
        assetClassId: i.assetClassId,
        security: {
          securityCode: i.securityCode,
          securityId: i.securityId,
          securityName: i.securityName,
        },
      }))
      .filter((i) => i.assetClassId !== AssetClassEnum.Unallocated.id);

    setDataRows([...itemsToProcess]);
  }, [items, dataRows]);

  const columns: GridColumns = [
    {
      field: 'assetClassId',
      headerName: 'ASSET CLASS',
      type: 'singleSelect',
      flex: 1,
      editable: true,
      sortable: false,
      renderCell: (params: GridRenderCellParams<number>) => {
        return <div>{AssetClassEnum.getById(params.value)?.displayName}</div>;
      },
      valueOptions: (params) => {
        const assetClasseIdsInUse = Object.entries(gridEditRowsStateSelector(apiRef.current.state))
          .filter((r) => r[0] !== params.id)
          .map((r) => r[1].assetClassId.value);

        return [...AssetClassEnum.getAll()]
          .filter((e) => !assetClasseIdsInUse.includes(e.id))
          .map((c) => ({ value: c.id, label: c.displayName }))
          .sort((a, b) => a.label.localeCompare(b.label));
      },
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        return { ...params.props, error: params.props.value === '' };
      },
      valueGetter: (params: GridValueGetterParams) => {
        return params.value !== null ? params.value : '';
      },
    },
    {
      field: 'security',
      headerName: 'DEFAULT SECURITY',
      type: 'singleSelect',
      flex: 1,
      editable: true,
      sortable: false,
      renderEditCell: (params: GridRenderEditCellParams<SecurityResult>) => {
        const { id, value } = params;

        return (
          <Autocomplete
            style={{ width: '100%' }}
            noOptionsText="No matching securities were found."
            value={value}
            disableClearable
            options={securitySearchResults}
            isOptionEqualToValue={(option, value) => (value ? option.securityId === value.securityId : true)}
            getOptionLabel={(option: SecurityResult) => `${option.securityCode} - ${option.securityName}`}
            onChange={(event: unknown, value: SecurityResult | null) => {
              handleSecurityOnChange(id, value);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                onChange={(event) => {
                  setSecuritySearch(event.target.value);
                }}
              />
            )}
          />
        );
      },
      renderCell: (params: GridRenderCellParams<SecuritySearchResult>) => {
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{
                letterSpacing: '1px',
              }}
            >
              {params.value?.securityName ?? ''}
            </Typography>
            <Typography color={'textSecondary'} variant={'h6'} align="left">
              {params.value?.securityCode ?? ''}
            </Typography>
          </div>
        );
      },
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        return { ...params.props, error: params.props.value === '' };
      },
      valueGetter: (params: GridValueGetterParams) => {
        return params.value !== null ? params.value : '';
      },
    },
    {
      field: 'actions',
      type: 'actions',
      sortable: false,
      width: 80,
      getActions: (params) => {
        return [
          <GridActionsCellItem key="1" icon={<DeleteIcon />} label="Delete" onClick={deleteRow(params.id.toString())} disabled={savingProgress.isLoading} />,
        ];
      },
    },
  ];

  const handleSecurityOnChange = (id: GridRowId, value: SecurityResult | null) => {
    apiRef.current.setEditCellValue({ id, field: 'security', value: value });
  };

  const handleAddClick = React.useCallback(() => {
    const id = (apiRef.current.getAllRowIds().length + 1).toString();

    const newRow: DataRow = {
      id,
      assetClassId: null,
      security: null,
    };
    apiRef.current.updateRows([{ ...newRow, id, isNew: true }]);
    apiRef.current.setRowMode(id, GridRowModes.Edit);
    apiRef.current.setEditCellValue({ id, field: 'assetClassId', value: '' });
    apiRef.current.setEditCellValue({ id, field: 'security', value: null });

    // Wait for the grid to render with the new row
    setTimeout(() => {
      apiRef.current.scrollToIndexes({
        rowIndex: apiRef.current.getRowsCount() - 1,
      });

      apiRef.current.setCellFocus(-1, 'name');
    });
  }, [apiRef]);

  const handleEditClick = React.useCallback(() => {
    setEditMode(true);
    apiRef.current.getAllRowIds().forEach((id) => {
      apiRef.current.setRowMode(id, GridRowModes.Edit);
    });
  }, [apiRef]);

  const handleCancelClick = React.useCallback(() => {
    setEditMode(false);
    apiRef.current.getAllRowIds().forEach((id) => {
      const rowMode = apiRef.current.getRowMode(id);
      if (rowMode === 'edit') {
        apiRef.current.setRowMode(id, GridRowModes.View);
      }
      const row = apiRef.current.getRow(id);
      if (!!row && row.isNew) {
        apiRef.current.updateRows([{ id, _action: 'delete' }]);
      }
    });

    resetDataRows();
  }, [apiRef, items]);

  const handleSave = useCallback(async () => {
    if (!!onSave) {
      const editModels = gridEditRowsStateSelector(apiRef.current.state);
      handleCancelClick();
      onSave(
        Object.entries(editModels).map((m) => ({
          assetClassId: m[1].assetClassId.value,
          securityId: m[1].security.value.securityId,
        }))
      );
    }
  }, [onSave, apiRef.current]);

  const deleteRow = React.useCallback(
    (id: string) => () => {
      apiRef.current.setRowMode(id, GridRowModes.View);
      apiRef.current.updateRows([{ id, _action: 'delete' }]);
    },
    [apiRef, dataRows]
  );

  return (
    <>
      <Typography variant="h4" style={{ paddingBottom: '10px', paddingTop: '10px' }}>
        Default Securities
      </Typography>
      <Paper elevation={3}>
        <StyledBox>
          <DataGridPro
            editMode="row"
            apiRef={apiRef}
            rows={savingProgress.isLoading ? [] : dataRows}
            columns={columns}
            columnVisibilityModel={{
              actions: editMode,
            }}
            pageSize={items.length}
            disableColumnMenu
            disableColumnReorder={true}
            style={{ height: '400px' }}
            components={{
              LoadingOverlay: LinearProgress,
              Toolbar: () => {
                validateDefaultSecurties();

                return (
                  <GridToolbarContainer>
                    <Stack direction="row" alignItems="center">
                      {savingProgress.isLoading && (
                        <div className="LoadingIndicator" style={{ padding: '7px' }}>
                          <PulseLoader size="9px" margin="5px" color={theme.palette.grey[400]} />
                        </div>
                      )}
                      {!hideEditControls && editMode && <Typography color={'error'}>{validationMessage}</Typography>}
                      {!hideEditControls && editMode && !savingProgress.isLoading && (
                        <IconButton disableFocusRipple disableRipple data-testid="addButton" onClick={handleAddClick} color={'primary'}>
                          <AddIcon style={{ height: '24px' }} />
                        </IconButton>
                      )}
                      {!hideEditControls && !editMode && (
                        <IconButton disableFocusRipple disableRipple data-testid="editButton" onClick={handleEditClick} color={'primary'}>
                          <EditIcon style={{ height: '24px' }} />
                        </IconButton>
                      )}
                      {!hideEditControls && editMode && !savingProgress.isLoading && (
                        <IconButton disableFocusRipple disableRipple data-testid="cancelButton" onClick={handleCancelClick} color={'primary'}>
                          <CancelIcon style={{ height: '24px' }} />
                        </IconButton>
                      )}
                      {!hideEditControls && editMode && !savingProgress.isLoading && (
                        <IconButton
                          disableFocusRipple
                          disableRipple
                          data-testid="saveButton"
                          onClick={handleSave}
                          color={'primary'}
                          disabled={validationMessage !== ''}
                        >
                          <SaveIcon style={{ height: '24px' }} />
                        </IconButton>
                      )}
                    </Stack>
                  </GridToolbarContainer>
                );
              },
              Footer: () => {
                return <></>;
              },
            }}
            loading={itemLoadingProgress.isLoading || savingProgress.isLoading}
            onRowEditStop={(_params: GridRowParams, event: MuiEvent<MuiBaseEvent>) => {
              // we don't want to end editting unless they click save
              event.defaultMuiPrevented = true;
            }}
            onRowEditStart={(_params: GridRowParams, event: MuiEvent<MuiBaseEvent>) => {
              // we don't want to end editting unless they click edit
              event.defaultMuiPrevented = true;
            }}
          />
        </StyledBox>
      </Paper>
    </>
  );
};
