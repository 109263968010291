import {
  FormControlLabel,
  Box,
  MenuItem,
  FormControl,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import React from 'react';
import { WO2Checkbox, WO2Select } from 'src/common';
import DateRangePicker from 'src/common/components/DateRangePicker';
import { ActionCreatorWithoutPayload, ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { ClientReportsParameters, MdaReportTypes, SortByCashTransactions } from '../../store/types';
import { DateRange } from 'src/store';
import { ExpandMore } from '@mui/icons-material';

export interface CashTransactionsProps {
  parameters: ClientReportsParameters;
  setCashTransactionsSortByParameter: ActionCreatorWithPayload<SortByCashTransactions, string>;
  setDateRangeCashTransactionsParameter: ActionCreatorWithPayload<DateRange, string>;
  setCashTransactionsSelectedParameter: ActionCreatorWithPayload<boolean, string>;
  expandCashTransactions: ActionCreatorWithoutPayload<string>;
  createReportSortRequest: (isAdd: boolean, id: number, text: string) => void;
}

export const CashTransactions = ({
  parameters,
  createReportSortRequest,
  setCashTransactionsSelectedParameter,
  setDateRangeCashTransactionsParameter,
  setCashTransactionsSortByParameter,
  expandCashTransactions,
}: CashTransactionsProps): JSX.Element => {
  const handleClickCashTransactionsSelect = () => {
    setCashTransactionsSelectedParameter(!parameters.selectedReports?.cashTransactions.selected);
    createReportSortRequest(
      !parameters.selectedReports?.cashTransactions.selected,
      MdaReportTypes.CashTransactions.id,
      MdaReportTypes.CashTransactions.displayName
    );
  };

  const handleClickReportDateCashTransactions = (dates: DateRange) => {
    setDateRangeCashTransactionsParameter(dates);
  };

  return (
    <Accordion
      onChange={expandCashTransactions}
      expanded={parameters.selectedReports?.cashTransactions.expanded || false}
      elevation={0}
    >
      <AccordionSummary expandIcon={<ExpandMore />}>
        <FormControlLabel
          sx={{ flex: 1 }}
          aria-label="Acknowledge"
          onClick={(event) => event.stopPropagation()}
          onFocus={(event) => event.stopPropagation()}
          control={
            <WO2Checkbox
              color="primary"
              checked={parameters.selectedReports?.cashTransactions.selected}
              onChangeHandler={handleClickCashTransactionsSelect}
            />
          }
          label="Cash Transactions"
        />
        <div onClick={(e) => e.stopPropagation()}>
          <DateRangePicker
            dateRange={{
              dateFrom: parameters?.selectedReports?.cashTransactions?.fromDate,
              dateTo: parameters?.selectedReports?.cashTransactions?.toDate,
              dateRangeType: parameters?.selectedReports?.cashTransactions?.dateRangeType,
            }}
            setDateRange={handleClickReportDateCashTransactions}
            inceptionDate={parameters.inceptionDate}
          />
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <Box display="flex" gap={1}>
          <FormControl>
            <WO2Select
              onChange={(e) => setCashTransactionsSortByParameter(e.target.value as SortByCashTransactions)}
              defaultValue={SortByCashTransactions.DateAscending}
            >
              <MenuItem value={SortByCashTransactions.DateAscending}>Date Ascending</MenuItem>
              <MenuItem value={SortByCashTransactions.DateDescending}>Date Descending</MenuItem>
            </WO2Select>
          </FormControl>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};
