import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../../../../../app/api';
import { buildEncodedQueryString } from '../../../../../../../common';
import { BenefitStatementDetails, OtherBenefits, PreservationComponentDetails, ResultWrapper, TaxationDetails } from './types';

export enum BenefitStatmentActionTypes {
  FetchBenefitStatementDetails = '@@client/details/superSimplifier/benefitStatement/FetchBenefitStatementDetails',
  FetchBenefitStatementPreservationComponent = '@@client/details/superSimplifier/benefitStatement/FetchBenefitStatementPreservationComponent',
  FetchBenefitStatementTaxationComponent = '@@client/details/superSimplifier/benefitStatement/FetchBenefitStatementTaxationComponent',
  FetchBenefitStatementOtherBenefitsComponent = '@@client/details/superSimplifier/benefitStatement/FetchBenefitStatementOtherBenefitComponent',
}

export enum BenefitStatmentApiEndpoints {
  FetchBenefitStatementDetails = '/customers/GetSuperSimplifierMemberBenefitStatement',
  FetchBenefitStatementPreservationComponent = '/ssc/GetPreservationComponent',
  FetchBenefitStatementTaxationComponent = '/ssc/GetTaxationComponent',
  FetchBenefitStatementOtherBenefitsComponent = '/ssc/GetOtherBenefits',
}

export const fetchBenefitStatementDetailsByClientId = createAsyncThunk(BenefitStatmentActionTypes.FetchBenefitStatementDetails, async (clientId: number) => {
  const queryString = buildEncodedQueryString({
    clientId,
  });
  const result = await api
    .get<BenefitStatementDetails>(`${BenefitStatmentApiEndpoints.FetchBenefitStatementDetails}${queryString}`)
    .then((data) => {
      const response: ResultWrapper<BenefitStatementDetails> = {
        status: data.status,
        statusText: data.statusText,
        responseData: data.data,
      };
      return response;
    })
    .catch((error) => {
      const response: ResultWrapper<null> = {
        status: error.status,
        statusText: error.statusText,
        responseData: null,
      };
      return response;
    });
  return result;
});

export const fetchBenefitStatementPreservationComponentByAccountId = createAsyncThunk(
  BenefitStatmentActionTypes.FetchBenefitStatementPreservationComponent,
  async (accountId: string) => {
    const queryString = buildEncodedQueryString({
      accountId,
    });

    const result = await api
      .get<PreservationComponentDetails>(`${BenefitStatmentApiEndpoints.FetchBenefitStatementPreservationComponent}${queryString}`)
      .then((data) => {
        const response: ResultWrapper<PreservationComponentDetails | null> = {
          status: data.status,
          statusText: data.statusText,
          responseData: data.data,
        };
        return response;
      })
      .catch((error) => {
        const response: ResultWrapper<PreservationComponentDetails | null> = {
          status: error.status,
          statusText: error.statusText,
          responseData: null,
        };
        return response;
      });
    return result;
  }
);

export const fetchBenefitStatementTaxationComponentByAccountId = createAsyncThunk(
  BenefitStatmentActionTypes.FetchBenefitStatementTaxationComponent,
  async (accountId: string) => {
    const queryString = buildEncodedQueryString({
      accountId,
    });
    const result = await api
      .get<TaxationDetails>(`${BenefitStatmentApiEndpoints.FetchBenefitStatementTaxationComponent}${queryString}`)
      .then((data) => {
        const response: ResultWrapper<TaxationDetails> = {
          status: data.status,
          statusText: data.statusText,
          responseData: data.data,
        };
        return response;
      })
      .catch((error) => {
        const response: ResultWrapper<null> = {
          status: error.status,
          statusText: error.statusText,
          responseData: null,
        };
        return response;
      });
    return result;
  }
);

export const fetchBenefitStatementOtherBenefitsComponentByAccountId = createAsyncThunk(
  BenefitStatmentActionTypes.FetchBenefitStatementOtherBenefitsComponent,
  async (accountId: string) => {
    const queryString = buildEncodedQueryString({
      accountId,
    });
    const result = await api
      .get<OtherBenefits>(`${BenefitStatmentApiEndpoints.FetchBenefitStatementOtherBenefitsComponent}${queryString}`)
      .then((data) => {
        const response: ResultWrapper<OtherBenefits> = {
          status: data.status,
          statusText: data.statusText,
          responseData: data.data,
        };
        return response;
      })
      .catch((error) => {
        const response: ResultWrapper<null> = {
          status: error.status,
          statusText: error.statusText,
          responseData: null,
        };
        return response;
      });
    return result;
  }
);
