import api from '../../../../app/api';
import { buildEncodedQueryString } from '../../../../common/utils';
import { SaveModelCompositionPayload, SaveModelCompositionResult, Security, SecuritySearchResults } from './types';

enum compositionApis {
  GetApprovedSecuritiesByAfslId = '/approvedservices/getapprovedsecuritiesbyafslid',
  SaveModelComposition = '/bff/SaveModelComposition',
  GetSecurityDetailsByIdAndAfslId = '/securities/getsecuritydetailsbyidandafslid',
}

export async function fetchSecuritySearchService(afslId: number, search: string): Promise<SecuritySearchResults> {
  const payload = {
    afslId: afslId,
    pagedRequest: {
      pageNumber: 1,
      pageSize: 1000,
      queryFields: [
        {
          fieldName: 'securityname',
          hasSearchTerm: true,
          searchTerm: search,
          isSortTerm: true,
          descendingSortDirection: false,
        },
        {
          fieldName: 'securitycode',
          hasSearchTerm: true,
          searchTerm: search,
          isSortTerm: true,
          descendingSortDirection: false,
        },
      ],
    },
  };
  const response = await api.post(`${compositionApis.GetApprovedSecuritiesByAfslId}`, payload);
  return Promise.resolve<SecuritySearchResults>(response.data);
}

export async function fetchSecurityService(afslId: number, securityId: number): Promise<Security> {
  const queryString = buildEncodedQueryString({
    securityId: securityId,
    afslId: afslId,
  });
  const response = await api.get(`${compositionApis.GetSecurityDetailsByIdAndAfslId}${queryString}`);
  return Promise.resolve<Security>(response.data);
}

export async function saveModelCompositions(modelCompositionPayload: SaveModelCompositionPayload): Promise<SaveModelCompositionResult> {
  const response = await api.post(compositionApis.SaveModelComposition, modelCompositionPayload);
  return Promise.resolve<SaveModelCompositionResult>(response.data);
}
