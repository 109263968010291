import { Enumeration } from '../../../../common/types';
import { AccountSearchResult } from '../../email/search/store/types';

export interface BulkCommonState {
  afsls: AfslViewModel;
  models: ModelItem[];
  securities: SimpleSecurityItem[];
}

export interface AfslViewModel {
  afsls: AfslItem[];
}

export interface AfslItem {
  afslId: number;
  afslName: string;
  practices: PracticeItem[];
}

export interface PracticeItem {
  practiceId: number;
  practiceName: string;
  advisors: AdviserItem[];
}

export interface AdviserItem {
  advisorId: number;
  advisorName: string;
}

export interface SecurityItem {
  id: number;
  code: string;
  name: string;
}

export interface SimpleSecurityItem {
  securityId: number;
  securityCode: string;
  securityName: string;
}

export interface SecurityItemWithPrice {
  id: number;
  code: string;
  name: string;
  unitPrice: number;
  priceDate: string;
  priceType: string | null;
  priceLimit: number | null;
  expiryType: string | null;
  expiryDate: string | null;
  assetClass?: string | null;
  assetClassId?: number | null;
}

export interface ModelItem {
  modelId: number;
  code: string;
  name: string;
}

export class ServiceType extends Enumeration {
  static Mda = new ServiceType(1, 'Mda', 'MDA');
  static Roa = new ServiceType(8, 'Roa', 'ROA');
  static Wholesale = new ServiceType(9, 'Wholesale', 'Wholesale');
}

export interface EmailTemplate {
  id: number;
  templateName: string;
  entityCoreId: number;
}

export type EmailTemplateResult = EmailTemplate;

export interface EmailTemplateDetail extends EmailTemplate {
  emailSubject: string;
  templateContent: string;
  emailCc: string;
  recipients: AccountSearchResult[];
}

export interface Parameter {
  id: number;
  name: string;
}

export interface Client {
  id: number;
  name: string;
}

export class ClientAccountTypeForBulk extends Enumeration {
  static Company = new ClientAccountTypeForBulk(1, 'Company', 'Company');
  static Individual = new ClientAccountTypeForBulk(2, 'Individual', 'Individual');
  static Joint = new ClientAccountTypeForBulk(3, 'Joint', 'Joint');
  static IndividualPension = new ClientAccountTypeForBulk(4, 'Pension', 'Pension');
  static Smsf = new ClientAccountTypeForBulk(5, 'SMSF', 'SMSF');
  static IndividualSuper = new ClientAccountTypeForBulk(6, 'Super', 'Super');
  static Trust = new ClientAccountTypeForBulk(7, 'Trust', 'Trust');

  static getByName = (name: string): ClientAccountTypeForBulk | undefined => {
    return Object.values(ClientAccountTypeForBulk)
      .filter((t) => t instanceof ClientAccountTypeForBulk)
      .find((t: ClientAccountTypeForBulk) => t.name.toLowerCase() === name.toLowerCase());
  };
}

export class NumericOperatorType extends Enumeration {
  static None = new NumericOperatorType(-1, '', '');
  static Between = new NumericOperatorType(0, 'Between', 'Between');
  static EqualsTo = new NumericOperatorType(1, 'Equals To', 'Equals To');
  static GreaterThan = new NumericOperatorType(2, 'Greater Than', 'Greater Than');
  static GreaterThanEqualsTo = new NumericOperatorType(3, 'Greater Than Equals To', 'Greater Than Equals To');
  static LessThan = new NumericOperatorType(4, 'Less Than', 'Less Than');
  static LessThanEqualsTo = new NumericOperatorType(5, 'Less Than Equals To', 'Less Than Equals To');
}
