import { connect, ConnectedProps } from 'react-redux';
import { progressSelectorFactory } from '../../../../../common';
import { RootState } from '../../../../../reducers';
import { selectHasClientEditAdminOnly, selectIsDataFromTriumph } from '../../../../../store/selectors';
import {
  selectClientId,
  selectClientMainDetails,
  selectDocumentTypes,
  selectSuperSimplifierDetails,
  selectAfslId,
} from '../../common/store/selectors';
import { CommonActionTypes, fetchDocumentTypes } from '../../common/store/thunks';
import { selectClientPortfolioDetailResults } from '../../dashboard/store/selectors';
import { fetchClientPortfolioDetails } from '../../dashboard/store/thunks';
import { AdviceFees } from './components/adviceFees';
import {
  selectActiveFeesItems,
  selectAdviceServicesList,
  selectAdvisor,
  selectClientStatus,
  selectEditingAdviceServiceList,
  selectFeeEdit,
  selectPendingFeesChangeSet,
  selectPendingFeesItems,
  selectTieredFeeDetailsEdit,
  selectTieredFeeDetailsItems,
  selectAFSLFeesList,
} from './store/selectors';
import { adviceFeesSlice } from './store/slice';
import {
  activateFeeApplicationDocusign,
  activateFeeApplicationUpload,
  activateFeeRenewalDocusign,
  activateFeeRenewalUpload,
  activateFees,
  AdviceFeesActionTypes,
  cancelDocusignFeesApplicationActivation,
  cancelDocusignFeesRenewalActivation,
  createAdviceFee,
  deleteAdviceFee,
  downloadSuperSimplifierApplicationFeeConsent,
  downloadSuperSimplifierOngoingFeeConsentRenewal,
  fetchActiveAdviceFees,
  fetchAdviceServicesList,
  fetchAFSLFeesList,
  fetchPendingAdviceFees,
  loadAdviceFees,
  removePendingFee,
  updateAdviceFee,
  updateSuperSimplifierClientAdviceService,
  createFee,
} from './store/thunks';

const mapStateToProps = (state: RootState) => ({
  clientId: selectClientId(state),
  advisor: selectAdvisor(state),
  superSimplifierItem: selectSuperSimplifierDetails(state),
  clientPortfolioDetail: selectClientPortfolioDetailResults(state),
  clientStatus: selectClientStatus(state),
  pendingFeesChangeSet: selectPendingFeesChangeSet(state),
  adviceServicesList: selectAdviceServicesList(state),
  isEditingAdviceServiceList: selectEditingAdviceServiceList(state),
  pendingFeesItems: selectPendingFeesItems(state),
  activeFeesItems: selectActiveFeesItems(state),
  selectedFeeItem: selectFeeEdit(state),
  selectAdviceServicesList: selectAdviceServicesList(state),
  afslFeesList: selectAFSLFeesList(state),
  afslId: selectAfslId(state),
  tieredFeeDetailsItems: selectTieredFeeDetailsItems(state),
  selectedTieredFeeDetailsEdit: selectTieredFeeDetailsEdit(state),
  hasClientEditAdminOnlyPermission: selectHasClientEditAdminOnly(state),
  clientMainDetails: selectClientMainDetails(state),
  documentTypes: selectDocumentTypes(state),
  isDataFromTriumph: selectIsDataFromTriumph(state),
  loadingProgress: progressSelectorFactory([
    CommonActionTypes.FetchSuperSimplifier,
    AdviceFeesActionTypes.FetchActiveAdviceFees,
    AdviceFeesActionTypes.FetchPendingAdviceFees,
    AdviceFeesActionTypes.FetchAdviceServicesList,
    AdviceFeesActionTypes.CancelDocusignFeesApplicationActivation,
    AdviceFeesActionTypes.CancelDocusignFeesRenewalActivation,
    AdviceFeesActionTypes.ActivateFeeRenewalUpload,
    AdviceFeesActionTypes.ActivateFeeRenewalDocusign,
    AdviceFeesActionTypes.ActivateFeeRenewal,
    AdviceFeesActionTypes.CreateAdviceFee,
    AdviceFeesActionTypes.UpdateAdviceFee,
    AdviceFeesActionTypes.DeleteAdviceFee,
    AdviceFeesActionTypes.RemovePendingFee,
    AdviceFeesActionTypes.LoadAdviceFees,
    AdviceFeesActionTypes.FetchAFSLFeesList,
  ])(state),
});

const mapDispatchToProps = {
  ...adviceFeesSlice.actions,
  fetchPendingAdviceFees,
  fetchActiveAdviceFees,
  fetchAFSLFeesList,
  fetchAdviceServicesList,
  updateSuperSimplifierClientAdviceService,
  loadAdviceFees,
  createAdviceFee,
  updateAdviceFee,
  deleteAdviceFee,
  removePendingFee,
  fetchClientPortfolioDetails,
  fetchDocumentTypes,
  activateFeeApplicationDocusign,
  activateFeeApplicationUpload,
  activateFeeRenewalDocusign,
  activateFeeRenewalUpload,
  activateFees,
  cancelDocusignFeesApplicationActivation,
  cancelDocusignFeesRenewalActivation,
  downloadSuperSimplifierOngoingFeeConsentRenewal,
  downloadSuperSimplifierApplicationFeeConsent,
  createFee,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector>;
export const AdviceFeesContainer = connector(AdviceFees);
