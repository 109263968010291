/* eslint-disable @typescript-eslint/no-explicit-any */
import { DatePicker} from '@mui/lab';
import TextField from '@mui/material/TextField';
import { FieldInputProps, FormikProps, useField } from 'formik';
import { DateTime } from 'luxon';
import React from 'react';
import { DateTimeFormat } from 'src/common/utils';
import { FormikErrorMessage } from 'src/common/components/formik';

interface FirstPensionPaymentMonthYearPickerProps {
  disableCloseOnSelect?: boolean;
  showRequiredAsterisk?: boolean;
  maxDate?: DateTime;
  field: FieldInputProps<any>;
  type: React.HTMLInputTypeAttribute | undefined;
  label: React.ReactNode;
  form: FormikProps<any>;
  style: any;
  onChange: any;
}

export const FirstPensionPaymentMonthYearPicker = ({ showRequiredAsterisk, disableCloseOnSelect, ...props }: FirstPensionPaymentMonthYearPickerProps): JSX.Element => {
  const [field, , helper] = useField(props.field.name);
  const { label, style } = props;

  const getMaxDate = () => {
    const fyEndDate = '06-30';
    const todaysDate = new Date();
    todaysDate.setHours(0, 0, 0, 0);
    let currentYear = todaysDate.getFullYear();
    const currentMonth = todaysDate.getMonth() + 1;

    if (currentMonth == 6) {
        return DateTime.fromObject({
            year: currentYear + 1,
            month: 6,
            day: 30
        })
    } else {
        const getCurrentFinancialYearTodayDate = new Date(`${currentYear}-${fyEndDate}`);
        getCurrentFinancialYearTodayDate.setHours(0, 0, 0, 0);
        if (todaysDate > getCurrentFinancialYearTodayDate) {
            currentYear = currentYear + 1;
        }
        return DateTime.fromObject({
            year: currentYear,
            month: 6,
            day: 30
        })
    }
  };

  const getMinDate = () => {
    const nextMonth = DateTime.now().plus({months:1});
        return DateTime.fromObject({
          year: nextMonth.year,
          month: nextMonth.month,
          day: 1
        });
  };

  return (
    <div id={`${field.name}`} {...(!!style && { style })}>
      <DatePicker
        defaultCalendarMonth={DateTime.now().plus({months:1})}
        minDate={getMinDate()}
        maxDate={getMaxDate()}
        inputFormat={DateTimeFormat.MonthDate.displayName}
        data-testid={field.name}
        disableCloseOnSelect={!!disableCloseOnSelect}
        {...props}
        {...field}
        {...(!!showRequiredAsterisk && !!label && { label: `* ${label}` })}
        onChange={(date: DateTime | null) => {
          helper.setValue(!!date && date.isValid ? date.startOf('day').toISODate() : !!date ? date.invalidReason : null);
          if (props.onChange) {
            props.onChange(!!date && date.isValid ? date.startOf('day').toISODate() : null);
          }
        }}
        onError={(reason) => {
          helper.setError(reason?.toString());
        }}
        value={field.value || null}
        views={['year','month']}
        renderInput={(params) => (
          <TextField {...params} variant="outlined" InputLabelProps={{ shrink: true }} name={field.name} onBlur={field.onBlur} />
        )}
      ></DatePicker>
      <FormikErrorMessage name={`${field.name}`} data-testid={`${field.name}ErrorMessage`}></FormikErrorMessage>
    </div>
  );
};
