import { FormControlLabel, Box, Typography, AccordionDetails, AccordionSummary, Accordion } from '@mui/material';
import React from 'react';
import { WO2Checkbox, WO2ToggleSwitch } from 'src/common';
import DateRangePicker from 'src/common/components/DateRangePicker';
import { ActionCreatorWithoutPayload, ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { ClientReportsParameters, MdaReportTypes } from '../../store/types';
import { DateRange } from 'src/store';
import { ExpandMore } from '@mui/icons-material';

export interface RealisedCapitalGainsAndLossesProps {
  parameters: ClientReportsParameters;
  expandGainsAndLossesRealised: ActionCreatorWithoutPayload<string>;
  setDateRangeRealisedCapitalGainsAndLossesParameter: ActionCreatorWithPayload<DateRange, string>;
  setRealisedCapitalAndGainsLossesSelectedParameter: ActionCreatorWithPayload<boolean, string>;
  setRealisedCapitalGainsAndLossesSummaryParameter: ActionCreatorWithPayload<boolean, string>;
  createReportSortRequest: (isAdd: boolean, id: number, text: string) => void;
}

export const RealisedCapitalGainsAndLosses = ({
  parameters,
  createReportSortRequest,
  setRealisedCapitalAndGainsLossesSelectedParameter: setGainsLossesRealisedSelectedParameter,
  setDateRangeRealisedCapitalGainsAndLossesParameter: setDateRangeGainsLossesRealisedParameter,
  setRealisedCapitalGainsAndLossesSummaryParameter: setGainsLossesRealisedSummaryParameter,
  expandGainsAndLossesRealised,
}: RealisedCapitalGainsAndLossesProps): JSX.Element => {
  const handleClickGainsLossesRealisedSelect = () => {
    setGainsLossesRealisedSelectedParameter(!parameters.selectedReports?.realisedCapitalGainsAndLosses.selected);
    createReportSortRequest(
      !parameters.selectedReports?.realisedCapitalGainsAndLosses.selected,
      MdaReportTypes.RealisedCapitalGainsAndLosses.id,
      MdaReportTypes.RealisedCapitalGainsAndLosses.displayName
    );
  };

  const handleClickReportDateGainsLossesRealised = (dates: DateRange) => {
    setDateRangeGainsLossesRealisedParameter(dates);
  };

  const handleClickSummaryGainsLossesRealised = () => {
    setGainsLossesRealisedSummaryParameter(!parameters.selectedReports.realisedCapitalGainsAndLosses.includeSummary);
  };

  return (
    <Accordion
      onChange={expandGainsAndLossesRealised}
      expanded={parameters.selectedReports?.realisedCapitalGainsAndLosses.expanded || false}
      elevation={0}
    >
      <AccordionSummary expandIcon={<ExpandMore />}>
        <FormControlLabel
          sx={{ flex: 1 }}
          aria-label="Acknowledge"
          onClick={(event) => event.stopPropagation()}
          onFocus={(event) => event.stopPropagation()}
          control={
            <WO2Checkbox
              color="primary"
              checked={parameters.selectedReports?.realisedCapitalGainsAndLosses.selected}
              onChangeHandler={handleClickGainsLossesRealisedSelect}
            />
          }
          label="Realised Capital Gains and Losses"
        />
        <div onClick={(e) => e.stopPropagation()}>
          <DateRangePicker
            dateRange={{
              dateFrom: parameters?.selectedReports.realisedCapitalGainsAndLosses?.fromDate,
              dateTo: parameters?.selectedReports.realisedCapitalGainsAndLosses?.toDate,
              dateRangeType: parameters?.selectedReports.realisedCapitalGainsAndLosses?.dateRangeType,
            }}
            setDateRange={handleClickReportDateGainsLossesRealised}
            inceptionDate={parameters.inceptionDate}
          />
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <Box display="flex" flexWrap="nowrap" alignItems="center">
          <WO2ToggleSwitch
            checked={parameters.selectedReports.realisedCapitalGainsAndLosses.includeSummary}
            onChange={handleClickSummaryGainsLossesRealised}
            color="primary"
            name="hidingOptions"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
          <Typography variant="h4">Include Summary</Typography>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};
