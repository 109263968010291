import { Box } from '@mui/material';
import 'date-fns';
import React, { useEffect } from 'react';
import { Route, Switch, useParams } from 'react-router-dom';
import { Props } from '../container';
import { Edit } from './edit/edit';
import { List } from './list/list';

export const Contacts = (props: Props): JSX.Element => {
  const { setContactEditIndex } = props;
  const routeParams = useParams<{ index: string }>();

  useEffect(() => {
    if ('index' in routeParams) {
      // we have an index in the url.  Must be for primary contact (0) or additional joint (1)
      setContactEditIndex(+routeParams.index);
    }
  }, [setContactEditIndex, routeParams.index]);

  return (
    <Box width={'100%'}>
      <Switch>
        <Route path={'/client/onboard/contacts/contact'}>
          <Edit {...props} />
        </Route>
        <Route>
          <List {...props} />
        </Route>
      </Switch>
    </Box>
  );
};
