import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchAfsls, fetchSecurities } from './thunks';
import { AfslViewModel, BulkCommonState, SimpleSecurityItem } from './types';

export const initialState: BulkCommonState = {
  afsls: { afsls: [] },
  models: [],
  securities: [],
};

export const commonSlice = createSlice({
  name: '@@bulk/common',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAfsls.fulfilled, (state, action: PayloadAction<AfslViewModel>) => {
      state.afsls = action.payload;
    });
    builder.addCase(fetchSecurities.fulfilled, (state, action: PayloadAction<SimpleSecurityItem[]>) => {
      state.securities = action.payload;
    });
  },
});
