import { Container, Grid } from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import { LoadingIndicator } from 'src/common/components/LoadingIndicator';
import { formatDollars } from '../../../../common';
import CardComponent from '../../../../common/components/CardComponent';
import { theme } from '../../../../themes';
import { Props } from '../container';
import { AssociationsTable } from './AssociationsTable';

export const AssociationsComponent = ({
  loadingProgress,
  modelVersionId,
  fetchModelAssociations,
  modelAssociations,
  isDataFromTriumph,
}: Props): JSX.Element => {
  const fetch = useCallback(() => {
    if (modelVersionId !== null) {
      fetchModelAssociations({ modelVersionId, isDataFromTriumph });
    }
  }, [fetchModelAssociations, modelVersionId, isDataFromTriumph]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return (
    <Container style={{ paddingTop: '20px', paddingBottom: '20px' }}>
      <LoadingIndicator progress={loadingProgress}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <CardComponent
              progress={loadingProgress}
              background={theme.palette.gradient1?.main}
              value={formatDollars(modelAssociations?.fua)}
              subText={'FUA'}
            />
          </Grid>
          <Grid item xs={6}>
            <CardComponent
              progress={loadingProgress}
              background={theme.palette.gradient2?.main}
              value={modelAssociations?.portfolioCount}
              subText={'Portfolios'}
            />
          </Grid>
        </Grid>
      </LoadingIndicator>
      <Grid container spacing={2} style={{ marginTop: '24px' }}>
        <Grid item xs={12}>
          {!!modelAssociations && !!modelAssociations.portfolioExposures && (
            <AssociationsTable progress={loadingProgress} data={modelAssociations?.portfolioExposures} />
          )}
        </Grid>
      </Grid>
    </Container>
  );
};
