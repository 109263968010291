import { AlertColor } from '@mui/material';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { WO2Snackbar } from 'src/common';
import { RootState } from 'src/reducers';
import { fetchClientReport, getReportStatus } from '../store/thunks';

export const ReportGenerationProgress = (): JSX.Element => {
  const { reportGeneration } = useSelector((state: RootState) => state.clients?.client?.newReports);
  const [displaySnackbar, setSnackbarState] = useState<boolean>(false);
  const [message, setMessage] = useState('');
  const [intervalId, setIntervalId] = useState(-1 as unknown);
  const [isGenerating, setIsGenerating] = useState(false);
  const [snackbarColor, setSnackbarColor] = useState('success' as AlertColor);
  const dispatch = useDispatch();

  const setSnackBar = (color: AlertColor, text: string) => {
    setSnackbarState(true);
    setMessage(text);
    setSnackbarColor(color);
  };

  useEffect(() => {
    setIsGenerating(reportGeneration.isGenerating);
    if (reportGeneration?.isGenerating === true) {
      dispatch(getReportStatus(reportGeneration));
      setSnackBar('success', 'Report is currently being generated. You will be notified when it is completed.');
      const interval = setInterval(function () {
        dispatch(getReportStatus(reportGeneration));
      }, 10 * 1000); //poll every 10 seconds

      setIntervalId(interval);

      setTimeout(function () {
        if (isGenerating) {
          clearInterval(interval);
          setSnackBar('error', 'Report timed-out.');
        }
      }, 10 * 60 * 1000); //clear interval after 10 minutes
    }
    if (reportGeneration?.isGenerating === false && intervalId !== -1) clearInterval(intervalId as NodeJS.Timeout);
  }, [reportGeneration?.isGenerating]);

  useEffect(() => {
    const key = reportGeneration.key;
    if (!key || key === null || key === '') return;
    if (!reportGeneration.isExpired) dispatch(fetchClientReport(reportGeneration));
    clearInterval(intervalId as NodeJS.Timeout);
  }, [reportGeneration.key]);

  useEffect(() => {
    if (reportGeneration.isGenerated) {
      setSnackBar('success', 'Report is successfully generated.');
    }
  }, [reportGeneration.isGenerated]);

  return (
    <Fragment>
      <WO2Snackbar
        severity={snackbarColor}
        MUISnackbarProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
          autoHideDuration: 5000,
          open: displaySnackbar,
          onClose: (e, reason) => {
            if (reason === 'clickaway') {
              setSnackbarState(true);
              return;
            }
            setSnackbarState(false);
          },
          message: message,
          disableWindowBlurListener: true,
        }}
      />
    </Fragment>
  );
};
