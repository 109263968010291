import { combineReducers } from 'redux';
import { beneficiariesSlice } from '../beneficaries/store/slice';
import { BeneficiariesState } from '../beneficaries/store/types';
import { benefitStatementSlice } from '../benefitStatement/store/slice';
import { BenefitStatementState } from '../benefitStatement/store/types';
import { contributionDetailsSlice } from '../contributions/store/slice';
import { ContributionDetailsState } from '../contributions/store/types';
import { insuranceSlice } from '../insuranceDetail/store/slice';
import { InsuranceState } from '../insuranceDetail/store/types';
import { pensionDetailSlice } from '../pensionDetails/store/slice';
import { PensionDetailStatement } from '../pensionDetails/store/types';
import { transactionSlice } from '../Transactions/store/slice';
import { SSTransactionsState } from '../Transactions/store/types';

export interface SuperSimplifierState {
  beneficiaries: BeneficiariesState;
  benefitStatement: BenefitStatementState;
  pensionDetail: PensionDetailStatement;
  insuranceDetail: InsuranceState;
  contributionDetails: ContributionDetailsState;
  transactions: SSTransactionsState;
}

export const superSimplifierReducer = combineReducers<SuperSimplifierState>({
  insuranceDetail: insuranceSlice.reducer,
  beneficiaries: beneficiariesSlice.reducer,
  benefitStatement: benefitStatementSlice.reducer,
  pensionDetail: pensionDetailSlice.reducer,
  contributionDetails: contributionDetailsSlice.reducer,
  transactions: transactionSlice.reducer,
});

export const initialState: SuperSimplifierState = {
  insuranceDetail: insuranceSlice.getInitialState(),
  beneficiaries: beneficiariesSlice.getInitialState(),
  benefitStatement: benefitStatementSlice.getInitialState(),
  pensionDetail: pensionDetailSlice.getInitialState(),
  contributionDetails: contributionDetailsSlice.getInitialState(),
  transactions: transactionSlice.getInitialState(),
};
