import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReportTemplate, ReportTemplateState } from './types';
import { fetchReportTemplates, saveReportTemplateFile } from './thunks';

export const initialState: ReportTemplateState = {
  templates: [],
};

export const reportsSlice = createSlice({
  name: '@@client/templates/reports',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchReportTemplates.fulfilled, (state, action: PayloadAction<ReportTemplate[]>) => {
      state.templates = action.payload;
    });
    builder.addCase(saveReportTemplateFile.fulfilled, (state, action: PayloadAction<ReportTemplate>) => {
      const templateIndex = state.templates.findIndex((template) => template.id === action.payload.id);
      if (templateIndex !== -1) {
        state.templates[templateIndex] = action.payload;
      } else {
        state.templates.push(action.payload);
      }
    });
  },
});
