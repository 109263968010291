import { createAsyncThunk } from '@reduxjs/toolkit';
import { FetchConstraintsPayload, SecurityConstraint, UpdateConstraintsPayload } from '.';
import api from '../../../../../../../app/api';
import { buildEncodedQueryString } from '../../../../../../../common';
import { commonSlice, fetchInvestmentServiceVersionsByClient } from '../../../store/common';

export enum ConstraintsActionTypes {
  FetchInvestmentServiceConstraintsAndExcludeRebalance = '@@client/investmentServices/GetSecuritiesConstraintAndExcludeRebalance/Fetch',
  CreateOrUpdateInvestmentServiceConstraintsAndExcludeRebalance = '@@client/investmentServices/CreateOrUpdateSecuritiesConstraintAndExcludeRebalance/Update',
}

export enum ConstraintsApiEndpoints {
  FetchInvestmentServiceConstraintsAndExcludeRebalance  = '/investmentservices/GetSecuritiesConstraintAndExcludeRebalance',
  UpdateInvestmentServiceConstraintsAndExcludeRebalance = '/investmentservices/CreateOrUpdateSecuritiesConstraintAndExcludeRebalance',
}

export const fetchConstraintsAndExcludeRebalance = createAsyncThunk(
  ConstraintsActionTypes.FetchInvestmentServiceConstraintsAndExcludeRebalance,
  async ({ investmentServiceVersionId, investmentServiceId, clientId }: FetchConstraintsPayload) => {
    const queryString = buildEncodedQueryString({
      clientId,
      investmentServiceId,
      investmentServiceVersionId,
    });

    const response = await api.get<SecurityConstraint[]>(`${ConstraintsApiEndpoints.FetchInvestmentServiceConstraintsAndExcludeRebalance}${queryString}`);
    
    return response.data;
  }
);

export const saveConstraintsAndExcludeRebalance = createAsyncThunk(
  ConstraintsActionTypes.CreateOrUpdateInvestmentServiceConstraintsAndExcludeRebalance,
  async (payload: UpdateConstraintsPayload, thunkApi) => {

    const response = await api.post<number>(`${ConstraintsApiEndpoints.UpdateInvestmentServiceConstraintsAndExcludeRebalance}`, payload);

    thunkApi.dispatch(commonSlice.actions.setInvestmentServiceVersionId(response.data));

    await thunkApi.dispatch(fetchConstraintsAndExcludeRebalance({ investmentServiceVersionId: payload.investmentServiceVersionId,
                                               clientId: payload.clientId,
                                               investmentServiceId: payload.investmentServiceId}));
    await thunkApi.dispatch(
      fetchInvestmentServiceVersionsByClient({
        clientId: payload.clientId,
        showLatestVersionOnly: false,
      })
    );

    return { message: 'Constraints saved' };
  }
);
