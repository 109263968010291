import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PagedRequest } from '../../../../store';
import { fetchFxList } from './thunks';
import { fetchPagedResults, FxItem, FxListState } from './types';

export const initialState: FxListState = {
  parameters: {
    currencyCodeOrNameSearch: '',
    pagination: {
      pageNumber: 1,
      pageSize: 25,
      queryFields: [
        {
          fieldName: "currencyPair",
          hasSearchTerm: false,
          searchTerm: "",
          isSortTerm: true,
          descendingSortDirection: false,
          operator: ""
        }
      ],
    },
  },
  forexCurrencyPairList: null,
};

export const fxListSlice = createSlice({
  name: '@@forexrates/list',
  initialState,
  reducers: {
    setSearchStringParameter: (state, action: PayloadAction<string | null>) => {
      state.parameters.currencyCodeOrNameSearch = action.payload;
    },
    setPaginationParameter: (state, action: PayloadAction<PagedRequest>) => {
      state.parameters.pagination = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFxList.fulfilled, (state, action: PayloadAction<fetchPagedResults<FxItem>>) => {
      state.forexCurrencyPairList = action.payload.results;
      state.parameters.pagination = action.payload.pagination;
    });
  },
});
