import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';
import { DateRange } from '../../../../store';
import { createRate, deleteRate, fetchFxDailyRates, updateRate } from './thunks';
import { fetchPagedResults, FxDailyRateItem, FxPriceState } from './types';

export const initialState: FxPriceState = {
  parameters: {
    dates: { dateFrom: DateTime.now().minus({ years: 1 }).toISODate(), dateTo: DateTime.now().toISODate() },
    pagination: {
      pageNumber: 1,
      pageSize: 25,
      queryFields: [
        {
          fieldName: 'date',
          descendingSortDirection: true,
          operator: '',
          hasSearchTerm: false,
          isSortTerm: true,
          searchTerm: '',
        }
      ],
    },
    baseCurrencyId: 5,
    quoteCurrencyId: 5
  },
  fxDailyRates: {
    pageNumber: 1,
    pageSize: 25,
    totalNumberOfPages: 100,
    totalNumberOfRecords: 10000,
    results: [],
  },
  editId: null
};

export const fxPriceSlice = createSlice({
  name: '@@fx/dailyrate',
  initialState,
  reducers: {
    setDateParameter: (state: FxPriceState, action: PayloadAction<DateRange>) => {
      state.parameters.dates = action.payload;
    },
    setEditId: (state: FxPriceState, action: PayloadAction<number | null | undefined>) => {
      state.editId = action.payload;
      // if we have a 'Create new item' row (id = null), then remove it.
      if (!state.fxDailyRates) {
        state.fxDailyRates = {
          pageNumber: 0,
          pageSize: 25,
          totalNumberOfPages: 0,
          totalNumberOfRecords: 0,
          results: []
        }
      }
      state.fxDailyRates.results = state.fxDailyRates?.results.filter((p) => !!p.id);
    },
    setAddRate: (state: FxPriceState, action: PayloadAction<FxDailyRateItem>) => {
      state.editId = 0;
      if (!state.fxDailyRates) {
        state.fxDailyRates = {
          pageNumber: 0,
          pageSize: 25,
          totalNumberOfPages: 0,
          totalNumberOfRecords: 0,
          results: []
        }
      }
      state.fxDailyRates.results = [action.payload, ...state.fxDailyRates.results];
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFxDailyRates.fulfilled, (state, action: PayloadAction<fetchPagedResults<FxDailyRateItem>>) => {
      state.fxDailyRates = action.payload.results;
      state.parameters.pagination = action.payload.pagination;
    });
    builder.addCase(createRate.fulfilled, (state) => {
      state.editId = null;
    });
    builder.addCase(createRate.rejected, (state) => {
      state.editId = null;
      if (!state.fxDailyRates) {
        state.fxDailyRates = {
          pageNumber: 0,
          pageSize: 25,
          totalNumberOfPages: 0,
          totalNumberOfRecords: 0,
          results: []
        }
      }
      state.fxDailyRates.results = state.fxDailyRates?.results.filter((p) => !!p.id);

    });
    builder.addCase(updateRate.fulfilled, (state,) => {
      state.editId = null;
    });
    builder.addCase(deleteRate.fulfilled, (state) => {
      // const index = state.fxDailyRates?.results.findIndex((i) => i.id === action.payload.id);
      // if (index ?? -1 >= 0) {
      //   state.fxDailyRates?.results.splice(index ?? 0, 1);
      // }
      state.editId = null;
    });
  },
});



