import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';
import { MdaReportTypes } from 'src/common/types';
import { SystemMinimumDate } from '../../../../../common';
import { DateRange } from '../../../../../store';
import { ClientPortfolio, MainDetails } from '../../common/store/types';
import { convertBase64StringToFile } from './../../../../../common/utils/fileDownload';
import {
  fetchClientAccount,
  fetchClientReport,
  fetchReportParametersList,
  getCGTRecordsForClient,
  getReportParametersById,
  getReportStatus,
  initiateReportGeneration,
  saveReportParameters,
} from './thunks';
import {
  CGTRecord,
  ClientReportsState,
  FilterByTransactions,
  GroupByPortfolioValuation,
  HideByPortfolioValuation,
  HidingOptions,
  HidingOptionTransactions,
  ReportGeneration,
  ReportingParameter,
  ReportingParameters,
  ReportToSort,
  SavedParameter,
  SecondarySortByIncomeReceived,
  SortByIncomeReceived,
  SortByPortfolioValuation,
  SortByTransactions,
} from './types';

export const initialState: ClientReportsState = {
  parameters: {
    clientName: '',
    inceptionDate: '',
    dates: { dateFrom: DateTime.fromISO(SystemMinimumDate).toISODate(), dateTo: DateTime.now().toISODate() },
    isDataFromTriumph: false,
    mergeReports: false,
    reportsToSort: [],
    showItemOrdering: false,
    includeLogo: true,
    reportingParameter: {
      visibleToAfsl: false,
      isSaveReportParameters: false,
      parameterName: '',
    },
    includeCoverLetter: false,
    includeAppendix: false,
    coverLetter: undefined,
    appendix: undefined,
    selectedReports: {
      incomeReceived: {
        dates: {
          dateFrom: DateTime.fromISO(SystemMinimumDate).toISODate(),
          dateTo: DateTime.now().toISODate(),
        },
        selected: false,
        sortBy: SortByIncomeReceived.AccountFullInformation,
        secondarySortBy: SecondarySortByIncomeReceived.DateAscending,
        filter: {
          excludeCash: false,
        },
      },
      transactions: {
        dates: {
          dateFrom: DateTime.fromISO(SystemMinimumDate).toISODate(),
          dateTo: DateTime.now().toISODate(),
        },
        selected: false,
        hidingOptions: {
          assetDescription: true,
          accounts: true,
          summary: false,
        },
        filter: {
          excludeCash: false,
          includeDistribution: false,
        },
        sortBy: SortByTransactions.account,
      },
      cashTransactions: {
        dates: {
          dateFrom: DateTime.fromISO(SystemMinimumDate).toISODate(),
          dateTo: DateTime.now().toISODate(),
        },
        selected: false,
      },
      expenses: {
        dates: {
          dateFrom: DateTime.fromISO(SystemMinimumDate).toISODate(),
          dateTo: DateTime.now().toISODate(),
        },
        selected: false,
        hidingOptions: HidingOptions.selected,
      },
      gainsLossesUnrealised: {
        selected: false,
        dates: {
          dateFrom: DateTime.fromISO(SystemMinimumDate).toISODate(),
          dateTo: DateTime.now().toISODate(),
        },
      },
      capitalGainsAndLossesRealised: {
        selected: false,
        dates: {
          dateFrom: DateTime.fromISO(SystemMinimumDate).toISODate(),
          dateTo: DateTime.now().toISODate(),
        },
        finaliseFinancialYear: false,
        financialYearCode: '',
      },
      capitalGainsAndLossesUnrealised: {
        selected: false,
        dates: {
          dateFrom: DateTime.fromISO(SystemMinimumDate).toISODate(),
          dateTo: DateTime.now().toISODate(),
        },
      },
      gainsLossesRealised: {
        dates: {
          dateFrom: DateTime.fromISO(SystemMinimumDate).toISODate(),
          dateTo: DateTime.now().toISODate(),
        },
        selected: false,
      },
      securityPerformance: {
        dates: {
          dateFrom: DateTime.fromISO(SystemMinimumDate).toISODate(),
          dateTo: DateTime.now().toISODate(),
        },
        selected: false,
      },
      portfolioValuation: {
        dates: {
          dateFrom: DateTime.fromISO(SystemMinimumDate).toISODate(),
          dateTo: DateTime.now().toISODate(),
        },
        selected: false,
        hidingOptions: {
          securityType: true,
          securityCode: true,
          unrealisedGain: true,
          gics: true,
          costBase: true,
          investmentService: true,
          market: true,
          securityName: true,
          avgUnitCost: true,
        },
        sortBy: SortByPortfolioValuation.SecurityType,
        groupBy: GroupByPortfolioValuation.AssetClass,
        portfolioIds: [],
      },
      assetAllocation: {
        dates: {
          dateFrom: DateTime.fromISO(SystemMinimumDate).toISODate(),
          dateTo: DateTime.now().toISODate(),
        },
        selected: false,
        investmentPrograms: [],
      },
    },
    selectedParameter: undefined,
  },
  reportGeneration: {
    isGenerating: false,
    isExpired: false,
    requestId: '',
    isDataFromTriumph: false,
    isMergeReport: false,
    key: '',
    clientId: 0,
    clientName: '',
    isGenerated: false,
    mergeReportOrder: [],
    dateRange: '',
    reportName: '',
  },
  savedParametersList: [],
  clientFinancialYears: [],
};

const blankcgtRecord: CGTRecord = {
  fyDescription: '',
  currentYear: false,
  statusDescription: '',
  strategyDescription: '',
  externalCarryForwardLoss: 0.0,
  platformCarryForwardLoss: 0.0,
  finalisedDate: undefined,
};

export const reportsSlice = createSlice({
  name: '@@client/reports',
  initialState,
  reducers: {
    setDateParameter: (state, action: PayloadAction<DateRange>) => {
      state.parameters.dates = action.payload;
      state.parameters.selectedReports.assetAllocation.dates = action.payload;
      state.parameters.selectedReports.gainsLossesUnrealised.dates = action.payload;
      state.parameters.selectedReports.portfolioValuation.dates = action.payload;
      state.parameters.selectedReports.incomeReceived.dates = action.payload;
      state.parameters.selectedReports.cashTransactions.dates = action.payload;
      state.parameters.selectedReports.expenses.dates = action.payload;
      state.parameters.selectedReports.gainsLossesRealised.dates = action.payload;
      state.parameters.selectedReports.capitalGainsAndLossesRealised.dates = action.payload;
      state.parameters.selectedReports.capitalGainsAndLossesUnrealised.dates = action.payload;
      state.parameters.selectedReports.securityPerformance.dates = action.payload;
      state.parameters.selectedReports.transactions.dates = action.payload;
    },
    setUseTriumphAsDataSourceParameter: (state, action: PayloadAction<boolean>) => {
      state.parameters.isDataFromTriumph = action.payload;
    },
    setMergeReportsParameter: (state, action: PayloadAction<boolean>) => {
      state.parameters.mergeReports = action.payload;
    },
    setIncludeLogoParameter: (state, action: PayloadAction<boolean>) => {
      state.parameters.includeLogo = action.payload;
    },
    setIncludeCoverLetterParameter: (state, action: PayloadAction<boolean>) => {
      state.parameters.includeCoverLetter = action.payload;
      if (!action.payload) state.parameters.coverLetter = undefined;
    },
    setIncludeAppendixParameter: (state, action: PayloadAction<boolean>) => {
      state.parameters.includeAppendix = action.payload;
      if (!action.payload) state.parameters.appendix = undefined;
    },
    setCashTransactionsSelectedParameter: (state, action: PayloadAction<boolean>) => {
      state.parameters.selectedReports.cashTransactions.selected = action.payload;
    },
    setExpensesSelectedParameter: (state, action: PayloadAction<boolean>) => {
      state.parameters.selectedReports.expenses.selected = action.payload;
    },
    setExpensesHidingOptionsParameter: (state, action: PayloadAction<HidingOptions>) => {
      state.parameters.selectedReports.expenses.hidingOptions = action.payload;
    },
    setGainsLossesUnrealisedSelectedParameter: (state, action: PayloadAction<boolean>) => {
      state.parameters.selectedReports.gainsLossesUnrealised.selected = action.payload;
    },
    setGainsLossesRealisedSelectedParameter: (state, action: PayloadAction<boolean>) => {
      state.parameters.selectedReports.gainsLossesRealised.selected = action.payload;
    },
    setCapitalGainsAndLossesRealisedSelectedParameter: (state, action: PayloadAction<boolean>) => {
      state.parameters.selectedReports.capitalGainsAndLossesRealised.selected = action.payload;
    },
    setCapitalGainsAndLossesUnrealisedSelectedParameter: (state, action: PayloadAction<boolean>) => {
      state.parameters.selectedReports.capitalGainsAndLossesUnrealised.selected = action.payload;
    },
    setSecurityPerformanceSelectedParameter: (state, action: PayloadAction<boolean>) => {
      state.parameters.selectedReports.securityPerformance.selected = action.payload;
    },
    setTransactionsSelectedParameter: (state, action: PayloadAction<boolean>) => {
      state.parameters.selectedReports.transactions.selected = action.payload;
    },
    setTransactionsAssetDescriptionHidingOptionsParameter: (state, action: PayloadAction<boolean>) => {
      state.parameters.selectedReports.transactions.hidingOptions.assetDescription = action.payload;
    },
    setTransactionsAccountsHidingOptionsParameter: (state, action: PayloadAction<boolean>) => {
      state.parameters.selectedReports.transactions.hidingOptions.accounts = action.payload;
    },
    setTransactionsSummaryHidingOptionsParameter: (state, action: PayloadAction<boolean>) => {
      state.parameters.selectedReports.transactions.hidingOptions.summary = action.payload;
    },
    setTransactionsExcludeCashFilterOptionsParameter: (state, action: PayloadAction<boolean>) => {
      state.parameters.selectedReports.transactions.filter.excludeCash = action.payload;
    },
    setTransactionsIncludeDistributionFilterOptionsParameter: (state, action: PayloadAction<boolean>) => {
      state.parameters.selectedReports.transactions.filter.includeDistribution = action.payload;
    },
    setTransactionsSortByParameter: (state, action: PayloadAction<SortByTransactions>) => {
      state.parameters.selectedReports.transactions.sortBy = action.payload;
      if (action.payload === SortByTransactions.account) {
        state.parameters.selectedReports.transactions.hidingOptions.accounts = true;
      }
    },
    setIncomeReceivedSelectedParameter: (state, action: PayloadAction<boolean>) => {
      state.parameters.selectedReports.incomeReceived.selected = action.payload;
    },
    setIncomeReceivedExcludeCashFilterOptionsParameter: (state, action: PayloadAction<boolean>) => {
      state.parameters.selectedReports.incomeReceived.filter.excludeCash = action.payload;
    },
    setIncomeReceivedSortByParameter: (state, action: PayloadAction<SortByIncomeReceived>) => {
      state.parameters.selectedReports.incomeReceived.sortBy = action.payload;
    },
    setIncomeReceivedSecondarySortByParameter: (state, action: PayloadAction<SecondarySortByIncomeReceived>) => {
      state.parameters.selectedReports.incomeReceived.secondarySortBy = action.payload;
    },
    setPortfolioValuationSelectedParameter: (state, action: PayloadAction<boolean>) => {
      state.parameters.selectedReports.portfolioValuation.selected = action.payload;
    },
    setPortfolioValuationSortByParameter: (state, action: PayloadAction<SortByPortfolioValuation>) => {
      state.parameters.selectedReports.portfolioValuation.sortBy = action.payload;
      if (action.payload === SortByPortfolioValuation.Gics) {
        state.parameters.selectedReports.portfolioValuation.hidingOptions.gics = false;
      } else if (action.payload === SortByPortfolioValuation.SecurityType) {
        state.parameters.selectedReports.portfolioValuation.hidingOptions.securityType = false;
      } else if (action.payload === SortByPortfolioValuation.Portfolio) {
        state.parameters.selectedReports.portfolioValuation.hidingOptions.investmentService = false;
      }
    },
    setPortfolioValuationGroupByParameter: (state, action: PayloadAction<GroupByPortfolioValuation>) => {
      state.parameters.selectedReports.portfolioValuation.groupBy = action.payload;
      if (action.payload === GroupByPortfolioValuation.Gics) {
        state.parameters.selectedReports.portfolioValuation.hidingOptions.gics = false;
      } else if (action.payload === GroupByPortfolioValuation.InvestmentService) {
        state.parameters.selectedReports.portfolioValuation.hidingOptions.investmentService = false;
      }
    },
    setPortfolioValuationSecurityTypeHidingOptionsParameter: (state, action: PayloadAction<boolean>) => {
      state.parameters.selectedReports.portfolioValuation.hidingOptions.securityType = action.payload;
    },
    setPortfolioValuationSecurityCodeHidingOptionsParameter: (state, action: PayloadAction<boolean>) => {
      state.parameters.selectedReports.portfolioValuation.hidingOptions.securityCode = action.payload;
    },
    setPortfolioValuationUnrealisedGainHidingOptionsParameter: (state, action: PayloadAction<boolean>) => {
      state.parameters.selectedReports.portfolioValuation.hidingOptions.unrealisedGain = action.payload;
      state.parameters.selectedReports.portfolioValuation.hidingOptions.avgUnitCost = action.payload;
    },
    setPortfolioValuationGicsHidingOptionsParameter: (state, action: PayloadAction<boolean>) => {
      state.parameters.selectedReports.portfolioValuation.hidingOptions.gics = action.payload;
    },
    setPortfolioValuationCostBaseHidingOptionsParameter: (state, action: PayloadAction<boolean>) => {
      state.parameters.selectedReports.portfolioValuation.hidingOptions.costBase = action.payload;
    },
    setPortfolioValuationInvestmentServiceHidingOptionsParameter: (state, action: PayloadAction<boolean>) => {
      state.parameters.selectedReports.portfolioValuation.hidingOptions.investmentService = action.payload;
    },
    setPortfolioValuationMarketHidingOptionsParameter: (state, action: PayloadAction<boolean>) => {
      state.parameters.selectedReports.portfolioValuation.hidingOptions.market = action.payload;
    },
    setPortfolioValuationSecurityNameHidingOptionsParameter: (state, action: PayloadAction<boolean>) => {
      state.parameters.selectedReports.portfolioValuation.hidingOptions.securityName = action.payload;
    },
    setPortfolioValuationAvgUnitCostHidingOptionsParameter: (state, action: PayloadAction<boolean>) => {
      state.parameters.selectedReports.portfolioValuation.hidingOptions.avgUnitCost = action.payload;
      state.parameters.selectedReports.portfolioValuation.hidingOptions.unrealisedGain = action.payload;
    },
    setAssetAllocationSelectedParameter: (state, action: PayloadAction<boolean>) => {
      state.parameters.selectedReports.assetAllocation.selected = action.payload;
    },
    setAssetAllocationInvestmentProgramsParameter: (state, action: PayloadAction<ClientPortfolio[]>) => {
      state.parameters.selectedReports.assetAllocation.investmentPrograms = action.payload;
    },
    setPortfolioValuationInvestmentProgramsParameter: (state, action: PayloadAction<ClientPortfolio[]>) => {
      state.parameters.selectedReports.portfolioValuation.portfolioIds = action.payload;
    },
    setSelectedReportOrderParameter: (state, action: PayloadAction<ReportToSort[]>) => {
      state.parameters.reportsToSort = action.payload;
    },
    setShowItemOderingModalParameter: (state, action: PayloadAction<boolean>) => {
      state.parameters.showItemOrdering = action.payload;
    },
    setAsAtDateAssetAllocationParameter: (state, action: PayloadAction<DateRange>) => {
      state.parameters.selectedReports.assetAllocation.dates = action.payload;
    },
    setAsAtDateGainsLossesUnrealisedParameter: (state, action: PayloadAction<DateRange>) => {
      state.parameters.selectedReports.gainsLossesUnrealised.dates = action.payload;
    },
    setAsAtDateCapitalGainsAndLossesUnrealisedParameter: (state, action: PayloadAction<DateRange>) => {
      state.parameters.selectedReports.capitalGainsAndLossesUnrealised.dates = action.payload;
    },
    setReportDatePortfolioValuationParameter: (state, action: PayloadAction<DateRange>) => {
      state.parameters.selectedReports.portfolioValuation.dates = action.payload;
    },
    setDateRangeIncomeReceivedParameter: (state, action: PayloadAction<DateRange>) => {
      state.parameters.selectedReports.incomeReceived.dates = action.payload;
    },
    setDateRangeCashTransactionsParameter: (state, action: PayloadAction<DateRange>) => {
      state.parameters.selectedReports.cashTransactions.dates = action.payload;
    },
    setDateRangeExpensesParameter: (state, action: PayloadAction<DateRange>) => {
      state.parameters.selectedReports.expenses.dates = action.payload;
    },
    setDateRangeGainsLossesRealisedParameter: (state, action: PayloadAction<DateRange>) => {
      state.parameters.selectedReports.gainsLossesRealised.dates = action.payload;
    },
    setDateRangeCapitalGainsAndLossesRealisedParameter: (state, action: PayloadAction<DateRange>) => {
      state.parameters.selectedReports.capitalGainsAndLossesRealised.dates = action.payload;
    },
    setFinalisedFinancialYearCapitalGainsAndLossesRealisedParameter: (state, action: PayloadAction<boolean>) => {
      state.parameters.selectedReports.capitalGainsAndLossesRealised.finaliseFinancialYear = action.payload;
    },
    setFinancialYearCapitalGainsAndLossesRealisedParameter: (state, action: PayloadAction<string>) => {
      state.parameters.selectedReports.capitalGainsAndLossesRealised.financialYearCode = action.payload;
    },
    setDateRangeSecurityPerformanceParameter: (state, action: PayloadAction<DateRange>) => {
      state.parameters.selectedReports.securityPerformance.dates = action.payload;
    },
    setDateRangeTransactionsParameter: (state, action: PayloadAction<DateRange>) => {
      state.parameters.selectedReports.transactions.dates = action.payload;
    },
    setCoverLetterParameter: (state, action: PayloadAction<File>) => {
      state.parameters.coverLetter = action.payload;
    },
    setAppendixParameter: (state, action: PayloadAction<File>) => {
      state.parameters.appendix = action.payload;
    },
    setReportGeneration: (state, action: PayloadAction<ReportGeneration>) => {
      state.reportGeneration = {
        ...state.reportGeneration,
        isGenerating: action.payload.isGenerating,
        requestId: action.payload.requestId,
        isExpired: action.payload.isExpired,
        isDataFromTriumph: action.payload.isDataFromTriumph,
        isMergeReport: action.payload.isMergeReport,
        key: action.payload.key,
        clientId: action.payload.clientId,
        clientName: action.payload.clientName,
        mergeReportOrder: action.payload.mergeReportOrder,
        dateRange: action.payload.dateRange,
        reportName: action.payload.reportName,
      };
    },
    setSelectedReportingParameter: (state, action: PayloadAction<SavedParameter | null>) => {
      state.parameters.selectedParameter = action.payload;
      if (action.payload === null) {
        state.parameters = initialState.parameters;
      }
    },
    setReportingParameter: (state, action: PayloadAction<ReportingParameter>) => {
      state.parameters.reportingParameter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchClientReport.fulfilled, (state, action: PayloadAction<string>) => {
      if (action.payload) {
        state.reportResult = null;
        state.reportGeneration.key = '';
        state.reportGeneration.isGenerated = true;
        state.reportGeneration.isGenerating = false;
      }
    });
    builder.addCase(fetchClientAccount.fulfilled, (state, action: PayloadAction<MainDetails>) => {
      state.parameters.inceptionDate = action.payload.inceptionDate ?? '';
      state.parameters.clientName = action.payload.name ?? '';
    });
    builder.addCase(initiateReportGeneration.pending, (state) => {
      state.reportGeneration.isGenerating = false;
      state.reportGeneration.isGenerated = false;
    });
    builder.addCase(initiateReportGeneration.fulfilled, (state, action: PayloadAction<string>) => {
      if (action.payload) {
        state.reportGeneration.requestId = action.payload;
        state.reportGeneration.isGenerating = true;
        state.reportGeneration.isGenerated = false;
      }
    });
    builder.addCase(getCGTRecordsForClient.fulfilled, (state, action: PayloadAction<CGTRecord[]>) => {
      if (action.payload) {
        state.clientFinancialYears = action.payload;
        state.clientFinancialYears.splice(0, 0, blankcgtRecord);
      }
    });
    builder.addCase(getReportStatus.fulfilled, (state, action: PayloadAction<ReportGeneration>) => {
      if (action.payload) {
        state.reportGeneration.isExpired = action.payload.isExpired;
        state.reportGeneration.key = action.payload.key;
        if (action.payload.key !== '' && action.payload.key !== null) state.reportGeneration.isGenerating = false;
      }
    });
    builder.addCase(fetchReportParametersList.fulfilled, (state, action: PayloadAction<SavedParameter[]>) => {
      state.savedParametersList = action.payload;
      if (state.parameters.selectedParameter === undefined) return;
      if (action.payload.filter((x) => x.id === state.parameters.selectedParameter?.id).length === 0) {
        state.parameters.selectedParameter = undefined;
      }
    });
    builder.addCase(saveReportParameters.fulfilled, (state, action: PayloadAction<string>) => {
      if (action.payload) {
        const parameter = {
          id: action.payload,
          name: state.parameters.reportingParameter?.parameterName,
        };

        state.savedParametersList.push(parameter);
        state.parameters.selectedParameter = parameter;
      }
    });
    builder.addCase(getReportParametersById.fulfilled, (state, action: PayloadAction<ReportingParameters>) => {
      state.parameters = {
        ...state.parameters,
        includeCoverLetter: initialState.parameters.includeCoverLetter,
        includeAppendix: initialState.parameters.includeAppendix,
        coverLetter: initialState.parameters.coverLetter,
        appendix: initialState.parameters.appendix,
      };

      state.parameters.selectedReports.assetAllocation.selected = false;
      state.parameters.selectedReports.cashTransactions.selected = false;
      state.parameters.selectedReports.expenses.selected = false;
      state.parameters.selectedReports.gainsLossesRealised.selected = false;
      state.parameters.selectedReports.capitalGainsAndLossesRealised.selected = false;
      state.parameters.selectedReports.capitalGainsAndLossesUnrealised.selected = false;
      state.parameters.selectedReports.gainsLossesUnrealised.selected = false;
      state.parameters.selectedReports.incomeReceived.selected = false;
      state.parameters.selectedReports.portfolioValuation.selected = false;
      state.parameters.selectedReports.securityPerformance.selected = false;
      state.parameters.selectedReports.transactions.selected = false;

      state.parameters.dates = {
        dateFrom: action.payload.fromDate,
        dateTo: action.payload.toDate,
        dateRangeType: action.payload.dateRangeType,
      };

      state.parameters.includeLogo = action.payload.includeLogo;
      state.parameters.reportsToSort = [];
      state.parameters.includeCoverLetter = action.payload.additionalDocuments?.includeCoverLetter;
      if (
        !!action.payload.additionalDocuments?.includeCoverLetter &&
        !!action.payload.additionalDocuments?.coverLetter
      ) {
        state.parameters.coverLetter = convertBase64StringToFile(
          action.payload.additionalDocuments?.coverLetter,
          'Cover Letter',
          'application/pdf'
        );
      }

      state.parameters.includeAppendix = action.payload.additionalDocuments?.includeAppendix;
      if (!!action.payload.additionalDocuments?.includeAppendix && !!action.payload.additionalDocuments?.appendix) {
        state.parameters.appendix = convertBase64StringToFile(
          action.payload.additionalDocuments?.appendix,
          'Appendix',
          'application/pdf'
        );
      }

      if (action.payload.assetAllocation !== null && action.payload.assetAllocation !== undefined) {
        state.parameters.selectedReports.assetAllocation.selected = true;

        state.parameters.selectedReports.assetAllocation.dates = {
          dateFrom: action.payload.fromDate,
          dateTo: action.payload.assetAllocation?.asAtDate ?? action.payload.toDate,
          dateRangeType: action.payload.assetAllocation?.asAtDateType,
        };
        state.parameters.reportsToSort.push({
          id: MdaReportTypes.AssetAllocation.id,
          text: MdaReportTypes.AssetAllocation.name,
        });
      }

      if (action.payload.cashTransactions !== null && action.payload.cashTransactions !== undefined) {
        state.parameters.selectedReports.cashTransactions.selected = true;
        state.parameters.selectedReports.cashTransactions.dates = {
          dateFrom: action.payload.cashTransactions?.fromDate ?? action.payload.fromDate,
          dateTo: action.payload.cashTransactions?.toDate ?? action.payload.toDate,
          dateRangeType: action.payload.cashTransactions?.dateRangeType,
        };
        state.parameters.reportsToSort.push({
          id: MdaReportTypes.CashTransactions.id,
          text: MdaReportTypes.CashTransactions.name,
        });
      }

      if (action.payload.expenses !== null && action.payload.expenses !== undefined) {
        state.parameters.selectedReports.expenses.selected = true;
        state.parameters.selectedReports.expenses.hidingOptions =
          action.payload.expenses?.hidingOptions === HidingOptions.selected
            ? HidingOptions.selected
            : HidingOptions.notSelected;
        state.parameters.selectedReports.expenses.dates = {
          dateFrom: action.payload.expenses?.fromDate ?? action.payload.fromDate,
          dateTo: action.payload.expenses?.toDate ?? action.payload.toDate,
          dateRangeType: action.payload.expenses?.dateRangeType,
        };
        state.parameters.reportsToSort.push({
          id: MdaReportTypes.Expenses.id,
          text: MdaReportTypes.Expenses.name,
        });
      }

      if (action.payload.gainsLossesRealised !== null && action.payload.gainsLossesRealised !== undefined) {
        state.parameters.selectedReports.gainsLossesRealised.selected = true;

        state.parameters.selectedReports.gainsLossesRealised.dates = {
          dateFrom: action.payload.gainsLossesRealised?.fromDate ?? action.payload.fromDate,
          dateTo: action.payload.gainsLossesRealised?.toDate ?? action.payload.toDate,
          dateRangeType: action.payload.gainsLossesRealised?.dateRangeType,
        };
        state.parameters.reportsToSort.push({
          id: MdaReportTypes.GainsLossesRealised.id,
          text: MdaReportTypes.GainsLossesRealised.name,
        });
      }

      if (
        action.payload.capitalGainsAndLossesRealised !== null &&
        action.payload.capitalGainsAndLossesRealised !== undefined
      ) {
        state.parameters.selectedReports.capitalGainsAndLossesRealised.selected = true;

        state.parameters.selectedReports.capitalGainsAndLossesRealised.dates = {
          dateFrom: action.payload.capitalGainsAndLossesRealised?.fromDate ?? action.payload.fromDate,
          dateTo: action.payload.capitalGainsAndLossesRealised?.toDate ?? action.payload.toDate,
          dateRangeType: action.payload.capitalGainsAndLossesRealised?.dateRangeType,
        };

        state.parameters.selectedReports.capitalGainsAndLossesRealised.finaliseFinancialYear =
          action.payload.capitalGainsAndLossesRealised.finaliseFinancialYear;

        state.parameters.selectedReports.capitalGainsAndLossesRealised.financialYearCode =
          action.payload.capitalGainsAndLossesRealised.financialYearCode;

        state.parameters.reportsToSort.push({
          id: MdaReportTypes.CapitalGainsAndLossesRealised.id,
          text: MdaReportTypes.CapitalGainsAndLossesRealised.name,
        });
      }

      if (
        action.payload.capitalGainsAndLossesUnrealised !== null &&
        action.payload.capitalGainsAndLossesUnrealised !== undefined
      ) {
        state.parameters.selectedReports.capitalGainsAndLossesUnrealised.selected = true;

        state.parameters.selectedReports.capitalGainsAndLossesUnrealised.dates = {
          dateFrom: action.payload.fromDate,
          dateTo: action.payload.capitalGainsAndLossesUnrealised?.asAtDate ?? action.payload.toDate,
          dateRangeType: action.payload.capitalGainsAndLossesUnrealised?.asAtDateType,
        };

        state.parameters.reportsToSort.push({
          id: MdaReportTypes.CapitalGainsAndLossesUnrealised.id,
          text: MdaReportTypes.CapitalGainsAndLossesUnrealised.name,
        });
      }

      if (action.payload.gainsLossesUnrealised !== null && action.payload.gainsLossesUnrealised !== undefined) {
        state.parameters.selectedReports.gainsLossesUnrealised.selected = true;

        state.parameters.selectedReports.gainsLossesUnrealised.dates = {
          dateFrom: action.payload.fromDate,
          dateTo: action.payload.gainsLossesUnrealised?.asAtDate ?? action.payload.toDate,
          dateRangeType: action.payload.gainsLossesUnrealised?.asAtDateType,
        };
        state.parameters.reportsToSort.push({
          id: MdaReportTypes.GainsLossesUnrealised.id,
          text: MdaReportTypes.GainsLossesUnrealised.name,
        });
      }

      if (action.payload.incomeReceived !== null && action.payload.incomeReceived !== undefined) {
        state.parameters.selectedReports.incomeReceived.selected = true;

        state.parameters.selectedReports.incomeReceived.dates = {
          dateFrom: action.payload.incomeReceived?.fromDate ?? action.payload.fromDate,
          dateTo: action.payload.incomeReceived?.toDate ?? action.payload.toDate,
          dateRangeType: action.payload.incomeReceived?.dateRangeType,
        };

        state.parameters.selectedReports.incomeReceived.sortBy = action.payload.incomeReceived?.sortBy;
        state.parameters.selectedReports.incomeReceived.secondarySortBy = action.payload.incomeReceived?.thenSortBy;
        state.parameters.selectedReports.incomeReceived.filter.excludeCash =
          action.payload.incomeReceived?.filteringOptions?.indexOf('ExcludeCash') >= 0;
        state.parameters.reportsToSort.push({
          id: MdaReportTypes.IncomeReceived.id,
          text: MdaReportTypes.IncomeReceived.name,
        });
      }

      if (action.payload.portfolioValuation !== null && action.payload.portfolioValuation !== undefined) {
        state.parameters.selectedReports.portfolioValuation.selected = true;

        state.parameters.selectedReports.portfolioValuation.dates = {
          dateFrom: action.payload.fromDate,
          dateTo: action.payload.portfolioValuation?.asAtDate ?? action.payload.toDate,
          dateRangeType: action.payload.portfolioValuation?.asAtDateType,
        };

        if (
          action.payload.portfolioValuation?.hidingOptions !== null &&
          action.payload.portfolioValuation?.hidingOptions !== undefined
        ) {
          state.parameters.selectedReports.portfolioValuation.hidingOptions.securityType =
            action.payload.portfolioValuation?.hidingOptions?.indexOf(HideByPortfolioValuation.securityType) >= 0;
          state.parameters.selectedReports.portfolioValuation.hidingOptions.costBase =
            action.payload.portfolioValuation?.hidingOptions?.indexOf(HideByPortfolioValuation.costBase) >= 0;
          state.parameters.selectedReports.portfolioValuation.hidingOptions.gics =
            action.payload.portfolioValuation?.hidingOptions?.indexOf(HideByPortfolioValuation.Gics) >= 0;
          state.parameters.selectedReports.portfolioValuation.hidingOptions.market =
            action.payload.portfolioValuation?.hidingOptions?.indexOf(HideByPortfolioValuation.market) >= 0;
          state.parameters.selectedReports.portfolioValuation.hidingOptions.unrealisedGain =
            action.payload.portfolioValuation?.hidingOptions?.indexOf(HideByPortfolioValuation.unrealisedGain) >= 0;
          state.parameters.selectedReports.portfolioValuation.hidingOptions.investmentService =
            action.payload.portfolioValuation?.hidingOptions?.indexOf(HideByPortfolioValuation.portfolio) >= 0;
          state.parameters.selectedReports.portfolioValuation.hidingOptions.avgUnitCost =
            action.payload.portfolioValuation?.hidingOptions?.indexOf(HideByPortfolioValuation.avgUnitCost) >= 0;
        }
        state.parameters.selectedReports.portfolioValuation.sortBy = action.payload.portfolioValuation.sortBy;
        state.parameters.selectedReports.portfolioValuation.groupBy = action.payload.portfolioValuation?.groupBy;
        state.parameters.reportsToSort.push({
          id: MdaReportTypes.PortfolioValuation.id,
          text: MdaReportTypes.PortfolioValuation.name,
        });
      }

      if (action.payload.securityPerformance !== null && action.payload.securityPerformance !== undefined) {
        state.parameters.selectedReports.securityPerformance.selected = true;

        state.parameters.selectedReports.securityPerformance.dates = {
          dateFrom: action.payload.securityPerformance?.fromDate ?? action.payload.fromDate,
          dateTo: action.payload.securityPerformance?.toDate ?? action.payload.toDate,
          dateRangeType: action.payload.securityPerformance?.dateRangeType,
        };
        state.parameters.reportsToSort.push({
          id: MdaReportTypes.SecurityPerformance.id,
          text: MdaReportTypes.SecurityPerformance.name,
        });
      }

      if (action.payload.transactions !== null && action.payload.transactions !== undefined) {
        state.parameters.selectedReports.transactions.selected = true;

        state.parameters.selectedReports.transactions.dates = {
          dateFrom: action.payload.transactions?.fromDate ?? action.payload.fromDate,
          dateTo: action.payload.transactions?.toDate ?? action.payload.toDate,
          dateRangeType: action.payload.transactions?.dateRangeType,
        };
        if (
          action.payload.transactions?.hidingOptions !== null &&
          action.payload.transactions?.hidingOptions !== undefined
        ) {
          state.parameters.selectedReports.transactions.hidingOptions.accounts =
            action.payload.transactions?.hidingOptions?.indexOf(HidingOptionTransactions.accounts) >= 0;
          state.parameters.selectedReports.transactions.hidingOptions.assetDescription =
            action.payload.transactions?.hidingOptions?.indexOf(HidingOptionTransactions.assetDescription) >= 0;
          state.parameters.selectedReports.transactions.hidingOptions.summary =
            action.payload.transactions?.hidingOptions?.indexOf(HidingOptionTransactions.summary) >= 0;
        }

        if (
          action.payload.transactions?.filteringOptions !== null &&
          action.payload.transactions?.filteringOptions !== undefined
        ) {
          state.parameters.selectedReports.transactions.filter.excludeCash =
            action.payload.transactions?.filteringOptions?.indexOf(FilterByTransactions.excludeCash) >= 0;
          state.parameters.selectedReports.transactions.filter.includeDistribution =
            action.payload.transactions?.filteringOptions?.indexOf(FilterByTransactions.includeDistribution) >= 0;
        }
        state.parameters.selectedReports.transactions.sortBy = action.payload.transactions?.sortBy;
        state.parameters.reportsToSort.push({
          id: MdaReportTypes.Transactions.id,
          text: MdaReportTypes.Transactions.name,
        });
      }
      const isMergeReport =
        action.payload.mergeReportOrder.findIndex((x) => x == MdaReportTypes.MergeReport.id) > -1 &&
        state.parameters.reportsToSort.length > 1;
      state.parameters.mergeReports = isMergeReport;
      if (isMergeReport) {
        state.parameters.reportsToSort.sort((a, b) => {
          return action.payload.mergeReportOrder.indexOf(a.id) - action.payload.mergeReportOrder.indexOf(b.id);
        });
      }
    });
  },
});
