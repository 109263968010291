import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchAfsls, fetchTemplates } from './thunks';
import { Afsl, PortfoliosState, Template } from './types';

export const initialState: PortfoliosState = {
  afslId: undefined,
  afsls: [],
  templates: [],
};

export const portfoliosSlice = createSlice({
  name: '@@client/templates/portfolios',
  initialState,
  reducers: {
    setAfslId: (state, action: PayloadAction<number | undefined>) => {
      state.afslId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAfsls.fulfilled, (state, action: PayloadAction<Afsl[]>) => {
      state.afsls = action.payload.sort((a, b) => a.name.localeCompare(b.name));
      if (!state.afslId && state.afsls.length > 0) {
        state.afslId = state.afsls[0].id;
      }
    });
    builder.addCase(fetchTemplates.fulfilled, (state, action: PayloadAction<Template[]>) => {
      state.templates = action.payload;
    });
  },
});
