import { connect, ConnectedProps } from 'react-redux';
import { progressSelectorFactory } from 'src/common';
import { RootState } from 'src/store';
import { selectClientId } from '../../../common/store/selectors';
import { fetchClientMainDetails } from '../../../common/store/thunks';
import { cgtConfigurationSlice, fetchFinancialRecordsForClient } from '../store';
import { CGTConfigurationActionTypes } from '../store/thunks';
import { BasicDetails } from './components/basicDetails';
import { selectCGTStratigies, selectClientCGTRecords, selectCurrentMatchingStrategy } from './store/selectors';
import { BasicCGTConfigurationActionTypes, fetchCGTStrategies, updateParcelMatchingMethod } from './store/thunks';

const mapStateToProps = (state: RootState) => ({
  clientId: selectClientId(state),
  currentSelectMatchingStrategy: selectCurrentMatchingStrategy(state),
  cgtStrategies: selectCGTStratigies(state),
  clientCgtRecords: selectClientCGTRecords(state),
  fetchFinancialRecordsForClientLoadingProgress: progressSelectorFactory([
    CGTConfigurationActionTypes.FetchFinancialRecordsForClient,
  ])(state),
  fetchCGTStrategiesLoadingProgress: progressSelectorFactory([BasicCGTConfigurationActionTypes.FetchCGTStrategies])(
    state
  ),
  updateMatchingStrategyLoadingProgress: progressSelectorFactory([
    BasicCGTConfigurationActionTypes.UpdateParcelMatchingMethod,
  ])(state),
});

const mapDispatchToProps = {
  ...cgtConfigurationSlice.actions,
  fetchFinancialRecordsForClient,
  fetchCGTStrategies,
  updateParcelMatchingMethod,
  fetchClientMainDetails,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector>;
export const BasicDetailsContainer = connector(BasicDetails);
