import { EditOutlined, Link as AttachTrusteeIcon, LinkOff as DettachTrusteeIcon, MoreVertOutlined, PersonAdd } from '@mui/icons-material';
import { Avatar, IconButton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { AnyAction } from 'redux';
import { useConfirmation } from 'src/common/components/dialogs';
import { useDebounce } from 'src/common/hooks';
import { LoadingProgress } from 'src/common/store/types';
import { CorporateType } from 'src/features/clients/common/enums';
import { getAvatarText, WO2Menu } from '../../../../../../../common';
import { ClientSideDataTable } from '../../../../../../../common/components/dataTable/clientSide';
import { DatatableColumn, FilterDataType } from '../../../../../../../common/components/dataTable/types';
import history from '../../../../../../../history';
import { theme } from '../../../../../../../themes';
import { AttachableTrustee, AttachTrusteePayload, DetachTrusteePayload, TrusteeAbbreviated, TrusteesToAttachPayload } from '../../store/types';
import { AttachTrustee, AttachTrusteeDialogPayload } from './attachTrustee';

export interface ListProps {
  hasClientEditPermission: boolean;
  trustees: TrusteeAbbreviated[];
  fetchTrustees: (clientId: number) => void;
  detachTrustee: (payload: DetachTrusteePayload) => void;
  clientId: number;
  loadingTrusteesProgress: LoadingProgress;

  trusteesToAttach: AttachableTrustee[];
  loadingTrusteesToAttach: LoadingProgress;
  fetchTrusteesToAttach: (payload: TrusteesToAttachPayload) => void;
  attachTrustee: (payload: AttachTrusteePayload) => Promise<AnyAction>;
  savingAttachProgress: LoadingProgress;

  match: { url: string };
}

export const List = (props: ListProps): JSX.Element => {
  const {
    hasClientEditPermission,
    trustees,
    fetchTrustees,
    clientId,
    loadingTrusteesProgress,
    attachTrustee,
    savingAttachProgress,
    detachTrustee,

    trusteesToAttach,
    fetchTrusteesToAttach,
    loadingTrusteesToAttach,

    match: { url },
  } = props;

  const [attachTrusteeOpen, setAttachTrusteeOpen] = useState<boolean>(false);
  const [trusteesSearchText, setTrusteesSearchText] = useState<string>('');
  const onTrusteeSearchTextChange = useDebounce<string>(trusteesSearchText, 500);

  const routeMatch = useRouteMatch();
  const confirm = useConfirmation();
  const shouldDisableAddTrustee = trustees.length > 0;

  useEffect(() => {
    if (!!clientId) {
      fetchTrustees(clientId);
    }
  }, [fetchTrustees, clientId]);

  useEffect(() => {
    if (onTrusteeSearchTextChange && onTrusteeSearchTextChange.length > 2 && !!clientId) {
      fetchTrusteesToAttach({ clientId, searchText: onTrusteeSearchTextChange });
    }
  }, [onTrusteeSearchTextChange, fetchTrusteesToAttach, clientId]);

  const nameColumn = (dataIndex: number): React.ReactNode => (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        marginRight: '10px',
      }}
    >
      <Avatar style={{ backgroundColor: theme.palette.primary.main, marginRight: '10px' }}>
        <Typography variant="h5"> {getAvatarText(`${trustees[dataIndex]?.name}`)}</Typography>
      </Avatar>
      <Typography variant="h5">{trustees[dataIndex]?.name}</Typography>
    </div>
  );

  const corporateTypeColumn = (dataIndex: number): React.ReactNode => (
    <Typography variant="h5">{!!trustees[dataIndex]?.corporateTypeId && CorporateType.getById(trustees[dataIndex]?.corporateTypeId)?.displayName}</Typography>
  );

  const actionsColumn = (dataIndex: number): React.ReactNode => {
    return (
      <WO2Menu
        testId={`trusteeActions_${dataIndex}`}
        buttonTitle="Trustee Actions"
        buttonIcon={<MoreVertOutlined color={'primary'} />}
        items={[
          {
            icon: <EditOutlined />,
            label: 'Edit Trustee',
            onClick: () => {
              history.push(`${routeMatch.url}/trustee/${trustees[dataIndex]?.corporationId}`);
            },
            testId: `editTrustee_${dataIndex}`,
          },
          {
            icon: <DettachTrusteeIcon />,
            label: 'Detach Trustee',
            onClick: () => {
              confirm({
                title: 'Detach trustee',
                description: 'Are you sure you we to detach this trustee?',
              }).then(() => {
                if (!!trustees[dataIndex]?.corporationId && clientId !== null) {
                  detachTrustee({
                    clientId,
                    trusteeId: trustees[dataIndex]?.corporationId,
                  });
                }
              });
            },
            testId: `detachTrustee_${dataIndex}`,
          },
        ]}
      />
    );
  };

  const columns: DatatableColumn[] = [
    {
      filterDataType: FilterDataType.string,
      name: 'name',
      label: 'NAME',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex): React.ReactNode => nameColumn(dataIndex),
      },
    },
    {
      name: 'corporationType',
      label: 'CORPORATE TYPE',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex): React.ReactNode => corporateTypeColumn(dataIndex),
      },
    },
    {
      name: 'actions',
      label: ' ',
      textAlign: 'right',
      options: {
        filter: false,
        sort: false,
        viewColumns: false,
        customBodyRenderLite: (dataIndex): React.ReactNode => actionsColumn(dataIndex),
      },
    },
  ];

  const addButtons = (): React.ReactNode => {
    return (
      <>
        <IconButton
          disableFocusRipple
          disableRipple
          disabled={shouldDisableAddTrustee}
          onClick={() => {
            history.push(`${url}/trustee`);
          }}
        >
          <PersonAdd />
        </IconButton>
        <IconButton
          data-testid="attachTrusteeButton"
          disabled={shouldDisableAddTrustee}
          onClick={() => {
            setAttachTrusteeOpen(true);
          }}
        >
          <AttachTrusteeIcon />
        </IconButton>
      </>
    );
  };

  const onFetchTrusteesToAttach = (searchText: string) => {
    setTrusteesSearchText(searchText);
  };

  const onAttachTrustee = async (payload: AttachTrusteeDialogPayload) => {
    if (!!clientId) {
      await attachTrustee({ clientId, trusteeId: payload.trusteeId }).then(() => {
        setAttachTrusteeOpen(false);
      });
    }
  };

  return (
    <>
      <Typography variant="h4" gutterBottom>
        Corporate Trustees
      </Typography>
      <ClientSideDataTable
        loadingProgress={loadingTrusteesProgress}
        columns={hasClientEditPermission ? columns : columns.filter((c) => c.name !== 'actions')}
        data={trustees}
        options={{
          pagination: true,
          customToolbar: () => hasClientEditPermission && addButtons(),
        }}
      ></ClientSideDataTable>
      {attachTrusteeOpen && (
        <AttachTrustee
          trustees={trusteesToAttach}
          trusteesProgress={loadingTrusteesToAttach}
          isOpen={attachTrusteeOpen}
          handleCloseModal={() => setAttachTrusteeOpen(false)}
          onSave={(payload: AttachTrusteeDialogPayload) => onAttachTrustee(payload)}
          fetchTrusteesToAttach={onFetchTrusteesToAttach}
          savingAttachProgress={savingAttachProgress}
        ></AttachTrustee>
      )}
    </>
  );
};
