import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchCGTStrategies } from '../basicDetails/store/thunks';
import { CGTStrategy } from '../basicDetails/store/types';
import { fetchFinancialRecordsForClient } from './thunks';
import { CGTConfigurationState, CGTFinancialYearRecord } from './types';

export const initialState: CGTConfigurationState = {
  basicCGTConfiguration: {
    strategyTypes: [],
    cgtFinancialYearRecords: [],
    currentSelectedStrategy: undefined,
  },
  carriedForwardLossesState: {
    previousFinancialYears: [],
  },
};

export const cgtConfigurationSlice = createSlice({
  name: '@@client/cgtconfiguration/configuration',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchFinancialRecordsForClient.fulfilled,
      (state: CGTConfigurationState, action: PayloadAction<CGTFinancialYearRecord[]>) => {
        const reversedArray = action.payload.sort((a: CGTFinancialYearRecord, b: CGTFinancialYearRecord) => {
          return new Date(b.fyEnd).getTime() - new Date(a.fyEnd).getTime();
        });
        state.basicCGTConfiguration.cgtFinancialYearRecords = reversedArray;
        state.carriedForwardLossesState.previousFinancialYears = reversedArray;
      }
    );
    builder.addCase(
      fetchCGTStrategies.fulfilled,
      (state: CGTConfigurationState, action: PayloadAction<CGTStrategy[]>) => {
        state.basicCGTConfiguration.strategyTypes = action.payload;
      }
    );
  },
});
