import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'src/app/api';
import { AssetClassEnum } from 'src/common/enums/assetClasses';
import { AssetTypeEnum } from 'src/common/enums/assetTypeEnum';
import { buildEncodedQueryString } from 'src/common/utils/stringFunctions';
import { Afsl, CreateTemplateRequest, CreateTemplateResponse, DeleteTemplatePayload, FetchAfslIdsResponse, FetchTemplatesRequest, Template } from '.';
import { ApprovedProduct } from '../../edit/store';

export enum TemplatePortfoliosActionTypes {
  FetchAfsls = '@@client/templates/portfolios/FetchAsflIds',
  FetchTemplates = '@@client/templates/portfolios/FetchTemplates',
  DeleteTemplate = '@@client/templates/portfolios/DeleteTemplate',
  CreateTemplate = '@@client/templates/portfolios/CreateTemplate',
}

export enum TemplatePortfoliosApiEndpoints {
  FetchAfsls = '/bff/GetAfslsPracticesAdvisersForUser',
  FetchTemplates = '/investmentservices/GetActiveTemplates',
  DeleteTemplate = '/investmentservices/DeleteTemplate',
  CreateTemplate = '/investmentservices/CreateTemplate',
  FetchApprovedProducts = '/investmentservices/GetApprovedProductListByAfsl',
}

export const fetchAfsls = createAsyncThunk<Afsl[]>(TemplatePortfoliosActionTypes.FetchAfsls, async () => {
  const response = await api.get<FetchAfslIdsResponse>(TemplatePortfoliosApiEndpoints.FetchAfsls);
  return response.data.afsls.map((a) => ({ id: a.afslId, name: a.afslName }));
});

export const fetchTemplates = createAsyncThunk<Template[], FetchTemplatesRequest>(
  TemplatePortfoliosActionTypes.FetchTemplates,
  async (payload: FetchTemplatesRequest) => {
    const queryString = buildEncodedQueryString({
      afslId: payload.afslId,
    });
    const response = await api.get<Template[]>(`${TemplatePortfoliosApiEndpoints.FetchTemplates}${queryString}`);
    return response.data;
  }
);

export const deleteTemplate = createAsyncThunk(TemplatePortfoliosActionTypes.DeleteTemplate, async (payload: DeleteTemplatePayload, thunkApi) => {
  const body = {
    investmentServiceTemplateId: payload.investmentServiceTemplateId,
    afslId: payload.afslId,
  };

  try {
    await api.post(`${TemplatePortfoliosApiEndpoints.DeleteTemplate}`, body);
    await thunkApi.dispatch(fetchTemplates({ afslId: payload.afslId }));
    return { message: 'Template deleted' };
  } catch (error) {
    return { message: 'An error occured deleting template ', variant: 'error' };
  }
});

export const createTemplate = createAsyncThunk(TemplatePortfoliosActionTypes.CreateTemplate, (payload: CreateTemplateRequest, thunkApi) => {
  const queryString = buildEncodedQueryString({
    afslId: payload.afslId,
  });
  return api
    .get<ApprovedProduct[]>(`${TemplatePortfoliosApiEndpoints.FetchApprovedProducts}${queryString}`)
    .then((approvedProducts) => {
      const cashAccount = approvedProducts.data.find((p) => p.code === 'MacqBankCMA.ADI');
      const configuration = {
        componentId: cashAccount?.componentId || 0,
        assetClassId: AssetClassEnum.AustralianCash.id,
        typeId: AssetTypeEnum.Security.id,
        allocation: 100,
      };

      return api
        .post<CreateTemplateResponse>(TemplatePortfoliosApiEndpoints.CreateTemplate, { ...payload, configurations: [configuration] })
        .then(async (response) => {
          if (!response.data.messages || response.data.messages.length === 0) {
            await thunkApi.dispatch(fetchTemplates({ afslId: payload.afslId || 0 }));
            return { message: 'Configurations saved', ...response.data };
          } else {
            return thunkApi.rejectWithValue({ message: response.data.messages.map((m) => m.description).join(), variant: 'error' });
          }
        });
    })
    .catch(() => {
      return thunkApi.rejectWithValue({ message: 'Could not save configurations at this time.', variant: 'error' });
    });
});
