import { v4 } from 'uuid';
import { DateTime } from 'luxon';
import { formatDollars, formatPercentage } from '../../common';

export const uuidv4 = (): string => {
  return v4();
};

export function allValuesEqual<T, V>(fieldType: string, fieldValue: V, obj?: T): boolean {
  return !obj || Object.values(obj).every((value) => typeof value !== fieldType || value === fieldValue);
}

export function nameof<T>(nameFunction: (obj: T) => unknown): string {
  const fnStr = nameFunction.toString();

  // "x => x.prop"
  return fnStr.substring(fnStr.indexOf('.') + 1);
}

export const yearsDiff = (startDateVal: string, endDateVal: string) => {
  const startDate = DateTime.fromISO(startDateVal);
  const endDate = DateTime.fromISO(endDateVal);
  const years = Number(endDate.diff(startDate, ['years']).toObject().years);
  return years;
};

export const totalsChartHeader = (
  startDateVal: string,
  endDateVal: string,
  changeInValuePercentageVal: number,
  changeInValueVal: number | undefined
) => {
  const years = yearsDiff(startDateVal, endDateVal);
  const totalAnnualisedReturn = (1 + changeInValuePercentageVal) ** (1 / years) - 1;

  let totalAnnualisedReturnText = '';
  if (years > 1) {
    totalAnnualisedReturnText = `&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Total return(% p.a.)* ${formatPercentage(
      totalAnnualisedReturn
    )}`;
  }

  const totalsChartHeader = `<p>
      Total return ${formatDollars(changeInValueVal)} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Total return(%)
      ${formatPercentage(changeInValuePercentageVal)}
      ${totalAnnualisedReturnText}
    </p>`;

  return totalsChartHeader;
};
