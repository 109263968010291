/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PagedRequest } from '../../../../store';
import { fetchModelList, fetchStrategyList } from './thunks';
import { fetchPagedResults, ModelItem, ModelListState, ModelOrStrategy, StrategyItem } from './types';

export const initialState: ModelListState = {
  parameters: {
    modelOrStrategy: ModelOrStrategy.Model,
    codeOrNameSearch: '',
    pagination: {
      pageNumber: 1,
      pageSize: 25,
      queryFields: [
        {
          fieldName: 'name',
          descendingSortDirection: false,
          operator: '',
          hasSearchTerm: false,
          isSortTerm: true,
          searchTerm: '',
        },
      ],
    },
  },
  strategyParameters: {
    codeOrNameSearch: '',
    pagination: {
      pageNumber: 1,
      pageSize: 25,
      queryFields: [
        {
          fieldName: 'name',
          descendingSortDirection: false,
          operator: '',
          hasSearchTerm: false,
          isSortTerm: true,
          searchTerm: '',
        },
      ],
    },
  },
  models: null,
  strategies: null,
};

export const modelListSlice = createSlice({
  name: '@@model/list',
  initialState,
  reducers: {
    setSearchStringParameter: (state, action: PayloadAction<string | null>) => {
      state.parameters.codeOrNameSearch = action.payload;
    },
    setStrategySearchStringParameter: (state, action: PayloadAction<string | null>) => {
      state.strategyParameters.codeOrNameSearch = action.payload;
    },
    setModelOrStrategyParameter: (state, action: PayloadAction<ModelOrStrategy>) => {
      state.parameters.modelOrStrategy = action.payload;
    },
    setPaginationParameter: (state, action: PayloadAction<PagedRequest>) => {
      state.parameters.pagination = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchModelList.fulfilled, (state, action: PayloadAction<fetchPagedResults<ModelItem>>) => {
      state.models = action.payload.results;
      state.parameters.pagination = action.payload.pagination;
    });
    builder.addCase(fetchStrategyList.fulfilled, (state, action: PayloadAction<fetchPagedResults<StrategyItem>>) => {
      state.strategies = action.payload.results;
      state.strategyParameters.pagination = action.payload.pagination;
    });
  },
});
