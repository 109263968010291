import { Box, Container, Grid } from '@mui/material';
import { DateTime } from 'luxon';
import React, { useCallback, useEffect } from 'react';
import SearchButton from 'src/features/clients/client/common/components/SearchButton';
import { formatDollars, formatPercentage } from '../../../../common';
import CardComponent from '../../../../common/components/CardComponent';
import DateRangePicker from '../../../../common/components/DateRangePicker';
import { PagedRequest } from '../../../../store';
import { theme } from '../../../../themes';
import { Props } from '../container';
import {
  DividendDistributionsByPaymentDateCsvRow,
  DividendDistributionsByPaymentDateItem,
  DividendDistributionsByPaymentDateThunkParameters,
  SecurityIncomeSummaryThunkParameters,
} from '../store/types';
import { IncomeTable } from './incomeTable';

export const IncomeComponent = (props: Props): JSX.Element => {
  const {
    parameters,
    securityIncomeSummary,
    dividendDistributionsByPaymentDate,
    setDateParameter,
    fetchSecurityIncomeSummary,
    fetchDividendDistributionsByPaymentDate,
    securityIncomeSummaryProgress,
    dividendDistributionsByPaymentDateProgress,
    downloadDividendDistributionsByPaymentDate,
    match: { params },
  } = props;

  const fetch = useCallback(() => {
    if (params.securityCode) {
      const requestPayload: SecurityIncomeSummaryThunkParameters = {
        securityCode: params.securityCode,
      };
      const requestPayload2: DividendDistributionsByPaymentDateThunkParameters = {
        pagination: {
          pageNumber: 1,
          pageSize: 25,
          queryFields: [
            {
              fieldName: 'paymentDate',
              descendingSortDirection: true,
              operator: '',
              hasSearchTerm: false,
              isSortTerm: true,
              searchTerm: '',
            },
            {
              fieldName: 'isLockedForPushdown',
              descendingSortDirection: true,
              operator: 'IsEqual',
              hasSearchTerm: true,
              isSortTerm: false,
              searchTerm: 'false',
            },
          ],
        },
        securityCode: params.securityCode,
        fromDate: DateTime.now().minus({ years: 1 }).toISODate(),
        toDate: DateTime.now().toISODate(),
      };
      fetchSecurityIncomeSummary(requestPayload);
      fetchDividendDistributionsByPaymentDate(requestPayload2);
    }
  }, [params.securityCode, fetchDividendDistributionsByPaymentDate, fetchSecurityIncomeSummary]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  function onSearchClicked() {
    if (params.securityCode) {
      const requestPayload2: DividendDistributionsByPaymentDateThunkParameters = {
        securityCode: params.securityCode,
        fromDate: parameters.dates.dateFrom,
        toDate: parameters.dates.dateTo,
        pagination: parameters.pagination,
      };
      fetchDividendDistributionsByPaymentDate(requestPayload2);
    }
  }

  const handleGridActions = (pagedRequest: PagedRequest) => {
    if (params.securityCode) {
      fetchDividendDistributionsByPaymentDate({
        pagination: pagedRequest,
        securityCode: params.securityCode,
        fromDate: parameters.dates.dateFrom,
        toDate: parameters.dates.dateTo,
      });
    }
  };

  return (
    <Container style={{ paddingTop: '20px', paddingBottom: '20px' }}>
      <Box display={'flex'} justifyContent={'space-between'} style={{ padding: '10px 0' }}>
        <Box paddingRight={'10px'}></Box>
        <Box display={'flex'} justifyContent={'space-between'}>
          <DateRangePicker dateRange={parameters.dates} setDateRange={setDateParameter} />
          <Box paddingRight={'10px'}></Box>
          <Box>
            <SearchButton onClick={onSearchClicked} />
          </Box>
        </Box>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <CardComponent
            {...props}
            progress={securityIncomeSummaryProgress}
            background={theme.palette.gradient1?.main}
            value={formatDollars(securityIncomeSummary?.latestNetIncomePerUnit)}
            subText={'Most Recent'}
          />
        </Grid>
        <Grid item xs={3}>
          <CardComponent
            {...props}
            progress={securityIncomeSummaryProgress}
            background={theme.palette.gradient2?.main}
            value={formatDollars(securityIncomeSummary?.oneYearNetIncomePerUnit)}
            subText={'1 Year Income'}
          />
        </Grid>
        <Grid item xs={3}>
          <CardComponent
            {...props}
            progress={securityIncomeSummaryProgress}
            background={theme.palette.gradient1?.main}
            value={formatPercentage(securityIncomeSummary?.oneYearNetIncomeYield)}
            subText={'Est. Income Yield'}
          />
        </Grid>
        <Grid item xs={3}>
          <CardComponent
            {...props}
            progress={securityIncomeSummaryProgress}
            background={theme.palette.gradient2?.main}
            value={formatPercentage(securityIncomeSummary?.oneYearGrossIncomeYield)}
            subText={'Est. Income Yield (inc Franking)'}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} style={{ paddingTop: '15px', paddingLeft: '10px' }}>
        <IncomeTable
          pagedRequest={parameters.pagination}
          pagedResults={dividendDistributionsByPaymentDate || undefined}
          progressPrices={dividendDistributionsByPaymentDateProgress}
          handlePagedRequest={handleGridActions}
          handleDownload={(
            pagedRequest: PagedRequest,
            mapper: (dividendDistributionsByPaymentDateItems: DividendDistributionsByPaymentDateItem[]) => DividendDistributionsByPaymentDateCsvRow[]
          ) =>
            downloadDividendDistributionsByPaymentDate({
              pagination: pagedRequest,
              securityCode: params.securityCode || '',
              fromDate: parameters.dates.dateFrom,
              toDate: parameters.dates.dateTo,
              mapper,
            })
          }
        ></IncomeTable>
      </Grid>
    </Container>
  );
};
