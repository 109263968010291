import { createSelector } from '@reduxjs/toolkit';
import { selectClientState } from '../../store/selectors';

export const selectGainsLosses = createSelector(selectClientState, (clientState) => clientState.gainsAndLosses);

export const selectResults = createSelector(selectGainsLosses, (gainsLosses) => gainsLosses.results);

export const selectParameters = createSelector(selectGainsLosses, (gainsLosses) => gainsLosses.parameters);

export const filteredResults = createSelector(selectResults, selectParameters, (results, parameters) =>
  results?.items.filter(
    (i) =>
      parameters.filter.trim() === '' ||
      i.groupName.toLowerCase().includes(parameters.filter.toLowerCase()) ||
      i.groupSubName.toLowerCase().includes(parameters.filter.toLowerCase())
  )
);
