import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { progressSelectorFactory } from '../../../common';
import { RootState } from '../../../reducers';
import { PerformanceComponent } from './components/PerformanceComponent';
import {
  selectDailyPerformanceChart,
  selectMonthlyPerformanceChart,
  selectParameters,
  selectPeriodicSummary,
  selectStrategyDashboard,
  selectStrategyDetails,
} from './store/selectors';
import { strategyPerformanceSlice } from './store/slice';
import {
  fetchDailyPerformanceChart,
  fetchMonthlyPerformanceChart,
  fetchPeriodicSummary,
  fetchStrategyDashboard,
  fetchStrategyDetails,
  StrategyPerformanceActionTypes,
} from './store/thunks';

const mapStateToProps = (state: RootState) => ({
  parameters: selectParameters(state),
  strategyDetails: selectStrategyDetails(state),
  strategyDashboard: selectStrategyDashboard(state),
  periodicSummary: selectPeriodicSummary(state),
  dailyPerformanceChart: selectDailyPerformanceChart(state),
  monthlyPerformanceChart: selectMonthlyPerformanceChart(state),
  loadingProgresStrategyDetails: progressSelectorFactory([StrategyPerformanceActionTypes.FetchStrategyDetails])(state),
  loadingProgresStrategyDashboard: progressSelectorFactory([StrategyPerformanceActionTypes.FetchStrategyDashboard])(state),
  loadingProgresPeriodicSummary: progressSelectorFactory([StrategyPerformanceActionTypes.FetchPeriodicSummary])(state),
  performanceChartProgress: progressSelectorFactory([StrategyPerformanceActionTypes.FetchDailyPerformanceChart])(state),
});

const mapDispatchToProps = {
  ...strategyPerformanceSlice.actions,
  fetchDailyPerformanceChart,
  fetchMonthlyPerformanceChart,
  fetchPeriodicSummary,
  fetchStrategyDetails,
  fetchStrategyDashboard,
};

interface MatchParams {
  strategyId?: string;
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector> & RouteComponentProps<MatchParams>;
export const StrategyPerformanceStateContainer = connect(mapStateToProps, mapDispatchToProps)(PerformanceComponent);
