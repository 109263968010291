import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AssetClassToleranceBand, Configuration, SecurityConstraint, SecurityToleranceBand, StrategicAssetAllocation } from '../../store/types';
import { fetchApprovedProducts, fetchBenchmarks, fetchTemplate } from './thunks';
import { ApprovedProduct, BenchmarkItem, EditState, FetchTemplateResponse, TemplateDetails } from './types';

export const initialState: EditState = {
  afslId: undefined,
  investmentServiceTemplateId: undefined,
  details: undefined,
  assetClassToleranceBands: [],
  securityToleranceBands: [],
  configurations: [],
  strategicAssetAllocations: [],
  securityConstraints: [],

  benchmarks: [],
  approvedProducts: [],
};

export const editSlice = createSlice({
  name: '@@client/templates/edit',
  initialState,
  reducers: {
    setAfslId: (state, action: PayloadAction<number>) => {
      state.afslId = action.payload;
    },
    setInvestmentServiceTemplateId: (state, action: PayloadAction<number>) => {
      state.investmentServiceTemplateId = action.payload;
    },
    setDetails: (state, action: PayloadAction<TemplateDetails>) => {
      state.details = action.payload;
    },
    setAssetClassToleranceBands: (state, action: PayloadAction<AssetClassToleranceBand[]>) => {
      state.assetClassToleranceBands = action.payload;
    },
    setSecurityToleranceBands: (state, action: PayloadAction<SecurityToleranceBand[]>) => {
      state.securityToleranceBands = action.payload;
    },
    setConfigurations: (state, action: PayloadAction<Configuration[]>) => {
      state.configurations = action.payload;
    },
    setStrategicAssetAllocations: (state, action: PayloadAction<StrategicAssetAllocation[]>) => {
      state.strategicAssetAllocations = action.payload;
    },
    setSecurityConstraints: (state, action: PayloadAction<SecurityConstraint[]>) => {
      state.securityConstraints = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTemplate.fulfilled, (state, action: PayloadAction<FetchTemplateResponse>) => {
      state.details = {
        name: action.payload.name,
        serviceTypeId: action.payload.serviceTypeId,
        primaryBenchmarkId: action.payload.primaryBenchmarkId,
        secondaryBenchmarkId: action.payload.secondaryBenchmarkId,
        portalBenchmarkIds: action.payload.portalBenchmarkIds,
        minTradeValue: action.payload.minTradeValue,
        fortyFiveDayRule: action.payload.fortyFiveDayRule,
        oneYearRule: action.payload.oneYearRule,
        rebalanceTypeId: action.payload.rebalanceTypeId,
      };
      state.assetClassToleranceBands = action.payload.assetClassToleranceBands;
      state.securityToleranceBands = action.payload.securityToleranceBands;
      state.configurations = action.payload.configurations;
      state.strategicAssetAllocations = action.payload.strategicAssetAllocations;
      state.securityConstraints = action.payload.securityConstraints;
    });
    builder.addCase(fetchApprovedProducts.fulfilled, (state, action: PayloadAction<ApprovedProduct[]>) => {
      state.approvedProducts = action.payload;
    });
    builder.addCase(fetchBenchmarks.fulfilled, (state, action: PayloadAction<BenchmarkItem[]>) => {
      state.benchmarks = action.payload;
    });
  },
});
