import { Enumeration } from './enumeration';

export class EntityType extends Enumeration {
  static Client = new EntityType(1, 'Client', 'Client');
  static AFSLLicensee = new EntityType(2, 'AFSLLicensee', 'AFSLLicensee');
  static Contact = new EntityType(3, 'Contact', 'Contact');
  static Practice = new EntityType(4, 'Practice', 'Practice');
  static Adviser = new EntityType(5, 'Adviser', 'Adviser');
  static Corporation = new EntityType(6, 'Corporation', 'Corporation');
  static Composite = new EntityType(7, 'Composite', 'Composite');
  static Group = new EntityType(8, 'Group', 'Group');
  static FundManager = new EntityType(9, 'FundManager', 'FundManager');

  static getByName = (name: string): EntityType | undefined => {
    return Object.values(EntityType)
      .filter((t) => t instanceof EntityType)
      .find((t: EntityType) => t.name.toLowerCase() === name.toLowerCase());
  };

  static getById = (id: number | null): EntityType | undefined => {
    return Object.values(EntityType)
      .filter((t) => t instanceof EntityType)
      .find((t: EntityType) => t.id === id);
  };
}
