import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';
import { DateRange } from '../../../store';
import { Client } from '../../bulk/common/store/types';
import { fetchMyReport, fetchReportList } from './thunks';
import { MyReport, Report, ReportFormat, ReportState } from './types';

export const initialState: ReportState = {
  parameters: {
    client: null,
    coverPage: false,
    addAppendix: false,
    mergeReports: false,
    reportFormat: ReportFormat.EXCEL.value,
    dates: {
      dateFrom: DateTime.now().toISODate(),
      dateTo: DateTime.now().toISODate(),
    },
  },
  myReport: [],
  reportList: [],
};

export const reportSlice = createSlice({
  name: '@@report',
  initialState,
  reducers: {
    setClient: (state, action: PayloadAction<Client | null>) => {
      state.parameters.client = action.payload;
    },
    setCoverPage: (state, action: PayloadAction<boolean>) => {
      state.parameters.coverPage = action.payload;
    },
    setAddAppendix: (state, action: PayloadAction<boolean>) => {
      state.parameters.addAppendix = action.payload;
    },
    setMergeReports: (state, action: PayloadAction<boolean>) => {
      state.parameters.mergeReports = action.payload;
    },
    setReportFormat: (state, action: PayloadAction<string>) => {
      state.parameters.reportFormat = action.payload;
    },
    setDates: (state, action: PayloadAction<DateRange>) => {
      state.parameters.dates = action.payload;
    },
    setHiddingOptionReport: (state, action: PayloadAction<{ value: boolean; indexReport: number; indexHidding: number }>) => {
      const data = [...state.reportList];
      data[action.payload.indexReport].hidingOptions[action.payload.indexHidding].value = action.payload.value;
      state.reportList = data;
    },
    setUseCiriteriaReport: (state, action: PayloadAction<{ value: boolean; indexReport: number }>) => {
      const data = [...state.reportList];
      data[action.payload.indexReport].useCiriteria = action.payload.value;
      state.reportList = data;
    },
    setOrganiseReport: (state, action: PayloadAction<{ value: string; indexReport: number }>) => {
      const data = [...state.reportList];
      data[action.payload.indexReport].organiseBy = action.payload.value;
      state.reportList = data;
    },
    setSortReport: (state, action: PayloadAction<{ value: string; indexReport: number }>) => {
      const data = [...state.reportList];
      data[action.payload.indexReport].sortBy = action.payload.value;
      state.reportList = data;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchReportList.fulfilled, (state, action: PayloadAction<Report[]>) => {
      const data = action.payload;
      data.map((report) => {
        report.hidingOptions.map((item) => {
          item.value = false;
          return item;
        });
        report.primarySortingOptions.map((item) => {
          item.value = item.name;
          return item;
        });
        report.groupingOptions.map((item) => {
          item.value = item.name;
          return item;
        });
        report.sortBy = report.primarySortingOptions[0].name;
        report.organiseBy = report.groupingOptions[0].name;
        report.useCiriteria = false;
        return report;
      });
      state.reportList = data;
    });
    builder.addCase(fetchMyReport.fulfilled, (state, action: PayloadAction<MyReport[]>) => {
      state.myReport = action.payload;
    });
  },
});
