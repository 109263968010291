import { Add as AddIcon, Cancel as CancelIcon, Delete as DeleteIcon, Edit as EditIcon, Save as SaveIcon } from '@mui/icons-material';
import { 
  Box, 
  FormControlLabel,
  IconButton, 
  LinearProgress, 
  Paper, 
  Stack, 
  styled, 
  Typography, 
  Autocomplete,
  TextField } from '@mui/material';
import { GridEditCellProps, GridToolbarContainer, GridValueGetterParams } from '@mui/x-data-grid';
import {
  DataGridPro,
  GridActionsCellItem,
  GridColumns,
  gridEditRowsStateSelector,
  GridPreProcessEditCellProps,
  GridRenderCellParams,
  GridRowModes,
  GridRowParams,
  MuiBaseEvent,
  MuiEvent,
  useGridApiRef,
  GridRowId,
  GridRenderEditCellParams
} from '@mui/x-data-grid-pro';
import { useDebounce } from 'src/common/hooks';
import React, { useCallback, useEffect, useState } from 'react';
import PulseLoader from 'react-spinners/PulseLoader';
import { LoadingProgress } from 'src/common/store/types';
import { theme } from '../../../../../../../themes';
import { ApprovedProduct } from '../../../store/common';
import { WO2Checkbox } from 'src/common';
import { fetchApprovedProductSearchService } from 'src/features/orders/rebalance/edit/store/services';
import { AssetType } from '../store/enums';
import { SecurityExclusion, SaveExclusionItem } from '../store/types';

const StyledBox = styled(Box)(() => ({
  width: '100%',
  '& .MuiDataGrid-cell--editing': {
    backgroundColor: 'rgb(255,215,115, 0.19)',
    color: '#1a3e72',
    '& .MuiInputBase-root': {
      height: '100%',
    },
  },
  '& .Mui-error': {
    backgroundColor: `#FFCCCC`,
    color: 'red',
    borderRadius: '4px',
    borderColor: 'red',
    borderStyle: 'solid',
    borderWidth: '1px',
  },
  '& .MuiDataGrid-editInputCell': {
    borderWidth: '2px',
  },
  '& input[type=number]': {
    textAlign: 'right',
    paddingRight: '0px',
  },
  '& .unallocated-row': {
    backgroundColor: `#FFCCCC`,
    color: 'red',
  },
  '& .MuiDataGrid-toolbarContainer': {
    justifyContent: 'flex-end',
  },
}));

export interface ExclusionsTableProps {
  itemsSecuritiesExclusions: SecurityExclusion[];
  itemLoadingProgress: LoadingProgress;
  securities: ApprovedProduct[];
  securitiesLoadingProgress: LoadingProgress;
  onSave?: (exclusionItems: SaveExclusionItem[]) => Promise<void>;
  savingProgressCreate: LoadingProgress;
  savingProgressDelete: LoadingProgress;
  hideEditControls?: boolean;
  clientSubTypeId: number | undefined;
  afslId: number | undefined;
  clientId: number | null;
  investmentServiceId: number | null;
}

interface SecuritiesDataRow {
  id: string;
  typeId: number | null;
  configurationId: number | null;
  securityId: number | null;
  approvedProduct: ApprovedProduct | null | undefined;
  excludeValuation: boolean;
  excludePerformance: boolean;
  excludeFees: boolean;
  clientId: number | null;
  investmentServiceId: number | null;
}

interface UpdatedRow {
  [key: string]: SecuritiesDataRow;
}

export const ReportSettingsTable = (props: ExclusionsTableProps): JSX.Element => {
  const { 
    itemsSecuritiesExclusions,
    itemLoadingProgress, 
    onSave, 
    securities, 
    securitiesLoadingProgress, 
    savingProgressCreate,
    savingProgressDelete,
    hideEditControls,
    afslId,
    clientId,
    investmentServiceId,
    clientSubTypeId } = props;
  const [dataRows, setDataRows] = useState<SecuritiesDataRow[]>([]);
  const [editMode, setEditMode] = useState<boolean>(false);

  const [approvedProductSearchResults, setApprovedProductSearchResults] = useState<ApprovedProduct[]>([]);
  const [approvedProductSearch, setApprovedProductSearch] = useState<string>('');
  const onapprovedProductSearchChange = useDebounce<string>(approvedProductSearch, 500);
  const rowsWithUpdatedValues = React.useRef<UpdatedRow>({});
  const apiRef = useGridApiRef();

  useEffect(() => {
    resetDataRows();
  }, [itemsSecuritiesExclusions]);

  useEffect(() => {
      new Promise<void>(async () => {
        let searchResults: ApprovedProduct[] = [];

        if (afslId && clientSubTypeId) {
          const results = await fetchApprovedProductSearchService(afslId, clientSubTypeId, approvedProductSearch);
          searchResults = results.results;
        }
        const allResults = [
          ...searchResults,
          ...itemsSecuritiesExclusions.reduce(
            (prev, curr): ApprovedProduct[] =>
              !!searchResults.find((sr) => sr.typeId === curr.typeId && sr.componentId === curr.securityId)
                ? prev
                : [
                    ...prev,
                    {
                      code: curr.code,
                      componentId: curr.securityId,
                      assetClassIds: curr.assetClassId > 0 ? [curr.assetClassId] : [],
                      name: curr.name,
                      typeId: curr.typeId,
                    },
                  ],
            [] as ApprovedProduct[]
          ),
        ];

        setApprovedProductSearchResults(allResults);
      });
  }, [onapprovedProductSearchChange, afslId, clientSubTypeId, itemsSecuritiesExclusions]);

  const handleApprovedProductOnChange = (id: GridRowId, value: ApprovedProduct | null) => {
    apiRef.current.setEditCellValue({ id, field: 'approvedProduct', value: value });
  
    if (rowsWithUpdatedValues.current[id]) {
      rowsWithUpdatedValues.current[id].approvedProduct = value;
    }
  };

  const resetDataRows = useCallback(async () => {
    setDataRows([]);

    setDataRows(
      itemsSecuritiesExclusions.map((i, index) => {
        const approvedProduct = approvedProductSearchResults.find((product) =>
            product.componentId === i.securityId && product.typeId === AssetType.Security
        );

        return {
          id: index.toString(),
          securityId: i.securityId,
          configurationId: i.configurationId,
          approvedProduct: approvedProduct || null,  
          excludeValuation: i.excludeValuation,
          excludePerformance: i.excludePerformance,
          excludeFees: i.excludeFees,
          clientId: i.clientId,  
          investmentServiceId: i.investmentServiceId, 
          typeId: i.typeId  
        };
      })
    );
  
    rowsWithUpdatedValues.current = {};
  
    itemsSecuritiesExclusions.forEach((i, index) => {
      rowsWithUpdatedValues.current[index.toString()] = {
        id: index.toString(),
        securityId: i.securityId,
        configurationId: i.configurationId,
        approvedProduct: approvedProductSearchResults.find(
          (product) => product.componentId === i.securityId && product.typeId === i.typeId
        ) || null,
        excludeValuation: i.excludeValuation,
        excludePerformance: i.excludePerformance,
        excludeFees: i.excludeFees,
        clientId: i.clientId,
        investmentServiceId: i.investmentServiceId,
        typeId: i.typeId
      };
    });
  
  }, [itemsSecuritiesExclusions, approvedProductSearchResults, setDataRows]);

  useEffect(() => {
    if (approvedProductSearchResults.length > 0) {
      resetDataRows(); 
    }
  }, [approvedProductSearchResults, resetDataRows]);
  
  const handleClickExcludeValuationSelect = (rowId: string, newValue: boolean) => {
    if (rowsWithUpdatedValues.current[rowId]) {
      rowsWithUpdatedValues.current[rowId].excludeValuation = newValue;
    }
  };
  
  const handleClickExcludePerformanceSelect = (rowId: string, newValue: boolean) => {
    if (rowsWithUpdatedValues.current[rowId]) {
      rowsWithUpdatedValues.current[rowId].excludePerformance = newValue;
    }
  }
  
  const handleClickExcludeFeesSelect = (rowId: string, newValue: boolean) => {
    if (rowsWithUpdatedValues.current[rowId]) {
      rowsWithUpdatedValues.current[rowId].excludeFees = newValue;
    }
  }

  const columns: GridColumns = [
    {
      field: 'approvedProduct',
      headerName: 'Security',
      type: 'singleSelect',
      flex: 1,
      editable: true,
      sortable: false,
      renderEditCell: (params: GridRenderEditCellParams<ApprovedProduct>) => {
        const { id, value } = params;
        let allOptions = approvedProductSearchResults;

        if (id !== undefined) {
          const typeId = AssetType.Security;

          allOptions = [...approvedProductSearchResults]
            .filter((p) => p.typeId === typeId)
            .sort((a, b) => a.name.localeCompare(b.name));
        }

        return (
          <Autocomplete
            style={{ width: '100%' }}
            noOptionsText="No matching securities were found."
            value={value}
            disableClearable
            options={allOptions}
            isOptionEqualToValue={(option, value) => (value ? option.componentId === value.componentId : true)}
            getOptionLabel={(option: ApprovedProduct) => {
              let returnVal = option.code ? `${option.code} - ${option.name}` : option.name;
              if (returnVal === undefined) {
                returnVal = '';
              }
              return returnVal;
            }}
            onChange={(event: unknown, value: ApprovedProduct | null) => {
              handleApprovedProductOnChange(id, value);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                onChange={(event) => {
                  setApprovedProductSearch(event.target.value);
                }}
              />
            )}
          />
        );
      },
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        
        rowsWithUpdatedValues.current[params.id].approvedProduct = params.props.value;
        const typeId = rowsWithUpdatedValues.current[params.id].typeId;
        const productInList = !!approvedProductSearchResults.find(
          (p) => p.typeId === typeId && p.componentId === params.props.value?.componentId
        );

        return { ...params.props, error: !productInList };
      },
      valueGetter: (params: GridValueGetterParams) => {
        return params.value !== null ? params.value : '';
      },
      renderCell: (params: GridRenderCellParams<ApprovedProduct>) => {
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{
                letterSpacing: '1px',
              }}
            >
              {params?.value?.name ?? ''}
            </Typography>
            <Typography color={'textSecondary'} variant={'h6'} align="left">
              {params?.value?.code ?? ''}
            </Typography>
          </div>
        );
      },
    },
    {
      field: 'excludeValuation',
      headerName: 'Valuation',
      type: 'boolean',
      flex: 1,
      editable: true,
      sortable: false,
      renderCell: (params: GridValueGetterParams) => {
        const rowId = params.id as string;

        return (
          <FormControlLabel
            aria-label="Acknowledge"
            onClick={(event) => event.stopPropagation()}
            onFocus={(event) => event.stopPropagation()}
            control={
              <WO2Checkbox
                color="primary"
                checked={params.value}
                onChangeHandler={(newValue: boolean) => {
                  handleClickExcludeValuationSelect(rowId, newValue); 
                }}
              />
            }
            label=""
          />
        )
      },
    },
    {
      field: 'excludePerformance',
      headerName: 'Performance',
      type: 'boolean',
      flex: 1,
      editable: true,
      sortable: false,
      renderCell: (params: GridValueGetterParams) => {
        const rowId = params.id as string;
        
        return (
          <FormControlLabel
            aria-label="Acknowledge"
            onClick={(event) => event.stopPropagation()}
            onFocus={(event) => event.stopPropagation()}
            control={
              <WO2Checkbox
                color="primary"
                checked={params.value}
                onChangeHandler={(newValue: boolean) => {
                  handleClickExcludePerformanceSelect(rowId, newValue); 
                }}
              />
            }
            label=""
          />
        )
      },
    },
    {
      field: 'excludeFees',
      headerName: 'Fees',
      type: 'boolean',
      flex: 1,
      editable: true,
      sortable: false,
      renderCell: (params: GridValueGetterParams) => {
        const rowId = params.id as string;

        return (
          <FormControlLabel
            aria-label="Acknowledge"
            onClick={(event) => event.stopPropagation()}
            onFocus={(event) => event.stopPropagation()}
            control={
              <WO2Checkbox
                color="primary"
                checked={params.value}
                onChangeHandler={(newValue: boolean) => {
                  handleClickExcludeFeesSelect(rowId, newValue); 
                }}
              />
            }
            label=""
          />
        )
      },
    },
    {
      field: 'actions',
      type: 'actions',
      sortable: false,
      width: 80,
      getActions: (params) => {
        return [
          <GridActionsCellItem key="1" icon={<DeleteIcon />} label="Delete" onClick={deleteRow(params.id.toString())} disabled={savingProgressCreate.isLoading} />,
        ];
      },
    },
  ];

  const handleAddClick = React.useCallback(async () => {
    const id = apiRef.current.getAllRowIds().length.toString();

    const newRow: SecuritiesDataRow = {
     id,
     typeId: AssetType.Security,
     securityId: null,
     configurationId: null,
     approvedProduct: null,
     excludeValuation: false,
     excludePerformance: false,
     excludeFees: false,
     clientId: null,
     investmentServiceId: null
    };

    rowsWithUpdatedValues.current[id] = newRow;
    
    apiRef.current.updateRows([{ ...newRow, id, isNew: true }]);
    apiRef.current.setRowMode(id, GridRowModes.Edit);

    await apiRef.current.setEditCellValue({ id: id.toString(), field: 'approvedProduct', value: null });
    await apiRef.current.setEditCellValue({ id, field: 'excludeValuation', value: newRow.excludeValuation });
    await apiRef.current.setEditCellValue({ id, field: 'excludePerformance', value: newRow.excludePerformance });
    await apiRef.current.setEditCellValue({ id, field: 'excludeFees', value: newRow.excludeFees });

    setTimeout(() => {
      apiRef.current.scrollToIndexes({
        rowIndex: apiRef.current.getRowsCount() - 1,
      });
      apiRef.current.setCellFocus(-1, 'name');
    });
  }, [apiRef]);

  const handleEditClick = React.useCallback(() => {
    setEditMode(true);

    apiRef.current.getAllRowIds().forEach((id) => {
    apiRef.current.setRowMode(id, GridRowModes.Edit);
    });
  }, [apiRef]);
  
  const handleCancelClick = React.useCallback(() => {
    apiRef.current.getAllRowIds().forEach((id) => {
      apiRef.current.setRowMode(id, GridRowModes.View);
      const row = apiRef.current.getRow(id);
      if (!!row && row.isNew) {
        apiRef.current.updateRows([{ id, _action: 'delete' }]);
      }
    });

    setEditMode(false);
    resetDataRows();
  }, [apiRef, approvedProductSearchResults]);
  
 const handleSave = useCallback(async () => {
    if (!!onSave) {
      const editModels = { ...gridEditRowsStateSelector(apiRef.current.state) };

        handleCancelClick();
        await onSave([
          ...Object.values(editModels).map((m: GridEditCellProps) => {

            return {
              clientId: clientId,
              investmentServiceId: investmentServiceId,
              securityId: m?.approvedProduct?.value?.componentId ?? null,
              excludeValuation: m?.excludeValuation?.value ?? null,
              excludePerformance: m?.excludePerformance?.value ?? null,
              excludeFees: m?.excludeFees?.value ?? null,
            };
          }),
        ]);
    }
  }, [onSave, apiRef.current, clientId, investmentServiceId]);

  const deleteRow = React.useCallback(
    (id: string) => () => {
      apiRef.current.setRowMode(id, GridRowModes.View);
      apiRef.current.updateRows([{ id, _action: 'delete' }]);
    },
    [apiRef, dataRows]
  );
    
  const isLoading =    savingProgressCreate.isLoading 
                    || itemLoadingProgress.isLoading 
                    || securitiesLoadingProgress.isLoading
                    || savingProgressDelete.isLoading;

  return (
    <>
      <Typography variant="h4" style={{ paddingBottom: '10px', paddingTop: '10px' }}>
        Excluded Securities
      </Typography>
      <div style={{ height: '400px' }}>
        <Paper elevation={3}>
          <StyledBox>
            <DataGridPro
              editMode="row"
              apiRef={apiRef}
              rows={isLoading || securities.length === 0 ? [] : dataRows}
              columns={columns}
              columnVisibilityModel={{
                actions: editMode,
              }}
              pageSize={itemsSecuritiesExclusions.length}
              disableColumnMenu
              disableColumnReorder={true}
              autoHeight
              components={{
                LoadingOverlay: LinearProgress,
                Toolbar: () => {
                  return (
                    <GridToolbarContainer>
                      <Stack direction="row" alignItems="center">
                        {isLoading && (
                          <div className="LoadingIndicator" style={{ padding: '7px' }}>
                            <PulseLoader size="9px" margin="5px" color={theme.palette.grey[400]} />
                          </div>
                        )}
                        {!hideEditControls && editMode && !isLoading && (
                          <IconButton disableFocusRipple disableRipple data-testid="addButton" onClick={handleAddClick} color={'primary'}>
                            <AddIcon style={{ height: '24px' }} />
                          </IconButton>
                        )}
                        {!hideEditControls && !editMode && (
                          <IconButton disableFocusRipple disableRipple data-testid="editButton" onClick={handleEditClick} color={'primary'}>
                            <EditIcon style={{ height: '24px' }} />
                          </IconButton>
                        )}
                        {!hideEditControls && editMode && !isLoading && (
                          <IconButton disableFocusRipple disableRipple data-testid="cancelButton" onClick={handleCancelClick} color={'primary'}>
                            <CancelIcon style={{ height: '24px' }} />
                          </IconButton>
                        )}
                        {!hideEditControls && editMode && !isLoading && (
                          <IconButton disableFocusRipple disableRipple data-testid="saveButton" onClick={handleSave} color={'primary'}>
                            <SaveIcon style={{ height: '24px' }} />
                          </IconButton>
                        )}
                      </Stack>
                    </GridToolbarContainer>
                  );
                },
              }}
              loading={isLoading}
              onRowEditStop={(_params: GridRowParams, event: MuiEvent<MuiBaseEvent>) => {
                event.defaultMuiPrevented = true;
              }}
              onRowEditStart={(_params: GridRowParams, event: MuiEvent<MuiBaseEvent>) => {
                event.defaultMuiPrevented = true;
              }}
            />
          </StyledBox>
        </Paper>
      </div>
    </>
  );
};
