import { AdviceEstimatedFee, PendingFeeActionType } from '../../client/details/adviceFees/store/types';
import { FeeCalculationType, FeeFrequency, FeeMethod } from '../enums';
import { EstimatedFee, TieredFeeDetails } from '../types';

interface CalculationResults {
  amount: number;
  percentageOfValue: number;
}

export interface CalculatedValues {
  totalAmount: number;
  maxAmount: number;
  maxPercentage: number;
}

export const getCalcualtedValues = (
  calculationTypeId: number | null,
  isActive: boolean,
  isSuperOrPension: boolean,
  values: {
    totalRolloverAmount?: number;
    contributionsAmount?: number;
    marketValue?: number;
    availableCash?: number;
  }
): CalculatedValues => {
  const totalAmount = isActive
    ? +(values.marketValue ?? 0).toFixed(2)
    : +(
        (values.totalRolloverAmount ?? 0) +
        (calculationTypeId === FeeCalculationType.UpfrontAdviceFee.id ? 0 : values.contributionsAmount ?? 0)
      ).toFixed(2);

  if (!isSuperOrPension) {
    return {
      totalAmount: totalAmount,
      maxAmount: 999999.99,
      maxPercentage: 99.9999,
    };
  }

  switch (calculationTypeId) {
    case FeeCalculationType.OngoingAdviceFee.id:
      return {
        totalAmount: totalAmount,
        maxAmount: +(totalAmount * 0.022).toFixed(2),
        maxPercentage: 2.2,
      };
    case FeeCalculationType.PortfolioManagementFee.id:
      return {
        totalAmount: totalAmount,
        maxAmount: +(totalAmount * 0.0165).toFixed(2),
        maxPercentage: 1.65,
      };
    case FeeCalculationType.UpfrontAdviceFee.id:
      return {
        totalAmount: totalAmount,
        maxAmount: +(totalAmount * 0.044).toFixed(2),
        maxPercentage: 4.4,
      };
    case FeeCalculationType.AdviserInsuranceFee.id:
      return {
        totalAmount: totalAmount,
        maxAmount: +(totalAmount * 0.025).toFixed(2),
        maxPercentage: 25,
      };
  }

  return {
    totalAmount: totalAmount,
    maxAmount: 0,
    maxPercentage: 0,
  };
};

export const calculateAmountAndPercentage = (
  feeDetails: EstimatedFee,
  totalRolloverAmount: number,
  contributionsAmount: number,
  isFeeConvertedFromMonthToAnnual: boolean
): CalculationResults => {
  const calculatedValues = getCalcualtedValues(feeDetails.calculationTypeId, false, false, {
    totalRolloverAmount: totalRolloverAmount,
    contributionsAmount: contributionsAmount,
  });
  return calculateAmountAndPercentageWithTotalAmount(
    feeDetails,
    calculatedValues.totalAmount,
    isFeeConvertedFromMonthToAnnual
  );
};

export const calculateAmountAndPercentageWithTotalAmount = (
  feeDetails: EstimatedFee,
  totalAmount: number,
  isFeeConvertedFromMonthToAnnual: boolean
): CalculationResults => {
  let amount = 0;
  let percentageOfValue = 0;

  if (feeDetails.methodId === FeeMethod.Dollar.id) {
    amount = feeDetails.amount ?? 0;

    // the annual fee needs to be multiplied by 12 when the frequency is per month
    if (
      isFeeConvertedFromMonthToAnnual &&
      feeDetails.calculationTypeId === FeeCalculationType.OngoingAdviceFee.id &&
      feeDetails.frequencyId === FeeFrequency.PerMonth.id
    ) {
      amount = +(12 * amount).toFixed(2);
    }

    // * 1000000 to give 4 decimal places
    percentageOfValue = totalAmount > 0 ? +(Math.round((amount / totalAmount) * 1000000) / 10000).toFixed(4) : 0;
  } else if (feeDetails.methodId === FeeMethod.Percentage.id) {
    amount = totalAmount > 0 ? +((totalAmount * (feeDetails.percentageOfValue ?? 0)) / 100).toFixed(2) : 0;
    percentageOfValue = feeDetails.percentageOfValue ?? 0;
  } else if (feeDetails.methodId === FeeMethod.Tiered.id) {
    const tieredFeeDetailsItems = feeDetails.tieredFeeDetails.items;
    const lastValidTieredItemIndex =
      (tieredFeeDetailsItems[tieredFeeDetailsItems.length - 1].to ?? 0) < totalAmount
        ? tieredFeeDetailsItems.length - 1
        : tieredFeeDetailsItems.findIndex(
            (item) =>
              !!item.to &&
              ((item.from < totalAmount && item.to > totalAmount) ||
                item.from === totalAmount ||
                item.to === totalAmount)
          );

    if (lastValidTieredItemIndex >= 0) {
      const validTieredFeeDetailsItems = tieredFeeDetailsItems.slice(0, lastValidTieredItemIndex + 1);
      const sumAmount = validTieredFeeDetailsItems
        .map((item) => item.amount ?? 0)
        .reduce((acc: number, value: number) => acc + value);
      const sumAmountByPercentage = validTieredFeeDetailsItems.reduce(
        (acc: number, tieredFeeDetailsItem: TieredFeeDetails, index: number, arraySelf: TieredFeeDetails[]) => {
          return (
            acc +
            (parseFloat(
              (
                (index === arraySelf.length - 1 && (tieredFeeDetailsItem?.to ?? 0) >= totalAmount
                  ? totalAmount
                  : tieredFeeDetailsItem.to ?? 0) - tieredFeeDetailsItem.from
              ).toFixed(2)
            ) *
              (tieredFeeDetailsItem?.percentage ?? 0)) /
              100
          );
        },
        0
      );

      amount = +(sumAmount + sumAmountByPercentage).toFixed(2);
      percentageOfValue = totalAmount > 0 ? +(Math.round((amount / totalAmount) * 1000000) / 10000).toFixed(4) : 0;
    }
  }

  return {
    amount: amount,
    percentageOfValue: percentageOfValue,
  };
};

export const calculateAdviceEstimatedFeesItems = (
  estimatedFeesItems: AdviceEstimatedFee[],
  totalRolloverAmount: number,
  contributionsAmount: number,
  isSuperOrPension: boolean,
  includeTotal?: boolean
): AdviceEstimatedFee[] => {
  const adviceEstimatedFees = calculateEstimatedFeesItems(
    estimatedFeesItems,
    totalRolloverAmount,
    contributionsAmount,
    includeTotal
  );

  return adviceEstimatedFees.map((adviceFeesItem) => {
    const calculatedValues = getCalcualtedValues(adviceFeesItem.calculationTypeId, false, isSuperOrPension, {
      totalRolloverAmount: totalRolloverAmount,
      contributionsAmount: contributionsAmount,
    });

    return {
      ...adviceFeesItem,
      isValid:
        adviceFeesItem.pendingAction === PendingFeeActionType.Delete.name ||
        (adviceFeesItem.percentageOfValue ?? 0) <= calculatedValues.maxPercentage,
    };
  });
};

export const calculateEstimatedFeesItems = <T extends EstimatedFee>(
  estimatedFeesItems: T[],
  totalRolloverAmount: number,
  contributionsAmount: number,
  includeTotal?: boolean
): T[] => {
  includeTotal = includeTotal === undefined ? true : includeTotal;

  const result = estimatedFeesItems.map((estimatedFeesItem) => {
    const calculatedValues = getCalcualtedValues(estimatedFeesItem.calculationTypeId, false, false, {
      totalRolloverAmount: totalRolloverAmount,
      contributionsAmount: contributionsAmount,
    });
    const calculatedAmountAndPercentage = calculateAmountAndPercentageWithTotalAmount(
      estimatedFeesItem,
      calculatedValues.totalAmount,
      true
    );
    return {
      ...estimatedFeesItem,
      amount: calculatedAmountAndPercentage.amount,
      percentageOfValue: calculatedAmountAndPercentage.percentageOfValue,
    };
  });

  return calculateFeesItems(result, includeTotal);
};

export const calculateActiveAdviceFeesItems = (
  estimatedFeesItems: AdviceEstimatedFee[],
  marketValue: number,
  availableCash: number,
  isSuperOrPension: boolean,
  includeTotal?: boolean
): AdviceEstimatedFee[] => {
  const adviceEstimatedFees = calculateActiveFeesItems(estimatedFeesItems, marketValue, availableCash, includeTotal);

  return adviceEstimatedFees.map((adviceFeesItem) => {
    const calculatedValues = getCalcualtedValues(adviceFeesItem.calculationTypeId, true, isSuperOrPension, {
      marketValue: marketValue,
      availableCash: availableCash,
    });

    return {
      ...adviceFeesItem,
      isValid: (adviceFeesItem.percentageOfValue ?? 0) <= calculatedValues.maxPercentage,
    };
  });
};

export const calculateActiveFeesItems = <T extends EstimatedFee>(
  estimatedFeesItems: T[],
  marketValue: number,
  availableCash: number,
  includeTotal?: boolean
): T[] => {
  includeTotal = includeTotal === undefined ? true : includeTotal;

  const result = estimatedFeesItems.map((estimatedFeesItem) => {
    const calculatedValues = getCalcualtedValues(estimatedFeesItem.calculationTypeId, true, false, {
      marketValue: marketValue,
      availableCash: availableCash,
    });
    const calculatedAmountAndPercentage = calculateAmountAndPercentageWithTotalAmount(
      estimatedFeesItem,
      calculatedValues.totalAmount,
      true
    );
    return {
      ...estimatedFeesItem,
      amount: calculatedAmountAndPercentage.amount,
      percentageOfValue: calculatedAmountAndPercentage.percentageOfValue,
    };
  });

  return calculateFeesItems(result, includeTotal);
};

const calculateFeesItems = <T extends EstimatedFee>(estimatedFeesItems: T[], includeTotal?: boolean): T[] => {
  includeTotal = includeTotal === undefined ? true : includeTotal;

  // add total row if result is not empty
  if (includeTotal && estimatedFeesItems.length > 0) {
    const totalFeeData = {
      ...estimatedFeesItems[0],
      index: -1,
      templateCode: null,
      name: '',
      calculationTypeId: FeeCalculationType.TotalFee.id,
      methodId: null,
      amount: estimatedFeesItems.reduce((acc: number, estimatedFeeItem: EstimatedFee) => {
        return +(acc + (estimatedFeeItem.amount || 0)).toFixed(2);
      }, 0),
      frequencyId: null,
      percentageOfValue: estimatedFeesItems.reduce((acc: number, estimatedFeeItem: EstimatedFee) => {
        return +(acc + (estimatedFeeItem.percentageOfValue || 0)).toFixed(4);
      }, 0),
      tieredFeeDetails: {
        items: [],
      },
    };

    estimatedFeesItems.push(totalFeeData);
  }

  return estimatedFeesItems;
};
