import api from '../../../../../../../app/api';
import { buildEncodedQueryString } from '../../../../../../../common';
import { CommonApiEndpoints, GetAssetClassesPayload, InvestmentService, PortfolioDetailResult } from './../../../store/common';
import { ConstraintsApiEndpoints } from './../../constraints/store/thunks';
import { FetchConstraintsPayload, SecurityConstraint } from './../../constraints/store/types';
import { MainDetailsApiEndpoints } from './../../details/store/thunks';
import { InvestmentServiceGetPayload } from './../../details/store/types';
import { ConfigurationsApiEndpoints, StrategicAllocationApiEndpoints } from './../../targets/store/thunks';
import { Configuration, GetConfigurationsPayload, GetStrategicAllocationPayload, StrategicAssetAllocation } from './../../targets/store/types';

export const getDetails = async ({ clientId, investmentServiceVersionId }: InvestmentServiceGetPayload): Promise<InvestmentService> => {
  const queryString = buildEncodedQueryString({
    clientId,
    investmentServiceVersionId,
  });
  const response = await api.get<InvestmentService>(`${MainDetailsApiEndpoints.FetchInvestmentService}${queryString}`);

  return response.data;
};

export const getConstraints = async (payload: FetchConstraintsPayload): Promise<SecurityConstraint[]> => {
  const queryString = buildEncodedQueryString({
    clientId: payload.clientId,
    investmentServiceVersionId: payload.investmentServiceVersionId,
  });
  const response = await api.get<SecurityConstraint[]>(`${ConstraintsApiEndpoints.FetchInvestmentServiceConstraintsAndExcludeRebalance}${queryString}`);

  return response.data;
};

export const getConfigurations = async ({ clientId, investmentServiceVersionId }: GetConfigurationsPayload): Promise<Configuration[]> => {
  const queryString = buildEncodedQueryString({
    clientId,
    investmentServiceVersionId,
  });

  const response = await api.get<Configuration[]>(`${ConfigurationsApiEndpoints.FetchConfigurations}${queryString}`);

  return response.data;
};

export const getStrategicAllocations = async ({ clientId, investmentServiceVersionId }: GetStrategicAllocationPayload): Promise<StrategicAssetAllocation[]> => {
  const queryString = buildEncodedQueryString({
    clientId,
    investmentServiceVersionId,
  });
  const response = await api.get<StrategicAssetAllocation[]>(`${StrategicAllocationApiEndpoints.FetchStrategicAllocations}${queryString}`);

  return response.data;
};

export const getPortfolioAssetClasses = async ({
  clientId,
  effectiveDate,
  groupingType,
  investmentServiceId,
}: GetAssetClassesPayload): Promise<PortfolioDetailResult> => {
  const queryString = buildEncodedQueryString({
    clientId,
    groupingType,
    effectiveDate,
    investmentServiceId,
  });
  const response = await api.get<PortfolioDetailResult>(`${CommonApiEndpoints.FetchInvestmentServiceAssetClasses}${queryString}`);

  return response.data;
};
