import Grid from '@mui/material/Grid';
import React from 'react';
import { ServerSideDataTable } from '../../../../common/components/dataTable/serverSide';
import { DatatableColumn, FilterDataType } from '../../../../common/components/dataTable/types';
import { PagedRequest, PagedResult } from '../../../../store';
import { StrategyItem } from '../store/types';

export interface Props {
  pagedResults?: PagedResult<StrategyItem>;
  pagedRequest: PagedRequest;
  handlePagedRequest: (pagedRequest: PagedRequest) => void;
  handleRowClick: (strategyId: number, versionId: number) => void;
  isLoading: boolean;
  hasErrors: boolean;
}

export function StrategyListTable(props: Props): JSX.Element {
  const { pagedResults, isLoading, hasErrors } = props;

  const columns: DatatableColumn[] = [
    {
      filterDataType: FilterDataType.string,
      name: 'code',
      label: 'CODE',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex) => pagedResults?.results[dataIndex]?.code,
      },
    },
    {
      filterDataType: FilterDataType.string,
      name: 'name',
      label: 'NAME',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex) => pagedResults?.results[dataIndex]?.name,
      },
    },
    {
      filterDataType: FilterDataType.string,
      name: 'description',
      label: 'DESCRIPTION',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex) => pagedResults?.results[dataIndex]?.description,
      },
    },
    {
      filterDataType: FilterDataType.string,
      name: 'type',
      label: 'TYPE',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex) => pagedResults?.results[dataIndex]?.type,
      },
    },
    {
      filterDataType: FilterDataType.string,
      name: 'version',
      label: 'VERSION',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex) => pagedResults?.results[dataIndex]?.version,
      },
    },
    {
      filterDataType: FilterDataType.string,
      name: 'status',
      label: 'STATUS',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex): React.ReactNode => pagedResults?.results[dataIndex]?.status,
      },
    },
  ];

  return (
    <Grid container style={{ width: '100%', margin: '10px' }}>
      <Grid item xs={12}>
        <ServerSideDataTable
          loadingProgress={{ isLoading, hasErrors }}
          columns={columns}
          pagedRequest={props.pagedRequest}
          pagedResult={props.pagedResults}
          options={{
            download: false,
          }}
          handlePagedRequest={props.handlePagedRequest}
          handleRowClick={(rowData: string[], rowMeta: { dataIndex: number; rowIndex: number }) => {
            const strategyId = props.pagedResults?.results[rowMeta.rowIndex].strategyId;
            const versionId = props.pagedResults?.results[rowMeta.rowIndex].versionId;
            if (!!strategyId && !!versionId) {
              props.handleRowClick(strategyId, versionId);
            }
          }}
        ></ServerSideDataTable>
      </Grid>
    </Grid>
  );
}
