import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FetchPagedResults } from '../../../../../../store';
import { DocumentDetails, DocumentsParameters } from '../../../common/store/types';
import { fetchDocumentForEdit, fetchDocuments } from './thunks';
import { DocumentsState } from './types';

export const initialState: DocumentsState = {
  documents: {
    items: {
      pageNumber: 1,
      pageSize: 25,
      totalNumberOfPages: 0,
      totalNumberOfRecords: 0,
      results: [],
    },
  },
  parameters: {
    pagination: {
      pageNumber: 1,
      pageSize: 25,
      queryFields: [
        {
          fieldName: 'created',
          descendingSortDirection: true,
          hasSearchTerm: false,
          isSortTerm: true,
          searchTerm: '',
          operator: '',
        },
      ],
    },
  },
};

export const documentsSlice = createSlice({
  name: 'documents',
  initialState,
  reducers: {
    setAddMode: (state) => {
      state.documents.edit = null;
    },
    cancelAddEditMode: (state) => {
      state.documents.edit = undefined;
    },
    setDocumentsParameters: (state, pagedRequest: PayloadAction<DocumentsParameters>) => {
      state.parameters = pagedRequest.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDocuments.fulfilled, (state, action: PayloadAction<FetchPagedResults<DocumentDetails>>) => {
      state.documents.items = action.payload.results;
      state.documents.edit = undefined;
    });
    builder.addCase(fetchDocumentForEdit.fulfilled, (state, action: PayloadAction<DocumentDetails>) => {
      state.documents.edit = action.payload;
    });
  },
});
