import { Autocomplete, TextField } from '@mui/material';
import React from 'react';
import { BenchmarkItem } from '../store/types';

interface Props {
  items: BenchmarkItem[];
  selectedItem: BenchmarkItem | null;
  setBenchmark: (benchmark: BenchmarkItem | null) => void;
}

export const BenchmarkSelect = (props: Props): JSX.Element => {
  const { selectedItem, items, setBenchmark } = props;
  const benchmarks = items || [];

  return (
    <Autocomplete
      style={{ width: '420px' }}
      id="benchmark-select"
      value={selectedItem}
      options={benchmarks}
      getOptionLabel={(option) => {
        return option.name ? option.name : '';
      }}
      renderInput={(params) => <TextField {...params} label="Benchmark" variant="outlined" fullWidth />}
      onChange={(_event: React.SyntheticEvent<Element, Event>, value: BenchmarkItem | null) => setBenchmark(value)}
    />
  );
};
