import { MarketCode } from '../store/types';

export default function roundUnitsByMarketCode(units: number, marketCode: string): number {
  switch (marketCode) {
    case MarketCode.ADI:
    case MarketCode.ADM:
      return Math.round(units * 100) / 100;
    case MarketCode.OffMarket:
      return Math.round(units * 100000000) / 100000000;
    default:
      return Math.floor(units);
  }
}
