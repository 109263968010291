export const toBase64 = (file: Blob): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if (!!reader.result) {
        const getContent = getBase64Content(reader.result as string);

        return resolve(getContent);
      }
      reject('');
    };
    reader.onerror = (error) => reject(error);
  });

export const getBase64Content = (encodedFile: string): string => encodedFile.substring(encodedFile.indexOf(',') + 1);
