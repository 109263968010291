import { combineReducers } from '@reduxjs/toolkit';
import { modelAssociationsSlice } from '../associations/store/slice';
import { ModelAssociationsState } from '../associations/store/types';
import { modelCommonSlice } from '../common/store/slice';
import { ModelCommonState } from '../common/store/types';
import { modelCompositionSlice } from '../composition/store/slice';
import { ModelCompositionState } from '../composition/store/types';
import { modelDashboardSlice } from '../dashboard/store/slice';
import { ModelDashboardState } from '../dashboard/store/types';
import { modelDetailsSlice } from '../details/store/slice';
import { ModelDetailsState } from '../details/store/types';
import { modelListSlice } from '../list/store/slice';
import { ModelListState } from '../list/store/types';
import { modelPerformanceSlice } from '../performance/store/slice';
import { ModelPerformanceState } from '../performance/store/types';

export type ModelState = {
  list: ModelListState;
  common: ModelCommonState;
  modelDashboard: ModelDashboardState;
  modelPerformance: ModelPerformanceState;
  modelComposition: ModelCompositionState;
  modelDetails: ModelDetailsState;
  modelAssociations: ModelAssociationsState;
};

export const modelReducer = combineReducers<ModelState>({
  list: modelListSlice.reducer,
  common: modelCommonSlice.reducer,
  modelDashboard: modelDashboardSlice.reducer,
  modelPerformance: modelPerformanceSlice.reducer,
  modelComposition: modelCompositionSlice.reducer,
  modelDetails: modelDetailsSlice.reducer,
  modelAssociations: modelAssociationsSlice.reducer,
});
