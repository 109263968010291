import { RebalanceState, Summary, TradeAction } from '../store/types';
import isCashTrade from './isCashTrade';

export function getRebalanceSummary(rebalanceEdit: RebalanceState): Summary {
  const openingCash = rebalanceEdit.rebalance?.availableCMA ?? 0;
  const totalBuysCash =
    rebalanceEdit.rebalance?.trades?.reduce(
      (prev, next) =>
        next.tradeAction === TradeAction.Buy && !isCashTrade(next) ? prev + next.newCalculatedValue : prev ?? 0,
      0
    ) ?? 0;
  const totalSellsCash =
    rebalanceEdit.rebalance?.trades?.reduce(
      (prev, next) =>
        (next.tradeAction === TradeAction.Sell || next.tradeAction === TradeAction.All) && !isCashTrade(next)
          ? prev + next.newCalculatedValue
          : prev ?? 0,
      0
    ) ?? 0;
  const proposedCash = openingCash + totalSellsCash - totalBuysCash;
  return { openingCash, totalBuysCash, totalSellsCash, proposedCash } as Summary;
}
