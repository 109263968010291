import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import api from '../../../../app/api';
import { buildEncodedQueryString, convertToCSVFormat, CSVDataType, downloadCsv } from '../../../../common';
import { PagedResult } from '../../../../store';
import { AfslViewModel } from './../../../bulk/common/store/types';
import { OnboardingWizardStatus } from './enum';
import {
  ClientItem,
  ClientsSummary,
  DownloadClientListPayload,
  fetchClientListPayload,
  fetchPagedResults,
  OnboardingWizardsModel,
  ProposedOrders,
  QuickOnboardClient,
  ResetStatusPayload,
} from './types';

export enum ClientListActionTypes {
  FetchClientsSummary = '@@client/list/FetchClientsSummary',
  FetchClientList = '@@client/list/FetchClientList',
  DownloadProposedOrders = '@@client/list/FetchProposedOrders',
  Download = '@@client/list/Download',
  FetchAllOnboarding = '@@onboarding/fetchAll',
  DeleteOnboardingWizard = '@@onboarding/delete',
  ResetStatus = '@@onboarding/ResetStatus',
  ResubmitClient = '@@clients/ResubmitClient',
  // Quick Onboard
  FetchQuickOnboardingAfsls = '@@clients/FetchQuickOnboardingAfsls',
  CreateQuickOnboardClient = '@@clients/CreateQuickOnboardClient',
}

export enum ClientListApiEndpoints {
  fetchClientsSummary = '/bff/GetClientSummary',
  fetchClientList = '/bff/GetClientList',
  FetchProposedOrders = 'onboarding/GetProposedOrdersByClientId',
  FetchAllOnboarding = 'onboarding/GetActiveOnboardingWizards',
  DeleteOnboardingWizard = 'onboarding/DeleteOnboardingWizard',
  ResetStatus = 'onboarding/ChangeOnboardingWizardStatus',
  ResubmitClient = 'onboarding/ChangeOnboardingWizardStatus',
  // Quick Onboard
  FetchAfsls = 'bff/GetAfslsPracticesAdvisersForUser',
  CreateQuickOnboardClient = 'customers/CreateClientAccount',
}

export const fetchClientsSummary = createAsyncThunk(
  ClientListActionTypes.FetchClientsSummary,
  async (isDataFromTriumph: boolean) => {
    const response = await api.post<ClientsSummary>(
      `${ClientListApiEndpoints.fetchClientsSummary}?isDataFromTriumph=${isDataFromTriumph}`
    );
    return response.data;
  }
);

export const fetchClientList = createAsyncThunk(
  ClientListActionTypes.FetchClientList,
  async (wrapper: fetchClientListPayload) => {
    const response = await getPagedClientList(wrapper);
    return {
      results: response.data,
      pagination: wrapper.pagination,
    } as fetchPagedResults<ClientItem>;
  }
);

export const downloadProposedOrders = createAsyncThunk(
  ClientListActionTypes.DownloadProposedOrders,
  async (clientId: number) => {
    const queryString = buildEncodedQueryString({
      clientId: clientId,
    });

    const response = await api.get<ProposedOrders>(`${ClientListApiEndpoints.FetchProposedOrders}${queryString}`);
    const csvData: CSVDataType[] = convertToCSVFormat(response.data.proposedOrders);

    downloadCsv(csvData, `Proposed_Orders_`);

    return { message: 'CSV downloaded' };
  }
);

export const downloadClientList = createAsyncThunk(
  ClientListActionTypes.Download,
  async (wrapper: DownloadClientListPayload) => {
    const response = await getPagedClientList({
      ...wrapper,
      pagination: { ...wrapper.pagination, pageNumber: 1, pageSize: 9999999 },
    });
    const csvData: CSVDataType[] = convertToCSVFormat(response.data.results);

    downloadCsv(csvData, `Clients_List_`);

    return { message: 'CSV downloaded' };
  }
);

const getPagedClientList = async (request: fetchClientListPayload) => {
  const body = {
    isDataFromTriumph: request.isDataFromTriumph ?? false,
    clientNameSearch: request.inputs.clientNameSearch,
    pagedRequest: { ...request.pagination, pageNumber: request.pagination.pageNumber },
  };
  return await api.post<PagedResult<ClientItem>>(ClientListApiEndpoints.fetchClientList, body);
};

export const fetchOnboardingWizardsModels = createAsyncThunk(ClientListActionTypes.FetchAllOnboarding, async () => {
  const response = await api.get<OnboardingWizardsModel[]>(`${ClientListApiEndpoints.FetchAllOnboarding}`);
  return response.data;
});

export const deleteOnboardingWizard = createAsyncThunk(
  ClientListActionTypes.DeleteOnboardingWizard,
  async (payload: { id: string }, thunkApi) => {
    const queryString = buildEncodedQueryString({
      id: payload.id,
    });

    const response = await api.delete(`${ClientListApiEndpoints.DeleteOnboardingWizard}${queryString}`);
    thunkApi.dispatch(fetchOnboardingWizardsModels());

    return response.data;
  }
);

// Quick Onboard
export const createQuickOnboardClient = createAsyncThunk(
  ClientListActionTypes.CreateQuickOnboardClient,
  async (payload: QuickOnboardClient) => {
    const response = await api
      .post<QuickOnboardClient, AxiosResponse<{ clientId: number }>>(
        ClientListApiEndpoints.CreateQuickOnboardClient,
        payload
      )
      .then((clientIdResponse) => {
        return {
          clientId: clientIdResponse.data.clientId,
          message:
            'New client created successfully. Please allow up to a minute for this to show up in your client list',
        };
      })
      .catch(() => ({ error: 'Failed to create new client.' }));

    return response;
  }
);

export const fetchAfsls = createAsyncThunk(ClientListActionTypes.FetchQuickOnboardingAfsls, async () => {
  const response = await api.get<AfslViewModel>(`${ClientListApiEndpoints.FetchAfsls}`);
  return response.data.afsls;
});

export const resubmitClient = createAsyncThunk(ClientListActionTypes.ResubmitClient, async (id: string) => {
  // strip out the 'items' from the editable collections
  const payload = {
    id,
  };

  // save to backend
  return api
    .post(`${ClientListApiEndpoints.ResubmitClient}`, payload)
    .then(() => {
      return { message: 'Client resubmitted.  Client will be available in a few minutes.' };
    })
    .catch(() => {
      return { message: 'Failed to submit client', variant: 'error' };
    });
});

export const resetStatus = createAsyncThunk(ClientListActionTypes.ResetStatus, async (payload: ResetStatusPayload) => {
  return await api
    .post(`${ClientListApiEndpoints.ResetStatus}`, {
      id: payload.id,
      statusId: OnboardingWizardStatus.Active.id,
      clientId: payload.clientId,
    })
    .then(() => {
      return {
        ...payload,
        message: 'Status reset',
      };
    })
    .catch(() => ({ ...payload, message: 'Could reset status at this time.', variant: 'error' }));
});
