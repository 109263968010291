import api from '../../../../../app/api';
import { GainsLossesParameters, GainsLossesResult } from './types';

export async function fetchUnrealisedService(parameters: GainsLossesParameters, clientId: number): Promise<GainsLossesResult> {
  const portfolioId = parameters.selectedPortfolio?.investmentProgramId;
  const date = parameters.dates.dateTo;
  const groupingType = parameters.groupingType;

  let queryString = `?clientId=${clientId}&date=${date}&groupingType=${groupingType}`;
  if (portfolioId != null) {
    queryString += `&investmentServiceId=${portfolioId}`;
  }

  const response = await api.get<GainsLossesResult>(`/unrealised/GetPortfolioUnrealisedGainsLosses${queryString}`);
  return Promise.resolve<GainsLossesResult>(response.data);
}

export async function fetchRealisedService(parameters: GainsLossesParameters, clientId: number): Promise<GainsLossesResult> {
  const portfolioId = parameters.selectedPortfolio?.investmentProgramId;
  const startDate = parameters.dates.dateFrom;
  const endDate = parameters.dates.dateTo;
  const groupingType = parameters.groupingType;

  let queryString = `?clientId=${clientId}&startDate=${startDate}&endDate=${endDate}&groupingType=${groupingType}`;
  if (portfolioId != null) {
    queryString += `&investmentServiceId=${portfolioId}`;
  }

  const response = await api.get<GainsLossesResult>(`/realised/GetPortfolioRealisedGainsLosses${queryString}`);
  return Promise.resolve<GainsLossesResult>(response.data);
}
