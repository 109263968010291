import { AnyAction } from 'redux';

export interface LoadingState {
  [index: string]: boolean;
}

const loadingReducer = (state = {} as LoadingState, action: AnyAction): LoadingState => {
  const { type } = action;
  const matches = /(.*)\/(Request|pending|Success|fulfilled|Failed|rejected)/.exec(type);

  if (!matches) return state;

  const [, requestName, requestState] = matches;
  return {
    ...state,
    [requestName]: requestState === 'Request' || requestState === 'pending',
  };
};

export default loadingReducer;
