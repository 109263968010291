import { createSelector } from '@reduxjs/toolkit';
import { selectSuperSimplifierDetails } from '../../store/selectors';

export const selectBenefitStatementDetails = createSelector(selectSuperSimplifierDetails, (superSimplfierDetails) => {
  return superSimplfierDetails.benefitStatement.details;
});

export const selectPreservationComponentDetails = createSelector(selectBenefitStatementDetails, (selectBenefitStatementDetails) => {
  return selectBenefitStatementDetails.preservationComponentDetails;
});

export const selectTaxationDetails = createSelector(selectBenefitStatementDetails, (selectBenefitStatementDetails) => {
  return selectBenefitStatementDetails.taxationDetails;
});

export const selectOtherBenefitsDetails = createSelector(selectBenefitStatementDetails, (selectBenefitStatementDetails) => {
  return selectBenefitStatementDetails.otherBenefits;
});

export const selectTransactionDetails = createSelector(selectBenefitStatementDetails, (selectBenefitStatementDetails) => {
  return selectBenefitStatementDetails.transactions;
});
