import { ExpandMore } from '@mui/icons-material';
import { IconButton, Paper, TableCell, TableRow, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import React, { useState } from 'react';
import {
  CSVDataType,
  DateTimeFormat,
  convertToCSVFormat,
  downloadCsv,
  formatNumberCommaSeparatedForCSV,
  getLocalDateTime,
  negativeDollars,
  uuidv4,
  xlNumFormat,
} from 'src/common';
import { ClientSideDataTable } from 'src/common/components/dataTable/clientSide';
import { DatatableColumn } from 'src/common/components/dataTable/types';
import {
  ExpandedRow,
  RealisedCapitalGainsLossesResult,
  RealisedGainsLossesResultHeaderCSVRow,
  RealisedGainsLossesResultItemCSVRow,
  RealisedGainsLossesResultItemCSVRowWithLineItems,
  RealisedParcelDetails,
  RealisedReportDetail,
} from '../store/types';
import { DateTime } from 'luxon';
import { ADVISER_CONTACT_EMAIL } from 'src/common/utils/dashContactDetails';

interface Props {
  results: RealisedCapitalGainsLossesResult | undefined;
  isLoading: boolean;
  hasErrors: boolean;
  generateRealisedCsv: boolean;
}

const useStyles = makeStyles(() => ({
  open: {
    transform: 'rotate(360deg)',
    transition: 'transform 0.25s',
  },
  close: {
    transform: ' rotate(270deg)',
    transition: 'transform 0.25s',
  },
}));

export default function CapitalRealisedGainsLossesTable(props: Props): JSX.Element {
  const { isLoading, hasErrors } = props;
  const rows: RealisedReportDetail[] = [];

  const [expanded, setExpanded] = useState<ExpandedRow>([]);
  const [open, setOpen] = useState(false);

  const serialiseRealisedData = (input: RealisedCapitalGainsLossesResult | undefined) => {
    if (!!input) {
      const securityDetailsItems = input.securityDetails && input.securityDetails.length ? input.securityDetails : [];
      securityDetailsItems.forEach((secDetails) => {
        const groupHeader: RealisedReportDetail = {
          ...(secDetails.securityTotals as RealisedReportDetail),
          isGroup: true,
          items: [],
        };

        secDetails.parcelDetails.forEach((parcel) => {
          const body: RealisedReportDetail = {
            ...(parcel as unknown as RealisedReportDetail),
            isGroup: false,
            items: [],
            acquisitionDate: parcel.acquisitionDate,
            strategy: parcel.strategy,
            disposalDate: parcel.disposalDate,
          };
          groupHeader.items.push(body);
        });
        rows.push(groupHeader);
      });
    }
  };

  serialiseRealisedData(props.results);

  function exportHandler(gainsLossesItems: RealisedParcelDetails[]) {
    const tree: RealisedGainsLossesResultHeaderCSVRow[] = [];

    function updateTree(
      node: RealisedGainsLossesResultItemCSVRow,
      groupName: string,
      groupSubName: string,
      isTotal: string
    ) {
      tree.push({
        isTotal: isTotal,
        securityCode: groupName,
        securityName: groupSubName,
        disposalDate: node.disposalDate
          ? DateTime.fromISO(node.disposalDate).toLocaleString(DateTime.DATE_SHORT)
          : 'N/A',
        acquisitionDate: node.acquisitionDate
          ? DateTime.fromISO(node.acquisitionDate).toLocaleString(DateTime.DATE_SHORT)
          : 'N/A',
        units: node.units ? (xlNumFormat(node.units, 4) as string) : '',
        originalCostBase: formatNumberCommaSeparatedForCSV(node.originalCostBase),
        taxFree: formatNumberCommaSeparatedForCSV(node.taxFree),
        taxDeferred: formatNumberCommaSeparatedForCSV(node.taxDeferred),
        taxExempt: formatNumberCommaSeparatedForCSV(node.taxExempt),
        cgtConcession: formatNumberCommaSeparatedForCSV(node.cgtConcession),
        amitCostBaseIncreaseDecrease: formatNumberCommaSeparatedForCSV(node.amitCostBaseIncreaseDecrease),
        adjustedCostbase: formatNumberCommaSeparatedForCSV(node.adjustedCostbase),
        reducedCostbase: formatNumberCommaSeparatedForCSV(node.reducedCostbase),
        proceeds: formatNumberCommaSeparatedForCSV(node.proceeds),
        realisedGainLoss: negativeDollars(formatNumberCommaSeparatedForCSV(node.realisedGainLoss)) as string,
        indexation: xlNumFormat(node.indexation) as string,
        discountable: xlNumFormat(node.discountable) as string,
        other: xlNumFormat(node.other) as string,
        capitalLoss: xlNumFormat(node.capitalLoss) as string,
        strategy: node.strategy,
      });
    }

    gainsLossesItems.forEach((row) => {
      const groupRow = row as RealisedGainsLossesResultItemCSVRowWithLineItems;
      groupRow.items.forEach((lineItemRow: RealisedParcelDetails) => {
        const lineItemRowForCSV = lineItemRow as RealisedGainsLossesResultItemCSVRow;
        updateTree(
          lineItemRowForCSV as RealisedGainsLossesResultItemCSVRow,
          groupRow.securityCode,
          groupRow.securityName,
          'N'
        );
      });
      updateTree(row as RealisedGainsLossesResultItemCSVRow, groupRow.securityCode, groupRow.securityName, 'Y');
    });

    const data: CSVDataType[] = convertToCSVFormat(tree);
    downloadCsv(data, `Capital_Gains_Losses_Realised_`);
  }

  if (props.generateRealisedCsv) {
    exportHandler(rows);
  }

  const gainsLossesHeader = (row: RealisedReportDetail, parentIndex: number, rowIndex: number) => {
    return (
      <React.Fragment key={uuidv4()}>
        <TableRow key={uuidv4()}>
          <TableCell style={{ width: '48px', maxWidth: '48px', padding: '0 0 0 5px' }}>
            <IconButton
              aria-label="expand"
              size="small"
              style={{ padding: '0' }}
              onClick={() => {
                setOpen(!open);
                if (expanded && expanded[parentIndex] && expanded[parentIndex].includes(rowIndex)) {
                  const update = expanded[parentIndex].filter((row) => row !== rowIndex);
                  setExpanded({ ...expanded, [parentIndex]: update });
                } else {
                  if (expanded[parentIndex]) {
                    const update = [...expanded[parentIndex].values(), rowIndex];
                    setExpanded({ ...expanded, [parentIndex]: update });
                  } else {
                    setExpanded({ ...expanded, [parentIndex]: [rowIndex] });
                  }
                }
              }}
            >
              <ExpandMore
                className={clsx(
                  !(expanded[parentIndex] && expanded[parentIndex].includes(rowIndex)) && classes.close,
                  expanded[parentIndex] && expanded[parentIndex].includes(rowIndex) && classes.open
                )}
                style={{ padding: 0 }}
              />
            </IconButton>
          </TableCell>
          <TableCell colSpan={2}>
            <Typography variant="h5" color={'primary'}>
              {row.securityCode}
            </Typography>
            <Typography variant="h5" color={'textSecondary'}>
              {row.securityName}
            </Typography>
          </TableCell>
        </TableRow>
        {row.items.map((childRow: RealisedReportDetail) => gainsLossesDetail(childRow, parentIndex, rowIndex, false))}
      </React.Fragment>
    );
  };

  const gainsLossesDetail = (row: RealisedReportDetail, parentIndex: number, key: number, visible: boolean) => {
    return (
      <TableRow
        key={uuidv4()}
        style={{
          visibility:
            (expanded[parentIndex] && expanded[parentIndex].includes(key)) || visible ? 'visible' : 'collapse',
        }}
      >
        <TableCell style={{ width: '48px', maxWidth: '48px' }}></TableCell>
        <TableCell style={{ width: '48px', maxWidth: '48px' }}>
          {getLocalDateTime(row.disposalDate, DateTimeFormat.Short)}
        </TableCell>
        <TableCell style={{ width: '48px', maxWidth: '48px' }}>
          {getLocalDateTime(row.acquisitionDate, DateTimeFormat.Short)}
        </TableCell>
        <TableCell align="right">{xlNumFormat(row.units, 4)}</TableCell>
        <TableCell align="right">{xlNumFormat(row.originalCostBase)}</TableCell>
        <TableCell align="right">{xlNumFormat(row.taxFree)}</TableCell>
        <TableCell align="right">{xlNumFormat(row.taxDeferred)}</TableCell>
        <TableCell align="right">{xlNumFormat(row.taxExempt)}</TableCell>
        <TableCell align="right">{xlNumFormat(row.cgtConcession)}</TableCell>
        <TableCell align="right">{xlNumFormat(row.amitCostBaseIncreaseDecrease)}</TableCell>
        <TableCell align="right">{xlNumFormat(row.adjustedCostbase)}</TableCell>
        <TableCell align="right">{xlNumFormat(row.reducedCostbase)}</TableCell>
        <TableCell align="right">{xlNumFormat(row.proceeds)}</TableCell>
        <TableCell align="right">
          <Typography
            variant="h5"
            align="right"
            color={
              row.realisedGainLoss == 0 || !row.realisedGainLoss
                ? 'textPrimary'
                : row.realisedGainLoss < 0
                ? 'error'
                : 'secondary'
            }
          >
            {xlNumFormat(row.realisedGainLoss)}
          </Typography>
        </TableCell>

        <TableCell align="right">{xlNumFormat(row.indexation)}</TableCell>
        <TableCell align="right">{xlNumFormat(row.discountable)}</TableCell>
        <TableCell align="right">{xlNumFormat(row.other)}</TableCell>
        <TableCell align="right">{xlNumFormat(row.capitalLoss)}</TableCell>
        <TableCell align="right">{row.strategy}</TableCell>
      </TableRow>
    );
  };

  const detailedColumn = (rowMeta: { dataIndex: number; rowIndex: number }): React.ReactNode => {
    return (
      <>
        {rows[rowMeta.dataIndex]?.items.map((item: RealisedReportDetail, index: number) => {
          if (item.isGroup) {
            return gainsLossesHeader(item, rowMeta.dataIndex, index);
          } else {
            return gainsLossesDetail(item, rowMeta.dataIndex, index, true);
          }
        })}
      </>
    );
  };

  const disposalDateColumn = (dataIndex: number): React.ReactNode => {
    return (
      <>
        <Typography variant="h5" color={'primary'}>
          {rows.length > 0 ? rows[dataIndex]?.securityCode : ''}
        </Typography>
        <Typography variant="h5" color={'textSecondary'}>
          {rows.length > 0 ? rows[dataIndex]?.securityName : ''}
        </Typography>
      </>
    );
  };

  const acquisitionDateColumn = (dataIndex: number): React.ReactNode => {
    return (
      <Typography variant="h5" align="right">
        {getLocalDateTime(rows[dataIndex]?.acquisitionDate, DateTimeFormat.FinancialDate)}
      </Typography>
    );
  };

  const unitsColumn = (dataIndex: number): React.ReactNode => {
    return (
      <Typography variant="h5" align="right">
        {xlNumFormat(rows[dataIndex]?.units, 4)}
      </Typography>
    );
  };

  const originalCostBaseColumn = (dataIndex: number): React.ReactNode => {
    return (
      <Typography variant="h5" align="right">
        {rows.length > 0 ? xlNumFormat(rows[dataIndex]?.originalCostBase) : ''}
      </Typography>
    );
  };

  const taxFreeColumn = (dataIndex: number): React.ReactNode => {
    return (
      <Typography variant="h5" align="right">
        {rows.length > 0 ? xlNumFormat(rows[dataIndex]?.taxFree) : ''}
      </Typography>
    );
  };

  const taxDeferredColumn = (dataIndex: number): React.ReactNode => {
    return (
      <Typography variant="h5" align="right">
        {rows.length > 0 ? xlNumFormat(rows[dataIndex]?.taxDeferred) : ''}
      </Typography>
    );
  };

  const taxExemptColumn = (dataIndex: number): React.ReactNode => {
    return (
      <Typography variant="h5" align="right">
        {rows.length > 0 ? xlNumFormat(rows[dataIndex]?.taxExempt) : ''}
      </Typography>
    );
  };

  const cgtConcessionColumn = (dataIndex: number): React.ReactNode => {
    return (
      <Typography variant="h5" align="right">
        {rows.length > 0 ? xlNumFormat(rows[dataIndex]?.cgtConcession) : ''}
      </Typography>
    );
  };

  const amitCostBaseIncreaseDecreaseColumn = (dataIndex: number): React.ReactNode => {
    return (
      <Typography variant="h5" align="right">
        {rows.length > 0 ? xlNumFormat(rows[dataIndex]?.amitCostBaseIncreaseDecrease) : ''}
      </Typography>
    );
  };

  const adjustedCostbaseColumn = (dataIndex: number): React.ReactNode => {
    return (
      <Typography variant="h5" align="right">
        {rows.length > 0 ? xlNumFormat(rows[dataIndex]?.adjustedCostbase) : ''}
      </Typography>
    );
  };

  const reducedCostbase = (dataIndex: number): React.ReactNode => {
    return (
      <Typography variant="h5" align="right">
        {rows.length > 0 ? xlNumFormat(rows[dataIndex]?.reducedCostbase) : ''}
      </Typography>
    );
  };

  const proceedsColumn = (dataIndex: number): React.ReactNode => {
    return (
      <Typography variant="h5" align="right">
        {rows.length > 0 ? xlNumFormat(rows[dataIndex]?.proceeds) : ''}
      </Typography>
    );
  };

  const realisedGainLossColumn = (dataIndex: number): React.ReactNode => {
    const gainAndLoss = rows[dataIndex]?.realisedGainLoss;
    return (
      <Typography
        variant="h5"
        align="right"
        color={!gainAndLoss ? 'textPrimary' : gainAndLoss < 0 ? 'error' : 'secondary'}
      >
        {negativeDollars(xlNumFormat(rows[dataIndex]?.realisedGainLoss))}
      </Typography>
    );
  };

  const indexationColumn = (dataIndex: number): React.ReactNode => {
    return (
      <Typography variant="h5" align="right">
        {rows.length > 0 ? xlNumFormat(rows[dataIndex]?.indexation) : ''}
      </Typography>
    );
  };

  const discountableColumn = (dataIndex: number): React.ReactNode => {
    return (
      <Typography variant="h5" align="right">
        {rows.length > 0 ? xlNumFormat(rows[dataIndex]?.discountable) : ''}
      </Typography>
    );
  };

  const otherColumn = (dataIndex: number): React.ReactNode => {
    return (
      <Typography variant="h5" align="right">
        {rows.length > 0 ? xlNumFormat(rows[dataIndex]?.other) : ''}
      </Typography>
    );
  };

  const capitalLossColumn = (dataIndex: number): React.ReactNode => {
    return (
      <Typography variant="h5" align="right">
        {rows.length > 0 ? xlNumFormat(rows[dataIndex]?.capitalLoss) : ''}
      </Typography>
    );
  };

  const strategyColumn = (dataIndex: number): React.ReactNode => {
    return (
      <Typography variant="h5" align="right">
        {rows[dataIndex]?.strategy}
      </Typography>
    );
  };

  const columns: DatatableColumn[] = [
    {
      name: 'DISPOSALDATE',
      label: 'DISPOSAL DATE',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex: number): React.ReactNode => disposalDateColumn(dataIndex),
      },
    },
    {
      name: 'ACQUISITIONDATE',
      label: 'ACQUISITION DATE',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex: number): React.ReactNode => acquisitionDateColumn(dataIndex),
      },
    },
    {
      name: 'UNITS',
      label: 'UNITS',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex: number): React.ReactNode => unitsColumn(dataIndex),
        setCellHeaderProps: () => {
          return {
            style: { textAlign: 'right' },
          };
        },
      },
    },
    {
      name: 'ORIGINALCOSTBASE',
      label: 'ORIGINAL COST BASE',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex: number): React.ReactNode => originalCostBaseColumn(dataIndex),
        setCellHeaderProps: () => {
          return {
            style: { textAlign: 'right' },
          };
        },
      },
    },
    {
      name: 'TAXFREE',
      label: 'TAX FREE',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex: number): React.ReactNode => taxFreeColumn(dataIndex),
        setCellHeaderProps: () => {
          return {
            style: { textAlign: 'right' },
          };
        },
      },
    },
    {
      name: 'TAXDEFERRED',
      label: 'TAX DEFERRED',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex: number): React.ReactNode => taxDeferredColumn(dataIndex),
        setCellHeaderProps: () => {
          return {
            style: { textAlign: 'right' },
          };
        },
      },
    },
    {
      name: 'TAXEXEMPT',
      label: 'TAX EXEMPT',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex: number): React.ReactNode => taxExemptColumn(dataIndex),
        setCellHeaderProps: () => {
          return {
            style: { textAlign: 'right' },
          };
        },
      },
    },
    {
      name: 'CGTCONCESSION',
      label: 'CGT CONCESSION',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex: number): React.ReactNode => cgtConcessionColumn(dataIndex),
        setCellHeaderProps: () => {
          return {
            style: { textAlign: 'right' },
          };
        },
      },
    },
    {
      name: 'COSTBASEINCREASEDECREASE',
      label: 'COST BASE INCREASE (DECREASE)',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex: number): React.ReactNode => amitCostBaseIncreaseDecreaseColumn(dataIndex),
        setCellHeaderProps: () => {
          return {
            style: { textAlign: 'right' },
          };
        },
      },
    },
    {
      name: 'ADJUSTEDCOSTBASE',
      label: 'ADJUSTED COST BASE',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex: number): React.ReactNode => adjustedCostbaseColumn(dataIndex),
        setCellHeaderProps: () => {
          return {
            style: { textAlign: 'right' },
          };
        },
      },
    },
    {
      name: 'REDUCEDCOSTBASE',
      label: 'REDUCED COST BASE',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex: number): React.ReactNode => reducedCostbase(dataIndex),
        setCellHeaderProps: () => {
          return {
            style: { textAlign: 'right' },
          };
        },
      },
    },
    {
      name: 'PROCEEDS',
      label: 'PROCEEDS',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex: number): React.ReactNode => proceedsColumn(dataIndex),
        setCellHeaderProps: () => {
          return {
            style: { textAlign: 'right' },
          };
        },
      },
    },
    {
      name: 'REALISEDGAINLOSS',
      label: 'REALISED GAIN/LOSS',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex: number): React.ReactNode => realisedGainLossColumn(dataIndex),
        setCellHeaderProps: () => {
          return {
            style: { textAlign: 'right' },
          };
        },
      },
    },
    {
      name: 'INDEXATION',
      label: 'INDEXATION',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex: number): React.ReactNode => indexationColumn(dataIndex),
        setCellHeaderProps: () => {
          return {
            style: { textAlign: 'right' },
          };
        },
      },
    },
    {
      name: 'DISCOUNTABLE',
      label: 'DISCOUNTABLE',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex: number): React.ReactNode => discountableColumn(dataIndex),
        setCellHeaderProps: () => {
          return {
            style: { textAlign: 'right' },
          };
        },
      },
    },
    {
      name: 'OTHER',
      label: 'OTHER',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex: number): React.ReactNode => otherColumn(dataIndex),
        setCellHeaderProps: () => {
          return {
            style: { textAlign: 'right' },
          };
        },
      },
    },
    {
      name: 'CAPITALLOSS',
      label: 'CAPITAL LOSS',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex: number): React.ReactNode => capitalLossColumn(dataIndex),
        setCellHeaderProps: () => {
          return {
            style: { textAlign: 'right' },
          };
        },
      },
    },
    {
      name: 'STRATEGY',
      label: 'STRATEGY',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex: number): React.ReactNode => strategyColumn(dataIndex),
        setCellHeaderProps: () => {
          return {
            style: { textAlign: 'right' },
          };
        },
      },
    },
  ];

  const classes = useStyles();

  const noRealisedDataMsg =
    `There are no disposals for this account for the date range selected. Please choose another date range to view Realised Capital Gains and Losses for that period. For any assistance, please contact our Adviser Services team on 1300 726 008 or ${ADVISER_CONTACT_EMAIL}`;

  return (
    <>
      <Paper elevation={0} style={{ marginBottom: '40px' }}>
        <ClientSideDataTable
          loadingProgress={{ isLoading, hasErrors }}
          columns={columns}
          data={rows ?? []}
          options={{
            filter: false,
            viewColumns: false,
            expandableRows: true,
            expandableRowsOnClick: true,
            renderExpandableRow: (_rowData, rowMeta) => detailedColumn(rowMeta),
          }}
          id="realised-client-gainslosses-datatable"
          noDataLabel={noRealisedDataMsg}
        ></ClientSideDataTable>
      </Paper>
    </>
  );
}
