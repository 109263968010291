import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { RootState } from '../../../reducers';
import { selectStrategy } from '../store/selectors';
import { StrategyTabs } from './components/StrategyTabs';
import { fetchStrategyDashboard } from './store/thunks';

const mapStateToProps = (state: RootState) => ({
  strategyDashboard: selectStrategy(state),
});

const mapDispatchToProps = {
  fetchStrategyDashboard,
};

interface MatchParams {
  strategyId: string;
  strategyVersionId: string;
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector> & RouteComponentProps<MatchParams>;
export const CommonContainer = connect(mapStateToProps, mapDispatchToProps)(StrategyTabs);
