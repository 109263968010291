import { combineReducers } from '@reduxjs/toolkit';
import { strategyAssociationsSlice } from '../associations/store/slice';
import { StrategyAssociations } from '../associations/store/types';
import { strategyCommonSlice } from '../common/store/slice';
import { StrategyCommonState } from '../common/store/types';
import { strategyCompositionSlice } from '../composition/store/slice';
import { StrategyCompositionState } from '../composition/store/types';
import { strategyDashboardSlice } from '../dashboard/store/slice';
import { StrategyDashboardState } from '../dashboard/store/types';
import { strategyDetailsSlice } from '../details/store/slice';
import { StrategyDetailsState } from '../details/store/types';
import { strategyPerformanceSlice } from '../performance/store/slice';
import { StrategyPerformanceState } from '../performance/store/types';

export type StrategyState = {
  common: StrategyCommonState;
  strategyAssociations: StrategyAssociations;
  strategyDetails: StrategyDetailsState;
  strategyComposition: StrategyCompositionState;
  strategyPerformance: StrategyPerformanceState;
  strategyDashboard: StrategyDashboardState;
};

export const strategyReducer = combineReducers<StrategyState>({
  common: strategyCommonSlice.reducer,
  strategyAssociations: strategyAssociationsSlice.reducer,
  strategyDetails: strategyDetailsSlice.reducer,
  strategyComposition: strategyCompositionSlice.reducer,
  strategyPerformance: strategyPerformanceSlice.reducer,
  strategyDashboard: strategyDashboardSlice.reducer,
});
