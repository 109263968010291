import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EditableCollection } from '../../../../../../common/store/types/editableCollection';
import { AddressDetails, DeleteAddressesModel } from '../../../common/store/types';
import { deleteAddress, fetchAddresses, updateAddress } from './thunks';

export const initialState: EditableCollection<AddressDetails, number> = {
  items: [],
  edit: undefined,
};

export const addressesSlice = createSlice({
  name: 'addresses',
  initialState,
  reducers: {
    setEdit: (state, action: PayloadAction<number | null>) => {
      state.edit = action.payload;
    },
    clearEdit: (state) => {
      state.edit = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAddresses.fulfilled, (state, action: PayloadAction<AddressDetails[]>) => {
      state.items = action.payload;
    });
    builder.addCase(updateAddress.fulfilled, (state) => {
      state.edit = undefined;
    });
    builder.addCase(deleteAddress.fulfilled, (state, action: PayloadAction<DeleteAddressesModel>) => {
      const addressIndex = state.items.findIndex((i) => i.addressId === action.payload.addressId);
      if (addressIndex >= 0) {
        state.items.splice(addressIndex, 1);
      }
    });
  },
});
