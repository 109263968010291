import { Backdrop, Box, Fade, Modal, OutlinedInput, Paper, TextField, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { DateTimeFormat, formatDollars, formatPercentage, getLocalDateTime } from '../../../../../common';
import { useDebounce } from '../../../../../common/hooks';
import {
  AddTradePayload,
  FetchSecurityRequestPayload,
  FetchSecuritySearchRequestPayload,
  MenuOptions,
  Rebalance,
  Security,
  SecuritySearchResult,
  SecuritySearchResults,
  Trade,
  TradeMethod,
} from '../store/types';
import { roundUnitsByMarketCode } from '../utils';
import decimalPlacesByMarketCode from '../utils/decimalPlacesByMarketCode';
import WO2Button from './../../../../../common/components/button/Button';
import QuickOrderBackground from './themes/light/images/quickOrderBackground.png';

interface Props {
  rebalance: Rebalance;
  fetchSecurities: (payload: FetchSecuritySearchRequestPayload) => void;
  fetchSecurity: (payload: FetchSecurityRequestPayload) => void;
  addTrade: (payload: AddTradePayload) => void;
  setClearGroupTrades: (payload: Trade[]) => void;
  setClearSecuritySearch: () => void;
  displayMenuOption: MenuOptions;
  setMenuOption: React.Dispatch<React.SetStateAction<MenuOptions>>;
  selectedSecurity: Security;
  securitySearchResults: SecuritySearchResults;
}

export default function QuickOrder(props: Props): JSX.Element {
  const {
    rebalance,
    fetchSecurities,
    fetchSecurity,
    addTrade,
    setClearSecuritySearch,
    displayMenuOption,
    setMenuOption,
    selectedSecurity,
    securitySearchResults,
  } = props;

  const [tradeMethod, setTradeMethodState] = useState(TradeMethod.Dollar);
  const [amountToTrade, setAmountToTrade] = useState<number>(0);
  const [calculatedUnits, setCalculatedUnits] = useState<number>(0);
  const [calculatedValue, setCalculatedValue] = useState<number>(0);
  const [calculatedPercent, setCalculatedPercent] = useState<number>(0);
  const [securitySearch, setSecuritySearch] = useState<string>('');
  const onSecuritySearchChange = useDebounce<string>(securitySearch, 500);

  useEffect(() => {
    if (onSecuritySearchChange && onSecuritySearchChange.length > 2) {
      fetchSecurities({
        afslId: rebalance.afslId,
        search: onSecuritySearchChange,
      });
    }
  }, [onSecuritySearchChange, rebalance.afslId, fetchSecurities]);

  useEffect(() => {
    const units =
      selectedSecurity && selectedSecurity !== undefined
        ? roundUnitsByMarketCode(
            selectedSecurity.currentUnitPrice === 0 || isNaN(selectedSecurity.currentUnitPrice)
              ? 0
              : tradeMethod === TradeMethod.Dollar
              ? amountToTrade / selectedSecurity.currentUnitPrice
              : tradeMethod === TradeMethod.Hash
              ? amountToTrade
              : ((amountToTrade / 100) * rebalance.totalPortfolioValue) / selectedSecurity.currentUnitPrice,
            selectedSecurity.marketCode
          )
        : 0;
    const value = selectedSecurity && selectedSecurity !== undefined ? units * selectedSecurity.currentUnitPrice : 0;
    const percent = selectedSecurity && selectedSecurity !== undefined ? value / rebalance.totalPortfolioValue : 0;
    setCalculatedUnits(units);
    setCalculatedValue(value);
    setCalculatedPercent(percent);
  }, [amountToTrade, selectedSecurity, tradeMethod, rebalance.totalPortfolioValue]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={displayMenuOption === MenuOptions.QuickOrder}
      onClose={() => {
        setClearSecuritySearch();
        setAmountToTrade(0);
        setTradeMethodState(TradeMethod.Hash);
        setMenuOption(MenuOptions.None);
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={displayMenuOption === MenuOptions.QuickOrder}>
        <Paper
          style={{
            backgroundImage: `url(${QuickOrderBackground})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            width: '634px',
            maxHeight: '750px',
            minHeight: '600px',
            padding: '40px 40px',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <Typography align={'center'} variant={'h2'}>
            Quick Order
          </Typography>
          <Typography align={'center'}>{rebalance.clientName}</Typography>

          <Typography align={'center'} variant={'h4'} color={'secondary'}>
            BUY
          </Typography>

          <Autocomplete
            noOptionsText="No matching securities were found."
            disableClearable
            options={securitySearchResults?.results ?? []}
            getOptionLabel={(option: SecuritySearchResult) => `${option.securityCode} - ${option.securityName}`}
            onChange={(event: unknown, value: SecuritySearchResult | null) => {
              value?.securityId &&
                rebalance?.afslId &&
                fetchSecurity({
                  afslId: rebalance.afslId,
                  securityId: value.securityId,
                });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Security"
                margin="normal"
                variant="outlined"
                fullWidth
                onChange={(event) => {
                  setSecuritySearch(event.target.value);
                }}
              />
            )}
          />

          {selectedSecurity && selectedSecurity !== undefined && (
            <>
              <Typography variant="h5" align={'center'} style={{ paddingTop: '20px' }}>
                {selectedSecurity.securityName}
              </Typography>
              <Typography variant="h2" align={'center'}>
                {formatDollars(selectedSecurity.currentUnitPrice)} AUD
              </Typography>
              <Typography variant="h5" align={'center'} style={{ paddingBottom: '20px' }}>
                As of {getLocalDateTime(selectedSecurity.currentUnitPriceTime, DateTimeFormat.DailyDate)}
              </Typography>
            </>
          )}

          <ToggleButtonGroup
            size="small"
            exclusive
            aria-label="small outlined button group"
            style={{ width: '100%', justifyContent: 'center', padding: '20px' }}
          >
            <ToggleButton
              onClick={() => {
                tradeMethod !== TradeMethod.Dollar && setTradeMethodState(TradeMethod.Dollar);
              }}
              selected={tradeMethod === TradeMethod.Dollar}
              value={TradeMethod.Dollar}
              style={{ width: '100px' }}
            >
              Amount
            </ToggleButton>
            <ToggleButton
              onClick={() => {
                tradeMethod !== TradeMethod.Percentage && setTradeMethodState(TradeMethod.Percentage);
              }}
              selected={tradeMethod === TradeMethod.Percentage}
              value={TradeMethod.Percentage}
              style={{ width: '100px' }}
            >
              % of Portfolio
            </ToggleButton>
            <ToggleButton
              onClick={() => {
                tradeMethod !== TradeMethod.Hash && setTradeMethodState(TradeMethod.Hash);
              }}
              selected={tradeMethod === TradeMethod.Hash}
              value={TradeMethod.Hash}
              style={{ width: '100px' }}
            >
              Units
            </ToggleButton>
          </ToggleButtonGroup>

          <Box width={'100%'} justifyContent={'center'} display={'flex'}>
            <NumberFormat
              value={amountToTrade}
              customInput={OutlinedInput}
              type="text"
              thousandSeparator
              fixedDecimalScale
              allowNegative={false}
              decimalScale={
                tradeMethod === TradeMethod.Dollar || tradeMethod === TradeMethod.Percentage || !selectedSecurity
                  ? 2
                  : decimalPlacesByMarketCode(selectedSecurity.marketCode)
              }
              onValueChange={(values) => {
                if (values.floatValue) {
                  setAmountToTrade(values.floatValue);
                }
              }}
            />
          </Box>

          <Box display={'flex'} justifyContent={'center'} padding={'20px'}>
            <Box width={'200px'}>
              <Typography variant={'h5'}>{Intl.NumberFormat('en-AU').format(calculatedUnits)} units</Typography>
              <Typography variant={'h5'}>{formatPercentage(calculatedPercent)} of portfolio</Typography>
            </Box>
            <Typography variant={'h5'}>{formatDollars(calculatedValue)} Amount</Typography>
          </Box>

          <Box display={'flex'} justifyContent={'center'} padding={'20px'}>
            <WO2Button
              variant="outlined"
              disableElevation
              style={{ marginRight: '20px' }}
              value="Cancel"
              onClick={() => {
                setClearSecuritySearch();
                setAmountToTrade(0);
                setTradeMethodState(TradeMethod.Hash);
                setMenuOption(MenuOptions.None);
              }}
            >
              Cancel
            </WO2Button>
            <WO2Button
              variant={'contained'}
              disableElevation
              value="Add to rebalance"
              disabled={!selectedSecurity || selectedSecurity === undefined}
              onClick={() => {
                selectedSecurity &&
                  selectedSecurity !== undefined &&
                  addTrade({
                    security: selectedSecurity,
                    units: calculatedUnits,
                  });
                setClearSecuritySearch();
                setAmountToTrade(0);
                setTradeMethodState(TradeMethod.Hash);
                setMenuOption(MenuOptions.None);
              }}
            >
              Add to rebalance
            </WO2Button>
          </Box>
        </Paper>
      </Fade>
    </Modal>
  );
}
