import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'src/app/api';
import { buildEncodedQueryString } from 'src/common/utils/stringFunctions';
import { Account, FetchAccountsPayload, SaveAccountsPayload } from '.';
import { commonSlice, fetchInvestmentServiceVersionsByClient } from '../../../store/common';

export enum AccountsActionTypes {
  FetchAccounts = '@@client/investmentServices/account/FetchInvestmentServiceAccounts',
  SaveAccounts = '@@client/investmentServices/account/SaveAccounts',
}

export enum AccountsApiEndpoints {
  FetchAccounts = '/investmentservices/GetAccountsByInvestmentProgramVersionId',
  SaveAccounts = '/investmentservices/CreateOrUpdateAccounts',
}

export const fetchAccounts = createAsyncThunk<Account[], FetchAccountsPayload>(AccountsActionTypes.FetchAccounts, async (payload: FetchAccountsPayload) => {
  const queryString = buildEncodedQueryString({
    investmentProgramVersionId: payload.investmentServiceVersionId,
  });

  const response = await api.get<Account[]>(`${AccountsApiEndpoints.FetchAccounts}${queryString}`);
  return response.data;
});

export const saveAccounts = createAsyncThunk(AccountsActionTypes.SaveAccounts, async (payload: SaveAccountsPayload, thunkApi) => {
  const response = await api.post<number>(`${AccountsApiEndpoints.SaveAccounts}`, payload);

  thunkApi.dispatch(commonSlice.actions.setInvestmentServiceVersionId(response.data));

  await thunkApi.dispatch(fetchAccounts({ investmentServiceVersionId: payload.investmentServiceVersionId, clientId: payload.clientId }));

  await thunkApi.dispatch(
    fetchInvestmentServiceVersionsByClient({
      clientId: payload.clientId,
      showLatestVersionOnly: false,
    })
  );

  return { message: 'Accounts saved' };
});
