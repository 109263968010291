import React from 'react';
import { formatPercentage } from '../../../../../../common';
import CardComponent from '../../../../../../common/components/CardComponent';
import { theme } from '../../../../../../themes';

interface Props {
  isLoading: boolean;
  hasErrors: boolean;
  totalValue: number;
}

const GainLossPercentageCard = (props: Props): JSX.Element => {
  const { isLoading, hasErrors, totalValue } = props;

  return (
    <CardComponent
      progress={{ isLoading, hasErrors }}
      background={theme.palette.gradient2?.main}
      value={formatPercentage(totalValue)}
      subText={'GAIN/LOSS (%)'}
    />
  );
};

export default GainLossPercentageCard;
