import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../../../../../app/api';
import { buildEncodedQueryString } from '../../../../../../../common';
import { ResultWrapper } from '../../benefitStatement/store/types';
import { PensionBankAccountDetails, PensionDetail } from './types';

export enum PensionDetailActionTypes {
  FetchPensionDetailByAccountId = '@@client/details/supersimplifier/pensionDetail',
  FetchPensionBankDetailByClientId = '@@client/details/supersimplifier/pensionBankDetail',
}

export enum PensionDetailApiEndPoints {
  FetchPensionDetail = '/ssc/GetPensionDetails',
  FetchPensionBankDetail = '/ssc/GetPensionBankDetails',
}

export const fetchPensionDetailByAccountId = createAsyncThunk(PensionDetailActionTypes.FetchPensionDetailByAccountId, async (accountId: string) => {
  const queryString = buildEncodedQueryString({
    accountId,
  });
  const response = await api
    .get<PensionDetail>(`${PensionDetailApiEndPoints.FetchPensionDetail}${queryString}`)
    .then((result) => {
      const response: ResultWrapper<PensionDetail> = {
        status: result.status,
        responseData: result.data,
      };
      return response;
    })
    .catch((error) => {
      const response: ResultWrapper<null> = {
        status: error.status,
        responseData: null,
      };
      return response;
    });
  return response;
});

export const fetchPensionBankDetailByClientId = createAsyncThunk(PensionDetailActionTypes.FetchPensionBankDetailByClientId, async (clientId: number) => {
  const queryString = buildEncodedQueryString({
    clientId,
  });
  const response = await api
    .get<PensionBankAccountDetails>(`${PensionDetailApiEndPoints.FetchPensionBankDetail}${queryString}`)
    .then((result) => {
      const response: ResultWrapper<PensionBankAccountDetails> = {
        status: result.status,
        responseData: result.data,
      };
      return response;
    })
    .catch((error) => {
      const response: ResultWrapper<null> = {
        status: error.status,
        responseData: null,
      };
      return response;
    });
  return response;
});
