import ResizeModule from '@dash/quill-resize-module';
import { Box, Card, CardContent, CardHeader, Grid, Link, TextField, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
//import Quill from 'quill';
import { useQuill } from '@dash/react-quilljs';
import Delta from 'quill-delta';
import 'quill/dist/quill.snow.css';
import React, { useCallback, useEffect, useState } from 'react';
import ImageDropAndPaste from 'src/common/utils/imageDropAndPaste';
import { uuidv4 } from 'src/common/utils/utilities';
import { useStyles } from '../../../../../themes/index';
import { Props } from '../container';
import { uploadAttachment } from '../store/services';
import { sendEmailPayload } from '../store/types';
import WO2Button from './../../../../../common/components/button/Button';
import { ImageDataProps } from './../../../../../common/utils/imageDropAndPaste';

export const Template = (props: Props): JSX.Element => {
  const classes = useStyles();
  const { history, accountSelected, accountList, setAccountSelected, sender, fetchUser, sendEmail } = props;
  const [isErrorSubject, setErrorSubject] = useState<boolean>(false);
  const [isErrorContent, setErrorContent] = useState<boolean>(false);
  const [isErrorCC, setErrorCC] = useState<boolean>(false);
  const [isErrorRecipients, setErrorRecipients] = useState<boolean>(false);
  const [subject, setSubject] = useState<string>('');
  const [cc, setCC] = useState<string>('');

  const { quill, quillRef, Quill } = useQuill({
    modules: {
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        [{ font: ['roboto', 'notoserif', 'montserrat', 'opensans'] }],
        [{ align: [] }],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ indent: '-1' }, { indent: '+1' }],
        [{ size: ['small', false, 'large', 'huge'] }],
        [{ header: [1, 2, false, false, false, false, false] }],
        ['attachment'],
        ['link', 'image'],
        [{ color: [] }, { background: [] }],
        ['clean'],
      ],
      resize: {
        locale: {
          center: 'mid',
          floatLeft: 'left',
          floatRight: 'right',
          restore: 'reset',
          altTip: '',
        },
      },
      imageDropAndPaste: {
        handler: async function (quill: typeof Quill, _imageDataUrl: string, _type: string, imageData: ImageDataProps) {
          const filename = uuidv4() + '.png';
          const file = imageData.toFile(filename);
          if (file) {
            const formData = new FormData();
            formData.append('Attachment', file);
            const res = await uploadAttachment(formData);
            const index = (quill.getSelection() || {}).index;
            if (index && index < 0) {
              quill.getLength();
              quill.insertEmbed(index, 'image', res, 'user');
            }
          }
        },
      },
      clipboard: {
        matchVisual: false,
      },
    },
    formats: ['font', 'bold', 'italic', 'underline', 'strike', 'align', 'list', 'indent', 'size', 'header', 'link', 'image', 'color', 'background', 'clean'],
  });

  if (Quill && !quill) {
    const Font = Quill.import('attributors/style/font');
    Font.whitelist = ['roboto', 'notoserif', 'montserrat', 'opensans'];
    Quill.register(Font, true);
    Quill.register(
      'modules/imageDropAndPaste',
      function (
        quill: typeof Quill,
        options: {
          maxWidth: number;
          maxHeight: number;
          quality: number;
          handler: (quill: typeof Quill, dataUrl: string, type: string, data: ImageDataProps) => void;
        }
      ) {
        new ImageDropAndPaste(quill, options);
      }
    );
    Quill.register('modules/resize', ResizeModule);
    const icons = Quill.import('ui/icons');
    icons['attachment'] =
      '<svg class="MuiSvgIcon-root jss83" focusable="false" viewBox="0 0 24 24" aria-hidden="true" tabindex="-1" data-ga-event-label="AttachFileSharp" style="outline: none"><path d="M16.5 6v11.5c0 2.21-1.79 4-4 4s-4-1.79-4-4V5c0-1.38 1.12-2.5 2.5-2.5s2.5 1.12 2.5 2.5v10.5c0 .55-.45 1-1 1s-1-.45-1-1V6H10v9.5c0 1.38 1.12 2.5 2.5 2.5s2.5-1.12 2.5-2.5V5c0-2.21-1.79-4-4-4S7 2.79 7 5v12.5c0 3.04 2.46 5.5 5.5 5.5s5.5-2.46 5.5-5.5V6h-1.5z"></path></svg>';
  }

  const load = useCallback((quill) => {
    quill.getModule('toolbar').addHandler('image', () => {
      const input = document.createElement('input');
      input.setAttribute('type', 'file');
      input.setAttribute('accept', 'image/*');
      input.click();

      input.onchange = async () => {
        if (input && input.files && input.files.length > 0) {
          const file = input.files[0];
          const body = new FormData();
          body.append('Attachment', file);
          const url = await uploadAttachment(body);
          const range = quill.getSelection();
          quill.insertEmbed(range.index, 'image', url);
        }
      };
    });
    const attachButton = document.querySelector('.ql-attachment');
    if (attachButton) {
      attachButton.addEventListener('click', () => {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.click();
        input.onchange = async () => {
          if (input && input.files && input.files.length > 0) {
            const file = input.files[0];
            const body = new FormData();
            body.append('Attachment', file);
            const url = await uploadAttachment(body);
            const range = quill.getSelection();
            let index = range.index;
            if (index === undefined || index === null) {
              index = 0;
            }
            quill.insertText(index, file.name, 'link', url);
          }
        };
      });
    }
  }, []);

  React.useEffect(() => {
    if (quill) {
      load(quill);
    }
  }, [quill, load]);

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  const handleBack = () => {
    setAccountSelected([]);
    history.push('/bulk/email');
  };

  const viewAllClients = () => {
    setAccountSelected(accountList);
  };

  const formattedCC = (emails: string) => {
    const emailsArray: string[] = emails.split(',');
    const newEmails: string[] = [];
    if (emails === '') {
      return [];
    }
    emailsArray.forEach((item) => {
      newEmails.push(item.trim());
    });
    return newEmails;
  };

  const handleClickSendEmail = () => {
    const isContentEmpty = quill !== undefined && quill.root.innerHTML.replace(/<(.|\n)*?>/g, '').trim().length === 0;

    const entityCoreIds: number[] = [];
    accountSelected?.map((item) => {
      entityCoreIds.push(item.clientId);
    });

    const payload: sendEmailPayload = {
      entityCoreIds: entityCoreIds,
      from: sender?.email || '',
      cc: formattedCC(cc),
      subject: subject,
      body: quill !== undefined ? quill.root.innerHTML : '',
    };

    const isValidCC = isValidEmailCC(cc);

    if (isValidCC || isContentEmpty || !subject.trim() || accountSelected.length === 0) {
      setErrorSubject(!subject.trim());
      setErrorContent(isContentEmpty);
      setErrorCC(isValidCC);
      setErrorRecipients(accountSelected.length === 0);
    } else {
      sendEmail(payload);
      setSubject('');
      if (quill !== undefined) {
        const delta: Delta = new Delta();
        quill.setContents(delta, 'api');
      }
      setCC('');
      setErrorSubject(false);
      setErrorContent(false);
      setErrorCC(false);
      setErrorRecipients(false);
      setAccountSelected([]);
    }
  };

  const isValidEmailCC = (value: string) => {
    const re = /^[a-zA-Z][\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
    const emailCC = formattedCC(value);
    if (value === '') {
      return false;
    }
    for (const email of emailCC) {
      if (!re.test(email)) {
        return true;
      }
    }
    return false;
  };

  return (
    <Grid container direction="column">
      <Card className={'Overflow'} elevation={0}>
        <CardHeader title={<Typography variant="h3">Email</Typography>}> </CardHeader>
        <CardContent>
          <Box marginTop={'10px'} paddingBottom={'15px'} marginBottom={'15px'}>
            <Typography variant="h5">
              <span style={{ marginRight: 20, color: '#808080c7', fontSize: 13 }}>SENDER</span>
              <span style={{ fontWeight: 500 }}>
                {sender?.firstName} {sender?.lastName} &lt;{sender?.email}&gt;
              </span>
            </Typography>
          </Box>
          <Typography style={{ color: '#808080c7', fontSize: 13 }} variant="h5">
            RECIPIENTS
          </Typography>
          <Box position={'relative'} paddingBottom={'20px'}>
            <Autocomplete
              multiple
              disableClearable
              id="tags-standard"
              options={accountList}
              getOptionLabel={(option) => {
                return option.clientName || '';
              }}
              onChange={(_event, newValue) => {
                setAccountSelected([...newValue]);
                setErrorRecipients(newValue.length === 0);
              }}
              value={accountSelected}
              renderInput={(params) => <TextField {...params} variant="outlined" style={{ height: 85, overflow: 'auto' }} />}
            />
            <Typography variant={'subtitle1'} color={'error'} style={{ marginTop: 3 }}>
              {isErrorRecipients ? 'No recipients selected' : ''}
            </Typography>
            <Link variant={'h5'} style={{ position: 'absolute', top: -30, right: 40 }} href="#" onClick={() => viewAllClients()}>
              Include all clients ({accountList.length})
            </Link>
          </Box>
          <Box padding={'0 20px 20px 20px'} border={'1px solid #D3D3D3'}>
            <TextField
              id="cc_field"
              placeholder="Cc"
              value={cc}
              onChange={(event) => setCC(event.target.value)}
              fullWidth
              error={isErrorCC}
              helperText={isErrorCC ? 'CC field invalid' : ''}
              margin="normal"
              variant="standard"
            />
            <TextField
              id="sbj_field"
              placeholder="Subject"
              value={subject}
              onChange={(event) => setSubject(event.target.value)}
              fullWidth
              error={isErrorSubject}
              helperText={isErrorSubject ? 'Subject field invalid' : ''}
              margin="normal"
              variant="standard"
            />
            <Box paddingTop={'20px'}>
              <div ref={quillRef} className={classes.contentEmail} style={isErrorContent ? { border: '2px solid #cd1604' } : undefined} />
              <Typography variant={'subtitle1'} color={'error'} style={{ marginTop: 3 }}>
                {isErrorContent ? 'Content field invalid' : ''}
              </Typography>
            </Box>
          </Box>
        </CardContent>
        <Box display={'flex'} justifyContent={'flex-end'} padding={'20px'}>
          <WO2Button variant={'outlined'} onClick={handleBack} style={{ marginRight: '10px' }}>
            Cancel
          </WO2Button>
          <WO2Button color={'primary'} onClick={handleClickSendEmail} variant="contained">
            Send
          </WO2Button>
        </Box>
      </Card>
    </Grid>
  );
};
