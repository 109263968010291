import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  FormControlLabel,
  Grid,
  TextField,
} from '@mui/material';
import { WO2Checkbox } from 'src/common';
import { ClientReportsParameters, MdaReportTypes } from '../../store/types';
import { ActionCreatorWithoutPayload, ActionCreatorWithPayload } from '@reduxjs/toolkit';
import React from 'react';
import { DateRange } from 'src/store';
import DateRangePicker from 'src/common/components/DateRangePicker';
import { ClientPortfolio } from '../../../common/store';
import { SecurityBenchmark } from 'src/features/securities/performance/store/types';
import { BenchmarkSelect } from 'src/features/securities/performance/components/benchmarkSelect';

export interface PortfolioSnapshotProps {
  parameters: ClientReportsParameters;
  setPortfolioSnapshotSelectedParameter: ActionCreatorWithPayload<boolean, string>;
  setDateRangePortfolioSnapshotParameter: ActionCreatorWithPayload<DateRange>;
  setPortfolioSnapshotPortfolioParameter: ActionCreatorWithPayload<number, string>;
  setPortfolioSnapshotBenchmarkParameter: ActionCreatorWithPayload<number>;
  expandPortfolioSnapshot: ActionCreatorWithoutPayload<string>;
  clientPortfolios: ClientPortfolio[];
  benchmarks: SecurityBenchmark[];
  createReportSortRequest: (isAdd: boolean, id: number, text: string) => void;
}

export const PortfolioSnapshot = ({
  parameters,
  setPortfolioSnapshotSelectedParameter,
  setDateRangePortfolioSnapshotParameter,
  setPortfolioSnapshotPortfolioParameter,
  setPortfolioSnapshotBenchmarkParameter,
  expandPortfolioSnapshot,
  benchmarks,
  clientPortfolios,
  createReportSortRequest,
}: PortfolioSnapshotProps) => {
  const handleClickPortfolioSnapshotSelect = () => {
    setPortfolioSnapshotSelectedParameter(!parameters.selectedReports?.portfolioSnapshot.selected);
    createReportSortRequest(
      !parameters.selectedReports?.portfolioSnapshot.selected,
      MdaReportTypes.PortfolioSnapshot.id,
      MdaReportTypes.PortfolioSnapshot.displayName
    );
  };

  const handleSelectPortfolio = (portfolio: ClientPortfolio | null) => {
    if (portfolio && portfolio.investmentProgramId) {
      setPortfolioSnapshotPortfolioParameter(portfolio.investmentProgramId);
    }
  };

  const handleClickDateForPortfolioSnapshot = (dates: DateRange) => {
    setDateRangePortfolioSnapshotParameter(dates);
  };

  const setBenchmark = (benchmark: SecurityBenchmark | null) => {
    setPortfolioSnapshotBenchmarkParameter(benchmark?.id ?? -1);
  };

  return (
    <Accordion
      onChange={expandPortfolioSnapshot}
      expanded={parameters.selectedReports?.portfolioSnapshot.expanded || false}
      elevation={0}
    >
      <AccordionSummary expandIcon={<ExpandMore />}>
        <FormControlLabel
          sx={{ flex: 1 }}
          aria-label="Acknowledge"
          onClick={(event) => event.stopPropagation()}
          onFocus={(event) => event.stopPropagation()}
          control={
            <WO2Checkbox
              color="primary"
              checked={parameters.selectedReports?.portfolioSnapshot.selected}
              onChangeHandler={handleClickPortfolioSnapshotSelect}
            />
          }
          label="Portfolio Snapshot"
        />
        <div onClick={(e) => e.stopPropagation()}>
          <DateRangePicker
            sx={{ minWidth: 194 }}
            dateRange={{
              dateFrom: parameters?.selectedReports.portfolioSnapshot?.fromDate,
              dateTo: parameters?.selectedReports.portfolioSnapshot?.toDate,
              dateRangeType: parameters?.selectedReports.portfolioSnapshot?.dateRangeType,
            }}
            setDateRange={handleClickDateForPortfolioSnapshot}
            inceptionDate={parameters.inceptionDate}
          />
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={1} direction="column">
          <Grid item>
            <Autocomplete
              noOptionsText="No items to select"
              id="portfoliosnapshot-multiselect"
              fullWidth
              limitTags={1}
              value={clientPortfolios.find(
                (portfolio) => portfolio.investmentProgramId === parameters.selectedReports.assetAllocation.portfolioId
              )}
              options={clientPortfolios}
              onChange={(_e: React.SyntheticEvent<Element, Event>, value: ClientPortfolio | null) =>
                handleSelectPortfolio(value)
              }
              getOptionLabel={(option: ClientPortfolio) => option.investmentServiceName}
              renderInput={(params) => <TextField {...params} label="Select a Portfolio" margin="normal" fullWidth />}
              isOptionEqualToValue={(option: ClientPortfolio, value: ClientPortfolio) =>
                option.investmentProgramId === value.investmentProgramId
              }
            />
          </Grid>
          <Grid item>
            <BenchmarkSelect
              setBenchmark={setBenchmark}
              selectedItem={
                benchmarks.find(
                  (benchmark) => benchmark.id === parameters.selectedReports.portfolioSnapshot.benchmarkId
                ) ?? null
              }
              items={benchmarks}
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
