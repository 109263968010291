import { Box, Typography } from '@mui/material';
import React, { useCallback } from 'react';
import { AddressTable } from 'src/features/clients/client/common/components/contactDetails/addressTable';
import { PageBackNextButtons } from '../../components/common/pageBackNextButtons';
import { Props } from '../container';

export const Addresses = (props: Props): JSX.Element => {
  const {
    id,
    history,
    prevNextRoutePaths,
    addresses,
    saveOnboarding,
    saveProgress,
    saveAddress,
    removeAddress,
    setAddressEdit,
    selectedAddress,
    availableAddressTypes,
    client,
  } = props;

  const onBackButtonClick = useCallback(() => {
    const { prevRoutePath } = prevNextRoutePaths;
    if (prevRoutePath) {
      history.push(prevRoutePath + (!!id ? `?id=${id}` : ''));
    }
  }, [history, prevNextRoutePaths, id]);

  const onNextButtonClick = useCallback(() => {
    const { nextRoutePath } = prevNextRoutePaths;
    if (nextRoutePath) {
      history.push(nextRoutePath + (!!id ? `?id=${id}` : ''));
    }
  }, [history, prevNextRoutePaths, id]);

  return (
    <Box width="100%">
      <Box style={{ paddingBottom: '10px', marginBottom: '30px' }}>
        <Typography variant="h7">What addresses are associated with this client?</Typography>
      </Box>
      <Box>
        <AddressTable
          addresses={addresses.map((a, index) => {
            return { ...a, addressId: index };
          })}
          selectedAddress={selectedAddress}
          loadingProgress={{ isLoading: false, hasErrors: false }}
          savingProgress={saveProgress}
          addressTypes={availableAddressTypes}
          save={(address) => saveAddress(address)}
          delete={async (index: number) => {
            await removeAddress(index);
          }}
          cancel={() => setAddressEdit(undefined)}
          setAddEdit={setAddressEdit}
        ></AddressTable>
      </Box>
      <PageBackNextButtons<unknown>
        onBackButtonClick={onBackButtonClick}
        onNextButtonClick={onNextButtonClick}
        onQuitButtonClick={async () => {
          const response = await saveOnboarding({ ...client, stepKey: 'addresses' });
          if (!!response && !!response.payload) {
            history.push('/client/list?mode=onboard');
          }
        }}
        progress={saveProgress}
      />
    </Box>
  );
};
