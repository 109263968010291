import { AnyAction } from '@reduxjs/toolkit';

export const DISMISS_MESSAGE = 'DISMISS_MESSAGE';

export type MessageActions = typeof DISMISS_MESSAGE;

export const dismissMessage = (key: number): AnyAction => ({
  type: DISMISS_MESSAGE,
  key,
});
