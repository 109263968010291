import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../../app/api';
import { buildEncodedQueryString } from '../../../../common';
import { PagedResult } from '../../../../store';
import {
  ConsolidatedSecurityHoldingsSummary,
  ConsolidatedSecurityHoldingsSummaryThunkParameters,
  DailySecurityPricesChartItem,
  DailySecurityPricesChartThunkParameters,
  SecurityDashboard,
  SecurityDashboardThunkParameters,
  SecurityPricesItem,
  SecurityPricesThunkParameters,
} from './types';

export enum SecurityDahboardActionTypes {
  FetchConsolidatedSecurityHoldingsSummary = '@@security/dashboard/FetchConsolidatedSecurityHoldingsSummary',
  FetchSecurityDashboard = '@@security/dashboard/FetchSecurityDashboard',
  FetchSecurityPrices = '@@security/dashboard/FetchSecurityPrices',
  FetchDailySecurityPricesChart = '@@security/dashboard/FetchDailySecurityPricesChart',
}

export enum SecurityDahboardApiEndpoints {
  fetchConsolidatedSecurityHoldingsSummary = '/bff/GetConsolidatedSecurityHoldingsSummary',
  fetchSecurityDashboard = '/bff/GetSecurityDashboard',
  fetchSecurityPrices = '/api/performances/security/GetPrices',
  fetchDailySecurityPricesChart = '/api/performances/security/GetDailyPricesChart',
}

export const fetchConsolidatedSecurityHoldingsSummary = createAsyncThunk(
  SecurityDahboardActionTypes.FetchConsolidatedSecurityHoldingsSummary,
  async (wrapper: ConsolidatedSecurityHoldingsSummaryThunkParameters) => {
    const response = await api.get<ConsolidatedSecurityHoldingsSummary>(
      `${SecurityDahboardApiEndpoints.fetchConsolidatedSecurityHoldingsSummary}?securityId=${wrapper.securityId}&isDataFromTriumph=${wrapper.isDataFromTriumph}`
    );
    return response.data;
  }
);

export const fetchSecurityDashboard = createAsyncThunk(
  SecurityDahboardActionTypes.FetchSecurityDashboard,
  async (wrapper: SecurityDashboardThunkParameters) => {
    const response = await api.get<SecurityDashboard>(
      `${SecurityDahboardApiEndpoints.fetchSecurityDashboard}?securityId=${wrapper.securityId}&securityCode=${wrapper.securityCode}`
    );
    return response.data;
  }
);

export const fetchDailySecurityPricesChart = createAsyncThunk(
  SecurityDahboardActionTypes.FetchDailySecurityPricesChart,
  async (wrapper: DailySecurityPricesChartThunkParameters) => {
    const response = await api.get<DailySecurityPricesChartItem[]>(
      `${SecurityDahboardApiEndpoints.fetchDailySecurityPricesChart}?securityId=${wrapper.securityId}&fromDate=${wrapper.fromDate}&toDate=${wrapper.toDate}`
    );
    return response.data;
  }
);

export const fetchSecurityPrices = createAsyncThunk(
  SecurityDahboardActionTypes.FetchSecurityPrices,
  async (wrapper: SecurityPricesThunkParameters) => {
    const queryString = buildEncodedQueryString({
      pagedRequest: wrapper.pagination,
      securityId: wrapper.securityId,
    });
    const response = await api.get<PagedResult<SecurityPricesItem>>(
      `${SecurityDahboardApiEndpoints.fetchSecurityPrices}${queryString}`
    );
    return {
      results: response.data,
      pagination: wrapper.pagination,
    };
  }
);
