import { Button, Grid, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { Autocomplete, EnumerationMultiSelect, NumericInput, ToggleButtonItem, WO2ToggleButtonGroup } from 'src/common';
import { VirtualizedAutocomplete } from 'src/common/components/Autocomplete/VirtualizedAutocomplete/VirtualizedAutocomplete';
import { AfslPracticeAdviserSelect } from 'src/features/bulk/common/components';
import { ClientAccountTypeForBulk, ModelItem, ServiceType, SimpleSecurityItem } from '../../../common/store/types';
import { Props } from '../filtersContainer';
import { SecurityHeld } from '../store/types';

const serviceTypeToggleButtons: ToggleButtonItem<string>[] = [
  { name: ServiceType.Mda.displayName, value: ServiceType.Mda.name },
  { name: ServiceType.Roa.displayName, value: ServiceType.Roa.name },
];

const securityHeldToggleButtons: ToggleButtonItem<string>[] = [
  { name: SecurityHeld.Held.displayName, value: SecurityHeld.Held.name },
  { name: SecurityHeld.NotHeld.displayName, value: SecurityHeld.NotHeld.name },
];

export const Filters = (props: Props): JSX.Element => {
  const {
    parameters,
    afsls,
    models,
    securities,
    setAfslParameter,
    setPracticeParameter,
    setAdviserParameter,
    setClientTypesParameter,
    setServiceTypeParameter,
    setPortfolioValueFrom,
    setPortfolioValueTo,
    setAvailableCashBalanceTo,
    setAvailableCashBalanceFrom,
    setSecurityHeld,
    setSecurity,
    setModel,
    fetchResults,
    fetchModels,
  } = props;

  const handleSearch = () => {
    const request = { ...parameters };
    request.isRebalanceDataSourceTriumph = props.isRebalanceDataSourceTriumph;
    fetchResults(request);
  };
  useEffect(() => {
    handleSearch();
  }, [props.isRebalanceDataSourceTriumph]);

  useEffect(() => {
    const afsls = parameters.afsls.map((x) => x.afslId);
    fetchModels(afsls);
  }, [parameters.afsls]);

  return (
    <Grid container direction="column">
      <AfslPracticeAdviserSelect
        data={afsls}
        selectedAfsls={parameters.afsls}
        selectedPractices={parameters.practices}
        selectedAdvisers={parameters.advisers}
        setSelectedAfsls={setAfslParameter}
        setSelectedPractices={setPracticeParameter}
        setSelectedAdvisers={setAdviserParameter}
      />

      <EnumerationMultiSelect
        label="Account Type"
        type={ClientAccountTypeForBulk}
        value={parameters.clientTypes}
        onChange={setClientTypesParameter}
      />
      <Grid container direction="column" alignItems="flex-start" style={{ paddingBottom: '10px', paddingTop: '10px' }}>
        <Typography variant={'h4'} style={{ paddingBottom: '10px ' }}>
          Service Type
        </Typography>
        <Grid
          style={{
            width: '100%',
          }}
        >
          <WO2ToggleButtonGroup
            fullWidth={true}
            buttons={serviceTypeToggleButtons}
            value={parameters.serviceType}
            onChangeHandler={setServiceTypeParameter}
          />
        </Grid>
      </Grid>
      <Grid style={{ textAlign: 'justify', padding: '10px 0' }}>
        <Typography variant={'h5'}>Portfolio value between</Typography>
        <Grid container direction="row">
          <Grid item={true} xs={6} style={{ padding: '10px 5px 10px 0' }}>
            <NumericInput
              prefix="$"
              label="From"
              value={parameters.portfolioValueFrom}
              onChange={setPortfolioValueFrom}
            />
          </Grid>
          <Grid item={true} xs={6} style={{ padding: '10px 0 10px 5px' }}>
            <NumericInput prefix="$" label="To" value={parameters.portfolioValueTo} onChange={setPortfolioValueTo} />
          </Grid>
        </Grid>
      </Grid>
      <Grid style={{ textAlign: 'justify', padding: '5px 0' }}>
        <Typography variant={'h5'}>Available cash balance</Typography>
        <Grid container direction="row">
          <Grid item={true} xs={6} style={{ padding: '10px 5px 10px 0' }}>
            <NumericInput
              prefix="$"
              label="From"
              value={parameters.availableCashBalanceFrom}
              onChange={setAvailableCashBalanceFrom}
            />
          </Grid>
          <Grid item={true} xs={6} style={{ padding: '10px 0 10px 5px' }}>
            <NumericInput
              prefix="$"
              label="To"
              value={parameters.availableCashBalanceTo}
              onChange={setAvailableCashBalanceTo}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container direction="column" alignItems="flex-start" style={{ paddingBottom: '10px', paddingTop: '10px' }}>
        <Typography variant={'h4'} style={{ paddingBottom: '10px ' }}>
          Security
        </Typography>
        <Grid
          style={{
            width: '100%',
          }}
        >
          <WO2ToggleButtonGroup
            fullWidth={true}
            buttons={securityHeldToggleButtons}
            value={parameters.securityHeld}
            onChangeHandler={setSecurityHeld}
          />
        </Grid>
      </Grid>
      <Grid style={{ paddingTop: '10px' }}>
        <VirtualizedAutocomplete<SimpleSecurityItem>
          selectedValue={parameters.security}
          options={securities}
          onOptionChange={setSecurity}
          filterOptionStringLogic={(s: SimpleSecurityItem) => s.securityCode + s.securityName}
          selectedOptionRenderedLabel={(s: SimpleSecurityItem) => `${s.securityCode} - ${s.securityName}`}
        />
      </Grid>

      <Grid style={{ textAlign: 'justify', padding: '10px 0' }}>
        <Typography variant={'h5'} style={{ padding: '10px 0' }}>
          Within Model
        </Typography>
        <Autocomplete<ModelItem>
          value={parameters.model}
          optionLabel={(i) => i.name}
          options={models}
          loading={false}
          onOptionChange={setModel}
        />
      </Grid>
      <Grid style={{ textAlign: 'end', marginTop: '20px' }}>
        <Button disableElevation color={'primary'} onClick={() => handleSearch()} variant="contained">
          Search
        </Button>
      </Grid>
    </Grid>
  );
};
