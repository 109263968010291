import { connect, ConnectedProps } from 'react-redux';
import { progressSelectorFactory } from '../../../../../../common';
import { RootState } from '../../../../../../reducers';
import { selectSuperMemberNumber } from '../store/selectors';
import { ContributionDetails } from './components/contributionDetails';
import { selectContributionDetails } from './store/selectors';
import { contributionDetailsSlice } from './store/slice';
import { ContributionDetailsActionTypes, fetchContributionDetails } from './store/thunks';

const mapStateToProps = (state: RootState) => ({
  superMemberNumber: selectSuperMemberNumber(state),
  contributionDetails: selectContributionDetails(state),
  loadingContributionDetails: progressSelectorFactory([ContributionDetailsActionTypes.FetchContributionDetails])(state),
});

const mapDispatchToProps = {
  ...contributionDetailsSlice.actions,
  fetchContributionDetails,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector>;
export const ContributionDetailsContainer = connector(ContributionDetails);
