import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { selectIsDataFromTriumph } from 'src/store/selectors';
import { progressSelectorFactory } from '../../../common';
import { RootState } from '../../../reducers';
import { DashboardComponent } from './components/DashboardComponent';
import {
  selectMonthlyDashboardChart,
  selectParameters,
  selectStrategyDashboard,
  selectStrategyDetails,
} from './store/selectors';
import { strategyDashboardSlice } from './store/slice';
import {
  fetchMonthlyDashboardChart,
  fetchStrategyDashboard,
  fetchStrategyDetails,
  StrategyDashboardActionTypes,
} from './store/thunks';

const mapStateToProps = (state: RootState) => ({
  parameters: selectParameters(state),
  strategyDetails: selectStrategyDetails(state),
  strategyDashboard: selectStrategyDashboard(state),
  monthlyDashboardChart: selectMonthlyDashboardChart(state),
  loadingProgresStrategyDetails: progressSelectorFactory([StrategyDashboardActionTypes.FetchStrategyDetails])(state),
  loadingProgresStrategyDashboard: progressSelectorFactory([StrategyDashboardActionTypes.FetchStrategyDashboard])(
    state
  ),
  dashboardChartProgress: progressSelectorFactory([StrategyDashboardActionTypes.FetchMonthlyDashboardChart])(state),
  isDataFromTriumph: selectIsDataFromTriumph(state),
});

const mapDispatchToProps = {
  ...strategyDashboardSlice.actions,
  fetchMonthlyDashboardChart,
  fetchStrategyDetails,
  fetchStrategyDashboard,
};

interface MatchParams {
  strategyId?: string;
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector> & RouteComponentProps<MatchParams>;
export const StrategyDashboardStateContainer = connect(mapStateToProps, mapDispatchToProps)(DashboardComponent);
