import { createSelector } from '@reduxjs/toolkit';
import _ from 'lodash';
import { RootState } from '../../../../../reducers';
import { isCashTrade } from '../utils';
import { GroupedByKey, PreapprovalGroupedTrades, RebalanceState, Totals, TradeAction, ValidationResult } from './types';

export const selectRebalance = createSelector(
  (state: RootState) => state.rebalance.checkout,
  (rebalanceCheckout: RebalanceState) => rebalanceCheckout.rebalance
);

export const selectGroupedTrades = createSelector(
  (state: RootState) => state.rebalance.checkout,
  (rebalanceCheckout: RebalanceState) => {
    if (rebalanceCheckout.rebalance?.preApprovalTradeItems) {
      return _.chain(rebalanceCheckout.rebalance.preApprovalTradeItems)
        .groupBy(GroupedByKey.AssetClass)
        .map(
          (value, key) =>
            ({
              groupedByKey: key,
              key: value.find((x) => x !== undefined)?.assetClass ?? 'Direct',
              title: value.find((x) => x !== undefined)?.assetClass ?? 'Direct',
              trades: value.filter((x) => x.calculatedTradeValue !== 0 && !isCashTrade(x)),
              totalCalculatedValue: value.reduce(
                (prev, next) =>
                  next.tradeAction === TradeAction.Buy && !isCashTrade(next)
                    ? prev + next.calculatedTradeValue
                    : !isCashTrade(next)
                    ? prev - next.calculatedTradeValue
                    : prev,
                0
              ),
              totalBuys: value.reduce(
                (prev, next) => (next.tradeAction === TradeAction.Buy && next.calculatedTradeValue !== 0 && !isCashTrade(next) ? prev + 1 : prev),
                0
              ),
              totalSells: value.reduce(
                (prev, next) =>
                  (next.tradeAction === TradeAction.Sell || next.tradeAction === TradeAction.All) && next.calculatedTradeValue !== 0 && !isCashTrade(next)
                    ? prev + 1
                    : prev,
                0
              ),
            } as PreapprovalGroupedTrades)
        )
        .filter((preapprovalGroupedTrades) => preapprovalGroupedTrades.trades && preapprovalGroupedTrades.trades.length > 0)
        .value();
    }
    return [];
  }
);

export const selectTotals = createSelector(
  (state: RootState) => state.rebalance.checkout,
  (rebalanceCheckout: RebalanceState) => {
    const totalBuysCash =
      rebalanceCheckout.rebalance?.preApprovalTradeItems?.reduce(
        (prev, next) => (next.tradeAction === TradeAction.Buy && !isCashTrade(next) ? prev + next.calculatedTradeValue : prev ?? 0),
        0
      ) ?? 0;
    const totalSellsCash =
      rebalanceCheckout.rebalance?.preApprovalTradeItems?.reduce(
        (prev, next) =>
          (next.tradeAction === TradeAction.Sell || next.tradeAction === TradeAction.All) && !isCashTrade(next) ? prev + next.calculatedTradeValue : prev ?? 0,
        0
      ) ?? 0;
    const totalOrderSize = totalBuysCash - totalSellsCash;
    return { totalBuysCash, totalSellsCash, totalOrderSize } as Totals;
  }
);

export const selectValidationMessages = createSelector(
  (state: RootState) => state.rebalance.checkout,
  (rebalanceCheckout: RebalanceState) => {
    return { errors: rebalanceCheckout.rebalance?.validationResult?.errors ?? [], information: [], warnings: [] } as ValidationResult;
  }
);

export const selectIsSubmitted = createSelector(
  (state: RootState) => state.rebalance.checkout,
  (rebalanceCheckout: RebalanceState) => {
    return rebalanceCheckout.submitted;
  }
);
