import { Typography } from '@mui/material';
import React from 'react';
import { DateTimeFormat, getLocalDateTime, nameof } from '../../../../../common';
import { DatatableColumn } from '../../../../../common/components/dataTable/types';
import { ReportSearchResult } from '../store/types';

const clientNameColumn = (dataIndex: number, results: ReportSearchResult[]): React.ReactNode => (
  <div>
    <Typography>{results[dataIndex]?.clientName}</Typography>
  </div>
);
const accountTypeColumn = (dataIndex: number, results: ReportSearchResult[]): React.ReactNode => (
  <div>
    <Typography>{results[dataIndex]?.accountType}</Typography>
  </div>
);
const adviserNameColumn = (dataIndex: number, results: ReportSearchResult[]): React.ReactNode => (
  <div>
    <Typography>{results[dataIndex]?.adviserName}</Typography>
  </div>
);
const statusColumn = (dataIndex: number, results: ReportSearchResult[]): React.ReactNode => (
  <div>
    <Typography>{results[dataIndex]?.status}</Typography>
  </div>
);

const executionDateColumn = (dataIndex: number, results: ReportSearchResult[]): React.ReactNode => (
  <Typography>{getLocalDateTime(results[dataIndex]?.executionDate, DateTimeFormat.Short)}</Typography>
);

const inceptionDateColumn = (dataIndex: number, results: ReportSearchResult[]): React.ReactNode => (
  <Typography>{getLocalDateTime(results[dataIndex]?.inceptionDate, DateTimeFormat.Short)}</Typography>
);

export const getResultColumns = (results: ReportSearchResult[]): DatatableColumn[] => [
  {
    textAlign: 'left',
    name: nameof((i: ReportSearchResult) => i.clientName),
    label: 'CLIENT NAME',
    options: {
      filter: false,
      sort: true,
      customBodyRenderLite: (dataIndex: number) => clientNameColumn(dataIndex, results),
    },
  },
  {
    textAlign: 'left',
    name: nameof((i: ReportSearchResult) => i.accountType),
    label: 'ACCOUNT TYPE',
    options: {
      filter: false,
      sort: true,
      customBodyRenderLite: (dataIndex) => accountTypeColumn(dataIndex, results),
    },
  },
  {
    textAlign: 'left',
    name: nameof((i: ReportSearchResult) => i.status),
    label: 'STATUS',
    options: {
      filter: false,
      sort: true,
      customBodyRenderLite: (dataIndex) => statusColumn(dataIndex, results),
    },
  },
  {
    textAlign: 'left',
    name: nameof((i: ReportSearchResult) => i.adviserName),
    label: 'ADVISER NAME',
    options: {
      filter: false,
      sort: true,
      customBodyRenderLite: (dataIndex) => adviserNameColumn(dataIndex, results),
    },
  },
  {
    textAlign: 'left',
    name: nameof((i: ReportSearchResult) => i.executionDate),
    label: 'SERVICES AGREEMENT DATE',
    options: {
      filter: false,
      sort: true,
      customBodyRenderLite: (dataIndex) => executionDateColumn(dataIndex, results),
    },
  },
  {
    textAlign: 'left',
    name: nameof((i: ReportSearchResult) => i.inceptionDate),
    label: 'INCEPTION DATE',
    options: {
      filter: false,
      sort: true,
      customBodyRenderLite: (dataIndex) => inceptionDateColumn(dataIndex, results),
    },
  },
];
