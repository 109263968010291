import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { AccountInstitutionType } from 'src/common/components/accounts/types';
import { progressSelectorFactory } from 'src/common/store/selectors/loadingProgressSelector';
import { RootState } from '../../../../reducers';
import { selectPrevNextRoutePaths } from '../store/routeSelectors';
import {
  selectAccountInfo,
  selectAdvisor,
  selectApprovedInstitutionIds,
  selectClientAccount,
  selectExistingAccounts,
  selectExternalInstitutions,
  selectId,
  selectNewAccounts,
} from '../store/selectors';
import { onboardSlice } from '../store/slice';
import {
  fetchApprovedInstitutionIds,
  fetchExternalInstitutions,
  OnboardActionTypes,
  saveAccountInfo,
} from '../store/thunks';
import { Accounts } from './components/accounts';

const mapStateToProps = (state: RootState, routeComponentProps: RouteComponentProps) => ({
  id: selectId(state),
  prevNextRoutePaths: selectPrevNextRoutePaths(state, routeComponentProps.location.pathname),
  clientAccount: selectClientAccount(state),
  existingAccounts: selectExistingAccounts(state),
  externalInstitutions: selectExternalInstitutions(state),
  newAccounts: selectNewAccounts(state),
  accountInfo: selectAccountInfo(state),
  saveProgress: progressSelectorFactory([OnboardActionTypes.SaveOnboarding])(state),
  approvedInstitutionIds: selectApprovedInstitutionIds(state),
  approvedInstitutionProgress: progressSelectorFactory([OnboardActionTypes.FetchApprovedInstitutionIds])(state),
  advisor: selectAdvisor(state),
  optionalSuperInstitutions: AccountInstitutionType.getSuperOptionalInstitutionTypes().map((x) => x.id),
});

const mapDispatchToProps = {
  ...onboardSlice.actions,
  fetchExternalInstitutions,
  fetchApprovedInstitutionIds,
  saveAccountInfo,
};

interface MatchParams {
  id?: string;
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector> & RouteComponentProps<MatchParams>;
export const AccountsContainer = connect(mapStateToProps, mapDispatchToProps)(Accounts);
