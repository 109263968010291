import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Account, fetchAccounts, LinkedAccountsState } from '.';

export const initialState: LinkedAccountsState = {
  accounts: [],
};
export const linkedAccountsSlice = createSlice({
  name: '@@clients/client/investmentServices/accounts',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAccounts.fulfilled, (state, action: PayloadAction<Account[]>) => {
      state.accounts = action.payload;
    });
  },
});
