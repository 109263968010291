import React from 'react';
import { useSelector } from 'react-redux';
import { formatDollars } from '../../../../../common';
import CardComponent from '../../../../../common/components/CardComponent';
import { RootState } from '../../../../../reducers';
import { theme } from '../../../../../themes';

interface Props {
  isLoading: boolean;
  hasErrors: boolean;
}

const TotalValueCard = (props: Props): JSX.Element => {
  const { isLoading, hasErrors } = props;
  const value = useSelector((state: RootState) => state.clients.client.gainsAndLosses?.results?.totalValue);

  return <CardComponent progress={{ isLoading, hasErrors }} background={theme.palette.gradient1?.main} value={formatDollars(value)} subText={'TOTAL VALUE'} />;
};

export default TotalValueCard;
