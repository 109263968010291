import { Delete, Edit, ManageAccounts, Publish, Replay } from '@mui/icons-material';
import RefreshRounded from '@mui/icons-material/RefreshRounded';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import { AnyAction } from '@reduxjs/toolkit';
import React, { useState } from 'react';
import { ClientSideDataTable } from '../../../../common/components/dataTable/clientSide';
import { DatatableColumn, FilterDataType } from '../../../../common/components/dataTable/types';
import { useConfirmation } from '../../../../common/components/dialogs';
import { LoadingProgress } from '../../../../common/store/types';
import history from '../../../../history';
import { ProcessClientDialog } from '../../common/components/processClientDialog';
import { OnboardingWizardStatus } from '../store/enum';
import { OnboardingWizards, ResetStatusPayload } from '../store/types';

export interface OnboardingsProps {
  hideAllActions: boolean;
  onboardings: OnboardingWizards[];
  loadingProgress: LoadingProgress;
  deleteOnboarding: (id: string) => void;
  resumeOnboarding: (client: OnboardingWizards) => void;
  resubmitProgress: LoadingProgress;
  resubmitClient: (id: string) => Promise<AnyAction>;
  resetStatus: (payload: ResetStatusPayload) => Promise<AnyAction>;
  resetStatusProgress: LoadingProgress;
  hasClientEditAdminOnlyPermission: boolean;
  fetchOnboardingWizardsModels: () => Promise<AnyAction>;
}

export const OnboardingTable = (props: OnboardingsProps): JSX.Element => {
  const confirm = useConfirmation();
  const {
    onboardings,
    hasClientEditAdminOnlyPermission,
    loadingProgress,
    deleteOnboarding,
    hideAllActions,
    resumeOnboarding,
    resubmitClient,
    resubmitProgress,
    resetStatus,
    resetStatusProgress,
    fetchOnboardingWizardsModels,
  } = props;

  const [isProcessClientDialogOpen, setIsProcessClientDialogOpen] = useState<boolean>(false);
  const [id, setId] = useState<string | undefined>(undefined);

  const nameColumn = (dataIndex: number): React.ReactNode => (
    <Typography data-testid="name" variant="h5">
      {props.onboardings[dataIndex]?.name}
    </Typography>
  );

  const typeColumn = (dataIndex: number): React.ReactNode => (
    <Typography data-testid="type" variant="h5">
      {props.onboardings[dataIndex]?.type}
    </Typography>
  );

  const adviserNameColumn = (dataIndex: number): React.ReactNode => (
    <Typography data-testid="adviser" variant="h5">
      {props.onboardings[dataIndex]?.adviserName}
    </Typography>
  );

  const contactColumn = (dataIndex: number): React.ReactNode => (
    <Typography data-testid="contact" variant="h5">
      {props.onboardings[dataIndex]?.contact}
    </Typography>
  );

  const createdDateColumn = (dataIndex: number): React.ReactNode => (
    <Typography data-testid="createdDate" variant="h5">
      {props.onboardings[dataIndex]?.createdOn}
    </Typography>
  );

  const lastUpdatedDateColumn = (dataIndex: number): React.ReactNode => (
    <Typography data-testid="lastUpdatedDate" variant="h5">
      {props.onboardings[dataIndex]?.lastUpdatedOn}
    </Typography>
  );

  const statusColumn = (dataIndex: number): React.ReactNode => (
    <Typography data-testid="status" variant="h5">
      {OnboardingWizardStatus.getById(props.onboardings[dataIndex]?.statusId)?.displayName}
    </Typography>
  );

  const actionsColumn = (dataIndex: number): React.ReactNode => {
    const status = OnboardingWizardStatus.getById(props.onboardings[dataIndex]?.statusId);
    switch (status) {
      case OnboardingWizardStatus.Active:
        return (
          <>
            <IconButton
              disableFocusRipple
              disableRipple
              onClick={() => {
                resumeOnboarding(props.onboardings[dataIndex]);
              }}
              data-testid={`editButton_${dataIndex}`}
            >
              <Edit color={'primary'} />
            </IconButton>
            <IconButton
              disableFocusRipple
              disableRipple
              onClick={() => {
                const thisId = props.onboardings[dataIndex].id;
                if (!!thisId) {
                  confirm({
                    title: 'Delete client',
                    description: 'Are you sure you wish to delete this client?',
                  }).then(() => {
                    deleteOnboarding(thisId);
                  });
                }
              }}
              data-testid={`deleteButton_${dataIndex}`}
            >
              <Delete color={'primary'} />
            </IconButton>
          </>
        );
      case OnboardingWizardStatus.Completed:
        return (
          <IconButton
            disableFocusRipple
            disableRipple
            onClick={() => {
              const clientId = props.onboardings[dataIndex].clientId;
              history.push(`/client/${clientId}/details/accounts`);
            }}
            data-testid={`manageButton_${dataIndex}`}
          >
            <ManageAccounts color={'primary'} />
          </IconButton>
        );
      case OnboardingWizardStatus.Error:
        return hasClientEditAdminOnlyPermission ? (
          <>
            <Tooltip title="Resubmit client">
              <IconButton
                disableFocusRipple
                disableRipple
                onClick={() => {
                  const thisId = props.onboardings[dataIndex].id;
                  if (!!thisId) {
                    setId(thisId);
                    setIsProcessClientDialogOpen(true);
                  }
                }}
                data-testid={`resubmitButton_${dataIndex}`}
              >
                <Publish color={'primary'} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Reset client status to Active">
              <IconButton
                disableFocusRipple
                disableRipple
                onClick={() => {
                  const client = props.onboardings[dataIndex];
                  confirm({
                    title: 'Reset onboarding status',
                    description: 'Are you sure you wish to reset the status of this client?',
                    description2:
                      'This action is permanent, please ensure you have selected the correct client before proceeding.',
                  }).then(() => {
                    resetStatus({ id: client.id, clientId: client?.clientId });
                  });
                }}
                data-testid={`resetButton_${dataIndex}`}
              >
                <Replay color={'primary'} />
              </IconButton>
            </Tooltip>
          </>
        ) : (
          <></>
        );
    }
  };

  const addButton = (): React.ReactNode => (
    <Tooltip title="Refresh">
      <RefreshRounded
        style={{ marginTop: '8px', marginRight: '6px', cursor: 'pointer', color: '#757575' }}
        onClick={() => {
          fetchOnboardingWizardsModels();
        }}
      ></RefreshRounded>
    </Tooltip>
  );

  const columns: DatatableColumn[] = [
    {
      filterDataType: FilterDataType.string,
      name: 'name',
      label: 'CLIENT NAME',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex: number): React.ReactNode => nameColumn(dataIndex),
      },
    },
    {
      filterDataType: FilterDataType.string,
      name: 'type',
      label: 'TYPE',
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex: number): React.ReactNode => typeColumn(dataIndex),
      },
    },
    {
      filterDataType: FilterDataType.string,
      name: 'contact',
      label: 'CONTACT',
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex: number): React.ReactNode => contactColumn(dataIndex),
      },
    },
    {
      filterDataType: FilterDataType.string,
      name: 'adviserName',
      label: 'ADVISER NAME',
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex: number): React.ReactNode => adviserNameColumn(dataIndex),
      },
    },
    {
      name: 'createdOn',
      textAlign: 'right',
      label: 'CREATED',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex: number): React.ReactNode => createdDateColumn(dataIndex),
      },
    },
    {
      name: 'lastUpdatedOn',
      textAlign: 'right',
      label: 'LAST UPDATED',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex: number): React.ReactNode => lastUpdatedDateColumn(dataIndex),
      },
    },
    {
      filterDataType: FilterDataType.enumeration,
      enumerationType: OnboardingWizardStatus,
      name: 'statusId',
      label: 'STATUS',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex: number): React.ReactNode => statusColumn(dataIndex),
      },
      renderFilterChipValue: (statusId: string) => {
        return OnboardingWizardStatus.getById(+statusId)?.displayName || 'n/a';
      },
    },
    {
      name: 'actions',
      label: ' ',
      textAlign: 'right',
      options: {
        filter: false,
        sort: false,
        viewColumns: false,
        customBodyRenderLite: (dataIndex: number): React.ReactNode => actionsColumn(dataIndex),
        setCellProps: () => {
          return {
            style: {
              minWidth: '100px',
              textAlign: 'right',
            },
          };
        },
      },
    },
  ];

  return (
    <Box paddingTop={'20px'}>
      <ClientSideDataTable
        loadingProgress={{
          isLoading: loadingProgress?.isLoading || resetStatusProgress?.isLoading,
          hasErrors: loadingProgress?.hasErrors || resetStatusProgress?.hasErrors,
        }}
        columns={hideAllActions ? columns.filter((c) => c.name !== 'actions') : columns}
        data={onboardings}
        options={{
          filter: true,
          pagination: true,
          customToolbar: () => !loadingProgress?.isLoading && addButton(),
        }}
      />
      <ProcessClientDialog
        isOpen={isProcessClientDialogOpen}
        saveProgress={resubmitProgress}
        handleClose={() => setIsProcessClientDialogOpen(false)}
        handleProceedButtonClick={async () => {
          if (!!id) {
            resubmitClient(id).then(() => setIsProcessClientDialogOpen(false));
          }
        }}
      />
    </Box>
  );
};
