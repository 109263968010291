/* eslint-disable @typescript-eslint/no-explicit-any */
import { TextField } from '@mui/material';
import { FieldInputProps, FormikProps, useField, useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import InputMask from 'react-input-mask';
import { FormikErrorMessage } from '.';

interface Props {
  onUpdateField?: (setFieldValue: (field: string, value: string) => void, setValue: (value: string) => void, value: string) => void;
  mask?: string;
  showRequiredAsterisk?: boolean;
  disabled: boolean | undefined;
  field: FieldInputProps<any>;
  type: React.HTMLInputTypeAttribute | undefined;
  label: React.ReactNode;
  form: FormikProps<any>;
  fullWidth: boolean | undefined;
}

export const FormikTextField = ({ onUpdateField, showRequiredAsterisk, ...props }: Props): JSX.Element => {
  const { disabled, field, mask, type, label, fullWidth } = props;
  const [, , helper] = useField(field.name);
  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    if (field.value === null) {
      helper.setValue('');
    }
  }, [field.value, helper]);

  if (!!mask) {
    return (
      <>
        <InputMask
          mask={mask}
          value={field.value || ''}
          disabled={disabled || false}
          onChange={(event) => {
            const trimmedValue = event.target.value.replace(/\s/g, '');

            if (!!onUpdateField) {
              onUpdateField(setFieldValue, helper.setValue, trimmedValue);
            } else {
              props.form.setFieldValue(field.name, trimmedValue);
            }
          }}
          onBlur={field.onBlur}
        >
          {(inputProps: any) => (
            <TextField
              variant="outlined"
              fullWidth={fullWidth}
              label={label}
              InputLabelProps={{
                shrink: true,
              }}
              data-testid={field.name}
              name={field.name}
              inputProps={{
                'data-testid': field.name,
              }}
              type={type || 'text'}
              {...(!!showRequiredAsterisk && !!label && { label: `* ${label}` })}
              disabled={disabled}
              {...inputProps}
            />
          )}
        </InputMask>
        <FormikErrorMessage name={field.name}></FormikErrorMessage>
      </>
    );
  }

  return (
    <>
      <TextField
        disabled={disabled || false}
        type="text"
        fullWidth={fullWidth}
        variant="outlined"
        label={label}
        InputLabelProps={{
          shrink: true,
        }}
        data-testid={field.name}
        inputProps={{
          'data-testid': field.name,
        }}
        {...field}
        {...(!!showRequiredAsterisk && !!label && { label: `* ${label}` })}
        value={field.value || ''}
        onChange={(event) => {
          if (!!onUpdateField) {
            onUpdateField(setFieldValue, helper.setValue, event.target.value);
          } else {
            helper.setValue(event.target.value, true);
          }
          helper.setTouched(true, false);
        }}
      />
      <FormikErrorMessage name={field.name}></FormikErrorMessage>
    </>
  );
};
