export const getFieldsToDisplay = (accountTypeId: number | null): string[] => {
  switch (accountTypeId) {
    case 1: {
      //Deposit - At Call
      return ['isExternal', 'bsb', 'broker', 'duration', 'rate', 'brokerNumber', 'maturityDate'];
    }
    case 2: {
      //Deposit - CMA
      return ['isExternal', 'bsb'];
    }
    case 3: {
      //Deposit - TD
      return ['isExternal', 'broker', 'duration', 'rate', 'brokerNumber', 'maturityDate'];
    }
    case 4: {
      //Deposit - TD
      return ['isExternal', 'bsb'];
    }
    case 5: {
      //Deposit - TD
      return ['isExternal', 'bsb'];
    }
    case 6: {
      //Deposit - TD
      return ['broker', 'accountDesignation', 'hin'];
    }
    case 8: {
      //Deposit - TD
      return [];
    }
    case 9: {
      //Deposit - TD
      return ['isExternal'];
    }
    case 10: {
      //Deposit - TD
      return [];
    }
    case 11: {
      //Deposit - TD
      return [];
    }
    case 12: {
      //ba
      return ['isExternal', 'bsb'];
    }
    case 13: {
      //Deposit - TD
      return [];
    }
    case 14: {
      //Deposit - TD
      return [];
    }
    default: {
      return [];
    }
  }
};
