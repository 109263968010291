import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';
import { DateRange } from '../../../../store';
import { fetchDailyPerformanceChart, fetchMonthlyPerformanceChart, fetchPeriodicSummary, fetchStrategyDashboard, fetchStrategyDetails } from './thunks';
import {
  DailyPerformanceChart,
  Frequency,
  MonthlyPerformanceChart,
  PeriodicSummary,
  StrategyDashboard,
  StrategyDetails,
  StrategyPerformanceState,
} from './types';

export const initialState: StrategyPerformanceState = {
  parameters: {
    dates: { dateFrom: DateTime.now().minus({ years: 1 }).toISODate(), dateTo: DateTime.now().toISODate() },
    frequency: Frequency.Daily,
    strategyBenchmarks: null,
    strategyBenchmarkSelected: {
      id: 1014,
      code: 'XJO',
      name: 'S&P/ASX 200 Index',
    },
  },
  strategyDashboard: null,
  strategyDetails: null,
  dailyPerformanceChart: null,
  monthlyPerformanceChart: null,
  periodicSummary: null,
};

export const strategyPerformanceSlice = createSlice({
  name: '@@strategy/Performance',
  initialState,
  reducers: {
    setDateParameter: (state, action: PayloadAction<DateRange>) => {
      state.parameters.dates = action.payload;
    },
    setFrequencyParameter: (state, action: PayloadAction<Frequency>) => {
      state.parameters.frequency = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchStrategyDashboard.fulfilled, (state, action: PayloadAction<StrategyDashboard>) => {
      state.strategyDashboard = action.payload;
    });
    builder.addCase(fetchStrategyDetails.fulfilled, (state, action: PayloadAction<StrategyDetails>) => {
      state.strategyDetails = action.payload;
    });
    builder.addCase(fetchDailyPerformanceChart.fulfilled, (state, action: PayloadAction<DailyPerformanceChart>) => {
      state.dailyPerformanceChart = action.payload;
    });
    builder.addCase(fetchMonthlyPerformanceChart.fulfilled, (state, action: PayloadAction<MonthlyPerformanceChart>) => {
      state.monthlyPerformanceChart = action.payload;
    });
    builder.addCase(fetchPeriodicSummary.fulfilled, (state, action: PayloadAction<PeriodicSummary>) => {
      state.periodicSummary = action.payload;
    });
  },
});
