import { createSelector } from '@reduxjs/toolkit';
import { AddressType } from '../../../common/store/enums';
import { selectDetails } from '../../store/selectors';

export const selectContacts = createSelector(selectDetails, (details) => details.contacts);

export const selectItems = createSelector(selectContacts, (contacts) => {
  return contacts.items.slice().sort((a, b) => (a.isPrimary === b.isPrimary ? 0 : a.isPrimary ? -1 : 1) || a.name.localeCompare(b.name));
});

export const selectEditDetails = createSelector(selectContacts, (contacts) => contacts.edit);

export const selectContact = createSelector(selectEditDetails, (edit) => edit?.contact);

export const selectEditContact = createSelector(selectContact, (contact) => contact?.edit);

export const selectEditContactId = createSelector(selectEditContact, (contact) => contact?.id || null);

export const selectAttachContactList = createSelector(selectContact, (contact) => contact?.attachContactList);

export const selectAddresses = createSelector(selectEditDetails, (edit) => edit.addresses);

export const selectAddressItems = createSelector(selectAddresses, (addressState) => addressState.items);

export const selectEditAddress = createSelector(selectAddresses, (addresses) => {
  if (addresses.edit === undefined) {
    return undefined;
  }
  return addresses.items.find((address) => address.addressId === addresses.edit) ?? null;
});

export const selectAvailableAddressTypes = createSelector(selectAddressItems, (addresses) => {
  let addressTypes = AddressType.getArray();

  addresses.forEach((address) => {
    if (!!address.addressTypeIds) {
      address.addressTypeIds.forEach((addressTypeId) => {
        const type = AddressType.getById(addressTypeId);
        addressTypes = addressTypes.filter((item) => item !== type);
      });
    }
  });
  return addressTypes;
});

export const selectEditDocuments = createSelector(selectEditDetails, (edit) => edit?.documents || null);

export const selectEditDocumentsParameters = createSelector(selectEditDocuments, (documents) => documents.parameters);

export const selectEditDocument = createSelector(selectEditDocuments, (documentsState) => documentsState.results.edit);

export const selectEditIdentifications = createSelector(selectEditDetails, (edit) => edit?.identifications || null);

export const selectEditIdentificationsParameters = createSelector(selectEditIdentifications, (identifications) => identifications.parameters);

export const selectEditIdentification = createSelector(selectEditIdentifications, (identificationState) => identificationState.results.edit);

export const selectPhoneNumberState = createSelector(selectEditDetails, (edit) => edit?.phoneNumbers);

export const selectPhoneNumbers = createSelector(selectPhoneNumberState, (phoneNumberState) => phoneNumberState.items);

export const selectPhoneNumberEditId = createSelector(selectPhoneNumberState, (phoneNumberState) => phoneNumberState.edit);

export const selectEmailAddressesState = createSelector(selectEditDetails, (edit) => edit?.emailAddresses);

export const selectEmailAddresses = createSelector(selectEmailAddressesState, (emailAddressesState) => emailAddressesState.items);

export const selectEmailAddressEditId = createSelector(selectEmailAddressesState, (emailAddressesState) => emailAddressesState.edit);
