import { Alert, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React from 'react';
import { formatNumberCommaSeparated } from 'src/common';
import { ResultWrapper } from '../../benefitStatement/store/types';
import { ContributionDetails } from '../store/types';

export interface Props {
  contributionDetails: ResultWrapper<ContributionDetails | null>;
}

export const Contributions = ({ contributionDetails }: Props): JSX.Element => {
  const totalConcessionData = (contributionDetails: ContributionDetails | null) => {
    if (contributionDetails === null) return 0;
    return (
      contributionDetails.award +
      contributionDetails.directedTerminationPaymentTaxable +
      contributionDetails.employerVoluntary +
      contributionDetails.memberConcessional +
      contributionDetails.salarySacrifice +
      contributionDetails.superGuarantee
    );
  };

  const totalNonConcessionData = (contributionDetails: ContributionDetails | null) => {
    if (contributionDetails === null) return 0;
    return (
      contributionDetails.assessableForeignFund +
      contributionDetails.cgtSmallBusRetirementExempt +
      contributionDetails.cgtSmallBus15yrExempt +
      contributionDetails.coContribution +
      contributionDetails.directedTerminationPaymentTaxFree +
      contributionDetails.fhssRecontribution +
      contributionDetails.kiwiNonAssessableForeignFund +
      contributionDetails.firstHomeSaverAccount +
      contributionDetails.homeDownsize +
      contributionDetails.insuranceProceeds +
      contributionDetails.lowIncomeSuperContribution +
      contributionDetails.memberNonConcessional +
      contributionDetails.nonAssessableForeignFund +
      contributionDetails.otherFamilyAndFriend +
      contributionDetails.personalInjury +
      contributionDetails.spouseAndChild
    );
  };

  return (
    <>
      <Typography variant="h4" style={{ padding: '20px 0' }}>
        <b>Concessional</b>
      </Typography>

      {contributionDetails.status !== 200 ? (
        <Alert style={{ marginRight: '20px', marginTop: '20px', marginBottom: '20px' }} variant="outlined" severity="info">
          <Typography>
            There is currently no contribution information for this member. If the member has been recently established it may take a few days for this data to
            be populated.
          </Typography>
        </Alert>
      ) : (
        <>
          <Paper elevation={5} style={{ marginBottom: '30px' }}>
            <TableContainer component={Paper} elevation={0}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">
                      <Typography variant="h6" color="textSecondary">
                        COMPONENT
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography variant="h6" color="textSecondary">
                        VALUE ($)
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="left">
                      <Typography variant="h5">Award</Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography data-testid="contributionDetailData" variant="h5">
                        ${formatNumberCommaSeparated(contributionDetails?.responseData?.award || 0, 2)}
                      </Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell align="left">
                      <Typography variant="h5">Concessional</Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography data-testid="memberConcessionalData" variant="h5">
                        ${formatNumberCommaSeparated(contributionDetails?.responseData?.memberConcessional || 0, 2)}
                      </Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell align="left">
                      <Typography variant="h5">Directed Termination Payment Taxable</Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography data-testid="directedTerminationPaymentTaxableData" variant="h5">
                        ${formatNumberCommaSeparated(contributionDetails?.responseData?.directedTerminationPaymentTaxable || 0, 2)}
                      </Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell align="left">
                      <Typography variant="h5">Employer Voluntary</Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography data-testid="employerVoluntaryData" variant="h5">
                        ${formatNumberCommaSeparated(contributionDetails?.responseData?.employerVoluntary || 0, 2)}
                      </Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell align="left">
                      <Typography variant="h5">Salary Sacrifice</Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography data-testid="salarySacrificeData" variant="h5">
                        ${formatNumberCommaSeparated(contributionDetails?.responseData?.salarySacrifice || 0, 2)}
                      </Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell align="left">
                      <Typography variant="h5">Super Guarantee</Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography data-testid="superGuaranteeData" variant="h5">
                        ${formatNumberCommaSeparated(contributionDetails?.responseData?.superGuarantee || 0, 2)}
                      </Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell align="left">
                      <Typography style={{ fontWeight: 'bold' }} variant="h5">
                        Total
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography data-testid="totalConcessionData" style={{ fontWeight: 'bold' }} variant="h5">
                        ${formatNumberCommaSeparated(totalConcessionData(contributionDetails.responseData ?? null) || 0, 2)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>

          <Typography variant="h4" style={{ padding: '20px 0' }}>
            <b>Non Concessional</b>
          </Typography>

          <Paper elevation={5} style={{ marginBottom: '30px' }}>
            <TableContainer component={Paper} elevation={0}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">
                      <Typography variant="h6" color="textSecondary">
                        COMPONENT
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography variant="h6" color="textSecondary">
                        VALUE ($)
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="left">
                      <Typography variant="h5">Assessable Foreign Fund</Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography data-testid="assessableForeignFundData" variant="h5">
                        ${formatNumberCommaSeparated(contributionDetails?.responseData?.assessableForeignFund || 0, 2)}
                      </Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell align="left">
                      <Typography variant="h5">CGT Small Bus 15 Exempt</Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography data-testid="cgtSmallBus15yrExemptData" variant="h5">
                        ${formatNumberCommaSeparated(contributionDetails?.responseData?.cgtSmallBus15yrExempt || 0, 2)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left">
                      <Typography variant="h5">CGT Small Business Retirement Exempt</Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography data-testid="cgtSmallBusRetirementExemptData" variant="h5">
                        ${formatNumberCommaSeparated(contributionDetails?.responseData?.cgtSmallBusRetirementExempt || 0, 2)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left">
                      <Typography variant="h5">CoContribution</Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography data-testid="coContributionData" variant="h5">
                        ${formatNumberCommaSeparated(contributionDetails?.responseData?.coContribution || 0, 2)}
                      </Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell align="left">
                      <Typography variant="h5">Directed Termination Payment Tax Free</Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography data-testid="directedTerminationPaymentTaxableData" variant="h5">
                        ${formatNumberCommaSeparated(contributionDetails?.responseData?.directedTerminationPaymentTaxFree || 0, 2)}
                      </Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell align="left">
                      <Typography variant="h5">FHSS Recontribution</Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography data-testid="fhssRecontributionData" variant="h5">
                        ${formatNumberCommaSeparated(contributionDetails?.responseData?.fhssRecontribution || 0, 2)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left">
                      <Typography variant="h5">First Home Saver Account</Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography data-testid="firstHomeSaverAccountData" variant="h5">
                        ${formatNumberCommaSeparated(contributionDetails?.responseData?.firstHomeSaverAccount || 0, 2)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left">
                      <Typography variant="h5">Home Downsize</Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography data-testid="homeDownsizeData" variant="h5">
                        ${formatNumberCommaSeparated(contributionDetails?.responseData?.homeDownsize || 0, 2)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left">
                      <Typography variant="h5">Insurance Proceeds</Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography data-testid="insuranceProceedsData" variant="h5">
                        ${formatNumberCommaSeparated(contributionDetails?.responseData?.insuranceProceeds || 0, 2)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left">
                      <Typography variant="h5">Kiwi Non Assessable Foreign Fund</Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography data-testid="kiwiNonAssessableForeignFundData" variant="h5">
                        ${formatNumberCommaSeparated(contributionDetails?.responseData?.kiwiNonAssessableForeignFund || 0, 2)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left">
                      <Typography variant="h5">Low Income Super</Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography data-testid="lowIncomeSuperContributionData" variant="h5">
                        ${formatNumberCommaSeparated(contributionDetails?.responseData?.lowIncomeSuperContribution || 0, 2)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left">
                      <Typography variant="h5">Non Assessable Foreign Fund</Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography data-testid="nonAssessableForeignFundData" variant="h5">
                        ${formatNumberCommaSeparated(contributionDetails?.responseData?.nonAssessableForeignFund || 0, 2)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left">
                      <Typography variant="h5">Non Concessional</Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography data-testid="memberNonConcessionalData" variant="h5">
                        ${formatNumberCommaSeparated(contributionDetails?.responseData?.memberNonConcessional || 0, 2)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left">
                      <Typography variant="h5">Other Family and Friend</Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography data-testid="otherFamilyAndFriendData" variant="h5">
                        ${formatNumberCommaSeparated(contributionDetails?.responseData?.otherFamilyAndFriend || 0, 2)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left">
                      <Typography variant="h5">Personal Injury</Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography data-testid="personalInjuryData" variant="h5">
                        ${formatNumberCommaSeparated(contributionDetails?.responseData?.personalInjury || 0, 2)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left">
                      <Typography variant="h5">Spouse And Child</Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography data-testid="spouseAndChildData" variant="h5">
                        ${formatNumberCommaSeparated(contributionDetails?.responseData?.spouseAndChild || 0, 2)}
                      </Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell align="left">
                      <Typography style={{ fontWeight: 'bold' }} variant="h5">
                        Total
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography data-testid="totalNonConcessionData" style={{ fontWeight: 'bold' }} variant="h5">
                        ${formatNumberCommaSeparated(totalNonConcessionData(contributionDetails.responseData ?? null) || 0, 2)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </>
      )}
    </>
  );
};
