import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../../../app/api';
import { sendEmailPayload, User } from './types';

export enum EmailTemplateSendTypes {
  FetchUser = '@@bulk/email/users',
  SendEmail = '@@bulk/email/SendEmail',
}

export enum EmailTemplateSendApi {
  FetchUser = '/users',
  SendEmail = '/emails/SendEmail',
}

export const fetchUser = createAsyncThunk(EmailTemplateSendTypes.FetchUser, async () => {
  const response = await api.get<User>(`${EmailTemplateSendApi.FetchUser}`);
  return response.data;
});

export const sendEmail = createAsyncThunk(EmailTemplateSendTypes.SendEmail, async (payload: sendEmailPayload) => {
  await api.post(`${EmailTemplateSendApi.SendEmail}`, payload);
  return { message: 'Email sent.' };
});
