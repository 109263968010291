import _ from 'lodash';
import { Feature } from './types';

export const isFeatureEnabled = (featureName: string, featureFlags: Feature[]): boolean => {
  const result = false;

  if (featureFlags) {
    const flag = _.find(featureFlags, (i) => i.name === featureName);
    if (flag) {
      return flag.enabled;
    }
  }

  return result;
};
