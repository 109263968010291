import AttachmentIcon from '@mui/icons-material/Attachment';
import { Backdrop, Box, Chip, Fade, Grid, Modal, Paper, Typography } from '@mui/material';
import { Field, Form, Formik, FormikProps } from 'formik';
import React, { useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import WO2Button from 'src/common/components/button/Button';
import { LoadingProgress } from 'src/common/store/types';
import * as yup from 'yup';
import { TestContext } from 'yup';
import {
  EditCancelSaveButtons,
  FormikKeyboardDatePicker,
  FormikSelect,
  FormikSwitch,
  FormikTextField,
  Mode,
} from '../../../../../../common/components/formik';
import { toBase64 } from '../../../../../../common/utils';
import { useStyles } from '../../../../../../themes/index';
import {
  DocumentDetails,
  DocumentType,
  identificationDocumentTypesWithExpiryDateRequired,
  SaveDocumentDetails,
} from '../../store/types';
export interface DocumentProps {
  type: 'Documents' | 'Identifications';
  documentTypes: DocumentType[];
  document: DocumentDetails;
  saveProgress: LoadingProgress;
  showRequiredAsterisk?: boolean;
  hideDocumentUpload?: boolean;
  onSave: (saveDocumentPayload: SaveDocumentDetails) => void;
  handleCloseModal: () => void;
  fetchDocumentTypes: () => void;
}

export function Document({
  type,
  document,
  documentTypes,
  saveProgress,
  showRequiredAsterisk,
  hideDocumentUpload,
  onSave,
  handleCloseModal,
  fetchDocumentTypes,
}: DocumentProps): JSX.Element {
  const initialFormValues: SaveDocumentDetails = {
    id: null,
    typeId: null,
    name: '',
    issueDate: '',
    expiryDate: '',
    number: '',
    placeOfIssue: '',
    file: null,
    isPhotoShown: false,
    fileName: '',
    base64Content: null,
  };

  const classes = useStyles();
  const [documentPayload, setDocumentPayload] = useState<SaveDocumentDetails>(initialFormValues);
  const [existingFileName, setExistingFileName] = useState<string | null>(null);

  useEffect(() => {
    fetchDocumentTypes();
  }, [fetchDocumentTypes]);

  useEffect(() => {
    setExistingFileName(document.fileName);

    setDocumentPayload({
      ...document,
      isPhotoShown: document.isPhotoShown || false,
      file: null,
      base64Content: null,
    });
  }, [document]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={true}
      onClose={() => handleCloseModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={true}>
        <Paper
          elevation={0}
          style={{
            width: '800px',
            minHeight: '300px',
            maxHeight: '660px',
            padding: '40px 40px',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <Formik<SaveDocumentDetails>
            initialValues={documentPayload}
            onSubmit={async (details) => {
              onSave({ ...details });
            }}
            enableReinitialize={true}
            validationSchema={yup.object({
              name: yup.string().required('Name required'),
              typeId: yup.number().nullable().required('Type required'),
              inceptionDate: yup.date().nullable(),
              issueDate: yup.date().nullable(),
              expiryDate: yup
                .date()
                .nullable()
                .when('typeId', {
                  is: (typeId) => !!identificationDocumentTypesWithExpiryDateRequired.find((id) => id === typeId),
                  then: yup.date().nullable().required('Expiry Date required'),
                }),
              number: type === 'Documents' ? yup.string() : yup.string().required('Document Number required'),
              placeOfIssue: yup.string(),
              file: !!hideDocumentUpload
                ? yup.mixed().nullable()
                : yup.mixed().test('file test', 'File required', function (this: TestContext, file: unknown): boolean {
                    const id = this.parent.id;
                    if (!!id) {
                      // we allow user to not select a file - that would keep existing file
                      return !!existingFileName;
                    } else {
                      // new document - must have a file
                      return !!file;
                    }
                  }),
            })}
          >
            {(props: FormikProps<SaveDocumentDetails>) => (
              <Form>
                <fieldset style={{ border: 'none', padding: '0' }}>
                  <Typography variant="h3" style={{ padding: '20px 0 0px 0' }}>
                    {type}
                  </Typography>
                  <Grid container>
                    {!hideDocumentUpload && (
                      <Grid item xs={12}>
                        <Dropzone
                          disabled={!document.isEditable}
                          onDrop={async (files) => {
                            // reset the name to the filename and set the file
                            if (!!files && !!files[0]) {
                              const file = files[0];

                              await toBase64(file).then((base64Content) => {
                                props.setValues({
                                  ...props.values,
                                  file,
                                  name: file.name,
                                  fileName: file.name,
                                  base64Content,
                                });
                                props.validateForm();
                              });
                            }
                          }}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div
                              data-testid="dropzone"
                              {...getRootProps({ className: classes.dropzone })}
                              style={{
                                borderColor: !!props.errors.file ? 'red' : 'gray',
                                marginTop: 10,
                                marginBottom: 20,
                                marginLeft: 0,
                                marginRight: 0,
                                minHeight: 80,
                              }}
                            >
                              <input
                                {...getInputProps({
                                  // accept: '.*',
                                  type: 'file',
                                })}
                              />
                              {(!!props.values.file || existingFileName) && (
                                <Chip
                                  disabled={!document.isEditable}
                                  label={props.values.file?.name || document.fileName}
                                  onDelete={() => {
                                    setExistingFileName(null); // if chip is showing existing fileName, set it to null
                                    props.setValues({ ...props.values, file: null });
                                  }}
                                  icon={<AttachmentIcon />}
                                />
                              )}
                              {!props.values.file && !existingFileName && (
                                <p style={{ color: !!props.errors.file ? 'red' : 'gray' }}>
                                  {'Drag and drop your file here, or click to select file'}
                                </p>
                              )}
                            </div>
                          )}
                        </Dropzone>
                      </Grid>
                    )}
                    <Grid item xs={6} style={{ paddingRight: '20px', paddingTop: '20px' }}>
                      <Grid item xs={12} style={{ paddingBottom: '20px', minHeight: '90px' }}>
                        <Field
                          disabled={!document.isEditable}
                          name="name"
                          component={FormikTextField}
                          label="DOCUMENT NAME"
                          showRequiredAsterisk={!!showRequiredAsterisk}
                          fullWidth
                        ></Field>
                      </Grid>
                      <Grid item xs={12} style={{ paddingBottom: '20px', minHeight: '90px' }}>
                        <Field
                          disabled={!document.isEditable}
                          component={FormikKeyboardDatePicker}
                          name="issueDate"
                          label="ISSUE DATE"
                          showRequiredAsterisk={!showRequiredAsterisk}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} style={{ paddingBottom: '20px', minHeight: '90px' }}>
                        <Field
                          disabled={!document.isEditable}
                          component={FormikKeyboardDatePicker}
                          name="expiryDate"
                          label="EXPIRY DATE"
                          showRequiredAsterisk={
                            !!showRequiredAsterisk &&
                            props.values.typeId !== null &&
                            !!identificationDocumentTypesWithExpiryDateRequired.find((id) => id === props.values.typeId)
                          }
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={6} style={{ paddingTop: '20px' }}>
                      <Grid item xs={12} style={{ paddingBottom: '20px', minHeight: '90px' }}>
                        <Field
                          disabled={!document.isEditable}
                          fieldName="typeId"
                          as={FormikSelect}
                          itemDisplayNameField="name"
                          label="DOCUMENT TYPE"
                          id="typeIdSelect"
                          data={documentTypes}
                          onChange={() => {
                            return;
                          }}
                          showRequiredAsterisk={!!showRequiredAsterisk}
                          fullWidth
                        ></Field>
                      </Grid>
                      <Grid item xs={12} style={{ paddingBottom: '20px', minHeight: '90px' }}>
                        <Field
                          disabled={!document.isEditable}
                          name="number"
                          component={FormikTextField}
                          label="DOCUMENT NUMBER"
                          showRequiredAsterisk={!!showRequiredAsterisk}
                          fullWidth
                        ></Field>
                      </Grid>
                      <Grid item xs={12} style={{ paddingBottom: '20px' }}>
                        <Field
                          disabled={!document.isEditable}
                          name="placeOfIssue"
                          component={FormikTextField}
                          label="ISSUE PLACE"
                          fullWidth
                        ></Field>
                      </Grid>
                      {type === 'Identifications' && (
                        <Grid item xs={12} style={{ paddingBottom: '20px' }}>
                          <Field
                            disabled={!document.isEditable}
                            name="isPhotoShown"
                            component={FormikSwitch}
                            label="IS PHOTO SHOWN"
                          ></Field>
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        {!document.isEditable && (
                          <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <WO2Button
                              type="button"
                              data-testid="closeButton"
                              variant="contained"
                              onClick={handleCloseModal}
                              style={{ marginRight: '10px' }}
                            >
                              Close
                            </WO2Button>
                          </Box>
                        )}
                        {document.isEditable && (
                          <EditCancelSaveButtons
                            mode={Mode.CancelSave}
                            handleCancelClick={handleCloseModal}
                            saveProgress={saveProgress}
                          ></EditCancelSaveButtons>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </fieldset>
              </Form>
            )}
          </Formik>
        </Paper>
      </Fade>
    </Modal>
  );
}
