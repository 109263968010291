import { moment } from 'src/common/types';
import { DateRange } from '../../../../../../store';

export interface OverviewState {
  parameters: CapitalGainsLossesParameters;
  unrealisedResult?: UnrealisedCapitalGainsLossesResult;
  realisedResult?: RealisedCapitalGainsLossesResult;
}

export interface UnrealisedGainsAndLossesRequest {
  clientId: number;
  dateTo: moment;
}

export interface RealisedGainsAndLossesRequest {
  clientId: number;
  dateFrom: moment;
  dateTo: moment;
}

export interface CapitalGainsLossesParameters {
  gainType: GainType;
  dates: DateRange; // daterange for realised
  filter: string;
}

export enum GainType {
  Unrealised = 'Unrealised',
  Realised = 'Realised',
}

export interface CapitalGainsSummary {
  carryForwardLoss?: number | null;
  carryForwardGain?: number | null;
  lossSubtotal?: number | null;
  gainLossSubtotal?: number | null;
  discountAfterLoss?: number | null;
  discountApplied?: number | null;
  gainLossTotal?: number | null;
}

export interface CapitalGainsTotal {
  units?: number | null;
  originalCostBase?: number | null;
  adjustChangeValue?: number | null;
  amitCBChangeValue?: number | null;
  cgtConcessionValue?: number | null;
  taxDeferredValue?: number | null;
  taxExemptValue?: number | null;
  taxFreeValue?: number | null;
  adjustedCostBase?: number | null;
  reducedCostBase?: number | null;
  appliedAdjustments?: number | null;
  acquisitionCostBase?: number | null;
  baseGainLoss?: number | null;
  capitalLoss?: number | null;
  capitalGainPreDiscount?: number | null;
  capitalGainPostDiscount?: number | null;
  capitalGainExempt?: number | null;
  capitalGainIndexed?: number | null;
  capitalGainOther?: number | null;
  disposalCostBase?: number | null;
}

export interface UnrealisedParcelDetails {
  acquisitionDate: moment;
  units?: number | null;
  originalCostBase?: number | null;
  costBaseAdjustments?: number | null;
  adjustedReducedCostbase?: number | null;
  marketValue?: number | null;
  unrealisedGainLoss?: number | null;
  indexation?: number | null;
  discountable?: number | null;
  other?: number | null;
  capitalLoss?: number | null;
}

export interface UnrealisedGainsLossesResultItemCSVRow {
  isTotal: string;
  securityCode: string;
  securityName: string;
  acquisitionDate: moment;
  units?: number | null;
  originalCostBase?: number | null;
  costBaseAdjustments?: number | null;
  adjustedReducedCostbase?: number | null;
  marketValue?: number | null;
  unrealisedGainLoss?: number | null;
  indexation?: number | null;
  discountable?: number | null;
  other?: number | null;
  capitalLoss?: number | null;
}

export interface UnrealisedGainsLossesResultItemCSVRowWithLineItems extends UnrealisedGainsLossesResultItemCSVRow {
  items: UnrealisedGainsLossesResultItemCSVRow[];
}

export interface UnrealisedGainsLossesResultHeaderCSVRow {
  isTotal: string;
  securityCode: string;
  securityName: string;
  acquisitionDate: moment;
  units: string;
  originalCostBase: string;
  costBaseAdjustments: string;
  adjustedReducedCostbase: string;
  marketValue: string;
  unrealisedGainLoss?: string;
  indexation: string;
  discountable: string;
  other: string;
  capitalLoss: string;
}

export interface UnrealisedCapitalGainsSecurityDetails {
  securityTotals: UnrealisedSecurityTotals;
  parcelDetails: UnrealisedParcelDetails[];
}

// GainsLossesResultItem
export interface UnrealisedCapitalGainsLossesResult {
  summary: CapitalGainsSummary;
  total: CapitalGainsTotal;
  securityDetails: UnrealisedCapitalGainsSecurityDetails[];
}

export interface RealisedCapitalGainsLossesResult {
  summary: CapitalGainsSummary;
  total: CapitalGainsTotal;
  securityDetails: RealisedCapitalGainsSecurityDetails[];
}

export interface RealisedSecurityTotals {
  units?: number | null;
  originalCostBase?: number | null;
  adjustmentsCostBase?: number | null;
  taxFree?: number | null;
  taxDeferred?: number | null;
  taxExempt?: number | null;
  cgtConcession?: number | null;
  corporateActionIncreaseDecrease?: number | null;
  amitCostBaseIncreaseDecrease?: number | null;
  adjustedCostbase?: number | null;
  reducedCostbase?: number | null;
  proceeds?: number | null;
  realisedGainLoss?: number | null;
  indexation?: number | null;
  discountable?: number | null;
  other?: number | null;
  capitalLoss?: number | null;
  securityCode: string;
  securityName: string;
  securityType: string;
  accountNumber: string;
}

export interface RealisedParcelDetails {
  units?: number | null;
  originalCostBase?: number | null;
  adjustmentsCostBase?: number | null;
  taxFree?: number | null;
  taxDeferred?: number | null;
  taxExempt?: number | null;
  cgtConcession?: number | null;
  corporateActionIncreaseDecrease?: number | null;
  amitCostBaseIncreaseDecrease?: number | null;
  adjustedCostbase?: number | null;
  reducedCostbase?: number | null;
  proceeds?: number | null;
  realisedGainLoss?: number | null;
  indexation?: number | null;
  discountable?: number | null;
  other?: number | null;
  capitalLoss?: number | null;
  acquisitionDate: moment;
  strategy: string;
  disposalDate: moment;
}

export interface RealisedGainsLossesResultItemCSVRow {
  isTotal: string;
  securityCode: string;
  securityName: string;
  disposalDate: moment;
  acquisitionDate: moment;
  units?: number | null;
  originalCostBase?: number | null;
  taxFree?: number | null;
  taxDeferred?: number | null;
  taxExempt?: number | null;
  cgtConcession?: number | null;
  amitCostBaseIncreaseDecrease?: number | null;
  adjustedCostbase?: number | null;
  reducedCostbase?: number | null;
  proceeds?: number | null;
  realisedGainLoss?: number | null;
  indexation?: number | null;
  discountable?: number | null;
  other?: number | null;
  capitalLoss?: number | null;
  strategy: string;
}

export interface RealisedGainsLossesResultItemCSVRowWithLineItems extends RealisedGainsLossesResultItemCSVRow {
  items: RealisedGainsLossesResultItemCSVRow[];
}

export interface RealisedGainsLossesResultHeaderCSVRow {
  isTotal: string;
  securityCode: string;
  securityName: string;
  disposalDate: moment;
  acquisitionDate: moment;
  units: string;
  originalCostBase: string;
  taxFree: string;
  taxDeferred: string;
  taxExempt: string;
  cgtConcession: string;
  amitCostBaseIncreaseDecrease: string;
  adjustedCostbase: string;
  reducedCostbase: string;
  proceeds: string;
  realisedGainLoss: string;
  indexation: string;
  discountable: string;
  other: string;
  capitalLoss: string;
  strategy: string;
}

export interface RealisedCapitalGainsSecurityDetails {
  securityTotals: RealisedSecurityTotals;
  parcelDetails: RealisedParcelDetails[];
}

export interface UnrealisedSecurityTotals {
  units?: number | null;
  originalCostBase?: number | null;
  costBaseAdjustments?: number | null;
  adjustedReducedCostbase?: number | null;
  marketValue?: number | null;
  unrealisedGainLoss?: number | null;
  indexation?: number | null;
  discountable?: number | null;
  other?: number | null;
  capitalLoss?: number | null;
  securityCode: string;
  securityName: string;
  securityType: string;
  accountNumber: string;
}

export interface ExpandedRow {
  [key: number]: number[];
}

export interface UnrealisedReportDetail extends UnrealisedSecurityTotals {
  isGroup: boolean;
  acquisitionDate: moment;
  items: UnrealisedReportDetail[];
}

export interface RealisedReportDetail extends RealisedSecurityTotals {
  isGroup: boolean;
  acquisitionDate: moment;
  items: RealisedReportDetail[];
  strategy: string;
  disposalDate: moment;
}
