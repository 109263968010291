import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../../app/api';
import { PagedResult } from '../../../../store';
import { FxItem, FxListParameters } from './types';

export enum FxListActionTypes {
  FetchFxList = '@@forexrates/list/FetchFxList',
  DownloadFxs = '@@forexrates/list/DownloadFxs',
}

export enum FxListApiEndpoints {
  fetchFxList = '/securities/GetForexCurrencyPairList',
}

const getForexCurrencyPairList = async (request: FxListParameters) => {
  const body = {
    currencyCodeOrNameSearch: request.currencyCodeOrNameSearch,
    pagedRequest: request.pagination,
  };
  return await api.post<PagedResult<FxItem>>(`${FxListApiEndpoints.fetchFxList}`, body);
};

export const fetchFxList = createAsyncThunk(FxListActionTypes.FetchFxList, async (wrapper: FxListParameters) => {
  const response = await getForexCurrencyPairList(wrapper);
  return {
    results: response.data,
    pagination: wrapper.pagination,
  };
});
