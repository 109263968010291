import { Skeleton, Typography } from '@mui/material';
import React from 'react';
import { LoadingProgress } from 'src/common/store/types';
import { WO2Modal } from '../../../../../../../common/components/Modal';

export interface Props {
  onClose: () => void;
  archivePortfolio: () => Promise<void>;
  progress: LoadingProgress;
}

export function ArchiveDialog({ onClose, archivePortfolio, progress }: Props): JSX.Element {
  return (
    <WO2Modal
      MuiDialogProps={{
        open: true,
        onClose: () => {
          onClose();
        },
        maxWidth: 'md',
      }}
      title={<>Archive portfolio</>}
      actions={[
        {
          label: 'Close',
          onClickHandler: () => {
            onClose();
          },
        },
        {
          label: 'Archive',
          onClickHandler: async () => {
            await archivePortfolio();
          },
          type: 'contained',
          disabled: progress.isLoading,
        },
      ]}
    >
      <>
        {progress.isLoading && (
          <>
            <Skeleton variant="rectangular" width="100%" height={50} style={{ marginTop: '30px' }} />
            <Skeleton variant="rectangular" width="100%" height={118} style={{ marginTop: '10px' }} />
          </>
        )}
        <>
          <Typography variant="h4" style={{ fontWeight: 700, paddingTop: '30px' }}>
            You can archive this portfolio to prevent rebalances using the configuration.
          </Typography>
          <br />
          <p>Please note, that without an active portfolio you will not be able to place orders for this client.</p>
        </>
      </>
    </WO2Modal>
  );
}
