import { ArrowBackOutlined } from '@mui/icons-material';
import { Box, Breadcrumbs, Grid, Link } from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Configuration, SecurityConstraint, StrategicAssetAllocation } from '../../store/types';
import { Props } from '../container';
import { AssetClassTolerances } from './assetClassTolerances';
import { ConfigurationTable } from './configurationTable';
import { ConstraintsTable } from './constraintsTable';
import { DetailsForm } from './detailsForm';
import { SecurityTolerances } from './securityTolerances';
import { StrategicAllocationTable } from './strategicAllocationTable';

export const Edit = ({
  fetchTemplate,
  isLoadingProgress,
  match,
  savingProgress,
  setDetails,
  setAssetClassToleranceBands,
  setSecurityToleranceBands,
  editState,
  updateTemplate,
  setAfslId,
  setInvestmentServiceTemplateId,
  saveTemplatePayload,
  setStrategicAssetAllocations,
  setSecurityConstraints,
  approvedProducts,
  approvedProductsLoadingProgress,
  cashAccountProduct,
  setConfigurations,
  fetchApprovedProducts,
  fetchBenchmarks,
}: Props): JSX.Element => {
  const history = useHistory();

  useEffect(() => {
    setAfslId(+match.params.afslId);
    setInvestmentServiceTemplateId(+match.params.id);
    fetchBenchmarks(+match.params.afslId);
  }, [match.params.afslId, match.params.id]);

  useEffect(() => {
    if (!!editState.afslId && !!editState.investmentServiceTemplateId) {
      fetchTemplate({ afslId: editState.afslId, investmentServiceTemplateId: editState.investmentServiceTemplateId });
      fetchApprovedProducts({ afslId: editState.afslId });
    }
  }, [editState.investmentServiceTemplateId, editState.afslId, fetchTemplate]);

  const onSave = useCallback(async () => {
    await updateTemplate();
  }, [saveTemplatePayload]);

  return (
    <>
      <Box style={{ width: '50%' }}>
        <Breadcrumbs aria-label="breadcrumb" style={{ paddingBottom: '20px' }}>
          <Link
            color="primary"
            onClick={() => {
              history.push(`/templates/portfolios/${editState.afslId}`);
            }}
            style={{ textDecoration: 'none' }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
              }}
            >
              <ArrowBackOutlined />
              <span style={{ paddingLeft: '10px', paddingTop: '1px' }}>Back to portfolios</span>
            </div>
          </Link>
        </Breadcrumbs>
      </Box>

      {!!editState.details && (
        <>
          <DetailsForm
            templateDetails={editState.details}
            benchmarks={editState.benchmarks}
            loadingProgress={isLoadingProgress}
            onSave={async (details) => {
              setDetails(details);
              return await onSave();
            }}
            savingProgress={savingProgress}
          ></DetailsForm>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <SecurityTolerances
                securityRebalanceToleranceBands={editState.securityToleranceBands}
                loadingProgress={isLoadingProgress}
                onSave={async (bands) => {
                  setSecurityToleranceBands(bands);
                  return await onSave();
                }}
                savingProgress={savingProgress}
              />
            </Grid>
            <Grid item xs={6}>
              <AssetClassTolerances
                assetClassToleranceBands={editState.assetClassToleranceBands}
                loadingProgress={isLoadingProgress}
                onSave={async (bands) => {
                  setAssetClassToleranceBands(bands);
                  return await onSave();
                }}
                savingProgress={savingProgress}
              />
            </Grid>
          </Grid>
          <ConfigurationTable
            items={editState.configurations}
            onSave={async (configurations: Configuration[]) => {
              setConfigurations(configurations);
              return await onSave();
            }}
            itemLoadingProgress={isLoadingProgress}
            approvedProducts={approvedProducts}
            cashAccountProduct={cashAccountProduct}
            savingProgress={savingProgress}
          />

          <StrategicAllocationTable
            items={editState.strategicAssetAllocations}
            onSave={async (strategicAllocationItems: StrategicAssetAllocation[]) => {
              setStrategicAssetAllocations(strategicAllocationItems);
              return await onSave();
            }}
            itemLoadingProgress={isLoadingProgress}
            savingProgress={savingProgress}
          />

          <ConstraintsTable
            items={editState.securityConstraints}
            securities={approvedProducts}
            onSave={async (constraints: SecurityConstraint[]) => {
              setSecurityConstraints(constraints);
              return await onSave();
            }}
            itemLoadingProgress={isLoadingProgress}
            securitiesLoadingProgress={approvedProductsLoadingProgress}
            savingProgress={savingProgress}
          />
        </>
      )}
    </>
  );
};
