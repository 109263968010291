import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../../../../app/api';
import { buildEncodedQueryString, downloadFile } from '../../../../../../common';
import { CommonAccountsApiEndpoints } from '../../../../../../common/components/accounts/enums';
import {
  Account,
  AccountType,
  AttachableCmaAccounts,
  CreateExternalAccountPayload,
  CreateUnlistedAccountPayload,
  DetachAccountPayload,
  ExternalInstitution,
  PendingAccount,
  PendingAccounts,
} from '../../../../../../common/components/accounts/types';
import {
  AccountDetails,
  ActionApplicationSubmissionPayload,
  BPAYAccountDetails,
  ClientNewMacquarieAccountDetailByClientIdPayload,
  CreateCustodyAccountsPayload,
  CreatePendingAccountPayload,
  DeleteAccountPayload,
  DownloadSuperSimplifierApplicationPayload,
  DownloadSuperSimplifierOngoingFeeConsentRenewalPayload,
  FetchAccountPayload,
  FetchBPAYDetailsPayload,
  InitiateAccountApplicationPayload,
  InitiateExistingMacquarieAccountApplicationPayload,
  InitiateNewMacquarieAccountApplicationPayload,
  LinkExistingPendingAccountPayload,
  SendFeeConsentRenewalPayload,
  SendFormPayload,
  UpdatePendingAccountStatusPayload,
  UploadSuperSimplifierApplicationFormPayload,
  UploadSuperSimplifierSignedOngoingFeeConsentRenewalPayload,
} from './types';
import { ContactRoles } from '../../superSimplifier/insuranceDetail/store/types';

export enum AccountsActionTypes {
  FetchAccounts = '@@client/details/account/FetchAccounts',
  FetchPendingAccounts = '@@client/details/account/FetchPendingaccounts',
  CreatePendingAccount = '@@client/details/account/CreatePendingaccount',
  LinkExistingPendingAccount = '@@client/details/account/LinkExistingPendingAccount',
  fetchTradingAccountForEdit = '@@client/details/account/fetchTradingAccountForEdit',
  fetchExternalAccountForEdit = '@@client/details/account/fetchExternalAccountForEdit',
  fetchAccountTypes = '@@client/details/account/fetchAccountTypes',
  CreateExternalAccount = '@@client/details/account/CreateExternalAccount',
  GetExternalAccountInstitutions = '@@client/details/account/GetExternalAccountInstitutions',
  CreateUnlistedAccount = '@@client/details/account/CreateAndAttachUnlistedAccount',
  DetachAccount = '@@client/details/account/DetachAccount',
  DownloadSuperSimplifierOngoingFeeConsentRenewal = '@@client/details/account/DownloadSuperSimplifierOngoingFeeConsentRenewal',
  DownloadSuperSimplifierApplication = '@@client/details/account/DownloadSuperSimplifierApplication',
  UploadSuperSimplifierSignedOngoingFeeConsentRenewal = '@@client/details/account/UploadSuperSimplifierSignedOngoingFeeConsentRenewal',
  SendFeeConsentRenewal = '@@client/details/account/SendFeeConsentRenewal',
  SendApplicationForm = '@@client/details/account/SendApplicationForm',
  InitiateExistingMacquarieAccountApplication = '@@client/details/pendingAccount/InitiateExistingMacquarieAccountApplication',
  SubmitOpenMarketsAccountApplication = '@@client/details/pendingAccount/SubmitOpenMarketsAccountApplication',
  SubmitAMMAccountApplication = '@@client/details/pendingAccount/SubmitAMMAccountApplication',
  SubmitDesktopBrokerAccountApplication = '@@client/details/pendingAccount/SubmitDesktopBrokerAccountApplication',
  FetchAttachableCmaAccounts = '@@client/details/pendingAccount/GetAttachableCmaAccounts',
  InitiateNewMacquarieAccountApplication = '@@client/details/pendingAccount/InitiateNewMacquarieAccountApplication',
  GetClientNewMacquarieAccountDetailByClientId = '@@client/details/pendingAccount/macquarie/GetClientNewAccountDetailByClientId',
  InitiateUxchangeAccountApplicationWorkflow = '@@client/details/pendingAccount/uxchangeworkflow/InitiateAccountApplicationWorkflow',
  DeletePendingAccount = '@@client/details/pendingAccount/DeletePendingAccount',
  UpdatePendingAccountStatus = '@@client/details/pendingAccount/UpdatePendingAccountStatus',
  FetchPendingSuperAccount = '@@client/details/pendingAccount/fetchPendingSuperAccount',
  ActionApplicationSubmission = '@@client/details/pendingAccount/ActionApplicationSubmission',
  UploadSuperSimplifierApplicationForm = '@@client/details/pendingAccount/UploadSuperSimplifierApplicationForm',
  CreateCustodyAccounts = '@@client/details/account/CreateCustodyAccounts',
  FetchClientContacts = '@@client/details/account/FetchClientContacts',
  FetchBPAYDetails = '@@client/details/account/FetchBPAYDetails',
}

export enum AccountsApiEndpoints {
  FetchAttachableCmaAccounts = '/accountsnext/GetAttachableCmaAccounts',
  FetchAccounts = '/accounts/GetAccountsByClientId',
  FetchPendingAccounts = '/accountsnext/GetPendingAccounts',
  CreatePendingAccount = 'accountsnext/CreatePendingAccounts',
  LinkExistingPendingAccount = '/accountsnext/linkpendingaccount',
  fetchAccountForEdit = '/customers/GetAccount',
  fetchAccountTypes = '/customers/GetAccountTypes',
  DownloadSuperSimplifierApplication = '/bff/GetSuperSimplifierApplication',
  DownloadSuperSimplifierOngoingFeeConsentRenewal = '/bff/GetSuperSimplifierOngoingFeeConsentRenewal',
  UploadSuperSimplifierSignedOngoingFeeConsentRenewal = '/documents/UploadDocument',
  SendApplicationForm = '/SuperSimplifierWorkflow/SendApplicationDocusign',
  SendFeeConsentRenewal = '/SuperSimplifierWorkflow/SendFeeRenewalDocusign',
  UpdateClientAccountStatusPublic = '/customers/UpdateClientAccountStatusPublic',
  InitiateExistingMacquarieAccountApplication = '/macquarieworkflow/InitiateExistingAccountApplicationWorkflow',
  InitiateAccountApplication = 'accountsnext/InitiateAccountApplication',
  InitiateNewMacquarieAccountApplication = '/macquarieworkflow/InitiateNewAccountApplicationWorkflow',
  ActionApplicationSubmission = '/SuperSimplifierWorkflow/ActionApplicationSubmission',
  GetClientNewMacquarieAccountDetailByClientId = '/macquarie/GetClientNewAccountDetailByClientId',
  InitiateUxchangeAccountApplicationWorkflow = 'accountsnext/InitiateAccountApplication',
  DeletePendingAccount = 'accountsnext/DeletePendingAccount',
  UpdatePendingAccountStatus = 'accountsnext/UpdatePendingAccountStatus',
  FetchPendingSuperAccount = 'accountsnext/GetPendingSuperAccount',
  UploadSuperSimplifierApplicationForm = 'SuperSimplifierWorkflow/UploadApplication',
  CreateCustodyAccounts = 'CustodyWorkflow/CreateCustodyAccounts',
  FetchClientContacts = 'customers/GetClientContacts',
  FetchBPAYDetails = 'api/ssc/GetBPAYDetails',
}

export const sendFeeConsentRenewal = createAsyncThunk(
  AccountsActionTypes.SendFeeConsentRenewal,
  async (payload: SendFeeConsentRenewalPayload) => {
    return api
      .post(AccountsApiEndpoints.SendFeeConsentRenewal, payload)
      .then(() => ({ message: 'Fee Consent Renewal sent.' }))
      .catch(() => ({ message: 'Could not send fee consent renewal at this time.', variant: 'error' }));
  }
);

export const sendApplicationForm = createAsyncThunk(
  AccountsActionTypes.SendApplicationForm,
  async (payload: SendFormPayload) => {
    return api
      .post(AccountsApiEndpoints.SendApplicationForm, payload)
      .then(() => ({ message: 'Application form sent.' }))
      .catch(() => ({ message: 'Could not send application form at this time.', variant: 'error' }));
  }
);

export const createPendingAccount = createAsyncThunk(
  AccountsActionTypes.CreatePendingAccount,
  async (payload: CreatePendingAccountPayload, thunkApi) => {
    return api
      .post(AccountsApiEndpoints.CreatePendingAccount, payload)
      .then(() => ({ message: 'Application form sent.' }))
      .then(() => {
        if (payload.clientId) {
          thunkApi.dispatch(fetchPendingAccounts(payload.clientId));
        }
      });
  }
);

export const linkExsistingPendingAccount = createAsyncThunk(
  AccountsActionTypes.LinkExistingPendingAccount,
  async (payload: LinkExistingPendingAccountPayload, thunkApi) => {
    const queryString = buildEncodedQueryString({
      clientId: payload.clientId,
    });

    return api
      .put(`${AccountsApiEndpoints.LinkExistingPendingAccount}${queryString}`, payload.accountsData)
      .then(() => ({ message: 'Application form sent.' }))
      .then(() => {
        if (payload.clientId) {
          thunkApi.dispatch(fetchPendingAccounts(payload.clientId));
        }
      });
  }
);

export const initiateExistingMacquarieAccountApplication = createAsyncThunk(
  AccountsActionTypes.InitiateExistingMacquarieAccountApplication,
  async (payload: InitiateExistingMacquarieAccountApplicationPayload, thunkApi) => {
    return api
      .post(`${AccountsApiEndpoints.InitiateAccountApplication}`, {
        clientId: payload.clientId,
        pendingAccountId: payload.pendingAccountId,
      })
      .then(() => ({ message: 'Application form sent.' }))
      .then(() => {
        if (payload.clientId) {
          thunkApi.dispatch(fetchPendingAccounts(payload.clientId));
        }
      });
  }
);

export const submitOpenMarkets = createAsyncThunk(
  AccountsActionTypes.SubmitOpenMarketsAccountApplication,
  async (payload: InitiateAccountApplicationPayload, thunkApi) => {
    return api
      .post(`${AccountsApiEndpoints.InitiateAccountApplication}`, payload)
      .then(() => ({ message: 'Application form sent.' }))
      .then(() => {
        if (payload.clientId) {
          thunkApi.dispatch(fetchPendingAccounts(payload.clientId));
        }
      });
  }
);

export const submitAMM = createAsyncThunk(
  AccountsActionTypes.SubmitAMMAccountApplication,
  async (payload: InitiateAccountApplicationPayload, thunkApi) => {
    return api
      .post(`${AccountsApiEndpoints.InitiateAccountApplication}`, payload)
      .then(() => ({ message: 'Application form sent.' }))
      .then(() => {
        if (payload.clientId) {
          thunkApi.dispatch(fetchPendingAccounts(payload.clientId));
        }
      });
  }
);

export const initiateNewMacquarieAccountApplication = createAsyncThunk(
  AccountsActionTypes.InitiateNewMacquarieAccountApplication,
  async (payload: InitiateNewMacquarieAccountApplicationPayload, thunkApi) => {
    return api
      .post(`${AccountsApiEndpoints.InitiateNewMacquarieAccountApplication}`, payload)
      .then(() => ({ message: 'Application form sent.' }))
      .then(() => {
        if (payload.clientId) {
          thunkApi.dispatch(fetchPendingAccounts(payload.clientId));
        }
      });
  }
);

export const initiateUxchangeworkflowAccountApplicationWorkflow = createAsyncThunk(
  AccountsActionTypes.InitiateUxchangeAccountApplicationWorkflow,
  async (payload: InitiateAccountApplicationPayload, thunkApi) => {
    return api
      .post(`${AccountsApiEndpoints.InitiateUxchangeAccountApplicationWorkflow}`, {
        pendingAccountId: payload.pendingAccountId,
        cmaSubNumber: payload.cmaSubNumber,
        forceRoa: payload.forceRoa,
        cmaName: payload.cmaName,
        cmaNumber: payload.cmaNumber,
      })
      .then(() => ({ message: 'Application form sent.' }))
      .then(() => {
        if (payload.clientId) {
          thunkApi.dispatch(fetchPendingAccounts(payload.clientId));
        }
      });
  }
);

export const submitDesktopBroker = createAsyncThunk(
  AccountsActionTypes.SubmitDesktopBrokerAccountApplication,
  async (payload: InitiateAccountApplicationPayload, thunkApi) => {
    return api
      .post(`${AccountsApiEndpoints.InitiateAccountApplication}`, payload)
      .then(() => ({ message: 'Application form sent.' }))
      .then(() => {
        if (payload.clientId) {
          thunkApi.dispatch(fetchPendingAccounts(payload.clientId));
        }
      });
  }
);

export const fetchClientNewMacquarieAccountDetailByClientId = createAsyncThunk(
  AccountsActionTypes.GetClientNewMacquarieAccountDetailByClientId,
  async (payload: ClientNewMacquarieAccountDetailByClientIdPayload) => {
    const response = await api.post(`${AccountsApiEndpoints.GetClientNewMacquarieAccountDetailByClientId}`, payload);
    return response.data;
  }
);

export const uploadSuperSimplifierOngoingFeeConsentRenewalForm = createAsyncThunk(
  AccountsActionTypes.UploadSuperSimplifierSignedOngoingFeeConsentRenewal,
  async (payload: UploadSuperSimplifierSignedOngoingFeeConsentRenewalPayload) => {
    return api
      .put(AccountsApiEndpoints.UploadSuperSimplifierSignedOngoingFeeConsentRenewal, payload)
      .then(() => {
        api.put(AccountsApiEndpoints.UpdateClientAccountStatusPublic, {
          clientId: payload.entityId,
          statusFlagId: 2,
        });
        return {
          message: 'Document Uploaded',
        };
      })
      .catch(() => ({ message: 'Could upload document at this time.', variant: 'error' }));
  }
);

export const downloadSuperSimplifierApplication = createAsyncThunk(
  AccountsActionTypes.DownloadSuperSimplifierApplication,
  async ({ clientId }: DownloadSuperSimplifierApplicationPayload) => {
    const queryString = buildEncodedQueryString({
      clientId,
    });

    return api
      .get(`${AccountsApiEndpoints.DownloadSuperSimplifierApplication}${queryString}`, {
        responseType: 'blob',
      })
      .then((response) => {
        downloadFile(response.data, 'SuperSimplifierApplicationForm', 'pdf');

        return { message: 'Downloaded application form.' };
      })
      .catch(() => ({ message: 'Could not download application at this time.', variant: 'error' }));
  }
);

export const actionApplicationSubmission = createAsyncThunk(
  AccountsActionTypes.ActionApplicationSubmission,
  async (payload: ActionApplicationSubmissionPayload) => {
    await api.post(AccountsApiEndpoints.ActionApplicationSubmission, payload).catch(() => {
      return { error: 'Unable to start application process.' };
    });
    return { message: 'Application submitted' };
  }
);

export const createCustodyAccounts = createAsyncThunk(
  AccountsActionTypes.CreateCustodyAccounts,
  async (payload: CreateCustodyAccountsPayload) => {
    await api.post(AccountsApiEndpoints.CreateCustodyAccounts, payload).catch(() => {
      return { error: 'Unable to create Custody accounts process.' };
    });
    return { message: 'Custody account application submitted' };
  }
);

export const uploadSuperSimplifierApplicationForm = createAsyncThunk(
  AccountsActionTypes.UploadSuperSimplifierApplicationForm,
  async (payload: UploadSuperSimplifierApplicationFormPayload) => {
    await api.post(AccountsApiEndpoints.UploadSuperSimplifierApplicationForm, payload).catch(() => {
      return { error: 'Unable to upload Super Simplifier Application successfully.' };
    });
    return { message: 'Application uploaded' };
  }
);

export const downloadSuperSimplifierOngoingFeeConsentRenewal = createAsyncThunk(
  AccountsActionTypes.DownloadSuperSimplifierOngoingFeeConsentRenewal,
  async ({ clientId }: DownloadSuperSimplifierOngoingFeeConsentRenewalPayload) => {
    const queryString = buildEncodedQueryString({
      clientId,
    });

    return api
      .get(`${AccountsApiEndpoints.DownloadSuperSimplifierOngoingFeeConsentRenewal}${queryString}`, {
        responseType: 'blob',
      })
      .then((response) => {
        downloadFile(response.data, 'SuperSimplifierOngoingFeeConsentRenewalForm', 'pdf');

        return { message: 'Downloaded ongoing fee consent renewal form.' };
      })
      .catch(() => ({
        message: 'Could not download ongoing fee consent renewal form at this time.',
        variant: 'error',
      }));
  }
);

export const createExternalAccount = createAsyncThunk(
  AccountsActionTypes.CreateExternalAccount,
  async (payload: CreateExternalAccountPayload, thunkApi) => {
    await api
      .post(CommonAccountsApiEndpoints.CreateExternalAccount, payload)
      .then(() => {
        thunkApi.dispatch(fetchAccounts(payload.clientId));
      })
      .catch(() => {
        return { error: 'External account could not be created' };
      });

    return { message: 'External account created' };
  }
);

export const fetchExternalInstitutions = createAsyncThunk(
  AccountsActionTypes.GetExternalAccountInstitutions,
  async () => {
    const response = await api.get<ExternalInstitution[]>(CommonAccountsApiEndpoints.GetAccountInstitutions);

    return response.data;
  }
);

export const createUnlistedAccount = createAsyncThunk(
  AccountsActionTypes.CreateUnlistedAccount,
  async (payload: CreateUnlistedAccountPayload, thunkApi) => {
    await api
      .post(CommonAccountsApiEndpoints.CreateUnlistedAccount, payload)
      .then(() => {
        thunkApi.dispatch(fetchAccounts(payload.clientId));
      })
      .catch(() => {
        return { message: 'Unlisted account could not be created', variant: 'error' };
      });

    return { message: 'Unlisted account created' };
  }
);

export const fetchAccounts = createAsyncThunk(AccountsActionTypes.FetchAccounts, async (clientId: number) => {
  const queryString = buildEncodedQueryString({
    clientId,
  });
  const response = await api.get<Account[]>(`${AccountsApiEndpoints.FetchAccounts}${queryString}`);
  return response.data;
});

export const onDetachAccount = createAsyncThunk(
  AccountsActionTypes.DetachAccount,
  async (payload: DetachAccountPayload, thunkApi) => {
    await api
      .post(CommonAccountsApiEndpoints.DetachAccount, payload)
      .then(() => {
        thunkApi.dispatch(fetchAccounts(payload.clientId));
      })
      .catch(() => {
        return { message: 'Account could not be detached', variant: 'error' };
      });

    return { message: 'Account successfully detached' };
  }
);

export const fetchBPAYDetails = createAsyncThunk(
  AccountsActionTypes.FetchBPAYDetails,
  async (wrapper: FetchBPAYDetailsPayload) => {
    let data: BPAYAccountDetails | undefined = undefined;
    const accountId = wrapper.accountId;
    if (wrapper.isSuper && wrapper.isActiveSsc) {
      const queryString = buildEncodedQueryString({
        accountId,
      });
      const response = await api.get<BPAYAccountDetails>(`${AccountsApiEndpoints.FetchBPAYDetails}${queryString}`);
      data = response.data;
    } else {
      data = {
        bpayAccountDetails: [],
      } as BPAYAccountDetails;
    }
    return data;
  }
);

export const fetchPendingAccounts = createAsyncThunk(
  AccountsActionTypes.FetchPendingAccounts,
  async (clientId: number) => {
    const queryString = buildEncodedQueryString({
      clientId,
    });
    const response = await api.get<PendingAccounts>(`${AccountsApiEndpoints.FetchPendingAccounts}${queryString}`);
    return response.data.pendingAccounts;
  }
);

export const fetchTradingAccountForEdit = createAsyncThunk(
  AccountsActionTypes.fetchTradingAccountForEdit,
  async (wrapper: FetchAccountPayload) => {
    const queryString = buildEncodedQueryString({
      clientId: wrapper.clientId,
      accountId: wrapper.accountId,
    });

    return await (
      await api.get<AccountDetails>(`${AccountsApiEndpoints.fetchAccountForEdit}${queryString}`)
    ).data;
  }
);

export const fetchExternalAccountForEdit = createAsyncThunk(
  AccountsActionTypes.fetchExternalAccountForEdit,
  async (wrapper: FetchAccountPayload) => {
    const queryString = buildEncodedQueryString({
      clientId: wrapper.clientId,
      accountId: wrapper.accountId,
    });

    return await (
      await api.get<AccountDetails>(`${AccountsApiEndpoints.fetchAccountForEdit}${queryString}`)
    ).data;
  }
);

export const fetchAccountTypes = createAsyncThunk(AccountsActionTypes.fetchAccountTypes, async () => {
  const response = await api.get<AccountType[]>(AccountsApiEndpoints.fetchAccountTypes);
  return response.data;
});

export const fetchAttachableCmaAccounts = createAsyncThunk(
  AccountsActionTypes.FetchAttachableCmaAccounts,
  async (clientId: number) => {
    const queryString = buildEncodedQueryString({
      clientId,
    });
    const response = await api.get<AttachableCmaAccounts>(
      `${AccountsApiEndpoints.FetchAttachableCmaAccounts}${queryString}`
    );
    return response.data;
  }
);

export const deletePendingAccount = createAsyncThunk(
  AccountsActionTypes.DeletePendingAccount,
  async (payload: DeleteAccountPayload, thunkApi) => {
    return api
      .post(`${AccountsApiEndpoints.DeletePendingAccount}`, { PendingAccountId: payload.pendingAccountId })
      .then(() => ({ message: 'Account deleted.' }))
      .then(() => {
        if (payload.clientId) {
          thunkApi.dispatch(fetchPendingAccounts(payload.clientId));
        }
      });
  }
);

export const updatePendingAccountStatus = createAsyncThunk(
  AccountsActionTypes.UpdatePendingAccountStatus,
  async (payload: UpdatePendingAccountStatusPayload, thunkApi) => {
    return api
      .post(`${AccountsApiEndpoints.UpdatePendingAccountStatus}`, {
        pendingAccountId: payload.pendingAccountId,
        status: payload.status,
        statusMessage: payload.statusMessage,
      })
      .then(() => ({ message: 'Marked as complete by DASH.' }))
      .then(() => {
        if (payload.clientId) {
          thunkApi.dispatch(fetchPendingAccounts(payload.clientId));
        }
      });
  }
);

export const fetchPendingSuperAccount = createAsyncThunk(
  AccountsActionTypes.FetchPendingSuperAccount,
  async (clientId: number) => {
    const queryString = buildEncodedQueryString({
      clientId,
    });
    const response = await api.get<PendingAccount>(`${AccountsApiEndpoints.FetchPendingSuperAccount}${queryString}`);
    return response.data;
  }
);

export const fetchClientContacts = createAsyncThunk(
  AccountsActionTypes.FetchClientContacts,
  async (clientId: number) => {
    const queryString = buildEncodedQueryString({
      clientId,
    });
    const response = await api.get<ContactRoles[]>(`${AccountsApiEndpoints.FetchClientContacts}${queryString}`);
    return response.data;
  }
);
