import { List, ListItem, ListItemIcon, ListItemText, Paper } from '@mui/material';
import React from 'react';
import { Link, useLocation, useRouteMatch } from 'react-router-dom';
import { MenuRoute } from '../../../../../common/types';

export interface LeftMenuProps {
  routes: MenuRoute[];
}

export const LeftMenu = ({ routes }: LeftMenuProps): JSX.Element => {
  const { pathname } = useLocation();
  const { url } = useRouteMatch();

  const paths = pathname.split('/');
  const getSubRoute = paths[paths.length - 1];

  return (
    <Paper elevation={5}>
      <List>
        {!!routes &&
          routes.map((r: MenuRoute) => (
            <ListItem
              className={'LeftMenu'}
              selected={getSubRoute === r.path}
              disableRipple
              key={r.menuIndex}
              button
              style={{ paddingLeft: '30px', paddingRight: '25px' }}
              component={Link}
              to={`${url}/${r.path}`}
            >
              <ListItemIcon>
                <ListItemIcon>{!!r.icon && <r.icon></r.icon>}</ListItemIcon>
              </ListItemIcon>
              <ListItemText primary={r.label} primaryTypographyProps={{ color: 'textSecondary', variant: 'h5' }} />
            </ListItem>
          ))}
      </List>
    </Paper>
  );
};
