import { Box, Checkbox, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { AccountInstitutionType } from 'src/common/components/accounts/types';

export interface AccountInstitutionItemProps {
  accountInstitution: AccountInstitutionType | undefined;
  isSelected: boolean;
  isAvailable: boolean;
  onClickHandler?: (institutionId: number) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    marginTop: '20px',
    height: '70px',
    '& .accountInstitutionOption': {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      width: '186px',
      border: '1px black solid',
      borderRadius: '4px',
      margin: '0 10px',
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: theme.palette.grey[100],
      },
      '&:first-of-type': {
        marginLeft: '0',
      },
      '& > p': {
        margin: '0 auto',
        fontSize: '12px',
      },
      '& .iconImage': {
        width: '50px',
        marginLeft: '10px',
      },
      // specifically set height to different image
      '& .iconImageMacquarieCMA': {
        width: '40px',
      },
      '& .iconImageAustralianMoneyMarket': {
        width: '60px',
      },
      '& .iconImageDesktopBroker': {
        width: '70px',
      },
      '& .iconImageUxChange': {
        width: '40px',
      },
      '&.accountInstitutionOptionSelected': {
        backgroundColor: theme.palette.grey[200],
      },
      '&.accountInstitutionOptionClickDisabled': {
        pointerEvents: 'none',
        '&:hover': {
          cursor: 'auto',
          backgroundColor: 'initial',
        },
      },
    },
  },
  disabled: { opacity: 0.4 },
}));

export const AccountInstitutionItem = (props: AccountInstitutionItemProps): JSX.Element => {
  const { accountInstitution, isSelected, isAvailable, onClickHandler } = props;
  const classes = useStyles();

  return (
    <div className={[classes.root, !isAvailable ? classes.disabled : ''].join(' ')}>
      {!!accountInstitution && (
        <Box
          key={accountInstitution.id}
          className={`accountInstitutionOption ${isSelected ? 'accountInstitutionOptionSelected' : ''} ${
            !onClickHandler || !isAvailable ? 'accountInstitutionOptionClickDisabled' : ''
          }`}
          onClick={onClickHandler ? () => onClickHandler(accountInstitution.id) : undefined}
          data-testid={
            !!accountInstitution ? `accountInstitutionOption_${accountInstitution.pendingCode.split(' ').join('')}` : 'accountInstitutionOption_unknown'
          }
        >
          <Checkbox
            checked={isSelected}
            color="primary"
            inputProps={{ 'aria-label': 'checkbox' }}
            style={{ position: 'absolute', padding: '0', top: 0, right: 0 }}
          />
          <img
            src={accountInstitution ? accountInstitution.getLogo() : AccountInstitutionType.getLogoById(null)}
            alt={`Institution-${accountInstitution.displayName}`}
            className={`iconImage iconImage${accountInstitution.name.replace(/ /g, '')}`}
          />
          <Typography variant="body1"> {accountInstitution.displayName}</Typography>
        </Box>
      )}
    </div>
  );
};
