import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { progressSelectorFactory } from '../../../common';
import { RootState } from '../../../reducers';
import { RateComponent } from './components/rateComponent';
import { selectParameters, selectFxDailyRates, selectEditId } from './store/selectors';
import { fxPriceSlice } from './store/slice';
import {
  deleteRate,
  downloadFxDailyRates,
  fetchFxDailyRates,
  fetchFxDailyRatesExport,
  SecurityPriceActionTypes,
  updateRate,
  createRate,
  fetchDailyRate,
} from './store/thunks';
import { selectHasClientEditAdminOnly } from '../../../store/selectors';

const mapStateToProps = (state: RootState) => ({
  parameters: selectParameters(state),
  fxDailyRates: selectFxDailyRates(state),
  editId: selectEditId(state),
  fxDailyProgress: progressSelectorFactory([
    SecurityPriceActionTypes.FetchFxDailyRates,
    SecurityPriceActionTypes.CreateDailyRate,
    SecurityPriceActionTypes.UpdateDailyRate,
    SecurityPriceActionTypes.DeleteDailyRate,
  ])(state),
  hasClientEditAdminOnlyPermission: selectHasClientEditAdminOnly(state),
});

const mapDispatchToProps = {
  ...fxPriceSlice.actions,
  fetchFxDailyRates,
  fetchFxDailyRatesExport,
  downloadFxDailyRates,
  fetchDailyRate,
  updateRate,
  deleteRate,
  createRate,
};

interface MatchParams {
  baseCurrencyId?: string;
  quoteCurrencyId?: string;
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector> & RouteComponentProps<MatchParams>;
export const FxDailyRateStateContainer = connect(mapStateToProps, mapDispatchToProps)(RateComponent);
