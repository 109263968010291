import { FiberManualRecord as DotIcon } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { DateTimeFormat, getLocalDateTime } from '../../../../../../common';
import { ClientSideDataTable } from '../../../../../../common/components/dataTable/clientSide';
import { DatatableColumn, FilterDataType } from '../../../../../../common/components/dataTable/types';
import { theme } from '../../../../../../themes';
import { Props } from '../container';

export const InvestmentServices = (props: Props): JSX.Element => {
  const { fetchInvestmentServices, clientId, isLoading, hasErrors } = props;

  useEffect(() => {
    if (!!clientId) {
      fetchInvestmentServices(clientId);
    }
  }, [fetchInvestmentServices, clientId]);

  function indicatorColor(bandName: string): string {
    switch (bandName) {
      case 'S0':
        return theme.palette.common.white;
      case 'S1':
        return theme.palette.green?.main;
      case 'S2':
        return theme.palette.secondary.main;
      case 'S3':
        return theme.palette.primary.main;
      case 'S4':
        return theme.palette.orange?.main;
      case 'S5':
        return theme.palette.error.main;
      default:
        return theme.palette.common.white;
    }
  }

  function statusColor(status: string): string {
    switch (status) {
      case 'Active':
        return theme.palette.secondary.main;
      case 'Pending':
        return theme.palette.orange?.main;
      default:
        return theme.palette.error.main;
    }
  }

  const dotColumn = (dataIndex: number): React.ReactNode => (
    <DotIcon fontSize={'large'} htmlColor={indicatorColor(props.investmentServices.items[dataIndex]?.bandName)} />
  );

  const codeColumn = (dataIndex: number): React.ReactNode => (
    <Typography color={'primary'} variant="h5">
      {props.investmentServices.items[dataIndex]?.code}
    </Typography>
  );

  const nameColumn = (dataIndex: number): React.ReactNode => <Typography variant="h5">{props.investmentServices.items[dataIndex]?.name}</Typography>;

  const typeColumn = (dataIndex: number): React.ReactNode => <Typography variant="h5">{props.investmentServices.items[dataIndex]?.type}</Typography>;

  const statusColumn = (dataIndex: number): React.ReactNode => (
    <Box color={statusColor(props.investmentServices.items[dataIndex]?.status)}>
      <Typography color={'inherit'} variant="h5">
        {props.investmentServices.items[dataIndex]?.status}
      </Typography>
    </Box>
  );

  const versionColumn = (dataIndex: number): React.ReactNode => <Typography variant="h5">{props.investmentServices.items[dataIndex]?.version}</Typography>;

  const startEndDateColumn = (dataIndex: number): React.ReactNode => (
    <>
      <Typography>{getLocalDateTime(props.investmentServices.items[dataIndex]?.startDate, DateTimeFormat.Short)}</Typography>
      <Typography>
        {!!props.investmentServices.items[dataIndex]?.endDate
          ? getLocalDateTime(props.investmentServices.items[dataIndex]?.endDate, DateTimeFormat.Short)
          : '-'}
      </Typography>
    </>
  );

  const columns: DatatableColumn[] = [
    {
      name: '',
      label: '',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex): React.ReactNode => dotColumn(dataIndex),
        setCellHeaderProps: () => ({
          style: { width: '24px' },
        }),
      },
    },
    {
      filterDataType: FilterDataType.string,
      name: 'code',
      label: 'CODE',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex): React.ReactNode => codeColumn(dataIndex),
      },
    },
    {
      filterDataType: FilterDataType.string,
      name: 'name',
      label: 'NAME',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex): React.ReactNode => nameColumn(dataIndex),
      },
    },
    {
      filterDataType: FilterDataType.string,
      name: 'type',
      label: 'SERVICE TYPE',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex): React.ReactNode => typeColumn(dataIndex),
      },
    },
    {
      filterDataType: FilterDataType.string,
      name: 'status',
      label: 'STATUS',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex): React.ReactNode => statusColumn(dataIndex),
      },
    },
    {
      filterDataType: FilterDataType.string,
      name: 'version',
      label: 'VERSION',
      textAlign: 'right',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex): React.ReactNode => versionColumn(dataIndex),
      },
    },
    {
      name: 'startEndDate',
      label: 'START END DATES',
      textAlign: 'right',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex): React.ReactNode => startEndDateColumn(dataIndex),
      },
    },
  ];

  return (
    <>
      <Typography variant="h4" gutterBottom>
        Investment Services
      </Typography>
      <ClientSideDataTable
        loadingProgress={{ isLoading, hasErrors }}
        columns={columns}
        data={props.investmentServices.items}
        options={{
          pagination: true,
        }}
        id="client-investment-services-datatable"
      ></ClientSideDataTable>
    </>
  );
};
