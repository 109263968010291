import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchStrategyComposition } from './thunks';
import { StrategyComposition, StrategyCompositionState, StrategyVersion } from './types';

export const initialState: StrategyCompositionState = {
  parameters: {
    selectedVersion: null,
  },
  strategyComposition: null,
};

export const strategyCompositionSlice = createSlice({
  name: '@@strategy/composition',
  initialState,
  reducers: {
    setSelectedVersion: (state, action: PayloadAction<StrategyVersion>) => {
      state.parameters.selectedVersion = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchStrategyComposition.fulfilled, (state, action: PayloadAction<StrategyComposition>) => {
      state.strategyComposition = action.payload;
      state.parameters.selectedVersion = action.payload.strategyVersions.find((v: StrategyVersion) => v.status === 'Active') || null;
    });
  },
});
