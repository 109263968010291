import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import FileSaver from 'file-saver';
import api from '../../../../../../app/api';
import { buildEncodedQueryString, extractFilenameFromContentDisposition } from '../../../../../../common';
import { FetchPagedResults, PagedResult } from '../../../../../../store';
import {
  DeleteDocumentPayload,
  DocumentDetails,
  DownloadDocumentPayload,
  FetchDocumentPayload,
  FetchDocumentsPayload,
  SaveDocumentPayload,
  // SaveContacIdentificationPayload,
} from '../../../common/store/types';

export enum DocumentsActionTypes {
  FetchDocuments = '@@client/details/documents/FetchDocuments',
  FetchDocument = '@@client/details/documents/FetchDocumentForEdit',
  DownloadDocument = '@@client/details/documents/DownloadDocument',
  DeleteDocument = '@@client/details/documents/DeleteDocument',
  SaveDocument = '@@client/details/documents/SaveDocument',
  // SaveIdentification = '@@client/details/documents/SaveIdentification',
}

export enum DocumentsApiEndpoints {
  FetchDocuments = '/documents/GetClientAttachments',
  FetchDocument = '/documents/GetClientAttachment',
  DownloadDocument = '/documents/Download',
  DeleteDocument = '/documents/DeleteClientAttachment',
  UpdateDocument = '/documents/UpdateClientAttachment',
  CreateDocument = '/documents/CreateClientAttachment',
  // UpdateIdentification = '/documents/UpdateClientAttachment',
  // CreateIdentification = '/documents/CreateClientAttachment',
}

export const fetchDocuments = createAsyncThunk(
  DocumentsActionTypes.FetchDocuments,
  async (wrapper: FetchDocumentsPayload) => {
    const body = {
      clientId: wrapper.clientId,
      pagedRequest: wrapper.parameters.pagination,
    };

    const response = await api.post<PagedResult<DocumentDetails>>(`${DocumentsApiEndpoints.FetchDocuments}`, body);
    return {
      results: response.data,
      pagination: wrapper.parameters.pagination,
    } as FetchPagedResults<DocumentDetails>;
  }
);

export const fetchDocumentForEdit = createAsyncThunk(
  DocumentsActionTypes.FetchDocument,
  async (wrapper: FetchDocumentPayload) => {
    const queryString = buildEncodedQueryString({
      entityCoreId: wrapper.clientId,
      attachmentId: wrapper.documentId,
    });

    const response = await api.get<DocumentDetails>(`${DocumentsApiEndpoints.FetchDocument}${queryString}`);
    return response.data;
  }
);

export const downloadDocument = createAsyncThunk(
  DocumentsActionTypes.DownloadDocument,
  async (wrapper: DownloadDocumentPayload) => {
    const queryString = buildEncodedQueryString({
      clientId: wrapper.clientId,
      attachmentId: wrapper.attachmentId,
    });

    await api
      .get(`${DocumentsApiEndpoints.DownloadDocument}${queryString}`, {
        responseType: 'blob',
      })
      .then((response: AxiosResponse) => {
        const fileName =
          extractFilenameFromContentDisposition(response.headers['content-disposition']) || wrapper.filename;
        FileSaver.saveAs(new Blob([response.data]), fileName);
      });
  }
);

export const deleteDocument = createAsyncThunk(
  DocumentsActionTypes.DeleteDocument,
  async (payload: DeleteDocumentPayload, thunkApi) => {
    await api.delete(DocumentsApiEndpoints.DeleteDocument, { data: payload });

    thunkApi.dispatch(fetchDocuments(payload.fetchPayload));
    return { message: 'Document deleted' };
  }
);

export const saveDocument = createAsyncThunk(
  DocumentsActionTypes.SaveDocument,
  async (payload: SaveDocumentPayload, thunkApi) => {
    await api.post(
      !!payload.document.id ? DocumentsApiEndpoints.UpdateDocument : DocumentsApiEndpoints.CreateDocument,
      {
        ...payload.document,
        clientId: payload.clientId,
      }
    );
    thunkApi.dispatch(fetchDocuments(payload.fetchPayload));

    return { message: !!payload.document.id ? 'Document saved' : 'Document added' };
  }
);

// export const saveIdentification = createAsyncThunk(
//   DocumentsActionTypes.SaveIdentification,
//   async (payload: SaveContacIdentificationPayload, thunkApi) => {
//     console.log('await api.post(');
//     await api.post(
//       // !!payload.document.id ? DocumentsApiEndpoints.UpdateIdentification : DocumentsApiEndpoints.CreateIdentification,
//       DocumentsApiEndpoints.CreateIdentification,
//       {
//         ...payload.document,
//         clientId: payload.clientId,
//       }
//     );
//     // payload.
//     // export interface SaveContacIdentificationPayload {
//     //   clientId: number;
//     //   contactId: number;
//     //   document: SaveIdentificationDetails;
//     //   fetchPayload: FetchContactIdentificationsPayload;
//     // }
//     thunkApi.dispatch(fetchDocuments(payload.fetchPayload));

//     return { message: !!payload.document.id ? 'Identification saved' : 'Identification added' };
//   }
// );
