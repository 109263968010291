import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PagedRequest } from '../../../../store';
import { fetchSecurityList } from './thunks';
import { fetchPagedResults, SecurityItem, SecurityListState } from './types';

export const initialState: SecurityListState = {
  parameters: {
    codeOrNameSearch: '',
    pagination: {
      pageNumber: 1,
      pageSize: 25,
      queryFields: [
        {
          fieldName: 'code',
          descendingSortDirection: false,
          operator: '',
          hasSearchTerm: false,
          isSortTerm: true,
          searchTerm: '',
        },
        {
          fieldName: 'securityStatus',
          hasSearchTerm: true,
          searchTerm: 'Active',
          operator: 'Contains',
          isSortTerm: false,
          descendingSortDirection: false,
        },
      ],
    },
  },
  securities: null,
};

export const securityListSlice = createSlice({
  name: '@@security/list',
  initialState,
  reducers: {
    setSearchStringParameter: (state, action: PayloadAction<string | null>) => {
      state.parameters.codeOrNameSearch = action.payload;
    },
    setPaginationParameter: (state, action: PayloadAction<PagedRequest>) => {
      state.parameters.pagination = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSecurityList.fulfilled, (state, action: PayloadAction<fetchPagedResults<SecurityItem>>) => {
      state.securities = action.payload.results;
      state.parameters.pagination = action.payload.pagination;
    });
  },
});
