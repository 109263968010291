import { Card, CardContent, CardHeader, Typography } from '@mui/material';
import React from 'react';
import { LoadingProgress } from '../store/types';
import { LoadingIndicator } from './LoadingIndicator';

export interface CardComponentProps {
  progress: LoadingProgress;
  background: string | undefined;
  icon?: React.ElementType;
  value: string | number | undefined;
  subText: string;
}

const CardComponent = (props: CardComponentProps): JSX.Element => {
  const { progress, background, icon, value, subText } = props;

  return (
    <LoadingIndicator progress={progress}>
      <Card className={'Card'} elevation={0} style={{ background: background, position: 'relative', flexDirection: 'column', justifyContent: 'center' }}>
        {icon && <CardHeader action={props.icon && <props.icon fontSize="large" />} />}
        {!icon && <CardHeader style={{ padding: '0px' }} />}
        <CardContent style={!icon ? { padding: '0 12px' } : { padding: '20px' }}>
          <Typography variant="h1" style={{ paddingBottom: '30px' }}>
            {value}
          </Typography>
          <Typography variant="caption">{subText}</Typography>
        </CardContent>
      </Card>
    </LoadingIndicator>
  );
};

export default CardComponent;
