import { AnyAction } from '@reduxjs/toolkit';
import api from '../app/api';

export const FETCH_DASHBOARD = 'FETCH_DASHBOARD';
export const FETCH_TOP_CLIENTS = 'FETCH_TOP_CLIENTS';
export const FETCH_PENDING_CLIENTS = 'FETCH_PENDING_CLIENTS';
export const FETCH_USER_DASHBOARD = 'FETCH_USER_DASHBOARD';
export const FETCH_PORTFOLIOS_OUT_OF_BALANCE = 'FETCH_PORTFOLIOS_OUT_OF_BALANCE';
export const AUTHENTICATE = 'AUTHENTICATE';
export const CHECK_TOKEN = 'CHECK_TOKEN';
export const LOGOUT = 'LOGOUT';
export const SET_CLIENTPERFORMANCE_DATES = 'SET_CLIENTPERFORMANCE_DATES';

export const fetchDashboard = (): AnyAction => {
  return {
    type: FETCH_DASHBOARD,
    payload: api.get('/dashboard'),
  };
};

export const fetchTopClients = (): AnyAction => {
  return {
    type: FETCH_TOP_CLIENTS,
    payload: api.get('/topclients'),
  };
};

export const fetchPendingClients = (): AnyAction => {
  return {
    type: FETCH_PENDING_CLIENTS,
    payload: api.get('/pendingclients'),
  };
};

export const fetchPortfoliosOutOfBalance = (): AnyAction => {
  return {
    type: FETCH_PORTFOLIOS_OUT_OF_BALANCE,
    payload: api.get('/portfoliosoutofbalance'),
  };
};

export const fetchUserDashboard = (): AnyAction => {
  return {
    type: FETCH_USER_DASHBOARD,
    payload: api.get('/userdashboard'),
  };
};

// export const fetchToken = (userInput: any): AnyAction => {
//   return {
//     type: AUTHENTICATE,
//     payload: api.get('/authenticate', userInput),
//   };
// };

// export const authFromLocalStorage = (token: any): AnyAction => {
//   return {
//     type: AUTHENTICATE,
//     payload: api.get('/authenticate', token),
//   };
// };

// export const authFromQueryString = (token: any): AnyAction => {
//   const loginObj: any = {};
//   loginObj.token = token;
//   return {
//     type: AUTHENTICATE,
//     payload: api.get('/authenticate', token),
//   };
// };

export const logOut = (): AnyAction => {
  return {
    type: LOGOUT,
    payload: {},
  };
};

// export const checkToken = (token) => {
//     return {
//         type: CHECK_TOKEN,
//         payload: validateToken(token)
//     }
// };
