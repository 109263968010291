import { connect, ConnectedProps } from 'react-redux';
import { progressSelectorFactory } from 'src/common';
import { RootState } from '../../../../../../reducers';
import { selectSuperMemberNumber, selectClientId } from '../store/selectors';
import { InsuranceDetail } from './components/insuranceDetail';
import { selectMainDetailsItem } from '../../mainDetails/store';
import {
  selectDeathAndTpdInsurance,
  selectIncomeProtectionInsurance,
  selectPremiumDetails,
  selectParameters,
  selectAuthorisedContact,
} from './store/selectors';
import { insuranceSlice } from './store/slice';
import {
  fetchDeathAndTpdInsuranceByAccountId,
  fetchIncomeProtectionInsuranceByAccountId,
  fetchPremiumDetails,
  InsuranceDetailActionTypes,
  fetchMetApplyToken,
  fetchAuthorisedContact,
  launchMetlifeCalculator,
} from './store/thunks';

const mapStateToProps = (state: RootState) => ({
  accountId: selectSuperMemberNumber(state),
  clientDetails: selectMainDetailsItem(state),
  loadingProgressDeathAndTpd: progressSelectorFactory([InsuranceDetailActionTypes.FetchDeathAndTpdInsuranceByAccountId])(state),
  loadingProgressIncomeProtection: progressSelectorFactory([InsuranceDetailActionTypes.FetchIncomeProtectionInsuranceByAccountId])(state),
  loadingProgressPremiumDetails: progressSelectorFactory([InsuranceDetailActionTypes.FetchPremiumDetailsByAccountId])(state),
  deathAndTpdDetails: selectDeathAndTpdInsurance(state),
  incomeProtectionInsuranceDetails: selectIncomeProtectionInsurance(state),
  premiumDetails: selectPremiumDetails(state),
  fetchMetApplyTokenLoadingProgress: progressSelectorFactory([InsuranceDetailActionTypes.FetchMetApplyTokenByClientId])(state),
  clientId: selectClientId(state),
  authorisedContactName: selectAuthorisedContact(state),
  parameters: selectParameters(state),
  fetchAuthorisedContactProgress: progressSelectorFactory([InsuranceDetailActionTypes.FetchAuthorisedContactProgress])(state),
});

const mapDispatchToProps = {
  ...insuranceSlice.actions,
  fetchDeathAndTpdInsuranceByAccountId,
  fetchIncomeProtectionInsuranceByAccountId,
  fetchPremiumDetails,
  fetchMetApplyToken,
  fetchAuthorisedContact,
  launchMetlifeCalculator,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector>;
export const InsuranceDetailContainer = connector(InsuranceDetail);
