import { TextField, TextFieldProps as MuiTextFieldProps } from '@mui/material';
import React from 'react';

interface CustomProps {
  style?: React.CSSProperties;
}

type TextInputProps = MuiTextFieldProps & CustomProps;

export const WO2TextInput: React.FC<TextInputProps> = ({ ...props }: TextInputProps) => {
  return <TextField {...props} variant="outlined" />;
};
