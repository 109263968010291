import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import React from 'react';
import { ServerSideDataTable } from '../../../../common/components/dataTable/serverSide';
import { DatatableColumn, FilterDataType } from '../../../../common/components/dataTable/types';
import { LoadingProgress } from '../../../../common/store/types';
import { PagedRequest, PagedResult } from '../../../../store';
import { FxItem } from '../store/types';

export interface Props {
  pagedResults?: PagedResult<FxItem>;
  pagedRequest: PagedRequest;
  handlePagedRequest: (pagedRequest: PagedRequest) => void;
  handleRowClick: (baseCurrencyId: number, quoteCurrencyId: number) => void;
  progressForexs: LoadingProgress;
}

export function FxListTable(props: Props): JSX.Element {
  const { pagedResults, progressForexs, pagedRequest } = props;

  const baseCodeColumn = (dataIndex: number): React.ReactNode => {
    return <Typography variant="h5">{pagedResults?.results[dataIndex]?.baseCurrencyCode}</Typography>;
  };

  const baseNameColumn = (dataIndex: number): React.ReactNode => {
    return <Typography variant="h5">{pagedResults?.results[dataIndex]?.baseCurrencyName}</Typography>;
  };

  const quoteCodeColumn = (dataIndex: number): React.ReactNode => {
    return <Typography variant="h5">{pagedResults?.results[dataIndex]?.quoteCurrencyCode}</Typography>;
  };

  const quoteNameColumn = (dataIndex: number): React.ReactNode => {
    return <Typography variant="h5">{pagedResults?.results[dataIndex]?.quoteCurrencyName}</Typography>;
  };

  const currencyPairColumn = (dataIndex: number): React.ReactNode => {
    return <Typography variant="h5">{pagedResults?.results[dataIndex]?.currencyPair}</Typography>;
  };

  const columns: DatatableColumn[] = [
    {
      filterDataType: FilterDataType.string,
      name: 'currencyPair',
      label: 'Currency Pair',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex): React.ReactNode => currencyPairColumn(dataIndex),
      },
    },
    {
      filterDataType: FilterDataType.string,
      name: 'baseCurrencyCode',
      label: 'Base Currency (ISO Code)',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex): React.ReactNode => baseCodeColumn(dataIndex),
      },
    },
    {
      filterDataType: FilterDataType.string,
      name: 'baseCurrencyName',
      label: 'Base Currency Name',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex): React.ReactNode => baseNameColumn(dataIndex),
      },
    },
    {
      filterDataType: FilterDataType.string,
      name: 'quoteCurrencyCode',
      label: 'Quote Currency (ISO Code)',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex): React.ReactNode => quoteCodeColumn(dataIndex),
      },
    },
    {
      filterDataType: FilterDataType.string,
      name: 'quoteCurrencyName',
      label: 'Quote Currency Name',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex): React.ReactNode => quoteNameColumn(dataIndex),
      },
    },
  ];

  return (
    <Grid container sx={{ width: '100%', margin: '10px' }}>
      <Grid item xs={12}>
        <ServerSideDataTable
          loadingProgress={progressForexs}
          columns={columns}
          pagedRequest={pagedRequest}
          pagedResult={props.pagedResults}
          options={{}}
          handlePagedRequest={props.handlePagedRequest}
          handleRowClick={(rowData: string[], rowMeta: { dataIndex: number; rowIndex: number }) => {
            const baseCurrencyId = props.pagedResults?.results[rowMeta.rowIndex].baseCurrencyId;
            const quoteCurrencyId = props.pagedResults?.results[rowMeta.rowIndex].quoteCurrencyId;
            if ( baseCurrencyId && quoteCurrencyId) {
              props.handleRowClick(baseCurrencyId, quoteCurrencyId);
            }
          }}
        ></ServerSideDataTable>
      </Grid>
    </Grid>
  );
}
