import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import { Card, CardContent, CardHeader, Container, Divider, IconButton, List, ListItem, ListItemText, Typography } from '@mui/material';
import React from 'react';
import { Tag } from '../store/types';

export const Parameters = (): JSX.Element => {
  const renderTags = () => {
    const emailTags: Tag[] = [
      { id: 2, name: '<AdviserEmail>' },
      { id: 3, name: '<AdviserFullName>' },
      { id: 4, name: '<AdviserPreferredName>' },
      { id: 5, name: '<AfslAbn>' },
      { id: 6, name: '<AfslBusinessAddress>' },
      { id: 7, name: '<AfslName>' },
      { id: 8, name: '<AfslNo>' },
      { id: 9, name: '<AfslPhoneNumber>' },
      { id: 19, name: '<ClientAccountName>' },
      { id: 12, name: '<ClientName>' },
      { id: 13, name: '<ClientPhysicalAddress>' },
      { id: 14, name: '<ClientPreferredName>' },
      { id: 16, name: '<InvestmentServiceName>' },
      { id: 17, name: '<PracticePhoneNumber>' },
      { id: 1, name: '<TodayDate>' },
      { id: 10, name: '<AuthSigPrefName>' },
    ];
    return emailTags.map((tag) => {
      return (
        <React.Fragment key={tag.id}>
          <ListItem>
            <ListItemText primary={tag.name} primaryTypographyProps={{ variant: 'h5' }} />
            <IconButton style={{ padding: 0 }} onClick={() => navigator.clipboard.writeText(tag.name)}>
              <FileCopyOutlinedIcon style={{ fontSize: 20 }} />
            </IconButton>
          </ListItem>
          <Divider />
        </React.Fragment>
      );
    });
  };

  return (
    <Container style={{ width: 500, padding: 0 }}>
      <Card elevation={0}>
        <CardHeader title={<Typography variant="h4">Tags</Typography>}> </CardHeader>
        <Divider />
        <CardContent style={{ paddingTop: 0 }}>
          <List component="nav" aria-label="mailbox folders" disablePadding>
            {renderTags()}
          </List>
        </CardContent>
      </Card>
    </Container>
  );
};
