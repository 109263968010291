import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchDashboard } from '../store/actions';

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    //this.props.fetchDashboard();
  }

  render() {
    return (
      <div>
        <h1>Forgot Password</h1>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    dashboard: state.dashboard,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchDashboard }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
