import { createSelector } from '@reduxjs/toolkit';
import { selectStrategy } from '../../store/selectors';

export const selectStrategyPerformanceState = createSelector(selectStrategy, (strategyState) => strategyState.strategyPerformance);
export const selectParameters = createSelector(selectStrategyPerformanceState, (performance) => performance.parameters);
export const selectStrategyDashboard = createSelector(selectStrategyPerformanceState, (PerformanceState) => PerformanceState.strategyDashboard);
export const selectStrategyDetails = createSelector(selectStrategyPerformanceState, (PerformanceState) => PerformanceState.strategyDetails);
export const selectPeriodicSummary = createSelector(selectStrategyPerformanceState, (PerformanceState) => PerformanceState.periodicSummary);
export const selectDailyPerformanceChart = createSelector(selectStrategyPerformanceState, (PerformanceState) => PerformanceState.dailyPerformanceChart);
export const selectMonthlyPerformanceChart = createSelector(selectStrategyPerformanceState, (PerformanceState) => PerformanceState.monthlyPerformanceChart);
