import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'src/app/api';
import { DateTimeFormat, downloadCsv, getLocalDateTimeNow } from 'src/common';
import { ComponentAllocations, CompositionDetail, CompositionGroup,
  FetchModelCompositionCSVPayload, FetchModelCompositionPayload, ModelCompositionMethod } from './types';

export enum ModelCompositionActionTypes {
  FetchCompositionDetail = '@@model/composition/FetchCompositionDetail',
  FetchModelCompositionCSV = '@@model/composition/FetchModelCompositionCSV',
}

export enum ModelCompositionApiEndpoints {
  fetchCompositionDetail = '/bff/GetModelComposition',
}

export const fetchCompositionDetail = createAsyncThunk(ModelCompositionActionTypes.FetchCompositionDetail, async (wrapper: FetchModelCompositionPayload) => {
  const response = await api.get<CompositionDetail>(`${ModelCompositionApiEndpoints.fetchCompositionDetail}?modelVersionId=${wrapper.modelVersionId}`);
  return response.data;
});

export const fetchModelCompositionCSV = createAsyncThunk(
  ModelCompositionActionTypes.FetchModelCompositionCSV,
  async (payload: FetchModelCompositionCSVPayload) => {
    await api.get<CompositionDetail>(`${ModelCompositionApiEndpoints.fetchCompositionDetail}?modelVersionId=${payload.modelVersionId}`).then((response) => {
      const data: Record<string, unknown>[] = [];

      response.data.groups.forEach((item: CompositionGroup) => {
        if (!!item.componentAllocations.length && item.label !== 'ALL') {
          item.componentAllocations.forEach((allocation: ComponentAllocations) => {
            if (response.data.method === ModelCompositionMethod.Weighted && payload.isModelVersionActive) {
              data.push({
                securityCode: allocation.componentCode,
                assetClass: allocation.assetClass,
                targetAllocation: allocation.weight,
                componentName: allocation.componentName,
                componentType: allocation.componentType,
                status: allocation.status,
                statusDate: allocation.statusDate,
                type: allocation.type,
                units: allocation.units,
                unitPrice: allocation.unitPrice,
                value: allocation.value,
              });
            } else {
              data.push({
                securityCode: allocation.componentCode,
                assetClass: allocation.assetClass,
                targetAllocation: allocation.weight,
                componentName: allocation.componentName,
                componentType: allocation.componentType,
                status: allocation.status,
                statusDate: allocation.statusDate,
                type: allocation.type,
              });
            }
          });
        }
      });

      downloadCsv(data, `Model_Composition_Allocations_${getLocalDateTimeNow(DateTimeFormat.Filename)}`);
    });

    return {
      message: 'CSV downloaded',
    };
  }
);
