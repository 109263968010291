import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { CGTConfiguration } from './common/cgtConfiguration';

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector> & RouteComponentProps;
export const CGTConfigurationContainer = connect(mapStateToProps, mapDispatchToProps)(CGTConfiguration);
