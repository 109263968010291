import { ArrowBackOutlined } from '@mui/icons-material';
import { Grid, IconButton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { DocumentsTable } from 'src/features/clients/client/common/components/documents/documentsTable';
import {
  AddressDetails,
  EmailAddress,
  PhoneNumber,
  SaveDocumentDetails,
} from 'src/features/clients/client/common/store/types';
import { PagedRequest } from 'src/store';
import history from '../../../../../../../history';
import { List as ContactsList } from '../../../../common/components/contacts';
import { Props } from '../../container';
import { TrusteeDetails, UpdateTrusteePayload } from '../../store/types';
import { AddressTable } from './../../../../common/components/contactDetails/addressTable';
import { EmailTable } from './../../../../common/components/contactDetails/emailTable';
import { PhoneNumberTable } from './../../../../common/components/contactDetails/phoneNumberTable';
import { DetailsForm } from './detailsForm';

interface EditRouteParams {
  id?: string;
  trusteeId?: string;
}

export const Edit = (props: Props): JSX.Element => {
  const {
    hasClientEditPermission,
    contact,
    trustee,
    fetchTrustee,
    loadingTrusteeProgress,
    saveTrustee,
    saveTrusteeProgress,
    addresses,
    selectedAddress,
    setAddressEdit,
    clearAddressEdit,
    availableAddressTypes,
    loadingAddressesProgress,
    savingAddressProgress,
    deleteAddress,
    saveAddress,
    fetchAddresses,
    fetchDocumentTypes,
    documentTypes,
    documents,
    loadingDocumentsProgress,
    fetchDocuments,
    fetchDocumentForEdit,
    documentsParameters,
    saveDocument,
    saveDocumentProgress,
    deleteDocument,
    downloadDocument,
    setDocumentAddMode,
    cancelDocumentAddEditMode,
    selectEditDocument,
    phoneNumbers,
    phoneNumberEditId,
    isLoadingContactDetails,
    emailAddresses,
    setPhoneNumberEditId,
    setPhoneNumberAdd,
    setEmailAddressEditId,
    setEmailAddressAdd,
    emailAddressEditId,
    fetchContactDetails,
    savePhoneNumber,
    deletePhoneNumber,
    saveEmailAddress,
    deleteEmailAddress,
  } = props;

  const location = useLocation();
  const [clientId, setClientId] = useState<number>(0);
  const [trusteeId, setTrusteeId] = useState<number | null>(trustee?.id || null);

  const routeParams = useParams<EditRouteParams>();

  useEffect(() => {
    if (trustee?.id !== trusteeId) {
      setTrusteeId(trustee?.id || null);
    }
  }, [trustee, trusteeId, setTrusteeId]);

  useEffect(() => {
    if (!!routeParams.id) {
      const incomingClientId = parseInt(routeParams.id);
      setClientId(incomingClientId);

      if (!!routeParams.trusteeId) {
        const incomingTrusteeId = parseInt(routeParams.trusteeId);
        setTrusteeId(incomingTrusteeId);
        if (!!clientId && !!incomingTrusteeId) {
          fetchTrustee({ clientId, trusteeId: incomingTrusteeId });
          fetchAddresses(incomingTrusteeId);
          !!documentsParameters &&
            fetchDocuments({ clientId, trusteeId: incomingTrusteeId, parameters: documentsParameters });
          fetchContactDetails(incomingTrusteeId);
        }
      }
    }
  }, [
    fetchTrustee,
    fetchAddresses,
    fetchDocuments,
    fetchContactDetails,
    routeParams.trusteeId,
    routeParams.id,
    clientId,
    documentsParameters,
  ]);

  const onSaveTrustee = async (trusteeDetails: TrusteeDetails) => {
    const payload: UpdateTrusteePayload = { clientId, id: trustee?.id || null, trustee: trusteeDetails };
    payload.trustee.tfn = (payload.trustee.tfn || '').replace(/[\s-_]/g, '');
    await saveTrustee(payload);
  };

  const handleSaveAddress = (address: AddressDetails) => {
    if (trusteeId !== null) {
      saveAddress({ address, trusteeId });
    }
  };

  const handleDeleteAddress = (addressId: number) => {
    if (trusteeId !== null) {
      deleteAddress({ trusteeId, addressId });
    }
  };

  const onSaveDocument = (document: SaveDocumentDetails) => {
    if (clientId !== null && trusteeId !== null && !!documentsParameters) {
      saveDocument({
        clientId,
        document,
        fetchPayload: { clientId, trusteeId, parameters: documentsParameters },
      }).then(() => {
        fetchDocuments({ clientId, trusteeId, parameters: documentsParameters });
      });
    }
  };

  const onDeleteDocument = (documentId: number) => {
    if (clientId !== null && trusteeId !== null && !!documentsParameters) {
      deleteDocument({
        clientId,
        trusteeId,
        attachmentId: documentId,
        fetchPayload: {
          clientId,
          trusteeId,
          parameters: documentsParameters,
        },
      }).then(() => {
        fetchDocuments({ clientId, trusteeId, parameters: documentsParameters });
      });
    }
  };

  const onAddEditDocument = (documentId: number | null | undefined) => {
    if (!!documentId && trusteeId !== null) {
      // load document from backend
      fetchDocumentForEdit({ trusteeId, documentId });
    } else if (documentId === null) {
      setDocumentAddMode();
    } else {
      cancelDocumentAddEditMode();
    }
  };

  const handleSavePhoneNumber = (phoneNumber: PhoneNumber) => {
    if (trusteeId !== null) {
      savePhoneNumber({
        trusteeId,
        phoneNumber,
      });
    }
  };

  const handleDeletePhoneNumber = (id: number) => {
    if (trusteeId !== null) {
      deletePhoneNumber({
        trusteeId,
        phoneNumber: id,
      });
    }
  };

  const handleSaveEmailAddress = (emailAddress: EmailAddress) => {
    if (trusteeId !== null) {
      saveEmailAddress({
        trusteeId,
        emailAddress,
      });
    }
  };

  const handleDeleteEmailAddress = (id: number) => {
    if (trusteeId !== null) {
      deleteEmailAddress({
        trusteeId,
        contactDetailId: id,
      });
    }
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <IconButton
            aria-label="contacts list"
            color="primary"
            data-testid={`cancelButton`}
            onClick={() => {
              history.push('../trustees');
            }}
            style={{ display: 'inline' }}
          >
            <ArrowBackOutlined />
          </IconButton>
          <Typography variant="h4" style={{ padding: '0 0 20px', display: 'inline' }}>
            {!!trusteeId ? trustee?.details?.name : 'New Trustee'}
          </Typography>
        </Grid>
        <Grid item xs={6}></Grid>
      </Grid>
      <DetailsForm
        hasClientEditPermission={hasClientEditPermission}
        details={trustee?.details || null}
        loadingProgress={loadingTrusteeProgress}
        saveProgress={saveTrusteeProgress}
        onSave={onSaveTrustee}
      ></DetailsForm>
      {!!trusteeId && (
        <>
          <ContactsList
            {...{ ...props, clientId: trusteeId, match: { url: `${location.pathname}` } }}
            primaryContactRequired={false}
          />
          <AddressTable
            selectedAddress={selectedAddress}
            setAddEdit={setAddressEdit}
            cancel={clearAddressEdit}
            addresses={addresses}
            save={handleSaveAddress}
            delete={handleDeleteAddress}
            addressTypes={availableAddressTypes}
            loadingProgress={loadingAddressesProgress}
            savingProgress={savingAddressProgress}
          ></AddressTable>
          {!!documents && (
            <DocumentsTable
              type="Documents"
              hideColumns={['points']}
              fetchDocumentTypes={fetchDocumentTypes}
              documentTypes={documentTypes}
              documents={documents.results.items}
              pagination={documents.parameters.pagination}
              onSave={onSaveDocument}
              onDelete={onDeleteDocument}
              progress={loadingDocumentsProgress}
              saveProgress={saveDocumentProgress}
              handleDownloadDocument={(attachmentId: number, filename: string) => {
                if (clientId !== null) {
                  downloadDocument({
                    clientId: trusteeId,
                    attachmentId,
                    filename,
                  });
                }
              }}
              handleGridActions={(pagedRequest: PagedRequest) => {
                if (clientId !== null) {
                  fetchDocuments({
                    clientId,
                    trusteeId,
                    parameters: { ...documents.parameters, pagination: pagedRequest },
                  });
                }
              }}
              onAddEdit={onAddEditDocument}
              selectedDocument={selectEditDocument}
              hasClientEditAdminOnlyPermission={true}
            ></DocumentsTable>
          )}
          <PhoneNumberTable
            items={phoneNumbers?.items || []}
            editId={phoneNumberEditId}
            onSave={handleSavePhoneNumber}
            onDelete={handleDeletePhoneNumber}
            onSelectEditId={setPhoneNumberEditId}
            onStartAddItem={setPhoneNumberAdd}
            progress={isLoadingContactDetails}
          ></PhoneNumberTable>
          <EmailTable
            items={emailAddresses?.items || []}
            editId={emailAddressEditId}
            onSave={handleSaveEmailAddress}
            onDelete={handleDeleteEmailAddress}
            onSelectEditId={setEmailAddressEditId}
            onStartAddItem={setEmailAddressAdd}
            progress={isLoadingContactDetails}
            hasMoneysoftAccess={contact?.hasMoneysoftAccess ?? false}
          ></EmailTable>
        </>
      )}
    </>
  );
};
