import { EditableCollection } from 'src/common/store/types';
import { moment } from 'src/common/types';
import { Enumeration } from 'src/common/types/enumeration';
import { EstimatedFee, TieredFeeDetails } from '../../../../common/types';

export interface AdviceFeesState {
  activeFees: EditableCollection<AdviceEstimatedFee, number>;
  pendingFeesChangeSet: PendingFeesModel | undefined;
  pendingFees: EditableCollection<AdviceEstimatedFee, number>;
  adviceServicesList: AdviceServiceModel[];
  editingTieredFeeDetails: EditableCollection<AdviceTieredFeeDetails, number>;
  isSubmitted: boolean | undefined;
  isEditingActiveFee: boolean | undefined;
  isEditingAdviceServiceList: boolean;
  afslFeesList: AfslFeesList[];
}

export interface FeeForClientModel {
  id: number;
  name: string | null;
  from: number | null;
  to: number | null;
  percentage: number | null;
  value: number | null;
  feeCalculationTypeId: number;
  feeCalculationType: string | null;
  feeStatusId: number;
  feeMethodId: number;
  feeMethod: string | null;
  feeFrequencyId: number;
  feeFrequency: string | null;
  isUserDefined: boolean;
  isTiered: boolean;
  hasEverBeenActive: boolean;
  templateCode: string | null;
  feeSource: string;
  feeSourceId: number;
}

export interface AdviceServiceModel {
  id: number;
  name: string;
  description: string;
  isSelected: boolean;
  serviceDetails: string;
}

export interface FeeForClientGroupModel {
  groupKey: string | null;
  fees: FeeForClientModel[];
}

export interface FetchedFeeForClientModel {
  results: FeeForClientGroupModel[];
}

export interface FetchedAdviceServicesListModel {
  results: AdviceServiceModel[];
}

export interface FetchedAFSLFeesListModel {
  results: AfslFeesList[];
}

export interface FetchedPendingFeeForClientModel {
  results: PendingFeesModel;
}

export interface PendingFeesModel {
  clientId: number;
  changeSetId: number;
  status: string;
  pendingFees: PendingFeeGroup[];
}

export interface PendingFeeGroup {
  groupKey: string;
  fees: PendingFeeAction[];
}

export interface PendingFeeAction {
  action: string;
  fee: PendingFee;
}

export interface PendingFee {
  id: number;
  name?: string;
  feeStatusId: number;
  feeStatus: string;
  from: number;
  to: number;
  percentage: number;
  value: number;
  feeCalculationTypeId: number;
  feeCalculationType?: string;
  feeMethodId: number;
  feeMethod: string;
  feeFrequencyId: number;
  feeFrequency: string;
  isUserDefined: boolean;
  isTiered: boolean;
  templateCode: string;
  feeSource: string;
  feeSourceId: number;
  hasEverBeenActive: boolean;
}

export interface AdviceEstimatedFee extends EstimatedFee<AdviceTieredFeeDetails> {
  feeId: number;
  statusId: number;
  isPending: boolean;
  pendingAction: string;
  isValid: boolean;
  hasEverBeenActive: boolean;
  isUserDefined: boolean;
  feeSource: string;
}

export interface AdviceTieredFeeDetails extends TieredFeeDetails {
  feeId: number;
}

export interface CreateClientFeeRequest {
  clientId: number;
  feeFrequencyId: number;
  feeMethodId: number;
  feeCalculationTypeId: number;
  tiered: boolean;
  from: number;
  to: number;
  amount: number;
  percentage: number;
  name: string;
  templateCode: string;
}

export interface UpdateClientFeeRequest extends CreateClientFeeRequest {
  clientFeeId: number;
}

export enum PendingFeesChangeSetStatus {
  Unknown = 'Unkown',
  Pending = 'Pending',
  InProgress = 'InProgress',
  Locked = 'Locked',
  Completed = 'Completed',
  Failed = 'Failed',
}

export class FeeStatusType extends Enumeration {
  static Unknown = new FeeStatusType(0, 'Unknown', 'Unknown');
  static Pending = new FeeStatusType(1, 'Pending', 'Pending');
  static InProgress = new FeeStatusType(2, 'InProgress', 'In Progress');
  static Active = new FeeStatusType(3, 'Active', 'Active');
  static Inactive = new FeeStatusType(4, 'Inactive', 'Inactive');
  static Locked = new FeeStatusType(5, 'Locked', 'Locked');

  static getArray = (): FeeStatusType[] => [
    FeeStatusType.Unknown,
    FeeStatusType.Pending,
    FeeStatusType.InProgress,
    FeeStatusType.Active,
    FeeStatusType.Inactive,
    FeeStatusType.Locked,
  ];

  static getById = (id: number): FeeStatusType | undefined => {
    return Object.values(FeeStatusType)
      .filter((t) => t instanceof FeeStatusType)
      .find((t: FeeStatusType) => t.id === id);
  };
}

export class PendingFeeActionType extends Enumeration {
  static None = new PendingFeeActionType(1, 'None', 'None');
  static Create = new PendingFeeActionType(2, 'Create', 'Create');
  static Delete = new PendingFeeActionType(3, 'Delete', 'Delete');

  static getArray = (): PendingFeeActionType[] => [PendingFeeActionType.None, PendingFeeActionType.Create, PendingFeeActionType.Delete];

  static getById = (id: number): PendingFeeActionType | undefined => {
    return Object.values(PendingFeeActionType)
      .filter((t) => t instanceof PendingFeeActionType)
      .find((t: PendingFeeActionType) => t.id === id);
  };
}

export class FeeSourceType extends Enumeration {
  static UnknownSource = new FeeStatusType(0, 'Unknown Source', 'Unknown Source');
  static Client = new FeeStatusType(1, 'Client', 'Client');
  static Adviser = new FeeStatusType(2, 'Adviser', 'Adviser');
  static Practice = new FeeStatusType(3, 'Practice', 'Practice');
  static AfsLicensee = new FeeStatusType(4, 'AfsLicensee', 'AFS Licensee');

  static getArray = (): FeeSourceType[] => [
    FeeSourceType.UnknownSource,
    FeeSourceType.Client,
    FeeSourceType.Adviser,
    FeeSourceType.Practice,
    FeeSourceType.AfsLicensee,
  ];

  static importedArray = (): FeeSourceType[] => [FeeSourceType.Adviser, FeeSourceType.Practice, FeeSourceType.AfsLicensee];

  static isImportedFeeSource = (id: number | undefined): boolean => {
    return !!this.importedArray().find((source) => source.id === id);
  };

  static getById = (id: number): FeeSourceType | undefined => {
    return Object.values(FeeSourceType)
      .filter((t) => t instanceof FeeSourceType)
      .find((t: FeeSourceType) => t.id === id);
  };
}

export interface UploadFeeConsentValues {
  isSuperOrPension: boolean;
  feeConsentTypeId: number | null;
  acknowledged: boolean;
  name: string;
  fileName: string;
  base64Content: string | null;
  issueDate: moment | null;
  expiryDate: moment | null;
  typeId: number | null;
  file: File | null;
}

export class FeeConsentType extends Enumeration {
  static Application = new FeeConsentType(1, 'Application', 'Application');
  static Renewal = new FeeConsentType(2, 'Renewal', 'Renewal');

  static getArray = (): FeeConsentType[] => [FeeConsentType.Application, FeeConsentType.Renewal];

  static getById = (id: number): FeeConsentType | undefined => {
    return Object.values(FeeConsentType)
      .filter((t) => t instanceof FeeConsentType)
      .find((t: FeeConsentType) => t.id === id);
  };
}

export class FeeConsentMethod extends Enumeration {
  static SendViaDocusign = new FeeConsentMethod(1, 'SendViaDocusign', 'Send via Docusign');
  static UploadDocument = new FeeConsentMethod(2, 'UploadDocument', 'Upload Document');

  static getArray = (): FeeConsentMethod[] => [FeeConsentMethod.SendViaDocusign, FeeConsentMethod.UploadDocument];

  static getById = (id: number): FeeConsentMethod | undefined => {
    return Object.values(FeeConsentMethod)
      .filter((t) => t instanceof FeeConsentMethod)
      .find((t: FeeConsentMethod) => t.id === id);
  };
}

export interface AfslFeesList {
  feeId : number;
  feeName: string;
  feeCode: string;
}

export interface AFSLFeesListResponse {
  afslFees: { name: string; code: string }[];
}
