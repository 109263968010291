import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchConsolidatedSecurityHoldingsSummary, fetchSecurityDetails } from './thunks';
import { ConsolidatedSecurityHoldingsSummary, SecurityDetails, SecurityDetailsState } from './types';

export const initialState: SecurityDetailsState = {
  consolidatedSecurityHoldingsSummary: null,
  securityDetails: null,
};

export const securityDetailsSlice = createSlice({
  name: '@@security/details',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchConsolidatedSecurityHoldingsSummary.fulfilled, (state, action: PayloadAction<ConsolidatedSecurityHoldingsSummary>) => {
      state.consolidatedSecurityHoldingsSummary = action.payload;
    });
    builder.addCase(fetchSecurityDetails.fulfilled, (state, action: PayloadAction<SecurityDetails>) => {
      state.securityDetails = action.payload;
    });
  },
});
