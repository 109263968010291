import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchInvestmentServices } from './thunks';
import { InvestmentService, InvestmentServicesState } from './types';
export const initialState: InvestmentServicesState = {
  items: [],
};

export const investmentServicesSlice = createSlice({
  name: 'investmentServices',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchInvestmentServices.fulfilled, (state, action: PayloadAction<InvestmentService[]>) => {
      state.items = action.payload;
    });
  },
});
