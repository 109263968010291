import { ButtonGroup } from '@mui/material';
import { DateTime } from 'luxon';
import React from 'react';
import { moment } from 'src/common/types';
import { SystemMinimumDate } from '../../../../common';
import { DashboardChartInterval, SecurityDashboardParameters } from '../store/types';
import WO2Button from './../../../../common/components/button/Button';

interface Props {
  parameters: SecurityDashboardParameters;
  intervalButtonClicked: (button: IIntervalButton) => void;
}

export interface IIntervalButton {
  name: DashboardChartInterval;
  startDate: moment;
  enabled: boolean;
}

const MonthlyPerformanceChartButtons = (props: Props): JSX.Element => {
  const inceptionDate = SystemMinimumDate;

  const getStartDate = (months: number) => {
    return DateTime.now().minus({ months: months }).minus({ days: 1 });
  };

  const getStartDateWeek = (weeks: number) => {
    return DateTime.now().minus({ weeks: weeks }).minus({ days: 1 }).toISODate();
  };

  const getClassName = (button: IIntervalButton, intervalName?: DashboardChartInterval) => {
    return intervalName === button.name ? 'Mui-disabled' : '';
  };

  const isButtonEnabled = (months: number) => {
    const startDate = getStartDate(months);
    return inceptionDate ? DateTime.fromISO(inceptionDate) < startDate : true;
  };

  const buttons: IIntervalButton[] = [
    {
      name: DashboardChartInterval.OneWeek,
      startDate: getStartDateWeek(1),
      enabled: isButtonEnabled(1),
    },
    {
      name: DashboardChartInterval.OneMonth,
      startDate: getStartDate(1).toISODate(),
      enabled: isButtonEnabled(1),
    },
    {
      name: DashboardChartInterval.ThreeMonths,
      startDate: getStartDate(3).toISODate(),
      enabled: isButtonEnabled(3),
    },
    {
      name: DashboardChartInterval.SixMonths,
      startDate: getStartDate(6).toISODate(),
      enabled: isButtonEnabled(6),
    },
    {
      name: DashboardChartInterval.OneYear,
      startDate: getStartDate(12).toISODate(),
      enabled: isButtonEnabled(12),
    },
    {
      name: DashboardChartInterval.Inception,
      startDate: inceptionDate,
      enabled: true,
    },
  ];

  return (
    <ButtonGroup disableElevation variant="text" style={{ display: 'flex', justifyContent: 'center' }}>
      {buttons.map((button, index) => (
        <WO2Button
          key={`button-${index}`}
          style={{ width: '70px' }}
          variant="text"
          disableElevation
          onClick={() => {
            props.intervalButtonClicked(button);
          }}
          className={`${getClassName(button, props.parameters?.dailySecurityPricesChartStartDate?.interval)} SemiTransparent`}
        >
          {button.name}
        </WO2Button>
      ))}
    </ButtonGroup>
  );
};

export default MonthlyPerformanceChartButtons;
