import { createSelector } from '@reduxjs/toolkit';
import { selectOrder } from '../../selectors';
import { selectBulk } from 'src/features/bulk/selectors';
import { BulkState } from 'src/features/bulk/types';

export const selectCommon = createSelector(selectBulk, (bulkState: BulkState) => bulkState.common);
export const selectSearch = createSelector(selectOrder, (orderState) => orderState.search);
export const selectParameters = createSelector(selectSearch, (searchState) => searchState.parameters);
export const selectSecurities = createSelector(selectCommon, (common) => common.securities);
export const selectSearchResults = createSelector(selectSearch, (searchState) => searchState.results);
export const selectSearchSelectedResults = createSelector(selectSearch, (searchState) => searchState.selectedResults);
export const selectModels = createSelector(selectSearch, (searchState) => searchState.models);
