import { Box, FormControlLabel } from '@mui/material';
import { WO2Checkbox } from 'src/common';
import { ClientReportsParameters, MdaReportTypes } from '../../store/types';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import React from 'react';
import { DateRange } from 'src/store';
import DateRangePicker from 'src/common/components/DateRangePicker';

export interface TaxSummaryProps {
  parameters: ClientReportsParameters;
  setTaxSummarySelectedParameter: ActionCreatorWithPayload<boolean, string>;
  setDateRangeTaxSummaryParameter: ActionCreatorWithPayload<DateRange>;
  createReportSortRequest: (isAdd: boolean, id: number, text: string) => void;
}

export const TaxSummary = ({
  parameters,
  setTaxSummarySelectedParameter,
  setDateRangeTaxSummaryParameter,
  createReportSortRequest,
}: TaxSummaryProps) => {
  const handleClickTaxSummarySelect = () => {
    setTaxSummarySelectedParameter(!parameters.selectedReports?.taxSummary.selected);
    createReportSortRequest(
      !parameters.selectedReports?.taxSummary.selected,
      MdaReportTypes.TaxSummary.id,
      MdaReportTypes.TaxSummary.displayName
    );
  };
  const handleClickDateForTaxSummary = (dates: DateRange) => {
    setDateRangeTaxSummaryParameter(dates);
  };
  return (
    <Box ml={6} display="flex" justifyContent="space-between" mt={1} mb={1}>
      <FormControlLabel
        aria-label="Acknowledge"
        onClick={(event) => event.stopPropagation()}
        onFocus={(event) => event.stopPropagation()}
        control={
          <WO2Checkbox
            color="primary"
            checked={parameters.selectedReports?.taxSummary.selected}
            onChangeHandler={handleClickTaxSummarySelect}
          />
        }
        label="Tax Summary"
      />
      <DateRangePicker
        dateRange={{
          dateFrom: parameters?.selectedReports.taxSummary?.fromDate,
          dateTo: parameters?.selectedReports.taxSummary?.toDate,
          dateRangeType: parameters?.selectedReports.taxSummary?.dateRangeType,
        }}
        setDateRange={handleClickDateForTaxSummary}
        inceptionDate={parameters.inceptionDate}
      />
    </Box>
  );
};
