import { Alert } from '@mui/material';
import Typography from '@mui/material/Typography';
import React, { useEffect } from 'react';
import { DateRange } from 'src/store/types/dateRange';
import { Props } from '../container';
import { FetchContributionDetailsRequest } from '../store/types';
import { ContributionDetailsDatePicker } from './contributionDetailsDatePicker';
import { Contributions } from './contributions';

export const ContributionDetails = (props: Props): JSX.Element => {
  const { superMemberNumber, contributionDetails, fetchContributionDetails } = props;

  const monthAndDayStart = '06-30';
  const firstDate = `2023-${monthAndDayStart}`;
  let selectedDate = '';

  const getCurrentFinancialYear = () => {
    const todaysDate = new Date();
    todaysDate.setHours(0, 0, 0, 0);

    const currentYear = todaysDate.getFullYear();
    const getCurrentFinancialYearTodayDate = new Date(`${currentYear}-${monthAndDayStart}`);
    getCurrentFinancialYearTodayDate.setHours(0, 0, 0, 0);

    return todaysDate <= getCurrentFinancialYearTodayDate ? currentYear : currentYear + 1;
  };

  const getMinMaxDates = () => {
    const dateRange: DateRange = {
      dateFrom: firstDate,
      dateTo: `${getCurrentFinancialYear()}-${monthAndDayStart}`,
    };
    return dateRange;
  };

  const minMaxDates: DateRange = getMinMaxDates();
  selectedDate = minMaxDates.dateTo;

  useEffect(() => {
    if (superMemberNumber && selectedDate) {
      const request: FetchContributionDetailsRequest = {
        accountId: superMemberNumber,
        financialYear: selectedDate,
      };
      fetchContributionDetails(request);
    }
  }, [fetchContributionDetails, superMemberNumber]);

  const setDate = (stringDate: string) => {
    if (stringDate && superMemberNumber) {
      const request: FetchContributionDetailsRequest = {
        accountId: superMemberNumber ?? '',
        financialYear: stringDate,
      };
      fetchContributionDetails(request);
    }
  };

  return (
    <>
      <Typography variant="h3" style={{ padding: '20px 0' }}>
        Contributions
      </Typography>

      <Typography sx={{ paddingBottom: '20px' }}>
        <strong>Important Note:</strong> DIY Master Plan (Super Simplifier Division) issued by Diversa Trustees Limited
        (ABN 46 074 281 314) (&quot;former fund&quot;) provided the members contribution information paid up to and
        including 27 November 2022. All contributions made from 28 November 2022 onwards are to current Super Simplifier
        (&quot;fund&quot;) (ABN 36 526 795 205; USI 36 526 795 205 001) issued by Equity Trustee Superannuation Limited
        (&quot;Trustee&quot;) (ABN 50 055 641 757, AFSL 229757, RSE L0001458). The information provided by your former
        fund has been made available in good faith and the Trustee accepts no liability for any errors or omissions
        within the information provided by the former fund. If you have any queries in relation to the information
        provided, please contact DASH Adviser Services on 1300 726 008.
      </Typography>

      <ContributionDetailsDatePicker
        setDate={setDate}
        data-testid="contributionDetailsDatePickerComponent"
        minMaxDateRange={minMaxDates}
        financialYearDate={selectedDate}
      ></ContributionDetailsDatePicker>

      {contributionDetails.status === 200 ? (
        <Contributions data-testid="contributionsComponent" contributionDetails={contributionDetails}></Contributions>
      ) : (
        <Alert
          style={{ marginRight: '20px', marginTop: '20px', marginBottom: '20px' }}
          variant="outlined"
          severity="info"
        >
          <Typography>
            There is currently no contribution information for this member. If the member has been recently established
            it may take a few days for this data to be populated.
          </Typography>
        </Alert>
      )}
    </>
  );
};
