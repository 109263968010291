import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { progressSelectorFactory } from 'src/common/store/selectors';
import { RootState } from 'src/reducers';
import { selectHasClientEditAdminOnly, selectIsDataFromTriumph } from 'src/store/selectors';
import { selectAfslId, selectClientId, selectEntityDetails, selectPortfolios } from '../../common/store/selectors';
import { selectCommon } from '../store';
import {
  commonSlice,
  selectInvestmentService,
  selectInvestmentServicesWithVersions,
  selectInvestmentServicesWithVersionsGrouped,
} from '../store/common';
import { RebalanceActionTypes, rebalanceSlice, rebalanceTemplate, selectValidationWarnings } from '../store/rebalance';
import { Overview } from './components/Overview';
import {
  selectGainsLossesItems,
  selectMarketValue,
  selectParameters,
  selectPortfolioAssetGroups,
  selectTemplates,
} from './store/selectors';
import { overviewSlice } from './store/slice';
import {
  createAndGetNewInvestmentService,
  fetchPortfolioDetail,
  fetchTemplates,
  PortfolioActionTypes,
  processCalculateHoldingsAndPerformance,
} from './store/thunks';

const mapStateToProps = (state: RootState) => ({
  clientId: selectClientId(state),
  entityDetails: selectEntityDetails(state),
  parameters: selectParameters(state),
  clientPortfolios: selectPortfolios(state),
  marketValue: selectMarketValue(state),
  gainsLossesItems: selectGainsLossesItems(state),
  portfolioAssetGroups: selectPortfolioAssetGroups(state),
  fetchPortfolioDetailProgress: progressSelectorFactory([PortfolioActionTypes.FetchPortfolioDetail])(state),
  templates: selectTemplates(state),
  fetchTemplatesProgress: progressSelectorFactory([PortfolioActionTypes.FetchTemplates])(state),
  createAndGetNewInvestmentServiceProgress: progressSelectorFactory([
    PortfolioActionTypes.CreateAndGetNewInvestmentService,
  ])(state),
  hasClientEditAdminOnlyPermission: selectHasClientEditAdminOnly(state),
  investmentServicesWithVersions: selectInvestmentServicesWithVersions(state),
  investmentServicesWithVersionsGrouped: selectInvestmentServicesWithVersionsGrouped(state),
  common: selectCommon(state),
  afslId: selectAfslId(state),
  rebalanceTemplateProgress: progressSelectorFactory([RebalanceActionTypes.RebalanceTemplate])(state),
  selectedInvestmentService: selectInvestmentService(state),
  rebalanceValidationWarnings: selectValidationWarnings(state),
  isDataFromTriumph: selectIsDataFromTriumph(state),
  processCalculateHoldingsAndPerformanceProgress: progressSelectorFactory([
    PortfolioActionTypes.ProcessCalculateHoldingsAndPerformance,
  ])(state),
});

const mapDispatchToProps = {
  ...overviewSlice.actions,
  fetchPortfolioDetail,
  fetchTemplates,
  createAndGetNewInvestmentService,
  rebalanceTemplate,
  processCalculateHoldingsAndPerformance,
  ...commonSlice.actions,
  ...rebalanceSlice.actions,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector> & RouteComponentProps;
export const OverviewContainer = connect(mapStateToProps, mapDispatchToProps)(Overview);
