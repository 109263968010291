import { Typography } from '@mui/material';
import React, { ReactNode, useEffect, useState } from 'react';
import { LoadingIndicator } from 'src/common';
import { Props } from '../container';
import { WO2Modal, WO2ToggleButtonGroup, ToggleButtonItem, WO2TextInput, DialogActionButton } from 'src/common/components';
import { DeathAndTPDInsurance } from './deathAndTPDInsurance';
import { IncomeProtectionDetail } from './incomeProtectionDetail';
import { InsurancePremiumDetails } from './premiumDetails';
import { MetlifeErrorMessage } from './metlifeErrorMessage';
import WO2Button from 'src/common/components/button/Button';
import { ContactRoles, SecureMetApplyToken } from '../store/types';
import { ResultWrapper } from '../../benefitStatement/store/types';
import PulseLoader from 'react-spinners/PulseLoader';
import { StatusType } from 'src/common/types';

export const InsuranceDetail = ({
  accountId,
  clientDetails,
  loadingProgressDeathAndTpd,
  loadingProgressIncomeProtection,
  loadingProgressPremiumDetails,
  fetchDeathAndTpdInsuranceByAccountId,
  fetchIncomeProtectionInsuranceByAccountId,
  fetchPremiumDetails,
  deathAndTpdDetails,
  incomeProtectionInsuranceDetails,
  premiumDetails,
  fetchMetApplyToken,
  clientId,
  authorisedContactName,
  parameters,
  setClientIdParameter,
  setIsSmokerParameter,
  setAccountIdParameter,
  fetchAuthorisedContact,
  launchMetlifeCalculator,
}: Props): JSX.Element => {
  const [newMetlifeApplicationOpen, setMetlifeApplicationOpen] = useState<boolean>(false);
  const [isClientSmoker, setClientSmoker] = useState<string>('0');
  const [authorisedContact, setAuthorisedContact] = useState<string>(authorisedContactName);
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);
  const [showProgressBar, setShowProgressBar] = useState<boolean>(false);
  const [icon, setIcon] = useState<ReactNode | null>(null);

  useEffect(() => {
    if (!!clientId) setClientIdParameter(clientId);
    if (!!accountId) setAccountIdParameter(accountId);

    if (!!accountId) {
      fetchDeathAndTpdInsuranceByAccountId(accountId);
    }
  }, [fetchDeathAndTpdInsuranceByAccountId, accountId]);

  useEffect(() => {
    if (!!accountId) {
      fetchIncomeProtectionInsuranceByAccountId(accountId);
    }
  }, [fetchIncomeProtectionInsuranceByAccountId, accountId]);

  useEffect(() => {
    if (!!accountId) {
      fetchPremiumDetails(accountId);
    }
  }, [fetchPremiumDetails, accountId]);

  useEffect(() => {
    if (!!clientId) {
      fetchAuthorisedContact(clientId).then((response) => {
        const contact = response.payload as ContactRoles;
        setAuthorisedContact(contact.name);
      });
    }
  }, [fetchAuthorisedContact, clientId]);

  useEffect(() => {
    if (showErrorMessage || showProgressBar) {
      setIcon(
        <div className="LoadingIndicator" style={{ paddingTop: '14px' }}>
          <PulseLoader size="12px" margin="5px" color={'#4306fa'} />
        </div>
      );
    } else {
      setIcon(<></>);
    }
  }, [showErrorMessage, showProgressBar]);

  const submitMetApplyClick = () => {
    setShowProgressBar(true);
    setShowErrorMessage(false);
    if (!!clientId && !!accountId) {
      fetchMetApplyToken(parameters)
        .then((response) => {
          const metlifeToken = response.payload as ResultWrapper<SecureMetApplyToken>;
          if (metlifeToken.status !== 200) {
            setShowErrorMessage(true);
          }
        })
        .catch((err) => {
          setShowErrorMessage(true);
          console.error(err);
        })
        .finally(() => {
          setShowProgressBar(false);
        });
    }
  };

  const handleSmokerChange = (isSmoker: string) => {
    if (isClientSmoker !== isSmoker) {
      const isSmokerFlag = isSmoker === '1' ? true : false;
      setIsSmokerParameter(isSmokerFlag);
      setClientSmoker(isSmoker);
    }
  };

  const handleLaunchMetApply = () => {
    setShowErrorMessage(false);
    setShowProgressBar(false);
    setMetlifeApplicationOpen(true);
  };

  const handleOpenMetlifeCalculator = () => {
    launchMetlifeCalculator();
  };

  const smokerToggleButtons: ToggleButtonItem<string>[] = [
    { name: 'Yes', value: '1' },
    { name: 'No', value: '0' },
  ];
  const dialogToggleButtons: DialogActionButton[] = [
    {
      label: 'Cancel',
      onClickHandler: () => {
        setMetlifeApplicationOpen(false);
      },
      type: 'outlined',
    },
    {
      label: showErrorMessage ? 'Retry' : 'Submit',
      onClickHandler: () => {
        submitMetApplyClick();
      },
      type: 'contained',
    },
  ];

  const ssStatus = clientDetails ? clientDetails.status : undefined;

  return (
    <>
      {process.env.REACT_APP_ENABLE_LAUNCH_METLIFE == 'true' && 
        <>
            {ssStatus === StatusType.Active.id &&
              <WO2Button
              style={{ marginRight: '10px', float: 'right', textTransform: 'none' }}
              onClick={() => handleLaunchMetApply()}
              variant="contained"
              id="launch_met"
              name="launch_met"
              >
                Manage application
              </WO2Button>
            }
            <WO2Button
              style={{ marginRight: '10px', float: 'right', textTransform: 'none' }}
              onClick={() => handleOpenMetlifeCalculator()}
              variant="contained"
              id="premium_calculator"
              name="premium_calculator"
            >
              Premium Calculator
            </WO2Button>
        </>
      }
      <Typography variant="h3" style={{ padding: '20px 0' }}>
        <b>Insurance</b>
      </Typography>
      <LoadingIndicator progress={loadingProgressDeathAndTpd}>
        <DeathAndTPDInsurance data-testid="deathAndTPDInsuranceComponent" details={deathAndTpdDetails}></DeathAndTPDInsurance>
      </LoadingIndicator>

      <LoadingIndicator progress={loadingProgressIncomeProtection}>
        <IncomeProtectionDetail data-testid="incomeProtectionDetailComponent" details={incomeProtectionInsuranceDetails}></IncomeProtectionDetail>
      </LoadingIndicator>

      <LoadingIndicator progress={loadingProgressPremiumDetails}>
        <InsurancePremiumDetails data-testid="insurancePremiumDetailComponent" details={premiumDetails}></InsurancePremiumDetails>
      </LoadingIndicator>

      <WO2Modal
        MuiDialogProps={{
          open: newMetlifeApplicationOpen,
          onClose: () => {
            setMetlifeApplicationOpen(false);
          },
        }}
        title={
          <>
            <b>Initiate/Continue Application</b>
          </>
        }
        actions={dialogToggleButtons}
      >
        <>
          {icon}
          {showErrorMessage && <MetlifeErrorMessage details="" />}
          {!showErrorMessage && (
            <>
              <p>
                <ul>
                  <WO2TextInput
                    disabled={false}
                    label="Member Name"
                    defaultValue={authorisedContact}
                    multiline={false}
                    fullWidth={true}
                    style={{ marginBottom: '20px' }}
                    variant="outlined"
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </ul>
              </p>
              <p>
                <ul>
                Have you smoked tobacco or any other substance, used 
                  <br />
                  e-cigarettes, vaping or any nicotine replacement products  <br />
                  in the last 12 months? &nbsp;
                  <WO2ToggleButtonGroup
                    fullWidth={false}
                    buttons={smokerToggleButtons}
                    value={isClientSmoker}
                    onChangeHandler={(isSmoker) => {
                      handleSmokerChange(isSmoker);
                    }}
                  />
                </ul>
              </p>
            </>
          )}
        </>
      </WO2Modal>
    </>
  );
};
