import { createAction } from '@reduxjs/toolkit';
import { AddressDetails, DocumentDetails } from '../../client/common/store/types';
import { AccountInfo, AccountTypeValues, AdditionalInformationValues, AdviceFees, SaveContactValues, SuperSimplifierDetails, Trustee } from './types';

export const setAccountTypeValues = createAction<AccountTypeValues>('onboarding/setAccountTypeValues');

export const setAdditionalInformationValues = createAction<AdditionalInformationValues>('onboarding/setAdditionalInformationValues');

export const setSuperSimplifierDetailsValues = createAction<SuperSimplifierDetails>('onboarding/setSuperSimplifierDetailsValues');

export const setAdviceFeesValues = createAction<AdviceFees>('onboarding/setAdviceFeesValues');

export const setAccountInfo = createAction<AccountInfo>('onboarding/setAccountInfo');

export const setContactValues = createAction<SaveContactValues>('onboarding/setContactValues');

export const setTrustee = createAction<Trustee | undefined>('onboarding/setTrustee');

export const removeContact = createAction<{ index: number }>('onboarding/removeContact');

export const setContactIdentificationValues = createAction<DocumentDetails[]>('onboarding/setContactIdentificationValues');

export const removeAddress = createAction<{ index: number }>('onboarding/removeAddress');

export const setAddress = createAction<AddressDetails>('onboarding/setAddress');
