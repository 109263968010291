import { createUserManager } from 'redux-oidc';

const userManagerConfig = {
  client_id: process.env.REACT_APP_IDENTITY_CLIENT_ID,
  redirect_uri: process.env.REACT_APP_REDIRECT_URL,
  response_type: 'code',
  scope: 'openid profile GatewayMDA',
  authority: process.env.REACT_APP_AUTH_URL,
  silent_redirect_uri: process.env.REACT_APP_SILENT_REDIRECT_URL,
  post_logout_redirect_uri: process.env.POST_LOGOUT_REDIRECT_URI,
  automaticSilentRenew: true,
  filterProtocolClaims: true,
  loadUserInfo: true,
  monitorSession: true,
};

export const userManager = createUserManager(userManagerConfig);
