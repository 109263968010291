import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../../../../../app/api';
import { buildEncodedQueryString } from '../../../../../../../common';
import { FetchSecurityExclusionPayload, SecurityExclusion, CreateUpdateSecurityExclusion, DeleteSecurityExclusionItem } from './types';

export enum SecurityExclusionActionTypes {
  FetchSecurityExclusion =  '@@client/investmentServices/exclusions/Fetch',
  CreateOrUpdateSecurityExclusion = '@@client/investmentServices/exclusions/Create',
  DeleteSecurityExclusion = '@@client/investmentServices/exclusions/Delete'
}

export enum SecurityExclusionEndpoints {
  FetchSecurityExclusion = '/investmentservices/GetSecurityExclusion',
  CreateOrUpdateSecurityExclusion = '/investmentservices/CreateOrUpdateSecurityExclusion',
  DeleteSecurityExclusion = '/investmentservices/DeleteSecurityExclusion'
}

export const fetchSecurityExclusion = createAsyncThunk(
  SecurityExclusionActionTypes.FetchSecurityExclusion,
  async ({ investmentServiceId, clientId }: FetchSecurityExclusionPayload) => {
    const queryString = buildEncodedQueryString({
      clientId,
      investmentServiceId,
    });
    
    const response = await api.get<SecurityExclusion[]>(`${SecurityExclusionEndpoints.FetchSecurityExclusion}${queryString}`);

    return response.data;
  }
);

export const createOrUpdateSecurityExclusion = createAsyncThunk(
  SecurityExclusionActionTypes.CreateOrUpdateSecurityExclusion,
  async (payload: CreateUpdateSecurityExclusion, thunkApi) => {

    await api.post<number>(`${SecurityExclusionEndpoints.CreateOrUpdateSecurityExclusion}`, payload);
    
    await thunkApi.dispatch(fetchSecurityExclusion({ investmentServiceId: payload.investmentServiceId, clientId: payload.clientId }));

    return { message: 'Security Exclusions created / updated' };
  }
);

export const deleteSecurityExclusion = createAsyncThunk(
  SecurityExclusionActionTypes.DeleteSecurityExclusion,
  async (payload: DeleteSecurityExclusionItem, thunkApi) => {
    const config = {
      data: payload, 
    };

    await api.delete(`${SecurityExclusionEndpoints.DeleteSecurityExclusion}`, config);

    await thunkApi.dispatch(fetchSecurityExclusion({ 
      investmentServiceId: payload.investmentServiceId, 
      clientId: payload.clientId,    
    }));
    
    return { ...payload, message: 'Securities deleted' };
  }
)