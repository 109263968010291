import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React from 'react';
import { formatNumberCommaSeparated } from '../../../../../../../common';
import { OtherBenefits } from './../store/types';

export interface Props {
  details: OtherBenefits | null | undefined;
}

export const OtherBenefitsComponents = ({ details }: Props): JSX.Element => {
  return (
    <>
      <Typography variant="h4" style={{ padding: '20px 0' }}>
        <b>Other Benefits</b>
      </Typography>
      <Paper elevation={5} style={{ marginBottom: '30px' }}>
        <TableContainer component={Paper} elevation={0}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="left" style={{ width: '70%' }}>
                  <Typography variant="h6" color="textSecondary">
                    DESCRIPTION
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography variant="h6" color="textSecondary">
                    VALUE ($)
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="left">
                  <Typography variant="h5">Death Benefit (including any insurance amount)</Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography data-testid="totalDeathSumInsuredData" variant="h5">
                    ${formatNumberCommaSeparated(details?.totalDeathSumInsured || 0, 2)}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">
                  <Typography variant="h5">Total and Permanent Disablement Benefit (including any insurance amount)</Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography data-testid="totalTPDSumInsuredData" variant="h5">
                    ${formatNumberCommaSeparated(details?.totalTPDSumInsured || 0, 2)}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};
