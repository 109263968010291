import { bindActionCreators, Dispatch } from '@reduxjs/toolkit';
import { connect, ConnectedProps } from 'react-redux';
import { progressSelectorFactory } from '../../../../common/store/selectors';
import { RootState } from '../../../../reducers';
import { selectClientId, selectPortfolios } from '../common/store/selectors';
import { GainsLosses } from './components/GainsLosses';
import { filteredResults, selectParameters } from './store/selectors';
import { gainsAndLossesSlice, realisedActions, unrealisedActions } from './store/slice';

const mapStateToProps = (state: RootState) => ({
  clientId: selectClientId(state),
  clientPortfolios: selectPortfolios(state),
  parameters: selectParameters(state),
  results: filteredResults(state),
  loadingRealisedProgress: progressSelectorFactory([realisedActions.actionName])(state),
  loadingUnrealisedProgress: progressSelectorFactory([unrealisedActions.actionName])(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    actions: bindActionCreators(gainsAndLossesSlice.actions, dispatch),
    fetchRealised: bindActionCreators(realisedActions.pending, dispatch),
    fetchUnrealised: bindActionCreators(unrealisedActions.pending, dispatch),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector>;
export const GainsLossesContainer = connect(mapStateToProps, mapDispatchToProps)(GainsLosses);
