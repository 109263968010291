import { createSelector } from '@reduxjs/toolkit';
import { selectClientState, selectPortfolio } from '../../store/selectors';
import { ClientAccountSubType, ClientAccountType } from './../../../../../common/types';

export const selectCommon = createSelector(selectClientState, (clientState) => clientState.common);
export const selectUserPortfolio = createSelector(selectPortfolio, (portfolio) => portfolio.common);
export const selectPortfolios = createSelector(selectCommon, (common) => common.portfolios);
export const selectPortfoliosWithoutAll = createSelector(selectPortfolios, (portfolios) =>
  portfolios.filter((p) => !!p.investmentProgramId)
);
export const selectEntityDetails = createSelector(selectCommon, (common) => common.entityDetails);
export const selectClientMainDetails = createSelector(selectCommon, (common) => common.clientMainDetails);
export const selectAccounts = createSelector(selectCommon, (common) => common.accounts);

export const selectClientId = createSelector(selectCommon, (common) => common.clientId);
export const selectIsSuperSimplifierClient = createSelector(selectClientState, (clientState) => {
  if (!clientState.details.mainDetails) {
    return false;
  }
  const details = clientState.details.mainDetails.item;
  const accountTypeId = details?.accountTypeId;
  const subTypeId = details?.subTypeId;

  if (
    accountTypeId === ClientAccountType.Individual.id &&
    !!subTypeId &&
    (subTypeId === ClientAccountSubType.Super.id || subTypeId === ClientAccountSubType.Pension.id)
  ) {
    return true;
  }

  return false;
});
export const selectAfslId = createSelector(selectEntityDetails, (entityDetails) => entityDetails?.afslId);
export const selectInvestmentServiceId = createSelector(selectUserPortfolio, (portfolio) => portfolio.investmentServiceId);
export const clientSubTypeId = createSelector(selectCommon, (common) => common.clientMainDetails?.subTypeId);

export const selectDocumentTypes = createSelector(selectCommon, (common) => common.documentTypes);
export const selectRoles = createSelector(selectCommon, (common) => common.roles);

export const selectClientRoles = createSelector(selectRoles, (roles) => {
  const clientRoles = roles.filter((role) => role.id !== 17 && role.id !== 18 && role.id !== 19 && role.id !== 21);
  return clientRoles;
});

export const selectSuperSimplifierDetails = createSelector(
  selectClientState,
  (clientState) => clientState.common.superSimplifier
);
export const selectClientStatus = createSelector(
  selectClientState,
  (clientState) => clientState.details?.mainDetails?.item?.status
);
