import React from 'react';
import { MainDetails as MainDetailsType } from '../../../common/store/types';
import { Props } from '../container';
import { DetailsForm } from './detailsForm';
import { Header } from './header';

export const MainDetails = ({
  updateClientAccount,
  clientId,
  details,
  isLoadingProgress,
  fetchClientAccount,
  saveProgress,
  hasClientEditPermission,
}: Props): JSX.Element => {
  const onSave = (details: MainDetailsType) => {
    if (!!clientId) {
      updateClientAccount({
        details: { ...details, clientId },
        message: 'Client details saved',
      }).then(() => {
        fetchClientAccount(clientId);
      });
    }
  };

  return (
    <>
      {!!details && !!clientId && (
        <>
          <Header details={details} clientId={clientId}></Header>
          {!!details && (
            <DetailsForm
              hasClientEditPermission={hasClientEditPermission}
              loadingProgress={isLoadingProgress}
              details={details}
              onSave={onSave}
              saveProgress={saveProgress}
            ></DetailsForm>
          )}
        </>
      )}
    </>
  );
};
