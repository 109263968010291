import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchModelAssociations } from './thunks';
import { ModelAssociations, ModelAssociationsState } from './types';

export const initialState: ModelAssociationsState = {
  modelAssociations: null,
};

export const modelAssociationsSlice = createSlice({
  name: '@@model/associations',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchModelAssociations.fulfilled, (state, action: PayloadAction<ModelAssociations>) => {
      state.modelAssociations = action.payload;
    });
  },
});
