import { ActionCreatorWithoutPayload, ActionCreatorWithPayload, ActionCreatorWithPreparedPayload, createAction } from '@reduxjs/toolkit';

interface AsyncAction<TFulfilled> {
  actionName: string;
  pending: ActionCreatorWithoutPayload;
  fulfilled: ActionCreatorWithPreparedPayload<[TFulfilled], TFulfilled, string, never, never>;
  rejected: ActionCreatorWithPayload<string>;
}

interface CustomAsyncAction<TPending, TFulfilled, TRejected> {
  actionName: string;
  pending: ActionCreatorWithPreparedPayload<[TPending], TPending, string, never, never>;
  fulfilled: ActionCreatorWithPreparedPayload<[TFulfilled], TFulfilled, string, never, never>;
  rejected: ActionCreatorWithPreparedPayload<[TRejected], TRejected, string, never, never>;
}

export const getCustomAsyncActions = <TPending, TFulfilled, TRejected>(name: string): CustomAsyncAction<TPending, TFulfilled, TRejected> => {
  return {
    actionName: name,
    pending: createActionWithPayload<TPending>(`${name}/pending`),
    fulfilled: createActionWithPayload<TFulfilled>(`${name}/fulfilled`),
    rejected: createActionWithPayload<TRejected>(`${name}/rejected`),
  };
};

export const getAsyncActions = <TFulfilled>(name: string): AsyncAction<TFulfilled> => {
  return {
    actionName: name,
    pending: createAction(`${name}/pending`),
    fulfilled: createActionWithPayload<TFulfilled>(`${name}/fulfilled`),
    rejected: createActionWithPayload<string>(`${name}/rejected`),
  };
};

const withPayloadType =
  <T>() =>
  (t: T) => ({ payload: t });

const createActionWithPayload = <T>(action: string): ActionCreatorWithPreparedPayload<[T], T, string, never, never> =>
  createAction(action, withPayloadType<T>());
