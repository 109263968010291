import { Route } from '../../store';
import { CommonContainer } from './common/container';
import { FxRateListStateContainer } from './list/container';

export const forexRateRoutes: Route[] = [
  {
    path: '/forexrate/list',
    component: FxRateListStateContainer,
    protected: true,
    exact: true,
  },
  {
    path: '/forexrate/:baseCurrencyId/:quoteCurrencyId',
    component: CommonContainer,
  },
];
