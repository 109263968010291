import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchStrategyDetails } from './thunks';
import { StrategyDetails, StrategyDetailsState } from './types';

export const initialState: StrategyDetailsState = {
  details: null,
};

export const strategyDetailsSlice = createSlice({
  name: '@@strategy/details',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchStrategyDetails.fulfilled, (state, action: PayloadAction<StrategyDetails>) => {
      state.details = action.payload;
    });
  },
});
