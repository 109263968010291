import { combineReducers } from '@reduxjs/toolkit';
import { commonSlice } from '../common/store/slice';
import { FxCommonState } from '../common/store/types';
import { fxListSlice } from '../list/store/slice';
import { FxListState } from '../list/store/types';
import { fxPriceSlice } from '../rate/store/slice';
import { FxPriceState } from '../rate/store/types';

export type ForexRateState = {
  common: FxCommonState;
  list: FxListState;
  rate: FxPriceState;
};

export const forexRateReducer = combineReducers<ForexRateState>({
  common: commonSlice.reducer,
  list: fxListSlice.reducer,
  rate: fxPriceSlice.reducer
});
