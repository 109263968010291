import { FormControlLabel, Grid, OutlinedInput, Radio, RadioGroup } from '@mui/material';
import React, { useState } from 'react';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import { WO2Modal } from 'src/common';
import { LoadingProgress } from 'src/common/store/types';
import { ProcessCalculateHoldingsAndPerformanceRequest } from '../../store';

export interface Props {
  onClose: () => void;
  submitTriumphRequest: (request: ProcessCalculateHoldingsAndPerformanceRequest) => void;
  submitTriumphProgress: LoadingProgress;
  clientId: number | null;
}

export function CalculateHoldingsAndPerformanceDialog({
  onClose,
  submitTriumphRequest,
  submitTriumphProgress,
  clientId,
}: Props): JSX.Element {
  const [isAllClients, setIsAllClients] = useState<boolean>(false);
  const [localClientId, setLocalClientId] = useState<number | null>(clientId);
  const handleRadioChange = () => {
    setIsAllClients(!isAllClients);
  };

  return (
    <WO2Modal
      MuiDialogProps={{
        open: true,
        onClose: () => {
          onClose();
        },
        maxWidth: 'md',
      }}
      title={<>Calculate Holdings & Performance</>}
      actions={[
        {
          label: 'Close',
          onClickHandler: () => {
            onClose();
          },
          type: 'outlined',
          disabled: false,
        },
        {
          label: 'Calculate Holdings & Performance',
          onClickHandler: () => {
            const request: ProcessCalculateHoldingsAndPerformanceRequest = {
              jobType: 'LoadAndCalculate',
              clientId: isAllClients ? null : localClientId,
              clientIds: null,
            };
            submitTriumphRequest(request);
            onClose();
          },
          type: 'contained',
          disabled: submitTriumphProgress.isLoading,
          buttonType: 'submit',
        },
      ]}
    >
      <>
        {!submitTriumphProgress.isLoading && (
          <Grid container justifyContent="flex-start" spacing={2}>
            <Grid item xs={12}>
              Runs full jobs for all client-based loads and calculations (from inception).
            </Grid>
            <RadioGroup
              row
              aria-label="triumphClient"
              name="row-radio-buttons-group"
              value={isAllClients}
              onChange={handleRadioChange}
            >
              <FormControlLabel value="false" control={<Radio />} label="ClientId" />
            </RadioGroup>
            <NumberFormat
              placeholder="Client Id"
              value={localClientId}
              customInput={OutlinedInput}
              type="text"
              data-id={'localClientId'}
              thousandSeparator={false}
              fixedDecimalScale={false}
              allowNegative={false}
              decimalScale={0}
              isAllowed={(values: NumberFormatValues) => {
                if (values.floatValue === undefined) return true;
                return true;
              }}
              onValueChange={(values) => {
                setLocalClientId(Number(values.floatValue));
              }}
            />
          </Grid>
        )}
      </>
    </WO2Modal>
  );
}
