import { connect, ConnectedProps } from 'react-redux';
import { progressSelectorFactory } from 'src/common';
import { RootState } from 'src/store';
import { cgtConfigurationSlice, fetchFinancialRecordsForClient } from '../store';
import { CGTConfigurationActionTypes } from '../store/thunks';
import { CarriedForwardLosses } from './components/carriedForwardGainsAndLosses';
import { selectPreviousFinancialYears } from './store/selectors';

const mapStateToProps = (state: RootState) => ({
  clientCgtRecords: selectPreviousFinancialYears(state),
  fetchFinancialRecordsForClientLoadingProgress: progressSelectorFactory([
    CGTConfigurationActionTypes.FetchFinancialRecordsForClient,
  ])(state),
});

const mapDispatchToProps = {
  ...cgtConfigurationSlice.actions,
  fetchFinancialRecordsForClient,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector>;
export const CarriedForwardLossesContainer = connector(CarriedForwardLosses);
