import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import React from 'react';
import { formatDollars, formatNumberCommaSeparated } from '../../../../common';
import { ServerSideDataTable } from '../../../../common/components/dataTable/serverSide';
import { DatatableColumn, FilterDataType } from '../../../../common/components/dataTable/types';
import { LoadingProgress } from '../../../../common/store/types';
import { PagedRequest, PagedResult } from '../../../../store';
import { SecurityExposureCsvRow, SecurityExposureItem } from '../store/types';

export interface Props {
  pagedResults?: PagedResult<SecurityExposureItem>;
  pagedRequest: PagedRequest;
  handlePagedRequest: (pagedRequest: PagedRequest) => void;
  progressPrices: LoadingProgress;
  handleDownload: (pagedRequest: PagedRequest, mapper: (securityExposure: SecurityExposureItem[]) => SecurityExposureCsvRow[]) => void;
}

export function SecurityExposureTable(props: Props): JSX.Element {
  const { pagedResults, progressPrices, handleDownload, pagedRequest } = props;

  const columns: DatatableColumn[] = [
    {
      filterDataType: FilterDataType.string,
      name: 'investmentServiceCode',
      label: 'PORTFOLIO ID',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: function customBodyRenderLite(dataIndex): React.ReactNode {
          return <Typography variant="h5">{pagedResults?.results[dataIndex]?.investmentServiceCode}</Typography>;
        },
      },
    },
    {
      filterDataType: FilterDataType.string,
      name: 'accountNumber',
      label: 'ACCOUNT NUMBER',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: function customBodyRenderLite(dataIndex): React.ReactNode {
          return <Typography variant="h5">{pagedResults?.results[dataIndex]?.accountNumber}</Typography>;
        },
      },
    },
    {
      filterDataType: FilterDataType.string,
      name: 'clientName',
      label: 'CLIENT AND PORTFOLIO NAME',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: function customBodyRenderLite(dataIndex): React.ReactNode {
          return (
            <>
              <Typography variant="h5">{pagedResults?.results[dataIndex]?.clientName}</Typography>
              <Typography color={'textSecondary'} variant={'h6'}>
                {pagedResults?.results[dataIndex]?.investmentServiceName}
              </Typography>
            </>
          );
        },
      },
    },
    {
      filterDataType: FilterDataType.string,
      textAlign: 'right',
      name: 'units',
      label: 'UNITS & PRICE',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: function customBodyRenderLite(dataIndex): React.ReactNode {
          return (
            <>
              <Typography variant="h5">{formatNumberCommaSeparated(pagedResults?.results[dataIndex]?.units ?? 0, 2)}</Typography>
              <Typography color={'textSecondary'} variant={'h6'}>
                {formatDollars(pagedResults?.results[dataIndex]?.unitPrice)}
              </Typography>
            </>
          );
        },
      },
    },
    {
      filterDataType: FilterDataType.string,
      textAlign: 'right',
      name: 'value',
      label: 'VALUE',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: function customBodyRenderLite(dataIndex): React.ReactNode {
          return <Typography variant="h5">{formatDollars(pagedResults?.results[dataIndex]?.value)}</Typography>;
        },
      },
    },
  ];

  return (
    <Grid container style={{ margin: '10px' }}>
      <Grid item xs={12}>
        <ServerSideDataTable
          loadingProgress={progressPrices}
          columns={columns}
          pagedRequest={pagedRequest}
          pagedResult={pagedResults}
          options={{}}
          csvDownload={() =>
            handleDownload(pagedRequest, (items) =>
              items.map((t) => {
                return {
                  investmentServiceCode: t.investmentServiceCode,
                  accountNumber: t.accountNumber,
                  clientName: t.clientName,
                  investmentServiceName: t.investmentServiceName,
                  unitPrice: formatDollars(t.unitPrice),
                  units: t.units,
                  value: formatDollars(t.value),
                };
              })
            )
          }
          handlePagedRequest={props.handlePagedRequest}
        ></ServerSideDataTable>
      </Grid>
    </Grid>
  );
}
