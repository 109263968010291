import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';
import { Transaction } from '../../transactions/store/types';
import { fetchClientPortfolioDetails, fetchMonthlyPerformanceChart, fetchRecentTransactionsList } from './thunks';
import {
  ClientPortfolioDetailsResult,
  ClientPortfolioSelectModel,
  DashboardState,
  MonthlyPerformanceChartResult,
  MonthlyPerformanceChartStartDateParameter,
  PerformanceChartInterval,
} from './types';

export const initialState: DashboardState = {
  parameters: {
    selectedPortfolio: null,
    monthlyPerformanceChartStartDate: {
      interval: PerformanceChartInterval.OneYear,
      startDate: DateTime.now().minus({ months: 12 }).toISODate(),
    },
    selectedHolding: null,
  },
};

export const dashboardSlice = createSlice({
  name: '@@client/dashboard',
  initialState,
  reducers: {
    setPortfolioParameter: (state, action: PayloadAction<ClientPortfolioSelectModel>) => {
      state.parameters.selectedPortfolio = action.payload;
    },
    setMonthlyPerformanceChartStartDate: (state, action: PayloadAction<MonthlyPerformanceChartStartDateParameter>) => {
      state.parameters.monthlyPerformanceChartStartDate = {
        ...action.payload,
        startDate: DateTime.fromISO(action.payload.startDate).minus({ days: 1 }).toISODate(),
      };
    },
    setSelectedHolding: (state, action: PayloadAction<string | null>) => {
      state.parameters.selectedHolding = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchMonthlyPerformanceChart.fulfilled,
      (state, action: PayloadAction<MonthlyPerformanceChartResult>) => {
        state.monthlyPerformanceChartResult = action.payload;
      }
    );

    builder.addCase(
      fetchClientPortfolioDetails.fulfilled,
      (state, action: PayloadAction<ClientPortfolioDetailsResult>) => {
        state.clientPortfolioDetailResult = action.payload;
      }
    );

    builder.addCase(fetchRecentTransactionsList.fulfilled, (state, action: PayloadAction<Transaction[]>) => {
      state.recentTransactionsListResult = action.payload;
    });
  },
});
