import { createSelector } from '@reduxjs/toolkit';
import { ForexRateState } from '.';
import { RootState } from '../../../reducers';

export const selectFx = createSelector(
  (state: RootState) => state,
  (state) => state.forexRate as ForexRateState
);

export const selectFxRate = createSelector(selectFx, (ForexRateState) => ForexRateState.rate);
