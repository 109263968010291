import { Paper, Typography } from '@mui/material';
import { default as React, useEffect, useState } from 'react';
import { Props } from '../container';
import { CreateDefaultSecurity, SaveConfigurationsItem, SaveStrategicAllocationItem } from '../store';
import { ConfigurationTable } from './configurationTable';
import { DefaultSecuritiesTable } from './defaultSecuritiesTable';
import { StrategicAllocationTable } from './strategicAllocationTable';

export const Targets = ({
  configurations,
  clientId,
  currentInvestmentVersionId,
  investmentService,
  loadingConfigurationsProgress,
  fetchConfigurations,
  saveConfigurations,
  savingConfigurationsProgress,
  fetchStrategicAllocations,
  cashAccountProduct,
  strategicAllocations,
  loadingStrategicAllocationsProgress,
  saveStrategicAllocations,
  savingStrategicAllocationsProgress,
  fetchDefaultSecurities,
  saveDefaultSecurities,
  defaultSecurities,
  afslId,
  activeInvestmentServiceVersionId,
}: Props): JSX.Element => {
  const [investmentServiceVersionId, setInvestmentServiceVersionId] = useState<number | undefined>(
    activeInvestmentServiceVersionId
  );
  useEffect(() => {
    if (!!investmentServiceVersionId && !!clientId) {
      fetchConfigurations({ investmentServiceVersionId: investmentServiceVersionId, clientId });
      fetchStrategicAllocations({ investmentServiceVersionId: investmentServiceVersionId, clientId });
      fetchDefaultSecurities({ investmentServiceVersionId: investmentServiceVersionId, clientId });
    }
  }, [
    fetchConfigurations,
    fetchStrategicAllocations,
    fetchDefaultSecurities,
    investmentServiceVersionId,
    investmentService,
    clientId,
  ]);

  useEffect(() => {
    setInvestmentServiceVersionId(currentInvestmentVersionId);
  }, [currentInvestmentVersionId]);

  return (
    <>
      <Typography variant="h3" style={{ paddingBottom: '20px' }}>
        Portfolio Targets
      </Typography>
      <Typography variant="h5" style={{ paddingBottom: '30px' }}>
        View and update portfolio composition and strategic asset allocation targets.
      </Typography>

      <Paper elevation={0} style={{ paddingBottom: '10px' }}>
        <ConfigurationTable
          items={configurations}
          onSave={async (configurationsToSave: SaveConfigurationsItem[]) => {
            if (!!clientId && !!currentInvestmentVersionId) {
              await saveConfigurations({
                investmentServiceVersionId: currentInvestmentVersionId,
                clientId,
                configurations: configurationsToSave,
              }).then((payload) => {
                if ((payload.payload as { message: string; variant?: string }).variant === 'error') {
                  throw payload.payload;
                }
                return payload;
              });
            }
          }}
          itemLoadingProgress={loadingConfigurationsProgress}
          cashAccountProduct={cashAccountProduct}
          savingProgress={savingConfigurationsProgress}
          clientSubTypeId={2}
          afslId={afslId}
        />
      </Paper>

      <Paper elevation={0} style={{ paddingBottom: '10px' }}>
        <StrategicAllocationTable
          items={strategicAllocations}
          onSave={async (strategicAllocationItems: SaveStrategicAllocationItem[]) => {
            if (!!clientId && !!currentInvestmentVersionId) {
              await saveStrategicAllocations({
                investmentServiceVersionId: currentInvestmentVersionId,
                clientId,
                strategicAssetAllocations: strategicAllocationItems,
              });
            }
          }}
          itemLoadingProgress={loadingStrategicAllocationsProgress}
          savingProgress={savingStrategicAllocationsProgress}
        />
      </Paper>

      <Paper elevation={0} style={{ paddingBottom: '10px' }}>
        <DefaultSecuritiesTable
          items={defaultSecurities}
          onSave={async (defaultSecurtyItems: CreateDefaultSecurity[]) => {
            if (!!clientId && !!currentInvestmentVersionId) {
              await saveDefaultSecurities({
                investmentServiceVersionId: currentInvestmentVersionId,
                clientId,
                defaultSecurities: defaultSecurtyItems,
              });
            }
          }}
          itemLoadingProgress={loadingStrategicAllocationsProgress}
          savingProgress={savingStrategicAllocationsProgress}
          afslId={afslId}
        />
      </Paper>
    </>
  );
};
