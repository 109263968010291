import { SvgIconComponent } from '@mui/icons-material';
import SaveIcon from '@mui/icons-material/SaveOutlined';
import LoadingButton from '@mui/lab/LoadingButton';
import { ButtonProps } from '@mui/material';
import React, { ReactNode } from 'react';
import { LoadingProgress } from '../store/types/loadingProgress';

export interface ProgressButtonProps extends ButtonProps {
  progress?: LoadingProgress;
  startIcon?: SvgIconComponent;
  children?: ReactNode;
  hideicon?: boolean;
  'data-testid'?: string;
}

export const ProgressButton = (props: ProgressButtonProps): JSX.Element => {
  const { progress, children, hideicon, startIcon, ...rest } = props;

  return (
    <LoadingButton
      loading={progress?.isLoading || false}
      loadingPosition={!hideicon ? 'start' : undefined}
      startIcon={startIcon && props.startIcon ? <props.startIcon></props.startIcon> : !!hideicon ? null : <SaveIcon></SaveIcon>}
      type={props.type}
      value={props.value}
      id={props.id}
      variant={props.variant}
      onClick={props.onClick}
      disabled={props.disabled}
      data-testid={props['data-testid']}
      {...rest}
    >
      {children}
    </LoadingButton>
  );
};
