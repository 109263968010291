import { Enumeration } from './enumeration';

export class PensionType extends Enumeration {
  static AccountBasedPension = new PensionType(1, 'AccountBasedPension', 'Account Based Pension');
  static TransitionRetirementPension = new PensionType(2, 'TransitionRetirementPension', 'Transition Retirement Pension');

  static getById = (subTypeId: number | null): PensionType | undefined => {
    return Object.values(PensionType)
      .filter((t) => t instanceof PensionType)
      .find((t: PensionType) => t.id === subTypeId);
  };

  static getArray = (): PensionType[] => [PensionType.AccountBasedPension, PensionType.TransitionRetirementPension];
}
