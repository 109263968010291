import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../../app/api';
import { convertToCSVFormat, CSVDataType, downloadCsv } from '../../../../common';
import { PagedResult } from '../../../../store';
import { DownloadSecuritiesPayload, SecurityItem, SecurityListParameters } from './types';

export enum SecurityListActionTypes {
  FetchSecuritylList = '@@security/list/FetchSecurityList',
  DownloadSecurities = '@@security/list/DownloadSecurities',
}

export enum SecurityListApiEndpoints {
  fetchSecurityList = '/securities/GetSecurityList',
}

const getSecurityList = async (request: SecurityListParameters) => {
  const body = {
    codeOrNameSearch: request.codeOrNameSearch,
    pagedRequest: request.pagination,
  };
  return await api.post<PagedResult<SecurityItem>>(`${SecurityListApiEndpoints.fetchSecurityList}`, body);
};

export const fetchSecurityList = createAsyncThunk(SecurityListActionTypes.FetchSecuritylList, async (wrapper: SecurityListParameters) => {
  const response = await getSecurityList(wrapper);
  return {
    results: response.data,
    pagination: wrapper.pagination,
  };
});

export const downloadSecurities = createAsyncThunk(SecurityListActionTypes.DownloadSecurities, async (wrapper: DownloadSecuritiesPayload) => {
  const response = await getSecurityList({ ...wrapper, pagination: { ...wrapper.pagination, pageNumber: 1, pageSize: 9999999 } });
  const data: CSVDataType[] = convertToCSVFormat(response.data.results);

  downloadCsv(data, `Securities_`);
  return { message: 'CSV downloaded' };
});
