import { createSelector } from '@reduxjs/toolkit';
import { selectSuperSimplifierDetails } from '../../store/selectors';

export const selectInsuranceDetail = createSelector(selectSuperSimplifierDetails, (superSimplfierDetails) => superSimplfierDetails.insuranceDetail);

export const selectDeathAndTpdInsurance = createSelector(selectInsuranceDetail, (insuranceDetail) => {
  return insuranceDetail.deathAndTpdInsurance;
});
export const selectIncomeProtectionInsurance = createSelector(selectInsuranceDetail, (insuranceDetail) => insuranceDetail.incomeProtectionInsurance);
export const selectPremiumDetails = createSelector(selectInsuranceDetail, (insuranceDetail) => insuranceDetail.premiumDetails);

export const selectFetchMetLifeToken = createSelector(selectInsuranceDetail, (insuranceDetail) => insuranceDetail.secureMetApplyToken);

export const selectParameters = createSelector(selectInsuranceDetail, (insuranceDetail) => insuranceDetail.parameters);
export const selectAuthorisedContact = createSelector(selectInsuranceDetail, (insuranceDetail) => insuranceDetail.authorisedContact.name);
