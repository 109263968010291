import { connect, ConnectedProps } from 'react-redux';
import { progressSelectorFactory } from '../../../../common';
import { RootState } from '../../../../reducers';
import { selectDailySecurityPricesChart, selectParameters } from '../store/selectors';
import { securityDashboardSlice } from '../store/slice';
import { fetchDailySecurityPricesChart, SecurityDahboardActionTypes } from '../store/thunks';
import { MonthlyPerformanceChartComponent } from './MonthlyPerformanceChartComponent';

const mapStateToProps = (state: RootState) => ({
  parameters: selectParameters(state),
  dailySecurityPricesChart: selectDailySecurityPricesChart(state),
  dashoardChartLoading: progressSelectorFactory([SecurityDahboardActionTypes.FetchDailySecurityPricesChart])(state),
});

const mapDispatchToProps = {
  fetchDailySecurityPricesChart,
  setChartDateParameter: securityDashboardSlice.actions.setChartDateParameter,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector>;

export const MonthlyPerformanceChartContainer = connect(mapStateToProps, mapDispatchToProps)(MonthlyPerformanceChartComponent);
