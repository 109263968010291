import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import FileSaver from 'file-saver';
import { AttachableContact, ContactRoles } from 'src/features/clients/common/types';
import { FetchPagedResults, PagedResult, RootState } from 'src/store';
import api from '../../../../../../app/api';
import { buildEncodedQueryString, extractFilenameFromContentDisposition } from '../../../../../../common';
import { selectClientId } from '../../../common/store/selectors';
import {
  AddressDetails,
  AttachContactPayload,
  ContactDetail,
  ContactDetails,
  DeleteAddressPayload as DeleteContactAddressPayload,
  DeleteContactDocumentPayload,
  DeleteEmailAddressPayload as DeleteContactEmailAddressPayload,
  DeletePhoneNumberPayload as DeleteContactPhoneNumberPayload,
  DetachContactPayload,
  DocumentDetails,
  FetchContactDocumentPayload,
  FetchContactDocumentsPayload,
  FetchContactPayload,
  FetchContactRolesRequestModel,
  FetchContactsToAttachPayload,
  SaveAddressPayload as SaveContactAddressPayload,
  SaveContactDocumentPayload,
  SaveContactPayload,
  SaveEmailAddressPayload as SaveContactEmailAddressPayload,
  SavePhoneNumberPayload as SaveContactPhoneNumberPayload,
  UpdateContactDetail,
  UpdateContactRolesPayload,
} from '../../../common/store/types';
import {
  cancelContactDocumentAddEditMode,
  cancelContactIdentificationAddEditMode,
  cancelDocumentAddEditMode,
  setContactEmailAddressEditId,
  setContactPhoneNumberEditId,
  setEmailAddressEditId,
  setPhoneNumberEditId,
} from './actions';
import { selectTrusteeId } from './selectors';
import {
  AttachableTrustee,
  AttachTrusteePayload,
  DeleteAddressPayload,
  DeleteDocumentPayload,
  DeleteEmailAddressPayload,
  DeletePhoneNumberPayload,
  DetachTrusteePayload,
  DownloadDocumentPayload,
  FetchDocumentPayload,
  FetchDocumentsPayload,
  FetchTrusteePayload,
  SaveAddressPayload,
  SaveDocumentPayload,
  SaveEmailAddressPayload,
  SavePhoneNumberPayload,
  TrusteeAbbreviated,
  TrusteeDetails,
  TrusteesToAttachPayload,
  UpdateContactEmailDetailPayload,
  UpdateTrusteePayload,
} from './types';

export enum TrusteesActionTypes {
  FetchTrustees = '@@client/details/trustees/FetchTrustees',
  FetchTrustee = '@@client/details/trustees/FetchTrustee',
  UpdateTrustee = '@@client/details/trustees/UpdateTrustee',
  DetachTrustee = '@@client/details/trustees/DetachTrustee',
  FetchTrusteesToAttach = '@@client/details/trustees/FetchTrusteesToAttach',
  AttachTrustee = '@@client/details/trustees/AttachTrustee',

  FetchAddresses = '@@client/details/trustee/addresses/Fetch',
  UpdateAddress = '@@client/details/trustee/addresses/Update',
  DeleteAddress = '@@client/details/trustee/addresses/Delete',

  FetchDocuments = '@@client/details/trustee/documents/Fetch',
  FetchDocument = '@@client/details/trustee/documents/FetchSingle',
  DownloadDocument = '@@client/details/trustee/documents/Download',
  DeleteDocument = '@@client/details/trustee/documents/Delete',
  SaveDocument = '@@client/details/trustee/documents/Save',

  FetchContactDetails = '@@client/details/trustee/FetchContactDetails',
  SavePhoneNumber = '@@client/details/trustee/savePhoneNumber',
  DeletePhoneNumber = '@@client/details/trustee/deletePhoneNumber',
  SaveEmailAddress = '@@client/details/trustee/saveEmailAddresses',
  DeleteEmailAddress = '@@client/details/trustee/deleteEmailAddresses',

  // contacts
  FetchContacts = '@@client/details/trustee/contact/FetchContacts',
  FetchContact = '@@client/details/trustee/contact/FetchContact',
  CreateContact = '@@client/details/trustee/contact/CreateContact',
  UpdateContact = '@@client/details/trustee/contact/UpdateContact',
  FetchContactsToAttach = '@@client/details/contact/trustee/FetchContactsToAttach',
  AttachContact = '@@client/details/trustee/contact/AttachContact',
  UpdateContactRoles = '@@client/details/trustee/contact/UpdateContactRoles',
  DetachContact = '@@client/details/trustee/contact/DetachContact',

  FetchContactAddresses = '@@client/details/trustee/contacts/addresses/Fetch',
  UpdateContactAddress = '@@client/details/trustee/contacts/addresses/Update',
  DeleteContactAddress = '@@client/details/trustee/contacts/addresses/Delete',

  FetchContactDocuments = '@@client/details/trustee/contact/documents/Fetch',
  FetchContactDocument = '@@client/details/trustee/contact/documents/FetchSingle',
  DownloadContactDocument = '@@client/details/trustee/contact/documents/Download',
  DeleteContactDocument = '@@client/details/trustee/contact/documents/Delete',
  SaveContactDocument = '@@client/details/trustee/contact/documents/Save',

  FetchContactIdentifications = '@@client/details/trustee/contacts/identifications/Fetch',
  FetchContactIdentification = '@@client/details/trustee/contacts/identifications/FetchSingle',
  DownloadContactIdentification = '@@client/details/trustee/contacts/identifications/DownloadD',

  FetchContactContactDetails = '@@client/details/trustee/contacts/FetchContactDetails',
  SaveContactPhoneNumber = '@@client/details/trustee/contacts/savePhoneNumber',
  DeleteContactPhoneNumber = '@@client/details/trustee/contacts/deletePhoneNumber',
  SaveContactEmailAddress = '@@client/details/trustee/contacts/saveEmailAddresses',
  DeleteContactEmailAddress = '@@client/details/trustee/contacts/deleteEmailAddresses',
}

export enum TrusteesApiEndpoints {
  FetchTrustees = '/customers/GetClientCorporateTrustees',
  FetchTrustee = '/customers/GetCorporation',
  UpdateTrustee = '/customers/UpdateCorporation',
  CreateTrustee = '/customers/CreateCorporation',
  DetachTrustee = '/customers/DetachCorporateTrustee',
  FetchTrusteesToAttach = '/customers/GetAttachableCorporateTrustees',
  AttachTrustee = '/customers/AttachCorporateTrusteeToClient',

  FetchAddresses = 'customers/GetCorporationAddresses',
  UpdateAddress = 'customers/UpdateAddressForCorporation',
  CreateAddress = 'customers/CreateAddressForCorporation',
  DeleteAddress = 'customers/DeleteAddressForCorporation',

  FetchDocuments = 'bff/GetCorporationAttachments',
  FetchDocument = 'documents/GetClientAttachment',
  DownloadDocument = 'documents/Download',
  DeleteDocument = 'bff/DeleteCorporationAttachment',
  UpdateDocument = 'bff/UpdateCorporationAttachment',
  CreateDocument = 'bff/CreateCorporationAttachment',

  FetchContactDetails = 'customers/GetCorporationContactDetails',
  CreateContactDetails = 'customers/CreateCorporationContactDetails',
  UpdateContactDetails = 'customers/UpdateCorporationContactDetails',
  DeleteContactDetails = 'customers/DeleteCorporationContactDetails',

  // contacts
  FetchContacts = 'customers/GetCorporationContacts',
  FetchContact = 'customers/GetContactForEntity',
  FetchContactRoles = 'customers/GetCorporationContact',
  UpdateContact = 'customers/UpdateCorporationContact',
  CreateContact = 'customers/CreateCorporationContact',
  FetchContactsToAttach = 'customers/GetAttachableContactsForCorporation',
  AttachContact = 'customers/AttachContactToCorporation',
  UpdateContactRoles = 'customers/UpdateContactToCorporationLinks',
  DetachContact = 'customers/DetachContactFromCorporation',

  FetchContactAddresses = 'customers/GetContactAddresses',
  UpdateContactAddress = 'customers/UpdateAddressForContact',
  CreateContactAddress = 'customers/CreateAddressForContact',
  DeleteContactAddress = 'customers/DeleteAddressForContact',

  FetchContactDocuments = 'documents/GetContactAttachments',
  FetchContactDocument = 'documents/GetContactAttachment',
  DownloadContactDocument = 'documents/Download',
  DeleteContactDocument = 'documents/DeleteContactAttachment',
  UpdateContactDocument = 'documents/UpdateContactAttachment',
  CreateContactDocument = 'documents/CreateContactAttachment',

  FetchContactIdentifications = 'documents/GetContactAttachments',
  FetchContactIdentification = 'documents/GetContactAttachment',
  DownloadContactIdentification = 'documents/Download',

  FetchContactContactDetails = 'customers/GetContactDetails',
  CreateContactContactDetails = 'customers/CreateContactDetails',
  UpdateContactContactDetails = 'customers/UpdateContactDetails',
  DeleteContactContactDetails = 'customers/DeleteContactDetails',
}

export const fetchTrustees = createAsyncThunk(TrusteesActionTypes.FetchTrustees, async (clientId: number) => {
  const queryString = buildEncodedQueryString({
    clientId,
  });
  const response = await api.get<TrusteeAbbreviated[]>(`${TrusteesApiEndpoints.FetchTrustees}${queryString}`);
  return response.data;
});

export const fetchTrustee = createAsyncThunk<{ id: number; details: TrusteeDetails }, FetchTrusteePayload>(
  TrusteesActionTypes.FetchTrustee,
  async (payload: FetchTrusteePayload) => {
    const queryString = buildEncodedQueryString({
      corporationId: payload.trusteeId,
    });
    const details = await api.get<TrusteeDetails>(`${TrusteesApiEndpoints.FetchTrustee}${queryString}`);
    return { id: payload.trusteeId, details: details.data };
  }
);

export const saveTrustee = createAsyncThunk(TrusteesActionTypes.UpdateTrustee, async (payload: UpdateTrusteePayload) => {
  const apiPayload = { clientId: payload.clientId, corporationId: payload.id, ...payload.trustee };
  if (!!apiPayload.corporationId) {
    return api
      .post(`${TrusteesApiEndpoints.UpdateTrustee}`, apiPayload)
      .then(() => {
        return { message: 'Trustee saved', trusteeId: apiPayload.corporationId, trustee: payload.trustee };
      })
      .catch(() => {
        return { message: 'Update trustee failed', variant: 'error' };
      });
  } else {
    return api
      .post(`${TrusteesApiEndpoints.CreateTrustee}`, apiPayload)
      .then((response) => {
        return { message: 'Trustee created', trusteeId: response.data.corporationId, trustee: payload.trustee };
      })
      .catch(() => {
        return { message: 'Create trustee failed', variant: 'error' };
      });
  }
});

export const detachTrustee = createAsyncThunk(TrusteesActionTypes.DetachTrustee, async (payload: DetachTrusteePayload, thunkApi) => {
  const apiPayload = {
    corporationId: payload.trusteeId,
    clientId: payload.clientId,
  };
  await api.post(`${TrusteesApiEndpoints.DetachTrustee}`, apiPayload);

  thunkApi.dispatch(fetchTrustees(apiPayload.clientId));
  return { message: 'Trustee detached' };
});

export const fetchTrusteesToAttach = createAsyncThunk(TrusteesActionTypes.FetchTrusteesToAttach, async (payload: TrusteesToAttachPayload) => {
  const queryString = buildEncodedQueryString({ clientId: payload.clientId, searchText: payload.searchText });
  const response = await api.get<AttachableTrustee[]>(`${TrusteesApiEndpoints.FetchTrusteesToAttach}${queryString}`);
  return response.data;
});

export const attachTrustee = createAsyncThunk(TrusteesActionTypes.AttachTrustee, async (payload: AttachTrusteePayload, thunkApi) => {
  const apiPayload = {
    corporationId: payload.trusteeId,
    clientId: payload.clientId,
  };

  await api.post(`${TrusteesApiEndpoints.AttachTrustee}`, apiPayload);

  thunkApi.dispatch(fetchTrustees(apiPayload.clientId));
  return { message: 'Trustee attached' };
});

//----Addresses----------------------
export const fetchAddresses = createAsyncThunk(TrusteesActionTypes.FetchAddresses, async (trusteeId: number) => {
  const queryString = buildEncodedQueryString({
    corporationId: trusteeId,
  });
  const response = await api.get<AddressDetails[]>(`${TrusteesApiEndpoints.FetchAddresses}${queryString}`);
  return response.data;
});

export const saveAddress = createAsyncThunk(TrusteesActionTypes.UpdateAddress, async (payload: SaveAddressPayload, thunkApi) => {
  const apiPayload = { ...payload.address, corporationId: payload.trusteeId };

  await api.post(!!payload.address.addressId ? TrusteesApiEndpoints.UpdateAddress : TrusteesApiEndpoints.CreateAddress, apiPayload);
  thunkApi.dispatch(fetchAddresses(payload.trusteeId));
  return { message: !!payload.address.addressId ? 'Address saved' : 'Address added' };
});

export const deleteAddress = createAsyncThunk(TrusteesActionTypes.DeleteAddress, async (payload: DeleteAddressPayload, thunkApi) => {
  const apiPayload = { ...payload, corporationId: payload.trusteeId };

  await api.post(`${TrusteesApiEndpoints.DeleteAddress}`, apiPayload);
  thunkApi.dispatch(fetchAddresses(payload.trusteeId));
  return { message: 'Address deleted' };
});

//----Documents----------------------
export const fetchDocuments = createAsyncThunk(TrusteesActionTypes.FetchDocuments, async (wrapper: FetchDocumentsPayload) => {
  const body = {
    clientId: wrapper.clientId,
    corporationId: wrapper.trusteeId,
    filter: 'documents',
    pagedRequest: wrapper.parameters.pagination,
  };

  const response = await api.post<PagedResult<DocumentDetails>>(`${TrusteesApiEndpoints.FetchDocuments}`, body);

  return {
    results: response.data,
    pagination: wrapper.parameters.pagination,
  } as FetchPagedResults<DocumentDetails>;
});

export const fetchDocumentForEdit = createAsyncThunk(TrusteesActionTypes.FetchDocument, async (wrapper: FetchDocumentPayload) => {
  const queryString = buildEncodedQueryString({
    entityCoreId: wrapper.trusteeId,
    attachmentId: wrapper.documentId,
  });

  return (await api.get<DocumentDetails>(`${TrusteesApiEndpoints.FetchDocument}${queryString}`)).data;
});

export const downloadDocument = createAsyncThunk(TrusteesActionTypes.DownloadDocument, async (wrapper: DownloadDocumentPayload) => {
  const queryString = buildEncodedQueryString({
    clientId: wrapper.clientId,
    attachmentId: wrapper.attachmentId,
  });

  await api
    .get(`${TrusteesApiEndpoints.DownloadDocument}${queryString}`, {
      responseType: 'blob',
    })
    .then((response: AxiosResponse) => {
      const fileName = extractFilenameFromContentDisposition(response.headers['content-disposition']) || wrapper.filename;
      FileSaver.saveAs(new Blob([response.data]), fileName);
    });
});

export const deleteDocument = createAsyncThunk(TrusteesActionTypes.DeleteDocument, async (payload: DeleteDocumentPayload, thunkApi) => {
  const apiPayload = { attachmentId: payload.attachmentId, corporationId: payload.trusteeId };

  await api.post(TrusteesApiEndpoints.DeleteDocument, apiPayload);

  thunkApi.dispatch(fetchDocuments(payload.fetchPayload));

  return { message: 'Document deleted' };
});

export const saveDocument = createAsyncThunk(TrusteesActionTypes.SaveDocument, async (payload: SaveDocumentPayload, thunkApi) => {
  await api.post(!!payload.document.id ? TrusteesApiEndpoints.UpdateDocument : TrusteesApiEndpoints.CreateDocument, {
    ...payload.document,
    attachmentId: payload.document.id,
    clientId: payload.clientId,
    corporationId: payload.fetchPayload.trusteeId,
    attachmentTypeId: payload.document.typeId,
  });
  thunkApi.dispatch(fetchDocuments(payload.fetchPayload));
  thunkApi.dispatch(cancelDocumentAddEditMode());

  return { message: !!payload.document.id ? 'Document saved' : 'Document added' };
});

//----contact details----------------------
export const fetchContactDetails = createAsyncThunk(TrusteesActionTypes.FetchContactDetails, async (corporationId: number) => {
  const queryString = buildEncodedQueryString({
    corporationId,
  });
  const response = await api.get<ContactDetail[]>(`${TrusteesApiEndpoints.FetchContactDetails}${queryString}`);
  return response.data;
});

export const savePhoneNumber = createAsyncThunk(TrusteesActionTypes.SavePhoneNumber, async (payload: SavePhoneNumberPayload, thunkApi) => {
  const body: UpdateContactEmailDetailPayload = {
    contactDetailId: payload.phoneNumber.id,
    corporationId: payload.trusteeId,
    areaCode: payload.phoneNumber.areaCode,
    countryCode: payload.phoneNumber.countryCode,
    phoneEmail: payload.phoneNumber.phoneNumber,
    contactDetailTypeId: payload.phoneNumber.typeId,
    preferred: payload.phoneNumber.preferred,
    clientPortalAccess: false,
    hasMoneysoftAccess: false,
  };

  if (!!body.contactDetailId) {
    await api.post<PagedResult<ContactDetail>>(`${TrusteesApiEndpoints.UpdateContactDetails}`, body);
  } else {
    await api.post<PagedResult<ContactDetail>>(`${TrusteesApiEndpoints.CreateContactDetails}`, body);
  }

  thunkApi.dispatch(fetchContactDetails(payload.trusteeId));
  thunkApi.dispatch(setPhoneNumberEditId(undefined));
  return { message: !!body.contactDetailId ? 'Phone number saved' : 'Phone number added' };
});

export const deletePhoneNumber = createAsyncThunk(TrusteesActionTypes.DeletePhoneNumber, async (payload: DeletePhoneNumberPayload, thunkApi) => {
  const apiPayload = { contactDetailId: payload.phoneNumber, corporationId: payload.trusteeId };

  await api.post<PagedResult<DocumentDetails>>(`${TrusteesApiEndpoints.DeleteContactDetails}`, apiPayload);
  thunkApi.dispatch(fetchContactDetails(apiPayload.corporationId));
  return { message: `Phone number deleted` };
});

export const saveEmailAddress = createAsyncThunk(TrusteesActionTypes.SaveEmailAddress, async (payload: SaveEmailAddressPayload, thunkApi) => {
  const body: UpdateContactEmailDetailPayload = {
    contactDetailId: payload.emailAddress.id,
    corporationId: payload.trusteeId,
    areaCode: null,
    countryCode: null,
    phoneEmail: payload.emailAddress.emailAddress,
    contactDetailTypeId: payload.emailAddress.typeId,
    preferred: payload.emailAddress.preferred,
    clientPortalAccess: payload.emailAddress.clientPortalAccess,
    hasMoneysoftAccess: payload.emailAddress.hasMoneysoftAccess,
  };

  if (!!body.contactDetailId) {
    await api.post<PagedResult<ContactDetail>>(`${TrusteesApiEndpoints.UpdateContactDetails}`, body);
  } else {
    await api.post<PagedResult<ContactDetail>>(`${TrusteesApiEndpoints.CreateContactDetails}`, body);
  }

  thunkApi.dispatch(fetchContactDetails(payload.trusteeId));
  thunkApi.dispatch(setEmailAddressEditId(undefined));
  return { message: !!payload.emailAddress.id ? 'Email address saved' : 'Email address added' };
});

export const deleteEmailAddress = createAsyncThunk(TrusteesActionTypes.DeleteEmailAddress, async (payload: DeleteEmailAddressPayload, thunkApi) => {
  const apiPayload = { contactDetailId: payload.contactDetailId, corporationId: payload.trusteeId };

  await api.post(`${TrusteesApiEndpoints.DeleteContactDetails}`, apiPayload);
  thunkApi.dispatch(fetchContactDetails(payload.trusteeId));
  return { message: 'Email address deleted' };
});

//----Contacts----------------------
export const fetchContacts = createAsyncThunk(TrusteesActionTypes.FetchContacts, async (corporationId: number) => {
  const queryString = buildEncodedQueryString({
    corporationId,
  });
  const response = await api.get<ContactRoles[]>(`${TrusteesApiEndpoints.FetchContacts}${queryString}`);
  return response.data;
});

export const fetchContact = createAsyncThunk(TrusteesActionTypes.FetchContact, async (params: FetchContactPayload, thunkApi) => {
  const state = thunkApi.getState() as RootState;
  const clientId = selectClientId(state);
  const corporationId = selectTrusteeId(state);

  const fetchContactQueryString = buildEncodedQueryString({
    clientId,
    entityCoreId: params.contactId,
  });
  const contact = await api.get<ContactDetails>(`${TrusteesApiEndpoints.FetchContact}${fetchContactQueryString}`);

  const fetchContactRolesQueryString = buildEncodedQueryString({
    corporationId,
    contactId: params.contactId,
  });
  const roles = await api.get<FetchContactRolesRequestModel>(`${TrusteesApiEndpoints.FetchContactRoles}${fetchContactRolesQueryString}`);

  const contactDetails: ContactDetails = { ...contact.data, isPrimary: roles.data.isPrimary, roles: roles.data.roleMappings };
  return contactDetails;
});

export const saveContact = createAsyncThunk(TrusteesActionTypes.CreateContact, async (payload: SaveContactPayload, thunkApi) => {
  const state = thunkApi.getState() as RootState;
  const corporationId = selectTrusteeId(state);
  const apiPayload = { ...payload, corporationId };

  return api
    .post<{ contactId: number }>(`${TrusteesApiEndpoints.CreateContact}`, apiPayload)
    .then((createContactResponse) => {
      return { message: 'Contact added', contactId: createContactResponse.data.contactId };
    })
    .catch(() => {
      return { message: 'Error adding contact', variant: 'error' };
    });
});

export const updateContact = createAsyncThunk(TrusteesActionTypes.UpdateContact, async (payload: SaveContactPayload, thunkApi) => {
  const state = thunkApi.getState() as RootState;
  const clientId = selectClientId(state);
  const corporationId = selectTrusteeId(state);

  return api
    .post(`${TrusteesApiEndpoints.UpdateContact}`, { corporationId, ...payload, clientId, contactId: payload.id })
    .then(() => {
      return { message: 'Contact updates' };
    })
    .catch(() => {
      return { message: 'Contact update failed', variant: 'error' };
    });
});

export const fetchContactsToAttach = createAsyncThunk(TrusteesActionTypes.FetchContactsToAttach, async (payload: FetchContactsToAttachPayload) => {
  const queryString = buildEncodedQueryString({ corporationId: payload.parentId, searchText: payload.searchText });
  const response = await api.get<AttachableContact[]>(`${TrusteesApiEndpoints.FetchContactsToAttach}${queryString}`);
  return response.data;
});

export const attachContact = createAsyncThunk(TrusteesActionTypes.AttachContact, async (payload: AttachContactPayload, thunkApi) => {
  const apiPayload = {
    corporationId: payload.clientId,
    contactId: payload.contactId,
    roles: payload.roles,
    isPrimary: payload.isPrimary,
  };

  await api.post(`${TrusteesApiEndpoints.AttachContact}`, apiPayload);

  thunkApi.dispatch(fetchContacts(apiPayload.corporationId));
  return { message: 'Contact attached' };
});

export const updateContactRoles = createAsyncThunk(TrusteesActionTypes.UpdateContactRoles, async (payload: UpdateContactRolesPayload, thunkApi) => {
  const apiPayload = {
    corporationId: payload.clientId,
    contactId: payload.contactId,
    roleMappings: payload.roleMappings,
    isPrimary: payload.isPrimary,
  };

  await api.post(`${TrusteesApiEndpoints.UpdateContactRoles}`, apiPayload);
  thunkApi.dispatch(fetchContacts(apiPayload.corporationId));
  return { message: 'Contact saved' };
});

export const detachContact = createAsyncThunk(TrusteesActionTypes.DetachContact, async (payload: DetachContactPayload, thunkApi) => {
  const apiPayload = {
    corporationId: payload.parentId,
    contactId: payload.contactId,
  };
  await api.post(`${TrusteesApiEndpoints.DetachContact}`, apiPayload);

  thunkApi.dispatch(fetchContacts(apiPayload.corporationId));
  return { message: 'Contact detached' };
});

//----Addresses----------------------
export const fetchContactAddresses = createAsyncThunk(TrusteesActionTypes.FetchContactAddresses, async (contactId: number) => {
  const queryString = buildEncodedQueryString({
    contactId,
  });
  const response = await api.get<AddressDetails[]>(`${TrusteesApiEndpoints.FetchContactAddresses}${queryString}`);
  return response.data;
});

export const saveContactAddress = createAsyncThunk(TrusteesActionTypes.UpdateContactAddress, async (payload: SaveContactAddressPayload, thunkApi) => {
  await api.post(!!payload.address.addressId ? TrusteesApiEndpoints.UpdateContactAddress : TrusteesApiEndpoints.CreateContactAddress, {
    ...payload.address,
    contactId: payload.contactId,
  });
  thunkApi.dispatch(fetchContactAddresses(payload.contactId));
  return { message: !!payload.address.addressId ? 'Address saved' : 'Address added' };
});

export const deleteContactAddress = createAsyncThunk(TrusteesActionTypes.DeleteContactAddress, async (payload: DeleteContactAddressPayload, thunkApi) => {
  await api.post(`${TrusteesApiEndpoints.DeleteContactAddress}`, payload);
  thunkApi.dispatch(fetchContactAddresses(payload.contactId));
  return { message: 'Address deleted' };
});

//----Documents----------------------
export const fetchContactDocuments = createAsyncThunk(TrusteesActionTypes.FetchContactDocuments, async (wrapper: FetchContactDocumentsPayload) => {
  const body = {
    clientId: wrapper.clientId,
    contactId: wrapper.contactId,
    filter: 'documents',
    pagedRequest: wrapper.parameters.pagination,
  };

  const response = await api.post<PagedResult<DocumentDetails>>(`${TrusteesApiEndpoints.FetchContactDocuments}`, body);

  return {
    results: response.data,
    pagination: wrapper.parameters.pagination,
  } as FetchPagedResults<DocumentDetails>;
});

export const fetchContactDocumentForEdit = createAsyncThunk(TrusteesActionTypes.FetchContactDocument, async (wrapper: FetchContactDocumentPayload) => {
  const queryString = buildEncodedQueryString({
    entityCoreId: wrapper.contactId,
    attachmentId: wrapper.documentId,
  });

  return (await api.get<DocumentDetails>(`${TrusteesApiEndpoints.FetchContactDocument}${queryString}`)).data;
});

//----Identifications----------------------
export const fetchContactIdentifications = createAsyncThunk(TrusteesActionTypes.FetchContactIdentifications, async (wrapper: FetchContactDocumentsPayload) => {
  const body = {
    clientId: wrapper.clientId,
    contactId: wrapper.contactId,
    filter: 'identification',
    pagedRequest: wrapper.parameters.pagination,
  };

  const response = await api.post<PagedResult<DocumentDetails>>(`${TrusteesApiEndpoints.FetchContactIdentifications}`, body);

  return {
    results: response.data,
    pagination: wrapper.parameters.pagination,
  } as FetchPagedResults<DocumentDetails>;
});

export const fetchContactIdentificationForEdit = createAsyncThunk(
  TrusteesActionTypes.FetchContactIdentification,
  async (wrapper: FetchContactDocumentPayload) => {
    const queryString = buildEncodedQueryString({
      entityCoreId: wrapper.contactId,
      attachmentId: wrapper.documentId,
    });

    return (await api.get<DocumentDetails>(`${TrusteesApiEndpoints.FetchContactIdentification}${queryString}`)).data;
  }
);

/// Common docs/idents -------------------
export const downloadContactDocument = createAsyncThunk(TrusteesActionTypes.DownloadContactDocument, async (wrapper: DownloadDocumentPayload) => {
  const queryString = buildEncodedQueryString({
    clientId: wrapper.clientId,
    attachmentId: wrapper.attachmentId,
  });

  await api
    .get(`${TrusteesApiEndpoints.DownloadContactDocument}${queryString}`, {
      responseType: 'blob',
    })
    .then((response: AxiosResponse) => {
      const fileName = extractFilenameFromContentDisposition(response.headers['content-disposition']) || wrapper.filename;
      FileSaver.saveAs(new Blob([response.data]), fileName);
    });
});

export const deleteContactDocument = createAsyncThunk(TrusteesActionTypes.DeleteContactDocument, async (payload: DeleteContactDocumentPayload, thunkApi) => {
  api.delete(TrusteesApiEndpoints.DeleteContactDocument, { data: payload });

  await thunkApi.dispatch(fetchContactDocuments(payload.fetchPayload));

  return { message: 'Document deleted' };
});

export const saveContactDocument = createAsyncThunk(TrusteesActionTypes.SaveContactDocument, async (payload: SaveContactDocumentPayload, thunkApi) => {
  await api.post(!!payload.document.id ? TrusteesApiEndpoints.UpdateContactDocument : TrusteesApiEndpoints.CreateContactDocument, {
    ...payload.document,
    clientId: payload.clientId,
    contactId: payload.fetchPayload.contactId,
  });
  thunkApi.dispatch(fetchContactDocuments(payload.fetchPayload));
  thunkApi.dispatch(cancelContactDocumentAddEditMode());
  thunkApi.dispatch(cancelContactIdentificationAddEditMode());

  return { message: !!payload.document.id ? 'Document saved' : 'Document added' };
});

//----contact details----------------------
export const fetchContactContactDetails = createAsyncThunk(TrusteesActionTypes.FetchContactContactDetails, async (contactId: number) => {
  const queryString = buildEncodedQueryString({
    contactId,
  });
  const response = await api.get<ContactDetail[]>(`${TrusteesApiEndpoints.FetchContactContactDetails}${queryString}`);
  return response.data;
});

export const saveContactPhoneNumber = createAsyncThunk(TrusteesActionTypes.SaveContactPhoneNumber, async (payload: SaveContactPhoneNumberPayload, thunkApi) => {
  const body: UpdateContactDetail = {
    contactDetailId: payload.phoneNumber.id,
    contactId: payload.contactId,
    areaCode: payload.phoneNumber.areaCode,
    countryCode: payload.phoneNumber.countryCode,
    phoneEmail: payload.phoneNumber.phoneNumber,
    contactDetailTypeId: payload.phoneNumber.typeId,
    preferred: payload.phoneNumber.preferred,
  };

  if (!!body.contactDetailId) {
    await api.post<PagedResult<ContactDetail>>(`${TrusteesApiEndpoints.UpdateContactContactDetails}`, body);
  } else {
    await api.post<PagedResult<ContactDetail>>(`${TrusteesApiEndpoints.CreateContactContactDetails}`, body);
  }

  thunkApi.dispatch(fetchContactContactDetails(payload.contactId));
  thunkApi.dispatch(setContactPhoneNumberEditId(undefined));
  return { message: !!body.contactDetailId ? 'Phone number saved' : 'Phone number added' };
});

export const deleteContactPhoneNumber = createAsyncThunk(
  TrusteesActionTypes.DeleteContactPhoneNumber,
  async (wrapper: DeleteContactPhoneNumberPayload, thunkApi) => {
    const body = wrapper;
    await api.post<PagedResult<DocumentDetails>>(`${TrusteesApiEndpoints.DeleteContactContactDetails}`, body);
    thunkApi.dispatch(fetchContactContactDetails(wrapper.contactId));
    return { message: `Phone number deleted` };
  }
);

export const saveContactEmailAddress = createAsyncThunk(
  TrusteesActionTypes.SaveContactPhoneNumber,
  async (payload: SaveContactEmailAddressPayload, thunkApi) => {
    const body: UpdateContactEmailDetailPayload = {
      contactDetailId: payload.emailAddress.id,
      corporationId: payload.contactId,
      areaCode: null,
      countryCode: null,
      phoneEmail: payload.emailAddress.emailAddress,
      contactDetailTypeId: payload.emailAddress.typeId,
      preferred: payload.emailAddress.preferred,
      clientPortalAccess: payload.emailAddress.clientPortalAccess,
      hasMoneysoftAccess: payload.emailAddress.hasMoneysoftAccess,
    };

    if (!!body.contactDetailId) {
      await api.post<PagedResult<ContactDetail>>(`${TrusteesApiEndpoints.UpdateContactContactDetails}`, body);
    } else {
      await api.post<PagedResult<ContactDetail>>(`${TrusteesApiEndpoints.CreateContactContactDetails}`, body);
    }

    thunkApi.dispatch(fetchContactContactDetails(payload.contactId));
    thunkApi.dispatch(setContactEmailAddressEditId(undefined));
    return { message: !!payload.emailAddress.id ? 'Email address saved' : 'Email address added' };
  }
);

export const deleteContactEmailAddress = createAsyncThunk(
  TrusteesActionTypes.DeleteContactEmailAddress,
  async (wrapper: DeleteContactEmailAddressPayload, thunkApi) => {
    const body = wrapper;
    await api.post(`${TrusteesApiEndpoints.DeleteContactContactDetails}`, body);
    thunkApi.dispatch(fetchContactContactDetails(wrapper.contactId));
    return { message: 'Email address deleted' };
  }
);
