import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'src/app/api';
import { buildEncodedQueryString } from 'src/common';
import { CarriedForwardLossesToUpdate } from '../carriedForwardLosses/store/types';
import { CGTFinancialYearRecord } from './types';

export enum CGTConfigurationActionTypes {
  FetchFinancialRecordsForClient = '@@client/capitalGainsAndLosses/FinancialRecords/Fetch',
  UpdateExternalCarriedForwardLosses = '@@client/capitalGainsAndLosses/FinancialRecords/Update',
}

export enum CGTConfigurationApiEndpoints {
  FetchFinancialRecordsForClient = '/customers/GetCGTRecords',
  UpdateExternalCarriedForwardLosses = '/customers/UpdateExternalCarriedForwardLoss',
}

export const fetchFinancialRecordsForClient = createAsyncThunk(
  CGTConfigurationActionTypes.FetchFinancialRecordsForClient,
  async (clientId: number) => {
    const queryString = buildEncodedQueryString({
      clientId,
    });
    const response = await api.get<CGTFinancialYearRecord[]>(
      `${CGTConfigurationApiEndpoints.FetchFinancialRecordsForClient}${queryString}`
    );
    return response.data;
  }
);

export const updateExternalCarriedForwardLosses = createAsyncThunk(
  CGTConfigurationActionTypes.UpdateExternalCarriedForwardLosses,
  async (payload: CarriedForwardLossesToUpdate) => {
    await api.post<CarriedForwardLossesToUpdate>(
      `${CGTConfigurationApiEndpoints.UpdateExternalCarriedForwardLosses}`,
      payload
    );
  }
);
