import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { Component, default as React } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { formatDollars, hasErrorsSelector, progressSelectorFactory } from '../../../../../common';
import CardComponent from '../../../../../common/components/CardComponent';
import { RootState } from '../../../../../reducers';
import { theme } from '../../../../../themes';
import { selectHoldings } from '../store/selectors';
import { DashboardActionTypes } from '../store/thunks';

class PortfolioValueCard extends Component<Props> {
  render() {
    return (
      <CardComponent
        progress={this.props.progress}
        background={theme.palette.gradient1?.main}
        value={formatDollars(this.props.holdings?.marketValue)}
        subText="TOTAL VALUE"
        icon={AccountBalanceIcon}
      />
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  holdings: selectHoldings(state),
  hasErrors: hasErrorsSelector(state, [DashboardActionTypes.FetchClientPortfolioDetails]),
  progress: progressSelectorFactory([DashboardActionTypes.FetchClientPortfolioDetails])(state),
});

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

export default connector(PortfolioValueCard);
