import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'src/app/api';
import { buildEncodedQueryString } from 'src/common';
import { ResultWrapper } from '../../benefitStatement/store/types';
import { AccountClient, Beneficiary, PendingBeneficiariesPayload } from './types';

export enum BeneficiariesActionTypes {
  FetchBeneficiaries = '@@client/details/superSimplifier/beneficiaries/FetchBeneficiaries',
  FetchAccountClients = '@@client/details/superSimplifier/beneficiaries/FetchAccountClients',
  CreateOrUpdateBeneficiaries = '@@client/details/superSimplifier/beneficiaries/CreateOrUpdateBeneficiaries'
}

export enum BeneficiariesApiEndpoints {
  FetchBeneficiaries = 'ssc/GetBeneficiaries',
  FetchAccountClients = 'ssc/GetAccountClients',
  CreateOrUpdateBeneficiaries = 'ssc/CreateOrUpdateBeneficiaries'
}

export const fetchBeneficiaries = createAsyncThunk(
  BeneficiariesActionTypes.FetchBeneficiaries,
  async (accountId: string) => {
    const queryString = buildEncodedQueryString({
      superMemberNumber: accountId,
    });
    const result = await api
      .get<Beneficiary[]>(`${BeneficiariesApiEndpoints.FetchBeneficiaries}${queryString}`)
      .then((data) => {
        const response: ResultWrapper<Beneficiary[]> = {
          responseData: data.data,
          status: data.status,
          statusText: data.statusText,
        };
        return response;
      })
      .catch((error) => {
        const response: ResultWrapper<null> = {
          responseData: null,
          status: error.status,
          statusText: error.statusText,
        };
        return response;
      });
    return result;
  }
);

export const fetchAccountClients = createAsyncThunk(
  BeneficiariesActionTypes.FetchAccountClients,
  async (accountId: string) => {
    const queryString = buildEncodedQueryString({
      accountId,
    });
    const result = await api
      .get<AccountClient[]>(`${BeneficiariesApiEndpoints.FetchAccountClients}${queryString}`)
      .then((data) => {
        const response: ResultWrapper<AccountClient[]> = {
          responseData: data.data,
          status: data.status,
          statusText: data.statusText,
        };
        return response;
      })
      .catch((error) => {
        const response: ResultWrapper<null> = {
          responseData: null,
          status: error.status,
          statusText: error.statusText,
        };
        return response;
      });
    return result;
  }
);

export const createOrUpdateBeneficiaries = createAsyncThunk(
  BeneficiariesActionTypes.CreateOrUpdateBeneficiaries,
  async(payload: PendingBeneficiariesPayload, thunkApi) => {
    return api
      .post(`${BeneficiariesApiEndpoints.CreateOrUpdateBeneficiaries}`, payload)
      .then(async () => {
        await thunkApi.dispatch(fetchBeneficiaries(payload.superMemberNumber));
        return thunkApi.fulfillWithValue({message: `Updated Pending Beneficiaries list for: ${payload.superMemberNumber}`});
      })
      .catch(() => {
        return thunkApi.rejectWithValue({ message: 'Could not save beneficiaries at this time.', variant: 'error' });
      });
  }
);
