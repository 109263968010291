import { Enumeration } from './enumeration';

export class MonthType extends Enumeration {
  static January = new MonthType(0, 'January', 'January');
  static February = new MonthType(1, 'February', 'February');
  static March = new MonthType(2, 'March', 'March');
  static April = new MonthType(3, 'April', 'April');
  static May = new MonthType(4, 'May', 'May');
  static June = new MonthType(5, 'June', 'June');
  static July = new MonthType(6, 'July', 'July');
  static August = new MonthType(7, 'August', 'August');
  static September = new MonthType(8, 'September', 'September');
  static October = new MonthType(9, 'October', 'October');
  static November = new MonthType(10, 'November', 'November');
  static December = new MonthType(11, 'December', 'December');

  static getById = (id: number): MonthType | null => {
    return Object.values(MonthType)
      .filter((t) => t instanceof MonthType)
      .find((t: MonthType) => t.id === id);
  };
}
