import { combineReducers } from '@reduxjs/toolkit';
import { RebalanceState as RebalanceCheckoutState } from 'src/features/orders/rebalance/checkout/store/types';
import { RebalanceState as RebalanceEditState } from 'src/features/orders/rebalance/edit/store/types';
import { rebalanceCheckoutSlice } from '../checkout/store/slice';
import { rebalanceEditSlice } from '../edit/store/slice';

export type OrderRebalanceState = {
  edit: RebalanceEditState;
  checkout: RebalanceCheckoutState;
};

export const rebalanceReducers = combineReducers<OrderRebalanceState>({
  edit: rebalanceEditSlice.reducer,
  checkout: rebalanceCheckoutSlice.reducer,
});
