import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import React from 'react';
import { DateTimeFormat, getLocalDateTime } from '../../../../common';
import { ServerSideDataTable } from '../../../../common/components/dataTable/serverSide';
import { DatatableColumn, FilterDataType } from '../../../../common/components/dataTable/types';
import { LoadingProgress } from '../../../../common/store/types';
import { PagedRequest, PagedResult } from '../../../../store';
import { DividendDistributionsByPaymentDateCsvRow, DividendDistributionsByPaymentDateItem } from '../store/types';

export interface Props {
  pagedResults?: PagedResult<DividendDistributionsByPaymentDateItem>;
  pagedRequest: PagedRequest;
  handlePagedRequest: (pagedRequest: PagedRequest) => void;
  progressPrices: LoadingProgress;
  handleDownload: (
    pagedRequest: PagedRequest,
    mapper: (dividendDistributionsByPaymentDateItems: DividendDistributionsByPaymentDateItem[]) => DividendDistributionsByPaymentDateCsvRow[]
  ) => void;
}

export function IncomeTable(props: Props): JSX.Element {
  const { pagedResults, progressPrices, handleDownload, pagedRequest } = props;

  const columns: DatatableColumn[] = [
    {
      filterDataType: FilterDataType.string,
      name: 'incomeType',
      label: 'INCOME TYPE',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: function customBodyRenderLite(dataIndex): React.ReactNode {
          return <Typography variant="h5">{pagedResults?.results[dataIndex]?.incomeType}</Typography>;
        },
      },
    },
    {
      filterDataType: FilterDataType.string,
      name: 'exDividendDate',
      label: 'EX DATE',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: function customBodyRenderLite(dataIndex): React.ReactNode {
          return <Typography variant="h5">{getLocalDateTime(pagedResults?.results[dataIndex]?.exDividendDate, DateTimeFormat.Short)}</Typography>;
        },
        setCellHeaderProps: () => {
          return {
            style: { textAlign: 'right' },
          };
        },
      },
    },
    {
      filterDataType: FilterDataType.string,
      name: 'paymentDate',
      label: 'PAYMENT DATE',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: function customBodyRenderLite(dataIndex): React.ReactNode {
          return <Typography variant="h5">{getLocalDateTime(pagedResults?.results[dataIndex]?.paymentDate, DateTimeFormat.Short)}</Typography>;
        },
      },
    },
    {
      filterDataType: FilterDataType.string,
      textAlign: 'right',
      name: 'netIncomePerUnit',
      label: 'AMOUNT (CPU)',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: function customBodyRenderLite(dataIndex): React.ReactNode {
          return <Typography variant="h5">{parseFloat((pagedResults?.results[dataIndex]?.netIncomePerUnit ?? 0).toFixed(4) ?? '') ?? ''}</Typography>;
        },
      },
    },
    {
      filterDataType: FilterDataType.string,
      textAlign: 'right',
      name: 'imputationCredits',
      label: 'FRANKING CREDITS (CPU)',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: function customBodyRenderLite(dataIndex): React.ReactNode {
          return <Typography variant="h5">{parseFloat((pagedResults?.results[dataIndex]?.imputationCredits ?? 0).toFixed(4))}</Typography>;
        },
      },
    },
    {
      filterDataType: FilterDataType.string,
      textAlign: 'right',
      name: 'grossIncomePerUnit',
      label: 'GROSS AMOUNT (CPU)',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: function customBodyRenderLite(dataIndex): React.ReactNode {
          return <Typography variant="h5">{parseFloat((pagedResults?.results[dataIndex]?.grossIncomePerUnit ?? 0).toFixed(4))}</Typography>;
        },
      },
    },
  ];

  return (
    <Grid container style={{ margin: '10px' }}>
      <Grid item xs={12}>
        <ServerSideDataTable
          loadingProgress={progressPrices}
          columns={columns}
          pagedRequest={pagedRequest}
          pagedResult={pagedResults}
          options={{}}
          csvDownload={() =>
            handleDownload(pagedRequest, (dividendDistributionsByPaymentDateItems) =>
              dividendDistributionsByPaymentDateItems.map((t) => {
                return {
                  securityCode: t.securityCode,
                  incomeType: t.incomeType,
                  exDividendDate: getLocalDateTime(t.exDividendDate, DateTimeFormat.Short),
                  paymentDate: getLocalDateTime(t.paymentDate, DateTimeFormat.Short),
                  netIncomePerUnit: t.netIncomePerUnit,
                  imputationCredits: t.imputationCredits,
                  grossIncomePerUnit: t.grossIncomePerUnit,
                  recordDate: getLocalDateTime(t.recordDate, DateTimeFormat.Short),
                  balanceDate: getLocalDateTime(t.balanceDate, DateTimeFormat.Short),
                };
              })
            )
          }
          handlePagedRequest={props.handlePagedRequest}
        ></ServerSideDataTable>
      </Grid>
    </Grid>
  );
}
