import { FormControl, InputLabel, MenuItem } from '@mui/material';
import React, { useRef } from 'react';
import { GainType } from '../store/types';
import { WO2Select } from './../../../../../common';

interface Props {
  selectedItem: GainType;
  setSelectedItem: (value: GainType) => void;
}

function GainTypeSelect(props: Props): JSX.Element {
  const inputLabel: React.MutableRefObject<HTMLLabelElement | null> = useRef(null);

  return (
    <FormControl variant="outlined">
      <InputLabel ref={inputLabel} id="enum-select-label">
        Type
      </InputLabel>
      <WO2Select
        id="realised-select"
        value={props.selectedItem}
        label={'Type'}
        onChange={(event) => {
          if (event.target.value) {
            props?.setSelectedItem(event.target.value as GainType);
          }
        }}
      >
        <MenuItem key={1} value={GainType.Unrealised}>
          Unrealised
        </MenuItem>
        <MenuItem key={2} value={GainType.Realised}>
          Realised
        </MenuItem>
      </WO2Select>
    </FormControl>
  );
}

export default GainTypeSelect;
