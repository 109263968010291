import { User, UserSettings } from 'oidc-client';
import queryString from 'query-string';
import { default as React, useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { reducer as oidcReducer } from 'redux-oidc';
import { RootState } from 'src/reducers';
import { userSettingsSlice } from 'src/store/userSettings/slice';
import history from '../history';
import { userManager } from '../userManager';

export const LogIn = (): JSX.Element => {
  const location = useLocation();

  useEffect(() => {
    sessionStorage.clear();
    localStorage.clear();

    const params = queryString.parse(location.search);

    if (
      params &&
      params.accessToken &&
      params.identityToken &&
      params.scope &&
      params.custom &&
      params.accessTokenLifetime
    ) {
      const usersettings: UserSettings = {
        id_token: params.identityToken.toString(),
        session_state: JSON.parse(params.custom.toString()).session_state,
        access_token: params.accessToken.toString(),
        refresh_token: '',
        token_type: 'Bearer',
        scope: params.scope.toString(),
        expires_at: Date.now() / 1000 + parseInt(params.accessTokenLifetime.toString()),
        state: null,
        profile: {
          aud: '',
          exp: 0,
          iat: 0,
          iss: '',
          sub: '',
        },
      };

      userSettingsSlice.actions.setRebalanceFromTriumph(
        params?.isRebalanceDataFromTriumph?.toString().toLowerCase() === 'true' ?? false
      );

      const user = new User(usersettings);
      userManager.storeUser(user).then(() => {
        userManager.events.load(user);
        userManager.getUser().then((user) => {
          if (user && !user.expired) {
            if (params.rebalanceId && params.investmentServiceId) {
              const rebalanceQuery = queryString.stringify({
                investmentServiceId: params.investmentServiceId,
                rebalanceId: params.rebalanceId,
                isRebalanceDataFromTriumph: params.isRebalanceDataFromTriumph,
              });
              history.push(`/rebalance?${rebalanceQuery}`);
            } else if (params.clientId) {
              history.push(`/client/${params.clientId}/dashboard`);
            } else if (params.url) {
              history.push(params.url.toString());
            }
          }
        });
      });
    } else {
      history.push('/404');
    }
  }, [location.search]);

  return <></>;
};

function mapStateToProps(state: RootState) {
  return {
    user: state.oidc.user,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
  return bindActionCreators({ oidcReducer }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(LogIn);
