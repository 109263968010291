import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { roundUnitsByMarketCode } from 'src/common';
import { SimpleSecurityItem } from '../../../common/store/types';
import { ValidateOrderResultPayload } from '../../types';
import { fetchPortfolioSecurityHoldings, validateBulkOrder } from './thunks';
import {
  HoldingAmount,
  OrderEditState,
  PortfolioSecurityHoldingResult,
  TradeAction,
  TradeMethod,
  WeightsType,
} from './types';

export const initialState: OrderEditState = {
  parameters: {
    securitySearch: null,
    security: null,
    tradeAction: TradeAction.Buy.name,
    tradeMethod: TradeMethod.Units.name,
    amount: null,
    priceType: null,
    priceLimit: null,
    expiryType: null,
    expiryDate: null,
    weightsType: WeightsType.Portfolio,
  },
  securityHoldings: null,
  isPreApproved: false,
  validationMessages: {
    errors: [],
    information: [],
    warnings: [],
  },
  investmentServicesLockedByOrders: [],
};

export const editSlice = createSlice({
  name: '@@bulk/order/edit',
  initialState,
  reducers: {
    setTradeAction: (state, action: PayloadAction<string>) => {
      state.parameters.tradeAction = action.payload;
    },
    setTradeMethod: (state, action: PayloadAction<string>) => {
      state.parameters.tradeMethod = action.payload;
    },
    setAmount: (state, action: PayloadAction<number | null>) => {
      state.parameters.amount = action.payload;
    },
    setPriceType: (state, action: PayloadAction<string | null>) => {
      state.parameters.priceType = action.payload;
    },
    setPriceLimit: (state, action: PayloadAction<number | null>) => {
      state.parameters.priceLimit = action.payload;
    },
    setExpiryType: (state, action: PayloadAction<string | null>) => {
      state.parameters.expiryType = action.payload;
    },
    setExpiryDate: (state, action: PayloadAction<string | null>) => {
      state.parameters.expiryDate = action.payload;
    },
    setWeightsType: (state, action: PayloadAction<WeightsType>) => {
      state.parameters.weightsType = action.payload;
    },
    setClearAmount: (state, action: PayloadAction<number>) => {
      if (state.securityHoldings) {
        state.securityHoldings.holdings.forEach((holding) => {
          if (holding.portfolioId === action.payload) {
            holding.amount = 0;
            holding.newUnits = 0;
            holding.assetClass = '';
            holding.assetClassId = 0;
          }
        });
      }
    },
    setAmounts: (state, action: PayloadAction<HoldingAmount[]>) => {
      if (state.securityHoldings) {
        state.securityHoldings.holdings.forEach((holding) => {
          const holdingAmount = action.payload.find((h) => h.portfolioId === holding.portfolioId);
          if (
            holdingAmount &&
            holding.portfolioId === holdingAmount.portfolioId &&
            holdingAmount.amount !== holding.amount
          ) {
            holding.newUnits =
              state.parameters.security && state.securityHoldings
                ? setUnits(
                    state.parameters.tradeAction,
                    state.parameters.tradeMethod === TradeMethod.Target.name
                      ? TradeMethod.Units.name
                      : state.parameters.tradeMethod,
                    holding.units,
                    holdingAmount.amount ?? 0,
                    state.securityHoldings.unitPrice,
                    holding.targetPercentage * 100,
                    state.securityHoldings.code.substring(state.securityHoldings.code.lastIndexOf('.') + 1),
                    holding.portfolioValue
                  )
                : 0;
            holding.amount = holdingAmount.amount ?? 0;
            holding.assetClass = '';
            holding.assetClassId = 0;
          }
          return holding;
        });
      }
    },
    setSecurity: (state, action: PayloadAction<SimpleSecurityItem | null>) => {
      state.parameters.security = action.payload;
      state.securityHoldings = null;
    },
    setSecuritySearch: (state, action: PayloadAction<string | null>) => {
      state.parameters.securitySearch = action.payload;
    },
    setCalculateOrder: (state) => {
      if (state.securityHoldings) {
        state.securityHoldings?.holdings.forEach((holding) => {
          holding.newUnits =
            (state.parameters.amount ||
              state.parameters.tradeAction === TradeAction.SellAll.name ||
              state.parameters.tradeMethod === TradeMethod.Target.name) &&
            state.parameters.security &&
            state.securityHoldings
              ? setUnits(
                  state.parameters.tradeAction,
                  state.parameters.tradeMethod,
                  holding.units,
                  state.parameters.tradeMethod === TradeMethod.Target.name
                    ? holding.targetPercentage * 100
                    : state.parameters.amount ?? 0,
                  state.securityHoldings.unitPrice,
                  holding.targetPercentage * 100,
                  state.securityHoldings.code.substring(state.securityHoldings.code.lastIndexOf('.') + 1),
                  holding.portfolioValue
                )
              : 0;

          holding.amount =
            state.parameters.tradeAction === TradeAction.SellAll.name
              ? holding.units
              : state.parameters.tradeAction === TradeAction.Sell.name &&
                state.parameters.tradeMethod === TradeMethod.Units.name &&
                holding.newUnits >= holding.units
              ? holding.units
              : state.parameters.tradeAction === TradeAction.Sell.name &&
                state.parameters.tradeMethod === TradeMethod.Amount.name &&
                holding.newUnits >= holding.units
              ? holding.units * (state.securityHoldings?.unitPrice ?? 0)
              : state.parameters.tradeAction === TradeAction.Sell.name &&
                state.parameters.tradeMethod === TradeMethod.Percentage.name &&
                holding.newUnits >= holding.units
              ? (100 / holding.portfolioValue) * (holding.newUnits * (state.securityHoldings?.unitPrice ?? 0))
              : state.parameters.tradeMethod === TradeMethod.Target.name
              ? holding.newUnits
              : state.parameters.amount ?? 0;
        });

        state.securityHoldings.tradeAction = state.parameters.tradeAction;
        state.securityHoldings.tradeMethod = state.parameters.tradeMethod;
      }
    },
    setIsPreApproved: (state) => {
      state.isPreApproved = false;
      state.validationMessages = {
        errors: [],
        information: [],
        warnings: [],
      };
    },
    setBulkOrderValidated: (state, action: PayloadAction<ValidateOrderResultPayload>) => {
      state.validationMessages = action.payload.validationResult;
      state.investmentServicesLockedByOrders = action.payload.investmentServicesLockedByOrders;
      state.isPreApproved = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchPortfolioSecurityHoldings.fulfilled,
      (state, action: PayloadAction<PortfolioSecurityHoldingResult>) => {
        action.payload.holdings.forEach((holding) => (holding.newUnits = 0));
        state.securityHoldings = action.payload;
      }
    );
    builder.addCase(validateBulkOrder.fulfilled, (state, action) => {
      state.validationMessages = action.payload.validationResult;
      state.investmentServicesLockedByOrders = action.payload.investmentServicesLockedByOrders;
      state.isPreApproved = true;
    });
    builder.addCase(validateBulkOrder.rejected, (state, action) => {
      if (action.payload) {
        state.investmentServicesLockedByOrders = action.payload.investmentServicesLockedByOrders;
        state.validationMessages = action.payload.validationResult;
      }
      state.isPreApproved = false;
    });
  },
});

const setUnits = (
  tradeAction: string,
  tradeMethod: string,
  currentUnits: number,
  amount: number,
  unitPrice: number,
  targetPercentage: number,
  marketCode: string,
  totalPortfolioValue: number
) => {
  return tradeAction === TradeAction.SellAll.name ||
    (tradeAction === TradeAction.Sell.name &&
      ((tradeMethod === TradeMethod.Units.name && amount >= currentUnits) ||
        (tradeMethod === TradeMethod.Amount.name &&
          amount >= roundUnitsByMarketCode(currentUnits * unitPrice, marketCode)) ||
        (tradeMethod === TradeMethod.Percentage.name &&
          roundUnitsByMarketCode(((amount / 100) * totalPortfolioValue) / unitPrice, marketCode) >= currentUnits)))
    ? roundUnitsByMarketCode(currentUnits, marketCode)
    : tradeMethod === TradeMethod.Amount.name
    ? roundUnitsByMarketCode(amount / unitPrice, marketCode)
    : tradeMethod === TradeMethod.Percentage.name
    ? roundUnitsByMarketCode(((amount / 100) * totalPortfolioValue) / unitPrice, marketCode)
    : tradeMethod === TradeMethod.Target.name &&
      tradeAction === TradeAction.Sell.name &&
      roundUnitsByMarketCode(currentUnits - ((amount / 100) * totalPortfolioValue) / unitPrice, marketCode) <= 0
    ? roundUnitsByMarketCode(0, marketCode)
    : tradeMethod === TradeMethod.Target.name &&
      tradeAction === TradeAction.Sell.name &&
      roundUnitsByMarketCode(currentUnits - ((amount / 100) * totalPortfolioValue) / unitPrice, marketCode) > 0
    ? roundUnitsByMarketCode(currentUnits - ((amount / 100) * totalPortfolioValue) / unitPrice, marketCode)
    : tradeMethod === TradeMethod.Target.name &&
      tradeAction === TradeAction.Buy.name &&
      roundUnitsByMarketCode(((amount / 100) * totalPortfolioValue) / unitPrice - currentUnits, marketCode) <= 0
    ? roundUnitsByMarketCode(0, marketCode)
    : tradeMethod === TradeMethod.Target.name &&
      tradeAction === TradeAction.Buy.name &&
      roundUnitsByMarketCode(((amount / 100) * totalPortfolioValue) / unitPrice - currentUnits, marketCode) > 0
    ? roundUnitsByMarketCode(((amount / 100) * totalPortfolioValue) / unitPrice - currentUnits, marketCode)
    : roundUnitsByMarketCode(amount, marketCode);
};
