import { AxiosRequestConfig } from 'axios';
import api from '../../../../../app/api';

export enum EmailTemplateSendApi {
  UploadAttachment = '/emails/UploadAttachment',
}

export async function uploadAttachment(payload: FormData): Promise<string> {
  const options: AxiosRequestConfig = {
    headers: { 'Content-Type': 'multipart/form-data' },
  };

  const response = await api.post(`${EmailTemplateSendApi.UploadAttachment}`, payload, options);
  return Promise.resolve<string>(response.data);
}
