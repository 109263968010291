import { AxiosResponse } from 'axios';
import { call, ForkEffect, put, takeLatest } from 'redux-saga/effects';
import api from '../../../app/api';
import { FeatureFlagsActionTypes } from './actions';
import { FeatureFlags } from './types';

function* fetchFeatureFlagsSaga() {
  try {
    const result: AxiosResponse<FeatureFlags> = yield call(fetchFeatureFlags);
    yield put({ type: FeatureFlagsActionTypes.FetchFeatureFlagsSuccess, payload: result.data });
  } catch (e) {
    yield put({ type: FeatureFlagsActionTypes.FetchFeatureFlagsFailed, payload: e });
  }
}

const fetchFeatureFlags = async (): Promise<AxiosResponse<FeatureFlags>> => {
  return api.get<FeatureFlags>('featureflags/GetFeatureFlags').then((response) => response);
};

export function* featureFlagsSaga(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(FeatureFlagsActionTypes.FetchFeatureFlagsRequest, fetchFeatureFlagsSaga);
}
