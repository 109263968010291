import React, { useCallback, useEffect } from 'react';
import { TabControl } from '../../../../common';
import { strategyTabRoutes } from '../../strategyTabRoutes';
import { Props } from '../container';
import { EntityDetailsCard } from './EntityDetailsCard';

export const StrategyTabs = (props: Props): JSX.Element => {
  const {
    fetchStrategyDashboard,
    match: { params },
  } = props;

  const strategyVersionId = Number(params.strategyVersionId ?? 0);
  const strategyId = Number(params.strategyId ?? 0);

  const fetch = useCallback(
    (strategyId: number, strategyVersionId: number) => {
      fetchStrategyDashboard({ strategyId, strategyVersionId });
    },
    [fetchStrategyDashboard]
  );

  useEffect(() => {
    fetch(strategyId, strategyVersionId);
  }, [strategyId, strategyVersionId, fetch]);

  return (
    <TabControl
      {...props}
      tileText={props.strategyDashboard.common.strategyDashboard?.strategyName}
      tileSubText={''}
      tileComponent={EntityDetailsCard}
      tabRoutes={strategyTabRoutes.filter((r) => r.visible())}
    />
  );
};
