import { FieldProps, useField } from 'formik';
import React from 'react';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import { FormikErrorMessage } from '.';
import { WO2NumberFormatInput } from '../../';

export interface NumberFormatProps {
  format?: string;
  mask?: string;
  placeholder: string;
  isNumericString: boolean;
  name: string;
  label: string;
  prefix?: string;
  suffix?: string;
  isAllowed?: (values: NumberFormatValues) => boolean;
  allowNegative?: boolean;
  thousandSeparator?: boolean | string;
  decimalScale?: number;
  fixedDecimalScale?: boolean;
  disabled?: boolean;
  onValueChange?: (values: NumberFormatValues) => void;
  fullWidth?: boolean;
}

export interface FormikNumberFormatProps {
  formikFieldProps: FieldProps;
  numberFormatProps: NumberFormatProps;
  isFloatValue?: boolean;
  showRequiredAsterisk?: boolean;
  dataTestid?: string;
  fullWidth?: boolean;
}

export const FormikNumberFormat = ({ showRequiredAsterisk, ...props }: FormikNumberFormatProps): JSX.Element => {
  const { formikFieldProps, numberFormatProps, isFloatValue, dataTestid, fullWidth } = props;
  const [field, , helpers] = useField(formikFieldProps.field.name);
  const { label } = numberFormatProps;

  numberFormatProps.fullWidth = fullWidth;

  return (
    <>
      <NumberFormat
        {...numberFormatProps}
        label={!!showRequiredAsterisk && !!label ? `* ${label}` : label}
        value={field.value}
        onValueChange={(vals) => {
          helpers.setValue(isFloatValue ? vals.floatValue ?? null : vals.value);
          if (!!numberFormatProps.onValueChange) {
            numberFormatProps.onValueChange(vals);
          }
        }}
        onBlur={() => helpers.setTouched(true, true)}
        customInput={WO2NumberFormatInput}
        data-testid={dataTestid}
      />
      <FormikErrorMessage name={field.name}></FormikErrorMessage>
    </>
  );
};
