/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { importFixedAllocationsAction } from './sagas/fixed/allocationsImporterSaga';
import { importWeightedAllocationsAction } from './sagas/weighted/allocationsImporterSaga';
import { fetchCompositionDetail } from './thunks';
import { AllocationsImporterResponse, CompositionDetail, ModelCompositionState } from './types';

export const initialState: ModelCompositionState = {
  parameters: {
    selectedGroup: 'ALL',
  },
  compositionDetail: null,
  importErrors: [],
  importWarnings: [],
  importInformation: [],
  modelUploadSuccess: false,
};

export const modelCompositionSlice = createSlice({
    name: '@@model/composition',
    initialState,
    reducers: {
      setSelectedGroupParameter: (state, action: PayloadAction<string>) => {
        state.parameters.selectedGroup = action.payload;
      },
      resetModelUploadSuccessStatus: (state, action: PayloadAction<boolean>) => {
        state.modelUploadSuccess = action.payload;
      },
    },
    extraReducers: (builder) => {
      builder.addCase(fetchCompositionDetail.fulfilled, (state: ModelCompositionState, action: PayloadAction<CompositionDetail>) => {
        state.compositionDetail = action.payload;
      });
      builder.addCase(importFixedAllocationsAction.fulfilled, (state: ModelCompositionState, action: PayloadAction<AllocationsImporterResponse>) => {
        state.importWarnings = action.payload.warnings;
        state.importInformation = action.payload.information;
        state.importErrors = action.payload.errors;
        state.modelUploadSuccess = true;
      });
      builder.addCase(importFixedAllocationsAction.rejected, (state: ModelCompositionState, action: PayloadAction<string[]>) => {
        state.importErrors = action.payload;
      });
      builder.addCase(importWeightedAllocationsAction.fulfilled, (state: ModelCompositionState, action: PayloadAction<AllocationsImporterResponse>) => {
        state.importWarnings = action.payload.warnings;
        state.importInformation = action.payload.information;
        state.importErrors = action.payload.errors;
        state.modelUploadSuccess = true;
      });
      builder.addCase(importWeightedAllocationsAction.rejected, (state: ModelCompositionState, action: PayloadAction<string[]>) => {
        state.importErrors = action.payload;
      });
    },
  });
