import { FormControlLabel, Typography, Box, AccordionSummary, Accordion, AccordionDetails } from '@mui/material';
import React from 'react';
import { WO2Checkbox, WO2ToggleSwitch } from 'src/common';
import DateRangePicker from 'src/common/components/DateRangePicker';
import { ActionCreatorWithoutPayload, ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { ClientReportsParameters, MdaReportTypes } from '../../store/types';
import { DateRange } from 'src/store';
import { ExpandMore } from '@mui/icons-material';

export interface ExpensesProps {
  parameters: ClientReportsParameters;
  setExpensesSelectedParameter: ActionCreatorWithPayload<boolean, string>;
  setDateRangeExpensesParameter: ActionCreatorWithPayload<DateRange, string>;
  setExpensesExcludeExpenseTypeParameter: ActionCreatorWithPayload<boolean, string>;
  expandExpenses: ActionCreatorWithoutPayload<string>;
  createReportSortRequest: (isAdd: boolean, id: number, text: string) => void;
}

export const Expenses = ({
  parameters,
  setExpensesSelectedParameter,
  setExpensesExcludeExpenseTypeParameter,
  setDateRangeExpensesParameter,
  expandExpenses,
  createReportSortRequest,
}: ExpensesProps): JSX.Element => {
  const handleClickExpensesSelect = () => {
    setExpensesSelectedParameter(!parameters.selectedReports?.expenses.selected);
    createReportSortRequest(
      !parameters.selectedReports?.expenses.selected,
      MdaReportTypes.Expenses.id,
      MdaReportTypes.Expenses.displayName
    );
  };

  const handleClickExpensesHidingOptions = () => {
    setExpensesExcludeExpenseTypeParameter(!parameters.selectedReports.expenses.excludeExpenseType);
  };

  const handleClickReportDateExpenses = (dates: DateRange) => {
    setDateRangeExpensesParameter(dates);
  };

  return (
    <Accordion
      onChange={expandExpenses}
      expanded={parameters.selectedReports?.expenses.expanded || false}
      elevation={0}
    >
      <AccordionSummary expandIcon={<ExpandMore />}>
        <FormControlLabel
          sx={{ flex: 1 }}
          aria-label="Acknowledge"
          onClick={(event) => event.stopPropagation()}
          onFocus={(event) => event.stopPropagation()}
          control={
            <WO2Checkbox
              color="primary"
              checked={parameters.selectedReports?.expenses.selected}
              onChangeHandler={handleClickExpensesSelect}
            />
          }
          label="Expenses"
        />
        <div onClick={(e) => e.stopPropagation()}>
          <DateRangePicker
            dateRange={{
              dateFrom: parameters?.selectedReports?.expenses?.fromDate,
              dateTo: parameters?.selectedReports?.expenses?.toDate,
              dateRangeType: parameters?.selectedReports?.expenses?.dateRangeType,
            }}
            setDateRange={handleClickReportDateExpenses}
            inceptionDate={parameters.inceptionDate}
          />
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <Box display="flex" flexWrap="nowrap" alignItems="center">
          <WO2ToggleSwitch
            checked={parameters.selectedReports.expenses.excludeExpenseType}
            onChange={handleClickExpensesHidingOptions}
            color="primary"
            name="exclude-expense-type"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
          <Typography>Exclude Expense Type</Typography>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};
