import { Grid, Skeleton } from '@mui/material';
import React from 'react';
import { LoadingIndicator, VirtualizedAutocomplete, WO2Modal } from '../../../common';
import DateRangePicker from '../../../common/components/DateRangePicker';
import { LoadingProgress } from '../../../common/store/types';
import { DateRange } from '../../../store/types/dateRange';
import { AfslPracticeAdviserSelect } from '../../bulk/common/components/AfslPracticeAdviserSelect';
import { AdviserItem, AfslItem, HoldingsParameters, PracticeItem, SimpleSecurityItem } from '../store/types';

export interface ExportHoldingDialogResponse {
  name: string;
  id: number | null;
  securityCode: string;
}

export interface Props {
  onClose: () => void;
  exportData: () => void;
  afslDetails: AfslItem[];
  securityDetails: SimpleSecurityItem[];
  parameters: HoldingsParameters;
  setAfslParameter: (ids: AfslItem[]) => void;
  setPracticeParameter: (ids: PracticeItem[]) => void;
  setAdviserParameter: (ids: AdviserItem[]) => void;
  setDateSelectedParameter: (date: DateRange) => void;
  exportLoadingProgress: LoadingProgress;
  exportingProcess: LoadingProgress;
  setSecurityParameter: (simpleSecurity: SimpleSecurityItem) => void;
}

export function ExportHoldingDialog({
  onClose,
  exportData,
  afslDetails,
  securityDetails,
  parameters,
  setAfslParameter,
  setPracticeParameter,
  setAdviserParameter,
  exportLoadingProgress,
  setSecurityParameter,
  exportingProcess,
  setDateSelectedParameter,
}: Props): JSX.Element {
  return (
    <WO2Modal
      MuiDialogProps={{
        open: true,
        onClose: () => {
          onClose();
        },
        maxWidth: 'md',
      }}
      title={<>Holdings Export</>}
      actions={[
        {
          label: 'Close',
          onClickHandler: () => {
            onClose();
          },
          type: 'contained',
          disabled: false,
        },
        {
          label: 'Export',
          onClickHandler: () => {
            exportData();
          },
          type: 'contained',
          disabled: false,
        },
      ]}
    >
      <LoadingIndicator progress={exportingProcess}>
        <div
          style={{
            minHeight: '30vh',
            maxHeight: '40vh',
          }}
        >
          {exportLoadingProgress.isLoading ? (
            <>
              <Skeleton variant="rectangular" width="100%" height={50} style={{ marginTop: '30px' }} />
              <Skeleton variant="rectangular" width="100%" height={118} style={{ marginTop: '10px' }} />
            </>
          ) : (
            <>
              <Grid style={{ paddingTop: '10px' }}>
                <DateRangePicker
                  dateRange={parameters.exportDateSelected}
                  setDateRange={setDateSelectedParameter}
                  isDateOnly={true}
                />
              </Grid>
              <Grid container direction="column">
                <AfslPracticeAdviserSelect
                  data={afslDetails}
                  selectedAfsls={parameters.afsls}
                  selectedPractices={parameters.practices}
                  selectedAdvisers={parameters.advisers}
                  setSelectedAfsls={setAfslParameter}
                  setSelectedPractices={setPracticeParameter}
                  setSelectedAdvisers={setAdviserParameter}
                />

                <Grid style={{ paddingTop: '10px' }}>
                  <VirtualizedAutocomplete<SimpleSecurityItem>
                    selectedValue={parameters.selectedSecuity}
                    options={securityDetails}
                    onOptionChange={setSecurityParameter}
                    filterOptionStringLogic={(s: SimpleSecurityItem) => s.securityCode + s.securityName}
                    selectedOptionRenderedLabel={(s: SimpleSecurityItem) => `${s.securityCode} - ${s.securityName}`}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </div>
      </LoadingIndicator>
    </WO2Modal>
  );
}
