import { Grid, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { Account, AccountInstitutionType } from './types';
import { ClientAccountSubType } from 'src/common/types';

export interface AccountDetailProps {
  account: Account;
  isSuperSimplifier?: boolean;
  entityDetailsType?: string;
  entityDetailsSubTypeId?: number | null;
}

const useStyles = makeStyles(() => ({
  rootDiv: {
    width: '100%',
    textAlign: 'left',
  },
  iconImage: {
    height: '30px',
    margin: '0 auto',
  },
  bsb: {
    paddingRight: '20px',
  },
}));

export const AccountDetail = ({ account, isSuperSimplifier, entityDetailsType, entityDetailsSubTypeId }: AccountDetailProps): JSX.Element => {
  const classes = useStyles();

  const institutionId = AccountInstitutionType.getValidInstitutionId(account.institutionId);

  let tooltipTitle = '';

  if(entityDetailsType === 'Individual'
    && account.accountTypeName === 'UXchange'
    && (entityDetailsSubTypeId === ClientAccountSubType.getByName('Super')?.id 
      || entityDetailsSubTypeId === ClientAccountSubType.getByName('Pension')?.id)) {
    tooltipTitle = 'DASH Custodial Managed Funds'
  } else {
    tooltipTitle = account.institution ?? '';
  }

  return (
    <div className={classes.rootDiv}>
      <Grid container spacing={2}>
        <Grid container item xs={3} alignContent={'center'}>
          <Tooltip title={tooltipTitle} arrow>
            <img
              src={AccountInstitutionType.getLogoById(institutionId)}
              alt="Institution"
              className={classes.iconImage}
            />
          </Tooltip>
        </Grid>
        <Grid container item xs={9}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h5" style={{ fontWeight: 700 }}>
                {account.accountName}
              </Typography>
            </Grid>
            {!!account.bsb && (
              <Typography variant="h5" className={classes.bsb}>
                BSB: {account.bsb}
              </Typography>
            )}
            <Typography variant="h5">
              {isSuperSimplifier ? 'Super member' : 'Account'} number: {account.accountNumber}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
