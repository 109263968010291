import { createSelector } from '@reduxjs/toolkit';
import { selectPortfolio } from '../../store/selectors';

export const selectOverview = createSelector(selectPortfolio, (portfolio) => portfolio.overview);

export const selectConfiguration = createSelector(selectPortfolio, (portfolio) => portfolio.configuration);

export const selectCommon = createSelector(selectPortfolio, (portfolio) => portfolio.common);

export const selectRebalance = createSelector(selectPortfolio, (portfolio) => portfolio.rebalance);
