import { Alert, Box, FormControl, FormControlLabel, Link, Paper, Radio, RadioGroup, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { WO2Checkbox } from '../../Checkbox';
import { WO2Modal } from '../../Modal';
import { AccountInstitutionType, AttachableCmaAccount, SelectedCmaAccount } from '../types';

export interface Props {
  onClose: () => void;
  onSubmit: (selectedCmaAccount: SelectedCmaAccount) => void;
  attachableCmaAccounts: AttachableCmaAccount[];
  superSimplifierAccountStatus: number | undefined;
}

export function OpenMarketsAccount({ attachableCmaAccounts, onClose, onSubmit, superSimplifierAccountStatus }: Props): JSX.Element {
  const [acknowledgedTandC, setAcknowledgedTandC] = useState<boolean>(false);
  const [acknowledgedROA, setAcknowledgedROA] = useState<boolean>(false);
  const [value, setValue] = React.useState<string | null>(null);
  const [helperText, setHelperText] = React.useState('');
  const [acknowledgedText, setAcknowledgedText] = React.useState('');

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    setHelperText('');
  };

  return (
    <WO2Modal
      MuiDialogProps={{
        open: true,
        onClose: () => {
          onClose();
        },
        maxWidth: 'sm',
      }}
      title={<b>Acknowledgement</b>}
      actions={[
        {
          label: 'Close',
          onClickHandler: () => {
            onClose();
          },
          type: 'contained',
          disabled: false,
        },
        {
          label: 'Submit',
          onClickHandler: () => {
            if (acknowledgedTandC && value) {
              const account = attachableCmaAccounts[Number(value)];
              onSubmit({
                bsb: account.bsb,
                forceRoa: acknowledgedROA,
                name: account.name,
                number: account.number,
              });
            }
            if (!acknowledgedTandC) {
              setAcknowledgedText('* Acknowledgement of Terms and Conditions is required.');
            }
            if (!value) {
              setHelperText('* Please select an option.');
            }
          },
          type: 'contained',
          disabled: !attachableCmaAccounts || attachableCmaAccounts.length === 0,
        },
      ]}
    >
      <>
        <Typography variant="body2" color="textPrimary" style={{ marginBottom: '12px', marginTop: '12px' }}>
          <strong>Are you sure you want to create this account</strong>
        </Typography>
        <Typography variant="body2" style={{ marginBottom: '24px ' }}>
          The application is ready to be submitted. Please ensure the application information is correct before proceeding.
        </Typography>
        <Typography variant="body2" color="textPrimary" style={{ marginBottom: '12px', marginTop: '12px' }}>
          <strong>Link CMA </strong>
        </Typography>
        <Stack direction="column" spacing={2}>
          {attachableCmaAccounts && attachableCmaAccounts.length > 0 ? (
            <FormControl component="fieldset" variant="standard">
              <RadioGroup row aria-label="gender" name="row-radio-buttons-group" value={value} onChange={handleRadioChange}>
                {attachableCmaAccounts.map((account, index) => (
                  <Stack key={index} spacing={2} direction="row" padding={'6px'}>
                    <Box width="50px" paddingTop="12px" paddingBottom="12px">
                      <FormControlLabel value={index.toString()} control={<Radio />} label="" style={{ margin: '0' }} />
                    </Box>
                    <Paper variant={'outlined'} style={{ padding: '12px', marginLeft: '0' }}>
                      <Stack key={index} spacing={2} direction="row">
                        <img src={AccountInstitutionType.MacquarieCma.getLogo()} alt="Institution" width={'40px'} />
                        <Box margin="0" marginLeft="5px !important">
                          <Typography variant="h5">BSB: {account.bsb}</Typography>
                          <Typography variant="h5">Account: {account.number}</Typography>
                        </Box>
                      </Stack>
                    </Paper>
                  </Stack>
                ))}
              </RadioGroup>
            </FormControl>
          ) : (
            <Alert variant="outlined" severity="warning">
              You must have a CMA application submitted or completed to submit other new accounts. Please setup a CMA before continuing.
            </Alert>
          )}
          {attachableCmaAccounts && attachableCmaAccounts.length > 0 && !value && (
            <Typography variant="body2" color="textSecondary">
              {helperText}
            </Typography>
          )}
        </Stack>
        <Typography variant="body2" color="textPrimary" style={{ marginBottom: '12px', marginTop: '12px' }}>
          <strong>Acknowledgements</strong>
        </Typography>
        <Stack direction="row" spacing={2}>
          <Box width="50px">
            <WO2Checkbox name="openmarketsAcknowledgementCheckbox" value={acknowledgedTandC} onChangeHandler={setAcknowledgedTandC} />
          </Box>
          <Typography variant="body2" style={{ marginBottom: '24px ' }}>
            I have provided the client and they have agreed to the terms and conditions and disclosures of OpenMarkets. These documents are available from{' '}
            <Link href="https://openmarkets.com.au">https://openmarkets.com.au</Link>
          </Typography>
        </Stack>
        {!(superSimplifierAccountStatus === null) && (
          <Stack direction="row" spacing={2}>
            <Box width="50px">
              <WO2Checkbox value={acknowledgedROA} onChangeHandler={setAcknowledgedROA} />
            </Box>
            <Typography variant="body2" style={{ marginBottom: '24px ' }}>
              This is an ROA client and I want to capture this client&apos;s digital consent (required for ROA clients).
            </Typography>
          </Stack>
        )}

        {!acknowledgedTandC && (
          <Typography variant="body2" color="textSecondary">
            {acknowledgedText}
          </Typography>
        )}
      </>
    </WO2Modal>
  );
}
