import { createSelector } from '@reduxjs/toolkit';
import _ from 'lodash';
import { RootState } from '../../../store';
import { LoadingState } from './../reducers/loading';
import { Selector } from './../types/redux';

export const selectLoading: Selector<LoadingState | undefined> = createSelector(
  (state: Partial<RootState>) => state,
  (state) => state.loading
);

export const isLoadingSelectorFactory = (actionTypes: string[]): Selector<boolean> => {
  return createSelector(selectLoading, (loading: LoadingState | undefined) => {
    return _(actionTypes).some((actionType) => {
      const matches = /(.*)\/(Request|pending|Success|fulfilled|Failed|rejected)/.exec(actionType);

      let requestName = actionType;
      if (matches) {
        const [, splitRequestName] = matches;
        requestName = splitRequestName;
      }

      return !loading?.hasOwnProperty(requestName) ? false : loading[requestName];
    });
  });
};
