import { createSelector } from '@reduxjs/toolkit';
import { ClientAccountSubType, ClientAccountType } from 'src/common/types';
import { AddressType } from 'src/features/clients/client/common/store/enums';
import { selectClientsState } from '../../store/selectors';
import { AccountTypeValues, AdditionalInformationValues, Contact } from './types';

export const selectOnboard = createSelector(selectClientsState, (clientsState) => clientsState.onboard);

export const selectSubmitted = createSelector(selectOnboard, (onboard) => onboard.submitted);

export const selectCommon = createSelector(selectOnboard, (onboard) => onboard.common);

export const selectClient = createSelector(selectOnboard, (onboard) => onboard.client);

export const selectId = createSelector(selectClient, (client) => client.id);

export const selectStepKey = createSelector(selectClient, (client) => client.stepKey);

export const selectAdvisor = createSelector(selectClient, (client) => client.advisor);

export const selectClientAccount = createSelector(selectClient, (client) => client.clientAccount);

export const selectAccountType = createSelector(selectClientAccount, (client) =>
  ClientAccountType.getById(client.accountTypeId)
);

export const selectSuperSimplifierDetailsValues = createSelector(
  selectClient,
  (client) => client.superSimplifierDetails
);

export const selectAdviceFeesValues = createSelector(selectClient, (client) => client.fees);

export const selectEstimatedFees = createSelector(selectAdviceFeesValues, (adviceFees) => adviceFees?.estimatedFees);

export const selectEstimatedFeesItems = createSelector(selectEstimatedFees, (estimatedFees) => {
  return estimatedFees?.items ?? [];
});

export const selectAdviceFeeEdit = createSelector(selectEstimatedFees, (estimatedFees) => {
  return estimatedFees?.edit;
});

export const selectEditingTieredFeeDetails = createSelector(
  selectAdviceFeeEdit,
  (adviceFee) => adviceFee?.tieredFeeDetails
);

export const selectTieredFeeDetailsEdit = createSelector(
  selectEditingTieredFeeDetails,
  (tieredFeeDetails) => tieredFeeDetails?.edit
);

export const selectTieredFeeDetailsItems = createSelector(
  selectEditingTieredFeeDetails,
  (tieredFeeDetails) => tieredFeeDetails?.items || []
);

export const selectStandardFees = createSelector(selectAdviceFeesValues, (adviceFees) => adviceFees?.standardFees);

export const selectContacts = createSelector(selectClient, (client) => client.contacts);

export const selectContactsItems = createSelector(selectContacts, (contacts) => contacts.items);

export const selectAdditionalContactsStartIndex = createSelector(
  selectContactsItems,
  selectAccountType,
  (contacts, accountType) => {
    if (accountType === ClientAccountType.Joint) {
      // additional contacts start after first 2 contacts (primary and additional)
      return 2;
    }
    // additional contacts start after first contact  (primary)
    return 1;
  }
);

export const selectContactsAdditionalItems = createSelector(
  selectContactsItems,
  selectAdditionalContactsStartIndex,
  (contacts, index) => {
    return contacts.slice(index);
  }
);

export const selectContactsEditIndex = createSelector(selectContacts, (contacts) => contacts.edit);

export const selectPrimaryContact = createSelector(selectContactsItems, (clients) => clients[0]);

export const selectAfsls = createSelector(selectCommon, (common) => common.afsls);

export const selectAfslAuthorisation = createSelector(selectCommon, (common) => common.afslAuthorisation);

export const selectDocumentTypes = createSelector(selectCommon, (common) => common.documentTypes);

export const selectExternalInstitutions = createSelector(selectCommon, (common) => common.externalInstitutions);

export const selectApprovedInstitutionIds = createSelector(selectCommon, (common) => common.approvedInstitutionIds);

export const selectAccounts = createSelector(selectClient, (client) => client.accounts);

export const selectExistingAccounts = createSelector(selectAccounts, (accounts) => accounts.existingAccounts);

export const selectNewAccounts = createSelector(selectAccounts, (accounts) => accounts.newAccounts);

export const selectAccountInfo = createSelector(selectAccounts, (accounts) => accounts.accountInfo);

export const selectTrustee = createSelector(selectClient, (client) => client.trustee);

export const selectTrusteeAddresses = createSelector(selectTrustee, (trustee) => trustee?.addresses);

export const selectIdentifications = createSelector(
  selectContactsEditIndex,
  selectContactsItems,
  (editIndex, contacts) => {
    if (editIndex !== null && editIndex !== undefined) {
      return contacts[editIndex].identifications;
    } else {
      return undefined;
    }
  }
);

export const selectParameters = createSelector(
  selectIdentifications,
  (selectIdentifications) => selectIdentifications?.parameters
);

export const selectEditIdentification = createSelector(
  selectIdentifications,
  (selectIdentifications) => selectIdentifications?.results.edit
);

export const selectAddresses = createSelector(selectClient, (client) => client.addresses);

export const selectAddressItems = createSelector(selectAddresses, (addresses) => addresses.items);

export const selectEditAddress = createSelector(selectAddresses, (addresses) => {
  if (addresses.edit === null) {
    return null;
  }
  if (addresses.edit !== undefined) {
    return { ...addresses.items[addresses.edit], addressId: addresses.edit };
  }
  return undefined;
});

export const selectAvailableAddressTypes = createSelector(selectAddressItems, (addresses) => {
  let addressTypes = AddressType.getArray();
  addresses.forEach((address) => {
    if (!!address.addressTypeIds) {
      address.addressTypeIds.forEach((addressTypeId) => {
        const type = AddressType.getById(addressTypeId);
        addressTypes = addressTypes.filter((item) => item !== type);
      });
    }
  });
  return addressTypes;
});

export const selectAccountTypeValues = createSelector(selectClient, (client): AccountTypeValues => {
  return {
    id: client.id,
    advisor: client.advisor,
    clientAccount: client.clientAccount,
    superSimplifierDetails: client.superSimplifierDetails,
  };
});

export const selectIsSuperSimplifierOnboardingClient = createSelector(selectClient, (client): boolean => {
  if (!client) {
    return false;
  }
  const accountTypeId = client.clientAccount?.accountTypeId;
  const subTypeId = client.clientAccount?.subTypeId;

  return (
    accountTypeId === ClientAccountType.Individual.id &&
    !!subTypeId &&
    (subTypeId === ClientAccountSubType.Super.id || subTypeId === ClientAccountSubType.Pension.id)
  );
});

export const selectAdditionalInformationValues = createSelector(
  selectClientAccount,
  selectContactsItems,
  selectSuperSimplifierDetailsValues,
  (clientAccount, contacts, superSimplifierDetails): AdditionalInformationValues => {
    return {
      contact: {
        contactId: contacts[0].contactId,
        titleId: contacts[0].details.titleId,
        firstName: contacts[0].details.firstName,
        lastName: contacts[0].details.lastName,
        middleName: contacts[0].details.middleName,
        dateOfBirth: contacts[0].details.dateOfBirth,
      },
      additionalContact:
        clientAccount.accountTypeId === ClientAccountType.Joint.id && contacts.length > 1
          ? {
              contactId: contacts[1].contactId,
              titleId: contacts[1].details.titleId,
              firstName: contacts[1].details.firstName,
              lastName: contacts[1].details.lastName,
              middleName: contacts[1].details.middleName,
              dateOfBirth: contacts[1].details.dateOfBirth,
            }
          : undefined,
      clientAccount: {
        name: clientAccount.name,
        tfn: clientAccount.tfn,
        tfnExemptionId: clientAccount.tfnExemptionId,
        abn: clientAccount.abn,
        acn: clientAccount.acn ?? '',
      },
      superSimplifierDetails: { pensionTypeId: superSimplifierDetails?.pensionTypeId || null },
    };
  }
);

export const selectContactForEdit = createSelector(
  selectContactsItems,
  selectContactsEditIndex,
  (contacts, index): Contact | undefined => {
    return index !== null && index !== undefined ? contacts[index] : undefined;
  }
);

export const selectContactAddresses = createSelector(selectContactForEdit, (contact) => contact?.addresses);

export const selectContactAddressesItems = createSelector(
  selectContactAddresses,
  (addresses) => addresses?.items || []
);

export const selectContactEditAddress = createSelector(selectContactAddresses, (addresses) => {
  if (addresses?.edit === null) {
    return null;
  }
  if (addresses?.edit !== undefined) {
    return { ...addresses.items[addresses.edit], addressId: addresses.edit };
  }
  return undefined;
});

export const selectContactAvailableAddressTypes = createSelector(selectContactAddressesItems, (addresses) => {
  let addressTypes = AddressType.getArray();
  addresses.forEach((address) => {
    if (!!address.addressTypeIds) {
      address.addressTypeIds.forEach((addressTypeId) => {
        const type = AddressType.getById(addressTypeId);
        addressTypes = addressTypes.filter((item) => item !== type);
      });
    }
  });
  return addressTypes;
});

export const selectAttachContactList = createSelector(selectCommon, selectContactsItems, (common, contacts) =>
  // filter out contacts that have already been attached
  common.attachContactList.filter((ac) => !contacts.find((c) => c.contactId === ac.contactId))
);
