import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../../reducers';
import { selectHasClientEditAdminOnly } from '../../../../store/selectors';
import { selectClientId, selectIsSuperSimplifierClient } from '../common/store/selectors';
import { Details } from './components/details';
import { selectMainDetailsItem } from './mainDetails/store/selectors';
import { fetchClientAccount } from './mainDetails/store/thunks';

const mapStateToProps = (state: RootState) => ({
  isSuperSimplifierClient: selectIsSuperSimplifierClient(state),
  clientId: selectClientId(state),
  mainDetails: selectMainDetailsItem(state),
  hasClientEditAdminOnlyPermission: selectHasClientEditAdminOnly(state),
});

const mapDispatchToProps = {
  fetchClientAccount,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector>;
export const DetailsContainer = connect(mapStateToProps, mapDispatchToProps)(Details);
