import { CheckCircleOutline, Delete, ErrorOutline, Info, RemoveRedEyeRounded } from '@mui/icons-material';
import InfoIcon from '@mui/icons-material/Info';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { formatNumberCommaSeparated, formatPercentage } from '../../../../../../common';
import { ClientSideDataTable } from '../../../../../../common/components/dataTable/clientSide';
import { DatatableColumn } from '../../../../../../common/components/dataTable/types';
import { LoadingProgress } from '../../../../../../common/store/types';
import { theme } from '../../../../../../themes';
import { feeGridHelpText, feesGridTooltipTexts } from '../../../../common/config/adviceFees';
import { FeeCalculationType, FeeMethod } from '../../../../common/enums';
import { AdviceEstimatedFee, FeeSourceType, PendingFeeActionType } from '../store/types';

export interface AdviceFeesTableProps {
  estimatedFeesItems: AdviceEstimatedFee[];
  loadingProgress: LoadingProgress;
  hideColumns?: string[];
  onAddEdit?: (feeCalculationTypeId: number, isViewMode: boolean) => void;
  onDelete?: (adviceEstimatedFee: AdviceEstimatedFee) => void;
  isSuperOrPension?: boolean;
}

export const AdviceFeesTable = (props: AdviceFeesTableProps): JSX.Element => {
  const { estimatedFeesItems, hideColumns, loadingProgress, onAddEdit, onDelete } = props;
  const isPending = !!estimatedFeesItems?.find((item) => item.isPending);
  const columnsToHide = isPending
    ? !!hideColumns
      ? [...hideColumns]
      : []
    : !!hideColumns
    ? [...hideColumns, 'change']
    : ['change'];

  const templateCodeColumn = (dataIndex: number): React.ReactNode => {
    return (
      <Typography variant="h5">
        {FeeCalculationType.getById(estimatedFeesItems[dataIndex]?.calculationTypeId || 0)?.id ===
        FeeCalculationType.TotalFee.id
          ? ''
          : estimatedFeesItems[dataIndex]?.templateCode || 'N/A'}
      </Typography>
    );
  };

  const feeNameColumn = (dataIndex: number): React.ReactNode => {
    return <Typography variant="h5">{estimatedFeesItems[dataIndex]?.name || ''}</Typography>;
  };

  const FeeCalculationTypeColumn = (dataIndex: number): React.ReactNode => {
    return (
      <Typography variant="h5">
        {FeeCalculationType.getById(estimatedFeesItems[dataIndex]?.calculationTypeId || 0)?.displayName || ''}
      </Typography>
    );
  };

  const calculationMethodColumn = (dataIndex: number): React.ReactNode => {
    return (
      <Typography variant="h5">
        {FeeMethod.getById(estimatedFeesItems[dataIndex]?.methodId || 0)?.displayName || ''}
      </Typography>
    );
  };

  const estimatedFeeColumn = (dataIndex: number): React.ReactNode => {
    const feeAmount = `$${formatNumberCommaSeparated(estimatedFeesItems[dataIndex]?.amount ?? 0, 2)}`;
    return <Typography variant="h5">{feeAmount}</Typography>;
  };

  const percentageOfValueColumn = (dataIndex: number): React.ReactNode => {
    const percentageOfValue = formatPercentage(
      (estimatedFeesItems[dataIndex]?.percentageOfValue ?? 0) / 100,
      '0.0000%',
      4
    );
    return <Typography variant="h5">{percentageOfValue}</Typography>;
  };

  const changeColumn = (dataIndex: number): React.ReactNode => {
    return <Typography variant="h5">{estimatedFeesItems[dataIndex]?.pendingAction || ''}</Typography>;
  };

  const validationColumn = (dataIndex: number): React.ReactNode => {
    return estimatedFeesItems[dataIndex]?.isValid ? (
      <CheckCircleOutline htmlColor={'LightGreen'}></CheckCircleOutline>
    ) : (
      <ErrorOutline htmlColor={'Red'}></ErrorOutline>
    );
  };

  const actionsColumn = (dataIndex: number): React.ReactNode => {
    if (FeeSourceType.isImportedFeeSource(estimatedFeesItems[dataIndex]?.feeSourceId || 0)) {
      return (
        <Box
          style={{ padding: '8px' }}
          data-testid={`importedInfoMessage_${isPending ? 'pending' : 'active'}_${dataIndex}`}
        >
          <Tooltip
            title={'Fee is configured for all clients. Update AFSL/Practice settings to update.'}
            placement={'top'}
            arrow
          >
            <Info style={{ width: '18px', height: '18px', marginLeft: '4px', fill: `${theme.palette.primary.main}` }} />
          </Tooltip>
        </Box>
      );
    }

    return (
      <>
        {!!onAddEdit && estimatedFeesItems[dataIndex]?.pendingAction !== PendingFeeActionType.Delete.name && (
          <IconButton
            type="button"
            data-testid={`editFeeButton`}
            disableFocusRipple
            disableRipple
            onClick={() => {
              onAddEdit(estimatedFeesItems[dataIndex].feeId, isPending ? false : true);
            }}
          >
           <RemoveRedEyeRounded />
          </IconButton>
        )}
        {!!onDelete &&
          (isPending) && (
            <IconButton
              type="button"
              data-testid={`deleteFeeButton`}
              disableFocusRipple
              disableRipple
              onClick={() => {
                if (!!estimatedFeesItems[dataIndex]) {
                  onDelete(estimatedFeesItems[dataIndex]);
                }
              }}
            >
                {<Delete />}
            </IconButton>
          )}
      </>
    );
  };

  let columns: DatatableColumn[] = [
    {
      name: 'templateCode',
      label: 'Template ID',
      textAlign: 'left',
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex): React.ReactNode => templateCodeColumn(dataIndex),
      },
    },
    {
      name: 'feeName',
      label: 'Fee Name',
      textAlign: 'left',
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex): React.ReactNode => feeNameColumn(dataIndex),
      },
    },
    {
      name: 'FeeCalculationType',
      label: 'Fee Type',
      textAlign: 'left',
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex): React.ReactNode => FeeCalculationTypeColumn(dataIndex),
      },
    },
    {
      name: 'calculationMethod',
      label: 'Method',
      textAlign: 'left',
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex): React.ReactNode => calculationMethodColumn(dataIndex),
      },
    },
    {
      name: 'estimatedFee',
      label: 'Est. Fee',
      textAlign: 'right',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex): React.ReactNode => estimatedFeeColumn(dataIndex),
        setCellProps: () => {
          return {
            style: {
              textAlign: 'right',
            },
          };
        },
      },
    },
    {
      name: 'percentageOfValue',
      label: '% of Value',
      textAlign: 'right',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex): React.ReactNode => percentageOfValueColumn(dataIndex),
        setCellProps: () => {
          return {
            style: {
              textAlign: 'right',
            },
          };
        },
      },
    },
    {
      name: 'change',
      label: 'Change',
      textAlign: 'left',
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex): React.ReactNode => changeColumn(dataIndex),
      },
    },
    {
      name: 'actions',
      label: 'Actions',
      textAlign: 'right',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex: number): React.ReactNode => actionsColumn(dataIndex),
      },
    },
    {
      name: 'validation',
      label: 'Validation',
      textAlign: 'right',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex: number): React.ReactNode => validationColumn(dataIndex),
      },
    },
  ];

  // remove columns needs to be hidden (exclude actions)
  if (columnsToHide.length > 0) {
    columns = columns.filter(
      (column: DatatableColumn) =>
        columnsToHide.map((hideColumn: string) => hideColumn.toLowerCase()).indexOf(column.name.toLowerCase()) === -1
    );
  }

  return (
    <div>
      <div style={{ width: '100%', minWidth: '900px', marginBottom: '20px' }}>
        <ClientSideDataTable
          columns={columns}
          loadingProgress={loadingProgress}
          data={estimatedFeesItems}
          options={{
            filter: false,
            pagination: false,
          }}
        />
      </div>
      {isPending && (
        <Box display={'flex'} alignItems={'center'} marginBottom={'80px'} height={'20px'}>
          <Tooltip
            title={
              <Box>
                {feesGridTooltipTexts.map((tooltipText, index) => (
                  <Typography key={index} variant="inherit" style={{ padding: '10px' }}>
                    {tooltipText}
                  </Typography>
                ))}
              </Box>
            }
            placement="right"
            arrow
          >
            <InfoIcon
              style={{ width: '18px', height: '18px', margin: '0 0 2px 2px', fill: `${theme.palette.primary.main}` }}
            />
          </Tooltip>
          <Typography variant="body1">&nbsp;{feeGridHelpText}</Typography>
        </Box>
      )}
    </div>
  );
};
