import { Enumeration } from 'src/common/types';
import { DateRange } from 'src/store';

export interface ClientNewReportsState {
  parameters: ClientReportsParameters;
  reportResult?: ReportResult | null;
  reportGeneration: ReportGeneration;
  savedParametersList: SavedParameter[];
  clientFinancialYears: CGTRecord[];
}

export enum MdaReportType {
  PortfolioValuation = 'Portfolio Valuation',
  CashTransactions = 'Cash Transactions',
  IncomeReport = 'Income Report',
  PortfolioSnapshot = 'Portfolio Snapshot',
  PortfolioPerformance = 'Portfolio Performance',
  SecurityContribution = 'Security Contribution',
  TransactionHistory = 'Transaction History',
  TaxSummary = 'Tax Summary',
  TaxInvoice = 'Tax Invoice',
  UnrealisedNonCGT = 'Unrealised Non CGT',
  RealisedCapitalGainsAndLosses = 'Realised Capital Gains & Losses',
  UnrealisedCapitalGainsAndLosses = 'Unrealised Capital Gains & Losses',
  AdditionsAndWithdrawals = 'Additions & Withdrawals',
  AssetClassPerformance = 'Asset Class Performance',
  HoldingsVsTargetVariance = 'Holdings vs Target Variance',
  Expenses = 'Expenses',
  SecurityPerformance = 'Security Performance',
  AssetAllocation = 'Asset Allocation',
  InvestmentIncomeTax = 'Investment Income Tax Component Report',
  MergeReport = 'Report',
}

export class MdaReportTypes extends Enumeration {
  static PortfolioValuation = new MdaReportTypes(1, 'Portfolio Valuation', MdaReportType.PortfolioValuation);
  static TransactionHistory = new MdaReportTypes(2, 'Transaction History', MdaReportType.TransactionHistory);
  static CashTransactions = new MdaReportTypes(3, 'Cash Transactions', MdaReportType.CashTransactions);
  static IncomeReport = new MdaReportTypes(4, 'Income Report', MdaReportType.IncomeReport);
  static RealisedCapitalGainsAndLosses = new MdaReportTypes(
    5,
    'RealisedCapitalGainsAndLosses',
    MdaReportType.RealisedCapitalGainsAndLosses
  );
  static UnrealisedCapitalGainsAndLosses = new MdaReportTypes(
    6,
    'UnrealisedCapitalGainsAndLosses',
    MdaReportType.UnrealisedCapitalGainsAndLosses
  );
  static Expenses = new MdaReportTypes(7, 'Expenses', MdaReportType.Expenses);
  static SecurityPerformance = new MdaReportTypes(8, 'Security Performance', MdaReportType.SecurityPerformance);
  static AssetAllocation = new MdaReportTypes(9, 'Asset Allocation', MdaReportType.AssetAllocation);
  static AdditionsAndWithdrawals = new MdaReportTypes(
    10,
    'AdditionsAndWithdrawals',
    MdaReportType.AdditionsAndWithdrawals
  );
  static AssetClassPerformance = new MdaReportTypes(11, 'AssetClassPerformance', MdaReportType.AssetClassPerformance);
  static HoldingsVsTargetVariance = new MdaReportTypes(
    12,
    'HoldingsVsTargetVariance',
    MdaReportType.HoldingsVsTargetVariance
  );
  static SecurityContribution = new MdaReportTypes(13, 'SecurityContribution', MdaReportType.SecurityContribution);
  static TaxSummary = new MdaReportTypes(14, 'TaxSummary', MdaReportType.TaxSummary);
  static TaxInvoice = new MdaReportTypes(15, 'TaxInvoice', MdaReportType.TaxInvoice);
  static PortfolioSnapshot = new MdaReportTypes(16, 'PortfolioSnapshot', MdaReportType.PortfolioSnapshot);
  static PortfolioPerformance = new MdaReportTypes(17, 'PortfolioPerformance', MdaReportType.PortfolioPerformance);
  static UnrealisedNonCgt = new MdaReportTypes(18, 'UnrealisedNonCgt', MdaReportType.UnrealisedNonCGT);
  static InvestmentIncomeTax = new MdaReportTypes(19, 'InvestmentIncomeTax', MdaReportType.InvestmentIncomeTax);
  static MergeReport = new MdaReportTypes(100, 'Report', MdaReportType.MergeReport);
}

export interface CGTRecord {
  fyDescription: string;
  fyStart?: Date;
  fyEnd?: Date;
  strategyDescription: string;
  statusDescription: string;
  currentYear: boolean;
  finalisedDate?: Date;
  platformCarryForwardLoss?: number;
  externalCarryForwardLoss?: number;
}

export type SelectedReport<T = unknown> = Omit<T, 'SelectedReport'> & { expanded?: boolean; selected?: boolean };

export type DateRangeBaseRequest = {
  dateRangeType?: string | null;
  fromDate: string;
  toDate: string;
};

export type AsAtDateBaseRequest = {
  asAtDateType?: string | null;
  asAtDate: string;
};

export interface AssetAllocation extends AsAtDateBaseRequest {
  portfolioId: number;
  includeAssetCategory: boolean;
  includeDetailTable: boolean;
  applyExclusionList: boolean;
}

export interface AssetClassPerformance extends DateRangeBaseRequest {
  excludeFrankingCredits: boolean;
  showAnnualisedReturns: boolean;
  applyExclusionList: boolean;
  excludeCapitalIncomeReturns: boolean;
  includeAllocationChange: boolean;
  includeReturnsSummary: boolean;
}

export interface HoldingsVsTargetVariance extends AsAtDateBaseRequest {
  applyExclusionList: boolean;
  includeSummary: boolean;
}

export interface CashTransactions extends DateRangeBaseRequest {
  sortBy: SortByCashTransactions;
}

export interface Expenses extends DateRangeBaseRequest {
  excludeExpenseType: boolean;
}

export interface RealisedCapitalGainsAndLosses extends DateRangeBaseRequest {
  includeSummary: boolean;
}

export interface IncomeReport extends DateRangeBaseRequest {
  groupBy: GroupByIncomeReport;
  sortBy: SortByIncomeReport;
  dateType: DateTypeIncomeReport;
  showOutstandingPaymentOnly: boolean;
  excludeCash: boolean;
}

export interface PortfolioPerformance extends DateRangeBaseRequest {
  benchmarkId: number;
  excludeFrankingCredits: boolean;
  showAnnualisedReturns: boolean;
  applyExclusionList: boolean;
  includeAfterFeesReturn: boolean;
}

export interface PortfolioSnapshot extends DateRangeBaseRequest {
  benchmarkId: number;
  portfolioId: number;
}

export interface PortfolioValuation extends AsAtDateBaseRequest {
  excludeCostBase: boolean;
  excludeMarket: boolean;
  excludeSecurityType: boolean;
  excludeGICS: boolean;
  excludeGainsLosses: boolean;
  excludeAvgUnitCost: boolean;
  excludeEstIncomeYield: boolean;
  excludeEstGrossIncomeYield: boolean;
  excludeFXGainsLosses: boolean;
  excludeLocalPriceValue: boolean;
  applyExclusionList: boolean;
  portfolioId: number;
  groupBy: GroupByPortfolioValuation;
  sortBy: SortByPortfolioValuation;
}

export interface RealisedCapitalGainsLosses extends DateRangeBaseRequest {
  incldueSummary: boolean;
}

export interface SecurityContribution extends DateRangeBaseRequest {
  groupBy: GroupBySecurityContribution;
  excludeFrankingCredits: boolean;
  showAnnualisedReturns: boolean;
  applyExclusionList: boolean;
  excludeCapitalIncomeReturns: boolean;
  includeDollarReturns: boolean;
  includeDetailTable: boolean;
}

export interface SecurityPerformance extends DateRangeBaseRequest {
  excludeFrankingCredits: boolean;
  showAnnualisedReturns: boolean;
  applyExclusionList: boolean;
  excludeCapitalIncomeReturns: boolean;
  groupBy: GroupBySecurityPerformance;
}

export interface TransactionHistory extends DateRangeBaseRequest {
  groupBy: GroupByTransactionHistory;
  sortBy: SortByTransactionHistory;
  excludeCash: boolean;
  includeDistribution: boolean;
  excludeAssetDescription: boolean;
  excludeAccounts: boolean;
  includeBrokerage: boolean;
  includeGST: boolean;
  includeOtherCharges: boolean;
}

export interface SaveReportingParametersRequest extends InitiateReportRequest {
  visibleToAfsl: boolean;
  parameterName?: string;
}

export interface InitiateReportRequest extends DateRangeBaseRequest {
  clientId: number;
  includeLogo: boolean;
  mergeReportOrder: number[];
  additionsAndWithdrawals?: DateRangeBaseRequest;
  assetAllocation?: AssetAllocation;
  assetClassPerformance?: AssetClassPerformance;
  holdingsVsTargetVariance?: HoldingsVsTargetVariance;
  cashTransactions?: CashTransactions;
  expenses?: Expenses;
  incomeReport?: IncomeReport;
  securityPerformance?: SecurityPerformance;
  portfolioSnapshot?: PortfolioSnapshot;
  portfolioPerformance?: PortfolioPerformance;
  portfolioValuation?: PortfolioValuation;
  securityContribution?: SecurityContribution;
  taxSummary?: DateRangeBaseRequest;
  taxInvoice?: DateRangeBaseRequest;
  investmentIncomeTaxComponent?: DateRangeBaseRequest;
  transactionHistory?: TransactionHistory;
  realisedCapitalGainsAndLosses?: RealisedCapitalGainsAndLosses;
  unrealisedCapitalGainsAndLosses?: AsAtDateBaseRequest;
  unrealisedNonCGT?: AsAtDateBaseRequest;
  includeCoverLetter: boolean;
  includeAppendix: boolean;
  isDataFromTriumph: boolean;
  coverLetter?: string;
  appendix?: string;
}

export interface ReportGeneration {
  isGenerating: boolean;
  requestId: string;
  isExpired: boolean;
  key: string;
  isDataFromTriumph: boolean;
  isMergeReport: boolean;
  clientId: number;
  clientName: string;
  isGenerated: boolean;
  mergeReportOrder: number[];
  dateRange: string;
  reportName: string;
}

export interface TempFileRef {
  fileRef: string;
}

export enum HideByAssetClassPerformance {
  FrankingCredits = 'excludeFrankingCredits',
  AnnualisedReturns = 'showAnnualisedReturns',
  ExclusionList = 'applyExclusionList',
  CapitalIncomeReturns = 'excludeCapitalIncomeReturns',
  AllocationChange = 'includeAllocationChange',
  ReturnsSummary = 'includeReturnsSummary',
}

export enum HideByTransactionHistory {
  Cash = 'excludeCash',
  Distribution = 'includeDistribution',
  AssetDescription = 'excludeAssetDescription',
  Accounts = 'excludeAccounts',
  Brokerage = 'includeBrokerage',
  Gst = 'includeGST',
  OtherCharges = 'includeOtherCharges',
}

export enum SortByTransactionHistory {
  Account = 'Account',
  TradeDate = 'TradeDate',
  SecurityName = 'SecurityName',
}

export enum GroupByTransactionHistory {
  Account = 'Account',
  TradeDate = 'TradeDate',
  SecurityName = 'SecurityName',
}

export enum HidingOptionSecurityPerformance {
  FrankingCredits = 'excludeFrankingCredits',
  AnnualisedReturns = 'showAnnualisedReturns',
  ExclusionList = 'applyExclusionList',
  CapitalIncomeReturn = 'excludeCapitalIncomeReturns',
}

export enum HidingOptionPortfolioPerformance {
  FrankingCredits = 'excludeFrankingCredits',
  AnnualisedReturns = 'showAnnualisedReturns',
  ExclusionList = 'applyExclusionList',
  AfterFeesReturn = 'includeAfterFeesReturn',
}

export enum FilterByTransactions {
  excludeCash = 'ExcludeCash',
  includeDistribution = 'IncludeDistribution',
}

export enum GroupBySecurityPerformance {
  AssetClass = 'AssetClass',
  Gics = 'GICS',
}

export enum GroupByIncomeReport {
  Account = 'Account',
  IncomeType = 'IncomeType',
}

export enum SortByCashTransactions {
  DateAscending = 'Ascending',
  DateDescending = 'Descending',
}

export enum DateTypeIncomeReport {
  ExDate = 'ExDate',
  TaxDate = 'TaxDate',
  PaymentDate = 'PaymentDate',
}

export enum SortByIncomeReport {
  Security = 'Security',
  PaymentDate = 'PaymentDate',
}

export enum HidingOptionHoldingsVsTargetVariance {
  ExclusionList = 'applyExclusionList',
  Summary = 'includeSummary',
}

export interface HidingOptionsRequest<T> {
  option: T;
  value: boolean;
}

export enum HidingOptionAssetAllocation {
  AssetCategory = 'includeAssetCategory',
  DetailTable = 'includeDetailTable',
  ExclusionList = 'applyExclusionList',
}

export enum HideByPortfolioValuation {
  SecurityType = 'excludeSecurityType',
  CostBase = 'excludeCostBase',
  Gics = 'excludeGICS',
  Market = 'excludeMarket',
  GainsLosses = 'excludeGainsLosses',
  AvgUnitCost = 'excludeAvgUnitCost',
  EstIncomeAndYield = 'excludeEstIncomeYield',
  EstGrossIncomeAndYield = 'excludeEstGrossIncomeYield',
  FxGainsLosses = 'excludeFXGainsLosses',
  LocalPriceAndValue = 'excludeLocalPriceValue',
  ExclusionList = 'applyExclusionList',
}

export enum HideBySecuriyContribution {
  FrankingCredit = 'excludeFrankingCredits',
  AnnualisedReturn = 'showAnnualisedReturns',
  ExclusionList = 'applyExclusionList',
  CapitalIncomeReturn = 'excludeCapitalIncomeReturns',
  DollarReturn = 'includeDollarReturns',
  DetailTable = 'includeDetailTable',
}

export enum SortByPortfolioValuation {
  SecurityType = 'SecurityType',
  SecurityName = 'SecurityName',
  Gics = 'GICS',
  Model = 'Model',
  Portfolio = 'Portfolio',
  AssetClass = 'AssetClass',
}

export enum GroupBySecurityContribution {
  AssetClass = 'AssetClass',
  Gics = 'GICS',
}

export enum GroupByPortfolioValuation {
  AssetClass = 'AssetClass',
  Gics = 'GICS',
  Portfolio = 'InvestmentService',
  Model = 'Model',
}

export interface SelectedReports {
  incomeReport: SelectedReport<IncomeReport>;
  assetClassPerformance: SelectedReport<AssetClassPerformance>;
  cashTransactions: SelectedReport<CashTransactions>;
  expenses: SelectedReport<Expenses>;
  unrealisedCapitalGainsAndLosses: SelectedReport<AsAtDateBaseRequest>;
  realisedCapitalGainsAndLosses: SelectedReport<RealisedCapitalGainsAndLosses>;
  securityPerformance: SelectedReport<SecurityPerformance>;
  portfolioValuation: SelectedReport<PortfolioValuation>;
  assetAllocation: SelectedReport<AssetAllocation>;
  additionsAndWithdrawals: SelectedReport<DateRangeBaseRequest>;
  holdingsVsTargetVariance: SelectedReport<HoldingsVsTargetVariance>;
  portfolioSnapshot: SelectedReport<PortfolioSnapshot>;
  taxInvoice: SelectedReport<DateRangeBaseRequest>;
  portfolioPerformance: SelectedReport<PortfolioPerformance>;
  securityContribution: SelectedReport<SecurityContribution>;
  taxSummary: SelectedReport<DateRangeBaseRequest>;
  investmentIncomeTaxComponent: SelectedReport<DateRangeBaseRequest>;
  unrealisedNonCGT: SelectedReport<AsAtDateBaseRequest>;
  transactionHistory: SelectedReport<TransactionHistory>;
}

export interface ClientReportsParameters {
  clientName: string;
  inceptionDate: string;
  showItemOrdering: boolean;
  dates: DateRange;
  isDataFromTriumph: boolean;
  mergeReports: boolean;
  includeLogo: boolean;
  reportingParameter: ReportingParameter;
  reportsToSort: ReportToSort[];
  expandedReports: boolean;
  selectedReports: SelectedReports;
  selectedParameter?: SavedParameter | null;
  includeCoverLetter: boolean;
  includeAppendix: boolean;
  coverLetter?: string;
  appendix?: string;
}

export interface ReportingParameter {
  visibleToAfsl: boolean;
  parameterName: string;
  isSaveReportParameters: boolean;
}

export interface ReportResult {
  data: [];
}

export interface ReportToSort {
  id: number;
  text: string;
}

export enum ReportAttachmentType {
  None = 'None',
  CoverLetter = 'Cover Letter',
  Appendix = 'Appendix',
}

export interface SavedParameter {
  id: string;
  name: string;
}

export interface FetchReportParametersRequest {
  requestId: string;
  afslId: number;
}

export interface ReportingParameters {
  clientId: number;
  includeLogo: boolean;
  fromDate: string;
  toDate: string;
  dateRangeType: string;
  additionalDocuments: {
    includeCoverLetter: boolean;
    includeAppendix: boolean;
    coverLetter?: string;
    appendix?: string;
  };
  mergeReportOrder: number[];
  cashTransactions?: {
    fromDate: string;
    toDate: string;
    dateRangeType: string;
  };
  expenses?: {
    hidingOptions: string;
    fromDate: string;
    toDate: string;
    dateRangeType: string;
  };
  transactionHistory?: {
    hidingOptions: string;
    filteringOptions: string;
    sortBy: SortByTransactionHistory;
    fromDate: string;
    toDate: string;
    dateRangeType: string;
  };
  assetClassPerformance?: {
    hidingOptions: string;
    fromDate: string;
    toDate: string;
    dateRangeType: string;
  };
  incomeReport?: {
    filteringOptions: string;
    groupBy: GroupByIncomeReport;
    sortBy: SortByIncomeReport;
    fromDate: string;
    toDate: string;
    dateRangeType: string;
  };
  portfolioValuation?: {
    hidingOptions: string;
    sortBy: SortByPortfolioValuation;
    groupBy: GroupByPortfolioValuation;
    asAtDate: string;
    asAtDateType: string;
  };
  assetAllocation?: {
    investmentProgramIds: number[];
    asAtDate: string;
    asAtDateType: string;
  };
  unrealisedCapitalGainsAndLosses?: {
    asAtDate: string;
    asAtDateType: string;
  };
  realisedCapitalGainsAndLosses?: {
    fromDate: string;
    toDate: string;
    dateRangeType: string;
  };
  securityPerformance?: {
    fromDate: string;
    toDate: string;
    dateRangeType: string;
  };
}
