import { Backdrop, Button, Fade, Modal, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import { theme } from 'src/themes';
interface Props {
  isOpen: boolean;
  isImporting: boolean;
  errors: string[];
  fileType: string;
  handleClose: () => void;
  handleImport: (file: File) => void;
}

export default function Importer({ isOpen, errors, fileType, handleClose, handleImport }: Props): JSX.Element {
  const [importErrors, setImportErrors] = useState<string[]>();
  const [isImporting, setImporting] = useState<boolean>(false);
  const [acceptedFiles, setAcceptedFiles] = useState<File[]>([]);

  const file = acceptedFiles.map((selectedFile) => (
    <span key={selectedFile.name}>
      {selectedFile.name} - {selectedFile.size} bytes
    </span>
  ));

  useEffect(() => {
    setImportErrors(errors);
  }, [errors]);

  const isImportingChanged = useCallback(() => {
    if (isImporting && !isImporting && (!errors || (errors && errors.length === 0))) {
      setImportErrors(undefined);
      acceptedFiles.length = 0;
      acceptedFiles.splice(0, acceptedFiles.length);
      handleClose();
    }
    setImporting(isImporting);
  }, [acceptedFiles, isImporting, errors, handleClose]);

  useEffect(isImportingChanged, [isImporting]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={isOpen}
      onClose={() => {
        handleClose();
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={isOpen}>
        <div
          style={{
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            padding: '20px 0 0 0', //theme.spacing(2, 4, 3),
            width: '634px',
            minHeight: '150px',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <Typography
            variant="h3"
            style={{
              height: '42px',
              color: theme.palette.text.primary,
              fontSize: '32px',
              fontWeight: 900,
              lineHeight: '44px',
              textAlign: 'center',
              padding: '10px',
              marginBottom: '24px',
            }}
          >
            Import {fileType}
          </Typography>
          <div
            style={{
              height: '18px',
              color: theme.palette.common.black,
              fontSize: '14px',
              fontWeight: 500,
              lineHeight: '18px',
              textAlign: 'left',
              margin: '20px',
            }}
          >
            File: {file}
          </div>
          <Dropzone onDrop={(files) => setAcceptedFiles(files)}>
            {({ getRootProps, getInputProps }) => (
              <div
                data-testid="dropzone"
                {...getRootProps()}
                style={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  padding: '20px',
                  borderRadius: '4px',
                  border: '2px dashed #eeeeee',
                  backgroundColor: '#fafafa',
                  color: '#bdbdbd',
                  outline: 'none',
                  transition: 'border .24s ease-in-out',
                  margin: '20px',
                }}
              >
                <input
                  {...getInputProps({
                    accept: fileType,
                    type: 'file',
                    onChange: () => setImportErrors(undefined),
                  })}
                />
                <p>Drag and drop your file here, or click to select file</p>
              </div>
            )}
          </Dropzone>
          {importErrors && importErrors.length > 0 && (
            <>
              <Typography variant="h5" style={{ marginLeft: '24px', color: 'red' }}>
                Import Errors:
              </Typography>
              <div
                style={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  padding: '12px',
                  border: '1px solid red',
                  borderRadius: '4px',
                  margin: '12px 24px',
                  width: 'calc(100% - 48px)',
                  overflowWrap: 'anywhere',
                  overflowY: 'scroll',
                  fontSize: '1rem',
                  fontFamily: 'monospace',
                  maxHeight: '40vh',
                }}
              >
                {importErrors}
              </div>
            </>
          )}
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              margin: 'auto',
              width: '380px',
              padding: '40px',
            }}
          >
            <Button
              variant="outlined"
              value="Cancel"
              onClick={() => {
                handleClose();
                setImportErrors(undefined);
                acceptedFiles.length = 0;
                acceptedFiles.splice(0, acceptedFiles.length);
              }}
              style={{
                marginRight: '24px',
                width: '170px',
                height: '48px',
                borderRadius: '60px',
                borderColor: theme.palette.primary.main,
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              value="Import"
              color="primary"
              disabled={!acceptedFiles || acceptedFiles.length !== 1}
              style={{
                width: '170px',
                borderRadius: '60px',
                height: '48px',
                borderColor: theme.palette.primary.main,
              }}
              onClick={() => {
                if (acceptedFiles && acceptedFiles.length === 1) {
                  handleImport(acceptedFiles[0]);
                }
              }}
            >
              Import
            </Button>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}
