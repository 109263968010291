import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { RootState } from '../../../reducers';
import { selectCompositionDetail } from '../composition/store/selectors';
import { selectModelDetail } from '../dashboard/store/selectors';
import { selectModelDetails } from '../details/store/selectors';
import { ModelTabs } from './components/ModelTabs';
import { selectCommonDetails } from './store/selectors';
import { modelCommonSlice } from './store/slice';
import { fetchHasEditModelAccess, fetchModelVersions } from './store/thunks';

const mapStateToProps = (state: RootState) => ({
  dashboardDetail: selectModelDetail(state),
  compositionDetail: selectCompositionDetail(state),
  modelDetails: selectModelDetails(state),
  common: selectCommonDetails(state),
});

const mapDispatchToProps = {
  fetchModelVersions,
  fetchHasEditModelAccess,
  setModelVersion: modelCommonSlice.actions.setModelVersion,
};

interface MatchParams {
  id?: string;
  modelId: string;
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector> & RouteComponentProps<MatchParams>;
export const CommonContainer = connect(mapStateToProps, mapDispatchToProps)(ModelTabs);
