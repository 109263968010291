import {
  Check,
  EditOutlined,
  Link as AttachContactIcon,
  LinkOff as DettachContactIcon,
  MoreVertOutlined,
  PeopleAlt as RolesIcon,
  PersonAdd,
} from '@mui/icons-material';
import { Avatar, IconButton, Tooltip, Typography } from '@mui/material';
import Alert from '@mui/material/Alert';
import React, { useEffect, useState } from 'react';
import { getAvatarText, WO2Menu, WO2MenuItem } from '../../../../../../../common';
import { ClientSideDataTable } from '../../../../../../../common/components/dataTable/clientSide';
import { DatatableColumn, FilterDataType } from '../../../../../../../common/components/dataTable/types';
import { useConfirmation } from '../../../../../../../common/components/dialogs';
import { useDebounce } from '../../../../../../../common/hooks';
import { LoadingProgress } from '../../../../../../../common/store/types';
import history from '../../../../../../../history';
import { theme } from '../../../../../../../themes';
import { useStyles } from '../../../../../../../themes/index';
import { AttachableContact, ContactRoles } from '../../../../../common/types';
import {
  AttachContactPayload,
  ContactDetails,
  DetachContactPayload,
  FetchContactPayload,
  FetchContactsToAttachPayload,
  Role,
  UpdateContactRolesPayload,
} from '../../../store/types';
import { AttachContact, AttachContactDialogPayload } from './attachContact';
import { EditRoles, EditRolesSavePayload } from './editRoles';

export interface ListProps {
  hasClientEditPermission: boolean;
  fetchContacts: (clientId: number) => void;
  fetchContact: (payload: FetchContactPayload) => void;
  contact: ContactDetails | undefined;
  updateContactRoles: (payload: UpdateContactRolesPayload) => void;
  clientId: number | null;
  contacts: ContactRoles[];
  loadingContactsProgress: LoadingProgress;
  contactsToAttach: AttachableContact[];
  roles: Role[];
  fetchContactsToAttach: (payload: FetchContactsToAttachPayload) => void;
  loadingContactsToAttach: LoadingProgress;
  fetchRoles: () => void;
  loadingRolesProgress: LoadingProgress;
  detachContact: (payload: DetachContactPayload) => void;
  attachContact: (payload: AttachContactPayload) => void;
  updateContactRolesProgress: LoadingProgress;
  match: { url: string };
  primaryContactRequired: boolean | undefined;
}

export const List = ({
  hasClientEditPermission,
  fetchContacts,
  fetchContact,
  contact,
  updateContactRoles,
  clientId,
  contacts,
  loadingContactsProgress,
  contactsToAttach,
  roles,
  fetchContactsToAttach,
  loadingContactsToAttach,
  fetchRoles,
  loadingRolesProgress,
  detachContact,
  attachContact,
  updateContactRolesProgress,
  match: { url },
  primaryContactRequired,
}: ListProps): JSX.Element => {
  const confirm = useConfirmation();
  const classes = useStyles();
  const [contactSearchText, setContactSearchText] = useState<string>('');
  const onContactSearchTextChange = useDebounce<string>(contactSearchText, 500);
  const [attachContactOpen, setAttachContactOpen] = useState<boolean>(false);
  const [rolesDialogOpen, setRolesDialogOpen] = useState<boolean>(false);

  const primaryContact = contacts.some((item) => item.isPrimary === true);

  useEffect(() => {
    if (!!clientId) {
      fetchContacts(clientId);
    }
  }, [fetchContacts, clientId]);

  useEffect(() => {
    if (onContactSearchTextChange && onContactSearchTextChange.length > 2 && !!clientId) {
      fetchContactsToAttach({ parentId: clientId, searchText: onContactSearchTextChange });
    }
  }, [onContactSearchTextChange, fetchContactsToAttach, clientId]);

  const nameColumn = (dataIndex: number): React.ReactNode => (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        marginRight: '10px',
      }}
    >
      <Avatar style={{ backgroundColor: theme.palette.primary.main, marginRight: '10px' }}>
        <Typography variant="h5"> {getAvatarText(`${contacts[dataIndex]?.name}`)}</Typography>
      </Avatar>
      <Typography variant="h5">{contacts[dataIndex]?.name}</Typography>
    </div>
  );

  const rolesColumn = (dataIndex: number): React.ReactNode => {
    return <Typography variant="h5">{contacts[dataIndex]?.roles.join(', ')}</Typography>;
  };

  const isPrimaryColumn = (dataIndex: number): React.ReactNode => <Typography variant="h5">{contacts[dataIndex]?.isPrimary && <Check></Check>}</Typography>;

  const actionsColumn = (dataIndex: number): React.ReactNode => {
    let isAdvisor = false;

    if (!!contacts[dataIndex]) {
      isAdvisor = !!contacts[dataIndex]?.roles.find((roleName) => roleName === 'Adviser');
    }

    const menuItems: WO2MenuItem[] = [
      {
        icon: <EditOutlined />,
        isHidden: isAdvisor,
        label: 'Edit Contact',
        onClick: () => {
          history.push(`${url}/contact/${contacts[dataIndex]?.id}`);
        },
        testId: `editContact_${dataIndex}`,
      },
      {
        icon: <DettachContactIcon />,
        isHidden: contacts[dataIndex]?.isPrimary || isAdvisor,
        label: 'Detach Contact',
        onClick: () => {
          confirm({
            title: 'Detach contact',
            description: 'Are you sure you we to detach this contact?',
          }).then(() => {
            if (!!contacts[dataIndex]?.id && clientId !== null) {
              detachContact({
                parentId: clientId,
                contactId: contacts[dataIndex]?.id,
              });
            }
          });
        },
        testId: `detachContact_${dataIndex}`,
      },
      {
        icon: <RolesIcon />,
        label: 'Edit Contact Role',
        isHidden: true, //don't need this now the roles are on edit page
        onClick: () => {
          if (!!contacts[dataIndex] && !!clientId) {
            const contactId = contacts[dataIndex].id;
            fetchContact({ clientId, contactId });
            setRolesDialogOpen(true);
          }
        },
        testId: `editContactRole_${dataIndex}`,
      },
    ];

    return menuItems.filter((i) => !i.isHidden).length > 0 ? (
      <WO2Menu testId={`contactActions_${dataIndex}`} buttonTitle="Contact Actions" buttonIcon={<MoreVertOutlined color={'primary'} />} items={menuItems} />
    ) : (
      <></>
    );
  };

  const columns: DatatableColumn[] = [
    {
      filterDataType: FilterDataType.string,
      name: 'name',
      label: 'NAME',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex): React.ReactNode => nameColumn(dataIndex),
      },
    },
    {
      name: 'roles',
      label: 'ROLES',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex): React.ReactNode => rolesColumn(dataIndex),
      },
    },
    {
      name: 'isPrimary',
      label: 'IS PRIMARY CONTACT',
      options: {
        filter: false,
        sort: true,
        customBodyRenderLite: (dataIndex): React.ReactNode => isPrimaryColumn(dataIndex),
      },
    },
    {
      name: 'actions',
      label: ' ',
      textAlign: 'right',
      options: {
        filter: false,
        sort: false,
        viewColumns: false,
        customBodyRenderLite: (dataIndex): React.ReactNode => actionsColumn(dataIndex),
      },
    },
  ];

  const addButtons = (): React.ReactNode => {
    return (
      <>
        {hasClientEditPermission && (
          <>
            <Tooltip title="Create contact" arrow>
              <IconButton
                className={classes.addToTable}
                disableFocusRipple
                disableRipple
                onClick={() => {
                  history.push(`${url}/contact`);
                }}
              >
                <PersonAdd />
              </IconButton>
            </Tooltip>

            <Tooltip title="Attach existing contact" arrow>
              <IconButton
                data-testid="attachContactButton"
                className={classes.addToTable}
                onClick={() => {
                  setAttachContactOpen(true);
                }}
              >
                <AttachContactIcon />
              </IconButton>
            </Tooltip>
          </>
        )}
      </>
    );
  };

  const onFetchContactsToAttach = (searchText: string) => {
    setContactSearchText(searchText);
  };

  const onAttachContact = (payload: AttachContactDialogPayload) => {
    if (!!clientId) {
      attachContact({ ...payload, clientId });
      setAttachContactOpen(false);
    }
  };

  const onEditContactRoles = (payload: EditRolesSavePayload) => {
    if (!!clientId) {
      updateContactRoles({ ...payload, clientId });
      setRolesDialogOpen(false);
    }
  };

  return (
    <>
      <Typography variant="h4" gutterBottom style={{ paddingTop: '15px ' }}>
        Contacts
      </Typography>
      {primaryContactRequired && !!contacts.length && !primaryContact && (
        <Alert variant="outlined" severity="warning" style={{ marginBottom: '10px' }}>
          <Typography>One of the contacts must be specified as &#34;Primary Contact&#34;</Typography>
        </Alert>
      )}
      <ClientSideDataTable
        loadingProgress={loadingContactsProgress}
        columns={hasClientEditPermission ? columns : columns.filter((c) => c.name !== 'actions')}
        data={contacts}
        options={{
          filter: false,
          pagination: true,
          customToolbar: () => addButtons(),
        }}
        id="client-contacts-datatable"
      ></ClientSideDataTable>
      {attachContactOpen && (
        <AttachContact
          contacts={contactsToAttach}
          contactsProgress={loadingContactsToAttach}
          roles={roles}
          loadingRoles={loadingRolesProgress}
          isOpen={attachContactOpen}
          handleCloseModal={() => setAttachContactOpen(false)}
          onSave={(payload: AttachContactDialogPayload) => onAttachContact(payload)}
          fetchRoles={fetchRoles}
          fetchContactsToAttach={onFetchContactsToAttach}
        ></AttachContact>
      )}
      {!!contact && (rolesDialogOpen || updateContactRolesProgress.isLoading) && (
        <EditRoles
          contact={contact}
          roles={roles}
          rolesProgress={loadingRolesProgress}
          isOpen={rolesDialogOpen || updateContactRolesProgress.isLoading}
          handleCloseModal={() => setRolesDialogOpen(false)}
          onSave={(payload: EditRolesSavePayload) => onEditContactRoles(payload)}
          fetchRoles={fetchRoles}
          saveProgress={updateContactRolesProgress}
          primaryContactId={contacts.find((c) => c.isPrimary)?.id}
        ></EditRoles>
      )}
    </>
  );
};
