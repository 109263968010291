import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchSecurityExclusion } from './thunks';
import { ExclusionsState, SecurityExclusion } from './types';

export const initialState: ExclusionsState = {
  securityExclusions: [],
};

export const exclusionsSlice = createSlice({
  name: '@@client/investmentServices/exclusions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSecurityExclusion.fulfilled, (state: ExclusionsState, action: PayloadAction<SecurityExclusion[]>) => {
      state.securityExclusions = action.payload;
    });
  },
});
