import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../../reducers';
import {
  selectHasClientEditAdminOnly,
  selectHasRebalanceEnhancedTrading,
  selectIsRebalanceDataSourceTriumph,
} from '../../../../store/selectors';
import CheckoutComponent from './components/checkout';
import {
  selectBulkOrderPadRequest,
  selectComments,
  selectIsSubmitted,
  selectSecurity,
  selectTotals,
  selectTrades,
  selectValidationMessages,
} from './store/selectors';
import { checkoutSlice } from './store/slice';
import { confirmBulkOrder } from './store/thunks';

const mapStateToProps = (state: RootState) => ({
  order: selectBulkOrderPadRequest(state),
  security: selectSecurity(state),
  comments: selectComments(state),
  trades: selectTrades(state),
  totals: selectTotals(state),
  validationMessages: selectValidationMessages(state),
  isSubmitted: selectIsSubmitted(state),
  hasRebalanceEnhancedTrading: selectHasRebalanceEnhancedTrading(state),
  hasClientEditAdminOnlyPermission: selectHasClientEditAdminOnly(state),
  isRebalanceDataSourceTriumph: selectIsRebalanceDataSourceTriumph(state),
});

const mapDispatchToProps = {
  ...checkoutSlice.actions,
  confirmBulkOrder,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector>;
export const CheckoutContainer = connect(mapStateToProps, mapDispatchToProps)(CheckoutComponent);
