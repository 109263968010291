import { createAsyncThunk } from '@reduxjs/toolkit';
import FileSaver from 'file-saver';
import { unparse } from 'papaparse';
import api from '../../../../app/api';
import { buildEncodedQueryString, convertToCSVFormat, CSVDataType, downloadCsv } from '../../../../common';
import { PagedResult } from '../../../../store';
import { DownloadSecurityPricePayload, SecurityListParameters, SecurityPriceItem, SecurityPriceStatistics, ThunkParameters } from './types';

export enum SecurityPriceActionTypes {
  FetchSecurityPriceStatistics = '@@security/price/FetchSecurityPriceStatistics',
  FetchSecurityPrices = '@@security/price/FetchSecurityPrices',
  DownloadSecurityPrices = '@@security/price/DownloadSecurityPrices',
  FetchDailySecurityPricesChart = '@@security/price/FetchDailySecurityPricesChart',
  FetchSecurityPricesDownloadAll = '@@security/price/FetchSecurityPricesDownloadAll',
}

export enum SecurityPriceApiEndpoints {
  fetchSecurityPriceStatistics = '/securities/GetSecurityPriceStatistics',
  fetchSecurityPrices = '/api/performances/security/GetPrices',
  fetchDailySecurityPricesChart = '/api/performances/security/GetDailyPricesChart',
}

export const fetchSecurityPriceStatistics = createAsyncThunk(SecurityPriceActionTypes.FetchSecurityPriceStatistics, async (wrapper: ThunkParameters) => {
  const response = await api.get<SecurityPriceStatistics>(
    `${SecurityPriceApiEndpoints.fetchSecurityPriceStatistics}?securityId=${wrapper.securityId}&fromDate=${wrapper.dates.dateFrom}&toDate=${wrapper.dates.dateTo}`
  );
  return response.data;
});

export const fetchSecurityPriceChart = createAsyncThunk(SecurityPriceActionTypes.FetchDailySecurityPricesChart, async (wrapper: ThunkParameters) => {
  const response = await api.get<SecurityPriceItem[]>(
    `${SecurityPriceApiEndpoints.fetchDailySecurityPricesChart}?securityId=${wrapper.securityId}&fromDate=${wrapper.dates.dateFrom}&toDate=${wrapper.dates.dateTo}`
  );
  return response.data;
});

export const fetchSecurityPrices = createAsyncThunk(SecurityPriceActionTypes.FetchSecurityPrices, async (wrapper: SecurityListParameters) => {
  const queryString = buildEncodedQueryString({ pagedRequest: wrapper.pagination, securityId: wrapper.securityId });
  const response = await api.get<PagedResult<SecurityPriceItem>>(`${SecurityPriceApiEndpoints.fetchSecurityPrices}${queryString}`);
  return {
    results: response.data,
    pagination: wrapper.pagination,
  };
});

export const fetchSecurityPricesExport = createAsyncThunk(SecurityPriceActionTypes.FetchSecurityPricesDownloadAll, async (wrapper: SecurityListParameters) => {
  const queryString = buildEncodedQueryString({ pagedRequest: wrapper.pagination, securityId: wrapper.securityId });

  const response = await api.get<PagedResult<SecurityPriceItem>>(`${SecurityPriceApiEndpoints.fetchSecurityPrices}${queryString}`);

  if (response) {
    const csv = unparse(response.data.results);
    const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    FileSaver.saveAs(csvData, 'WO2_security_prices.csv');
  }
});

const getSecurityPrices = async (request: SecurityListParameters) => {
  const queryString = buildEncodedQueryString({
    pagedRequest: request.pagination,
    securityId: request.securityId,
  });
  return await api.get<PagedResult<SecurityPriceItem>>(`${SecurityPriceApiEndpoints.fetchSecurityPrices}${queryString}`);
};

export const downloadSecurityPrices = createAsyncThunk(SecurityPriceActionTypes.DownloadSecurityPrices, async (wrapper: DownloadSecurityPricePayload) => {
  const response = await getSecurityPrices({ ...wrapper, pagination: { ...wrapper.pagination, pageNumber: 1, pageSize: 9999999 } });
  const data: CSVDataType[] = convertToCSVFormat(response.data.results);

  downloadCsv(data, `SecurityPrices_`);
  return { message: 'CSV downloaded' };
});
