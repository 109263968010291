import { Breadcrumbs, Container, Link, Typography } from '@mui/material';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Props } from '../container';
import { Parameters } from './parameters';
import { Template } from './template';

export const SendEmail = (props: Props & RouteComponentProps): JSX.Element => {
  return (
    <Container style={{ paddingTop: '40px', paddingBottom: '40px' }}>
      <Typography variant="h2" style={{ display: 'inline-flex', width: '100%', height: '48px' }}>
        Bulk
      </Typography>
      <Breadcrumbs aria-label="breadcrumb">
        <Link color="inherit" href="/bulk/email">
          Bulk
        </Link>
        <Typography color="textPrimary">Email</Typography>
      </Breadcrumbs>
      <Container style={{ display: 'flex', padding: '30px 0 0 0' }}>
        <Parameters />
        <Container>
          <Template {...props} />
        </Container>
      </Container>
    </Container>
  );
};
