import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DateTimeFormat, getLocalDateTime } from 'src/common';
import { BeneficiaryViewModel } from '../store/types';

export interface Props {
  beneficiariesDetails: BeneficiaryViewModel[];
}

export const BeneficiariesTable = ({ beneficiariesDetails }: Props): JSX.Element => {
  const [beneficiaries, setBeneficiaries] = useState<BeneficiaryViewModel[]>([]);
  useEffect(() => {
    setBeneficiaries(beneficiariesDetails);
  }, [beneficiariesDetails]);

  return (
    <Paper elevation={5} style={{ marginBottom: '30px' }}>
      <TableContainer component={Paper} elevation={0}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left" style={{ width: '25%' }}>
                <Typography variant="h6" color="textSecondary">
                  NAME
                </Typography>
              </TableCell>
              <TableCell align="left" style={{ width: '21%' }}>
                <Typography variant="h6" color="textSecondary">
                  RELATIONSHIP
                </Typography>
              </TableCell>
              <TableCell align="left" style={{ width: '20%' }}>
                <Typography variant="h6" color="textSecondary">
                  TYPE
                </Typography>
              </TableCell>
              <TableCell align="left" style={{ width: '10%' }}>
                <Typography variant="h6" color="textSecondary">
                  PERCENTAGE
                </Typography>
              </TableCell>
              <TableCell align="left" style={{ width: '12%' }}>
                <Typography variant="h6" color="textSecondary">
                  START DATE
                </Typography>
              </TableCell>
              <TableCell align="left" style={{ width: '12%' }}>
                <Typography variant="h6" color="textSecondary">
                  END DATE
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {beneficiaries?.map((beneficiary, index) => (
              <TableRow
                key={`beneficiary${index}${beneficiary.name}${beneficiary.relationship}`}
                style={{ fontWeight: 'bold' }}
              >
                <TableCell align="left">
                  <Typography data-testid="beneficiaryNameData" variant="h5">
                    {beneficiary.name}
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography data-testid="beneficiaryRelationshipData" variant="h5">
                    {beneficiary.relationship}
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography data-testid="beneficiaryBindingFlagData" variant="h5">
                    {beneficiary.type}
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography data-testid="beneficiaryPercentageData" variant="h5">
                    {beneficiary.percentage}
                    {beneficiary.percentage === '-' ? '' : '%'}
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography data-testid="beneficiaryStartDateData" variant="h5">
                    {!!beneficiary.startDate ? getLocalDateTime(beneficiary.startDate, DateTimeFormat.Short) : '-'}
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography data-testid="beneficiaryEndDateData" variant="h5">
                    {!!beneficiary.endDate ? getLocalDateTime(beneficiary.endDate, DateTimeFormat.Short) : '-'}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};
