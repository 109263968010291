import { Alert, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { PagedRequest } from 'src/store';
import { Props } from '../container';
import { DownloadSSTransactionsPayload, fetchSSTransactionPayload, SSTransaction, SSTransactionCsvRow } from '../store/types';
import { TransactionTable } from './transactionTable';
import { LoadingIndicator } from 'src/common';

export const Transactions = ({
  transactions,
  parameters,
  loadingProgress,
  fetchPagedTransactions,
  downloadTransactions,
  setAccountId,
  accountId,
}: Props): JSX.Element => {
  useEffect(() => {
    if (!!parameters && parameters.accountId !== '' && !!accountId && accountId !== '') {
      const payload: fetchSSTransactionPayload = {
        inputs: parameters,
        pagination: parameters.pagination,
      };
      fetchPagedTransactions(payload);
    }
  }, [fetchPagedTransactions, parameters]);

  useEffect(() => {
    if (!!accountId) {
      setAccountId(accountId);
    }
  }, [accountId]);

  const handleGridActions = (pagedRequest: PagedRequest) => {
    fetchPagedTransactions({
      inputs: {
        pagination: pagedRequest,
        accountId: parameters.accountId,
      },
      pagination: pagedRequest,
    });
  };

  return (
    <>
      <Typography variant="h3" style={{ padding: '20px 0' }}>
        <b>Administrator Transactions</b>
      </Typography>
      
      <LoadingIndicator progress={loadingProgress}>
      {loadingProgress.hasErrors = false}
      {transactions === null || transactions === undefined || transactions.results.length === 0 ? (
        <Alert style={{ marginRight: '20px', marginTop: '20px', marginBottom: '20px' }} variant="outlined" severity="info">
          <Typography>
            There is currently no administrator transactions information for this member. If the member has been recently established it may take a few days for
            this data to be populated.
          </Typography>
        </Alert>
      ) : (
        <>
          <TransactionTable
            handleDownload={(pagedRequest: PagedRequest, mapper: (t: SSTransaction[]) => SSTransactionCsvRow[]) => {
              const downloadPayload: DownloadSSTransactionsPayload = {
                inputs: parameters,
                pagination: pagedRequest,
                mapper,
              };
              downloadTransactions(downloadPayload);
            }}
            handlePagedRequest={handleGridActions}
            pagedRequest={parameters.pagination}
            pagedResults={transactions || undefined}
            progress={loadingProgress}
          ></TransactionTable>
        </>
      )}
        </LoadingIndicator>
    </>
  );
};
