import { connect, ConnectedProps } from 'react-redux';
import { progressSelectorFactory } from 'src/common';
import { RootState } from 'src/reducers';
import { selectSuperMemberNumber } from '../store/selectors';
import { Transactions } from './components/transactions';
import { selectTransactions, selectTransactionsParameters } from './store/selectors';
import { transactionSlice } from './store/slice';
import { downloadTransactions, fetchPagedTransactions, TransactionActionTypes } from './store/thunks';

const mapStateToProps = (state: RootState) => ({
  transactions: selectTransactions(state),
  parameters: selectTransactionsParameters(state),
  loadingProgress: progressSelectorFactory([TransactionActionTypes.FetchPagedTransactions])(state),
  accountId: selectSuperMemberNumber(state),
});

const mapDispatchToProps = {
  ...transactionSlice.actions,
  fetchPagedTransactions,
  downloadTransactions,
  // return {
  //   setAccountId: (payload: string | null) => dispatch(transactionSlice.actions.setAccountId(payload)),
  //   fetchPagedTransactions: (payload: fetchSSTransactionPayload) => fetchPagedTransactions(payload),
  //   downloadTransactions: (payload: DownloadSSTransactionsPayload) => downloadTransactions(payload),
  // };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector>;
export const TransactionsContainer = connector(Transactions);
