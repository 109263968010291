import { Enumeration } from './enumeration';

export class SecurityStatus extends Enumeration {
  static Active = new SecurityStatus(1, 'Active', 'Active');
  static Inactive = new SecurityStatus(2, 'Inactive', 'Inactive');
  static Matured = new SecurityStatus(3, 'Matured', 'Matured');
  static Pending = new SecurityStatus(4, 'Pending', 'Pending');
  static Sold = new SecurityStatus(5, 'Sold', 'Sold');
  static Terminated = new SecurityStatus(7, 'Terminated', 'Terminated');
  static Suspended = new SecurityStatus(8, 'Suspended', 'Suspended');
  static Removed = new SecurityStatus(9, 'Removed', 'Removed');
  static Open = new SecurityStatus(10, 'Open', 'Open');
  static Archived = new SecurityStatus(11, 'Archived', 'Archived');
  static DocumentsRequired = new SecurityStatus(12, 'DocumentsRequired', 'Documents required');
  static Proforma = new SecurityStatus(13, 'Proforma', 'Proforma');
  static WaitingForApproval = new SecurityStatus(14, 'WaitingForApproval', 'Waiting for Approval');
  static DatafeedActive = new SecurityStatus(15, 'DatafeedActive', 'Datafeed active');
  static Delisted = new SecurityStatus(16, 'Delisted', 'Delisted');
  static Closed = new SecurityStatus(17, 'Closed', 'Closed');
  static Dormant = new SecurityStatus(18, 'Dormant', 'Dormant');

  static getById = (id: number): SecurityStatus | null => {
    return Object.values(SecurityStatus)
      .filter((t) => t instanceof SecurityStatus)
      .find((t: SecurityStatus) => t.id === id);
  };
}
