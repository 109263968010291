import { createSelector } from '@reduxjs/toolkit';
import { selectClientMainDetails } from 'src/features/clients/client/common/store';
import { selectCGTConfiguration } from '../../store';

export const selectBasicCGTConfiguration = createSelector(selectCGTConfiguration, (cgtConfiguration) => {
  return cgtConfiguration.basicCGTConfiguration;
});

export const selectClientCGTRecords = createSelector(selectBasicCGTConfiguration, (basicCGTConfiguration) => {
  return basicCGTConfiguration.cgtFinancialYearRecords;
});

export const selectCGTStratigies = createSelector(selectBasicCGTConfiguration, (basicCGTConfiguration) => {
  return basicCGTConfiguration.strategyTypes;
});

export const selectCurrentMatchingStrategy = createSelector(selectClientMainDetails, (mainDetails) => {
  return mainDetails?.currentCGTStrategyId;
});
