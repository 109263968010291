import { TabRoute } from '../../../store';
import { CapitalGainsAndLossesContainer } from './capitalgainslosses/container';
import { ClientDashboardContainer } from './dashboard/container';
import { DetailsContainer } from './details/container';
import { GainsLossesContainer } from './gainslosses/container';
import { PerformanceContainer } from './performance/container';
import { PortfolioContainer } from './portfolio/container';
import { ReportsContainer } from './reports/container';
import { ReportsV2Container } from './reportsV2/container';
import { TransactionsStateContainer } from './transactions/container';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getClientTabRoutes = (hasClientEditAdminOnlyPermission: boolean): TabRoute[] => [
  {
    label: 'DASHBOARD',
    order: 0,
    path: '/dashboard',
    component: ClientDashboardContainer,
    visible: (): boolean => true,
  },
  {
    label: 'PORTFOLIO',
    order: 1,
    path: '/portfolio',
    component: PortfolioContainer,
    visible: (): boolean => true,
  },
  {
    label: 'PERFORMANCE',
    order: 2,
    path: '/performance',
    component: PerformanceContainer,
    visible: (): boolean => true,
  },
  {
    label: 'GAINS & LOSSES',
    order: 3,
    path: '/gainslosses',
    component: GainsLossesContainer,
    visible: (hasClientEditAdminOnlyPermission) => !!hasClientEditAdminOnlyPermission,
  },
  {
    label: 'CAPITAL GAINS & LOSSES',
    order: 4,
    path: '/capitalgainslosses',
    component: CapitalGainsAndLossesContainer,
    visible: (): boolean => true,
  },
  {
    label: 'TRANSACTIONS',
    order: 5,
    path: '/transactions',
    component: TransactionsStateContainer,
    visible: (): boolean => true,
  },
  {
    label: 'DETAILS',
    order: 6,
    path: '/details',
    component: DetailsContainer,
    visible: (): boolean => true,
  },
  {
    label: 'REPORTS',
    order: 7,
    path: '/reports',
    component: ReportsContainer,
    visible: (): boolean => true,
  },
  {
    label: 'NEW REPORTS',
    order: 8,
    path: '/new-reports',
    component: ReportsV2Container,
    visible: (hasClientEditAdminOnlyPermission): boolean =>
      !!hasClientEditAdminOnlyPermission &&
      process.env.REACT_APP_ENV !== 'production' &&
      process.env.REACT_APP_ENV !== 'staging',
  },
];
