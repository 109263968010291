import { createSelector } from '@reduxjs/toolkit';
import { AddressType } from '../../../common/store/enums';
import { selectAddresses } from '../../store/selectors';

export const selectAddressItems = createSelector(selectAddresses, (addresses) => addresses.items);

export const selectEditAddress = createSelector(selectAddresses, (addresses) => {
  if (addresses.edit === undefined) {
    return undefined;
  }
  return addresses.items.find((address) => address.addressId === addresses.edit) ?? null;
});

export const selectAvailableAddressTypes = createSelector(selectAddressItems, (addresses) => {
  let addressTypes = AddressType.getArray();

  addresses.forEach((address) => {
    if (!!address.addressTypeIds) {
      address.addressTypeIds.forEach((addressTypeId) => {
        const type = AddressType.getById(addressTypeId);
        addressTypes = addressTypes.filter((item) => item !== type);
      });
    }
  });
  return addressTypes;
});
