import { AccountBalanceWalletOutlined } from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';
import React from 'react';
import { DialogActionButton, formatDollars, formatNumberCommaSeparated, WO2Modal, WO2TextInput } from '../../../../../common';
import { theme } from '../../../../../themes';
import { TransactionDetails as TransactionDetailsType } from '../store/types';
import { TransactionDetailsTabs } from './transactionDetailsTabs';

export interface Props {
  isOpen: boolean;
  transaction: TransactionDetailsType;
  handleCloseModal: () => void;
}

export const TransactionDetails = (props: Props): JSX.Element => {
  
  const getTransactionTypeColorCode = (code: string): string => {
    if (!code) {
      return theme.palette.common.black;
    }

    switch (code.toLowerCase()) {
      case 'buy':
      case 'deposit':
        return theme.palette.green.main;
      case 'sale/redemption':
      case 'withdrawal':
        return theme.palette.error.main;
      default:
        return theme.palette.common.black;
    }
  };

  const dialogActionButtons: DialogActionButton[] = [
    {
      label: 'Cancel',
      onClickHandler: () => {
        props.handleCloseModal();
      },
    },
  ];

  return (
    <WO2Modal
      MuiDialogProps={{
        open: props.isOpen,
        onClose: props.handleCloseModal,
      }}
      title={
        <>
          <AccountBalanceWalletOutlined style={{ float: 'left', marginRight: '12px' }} />
          TRANSACTION DETAILS
        </>
      }
      actions={dialogActionButtons}
    >
      <Grid container spacing={2}>
        <Grid item xs={9}>
          <Typography
            variant="h4"
            align={'left'}
            color={'primary'}
            style={{ textTransform: 'uppercase', marginBottom: '16px', marginTop: '16px', fontWeight: 'bold', letterSpacing: '1px' }}
          >
            {props.transaction?.securityCode}
          </Typography>
          <Typography variant="h5" align={'left'}>
            {props.transaction?.securityName}
          </Typography>
          <Typography
            variant="h5"
            align={'left'}
            style={{
              color: getTransactionTypeColorCode(props.transaction?.systemTransactionType),
            }}
          >
            {props.transaction?.systemTransactionType}
          </Typography>
          <Typography variant="h5" align={'left'}>
            {!!props.transaction?.units ? `${formatNumberCommaSeparated(props.transaction?.units)} units ` : ''}
            {!!props.transaction?.unitPrice ? ` @ ${formatDollars(props.transaction?.unitPrice)}` : ''}
          </Typography>
        </Grid>
        <Grid
          item
          xs={3}
          style={{
            display: 'flex',
            alignItems: 'flex-end',
            alignContent: 'end',
            justifyContent: 'flex-end',
            flexDirection: 'row',
          }}
        >
          <Typography variant="h3" align={'right'}>
            {formatDollars(props.transaction?.transactionValue)}
          </Typography>
        </Grid>
        <Grid item xs={12} >
          <TransactionDetailsTabs transaction={props.transaction} testId=""/>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" align={'left'} style={{ textTransform: 'uppercase', marginBottom: '12px', marginTop: '12px', fontWeight: 'bold' }}>
            Comments
          </Typography>
          <WO2TextInput disabled={true} value={props.transaction?.comments} multiline={true} fullWidth={true} style={{ marginBottom: '24px' }} />
        </Grid>
      </Grid>
    </WO2Modal>
  );
};
