import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'src/app/api';
import { convertToCSVFormat, CSVDataType, downloadCsv } from 'src/common';
import { PagedResult } from 'src/store';
import { fetchPagedResults, fetchSSTransactionPayload, SSTransaction } from './types';

export enum TransactionActionTypes {
  FetchPagedTransactions = '@@client/superSimplifier/Transactions/FetchPagedTransactions',
  DownloadTransactions = '@@client/superSimplifier/Transactions/DownloadTransactions',
}

export enum TransactionApiEndPoints {
  fetchPagedTransactions = '/ssc/GetCashTransactions',
}

export const fetchPagedTransactions = createAsyncThunk(TransactionActionTypes.FetchPagedTransactions, async (wrapper: fetchSSTransactionPayload) => {
  const response = await getPagedTransaction(wrapper);
  return {
    results: response.data,
    pagination: wrapper.pagination,
  } as fetchPagedResults<SSTransaction>;
});

export const downloadTransactions = createAsyncThunk(TransactionActionTypes.DownloadTransactions, async (wrapper: fetchSSTransactionPayload) => {
  const response = await getPagedTransaction({ ...wrapper, pagination: { ...wrapper.pagination, pageNumber: 1, pageSize: 9999999 } });
  const csvData: CSVDataType[] = convertToCSVFormat(response.data.results);

  downloadCsv(csvData, 'Transactions_');

  return { message: 'CSV downloaded' };
});

const getPagedTransaction = async (request: fetchSSTransactionPayload) => {
    const pagination = { ...request.pagination };
    if (pagination.queryFields.length == 0) {
        pagination.queryFields = [
            {
                fieldName: 'date',
                descendingSortDirection: true,
                operator: '',
                hasSearchTerm: false,
                isSortTerm: true,
                searchTerm: '',
            },
        ]
    }

    const body = {
        accountId: request.inputs.accountId,
        pagedRequest: { ...pagination, pageNumber: request.pagination.pageNumber },
    };
    return await api.post<PagedResult<SSTransaction>>(TransactionApiEndPoints.fetchPagedTransactions, body);
};
