import { Card, Container } from '@mui/material';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React from 'react';
import {
  DateTimeFormat,
  formatPercentage,
  getLocalDateTime,
  totalsChartHeader,
  yearsDiff,
} from '../../../../../common';
import { LoadingIndicator } from '../../../../../common/components/LoadingIndicator';
import { LoadingProgress } from '../../../../../common/store/types';
import { theme } from '../../../../../themes';
import { BenchmarkPerformanceResult, Frequency, PerformanceChartResult } from '../store/types';

export interface Props {
  performanceChartProgress: LoadingProgress;
  frequency: Frequency;
  performanceChartResults?: PerformanceChartResult;
  benchmarkPerformanceResults?: BenchmarkPerformanceResult | null;
}

export const PerformanceChart = (props: Props): JSX.Element => {
  const { performanceChartProgress, performanceChartResults, benchmarkPerformanceResults, frequency } = props;

  const dateFormat = frequency === Frequency.Daily ? DateTimeFormat.ChartDayDate : DateTimeFormat.ChartMonthDate;
  const categories = performanceChartResults?.seriesData?.map((i) => getLocalDateTime(i.date, dateFormat)) ?? [];
  const returns = performanceChartResults?.seriesData?.map((i) => i.return) ?? [0];
  const returnValues = returns.map((i) => ({
    y: i,
    color: i > 0 ? theme.palette.secondary.main : theme.palette.error.main,
  }));

  // Chart data
  const cumulativeValues = performanceChartResults?.seriesData?.map((i) => i.cumulativeReturn) ?? [0];
  // benchmark data does not contain weekends so need to map by index of the category
  const benchmarkPerformanceValues = (benchmarkPerformanceResults?.seriesData ?? [])
    .filter((i) => categories.indexOf(getLocalDateTime(i.date, dateFormat)) >= 0)
    .map((i) => {
      const date = getLocalDateTime(i.date, dateFormat);
      const index = categories.indexOf(date, 0);

      return [index, i.cumulativeReturn];
    });

  const startDate = performanceChartResults?.seriesData[0]?.date ?? '';
  const endDate = performanceChartResults?.seriesData[performanceChartResults?.seriesData.length - 1]?.date ?? '';

  const chartHeader = totalsChartHeader(
    startDate,
    endDate,
    performanceChartResults?.changeInValuePercentage ?? 0,
    performanceChartResults?.changeInValue
  );

  const chartOptions = {
    chart: {
      renderTo: 'container',
      backgroundColor: 'white',
      plotBackgroundColor: 'transparent',
      height: '400px',
      marginLeft: 50,
    },
    plotOptions: {
      series: {
        allowPointSelect: false,
        groupPadding: 0,
        pointPadding: 0.1,
        borderWidth: 0,
      },
    },
    title: {
      text: 'Portfolio Performance',
      align: 'left',
      style: {
        fontFamily: theme.typography.fontFamily,
        fontSize: '22px',
        fontWeight: 400,
        fill: '#000',
      },
    },
    subtitle: {
      text: chartHeader,
      align: 'left',
      useHTML: true,
      style: {
        fontFamily: theme.typography.fontFamily,
        fontSize: '18px',
        fontWeight: 400,
      },
    },
    xAxis: [
      {
        gridLineColor: 'transparent',
        categories,
      },
    ],
    yAxis: [
      {
        title: { text: undefined },
        gridLineColor: theme.palette.lightGrey.main,
        gridLineWidth: 1,
        lineColor: 'transparent',
        opposite: true,
        labels: {
          formatter: function (this: { value: string }) {
            const percentageAsNumber = parseFloat(this.value);

            return formatPercentage(percentageAsNumber);
          },
        },
        plotLines: [
          {
            color: theme.palette.graphBackground?.main,
            width: 0.5,
            value: 0,
          },
        ],
      },
    ],
    tooltip: {
      shared: true,
      useHTML: true,
      formatter: function (this: Highcharts.TooltipFormatterContextObject) {
        const header = '<div style="font-family: Avenir;">';
        const points = this.points?.reduce(function (s: string, point: Highcharts.TooltipFormatterContextObject) {
          return (
            s +
            '<br/><span style="color:' +
            point.color +
            '">\u25CF</span>  ' +
            point.series.name +
            ': ' +
            formatPercentage(point.y)
          );
        }, '<b>' + this.x + '</b>');
        const footer = '</div>';

        return header + points + footer;
      },
    },
    series: [
      {
        color: theme.palette.primary.dark,
        name: 'Benchmark Performance',
        type: 'line',
        data: benchmarkPerformanceValues,
        dashStyle: 'ShortDash',
        zIndex: 100,
        states: {
          inactive: {
            opacity: 1,
          },
        },
        marker: {
          enabled: false,
        },
      },
      {
        color: theme.palette.primary.main,
        name: 'Cumulative Return',
        type: 'line',
        dashStyle: 'Solid',
        data: cumulativeValues,
        zIndex: 100,
        states: {
          inactive: {
            opacity: 1,
          },
        },
        marker: {
          enabled: false,
        },
      },
      {
        showInLegend: false,
        name: 'Return',
        type: 'column',
        data: returnValues,
        states: {
          inactive: {
            opacity: 1,
          },
        },
      },
    ],
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
  };

  return (
    <LoadingIndicator progress={performanceChartProgress}>
      <Card square elevation={0} style={{ background: theme.palette.common.white, padding: '30px 0' }}>
        <Container fixed>
          <HighchartsReact highcharts={Highcharts} options={chartOptions} />
        </Container>
        {yearsDiff(startDate, endDate) > 1 && (
          <div style={{ padding: '20px 40px 0 30px', fontSize: '0.8em', fontStyle: 'italic' }}>
            * The annualised return is calculated as ((1 + TR)^(1/Y) - 1) x 100 and gives the portfolio&apos;s
            annualised return for the charted date range, where Y is the number of years covered in the charted period
            and TR is the total return over the same period.
          </div>
        )}
      </Card>
    </LoadingIndicator>
  );
};
