import { createAsyncThunk } from '@reduxjs/toolkit';
import api, { ApiError } from '../../../../../../app/api';
import {
  DeleteAbandonedRebalancesByClientRequest,
  RebalancePortfolioRequest,
  RebalancePortfolioResponse,
} from './types';

export enum RebalanceActionTypes {
  RebalanceTemplate = '@@client/investmentServices/rebalancesmanual/Initiate',
  DeleteAbandonedRebalances = '@@client/investmentServices/rebalancesmanual/DeleteAbandonedRebalances',
}

export enum RebalanceApiEndpoints {
  RebalanceTemplate = '/rebalancesmanual/Initiate',
  DeleteAbandonedRebalances = '/rebalancesmanual/DeleteAbandonedRebalances',
}

export const deleteAbandonedRebalances = createAsyncThunk<void, DeleteAbandonedRebalancesByClientRequest>(
  RebalanceActionTypes.DeleteAbandonedRebalances,
  async (payload, thunkApi) => {
    try {
      return await api.post(RebalanceApiEndpoints.DeleteAbandonedRebalances, payload);
    } catch (e) {
      return thunkApi.rejectWithValue(JSON.stringify(e));
    }
  }
);

export const rebalanceTemplate = createAsyncThunk<RebalancePortfolioResponse, RebalancePortfolioRequest>(
  RebalanceActionTypes.RebalanceTemplate,
  async (payload, thunkApi) => {
    return api
      .post<RebalancePortfolioResponse | string>(RebalanceApiEndpoints.RebalanceTemplate, payload)
      .then((response) => {
        if (typeof response.data === 'string' || response.data instanceof String) {
          return {
            message: 'Rebalance initialisation complete',
            rebalanceId: response.data as string,
            validationMessages: [],
          };
        }
        return response.data;
      })
      .catch((error: ApiError) => {
        if (error.name === 'wrapped-error') {
          return { message: '', rebalanceId: null, validationMessages: [error.message || ''] };
        }
        return thunkApi.rejectWithValue({ message: 'Could not initiate rebalance at this time.', variant: 'error' });
      });
  }
);
