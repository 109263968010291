import { Enumeration } from '../types';

export class AssetTypeEnum extends Enumeration {
  static Security = new AssetTypeEnum(1, 'Security', 'Security');
  static Model = new AssetTypeEnum(2, 'Model', 'Model');
  static Strategy = new AssetTypeEnum(3, 'Strategy', 'Strategy');

  static getById = (status: number | undefined): AssetTypeEnum | undefined => {
    return Object.values(AssetTypeEnum)
      .filter((t) => t instanceof AssetTypeEnum)
      .find((t: AssetTypeEnum) => t.id === status);
  };

  static getByName = (name: string): AssetTypeEnum | undefined => {
    return Object.values(AssetTypeEnum)
      .filter((t) => t instanceof AssetTypeEnum)
      .find((t: AssetTypeEnum) => t.name.toLowerCase() === name.toLowerCase());
  };

  static getAll = (): AssetTypeEnum[] => {
    return Object.values(AssetTypeEnum)
      .filter((t) => !!t['name'])
      .map((val) => {
        return { id: val.id, name: val.name, displayName: val.displayName };
      });
  };
}
