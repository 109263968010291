import { Alert, Box, Link, Typography } from '@mui/material';
import React from 'react';
import { RebalanceValidationRequest, ValidateRebalanceSuccessPayload, ValidationResult } from '../store/types';
import { ADVISER_CONTACT_EMAIL } from 'src/common/utils/dashContactDetails';
import { useHistory } from 'react-router-dom';

interface Props {
  validationMessages: ValidationResult;
  setRebalanceValidated: (payload: ValidateRebalanceSuccessPayload) => void;
  rebalanceValidationRequest: RebalanceValidationRequest;
}

export default function ValidationMessages(props: Props): JSX.Element {
  const { validationMessages } = props;

  const history = useHistory();

  const renderWarningInformationIssues = () => {
    if (validationMessages.errors && validationMessages.errors.length > 0) {
      return <Typography>The following trade(s) are outside the portfolio&#39;s parameters.</Typography>;
    }
    return (
      <Typography>
        The following trade(s) are outside the portfolio&#39;s parameters. You can update them or proceed to the trading
        checkout{' '}
        <Link onClick={() => history.push('/rebalance/checkout')}>here</Link>.
      </Typography>
    );
  };

  return (
    <Box marginTop={'15px'} marginBottom={'15px'} marginLeft={'10px'} marginRight={'10px'}>
      {validationMessages && validationMessages.errors && validationMessages.errors.length > 0 && (
        <Alert style={{ marginRight: '20px' }} variant="outlined" severity="error">
          <Typography>
            The following trade(s) require your attention. Please review and update them before submitting again. Please
            contact us on 1300 726 008 or <a href={`mailto: ${ADVISER_CONTACT_EMAIL}`}>{ADVISER_CONTACT_EMAIL}</a> for
            assistance.
          </Typography>
          <ul style={{ textAlign: 'left' }}>
            {validationMessages.errors.map((errors) => (
              <li key={errors}>{errors}</li>
            ))}
          </ul>
        </Alert>
      )}
      {validationMessages && validationMessages.warnings && validationMessages.warnings.length > 0 && (
        <Alert style={{ marginRight: '20px' }} variant="outlined" severity="warning">
          {renderWarningInformationIssues()}

          <ul style={{ textAlign: 'left' }}>
            {validationMessages.warnings.map((warning) => (
              <li key={warning}>{warning}</li>
            ))}
          </ul>
        </Alert>
      )}
      {validationMessages && validationMessages.information && validationMessages.information.length > 0 && (
        <Alert style={{ marginRight: '20px' }} variant="outlined" severity="info">
          {renderWarningInformationIssues()}

          <ul style={{ textAlign: 'left' }}>
            {validationMessages.information.map((information) => (
              <li key={information}>{information}</li>
            ))}
          </ul>
        </Alert>
      )}
    </Box>
  );
}
