import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '0px 24px 0px 24px',
  },

  root: {
    backgroundColor: 'white',
    marginTop: theme.spacing(2),
    overflowX: 'auto',
    padding: '0 0 10px 0',
    margin: '0 10px 10px 10px',
  },
  alignRight: {
    textAlign: 'right',
  },
  lineItemBlackText: {
    height: '16px',
    color: theme.palette.secondary.main,
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '16px',
    paddingBottom: '8px',
  },
  redText: {
    height: '19px',
    color: theme.palette.error.main,
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '21px',
  },
  StyledTableCellBlue: {
    width: '30%',
    height: '50px',
    paddingBottom: '20px',
  },
  table: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    alignItems: 'center',
    padding: '0 25px',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.light,
    height: '80px',
  },
  tableRow: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    alignItems: 'center',
    width: '1446px',
    padding: 0,
    margin: 0,
  },
  tableRowItems: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    alignItems: 'center',
    width: '1472px',
    height: '70px',
  },
  doubleWidthTableCell: {
    width: '280px',
    padding: '20 0',
    margin: '20 0',
    fontSize: 14,
    textAlign: 'left',
  },
  tableCell: {
    width: '120px',
    padding: '20 0',
    margin: '20 0',
    fontSize: 14,
    textAlign: 'left',
  },
  childGroupPanelDetailsRoot: {
    padding: '0px',
  },
  container: {
    maxHeight: 440,
  },
  filterContainer: {
    display: 'flex',
    marginBottom: '10px',
    justifyContent: 'space-between',
  },
  filterLabel: {
    textAlign: 'left',
    alignItems: 'center',
    display: 'flex',
    fontWeight: 400,
    fontFamily: 'AvenirBook',
    width: 150,
  },
  select: {
    width: '180px',
    backgroundColor: 'white',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    marginLeft: 0,
    backgroundColor: 'white',
  },
  apply: {
    borderRadius: 60,
    height: 48,
    width: 156,
    fontFamily: 'AvenirBook',
    borderColor: theme.palette.primary.main,
    boxShadow: 'none',
    textTransform: 'none',
    fontWeight: 600,
  },
  tr: {
    position: 'relative',
    '&:not(:first-of-type)&::after': {
      position: 'absolute',
      content: "''",
      left: '15px',
      right: '15px',
    },
  },
  trClickable: {
    position: 'relative',
    '&:not(:first-of-type)&::after': {
      position: 'absolute',
      content: "''",
      left: '15px',
      right: '15px',
    },
    '&:hover': {
      backgroundColor: '#f1f5ff !important',
      cursor: 'pointer',
    },
  },
  td: {
    root: {
      borderBottom: 'none',
    },
  },
  thead: {
    borderTop: '1px solid #ebebeb',
  },
  headerHorizontalAlignRight: {
    '& span': {
      justifyContent: 'right',
      '& button': {
        marginRight: '-8px',
        '& span': {
          '&.MuiTableSortLabel-root': {
            marginRight: '-12px',
          },
        },
        '& div[class*="MUIDataTableHeadCell-sortAction"]': {
          '& div[class*="MUIDataTableHeadCell-data"]': {
            textAlign: 'right',
          },
        },
      },
    },
  },
}));
