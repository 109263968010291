import { createAsyncThunk } from '@reduxjs/toolkit';
import { buildEncodedQueryString } from 'src/common';
import api from '../../../../../app/api';
import { ModelItem } from '../../../common/store/types';
import { OrderSearchParameters, OrderSearchResult } from './types';

export enum OrderSearchActionTypes {
  FetchResults = '@@bulk/order/search/FetchResults',
  FetchModels = '@@bulk/common/FetchModels',
}

export enum OrderSearchApi {
  FetchModels = '/strategies/GetModelList',
  FetchResults = '/bff/GetFilteredPortfolios',
}

export const fetchResults = createAsyncThunk(
  OrderSearchActionTypes.FetchResults,
  async (parameters: OrderSearchParameters) => {
    const queryString = buildEncodedQueryString({
      afslIds: parameters.afsls.map((i) => i.afslId),
      practiceIds: parameters.practices.map((i) => i.practiceId),
      adviserIds: parameters.advisers.map((i) => i.advisorId),
      clientTypes: parameters.clientTypes,
      serviceType: parameters.serviceType,
      portfolioValueFrom: parameters.portfolioValueFrom,
      portfolioValueTo: parameters.portfolioValueTo,
      availableCashBalanceFrom: parameters.availableCashBalanceFrom,
      availableCashBalanceTo: parameters.availableCashBalanceTo,
      securityHeld: parameters.securityHeld,
      securityId: parameters.security ? parameters.security.securityId : null,
      modelId: parameters.model ? parameters.model.modelId : null,
      isDataFromTriumph: parameters.isRebalanceDataSourceTriumph,
    });

    const response = await api.get<OrderSearchResult[]>(`${OrderSearchApi.FetchResults}${queryString}`);
    return response.data;
  }
);

export const fetchModels = createAsyncThunk(OrderSearchActionTypes.FetchModels, async (afslIds?: number[]) => {
  const response = await api.post(`${OrderSearchApi.FetchModels}`, !!afslIds ? afslIds : []);
  return Promise.resolve<ModelItem[]>(response.data);
});
