import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';
import { SystemMinimumDate } from 'src/common';
import { DateRange } from 'src/store';
import { AdviserItem, AfslItem, ModelItem, PracticeItem, SecurityItem } from '../../../common/store/types';
import { fetchModels, fetchResults, fetchSecurities } from './thunks';
import { ReportSearchResult, ReportSearchState, SecurityHeld, SecurityModelAssociation } from './types';

export const initialState: ReportSearchState = {
  parameters: {
    afsls: [],
    practices: [],
    advisers: [],
    serviceType: 'All',
    clientTypes: [],
    statuses: [],
    securityHeld: SecurityHeld.Hold.name,
    security: null,
    securitySearch: null,
    model: null,
    holdingsValue: {
      parameterType: '',
      fromValue: null,
      toValue: null,
    },
    availableCashBalance: {
      parameterType: '',
      fromValue: null,
      toValue: null,
    },
    servicesAgreementDate: {
      dateRangeType: 'Inception To Date',
      dateFrom: DateTime.fromISO(SystemMinimumDate).toISODate(),
      dateTo: DateTime.now().toISODate(),
    },
    inceptionDate: {
      dateRangeType: 'Inception To Date',
      dateFrom: DateTime.fromISO(SystemMinimumDate).toISODate(),
      dateTo: DateTime.now().toISODate(),
    },
    holdingsDate: {
      dateRangeType: "Today's Date",
      dateFrom: DateTime.fromISO(SystemMinimumDate).toISODate(),
      dateTo: DateTime.now().toISODate(),
    },
    associations: [],
  },
  securities: [],
  models: [],
  results: [],
  selectedResults: [],
};

export const searchSlice = createSlice({
  name: '@@bulk/report/search',
  initialState,
  reducers: {
    setAfslParameter: (state, action: PayloadAction<AfslItem[]>) => {
      state.parameters.afsls = action.payload;
    },
    setPracticeParameter: (state, action: PayloadAction<PracticeItem[]>) => {
      state.parameters.practices = action.payload;
    },
    setAdviserParameter: (state, action: PayloadAction<AdviserItem[]>) => {
      state.parameters.advisers = action.payload;
    },
    setClientTypesParameter: (state, action: PayloadAction<string[]>) => {
      state.parameters.clientTypes = action.payload;
    },
    setStatusesParameter: (state, action: PayloadAction<string[]>) => {
      state.parameters.statuses = action.payload;
    },
    setServiceTypeParameter: (state, action: PayloadAction<string>) => {
      state.parameters.serviceType = action.payload;
    },
    setSecurityHeld: (state, action: PayloadAction<string>) => {
      state.parameters.securityHeld = action.payload;
    },
    setSecurity: (state, action: PayloadAction<SecurityItem | null>) => {
      state.parameters.security = action.payload;
    },
    setSecuritySearch: (state, action: PayloadAction<string | null>) => {
      state.parameters.securitySearch = action.payload;
    },
    setModel: (state, action: PayloadAction<ModelItem | null>) => {
      state.parameters.model = action.payload;
    },
    setSelectedResults: (state, action: PayloadAction<number[]>) => {
      state.selectedResults = action.payload;
    },
    setHoldingsValueOperator: (state, action: PayloadAction<string>) => {
      state.parameters.holdingsValue = {
        ...state.parameters.holdingsValue,
        parameterType: action.payload,
      };
    },
    setHoldingsFromValue: (state, action: PayloadAction<number | null>) => {
      state.parameters.holdingsValue = {
        ...state.parameters.holdingsValue,
        fromValue: action.payload,
      };
    },
    setHoldingsToValue: (state, action: PayloadAction<number | null>) => {
      state.parameters.holdingsValue = {
        ...state.parameters.holdingsValue,
        toValue: action.payload,
      };
    },
    setAvailableCashBalanceOperator: (state, action: PayloadAction<string>) => {
      state.parameters.availableCashBalance = {
        ...state.parameters.availableCashBalance,
        parameterType: action.payload,
      };
    },
    setAvailableCashBalanceFromValue: (state, action: PayloadAction<number | null>) => {
      state.parameters.availableCashBalance = {
        ...state.parameters.availableCashBalance,
        fromValue: action.payload,
      };
    },
    setAvailableCashBalanceToValue: (state, action: PayloadAction<number | null>) => {
      state.parameters.availableCashBalance = {
        ...state.parameters.availableCashBalance,
        toValue: action.payload,
      };
    },
    setServicesAgreementDate: (state, action: PayloadAction<DateRange>) => {
      state.parameters.servicesAgreementDate = action.payload;
    },
    setInceptionDate: (state, action: PayloadAction<DateRange>) => {
      state.parameters.inceptionDate = action.payload;
    },
    setHoldingsDate: (state, action: PayloadAction<DateRange>) => {
      state.parameters.holdingsDate = action.payload;
    },
    addAssociation: (state, action: PayloadAction<SecurityModelAssociation>) => {
      const exists = state.parameters.associations.filter((x) => x.id === action.payload.id).length > 0;
      if (exists) return;
      state.parameters.associations.push(action.payload);
    },
    removeAssociation: (state, action: PayloadAction<SecurityModelAssociation>) => {
      const index = state.parameters.associations.findIndex((x) => x.id === action.payload.id);
      if (index === -1) return;
      state.parameters.associations.splice(index, 1);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchResults.fulfilled, (state, action: PayloadAction<ReportSearchResult[]>) => {
      state.results = action.payload;
    });

    builder.addCase(fetchSecurities.fulfilled, (state, action: PayloadAction<SecurityItem[]>) => {
      state.securities = action.payload;
    });

    builder.addCase(fetchModels.pending, (state) => {
      state.models = [];
    });

    builder.addCase(fetchModels.fulfilled, (state, action: PayloadAction<ModelItem[]>) => {
      state.models = action.payload;
    });
  },
});
