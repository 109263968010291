import { Alert, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React from 'react';
import { ResultWrapper } from '../../benefitStatement/store/types';
import { PensionBankAccountDetails } from '../store/types';

export interface Props {
  detail: ResultWrapper<PensionBankAccountDetails | null>;
}

export const PensionBankDetails = ({ detail }: Props): JSX.Element => {
  return (
    <>
      <Typography variant="h4" style={{ paddingBottom: '20px' }}>
        Pension Bank Account
      </Typography>

      {detail.status === 200 ? (
        <Paper elevation={5} style={{ marginBottom: '30px' }}>
          <TableContainer component={Paper} elevation={0}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="left" style={{ width: '70%' }}>
                    <Typography variant="h6" color="textSecondary">
                      DESCRIPTION
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="h6" color="textSecondary">
                      VALUE
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="left">
                    <Typography variant="h5">BSB</Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography data-testid="pensionBankDetailsBSB" variant="h5">
                      {detail?.responseData?.bsb}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">
                    <Typography variant="h5">Account Number</Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography data-testid="pensionBankDetailsAccountNumber" variant="h5">
                      {detail?.responseData?.accountNo}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">
                    <Typography variant="h5">Account Name</Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography data-testid="pensionBankDetailsAccountName" variant="h5">
                      {detail?.responseData?.accountName}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      ) : (
        <Alert style={{ marginRight: '20px', marginTop: '20px', marginBottom: '20px' }} variant="outlined" severity="info">
          <Typography>
            There is currently no Pension bank detail information for this member. If the member has been recently established it may take a few days for this
            data to be populated.
          </Typography>
        </Alert>
      )}
    </>
  );
};
