import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../../../../../app/api';
import { buildEncodedQueryString } from '../../../../../../../common';
import { commonSlice, fetchInvestmentServiceVersionsByClient } from '../../../store/common';
import {
  Configuration,
  DefaultSecurity,
  GetConfigurationsPayload,
  GetDefaultSecuritiesPayload,
  GetStrategicAllocationPayload,
  SaveConfigurationsPayload,
  SaveDefaultSecuritiesPayload,
  SaveStrategicAllocationPayload,
  StrategicAssetAllocation,
} from './types';

export enum ConfigurationsActionTypes {
  FetchConfigurations = '@@client/investmentServices/configurations/Fetch',
  SaveConfigurations = '@@client/investmentServices/configurations/Save',
  DeleteConfigurations = '@@client/investmentServices/configurations/Delete',
}

export enum ConfigurationsApiEndpoints {
  FetchConfigurations = '/investmentservices/GetInvestmentServiceConfigurations',
  SaveConfigurations = '/investmentservices/CreateOrUpdateConfigurations ',
}

export enum StrategicAllocationActionTypes {
  FetchStrategicAllocations = '@@client/investmentServices/StrategicAllocation/Fetch',
  UpdateStrategicAllocation = '@@client/investmentServices/StrategicAllocation/Update',
}

export enum StrategicAllocationApiEndpoints {
  FetchStrategicAllocations = '/investmentservices/GetInvestmentServiceStrategicAllocations',
  UpdateStrategicAllocation = '/investmentservices/CreateOrUpdateStrategicAssetAllocations',
}

export enum DefaultSecuritiesActionTypes {
  FetchDefaultSecurities = '@@client/investmentServices/DefaultSecurities/Fetch',
  UpdateDefaultSecurities = '@@client/investmentServices/DefaultSecurities/Update',
}

export enum DefaultSecuritiesApiEndpoints {
  FetchDefaultSecurities = '/investmentservices/GetInvestmentServiceDefaultSecurities',
  UpdateDefaultSecurities = '/investmentservices/CreateOrUpdateDefaultSecurities',
}

export const fetchConfigurations = createAsyncThunk(
  ConfigurationsActionTypes.FetchConfigurations,
  async ({ investmentServiceVersionId, clientId }: GetConfigurationsPayload) => {
    const queryString = buildEncodedQueryString({
      investmentServiceVersionId,
      clientId,
    });
    const response = await api.get<Configuration[]>(`${ConfigurationsApiEndpoints.FetchConfigurations}${queryString}`);

    return response.data;
  }
);

export const saveConfigurations = createAsyncThunk(ConfigurationsActionTypes.SaveConfigurations, async (payload: SaveConfigurationsPayload, thunkApi) => {
  return api
    .post<number>(ConfigurationsApiEndpoints.SaveConfigurations, payload)
    .then(async (response) => {
      thunkApi.dispatch(commonSlice.actions.setInvestmentServiceVersionId(response.data));
      await thunkApi.dispatch(fetchConfigurations({ clientId: payload.clientId, investmentServiceVersionId: payload.investmentServiceVersionId }));
      await thunkApi.dispatch(
        fetchInvestmentServiceVersionsByClient({
          clientId: payload.clientId,
          showLatestVersionOnly: false,
        })
      );

      return thunkApi.fulfillWithValue({ message: 'Configurations saved' });
    })
    .catch(() => {
      return thunkApi.rejectWithValue({ message: 'Could not save configurations at this time.', variant: 'error' });
    });
});

export const fetchStrategicAllocations = createAsyncThunk(
  StrategicAllocationActionTypes.FetchStrategicAllocations,
  async ({ investmentServiceVersionId, clientId }: GetStrategicAllocationPayload) => {
    const queryString = buildEncodedQueryString({
      investmentServiceVersionId,
      clientId,
    });
    const response = await api.get<StrategicAssetAllocation[]>(`${StrategicAllocationApiEndpoints.FetchStrategicAllocations}${queryString}`);

    return response.data;
  }
);

export const saveStrategicAllocations = createAsyncThunk(
  StrategicAllocationActionTypes.UpdateStrategicAllocation,
  async (payload: SaveStrategicAllocationPayload, thunkApi) => {
    const response = await api.post<number>(StrategicAllocationApiEndpoints.UpdateStrategicAllocation, payload);

    thunkApi.dispatch(commonSlice.actions.setInvestmentServiceVersionId(response.data));
    await thunkApi.dispatch(fetchStrategicAllocations({ investmentServiceVersionId: payload.investmentServiceVersionId, clientId: payload.clientId }));

    await thunkApi.dispatch(
      fetchInvestmentServiceVersionsByClient({
        clientId: payload.clientId,
        showLatestVersionOnly: false,
      })
    );

    return { message: 'Strategic Allocations saved' };
  }
);

export const fetchDefaultSecurities = createAsyncThunk(
  DefaultSecuritiesActionTypes.FetchDefaultSecurities,
  async ({ investmentServiceVersionId, clientId }: GetDefaultSecuritiesPayload) => {
    const queryString = buildEncodedQueryString({
      investmentServiceVersionId,
      clientId,
    });
    const response = await api.get<DefaultSecurity[]>(`${DefaultSecuritiesApiEndpoints.FetchDefaultSecurities}${queryString}`);

    return response.data;
  }
);

export const saveDefaultSecurities = createAsyncThunk(
  DefaultSecuritiesActionTypes.UpdateDefaultSecurities,
  async (payload: SaveDefaultSecuritiesPayload, thunkApi) => {
    const response = await api.post<number>(DefaultSecuritiesApiEndpoints.UpdateDefaultSecurities, payload);

    thunkApi.dispatch(commonSlice.actions.setInvestmentServiceVersionId(response.data));
    await thunkApi.dispatch(fetchDefaultSecurities({ investmentServiceVersionId: payload.investmentServiceVersionId, clientId: payload.clientId }));

    await thunkApi.dispatch(
      fetchInvestmentServiceVersionsByClient({
        clientId: payload.clientId,
        showLatestVersionOnly: false,
      })
    );

    return { message: 'Default Securities saved' };
  }
);
