import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../../../app/api';
import { buildEncodedQueryString } from '../../../../../common/utils';
import {
  ClientAccount,
  ClientMainDetails,
  ClientPortfolio,
  DocumentType,
  EntityDetails,
  Role,
  SuperSimplifierDetails,
  UpdateSuperSimplifierPayload,
} from './types';

export enum CommonActionTypes {
  FetchClientPortfolios = '@@client/common/GetInvestmentServicesByClient',
  FetchEntityDetails = '@@client/common/FetchEntityDetails',
  FetchClientMainDetails = '@@client/common/FetchClientMainDetails',
  FetchClientAccounts = '@@client/common/GetAccountsByClient',
  FetchDocumentTypes = '@@client/common/FetchDocumentTypes',
  FetchRoles = '@@client/common/FetchRoles',
  FetchSuperSimplifier = '@@client/common/FetchSuperSimplifier',
  UpdateSuperSimplifier = '@@client/details/superSimplifier/Update',
}

// Endpoints
export enum CommonApi {
  FetchClientPortfolios = '/investmentservices/GetInvestmentServicesByClient',
  FetchEntityDetails = '/customers/GetEntityDetails',
  FetchClientMainDetails = '/customers/GetClientMainDetails',
  FetchClientAccounts = '/investmentservices/GetAccountsByClient',
  FetchDocumentTypes = '/documents/GetAttachmentTypes',
  FetchRoles = '/customers/GetRoles',
  FetchSuperSimplifier = '/customers/GetClientSuperSimplifierDetails',
  UpdateSuperSimplifier = '/customers/UpdateClientSuperSimplifierDetails',
}

export const fetchClientPortfolios = createAsyncThunk(
  CommonActionTypes.FetchClientPortfolios,
  async (clientId: number) => {
    const queryString = buildEncodedQueryString({
      clientId: clientId,
    });

    const response = await api.get<ClientPortfolio[]>(`${CommonApi.FetchClientPortfolios}${queryString}`);
    return response.data;
  }
);

export const fetchEntityDetails = createAsyncThunk(CommonActionTypes.FetchEntityDetails, async (clientId: number) => {
  const queryString = buildEncodedQueryString({
    clientId: clientId,
  });

  const response = await api.get<EntityDetails>(`${CommonApi.FetchEntityDetails}${queryString}`);
  return response.data;
});

export const fetchClientMainDetails = createAsyncThunk(
  CommonActionTypes.FetchClientMainDetails,
  async (clientId: number) => {
    const queryString = buildEncodedQueryString({
      clientId: clientId,
    });

    const response = await api.get<ClientMainDetails>(`${CommonApi.FetchClientMainDetails}${queryString}`);
    return response.data;
  }
);

export const fetchClientAccounts = createAsyncThunk(CommonActionTypes.FetchClientAccounts, async (clientId: number) => {
  const queryString = buildEncodedQueryString({
    clientId: clientId,
  });

  const response = await api.get<ClientAccount[]>(`${CommonApi.FetchClientAccounts}${queryString}`);
  return response.data;
});

export const fetchDocumentTypes = createAsyncThunk(CommonActionTypes.FetchDocumentTypes, async () => {
  const response = await api.get<DocumentType[]>(CommonApi.FetchDocumentTypes);
  return response.data;
});

export const fetchRoles = createAsyncThunk(CommonActionTypes.FetchRoles, async () => {
  const response = await api.get<Role[]>(CommonApi.FetchRoles);
  return response.data;
});

export const fetchSuperSimplifier = createAsyncThunk(
  CommonActionTypes.FetchSuperSimplifier,
  async (clientId: number) => {
    const queryString = buildEncodedQueryString({
      clientId,
    });
    const response = await api.get<SuperSimplifierDetails>(`${CommonApi.FetchSuperSimplifier}${queryString}`);
    return response.data;
  }
);

export const updateSuperSimplifier = createAsyncThunk(
  CommonActionTypes.UpdateSuperSimplifier,
  async (payload: { details: UpdateSuperSimplifierPayload; message: string }, thunkApi) => {
    return await api
      .post(CommonApi.UpdateSuperSimplifier, payload.details)
      .then(() => {
        thunkApi.dispatch(fetchSuperSimplifier(payload.details.clientId));

        return { message: payload.message };
      })
      .catch(() => {
        return { message: 'Failed to update Super Simplifier details.', variant: 'error' };
      });
  }
);
