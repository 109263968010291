import { FormControl, InputLabel, MenuItem } from '@mui/material';
import React from 'react';
import { WO2Select } from '../../../../../../common';
import { GroupingType } from '../store/types';

interface Props {
  selectedItem: GroupingType;
  setSelectedItem: (payload: GroupingType) => void;
}

export const GroupingSelect = (props: Props): JSX.Element => {
  return (
    <FormControl variant="outlined" style={{ minWidth: '114px' }}>
      <InputLabel id="grouping-select-label">Group By</InputLabel>
      <WO2Select
        labelId="grouping-select-label"
        label="Group By"
        id="grouping-select"
        value={props.selectedItem}
        onChange={(event) => {
          props.setSelectedItem(event.target.value as GroupingType);
        }}
      >
        <MenuItem key={1} value={GroupingType.AssetClass}>
          Asset Class
        </MenuItem>
        <MenuItem key={2} value={GroupingType.Model}>
          Model
        </MenuItem>
        <MenuItem key={3} value={GroupingType.Account}>
          Account
        </MenuItem>
      </WO2Select>
    </FormControl>
  );
};
