import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  fetchBenefitStatementDetailsByClientId,
  fetchBenefitStatementOtherBenefitsComponentByAccountId,
  fetchBenefitStatementPreservationComponentByAccountId,
  fetchBenefitStatementTaxationComponentByAccountId,
} from './thunks';
import { BenefitStatementDetails, BenefitStatementState, OtherBenefits, PreservationComponentDetails, ResultWrapper, TaxationDetails } from './types';

export const initialState: BenefitStatementState = {
  details: {
    preservationComponentDetails: {},
    contributionDetails: {},
    statementDetails: {},
    taxationDetails: {},
    otherBenefits: {},
    transactions: {
      parameters: {
        pagination: {
          pageNumber: 1,
          pageSize: 10,
          queryFields: [],
        },
        accountId: '',
      },
      transactions: {
        pageNumber: 1,
        pageSize: 10,
        results: [],
        totalNumberOfPages: 0,
        totalNumberOfRecords: 0,
      },
    },
  },
};

export const benefitStatementSlice = createSlice({
  name: 'benefitStatementSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchBenefitStatementDetailsByClientId.fulfilled, (state, action: PayloadAction<ResultWrapper<BenefitStatementDetails | null>>) => {
      state.details.statementDetails = action.payload;
    });
    builder.addCase(
      fetchBenefitStatementPreservationComponentByAccountId.fulfilled,
      (state, action: PayloadAction<ResultWrapper<PreservationComponentDetails | null>>) => {
        state.details.preservationComponentDetails = action.payload;
      }
    );
    builder.addCase(fetchBenefitStatementTaxationComponentByAccountId.fulfilled, (state, action: PayloadAction<ResultWrapper<TaxationDetails | null>>) => {
      state.details.taxationDetails = action.payload;
    });
    builder.addCase(fetchBenefitStatementOtherBenefitsComponentByAccountId.fulfilled, (state, action: PayloadAction<ResultWrapper<OtherBenefits | null>>) => {
      state.details.otherBenefits = action.payload;
    });
  },
});
