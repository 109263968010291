import { ADVISER_CONTACT_EMAIL } from 'src/common/utils/dashContactDetails';
import { Enumeration } from '../../types';

export interface WO2AccountActionType {
  clickHandler: (accountId: number | null | undefined) => void;
  disabled?: boolean;
  icon: React.ReactElement;
  label: string;
  name: keyof Account;
}

export interface AttachableCmaAccount {
  bsb: string;
  name: string;
  number: string;
}

export interface SelectedCmaAccount extends AttachableCmaAccount {
  forceRoa: boolean;
}

export interface AttachableCmaAccounts {
  attachableCmaAccounts: AttachableCmaAccount[];
}

export interface WO2PendingAccountActionType {
  clickHandler: (accountId: number | null | undefined) => void;
  disabled?: boolean;
  icon: React.ReactElement;
  label: string;
  name: keyof PendingAccount;
}

export interface Account {
  accountId: number;
  accountTypeId: number;
  accountName: string;
  accountNumber: string;
  bsb: string;
  institutionId: number | null;
  institution: string;
  securityStatus: number | null;
  isExternal: boolean;
  brokerId?: number | null;
  entityDetailsSubTypeId?: number | null;
  accountTypeName?: string;
}

export interface WithdrawalAccount {
  accountId: number;
  accountType: number;
  accountName: string;
  accountNumber: string;
  bsb: string;
  institutionId: number | null;
  institution: string;
  securityStatus: number | null;
  isExternal: boolean;
  brokerId?: number | null;
  entityDetailsSubTypeId?: number | null;
  accountTypeName?: string;
}

export interface Errors {
  code: string | null;
  message: string | null;
  severity: number | null;
}

export interface PendingAccount {
  id: string | null;
  name: string | null;
  number: string | null;
  bsb: string | null;
  institutionId: number;
  institution: string | null;
  statusId: number;
  statusMessage: string | null;
  isDataStatusReady: boolean | null;
  state: string | null;
  errors: Errors[] | null;
}

export interface PendingAccounts {
  pendingAccounts: PendingAccount[];
}

export interface PendingAccountWithActions extends PendingAccount {
  actions: WO2PendingAccountActionType[];
}

export interface AccountType {
  id: number;
  type: string;
  description: string;
}
export interface AccountView {
  accountId: number | null;
  accountTypeId: number | null;
  accountName: string;
  accountNumber: string;
  bsb: string;
  institutionId: number | null;
  institution: string;
  securityStatus: number | null;
  isExternal: boolean;
}

export interface PendingAccountView {
  accountId: string | null;
  accountTypeId: number | null;
  accountName: string;
  accountNumber: string;
  bsb: string;
  institutionId: number | null;
  institution: string;
  securityStatus: number | null;
  isExternal: boolean;
}

export interface DetachAccountPayload {
  clientId: number;
  accountId: number;
}

export interface DetachPendingAccountPayload {
  clientId: number;
  accountId: string;
}

export interface SaveuXchangeAccountDetails {
  riskProfile: string;
  sourceOfFunds: string;
}
export interface UnlistedAccount {
  accountName: string;
  accountNumber: string;
}

export interface CreateUnlistedAccountPayload extends UnlistedAccount {
  clientId: number;
}

export interface ExternalAccount {
  accountName: string;
  accountNumber: string;
  bsb: string;
  institutionId: number | null;
}

export interface CreateExternalAccountPayload extends ExternalAccount {
  clientId: number;
}

export interface ExistingAccount {
  institutionId: number | null;
  accountName: string | null;
  accountNumber: string | null;
  bsb: string | null;
  accountId: string | null;
  accountTypeId: number | null;
  institution: string | null;
  securityStatus: number | null;
  isExternal: boolean | null;
  serviceTypeId: number | null;
}

export interface NewAccount {
  institutionIds: number[];
  accountInfo: NewAccountInfo;
}
export interface NewAccountInfo {
  isHinTransfer: boolean | null;
  hin: string | null;
  pid: string | null;
}

// common form types

export class SecurityStatus extends Enumeration {
  static Active = new SecurityStatus(1, 'Active', 'Active');
  static Inactive = new SecurityStatus(2, 'Inactive', 'Inactive');
  static Matured = new SecurityStatus(3, 'Matured', 'Matured');
  static Pending = new SecurityStatus(4, 'Pending', 'Pending');
  static Sold = new SecurityStatus(5, 'Sold', 'Sold');
  static Terminated = new SecurityStatus(7, 'Terminated', 'Terminated');
  static Suspended = new SecurityStatus(8, 'Suspended', 'Suspended');
  static Removed = new SecurityStatus(9, 'Removed', 'Removed');
  static Open = new SecurityStatus(10, 'Open', 'Open');
  static Archived = new SecurityStatus(11, 'Archived', 'Archived');
  static DocumentsRequired = new SecurityStatus(12, 'DocumentsRequired', 'Documents required');
  static Proforma = new SecurityStatus(13, 'Proforma', 'Proforma');
  static WaitingForApproval = new SecurityStatus(14, 'WaitingForApproval', 'Waiting for Approval');
  static DatafeedActive = new SecurityStatus(15, 'DatafeedActive', 'Datafeed active');
  static Delisted = new SecurityStatus(16, 'Delisted', 'Delisted');
  static Closed = new SecurityStatus(17, 'Closed', 'Closed');
  static Dormant = new SecurityStatus(18, 'Dormant', 'Dormant');

  static getById = (id: number): SecurityStatus | null | undefined => {
    return Object.values(SecurityStatus)
      .filter((t) => t instanceof SecurityStatus)
      .find((t: SecurityStatus) => t.id === id);
  };
}

export class PendingAccountStatus extends Enumeration {
  static Proposed = new PendingAccountStatus(1, 'Proposed', 'Proposed');
  static Initiated = new PendingAccountStatus(2, 'Initiated', 'Initiated');
  static Submitted = new PendingAccountStatus(3, 'Submitted', 'Submitted to vendor');
  static Error = new PendingAccountStatus(5, 'Error', `Error - please contact ${ADVISER_CONTACT_EMAIL}`);

  static getById = (id: number): PendingAccountStatus | null | undefined => {
    return Object.values(PendingAccountStatus)
      .filter((t) => t instanceof PendingAccountStatus)
      .find((t: PendingAccountStatus) => t.id === id);
  };

  static getByName = (name: string): PendingAccountStatus | null | undefined => {
    return Object.values(PendingAccountStatus)
      .filter((t) => t instanceof PendingAccountStatus)
      .find((t: PendingAccountStatus) => t.name === name);
  };
}

export class AccountInstitutionType extends Enumeration {
  pendingCode: string;

  static Unlisted = new AccountInstitutionType(11, 'Unlisted', 'Unlisted', 'Unlisted');
  static MacquarieCma = new AccountInstitutionType(10, 'MacquarieCMA', 'Macquarie CMA', 'Macquarie');
  static DesktopBroker = new AccountInstitutionType(13, 'DesktopBroker', 'Desktop Broker', 'DesktopBroker');
  static Openmarkets = new AccountInstitutionType(61, 'Openmarkets', ' Openmarkets', 'OpenMarkets');
  static AMM = new AccountInstitutionType(6, 'AustralianMoneyMarket', 'Australian Money Market', 'AMM');
  static ManagedFunds = new AccountInstitutionType(81, 'UxChange', 'Managed Funds', 'uXchange');

  constructor(id: number, name: string, displayName: string, pendingCode: string) {
    super(id, name, displayName);
    this.pendingCode = pendingCode;
  }

  getLogo = (): string => {
    return `/images/institution/${this.id}-${this.name}.png`;
  };

  static getLogoById = (id: number | null): string => {
    const type = this.getById(id);
    return type?.getLogo() ?? '/images/institution/unknown.png';
  };

  static getAllAccountInstitutionTypes = (): AccountInstitutionType[] => [AccountInstitutionType.Unlisted];

  static getAllPendingInstitutionTypes = (): AccountInstitutionType[] => [
    AccountInstitutionType.MacquarieCma,
    AccountInstitutionType.DesktopBroker,
    AccountInstitutionType.Openmarkets,
    AccountInstitutionType.AMM,
    AccountInstitutionType.ManagedFunds,
  ];

  static getAllExistingAccountInstitutions = (): AccountInstitutionType[] => [
    AccountInstitutionType.MacquarieCma,
    AccountInstitutionType.DesktopBroker,
    AccountInstitutionType.Openmarkets,
    AccountInstitutionType.AMM,
  ];

  static getAllNewAccountInstitutions = (): AccountInstitutionType[] =>
    AccountInstitutionType.getAllPendingInstitutionTypes().filter(
      (institution: AccountInstitutionType) => institution.displayName !== AccountInstitutionType.AMM.pendingCode
    );

  static getAllOnboardingAccountInstitutionTypes = (): AccountInstitutionType[] => [
    AccountInstitutionType.MacquarieCma,
    AccountInstitutionType.DesktopBroker,
    AccountInstitutionType.Openmarkets,
    AccountInstitutionType.AMM,
  ];

  static getAccountInstitutionsNeedIdentification = (): AccountInstitutionType[] => [
    AccountInstitutionType.DesktopBroker,
    AccountInstitutionType.AMM,
  ];
  static getAccountInstitutionsNeedHinTransfer = (): AccountInstitutionType[] => [
    AccountInstitutionType.DesktopBroker,
    AccountInstitutionType.Openmarkets,
  ];
  static getAccountInstitutionsNeedRiskProfile = (): AccountInstitutionType[] => [
    AccountInstitutionType.MacquarieCma,
    AccountInstitutionType.ManagedFunds,
  ];
  static getAccountInstitutionsNeedAdditionalDetails = (): AccountInstitutionType[] => [AccountInstitutionType.AMM];
  static getSuperOptionalInstitutionTypes = (): AccountInstitutionType[] => [AccountInstitutionType.AMM];

  static getArray = (): AccountInstitutionType[] => [
    AccountInstitutionType.Unlisted,
    AccountInstitutionType.MacquarieCma,
    AccountInstitutionType.DesktopBroker,
    AccountInstitutionType.Openmarkets,
    AccountInstitutionType.AMM,
  ];

  static readonly InstitutionTypeIds = [
    this.AMM.id,
    this.MacquarieCma.id,
    this.DesktopBroker.id,
    this.Openmarkets.id,
    this.ManagedFunds.id,
  ];

  static getValidInstitutionId = (accountInstitutionId: number | null) => {
    return AccountInstitutionType.InstitutionTypeIds.includes(accountInstitutionId ?? 0) ? accountInstitutionId : null;
  };

  static getById = (id: number | null): AccountInstitutionType | undefined => {
    return Object.values(AccountInstitutionType)
      .filter((t) => t instanceof AccountInstitutionType)
      .find((t: AccountInstitutionType) => t.id === id);
  };

  static getByName = (name: string): AccountInstitutionType | undefined => {
    return Object.values(AccountInstitutionType)
      .filter((t) => t instanceof AccountInstitutionType)
      .find((t: AccountInstitutionType) => t.name.toLowerCase() === name.toLowerCase());
  };
}

export enum OtherAccountTypes {
  External = 'External',
}

export interface SourceOfFundsItem {
  id: number;
  displayName: string;
}

export interface ExternalInstitution {
  institutionId: number;
  code: string;
  description: string;
}
export class SourceOfFunds extends Enumeration {
  static BusinessIncome = new SourceOfFunds(1, 'BusinessIncome', 'Business income/earnings');
  static BusinessProfits = new SourceOfFunds(2, 'BusinessProfits', 'Business profits');
  static CompensationPayment = new SourceOfFunds(3, 'CompensationPayment', 'Compensation payment');
  static Gift = new SourceOfFunds(4, 'Gift', 'Gift/Donation');
  static GovernmentBenefits = new SourceOfFunds(5, 'Gov', 'Government benefits');
  static Inheritance = new SourceOfFunds(6, 'Inheritance', 'Inheritance');
  static InsurancePayment = new SourceOfFunds(7, 'InsurancePayment', 'Insurance payment');
  static InvestmentIncome = new SourceOfFunds(8, 'InvestmentIncome', 'Investment income/earnings');
  static Redundancy = new SourceOfFunds(9, 'Redundancy', 'Redundancy');
  static RentalIncome = new SourceOfFunds(10, 'RentalIncome', 'Rental income');
  static SalaryWages = new SourceOfFunds(11, 'SalaryWages', 'Salary/Wages');
  static SaleOfAssets = new SourceOfFunds(12, 'SaleOfAssets', 'Sale of assets');
  static SuperannuationPension = new SourceOfFunds(13, 'SuperannuationPension', 'Superannuation/pension');
  static Windfall = new SourceOfFunds(14, 'Windfall', 'Windfall');

  static getArray = (): SourceOfFunds[] => [
    SourceOfFunds.BusinessIncome,
    SourceOfFunds.BusinessProfits,
    SourceOfFunds.CompensationPayment,
    SourceOfFunds.Gift,
    SourceOfFunds.GovernmentBenefits,
    SourceOfFunds.Inheritance,
    SourceOfFunds.InsurancePayment,
    SourceOfFunds.InvestmentIncome,
    SourceOfFunds.Redundancy,
    SourceOfFunds.RentalIncome,
    SourceOfFunds.SalaryWages,
    SourceOfFunds.SaleOfAssets,
    SourceOfFunds.SuperannuationPension,
    SourceOfFunds.Windfall,
  ];
}

enum externalAccountTypes {
  depositAtCall = 1,
  depositCMA = 2,
  depositTD = 3,
  securityBond = 4,
  SecurityMF = 5,
  UnlistedAssets = 9,
  ba = 12,
}

export const externalAccountTypesIds: number[] = [
  externalAccountTypes.depositAtCall,
  externalAccountTypes.depositCMA,
  externalAccountTypes.depositTD,
  externalAccountTypes.securityBond,
  externalAccountTypes.SecurityMF,
  externalAccountTypes.UnlistedAssets,
  externalAccountTypes.ba,
];
