import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AdviserItem,
  AfslItem,
  ModelItem,
  PracticeItem,
  SimpleSecurityItem
} from '../../../common/store/types';
import { fetchAccountSearchResults, fetchModels } from './thunks';
import { AccountSearchResult, EmailSearchState, SecurityHeld } from './types';

export const initialState: EmailSearchState = {
  parameters: {
    afsls: [],
    practices: [],
    advisers: [],
    serviceTypeId: null,
    accountType: [],
    portfolioValueFrom: null,
    portfolioValueTo: null,
    availableCashBalanceFrom: null,
    availableCashBalanceTo: null,
    securityHeld: SecurityHeld.Held.name,
    security: null,
    securitySearch: null,
    includeZeroHoldings: false,
    model: null,
  },
  models: [],
  accountSearchResult: [],
  accountSelected: [],
};

export const searchSlice = createSlice({
  name: '@@bulk/email/search',
  initialState,
  reducers: {
    setAfslParameter: (state, action: PayloadAction<AfslItem[]>) => {
      state.parameters.afsls = action.payload;
    },
    setPracticeParameter: (state, action: PayloadAction<PracticeItem[]>) => {
      state.parameters.practices = action.payload;
    },
    setAdviserParameter: (state, action: PayloadAction<AdviserItem[]>) => {
      state.parameters.advisers = action.payload;
    },
    setAccountTypeParameter: (state, action: PayloadAction<string[]>) => {
      state.parameters.accountType = action.payload;
    },
    setServiceTypeParameter: (state, action: PayloadAction<number | null>) => {
      state.parameters.serviceTypeId = action.payload;
    },
    setPortfolioValueFrom: (state, action: PayloadAction<number | null>) => {
      state.parameters.portfolioValueFrom = action.payload;
    },
    setPortfolioValueTo: (state, action: PayloadAction<number | null>) => {
      state.parameters.portfolioValueTo = action.payload;
    },
    setAvailableCashBalanceFrom: (state, action: PayloadAction<number | null>) => {
      state.parameters.availableCashBalanceFrom = action.payload;
    },
    setAvailableCashBalanceTo: (state, action: PayloadAction<number | null>) => {
      state.parameters.availableCashBalanceTo = action.payload;
    },
    setSecurityHold: (state, action: PayloadAction<string>) => {
      state.parameters.securityHeld = action.payload;
    },
    setSecurity: (state, action: PayloadAction<SimpleSecurityItem | null>) => {
      state.parameters.security = action.payload;
    },
    setSecuritySearch: (state, action: PayloadAction<string | null>) => {
      state.parameters.securitySearch = action.payload;
    },
    setIncludeZeroHoldings: (state, action: PayloadAction<boolean>) => {
      state.parameters.includeZeroHoldings = action.payload;
    },
    setModel: (state, action: PayloadAction<ModelItem | null>) => {
      state.parameters.model = action.payload;
    },
    setAccountSelected: (state, action: PayloadAction<AccountSearchResult[]>) => {
      state.accountSelected = action.payload;
    },
    setDefaultParameters: (state) => {
      state.parameters = initialState.parameters;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAccountSearchResults.fulfilled, (state, action: PayloadAction<AccountSearchResult[]>) => {
      state.accountSearchResult = action.payload;
    });
    builder.addCase(fetchModels.pending, (state) => {
      state.models = [];
    });
    builder.addCase(fetchModels.fulfilled, (state, action: PayloadAction<ModelItem[]>) => {
      state.models = action.payload;
    });
  },
});
