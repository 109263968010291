import { Alert, Container, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { LoadingIndicator } from 'src/common';
import { Props } from '../container';
import {
  AccountClient,
  Beneficiary,
  BeneficiaryViewModel,
  PendingBeneficiaryDetails,
  activeBindingTypes,
  inactiveBindingTypes
} from '../store/types';
import { BeneficiariesTable } from './beneficiariesTable';
import { PendingBeneficiariesNominationTypeEnum } from '../enums/pendingBeneficiariesNominationTypeEnum';
import { PendingBeneficiariesTable, getNameStructure } from './pendingBeneficiariesTable';

export const Beneficiaries = ({
  superMemberNumber,
  clientId,
  fetchBeneficiaries,
  fetchAccountClients,
  createOrUpdateBeneficiaries,
  hasClientEditAdminOnly,
  loadingProgress,
  savingPendingBeneficiariesProgress,
  beneficiariesDetails,
  accountClients,
}: Props): JSX.Element => {
  const [validResponse, setValidResponse] = useState(false);
  const [activeBeneficiaries, setActiveBeneficiaries] = useState<BeneficiaryViewModel[] | null>([]);
  const [inActiveBeneficiaries, setInactiveBeneficiaries] = useState<BeneficiaryViewModel[] | null>([]);
  const [pendingBeneficiaries, setPendingBeneficiaries] = useState<PendingBeneficiaryDetails[] | null>([]);
  const activeTypes: string[] = activeBindingTypes;
  const inactiveTypes: string[] = inactiveBindingTypes;

  useEffect(() => {
    const active: BeneficiaryViewModel[] | null = [];
    const inactive: BeneficiaryViewModel[] | null = [];
    const pending: PendingBeneficiaryDetails[] | null = [];

    if (accountClients.status === 200 && !!accountClients.responseData && accountClients.responseData.length >= 0) {
      accountClients.responseData?.forEach((ac: AccountClient) => {
        const vm = createViewModel(ac);
        if (activeTypes.includes(ac.bindingFlag)) {
          active?.push(vm);
        } else if (inactiveTypes.includes(ac.bindingFlag)) {
          inactive?.push(vm);
        }
      });
    }
    if (
      beneficiariesDetails.status === 200 &&
      !!beneficiariesDetails.responseData &&
      beneficiariesDetails.responseData.length >= 0
    ) {
      beneficiariesDetails.responseData?.forEach((bd: Beneficiary) => {
        const vm = createViewModelFromBeneficary(bd);
        if (bd.effectiveFrom) {
          if (activeTypes.includes(bd.bindingFlag)) {
            active?.push(vm);
          } else if (inactiveTypes.includes(bd.bindingFlag)) {
            inactive?.push(vm);
          }
        }
        else {
          const pbr = createPendingBeneficiaryRecord(bd)
          pending?.push(pbr);
        }
      });
    }

    if (active.length > 0) setActiveBeneficiaries(active);
    if (inactive.length > 0) setInactiveBeneficiaries(inactive);
    setPendingBeneficiaries(pending);

    setValidResponse((!!active && active.length > 0) || (!!inactive && inactive.length > 0));
  }, [accountClients, beneficiariesDetails]);

  useEffect(() => {
    setActiveBeneficiaries(null);
    setInactiveBeneficiaries(null);
  }, [superMemberNumber, clientId]);

  useEffect(() => {
    if (!!superMemberNumber) {
      fetchBeneficiaries(superMemberNumber);
    }
  }, [fetchBeneficiaries, superMemberNumber]);

  useEffect(() => {
    if (!!superMemberNumber) {
      fetchAccountClients(superMemberNumber + '');
    }
  }, [fetchAccountClients, superMemberNumber]);

  const createViewModel = (accountClient: AccountClient) => {
    const viewModel: BeneficiaryViewModel = {
      name: accountClient.name,
      endDate: undefined,
      startDate: accountClient.effectiveFrom,
      percentage: '100',
      type: accountClient.bindingFlag,
      relationship: '',
    };
    return viewModel;
  };

  const createPendingBeneficiaryRecord = (beneficiary: Beneficiary) => {
    const record: PendingBeneficiaryDetails = {
      id: beneficiary.id,
      firstName: getNameStructure(beneficiary.name).firstName,
      lastName: getNameStructure(beneficiary.name).lastName,
      dateOfBirth: beneficiary.dateOfBirth,
      relationship: beneficiary.relationship,
      nominationType: beneficiary.bindingFlag == 'Non Binding' && !beneficiary.endDate ? 
                        PendingBeneficiariesNominationTypeEnum.NonbindingNonLapsing.displayName : '',
      percentage: beneficiary.percentage
    };
    return record;
  }

  const createViewModelFromBeneficary = (beneficiary: Beneficiary) => {
    const viewModel: BeneficiaryViewModel = {
      name: beneficiary.name,
      endDate: beneficiary.endDate,
      startDate: beneficiary.startDate,
      percentage: !beneficiary.percentage ? '-' : beneficiary.percentage + '',
      type: beneficiary.bindingFlag,
      relationship: !beneficiary.relationship ? '-' : beneficiary.relationship,
    };
    return viewModel;
  };

  return (
    <>
      <Typography variant="h2" style={{ padding: '20px 0' }}>
        Beneficiaries
      </Typography>
      {
        hasClientEditAdminOnly && (
          <Container disableGutters={true} sx={{paddingBottom: '30px', marginLeft: '-15px'}}>
          <PendingBeneficiariesTable 
            items={pendingBeneficiaries ?? []}
            itemLoadingProgress={loadingProgress}
            savingProgress={savingPendingBeneficiariesProgress}
            onSave={async (detailsToSave: PendingBeneficiaryDetails[]) => {
              await createOrUpdateBeneficiaries({
                superMemberNumber: superMemberNumber ?? '',
                pendingBeneficiaryDetails: detailsToSave
              })
              .then((payload) => {
                if ((payload.payload as { message: string; variant?: string }).variant === 'error') {
                  throw payload.payload;
                }
                return payload;
              });
            }}
          />
        </Container>
        )
      }
      {validResponse ? (
        <LoadingIndicator progress={loadingProgress}>
          {!!activeBeneficiaries && activeBeneficiaries.length > 0 && (
            <>
              <Typography variant="h3" style={{ paddingBottom: '20px' }}>
                Active Beneficiaries
              </Typography>
              <BeneficiariesTable
                data-testid="activeBeneficiariesTableComponent"
                beneficiariesDetails={activeBeneficiaries}
              ></BeneficiariesTable>
            </>
          )}
          {!!inActiveBeneficiaries && inActiveBeneficiaries.length > 0 && (
            <>
              {inActiveBeneficiaries.length > 0 ? (
                <>
                  <Typography variant="h3" style={{ paddingBottom: '20px' }}>
                    Inactive Beneficiaries
                  </Typography>
                  <BeneficiariesTable
                    data-testid="inActiveBeneficiariesTableComponent"
                    beneficiariesDetails={inActiveBeneficiaries}
                  ></BeneficiariesTable>
                </>
              ) : (
                <></>
              )}
            </>
          )}
        </LoadingIndicator>
      ) : (
        <Alert
          style={{ marginRight: '20px', marginTop: '20px', marginBottom: '20px' }}
          variant="outlined"
          severity="info"
        >
          <Typography>
            There is currently no beneficiary information for this member. If the member has been recently established
            it may take a few days for this data to be populated.
          </Typography>
        </Alert>
      )}
    </>
  );
};
