import { Box } from '@mui/material';
import 'date-fns';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { AnyAction } from 'redux';
import history from '../../../../../../history';
import { Edit } from '../../../common/components/contacts/edit/edit';
import { List } from '../../../common/components/contacts/list/list';
import { SaveContactPayload } from '../../../common/store/types';
import { Props } from '../container';

export const Contacts = (props: Props): JSX.Element => {
  const { clientId } = props;



  return (
    <Box width={'100%'}>
      <Switch>
        <Route path={`${props.match.path}/contact/:contactId?`}>
          <Edit
            {...{
              ...props,
              parentId: clientId,
              onBackClick: (createMode: boolean) => {
                history.push(createMode ? `../contacts` : `../../contacts`);
              },
              saveContact: (payload: SaveContactPayload) => {
                props.saveContact(payload).then((saveResponse: AnyAction) => {
                  history.push(`./contact/${saveResponse.payload.contactId}`);
                });
              },
            }}
          />
        </Route>
        <Route path={props.match.path}>
          <List {...props} primaryContactRequired={true} />
        </Route>
      </Switch>
    </Box>
  );
};
