import { connect, ConnectedProps } from 'react-redux';
import { progressSelectorFactory } from '../../../../../common';
import { RootState } from '../../../../../reducers';
import { selectHasClientEdit, selectHasClientEditAdminOnly } from '../../../../../store/selectors';
import {
  selectClientId,
  selectDocumentTypes,
  selectEntityDetails,
  selectIsSuperSimplifierClient,
  selectSuperSimplifierDetails,
} from '../../common/store/selectors';
import { CommonActionTypes, fetchDocumentTypes, fetchEntityDetails } from '../../common/store/thunks';
import { selectPendingFeesChangeSet } from '../adviceFees/store/selectors';
import {
  activateFeeRenewalDocusign,
  activateFeeRenewalUpload,
  activateFees,
  fetchPendingAdviceFees,
} from '../adviceFees/store/thunks';
import { selectMainDetailsItem } from './../mainDetails/store/selectors';
import { fetchClientAccount } from './../mainDetails/store/thunks';
import { Accounts } from './components/accounts';
import {
  selectAccountTypes,
  selectAttachableCmaAccounts,
  selectBpayAccountDetails,
  selectContactRoleItems,
  selectedExternalAccount,
  selectedTradingAccount,
  selectExistingAccounts,
  selectExternalAccountItems,
  selectExternalInstitutions,
  selectMacquarieCashOnlinePortal,
  selectNewAccounts,
  selectPendingAccountItems,
  selectPendingSuperAccount,
  selectTradingAccountItems,
} from './store/selectors';
import { accountsSlice } from './store/slice';
import {
  AccountsActionTypes,
  actionApplicationSubmission,
  createCustodyAccounts,
  createExternalAccount,
  createPendingAccount,
  createUnlistedAccount,
  deletePendingAccount,
  downloadSuperSimplifierApplication,
  downloadSuperSimplifierOngoingFeeConsentRenewal,
  fetchAccounts,
  fetchBPAYDetails,
  fetchAccountTypes,
  fetchAttachableCmaAccounts,
  fetchClientNewMacquarieAccountDetailByClientId,
  fetchExternalAccountForEdit,
  fetchExternalInstitutions,
  fetchPendingAccounts,
  fetchPendingSuperAccount,
  fetchTradingAccountForEdit,
  initiateExistingMacquarieAccountApplication,
  initiateNewMacquarieAccountApplication,
  initiateUxchangeworkflowAccountApplicationWorkflow,
  linkExsistingPendingAccount,
  onDetachAccount,
  sendApplicationForm,
  sendFeeConsentRenewal,
  submitAMM,
  submitDesktopBroker,
  submitOpenMarkets,
  updatePendingAccountStatus,
  uploadSuperSimplifierApplicationForm,
  uploadSuperSimplifierOngoingFeeConsentRenewalForm,
  fetchClientContacts,
} from './store/thunks';

const mapStateToProps = (state: RootState) => ({
  hasClientEditPermission: selectHasClientEdit(state),
  clientId: selectClientId(state),

  tradingAccounts: selectTradingAccountItems(state),
  selectedTradingAccount: selectedTradingAccount(state),
  saveUnlistedAccountProgress: progressSelectorFactory([AccountsActionTypes.CreateUnlistedAccount])(state),

  pendingSuperAccount: selectPendingSuperAccount(state),
  pendingAccounts: selectPendingAccountItems(state),
  contactRoles: selectContactRoleItems(state),
  savePendingAccountsLoadingProgress: progressSelectorFactory([AccountsActionTypes.CreatePendingAccount])(state),
  linkExsistingPendingAccountLoadingProgress: progressSelectorFactory([AccountsActionTypes.LinkExistingPendingAccount])(
    state
  ),

  externalAccounts: selectExternalAccountItems(state),
  selectedExternalAccount: selectedExternalAccount(state),
  saveExternalAccountLoadingProgress: progressSelectorFactory([AccountsActionTypes.CreateExternalAccount])(state),
  externalInstitutions: selectExternalInstitutions(state),
  existingAccounts: selectExistingAccounts(state),
  bPayAccountDetails: selectBpayAccountDetails(state),
  newAccounts: selectNewAccounts(state),
  attachableCmaAccounts: selectAttachableCmaAccounts(state),
  loadingProgress: progressSelectorFactory([
    AccountsActionTypes.fetchExternalAccountForEdit,
    AccountsActionTypes.fetchTradingAccountForEdit,
  ])(state),
  superSimplifierLoadingProgress: progressSelectorFactory([CommonActionTypes.FetchSuperSimplifier])(state),

  accountTypes: selectAccountTypes(state),
  loadingTypesProgress: progressSelectorFactory([AccountsActionTypes.fetchAccountTypes])(state),

  // Super Simplifier Users
  isSuperSimplifierClient: selectIsSuperSimplifierClient(state),
  superSimplifierDetails: selectSuperSimplifierDetails(state),
  uploadSuperSimplifierFormLoadingProgress: progressSelectorFactory([
    AccountsActionTypes.UploadSuperSimplifierSignedOngoingFeeConsentRenewal,
  ])(state),
  downloadSuperSimplifierApplicationLoadingProgress: progressSelectorFactory([
    AccountsActionTypes.DownloadSuperSimplifierApplication,
  ])(state),
  downloadConsentFeeRenewalLoadingProgress: progressSelectorFactory([
    AccountsActionTypes.DownloadSuperSimplifierOngoingFeeConsentRenewal,
  ])(state),
  fetchPendingSuperAccountLoadingProgress: progressSelectorFactory([AccountsActionTypes.FetchPendingSuperAccount])(
    state
  ),
  sendApplicationLoadingProgress: progressSelectorFactory([
    AccountsActionTypes.SendApplicationForm,
    AccountsActionTypes.SubmitDesktopBrokerAccountApplication,
    AccountsActionTypes.SubmitOpenMarketsAccountApplication,
  ])(state),
  documentTypes: selectDocumentTypes(state),
  clientDetails: selectMainDetailsItem(state),
  clientContactDetails: selectEntityDetails(state)?.contactDetails,
  macquarieCashOnlinePortal: selectMacquarieCashOnlinePortal(state),

  loadingPendingProgress: progressSelectorFactory([AccountsActionTypes.FetchPendingAccounts])(state),
  hasClientEditAdminOnlyPermission: selectHasClientEditAdminOnly(state),
  pendingFeesChangeSet: selectPendingFeesChangeSet(state),

  entityDetailsType: selectEntityDetails(state)?.type,
  entityDetailsSubTypeId: selectEntityDetails(state)?.subTypeId
});

const mapDispatchToProps = {
  ...accountsSlice.actions,
  createExternalAccount,
  createUnlistedAccount,
  downloadSuperSimplifierOngoingFeeConsentRenewal,
  downloadSuperSimplifierApplication,
  actionApplicationSubmission,
  createCustodyAccounts,
  fetchAccounts,
  fetchBPAYDetails,
  fetchAttachableCmaAccounts,
  fetchPendingAccounts,
  fetchAccountTypes,
  fetchClientAccount,
  fetchDocumentTypes,
  fetchExternalAccountForEdit,
  fetchExternalInstitutions,
  fetchTradingAccountForEdit,
  onDetachAccount,
  sendApplicationForm,
  sendFeeConsentRenewal,
  uploadSuperSimplifierOngoingFeeConsentRenewalForm,
  uploadSuperSimplifierApplicationForm,
  fetchEntityDetails,
  submitOpenMarkets,
  submitAMM,
  submitDesktopBroker,
  createPendingAccount,
  linkExsistingPendingAccount,
  initiateExistingMacquarieAccountApplication,
  initiateNewMacquarieAccountApplication,
  initiateUxchangeworkflowAccountApplicationWorkflow,
  fetchClientNewMacquarieAccountDetailByClientId,
  deletePendingAccount,
  updatePendingAccountStatus,
  fetchPendingSuperAccount,
  activateFees,
  activateFeesDocusign: activateFeeRenewalDocusign,
  activateFeesUpload: activateFeeRenewalUpload,
  fetchPendingAdviceFees,
  fetchClientContacts,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector>;
export const AccountsContainer = connector(Accounts);
