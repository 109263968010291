import 'date-fns';
import React, { useEffect } from 'react';
import { PagedRequest } from '../../../../../../store';
import { DocumentsTable } from '../../../common/components/documents/documentsTable';
import { SaveDocumentDetails } from '../../../common/store/types';
import { Props } from '../container';

export const Documents = (props: Props): JSX.Element => {
  const {
    hasClientEditPermission,
    clientId,
    documents,
    documentTypes,
    parameters,
    fetchDocumentForEdit,
    selectEditDocument,
    setAddMode,
    cancelAddEditMode,
    fetchDocumentTypes,
    fetchDocuments,
    downloadDocument,
    progress,
    deleteDocument,
    setDocumentsParameters,
    saveDocument,
    saveProgress,
    hasClientEditAdminOnlyPermission,
  } = props;

  const hideColumns = ['points', 'expiry'];

  useEffect(() => {
    if (!!clientId) {
      fetchDocuments({ clientId, parameters });
    }
  }, [fetchDocumentTypes, fetchDocuments, clientId, parameters]);

  const onSaveDocument = (document: SaveDocumentDetails) => {
    if (!!clientId) {
      saveDocument({
        clientId,
        document,
        fetchPayload: { clientId, parameters },
      });
    }
  };

  const onDeleteDocument = (documentId: number) => {
    if (!!clientId) {
      deleteDocument({
        clientId,
        attachmentId: documentId,
        fetchPayload: {
          clientId,
          parameters,
        },
      });
    }
  };

  const handleDownloadDocument = (attachmentId: number, filename: string) => {
    if (!!clientId) {
      downloadDocument({
        clientId,
        attachmentId,
        filename,
      });
    }
  };

  const handleGridActions = (pagedRequest: PagedRequest) => {
    setDocumentsParameters({ ...parameters, pagination: pagedRequest });
  };

  const onAddEdit = (documentId: number | null | undefined) => {
    if (!!documentId && !!clientId) {
      // load document from backend
      fetchDocumentForEdit({ clientId, documentId });
    } else if (documentId === null) {
      setAddMode();
    } else {
      cancelAddEditMode();
    }
  };

  return (
    <DocumentsTable
      hideAllActions={!hasClientEditPermission}
      type="Documents"
      hideColumns={hideColumns}
      fetchDocumentTypes={fetchDocumentTypes}
      documentTypes={documentTypes}
      documents={documents.documents.items}
      pagination={parameters.pagination}
      progress={progress}
      saveProgress={saveProgress}
      selectedDocument={selectEditDocument}
      onAddEdit={onAddEdit}
      handleGridActions={handleGridActions}
      handleDownloadDocument={handleDownloadDocument}
      onSave={onSaveDocument}
      onDelete={onDeleteDocument}
      hasClientEditAdminOnlyPermission={hasClientEditAdminOnlyPermission}
    ></DocumentsTable>
  );
};
