import { all, AllEffect, call, ForkEffect, put, select, takeLatest } from 'redux-saga/effects';
import { selectClientId } from '../../common/store/selectors';
import { realisedActions, unrealisedActions } from '../store/slice';
import { selectParameters } from './selectors';
import { fetchRealisedService, fetchUnrealisedService } from './services';
import { GainsLossesParameters, GainsLossesResult } from './types';

function* fetchUnrealisedRequest() {
  try {
    const parameters: GainsLossesParameters = yield select(selectParameters);
    const clientId: number = yield select(selectClientId);
    const response: GainsLossesResult = yield call(fetchUnrealisedService, parameters, clientId);

    yield put(unrealisedActions.fulfilled(response));
  } catch (e) {
    yield put(unrealisedActions.rejected(e));
  }
}

function* fetchUnrealisedSaga() {
  yield takeLatest(unrealisedActions.pending, fetchUnrealisedRequest);
}

function* fetchRealisedRequest() {
  try {
    const parameters: GainsLossesParameters = yield select(selectParameters);
    const clientId: number = yield select(selectClientId);
    const response: GainsLossesResult = yield call(fetchRealisedService, parameters, clientId);
    yield put(realisedActions.fulfilled(response));
  } catch (e) {
    yield put(realisedActions.rejected(e));
  }
}

function* fetchRealisedSaga() {
  yield takeLatest(realisedActions.pending, fetchRealisedRequest);
}

export default function* rootSaga(): Generator<AllEffect<Generator<ForkEffect<never>, void, unknown>>, void, unknown> {
  yield all([fetchRealisedSaga(), fetchUnrealisedSaga()]);
}
