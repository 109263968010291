import { createAction } from '@reduxjs/toolkit';

export const cancelDocumentAddEditMode = createAction('trustee/cancelDocumentAddEditMode');

export const setPhoneNumberEditId = createAction<number | null | undefined>('trustee/setPhoneNumberEditId');

export const setEmailAddressEditId = createAction<number | null | undefined>('trustee/setEmailAddressEditId');

export const cancelContactDocumentAddEditMode = createAction('trustee/cancelContactDocumentAddEditMode');
export const cancelContactIdentificationAddEditMode = createAction('trustee/cancelContactIdentificationAddEditMode');

export const setContactPhoneNumberEditId = createAction<number | null | undefined>('trustee/setContactPhoneNumberEditId');

export const setContactEmailAddressEditId = createAction<number | null | undefined>('trustee/setContactEmailAddressEditId');
