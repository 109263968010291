import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import FileSaver from 'file-saver';
import { buildEncodedQueryString, extractFilenameFromContentDisposition } from 'src/common';
import api, { ApiError } from '../../../../app/api';
import { PagedResult } from '../../../../store';
import {
  FetchPagedResults,
  FetchWorkflowListPayload,
  GetPensionAccountsRequest,
  PensionAccountVM,
  PensionRestartWorkflowRequest,
  SuperMemberDataRequest,
  UpdateWorkflowResponse,
  WorkflowABAFileIdVM,
  WorkflowActionRequest,
  WorkflowItem,
} from './types';

export enum WorkflowListActionTypes {
  FetchWorkflowList = '@@workflows/list/FetchWorkflowList',
  UploadSuperMemberData = '@@workflows/list/supermemberdataimport',
  TerminateWorkflow = '@@workflows/list/terminateWorkflow',
  ResumeWorkflow = '@@workflows/list/resumeWorkflow',
  DiscardWorkflow = '@@workflows/list/discardWorkflow',
  CompleteWorkflow = '@@workflows/list/completeWorkflow',
  DownloadWorkflowDocument = '@@workflows/workflow/documentId',
  DownloadDocument = '@@workflows/workflow/DownloadDocument',
  FetchPensionAccounts = '@@workflows/list/GetPensionAccounts',
  RestartPensionWorkflow = '@@workflows/list/RestartPensionWorkflow'
}

export enum WorkflowListApiEndpoints {
  getAllWorkflows = '/workflowmanager/GetAllWorkflows',
  uploadSuperMemberData = '/SuperSimplifierWorkflow/UploadSuperMemberData',
  terminateWorkflow = '/workflowmanager/TerminateWorkflow',
  resumeWorkflow = '/workflowmanager/ResumeWorkflow',
  discardWorkflow = '/workflowmanager/DiscardWorkflow',
  completeWorkflow = '/workflowmanager/CompleteWorkflow',
  downloadWorkflowDocument = '/workflowmanager/GetWorkflowABAFileId',
  downloadDocument = 'documents/Download',
  getPensionAccounts = 'customers/GetPensionAccounts',
  restartPensionApplication = 'onboarding/RestartPensionApplication'
}

export const fetchWorkflowList = createAsyncThunk(
  WorkflowListActionTypes.FetchWorkflowList,
  async (wrapper: FetchWorkflowListPayload) => {
    const response = await getPagedWorkflowsList(wrapper);
    return {
      results: response.data,
      pagination: wrapper.pagination,
    } as FetchPagedResults<WorkflowItem>;
  }
);

const getPagedWorkflowsList = async (request: FetchWorkflowListPayload) => {
  const body = {
    pagedRequest: { ...request.pagination, pageNumber: request.pagination.pageNumber },
  };

  return api.post<PagedResult<WorkflowItem>>(WorkflowListApiEndpoints.getAllWorkflows, body);
};

export const uploadSuperMemberDataCsv = createAsyncThunk<void, SuperMemberDataRequest[], { rejectValue: string[] }>(
  WorkflowListActionTypes.UploadSuperMemberData,
  async (request: SuperMemberDataRequest[], thunkApi) => {
    try {
      return await api.post(WorkflowListApiEndpoints.uploadSuperMemberData, request);
    } catch (e) {
      const result: string[] = [`${JSON.stringify(e)}`];
      return thunkApi.rejectWithValue(result);
    }
  }
);

export const terminateWorkflow = createAsyncThunk(
  WorkflowListApiEndpoints.terminateWorkflow,
  async (request: WorkflowActionRequest) => {
    const workflowId = request.workflowId;
    try {
      const response = await api.post(WorkflowListApiEndpoints.terminateWorkflow, request).then(async () => {
        const body = GetRequestBody(workflowId);
        const res = await api.post<PagedResult<WorkflowItem>>(WorkflowListApiEndpoints.getAllWorkflows, body);
        return processResult(res, workflowId);
      });
      return response;
    } catch (e) {
      return formatError(e);
    }
  }
);

export const resumeWorkflow = createAsyncThunk(
  WorkflowListApiEndpoints.resumeWorkflow,
  async (request: WorkflowActionRequest) => {
    const workflowId = request.workflowId;
    try {
      const response = await api.post(WorkflowListApiEndpoints.resumeWorkflow, request).then(async () => {
        const body = GetRequestBody(workflowId);

        const res = await api.post<PagedResult<WorkflowItem>>(WorkflowListApiEndpoints.getAllWorkflows, body);
        return processResult(res, workflowId);
      });
      return response;
    } catch (e) {
      return formatError(e);
    }
  }
);

export const discardWorkflow = createAsyncThunk(
  WorkflowListApiEndpoints.discardWorkflow,
  async (request: WorkflowActionRequest) => {
    const workflowId = request.workflowId;
    try {
      const response = await api.post(WorkflowListApiEndpoints.discardWorkflow, request).then(async () => {
        const body = GetRequestBody(workflowId);

        const res = await api.post<PagedResult<WorkflowItem>>(WorkflowListApiEndpoints.getAllWorkflows, body);
        return processResult(res, workflowId);
      });
      return response;
    } catch (e) {
      return formatError(e);
    }
  }
);

export const completeWorkflow = createAsyncThunk(
  WorkflowListApiEndpoints.completeWorkflow,
  async (request: WorkflowActionRequest) => {
    const workflowId = request.workflowId;
    try {
      const response = await api.post(WorkflowListApiEndpoints.completeWorkflow, request).then(async () => {
        const body = GetRequestBody(workflowId);
        const res = await api.post<PagedResult<WorkflowItem>>(WorkflowListApiEndpoints.getAllWorkflows, body);
        return processResult(res, workflowId);
      });
      return response;
    } catch (e) {
      return formatError(e);
    }
  }
);
export const downloadWorkflowDocument = createAsyncThunk(
  WorkflowListApiEndpoints.downloadWorkflowDocument,
  async (request: WorkflowActionRequest) => {
    const queryString = buildEncodedQueryString({
      workflowId: request.workflowId,
    });

    await api
      .get<WorkflowABAFileIdVM>(`${WorkflowListApiEndpoints.downloadWorkflowDocument}${queryString}`)
      .then((response: AxiosResponse) => {
        const queryString = buildEncodedQueryString({
          clientId: response.data.clientId,
          attachmentId: response.data.documentId,
        });
        api
          .get(`${WorkflowListApiEndpoints.downloadDocument}${queryString}`, {
            responseType: 'blob',
          })
          .then((response: AxiosResponse) => {
            const fileName =
              extractFilenameFromContentDisposition(response.headers['content-disposition']) || 'ABAFile.csv';
            FileSaver.saveAs(new Blob([response.data]), fileName);
          });
      });
  }
);

export const getPensionAccounts = createAsyncThunk(WorkflowListActionTypes.FetchPensionAccounts,
  async (request: GetPensionAccountsRequest) => {
    const queryString = buildEncodedQueryString({
      statusFlag: request.statusFlag
    });

    const res = await api.get<PensionAccountVM[]>(`${WorkflowListApiEndpoints.getPensionAccounts}${queryString}`);

    return res.data;
  }
);

export const restartPensionWorkflow = createAsyncThunk(WorkflowListActionTypes.RestartPensionWorkflow,
  async (request: PensionRestartWorkflowRequest) => {
    const response = await api.post<string>(WorkflowListApiEndpoints.restartPensionApplication, request);
    if (response.data != null)
      return {message: 'Pension restart workflow created successfully.'};
  }
);

const formatError = (e: string): UpdateWorkflowResponse => {
  const result: string[] = [`${JSON.stringify(e)}`];
  const obj: ApiError = JSON.parse(result[0]);
  const res: UpdateWorkflowResponse = {
    message: obj?.message,
    success: false,
  };
  return res;
};

const processResult = (res: AxiosResponse<PagedResult<WorkflowItem>>, workflowId: string) => {
  try {
    const foundWorkflow = res.data.results.find((wf) => wf.id === workflowId);
    if (!!foundWorkflow) {
      const result: UpdateWorkflowResponse = {
        workflowItem: foundWorkflow,
        success: true,
      };
      return result;
    } else {
      const result: UpdateWorkflowResponse = {
        success: false,
        message: 'Unable to find workflow',
      };
      return result;
    }
  } catch (e) {
    const msg = formatError(e);
    const res: UpdateWorkflowResponse = {
      message: msg?.message,
      success: false,
    };
    return res;
  }
};

const GetRequestBody = (workflowId: string) => {
  return {
    pagedRequest: {
      pageNumber: 1,
      pageSize: 1,
      queryFields: [
        {
          fieldName: 'id',
          hasSearchTerm: true,
          searchTerm: workflowId,
          operator: 'Contains',
          isSortTerm: false,
          descendingSortDirection: false,
        },
      ],
    },
  };
};
