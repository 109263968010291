import { Description } from '@mui/icons-material';
import { MenuRoute } from 'src/features/clients/common/types';
import { BasicDetailsContainer } from './basicDetails/container';
import { CarriedForwardLossesContainer } from './carriedForwardLosses/container';

export const cgtConfigurationRoutes: MenuRoute[] = [
  {
    label: 'Basic Details',
    menuIndex: 0,
    path: 'details',
    component: BasicDetailsContainer,
    icon: Description,
    visible: (): boolean => true,
  },
  {
    label: 'Carry Forward',
    menuIndex: 1,
    path: 'carriedForwardLosses',
    component: CarriedForwardLossesContainer,
    icon: Description,
    visible: (): boolean => true,
  },
];
