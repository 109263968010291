import {
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DateTimeFormat, LoadingIndicator, ProgressButton, WO2Select, getLocalDateTime } from 'src/common';
import WO2Button from 'src/common/components/button/Button';
import { CGTFinancialYearRecord } from '../../store';
import { Props } from '../container';
import { UpdateParcelMatchingMethod } from '../store/types';

export const BasicDetails = (props: Props): JSX.Element => {
  const {
    clientId,
    fetchFinancialRecordsForClientLoadingProgress,
    fetchFinancialRecordsForClient,
    fetchCGTStrategies,
    clientCgtRecords,
    cgtStrategies,
    updateParcelMatchingMethod,
    fetchClientMainDetails,
    updateMatchingStrategyLoadingProgress,
  } = props;

  const [editMode, setEditMode] = useState(false);

  let currentSelectMatchingStrategy = props.currentSelectMatchingStrategy;

  const strats = cgtStrategies || [];

  const selectCGTStrategy = (id: number) => {
    const item = cgtStrategies.find((i) => i.strategyId === id);
    currentSelectMatchingStrategy = item?.strategyId;
  };

  useEffect(() => {
    if (strats && strats.length > 0 && !!currentSelectMatchingStrategy) {
      const foundStrat = strats.find((i) => i.strategyId === currentSelectMatchingStrategy);
      if (!foundStrat) {
        selectCGTStrategy(strats[0].strategyId);
      } else {
        selectCGTStrategy(foundStrat.strategyId);
      }
    }
  }, [strats, selectCGTStrategy]);

  useEffect(() => {
    fetchCGTStrategies();
    if (!!clientId) {
      fetchFinancialRecordsForClient(clientId);
    }
  }, [fetchCGTStrategies, fetchFinancialRecordsForClient, clientId]);

  const handleUpdateStrategyClick = () => {
    if (clientId !== null && !!currentSelectMatchingStrategy) {
      const payload: UpdateParcelMatchingMethod = {
        clientId: clientId,
        parcelMatchingMethodId: currentSelectMatchingStrategy,
      };
      updateParcelMatchingMethod(payload).then(() => {
        fetchClientMainDetails(clientId);
      });
    }
  };

  return (
    <>
      <Container>
        <Typography variant="h3" style={{ paddingBottom: '20px' }}>
          Capital Gains - Configuration
        </Typography>
        <Typography variant="h5" style={{ paddingBottom: '20px', marginBottom: '10px' }}>
          View and update capital gains configurations for your clients portfolio(s).
        </Typography>
        <Paper elevation={5} style={{ padding: '20px', marginBottom: '20px' }}>
          <LoadingIndicator progress={fetchFinancialRecordsForClientLoadingProgress} spinner={false}>
            {!!cgtStrategies && cgtStrategies.length > 0 && !!currentSelectMatchingStrategy && (
              <FormControl variant="outlined" style={{ width: '260px', marginBottom: '20px' }}>
                <InputLabel id="test-id-cgt-strategies-label">Parcel Match Method*</InputLabel>
                <WO2Select
                  labelId="test-id-cgt-strategies-label"
                  id="test-id-cgt-strategies"
                  defaultValue={currentSelectMatchingStrategy}
                  label={'Parcel Match Method*'}
                  onChange={(event) => {
                    selectCGTStrategy(Number(event.target.value));
                  }}
                  disabled={!editMode}
                >
                  {strats.map((item) => (
                    <MenuItem key={`${item.strategyId}`} value={item.strategyId}>
                      {item.description}
                    </MenuItem>
                  ))}
                </WO2Select>
              </FormControl>
            )}
            <Grid container direction="row" spacing={3} style={{ paddingBottom: '10px' }}>
              <Grid item={true} xs={12}>
                <Typography style={{ paddingTop: '10px', color: '#7D7D7D' }}>
                  *Changes to parcel match method will apply from the lock date. Please contact DASH client service team
                  for any changes for prior financial years.
                </Typography>
              </Grid>
              <Grid item container xs={12} justifyContent="flex-end">
                {editMode && (
                  <>
                    <WO2Button
                      type="button"
                      data-testid="cancelButton"
                      variant={'contained'}
                      onClick={() => setEditMode(!editMode)}
                      style={{ marginRight: '10px' }}
                    >
                      Cancel
                    </WO2Button>
                    <WO2Button
                      type="button"
                      data-testid="saveButton"
                      variant={'contained'}
                      onClick={() => {
                        handleUpdateStrategyClick();
                        setEditMode(!editMode);
                      }}
                      style={{ marginRight: '10px' }}
                    >
                      Save
                    </WO2Button>
                  </>
                )}
                {!editMode && (
                  <ProgressButton
                    disabled={clientId === null || !currentSelectMatchingStrategy}
                    type={'button'}
                    data-testid="save-cgt-matching-strategy-button"
                    variant={'contained'}
                    progress={updateMatchingStrategyLoadingProgress}
                    onClick={() => setEditMode(!editMode)}
                  >
                    Edit
                  </ProgressButton>
                )}
              </Grid>
            </Grid>
          </LoadingIndicator>
        </Paper>

        <Typography variant="h5" style={{ paddingBottom: '20px' }}>
          Financial year status
        </Typography>
        <Paper elevation={5}>
          <TableContainer component={Paper} elevation={0}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant="h5" style={{ color: '#7D7D7D' }}>
                      FINANCIAL YEAR ENDING
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="h5" style={{ color: '#7D7D7D' }}>
                      STATUS
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="h5" style={{ color: '#7D7D7D' }}>
                      FINALISED DATE
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {clientCgtRecords.map((record: CGTFinancialYearRecord) => (
                  <TableRow key={record.fyDescription}>
                    <TableCell component="th" scope="row">
                      <Typography variant="h5">{`${getLocalDateTime(
                        record?.fyEnd,
                        DateTimeFormat.FinancialDate
                      )}`}</Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography variant="h5">{record?.statusDescription}</Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography variant="h5">{`${getLocalDateTime(
                        record?.finalisedDate,
                        DateTimeFormat.FinancialDate
                      )}`}</Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Container>
    </>
  );
};
