/* eslint-disable @typescript-eslint/no-explicit-any */
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { PagedRequest } from 'src/store/types/pagedRequest';
import { progressSelectorFactory } from '../../../common/store/selectors';
import { RootState } from '../../../reducers';
import { selectHasClientEditAdminOnly, selectIsDataFromTriumph } from '../../../store/selectors';
import { resetClientStateAction } from '../client/store';
import { ClientList } from './components/list';
import {
  selectClientListResults,
  selectOnboardings,
  selectParameters,
  selectQuickOnboardingAfsls,
  selectTotals,
} from './store/selectors';
import { clientListSlice } from './store/slice';
import {
  ClientListActionTypes,
  createQuickOnboardClient,
  deleteOnboardingWizard,
  downloadClientList,
  downloadProposedOrders,
  fetchClientList,
  fetchClientsSummary,
  fetchOnboardingWizardsModels,
  resetStatus,
  resubmitClient,
} from './store/thunks';
import {
  DownloadClientListPayload,
  fetchClientListPayload,
  QuickOnboardClient,
  ResetStatusPayload,
} from './store/types';

const mapStateToProps = (state: RootState) => ({
  totals: selectTotals(state),
  clients: selectClientListResults(state),
  parameters: selectParameters(state),
  loadingProgress: progressSelectorFactory([ClientListActionTypes.FetchClientsSummary])(state),
  addQuickClientLoadingProgress: progressSelectorFactory([ClientListActionTypes.CreateQuickOnboardClient])(state),
  clientList: selectClientListResults(state),
  isDataFromTriumph: selectIsDataFromTriumph(state),
  clientListProgress: progressSelectorFactory([ClientListActionTypes.FetchClientList])(state),
  onboardings: selectOnboardings(state),
  onboardingsLoadProgress: progressSelectorFactory([ClientListActionTypes.FetchAllOnboarding])(state),
  // Quick Onboarding
  afsls: selectQuickOnboardingAfsls(state),
  hasClientEditAdminOnlyPermission: selectHasClientEditAdminOnly(state),

  resubmitProgress: progressSelectorFactory([ClientListActionTypes.ResubmitClient])(state),
  resetStatusProgress: progressSelectorFactory([ClientListActionTypes.ResetStatus])(state),
});

const mapDispatchToProps = (dispatch: any) => {
  return {
    setSearchStringParameter: (payload: string | null) =>
      dispatch(clientListSlice.actions.setSearchStringParameter(payload)),
    setPaginationParameter: (payload: PagedRequest) =>
      dispatch(clientListSlice.actions.setPaginationParameter(payload)),
    fetchAfsls: (isDataFromTriumph: boolean) => dispatch(fetchClientsSummary(isDataFromTriumph)),
    fetchClientsSummary: (isDataFromTriumph: boolean) => dispatch(fetchClientsSummary(isDataFromTriumph)),
    fetchClientList: (payload: fetchClientListPayload) => dispatch(fetchClientList(payload)),
    downloadClientList: (payload: DownloadClientListPayload) => dispatch(downloadClientList(payload)),
    downloadProposedOrders: (clientId: number) => dispatch(downloadProposedOrders(clientId)),
    fetchOnboardingWizardsModels: () => dispatch(fetchOnboardingWizardsModels()),
    deleteOnboardingWizard: (payload: { id: string }) => dispatch(deleteOnboardingWizard(payload)),
    // Quick Onboarding
    createQuickOnboardClient: (payload: QuickOnboardClient) => dispatch(createQuickOnboardClient(payload)),

    resubmitClient: (id: string) => dispatch(resubmitClient(id)),
    resetStatus: (payload: ResetStatusPayload) => dispatch(resetStatus(payload)),

    resetClientStateAction: () => dispatch(resetClientStateAction),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector> & RouteComponentProps;
export const ClientListStateContainer = connector(ClientList);
