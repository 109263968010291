import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../../../../app/api';
import { buildEncodedQueryString } from '../../../../../../common';
import { MainDetails, UpdateMainDetailsPayload } from '../../../common/store/types';

export enum MainDetailsActionTypes {
  Fetch = '@@client/details/mainDetails/Fetch',
  Update = '@@client/details/mainDetails/Update',
}

export enum MainDetailsApiEndpoints {
  Fetch = '/customers/GetClientMainDetails',
  Update = '/customers/UpdateClientAccount',
}

export const fetchClientAccount = createAsyncThunk(MainDetailsActionTypes.Fetch, async (clientId: number) => {
  const queryString = buildEncodedQueryString({
    clientId,
  });
  const response = await api.get<MainDetails>(`${MainDetailsApiEndpoints.Fetch}${queryString}`);
  return response.data;
});

export const updateClientAccount = createAsyncThunk(MainDetailsActionTypes.Update, async (payload: { details: UpdateMainDetailsPayload; message: string }) => {
  await api.post(`${MainDetailsApiEndpoints.Update}`, payload.details);
  return { message: payload.message };
});
