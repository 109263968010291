import { createSelector } from '@reduxjs/toolkit';
import { selectModel } from '../../store/selectors';

export const selectListState = createSelector(selectModel, (modelState) => modelState.list);
export const selectParameters = createSelector(selectListState, (listState) => listState.parameters);
export const selectStrategyParameters = createSelector(selectListState, (listState) => listState.strategyParameters);
export const selectModelList = createSelector(selectListState, (listState) => listState?.models);
export const selectStrategyList = createSelector(selectListState, (listState) => listState?.strategies);
export const selectPagination = createSelector(selectParameters, (parameters) => parameters.pagination);
export const selectStrategyPagination = createSelector(selectStrategyParameters, (parameters) => parameters.pagination);
