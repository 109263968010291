import { moment } from 'src/common/types';
import { TriumphBaseRequest } from 'src/features/clients/client/portfolio/overview/store';

export interface ModelDashboardState {
  parameters: ModelDashboardParameters;
  modelDetail: ModelDetail | null;
  performanceDetails: PerformanceDetails | null;
}

export interface ModelDashboardParameters {
  performanceChartStartDate: PerformanceChartStartDateParameter;
  selectedGroup: string;
}

export enum PerformanceChartInterval {
  OneMonth = '1M',
  ThreeMonths = '3M',
  SixMonths = '6M',
  OneYear = '1Y',
  ThreeYears = '3Y',
  Inception = 'ALL',
}

export interface PerformanceChartStartDateParameter {
  startDate: moment;
  interval: PerformanceChartInterval;
}

export interface ModelDetail {
  modelId: number;
  modelVersionId: number;
  clientCount: number;
  method: string;
  name: string;
  fua: number;
  portfolioCount: number;
  oneMonthReturn: number;
  inceptionReturn: number;
  groups: AssetClassAllocations[];
}

export interface AssetClassAllocations {
  label: string;
  weight: number;
  value: number;
  componentAllocations: ComponentAllocations[];
}

export interface ComponentAllocations {
  componentType: string;
  componentId?: number;
  componentKey?: number;
  componentCode: string;
  componentName: string;
  unitPrice: number;
  units: number;
  value: number;
  weight: number;
}

export interface PerformanceDetails {
  modelId: number;
  modelVersionId: number;
  startMonth: Date;
  endMonth: Date;
  seriesData: SeriesData[];
}

export interface SeriesData {
  date: moment;
  return: number;
  cumulativeReturn: number;
}

export interface fetchPerformanceDetailsPayload {
  modelVersionId: number;
  startDate: moment;
  endDate: moment;
}

export interface FetchModelDetailPayload extends TriumphBaseRequest {
  modelVersionId: number;
}

export enum PerformanceChartFrequency {
  Daily = 'Daily',
  Monthly = 'Monthly',
}

export interface FetchModelDetailPayload extends TriumphBaseRequest {
  modelVersionId: number;
}
