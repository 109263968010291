import { makeStyles } from '@mui/styles';
import React from 'react';
import WO2Button from 'src/common/components/button/Button';

interface Props {
  onClick: () => void;
}

const useStyles = makeStyles(() => ({
  root: {
    padding: '0',
  },
}));

export default function SearchButton(props: Props): JSX.Element {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <WO2Button
        type="button"
        data-testid="searchButton"
        variant={'contained'}
        onClick={() => {
          props.onClick();
        }}
      >
        Search
      </WO2Button>
    </div>
  );
}
