import { DateTime } from 'luxon';
import { moment } from '../types';
import { Enumeration } from '../types/enumeration';

const pluralRules = new Intl.PluralRules('en-US', {
  type: 'ordinal',
});
const suffixes = new Map([
  ['one', 'st'],
  ['two', 'nd'],
  ['few', 'rd'],
  ['other', 'th'],
]);

export const SystemMinimumDate = '2000-01-01T00:00:00.00';

export class DateTimeFormat extends Enumeration {
  static Short = new DateTimeFormat(1, 'Short', 'dd/MM/yyyy');
  static DateTime = new DateTimeFormat(2, 'DateTime', 'dd/MM/yyyy HH:mm:ss');
  static Filename = new DateTimeFormat(3, 'Filename', 'dd-MM-yyyy HH-mm-ss');
  static ChartMonthDate = new DateTimeFormat(4, 'ChartMonthDate', 'MMM-yy');
  static ChartDayDate = new DateTimeFormat(5, 'ChartDayDate', 'dd-MMM-yy');
  static DailyDate = new DateTimeFormat(6, 'DailyDate', 'dd-MMM-yyyy');
  static MonthlyDate = new DateTimeFormat(7, 'MonthlyDate', 'MMM yyyy');
  static DayDate = new DateTimeFormat(8, 'DayDate', 'dd MMM yyyy');
  static MonthDate = new DateTimeFormat(9, 'MonthDate', 'MMMM yyyy');
  static LetterDate = new DateTimeFormat(10, 'LetterDate', 'dd MMM yyyy');
  static LetterShortDate = new DateTimeFormat(11, 'LetterShortDate', 'd MMM yyyy');
  static DateTimeShort = new DateTimeFormat(12, 'DateTimeShort', 'dd/MM/yyyy hh:mm a');
  static BackendDate = new DateTimeFormat(13, 'BackendDate', 'yyyy-MM-dd');
  static FinancialDate = new DateTimeFormat(14, 'FinancialDate', 'dd MMMM yyyy');
  static Year = new DateTimeFormat(15, 'Year', 'yyyy');
}

export const getLocalDateTimeNow = (format: DateTimeFormat): moment => DateTime.now().toFormat(format.displayName);

export const getLocalDateTime = (isoDateTime: moment | undefined, format: DateTimeFormat, isUtc = false): moment => {
  if (!!isoDateTime) {
    const dateTime = isUtc ? DateTime.fromISO(isoDateTime, { zone: 'utc' }).toLocal() : DateTime.fromISO(isoDateTime);

    if (format.id === DateTimeFormat.LetterDate.id) {
      return `${dateTime.day}${suffixes.get(pluralRules.select(dateTime.day))} ${dateTime.monthLong} ${dateTime.year}`;
    }
    return dateTime.toFormat(format.displayName);
  }
  return '';
};

export const getUtcDateTime = (isoDateTime: moment | undefined, format: DateTimeFormat, isUtc = false): moment => {
  if (!!isoDateTime && format.id !== DateTimeFormat.LetterDate.id) {
    return isUtc
      ? DateTime.fromFormat(isoDateTime, format.displayName).toISO()
      : DateTime.fromFormat(isoDateTime, format.displayName).toUTC().toISO();
  }
  return '';
};

export const datePickerDateToLuxonDate = (datePickerDate: string) => {
  return DateTime.fromJSDate(new Date(datePickerDate));
};
