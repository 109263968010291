import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { isLoadingSelectorFactory, progressSelectorFactory } from '../../../common';
import { RootState } from '../../../reducers';
import { PriceComponent } from './components/PriceComponent';
import { selectParameters, selectSecurityPriceChart, selectSecurityPrices, selectSecurityPriceStatistics } from './store/selectors';
import { securityPriceSlice } from './store/slice';
import {
  downloadSecurityPrices,
  fetchSecurityPriceChart,
  fetchSecurityPrices,
  fetchSecurityPricesExport,
  fetchSecurityPriceStatistics,
  SecurityPriceActionTypes,
} from './store/thunks';

const mapStateToProps = (state: RootState) => ({
  parameters: selectParameters(state),
  priceStatistics: selectSecurityPriceStatistics(state),
  priceChart: selectSecurityPriceChart(state),
  securityPrices: selectSecurityPrices(state),
  performanceChartLoading: isLoadingSelectorFactory([SecurityPriceActionTypes.FetchDailySecurityPricesChart])(state),
  securityPriceStatisticsProgress: progressSelectorFactory([SecurityPriceActionTypes.FetchSecurityPriceStatistics])(state),
  priceChartLoading: progressSelectorFactory([SecurityPriceActionTypes.FetchDailySecurityPricesChart])(state),
});

const mapDispatchToProps = {
  ...securityPriceSlice.actions,
  fetchSecurityPriceStatistics,
  fetchSecurityPriceChart,
  fetchSecurityPrices,
  fetchSecurityPricesExport,
  downloadSecurityPrices,
};

interface MatchParams {
  securityId?: string;
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector> & RouteComponentProps<MatchParams>;
export const SecurityPriceStateContainer = connect(mapStateToProps, mapDispatchToProps)(PriceComponent);
