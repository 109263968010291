import { Box, Grid, Typography } from '@mui/material';
import { Field, FieldProps, Formik } from 'formik';
import React from 'react';
import { ClientAccountSubType, ClientAccountType } from 'src/common/types';
import {
  AccountInstitutionType,
  ExternalAccount,
  ExternalInstitution,
} from '../../../../../common/components/accounts/types';
import {
  FormikEnumerationSelect,
  FormikNumberFormat,
  FormikSelect,
  FormikSwitch,
  FormikTextField,
} from '../../../../../common/components/formik';
import { AccountInstitutionItem } from '../../components/common/accountInstitutionItem';
import { SourceOfFunds } from '../../store/enums';
import { Accounts as AccountsType, ClientAccount } from '../../store/types';

export interface AccountsProps {
  clientAccount: ClientAccount;
  accountsValues: AccountsType;
  externalInstitutions: ExternalInstitution[];
  clientIsSuperOrPension: boolean;
}

interface AccountInfoValues {
  riskProfile: string;
  sourceOfFunds: string;
  isHinTransfer: boolean | null;
  hin: string | null;
  externalAccount: ExternalAccount | null;
}

export const Accounts = (props: AccountsProps): JSX.Element => {
  const { clientAccount, accountsValues, externalInstitutions, clientIsSuperOrPension } = props;
  const { existingAccounts, newAccounts, accountInfo } = accountsValues;

  const initialFormValues: AccountInfoValues = {
    riskProfile: accountInfo.riskProfile,
    sourceOfFunds: accountInfo.sourceOfFunds,
    isHinTransfer: accountInfo.isHinTransfer,
    hin: accountInfo.hin,
    externalAccount: accountInfo.externalAccount,
  };
  const institutions = externalInstitutions
    .filter((institution) => !!institution.description)
    .map((institution) => ({ id: institution.institutionId, name: institution.description }));

  return (
    <Box width="100%" maxWidth="800px">
      <Box marginBottom={'40px'}>
        {Array.isArray(existingAccounts) && existingAccounts.length > 0 && (
          <Box>
            <Typography variant="h4">Existing Accounts to Link</Typography>
            <Box display="flex" data-testid="accountsLinkExisting" marginBottom="40px">
              {existingAccounts.map((accountInstitution) => {
                return (
                  <AccountInstitutionItem
                    key={`accountExistingInstitutionItem_${accountInstitution.institutionId}`}
                    accountInstitution={AccountInstitutionType.getById(accountInstitution.institutionId)}
                    isSelected={true}
                    isAvailable={false}
                  />
                );
              })}
            </Box>
          </Box>
        )}
      </Box>
      <Box marginBottom={'40px'}>
        {Array.isArray(newAccounts) && newAccounts.length > 0 && (
          <Box>
            <Typography variant="h4">Accounts to be opened</Typography>
            <Typography variant="body1" style={{ marginTop: '10px' }}>
              Please note, these accounts will not be active until each application is submitted and approved by the
              respective third parties. This must be completed after the client is onboarded.
            </Typography>
            <Box display="flex" data-testid="accountsSelectNew" marginBottom="40px">
              {newAccounts.map((accountInstitution) => {
                return (
                  <AccountInstitutionItem
                    key={`accountNewInstitutionItem_${accountInstitution.institutionId}`}
                    accountInstitution={AccountInstitutionType.getById(accountInstitution.institutionId)}
                    isSelected={true}
                    isAvailable={false}
                  />
                );
              })}
            </Box>

            {!clientIsSuperOrPension && (
              <>
                <Typography variant="h4" gutterBottom>
                  Additional information for account providers
                </Typography>
                <Formik<AccountInfoValues>
                  enableReinitialize={true}
                  initialValues={initialFormValues}
                  onSubmit={() => {
                    /* VOID */
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} style={{ marginBottom: '30px' }}>
                      <Field
                        name="isHinTransfer"
                        component={FormikSwitch}
                        label="HIN Transfer (select this if you would like to transfer an existing HIN to the new broker account)"
                        disabled
                        fullWidth
                      />
                    </Grid>
                    {!!initialFormValues.isHinTransfer && (
                      <Grid container>
                        <Grid item xs={6}>
                          <Field
                            name="hin"
                            showRequiredAsterisk={true}
                            component={FormikTextField}
                            label="HIN"
                            disabled={true}
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                    )}
                    <>
                      <Grid item xs={6}>
                        <Field
                          component={FormikTextField}
                          name="riskProfile"
                          label="RISK PROFILE"
                          disabled={true}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          component={FormikEnumerationSelect}
                          type={SourceOfFunds}
                          valueIsId={false}
                          name="sourceOfFunds"
                          label="SOURCE OF FUNDS"
                          disabled={true}
                          fullWidth
                        />
                      </Grid>
                    </>
                  </Grid>
                </Formik>
              </>
            )}
          </Box>
        )}
        <Box>
          <Formik<AccountInfoValues>
            enableReinitialize={true}
            initialValues={initialFormValues}
            onSubmit={() => {
              /* VOID */
            }}
          >
            {clientAccount.accountTypeId === ClientAccountType.Individual.id &&
              clientAccount.subTypeId === ClientAccountSubType.Pension.id &&
              !!initialFormValues.externalAccount && (
                <Grid item xs={12} data-testid="externalAccountInfoGrid" style={{ marginTop: '20px' }}>
                  <Typography variant="h4" style={{ marginBottom: '20px' }}>
                    {"Where will the member's pension payments be paid to?"}
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Field
                        name="externalAccount.accountName"
                        component={FormikTextField}
                        label="ACCOUNT NAME"
                        showRequiredAsterisk={true}
                        disabled={true}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6} style={{ pointerEvents: 'none' }}>
                      <Field
                        fieldName="externalAccount.institutionId"
                        as={FormikSelect}
                        itemDisplayNameField="name"
                        label="INSTITUTION"
                        data={institutions.length > 0 ? institutions : undefined}
                        valueIsId={true}
                        showRequiredAsterisk={true}
                        disabled={true}
                        onChange={() => {
                          return;
                        }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        name="externalAccount.accountNumber"
                        component={FormikTextField}
                        label="ACCOUNT NUMBER"
                        showRequiredAsterisk={true}
                        disabled={true}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Field name="externalAccount.bsb" label="BSB" fullWidth>
                        {(fieldProps: FieldProps) => {
                          return (
                            <FormikNumberFormat
                              formikFieldProps={fieldProps}
                              numberFormatProps={{
                                format: '###-###',
                                mask: '_',
                                placeholder: '',
                                name: fieldProps.field.name,
                                label: 'BSB NUMBER',
                                isNumericString: true,
                                disabled: true,
                              }}
                              showRequiredAsterisk={true}
                              fullWidth={true}
                            />
                          );
                        }}
                      </Field>
                    </Grid>
                  </Grid>
                </Grid>
              )}
          </Formik>
        </Box>
      </Box>
    </Box>
  );
};
