import { Description, Payment, Preview, Report, TrackChanges, ViewInArRounded } from '@mui/icons-material';
import { MenuRoute } from '../../../common/types';
import { AccountsContainer } from './accounts/container';
import { ConstraintsContainer } from './constraints/container';
import { DetailsContainer } from './details/container';
import { ReviewContainer } from './review/container';
import { TargetsContainer } from './targets/container';
import { ReportContainer } from './report/container';

export const investmentsRoutes: MenuRoute[] = [
  {
    label: 'Basic Details',
    menuIndex: 0,
    path: 'details',
    component: DetailsContainer,
    icon: Description,
    visible: (): boolean => true,
  },
  {
    label: 'Portfolio Targets',
    menuIndex: 2,
    path: 'targets',
    component: TargetsContainer,
    icon: TrackChanges,
    visible: (): boolean => true,
  },
  {
    label: 'Constraint Settings',
    menuIndex: 1,
    path: 'constraints',
    component: ConstraintsContainer,
    icon: ViewInArRounded,
    visible: (): boolean => true,
  },
  {
    label: 'Linked Accounts',
    menuIndex: 3,
    path: 'accounts',
    component: AccountsContainer,
    icon: Payment,
    visible: (): boolean => true,
  },
  {
    label: 'Report Settings',
    menuIndex: 4,
    path: 'report',
    component: ReportContainer,
    icon: Report,
    visible: (): boolean => true,
  },
  {
    label: 'History',
    menuIndex: 7,
    path: 'review',
    component: ReviewContainer,
    icon: Preview,
    visible: (): boolean => true,
  },
];
