import { TabRoute } from '../../store';
import { FxDailyRateStateContainer } from './rate/container';

export const forexRateTabRoutes: TabRoute[] = [
  {
    label: 'RATES',
    order: 0,
    path: '/rates',
    component: FxDailyRateStateContainer,
    visible: (): boolean => true,
  }
];
