import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../store';

const selectReport = createSelector(
  (state: RootState) => state,
  (state) => state.report
);

export const selectParameters = createSelector(selectReport, (reportState) => reportState.parameters);
export const selectMyReport = createSelector(selectReport, (reportState) => reportState.myReport);
export const selectReportList = createSelector(selectReport, (reportState) => reportState.reportList);
