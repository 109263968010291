import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'src/app/api';
import { CGTStrategy, UpdateParcelMatchingMethod } from './types';

export enum BasicCGTConfigurationActionTypes {
  FetchCGTStrategies = '@@client/capitalGainsAndLosses/CGTStrategies/Fetch',
  UpdateParcelMatchingMethod = '@@client/capitalGainsAndLosses/CGTStrategies/UpdateParcelMatchingMethod',
}

export enum BasicCGTConfigurationApiEndpoints {
  FetchCGTStrategies = '/customers/GetCGTStrategies',
  UpdateParcelMatchingMethod = '/customers/UpdateParcelMatchingMethod',
}

export const fetchCGTStrategies = createAsyncThunk(BasicCGTConfigurationActionTypes.FetchCGTStrategies, async () => {
  const response = await api.get<CGTStrategy[]>(`${BasicCGTConfigurationApiEndpoints.FetchCGTStrategies}`);
  return response.data;
});

export const updateParcelMatchingMethod = createAsyncThunk(
  BasicCGTConfigurationActionTypes.UpdateParcelMatchingMethod,
  async (payload: UpdateParcelMatchingMethod) => {
    await api.post<UpdateParcelMatchingMethod>(
      `${BasicCGTConfigurationApiEndpoints.UpdateParcelMatchingMethod}`,
      payload
    );
  }
);
