import { connect, ConnectedProps } from 'react-redux';
import { selectClientStatus } from 'src/features/clients/client/common/store/selectors';
import { selectHasClientEdit, selectHasClientEditAdminOnly } from 'src/store/selectors';
import { progressSelectorFactory } from '../../../../../common';
import { RootState } from '../../../../../reducers';
import { selectClientId, selectSuperSimplifierDetails } from '../../common/store/selectors';
import { CommonActionTypes, updateSuperSimplifier } from '../../common/store/thunks';
import { selectMainDetailsItem } from '../mainDetails/store/selectors';
import { SuperSimplifier } from './components/superSimplifier';
import { superSimplifierRoutes } from './superSimplifierRoutes';

const mapStateToProps = (state: RootState) => ({
  clientId: selectClientId(state),
  details: selectSuperSimplifierDetails(state),
  loadingProgress: progressSelectorFactory([CommonActionTypes.FetchSuperSimplifier])(state),
  savingProgress: progressSelectorFactory([CommonActionTypes.UpdateSuperSimplifier])(state),
  detailsRoutes: superSimplifierRoutes,
  hasClientEditPermission: selectHasClientEdit(state),
  hasClientEditAdminOnlyPermission: selectHasClientEditAdminOnly(state),
  status: selectClientStatus(state),
  mainDetailsItem: selectMainDetailsItem(state),
});

const mapDispatchToProps = {
  updateSuperSimplifier,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector>;
export const SuperSimplifierContainer = connector(SuperSimplifier);
