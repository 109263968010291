import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  additionalInfo: {
    width: '100%',
    '& .accountInfoSelect': {
      '& > fieldset': {
        width: '800px',
        border: 'none',
        margin: '0',
        padding: '0',
        '& div.MuiGrid-container': {
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          '& div.MuiGrid-item': {
            width: '100%',
            marginBottom: '30px',
          },
          '& .selectPrimaryContact': {
            width: '390px',
            marginBottom: '30px',
            '& .MuiOutlinedInput-root': {
              borderRadius: '50px',
            },
          },
        },
      },
      '& .accountInformation': {
        '& div.MuiGrid-container': {
          '& div.MuiGrid-item': {
            width: 'calc(50% - 10px)',
          },
        },
      },
      '& .accountOtherInfo': {
        '& div.MuiGrid-container': {
          width: '390px',
        },
        '& .serviceType': {
          '& button': {
            height: '36px',
            minHeight: '36px',
          },
        },
      },
    },
  },
  autoCompleteInnerTextField: {
    margin: '0 !important',
  },
}));
