import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { selectClientId } from '../common/store';
import { CapitalGainsLossesSwitch } from './components/gainslossesSwitch';
import { fetchFinancialRecordsForClient } from './configuration/store/thunks';
import { RootState } from '../../../../reducers';

const mapStateToProps = (state: RootState) => ({
  clientId: selectClientId(state),
});

interface MatchParams {
  investmentServiceId?: string;
}

const mapDispatchToProps = {
  fetchFinancialRecordsForClient,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector> & RouteComponentProps<MatchParams>;
export const CapitalGainsAndLossesContainer = connect(mapStateToProps, mapDispatchToProps)(CapitalGainsLossesSwitch);
