import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { rebalanceTemplate } from './thunks';
import { RebalancePortfolioResponse, RebalanceState } from './types';

export const initialState: RebalanceState = {
  rebalanceId: null,
  validationWarnings: [],
};

export const rebalanceSlice = createSlice({
  name: '@common/clients/client/portfolios/rebalance',
  initialState,
  reducers: {
    clearRebalance: (state) => {
      state.rebalanceId = initialState.rebalanceId;
      state.validationWarnings = initialState.validationWarnings;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(rebalanceTemplate.fulfilled, (state, action: PayloadAction<RebalancePortfolioResponse>) => {
      state.rebalanceId = action.payload.rebalanceId;
      state.validationWarnings = action.payload.validationMessages;
    });
  },
});
