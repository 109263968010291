/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'src/app/api';
import { ArchivePortfolioPayload, ArchivePortfolioResponse } from '.';

export enum ArchiveActionTypes {
  ArchivePortfolio = '@@client/investmentServices/configuration/ArchivePortfolio',
}

export enum ArchiveApiEndpoints {
  ArchivePortfolio = '/api/investmentServices/ArchiveInvestmentService',
}

export const archivePortfolio = createAsyncThunk<ArchivePortfolioResponse, ArchivePortfolioPayload>(
  ArchiveActionTypes.ArchivePortfolio,
  async (payload: ArchivePortfolioPayload) => {
    return await api
      .post<ArchivePortfolioResponse>(ArchiveApiEndpoints.ArchivePortfolio, payload)
      .then((response) => {
        return { ...response.data, message: 'Portfolio archived' };
      })
      .catch((e) => {
        throw e;
      });
  }
);
