import { TabRoute } from '../../../../store';
import { EmailSearchFiltersContainer } from '../../email/search/filtersContainer';
import { OrderSearchFiltersContainer } from '../../order/search/filtersContainer';
import { RebalanceSearchFiltersContainer } from '../../rebalance/search/filtersContainer';
import { ReportSearchFiltersContainer } from '../../report/search/filtersContainer';

export const bulkTabRoutes: TabRoute[] = [
  {
    label: 'REPORT',
    order: 0,
    path: '/report',
    component: ReportSearchFiltersContainer,
    visible: (hasClientEditAdminOnlyPermission?: boolean): boolean => !!hasClientEditAdminOnlyPermission,
  },
  {
    label: 'EMAIL',
    order: 1,
    path: '/email',
    component: EmailSearchFiltersContainer,
    visible: (): boolean => true,
  },
  {
    label: 'ORDER',
    order: 2,
    path: '/order',
    component: OrderSearchFiltersContainer,
    visible: (): boolean => true,
  },
  {
    label: 'REBALANCE',
    order: 3,
    path: '/rebalance',
    component: RebalanceSearchFiltersContainer,
    visible: (): boolean => process.env.REACT_APP_NONPROD_ENV === 'true',
  },
];
