import { Enumeration } from './enumeration';

export class StatusType extends Enumeration {
  static Pending = new StatusType(1, 'Pending', 'Pending');
  static Active = new StatusType(2, 'Active', 'Active');
  static Terminated = new StatusType(3, 'Terminated', 'Terminated');
  static WaitingForApproval = new StatusType(4, 'WaitingForApproval', 'Waiting for Approval');
  static Stalled = new StatusType(5, 'Stalled', 'Stalled');
  static DatafeedActive = new StatusType(6, 'DatafeedActive', 'datafeedActive');

  static getById = (status: number): StatusType | undefined => {
    return Object.values(StatusType)
      .filter((t) => t instanceof StatusType)
      .find((t: StatusType) => t.id === status);
  };
}
