import { Breakpoint } from '@mui/material';
import { EditablePagedCollection } from 'src/common/store/types';
import { moment } from 'src/common/types';
import { Contact, ContactRoles } from 'src/features/clients/common/types';
import { PagedRequest } from 'src/store';

export interface ClientCommonState {
  clientId: number | null;
  portfolios: ClientPortfolio[];
  entityDetails?: EntityDetails;
  clientMainDetails?: ClientMainDetails;
  accounts: ClientAccount[];
  documentTypes: DocumentType[];
  roles: Role[];
  superSimplifier: SuperSimplifierDetails | null;
}

export interface ClientPortfolio {
  investmentProgramId: number | null;
  investmentServiceName: string;
  inceptionDate: moment;
}

export interface EntityDetails {
  clientId: number;
  name: string;
  type: string;
  subTypeId: number | null;
  adviserId: number;
  practiceId: number;
  afslId: number;
  afslNo: number;
  afslName: string;
  contactDetails?: EntityContactDetails;
  superMemberNumber: string;
}

export interface ClientMainDetails {
  abn: string | null;
  accountName: string;
  accountTypeId: number;
  acn: string | null;
  applicationId: string | null;
  corporateTypeId: number;
  designationAccount: string;
  inceptionDate: moment | null;
  industryType: string | null;
  isResident: boolean | null;
  name: string;
  natureOfBusiness: string | null;
  preferredName: string;
  riskProfile: string;
  servicesAgreementDate: moment | null;
  sourceOfFunds: string;
  status: number;
  subTypeId: number | null;
  tfn: string | null;
  tfnExemptionId: number | null;
  trusteeTypeId: number | null;
  typeOfTrustId: number | null;
  currentCGTStrategyId: number | null;
}

export interface EntityContactDetails {
  name: string;
  email: string;
  phone: string;
}

export interface ClientAccount {
  accountId: number;
  accountName: string;
  accountNumber: string;
  investmentProgramIds: number[];
}

export interface ClientAccountSelectModel {
  accountId: number | null;
  accountName: string;
  accountNumber: string;
  accountIdList: number[]; // account ids array to be used for endpoint to cater for All accounts
}

export interface AddressDetails {
  addressId: number | null;
  addressTypeIds?: number[];
  line1: string;
  line2: string;
  suburb: string;
  state: string;
  postcode: string;
  country: string;
}

export interface UpdateAddressesModel extends AddressDetails {
  clientId: number;
}

export interface DeleteAddressesModel {
  clientId: number;
  addressId: number;
}

export interface DocumentDetails {
  id: number | null;
  name: string;
  fileName: string;
  issueDate: moment | null;
  expiryDate: moment | null;
  typeId: number | null;
  type?: string;
  extensionId: number | null;
  number: string;
  placeOfIssue: string;
  isPhotoShown: boolean | null;
  dateCreated?: moment;
  isEditable: boolean;
}

export interface IdentificationDetails {
  id: number | null;
  stockNumber: string;
  number: string;
  fileName: string;
  issueDate: moment | null;
  typeId: number | null;
  type?: string;
  extensionId: number | null;
  dateCreated?: moment;
  isEditable: boolean;
}

export interface SaveDocumentDetails {
  id: number | null;
  name: string;
  fileName: string;
  base64Content: string | null;
  issueDate: moment | null;
  expiryDate: moment | null;
  typeId: number | null;
  number: string;
  placeOfIssue: string;
  file: File | null;
  isPhotoShown: boolean | null;
}

export interface SaveIdentificationDetails {
  id: number | null;
  name: string;
  fileName: string;
  base64Content: string | null;
  typeId: number | null;
  subType: number | null;
  file: File | null;
  identificationDocType: number | null;
  issueDate: moment | null;
  number: string;
  stockNumber: string;
  documentNo: string;
  centrelinkCardType: string;
  centrelinkCardTypeId: number | null;
  centrelinkReferenceNumber: string;
  expiryDate: string;
  centrelinkExpiryDate: string;
  medicareNumber: string;
  medicareExpiryDate: string;
  medicareCardColourTypeId: number | null;
  medicareCardColour: string;
  medicareIrn: string;
  licenceCardNumber: string;
  licenceNumber: string;
  licenceExpiryDate: string;
  licenceIssuePlace: string;
  issueStateOrTerritoryId: number | null;
  foreignPassportNumber: string;
  foreignPassportDateOfIssue: string;
  placeOfIssue: string;
  foreignPassportCountry: number | null;
  foreignPassportDateOfExpiry: string;
  registrationState: number | null;
  registrationNumber: string;
  certificateNumber: string;
  registrationDate: moment | null;
  certificatePrintedDate: moment | null;
  dateOfIssue: string;
  verifiedOn: moment | null;
  dateOfExpiry: string;
  birthCertificateNumber: string;
  birthCertificate: string;
  isEditable: boolean;
  IdentityVerificationCertificateVerifiedOn: string;
}

export interface GreenIdResponse {
  contactId: number;
  greenIdVerificationId: string;
  greenIdStatus: {
    id: number;
    greenIdStatus: string;
  };
}

export interface PhoneNumber {
  id: number | null;
  typeId: number;
  countryCode: string;
  areaCode: string;
  phoneNumber: string;
  preferred: boolean;
}

export interface EmailAddress {
  id: number | null;
  typeId: number;
  emailAddress: string;
  clientPortalAccess: boolean;
  hasMoneysoftAccess: boolean;
  preferred: boolean;
}

export interface DocumentType {
  id: number;
  stringKey: string;
  name: string;
  subType: string;
}

export interface UiIdentificationTypes extends DocumentType {
  uiTypeId: number;
}

export interface DocumentPoints {
  id: number;
  points: number;
  type: 'primary' | 'secondary';
}

export const feeConsentDocumentTypes: number[] = [69, 48];

export const identificationDocumentTypes: number[] = [3, 5, 7, 8, 9, 10, 11, 12, 14, 72];

export const identificationDocumentTypesWithExpiryDateRequired: number[] = [3, 8, 9, 10, 12, 14];

export const identificationDocumentPoints: DocumentPoints[] = [
  { id: 3, points: 70, type: 'primary' },
  { id: 5, points: 70, type: 'primary' },
  { id: 7, points: 70, type: 'primary' },
  { id: 8, points: 25, type: 'secondary' },
  { id: 9, points: 40, type: 'secondary' },
  { id: 10, points: 40, type: 'primary' },
  { id: 11, points: 20, type: 'secondary' },
  { id: 12, points: 70, type: 'primary' },
  { id: 13, points: 25, type: 'secondary' },
  { id: 14, points: 25, type: 'secondary' },
];

export interface Role {
  id: number;
  name: string;
}

export interface MainDetailsState {
  item?: MainDetails;
}

export type MainDetails = IndividualDetails & JointDetails & CompanyDetails & SmsfDetails & TrustDetails;

export interface DetailsBase {
  status: number;
  applicationId: string | null;
  accountTypeId: number;
  subTypeId: number | null;

  name: string;
  preferredName: string;
  tfn: string | null;
  tfnExemptionId: number | null;

  designationAccount: string;
  servicesAgreementDate: moment | null;
  inceptionDate: moment | null;
  riskProfile: string;

  sourceOfFunds: string;
  isResident: boolean | null;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IndividualDetails extends DetailsBase {}

export type JointDetails = DetailsBase;

export interface CompanyDetails extends DetailsBase {
  corporateTypeId: number | null;
  natureOfBusiness: string;
  industryType: string;
  abn: string | null;
  acn: string;
}

export interface SmsfDetails extends DetailsBase {
  abn: string | null;
  trusteeTypeId: number | null;
}

export interface TrustDetails extends DetailsBase {
  abn: string | null;
  trusteeTypeId: number | null;
  typeOfTrustId: number | null;
}

export interface UpdateMainDetailsPayload extends MainDetails {
  clientId: number;
}

export interface ContactDetail {
  id: number | null;
  areaCode: string;
  countryCode: string;
  phoneEmail: string;
  contactDetailTypeId: number;
  preferred: boolean;
  clientPortalAccess: boolean | null;
  hasMoneysoftAccess: boolean | null;
}

export interface ContactsState {
  items: ContactRoles[];
  edit: Contact;
}

export interface EditDocuments {
  parameters: DocumentsParameters;
  results: EditablePagedCollection<DocumentDetails>;
}

interface ContactDetailBase {
  titleId: number | null;
  genderId: number | null;
  firstName: string;
  middleName: string;
  lastName: string;
  dateOfBirth: moment | null;
  preferredName: string;
  tfn: string | null;
  tfnExemptionId: number | null;
  occupation: string;
  position: string | null;
  nationality: string;
  isResident: boolean;
  roles: RolePercentageModel[];
  isPrimary: boolean;
}

export interface ContactDetails extends ContactDetailBase {
  id: number | null;
  hasMoneysoftAccess: boolean;
  dvsConsentReceived?: boolean;
  greenIdVerificationId?: string;
  greenIdVerificationStatus?: number;
  consentAcceptedDate?: string;
}

export interface GreenidStatus {
  dvsConsentReceived?: boolean;
  greenIdVerificationId?: string;
  greenIdVerificationStatus?: number;
  consentAcceptedDate?: string;
}

export interface UpdateGreenIdDetailsPayload {
  contactId: number;
  dvsConsentReceived: boolean;
  consentAcceptedDate: string | null;
  greenIdVerificationId: string;
  greenIdStatus: number | null;
}

export interface FetchContactPayload {
  clientId: number;
  contactId: number;
}

export type SaveContactPayload = ContactDetails;

export interface SubmitGreenIdPayload {
  clientId: number;
  contactId: number;
}

export interface AttachContactPayload {
  clientId: number;
  contactId: number;
  roles: RolePercentageModel[];
  isPrimary: boolean;
}

export interface DetachContactPayload {
  parentId: number; // clientId or TrusteeId
  contactId: number;
}

export interface FetchContactsToAttachPayload {
  parentId: number; // clientId or TrusteeId
  searchText: string;
}

export interface FetchContactDocumentsPayload {
  clientId: number;
  contactId: number;
  parameters: DocumentsParameters;
}

export interface FetchContactIdentificationsPayload {
  clientId: number;
  contactId: number;
  parameters: IdentificationParameters;
}

export interface DownloadDocumentPayload {
  clientId: number;
  attachmentId: number;
  filename: string;
}

export interface SavePhoneNumberPayload {
  contactId: number;
  phoneNumber: PhoneNumber;
}

export interface UpdateContactDetail {
  contactId: number;
  contactDetailId: number | null;
  areaCode: string;
  countryCode: string;
  phoneEmail: string;
  contactDetailTypeId: number;
  preferred: boolean;
}

export interface UpdateContactEmailDetail {
  contactId: number;
  contactDetailId: number | null;
  areaCode: string;
  countryCode: string;
  phoneEmail: string;
  contactDetailTypeId: number;
  preferred: boolean;
  clientPortalAccess: boolean;
  hasMoneysoftAccess: boolean;
}

export interface UpdateContactEmailDetailPayload {
  contactId: number;
  contactDetailId: number | null;
  areaCode: string | null;
  countryCode: string | null;
  phoneEmail: string;
  contactDetailTypeId: number;
  preferred: boolean;
  clientPortalAccess: boolean | null;
  hasMoneysoftAccess: boolean | null;
}

export interface DeletePhoneNumberPayload {
  contactId: number;
  contactDetailId: number;
}

export interface SaveEmailAddressPayload {
  contactId: number;
  emailAddress: EmailAddress;
}

export interface DeleteEmailAddressPayload {
  contactId: number;
  contactDetailId: number;
}

export interface SaveDocumentPayload {
  clientId: number;
  document: SaveDocumentDetails;
  fetchPayload: FetchDocumentsPayload;
}

export interface SaveContactDocumentPayload {
  clientId: number;
  contactId: number;
  document: SaveDocumentDetails;
  fetchPayload: FetchContactDocumentsPayload;
}

export interface SaveContacIdentificationPayload {
  clientId: number;
  contactId: number;
  document: SaveIdentificationDetails;
  fetchPayload: FetchContactIdentificationsPayload;
}

export interface DeleteContactDocumentPayload {
  clientId: number;
  contactId: number;
  attachmentId: number;
  fetchPayload: FetchContactDocumentsPayload;
}

export interface FetchContactDocumentPayload {
  contactId: number;
  documentId: number;
}

export interface SaveAddressPayload {
  contactId: number;
  address: AddressDetails;
}

export interface DeleteAddressPayload {
  contactId: number;
  addressId: number;
}

export interface UpdateContactRolesPayload {
  clientId: number;
  contactId: number;
  roleMappings: RolePercentageModel[];
  isPrimary: boolean;
}

export interface RolePercentageModel {
  roleId: number;
  percent: number | null;
  relationship: string | null;
}

export interface FetchContactRolesRequestModel {
  roleMappings: RolePercentageModel[];
  isPrimary: boolean;
  hasMoneysoftAccess: boolean;
}

export const AuthorisedSignatoryRoleId = 9;
export const TrusteeRoleId = 4;
export const BeneficialOwnerRoleId = 16;
export const BeneficiaryRoleId = 2;
export const DirectorCompanyOfficerRoleId = 10;

export interface PercentageRole {
  id: number;
  fieldName: string;
  label: string;
}

export const PercentageRoles: PercentageRole[] = [
  { id: BeneficiaryRoleId, fieldName: 'beneficiaryPercentage', label: 'BENEFICIARY %' },
  { id: BeneficialOwnerRoleId, fieldName: 'beneficialOwnerPercentage', label: 'BENEFICIAL OWNER %' },
  {
    id: DirectorCompanyOfficerRoleId,
    fieldName: 'directorCompanyOfficerPercentage',
    label: 'DIRECTOR/COMPANY OFFICER %',
  },
];

export interface DocumentsParameters {
  pagination: PagedRequest;
}

export interface IdentificationParameters {
  pagination: PagedRequest;
}

export interface FetchDocumentsPayload {
  clientId: number;
  parameters: DocumentsParameters;
}

export interface FetchDocumentPayload {
  clientId: number;
  documentId: number;
}

export interface DeleteDocumentPayload {
  clientId: number;
  attachmentId: number;
  fetchPayload: FetchDocumentsPayload;
}

export interface DeleteContactDocumentPayload {
  clientId: number;
  contactId: number;
  attachmentId: number;
  fetchPayload: FetchContactDocumentsPayload;
}

export interface SaveDocumentPayload {
  clientId: number;
  document: SaveDocumentDetails;
  fetchPayload: FetchDocumentsPayload;
}

export interface SuperSimplifierDetails {
  superMemberNumber: string;
  subAccountTypeId: number | null;
  fundName: string;
  memberNumber: string;
  superAnnuationId: string;
  rolloverAmount: number | null;
  fullRollover: boolean;
  fundNameSecond: string;
  memberNumberSecond: string;
  superAnnuationIdSecond: string;
  rolloverAmountSecond: number | null;
  fullRolloverSecond: boolean;
  fundNameThird: string;
  memberNumberThird: string;
  superAnnuationIdThird: string;
  rolloverAmountThird: number | null;
  fullRolloverThird: boolean;
  resident: boolean | null;
  ageOver65: boolean | null;
  ageOver60NotPaid: boolean | null;
  ageNotOver60NotPaid: boolean | null;
  reachedAge: boolean | null;
  atp: boolean | null;
  paymentFrequencyId: number | null;
  paymentLimitTypeId: number | null;
  paymentLimit: number | null;
  paymentTypeId: number | null;
  pensionTypeId: number | null;
  feeAnniversaryDate: moment | null;
  inSpecie: boolean | null;
  inSpecieSecond: boolean | null;
  inSpecieThird: boolean | null;
  contributionsAmount: number | null;
  firstPaymentDate: string;
}

export interface UpdateSuperSimplifierPayload extends SuperSimplifierDetails {
  clientId: number;
}

export interface TypesItem {
  uiTypeId: number;
  apiTypeId: number;
  uiTypeName: string;
  apiTypeName: string;
}

export interface LicenceCardNumberLength {
  min: number;
  max: number;
  error: string;
  isValid?: (input: string) => boolean;
}

export interface HelpDoc {
  img1: string | null;
  img2: string | null;
  img3: string | null;
  img4: string | null;
  maxWidth?: Breakpoint;
}
