import { Autocomplete, AutocompleteRenderInputParams, Grid, Skeleton, TextField } from '@mui/material';
import { Field, Form, Formik, FormikProps } from 'formik';
import React, { useEffect, useRef } from 'react';
import { FormikEnumerationSelect } from 'src/common/components/formik';
import { LoadingProgress } from 'src/common/store/types/loadingProgress';
import { ServiceTypesEnum } from 'src/common/types/serviceType';
import * as yup from 'yup';
import { WO2Modal } from '../../../../../../../common/components/Modal';
import { InvestmentServiceTemplate } from '../../store/types';

export interface NewPortfolioDialogResponse {
  template: InvestmentServiceTemplate | null;
  serviceTypeId: number | null;
}

export interface Props {
  onClose: () => void;
  fetchTemplates: () => void;
  fetchTemplatesProgress: LoadingProgress;
  templates: InvestmentServiceTemplate[];
  onCreate: (payload: NewPortfolioDialogResponse) => Promise<void>;
  createProgress: LoadingProgress;
}

export function NewPortfolioDialog({ onClose, onCreate, templates, fetchTemplates, fetchTemplatesProgress, createProgress }: Props): JSX.Element {
  const formRef = useRef<FormikProps<NewPortfolioDialogResponse>>(null);
  const noTemplateOption = { name: '--- NONE ---', investmentServiceTemplateVersionId: null };

  useEffect(() => {
    fetchTemplates();
  }, []);

  const initialFormValues: NewPortfolioDialogResponse = {
    template: null,
    serviceTypeId: null,
  };

  return (
    <WO2Modal
      MuiDialogProps={{
        open: true,
        onClose: () => {
          onClose();
        },
        maxWidth: 'md',
      }}
      title={<>Create a portfolio</>}
      actions={[
        {
          label: 'Close',
          onClickHandler: () => {
            onClose();
          },
          type: 'outlined',
          disabled: false,
        },
        {
          label: 'Create',
          onClickHandler: () => {
            formRef.current?.submitForm();
          },
          type: 'contained',
          disabled: createProgress.isLoading,
          buttonType: 'submit',
        },
      ]}
    >
      <>
        {createProgress.isLoading && (
          <>
            <Skeleton variant="rectangular" width="100%" height={50} style={{ marginTop: '30px' }} />
            <Skeleton variant="rectangular" width="100%" height={118} style={{ marginTop: '10px' }} />
          </>
        )}
        {!createProgress.isLoading && (
          <>
            <p>Select a portfolio template or select &apos;None&apos; to set the portfolio details manually.</p>
            <Formik<NewPortfolioDialogResponse>
              innerRef={formRef}
              enableReinitialize={true}
              initialValues={initialFormValues}
              onSubmit={(values: NewPortfolioDialogResponse) => {
                onCreate(values).then(() => {
                  onClose();
                });
              }}
              validationSchema={yup.object({
                template: yup.object().nullable().shape({
                  investmentServiceTemplateVersionId: yup.number(),
                  name: yup.string().nullable(),
                  code: yup.string().nullable(),
                }),
                serviceTypeId: yup.number().nullable().required('Service type is required'),
              })}
            >
              {(formikProps: FormikProps<NewPortfolioDialogResponse>) => (
                <Form>
                  <fieldset style={{ border: 'none', padding: '0', paddingTop: '20px' }}>
                    <Grid container justifyContent="flex-start" spacing={2}>
                      <Grid item xs={12}>
                        <Field
                          component={Autocomplete}
                          options={[noTemplateOption, ...templates].map((o, idx) => ({ key: idx, ...o }))}
                          name="template"
                          label="TEMPLATE"
                          clearOnEscape={true}
                          fullWidth
                          loading={fetchTemplatesProgress.isLoading}
                          value={formikProps.values.template || noTemplateOption}
                          isOptionEqualToValue={(o: InvestmentServiceTemplate, v: InvestmentServiceTemplate) => {
                            return o.investmentServiceTemplateVersionId === v.investmentServiceTemplateVersionId;
                          }}
                          getOptionLabel={(option: InvestmentServiceTemplate | string): string => {
                            if (typeof option === 'string' || option instanceof String) {
                              // user is typing in box
                              return option as string;
                            }

                            if (!!option.code) {
                              return `${option.name} - ${option.code}`;
                            }
                            return `${option.name}`;
                          }}
                          renderInput={(params: AutocompleteRenderInputParams): React.ReactNode => (
                            <TextField
                              {...params}
                              label="TEMPLATE"
                              type="text"
                              variant="outlined"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              data-testid="template"
                            />
                          )}
                          renderOption={(props: React.HTMLAttributes<HTMLLIElement>, option: InvestmentServiceTemplate) => {
                            return (
                              <li {...props} key={option.investmentServiceTemplateVersionId}>
                                {option.name}
                              </li>
                            );
                          }}
                          onChange={(e: React.SyntheticEvent<Element, Event>, value: InvestmentServiceTemplate | null) => {
                            if (!!value) {
                              formikProps.setFieldValue('template', value);
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          component={FormikEnumerationSelect}
                          type={ServiceTypesEnum}
                          name="serviceTypeId"
                          showNone={false}
                          valueIsId={true}
                          label="SERVICE TYPE *"
                        />
                      </Grid>
                    </Grid>
                  </fieldset>
                </Form>
              )}
            </Formik>
          </>
        )}
      </>
    </WO2Modal>
  );
}
