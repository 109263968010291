import { Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { Props } from '../container';
import { ReportSettingsTable } from './reportSettingsTable';
import { SaveExclusionItem } from '../store/types';

export const ReportSettings = ({
  securityExclusions,
  afslId,
  clientId,
  investmentServiceId,
  isLoadingProgress,
  fetchSecurityExclusion,
  createOrUpdateSecurityExclusion,
  deleteSecurityExclusion,
  securities,
  securitiesLoadingProgress,
  savingProgressCreate,
  savingProgressDelete,
  clientsubTypeId
}: Props): JSX.Element => {

  useEffect(() => {
    if (!!investmentServiceId && !!clientId) {
      fetchSecurityExclusion({investmentServiceId, clientId });
    }
  }, [fetchSecurityExclusion, investmentServiceId, clientId]);

  return (
    <>
      <Typography variant="h3" style={{ paddingBottom: '20px' }}>
        Security Exclusions
      </Typography>
      <Typography variant="h5" style={{ paddingBottom: '30px' }}>
        View and update the security exclusions.
      </Typography>
    
      <ReportSettingsTable
        itemsSecuritiesExclusions={securityExclusions}
        clientSubTypeId={clientsubTypeId ?? undefined}
        afslId={afslId}
        clientId={clientId}
        investmentServiceId={investmentServiceId}
        securities={securities}
        onSave={async (exclusions: SaveExclusionItem[]) => {
          if (investmentServiceId && clientId) {

            const exclusionsToCreateOrUpdate: SaveExclusionItem[] = [];
            const exclusionsToDelete: number[] = [];
            const existingSecurityIds = securityExclusions.map(exclusion => exclusion.securityId);
            const validExistingSecurityIds = existingSecurityIds.filter((id): id is number => id !== null);
  
            exclusions.forEach(exclusion => {
              if (exclusion.securityId !== null && !validExistingSecurityIds.includes(exclusion.securityId)) {
                exclusionsToCreateOrUpdate.push(exclusion);
              } else if (exclusion.securityId !== null) {
                const securityExclusionDetail = securityExclusions.filter(x => x.securityId === exclusion.securityId);

                securityExclusionDetail.forEach(excludedItem => {
                  const exclusionUpdateItem: SaveExclusionItem = {
                    securityId: exclusion.securityId, 
                    excludeValuation: exclusion.excludeValuation, 
                    excludePerformance: exclusion.excludePerformance,
                    excludeFees: exclusion.excludeFees,
                    excludeRebalance: excludedItem.excludeRebalance
                };
                      
                exclusionsToCreateOrUpdate.push(exclusionUpdateItem)});
              }
            });

            securityExclusions.forEach(exclusion => {
              if (!exclusions.some(deleteExclusion => deleteExclusion.securityId === exclusion.securityId)) {
                exclusionsToDelete.push(exclusion.securityId);
              }
            });
            
            if (exclusionsToCreateOrUpdate.length > 0) {
              await createOrUpdateSecurityExclusion({
                clientId,
                investmentServiceId,
                securitiesExcluded: exclusionsToCreateOrUpdate,
              });
            }
  
            if (exclusionsToDelete.length > 0) {
                await deleteSecurityExclusion({
                clientId,
                investmentServiceId,
                securityIds: exclusionsToDelete,
              });
            }
          }
        }}
        itemLoadingProgress={isLoadingProgress}
        securitiesLoadingProgress={securitiesLoadingProgress}
        savingProgressCreate={savingProgressCreate}
        savingProgressDelete={savingProgressDelete}
      />
    </>
  );
};
