import Grid from '@mui/material/Grid';
import 'date-fns';
import React from 'react';
import { formatDollars } from '../../../../../common';
import CardComponent from '../../../../../common/components/CardComponent';
import { LoadingProgress } from '../../../../../common/store/types';
import { theme } from '../../../../../themes';
import { TransactionsTotals } from '../store/types';

export interface Props {
  loadingProgress: LoadingProgress;
  totals: TransactionsTotals | null;
}

export const TransactionCards = (props: Props): JSX.Element => {
  const { loadingProgress } = props;

  return (
    <>
      <Grid item xs={3}>
        <CardComponent
          progress={loadingProgress}
          background={theme.palette.gradient1?.main}
          value={!!props.totals ? formatDollars(props.totals?.purchases) : ''}
          subText={'PURCHASES'}
        />
      </Grid>
      <Grid item xs={3}>
        <CardComponent
          progress={loadingProgress}
          background={theme.palette.gradient2?.main}
          value={!!props.totals ? formatDollars(props.totals?.sales) : ''}
          subText={'SALES'}
        />
      </Grid>
      <Grid item xs={3}>
        <CardComponent
          progress={loadingProgress}
          background={theme.palette.gradient1?.main}
          value={!!props.totals ? formatDollars(props.totals?.corporateActions) : ''}
          subText={'CORP ACTIONS & OTHERS'}
        />
      </Grid>
      <Grid item xs={3}>
        <CardComponent
          progress={loadingProgress}
          background={theme.palette.gradient2?.main}
          value={!!props.totals ? formatDollars(props.totals?.income) : ''}
          subText={'INCOME'}
        />
      </Grid>
    </>
  );
};
