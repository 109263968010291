import React, { Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { formatDollars, progressSelectorFactory } from '../../../../../../common';
import CardComponent from '../../../../../../common/components/CardComponent';
import { RootState } from '../../../../../../reducers';
import { theme } from '../../../../../../themes';
import { selectGainsLossesDetail } from '../store/selectors';
import { PortfolioActionTypes } from '../store/thunks';

class CapitalReturnCard extends Component<Props> {
  render() {
    return (
      <CardComponent
        progress={this.props.loadingProgress}
        background={theme.palette.gradient1?.main}
        value={formatDollars(this.props.gainsLossesDetail?.costBase)}
        subText={'COST BASE'}
      />
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  gainsLossesDetail: selectGainsLossesDetail(state),
  loadingProgress: progressSelectorFactory([PortfolioActionTypes.FetchPortfolioDetail])(state),
});

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

export default connector(CapitalReturnCard);
