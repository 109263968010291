import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';
import {
  fetchClientAccount,
  fetchClientReport,
  fetchReportParametersList,
  getCGTRecordsForClient,
  getReportParametersById,
  getReportStatus,
  initiateReportGeneration,
  saveAppendixFile,
  saveCoverPageFile,
  saveReportParameters,
} from './thunks';
import {
  CGTRecord,
  ClientNewReportsState,
  FilterByTransactions,
  GroupByPortfolioValuation,
  HideByPortfolioValuation,
  HidingOptionAssetAllocation,
  HidingOptionHoldingsVsTargetVariance,
  HidingOptionsRequest,
  ReportGeneration,
  ReportingParameter,
  ReportingParameters,
  ReportToSort,
  SavedParameter,
  SortByPortfolioValuation,
  SortByTransactionHistory,
  GroupBySecurityPerformance,
  HidingOptionSecurityPerformance,
  HidingOptionPortfolioPerformance,
  GroupBySecurityContribution,
  HideBySecuriyContribution,
  GroupByTransactionHistory,
  HideByTransactionHistory,
  HideByAssetClassPerformance,
  GroupByIncomeReport,
  SortByIncomeReport,
  DateTypeIncomeReport,
  SortByCashTransactions,
  TempFileRef,
  MdaReportTypes,
} from './types';
import { HidingOptionTransactions } from '../../reports/store/types';
import { SystemMinimumDate } from 'src/common';
import { DateRange } from 'src/store';
import { MainDetails } from '../../common/store';

const dateFrom = DateTime.fromISO(SystemMinimumDate).toISODate();
const dateTo = DateTime.now().toISODate();

export const initialState: ClientNewReportsState = {
  parameters: {
    clientName: '',
    inceptionDate: '',
    dates: { dateFrom: dateFrom, dateTo: dateTo },
    isDataFromTriumph: false,
    mergeReports: false,
    reportsToSort: [],
    showItemOrdering: false,
    includeLogo: true,
    reportingParameter: {
      visibleToAfsl: false,
      isSaveReportParameters: false,
      parameterName: '',
    },
    expandedReports: false,
    includeCoverLetter: false,
    includeAppendix: false,
    coverLetter: undefined,
    appendix: undefined,
    selectedReports: {
      assetClassPerformance: {
        selected: false,
        dateRangeType: '',
        fromDate: dateFrom,
        toDate: dateTo,
        excludeFrankingCredits: true,
        showAnnualisedReturns: false,
        applyExclusionList: true,
        excludeCapitalIncomeReturns: false,
        includeAllocationChange: true,
        includeReturnsSummary: false,
      },
      holdingsVsTargetVariance: {
        selected: false,
        asAtDateType: '',
        asAtDate: dateTo,
        includeSummary: false,
        applyExclusionList: true,
      },
      portfolioSnapshot: {
        selected: false,
        dateRangeType: '',
        fromDate: dateFrom,
        toDate: dateTo,
        portfolioId: -1,
        benchmarkId: -1,
      },
      taxInvoice: {
        dateRangeType: '',
        fromDate: dateFrom,
        toDate: dateTo,
        selected: false,
      },
      portfolioPerformance: {
        selected: false,
        dateRangeType: '',
        fromDate: dateFrom,
        toDate: dateTo,
        excludeFrankingCredits: true,
        showAnnualisedReturns: false,
        applyExclusionList: true,
        includeAfterFeesReturn: true,
        benchmarkId: -1,
      },
      securityContribution: {
        selected: false,
        dateRangeType: '',
        fromDate: dateFrom,
        toDate: dateTo,
        groupBy: GroupBySecurityContribution.AssetClass,
        excludeFrankingCredits: true,
        showAnnualisedReturns: false,
        applyExclusionList: true,
        excludeCapitalIncomeReturns: true,
        includeDollarReturns: false,
        includeDetailTable: true,
      },
      taxSummary: {
        selected: false,
        dateRangeType: '',
        fromDate: dateFrom,
        toDate: dateTo,
      },
      investmentIncomeTaxComponent: {
        selected: false,
        dateRangeType: '',
        fromDate: dateFrom,
        toDate: dateTo,
      },
      incomeReport: {
        dateRangeType: '',
        fromDate: dateFrom,
        toDate: dateTo,
        selected: false,
        groupBy: GroupByIncomeReport.Account,
        sortBy: SortByIncomeReport.Security,
        dateType: DateTypeIncomeReport.ExDate,
        excludeCash: false,
        showOutstandingPaymentOnly: false,
      },
      transactionHistory: {
        dateRangeType: '',
        fromDate: dateFrom,
        toDate: dateTo,
        selected: false,
        excludeCash: false,
        includeDistribution: false,
        excludeAssetDescription: true,
        excludeAccounts: true,
        includeBrokerage: true,
        includeGST: false,
        includeOtherCharges: false,
        sortBy: SortByTransactionHistory.SecurityName,
        groupBy: GroupByTransactionHistory.Account,
      },
      cashTransactions: {
        fromDate: dateFrom,
        toDate: dateTo,
        sortBy: SortByCashTransactions.DateAscending,
        selected: false,
      },
      expenses: {
        fromDate: dateFrom,
        toDate: dateTo,
        excludeExpenseType: true,
        selected: false,
      },
      unrealisedCapitalGainsAndLosses: {
        selected: false,
        asAtDate: dateTo,
      },
      realisedCapitalGainsAndLosses: {
        fromDate: dateFrom,
        toDate: dateTo,
        includeSummary: true,
        selected: false,
      },
      securityPerformance: {
        fromDate: dateFrom,
        toDate: dateTo,
        groupBy: GroupBySecurityPerformance.AssetClass,
        excludeFrankingCredits: true,
        showAnnualisedReturns: false,
        excludeCapitalIncomeReturns: true,
        applyExclusionList: true,
        selected: false,
      },
      portfolioValuation: {
        asAtDate: dateTo,
        selected: false,
        excludeSecurityType: true,
        excludeGainsLosses: true,
        excludeGICS: true,
        excludeCostBase: true,
        excludeMarket: true,
        excludeAvgUnitCost: true,
        excludeEstIncomeYield: true,
        excludeEstGrossIncomeYield: true,
        excludeFXGainsLosses: true,
        excludeLocalPriceValue: true,
        applyExclusionList: true,
        sortBy: SortByPortfolioValuation.SecurityName,
        groupBy: GroupByPortfolioValuation.AssetClass,
        portfolioId: -1,
      },
      assetAllocation: {
        asAtDate: dateTo,
        includeAssetCategory: true,
        includeDetailTable: false,
        applyExclusionList: true,
        selected: false,
        portfolioId: -1,
      },
      additionsAndWithdrawals: {
        dateRangeType: '',
        fromDate: dateFrom,
        toDate: dateTo,
        selected: false,
      },
      unrealisedNonCGT: {
        selected: false,
        asAtDateType: '',
        asAtDate: dateTo,
      },
    },
    selectedParameter: undefined,
  },
  reportGeneration: {
    isGenerating: false,
    isExpired: false,
    requestId: '',
    isDataFromTriumph: false,
    isMergeReport: false,
    key: '',
    clientId: 0,
    clientName: '',
    isGenerated: false,
    mergeReportOrder: [],
    dateRange: '',
    reportName: '',
  },
  savedParametersList: [],
  clientFinancialYears: [],
};

const blankcgtRecord: CGTRecord = {
  fyDescription: '',
  currentYear: false,
  statusDescription: '',
  strategyDescription: '',
  externalCarryForwardLoss: 0.0,
  platformCarryForwardLoss: 0.0,
  finalisedDate: undefined,
};

export const newReportsSlice = createSlice({
  name: '@@client/newReports',
  initialState,
  reducers: {
    setDateParameter: (state, action: PayloadAction<DateRange>) => {
      state.parameters.dates = action.payload;
      state.parameters.selectedReports.portfolioSnapshot.fromDate = action.payload.dateFrom;
      state.parameters.selectedReports.portfolioSnapshot.toDate = action.payload.dateTo;
      state.parameters.selectedReports.portfolioSnapshot.dateRangeType = action.payload.dateRangeType;
      state.parameters.selectedReports.assetAllocation.asAtDate = action.payload.dateTo;
      state.parameters.selectedReports.assetAllocation.asAtDateType = action.payload.dateRangeType;
      state.parameters.selectedReports.unrealisedCapitalGainsAndLosses.asAtDate = action.payload.dateTo;
      state.parameters.selectedReports.unrealisedCapitalGainsAndLosses.asAtDateType = action.payload.dateRangeType;
      state.parameters.selectedReports.portfolioValuation.asAtDate = action.payload.dateTo;
      state.parameters.selectedReports.portfolioValuation.asAtDateType = action.payload.dateRangeType;
      state.parameters.selectedReports.incomeReport.fromDate = action.payload.dateFrom;
      state.parameters.selectedReports.incomeReport.toDate = action.payload.dateTo;
      state.parameters.selectedReports.incomeReport.dateRangeType = action.payload.dateRangeType;
      state.parameters.selectedReports.cashTransactions.fromDate = action.payload.dateFrom;
      state.parameters.selectedReports.cashTransactions.toDate = action.payload.dateTo;
      state.parameters.selectedReports.cashTransactions.dateRangeType = action.payload.dateRangeType;
      state.parameters.selectedReports.expenses.fromDate = action.payload.dateFrom;
      state.parameters.selectedReports.expenses.toDate = action.payload.dateTo;
      state.parameters.selectedReports.expenses.dateRangeType = action.payload.dateRangeType;
      state.parameters.selectedReports.realisedCapitalGainsAndLosses.fromDate = action.payload.dateFrom;
      state.parameters.selectedReports.realisedCapitalGainsAndLosses.toDate = action.payload.dateTo;
      state.parameters.selectedReports.realisedCapitalGainsAndLosses.dateRangeType = action.payload.dateRangeType;
      state.parameters.selectedReports.securityPerformance.fromDate = action.payload.dateFrom;
      state.parameters.selectedReports.securityPerformance.toDate = action.payload.dateTo;
      state.parameters.selectedReports.securityPerformance.dateRangeType = action.payload.dateRangeType;
      state.parameters.selectedReports.transactionHistory.fromDate = action.payload.dateFrom;
      state.parameters.selectedReports.transactionHistory.toDate = action.payload.dateTo;
      state.parameters.selectedReports.transactionHistory.dateRangeType = action.payload.dateRangeType;
      state.parameters.selectedReports.additionsAndWithdrawals.fromDate = action.payload.dateFrom;
      state.parameters.selectedReports.additionsAndWithdrawals.toDate = action.payload.dateTo;
      state.parameters.selectedReports.additionsAndWithdrawals.dateRangeType = action.payload.dateRangeType;
      state.parameters.selectedReports.investmentIncomeTaxComponent.fromDate = action.payload.dateFrom;
      state.parameters.selectedReports.investmentIncomeTaxComponent.toDate = action.payload.dateTo;
      state.parameters.selectedReports.investmentIncomeTaxComponent.dateRangeType = action.payload.dateRangeType;
      state.parameters.selectedReports.securityContribution.fromDate = action.payload.dateFrom;
      state.parameters.selectedReports.securityContribution.toDate = action.payload.dateTo;
      state.parameters.selectedReports.securityContribution.dateRangeType = action.payload.dateRangeType;
      state.parameters.selectedReports.taxSummary.fromDate = action.payload.dateFrom;
      state.parameters.selectedReports.taxSummary.toDate = action.payload.dateTo;
      state.parameters.selectedReports.taxSummary.dateRangeType = action.payload.dateRangeType;
      state.parameters.selectedReports.holdingsVsTargetVariance.asAtDate = action.payload.dateTo;
      state.parameters.selectedReports.holdingsVsTargetVariance.asAtDateType = action.payload.dateRangeType;
      state.parameters.selectedReports.assetClassPerformance.fromDate = action.payload.dateFrom;
      state.parameters.selectedReports.assetClassPerformance.toDate = action.payload.dateTo;
      state.parameters.selectedReports.assetClassPerformance.dateRangeType = action.payload.dateRangeType;
      state.parameters.selectedReports.unrealisedNonCGT.asAtDate = action.payload.dateTo;
      state.parameters.selectedReports.unrealisedNonCGT.asAtDateType = action.payload.dateRangeType;
      state.parameters.selectedReports.taxInvoice.fromDate = action.payload.dateFrom;
      state.parameters.selectedReports.taxInvoice.toDate = action.payload.dateTo;
      state.parameters.selectedReports.taxInvoice.dateRangeType = action.payload.dateRangeType;
    },
    expandAll: (state) => {
      state.parameters.expandedReports = !state.parameters.expandedReports;
      state.parameters.selectedReports.assetAllocation.expanded = state.parameters.expandedReports;
      state.parameters.selectedReports.assetClassPerformance.expanded = state.parameters.expandedReports;
      state.parameters.selectedReports.holdingsVsTargetVariance.expanded = state.parameters.expandedReports;
      state.parameters.selectedReports.cashTransactions.expanded = state.parameters.expandedReports;
      state.parameters.selectedReports.expenses.expanded = state.parameters.expandedReports;
      state.parameters.selectedReports.incomeReport.expanded = state.parameters.expandedReports;
      state.parameters.selectedReports.securityPerformance.expanded = state.parameters.expandedReports;
      state.parameters.selectedReports.securityContribution.expanded = state.parameters.expandedReports;
      state.parameters.selectedReports.portfolioSnapshot.expanded = state.parameters.expandedReports;
      state.parameters.selectedReports.portfolioPerformance.expanded = state.parameters.expandedReports;
      state.parameters.selectedReports.portfolioValuation.expanded = state.parameters.expandedReports;
      state.parameters.selectedReports.realisedCapitalGainsAndLosses.expanded = state.parameters.expandedReports;
      state.parameters.selectedReports.transactionHistory.expanded = state.parameters.expandedReports;
    },
    expandAssetAllocation: (state) => {
      state.parameters.selectedReports.assetAllocation.expanded =
        !state.parameters.selectedReports.assetAllocation.expanded;
    },
    expandAssetClassPerformance: (state) => {
      state.parameters.selectedReports.assetClassPerformance.expanded =
        !state.parameters.selectedReports.assetClassPerformance.expanded;
    },
    expandHoldingsVsTargetVariance: (state) => {
      state.parameters.selectedReports.holdingsVsTargetVariance.expanded =
        !state.parameters.selectedReports.holdingsVsTargetVariance.expanded;
    },
    expandCashTransactions: (state) => {
      state.parameters.selectedReports.cashTransactions.expanded =
        !state.parameters.selectedReports.cashTransactions.expanded;
    },
    expandExpenses: (state) => {
      state.parameters.selectedReports.expenses.expanded = !state.parameters.selectedReports.expenses.expanded;
    },
    expandIncomeReport: (state) => {
      state.parameters.selectedReports.incomeReport.expanded = !state.parameters.selectedReports.incomeReport.expanded;
    },
    expandSecurityPerformance: (state) => {
      state.parameters.selectedReports.securityPerformance.expanded =
        !state.parameters.selectedReports.securityPerformance.expanded;
    },
    expandPortfolioSnapshot: (state) => {
      state.parameters.selectedReports.portfolioSnapshot.expanded =
        !state.parameters.selectedReports.portfolioSnapshot.expanded;
    },
    expandPortfolioPerformance: (state) => {
      state.parameters.selectedReports.portfolioPerformance.expanded =
        !state.parameters.selectedReports.portfolioPerformance.expanded;
    },
    expandPortfolioValuation: (state) => {
      state.parameters.selectedReports.portfolioValuation.expanded =
        !state.parameters.selectedReports.portfolioValuation.expanded;
    },
    expandGainsAndLossesRealised: (state) => {
      state.parameters.selectedReports.realisedCapitalGainsAndLosses.expanded =
        !state.parameters.selectedReports.realisedCapitalGainsAndLosses.expanded;
    },
    expandSecurityContribution: (state) => {
      state.parameters.selectedReports.securityContribution.expanded =
        !state.parameters.selectedReports.securityContribution.expanded;
    },
    expandTransactionHistory: (state) => {
      state.parameters.selectedReports.transactionHistory.expanded =
        !state.parameters.selectedReports.transactionHistory.expanded;
    },
    setUseTriumphAsDataSourceParameter: (state, action: PayloadAction<boolean>) => {
      state.parameters.isDataFromTriumph = action.payload;
    },
    setMergeReportsParameter: (state, action: PayloadAction<boolean>) => {
      state.parameters.mergeReports = action.payload;
    },
    setIncludeLogoParameter: (state, action: PayloadAction<boolean>) => {
      state.parameters.includeLogo = action.payload;
    },
    setIncludeCoverLetterParameter: (state, action: PayloadAction<boolean>) => {
      state.parameters.includeCoverLetter = action.payload;
      if (!action.payload) state.parameters.coverLetter = undefined;
    },
    setIncludeAppendixParameter: (state, action: PayloadAction<boolean>) => {
      state.parameters.includeAppendix = action.payload;
      if (!action.payload) state.parameters.appendix = undefined;
    },
    setCashTransactionsSortByParameter: (state, action: PayloadAction<SortByCashTransactions>) => {
      state.parameters.selectedReports.cashTransactions.sortBy = action.payload;
    },
    setCashTransactionsSelectedParameter: (state, action: PayloadAction<boolean>) => {
      state.parameters.selectedReports.cashTransactions.selected = action.payload;
    },
    setUnrealisedNonCGTSelectedParameter: (state, action: PayloadAction<boolean>) => {
      state.parameters.selectedReports.unrealisedNonCGT.selected = action.payload;
    },
    setAssetClassPerformanceSelectedParameter: (state, action: PayloadAction<boolean>) => {
      state.parameters.selectedReports.assetClassPerformance.selected = action.payload;
    },
    setExpensesSelectedParameter: (state, action: PayloadAction<boolean>) => {
      state.parameters.selectedReports.expenses.selected = action.payload;
    },
    setExpensesExcludeExpenseTypeParameter: (state, action: PayloadAction<boolean>) => {
      state.parameters.selectedReports.expenses.excludeExpenseType = action.payload;
    },
    setCapitalGainsLossesUnrealisedSelectedParameter: (state, action: PayloadAction<boolean>) => {
      state.parameters.selectedReports.unrealisedCapitalGainsAndLosses.selected = action.payload;
    },
    setRealisedCapitalGainsAndLossesSelectedParameter: (state, action: PayloadAction<boolean>) => {
      state.parameters.selectedReports.realisedCapitalGainsAndLosses.selected = action.payload;
    },
    setRealisedCapitalGainsAndLossesSummaryParameter: (state, action: PayloadAction<boolean>) => {
      state.parameters.selectedReports.realisedCapitalGainsAndLosses.includeSummary = action.payload;
    },
    setSecurityPerformanceSelectedParameter: (state, action: PayloadAction<boolean>) => {
      state.parameters.selectedReports.securityPerformance.selected = action.payload;
    },
    setTransactionHistorySelectedParameter: (state, action: PayloadAction<boolean>) => {
      state.parameters.selectedReports.transactionHistory.selected = action.payload;
    },
    setTransactionHistoryHidingOptionsParameter: (
      state,
      action: PayloadAction<HidingOptionsRequest<HideByTransactionHistory>>
    ) => {
      state.parameters.selectedReports.transactionHistory[action.payload.option] = action.payload.value;
    },
    setAssetClassPerformanceHidingOptionParameter: (
      state,
      action: PayloadAction<HidingOptionsRequest<HideByAssetClassPerformance>>
    ) => {
      state.parameters.selectedReports.assetClassPerformance[action.payload.option] = action.payload.value;
    },
    setTransactionHistoryGroupByParameter: (state, action: PayloadAction<GroupByTransactionHistory>) => {
      state.parameters.selectedReports.transactionHistory.groupBy = action.payload;
    },
    setTransactionHistorySortByParameter: (state, action: PayloadAction<SortByTransactionHistory>) => {
      state.parameters.selectedReports.transactionHistory.sortBy = action.payload;
    },
    setIncomeReportSelectedParameter: (state, action: PayloadAction<boolean>) => {
      state.parameters.selectedReports.incomeReport.selected = action.payload;
    },
    setIncomeReportExcludeCashFilterOptionsParameter: (state, action: PayloadAction<boolean>) => {
      state.parameters.selectedReports.incomeReport.excludeCash = action.payload;
    },
    setIncomeReportOutstandingPaymentsOnlyFilterOptionsParameter: (state, action: PayloadAction<boolean>) => {
      state.parameters.selectedReports.incomeReport.showOutstandingPaymentOnly = action.payload;
    },
    setIncomeReportGroupByParameter: (state, action: PayloadAction<GroupByIncomeReport>) => {
      state.parameters.selectedReports.incomeReport.groupBy = action.payload;
    },
    setIncomeReportSortByParameter: (state, action: PayloadAction<SortByIncomeReport>) => {
      state.parameters.selectedReports.incomeReport.sortBy = action.payload;
    },
    setIncomeReportDateTypeParameter: (state, action: PayloadAction<DateTypeIncomeReport>) => {
      state.parameters.selectedReports.incomeReport.dateType = action.payload;
    },
    setSecurityPerformanceGroupByParameter: (state, action: PayloadAction<GroupBySecurityPerformance>) => {
      state.parameters.selectedReports.securityPerformance.groupBy = action.payload;
    },
    setPortfolioValuationSelectedParameter: (state, action: PayloadAction<boolean>) => {
      state.parameters.selectedReports.portfolioValuation.selected = action.payload;
    },
    setPortfolioValuationSortByParameter: (state, action: PayloadAction<SortByPortfolioValuation>) => {
      state.parameters.selectedReports.portfolioValuation.sortBy = action.payload;
      if (action.payload === SortByPortfolioValuation.Gics) {
        state.parameters.selectedReports.portfolioValuation.excludeGICS = false;
      } else if (action.payload === SortByPortfolioValuation.SecurityType) {
        state.parameters.selectedReports.portfolioValuation.excludeSecurityType = false;
      }
    },
    setPortfolioValuationGroupByParameter: (state, action: PayloadAction<GroupByPortfolioValuation>) => {
      state.parameters.selectedReports.portfolioValuation.groupBy = action.payload;
      if (action.payload === GroupByPortfolioValuation.Gics) {
        state.parameters.selectedReports.portfolioValuation.excludeGICS = false;
      }
    },
    setSecurityContributionGroupByParamater: (state, action: PayloadAction<GroupBySecurityContribution>) => {
      state.parameters.selectedReports.securityContribution.groupBy = action.payload;
    },
    setSecurityContributionHidingOptionParameter: (
      state,
      action: PayloadAction<HidingOptionsRequest<HideBySecuriyContribution>>
    ) => {
      state.parameters.selectedReports.securityContribution[action.payload.option] = action.payload.value;
    },
    setPortfolioValuationHidingOptionParameter: (
      state,
      action: PayloadAction<HidingOptionsRequest<HideByPortfolioValuation>>
    ) => {
      state.parameters.selectedReports.portfolioValuation[action.payload.option] = action.payload.value;
    },
    setAssetAllocationSelectedParameter: (state, action: PayloadAction<boolean>) => {
      state.parameters.selectedReports.assetAllocation.selected = action.payload;
    },
    setHoldingsVsTargetVarianceSelectedParameter: (state, action: PayloadAction<boolean>) => {
      state.parameters.selectedReports.holdingsVsTargetVariance.selected = action.payload;
    },
    setTaxSummarySelectedParameter: (state, action: PayloadAction<boolean>) => {
      state.parameters.selectedReports.taxSummary.selected = action.payload;
    },
    setSecurityContributionSelectedParameter: (state, action: PayloadAction<boolean>) => {
      state.parameters.selectedReports.securityContribution.selected = action.payload;
    },
    setPortfolioSnapshotSelectedParameter: (state, action: PayloadAction<boolean>) => {
      state.parameters.selectedReports.portfolioSnapshot.selected = action.payload;
    },
    setTaxInvoiceSelectedParameter: (state, action: PayloadAction<boolean>) => {
      state.parameters.selectedReports.taxInvoice.selected = action.payload;
    },
    setPortfolioPerformanceSelectedParameter: (state, action: PayloadAction<boolean>) => {
      state.parameters.selectedReports.portfolioPerformance.selected = action.payload;
    },
    setInvestmentIncomeTaxSelectedParameter: (state, action: PayloadAction<boolean>) => {
      state.parameters.selectedReports.investmentIncomeTaxComponent.selected = action.payload;
    },
    setDateRangeAssetClassPerformanceParameter: (state, action: PayloadAction<DateRange>) => {
      state.parameters.selectedReports.assetClassPerformance.dateRangeType = action.payload.dateRangeType;
      state.parameters.selectedReports.assetClassPerformance.toDate = action.payload.dateTo;
      state.parameters.selectedReports.assetClassPerformance.fromDate = action.payload.dateFrom;
    },
    setDateRangePortfolioSnapshotParameter: (state, action: PayloadAction<DateRange>) => {
      state.parameters.selectedReports.portfolioSnapshot.dateRangeType = action.payload.dateRangeType;
      state.parameters.selectedReports.portfolioSnapshot.fromDate = action.payload.dateFrom;
      state.parameters.selectedReports.portfolioSnapshot.toDate = action.payload.dateTo;
    },
    setDateRangeSecurityContributionParameter: (state, action: PayloadAction<DateRange>) => {
      state.parameters.selectedReports.securityContribution.dateRangeType = action.payload.dateRangeType;
      state.parameters.selectedReports.securityContribution.toDate = action.payload.dateTo;
      state.parameters.selectedReports.securityContribution.fromDate = action.payload.dateFrom;
    },
    setDateRangeTaxSummaryParameter: (state, action: PayloadAction<DateRange>) => {
      state.parameters.selectedReports.taxSummary.dateRangeType = action.payload.dateRangeType;
      state.parameters.selectedReports.taxSummary.toDate = action.payload.dateTo;
      state.parameters.selectedReports.taxSummary.fromDate = action.payload.dateFrom;
    },
    setDateRangeTaxInvoiceParameter: (state, action: PayloadAction<DateRange>) => {
      state.parameters.selectedReports.taxInvoice.dateRangeType = action.payload.dateRangeType;
      state.parameters.selectedReports.taxInvoice.toDate = action.payload.dateTo;
      state.parameters.selectedReports.taxInvoice.fromDate = action.payload.dateFrom;
    },
    setDateRangePortfolioPerformanceParameter: (state, action: PayloadAction<DateRange>) => {
      state.parameters.selectedReports.portfolioPerformance.dateRangeType = action.payload.dateRangeType;
      state.parameters.selectedReports.portfolioPerformance.toDate = action.payload.dateTo;
      state.parameters.selectedReports.portfolioPerformance.fromDate = action.payload.dateFrom;
    },
    setDateRangeInvestmentIncomeTaxParameter: (state, action: PayloadAction<DateRange>) => {
      state.parameters.selectedReports.investmentIncomeTaxComponent.dateRangeType = action.payload.dateRangeType;
      state.parameters.selectedReports.investmentIncomeTaxComponent.toDate = action.payload.dateTo;
      state.parameters.selectedReports.investmentIncomeTaxComponent.fromDate = action.payload.dateFrom;
    },
    setAdditionsAndWithdrawalsSelectedParameter: (state, action: PayloadAction<boolean>) => {
      state.parameters.selectedReports.additionsAndWithdrawals.selected = action.payload;
    },
    setAssetAllocationPortfolioParameter: (state, action: PayloadAction<number>) => {
      state.parameters.selectedReports.assetAllocation.portfolioId = action.payload;
    },
    setPortfolioSnapshotPortfolioParameter: (state, action: PayloadAction<number>) => {
      state.parameters.selectedReports.portfolioSnapshot.portfolioId = action.payload;
    },
    setPortfolioSnapshotBenchmarkParameter: (state, action: PayloadAction<number>) => {
      state.parameters.selectedReports.portfolioSnapshot.benchmarkId = action.payload;
    },
    setPortfolioPerformanceBenchmarkParameter: (state, action: PayloadAction<number>) => {
      state.parameters.selectedReports.portfolioPerformance.benchmarkId = action.payload;
    },
    setPortfolioValuationPortfolioIdParameter: (state, action: PayloadAction<number>) => {
      state.parameters.selectedReports.portfolioValuation.portfolioId = action.payload;
    },
    setSelectedReportOrderParameter: (state, action: PayloadAction<ReportToSort[]>) => {
      state.parameters.reportsToSort = action.payload;
    },
    setShowItemOderingModalParameter: (state, action: PayloadAction<boolean>) => {
      state.parameters.showItemOrdering = action.payload;
    },
    setAsAtDateAssetAllocationParameter: (state, action: PayloadAction<DateRange>) => {
      state.parameters.selectedReports.assetAllocation.asAtDateType = action.payload.dateRangeType;
      state.parameters.selectedReports.assetAllocation.asAtDate = action.payload.dateTo;
    },
    setAsAtDateHoldingsVsTargetVarianceParameter: (state, action: PayloadAction<DateRange>) => {
      state.parameters.selectedReports.holdingsVsTargetVariance.asAtDateType = action.payload.dateRangeType;
      state.parameters.selectedReports.holdingsVsTargetVariance.asAtDate = action.payload.dateTo;
    },
    setDateRangeAdditionsAndWithdrawalsParameter: (state, action: PayloadAction<DateRange>) => {
      state.parameters.selectedReports.additionsAndWithdrawals.dateRangeType = action.payload.dateRangeType;
      state.parameters.selectedReports.additionsAndWithdrawals.toDate = action.payload.dateTo;
      state.parameters.selectedReports.additionsAndWithdrawals.fromDate = action.payload.dateFrom;
    },
    setAsAtDateCapitalGainsLossesUnrealisedParameter: (state, action: PayloadAction<DateRange>) => {
      state.parameters.selectedReports.unrealisedCapitalGainsAndLosses.asAtDateType = action.payload.dateRangeType;
      state.parameters.selectedReports.unrealisedCapitalGainsAndLosses.asAtDate = action.payload.dateTo;
    },
    setAsAtDateUnrealisedNonCGTParameter: (state, action: PayloadAction<DateRange>) => {
      state.parameters.selectedReports.unrealisedNonCGT.asAtDateType = action.payload.dateRangeType;
      state.parameters.selectedReports.unrealisedNonCGT.asAtDate = action.payload.dateTo;
    },
    setReportDatePortfolioValuationParameter: (state, action: PayloadAction<DateRange>) => {
      state.parameters.selectedReports.portfolioValuation.asAtDateType = action.payload.dateRangeType;
      state.parameters.selectedReports.portfolioValuation.asAtDate = action.payload.dateTo;
    },
    setDateRangeIncomeReportParameter: (state, action: PayloadAction<DateRange>) => {
      state.parameters.selectedReports.incomeReport.dateRangeType = action.payload.dateRangeType;
      state.parameters.selectedReports.incomeReport.toDate = action.payload.dateTo;
      state.parameters.selectedReports.incomeReport.fromDate = action.payload.dateFrom;
    },
    setDateRangeCashTransactionsParameter: (state, action: PayloadAction<DateRange>) => {
      state.parameters.selectedReports.cashTransactions.dateRangeType = action.payload.dateRangeType;
      state.parameters.selectedReports.cashTransactions.toDate = action.payload.dateTo;
      state.parameters.selectedReports.cashTransactions.fromDate = action.payload.dateFrom;
    },
    setDateRangeExpensesParameter: (state, action: PayloadAction<DateRange>) => {
      state.parameters.selectedReports.expenses.dateRangeType = action.payload.dateRangeType;
      state.parameters.selectedReports.expenses.toDate = action.payload.dateTo;
      state.parameters.selectedReports.expenses.fromDate = action.payload.dateFrom;
    },
    setDateRangeRealisedCapitalGainsAndLossesParameter: (state, action: PayloadAction<DateRange>) => {
      state.parameters.selectedReports.realisedCapitalGainsAndLosses.dateRangeType = action.payload.dateRangeType;
      state.parameters.selectedReports.realisedCapitalGainsAndLosses.toDate = action.payload.dateTo;
      state.parameters.selectedReports.realisedCapitalGainsAndLosses.fromDate = action.payload.dateFrom;
    },
    setDateRangeSecurityPerformanceParameter: (state, action: PayloadAction<DateRange>) => {
      state.parameters.selectedReports.securityPerformance.dateRangeType = action.payload.dateRangeType;
      state.parameters.selectedReports.securityPerformance.toDate = action.payload.dateTo;
      state.parameters.selectedReports.securityPerformance.fromDate = action.payload.dateFrom;
    },
    setDateRangeTransactionHistoryParameter: (state, action: PayloadAction<DateRange>) => {
      state.parameters.selectedReports.transactionHistory.dateRangeType = action.payload.dateRangeType;
      state.parameters.selectedReports.transactionHistory.toDate = action.payload.dateTo;
      state.parameters.selectedReports.transactionHistory.fromDate = action.payload.dateFrom;
    },
    setPortfolioPerformanceHidingOption: (
      state,
      action: PayloadAction<HidingOptionsRequest<HidingOptionPortfolioPerformance>>
    ) => {
      state.parameters.selectedReports.portfolioPerformance[action.payload.option] = action.payload.value;
    },
    setAssetAllocationHidingOption: (
      state,
      action: PayloadAction<HidingOptionsRequest<HidingOptionAssetAllocation>>
    ) => {
      state.parameters.selectedReports.assetAllocation[action.payload.option] = action.payload.value;
    },
    setSecurityPerformanceHidingOptions: (
      state,
      action: PayloadAction<HidingOptionsRequest<HidingOptionSecurityPerformance>>
    ) => {
      state.parameters.selectedReports.securityPerformance[action.payload.option] = action.payload.value;
    },
    setHoldingsVsTargetVarianceHidingOptions: (
      state,
      action: PayloadAction<HidingOptionsRequest<HidingOptionHoldingsVsTargetVariance>>
    ) => {
      state.parameters.selectedReports.holdingsVsTargetVariance[action.payload.option] = action.payload.value;
    },
    setReportGeneration: (state, action: PayloadAction<ReportGeneration>) => {
      state.reportGeneration = {
        ...state.reportGeneration,
        isGenerating: action.payload.isGenerating,
        requestId: action.payload.requestId,
        isExpired: action.payload.isExpired,
        isDataFromTriumph: action.payload.isDataFromTriumph,
        isMergeReport: action.payload.isMergeReport,
        key: action.payload.key,
        clientId: action.payload.clientId,
        clientName: action.payload.clientName,
        mergeReportOrder: action.payload.mergeReportOrder,
        dateRange: action.payload.dateRange,
        reportName: action.payload.reportName,
      };
    },
    setSelectedReportingParameter: (state, action: PayloadAction<SavedParameter | null>) => {
      state.parameters.selectedParameter = action.payload;
      if (action.payload === null) {
        state.parameters = initialState.parameters;
      }
    },
    setReportingParameter: (state, action: PayloadAction<ReportingParameter>) => {
      state.parameters.reportingParameter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchClientReport.fulfilled, (state, action: PayloadAction<string>) => {
      if (action.payload) {
        state.reportResult = null;
        state.reportGeneration.key = '';
        state.reportGeneration.isGenerated = true;
        state.reportGeneration.isGenerating = false;
      }
    });
    builder.addCase(fetchClientAccount.fulfilled, (state, action: PayloadAction<MainDetails>) => {
      state.parameters.inceptionDate = action.payload.inceptionDate ?? '';
      state.parameters.clientName = action.payload.name ?? '';
    });
    builder.addCase(initiateReportGeneration.pending, (state) => {
      state.reportGeneration.isGenerating = false;
      state.reportGeneration.isGenerated = false;
    });
    builder.addCase(saveCoverPageFile.fulfilled, (state, action: PayloadAction<TempFileRef>) => {
      state.parameters.includeCoverLetter = true;
      state.parameters.coverLetter = action.payload.fileRef;
    });
    builder.addCase(saveCoverPageFile.rejected, (state) => {
      state.parameters.includeCoverLetter = false;
      state.parameters.coverLetter = undefined;
    });
    builder.addCase(saveAppendixFile.fulfilled, (state, action: PayloadAction<TempFileRef>) => {
      state.parameters.includeAppendix = true;
      state.parameters.appendix = action.payload.fileRef;
    });
    builder.addCase(saveAppendixFile.rejected, (state) => {
      state.parameters.includeAppendix = false;
      state.parameters.appendix = undefined;
    });
    builder.addCase(initiateReportGeneration.fulfilled, (state, action: PayloadAction<string>) => {
      if (action.payload) {
        state.reportGeneration.requestId = action.payload;
        state.reportGeneration.isGenerating = true;
        state.reportGeneration.isGenerated = false;
      }
    });
    builder.addCase(getCGTRecordsForClient.fulfilled, (state, action: PayloadAction<CGTRecord[]>) => {
      if (action.payload) {
        state.clientFinancialYears = action.payload;
        state.clientFinancialYears = [blankcgtRecord];
      }
    });
    builder.addCase(getReportStatus.fulfilled, (state, action: PayloadAction<ReportGeneration>) => {
      if (action.payload) {
        state.reportGeneration.isExpired = action.payload.isExpired;
        state.reportGeneration.key = action.payload.key;
        if (action.payload.key !== '' && action.payload.key !== null) state.reportGeneration.isGenerating = false;
      }
    });
    builder.addCase(fetchReportParametersList.fulfilled, (state, action: PayloadAction<SavedParameter[]>) => {
      state.savedParametersList = action.payload;
      if (state.parameters.selectedParameter === undefined) return;
      if (action.payload.filter((x) => x.id === state.parameters.selectedParameter?.id).length === 0) {
        state.parameters.selectedParameter = undefined;
      }
    });
    builder.addCase(saveReportParameters.fulfilled, (state, action: PayloadAction<string>) => {
      if (action.payload) {
        const parameter = {
          id: action.payload,
          name: state.parameters.reportingParameter?.parameterName,
        };

        state.savedParametersList.push(parameter);
        state.parameters.selectedParameter = parameter;
      }
    });
    builder.addCase(getReportParametersById.fulfilled, (state, action: PayloadAction<ReportingParameters>) => {
      state.parameters = {
        ...state.parameters,
        includeCoverLetter: initialState.parameters.includeCoverLetter,
        includeAppendix: initialState.parameters.includeAppendix,
        coverLetter: initialState.parameters.coverLetter,
        appendix: initialState.parameters.appendix,
      };

      state.parameters.selectedReports.assetAllocation.selected = false;
      state.parameters.selectedReports.cashTransactions.selected = false;
      state.parameters.selectedReports.expenses.selected = false;
      state.parameters.selectedReports.realisedCapitalGainsAndLosses.selected = false;
      state.parameters.selectedReports.unrealisedCapitalGainsAndLosses.selected = false;
      state.parameters.selectedReports.incomeReport.selected = false;
      state.parameters.selectedReports.portfolioValuation.selected = false;
      state.parameters.selectedReports.securityPerformance.selected = false;
      state.parameters.selectedReports.transactionHistory.selected = false;

      state.parameters.dates = {
        dateFrom: action.payload.fromDate,
        dateTo: action.payload.toDate,
        dateRangeType: action.payload.dateRangeType,
      };

      state.parameters.includeLogo = action.payload.includeLogo;
      state.parameters.reportsToSort = [];
      state.parameters.includeCoverLetter = action.payload.additionalDocuments?.includeCoverLetter;
      if (
        !!action.payload.additionalDocuments?.includeCoverLetter &&
        !!action.payload.additionalDocuments?.coverLetter
      ) {
        // state.parameters.coverLetter = convertBase64StringToFile(
        //   action.payload.additionalDocuments?.coverLetter,
        //   'Cover Letter',
        //   'application/pdf'
        // );
      }

      state.parameters.includeAppendix = action.payload.additionalDocuments?.includeAppendix;
      if (!!action.payload.additionalDocuments?.includeAppendix && !!action.payload.additionalDocuments?.appendix) {
        // state.parameters.appendix = convertBase64StringToFile(
        //   action.payload.additionalDocuments?.appendix,
        //   'Appendix',
        //   'application/pdf'
        // );
      }

      if (action.payload.assetAllocation !== null && action.payload.assetAllocation !== undefined) {
        state.parameters.selectedReports.assetAllocation.selected = true;
        state.parameters.selectedReports.assetAllocation.asAtDate = action.payload.assetAllocation?.asAtDate;
        state.parameters.selectedReports.assetAllocation.asAtDateType = action.payload.assetAllocation?.asAtDateType;

        state.parameters.reportsToSort.push({
          id: MdaReportTypes.AssetAllocation.id,
          text: MdaReportTypes.AssetAllocation.name,
        });
      }

      if (action.payload.cashTransactions !== null && action.payload.cashTransactions !== undefined) {
        state.parameters.selectedReports.cashTransactions.selected = true;

        state.parameters.selectedReports.cashTransactions.dateRangeType =
          action.payload.cashTransactions?.dateRangeType;
        state.parameters.selectedReports.cashTransactions.fromDate = action.payload.cashTransactions?.fromDate;
        state.parameters.selectedReports.cashTransactions.toDate = action.payload.cashTransactions?.toDate;

        state.parameters.reportsToSort.push({
          id: MdaReportTypes.CashTransactions.id,
          text: MdaReportTypes.CashTransactions.name,
        });
      }

      if (action.payload.expenses !== null && action.payload.expenses !== undefined) {
        state.parameters.selectedReports.expenses.selected = true;

        state.parameters.selectedReports.expenses.dateRangeType = action.payload.expenses?.dateRangeType;
        state.parameters.selectedReports.expenses.fromDate = action.payload.expenses?.fromDate;
        state.parameters.selectedReports.expenses.toDate = action.payload.expenses?.toDate;

        state.parameters.reportsToSort.push({
          id: MdaReportTypes.Expenses.id,
          text: MdaReportTypes.Expenses.name,
        });
      }

      if (
        action.payload.realisedCapitalGainsAndLosses !== null &&
        action.payload.realisedCapitalGainsAndLosses !== undefined
      ) {
        state.parameters.selectedReports.realisedCapitalGainsAndLosses.selected = true;

        state.parameters.selectedReports.realisedCapitalGainsAndLosses.dateRangeType =
          action.payload.realisedCapitalGainsAndLosses?.dateRangeType;
        state.parameters.selectedReports.realisedCapitalGainsAndLosses.fromDate =
          action.payload.realisedCapitalGainsAndLosses?.fromDate;
        state.parameters.selectedReports.realisedCapitalGainsAndLosses.toDate =
          action.payload.realisedCapitalGainsAndLosses?.toDate;

        state.parameters.reportsToSort.push({
          id: MdaReportTypes.RealisedCapitalGainsAndLosses.id,
          text: MdaReportTypes.RealisedCapitalGainsAndLosses.name,
        });
      }
      if (
        action.payload.unrealisedCapitalGainsAndLosses !== null &&
        action.payload.unrealisedCapitalGainsAndLosses !== undefined
      ) {
        state.parameters.selectedReports.unrealisedCapitalGainsAndLosses.selected = true;

        state.parameters.selectedReports.unrealisedCapitalGainsAndLosses.asAtDateType =
          action.payload.unrealisedCapitalGainsAndLosses?.asAtDateType;
        state.parameters.selectedReports.unrealisedCapitalGainsAndLosses.asAtDate =
          action.payload.unrealisedCapitalGainsAndLosses?.asAtDate;

        state.parameters.reportsToSort.push({
          id: MdaReportTypes.UnrealisedCapitalGainsAndLosses.id,
          text: MdaReportTypes.UnrealisedCapitalGainsAndLosses.name,
        });
      }

      if (action.payload.incomeReport) {
        state.parameters.selectedReports.incomeReport.selected = true;

        state.parameters.selectedReports.incomeReport.dateRangeType = action.payload.incomeReport?.dateRangeType;
        state.parameters.selectedReports.incomeReport.fromDate = action.payload.incomeReport?.fromDate;
        state.parameters.selectedReports.incomeReport.toDate = action.payload.incomeReport?.toDate;

        state.parameters.selectedReports.incomeReport.groupBy = action.payload.incomeReport?.groupBy;
        state.parameters.selectedReports.incomeReport.sortBy = action.payload.incomeReport?.sortBy;
        state.parameters.selectedReports.incomeReport.excludeCash =
          action.payload.incomeReport?.filteringOptions?.indexOf('ExcludeCash') >= 0;
        // todo: push to parameters.reportsToSort
      }

      if (action.payload.portfolioValuation) {
        state.parameters.selectedReports.portfolioValuation.selected = true;

        state.parameters.selectedReports.portfolioValuation.asAtDateType =
          action.payload.portfolioValuation?.asAtDateType;
        state.parameters.selectedReports.portfolioValuation.asAtDate = action.payload.portfolioValuation?.asAtDate;

        if (action.payload.portfolioValuation?.hidingOptions) {
          state.parameters.selectedReports.portfolioValuation.excludeSecurityType =
            action.payload.portfolioValuation?.hidingOptions?.indexOf(HideByPortfolioValuation.SecurityType) >= 0;
          state.parameters.selectedReports.portfolioValuation.excludeCostBase =
            action.payload.portfolioValuation?.hidingOptions?.indexOf(HideByPortfolioValuation.CostBase) >= 0;
          state.parameters.selectedReports.portfolioValuation.excludeGICS =
            action.payload.portfolioValuation?.hidingOptions?.indexOf(HideByPortfolioValuation.Gics) >= 0;
          state.parameters.selectedReports.portfolioValuation.excludeMarket =
            action.payload.portfolioValuation?.hidingOptions?.indexOf(HideByPortfolioValuation.Market) >= 0;
          state.parameters.selectedReports.portfolioValuation.excludeAvgUnitCost =
            action.payload.portfolioValuation?.hidingOptions?.indexOf(HideByPortfolioValuation.AvgUnitCost) >= 0;
        }
        state.parameters.selectedReports.portfolioValuation.sortBy = action.payload.portfolioValuation.sortBy;
        state.parameters.selectedReports.portfolioValuation.groupBy = action.payload.portfolioValuation?.groupBy;
        state.parameters.reportsToSort.push({
          id: MdaReportTypes.PortfolioValuation.id,
          text: MdaReportTypes.PortfolioValuation.name,
        });
      }

      if (action.payload.securityPerformance) {
        state.parameters.selectedReports.securityPerformance.selected = true;

        state.parameters.selectedReports.securityPerformance.dateRangeType =
          action.payload.securityPerformance?.dateRangeType;
        state.parameters.selectedReports.securityPerformance.fromDate = action.payload.securityPerformance?.fromDate;
        state.parameters.selectedReports.securityPerformance.toDate = action.payload.securityPerformance?.toDate;

        state.parameters.reportsToSort.push({
          id: MdaReportTypes.SecurityPerformance.id,
          text: MdaReportTypes.SecurityPerformance.name,
        });
      }

      if (action.payload.assetClassPerformance) {
        state.parameters.selectedReports.assetClassPerformance.selected = true;

        state.parameters.selectedReports.assetClassPerformance.dateRangeType =
          action.payload.assetClassPerformance?.dateRangeType;
        state.parameters.selectedReports.assetClassPerformance.fromDate =
          action.payload.assetClassPerformance?.fromDate;
        state.parameters.selectedReports.assetClassPerformance.toDate = action.payload.assetClassPerformance?.toDate;

        if (action.payload.assetClassPerformance.hidingOptions) {
          const hidingOptions = action.payload.assetClassPerformance.hidingOptions;
          state.parameters.selectedReports.assetClassPerformance.excludeFrankingCredits =
            hidingOptions.indexOf(HideByAssetClassPerformance.FrankingCredits) >= 0;
          state.parameters.selectedReports.assetClassPerformance.showAnnualisedReturns =
            hidingOptions.indexOf(HideByAssetClassPerformance.AnnualisedReturns) >= 0;
          state.parameters.selectedReports.assetClassPerformance.applyExclusionList =
            hidingOptions.indexOf(HideByAssetClassPerformance.ExclusionList) >= 0;
          state.parameters.selectedReports.assetClassPerformance.excludeCapitalIncomeReturns =
            hidingOptions.indexOf(HideByAssetClassPerformance.CapitalIncomeReturns) >= 0;
          state.parameters.selectedReports.assetClassPerformance.includeAllocationChange =
            hidingOptions.indexOf(HideByAssetClassPerformance.AllocationChange) >= 0;
          state.parameters.selectedReports.assetClassPerformance.includeReturnsSummary =
            hidingOptions.indexOf(HideByAssetClassPerformance.ReturnsSummary) >= 0;
        }
        // todo: push to state.parameters.reportsToSort
      }
      if (action.payload.transactionHistory) {
        state.parameters.selectedReports.transactionHistory.selected = true;

        state.parameters.selectedReports.transactionHistory.dateRangeType =
          action.payload.transactionHistory?.dateRangeType;
        state.parameters.selectedReports.transactionHistory.fromDate = action.payload.transactionHistory?.fromDate;
        state.parameters.selectedReports.transactionHistory.toDate = action.payload.transactionHistory?.toDate;

        if (action.payload.transactionHistory?.hidingOptions) {
          state.parameters.selectedReports.transactionHistory.excludeAccounts =
            action.payload.transactionHistory?.hidingOptions?.indexOf(HidingOptionTransactions.accounts) >= 0;
          state.parameters.selectedReports.transactionHistory.excludeAssetDescription =
            action.payload.transactionHistory?.hidingOptions?.indexOf(HidingOptionTransactions.assetDescription) >= 0;
        }

        if (action.payload.transactionHistory?.filteringOptions) {
          state.parameters.selectedReports.transactionHistory.excludeCash =
            action.payload.transactionHistory?.filteringOptions?.indexOf(FilterByTransactions.excludeCash) >= 0;
          state.parameters.selectedReports.transactionHistory.includeDistribution =
            action.payload.transactionHistory?.filteringOptions?.indexOf(FilterByTransactions.includeDistribution) >= 0;
        }
        state.parameters.selectedReports.transactionHistory.sortBy = action.payload.transactionHistory?.sortBy;
        state.parameters.reportsToSort.push({
          id: MdaReportTypes.TransactionHistory.id,
          text: MdaReportTypes.TransactionHistory.name,
        });
      }
      const isMergeReport =
        action.payload.mergeReportOrder.findIndex((x) => x == MdaReportTypes.MergeReport.id) > -1 &&
        state.parameters.reportsToSort.length > 1;
      state.parameters.mergeReports = isMergeReport;
      if (isMergeReport) {
        state.parameters.reportsToSort.sort((a, b) => {
          return action.payload.mergeReportOrder.indexOf(a.id) - action.payload.mergeReportOrder.indexOf(b.id);
        });
      }
    });
  },
});
