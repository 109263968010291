import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Fab, FabProps as MuiFabProps } from '@mui/material';
import React from 'react';

interface FabMenuProps {
  isMenuOpen: boolean;
  toggleMenu: (value: React.SetStateAction<boolean>) => void;
  muiFabProps?: MuiFabProps;
  menuItems: Array<FabMenuItem>;
  name: string;
}

export type FabMenuItem = {
  label: string;
  icon?: React.ReactElement;
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
};

export const FabMenu: React.FC<FabMenuProps> = ({ name, isMenuOpen, toggleMenu, muiFabProps, menuItems }: FabMenuProps) => (
  <Box
    style={{
      position: 'fixed',
      bottom: '0',
      right: '0',
      zIndex: 1200,
      textAlign: 'right',
      width: isMenuOpen ? '100%' : '90px',
      height: isMenuOpen ? '100%' : '90px',
    }}
    onClick={() => {
      toggleMenu(false);
    }}
  >
    {!isMenuOpen && (
      <Fab
        style={{
          position: 'absolute',
          bottom: '28px',
          right: '24px',
        }}
        color="primary"
        onClick={() => toggleMenu(isMenuOpen)}
        name={name}
        {...muiFabProps}
      >
        <AddIcon />
      </Fab>
    )}
    {isMenuOpen &&
      menuItems.map((menuItem, idx: number) => (
        <Box
          style={{
            position: 'absolute',
            bottom: idx === 0 ? '110px' : 'calc(110px + ' + idx + ' * 56px)',
            right: '24px',
          }}
          key={`fabMenuItem-${menuItem.label}-${idx}`}
        >
          <Fab variant="extended" size="medium" color="default" onClick={menuItem.onClick} name={name} style={{ textTransform: 'none' }}>
            {menuItem.icon} {menuItem.label}
          </Fab>
        </Box>
      ))}
    {isMenuOpen && (
      <Fab
        color="primary"
        style={{
          position: 'absolute',
          bottom: '28px',
          right: '24px',
        }}
        onClick={() => toggleMenu(isMenuOpen)}
        name={name}
      >
        <CloseIcon />
      </Fab>
    )}
  </Box>
);
