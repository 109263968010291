import { Box, Container, Grid } from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import SearchButton from 'src/features/clients/client/common/components/SearchButton';
import { DateTimeFormat, formatPercentage, getLocalDateTime } from '../../../../common';
import { EnumSelect } from '../../../../common/components';
import CardComponent from '../../../../common/components/CardComponent';
import DateRangePicker from '../../../../common/components/DateRangePicker';
import { LoadingIndicator } from '../../../../common/components/LoadingIndicator';
import { theme } from '../../../../themes';
import { Props } from '../container';
import { BenchmarkPerformanceThunkParameters, Frequency, PerformanceChartThunkParameters, SecurityPeriodicSummaryThunkParameters } from '../store/types';
import { BenchmarkSelect } from './benchmarkSelect';
import { PerformanceChart } from './performanceChart';
import { PeriodicSummaryTable } from './periodicSummaryTable';

export const PerformanceComponent = (props: Props): JSX.Element => {
  const {
    parameters,
    setDateParameter,
    setBenchmarkParameter,
    setFrequencyParameter,
    monthlyPerformanceChart,
    dailyPerformanceChart,
    fetchMonthlyPerformanceChart,
    fetchSecurityPeriodicSummary,
    fetchSecurityBenchmarks,
    fetchMonthlyBenchmarkPerformance,
    fetchDailyBenchmarkPerformance,
    fetchDailyPerformanceChart,
    monthlyPerformanceChartProgress,
    securityBenchmarksProgress,
    match: { params },
  } = props;

  const fetch = useCallback(() => {
    if (params.securityId) {
      const monthlyPerformanceChartRequestPayload: PerformanceChartThunkParameters = {
        securityId: parseInt(params.securityId),
        fromDate: parameters.dates.dateFrom,
        toDate: parameters.dates.dateTo,
      };
      const dailyPerformanceChartRequestPayload: PerformanceChartThunkParameters = {
        securityId: parseInt(params.securityId),
        fromDate: parameters.dates.dateFrom,
        toDate: parameters.dates.dateTo,
      };
      fetchMonthlyPerformanceChart(monthlyPerformanceChartRequestPayload);
      fetchDailyPerformanceChart(dailyPerformanceChartRequestPayload);
      fetchSecurityBenchmarks({ securityId: parseInt(params.securityId) });
    }

    if (parameters.securityBenchmarkSelected?.id && params.securityId) {
      const benchmarkPerformanceRequestPayload: BenchmarkPerformanceThunkParameters = {
        strategyId: parameters.securityBenchmarkSelected.id,
        startDate: parameters.dates.dateFrom,
        endDate: parameters.dates.dateTo,
      };

      fetchMonthlyBenchmarkPerformance(benchmarkPerformanceRequestPayload);
      fetchDailyBenchmarkPerformance(benchmarkPerformanceRequestPayload);
    }

    if (params.securityId) {
      const periodicSummaryRequestPayload: SecurityPeriodicSummaryThunkParameters = {
        securityId: parseInt(params.securityId),
        strategyId: parameters.securityBenchmarkSelected?.id ? parameters.securityBenchmarkSelected.id : null,
        endDate: parameters.dates.dateTo,
      };

      fetchSecurityPeriodicSummary(periodicSummaryRequestPayload);
    }
  }, [
    fetchMonthlyPerformanceChart,
    fetchDailyPerformanceChart,
    fetchSecurityBenchmarks,
    params.securityId,
    parameters.dates.dateFrom,
    parameters.dates.dateTo,
    fetchSecurityPeriodicSummary,
    fetchMonthlyBenchmarkPerformance,
    fetchDailyBenchmarkPerformance,
    parameters.securityBenchmarkSelected,
  ]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  function onSearchClicked() {
    if (params.securityId) {
      const performanceChartRequestPayload: PerformanceChartThunkParameters = {
        securityId: parseInt(params.securityId),
        fromDate: parameters.dates.dateFrom,
        toDate: parameters.dates.dateTo,
      };
      fetchMonthlyPerformanceChart(performanceChartRequestPayload);
      fetchDailyPerformanceChart(performanceChartRequestPayload);
    }

    callStrategyEndpoints();
  }

  function callStrategyEndpoints() {
    if (parameters.securityBenchmarkSelected?.id && params.securityId) {
      const benchmarkPerformanceRequestPayload: BenchmarkPerformanceThunkParameters = {
        strategyId: parameters.securityBenchmarkSelected.id,
        startDate: parameters.dates.dateFrom,
        endDate: parameters.dates.dateTo,
      };

      fetchMonthlyBenchmarkPerformance(benchmarkPerformanceRequestPayload);
      fetchDailyBenchmarkPerformance(benchmarkPerformanceRequestPayload);
    }

    if (params.securityId) {
      const periodicSummaryRequestPayload: SecurityPeriodicSummaryThunkParameters = {
        securityId: parseInt(params.securityId),
        strategyId: parameters?.securityBenchmarkSelected?.id ? parameters.securityBenchmarkSelected.id : null,
        endDate: parameters.dates.dateTo,
      };

      fetchSecurityPeriodicSummary(periodicSummaryRequestPayload);
    }
  }

  const bestInfoCard = function () {
    let x = '';
    if (parameters.frequency === Frequency.Daily) {
      x = `Best Day (${getLocalDateTime(dailyPerformanceChart?.bestDayDate, DateTimeFormat.DailyDate)})`;
    } else {
      x = `Best Month (${getLocalDateTime(monthlyPerformanceChart?.bestMonthDate, DateTimeFormat.MonthlyDate)})`;
    }
    return x;
  };

  const worstInfoCard = function () {
    let x = '';
    if (parameters.frequency === Frequency.Daily) {
      x = `Worst Day (${getLocalDateTime(dailyPerformanceChart?.worstDayDate, DateTimeFormat.DailyDate)})`;
    } else {
      x = `Worst Month (${getLocalDateTime(monthlyPerformanceChart?.worstMonthDate, DateTimeFormat.MonthlyDate)})`;
    }
    return x;
  };

  const capitalReturn = function () {
    let val = 0;
    if (parameters.frequency === Frequency.Daily) {
      val = dailyPerformanceChart?.capitalReturn ? dailyPerformanceChart?.capitalReturn : 0;
    } else {
      val = monthlyPerformanceChart?.capitalReturn ? monthlyPerformanceChart?.capitalReturn : 0;
    }
    return val;
  };

  const incomeReturn = function () {
    let val = 0;
    if (parameters.frequency === Frequency.Daily) {
      val = dailyPerformanceChart?.incomeReturn ? dailyPerformanceChart?.incomeReturn : 0;
    } else {
      val = monthlyPerformanceChart?.incomeReturn ? monthlyPerformanceChart?.incomeReturn : 0;
    }
    return val;
  };

  const totalReturn = function () {
    let val = 0;
    if (parameters.frequency === Frequency.Daily) {
      val = dailyPerformanceChart?.totalReturn ? dailyPerformanceChart?.totalReturn : 0;
    } else {
      val = monthlyPerformanceChart?.totalReturn ? monthlyPerformanceChart?.totalReturn : 0;
    }
    return val;
  };

  const bestReturn = function () {
    let val = 0;
    if (parameters.frequency === Frequency.Daily) {
      val = dailyPerformanceChart?.bestDayReturn ? dailyPerformanceChart?.bestDayReturn : 0;
    } else {
      val = monthlyPerformanceChart?.bestMonthReturn ? monthlyPerformanceChart?.bestMonthReturn : 0;
    }
    return val;
  };

  const worstReturn = function () {
    let val = 0;
    if (parameters.frequency === Frequency.Daily) {
      val = dailyPerformanceChart?.worstDayReturn ? dailyPerformanceChart?.worstDayReturn : 0;
    } else {
      val = monthlyPerformanceChart?.worstMonthReturn ? monthlyPerformanceChart?.worstMonthReturn : 0;
    }
    return val;
  };

  const annualVolatility = function () {
    let val = 0;
    if (parameters.frequency === Frequency.Daily) {
      val = dailyPerformanceChart?.annualVolatility ? dailyPerformanceChart?.annualVolatility : 0;
    } else {
      val = monthlyPerformanceChart?.annualVolatility ? monthlyPerformanceChart?.annualVolatility : 0;
    }
    return val;
  };

  return (
    <Container style={{ paddingTop: '20px', paddingBottom: '20px' }}>
      <Box display={'flex'} justifyContent={'space-between'} style={{ padding: '10px 0' }}>
        <Box paddingRight={'10px'}></Box>
        <Box display={'flex'} justifyContent={'space-between'}>
          <DateRangePicker dateRange={parameters.dates} setDateRange={setDateParameter} />
          <Box paddingRight={'10px'}>
            <EnumSelect
              label="Frequency"
              enumType={Frequency}
              value={parameters?.frequency}
              onChange={(value) => {
                setFrequencyParameter(Frequency[value]);
              }}
            />
          </Box>
          <Box paddingRight={'10px'}>
            <LoadingIndicator progress={securityBenchmarksProgress}>
              <BenchmarkSelect
                items={parameters.securityBenchmarks}
                selectedItem={parameters?.securityBenchmarkSelected ?? null}
                setBenchmark={setBenchmarkParameter}
              />
            </LoadingIndicator>
          </Box>
          <Box>
            <SearchButton onClick={onSearchClicked} />
          </Box>
        </Box>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <CardComponent
            {...props}
            progress={monthlyPerformanceChartProgress}
            background={theme.palette.gradient1?.main}
            value={formatPercentage(capitalReturn())}
            subText={'Capital Return'}
          />
        </Grid>
        <Grid item xs={2}>
          <CardComponent
            {...props}
            progress={monthlyPerformanceChartProgress}
            background={theme.palette.gradient2?.main}
            value={formatPercentage(incomeReturn())}
            subText={'Income Return'}
          />
        </Grid>
        <Grid item xs={2}>
          <CardComponent
            {...props}
            progress={monthlyPerformanceChartProgress}
            background={theme.palette.gradient1?.main}
            value={formatPercentage(totalReturn())}
            subText={'Total Return'}
          />
        </Grid>
        <Grid item xs={2}>
          <CardComponent
            {...props}
            progress={monthlyPerformanceChartProgress}
            background={theme.palette.gradient2?.main}
            value={formatPercentage(bestReturn())}
            subText={bestInfoCard()}
          />
        </Grid>
        <Grid item xs={2}>
          <CardComponent
            {...props}
            progress={monthlyPerformanceChartProgress}
            background={theme.palette.gradient1?.main}
            value={formatPercentage(worstReturn())}
            subText={worstInfoCard()}
          />
        </Grid>
        <Grid item xs={2}>
          <CardComponent
            {...props}
            progress={monthlyPerformanceChartProgress}
            background={theme.palette.gradient2?.main}
            value={formatPercentage(annualVolatility())}
            subText={'Annualised Volatility'}
          />
        </Grid>
      </Grid>

      <PerformanceChart {...props} />

      <PeriodicSummaryTable {...props} />
    </Container>
  );
};
