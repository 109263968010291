import * as t from 'io-ts';
import { moment } from 'src/common/types';
import { PagedRequest, PagedResult } from '../../../../store';

export interface WorkflowListState {
  parameters: WorkflowListParameters;
  workflows: PagedResult<WorkflowItem> | null | undefined;
  selectedWorkflow: WorkflowItem | null | undefined;
  workflowError: string | undefined;
  importErrors: string[];
  importUploadSuccess: boolean;
  isImporting: boolean;
  pensionAccounts: PensionAccountVM[] | null | undefined;
}

export interface GetPensionAccountsRequest {
  statusFlag: number;
}

export interface PensionAccountVM {
  clientId: number;
  name: string;
  superMemberNumber: string;
}

export interface WorkflowListParameters {
  pagination: PagedRequest;
}

export interface FetchPagedResults<T> {
  results: PagedResult<T>;
  pagination: PagedRequest;
}

export interface WorkflowItem {
  id: string;
  name: string;
  entityId: string;
  clientName: string;
  status: string;
  businessStatus: string;
  createdBy: string;
  lastChangedBy: string;
  createdOn: moment;
  modifiedOn: moment;
  adviserName: string;
  afslName: string;
  practiceName: string;
  errorMessage: string;
  afslId: number;
  adviserId: number;
  practiceId: number;
}

export interface WorkflowABAFileIdVM {
  documentId: number;
  clientId: number;
}

export interface FetchWorkflowListPayload {
  pagination: PagedRequest;
}

export enum MenuOptions {
  None = 'None',
  Import = 'Import',
  DownloadTemplate = 'DownloadTemplate',
}

export interface ImportRequestPayload {
  file: File;
}

type NullAndUndefinedString = string | null | undefined;
type NullAndUndefinedNumber = number | null | undefined;

export interface SuperMemberData {
  MemberNumber: string;
  BeneficiaryName1: NullAndUndefinedString;
  BeneficiaryRelationship1: NullAndUndefinedString;
  BeneficiaryPercent1: NullAndUndefinedNumber;
  BeneficiaryBinding1: NullAndUndefinedString;
  BeneficiaryName2: NullAndUndefinedString;
  BeneficiaryRelationship2: NullAndUndefinedString;
  BeneficiaryPercent2: NullAndUndefinedNumber;
  BeneficiaryBinding2: NullAndUndefinedString;
  BeneficiaryName3: NullAndUndefinedString;
  BeneficiaryRelationship3: NullAndUndefinedString;
  BeneficiaryPercent3: NullAndUndefinedNumber;
  BeneficiaryBinding3: NullAndUndefinedString;
  BeneficiaryName4: NullAndUndefinedString;
  BeneficiaryRelationship4: NullAndUndefinedString;
  BeneficiaryPercent4: NullAndUndefinedNumber;
  BeneficiaryBinding4: NullAndUndefinedString;
  BeneficiaryName5: NullAndUndefinedString;
  BeneficiaryRelationship5: NullAndUndefinedString;
  BeneficiaryPercent5: NullAndUndefinedNumber;
  BeneficiaryBinding5: NullAndUndefinedString;
  BeneficiaryName6: NullAndUndefinedString;
  BeneficiaryRelationship6: NullAndUndefinedString;
  BeneficiaryPercent6: NullAndUndefinedNumber;
  BeneficiaryBinding6: NullAndUndefinedString;
  BeneficiaryName7: NullAndUndefinedString;
  BeneficiaryRelationship7: NullAndUndefinedString;
  BeneficiaryPercent7: NullAndUndefinedNumber;
  BeneficiaryBinding7: NullAndUndefinedString;
  BeneficiaryName8: NullAndUndefinedString;
  BeneficiaryRelationship8: NullAndUndefinedString;
  BeneficiaryPercent8: NullAndUndefinedNumber;
  BeneficiaryBinding8: NullAndUndefinedString;
  BeneficiaryName9: NullAndUndefinedString;
  BeneficiaryRelationship9: NullAndUndefinedString;
  BeneficiaryPercent9: NullAndUndefinedNumber;
  BeneficiaryBinding9: NullAndUndefinedString;
  BeneficiaryName10: NullAndUndefinedString;
  BeneficiaryRelationship10: NullAndUndefinedString;
  BeneficiaryPercent10: NullAndUndefinedNumber;
  BeneficiaryBinding10: NullAndUndefinedString;
  DeathPremium: NullAndUndefinedNumber;
  TBDPremium: NullAndUndefinedNumber;
  IncomeProtection: NullAndUndefinedNumber;
  PensionPaymentValue: NullAndUndefinedNumber;
  PensionPaymentFrequency: NullAndUndefinedNumber;
  NextPensionPaymentDate: NullAndUndefinedString;
  ConcessionalContribution: NullAndUndefinedNumber;
  NonConcessionalContribution: NullAndUndefinedNumber;
  UnrestrictedNonPreserved: NullAndUndefinedNumber;
  RestrictedNonPreserved: NullAndUndefinedNumber;
  Preserved: NullAndUndefinedNumber;
  Taxable: NullAndUndefinedNumber;
  TaxFree: NullAndUndefinedNumber;
  TaxLiability: NullAndUndefinedNumber;
}

export const MemberRow: t.Type<SuperMemberData> = t.type({
  MemberNumber: t.string,
  BeneficiaryName1: t.union([t.string, t.undefined, t.null]),
  BeneficiaryRelationship1: t.union([t.string, t.undefined, t.null]),
  BeneficiaryPercent1: t.union([t.number, t.undefined, t.null]),
  BeneficiaryBinding1: t.union([t.string, t.undefined, t.null]),
  BeneficiaryName2: t.union([t.string, t.undefined, t.null]),
  BeneficiaryRelationship2: t.union([t.string, t.undefined, t.null]),
  BeneficiaryPercent2: t.union([t.number, t.undefined, t.null]),
  BeneficiaryBinding2: t.union([t.string, t.undefined, t.null]),
  BeneficiaryName3: t.union([t.string, t.undefined, t.null]),
  BeneficiaryRelationship3: t.union([t.string, t.undefined, t.null]),
  BeneficiaryPercent3: t.union([t.number, t.undefined, t.null]),
  BeneficiaryBinding3: t.union([t.string, t.undefined, t.null]),
  BeneficiaryName4: t.union([t.string, t.undefined, t.null]),
  BeneficiaryRelationship4: t.union([t.string, t.undefined, t.null]),
  BeneficiaryPercent4: t.union([t.number, t.undefined, t.null]),
  BeneficiaryBinding4: t.union([t.string, t.undefined, t.null]),
  BeneficiaryName5: t.union([t.string, t.undefined, t.null]),
  BeneficiaryRelationship5: t.union([t.string, t.undefined, t.null]),
  BeneficiaryPercent5: t.union([t.number, t.undefined, t.null]),
  BeneficiaryBinding5: t.union([t.string, t.undefined, t.null]),
  BeneficiaryName6: t.union([t.string, t.undefined, t.null]),
  BeneficiaryRelationship6: t.union([t.string, t.undefined, t.null]),
  BeneficiaryPercent6: t.union([t.number, t.undefined, t.null]),
  BeneficiaryBinding6: t.union([t.string, t.undefined, t.null]),
  BeneficiaryName7: t.union([t.string, t.undefined, t.null]),
  BeneficiaryRelationship7: t.union([t.string, t.undefined, t.null]),
  BeneficiaryPercent7: t.union([t.number, t.undefined, t.null]),
  BeneficiaryBinding7: t.union([t.string, t.undefined, t.null]),
  BeneficiaryName8: t.union([t.string, t.undefined, t.null]),
  BeneficiaryRelationship8: t.union([t.string, t.undefined, t.null]),
  BeneficiaryPercent8: t.union([t.number, t.undefined, t.null]),
  BeneficiaryBinding8: t.union([t.string, t.undefined, t.null]),
  BeneficiaryName9: t.union([t.string, t.undefined, t.null]),
  BeneficiaryRelationship9: t.union([t.string, t.undefined, t.null]),
  BeneficiaryPercent9: t.union([t.number, t.undefined, t.null]),
  BeneficiaryBinding9: t.union([t.string, t.undefined, t.null]),
  BeneficiaryName10: t.union([t.string, t.undefined, t.null]),
  BeneficiaryRelationship10: t.union([t.string, t.undefined, t.null]),
  BeneficiaryPercent10: t.union([t.number, t.undefined, t.null]),
  BeneficiaryBinding10: t.union([t.string, t.undefined, t.null]),
  DeathPremium: t.union([t.number, t.undefined, t.null]),
  TBDPremium: t.union([t.number, t.undefined, t.null]),
  IncomeProtection: t.union([t.number, t.undefined, t.null]),
  PensionPaymentValue: t.union([t.number, t.undefined, t.null]),
  PensionPaymentFrequency: t.union([t.number, t.undefined, t.null]),
  NextPensionPaymentDate: t.union([t.string, t.undefined, t.null]),
  ConcessionalContribution: t.union([t.number, t.undefined, t.null]),
  NonConcessionalContribution: t.union([t.number, t.undefined, t.null]),
  UnrestrictedNonPreserved: t.union([t.number, t.undefined, t.null]),
  RestrictedNonPreserved: t.union([t.number, t.undefined, t.null]),
  Preserved: t.union([t.number, t.undefined, t.null]),
  Taxable: t.union([t.number, t.undefined, t.null]),
  TaxFree: t.union([t.number, t.undefined, t.null]),
  TaxLiability: t.union([t.number, t.undefined, t.null]),
});

export interface SuperMemberDataRequest {
  MemberNumber: string;
  Beneficiaries: SuperMemberBeneficiariesRequest[];
  BenefitStatement: SuperMemberBenefitStatementRequest;
  Insurance: SuperMemberInsuranceRequest;
}

export interface SuperMemberBeneficiariesRequest {
  BeneficiaryNumber: number;
  Name: string;
  Relationship: NullAndUndefinedString;
  Percent: NullAndUndefinedNumber;
  Binding: NullAndUndefinedString;
}

export interface SuperMemberBenefitStatementRequest {
  ConcessionalContribution: NullAndUndefinedNumber;
  NonConcessionalContribution: NullAndUndefinedNumber;
  UnrestrictedNonPreserved: NullAndUndefinedNumber;
  RestrictedNonPreserved: NullAndUndefinedNumber;
  Preserved: NullAndUndefinedNumber;
  Taxable: NullAndUndefinedNumber;
  TaxFree: NullAndUndefinedNumber;
  TaxLiability: NullAndUndefinedNumber;
}

export interface SuperMemberInsuranceRequest {
  DeathPremium: NullAndUndefinedNumber;
  TotalPermanentDisabilityPremium: NullAndUndefinedNumber;
  IncomeProtection: NullAndUndefinedNumber;
  PensionPaymentValue: NullAndUndefinedNumber;
  PensionPaymentFrequency: NullAndUndefinedNumber;
  NextPensionPaymentDate: Date | null | undefined;
}

export interface WorkflowActionRequest {
  workflowId: string;
}

export interface UpdateWorkflowResponse {
  workflowItem?: WorkflowItem;
  message?: string;
  success: boolean;
}

export enum WorkflowAction {
  TerminateWorkflow = 'Terminate workflow',
  ResumeWorkflow = 'Retry a suspended workflow',
  DiscardWorkflow = 'Unlock for second attempt',
  CompleteWorkflow = 'Complete Workflow',
  DownloadWorkflowDocument = 'Download Workflow Document',
}

export enum WorkflowStatus {
  New = 'New',
  WithWealthO2 = 'In Progress - With DASH',
  WithThirdparty = 'In Progress - With Third party',
  WithClient = 'In Progress - With client',
  WithAdviser = 'In Progress - With adviser',
  Suspended = 'Suspended',
  Terminated = 'Terminated',
  Complete = 'Complete',
  Unspecified = 'Unspecified',
  Failed = 'Failed',
  WaitingOnAvailableCash = 'In Progress - Waiting on available cash',
  InProgressDASHApproval = 'In Progress - DASH approval',
}

export interface WorkflowActionDescription {
  action: WorkflowAction;
  displayName: string;
  description: string;
  validation: (workflow: WorkflowItem | null | undefined) => boolean;
  visible: (workflow: WorkflowItem) => boolean;
}

export const terminateWorkflowDescription: WorkflowActionDescription = {
  action: WorkflowAction.TerminateWorkflow,
  displayName: 'Terminate Workflow',
  description: 'CAUTION - this cannot be reveresed. Cancels the workflow and stops it from running any further action',
  validation: (workflow: WorkflowItem | null | undefined) => {
    if (!!workflow)
      return (
        workflow?.businessStatus !== WorkflowStatus.Suspended &&
        workflow?.businessStatus !== WorkflowStatus.WithWealthO2 &&
        workflow?.businessStatus !== WorkflowStatus.WithClient
      );
    return true;
  },
  visible: (): boolean => true,
};

export const resumeWorkflowDescription: WorkflowActionDescription = {
  action: WorkflowAction.ResumeWorkflow,
  displayName: 'Retry a suspended workflow',
  description:
    'Generally you will need to update the invalid data before retrying a workflow. Review the error before performing this action.',
  validation: (workflow: WorkflowItem | null | undefined) => {
    if (!!workflow) return workflow?.businessStatus !== WorkflowStatus.Suspended;
    return true;
  },
  visible: (): boolean => true,
};

export const discardWorkflowDescription: WorkflowActionDescription = {
  action: WorkflowAction.DiscardWorkflow,
  displayName: 'Unlock for second attempt',
  description:
    'Removes the lock on a client, preventing duplicate Super Simplifier applications from being submitted. This has no function for other workflow',
  validation: (workflow: WorkflowItem | null | undefined) => {
    if (!!workflow) return workflow?.businessStatus !== WorkflowStatus.Terminated;
    return true;
  },
  visible: (workflow: WorkflowItem) => {
    return (
      workflow.name === 'SuperSimplifierAccountApplicationWorkFlow' ||
      workflow.name === 'SuperSimplifierUploadAccountApplication'
    );
  },
};

export const completeWorkflowDescription: WorkflowActionDescription = {
  action: WorkflowAction.CompleteWorkflow,
  displayName: 'Complete Withdrawal',
  description: 'Marks the workflow as as complete after bank files have been loaded',
  validation: (workflow: WorkflowItem | null | undefined) => {
    if (!!workflow) return !(workflow?.businessStatus === WorkflowStatus.InProgressDASHApproval);
    return false;
  },
  visible: (workflow: WorkflowItem) => {
    return workflow.name === 'CashWithdrawal';
  },
};

export const downloadWorkflowDocumentDescription: WorkflowActionDescription = {
  action: WorkflowAction.DownloadWorkflowDocument,
  displayName: 'Download withdrawal file',
  description: 'Download cash withdrawal file to process with the bank.',
  validation: (workflow: WorkflowItem | null | undefined) => {
    if (!!workflow) {
      return !(workflow.businessStatus === WorkflowStatus.InProgressDASHApproval);
    }
    return false;
  },
  visible: (workflow: WorkflowItem) => {
    return workflow.name === 'CashWithdrawal';
  },
};

export interface PensionRestartWorkflowRequest {
  clientId: number;
}
