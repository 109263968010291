import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../../reducers';
import RebalanceCheckoutComponent from './components/checkout';
import { selectGroupedTrades, selectIsSubmitted, selectRebalance, selectTotals, selectValidationMessages } from './store/selectors';
import { rebalanceCheckoutSlice } from './store/slice';
import { confirmRebalance } from './store/thunks';
import { selectHasRebalanceEnhancedTrading, selectHasClientEditAdminOnly } from '../../../../store/selectors';

const mapStateToProps = (state: RootState) => ({
  rebalance: selectRebalance(state),
  groupedTrades: selectGroupedTrades(state),
  totals: selectTotals(state),
  validationMessages: selectValidationMessages(state),
  isSubmitted: selectIsSubmitted(state),
  hasRebalanceEnhancedTrading: selectHasRebalanceEnhancedTrading(state),
  hasClientEditAdminOnlyPermission: selectHasClientEditAdminOnly(state),
});

const mapDispatchToProps = {
  ...rebalanceCheckoutSlice.actions,
  confirmRebalance,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector>;
export const RebalanceCheckoutContainer = connect(mapStateToProps, mapDispatchToProps)(RebalanceCheckoutComponent);
