import { AddOutlined, CancelOutlined, DeleteOutlined, EditOutlined, SaveOutlined } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { Form, Formik, FormikProps } from 'formik';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as yup from 'yup';
import { useStyles } from '../../../../themes';
import { useConfirmation } from '../../dialogs';
import { DatatableColumn } from '../types';
import { ClientSideDataTable, ClientSideDataTableProps } from './clientSideDataTable';

export interface InlineEditDataTableProps<T> extends Omit<ClientSideDataTableProps<T>, 'columns, initialValues'> {
  columns: InlineEditColumn[];
  newItem: T;
  editId?: number | null;
  validationSchema: yup.ObjectSchema;
  deleteOptions?: DeleteOptions;
  hideAllActions?: boolean;
  hideToolBar?: boolean;
  canDelete?: (id: number | null) => boolean;
  onSave: (details: T) => void;
  onDelete: (id: number) => void;
  onSelectEditId: (id: number | null | undefined) => void;
  onSetItemAdd: (newRecord: T) => void;
}

interface DeleteOptions {
  dialogTitle: string;
  dialogDescription: string;
}

export interface InlineEditColumn extends DatatableColumn {
  name: string;
  label: string;
  templates: InlineEditColumnSet;
}

export interface InlineEditColumnSet {
  standardTemplate: (dataIndex: number) => JSX.Element;
  editTemplate: (dataIndex: number) => JSX.Element;
}

export function InlineEditDataTable<T extends { id: number | null }>(props: InlineEditDataTableProps<T>): JSX.Element {
  const confirm = useConfirmation();
  const classes = useStyles();

  const {
    data,
    columns,
    newItem,
    editId,
    deleteOptions,
    validationSchema,
    hideAllActions,
    hideToolBar,
    canDelete,
    onSave,
    onDelete,
    onSelectEditId,
    onSetItemAdd,
  } = props;
  const formRef = useRef<FormikProps<T>>(null);
  const [tableColumns, setTableColumns] = useState<DatatableColumn[]>([]);

  const fetchCellProps = useCallback(
    (column: Partial<InlineEditColumn>, cellValue: string, dataIndex: number, columnIndex: number) => {
      if (!!column.options?.setCellProps) {
        column.options?.setCellProps(cellValue, dataIndex, columnIndex);
      }
      return {
        ...column.options?.setCellProps,
        style: {
          verticalAlign: column?.verticalAlign ?? (editId === data[dataIndex]?.id ? 'top' : 'inherit'),
          textAlign: column?.textAlign || 'inherit',
        },
      };
    },
    [data, editId]
  );

  useEffect(() => {
    setTableColumns(
      columns.map((c) => {
        return {
          ...c,
          options: {
            ...c.options,
            filterList: editId === null ? [] : c.options?.filterList, // remove filter if adding
            customBodyRenderLite: (dataIndex: number) => {
              if (data.length > 0) {
                if (editId === data[dataIndex]?.id) {
                  return c.templates.editTemplate(dataIndex);
                } else {
                  return c.templates.standardTemplate(dataIndex);
                }
              }
            },
            setCellProps: (cellValue: string, dataIndex: number, columnIndex: number) => fetchCellProps(c, cellValue, dataIndex, columnIndex),
          },
        };
      })
    );
  }, [columns, editId, data, fetchCellProps]);

  const actionsColumn: DatatableColumn = {
    name: '_actions',
    label: 'ACTIONS',
    textAlign: 'center',
    options: {
      filter: false,
      sort: false,
      empty: true,
      customBodyRender: (_value, meta) => {
        if (data && data[meta.rowIndex]) {
          return actionsColumnRender(data[meta.rowIndex].id);
        }
        return actionsColumnRender(null);
      },
      setCellProps: (cellValue: string, dataIndex: number, columnIndex: number) => fetchCellProps({}, cellValue, dataIndex, columnIndex),
    },
  };

  const actionsColumnRender = (id: number | null): React.ReactNode => {
    if (editId === id) {
      return (
        <div style={{ textAlign: 'center' }}>
          <IconButton
            aria-label="cancel"
            color="primary"
            data-testid={`cancelButton:${id}`}
            onClick={() => {
              onSelectEditId(undefined);
              // clear form data
              formRef?.current?.resetForm();
            }}
          >
            <CancelOutlined />
          </IconButton>
          <IconButton
            aria-label="save"
            color="primary"
            data-testid={`saveButton:${id}`}
            onClick={() => {
              if (!!formRef && !!formRef.current) {
                formRef.current.handleSubmit();
              }
            }}
          >
            <SaveOutlined />
          </IconButton>
        </div>
      );
    }
    return (
      <div style={{ textAlign: 'center' }}>
        <IconButton
          aria-label="edit"
          color="primary"
          data-testid={`editButton:${id}`}
          onClick={() => {
            onSelectEditId(id);
          }}
        >
          <EditOutlined />
        </IconButton>
        {!!deleteOptions && (!canDelete || canDelete(id)) && (
          <IconButton
            aria-label="edit"
            color="primary"
            data-testid={`deleteButton:${id}`}
            onClick={() => {
              if (id !== null) {
                confirm({
                  title: deleteOptions.dialogTitle,
                  description: deleteOptions.dialogDescription,
                }).then(() => {
                  onDelete(id);
                  // clear form data
                  formRef?.current?.resetForm();
                });
              }
            }}
          >
            <DeleteOutlined />
          </IconButton>
        )}
      </div>
    );
  };

  const addButton = (): React.ReactNode => {
    return !!newItem ? (
      <IconButton
        disableFocusRipple
        disableRipple
        className={classes.addToTable}
        data-testid="addButton"
        disabled={data.length > 0 && data[0].id === null}
        onClick={() => {
          if (data.length === 0 || data[0].id !== null) {
            // only add a create row if the first item in the data is not null or data is empty
            onSetItemAdd(newItem);
            // clear form data
            formRef?.current?.resetForm();
          }
        }}
      >
        <AddOutlined />
      </IconButton>
    ) : (
      <></>
    );
  };

  return (
    <Formik<T>
      innerRef={formRef}
      enableReinitialize={true}
      initialValues={data.find((d) => d.id === editId) || newItem}
      validationSchema={validationSchema}
      onSubmit={async (details) => onSave(details)}
    >
      <Form>
        <fieldset style={{ border: 'none', padding: '0', margin: '0' }}>
          <ClientSideDataTable
            {...{ ...props, columns: hideAllActions ? tableColumns : [...tableColumns, actionsColumn] }}
            options={{ ...props.options, page: editId === null ? 0 : props.options.page, customToolbar: () => !hideToolBar && !hideAllActions && addButton() }}
          ></ClientSideDataTable>
        </fieldset>
      </Form>
    </Formik>
  );
}
