import { createSelector } from '@reduxjs/toolkit';
import { selectMainDetailsItem } from '../../mainDetails/store/selectors';
import { selectDetails } from '../../store/selectors';
import { selectClient } from 'src/features/clients/onboard/store/selectors';

export const selectClientStatus = createSelector(selectMainDetailsItem, (mainDetailsItem) => {
  return mainDetailsItem?.status ?? undefined;
});

export const selectAdvisor = createSelector(selectClient, (details) => details.advisor)

export const selectAdviceFeesValues = createSelector(selectDetails, (details) => details.adviceFees);

export const selectAdviceServicesList = createSelector(selectAdviceFeesValues, (adviceFees) => adviceFees.adviceServicesList);

export const selectPendingFees = createSelector(selectAdviceFeesValues, (adviceFees) => adviceFees.pendingFees);

export const selectAFSLFeesList = createSelector(selectAdviceFeesValues, (adviceFees) => adviceFees.afslFeesList);

export const selectPendingFeesChangeSet = createSelector(selectAdviceFeesValues, (adviceFees) => adviceFees.pendingFeesChangeSet);

export const selectActiveFees = createSelector(selectAdviceFeesValues, (adviceFees) => adviceFees.activeFees);

export const selectEditingAdviceServiceList = createSelector(
  selectAdviceFeesValues,
  (adviceFees) => adviceFees.isEditingAdviceServiceList
);

export const selectPendingFeesItems = createSelector(selectPendingFees, (pendingFees) => {
  return pendingFees?.items ?? [];
});

export const selectActiveFeesItems = createSelector(selectActiveFees, (activeFees) => {
  return activeFees.items.filter((activeFee) => activeFee.hasEverBeenActive);
});

export const selectFeeEdit = createSelector(selectAdviceFeesValues, (adviceFees) => {
  const fees = adviceFees.isEditingActiveFee ? adviceFees?.activeFees : adviceFees?.pendingFees;
  const editValue = fees?.edit;

  if (typeof editValue === 'number') {
    return fees?.items[editValue] ?? undefined;
  }

  return editValue;
});

export const selectEditingTieredFeeDetails = createSelector(selectAdviceFeesValues, (adviceFees) => adviceFees.editingTieredFeeDetails || { items: [] });

export const selectTieredFeeDetailsEdit = createSelector(selectEditingTieredFeeDetails, (editingTieredFeeDetails) => editingTieredFeeDetails.edit);

export const selectTieredFeeDetailsItems = createSelector(selectEditingTieredFeeDetails, (editingTieredFeeDetails) => editingTieredFeeDetails.items);
