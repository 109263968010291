import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { progressSelectorFactory } from '../../../common';
import { RootState } from '../../../reducers';
import { CompositionComponent } from './components/CompositionComponent';
import { selectedVersion, strategyComposition, versions } from './store/selectors';
import { strategyCompositionSlice } from './store/slice';
import { fetchStrategyComposition, StrategyCompositionActionTypes } from './store/thunks';

const actionTypes = [StrategyCompositionActionTypes.FetchStrategyComposition];

const mapStateToProps = (state: RootState) => ({
  strategyComposition: strategyComposition(state),
  selectedVersion: selectedVersion(state),
  versions: versions(state),
  loadingProgress: progressSelectorFactory(actionTypes)(state),
});

const mapDispatchToProps = {
  ...strategyCompositionSlice.actions,
  fetchStrategyComposition,
};

interface MatchParams {
  strategyId?: string;
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector> & RouteComponentProps<MatchParams>;
export const StrategyCompositionStateContainer = connect(mapStateToProps, mapDispatchToProps)(CompositionComponent);
