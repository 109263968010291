import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Container, FormControlLabel, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect } from 'react';
import { WO2Checkbox, WO2ToggleButtonGroup, WO2ToggleSwitch } from '../../../common';
import { Props } from '../container';
import WO2Button from './../../../common/components/button/Button';

export const useStyles = makeStyles(() => ({
  root: {
    '& .MuiAccordionSummary-expandIconWrapper': {
      order: 'unset',
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: '12px 0',
    },
  },
}));

export const Results = (props: Props): JSX.Element => {
  const classes = useStyles();
  const { reportList, fetchReportList, setHiddingOptionReport, setUseCiriteriaReport, setOrganiseReport, setSortReport } = props;

  useEffect(() => {
    fetchReportList();
  }, [fetchReportList]);

  const handleChangeHiddingOption = (event: React.ChangeEvent<HTMLInputElement>, indexReport: number, indexHidding: number) => {
    const value = event.target.checked;
    setHiddingOptionReport({ value, indexReport, indexHidding });
  };

  const renderReports = () => {
    return reportList.map((report, indexReport) => (
      <Grid style={{ marginBottom: 30 }} key={report.id}>
        <Accordion elevation={0}>
          <AccordionSummary style={{ paddingLeft: 20 }} expandIcon={<ExpandMoreIcon style={{ float: 'right' }} />} aria-controls="panel1a-content">
            <Typography style={{ marginBottom: 0 }} variant="subtitle1" display="block" gutterBottom>
              {report.displayName}
              <br />
              <span style={{ color: '#bdbdbd', fontSize: 12 }}>{report.type}</span>
              <br />
            </Typography>
          </AccordionSummary>
          <AccordionDetails style={{ borderTop: '1px solid rgba(0, 0, 0, 0.08)', padding: '12px 20px' }}>
            <Grid>
              <WO2Checkbox
                label="Use template ciriteria"
                value={report.useCiriteria}
                onChangeHandler={(value) => setUseCiriteriaReport({ value, indexReport })}
              />
            </Grid>
            <Grid style={{ textAlign: 'justify', paddingTop: '10px' }}>
              <Typography variant="h5">Organise By</Typography>
              <Grid container direction="column" alignItems="flex-start" style={{ paddingBottom: '10px', paddingTop: '10px' }}>
                <Grid>
                  <WO2ToggleButtonGroup
                    buttons={report.groupingOptions}
                    value={report.organiseBy}
                    onChangeHandler={(value) => setOrganiseReport({ value, indexReport })}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid style={{ textAlign: 'justify', paddingTop: '10px' }}>
              <Typography variant="h5">Sort By</Typography>
              <Grid container direction="column" alignItems="flex-start" style={{ paddingBottom: '10px', paddingTop: '10px' }}>
                <Grid>
                  <WO2ToggleButtonGroup
                    buttons={report.primarySortingOptions}
                    value={report.sortBy}
                    onChangeHandler={(value) => setSortReport({ value, indexReport })}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid container style={{ paddingTop: '15px' }}>
              {report.hidingOptions.map((item, indexHidding) => (
                <Grid item xs={3} key={indexHidding}>
                  <FormControlLabel
                    control={<WO2ToggleSwitch checked={item.value} onChange={(e) => handleChangeHiddingOption(e, indexReport, indexHidding)} color="primary" />}
                    label={item.displayName}
                  />
                </Grid>
              ))}
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
    ));
  };

  return (
    <Container className={classes.root} style={{ width: '100%', paddingRight: 0, paddingLeft: 50 }}>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: -60,
          marginBottom: 20,
        }}
      >
        <WO2Button style={{ borderRadius: 20, marginRight: 15, height: 40 }} variant="outlined">
          Recent reports
        </WO2Button>
        <WO2Button style={{ borderRadius: 20, width: 130, height: 40 }} color={'primary'} variant="contained">
          Next
        </WO2Button>
      </Box>
      {renderReports()}
    </Container>
  );
};
