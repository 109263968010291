import { connect, ConnectedProps } from 'react-redux';
import { hasErrorsSelectorFactory, isLoadingSelectorFactory } from 'src/common';
import { RootState } from 'src/store';
import { selectIsDataFromTriumph } from 'src/store/selectors';
import { selectAfsls } from '../../common/store/selectors';
import { selectSecurities } from '../../order/search/store/selectors';
import { FormSearch } from './components/formSearch';
import { selectModels, selectParameters } from './store/selectors';
import { searchSlice } from './store/slice';
import { EmailSearchTypes, fetchAccountSearchResults, fetchModels } from './store/thunks';

const actionTypes = [EmailSearchTypes.FetchAccountResults];

const mapStateToProps = (state: RootState) => ({
  afsls: selectAfsls(state),
  models: selectModels(state),
  parameters: selectParameters(state),
  securities: selectSecurities(state),
  isLoading: isLoadingSelectorFactory(actionTypes)(state),
  hasErrors: hasErrorsSelectorFactory(actionTypes)(state),
  isDataFromTriumph: selectIsDataFromTriumph(state),
});

const mapDispatchToProps = {
  ...searchSlice.actions,
  fetchAccountSearchResults,
  fetchModels,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector>;
export const EmailSearchFiltersContainer = connector(FormSearch);
