import { createSelector } from '@reduxjs/toolkit';
import { selectApprovedProducts } from '../../../store/common';
import { selectTargets } from '../../store/selectors';

export const selectConfigurations = createSelector(selectTargets, (targets) => targets.configurations);

export const selectStrategicAllocations = createSelector(selectTargets, (targets) => targets.strategicAllocations);

export const selectCashAccountProduct = createSelector(
  selectApprovedProducts,
  (approvedProducts) => approvedProducts.find((p) => p.code === 'MacqBankCMA.ADI') || undefined
);

export const selectDefaultSecurities = createSelector(selectTargets, (targets) => targets.defaultSecurities);
