import { Enumeration } from './enumeration';

export class Title extends Enumeration {
  static Mr = new Title(1, 'Mr', 'Mr');
  static Mrs = new Title(2, 'Mrs', 'Mrs');
  static Ms = new Title(3, 'Ms', 'Ms');
  static Miss = new Title(4, 'Miss', 'Miss');
  static Dr = new Title(5, 'Dr', 'Dr');

  static getById = (id: number | null): Title | undefined => {
    return Object.values(Title)
      .filter((t) => t instanceof Title)
      .find((t: Title) => t.id === id);
  };

  static getByName = (name: string): Title | undefined => {
    return Object.values(Title)
      .filter((t) => t instanceof Title)
      .find((t: Title) => t.name.toLowerCase() === name.toLowerCase());
  };
}
