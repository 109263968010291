import { DateRange } from '../../../store';
import { Client } from '../../bulk/common/store/types';

export interface ReportState {
  parameters: ReportParameters;
  myReport: MyReport[];
  reportList: Report[];
}

export interface Report {
  id: number;
  displayName: string;
  name: string;
  type: string;
  groupingOptions: Enumeration[];
  primarySortingOptions: Enumeration[];
  hidingOptions: HidingOption[];
  organiseBy: string;
  sortBy: string;
  useCiriteria: boolean;
}

export interface MyReport {
  id: number;
  name: string;
  type: string;
}

export class Enumeration {
  value: string;
  name: string;
  displayName: string;

  constructor(value: string, name: string, displayName: string) {
    this.name = name;
    this.displayName = displayName;
    this.value = value;
  }
}

export interface ReportParameters {
  client: Client | null;
  coverPage: boolean;
  addAppendix: boolean;
  mergeReports: boolean;
  reportFormat: string;
  dates: DateRange;
}

export interface HidingOption {
  name: string;
  displayName: string;
  value: boolean;
}

export class ReportFormat extends Enumeration {
  static EXCEL = new ReportFormat('excel', 'excel', 'Excel');
  static WORD = new ReportFormat('word', 'word', 'Word');
  static PDF = new ReportFormat('pdf', 'pdf', 'PDF');
}
