import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';
import { SystemMinimumDate } from 'src/common/utils/dateFunctions';
import { DateRange } from '../../../../../store';
import { getAsyncActions } from '../../../../../store/asyncAction';
import { ClientPortfolio } from '../../common/store/types';
import { GainsLossesResult, GainsLossesState, GainType, GroupingType } from './types';

export const initialState: GainsLossesState = {
  parameters: {
    selectedPortfolio: null,
    gainType: GainType.Unrealised,
    groupingType: GroupingType.Security,
    dates: { dateFrom: DateTime.fromISO(SystemMinimumDate).toISODate(), dateTo: DateTime.now().toISODate() },
    filter: '',
  },
  results: undefined,
};

export const unrealisedActions = getAsyncActions<GainsLossesResult>('@@client/gainsLosses/fetchUnrealised');
export const realisedActions = getAsyncActions<GainsLossesResult>('@@client/gainsLosses/fetchRealised');

export const gainsAndLossesSlice = createSlice({
  name: '@@client/gainsLosses',
  initialState,
  reducers: {
    setPortfolioParameter: (state, action: PayloadAction<ClientPortfolio>) => {
      state.parameters.selectedPortfolio = action.payload;
    },
    setGainTypeParameter: (state, action: PayloadAction<GainType>) => {
      state.parameters.gainType = action.payload;
    },
    setGroupingTypeParameter: (state, action: PayloadAction<GroupingType>) => {
      state.parameters.groupingType = action.payload;
    },
    setDateParameter: (state, action: PayloadAction<DateRange>) => {
      state.parameters.dates = action.payload;
    },
    setFilterParameter: (state, action: PayloadAction<string>) => {
      state.parameters.filter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(unrealisedActions.fulfilled, (state, action: PayloadAction<GainsLossesResult>) => {
      state.results = action.payload;
    });
    builder.addCase(realisedActions.fulfilled, (state, action: PayloadAction<GainsLossesResult>) => {
      state.results = action.payload;
    });
  },
});
