import Download from '@mui/icons-material/Download';
import { Card, CardContent, Container, Divider, Grid, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { WO2ToggleSwitch } from '../../../../../common';
import DateRangePicker from '../../../../../common/components/DateRangePicker';
import { DateRange } from '../../../../../store/types/dateRange';
import { Props } from '../container';
import { ReportAttachmentType, ReportToSort } from '../store/types';
import { UploadReportAttachment } from './UploadReportAttachment';
import { AdditionsAndWithdrawals } from './report/AdditionsAndWithdrawals';
import { AssetAllocation } from './report/AssetAllocation';
import { CashTransactions } from './report/CashTransactions';
import { Expenses } from './report/Expenses';
import { RealisedCapitalGainsAndLosses } from './report/RealisedCapitalGainsAndLosses';
import { UnrealisedCapitalGainsAndLosses } from './report/UnrealisedCapitalGainsAndLosses';
import { IncomeReport } from './report/IncomeReport';
import { PortfolioValuation } from './report/PortfolioValuation';
import { SecurityPerformance } from './report/SecurityPerformance';
import { TransactionHistory } from './report/TransactionHistory';
import { HoldingsVsTargetVariance } from './report/HoldingsVsTargetVariance';
import { PortfolioSnapshot } from './report/PortfolioSnapshot';
import { TaxInvoice } from './report/TaxInvoice';
import { PorfolioPerformance } from './report/PortfolioPerformance';
import { SecurityContribution } from './report/SecurityContribution';
import { TaxSummary } from './report/TaxSummary';
import { InvestmentIncomeTax } from './report/InvestmentIncomeTax';
import { AssetClassPerformance } from './report/AssetClassPerformance';
import WO2Button from 'src/common/components/button/Button';
import { UnrealisedNonCGTGainsAndLosses } from './report/UnrealisedNonCGTGainsAndLosses';
import { ReportingToolbar } from './ReportingToolbar';

export const ReportsComponent = ({
  afslId,
  parameters,
  savedParametersList,
  reportGeneration,
  setDateParameter,
  fetchBenchmarks,
  benchmarks,
  setPortfolioSnapshotBenchmarkParameter,
  setUseTriumphAsDataSourceParameter,
  setMergeReportsParameter,
  setIncludeLogoParameter,
  setCashTransactionsSelectedParameter,
  setExpensesSelectedParameter,
  setCapitalGainsLossesUnrealisedSelectedParameter: setGainsLossesUnrealisedSelectedParameter,
  setRealisedCapitalGainsAndLossesSelectedParameter: setGainsLossesRealisedSelectedParameter,
  setSecurityPerformanceSelectedParameter,
  setTransactionHistorySelectedParameter: setTransactionsSelectedParameter,
  setTransactionHistoryHidingOptionsParameter: setTransactionsHidingOptionsParameter,
  setTransactionHistorySortByParameter: setTransactionsSortByParameter,
  setTransactionHistoryGroupByParameter: setTransactionsGroupByParameter,
  setIncomeReportSelectedParameter,
  setIncomeReportOutstandingPaymentsOnlyFilterOptionsParameter,
  setIncomeReportExcludeCashFilterOptionsParameter,
  setIncomeReportSortByParameter,
  setIncomeReportGroupByParameter,
  setPortfolioValuationSelectedParameter,
  setPortfolioValuationSortByParameter,
  setPortfolioValuationGroupByParameter,
  setDateRangeSecurityContributionParameter,
  setAssetAllocationSelectedParameter,
  setHoldingsVsTargetVarianceSelectedParameter,
  setAsAtDateHoldingsVsTargetVarianceParameter,
  setAssetAllocationPortfolioParameter,
  setPortfolioValuationPortfolioIdParameter,
  setSelectedReportOrderParameter,
  setShowItemOderingModalParameter,
  setAsAtDateAssetAllocationParameter,
  setDateRangeAdditionsAndWithdrawalsParameter,
  setAsAtDateCapitalGainsLossesUnrealisedParameter: setAsAtDateGainsLossesUnrealisedParameter,
  setReportDatePortfolioValuationParameter,
  setDateRangeIncomeReportParameter,
  setAdditionsAndWithdrawalsSelectedParameter,
  setDateRangeCashTransactionsParameter,
  setDateRangeExpensesParameter,
  setExpensesExcludeExpenseTypeParameter,
  setCashTransactionsSortByParameter,
  setDateRangeRealisedCapitalGainsAndLossesParameter: setDateRangeGainsLossesRealisedParameter,
  setDateRangeSecurityPerformanceParameter,
  setPortfolioSnapshotSelectedParameter,
  setDateRangePortfolioSnapshotParameter,
  setTaxInvoiceSelectedParameter,
  setDateRangeTaxInvoiceParameter,
  setDateRangeTransactionHistoryParameter: setDateRangeTransactionsParameter,
  setDateRangePortfolioPerformanceParameter,
  setPortfolioPerformanceSelectedParameter,
  setSecurityContributionSelectedParameter,
  setTaxSummarySelectedParameter,
  setDateRangeTaxSummaryParameter,
  setInvestmentIncomeTaxSelectedParameter,
  setDateRangeInvestmentIncomeTaxParameter,
  setAsAtDateUnrealisedNonCGTParameter,
  setUnrealisedNonCGTSelectedParameter,
  clientPortfolios,
  getCGTRecordsForClient,
  fetchClientAccount,
  match: { params },
  generateProgress,
  setIncludeAppendixParameter,
  setIncludeCoverLetterParameter,
  setReportGeneration,
  setReportingParameter,
  fetchReportParametersList,
  setSelectedReportingParameter,
  getReportParametersById,
  generateProgressReportParameters,
  isDataFromTriumph,
  setAssetAllocationHidingOption,
  setHoldingsVsTargetVarianceHidingOptions,
  setSecurityPerformanceHidingOptions,
  setSecurityPerformanceGroupByParameter,
  setPortfolioSnapshotPortfolioParameter,
  setPortfolioPerformanceHidingOption,
  setPortfolioPerformanceBenchmarkParameter,
  setRealisedCapitalGainsAndLossesSummaryParameter: setGainsLossesRealisedSummaryParameter,
  setPortfolioValuationHidingOptionParameter,
  setSecurityContributionHidingOptionParameter,
  setSecurityContributionGroupByParamater,
  setIncomeReportDateTypeParameter,
  setAssetClassPerformanceHidingOptionParameter,
  setAssetClassPerformanceSelectedParameter,
  setDateRangeAssetClassPerformanceParameter,
  expandAssetAllocation,
  expandAssetClassPerformance,
  expandHoldingsVsTargetVariance,
  expandCashTransactions,
  expandExpenses,
  expandIncomeReport,
  expandPortfolioSnapshot,
  expandPortfolioPerformance,
  expandPortfolioValuation,
  expandSecurityPerformance,
  expandGainsAndLossesRealised,
  expandSecurityContribution,
  expandTransactionHistory: expandTransactions,
  expandAll,
  saveCoverPageFile,
  saveAppendixFile,
}: Props): JSX.Element => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [currentReportType, setCurrentReportType] = useState<ReportAttachmentType>(ReportAttachmentType.None);

  useEffect(() => {
    if (!parameters.selectedParameter) return;
    getReportParametersById({
      requestId: parameters.selectedParameter?.id,
      afslId: afslId ?? 0,
    });
  }, [parameters.selectedParameter]);

  const updateReportDates = (dates: DateRange) => {
    setDateParameter(dates);
  };

  const closeUploader = () => {
    setIsMenuOpen(false);

    currentReportType === ReportAttachmentType.CoverLetter
      ? setIncludeCoverLetterParameter(false)
      : setIncludeAppendixParameter(false);
  };

  const setFile = (file: File) => {
    setIsMenuOpen(false);

    if (currentReportType === ReportAttachmentType.CoverLetter) {
      saveCoverPageFile(file);
    } else if (currentReportType === ReportAttachmentType.Appendix) {
      saveAppendixFile(file);
    }
  };

  useEffect(() => {
    const clientId = Number(params.id) ?? 0;
    if (clientId > 0) {
      fetchClientAccount(clientId);
      getCGTRecordsForClient(clientId);
      if (afslId !== undefined && afslId > 0) {
        fetchReportParametersList(afslId);
      }
    }
  }, [fetchClientAccount, getCGTRecordsForClient, params.id, afslId]);

  useEffect(() => {
    if (afslId && afslId > 0) {
      fetchBenchmarks(afslId);
    }
  }, [afslId]);

  const createReportSortRequest = (isAdd: boolean, id: number, text: string) => {
    const input: ReportToSort = { id: id, text: text };
    let result: ReportToSort[] = [];

    if (isAdd) {
      result = insertItem(parameters.reportsToSort, { item: input, index: parameters.reportsToSort.length });
      setSelectedReportOrderParameter(result);
    } else {
      const index = parameters.reportsToSort.findIndex((x) => x.id === input.id);
      if (index > -1) {
        result = removeItem(parameters.reportsToSort, { item: input, index: index });
        setSelectedReportOrderParameter(result);
      }
    }
  };

  const insertItem = (array: ReportToSort[], action: { item: ReportToSort; index: number }) => {
    const newArray = array.slice();
    newArray.splice(action.index, 0, action.item);
    return newArray;
  };

  const removeItem = (array: ReportToSort[], action: { item: ReportToSort; index: number }) => {
    const newArray = array.slice();
    newArray.splice(action.index, 1);
    return newArray;
  };

  /**
   * TODO: Fix the file saving.
   * @param reportType
   */
  const downloadFile = (reportType: ReportAttachmentType) => {
    if (reportType === ReportAttachmentType.CoverLetter) {
      if (parameters.coverLetter === undefined) return;
      //FileSaver.saveAs(parameters.coverLetter, `${parameters.coverLetter?.name}`, { autoBom: false });
      return;
    }
    if (parameters.appendix === undefined) return;
    //FileSaver.saveAs(parameters.appendix, `${parameters.appendix?.name}`, { autoBom: false });
  };

  useEffect(() => {
    setUseTriumphAsDataSourceParameter(!parameters.isDataFromTriumph);
  }, [isDataFromTriumph]);

  const handleClickIncludeCoverLetter = () => {
    setIncludeCoverLetterParameter(!parameters.includeCoverLetter);
    setIsMenuOpen(!parameters.includeCoverLetter);
    setCurrentReportType(ReportAttachmentType.CoverLetter);
  };

  const isDatesLessThanFullYear = (dates: DateRange): boolean => {
    const dateFrom = new Date(dates.dateFrom);
    const dateTo = new Date(dates.dateTo);
    return dateTo.getFullYear() - dateFrom.getFullYear() < 1;
  };

  const handleClickMergeReports = () => {
    setMergeReportsParameter(!parameters.mergeReports);
  };

  const handleClickIncludeAppendix = () => {
    setIncludeAppendixParameter(!parameters.includeAppendix);
    setIsMenuOpen(!parameters.includeAppendix);
    setCurrentReportType(ReportAttachmentType.Appendix);
  };

  const handleClickIncludeLogo = () => {
    setIncludeLogoParameter(!parameters.includeLogo);
  };

  return (
    <Container style={{ paddingTop: '20px' }}>
      <ReportingToolbar
        savedParametersList={savedParametersList}
        reportGeneration={reportGeneration}
        generateProgress={generateProgress}
        parameters={parameters}
        setSelectedReportingParameter={setSelectedReportingParameter}
        setReportingParameter={setReportingParameter}
        generateProgressReportParameters={generateProgressReportParameters}
        setShowItemOderingModalParameter={setShowItemOderingModalParameter}
        setSelectedReportOrderParameter={setSelectedReportOrderParameter}
        setReportGeneration={setReportGeneration}
        isDataFromTriumph={isDataFromTriumph}
        handleClickMergeReports={handleClickMergeReports}
        clientId={params.id}
      />
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Card elevation={0}>
            <Grid container>
              <Typography variant="h3" align="left" style={{ padding: '10px' }}>
                Report Parameters
              </Typography>
            </Grid>
            <CardContent>
              <DateRangePicker
                dateRange={parameters?.dates}
                setDateRange={updateReportDates}
                inceptionDate={parameters.inceptionDate}
              />
              <Grid container style={{ paddingTop: '15px' }}>
                <WO2ToggleSwitch
                  checked={parameters.mergeReports}
                  onChange={handleClickMergeReports}
                  color="primary"
                  name="mergeReports"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
                <Typography variant="h4" style={{ paddingTop: '10px' }}>
                  Merge Reports
                </Typography>
              </Grid>
              <Grid container style={{ paddingTop: '10px' }}>
                <WO2ToggleSwitch
                  checked={parameters.includeLogo}
                  onChange={handleClickIncludeLogo}
                  color="primary"
                  name="includeLogo"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
                <Typography variant="h4" style={{ paddingTop: '10px' }}>
                  Include Logo
                </Typography>
              </Grid>
              <Grid container style={{ paddingTop: '10px' }}>
                <WO2ToggleSwitch
                  checked={parameters.includeCoverLetter}
                  onChange={handleClickIncludeCoverLetter}
                  color="primary"
                  name="includeCoverPage"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
                <Typography variant="h4" style={{ paddingTop: '10px' }}>
                  Cover Page
                </Typography>
                {parameters.includeCoverLetter && parameters.coverLetter !== undefined && (
                  <Tooltip
                    title="Download"
                    style={{ marginTop: '10px' }}
                    onClick={() => {
                      downloadFile(ReportAttachmentType.CoverLetter);
                    }}
                  >
                    <Download></Download>
                  </Tooltip>
                )}
              </Grid>
              <Grid container style={{ paddingTop: '10px' }}>
                <WO2ToggleSwitch
                  checked={parameters.includeAppendix}
                  onChange={handleClickIncludeAppendix}
                  color="primary"
                  name="includeAppendix"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
                <Typography variant="h4" style={{ paddingTop: '10px' }}>
                  Appendix
                </Typography>
                {parameters.includeAppendix && parameters.appendix !== undefined && (
                  <Tooltip
                    title="Download"
                    style={{ marginTop: '10px' }}
                    onClick={() => {
                      downloadFile(ReportAttachmentType.Appendix);
                    }}
                  >
                    <Download></Download>
                  </Tooltip>
                )}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item container display="flex" direction="column" xs={9} mt={2} spacing={1}>
          <Grid item>
            <WO2Button variant="contained" onClick={expandAll}>
              Expand
            </WO2Button>
          </Grid>
          <Grid item>
            <AdditionsAndWithdrawals
              parameters={parameters}
              setDateRangeAdditionsAndWithdrawalsParameter={setDateRangeAdditionsAndWithdrawalsParameter}
              setAdditionsAndWithdrawalsSelectedParameter={setAdditionsAndWithdrawalsSelectedParameter}
              createReportSortRequest={createReportSortRequest}
            />
          </Grid>
          <Grid item>
            <Divider />
          </Grid>
          <Grid item>
            <AssetAllocation
              expandAssetAllocation={expandAssetAllocation}
              setAssetAllocationHidingOption={setAssetAllocationHidingOption}
              clientPortfolios={clientPortfolios}
              parameters={parameters}
              setAssetAllocationSelectedParameter={setAssetAllocationSelectedParameter}
              setAssetAllocationPortfolioParameter={setAssetAllocationPortfolioParameter}
              setAsAtDateAssetAllocationParameter={setAsAtDateAssetAllocationParameter}
              createReportSortRequest={createReportSortRequest}
            />
          </Grid>
          <Grid item>
            <Divider />
          </Grid>
          <Grid item>
            <AssetClassPerformance
              parameters={parameters}
              expandAssetClassPerformance={expandAssetClassPerformance}
              isDatesLessThanFullYear={isDatesLessThanFullYear}
              setAssetClassPerformanceHidingOptionParameter={setAssetClassPerformanceHidingOptionParameter}
              setAssetClassPerformanceSelectedParameter={setAssetClassPerformanceSelectedParameter}
              setDateRangeAssetClassPerformanceParameter={setDateRangeAssetClassPerformanceParameter}
              createReportSortRequest={createReportSortRequest}
            />
          </Grid>
          <Grid item>
            <Divider />
          </Grid>
          <Grid item>
            <HoldingsVsTargetVariance
              parameters={parameters}
              createReportSortRequest={createReportSortRequest}
              expandHoldingsVsTargetVariance={expandHoldingsVsTargetVariance}
              setHoldingsVsTargetVarianceHidingOptions={setHoldingsVsTargetVarianceHidingOptions}
              setHoldingsVsTargetVarianceSelectedParameter={setHoldingsVsTargetVarianceSelectedParameter}
              setAsAtDateHoldingsVsTargetVarianceParameter={setAsAtDateHoldingsVsTargetVarianceParameter}
            />
          </Grid>
          <Grid item>
            <Divider />
          </Grid>
          <Grid item>
            <CashTransactions
              parameters={parameters}
              setCashTransactionsSortByParameter={setCashTransactionsSortByParameter}
              expandCashTransactions={expandCashTransactions}
              setDateRangeCashTransactionsParameter={setDateRangeCashTransactionsParameter}
              setCashTransactionsSelectedParameter={setCashTransactionsSelectedParameter}
              createReportSortRequest={createReportSortRequest}
            />
          </Grid>
          <Grid item>
            <Divider />
          </Grid>
          <Grid item>
            <Expenses
              parameters={parameters}
              expandExpenses={expandExpenses}
              setExpensesExcludeExpenseTypeParameter={setExpensesExcludeExpenseTypeParameter}
              setDateRangeExpensesParameter={setDateRangeExpensesParameter}
              setExpensesSelectedParameter={setExpensesSelectedParameter}
              createReportSortRequest={createReportSortRequest}
            />
          </Grid>
          <Grid item>
            <Divider />
          </Grid>
          <Grid item>
            <IncomeReport
              parameters={parameters}
              setIncomeReportOutstandingPaymentsOnlyFilterOptionsParameter={
                setIncomeReportOutstandingPaymentsOnlyFilterOptionsParameter
              }
              expandIncomeReport={expandIncomeReport}
              setIncomeReportDateTypeParameter={setIncomeReportDateTypeParameter}
              setIncomeReportSelectedParameter={setIncomeReportSelectedParameter}
              setIncomeReportExcludeCashFilterOptionsParameter={setIncomeReportExcludeCashFilterOptionsParameter}
              setIncomeReportSortByParameter={setIncomeReportSortByParameter}
              setIncomeReportGroupByParameter={setIncomeReportGroupByParameter}
              setDateRangeIncomeReportParameter={setDateRangeIncomeReportParameter}
              createReportSortRequest={createReportSortRequest}
            />
          </Grid>
          <Grid item>
            <Divider />
          </Grid>
          <Grid item>
            <SecurityPerformance
              parameters={parameters}
              isDatesLessThanFullYear={isDatesLessThanFullYear}
              expandSecurityPerformance={expandSecurityPerformance}
              setSecurityPerformanceGroupByParameter={setSecurityPerformanceGroupByParameter}
              setSecurityPerformanceHidingOptions={setSecurityPerformanceHidingOptions}
              setDateRangeSecurityPerformanceParameter={setDateRangeSecurityPerformanceParameter}
              setSecurityPerformanceSelectedParameter={setSecurityPerformanceSelectedParameter}
              createReportSortRequest={createReportSortRequest}
            />
          </Grid>
          <Grid item>
            <Divider />
          </Grid>
          <Grid item>
            <PortfolioSnapshot
              expandPortfolioSnapshot={expandPortfolioSnapshot}
              clientPortfolios={clientPortfolios}
              parameters={parameters}
              benchmarks={benchmarks}
              setPortfolioSnapshotBenchmarkParameter={setPortfolioSnapshotBenchmarkParameter}
              createReportSortRequest={createReportSortRequest}
              setPortfolioSnapshotPortfolioParameter={setPortfolioSnapshotPortfolioParameter}
              setDateRangePortfolioSnapshotParameter={setDateRangePortfolioSnapshotParameter}
              setPortfolioSnapshotSelectedParameter={setPortfolioSnapshotSelectedParameter}
            />
          </Grid>
          <Grid item>
            <Divider />
          </Grid>
          <Grid item>
            <TaxInvoice
              createReportSortRequest={createReportSortRequest}
              parameters={parameters}
              setDateRangeTaxInvoiceParameter={setDateRangeTaxInvoiceParameter}
              setTaxInvoiceSelectedParameter={setTaxInvoiceSelectedParameter}
            />
          </Grid>
          <Grid item>
            <Divider />
          </Grid>
          <Grid item>
            <PorfolioPerformance
              parameters={parameters}
              createReportSortRequest={createReportSortRequest}
              benchmarks={benchmarks}
              expandPortfolioPerformance={expandPortfolioPerformance}
              setPortfolioPerformanceHidingOption={setPortfolioPerformanceHidingOption}
              setPortfolioPerformanceBenchmarkParameter={setPortfolioPerformanceBenchmarkParameter}
              setDateRangePortfolioPerformanceParameter={setDateRangePortfolioPerformanceParameter}
              setPortfolioPerformanceSelectedParameter={setPortfolioPerformanceSelectedParameter}
            />
          </Grid>
          <Grid item>
            <Divider />
          </Grid>
          <Grid item>
            <PortfolioValuation
              parameters={parameters}
              createReportSortRequest={createReportSortRequest}
              expandPortfolioValuation={expandPortfolioValuation}
              setPortfolioValuationHidingOptionParameter={setPortfolioValuationHidingOptionParameter}
              setPortfolioValuationSelectedParameter={setPortfolioValuationSelectedParameter}
              setPortfolioValuationSortByParameter={setPortfolioValuationSortByParameter}
              setPortfolioValuationGroupByParameter={setPortfolioValuationGroupByParameter}
              setReportDatePortfolioValuationParameter={setReportDatePortfolioValuationParameter}
              setPortfolioValuationPortfolioIdParameter={setPortfolioValuationPortfolioIdParameter}
              clientPortfolios={clientPortfolios}
            />
          </Grid>
          <Grid item>
            <Divider />
          </Grid>
          <Grid item>
            <RealisedCapitalGainsAndLosses
              parameters={parameters}
              expandGainsAndLossesRealised={expandGainsAndLossesRealised}
              setRealisedCapitalGainsAndLossesSummaryParameter={setGainsLossesRealisedSummaryParameter}
              setDateRangeRealisedCapitalGainsAndLossesParameter={setDateRangeGainsLossesRealisedParameter}
              setRealisedCapitalAndGainsLossesSelectedParameter={setGainsLossesRealisedSelectedParameter}
              createReportSortRequest={createReportSortRequest}
            />
          </Grid>
          <Grid item>
            <Divider />
          </Grid>
          <Grid item>
            <UnrealisedCapitalGainsAndLosses
              parameters={parameters}
              setAsAtDateGainsLossesUnrealisedParameter={setAsAtDateGainsLossesUnrealisedParameter}
              setGainsLossesUnrealisedSelectedParameter={setGainsLossesUnrealisedSelectedParameter}
              createReportSortRequest={createReportSortRequest}
            />
          </Grid>
          <Grid item>
            <Divider />
          </Grid>
          <Grid item>
            <SecurityContribution
              parameters={parameters}
              expandSecurityContribution={expandSecurityContribution}
              isDatesLessThanFullYear={isDatesLessThanFullYear}
              createReportSortRequest={createReportSortRequest}
              setSecurityContributionGroupByParamater={setSecurityContributionGroupByParamater}
              setSecurityContributionHidingOptionParameter={setSecurityContributionHidingOptionParameter}
              setDateRangeSecurityContributionParameter={setDateRangeSecurityContributionParameter}
              setSecurityContributionSelectedParameter={setSecurityContributionSelectedParameter}
            />
          </Grid>
          <Grid item>
            <Divider />
          </Grid>
          <Grid item>
            <TaxSummary
              createReportSortRequest={createReportSortRequest}
              parameters={parameters}
              setDateRangeTaxSummaryParameter={setDateRangeTaxSummaryParameter}
              setTaxSummarySelectedParameter={setTaxSummarySelectedParameter}
            />
          </Grid>
          <Grid item>
            <Divider />
          </Grid>
          <Grid item>
            <InvestmentIncomeTax
              parameters={parameters}
              createReportSortRequest={createReportSortRequest}
              setDateRangeInvestmentIncomeTaxParameter={setDateRangeInvestmentIncomeTaxParameter}
              setInvestmentIncomeTaxSelectedParameter={setInvestmentIncomeTaxSelectedParameter}
            />
          </Grid>
          <Grid item>
            <Divider />
          </Grid>
          <Grid item>
            <TransactionHistory
              parameters={parameters}
              expandTransactions={expandTransactions}
              setTransactionsGroupByParameter={setTransactionsGroupByParameter}
              setTransactionsSortByParameter={setTransactionsSortByParameter}
              setTransactionsSelectedParameter={setTransactionsSelectedParameter}
              setDateRangeTransactionsParameter={setDateRangeTransactionsParameter}
              setTransactionsHidingOptionsParameter={setTransactionsHidingOptionsParameter}
              createReportSortRequest={createReportSortRequest}
            />
          </Grid>
          <Grid item>
            <Divider />
          </Grid>
          <Grid item>
            <UnrealisedNonCGTGainsAndLosses
              parameters={parameters}
              setUnrealisedNonCGTSelectedParameter={setUnrealisedNonCGTSelectedParameter}
              setAsAtDateUnrealisedNonCGTParameter={setAsAtDateUnrealisedNonCGTParameter}
              createReportSortRequest={createReportSortRequest}
            />
          </Grid>
        </Grid>
      </Grid>
      <UploadReportAttachment
        isOpen={isMenuOpen}
        handleImport={setFile}
        handleClose={closeUploader}
        title={`Upload ${currentReportType}`}
      />
    </Container>
  );
};
