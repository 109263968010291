import { FormControl, InputLabel, MenuItem } from '@mui/material';
import React, { useRef } from 'react';
import { WO2Select } from './../';

interface Props<T> {
  label?: string;
  enumType: T;
  value: keyof T;
  onChange: (value: keyof T) => void;
}

export function EnumSelect<T>(props: Props<T>): JSX.Element {
  const inputLabel: React.MutableRefObject<HTMLLabelElement | null> = useRef(null);

  return (
    <div>
      <FormControl variant="outlined">
        <InputLabel ref={inputLabel} id="enum-select-label">
          {props.label}
        </InputLabel>
        <WO2Select
          labelId="enum-select-label"
          id="enum-select"
          value={props.value}
          onChange={(event) => {
            event.target.value && event.target.value !== props.value && props.onChange(event.target.value as keyof T);
          }}
          label={props.label}
        >
          {Object.keys(props.enumType).map((item) => {
            return (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            );
          })}
        </WO2Select>
      </FormControl>
    </div>
  );
}
