import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EditablePagedCollection } from 'src/common/store/types';
import { AttachableContact, Contact, ContactRoles } from 'src/features/clients/common/types';
import { FetchPagedResults } from 'src/store';
import { EmailType, PhoneNumberType } from '../../../common/store/enums';
import { AddressDetails, ContactDetail, ContactDetails, DocumentDetails, DocumentsParameters, EmailAddress, PhoneNumber } from '../../../common/store/types';
import { cancelDocumentAddEditMode, setEmailAddressEditId, setPhoneNumberEditId } from './actions';
import {
  fetchAddresses,
  fetchContact,
  fetchContactAddresses,
  fetchContactContactDetails,
  fetchContactDetails,
  fetchContactDocumentForEdit,
  fetchContactDocuments,
  fetchContactIdentificationForEdit,
  fetchContactIdentifications,
  fetchContacts,
  fetchContactsToAttach,
  fetchDocumentForEdit,
  fetchDocuments,
  fetchTrustee,
  fetchTrustees,
  fetchTrusteesToAttach,
  saveAddress,
  saveContactAddress,
  saveTrustee,
} from './thunks';
import { AttachableTrustee, TrusteeAbbreviated, TrusteeDetails, TrusteesState } from './types';

export const initialState: TrusteesState = {
  items: [],
  edit: {
    id: null,
    details: null,
    addresses: { items: [] },
    emailAddresses: { items: [] },
    phoneNumbers: { items: [] },
    documents: {
      parameters: {
        pagination: {
          pageNumber: 1,
          pageSize: 25,
          queryFields: [],
        },
      },
      results: {
        items: {
          pageNumber: 1,
          pageSize: 25,
          totalNumberOfPages: 0,
          totalNumberOfRecords: 0,
          results: [],
        },
      },
    },
    contacts: {
      items: [],
      edit: {
        contact: { edit: undefined, attachContactList: [] },
        addresses: {
          items: [],
          edit: undefined,
        },
        documents: {
          parameters: {
            pagination: {
              pageNumber: 1,
              pageSize: 2,
              queryFields: [],
            },
          },
          results: {
            items: {
              pageNumber: 1,
              pageSize: 2,
              totalNumberOfPages: 1,
              totalNumberOfRecords: 0,
              results: [],
            },
            edit: undefined,
          },
        },
        identifications: {
          parameters: {
            pagination: {
              pageNumber: 1,
              pageSize: 2,
              queryFields: [],
            },
          },
          results: {
            items: {
              pageNumber: 1,
              pageSize: 2,
              totalNumberOfPages: 1,
              totalNumberOfRecords: 0,
              results: [],
            },
            edit: undefined,
          },
        },
        phoneNumbers: {
          items: [],
          edit: undefined,
        },
        emailAddresses: {
          items: [],
          edit: undefined,
        },
      },
    },
    attachableTrustees: [],
  },
};

export const trusteesSlice = createSlice({
  name: 'trustees',
  initialState,
  reducers: {
    setAddressEdit: (state: TrusteesState, action: PayloadAction<number | null>) => {
      if (!!state.edit) {
        state.edit.addresses.edit = action.payload;
      }
    },
    clearAddressEdit: (state: TrusteesState) => {
      if (!!state.edit) {
        state.edit.addresses.edit = undefined;
      }
    },

    setDocumentAddMode: (state: TrusteesState) => {
      if (!!state.edit) {
        state.edit.documents.results.edit = null;
      }
    },

    setPhoneNumberAdd: (state: TrusteesState, action: PayloadAction<PhoneNumber>) => {
      if (!!state.edit) {
        state.edit.phoneNumbers.edit = action.payload.id;
        state.edit.phoneNumbers.items = [action.payload, ...state.edit.phoneNumbers.items];
      }
    },

    setEmailAddressAdd: (state: TrusteesState, action: PayloadAction<EmailAddress>) => {
      if (!!state.edit) {
        state.edit.emailAddresses.edit = action.payload.id;
        state.edit.emailAddresses.items = [action.payload, ...state.edit.emailAddresses.items];
      }
    },

    // contacts
    setContactEdit: (state: TrusteesState, action: PayloadAction<ContactDetails>) => {
      if (!!state.edit) {
        state.edit.contacts.edit.contact.edit = action.payload;
      }
    },
    clearContactEdit: (state: TrusteesState) => {
      if (!!state.edit) {
        state.edit.contacts.edit.contact.edit = initialState.edit?.contacts.edit.contact.edit;
      }
    },
    setContactAddressEdit: (state: TrusteesState, action: PayloadAction<number | null>) => {
      if (!!state.edit) {
        state.edit.contacts.edit.addresses.edit = action.payload;
      }
    },
    clearContactAddressEdit: (state: TrusteesState) => {
      if (!!state.edit) {
        state.edit.contacts.edit.addresses.edit = undefined;
      }
    },

    setContactEditDocumentsParameters: (state: TrusteesState, action: PayloadAction<DocumentsParameters>) => {
      if (!!state.edit) {
        state.edit.contacts.edit.documents = {
          parameters: action.payload,
          results: initialState.edit?.contacts.edit.documents.results as EditablePagedCollection<DocumentDetails>,
        };
      }
    },
    setContactDocumentAddMode: (state: TrusteesState) => {
      if (!!state.edit) {
        state.edit.contacts.edit.documents.results.edit = null;
      }
    },
    cancelContactDocumentAddEditMode: (state: TrusteesState) => {
      if (!!state.edit) {
        state.edit.contacts.edit.documents.results.edit = undefined;
      }
    },

    setContactEditIdentificationsParameters: (state: TrusteesState, action: PayloadAction<DocumentsParameters>) => {
      if (!!state.edit) {
        state.edit.contacts.edit.identifications = {
          parameters: action.payload,
          results: initialState.edit?.contacts.edit.identifications.results as EditablePagedCollection<DocumentDetails>,
        };
      }
    },
    setContactIdentificationAddMode: (state: TrusteesState) => {
      if (!!state.edit) {
        state.edit.contacts.edit.identifications.results.edit = null;
      }
    },
    cancelContactIdentificationAddEditMode: (state: TrusteesState) => {
      if (!!state.edit) {
        state.edit.contacts.edit.identifications.results.edit = undefined;
      }
    },

    setContactPhoneNumberEditId: (state: TrusteesState, action: PayloadAction<number | null | undefined>) => {
      if (!!state.edit) {
        state.edit.contacts.edit.phoneNumbers.edit = action.payload;
        // if we have a 'Create new item' row (id = null), then remove it.
        state.edit.contacts.edit.phoneNumbers.items = state.edit.contacts.edit.phoneNumbers.items.filter((p) => !!p.id);
      }
    },
    setContactPhoneNumberAdd: (state: TrusteesState, action: PayloadAction<PhoneNumber>) => {
      if (!!state.edit) {
        state.edit.contacts.edit.phoneNumbers.edit = action.payload.id;
        state.edit.contacts.edit.phoneNumbers.items = [action.payload, ...state.edit.contacts.edit.phoneNumbers.items];
      }
    },
    setContactEmailAddressEditId: (state: TrusteesState, action: PayloadAction<number | null | undefined>) => {
      if (!!state.edit) {
        state.edit.contacts.edit.emailAddresses.edit = action.payload;
        // if we have a 'Create new item' row (id = null), then remove it.
        state.edit.contacts.edit.emailAddresses.items = state.edit.contacts.edit.emailAddresses.items.filter((p) => !!p.id);
      }
    },
    setContactEmailAddressAdd: (state: TrusteesState, action: PayloadAction<EmailAddress>) => {
      if (!!state.edit) {
        state.edit.contacts.edit.emailAddresses.edit = action.payload.id;
        state.edit.contacts.edit.emailAddresses.items = [action.payload, ...state.edit.contacts.edit.emailAddresses.items];
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTrustees.pending, (state: TrusteesState) => {
      state.edit = initialState.edit;
    });
    builder.addCase(fetchTrustees.fulfilled, (state: TrusteesState, action: PayloadAction<TrusteeAbbreviated[]>) => {
      state.items = action.payload;
    });
    builder.addCase(fetchTrustee.fulfilled, (state: TrusteesState, action: PayloadAction<{ id: number; details: TrusteeDetails }>) => {
      if (!!state.edit) {
        state.edit.id = action.payload.id;
        state.edit.details = action.payload.details;
      }
    });
    builder.addCase(
      saveTrustee.fulfilled,
      (state, action: PayloadAction<{ trusteeId?: number; trustee?: TrusteeDetails; message?: string; variant?: string }>) => {
        if (!!action.payload.trusteeId && state.edit) {
          state.edit.id = action.payload.trusteeId;
          state.edit.details = action.payload.trustee || null;
        }
      }
    );
    builder.addCase(fetchTrusteesToAttach.fulfilled, (state, action: PayloadAction<AttachableTrustee[]>) => {
      if (!!state.edit) {
        state.edit.attachableTrustees = action.payload;
      }
    });

    builder.addCase(fetchAddresses.fulfilled, (state, action: PayloadAction<AddressDetails[]>) => {
      if (!!state.edit) {
        state.edit.addresses = { items: action.payload };
      }
    });

    builder.addCase(fetchDocuments.fulfilled, (state, action: PayloadAction<FetchPagedResults<DocumentDetails>>) => {
      if (!!state.edit?.documents) {
        state.edit.documents.results.items = action.payload.results;
      }
    });
    builder.addCase(fetchDocumentForEdit.fulfilled, (state, action: PayloadAction<DocumentDetails>) => {
      if (!!state.edit?.documents) {
        state.edit.documents.results.edit = action.payload;
      }
    });
    builder.addCase(cancelDocumentAddEditMode, (state) => {
      if (!!state.edit) {
        state.edit.documents.results.edit = undefined;
      }
    });
    builder.addCase(fetchContactDetails.fulfilled, (state, action: PayloadAction<ContactDetail[]>) => {
      if (!!state.edit) {
        state.edit.phoneNumbers.items = action.payload
          .filter((i) => !!PhoneNumberType.getById(i.contactDetailTypeId))
          .map((i) => {
            return {
              id: i.id,
              typeId: i.contactDetailTypeId,
              countryCode: i.countryCode,
              areaCode: i.areaCode,
              phoneNumber: i.phoneEmail,
              preferred: i.preferred,
            };
          });

        state.edit.emailAddresses.items = action.payload
          .filter((i) => !!EmailType.getById(i.contactDetailTypeId))
          .map((i) => {
            return {
              id: i.id,
              typeId: i.contactDetailTypeId,
              emailAddress: i.phoneEmail,
              preferred: i.preferred,
              clientPortalAccess: i.clientPortalAccess ?? false,
              hasMoneysoftAccess: i.hasMoneysoftAccess ?? false,
            };
          });
      }
    });
    builder.addCase(saveAddress.fulfilled, (state) => {
      if (!!state.edit) {
        state.edit.addresses.edit = undefined;
      }
    });
    builder.addCase(setPhoneNumberEditId, (state: TrusteesState, action: PayloadAction<number | null | undefined>) => {
      if (!!state.edit) {
        state.edit.phoneNumbers.edit = action.payload;
        // if we have a 'Create new item' row (id = null), then remove it.
        state.edit.phoneNumbers.items = state.edit.phoneNumbers.items.filter((p) => !!p.id);
      }
    });
    builder.addCase(setEmailAddressEditId, (state: TrusteesState, action: PayloadAction<number | null | undefined>) => {
      if (!!state.edit) {
        state.edit.emailAddresses.edit = action.payload;
        // if we have a 'Create new item' row (id = null), then remove it.
        state.edit.emailAddresses.items = state.edit.emailAddresses.items.filter((p) => !!p.id);
      }
    });

    // Contacts
    builder.addCase(fetchContacts.fulfilled, (state, action: PayloadAction<ContactRoles[]>) => {
      if (!!state.edit) {
        state.edit.contacts.items = action.payload;
        state.edit.contacts.edit = initialState.edit?.contacts.edit as Contact;
      }
    });
    builder.addCase(fetchContact.pending, (state) => {
      if (!!state.edit) {
        state.edit.contacts.edit = initialState.edit?.contacts.edit as Contact;
      }
    });
    builder.addCase(fetchContact.fulfilled, (state, action: PayloadAction<ContactDetails>) => {
      if (!!state.edit) {
        state.edit.contacts.edit.contact.edit = action.payload;
      }
    });
    builder.addCase(fetchContactsToAttach.fulfilled, (state, action: PayloadAction<AttachableContact[]>) => {
      if (!!state.edit) {
        state.edit.contacts.edit.contact.attachContactList = action.payload;
      }
    });
    builder.addCase(fetchContactAddresses.fulfilled, (state, action: PayloadAction<AddressDetails[]>) => {
      if (!!state.edit) {
        state.edit.contacts.edit.addresses = { items: action.payload };
      }
    });
    builder.addCase(fetchContactDocuments.fulfilled, (state, action: PayloadAction<FetchPagedResults<DocumentDetails>>) => {
      if (!!state.edit?.contacts.edit.documents) {
        state.edit.contacts.edit.documents.results.items = action.payload.results;
        state.edit.contacts.edit.documents.results.edit = undefined;
      }
    });
    builder.addCase(fetchContactDocumentForEdit.fulfilled, (state, action: PayloadAction<DocumentDetails>) => {
      if (!!state.edit) {
        state.edit.contacts.edit.documents.results.edit = action.payload;
      }
    });
    builder.addCase(fetchContactIdentifications.fulfilled, (state, action: PayloadAction<FetchPagedResults<DocumentDetails>>) => {
      if (!!state.edit?.contacts.edit.identifications) {
        state.edit.contacts.edit.identifications.results.items = action.payload.results;
      }
    });
    builder.addCase(fetchContactIdentificationForEdit.fulfilled, (state, action: PayloadAction<DocumentDetails>) => {
      if (!!state.edit?.contacts.edit.identifications) {
        state.edit.contacts.edit.identifications.results.edit = action.payload;
      }
    });
    builder.addCase(fetchContactContactDetails.fulfilled, (state, action: PayloadAction<ContactDetail[]>) => {
      if (!!state.edit?.contacts.edit) {
        state.edit.contacts.edit.phoneNumbers.items = action.payload
          .filter((i) => !!PhoneNumberType.getById(i.contactDetailTypeId))
          .map((i) => {
            return {
              id: i.id,
              typeId: i.contactDetailTypeId,
              countryCode: i.countryCode,
              areaCode: i.areaCode,
              phoneNumber: i.phoneEmail,
              preferred: i.preferred,
            };
          });

        state.edit.contacts.edit.emailAddresses.items = action.payload
          .filter((i) => !!EmailType.getById(i.contactDetailTypeId))
          .map((i) => {
            return {
              id: i.id,
              typeId: i.contactDetailTypeId,
              emailAddress: i.phoneEmail,
              preferred: i.preferred,
              clientPortalAccess: i.clientPortalAccess ?? false,
              hasMoneysoftAccess: i.hasMoneysoftAccess ?? false,
            };
          });
      }
    });
    builder.addCase(saveContactAddress.fulfilled, (state) => {
      if (!!state.edit?.contacts.edit) {
        state.edit.contacts.edit.addresses.edit = undefined;
      }
    });
  },
});
