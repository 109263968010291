import React from 'react';
import { Grid, Container } from '@mui/material';

export interface ClientDetails {
  clientName: string | undefined;
}

const PdfHeader = (props: ClientDetails) => {
  return (
    <Container style={{ display: 'inline-flex', paddingBottom: '20px' }}>
      <Grid container style={{ margin: '30px 30px 0 0' }}>
        <Grid item xs={12}>
          <div style={{ fontFamily: 'AvenirMedium', lineHeight: 1.167, fontSize: '22px', fontWeight: 'normal' }}>
            {props.clientName}
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};

export default PdfHeader;
