import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { RootState } from 'src/store';
import { selectClientId } from '../common/store';
import { Portfolios } from './components/portfolios';
import { fetchInvestmentServiceVersionsByClient } from './store/common';

const mapStateToProps = (state: RootState) => ({
  clientId: selectClientId(state),
});

interface MatchParams {
  investmentServiceId?: string;
}

const mapDispatchToProps = {
  fetchInvestmentServiceVersionsByClient,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector> & RouteComponentProps<MatchParams>;
export const PortfolioContainer = connect(mapStateToProps, mapDispatchToProps)(Portfolios);
