import { Search as SearchIcon } from '@mui/icons-material';
import { Box, Container, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import React, { useEffect } from 'react';
import history from '../../../../history';
import { PagedRequest } from '../../../../store';
import { theme } from '../../../../themes';
import { Props } from '../container';
import { SecuritiesCsvRow, SecurityItem } from '../store/types';
import { WO2TextInput } from './../../../../common';
import { SecurityListTable } from './securityListTable';

export const SecurityList = (props: Props): JSX.Element => {
  const { parameters, setSearchStringParameter, securityList, securityListProgress, fetchSecurityList, downloadSecurities } = props;

  useEffect(() => {
    fetchSecurityList(parameters);
  }, [fetchSecurityList, parameters]);

  const handleGridActions = (pagedRequest: PagedRequest) => {
    fetchSecurityList({
      pagination: pagedRequest,
      codeOrNameSearch: parameters.codeOrNameSearch,
    });
  };

  const handleSecurityClick = (securityId: number, securityCode: string) => {
    history.push(`/security/${securityId}/${securityCode}/dashboard`);
  };

  const [selectedTab, setValue] = React.useState(0);

  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Container style={{ paddingTop: '20px', paddingBottom: '20px' }}>
      <Tabs
        value={selectedTab}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleTabChange}
        style={{ paddingBottom: '30px' }}
        TabIndicatorProps={{ style: { height: '4px' } }}
      >
        <Tab label="Securities" href="/security/list" />
        <Tab label="Forex Rates" href="/forexrate/list" />
      </Tabs>
      <Box display={'flex'} justifyContent={'space-between'}>
        <Grid>
          <Typography
            style={{
              height: '48px',
              color: '#000a12',
              fontFamily: theme.typography.fontFamily,
              fontSize: '36px',
              fontWeight: 400,
              lineHeight: '48px',
              paddingRight: '20px',
              marginLeft: '11px',
            }}
          >
            Securities
          </Typography>
        </Grid>
        <Box display={'flex'} justifyContent={'space-between'} style={{ padding: '10px 0' }}>
          <Box display={'flex'} justifyContent={'space-between'}>
            <Box paddingRight={'10px'}>
              <WO2TextInput
                placeholder={'Security Search'}
                inputProps={{ 'aria-label': 'search' }}
                onChange={(event) => {
                  setSearchStringParameter(event.target.value as string);
                }}
                InputProps={{ startAdornment: <SearchIcon style={{ margin: '0 10px' }}>search</SearchIcon> }}
                value={props.parameters.codeOrNameSearch}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Grid container spacing={2} style={{ paddingTop: '15px', paddingLeft: '10px' }}>
        <SecurityListTable
          pagedRequest={parameters.pagination}
          pagedResults={securityList || undefined}
          handlePagedRequest={handleGridActions}
          handleRowClick={handleSecurityClick}
          progressSecurities={securityListProgress}
          handleDownload={(pagedRequest: PagedRequest, mapper: (securities: SecurityItem[]) => SecuritiesCsvRow[]) =>
            downloadSecurities({
              pagination: pagedRequest,
              codeOrNameSearch: parameters.codeOrNameSearch || '',
              mapper,
            })
          }
        ></SecurityListTable>
      </Grid>
    </Container>
  );
};
