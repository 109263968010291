export const roundUnitsByMarketCode = (units: number | null, marketCode: string): number => {
  let result = 0;
  if (units) {
    switch (marketCode.toLowerCase()) {
      case 'offmarket':
        result = Math.round(units * 1000000) / 1000000;
        break;
      case 'adi':
      case 'adm':
        result = Math.round(units * 100) / 100;
        break;
      default:
        result = Math.floor(units);
    }
  }
  return result;
};
export const decimalPlacesByMarketCode = (marketCode: string | undefined): number => {
  switch (marketCode) {
    case 'adi':
    case 'adm':
      return 2;
    case 'offmarket':
      return 6;
    default:
      return 0;
  }
};
export const formatNumberCommaSeparated = (num: number | null | undefined, decimalPlaces?: number): string => {
  return !!num || num === 0
    ? new Intl.NumberFormat('en-AU', {
        minimumFractionDigits: decimalPlaces,
        maximumFractionDigits: decimalPlaces,
      }).format(num)
    : '';
};

export const formatNumberCommaSeparatedForCSV = (val: number | null | undefined, decimalPlaces = 2) =>
  formatNumberCommaSeparated(val ?? 0, decimalPlaces) ?? '0.00';

export const roundDecimalToDigits = (num: number, decimalPlaces: number) => {
  const epsilon = Number.EPSILON ?? Math.pow(2, -52);
  const p = Math.pow(10, decimalPlaces || 0);
  const n = (num * p) * (1 + epsilon);
  return Math.round(n) / p;
};