/* eslint-disable @typescript-eslint/no-explicit-any */
import { DesktopDatePicker } from '@mui/lab';
import TextField from '@mui/material/TextField';
import { FieldInputProps, FormikProps, useField } from 'formik';
import { DateTime } from 'luxon';
import React from 'react';
import { DateTimeFormat } from 'src/common/utils';
import { FormikErrorMessage } from '.';

interface Props {
  disableCloseOnSelect?: boolean;
  showRequiredAsterisk?: boolean;
  fullWidth?: boolean;
  maxDate?: DateTime;
  field: FieldInputProps<any>;
  type: React.HTMLInputTypeAttribute | undefined;
  label: React.ReactNode;
  form: FormikProps<any>;
  style: any;
  onChange: any;
}

export const FormikKeyboardDatePicker = ({ showRequiredAsterisk, disableCloseOnSelect, fullWidth, ...props }: Props): JSX.Element => {
  const [field, , helper] = useField(props.field.name);
  const { label, style } = props;

  return (
    <div id={`${field.name}`} {...(!!style && { style })}>
      <DesktopDatePicker
        inputFormat={DateTimeFormat.Short.displayName}
        data-testid={field.name}
        disableCloseOnSelect={!!disableCloseOnSelect}
        {...props}
        {...field}
        {...(!!showRequiredAsterisk && !!label && { label: `* ${label}` })}
        onChange={(date: DateTime | null, keyboardInputValue?: string | undefined) => {
          helper.setValue(!!date && date.isValid ? date.startOf('day').toISODate() : !!date ? date.invalidReason : null);
          if (props.onChange) {
            props.onChange(!!date && date.isValid ? date.startOf('day').toISODate() : null, keyboardInputValue);
          }
        }}
        onError={(reason) => {
          helper.setError(reason?.toString());
        }}
        value={field.value || null}
        renderInput={(params) => (
          <TextField {...params} variant="outlined" fullWidth={!!fullWidth} InputLabelProps={{ shrink: true }} name={field.name} onBlur={field.onBlur} />
        )}
      ></DesktopDatePicker>
      <FormikErrorMessage name={`${field.name}`} data-testid={`${field.name}ErrorMessage`}></FormikErrorMessage>
    </div>
  );
};
