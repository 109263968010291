import { Backdrop, Box, Fade, Modal, Typography } from '@mui/material';
import React from 'react';
import WO2Button from 'src/common/components/button/Button';
import { ProgressButton } from 'src/common/components/progressButton';
import { LoadingProgress } from 'src/common/store/types';

export interface ProcessClientDialogProps {
  isOpen: boolean;
  saveProgress: LoadingProgress;
  handleClose: () => void;
  handleProceedButtonClick: () => void;
}

export const ProcessClientDialog = (props: ProcessClientDialogProps): JSX.Element => {
  const { isOpen, handleClose, handleProceedButtonClick, saveProgress } = props;

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isOpen}
        disableEscapeKeyDown
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={isOpen}>
          <Box
            style={{
              maxWidth: '550px',
              padding: '20px',
              backgroundColor: 'white',
              outline: 'none',
              borderRadius: '6px',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            <Typography variant="h4" gutterBottom>
              Are you sure you wish to submit the client?
            </Typography>
            <Box display={'flex'} marginTop={'40px'} flexDirection={'row'} justifyContent={'space-between'}>
              <WO2Button
                color={'primary'}
                variant={'outlined'}
                onClick={handleClose}
                value="Back"
                style={{ minWidth: '160px', height: '36px', marginRight: '20px' }}
                data-testid={'closeButton'}
              >
                Back
              </WO2Button>
              <ProgressButton
                type="submit"
                onClick={handleProceedButtonClick}
                variant={'contained'}
                progress={saveProgress}
                data-testid={'saveButton'}
                style={{ minWidth: '160px', height: '36px' }}
              >
                Proceed
              </ProgressButton>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};
