import InfoIcon from '@mui/icons-material/Info';
import { Backdrop, Container, Fade, Grid, Modal, Paper, Tooltip, Typography } from '@mui/material';
import { Field, FieldProps, Form, Formik, FormikProps } from 'formik';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { CalculatedValues, getCalcualtedValues } from 'src/features/clients/common/utils/calculation';
import * as yup from 'yup';
import { ToggleButtonItem, formatNumberCommaSeparated, formatPercentage } from '../../../../../../common';
import {
  ButtonType,
  EditCancelSaveButtons,
  FormikNumberFormat,
  FormikToggleButton,
  Mode,
} from '../../../../../../common/components/formik';
import { theme } from '../../../../../../themes';
import { TieredValidateResultTypes } from '../../../../common/config/tieredValidations';
import { FeeCalculationType, FeeFrequency, FeeMethod } from '../../../../common/enums';
import { FeeDetailsValues, TieredFeeDetails } from '../../../../common/types';
import { AdviceEstimatedFee, AdviceTieredFeeDetails, FeeSourceType } from '../store/types';
import { TieredTable } from './tiered/tieredTable';

export interface AdviceFeeDetailsEditProps {
  unavailableFeeCalculationTypes: number[];
  estimatedFeesItems: AdviceEstimatedFee[];
  selectedFeeItem: AdviceEstimatedFee | null | undefined;
  tieredFeeDetailsItems: AdviceTieredFeeDetails[];
  selectedTieredFeeDetailsEdit: number | null | undefined;
  isViewMode: boolean;
  totalRolloverAmount: number;
  contributionsAmount: number;
  marketValue: number;
  availableCash: number;
  isActive: boolean | undefined;
  isSuperOrPension: boolean | undefined;
  onSave: (feeDetails: AdviceEstimatedFee) => void;
  onCancel: () => void;
  handleCloseModal: () => void;
  setTieredFeeDetailsEditId: (id: number | null | undefined) => void;
  setTieredFeeDetailsAdd: (tieredFee: AdviceTieredFeeDetails) => void;
  saveEditingTieredFeeDetails: (tieredFee: AdviceTieredFeeDetails) => void;
  removeEditingTieredFeeDetails: (id: number) => void;
  resetSelectedFeeFields: boolean;
  getPendingFeeItems: () => void;
}

export const AdviceFeeDetailsEdit = (props: AdviceFeeDetailsEditProps): JSX.Element => {
  const {
    unavailableFeeCalculationTypes,
    selectedFeeItem,
    tieredFeeDetailsItems,
    selectedTieredFeeDetailsEdit,
    isViewMode,
    totalRolloverAmount,
    contributionsAmount,
    marketValue,
    availableCash,
    isActive,
    isSuperOrPension,
    handleCloseModal,
    onSave,
    onCancel,
    setTieredFeeDetailsEditId,
    setTieredFeeDetailsAdd,
    saveEditingTieredFeeDetails,
    removeEditingTieredFeeDetails,
    resetSelectedFeeFields,
    getPendingFeeItems,
  } = props;

  const formRef = useRef<FormikProps<FeeDetailsValues>>(null);
  const [selectedFeeItemId, setSelectedFeeItemId] = useState<AdviceEstimatedFee | null | undefined>(
    selectedFeeItem ?? null
  );
  const [selectedCalculationTypeId, setSelectedCalculationTypeId] = useState<number | null>(
    selectedFeeItemId?.calculationTypeId ?? null
  );
  const [selectedCalculationMethodId, setSelectedCalculationMethodId] = useState<number | null>(
    selectedFeeItemId?.methodId ?? null
  );
  const [selectedFrequencyId, setSelectedFrequencyId] = useState<number | null>(selectedFeeItemId?.frequencyId ?? null);
  const [calculatedValues, setCalculatedValues] = useState<CalculatedValues | null>(null);
  const [tieredValidatedError, setTieredValidatedError] = useState<number | null>(null);

  useEffect(() => {
    setCalculatedValues(
      getCalcualtedValues(selectedCalculationTypeId, isActive === true, isSuperOrPension === true, {
        availableCash: availableCash,
        contributionsAmount: contributionsAmount,
        marketValue: marketValue,
        totalRolloverAmount: totalRolloverAmount,
      })
    );
  }, [selectedCalculationTypeId, setCalculatedValues]);

  const FeeCalculationTypeArray = isSuperOrPension
    ? FeeCalculationType.getArray()
    : FeeCalculationType.getArray().splice(0, 3);

  const feeTypeToggleButtons: ToggleButtonItem<number>[] = FeeCalculationTypeArray.map((fee: FeeCalculationType) => {
    return { name: fee.displayName, value: fee.id, disabled: unavailableFeeCalculationTypes.includes(fee.id) };
  });

  const restrictedFeeCalculationTypes =
    !resetSelectedFeeFields && !!selectedFeeItem
      ? selectedFeeItem.methodId === FeeMethod.Tiered.id
        ? [FeeMethod.Dollar, FeeMethod.Percentage]
        : [FeeMethod.Tiered]
      : [];

  useEffect(() => {
    if (resetSelectedFeeFields) {
      onCreateResetFields();
    }
  }, []);

  const FeeMethodToggleButtons: ToggleButtonItem<number>[] = FeeMethod.getArray().map(
    (calculationMethod: FeeMethod) => {
      if (selectedCalculationTypeId === FeeCalculationType.AdviserInsuranceFee.id) {
        restrictedFeeCalculationTypes.push(FeeMethod.Dollar);
        restrictedFeeCalculationTypes.push(FeeMethod.Tiered);
      }
      if (selectedCalculationTypeId === FeeCalculationType.UpfrontAdviceFee.id) {
        restrictedFeeCalculationTypes.push(FeeMethod.Tiered);
      }
      return {
        name: calculationMethod.displayName,
        value: calculationMethod.id,
        disabled: restrictedFeeCalculationTypes.includes(calculationMethod),
      };
    }
  );

  const FeeFrequencyToggleButtons: ToggleButtonItem<number>[] = FeeFrequency.getArray().map(
    (frequency: FeeFrequency) => {
      return { name: frequency.displayName, value: frequency.id };
    }
  );

  const feeAmountFrequencyTooltipTitle: React.ReactNode = (
    <ul style={{ padding: '10px 10px 10px 20px' }}>
      <li>Fees are charged monthly in arrears.</li>
      <li>
        Entering a per annum amount will mean fees are charged based on the amount of days in the corresponding month.
      </li>
      <li>Entering a per month fee will mean the fees charged each month are the same value.</li>
    </ul>
  );

  const formValues: FeeDetailsValues = {
    templateCode: selectedFeeItem?.templateCode ?? null,
    name: selectedFeeItem?.name ?? '',
    feeSourceId: FeeSourceType.Client.id,
    calculationTypeId: selectedCalculationTypeId,
    methodId: selectedCalculationMethodId,
    frequencyId: selectedFrequencyId,
    amount: formRef?.current?.values.amount ?? selectedFeeItem?.amount ?? null,
    percentageOfValue: formRef?.current?.values.percentageOfValue ?? selectedFeeItem?.percentageOfValue ?? null,
    tieredFeeDetails: { items: tieredFeeDetailsItems },
  };

  const validateTieredFeeDetails = (): TieredValidateResultTypes => {
    let result = TieredValidateResultTypes.Success;
    const currentFormValues = formRef?.current?.values;

    if (!!currentFormValues && Array.isArray(currentFormValues.tieredFeeDetails.items)) {
      if (currentFormValues.tieredFeeDetails.items.length === 0) {
        return TieredValidateResultTypes.NoTieredItem;
      }

      const currentTieredFeeDetailsItems = currentFormValues.tieredFeeDetails.items;
      const firstFromValueOverlapItemIndex =
        currentTieredFeeDetailsItems.length > 1
          ? currentTieredFeeDetailsItems.findIndex((item: TieredFeeDetails, index: number) => {
              return (
                index + 1 < currentTieredFeeDetailsItems.length &&
                parseFloat(((item?.to || 0) + 0.01).toFixed(2)) !== currentTieredFeeDetailsItems[index + 1].from
              );
            })
          : -1;

      if (currentTieredFeeDetailsItems[0].from > 0) {
        result = TieredValidateResultTypes.FirstFromValueNotZero;
      } else if (firstFromValueOverlapItemIndex >= 0) {
        result =
          (currentTieredFeeDetailsItems[firstFromValueOverlapItemIndex]?.to ?? 0) >
          currentTieredFeeDetailsItems[firstFromValueOverlapItemIndex + 1].from
            ? TieredValidateResultTypes.ValuesOverlap
            : TieredValidateResultTypes.GapBetweenValues;
      }
    }

    return result;
  };

  const onSaveTieredFeeDetails = (tieredFeeDetails: AdviceTieredFeeDetails) => {
    if (!tieredFeeDetails.percentage && !tieredFeeDetails.amount) {
      tieredFeeDetails.amount = 0;
    }
    // clear validation result if exists
    setTieredValidatedError(null);
    saveEditingTieredFeeDetails(tieredFeeDetails);
  };

  const onDeleteTieredFeeDetails = (id: number) => {
    // clear validation result if exists
    setTieredValidatedError(null);
    removeEditingTieredFeeDetails(id);
  };

  const handleCancelClick = useCallback(() => {
    onCreateResetFields();
    onCancel();
    handleCloseModal();
  }, [onCancel, handleCloseModal]);

  const onCreateResetFields = () => {
    // clear existing field if exists
    getPendingFeeItems();
    setSelectedCalculationTypeId(null);
    setTieredValidatedError(null);
    setSelectedCalculationMethodId(null);
    setSelectedFrequencyId(null);
    setSelectedFeeItemId(null);
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={true}
        onClose={(_, reason) => {
          if (reason !== 'backdropClick') {
            handleCloseModal();
          }
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={true}>
          <Paper
            elevation={0}
            style={{
              width: '1060px',
              padding: '40px',
              maxHeight: '100%',
              overflow: 'auto',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            <Formik<FeeDetailsValues>
              data-testid={'adviceFeeDetailsEditForm'}
              enableReinitialize={true}
              initialValues={formValues}
              innerRef={formRef}
              validationSchema={yup.object({
                calculationTypeId: yup.number().nullable().required('Fee Type is required'),
                methodId: yup.number().nullable().required('Calculation Method is required'),
                frequencyId: yup
                  .number()
                  .nullable()
                  .when(['calculationTypeId', 'methodId'], {
                    is: (calculationTypeId, methodId) =>
                      calculationTypeId === FeeCalculationType.OngoingAdviceFee.id && methodId === FeeMethod.Dollar.id,
                    then: yup.number().required('Fee Amount Frequency is required'),
                  }),
                amount: yup
                  .number()
                  .nullable()
                  .when('methodId', {
                    is: (methodId) => methodId === FeeMethod.Dollar.id,
                    then: yup
                      .number()
                      .required('Fee Amount is required')
                      .moreThan(0, 'Fee Amount should be a positive number')
                      .lessThan(1000000, 'Fee Amount should be less than $1,000,000.00')
                      .max(
                        calculatedValues?.maxAmount ?? 0,
                        `Fee Amount should be less then or equal to \$${formatNumberCommaSeparated(
                          calculatedValues?.maxAmount
                        )}`
                      )
                      .when('frequencyId', {
                        is: (frequencyId) => frequencyId === FeeFrequency.PerMonth.id,
                        then: yup
                          .number()
                          .max(
                            (calculatedValues?.maxAmount ?? 0) / 12,
                            `Fee Amount should be less then or equal to \$${formatNumberCommaSeparated(
                              (calculatedValues?.maxAmount ?? 0) / 12
                            )}`
                          ),
                      }),
                  }),
                percentageOfValue: yup
                  .number()
                  .nullable()
                  .when(['calculationTypeId', 'methodId'], {
                    is: (calculationTypeId, methodId) => !!calculationTypeId && methodId === FeeMethod.Percentage.id,
                    then: yup
                      .number()
                      .required('Fee Percentage is required')
                      .moreThan(0, 'Fee Percentage should be a positive number')
                      .lessThan(99, 'Fee Percentage should be less than 100%')
                      .max(
                        calculatedValues?.maxPercentage ?? 0,
                        `Fee Percentage must be less then or equal to ${formatPercentage(
                          (calculatedValues?.maxPercentage ?? 0) / 100
                        )}`
                      ),
                  }),
              })}
              onSubmit={async (feeDetails: FeeDetailsValues) => {
                if (selectedCalculationMethodId === FeeMethod.Tiered.id) {
                  const result = validateTieredFeeDetails();
                  if (result !== TieredValidateResultTypes.Success) {
                    setTieredValidatedError(result);
                    return;
                  }
                }
                if (formRef?.current?.isValid) {
                  onSave({
                    feeId: selectedFeeItem?.feeId || 0,
                    index: selectedFeeItem?.index || 0,
                    templateCode: null,
                    name: FeeCalculationType.getById(feeDetails.calculationTypeId ?? 0)?.displayName ?? '',
                    calculationTypeId: feeDetails.calculationTypeId ?? 0,
                    methodId: feeDetails.methodId ?? 0,
                    frequencyId:
                      feeDetails.calculationTypeId === FeeCalculationType.OngoingAdviceFee.id &&
                      feeDetails.methodId === FeeMethod.Dollar.id &&
                      !!feeDetails.frequencyId
                        ? feeDetails.frequencyId
                        : FeeFrequency.NotDefined.id,
                    amount: feeDetails.amount ?? 0,
                    percentageOfValue: feeDetails.percentageOfValue ?? 0,
                    tieredFeeDetails: {
                      items: feeDetails.methodId === FeeMethod.Tiered.id ? tieredFeeDetailsItems : [],
                    },
                    statusId: 0,
                    isPending: false,
                    pendingAction: '',
                    isValid: false,
                    hasEverBeenActive: false,
                    isUserDefined: true,
                    feeSource: '',
                    feeSourceId: 0,
                  });
                  handleCloseModal();
                }
              }}
            >
              {(formikProps: FormikProps<FeeDetailsValues>) => (
                <Form>
                  <fieldset
                    style={{ border: 'none', margin: '0', padding: '0', pointerEvents: isViewMode ? 'none' : 'auto' }}
                  >
                    <Grid container style={{ marginBottom: '10px' }}>
                      <Typography variant="h4">Fee Type</Typography>
                      <Grid item xs={12} style={{ minHeight: '90px' }}>
                        <Field
                          exclusive={true}
                          component={FormikToggleButton}
                          buttons={feeTypeToggleButtons}
                          formik={formikProps}
                          name={'calculationTypeId'}
                          style={{ width: '980px' }}
                          fullWidth
                          onChangeHandler={(value: number) => {
                            if (value === FeeCalculationType.AdviserInsuranceFee.id) {
                              setSelectedCalculationMethodId(FeeMethod.Percentage.id);
                            }
                            if (value === FeeCalculationType.UpfrontAdviceFee.id) {
                              setSelectedCalculationMethodId(null);
                            }
                            setSelectedCalculationTypeId(value);
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid container style={{ marginBottom: '10px', alignContent: 'flex-start' }}>
                      <Typography variant="h4">Calculation Methodology</Typography>
                      <Grid item xs={12} style={{ minHeight: '90px' }}>
                        <Field
                          exclusive={true}
                          component={FormikToggleButton}
                          buttons={FeeMethodToggleButtons}
                          formik={formikProps}
                          name={'methodId'}
                          style={{ width: '780px' }}
                          fullWidth
                          onChangeHandler={(value: number) => {
                            setSelectedCalculationMethodId(value);
                          }}
                        />
                      </Grid>
                      {formikProps.values.calculationTypeId === FeeCalculationType.OngoingAdviceFee.id &&
                        formikProps.values.methodId === FeeMethod.Dollar.id && (
                          <Grid container style={{ marginBottom: '10px' }}>
                            <Container style={{ display: 'flex', alignItems: 'center', padding: '0' }}>
                              <Typography variant="h4">Fee Amount Frequency</Typography>
                              <Tooltip title={feeAmountFrequencyTooltipTitle} placement={'right'} arrow>
                                <InfoIcon
                                  style={{
                                    width: '18px',
                                    height: '18px',
                                    marginLeft: '4px',
                                    fill: `${theme.palette.primary.main}`,
                                  }}
                                />
                              </Tooltip>
                            </Container>
                            <Grid item xs={12}>
                              <Field
                                exclusive={true}
                                component={FormikToggleButton}
                                buttons={FeeFrequencyToggleButtons}
                                formik={formikProps}
                                name={'frequencyId'}
                                style={{ width: '780px' }}
                                fullWidth
                                onChangeHandler={(value: number) => {
                                  setSelectedFrequencyId(value);
                                }}
                              />
                            </Grid>
                            <Typography variant="body1" style={{ fontStyle: 'italic' }}>
                              * All fee amounts are to be entered inclusive of GST.
                            </Typography>
                          </Grid>
                        )}
                      {formikProps.values.methodId === FeeMethod.Dollar.id && (
                        <Grid item xs={4} style={{ marginTop: '10px', minHeight: '70px' }}>
                          <Field name="amount" label="FEE AMOUNT" fullWidth>
                            {(fieldProps: FieldProps) => {
                              return (
                                <FormikNumberFormat
                                  formikFieldProps={fieldProps}
                                  numberFormatProps={{
                                    placeholder: '$0.00',
                                    isNumericString: true,
                                    allowNegative: false,
                                    decimalScale: 2,
                                    thousandSeparator: true,
                                    prefix: '$',
                                    name: fieldProps.field.name,
                                    label: 'FEE AMOUNT ($)',
                                    isAllowed: (values) =>
                                      !values.floatValue || (values.floatValue > 0 && values.floatValue < 1000000),
                                  }}
                                  isFloatValue={true}
                                  showRequiredAsterisk={true}
                                  fullWidth={true}
                                />
                              );
                            }}
                          </Field>
                        </Grid>
                      )}
                      {formikProps.values.methodId === FeeMethod.Percentage.id && (
                        <Grid item xs={4} style={{ marginTop: '10px', minHeight: '70px' }}>
                          <Field name="percentageOfValue" label="FEE AMOUNT" fullWidth>
                            {(fieldProps: FieldProps) => {
                              return (
                                <FormikNumberFormat
                                  formikFieldProps={fieldProps}
                                  numberFormatProps={{
                                    placeholder: '0.0000%',
                                    isNumericString: true,
                                    allowNegative: false,
                                    decimalScale: 4,
                                    thousandSeparator: true,
                                    isAllowed: (values) =>
                                      !values.floatValue || (values.floatValue > 0 && values.floatValue < 100),
                                    suffix: '%',
                                    name: fieldProps.field.name,
                                    label: 'FEE AMOUNT (%)',
                                  }}
                                  isFloatValue={true}
                                  showRequiredAsterisk={true}
                                  fullWidth={true}
                                />
                              );
                            }}
                          </Field>
                        </Grid>
                      )}
                    </Grid>
                  </fieldset>
                </Form>
              )}
            </Formik>
            {selectedCalculationMethodId === FeeMethod.Tiered.id && (
              <TieredTable
                items={tieredFeeDetailsItems}
                editId={selectedTieredFeeDetailsEdit}
                tieredValidatedError={tieredValidatedError}
                isViewMode={isViewMode}
                isSelectedFee={!!selectedFeeItemId}
                progress={{ isLoading: false, hasErrors: false }}
                onSave={onSaveTieredFeeDetails}
                onDelete={onDeleteTieredFeeDetails}
                onSelectEditId={setTieredFeeDetailsEditId}
                onStartAddItem={setTieredFeeDetailsAdd}
              />
            )}
            <EditCancelSaveButtons
              mode={Mode.CancelSave}
              saveButtonIsNotSubmit={true}
              handleCancelClick={handleCancelClick}
              handleSaveClick={async () => {
                formRef?.current?.handleSubmit();
              }}
              disabledButtonTypes={
                isViewMode ||
                (selectedCalculationMethodId === FeeMethod.Tiered.id && selectedTieredFeeDetailsEdit !== undefined)
                  ? ButtonType.Save
                  : undefined
              }
            />
          </Paper>
        </Fade>
      </Modal>
    </div>
  );
};
