import { Alert, Box, FormControl, FormControlLabel, Paper, Radio, RadioGroup, Stack, Typography } from '@mui/material';
import React from 'react';
import { InitiateAccountApplicationPayload } from 'src/features/clients/client/details/accounts/store/types';
import { WO2Checkbox } from '../../Checkbox';
import { WO2Modal } from '../../Modal';
import { AccountInstitutionType, AttachableCmaAccount } from '../types';

export interface Props {
  onClose: () => void;
  submitAccountId: number | null;
  onSubmit: (selectedCmaAccount: InitiateAccountApplicationPayload) => void;
  attachableCmaAccounts: AttachableCmaAccount[];
  clientId: number | null | undefined;
}

export function UXchangeAccount({
  attachableCmaAccounts,
  submitAccountId,
  clientId,
  onClose,
  onSubmit,
}: Props): JSX.Element {
  const [acknowledgedTandC, setAcknowledgedTandC] = React.useState<boolean>(false);
  const [acknowledgedText, setAcknowledgedText] = React.useState('');
  const [helperText, setHelperText] = React.useState('');
  const [acknowledgedROA, setAcknowledgedROA] = React.useState<boolean>(false);
  const [value, setValue] = React.useState<string | null>(null);

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    setHelperText('');
  };

  return (
    <WO2Modal
      MuiDialogProps={{
        open: true,
        onClose: () => {
          onClose();
        },
        maxWidth: 'sm',
      }}
      title={<b>Acknowledgement</b>}
      actions={[
        {
          label: 'Close',
          onClickHandler: () => {
            onClose();
          },
          type: 'contained',
          disabled: false,
        },
        {
          label: 'Submit',
          onClickHandler: () => {
            if (acknowledgedTandC && submitAccountId && clientId && value) {
              const account = attachableCmaAccounts[Number(value)];
              onSubmit({
                pendingAccountId: submitAccountId.toString(),
                clientId,
                cmaSubNumber: account.bsb,
                cmaName: account.name,
                cmaNumber: account.number,
                forceRoa: acknowledgedROA,
              });
              !!onClose && onClose();
            }
            if (!acknowledgedTandC) {
              setAcknowledgedText('* Acknowledgement of Terms and Conditions is required.');
            }
            if (!value) {
              setHelperText('* Please select an option.');
            }
          },
          type: 'contained',
          disabled: !attachableCmaAccounts || attachableCmaAccounts.length === 0,
        },
      ]}
    >
      <>
        <Typography variant="h4" style={{ fontWeight: 700, paddingTop: '10px' }}>
          Are you sure you want to create this account?
        </Typography>
        <p>The application is ready to be submitted. Please ensure the application is correct before processing.</p>

        <Typography variant="h4" style={{ fontWeight: 700, paddingTop: '10px' }}>
          Link CMA
        </Typography>

        <Stack data-testid="attachableCmaAccountsBox" direction="column" spacing={2}>
          {attachableCmaAccounts && attachableCmaAccounts.length > 0 ? (
            <FormControl component="fieldset" variant="standard">
              <RadioGroup
                row
                aria-label="gender"
                name="row-radio-buttons-group"
                value={value}
                onChange={handleRadioChange}
              >
                {attachableCmaAccounts.map((account, index) => (
                  <Stack
                    data-testid="attachableCmaAccountsComponent"
                    key={index}
                    spacing={2}
                    direction="row"
                    padding={'6px'}
                  >
                    <Box width="50px" paddingTop="12px" paddingBottom="12px">
                      <FormControlLabel
                        value={index ?? 0}
                        control={<Radio name={`attachableCmaAccountRadio${index}`} />}
                        label=""
                        style={{ margin: '0' }}
                      />
                    </Box>
                    <Paper variant={'outlined'} style={{ padding: '12px', marginLeft: '0' }}>
                      <Stack key={index} spacing={2} direction="row">
                        <img
                          src={AccountInstitutionType.MacquarieCma.getLogo()}
                          alt={`${AccountInstitutionType.ManagedFunds.displayName} logo`}
                          height="48"
                        />
                        <div>
                          <Typography variant="h5">BSB: {account.bsb}</Typography>
                          <Typography variant="h5">number: {account.number}</Typography>
                        </div>
                      </Stack>
                    </Paper>
                  </Stack>
                ))}
              </RadioGroup>
            </FormControl>
          ) : (
            <Alert variant="outlined" severity="warning">
              You must have a CMA application submitted or completed to submit other new accounts. Please setup a CMA
              before continuing.
            </Alert>
          )}
          {attachableCmaAccounts && attachableCmaAccounts.length > 0 && !value && (
            <Typography variant="body2" color="red" data-testid="helperText">
              {helperText}
            </Typography>
          )}
        </Stack>

        <Typography variant="h4" style={{ fontWeight: 700, padding: '10px 0' }}>
          Acknowledgements
        </Typography>

        <Stack direction="row" spacing={2}>
          <Box width="50px">
            <WO2Checkbox
              name="termsAndConds"
              color="primary"
              checked={acknowledgedTandC}
              onChangeHandler={setAcknowledgedTandC}
            />
          </Box>
          <Typography variant="body2" style={{ marginBottom: '24px ' }}>
            I hereby declare that I am not a US Person as defined in the PDS; that the applicant is not a US Person as
            defined in the PDS, and I have completed an appropriate customer identification procedure (CID) on this
            investor which meets the AML/CTF Act. I have provided the latest uXchange PDS to the applicant who has read,
            accepted and understood the terms.
          </Typography>
        </Stack>

        {!acknowledgedTandC && (
          <Typography data-testid="acknowledgedText" variant="body2" color="red">
            {acknowledgedText}
          </Typography>
        )}

        <Stack direction="row" spacing={2}>
          <Box width="50px">
            <WO2Checkbox name="isROA" value={acknowledgedROA} onChangeHandler={setAcknowledgedROA} />
          </Box>
          <Typography variant="body2" style={{ marginBottom: '24px ' }}>
            This is an ROA client and I want DASH to send this client a uXchange application form for digial consent.
          </Typography>
        </Stack>
      </>
    </WO2Modal>
  );
}
