import { Checkbox, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import React from 'react';
import { NumberFormatValues } from 'react-number-format';
import { Field, FieldProps } from 'formik';
import { FxDailyRateCsvRow, FxDailyRateItem } from '../store/types';
import { DateTime } from 'luxon';
import * as yup from 'yup';
import { InlineEditColumnSet, InlineServerSideEditBaseProps, InlineServerSideEditColumn, InlineServerSideEditDataTable } from 'src/common/components/dataTable/serverSide';
import { DateTimeFormat, formatNumberCommaSeparated, getLocalDateTime } from 'src/common';
import { FormikKeyboardDatePicker, FormikNumberFormat } from 'src/common/components/formik';
import { PagedRequest, PagedResult } from 'src/store';
import { LoadingProgress } from 'src/common/store/types';

export interface Props {
  pagedResults?: PagedResult<FxDailyRateItem>;
  pagedRequest: PagedRequest;
  handlePagedRequest: (pagedRequest: PagedRequest) => void;
  loadingProgress: LoadingProgress;
  onSelectEditId: (id: number | null | undefined) => void;
  onStartAddItem: (newItem: FxDailyRateItem) => void;
  onSave: (item: FxDailyRateItem) => void;
  onDelete: (id: number) => void;
  isViewMode: boolean;
  handleDownload: (
    pagedRequest: PagedRequest,
    mapper: (dailyRateItemes: FxDailyRateItem[]) => FxDailyRateCsvRow[]
  ) => void;
}

export type DailyRatesEditTableProps = InlineServerSideEditBaseProps<FxDailyRateItem> & Props;
export const FxRateListTable = ({
  pagedRequest,
  pagedResults,
  handlePagedRequest,
  onSelectEditId,
  editId,
  loadingProgress,
  onStartAddItem,
  onSave,
  onDelete,
  isViewMode,
}: DailyRatesEditTableProps): JSX.Element => {
  const getNumberFormatPropsValues = (fieldName: string, isFieldDisabled = false) => {
    const commonNumberFormatPropsValue = {
      isNumericString: true,
      allowNegative: false,
      fixedDecimalScale: true,
      decimalScale: 8,
      thousandSeparator: true,
      disabled: isFieldDisabled,
      name: fieldName,
      label: '',
      fullWidth: true,
    };
    return {
      ...commonNumberFormatPropsValue,
      placeholder: '$0.00000000',
      prefix: '$',
    };
  };

  const amountTemplate = (): InlineEditColumnSet => ({
    standardTemplate: function standardTemplate(dataIndex: number) {
      return <Typography variant={'h5'}>{`$${formatNumberCommaSeparated(pagedResults?.results[dataIndex]?.currencyRate ?? 0, 8)}`}</Typography>
    },
    editTemplate: function editTemplate(dataIndex: number) {
      return (
        <Field
          name="currencyRate"
          label="Rate"
          fullWidth
          value={pagedResults?.results[dataIndex]?.currencyRate}
          showRequiredAsterisk
        >
          {(fieldProps: FieldProps) => {
            return (
              <FormikNumberFormat
                formikFieldProps={fieldProps}
                numberFormatProps={{
                  ...getNumberFormatPropsValues(fieldProps.field.name),
                  onValueChange: (val: NumberFormatValues) => {
                    if (val.floatValue !== undefined) {
                      // clear percentage value
                    }
                  },
                }}
                isFloatValue={true}
              />
            );
          }}
        </Field>
      );
    },
  });

  const dateTemplate = (): InlineEditColumnSet => ({
    standardTemplate: function standardTemplate(dataIndex: number) {
      return (
        <Typography variant={'h5'}>{`${getLocalDateTime(
          pagedResults?.results[dataIndex]?.date,
          DateTimeFormat.Short
        )}`}</Typography>
      );
    },
    editTemplate: function editTemplate(dataIndex: number) {
      return (
        <Field
          disabled={(pagedResults?.results[dataIndex]?.id ?? 0) > 0}
          component={FormikKeyboardDatePicker}
          name="date"
          label="DATE"
          maxDate={DateTime.now()}
          value={pagedResults?.results[dataIndex]?.date}
          showRequiredAsterisk
          fullWidth
        ></Field>
      );
    },
  });

  const manuallyOverridenTemplate = (): InlineEditColumnSet => ({
    standardTemplate: function standardTemplate(dataIndex: number) {
      return (
        <Field
          disabled
          name='manuallyOverriden'
          label='MANUAL OVERRIDE'
          component={Checkbox}
          checked={pagedResults?.results[dataIndex]?.manuallyOverriden ?? false}
        />
      );
    },
    editTemplate: function editTemplate(dataIndex: number) {
      return (
        <Field
          name='manuallyOverriden'
          label='MANUAL OVERRIDE'
          checked={pagedResults?.results[dataIndex]?.manuallyOverriden}
        >
          {(fieldProps: FieldProps) => {
            return (
              <Checkbox
                {...fieldProps.field} 
                checked={fieldProps.field.value}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  fieldProps.form.setFieldValue(fieldProps.field.name, event.currentTarget.checked); 
                }}
              />
            );
          }}
        </Field>
      )
    }
  })

  const columns: InlineServerSideEditColumn[] = [
    {
      name: 'date',
      label: 'DATE',
      templates: dateTemplate(),
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'rate',
      label: 'RATE',
      textAlign: 'right',
      templates: amountTemplate(),
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'manuallyOverriden',
      label: 'MANUAL OVERRIDE',
      templates: manuallyOverridenTemplate(),
      textAlign: 'center',
      verticalAlign: 'middle',
      options: {
        filter: false,
        sort: false
      }
    }
  ];

  return (
    <Grid container style={{ width: '100%', margin: '10px' }}>
      <Grid item xs={12}>
        <InlineServerSideEditDataTable<FxDailyRateItem>
          hideAllActions={isViewMode}
          pagedRequest={pagedRequest}
          pagedResult={pagedResults}
          handlePagedRequest={handlePagedRequest}
          options={{
            filter: false,
            sort: false,
            viewColumns: false,
            pagination: true,
            rowsPerPage: 25,
          }}
          columns={columns}
          loadingProgress={loadingProgress}
          deleteOptions={{
            dialogTitle: 'Delete Rate',
            dialogDescription: 'Are you sure you want to delete this rate? This action is permanent.',
            dialogDescription2: 'Please ensure you have selected the correct record before processing.',
          }}
          newItem={{
            id: 0,
            date: DateTime.now().toISODate(),
            baseCurrencyId: 5,
            quoteCurrencyId: 5,
            manuallyOverriden: false,
            currencyRate: null,
          }}
          onSave={onSave}
          onDelete={onDelete}
          onSelectEditId={onSelectEditId}
          onSetItemAdd={onStartAddItem}
          editId={editId}
          canDelete={() => true}
          validationSchema={yup.object({
            date: yup.string().required('Date required'),
            currencyRate: yup
              .number()
              .transform((value) => (Number.isNaN(value) ? null : value))
              .nullable()
              .required('Rate is required'),
          })}
        />
      </Grid>
    </Grid>
  );
};
