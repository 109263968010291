import {
  Alert,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React from 'react';
import { formatNumberCommaSeparated } from 'src/common';
import { ResultWrapper } from '../../benefitStatement/store/types';
import { PremiumDetails } from '../store/types';

export interface Props {
  details: ResultWrapper<PremiumDetails | null>;
}

export const InsurancePremiumDetails = ({ details }: Props): JSX.Element => {
  return (
    <>
      <Typography variant="h4" style={{ padding: '20px 0' }}>
        <b>Premium Details</b>
      </Typography>

      {details.status === 200 ? (
        <Paper elevation={5} style={{ marginBottom: '30px' }}>
          <TableContainer component={Paper} elevation={0}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="left" style={{ width: '70%' }}>
                    <Typography variant="h6" color="textSecondary">
                      COVER TYPE
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="h6" color="textSecondary">
                      PREMIUM ($)
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="h6" color="textSecondary">
                      PREMIUM FREQUENCY
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="left">
                    <Typography variant="h5">Death</Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography data-testid="deathPremiumData" variant="h5">
                      ${`${formatNumberCommaSeparated(details?.responseData?.deathPremium || 0, 2)}`}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography data-testid="premiumFrequencyDeathData">
                      {details?.responseData?.premiumFrequency}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">
                    <Typography variant="h5">Total Permanent Disability (TPD)</Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography data-testid="tpdPremiumData" variant="h5">
                      ${`${formatNumberCommaSeparated(details?.responseData?.tpdPremium || 0, 2)}`}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography data-testid="premiumFrequencyTPDData">
                      {details?.responseData?.premiumFrequency}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">
                    <Typography variant="h5">Income Protection (IP)</Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography data-testid="totalPremiumData" variant="h5">
                      ${`${formatNumberCommaSeparated(details?.responseData?.totalIPPremium || 0, 2)}`}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography data-testid="premiumFrequencyICData">
                      {details?.responseData?.premiumFrequency}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      ) : (
        <Alert
          style={{ marginRight: '20px', marginTop: '20px', marginBottom: '20px' }}
          variant="outlined"
          severity="info"
        >
          <Typography>
            There is currently no insurance premium information for this member. If the member has been recently
            established it may take a few days for this data to be populated.
          </Typography>
        </Alert>
      )}
    </>
  );
};
