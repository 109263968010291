import Check from '@mui/icons-material/Check';
import InfoIcon from '@mui/icons-material/Info';
import { Container, Tooltip, Typography } from '@mui/material';
import { Field } from 'formik';
import React, { useState } from 'react';
import * as yup from 'yup';
import {
  InlineEditBaseProps,
  InlineEditColumn,
  InlineEditColumnSet,
  InlineEditDataTable,
} from '../../../../../../common/components/dataTable/clientSide';
import { FilterDataType } from '../../../../../../common/components/dataTable/types';
import { FormikEnumerationSelect, FormikSwitch, FormikTextField } from '../../../../../../common/components/formik';
import { theme } from '../../../../../../themes';
import { EmailType } from '../../store/enums';
import { EmailAddress } from '../../store/types';

export type EmailEditTableProps = InlineEditBaseProps<EmailAddress> & { hasMoneysoftAccess: boolean };

export const EmailTable = (props: EmailEditTableProps): JSX.Element => {
  const {
    items,
    onSave,
    onDelete,
    editId,
    onSelectEditId,
    onStartAddItem,
    progress,
    hideColumns,
    hideAllActions,
    hasMoneysoftAccess,
  } = props;

  const [clientportalAccess, setClientportalAccess] = useState<boolean | null>(null);

  const tooltipText =
    'Setting this to yes will send an email to the client to create a password and instructions on how to access the client portal and mobile apps. More information about the client portal is available on the DASH academy.';

  const typeColumn = (): InlineEditColumnSet => {
    return {
      standardTemplate: function standardTemplate(dataIndex: number) {
        return <Typography variant="h5">{EmailType.getById(items[dataIndex]?.typeId)?.displayName}</Typography>;
      },
      editTemplate: function editTemplate() {
        return (
          <Field
            component={FormikEnumerationSelect}
            type={EmailType}
            showNone={false}
            name="typeId"
            valueIsId={true}
            label="TYPE"
            fullWidth
          />
        );
      },
    };
  };

  const emailAddressColumn = (): InlineEditColumnSet => {
    return {
      standardTemplate: function standardTemplate(dataIndex: number) {
        return <Typography variant="h5">{items[dataIndex]?.emailAddress}</Typography>;
      },
      editTemplate: function editTemplate() {
        return <Field component={FormikTextField} name="emailAddress" label="EMAIL ADDRESS" fullWidth />;
      },
    };
  };

  const clientPortalAccessColumn = (): InlineEditColumnSet => {
    return {
      standardTemplate: function standardTemplate(dataIndex: number) {
        return <Typography variant="h5">{!!items[dataIndex]?.clientPortalAccess && <Check></Check>}</Typography>;
      },
      editTemplate: function editTemplate() {
        return (
          <>
            <Field
              name="clientPortalAccess"
              component={FormikSwitch}
              onChange={(val: boolean) => setClientportalAccess(val ? true : false)}
              label="CLIENT PORTAL ACCESS"
            ></Field>
            <Container style={{ display: 'flex', alignItems: 'center', padding: '4px 0 0' }}>
              <Typography variant="body1" style={{ fontSize: '14px', letterSpacing: 0, lineHeight: '16px' }}>
                Access should only be granted for active accounts with investments.
              </Typography>
              <Tooltip title={tooltipText} arrow>
                <InfoIcon style={{ width: '16px', height: '16px', fill: `${theme.palette.primary.main}` }} />
              </Tooltip>
            </Container>
          </>
        );
      },
    };
  };

  const moneysoftAccessColumn = (): InlineEditColumnSet => {
    return {
      standardTemplate: function standardTemplate(dataIndex: number) {
        return <Typography variant="h5">{!!items[dataIndex]?.hasMoneysoftAccess && <Check></Check>}</Typography>;
      },
      editTemplate: function editTemplate(dataIndex: number) {
        if (clientportalAccess === null) {
          setClientportalAccess(items[dataIndex]?.clientPortalAccess);
        }
        return (
          <Field
            name="hasMoneysoftAccess"
            disabled={!clientportalAccess}
            component={FormikSwitch}
            label="MONEYSOFT ACCESS"
          ></Field>
        );
      },
    };
  };

  const preferredColumn = (): InlineEditColumnSet => {
    return {
      standardTemplate: function standardTemplate(dataIndex: number) {
        return (
          <Typography variant="h5" align="center">
            {!!items[dataIndex]?.preferred && <Check></Check>}
          </Typography>
        );
      },
      editTemplate: function editTemplate() {
        return (
          <Field name="preferred" component={FormikSwitch} label="PREFERRED" style={{ textAlign: 'center' }}></Field>
        );
      },
    };
  };

  let columns: InlineEditColumn[] = [
    {
      filterDataType: FilterDataType.string,
      name: 'type',
      label: 'TYPE',
      templates: typeColumn(),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'emailAddress',
      label: 'EMAIL ADDRESS',
      templates: emailAddressColumn(),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'clientPortalAccess',
      label: 'CLIENT PORTAL ACCESS',
      templates: clientPortalAccessColumn(),
      options: {
        filter: true,
        sort: true,
      },
    },
  ];

  if (hasMoneysoftAccess) {
    columns.push({
      name: 'hasMoneysoftAccess',
      label: 'MONEYSOFT ACCESS',
      templates: moneysoftAccessColumn(),
      options: {
        filter: true,
        sort: true,
      },
    });
  }

  columns.push({
    name: 'preferred',
    label: 'PREFERRED',
    textAlign: 'center',
    templates: preferredColumn(),
    options: {
      filter: true,
      sort: true,
    },
  });

  // remove columns needs to be hidden (exclude actions)
  if (hideColumns && hideColumns.length > 0) {
    columns = columns.filter(
      (column: InlineEditColumn) =>
        hideColumns.map((hideColumn: string) => hideColumn.toLowerCase()).indexOf(column.name.toLowerCase()) === -1
    );
  }

  return (
    <div style={{ paddingTop: '20px' }}>
      <Typography variant="h4" gutterBottom>
        Email Addresses
      </Typography>
      <InlineEditDataTable<EmailAddress>
        loadingProgress={progress}
        hideAllActions={hideAllActions}
        columns={columns}
        data={items}
        options={{
          filter: false,
          pagination: true,
          rowsPerPage: 2,
        }}
        deleteOptions={{
          dialogTitle: 'Are you sure you want to delete this email address?',
          dialogDescription: 'This action is permanent and cannot be reverted',
        }}
        newItem={{
          id: null,
          typeId: EmailType.Home.id,
          emailAddress: '',
          clientPortalAccess: false,
          hasMoneysoftAccess: false,
          preferred: false,
        }}
        onSave={onSave}
        onDelete={onDelete}
        onSelectEditId={onSelectEditId}
        onSetItemAdd={onStartAddItem}
        editId={editId}
        validationSchema={yup.object({
          emailAddress: yup
            .string()
            .required('Email address is required')
            .email('Please enter an email address in the format of clientname@example.com'),
          typeId: yup.number().required('Type is required'),
        })}
      ></InlineEditDataTable>
    </div>
  );
};
