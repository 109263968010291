import { Alert, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { LoadingIndicator } from 'src/common/components/LoadingIndicator';
import { Props } from '../container';
import { MemberDetails } from './memberDetails';
import { OtherBenefitsComponents } from './otherBenefits';
import { PreservationComponents } from './preservationComponents';
import { TaxationComponents } from './taxationComponents';

export const BenefitStatement = ({
  clientId,
  details,
  loadingProgressMainDetails,
  loadingProgressPreservationComponentDetails,
  loadingProgressTaxationComponentDetails,
  loadingProgressOtherBenefitDetails,
  fetchBenefitStatementDetailsByClientId,
  fetchBenefitStatementPreservationComponentByAccountId,
  fetchBenefitStatementTaxationComponentByAccountId,
  fetchBenefitStatementOtherBenefitsComponentByAccountId,
  preservationComponentDetails,
  taxationDetails,
  otherBenefits,
  isSuperSimplifierClient,
}: Props): JSX.Element => {
  useEffect(() => {
    if (!!clientId) {
      fetchBenefitStatementDetailsByClientId(clientId);
    }
  }, [fetchBenefitStatementDetailsByClientId, clientId]);
  useEffect(() => {
    if (!!details?.superMemberNumber) {
      fetchBenefitStatementPreservationComponentByAccountId(details?.superMemberNumber);
    }
  }, [fetchBenefitStatementPreservationComponentByAccountId, details?.superMemberNumber]);
  useEffect(() => {
    if (!!details?.superMemberNumber) {
      fetchBenefitStatementTaxationComponentByAccountId(details?.superMemberNumber);
    }
  }, [fetchBenefitStatementTaxationComponentByAccountId, details?.superMemberNumber]);
  useEffect(() => {
    if (!!details?.superMemberNumber) {
      fetchBenefitStatementOtherBenefitsComponentByAccountId(details?.superMemberNumber);
    }
  }, [fetchBenefitStatementOtherBenefitsComponentByAccountId, details?.superMemberNumber]);

  useEffect(() => {
    hasCompleteSetOfData();
  }, [preservationComponentDetails, taxationDetails, otherBenefits]);

  const hasCompleteSetOfData = () => {
    return taxationDetails.status === 200 && preservationComponentDetails.status === 200 && otherBenefits.status === 200 && !!details;
  };

  return (
    <>
      {isSuperSimplifierClient && !!details && (
        <>
          <Typography variant="h3" style={{ padding: '20px 0' }}>
            Benefit Statement
          </Typography>
          {hasCompleteSetOfData() ? (
            <>
              <LoadingIndicator progress={loadingProgressMainDetails}>
                <MemberDetails data-testid="memberDetailsComponent" details={details}></MemberDetails>
              </LoadingIndicator>
              <LoadingIndicator progress={loadingProgressPreservationComponentDetails}>
                <PreservationComponents
                  data-testid="preservationComponentsComponent"
                  details={preservationComponentDetails?.responseData}
                ></PreservationComponents>
              </LoadingIndicator>
              <LoadingIndicator progress={loadingProgressTaxationComponentDetails}>
                <TaxationComponents data-testid="taxationComponentsComponent" details={taxationDetails?.responseData}></TaxationComponents>
              </LoadingIndicator>
              <LoadingIndicator progress={loadingProgressOtherBenefitDetails}>
                <OtherBenefitsComponents data-testid="otherBenefitsComponent" details={otherBenefits?.responseData}></OtherBenefitsComponents>
              </LoadingIndicator>

              <p>The values above are an estimated account balance based on information provided by the administrator for Super Simplifier.</p>
              <p>The estimated account balance includes: </p>

              <ul>
                <li>All contributions that have been received and allocated.</li>
                <li>Fees, charges and taxes that have already been deducted from the member’s account.</li>
              </ul>
              <p>Some transactions may not have been added to the estimated account balance.</p>

              <p>
                For example, if the member has made a recent contribution, we may not have received and allocated the payment into their account yet. Also, this
                account balance may not yet include all deductions for taxes, fees and costs because these are applied at specific times.
              </p>

              <p>
                The value of your investment returns, fees and other costs are confirmed and applied to the member annual statement each year or an exit
                statement if the member closes their account.
              </p>
            </>
          ) : (
            <Alert style={{ marginRight: '20px', marginTop: '20px', marginBottom: '20px' }} variant="outlined" severity="info">
              <Typography>
                There is currently no benefit statement information for this member. If the member has been recently established it may take a few days for this
                data to be populated.
              </Typography>
            </Alert>
          )}
        </>
      )}
    </>
  );
};
