import { Enumeration } from './enumeration';

export class AttachmentType extends Enumeration {
  static FeeConsent = new AttachmentType(68, 'FeeConsent', 'Fee Consent');
  static SuperSimplifierSuper = new AttachmentType(45, 'SuperSimplifierSuper', 'Super Simplifier Member Application Form - Super');
  static SuperSimplifierPension = new AttachmentType(44, 'SuperSimplifierPension', 'Super Simplifier Member Application Form - Pension');

  static getById = (subTypeId: number | null): AttachmentType | undefined => {
    return Object.values(AttachmentType)
      .filter((t) => t instanceof AttachmentType)
      .find((t: AttachmentType) => t.id === subTypeId);
  };
}
