import { FeatureFlagsState } from './types';
import { FeatureFlagsActions, FeatureFlagsActionTypes } from './actions';

const initialState: FeatureFlagsState = {
  featureFlags: {
    version: -1,
    features: [],
  },
};

export default function (state = initialState, action: FeatureFlagsActions): FeatureFlagsState {
  switch (action.type) {
    case FeatureFlagsActionTypes.FetchFeatureFlagsSuccess:
      return {
        ...state,
        featureFlags: action.payload,
      };

    default:
      return state;
  }
}
