import { ExpandMore, GetApp } from '@mui/icons-material';
import { IconButton, Paper, TableCell, TableRow, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import {
  convertToCSVFormat,
  CSVDataType,
  DateTimeFormat,
  downloadCsv,
  FabMenu,
  FabMenuItem,
  formatDollars,
  formatNumberCommaSeparated,
  formatPercentage,
  getLocalDateTime,
  uuidv4,
} from '../../../../../common';
import { ClientSideDataTable } from '../../../../../common/components/dataTable/clientSide';
import { DatatableColumn } from '../../../../../common/components/dataTable/types';
import { ExpandedRow, GainsLossesResultItem, GainsLossesResultItemCSVRow, GroupingType } from '../store/types';

interface Props {
  results: GainsLossesResultItem[];
  isRealisedGainType: boolean;
  isLoading: boolean;
  hasErrors: boolean;
  groupBy: GroupingType;
}

const useStyles = makeStyles(() => ({
  open: {
    transform: 'rotate(360deg)',
    transition: 'transform 0.25s',
  },
  close: {
    transform: ' rotate(270deg)',
    transition: 'transform 0.25s',
  },
}));

export default function GainsLossesTableComponent(props: Props): JSX.Element {
  const { isLoading, hasErrors } = props;
  const rows = props.results ? props.results : [];

  const [expanded, setExpanded] = useState<ExpandedRow>([]);
  const [open, setOpen] = useState(false);
  const [isMenuOpen, setMenuState] = useState(false);

  function exportHandler(gainsLossesItems: GainsLossesResultItem[]) {
    const tree: GainsLossesResultItemCSVRow[] = [];

    function updateTree(node: GainsLossesResultItem, groupName: string, groupSubName: string) {
      tree.push({
        isGroup: node.isGroup ? 'Y' : 'N',
        securityCode: groupName,
        securityName: groupSubName,
        tradeDate: node.tradeDate !== null ? node.tradeDate : 'N/A',
        acquisitionDate: node.acquisitionDate !== null ? node.acquisitionDate : 'N/A',
        units: node.units,
        costBase: formatDollars(node.costBase),
        marketValue: formatDollars(node.marketValue),
        unitPrice: formatDollars(node.unitPrice),
        gainLossValue: formatDollars(node.gainLossValue),
        otherGainValue: formatDollars(node.otherGainValue),
        discountableGainValue: formatDollars(node.discountableGainValue),
        capitalLossValue: formatDollars(node.capitalLossValue),
      });
    }

    function mergeAndFlattenTree(items: GainsLossesResultItem[], groupName: string, groupSubName: string) {
      if (!!items.length) {
        for (let i = 0; i < items.length; i++) {
          updateTree(items[i], groupName, groupSubName);
          mergeAndFlattenTree(items[i].items, items[i].groupName, items[i].groupSubName);
        }
      }
    }
    gainsLossesItems.forEach((item: GainsLossesResultItem) => {
      updateTree(item, item.groupName, item.groupSubName);
      mergeAndFlattenTree(item.items, item.groupName, item.groupSubName);
    });

    const data: CSVDataType[] = convertToCSVFormat(tree);

    downloadCsv(data, `Gains_Losses_`);
  }

  useEffect(() => {
    setOpen(false);
    setExpanded([]);
  }, [rows]);

  const fabMenuItems: Array<FabMenuItem> = [
    {
      label: 'Export',
      onClick: () => {
        exportHandler(rows);
      },
      icon: <GetApp style={{ transform: 'rotate(180deg)' }} />,
    },
  ];

  const gainsLossesHeader = (row: GainsLossesResultItem, parentIndex: number, rowIndex: number) => {
    return (
      <React.Fragment key={uuidv4()}>
        <TableRow key={uuidv4()}>
          <TableCell style={{ width: '48px', maxWidth: '48px', padding: '0 0 0 5px' }}>
            <IconButton
              aria-label="expand"
              size="small"
              style={{ padding: '0' }}
              onClick={() => {
                setOpen(!open);
                if (expanded && expanded[parentIndex] && expanded[parentIndex].includes(rowIndex)) {
                  const update = expanded[parentIndex].filter((row) => row !== rowIndex);
                  setExpanded({ ...expanded, [parentIndex]: update });
                } else {
                  if (expanded[parentIndex]) {
                    const update = [...expanded[parentIndex].values(), rowIndex];
                    setExpanded({ ...expanded, [parentIndex]: update });
                  } else {
                    setExpanded({ ...expanded, [parentIndex]: [rowIndex] });
                  }
                }
              }}
            >
              <ExpandMore
                className={clsx(
                  !(expanded[parentIndex] && expanded[parentIndex].includes(rowIndex)) && classes.close,
                  expanded[parentIndex] && expanded[parentIndex].includes(rowIndex) && classes.open
                )}
                style={{ padding: 0 }}
              />
            </IconButton>
          </TableCell>
          <TableCell colSpan={2}>
            <Typography variant="h5" color={'primary'}>
              {row.groupName}
            </Typography>
            <Typography variant="h5" color={'textSecondary'}>
              {row.groupSubName}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="h5" align="right">
              {formatNumberCommaSeparated(row.units)}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="h5" align="right">
              {formatDollars(row.costBase)}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="h5" align="right">
              {formatDollars(row.marketValue)}
            </Typography>
          </TableCell>
          <TableCell align="left">
            <Typography
              variant="h5"
              align="right"
              color={!row.gainLossValue ? 'textPrimary' : row.gainLossValue < 0 ? 'error' : 'secondary'}
            >
              {formatDollars(row.gainLossValue)}
            </Typography>
            <Typography variant="h6" align="right" color={'textSecondary'}>
              {formatPercentage(row.gainLossPercentage)}
            </Typography>
          </TableCell>
          <TableCell align="left">
            <Typography
              variant="h5"
              align="right"
              color={!row.otherGainValue ? 'textPrimary' : row.otherGainValue < 0 ? 'error' : 'secondary'}
            >
              {formatDollars(row.otherGainValue)}
            </Typography>
            <Typography variant="h6" align="right" color={'textSecondary'}>
              {formatPercentage(row.otherGainPercentage)}
            </Typography>
          </TableCell>
          <TableCell align="left">
            <Typography
              variant="h5"
              align="right"
              color={!row.discountableGainValue ? 'textPrimary' : row.discountableGainValue < 0 ? 'error' : 'secondary'}
            >
              {formatDollars(row.discountableGainValue)}
            </Typography>
            <Typography variant="h6" align="right" color={'textSecondary'}>
              {formatPercentage(row.discountableGainPercentage)}
            </Typography>
          </TableCell>
          <TableCell align="left">
            <Typography
              variant="h5"
              align="right"
              color={!row.capitalLossValue ? 'textPrimary' : row.capitalLossValue < 0 ? 'error' : 'secondary'}
            >
              {formatDollars(row.capitalLossValue)}
            </Typography>
            <Typography variant="h6" align="right" color={'textSecondary'}>
              {formatPercentage(row.capitalLossPercentage)}
            </Typography>
          </TableCell>
        </TableRow>
        {row.items.map((childRow: GainsLossesResultItem) => gainsLossesDetail(childRow, parentIndex, rowIndex, false))}
      </React.Fragment>
    );
  };

  const gainsLossesDetail = (row: GainsLossesResultItem, parentIndex: number, key: number, visible: boolean) => {
    return (
      <TableRow
        key={uuidv4()}
        style={{
          visibility:
            (expanded[parentIndex] && expanded[parentIndex].includes(key)) || visible ? 'visible' : 'collapse',
        }}
      >
        <TableCell style={{ width: '48px', maxWidth: '48px' }}></TableCell>
        <TableCell style={{ width: '175px', maxWidth: '175px' }}>
          {getLocalDateTime(row.tradeDate, DateTimeFormat.Short)}
        </TableCell>
        <TableCell style={{ width: '175px', maxWidth: '175px' }}>
          {getLocalDateTime(row.acquisitionDate, DateTimeFormat.Short)}
        </TableCell>
        <TableCell align="right">{formatNumberCommaSeparated(row.units)}</TableCell>
        <TableCell align="right">{formatDollars(row.costBase)}</TableCell>
        <TableCell align="right">{formatDollars(row.marketValue)}</TableCell>
        <TableCell align="left">
          <Typography
            variant="h5"
            align="right"
            color={!row.gainLossValue ? 'textPrimary' : row.gainLossValue < 0 ? 'error' : 'secondary'}
          >
            {formatDollars(row.gainLossValue)}
          </Typography>
          <Typography variant="h6" align="right" color={'textSecondary'}>
            {formatPercentage(row.gainLossPercentage)}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography
            variant="h5"
            align="right"
            color={!row.otherGainValue ? 'textPrimary' : row.otherGainValue < 0 ? 'error' : 'secondary'}
          >
            {formatDollars(row.otherGainValue)}
          </Typography>
          <Typography variant="h6" align="right" color={'textSecondary'}>
            {formatPercentage(row.otherGainPercentage)}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography
            variant="h5"
            align="right"
            color={!row.discountableGainValue ? 'textPrimary' : row.discountableGainValue < 0 ? 'error' : 'secondary'}
          >
            {formatDollars(row.discountableGainValue)}
          </Typography>
          <Typography variant="h6" align="right" color={'textSecondary'}>
            {formatPercentage(row.discountableGainPercentage)}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography
            variant="h5"
            align="right"
            color={!row.capitalLossValue ? 'textPrimary' : row.capitalLossValue < 0 ? 'error' : 'secondary'}
          >
            {formatDollars(row.capitalLossValue)}
          </Typography>
          <Typography variant="h6" align="right" color={'textSecondary'}>
            {formatPercentage(row.capitalLossPercentage)}
          </Typography>
        </TableCell>
      </TableRow>
    );
  };

  const detailedColumn = (rowMeta: { dataIndex: number; rowIndex: number }): React.ReactNode => {
    return (
      <>
        {rows[rowMeta.dataIndex]?.items.map((item: GainsLossesResultItem, index: number) => {
          if (item.isGroup) {
            return gainsLossesHeader(item, rowMeta.dataIndex, index);
          } else {
            return gainsLossesDetail(item, rowMeta.dataIndex, index, true);
          }
        })}
      </>
    );
  };

  const tradeDateColumn = (dataIndex: number): React.ReactNode => {
    if (props.groupBy === GroupingType.Account) {
      return (
        <Typography variant="h5" color={'primary'} style={{ fontWeight: 600 }}>
          {rows.length > 0 ? rows[dataIndex]?.groupSubName : ''}
        </Typography>
      );
    } else {
      return (
        <>
          <Typography variant="h5" color={'primary'}>
            {rows.length > 0 ? rows[dataIndex]?.groupName : ''}
          </Typography>
          <Typography variant="h5" color={'textSecondary'}>
            {rows.length > 0 ? rows[dataIndex]?.groupSubName : ''}
          </Typography>
        </>
      );
    }
  };

  const unitsColumn = (dataIndex: number): React.ReactNode => {
    return (
      <Typography variant="h5" align="right">
        {rows.length > 0 && props.groupBy !== GroupingType.Account
          ? formatNumberCommaSeparated(rows[dataIndex]?.units)
          : ''}
      </Typography>
    );
  };

  const acquisitionDateColumn = (): React.ReactNode => {
    return <></>;
  };

  const costbaseColumn = (dataIndex: number): React.ReactNode => {
    return (
      <Typography variant="h5" align="right">
        {rows.length > 0 ? formatDollars(rows[dataIndex]?.costBase) : ''}
      </Typography>
    );
  };

  const proceedsColumn = (dataIndex: number): React.ReactNode => {
    return (
      <Typography variant="h5" align="right">
        {rows.length > 0 ? formatDollars(rows[dataIndex]?.marketValue) : ''}
      </Typography>
    );
  };

  const gainLossColumn = (dataIndex: number): React.ReactNode => {
    return (
      <>
        <Typography
          variant="h5"
          align="right"
          color={
            !rows[dataIndex]?.gainLossValue ? 'textPrimary' : rows[dataIndex]?.gainLossValue < 0 ? 'error' : 'secondary'
          }
        >
          {rows.length > 0 ? formatDollars(rows[dataIndex]?.gainLossValue) : ''}
        </Typography>
        <Typography variant="h6" align="right" color={'textSecondary'}>
          {rows.length > 0 ? formatPercentage(rows[dataIndex]?.gainLossPercentage) : ''}
        </Typography>
      </>
    );
  };

  const otherGainColumn = (dataIndex: number): React.ReactNode => {
    return (
      <>
        <Typography
          variant="h5"
          align="right"
          color={
            !rows[dataIndex]?.otherGainValue
              ? 'textPrimary'
              : rows[dataIndex]?.otherGainValue < 0
              ? 'error'
              : 'secondary'
          }
        >
          {rows.length > 0 ? formatDollars(rows[dataIndex]?.otherGainValue) : ''}
        </Typography>
        <Typography variant="h6" align="right" color={'textSecondary'}>
          {rows.length > 0 ? formatPercentage(rows[dataIndex]?.otherGainPercentage) : ''}
        </Typography>
      </>
    );
  };

  const discountableGainColumn = (dataIndex: number): React.ReactNode => {
    return (
      <>
        <Typography
          variant="h5"
          align="right"
          color={
            !rows[dataIndex]?.discountableGainValue
              ? 'textPrimary'
              : rows[dataIndex]?.discountableGainValue < 0
              ? 'error'
              : 'secondary'
          }
        >
          {rows.length > 0 ? formatDollars(rows[dataIndex]?.discountableGainValue) : ''}
        </Typography>
        <Typography variant="h6" align="right" color={'textSecondary'}>
          {rows.length > 0 ? formatPercentage(rows[dataIndex]?.discountableGainPercentage) : ''}
        </Typography>
      </>
    );
  };

  const capitalLossColumn = (dataIndex: number): React.ReactNode => {
    return (
      <>
        <Typography
          variant="h5"
          align="right"
          color={
            !rows[dataIndex]?.capitalLossValue
              ? 'textPrimary'
              : rows[dataIndex]?.capitalLossValue < 0
              ? 'error'
              : 'secondary'
          }
        >
          {rows.length > 0 ? formatDollars(rows[dataIndex]?.capitalLossValue) : ''}
        </Typography>
        <Typography variant="h6" align="right" color={'textSecondary'}>
          {rows.length > 0 ? formatPercentage(rows[dataIndex]?.capitalLossPercentage) : ''}
        </Typography>
      </>
    );
  };

  const columns: DatatableColumn[] = [
    {
      name: 'TRADEDATE',
      label: 'TRADE DATE',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex: number): React.ReactNode => tradeDateColumn(dataIndex),
        setCellHeaderProps: () => {
          return {
            style: { width: '150px', maxWidth: '150px' },
          };
        },
      },
    },
    {
      name: 'ACQUISITIONDATE',
      label: 'ACQUISITION DATE',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (): React.ReactNode => acquisitionDateColumn(),
      },
    },
    {
      name: 'UNITS',
      label: 'UNITS',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex: number): React.ReactNode => unitsColumn(dataIndex),
        setCellHeaderProps: () => {
          return {
            style: { textAlign: 'right' },
          };
        },
      },
    },
    {
      name: 'COSTBASE',
      label: 'COST BASE',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex: number): React.ReactNode => costbaseColumn(dataIndex),
        setCellHeaderProps: () => {
          return {
            style: { textAlign: 'right' },
          };
        },
      },
    },
    {
      name: 'PROCEEDSMARKET',
      label: `${props.isRealisedGainType ? 'PROCEEDS' : 'MARKET VALUE'}`,
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex: number): React.ReactNode => proceedsColumn(dataIndex),
        setCellHeaderProps: () => {
          return {
            style: { textAlign: 'right' },
          };
        },
      },
    },
    {
      name: 'GAIN/LOSS',
      label: 'GAIN/LOSS',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex: number): React.ReactNode => gainLossColumn(dataIndex),
        setCellHeaderProps: () => {
          return {
            style: { textAlign: 'right' },
          };
        },
      },
    },
    {
      name: 'OTHERGAIN',
      label: 'OTHER GAIN',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex: number): React.ReactNode => otherGainColumn(dataIndex),
        setCellHeaderProps: () => {
          return {
            style: { textAlign: 'right' },
          };
        },
      },
    },
    {
      name: 'DISCOUNTABLEGAIN',
      label: 'DISCOUNTABLE GAIN',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex: number): React.ReactNode => discountableGainColumn(dataIndex),
        setCellHeaderProps: () => {
          return {
            style: { textAlign: 'right' },
          };
        },
      },
    },
    {
      name: 'CAPITALLOSS',
      label: 'CAPITAL LOSS',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex: number): React.ReactNode => capitalLossColumn(dataIndex),
        setCellHeaderProps: () => {
          return {
            style: { textAlign: 'right' },
          };
        },
      },
    },
  ];

  const classes = useStyles();

  return (
    <Paper elevation={0} style={{ marginBottom: '40px' }}>
      {!!rows.length && (
        <FabMenu
          isMenuOpen={isMenuOpen}
          toggleMenu={() => {
            setMenuState(!isMenuOpen);
          }}
          menuItems={fabMenuItems}
          name="isMenuOpen"
        />
      )}
      <ClientSideDataTable
        loadingProgress={{ isLoading, hasErrors }}
        columns={columns}
        data={rows ?? []}
        options={{
          filter: false,
          viewColumns: false,
          expandableRows: true,
          expandableRowsOnClick: true,
          renderExpandableRow: (_rowData, rowMeta) => detailedColumn(rowMeta),
        }}
        id="client-gainslosses-datatable"
      ></ClientSideDataTable>
    </Paper>
  );
}
