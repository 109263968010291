import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchSecurityDetails } from './thunks';
import { SecurityCommonState, SecurityDetails } from './types';

const initialState: SecurityCommonState = {
  securityId: 0,
  securityDetails: {
    securityId: 0,
    assetClasses: [],
    code: '',
    currency: '',
    description: '',
    gicsSector: '',
    gicsSubGroup: '',
    isin: '',
    market: '',
    name: '',
    securityIcr: 0,
    trustType: '',
  },
};

export const commonSlice = createSlice({
  name: '@@security/common',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSecurityDetails.fulfilled, (state, action: PayloadAction<SecurityDetails>) => {
      state.securityDetails = action.payload;
    });
  },
});
