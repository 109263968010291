export {
  OPTIONS_LIST_MAX_ROWS,
  ROW_HEIGHT,
  LIST_BOX_PADDING,
  OVERSCAN_COUNT,
  LIST_BOX_RELATIVE_WIDTH,
  LIST_BOX_FILTERED_ROW_LIMIT,
};
const LIST_BOX_PADDING = 20;
const ROW_HEIGHT = 70;
const OPTIONS_LIST_MAX_ROWS = 8;
const OVERSCAN_COUNT = 2;

const LIST_BOX_RELATIVE_WIDTH = '100%';
const LIST_BOX_FILTERED_ROW_LIMIT = 30;
