import { User } from 'oidc-client';
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { RootState } from './store';
import { userManager } from './userManager';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ProtectedRoute = ({ ...props }) => {
  const auth = useSelector((state: RootState) => state.oidc.user);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  useEffect(() => {
    userManager.getUser().then((user) => {
      if (user && !user.expired) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
    });
  }, [auth]);

  if (isAuthenticated === null) {
    return <></>;
  }

  const propsWithoutComponent = { ...props }; // create a shallow copy
  delete propsWithoutComponent.component;

  return (
    <Route
      {...propsWithoutComponent}
      render={(renderProps) =>
        isAuthenticated || process.env.REACT_APP_AUTH_DISABLED === 'true' ? (
          <props.component {...renderProps} />
        ) : process.env.REACT_APP_PUBLIC_URL ? (
          <Redirect to={process.env.REACT_APP_PUBLIC_URL} />
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};

function mapStateToProps(state: { oidc: { user: User } }) {
  return {
    user: state.oidc.user,
  };
}

export default connect(mapStateToProps)(ProtectedRoute);
