import { createSelector } from '@reduxjs/toolkit';
import { selectConfiguration } from '../../store/selectors';
import { RootState } from 'src/reducers';


export const selectLinkedAccounts = createSelector(selectConfiguration, (configuration) => configuration?.linkedAccounts);

export const selectConstraints = createSelector(selectConfiguration, (configuration) => configuration?.constraints);

export const selectExclusions  = createSelector(selectConfiguration, (configuration) => configuration?.exclusions);

export const selectTargets = createSelector(selectConfiguration, (configuration) => configuration?.targets);

export const selectDetails = createSelector(selectConfiguration, (configuration) => configuration?.details);

export const selectActivation = createSelector(selectConfiguration, (configuration) => configuration?.activation);

export const selectClientState = createSelector((state: RootState) => state, (state) => state.clients);
