import { connect, ConnectedProps } from 'react-redux';
import { selectClientId, selectClientStatus, selectSuperSimplifierDetails } from 'src/features/clients/client/common/store/selectors';
import { selectHasClientEdit, selectHasClientEditAdminOnly } from 'src/store/selectors';
import { progressSelectorFactory } from '../../../../../../common';
import { RootState } from '../../../../../../reducers';
import { CommonActionTypes, updateSuperSimplifier } from '../../../common/store';
import { SuperSimplifierSummary } from './components/superSimplifierSummary';

const mapStateToProps = (state: RootState) => ({
  clientId: selectClientId(state),
  details: selectSuperSimplifierDetails(state),
  loadingProgress: progressSelectorFactory([CommonActionTypes.FetchSuperSimplifier])(state),
  savingProgress: progressSelectorFactory([CommonActionTypes.UpdateSuperSimplifier])(state),
  hasClientEditPermission: selectHasClientEdit(state),
  hasClientEditAdminOnlyPermission: selectHasClientEditAdminOnly(state),
  status: selectClientStatus(state),
  readonly: true,
});

const mapDispatchToProps = {
  updateSuperSimplifier,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector>;
export const SuperSimplifierSummaryContainer = connector(SuperSimplifierSummary);
