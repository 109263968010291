import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ResultWrapper } from '../../benefitStatement/store/types';
import {
  fetchDeathAndTpdInsuranceByAccountId,
  fetchIncomeProtectionInsuranceByAccountId,
  fetchPremiumDetails,
  fetchMetApplyToken,
  fetchAuthorisedContact,
} from './thunks';
import { DeathAndTpdInsurance, IncomeProtectionInsurance, InsuranceState, PremiumDetails, ContactRoles, SecureMetApplyToken } from './types';

export const initialState: InsuranceState = {
  parameters: {
    clientId: 0,
    isSmoker: false,
    accountId: '',
  },
  deathAndTpdInsurance: {
    responseData: {
      approvedDeathInsuredAmount: 0,
      approvedTPDInsuredAmount: 0,
    },
  },
  incomeProtectionInsurance: {
    responseData: {
      coverPeriod: '',
      totalSCSumInsured: 0,
      waitingPeriod: '',
    },
  },
  premiumDetails: {
    responseData: {
      deathPremium: 0,
      premiumFrequency: '',
      totalDTPDPremium: 0,
      tpdPremium: 0,
      totalIPPremium: 0
    },
  },
  secureMetApplyToken: {
    responseData: {
      token: '',
    },
  },
  authorisedContact: { id: 0, isPrimary: false, name: '' } as ContactRoles,
};

export const insuranceSlice = createSlice({
  name: 'insuranceSlice',
  initialState,
  reducers: {
    setClientIdParameter: (state, action: PayloadAction<number>) => {
      state.parameters.clientId = action.payload;
    },
    setIsSmokerParameter: (state, action: PayloadAction<boolean>) => {
      state.parameters.isSmoker = action.payload;
    },
    setAccountIdParameter: (state, action: PayloadAction<string>) => {
      state.parameters.accountId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDeathAndTpdInsuranceByAccountId.fulfilled, (state, action: PayloadAction<ResultWrapper<DeathAndTpdInsurance | null>>) => {
      state.deathAndTpdInsurance = action.payload;
    });
    builder.addCase(fetchIncomeProtectionInsuranceByAccountId.fulfilled, (state, action: PayloadAction<ResultWrapper<IncomeProtectionInsurance | null>>) => {
      state.incomeProtectionInsurance = action.payload;
    });
    builder.addCase(fetchPremiumDetails.fulfilled, (state, action: PayloadAction<ResultWrapper<PremiumDetails | null>>) => {
      state.premiumDetails = action.payload;
    });
    builder.addCase(fetchMetApplyToken.fulfilled, (state, action: PayloadAction<ResultWrapper<SecureMetApplyToken | null>>) => {
      state.secureMetApplyToken = action.payload;
    });

    builder.addCase(fetchAuthorisedContact.fulfilled, (state, action: PayloadAction<ContactRoles>) => {
      state.authorisedContact = action.payload;
    });
  },
});
