import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import React from 'react';
import { ServerSideDataTable } from '../../../../common/components/dataTable/serverSide';
import { DatatableColumn, FilterDataType } from '../../../../common/components/dataTable/types';
import { LoadingProgress } from '../../../../common/store/types';
import { PagedRequest, PagedResult } from '../../../../store';
import { SecuritiesCsvRow, SecurityItem } from '../store/types';

export interface Props {
  pagedResults?: PagedResult<SecurityItem>;
  pagedRequest: PagedRequest;
  handlePagedRequest: (pagedRequest: PagedRequest) => void;
  handleRowClick: (securityId: number, securityCode: string) => void;
  progressSecurities: LoadingProgress;
  handleDownload: (pagedRequest: PagedRequest, mapper: (dividendDistributionsByPaymentDateItems: SecurityItem[]) => SecuritiesCsvRow[]) => void;
}

export function SecurityListTable(props: Props): JSX.Element {
  const { pagedResults, progressSecurities, handleDownload, pagedRequest } = props;

  const codeColumn = (dataIndex: number): React.ReactNode => {
    return <Typography variant="h5">{pagedResults?.results[dataIndex]?.code}</Typography>;
  };

  const nameColumn = (dataIndex: number): React.ReactNode => {
    return <Typography variant="h5">{pagedResults?.results[dataIndex]?.name}</Typography>;
  };

  const securityTypeColumn = (dataIndex: number): React.ReactNode => {
    return <Typography variant="h5">{pagedResults?.results[dataIndex]?.typeDescription}</Typography>;
  };

  const securityCategoryColumn = (dataIndex: number): React.ReactNode => {
    return <Typography variant="h5">{pagedResults?.results[dataIndex]?.categoryDescription}</Typography>;
  };

  const marketColumn = (dataIndex: number): React.ReactNode => {
    return <Typography variant="h5">{pagedResults?.results[dataIndex]?.market}</Typography>;
  };

  const statusColumn = (dataIndex: number): React.ReactNode => {
    return <Typography variant="h5">{pagedResults?.results[dataIndex]?.status}</Typography>;
  };

  const columns: DatatableColumn[] = [
    {
      filterDataType: FilterDataType.string,
      name: 'code',
      label: 'CODE',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex): React.ReactNode => codeColumn(dataIndex),
      },
    },
    {
      filterDataType: FilterDataType.string,
      name: 'name',
      label: 'NAME',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex): React.ReactNode => nameColumn(dataIndex),
      },
    },
    {
      filterDataType: FilterDataType.string,
      name: 'securityType',
      label: 'TYPE',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex): React.ReactNode => securityTypeColumn(dataIndex),
      },
    },
    {
      filterDataType: FilterDataType.string,
      name: 'securityCategory',
      label: 'CATEGORY',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex): React.ReactNode => securityCategoryColumn(dataIndex),
      },
    },
    {
      filterDataType: FilterDataType.string,
      name: 'market',
      label: 'MARKET',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex): React.ReactNode => marketColumn(dataIndex),
      },
    },
    {
      filterDataType: FilterDataType.string,
      name: 'securityStatus',
      label: 'STATUS',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex: number): React.ReactNode => statusColumn(dataIndex),
      },
    },
  ];

  return (
    <Grid container sx={{ width: '100%', margin: '10px' }}>
      <Grid item xs={12}>
        <ServerSideDataTable
          loadingProgress={progressSecurities}
          columns={columns}
          pagedRequest={pagedRequest}
          pagedResult={props.pagedResults}
          options={{}}
          handlePagedRequest={props.handlePagedRequest}
          csvDownload={() =>
            handleDownload(pagedRequest, (dividendDistributionsByPaymentDateItems) =>
              dividendDistributionsByPaymentDateItems.map((t) => {
                return {
                  code: t.code,
                  name: t.name,
                  typeDescription: t.typeDescription,
                  categoryDescription: t.categoryDescription,
                  market: t.market,
                  status: t.status,
                };
              })
            )
          }
          handleRowClick={(rowData: string[], rowMeta: { dataIndex: number; rowIndex: number }) => {
            const securityId = props.pagedResults?.results[rowMeta.rowIndex].id;
            const securityCode = props.pagedResults?.results[rowMeta.rowIndex].code;
            if (securityId && securityCode) {
              props.handleRowClick(securityId, securityCode);
            }
          }}
        ></ServerSideDataTable>
      </Grid>
    </Grid>
  );
}
