import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import React from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { ClientState } from '../store/types';
import { onboardRoutes } from './onboardRoutes';

export interface Props {
  clientState?: ClientState;
  hasClientEditAdminOnlyPermission?: boolean;
}

export const LeftMenu = (props: Props): JSX.Element => {
  const { clientState, hasClientEditAdminOnlyPermission } = props;
  const location = useLocation();
  const routeMatch = useRouteMatch();

  return (
    <>
      {!!clientState && (
        <List>
          {onboardRoutes
            .filter((route) => route.visible(clientState, hasClientEditAdminOnlyPermission))
            .sort((a, b) => (a.menuIndex || 0) - (b.menuIndex || 0))
            .map((r) => {
              return (
                <ListItem
                  disabled={location.pathname !== `${routeMatch.url}/${r.url}`}
                  className={'LeftMenu'}
                  selected={r.selected(location.pathname, routeMatch.url, r)}
                  disableRipple
                  key={r.menuIndex}
                  style={{ paddingLeft: '30px', paddingRight: '25px' }}
                  button
                >
                  <ListItemIcon>{!!r.icon && <r.icon></r.icon>}</ListItemIcon>
                  <ListItemText
                    primary={`${r.label}`}
                    primaryTypographyProps={{ color: 'textSecondary', variant: 'h5' }}
                  />
                </ListItem>
              );
            })}
        </List>
      )}
    </>
  );
};
