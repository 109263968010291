import { connect, ConnectedProps } from 'react-redux';
import { hasErrorsSelectorFactory, isLoadingSelectorFactory } from '../../../../../common';
import { RootState } from '../../../../../reducers';
import { selectClientId } from '../../common/store/selectors';
import { selectParameters, selectRecentTransactionsListResults } from '../store/selectors';
import { DashboardActionTypes, fetchRecentTransactionsList } from '../store/thunks';
import { RecentTransactionsComponent } from './RecentTransactionsComponent';

const actionTypes = [DashboardActionTypes.FetchRecentTransactionsList];

const mapStateToProps = (state: RootState) => ({
  isLoading: isLoadingSelectorFactory(actionTypes)(state),
  hasErrors: hasErrorsSelectorFactory(actionTypes)(state),
  clientId: selectClientId(state),
  parameters: selectParameters(state),
  result: selectRecentTransactionsListResults(state),
});

const mapDispatchToProps = {
  fetchRecentTransactionsList,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector>;
export const RecentTransactionsContainer = connect(mapStateToProps, mapDispatchToProps)(RecentTransactionsComponent);
