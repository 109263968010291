import { Autocomplete, TextField } from '@mui/material';
import _ from 'lodash';
import { default as React } from 'react';
import { SavedParameter } from '../store/types';

interface Props {
  items: SavedParameter[];
  selectedParameter: SavedParameter | null;
  setParameter: (SavedParameter: SavedParameter | null) => void;
}

function ReportingParameterSelect(props: Props): JSX.Element {
  const { selectedParameter, items, setParameter } = props;
  const parameters = items || [];

  function handleSetParameter(param: SavedParameter | null) {
    if (param === null || param?.id === null) {
      setParameter(null);
      return;
    }
    const parameter = _.find(parameters, (i) => i.id === param?.id);
    if (parameter) {
      setParameter(parameter);
    }
  }

  return (
    <Autocomplete
      style={{ width: '420px' }}
      id="parameter-select"
      value={selectedParameter}
      options={parameters}
      blurOnSelect={false}
      getOptionLabel={(option) => {
        return option.name ? option.name : 'No saved parameter';
      }}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.id}>
            {option.name}
          </li>
        );
      }}
      renderInput={(params) => <TextField {...params} label="Saved Reporting Parameters" variant="outlined" fullWidth />}
      onChange={(_event: React.SyntheticEvent<Element, Event>, value: SavedParameter | null) => handleSetParameter(value)}
    />
  );
}

export default ReportingParameterSelect;
