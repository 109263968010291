import { Box, Container, Grid } from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import SearchButton from 'src/features/clients/client/common/components/SearchButton';
import { formatDollars, formatPercentage } from '../../../../common';
import { EnumSelect } from '../../../../common/components';
import CardComponent from '../../../../common/components/CardComponent';
import DateRangePicker from '../../../../common/components/DateRangePicker';
import { theme } from '../../../../themes';
import { Props } from '../container';
import { FetchDailyPerformanceChartPayload, FetchMonthlyPerformanceChartPayload, Frequency } from '../store/types';
import { PerformanceChart } from './performanceChart';
import { PeriodicSummaryTable } from './periodicSummaryTable';

export const PerformanceComponent = (props: Props): JSX.Element => {
  const {
    parameters,
    loadingProgresStrategyDetails,
    loadingProgresStrategyDashboard,
    strategyDetails,
    strategyDashboard,
    fetchDailyPerformanceChart,
    fetchMonthlyPerformanceChart,
    fetchPeriodicSummary,
    fetchStrategyDetails,
    fetchStrategyDashboard,
    setFrequencyParameter,
    setDateParameter,
    match: { params },
  } = props;

  const fetch = useCallback(() => {
    if (params.strategyId) {
      const dailyPerformanceChartRequestPayload: FetchDailyPerformanceChartPayload = {
        strategyId: parseInt(params.strategyId),
        fromDate: parameters.dates.dateFrom,
        toDate: parameters.dates.dateTo,
      };
      fetchStrategyDashboard({ strategyId: parseInt(params.strategyId) });
      fetchStrategyDetails({ strategyId: parseInt(params.strategyId) });
      fetchDailyPerformanceChart(dailyPerformanceChartRequestPayload);
      fetchPeriodicSummary({
        strategyId: parseInt(params.strategyId),
        benchmarkStrategyId: parameters.strategyBenchmarkSelected.id,
        endDate: parameters.dates.dateTo,
      });
    }
  }, [params.strategyId, fetchDailyPerformanceChart, fetchMonthlyPerformanceChart, fetchPeriodicSummary, fetchStrategyDetails, fetchStrategyDashboard]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  function callStrategyEndpoints() {
    if (params.strategyId) {
      const dailyPerformanceChartRequestPayload: FetchDailyPerformanceChartPayload = {
        strategyId: parseInt(params.strategyId),
        fromDate: parameters.dates.dateFrom,
        toDate: parameters.dates.dateTo,
      };
      const monthlyPerformanceChartRequestPayload: FetchMonthlyPerformanceChartPayload = {
        strategyId: parseInt(params.strategyId),
        fromDate: parameters.dates.dateFrom,
        toDate: parameters.dates.dateTo,
      };
      fetchStrategyDashboard({ strategyId: parseInt(params.strategyId) });
      fetchStrategyDetails({ strategyId: parseInt(params.strategyId) });
      if (parameters.frequency === Frequency.Daily) {
        fetchDailyPerformanceChart(dailyPerformanceChartRequestPayload);
      } else {
        fetchMonthlyPerformanceChart(monthlyPerformanceChartRequestPayload);
      }
      fetchPeriodicSummary({
        strategyId: parseInt(params.strategyId),
        benchmarkStrategyId: parameters.strategyBenchmarkSelected.id,
        endDate: parameters.dates.dateTo,
      });
    }
  }

  function onSearchClicked() {
    callStrategyEndpoints();
  }

  return (
    <Container style={{ paddingTop: '20px', paddingBottom: '20px' }}>
      <Box display={'flex'} justifyContent={'space-between'} style={{ padding: '10px 0' }}>
        <Box paddingRight={'10px'}></Box>
        <Box display={'flex'} justifyContent={'space-between'}>
          <DateRangePicker dateRange={parameters.dates} setDateRange={setDateParameter} />
          <Box paddingRight={'10px'}>
            <EnumSelect
              label="Frequency"
              enumType={Frequency}
              value={parameters?.frequency}
              onChange={(value) => {
                setFrequencyParameter(Frequency[value]);
              }}
            />
          </Box>
          <Box paddingRight={'10px'}></Box>
          <Box>
            <SearchButton onClick={onSearchClicked} />
          </Box>
        </Box>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <CardComponent
            progress={loadingProgresStrategyDetails}
            background={theme.palette.gradient1?.main}
            value={formatDollars(strategyDetails?.fua)}
            subText={'FUA'}
          />
        </Grid>
        <Grid item xs={3}>
          <CardComponent
            progress={loadingProgresStrategyDetails}
            background={theme.palette.gradient2?.main}
            value={strategyDetails?.portfolioCount}
            subText={'Portfolios'}
          />
        </Grid>
        <Grid item xs={3}>
          <CardComponent
            progress={loadingProgresStrategyDashboard}
            background={theme.palette.gradient1?.main}
            value={formatPercentage(strategyDashboard?.oneMonthReturn)}
            subText={'1 MONTH RETURN'}
          />
        </Grid>
        <Grid item xs={3}>
          <CardComponent
            progress={loadingProgresStrategyDashboard}
            background={theme.palette.gradient2?.main}
            value={formatPercentage(strategyDashboard?.inceptionReturn)}
            subText={'INCEPTION RETURN'}
          />
        </Grid>
      </Grid>
      <PerformanceChart {...props} frequency={parameters.frequency} />
      <PeriodicSummaryTable {...props} />
    </Container>
  );
};
