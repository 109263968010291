export function validateABNNumber(abn: string | null | undefined): boolean {
  if (!abn || abn === null || abn === '') {
    return false;
  }

  // remove spaces
  // remove hyphens
  const rawValue = abn.replace(/[\s-]/g, '');

  // validate length
  if (rawValue.length !== 11) {
    return false;
  }

  //  Subtract 1 from the first (left-most) digit (5) to give 41 824 753 556
  const firstValue = parseInt(rawValue.substring(0, 1), 10) - 1;

  if (isNaN(firstValue) === true || firstValue < 0) {
    return false;
  }

  // Multiply each of the digits in 41 824 753 556 by the "weighting factor" based on its position as shown in the table below
  const weight: number[] = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19];
  const newNumber = `${firstValue}${rawValue.substr(1)}`;
  let sumOfWeights = 0;

  const hasCharacters = newNumber.split('').some((d: string, position: number) => {
    const digit = parseInt(d, 10);

    // Sum (Digit * weight)
    const sum = digit * weight[position];
    sumOfWeights += sum;

    return isNaN(digit) === true;
  });

  if (hasCharacters) {
    return false;
  }

  // Divide 534 by 89 giving 6 with zero remainder.
  // If remainder is 0, this is a valid ABN.
  const remainder = (sumOfWeights / 89).toFixed(1);

  return !!remainder.split('.')[1] ? remainder.split('.')[1] === '0' : false;
}
