import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { ReportTemplateComponent } from './components/ReportTemplateComponent';
import { RootState } from 'src/reducers';
import {
  downloadReportTemplateFile,
  fetchReportTemplates,
  saveReportTemplateFile,
  TemplateReportsActionTypes,
} from './store/thunks';
import { progressSelectorFactory } from 'src/common';
import { selectTemplates } from './store/selectors';

const mapStateToProps = (state: RootState) => ({
  templates: selectTemplates(state),
  downloadReportTemplateFileProgress: progressSelectorFactory([TemplateReportsActionTypes.DownloadReportTemplateFile])(
    state
  ),
  saveReportTemplateFileProgress: progressSelectorFactory([TemplateReportsActionTypes.SaveReportTemplateFile])(state),
  fetchReportTemplatesProgress: progressSelectorFactory([TemplateReportsActionTypes.FetchReportTemplates])(state),
});

const mapDispatchToProps = {
  fetchReportTemplates,
  saveReportTemplateFile,
  downloadReportTemplateFile,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector> & RouteComponentProps;
export const ReportsContainer = connect(mapStateToProps, mapDispatchToProps)(ReportTemplateComponent);
