import { Backdrop, Box, Card, CardHeader, Fade, Modal, Typography, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { WO2ToggleSwitch } from 'src/common';
import WO2Button from './../../../../../common/components/button/Button';
export interface Props {
  handleSave: (parameterName: string, visibleToAfsl: boolean) => void;
  handleClose: () => void;
  isOpen: boolean;
  isDuplicate: boolean;
}

export const SaveReportingParameters = (props: Props): JSX.Element => {
  const [parameterName, setParameterName] = useState('');
  const [visibleToAfsl, setVisibleToAfsl] = useState(false);

  const handleChangeParameterName = (name: string) => {
    setParameterName(name);
  };

  const handleChangeToggleSwitch = () => {
    setVisibleToAfsl(!visibleToAfsl);
  };

  useEffect(() => {
    if (!props.isOpen) {
      setParameterName('');
      setVisibleToAfsl(false);
    }
  }, [props.isOpen]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={props.isOpen}
      onClose={() => {
        props.handleClose();
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={props.isOpen}>
        <Card elevation={0} style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', maxWidth: '550px' }}>
          <CardHeader title={<Typography variant="h3">Save Reporting Parameter as</Typography>}> </CardHeader>
          <Box padding={'0 20px'}>
            <TextField
              label={'Reporting Parameter name'}
              variant="outlined"
              fullWidth
              value={parameterName}
              onChange={(event) => handleChangeParameterName(event.target.value)}
            />
          </Box>
          <Box display={'flex'} padding={'0 20px'} justifyContent={'flex-end'}>
            <Typography style={{ paddingTop: '10px' }}>
              * Give a meaningful name based on all the reporting parameters you have selected so that you on anyone part of your AFSL can re-use the settings
              in future.
            </Typography>
          </Box>
          <Box display={'flex'} justifyContent={'flex-start'} padding={'20px'}>
            <Typography style={{ paddingTop: '10px' }}>Visible to me</Typography>
            <WO2ToggleSwitch
              checked={visibleToAfsl}
              onChange={handleChangeToggleSwitch}
              color="primary"
              name="visibleToAfsl"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
            <Typography style={{ paddingTop: '10px' }}>Visible to anyone in the AFSL</Typography>
          </Box>
          <Box display={'flex'} justifyContent={'space-between'} padding={'20px'}>
            <Box paddingRight={'10px'}>{!!props.isDuplicate && <Typography color={'red'}>Name already exists</Typography>}</Box>
            <Box display={'flex'} justifyContent={'flex-end'}>
              <WO2Button
                variant="contained"
                value="Save"
                color="primary"
                disabled={!parameterName}
                onClick={() => {
                  if (!!parameterName) {
                    props.handleSave(parameterName, visibleToAfsl);
                  }
                }}
              >
                Save Reporting Parameters
              </WO2Button>
            </Box>
          </Box>
        </Card>
      </Fade>
    </Modal>
  );
};
