import AddIcon from '@mui/icons-material/Add';
import { Box, Container, Grid } from '@mui/material';
import { DateTime } from 'luxon';
import React, { useCallback, useEffect } from 'react';
import WO2Button from 'src/common/components/button/Button';
import SearchButton from 'src/features/clients/client/common/components/SearchButton';
import { formatDollars, formatPercentage } from '../../../../common';
import CardComponent from '../../../../common/components/CardComponent';
import DateRangePicker from '../../../../common/components/DateRangePicker';
import { PagedRequest } from '../../../../store';
import { theme } from '../../../../themes';
import { Props } from '../container';
import { SecurityListParameters, SecurityPriceCsvRow, SecurityPriceItem, ThunkParameters } from '../store/types';
import { PriceChart } from './priceChart';
import { SecurityListTable } from './securityListTable';

export const PriceComponent = (props: Props): JSX.Element => {
  const {
    parameters,
    priceStatistics,
    securityPrices,
    setDateParameter,
    fetchSecurityPriceStatistics,
    fetchSecurityPriceChart,
    fetchSecurityPrices,
    fetchSecurityPricesExport,
    securityPriceStatisticsProgress,
    downloadSecurityPrices,
    match: { params },
  } = props;

  const fetch = useCallback(() => {
    if (params.securityId) {
      const requestPayload: ThunkParameters = {
        securityId: parseInt(params.securityId),
        dates: { dateFrom: DateTime.now().minus({ years: 1 }).toISODate(), dateTo: DateTime.now().toISODate() },
      };

      const requestPayload2: SecurityListParameters = {
        pagination: {
          pageNumber: 1,
          pageSize: 25,
          queryFields: [
            {
              fieldName: 'date',
              descendingSortDirection: true,
              operator: '',
              hasSearchTerm: false,
              isSortTerm: true,
              searchTerm: '',
            },
          ],
        },
        securityId: parseInt(params.securityId),
      };

      fetchSecurityPriceStatistics(requestPayload);
      fetchSecurityPriceChart(requestPayload);
      fetchSecurityPrices(requestPayload2);
    }
  }, [fetchSecurityPriceStatistics, fetchSecurityPriceChart, fetchSecurityPrices, params.securityId]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  function onSearchClicked() {
    if (params.securityId) {
      const requestPayload: ThunkParameters = {
        securityId: parseInt(params.securityId),
        dates: {
          dateFrom: parameters.dates.dateFrom,
          dateTo: parameters.dates.dateTo,
        },
      };
      fetchSecurityPriceStatistics(requestPayload);
      fetchSecurityPriceChart(requestPayload);
    }
  }

  const handleGridActions = (pagedRequest: PagedRequest) => {
    if (params.securityId) {
      fetchSecurityPrices({ pagination: pagedRequest, securityId: parseInt(params.securityId) });
    }
  };

  const exportCSV = () => {
    const paginationExport = {
      pageNumber: 1,
      pageSize: 25000000,
      queryFields: [
        {
          fieldName: 'date',
          descendingSortDirection: true,
          operator: '',
          hasSearchTerm: false,
          isSortTerm: true,
          searchTerm: '',
        },
      ],
    };
    if (params.securityId) {
      fetchSecurityPricesExport({ pagination: paginationExport, securityId: parseInt(params.securityId) });
    }
  };

  return (
    <Container style={{ paddingTop: '20px', paddingBottom: '20px' }}>
      <Box display={'flex'} justifyContent={'space-between'} style={{ padding: '10px 0' }}>
        <Box paddingRight={'10px'}></Box>
        <Box display={'flex'} justifyContent={'space-between'}>
          <DateRangePicker dateRange={parameters.dates} setDateRange={setDateParameter} />
          <Box paddingRight={'10px'}></Box>
          <Box>
            <SearchButton onClick={onSearchClicked} />
          </Box>
          <Box>
            <WO2Button
              type="button"
              data-testid="exportPriceButton"
              variant={'contained'}
              startIcon={<AddIcon />}
              onClick={() => {
                exportCSV();
              }}
            >
              Export
            </WO2Button>
          </Box>
        </Box>
      </Box>
      <Grid container spacing={2} style={{ paddingBottom: '10px' }}>
        <Grid item xs={3}>
          <CardComponent
            {...props}
            progress={props.securityPriceStatisticsProgress}
            background={theme.palette.gradient1?.main}
            value={formatDollars(priceStatistics?.lastPrice)}
            subText={'Last Price'}
          />
        </Grid>
        <Grid item xs={3}>
          <CardComponent
            {...props}
            progress={props.securityPriceStatisticsProgress}
            background={theme.palette.gradient2?.main}
            value={formatDollars(priceStatistics?.minimumPrice)}
            subText={'Min Price'}
          />
        </Grid>
        <Grid item xs={3}>
          <CardComponent
            {...props}
            progress={props.securityPriceStatisticsProgress}
            background={theme.palette.gradient1?.main}
            value={formatDollars(priceStatistics?.maximumPrice)}
            subText={'Max Price'}
          />
        </Grid>
        <Grid item xs={3}>
          <CardComponent
            {...props}
            progress={props.securityPriceStatisticsProgress}
            background={theme.palette.gradient2?.main}
            value={formatPercentage(priceStatistics?.capitalReturn)}
            subText={'Capital Return'}
          />
        </Grid>
      </Grid>

      <PriceChart {...props} />

      <Grid container spacing={2} style={{ paddingTop: '15px', paddingLeft: '10px' }}>
        <SecurityListTable
          pagedRequest={parameters.pagination}
          pagedResults={securityPrices || undefined}
          progressPrices={securityPriceStatisticsProgress}
          handlePagedRequest={handleGridActions}
          handleDownload={(pagedRequest: PagedRequest, mapper: (securityPriceItems: SecurityPriceItem[]) => SecurityPriceCsvRow[]) =>
            downloadSecurityPrices({
              pagination: pagedRequest,
              securityId: parseInt(params.securityId ?? ''),
              mapper,
            })
          }
        ></SecurityListTable>
      </Grid>
    </Container>
  );
};
