import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React from 'react';
import { ClientAccountSubType, ClientAccountType } from '../../../../../../common/types';
import { MainDetails } from '../../../common/store/types';
import { Status } from '../store';

export interface Props {
  clientId: number;
  details: MainDetails;
}

export const Header = (props: Props): JSX.Element => {
  return (
    <>
      <Typography variant="h4" style={{ paddingBottom: '20px' }}>
        Main
      </Typography>
      <TableContainer component={Paper} elevation={0}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left">
                <Typography variant="h6" color="textSecondary">
                  CLIENT ID
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography variant="h6" color="textSecondary">
                  ACCOUNT TYPE
                </Typography>
              </TableCell>
              {!!props.details.subTypeId && (
                <TableCell align="left">
                  <Typography variant="h6" color="textSecondary">
                    SUB TYPE
                  </Typography>
                </TableCell>
              )}
              <TableCell align="left">
                <Typography variant="h6" color="textSecondary">
                  STATUS
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="left">
                <Typography variant="h5">{props.clientId}</Typography>
              </TableCell>
              <TableCell align="left">
                <Typography variant="h5">{ClientAccountType.getById(props.details.accountTypeId)?.displayName}</Typography>
              </TableCell>
              {!!props.details.subTypeId && (
                <TableCell align="left">
                  <Typography variant="h5">{ClientAccountSubType.getById(props.details.subTypeId)?.displayName}</Typography>
                </TableCell>
              )}
              <TableCell align="left">
                <Typography variant="h5">{Status.getById(props.details.status)?.displayName}</Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
