import { FormControlLabel, Box } from '@mui/material';
import React from 'react';
import { WO2Checkbox } from 'src/common';
import DateRangePicker from 'src/common/components/DateRangePicker';
import { ClientReportsParameters, MdaReportTypes } from '../../store/types';
import { DateRange } from 'src/store';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';

export interface UnrealisedNonCGTGainsAndLossesProps {
  parameters: ClientReportsParameters;
  setAsAtDateUnrealisedNonCGTParameter: ActionCreatorWithPayload<DateRange, string>;
  setUnrealisedNonCGTSelectedParameter: ActionCreatorWithPayload<boolean, string>;
  createReportSortRequest: (isAdd: boolean, id: number, text: string) => void;
}

export const UnrealisedNonCGTGainsAndLosses = ({
  parameters,
  setUnrealisedNonCGTSelectedParameter,
  setAsAtDateUnrealisedNonCGTParameter,
  createReportSortRequest,
}: UnrealisedNonCGTGainsAndLossesProps): JSX.Element => {
  const handleClickSelect = () => {
    setUnrealisedNonCGTSelectedParameter(!parameters.selectedReports.unrealisedNonCGT.selected);
    createReportSortRequest(
      !parameters.selectedReports?.unrealisedNonCGT.selected,
      MdaReportTypes.UnrealisedNonCgt.id,
      MdaReportTypes.UnrealisedNonCgt.displayName
    );
  };

  const handleClickDate = (dates: DateRange) => {
    setAsAtDateUnrealisedNonCGTParameter(dates);
  };

  return (
    <Box ml={6} justifyContent="space-between" display="flex" alignItems="center" mt={1} mb={1}>
      <FormControlLabel
        aria-label="Acknowledge"
        onClick={(event) => event.stopPropagation()}
        onFocus={(event) => event.stopPropagation()}
        control={
          <WO2Checkbox
            color="primary"
            checked={parameters.selectedReports?.unrealisedNonCGT.selected}
            onChangeHandler={handleClickSelect}
          />
        }
        label="Unrealised Non-CGT Gains and Losses"
      />
      <DateRangePicker
        sx={{ minWidth: 194 }}
        dateRange={{
          dateFrom: parameters?.selectedReports.unrealisedNonCGT?.asAtDate,
          dateTo: new Date().toISOString(),
          dateRangeType: parameters?.selectedReports.unrealisedNonCGT?.asAtDateType,
        }}
        setDateRange={handleClickDate}
        isDateOnly
      />
    </Box>
  );
};
