import { ResultWrapper } from '../../benefitStatement/store/types';

export interface PensionDetailStatement {
  details: ResultWrapper<PensionDetail | null>;
  bankDetails: ResultWrapper<PensionBankAccountDetails | null>;
}

export interface PensionDetail {
  accountId?: string;
  pensionType?: string;
  reviewOption?: string;
  paymentAmount?: number;
  nextPaymentDate?: string;
  frequency?: string;
  totalPeriodicPension?: number;
  minimumPension?: number;
  maximumPension?: number;
}

export interface PensionBankAccountDetails {
  bsb?: string;
  accountNo?: string;
  accountName?: string;
}

export enum BluedoorPaymentFrequency {
  Fortnightly = 'F',
  Monthly = 'I',
  Quarterly = 'Q',
  HalfYearly = 'H',
  Yearly = 'Y',
}
