import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../../../app/api';
import { GetPreApprovalBulkValidationRequest, ValidateOrderResultPayload, ValidationOrderResult } from '../../types';
import { FetchPortfolioSecurityHoldingsParameters, PortfolioSecurityHoldingResult, ValidationResult } from './types';

export enum OrderEditActionTypes {
  ValidateBulkOrder = '@@bulk/order/edit/ValidateBulkOrder',
  FetchSecurities = '@@bulk/order/edit/FetchSecurities',
  FetchPortfolioSecurityHoldings = '@@bulk/order/edit/FetchPortfolioSecurityHoldings',
}

export enum OrderEditApi {
  ValidateBulkOrder = '/rebalancesmanual/GetPreapprovalBulkValidation',
  FetchSecurities = '/securities/GetSecuritiesBySearchTerm',
  FetchPortfolioSecurityHoldings = '/bff/GetPortfolioSecurityHoldings',
}

export const fetchPortfolioSecurityHoldings = createAsyncThunk(
  OrderEditActionTypes.FetchPortfolioSecurityHoldings,
  async (payload: FetchPortfolioSecurityHoldingsParameters) => {
    const response = await api.post<PortfolioSecurityHoldingResult>(
      `${OrderEditApi.FetchPortfolioSecurityHoldings}`,
      payload
    );
    return response.data;
  }
);

export const validateBulkOrder = createAsyncThunk<
  ValidateOrderResultPayload,
  GetPreApprovalBulkValidationRequest,
  { rejectValue: ValidateOrderResultPayload }
>(OrderEditActionTypes.ValidateBulkOrder, async (payload: GetPreApprovalBulkValidationRequest, thunkApi) => {
  try {
    const result = await Promise.resolve<ValidationOrderResult>(
      (
        await api.post<ValidationOrderResult>(`${OrderEditApi.ValidateBulkOrder}`, payload)
      ).data
    );
    return result && ((result.errors && result.errors.length > 0) || (result.warnings && result.warnings.length > 0))
      ? thunkApi.rejectWithValue({
          order: payload.preApprovalInvestmentServices,
          investmentServicesLockedByOrders: result.investmentServicesLockedByOrders,
          validationResult: { errors: result.errors, warnings: result.warnings, information: [] },
        })
      : {
          order: payload.preApprovalInvestmentServices,
          investmentServicesLockedByOrders: result.investmentServicesLockedByOrders,
          validationResult: { errors: result.errors, warnings: result.warnings, information: [] },
        };
  } catch (e) {
    const result: ValidationResult = { errors: [`${e.status} - ${e.statusText}`], warnings: [], information: [] };
    return thunkApi.rejectWithValue({
      order: payload.preApprovalInvestmentServices,
      investmentServicesLockedByOrders: [],
      validationResult: result,
    });
  }
});
