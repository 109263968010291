import * as React from 'react';
import { ConfirmationDialog, ConfirmationOptions } from '.';

const ConfirmationServiceContext = React.createContext<(options: ConfirmationOptions) => Promise<void>>(Promise.reject);

export const useConfirmation = (): ((options: ConfirmationOptions) => Promise<void>) =>
  React.useContext<(options: ConfirmationOptions) => Promise<void>>(ConfirmationServiceContext);

export const ConfirmationServiceProvider = (routerComponent?: { children: JSX.Element }): JSX.Element => {
  const [confirmationState, setConfirmationState] = React.useState<ConfirmationOptions | null>(null);

  const awaitingPromiseRef = React.useRef<{
    resolve: () => void;
    reject: () => void;
  }>();

  const openConfirmation = (options: ConfirmationOptions) => {
    setConfirmationState(options);
    return new Promise<void>((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject };
    });
  };

  const handleClose = () => {
    if (!!confirmationState && confirmationState.catchOnCancel && awaitingPromiseRef.current) {
      awaitingPromiseRef.current.reject();
    }

    setConfirmationState(null);
  };

  const handleSubmit = () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve();
    }

    setConfirmationState(null);
  };
  return (
    <>
      <ConfirmationServiceContext.Provider value={openConfirmation}>{routerComponent?.children}</ConfirmationServiceContext.Provider>
      {!!confirmationState && <ConfirmationDialog open={Boolean(confirmationState)} onConfirm={handleSubmit} onCancel={handleClose} {...confirmationState} />}
    </>
  );
};
