import { Enumeration } from './enumeration';

export class ClientAccountSubType extends Enumeration {
  static NonSuper = new ClientAccountSubType(1, 'NonSuper', 'Non Super');
  static Super = new ClientAccountSubType(2, 'Super', 'Super');
  static Pension = new ClientAccountSubType(3, 'Pension', 'Pension');

  static getById = (subTypeId: number | null): ClientAccountSubType | undefined => {
    return Object.values(ClientAccountSubType)
      .filter((t) => t instanceof ClientAccountSubType)
      .find((t: ClientAccountSubType) => t.id === subTypeId);
  };

  static getByName = (name: string): ClientAccountSubType | undefined => {
    return Object.values(ClientAccountSubType)
      .filter((t) => t instanceof ClientAccountSubType)
      .find((t: ClientAccountSubType) => t.name.toLowerCase() === name.toLowerCase());
  };

  static getArray = (): ClientAccountSubType[] => [
    ClientAccountSubType.NonSuper,
    ClientAccountSubType.Super,
    ClientAccountSubType.Pension,
  ];
}
