import 'date-fns';
import React, { useEffect } from 'react';
import { AddressTable } from '../../../common/components/contactDetails/addressTable';
import { AddressDetails } from '../../../common/store/types';
import { Props } from '../container';

export const Addresses = (props: Props): JSX.Element => {
  const {
    hasClientEditPermission,
    clientId,
    addresses,
    fetchAddresses,
    deleteAddress,
    loadingProgress,
    savingProgress,
    setEdit,
    selectedAddress,
    updateAddress,
    clearEdit,
    availableAddressTypes,
  } = props;

  useEffect(() => {
    if (!!clientId) {
      fetchAddresses(clientId);
    }
  }, [fetchAddresses, clientId]);

  const save = (address: AddressDetails) => {
    if (!!clientId) {
      updateAddress({ ...address, clientId });
    }
  };

  const onDelete = (addressId: number) => {
    if (!!clientId) {
      deleteAddress({ clientId, addressId });
    }
  };

  const addEdit = (id: number | null) => {
    setEdit(id);
  };

  const clearAddress = () => {
    clearEdit();
  };

  return (
    <AddressTable
      hideAllActions={!hasClientEditPermission}
      addresses={addresses}
      selectedAddress={selectedAddress}
      loadingProgress={loadingProgress}
      savingProgress={savingProgress}
      addressTypes={availableAddressTypes}
      save={save}
      delete={onDelete}
      cancel={clearAddress}
      setAddEdit={addEdit}
    ></AddressTable>
  );
};
