import { Alert, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React from 'react';
import { formatNumberCommaSeparated } from 'src/common';
import { ResultWrapper } from '../../benefitStatement/store/types';
import { IncomeProtectionInsurance } from '../store/types';

export interface Props {
  details: ResultWrapper<IncomeProtectionInsurance | null>;
}

export const IncomeProtectionDetail = ({ details }: Props): JSX.Element => {
  return (
    <>
      <Typography variant="h4" style={{ padding: '20px 0' }}>
        <b>Income Protection Insurance</b>
      </Typography>

      {details.status === 200 ? (
        <Paper elevation={5} style={{ marginBottom: '30px' }}>
          <TableContainer component={Paper} elevation={0}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="left" style={{ width: '70%' }}>
                    <Typography variant="h6" color="textSecondary">
                      DESCRIPTION
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="h6" color="textSecondary">
                      VALUE ($)
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="left">
                    <Typography variant="h5">Waiting Period</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography data-testid="waitingPeriodData" variant="h5">
                      {details?.responseData?.waitingPeriod}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">
                    <Typography variant="h5">Payment Period</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography data-testid="coverPeriodData" variant="h5">
                      {details?.responseData?.coverPeriod}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">
                    <Typography variant="h5">Amount of Cover</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography data-testid="totalSCSumInsuredData" variant="h5">
                      ${`${formatNumberCommaSeparated(details?.responseData?.totalSCSumInsured || 0, 2)}`}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      ) : (
        <Alert style={{ marginRight: '20px', marginTop: '20px', marginBottom: '20px' }} variant="outlined" severity="info">
          <Typography>
            There is currently no income protection information for this member. If the member has been recently established it may take a few days for this
            data to be populated.
          </Typography>
        </Alert>
      )}
    </>
  );
};
