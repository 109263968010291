import { FormControl, InputLabel, OutlinedInput } from '@mui/material';
import { default as React, useEffect, useRef, useState } from 'react';
import NumberFormat from 'react-number-format';

interface Props {
  label?: string;
  value: number | null;
  prefix?: string;
  onChange: (value: number | null) => void;
}

export const NumericInput = (props: Props): JSX.Element => {
  const [labelWidth, setLabelWidth] = useState(0);
  const inputLabel: React.RefObject<HTMLLabelElement> | null | undefined = useRef(null);
  const { label, value, prefix, onChange } = props;

  useEffect(() => {
    if (inputLabel && inputLabel.current) {
      setLabelWidth(inputLabel.current.offsetWidth);
    }
  }, []);

  return (
    <FormControl variant="outlined" style={{ width: '100%' }}>
      <InputLabel ref={inputLabel} id="operator-select-label">
        {label}
      </InputLabel>
      <NumberFormat
        value={value ?? undefined}
        name="value"
        type="text"
        inputProps={{ labelwidth: labelWidth }}
        customInput={OutlinedInput}
        thousandSeparator
        onValueChange={(event) => {
          onChange(Number(event.value));
        }}
        prefix={prefix ?? ''}
      />
    </FormControl>
  );
};
