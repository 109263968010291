import { connect, ConnectedProps } from 'react-redux';
import { selectClientId } from 'src/features/clients/client/common/store/selectors';
import { selectCurrentInvestmentVersionId } from 'src/features/clients/client/portfolio/store/common/selectors';
import { isLoadingSelectorFactory } from '../../../../common/store/selectors/isLoadingSelectorFactory';
import { RootState } from '../../../../reducers';
import { selectHasClientEditAdminOnly, selectHasRebalanceEnhancedTrading } from '../../../../store/selectors';
import Edit from './components/edit';
import { importTradesAction } from './store/sagas/importSaga';
import {
  selectExportTrades,
  selectFilter,
  selectGroupedByKey,
  selectGroupedFilteredTrades,
  selectIgnoreMinimumTrade,
  selectImportErrors,
  selectIsPreApproved,
  selectRebalance,
  selectRebalanceKey,
  selectRebalanceMode,
  selectRebalanceValidationRequest,
  selectReceivingAccount,
  selectSaleMethod,
  selectSecurity,
  selectSecuritySearchResults,
  selectSummary,
  selectTargetCash,
  selectValidationMessages,
  selectWithdrawalMethod,
  selectWithdrawCash,
  selectWithdrawValue,
} from './store/selectors';
import { rebalanceEditSlice } from './store/slice';
import {
  deleteRebalance,
  fetchRebalance,
  fetchSecurities,
  fetchSecurity,
  fetchWithdrawalDetailsData,
  validateRebalance,
} from './store/thunks';

import { fetchInvestmentServiceVersionsByClient } from 'src/features/clients/client/portfolio/store/common/thunks';

const mapStateToProps = (state: RootState) => ({
  rebalance: selectRebalance(state),
  filteredTrades: selectGroupedFilteredTrades(state),
  rebalanceKey: selectRebalanceKey(state),
  filter: selectFilter(state),
  summary: selectSummary(state),
  isImporting: isLoadingSelectorFactory([importTradesAction.actionName])(state),
  validationMessages: selectValidationMessages(state),
  groupedByKey: selectGroupedByKey(state),
  isPreApproved: selectIsPreApproved(state),
  selectedSecurity: selectSecurity(state),
  securitySearchResults: selectSecuritySearchResults(state),
  rebalanceValidationRequest: selectRebalanceValidationRequest(state),
  selectedExportTrades: selectExportTrades(state),
  importErrors: selectImportErrors(state),
  hasRebalanceEnhancedTrading: selectHasRebalanceEnhancedTrading(state),
  hasClientEditAdminOnlyPermission: selectHasClientEditAdminOnly(state),
  rebalanceMode: selectRebalanceMode(state),
  ignoreMinimumTrade: selectIgnoreMinimumTrade(state),
  saleMethod: selectSaleMethod(state),
  targetCash: selectTargetCash(state),
  withdrawalMethod: selectWithdrawalMethod(state),
  withdrawCash: selectWithdrawCash(state),
  receivingAccount: selectReceivingAccount(state),
  withdrawValue: selectWithdrawValue(state),
  clientId: selectClientId(state),
  portfolioVersionId: selectCurrentInvestmentVersionId(state),
});

const importTrades = importTradesAction.pending;
const mapDispatchToProps = {
  ...rebalanceEditSlice.actions,
  deleteRebalance,
  fetchRebalance,
  fetchSecurities,
  fetchSecurity,
  validateRebalance,
  importTrades,
  fetchWithdrawalDetailsData,
  fetchInvestmentServiceVersionsByClient,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector>;
export const RebalanceEditContainer = connect(mapStateToProps, mapDispatchToProps)(Edit);
