import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FeeMethod } from '../../../../common/enums';
import { AdviceFeesService } from '../adviceFeesService';
import { createAdviceFee, fetchActiveAdviceFees, fetchAdviceServicesList, fetchAFSLFeesList, fetchPendingAdviceFees } from './thunks';
import {
  AdviceFeesState,
  AdviceTieredFeeDetails,
  AFSLFeesListResponse,
  FetchedAdviceServicesListModel,
  FetchedFeeForClientModel,
  FetchedPendingFeeForClientModel,
} from './types';

export const initialState: AdviceFeesState = {
  pendingFeesChangeSet: undefined,
  pendingFees: {
    items: [],
  },
  activeFees: {
    items: [],
  },
  editingTieredFeeDetails: {
    items: [],
  },
  isEditingAdviceServiceList: false,
  adviceServicesList: [],
  afslFeesList: [],
  isSubmitted: undefined,
  isEditingActiveFee: undefined,
};

export const adviceFeesSlice = createSlice({
  name: 'adviceFees',
  initialState,
  reducers: {
    toggleEditingAdviceServiceList: (state) => {
      state.isEditingAdviceServiceList = !state.isEditingAdviceServiceList
    },
    toggleAdviceFeeConsent: (state, action: PayloadAction<number>) => {
      const adviceFee = state.adviceServicesList.find((fee) => fee.id === action.payload);

      if (!adviceFee) return;
      adviceFee.isSelected = !adviceFee.isSelected;
    },
    setPendingFeeEdit: (state, action: PayloadAction<number | null | undefined>) => {
      // need to copy data from `estimatedFees.items` to editingTieredFeeDetails for tiered type
      if (typeof action.payload === 'number') {
        const feeItemId = action.payload;
        const feeItemIndex = state.pendingFees.items.findIndex((item) => item.feeId === feeItemId);
        if (feeItemIndex >= 0 && state.pendingFees.items[feeItemIndex] && FeeMethod.Tiered.id === state.pendingFees.items[feeItemIndex].methodId) {
          state.editingTieredFeeDetails.items = state.pendingFees.items[feeItemIndex].tieredFeeDetails.items;
        }

        state.pendingFees.edit = feeItemIndex >= 0 ? feeItemIndex : 0;
      }
      state.isEditingActiveFee = false;
    },
    setActiveFeeEdit: (state, action: PayloadAction<number | null | undefined>) => {
      // need to copy data from `estimatedFees.items` to editingTieredFeeDetails for tiered type
      if (typeof action.payload === 'number') {
        const feeItemId = action.payload;
        const feeItemIndex = state.activeFees.items.findIndex((item) => item.feeId === feeItemId);
        if (feeItemIndex >= 0 && state.activeFees.items[feeItemIndex] && FeeMethod.Tiered.id === state.activeFees.items[feeItemIndex].methodId) {
          state.editingTieredFeeDetails.items = state.activeFees.items[feeItemIndex].tieredFeeDetails.items;
        }

        state.activeFees.edit = feeItemIndex >= 0 ? feeItemIndex : 0;
      }
      state.isEditingActiveFee = true;
    },
    setTieredFeeDetailsEditId: (state, action: PayloadAction<number | null | undefined>) => {
      state.editingTieredFeeDetails.edit = action.payload;
      state.editingTieredFeeDetails.items = state.editingTieredFeeDetails.items.filter((tieredFeeDetailsItem) => !!tieredFeeDetailsItem.id);
    },
    setTieredFeeDetailsAdd: (state, action: PayloadAction<AdviceTieredFeeDetails>) => {
      if (state.editingTieredFeeDetails.edit !== null) {
        state.editingTieredFeeDetails.edit = action.payload.id;
        state.editingTieredFeeDetails.items = [...state.editingTieredFeeDetails.items, action.payload];
      }
    },
    saveEditingTieredFeeDetails: (state, action: PayloadAction<AdviceTieredFeeDetails>) => {
      const tieredFeeDetailsIdEdit = state.editingTieredFeeDetails.edit;
      if (tieredFeeDetailsIdEdit !== undefined) {
        if (tieredFeeDetailsIdEdit !== null) {
          // edit
          const index = state.editingTieredFeeDetails.items.findIndex((item) => item.id === tieredFeeDetailsIdEdit);
          if (index >= 0) {
            state.editingTieredFeeDetails.items[index] = action.payload;
          }
        } else {
          // add tiered fee details
          // TODO: the `id` here is using items.length for now to bypass the id isNull check in InlineEditDataTable, need to change when backend is ready
          const tieredFeeDetailsItem = { ...action.payload, id: state.editingTieredFeeDetails.items.length };
          state.editingTieredFeeDetails.items.pop();
          state.editingTieredFeeDetails.items.push(tieredFeeDetailsItem);
        }
        state.editingTieredFeeDetails.edit = undefined;
      }
    },
    removeEditingTieredFeeDetails: (state, action: PayloadAction<number>) => {
      const feeItemIndex = state.editingTieredFeeDetails.items.findIndex((item) => item.id === action.payload);
      if (feeItemIndex >= 0) {
        state.editingTieredFeeDetails.items.splice(feeItemIndex, 1);
      }
    },
    clearEditingTieredFeeDetails: (state) => {
      state.editingTieredFeeDetails = { items: [] };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAdviceServicesList.fulfilled, (state, action: PayloadAction<FetchedAdviceServicesListModel>) => {
      state.adviceServicesList = action.payload.results;
    });
    builder.addCase(fetchPendingAdviceFees.fulfilled, (state, action: PayloadAction<FetchedPendingFeeForClientModel>) => {
        state.pendingFeesChangeSet = action.payload.results;
      state.pendingFees.items = AdviceFeesService.MapPendingFeesToAdviceEstimatedFees(action.payload.results?.pendingFees);
    });
    builder.addCase(fetchActiveAdviceFees.fulfilled, (state, action: PayloadAction<FetchedFeeForClientModel>) => {
      if (Array.isArray(action.payload.results)) {
        state.activeFees.items = AdviceFeesService.MapFeesForClientGroupModelToAdviceEstimatedFees(action.payload.results);
      }
    });
    builder.addCase(fetchAFSLFeesList.fulfilled, (state, action: PayloadAction<AFSLFeesListResponse>) => {
      if (Array.isArray(action.payload)) {
        state.afslFeesList = action.payload;
      }
    });
    builder.addCase(createAdviceFee.fulfilled, (state) => {
      // remove editingTieredFeeDetails data
      state.editingTieredFeeDetails = { items: [] };
      // clear estimatedFees.edit
      state.pendingFees.edit = undefined;
    });
  },
});
