import { Grid, Paper, Typography } from '@mui/material';
import React, { ReactElement } from 'react';
import { RouteComponentProps } from 'react-router-dom';

interface Props extends RouteComponentProps {
  tileSubText: string;
  tileText: string;
}

export const EntityDetailsCard = (props: Props): ReactElement => {
  return (
    <Paper style={{ display: 'inline-flex', paddingBottom: '20px' }} elevation={0} square>
      <Grid container style={{ margin: '30px 30px 0 0' }}>
        <Grid item xs={12}>
          <Typography variant="h3">{props.tileText}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4">{props.tileSubText}</Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};
