import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../reducers';
import { HoldingGroup, HoldingItem } from './types';

const selectHoldings = createSelector(
  (state: RootState) => state,
  (state) => state.holdings
);

export const selectConsolidatedSecurityHoldings = createSelector(selectHoldings, (holdingsState) => holdingsState.ConsolidatedSecurityHoldings);
export const selectAfsls = createSelector(selectHoldings, (holdings) => holdings.afsls.afsls);
export const selectSecurities = createSelector(selectHoldings, (holdings) => holdings.securities);
export const selectParameters = createSelector(selectHoldings, (holdingsState) => holdingsState.parameters);

export const leftTable = createSelector(selectHoldings, (holdingsState) => {
  const data: HoldingGroup[] = [];
  data.push({
    label: 'ALL',
    groupKey: 'ALL',
    value: holdingsState.ConsolidatedSecurityHoldings.value,
    weight: holdingsState.ConsolidatedSecurityHoldings.weight,
  });
  holdingsState.ConsolidatedSecurityHoldings.groups.forEach((item) => {
    data.push({
      label: item.label ? item.label : 'Direct',
      groupKey: item.groupKey,
      value: item.value,
      weight: item.weight,
    });
  });
  return data;
});

export const rightTable = createSelector(selectHoldings, (holdingsState) => {
  const newState = {
    groupKey: '' as string | null,
    value: 0 as number,
    weight: 0 as number,
    rows: [] as HoldingItem[],
  };
  const rows: HoldingItem[] = [];
  if (holdingsState.parameters.selectedGroup.groupKey === 'ALL') {
    newState.weight = holdingsState.ConsolidatedSecurityHoldings.weight;
    newState.value = holdingsState.ConsolidatedSecurityHoldings.value;
    newState.groupKey = holdingsState.ConsolidatedSecurityHoldings.groupKey;
    holdingsState.ConsolidatedSecurityHoldings.consolidatedSecurityHoldings.forEach((item) => {
      rows.push({
        securityId: item.securityId,
        securityCode: item.securityCode,
        securityName: item.securityName,
        securityPrice: item.securityPrice,
        units: item.units,
        value: item.value,
        weight: item.weight,
        numberOfClients: item.numberOfClients,
      });
    });
  } else if (holdingsState.parameters.selectedGroup.groupKey === 'Direct') {
    holdingsState.ConsolidatedSecurityHoldings.groups.forEach((item2) => {
      if (item2.groupKey === null || item2.groupKey === '' || item2.groupKey === 'Direct') {
        newState.weight = item2.weight;
        newState.value = item2.value;
      }
      if (item2.groupKey === '' || item2.groupKey === null || item2.groupKey === 'Direct') {
        item2.consolidatedSecurityHoldings.forEach((item3) => {
          rows.push({
            securityId: item3.securityId,
            securityCode: item3.securityCode,
            securityName: item3.securityName,
            securityPrice: item3.securityPrice,
            units: item3.units,
            value: item3.value,
            weight: item3.weight,
            numberOfClients: item3.numberOfClients,
          });
        });
      }
    });
  } else {
    holdingsState.ConsolidatedSecurityHoldings.groups.forEach((item4) => {
      if (item4.groupKey === holdingsState.parameters.selectedGroup.groupKey) {
        newState.weight = item4.weight;
        newState.value = item4.value;
      }
      item4.consolidatedSecurityHoldings.forEach((item5) => {
        if (item4.groupKey === holdingsState.parameters.selectedGroup.groupKey) {
          rows.push({
            securityId: item5.securityId,
            securityCode: item5.securityCode,
            securityName: item5.securityName,
            securityPrice: item5.securityPrice,
            units: item5.units,
            value: item5.value,
            weight: item5.weight,
            numberOfClients: item5.numberOfClients,
          });
        }
      });
    });
  }

  rows.sort(function (a, b) {
    if (a && a.securityName && b && b.securityName) {
      if (a.securityName < b.securityName) {
        return -1;
      }
      if (a.securityName > b.securityName) {
        return 1;
      }
    }
    return 0;
  });

  newState.rows = rows;

  return newState;
});

export const filteredResults = createSelector(rightTable, selectParameters, (results, parameters) =>
  results.rows?.filter(
    (i) =>
      parameters.filterText.trim() === '' ||
      i.securityCode?.toLowerCase().includes(parameters.filterText.toLowerCase()) ||
      i.securityName?.toLowerCase().includes(parameters.filterText.toLowerCase())
  )
);

export const totalValue = createSelector(selectHoldings, (holdingsState) => {
  let totalValue = 0;
  holdingsState.ConsolidatedSecurityHoldings.consolidatedSecurityHoldings.forEach((row) => {
    totalValue = totalValue + row.value;
  });
  return totalValue;
});
