import { FiberManualRecord as DotIcon } from '@mui/icons-material';
import { Grid, Paper, Theme, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { useEffect, useState } from 'react';
import { formatPercentage } from '../../../../common';
import { ModelAllocation, ModelItem } from '../store/types';

export interface Props {
  model: ModelItem;
  openModel: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
  },
  chart: {
    height: '100px',
  },
}));

type ChartSeriesOptions = Partial<Highcharts.SeriesOptionsType> & Record<string, string>;

export const Detailed = (props: Props): JSX.Element => {
  const classes = useStyles();

  const { model, openModel } = props;

  const [orderedAllocations, SetOrderedAllocations] = useState<ModelAllocation[]>([]);

  useEffect(() => {
    SetOrderedAllocations(
      (!model.otherSecuritiesAllocation
        ? model.topSecurityAllocations
        : [
            ...model.topSecurityAllocations,
            {
              securityId: 0,
              securityCode: 'Other',
              securityName: model.otherSecuritiesAllocation.name,
              value: model.otherSecuritiesAllocation.value,
            },
          ]) || []
    );
  }, [model]);

  const chartColors: string[] = ['#2c82be', '#429ff9', '#423cff', '#0080ff', '#000080', '#008ecc'];
  const chartOptions: Highcharts.Options = {
    chart: {
      type: 'bar',
      height: '100px',
    },
    title: {
      text: '',
    },
    colors: chartColors,
    plotOptions: {
      series: {
        stacking: 'percent',
        borderWidth: 0,
      },
    },
    legend: { enabled: false },
    xAxis: {
      visible: false,
      labels: {
        enabled: false,
      },
      minPadding: 0,
      maxPadding: 0,
    },
    yAxis: {
      visible: false,
      labels: {
        enabled: false,
      },
      reversedStacks: false,
    },
    credits: {
      enabled: false,
    },
    series: orderedAllocations.map((a) => {
      return {
        name: a.securityName,
        data: [a.value],
      };
    }) as Array<ChartSeriesOptions> as Array<Highcharts.SeriesOptionsType>,
    tooltip: {
      formatter: function (this: Highcharts.TooltipFormatterContextObject) {
        return `<span style="color:${this.color}">●</span> ${this.series.name}: <b>${this.percentage?.toFixed(2)}%</b><br/>`;
      },
    },
  };

  const OneMonthReturn: number = model.oneMonthReturn ?? 0;

  return (
    <Paper className={classes.paper}>
      <Grid container spacing={1} style={{ height: '60px', paddingBottom: '10px' }}>
        <Grid item xs={11}>
          <Typography
            variant="h3"
            color={'primary'}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              openModel();
            }}
          >
            {model.name}
          </Typography>
        </Grid>
        <Grid item xs={1}></Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography noWrap color={'textSecondary'} variant={'h5'}>
          1 MONTH RETURN
        </Typography>
        <Typography variant={'h5'} color={OneMonthReturn > 0 ? 'secondary' : OneMonthReturn < 0 ? 'error' : 'textPrimary'}>
          {formatPercentage(model.oneMonthReturn)}
        </Typography>
      </Grid>

      <div className={classes.chart}>{<HighchartsReact highcharts={Highcharts} options={chartOptions} />}</div>
      <Grid container style={{ height: '300px', display: 'flex', flexDirection: 'column' }}>
        {orderedAllocations.map((a, index) => (
          <Grid container spacing={1} key={`${model.modelId}-${a.securityId}`} style={{ height: '55px' }}>
            <Grid item xs={1}>
              <DotIcon htmlColor={chartColors[index]}></DotIcon>
            </Grid>
            <Grid item xs={8}>
              {!!a.securityCode && (
                <Tooltip title={a.securityCode ?? ''} arrow>
                  <Typography noWrap color={'primary'} variant={'h5'}>
                    {a.securityCode}
                  </Typography>
                </Tooltip>
              )}
              <Tooltip title={a.securityName ?? ''} arrow>
                <Typography noWrap color={'textSecondary'} variant={'h5'}>
                  {a.securityName}
                </Typography>
              </Tooltip>
            </Grid>
            <Grid item xs={3} style={{ textAlign: 'right', paddingRight: '25px' }}>
              <Typography variant={'h5'}>{formatPercentage(a.value)}</Typography>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
};
