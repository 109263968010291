import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';
import { SystemMinimumDate } from '../../../../common';
import { DateRange } from '../../../../store';
import { fetchConsolidatedSecurityHoldingsSummary, fetchSecurityExposure } from './thunks';
import { ConsolidatedSecurityHoldingsSummary, fetchPagedResults, SecurityAssociatedClientsState, SecurityExposureItem } from './types';

export const initialState: SecurityAssociatedClientsState = {
  parameters: {
    dateSelected: { dateFrom: DateTime.fromISO(SystemMinimumDate).toISODate(), dateTo: DateTime.now().toISODate() },
    pagination: {
      pageNumber: 1,
      pageSize: 25,
      queryFields: [
        {
          fieldName: 'investmentServiceCode',
          descendingSortDirection: false,
          operator: '',
          hasSearchTerm: false,
          isSortTerm: true,
          searchTerm: '',
        },
      ],
    },
  },
  consolidatedSecurityHoldingsSummary: {
    securityId: 0,
    fua: 0,
    portfolioCount: 0,
  },
  securityExposure: {
    pageNumber: 1,
    pageSize: 25,
    totalNumberOfPages: 100,
    totalNumberOfRecords: 10000,
    results: [],
  },
};

export const securityAssociatedClientsSlice = createSlice({
  name: '@@security/associatedClients',
  initialState,
  reducers: {
    setDateParameter: (state, action: PayloadAction<DateRange>) => {
      state.parameters.dateSelected = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchConsolidatedSecurityHoldingsSummary.fulfilled, (state, action: PayloadAction<ConsolidatedSecurityHoldingsSummary>) => {
      state.consolidatedSecurityHoldingsSummary = action.payload;
    });
    builder.addCase(fetchSecurityExposure.fulfilled, (state, action: PayloadAction<fetchPagedResults<SecurityExposureItem>>) => {
      state.securityExposure = action.payload.results;
      state.parameters.pagination = action.payload.pagination;
    });
  },
});
