import { Box, Button, Card, CardContent, CardHeader, Divider, Grid, ListItem, ListSubheader, Tooltip, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { formatDollars } from '../../../../../common';
import { LoadingIndicator } from '../../../../../common/components/LoadingIndicator';
import { DateTimeFormat, formatNumberCommaSeparated, getLocalDateTime, roundUnitsByMarketCode } from '../../../../../common/utils';
import { Transaction } from '../../transactions/store/types';
import { DashboardParameters, RecentTransactionsByDate, ThunkParameters } from '../store/types';

interface Props extends RouteComponentProps {
  hasErrors: boolean;
  isLoading: boolean;
  clientId: number | null;
  parameters: DashboardParameters;
  result?: Transaction[];
  fetchRecentTransactionsList: (arg: ThunkParameters) => void;
}

export const RecentTransactionsComponent = ({
  isLoading,
  hasErrors,
  clientId,
  parameters,
  fetchRecentTransactionsList,
  result,
  history,
}: Props): JSX.Element => {
  const viewAllTransactions = (clientId: number) => {
    history.push(`/client/${clientId}/transactions`);
  };

  useEffect(() => {
    if (!!clientId && !!parameters?.selectedPortfolio && parameters.selectedPortfolio.accountIdList.length > 0) {
      fetchRecentTransactionsList({ clientId, parameters });
    }
  }, [clientId, parameters, fetchRecentTransactionsList]);

  const getTransactionsByDate = (items?: Transaction[]): RecentTransactionsByDate => {
    if (!!items && !!items.length) {
      const result = items
        .slice()
        .sort((a, b) => {
          let returnValue = DateTime.fromISO(b.tradeDate).toMillis() - DateTime.fromISO(a.tradeDate).toMillis();

          if (returnValue === 0) {
            returnValue = a.securityCode.localeCompare(b.securityCode);
          }
          if (returnValue === 0) {
            returnValue = a.transactionValue ?? 0 - (b.transactionValue ?? 0);
          }

          return returnValue;
        })
        .slice(0, 10)
        .reduce((accumulator: RecentTransactionsByDate, item: Transaction) => {
          const tradeDate = getLocalDateTime(item.tradeDate, DateTimeFormat.LetterShortDate);

          if (!accumulator[tradeDate]) {
            accumulator[tradeDate] = [];
          }

          accumulator[tradeDate].push(item);

          return accumulator;
        }, {});

      return result;
    }

    return {};
  };

  const transactionsByDate = getTransactionsByDate(result);

  return (
    <Card elevation={0}>
      <CardHeader
        title={
          <Typography gutterBottom variant="h3">
            Recent Transactions
          </Typography>
        }
      />
      <Divider />
      <LoadingIndicator progress={{ isLoading, hasErrors }}>
        <CardContent>
          <Grid container spacing={2}>
            {Object.keys(transactionsByDate).map((tradeDate: string) => {
              const items = [];
              if (Object.keys(transactionsByDate).length > 0) {
                items.push(
                  <ListSubheader key={`${tradeDate}-h`}>
                    <Typography gutterBottom variant="h4" color={'textPrimary'}>
                      {tradeDate}
                    </Typography>
                  </ListSubheader>
                );
              }

              return transactionsByDate[tradeDate].reduce((accumulator: JSX.Element[], transaction: Transaction, index: number) => {
                const securityCode = transaction.securityCode.substring(0, transaction.securityCode.indexOf('.'));
                const marketCode = transaction.securityCode.substring(transaction.securityCode.indexOf('.') + 1);

                let priceLine = (
                  <Typography variant="h5" noWrap color={'textSecondary'} style={{ textAlign: 'right' }}>{`${formatNumberCommaSeparated(
                    roundUnitsByMarketCode(transaction.units, marketCode)
                  )} units @ ${formatDollars(transaction.unitPrice)}`}</Typography>
                );
                if (marketCode === 'ADI') {
                  priceLine = (
                    <Typography variant="h5" noWrap color={'textSecondary'} style={{ textAlign: 'right' }}>
                      {transaction.comments}
                    </Typography>
                  );
                }

                accumulator.push(
                  <ListItem key={`${transaction.tradeDate}-${index}`} disableGutters>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={5}>
                        <Typography variant="h5" color={'textSecondary'}>
                          {securityCode}
                        </Typography>
                        <Typography variant="h5" color={'primary'}>
                          {transaction.systemTransactionType}
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography variant="h5" style={{ textAlign: 'right' }}>
                          {formatDollars(transaction.transactionValue)}
                        </Typography>
                        <Tooltip title={priceLine ?? ''} arrow>
                          {priceLine}
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </ListItem>
                );
                return accumulator;
              }, items);
            })}
            <Grid item xs={12}>
              <Divider />
              <Box paddingTop={'20px'} display={'flex'} justifyContent={'center'}>
                <Button
                  className={'ShowMore'}
                  onClick={() => {
                    if (!!clientId) {
                      viewAllTransactions(clientId);
                    }
                  }}
                >
                  View All Transactions
                </Button>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </LoadingIndicator>
    </Card>
  );
};
