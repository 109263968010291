/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';
import { SystemMinimumDate } from '../../../common';
import { DateRange } from '../../../store';
import { fetchAfsls, FetchConsolidatedSecurityHoldings, fetchSecurities } from './thunks';
import { AdviserItem, AfslItem, AfslViewModel, ConsolidatedSecurityHoldings, Grouping, HoldingsState, PracticeItem, SelectedGroup, SimpleSecurityItem } from './types';

export const initialState: HoldingsState = {
  afsls:{
    afsls:[],
  },
  securities:[],
  parameters: {
    groupingSelected: Grouping.AssetClassSecurity,
    selectedSecuity:null,
    selectedGroup: {
      label: 'ALL',
      groupKey: '',
    },
    exportDateSelected: {
      dateFrom: DateTime.fromISO(SystemMinimumDate).toISODate(),
      dateTo: DateTime.now().toISODate(),
    },
    dateSelected: {
      dateFrom: DateTime.fromISO(SystemMinimumDate).toISODate(),
      dateTo: DateTime.now().toISODate(),
    },
    filterText: '',
    afsls: [],
    practices: [],
    advisers: [],
  },
  ConsolidatedSecurityHoldings: {
    groupKey: '',
    label: '',
    value: 0,
    weight: 0,
    groups: [],
    consolidatedSecurityHoldings: [],
  },
  ConsolidatedHoldings: { headers: [], rows: [] },
};

export const holdingsSlice = createSlice({
  name: '@@holdings',
  initialState,
  reducers: {
    setAfslParameter: (state, action: PayloadAction<AfslItem[]>) => {
      state.parameters.afsls = action.payload;
    },
    setPracticeParameter: (state, action: PayloadAction<PracticeItem[]>) => {
      state.parameters.practices = action.payload;
    },
    setAdviserParameter: (state, action: PayloadAction<AdviserItem[]>) => {
      state.parameters.advisers = action.payload;
    },
    setSecurityParameter: (state, action: PayloadAction<SimpleSecurityItem | null>) => {
      state.parameters.selectedSecuity = action.payload;
    },
    setDateSelectedParameter: (state, action: PayloadAction<DateRange>) => {
      state.parameters.dateSelected = action.payload;
    },
    setExportDateSelectedParameter: (state, action: PayloadAction<DateRange>) => {
      state.parameters.exportDateSelected = action.payload;
    },    
    setFilterTextParameter: (state, action: PayloadAction<string>) => {
      state.parameters.filterText = action.payload;
    },
    setGroupingSelectedParameter: (state, action: PayloadAction<string>) => {
      let grouping: Grouping = Grouping.AssetClassSecurity;

      if (action.payload === 'Asset class, Security') {
        grouping = Grouping.AssetClassSecurity;
      } else if (action.payload === 'Adviser, Portfolio') {
        grouping = Grouping.AdviserPortfolio;
      } else if (action.payload === 'Model, Security') {
        grouping = Grouping.ModelSecurity;
      }
      state.parameters.groupingSelected = grouping;
    },
    setSelectedGroupParameter: (state, action: PayloadAction<SelectedGroup>) => {
      state.parameters.selectedGroup = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(FetchConsolidatedSecurityHoldings.fulfilled, (state, action: PayloadAction<ConsolidatedSecurityHoldings>) => {
      state.ConsolidatedSecurityHoldings = action.payload;
    });
    builder.addCase(fetchAfsls.fulfilled, (state, action: PayloadAction<AfslViewModel>) => {
      state.afsls = action.payload;
    });
    builder.addCase(fetchSecurities.fulfilled, (state, action: PayloadAction<SimpleSecurityItem[]>) => {
      state.securities = action.payload;
    });
  },
});
