import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../../app/api';
import { Associations, fetchStrategyAssociationsPayload } from './types';

export enum StrategyAssociationsActionTypes {
  FetchStrategyAssociations = '@@strategy/FetchStrategyExposure',
}

export enum StrategyAssociationsApiEndpoints {
  fetchStrategyAssociations = '/bff/GetStrategyExposure',
}

export const fetchStrategyAssociations = createAsyncThunk(
  StrategyAssociationsActionTypes.FetchStrategyAssociations,
  async (wrapper: fetchStrategyAssociationsPayload) => {
    const response = await api.get<Associations>(
      `${StrategyAssociationsApiEndpoints.fetchStrategyAssociations}?strategyVersionId=${wrapper.strategyVersionId}&isDataFromTriumph=${wrapper.isDataFromTriumph}`
    );
    return response.data;
  }
);
