import { Grid, Paper } from '@mui/material';
import { Field, Form, Formik, FormikProps } from 'formik';
import React, { useEffect, useState } from 'react';
import { CorporateType, TfnExemptions } from 'src/features/clients/common/enums';
import * as yup from 'yup';
import {
  EditCancelSaveButtons,
  FormikEnumerationSelect,
  FormikSwitch,
  FormikTextField,
  Mode,
  yupValidateAbn,
  yupValidateAcn,
  yupValidateTfn,
} from '../../../../../../../common/components/formik';
import { LoadingIndicator } from '../../../../../../../common/components/LoadingIndicator';
import { LoadingProgress } from '../../../../../../../common/store/types';
import history from '../../../../../../../history';
import { TrusteeDetails } from '../../store/types';

export interface Props {
  hasClientEditPermission: boolean;
  details?: TrusteeDetails | null;
  loadingProgress: LoadingProgress;
  saveProgress: LoadingProgress;
  onSave: (details: TrusteeDetails) => void;
}

export const DetailsForm = (props: Props): JSX.Element => {
  const { onSave, details, loadingProgress, saveProgress } = props;

  const initialFormValues: TrusteeDetails = {
    name: '',
    type: 'corporate',
    corporateTypeId: null,
    abn: '',
    acn: '',
    tfn: '',
    tfnExemptionId: null,
    natureOfBusiness: '',
    industryType: '',
    isResident: true,
  };

  const [formValues, setFormValues] = useState<TrusteeDetails>(initialFormValues);

  useEffect(() => {
    if (!!details) {
      setFormValues(details);
    }
  }, [details]);

  return (
    <Paper elevation={5}>
      <LoadingIndicator progress={loadingProgress}>
        <Formik<TrusteeDetails>
          enableReinitialize={true}
          initialValues={formValues}
          onSubmit={async (saveDetails) => {
            onSave(saveDetails);
          }}
          validationSchema={yup.object({
            id: yup.number().nullable(),
            name: yup.string().required('Name is required'),
            corporateTypeId: yup.number().nullable().required('Corporate type is required'),
            abn: yup.string().nullable().test('test-abn', 'Not a valid ABN Number', yupValidateAbn),
            acn: yup
              .string()
              .nullable()
              .test('test-acn', 'Not a valid ACN', function (this: yup.TestContext, acn: string | null | undefined): boolean {
                return !acn || yupValidateAcn(acn);
              }),
            tfn: yup.string().test('test-tfn', 'Not a valid Tax File Number', function (this: yup.TestContext, tfn: string | null | undefined): boolean {
              if (this.parent.tfnExemptionId) {
                return (!!tfn && TfnExemptions.isValidExemptionCode(tfn)) || yupValidateTfn(tfn);
              }

              return !tfn || yupValidateTfn(tfn);
            }),
            tfnExemptionId: yup.number().nullable(),
            natureOfBusiness: yup.string(),
            industryType: yup.string(),
            isResident: yup.boolean().required('Required'),
          })}
        >
          {(formikProps: FormikProps<TrusteeDetails>) => (
            <Form>
              <fieldset style={{ border: 'none' }}>
                <Grid container>
                  <Grid item xs={6}>
                    <Grid item xs={12} style={{ margin: '20px 10px' }}>
                      <Field component={FormikTextField} name="name" label="NAME" fullWidth />
                    </Grid>
                    <Grid item xs={12} style={{ margin: '20px 10px' }}>
                      <Field component={FormikEnumerationSelect} type={CorporateType} name="corporateTypeId" label="CORPORATE TYPE" valueIsId={true} />
                    </Grid>
                    <Grid item xs={12} style={{ margin: '20px 10px' }}>
                      <Field component={FormikTextField} name="natureOfBusiness" label="NATURE OF BUSINESS" fullWidth />
                    </Grid>
                    <Grid item xs={12} style={{ margin: '20px 10px' }}>
                      <Field component={FormikTextField} name="industryType" label="INDUSTRY TYPE" fullWidth />
                    </Grid>
                    <Grid item xs={12} style={{ margin: '20px 10px' }}>
                      <Field name={'isResident'} component={FormikSwitch} label="RESIDENT"></Field>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid item xs={12} style={{ margin: '20px 10px' }}>
                      <Field mask="99 999 999 999" component={FormikTextField} name="abn" label="AUSTRALIAN BUSINESS NUMBER (ABN)" fullWidth />
                    </Grid>
                    <Grid item xs={12} style={{ margin: '20px 10px' }}>
                      <Field mask="999 999 999" component={FormikTextField} name="acn" label="AUSTRALIAN COMPANY NUMBER (ACN)" fullWidth />
                    </Grid>
                    <Grid item xs={12} style={{ margin: '20px 10px' }}>
                      <Field
                        component={FormikEnumerationSelect}
                        type={TfnExemptions}
                        name="tfnExemptionId"
                        label="TFN EXEMPTION"
                        onChange={(value: number | null) => {
                          if (value === null) {
                            if (!!formikProps.values.tfn && TfnExemptions.isValidExemptionCode(formikProps.values.tfn)) {
                              formikProps.setFieldValue('tfn', null);
                            }
                          } else {
                            const tfnExemptionsCode = TfnExemptions.getTfnExemptionCodeById(value);
                            if (!!tfnExemptionsCode) {
                              formikProps.setFieldValue('tfn', tfnExemptionsCode.code);
                              formikProps.setFieldTouched('tfn', false, false);
                            }
                          }
                          formikProps.setFieldTouched('tfnExemptionId', false, false);
                        }}
                        valueIsId={true}
                      />
                    </Grid>
                    <Grid item xs={12} style={{ margin: '20px 10px' }}>
                      <Field
                        component={FormikTextField}
                        name="tfn"
                        label="TAX FILE NUMBER (TFN)"
                        mask="999 999 999"
                        disabled={!!formikProps.values.tfnExemptionId}
                        fullWidth
                        type="password"
                        autocomplete="new-password"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <EditCancelSaveButtons
                  mode={Mode.CancelSave}
                  handleCancelClick={() => {
                    history.push('../trustees');
                  }}
                  saveProgress={saveProgress}
                ></EditCancelSaveButtons>
              </fieldset>
            </Form>
          )}
        </Formik>
      </LoadingIndicator>
    </Paper>
  );
};
