import { Typography } from '@mui/material';
import React from 'react';
import { DeleteAccountPayload } from 'src/features/clients/client/details/accounts/store/types';
import { WO2Modal } from '../../Modal';

export interface Props {
  deleteAccountId: number | null;
  clientId: number | null | undefined;
  onDelete: (deleteAccountPayload: DeleteAccountPayload) => void;
  onCloseDelete: () => void;
}

export function DeleteAccount({ onCloseDelete, deleteAccountId, onDelete, clientId }: Props): JSX.Element {
  return (
    <WO2Modal
      MuiDialogProps={{
        open: true,
        onClose: () => {
          onCloseDelete();
        },
        maxWidth: 'sm',
      }}
      title={<b>Delete account</b>}
      actions={[
        {
          label: 'Close',
          onClickHandler: () => {
            onCloseDelete();
          },
          type: 'contained',
          disabled: false,
        },
        {
          label: 'Delete',
          onClickHandler: () => {
            if (deleteAccountId && clientId) {
              onDelete({
                clientId,
                pendingAccountId: deleteAccountId.toString(),
              });
              !!onCloseDelete && onCloseDelete();
            }
          },
          type: 'contained',
          disabled: false,
        },
      ]}
    >
      <>
        <Typography variant="h4" style={{ fontWeight: 700, paddingTop: '10px' }}>
          Are you sure you want to delete this account?
        </Typography>
        <p>This will remove the pending account and all it&apos;s details. This cannot be undone.</p>
      </>
    </WO2Modal>
  );
}
