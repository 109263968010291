import { Route } from '../../store';
import { CommonContainer } from './client/common/container';
import { ClientListStateContainer } from './list/container';
import { OnboardContainer } from './onboard/container';

export const clientRoutes: Route[] = [
  {
    path: '/client/list',
    component: ClientListStateContainer,
    protected: true,
    exact: true,
  },
  {
    path: '/client/onboard',
    component: OnboardContainer,
    protected: true,
    exact: false,
  },
  {
    path: '/client/:id',
    component: CommonContainer,
    exact: false,
  },
];
