import { validateABNNumber } from '../../../utils';

export const yupValidateAbn = (value: string | null | undefined): boolean => {
  const rawValue = (value || '').replace(/[\s-_]/g, '');

  if (!!rawValue) {
    return validateABNNumber(value);
  }
  return true;
};
