import React, { createContext } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { FeatureFlagsActionTypes, FetchFeatureFlagsRequestAction } from './actions';
// import { RootState } from '../../../reducers';
import { Feature } from './types';

export const FeatureFlagContext = createContext<Feature[]>([]);

export const FeatureFlagProvider: React.FC = ({ children }) => {
  // TODO - Enable feature flags when istio is ready
  const featureFlags = { features: [] };
  // const dispatch = useDispatch();
  // const featureFlags = useSelector((state: RootState) => state.featureFlags.featureFlags);
  // useEffect(() => {
  //     dispatch({ type: FeatureFlagsActionTypes.FetchFeatureFlagsRequest} as FetchFeatureFlagsRequestAction);
  // }, [dispatch]);

  return <FeatureFlagContext.Provider value={featureFlags.features}>{children}</FeatureFlagContext.Provider>;
};
export default FeatureFlagProvider;
