import { DateRange } from '../../../store';
import { TriumphBaseRequest } from '../../clients/client/portfolio/overview/store';

export enum Grouping {
  ModelSecurity = 'Model, Security',
  AssetClassSecurity = 'Asset class, Security',
  AdviserPortfolio = 'Adviser, Portfolio',
}

export interface SelectedGroup {
  label: string | null;
  groupKey: string | null;
}

export interface HoldingGroup {
  groupKey: string | null;
  label: string | null;
  value: number;
  weight: number;
}

export interface HoldingsParameters {
  groupingSelected: Grouping;
  selectedGroup: SelectedGroup;
  dateSelected: DateRange;
  filterText: string;
  afsls: AfslItem[],
  practices: PracticeItem[],
  advisers: AdviserItem[],
  selectedSecuity: SimpleSecurityItem | null;
  exportDateSelected: DateRange;
}

export interface HoldingItem {
  securityId: number;
  securityCode: string | null;
  securityName: string | null;
  securityPrice: number;
  units: number;
  value: number;
  weight: number;
  numberOfClients: number;
}

export interface GroupItem {
  groupKey: string | null;
  label: string | null;
  value: number;
  weight: number;
  consolidatedSecurityHoldings: HoldingItem[];
}

export interface ConsolidatedSecurityHoldings {
  groupKey: string | null;
  label: string | null;
  value: number;
  weight: number;
  groups: GroupItem[];
  consolidatedSecurityHoldings: HoldingItem[];
}

export interface ConsolidatedHoldingsFields {
  fields: (string | number)[];
}

export interface ConsolidatedHoldings {
  headers: string[];
  rows: ConsolidatedHoldingsFields[];
}

export interface HoldingsState {
  parameters: HoldingsParameters;
  ConsolidatedSecurityHoldings: ConsolidatedSecurityHoldings;
  ConsolidatedHoldings: ConsolidatedHoldings;
  afsls: AfslViewModel;
  securities: SimpleSecurityItem[];
  
}

export interface FetchClientListPayload extends TriumphBaseRequest {
  primaryGrouping: Grouping;
  date: string;
}

export interface FetchConsolidatedHoldingsPayload extends TriumphBaseRequest {
  date: string;
  licenseeIds: number[];
  practiceIds: number[];
  adviserIds: number[];
  securityIds: number[];
  afslId: number | null;
  practiceId: number | null;
  isDateFromTriumph: boolean;
}

export interface AfslViewModel {
  afsls: AfslItem[];
}

export interface AfslItem {
  afslId: number;
  afslName: string;
  practices: PracticeItem[];
}

export interface PracticeItem {
  practiceId: number;
  practiceName: string;
  advisors: AdviserItem[];
}

export interface AdviserItem {
  advisorId: number;
  advisorName: string;
}

export interface SimpleSecurityItem {
  securityId: number;
  securityCode: string;
  securityName: string;
}