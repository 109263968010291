import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../../app/api';
import { buildEncodedQueryString } from '../../../../common';
import { PagedResult } from '../../../../store';
import { fetchPagedResults, ModelItem, ModelListParameters, StrategyItem, StrategyListParameters } from './types';

export enum ModelListActionTypes {
  FetchModelList = '@@model/list/FetchModelList',
  FetchStrategyList = '@@strategy/list/FetchStrategyList',
}

export enum ModelListApiEndpoints {
  fetchModelList = '/bff/GetModels',
  fetchStrategyList = 'strategies/GetStrategies',
}

export const fetchModelList = createAsyncThunk(ModelListActionTypes.FetchModelList, async (wrapper: ModelListParameters) => {
  const payload = {
    pageNumber: wrapper.pagination.pageNumber,
    pageSize: wrapper.pagination.pageSize,
    codeOrNameSearch: wrapper.codeOrNameSearch,
    queryFields: [...wrapper.pagination.queryFields],
  };

  const response = await api.post<PagedResult<ModelItem>>(`${ModelListApiEndpoints.fetchModelList}`, payload);
  return {
    results: response.data,
    pagination: wrapper.pagination,
  } as fetchPagedResults<ModelItem>;
});

export const fetchStrategyList = createAsyncThunk(ModelListActionTypes.FetchStrategyList, async (wrapper: StrategyListParameters) => {
  const queryString = buildEncodedQueryString({
    PagedRequest: { ...wrapper.pagination, codeOrNameSearch: wrapper.codeOrNameSearch },
  });

  const response = await api.get<PagedResult<StrategyItem>>(`${ModelListApiEndpoints.fetchStrategyList}${queryString}`);
  return {
    results: response.data,
    pagination: wrapper.pagination,
  } as fetchPagedResults<StrategyItem>;
});
