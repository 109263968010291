import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { selectIsDataFromTriumph } from 'src/store/selectors';
import { progressSelectorFactory } from '../../../common';
import { RootState } from '../../../reducers';
import { DashboardComponent } from './components/DashboardComponent';
import {
  selectConsolidatedSecurityHoldingsSummary,
  selectDailySecurityPricesChart,
  selectParameters,
  selectSecurityDashboard,
  selectSecurityPrices,
} from './store/selectors';
import { securityDashboardSlice } from './store/slice';
import {
  fetchConsolidatedSecurityHoldingsSummary,
  fetchDailySecurityPricesChart,
  fetchSecurityDashboard,
  fetchSecurityPrices,
  SecurityDahboardActionTypes,
} from './store/thunks';

const mapStateToProps = (state: RootState) => ({
  parameters: selectParameters(state),
  consolidatedSecurityHoldingsSummary: selectConsolidatedSecurityHoldingsSummary(state),
  securityPrices: selectSecurityPrices(state),
  securityDashboard: selectSecurityDashboard(state),
  dailySecurityPricesChart: selectDailySecurityPricesChart(state),
  consolidatedSecurityHoldingsSummaryProgress: progressSelectorFactory([
    SecurityDahboardActionTypes.FetchConsolidatedSecurityHoldingsSummary,
  ])(state),
  dailySecurityPricesChartProgress: progressSelectorFactory([
    SecurityDahboardActionTypes.FetchDailySecurityPricesChart,
  ])(state),
  SecurityDashboardProgress: progressSelectorFactory([SecurityDahboardActionTypes.FetchSecurityDashboard])(state),
  SecurityPricesProgress: progressSelectorFactory([SecurityDahboardActionTypes.FetchSecurityPrices])(state),
  isDataFromTriumph: selectIsDataFromTriumph(state),
});

const mapDispatchToProps = {
  ...securityDashboardSlice.actions,
  fetchConsolidatedSecurityHoldingsSummary,
  fetchDailySecurityPricesChart,
  fetchSecurityDashboard,
  fetchSecurityPrices,
};

interface MatchParams {
  securityId?: string;
  securityCode?: string;
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector> & RouteComponentProps<MatchParams>;
export const SecurityDashboardStateContainer = connect(mapStateToProps, mapDispatchToProps)(DashboardComponent);
