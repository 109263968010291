import React, { useCallback, useEffect } from 'react';
import { TabControl } from '../../../../common';
import { securityTabRoutes } from '../../securityTabRoutes';
import { Props } from '../container';
import { EntityDetailsCard } from './EntityDetailsCard';

export const SecurityTabs = (props: Props): JSX.Element => {
  const {
    fetchSecurityDetails,
    match: { params },
  } = props;

  const securityId = Number(params.securityId ?? 0);

  const fetch = useCallback(
    (securityId: number) => {
      fetchSecurityDetails(securityId);
    },
    [fetchSecurityDetails]
  );

  useEffect(() => {
    fetch(securityId);
  }, [securityId, fetch]);

  return (
    <TabControl
      {...props}
      tileText={props.securityCommon.securityDetails.code}
      tileSubText={props.securityCommon.securityDetails.name}
      tileComponent={EntityDetailsCard}
      tabRoutes={securityTabRoutes.filter((r) => r.visible())}
    />
  );
};
