import React, { useCallback, useEffect } from 'react';
import { TabControl } from '../../../../../common';
import { getClientTabRoutes } from '../../clientTabRoutes';
import { Props } from '../container';
import { EntityDetailsCard } from './EntityDetailsCard';

export const ClientTabs = (props: Props): JSX.Element => {
  const {
    fetchClientPortfolios,
    fetchEntityDetails,
    fetchClientMainDetails,
    fetchClientAccounts,
    fetchSuperSimplifier,
    isSuperSimplifierClient,
    match: { params },
    setClientIdParameter,
    hasClientEditAdminOnlyPermission,
  } = props;
  const clientId = Number(params.id ?? 0);
  const fetch = useCallback(
    (clientId: number) => {
      setClientIdParameter(clientId);
      fetchClientPortfolios(clientId);
      fetchEntityDetails(clientId);
      fetchClientMainDetails(clientId);
      fetchClientAccounts(clientId);

      if (isSuperSimplifierClient) {
        fetchSuperSimplifier(clientId);
      }
    },
    [
      fetchEntityDetails,
      fetchClientMainDetails,
      fetchClientPortfolios,
      setClientIdParameter,
      fetchClientAccounts,
      fetchSuperSimplifier,
      isSuperSimplifierClient,
    ]
  );

  useEffect(() => {
    fetch(clientId);
  }, [clientId, fetch]);

  return (
    <TabControl
      {...props}
      tileComponent={EntityDetailsCard}
      tabRoutes={getClientTabRoutes(hasClientEditAdminOnlyPermission).filter((r) =>
        r.visible(hasClientEditAdminOnlyPermission)
      )}
    />
  );
};
