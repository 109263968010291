import { Box, Container, Grid } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import SearchButton from 'src/features/clients/client/common/components/SearchButton';
import { formatPercentage } from '../../../../common';
import { EnumSelect, WO2Menu } from '../../../../common/components';
import CardComponent from '../../../../common/components/CardComponent';
import DateRangePicker from '../../../../common/components/DateRangePicker';
import { theme } from '../../../../themes';
import { Props } from '../container';
import { FetchModelPerformancechartWithBenchmarkPayload, Frequency } from '../store/types';
import { PerformanceChart } from './performanceChart';
import { PeriodicSummaryTable } from './periodicSummaryTable';
import ArrowDropDownSharpIcon from '@mui/icons-material/ArrowDropDownSharp';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import { usePDF } from 'react-to-pdf';
import PdfHeader from 'src/features/clients/client/common/components/PdfHeader';
import PdfFooter from 'src/features/clients/client/common/components/PdfFooter';

export const PerformanceComponent = (props: Props): JSX.Element => {
  const {
    parameters,
    modelDetail,
    fetchModelPerformanceChartWithBenchmark,
    fetchModelPeriodicSummary,
    fetchModelDetail,
    setFrequencyParameter,
    setDateParameter,
    loadingProgresModelPerformancechartWithBenchmark,
    loadingProgresModelPeriodicSummary,
    modelPeriodicSummary,
    modelPerformancechartWithBenchmark,
    match: { params },
  } = props;

  const fetch = useCallback(() => {
    if (params.modelId) {
      const modelPerformancechartWithBenchmarkPayload: FetchModelPerformancechartWithBenchmarkPayload = {
        modelVersionId: parseInt(params.modelVersionId ?? ''),
        startDate: parameters.dates.dateFrom,
        endDate: parameters.dates.dateTo,
        frequency: parameters.frequency,
        strategyId: parameters.modelBenchmarkSelected?.id ?? 0,
      };
      fetchModelPerformanceChartWithBenchmark(modelPerformancechartWithBenchmarkPayload);

      if (params.modelVersionId) {
        fetchModelPeriodicSummary({
          modelVersionId: parseInt(params.modelVersionId),
          benchmarkStrategyId: parameters.modelBenchmarkSelected?.id ?? 0,
          endDate: parameters.dates.dateTo,
        });
        fetchModelDetail(parseInt(params.modelVersionId));
      }
    }
  }, [params.modelVersionId, fetchModelPeriodicSummary, fetchModelPerformanceChartWithBenchmark]);

  const [showPdfFooterAndHeader, setShowPdfFooterAndHeader] = useState(false);
  const { toPDF, targetRef } = usePDF({ filename: `${modelDetail?.name} - Performance.pdf` });

  useEffect(() => {
    if (showPdfFooterAndHeader) {
      toPDF();
      setShowPdfFooterAndHeader(false);
    }
  }, [showPdfFooterAndHeader]);

  const generatePdf = () => {
    setShowPdfFooterAndHeader(true);
  };

  useEffect(() => {
    fetch();
  }, [fetch]);

  function callModelEndpoints() {
    if (params.modelVersionId) {
      const modelPerformancechartWithBenchmarkPayload: FetchModelPerformancechartWithBenchmarkPayload = {
        modelVersionId: parseInt(params.modelVersionId ?? ''),
        startDate: parameters.dates.dateFrom,
        endDate: parameters.dates.dateTo,
        frequency: parameters.frequency,
        strategyId: parameters.modelBenchmarkSelected?.id ?? 0,
      };
      fetchModelPerformanceChartWithBenchmark(modelPerformancechartWithBenchmarkPayload);
      fetchModelPeriodicSummary({
        modelVersionId: parseInt(params.modelVersionId ?? ''),
        benchmarkStrategyId: parameters.modelBenchmarkSelected?.id ?? 0,
        endDate: parameters.dates.dateTo,
      });
    }
  }

  function onSearchClicked() {
    callModelEndpoints();
  }

  const bestLabel = parameters.frequency === 'Daily' ? 'Best Day' : 'Best Month';
  const worstLabel = parameters.frequency === 'Daily' ? 'Worst Day' : 'Worst Month';

  const exportButtons = [
    {
      icon: <PictureAsPdfOutlinedIcon />,
      label: 'Export to PDF',
      onClick: generatePdf,
      testId: `exportSecurityPerformanceAction`,
    },
  ];

  return (
    <Container style={{ paddingTop: '20px', paddingBottom: '20px' }}>
      <Box display={'flex'} justifyContent={'space-between'} style={{ padding: '10px 0' }}>
        <Box paddingRight={'10px'}></Box>
        <Box display={'flex'} justifyContent={'space-between'}>
        <WO2Menu
            testId="export_button_mod_perf"
            buttonTitle="Export"
            buttonIcon={
              <div
                style={{
                  paddingLeft: '10px',
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  backgroundColor: 'blue',
                  borderRadius: '25px',
                  padding: '10px 10px 10px 20px',
                  marginTop: '-7px',
                }}
              >
                <span style={{ color: '#ffffff', fontSize: '0.875rem' }}>EXPORT</span>
                <ArrowDropDownSharpIcon sx={{ color: '#ffffff' }} />
              </div>
            }
            items={exportButtons} />
          <DateRangePicker dateRange={parameters.dates} setDateRange={setDateParameter} />
          <Box paddingRight={'10px'}>
            <EnumSelect
              label="Frequency"
              enumType={Frequency}
              value={parameters?.frequency}
              onChange={(value) => {
                setFrequencyParameter(Frequency[value]);
              }}
            />
          </Box>
          <Box paddingRight={'10px'}></Box>
          <Box>
            <SearchButton onClick={onSearchClicked} />
          </Box>
        </Box>
      </Box>
      <div ref={targetRef} style={{ padding: '0 20px' }}>
        {showPdfFooterAndHeader && <PdfHeader clientName={`${modelDetail?.name} - Performance`} />}
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <CardComponent
              progress={loadingProgresModelPerformancechartWithBenchmark}
              background={theme.palette.gradient1?.main}
              value={formatPercentage(modelPerformancechartWithBenchmark?.capitalReturn)}
              subText={'Capital Return'}
            />
          </Grid>
          <Grid item xs={2}>
            <CardComponent
              progress={loadingProgresModelPerformancechartWithBenchmark}
              background={theme.palette.gradient2?.main}
              value={formatPercentage(modelPerformancechartWithBenchmark?.incomeReturn)}
              subText={'Income Return'}
            />
          </Grid>
          <Grid item xs={2}>
            <CardComponent
              progress={loadingProgresModelPerformancechartWithBenchmark}
              background={theme.palette.gradient1?.main}
              value={formatPercentage(modelPerformancechartWithBenchmark?.totalReturn)}
              subText={'Total Return'}
            />
          </Grid>
          <Grid item xs={2}>
            <CardComponent
              progress={loadingProgresModelPerformancechartWithBenchmark}
              background={theme.palette.gradient2?.main}
              value={formatPercentage(modelPerformancechartWithBenchmark?.bestDayReturn)}
              subText={bestLabel}
            />
          </Grid>
          <Grid item xs={2}>
            <CardComponent
              progress={loadingProgresModelPerformancechartWithBenchmark}
              background={theme.palette.gradient1?.main}
              value={formatPercentage(modelPerformancechartWithBenchmark?.worstDayReturn)}
              subText={worstLabel}
            />
          </Grid>
          <Grid item xs={2}>
            <CardComponent
              progress={loadingProgresModelPerformancechartWithBenchmark}
              background={theme.palette.gradient2?.main}
              value={formatPercentage(modelPerformancechartWithBenchmark?.annualVolatility)}
              subText={'A. Volatility'}
            />
          </Grid>
        </Grid>

        <PerformanceChart
          modelPerformancechartWithBenchmark={modelPerformancechartWithBenchmark}
          loadingProgresModelPerformancechartWithBenchmark={loadingProgresModelPerformancechartWithBenchmark}
          frequency={parameters.frequency}
        />

        <PeriodicSummaryTable
          modelDetail={modelDetail}
          parameters={parameters}
          loadingProgresModelPeriodicSummary={loadingProgresModelPeriodicSummary}
          modelPeriodicSummary={modelPeriodicSummary}
        />

        {showPdfFooterAndHeader && <PdfFooter />}
      </div>
    </Container>
  );
};
