/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../../../../../app/api';
import { buildEncodedQueryString } from '../../../../../../../common';
import { commonSlice, fetchInvestmentServiceVersionsByClient } from '../../../store/common';
import {
  BenchmarkItem,
  InvestmentServiceGetPayload,
  UpdateInvestmentServiceAssetClassTolerancesPayload,
  UpdateInvestmentServiceDetailsPayload,
  UpdateInvestmentServiceSucurityTolerancesPayload,
} from './types';

export enum MainDetailsActionTypes {
  FetchInvestmentService = '@@client/investmentServices/details/InvestmentService/Fetch',
  FetchBenchmarks = '@@client/investmentServices/details/Benchmarks/Fetch',
  UpdateInvestmentService = '@@client/investmentServices/details/InvestmentService/Update',
  UpdateInvestmentServiceSecurityTolerances = '@@client/investmentServices/details/InvestmentService/UpdateInvestmentServiceSucurityTolerances',
  UpdateInvestmentServiceAssetClassTolerances = '@@client/investmentServices/details/InvestmentService/UpdateInvestmentServiceAssetClassTolerances',
}

export enum MainDetailsApiEndpoints {
  FetchInvestmentService = '/investmentservices/GetInvestmentServiceVersion',
  FetchBenchmarkService = '/strategies/GetBenchmarksForAfsl',
  UpdateInvestmentService = '/investmentservices/CreateOrUpdateBasicDetails',
  UpdateInvestmentServiceSecurityTolerances = '/investmentservices/CreateOrUpdateSecurityTolerances',
  UpdateInvestmentServiceAssetClassTolerances = '/investmentservices/CreateOrUpdateAssetClassTolerances',
}

export const fetchInvestmentService = createAsyncThunk(
  MainDetailsActionTypes.FetchInvestmentService,
  async ({ clientId, investmentServiceVersionId }: InvestmentServiceGetPayload) => {
    const queryString = buildEncodedQueryString({
      clientId,
      investmentServiceVersionId,
    });
    const response = await api.get<any>(`${MainDetailsApiEndpoints.FetchInvestmentService}${queryString}`);

    return {
      ...response.data,
      clientPortalBenchmarks: response.data.clientPortalBenchmarks.map((b: any) => ({ id: b.productId, name: b.name })),
    };
  }
);

export const updateInvestmentService = createAsyncThunk(
  MainDetailsActionTypes.UpdateInvestmentService,
  async (payload: UpdateInvestmentServiceDetailsPayload, thunkApi) => {
    const response = await api.post<number>(`${MainDetailsApiEndpoints.UpdateInvestmentService}`, payload);

    thunkApi.dispatch(commonSlice.actions.setInvestmentServiceVersionId(response.data));

    await thunkApi.dispatch(
      fetchInvestmentServiceVersionsByClient({
        clientId: payload.clientId,
        showLatestVersionOnly: false,
      })
    );
    return { message: 'Investment Service details saved' };
  }
);

export const fetchBenchmarks = createAsyncThunk(MainDetailsActionTypes.FetchBenchmarks, async (afslId: number) => {
  const queryString = buildEncodedQueryString({
    afslId,
  });

  const response = await api.get<BenchmarkItem[]>(`${MainDetailsApiEndpoints.FetchBenchmarkService}${queryString}`);
  return response.data;
});

export const updateInvestmentServiceSecurityTolerances = createAsyncThunk(
  MainDetailsActionTypes.UpdateInvestmentServiceSecurityTolerances,
  async (payload: UpdateInvestmentServiceSucurityTolerancesPayload) => {
    await api.post(`${MainDetailsApiEndpoints.UpdateInvestmentServiceSecurityTolerances}`, payload);

    return { message: 'Security tolerances saved' };
  }
);

export const updateInvestmentServiceAssetClassTolerances = createAsyncThunk(
  MainDetailsActionTypes.UpdateInvestmentServiceAssetClassTolerances,
  async (payload: UpdateInvestmentServiceAssetClassTolerancesPayload) => {
    await api.post(`${MainDetailsApiEndpoints.UpdateInvestmentServiceAssetClassTolerances}`, payload);

    return { message: 'Asset class tolerances saved' };
  }
);
