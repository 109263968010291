import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { RootState } from '../../../reducers';
import { selectSecurityCommon } from '../price/store/selectors';
import { selectSecurityPrice } from '../store/selectors';
import { SecurityTabs } from './components/SecurityTabs';
import { fetchSecurityDetails } from './store/thunks';

const mapStateToProps = (state: RootState) => ({
  securityPrice: selectSecurityPrice(state),
  securityCommon: selectSecurityCommon(state),
});

const mapDispatchToProps = {
  fetchSecurityDetails,
};

interface MatchParams {
  securityId?: string;
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector> & RouteComponentProps<MatchParams>;
export const CommonContainer = connect(mapStateToProps, mapDispatchToProps)(SecurityTabs);
