import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, FormControlLabel, Grid, Typography } from '@mui/material';
import { WO2Checkbox, WO2ToggleSwitch } from 'src/common';
import {
  ClientReportsParameters,
  HidingOptionPortfolioPerformance,
  HidingOptionsRequest,
  MdaReportTypes,
} from '../../store/types';
import { ActionCreatorWithoutPayload, ActionCreatorWithPayload } from '@reduxjs/toolkit';
import React from 'react';
import { DateRange } from 'src/store';
import DateRangePicker from 'src/common/components/DateRangePicker';
import { BenchmarkSelect } from 'src/features/securities/performance/components/benchmarkSelect';
import { SecurityBenchmark } from 'src/features/securities/performance/store/types';

export interface PorfolioPerformanceProps {
  parameters: ClientReportsParameters;
  setPortfolioPerformanceSelectedParameter: ActionCreatorWithPayload<boolean, string>;
  setDateRangePortfolioPerformanceParameter: ActionCreatorWithPayload<DateRange>;
  setPortfolioPerformanceHidingOption: ActionCreatorWithPayload<
    HidingOptionsRequest<HidingOptionPortfolioPerformance>,
    string
  >;
  expandPortfolioPerformance: ActionCreatorWithoutPayload<string>;
  setPortfolioPerformanceBenchmarkParameter: ActionCreatorWithPayload<number>;
  benchmarks: SecurityBenchmark[];
  createReportSortRequest: (isAdd: boolean, id: number, text: string) => void;
}

export const PorfolioPerformance = ({
  parameters,
  setPortfolioPerformanceSelectedParameter,
  setDateRangePortfolioPerformanceParameter,
  setPortfolioPerformanceHidingOption,
  setPortfolioPerformanceBenchmarkParameter,
  benchmarks,
  expandPortfolioPerformance,
  createReportSortRequest,
}: PorfolioPerformanceProps) => {
  const handleClickPortfolioPerformanceSelect = () => {
    setPortfolioPerformanceSelectedParameter(!parameters.selectedReports?.portfolioPerformance.selected);
    createReportSortRequest(
      !parameters.selectedReports?.portfolioPerformance.selected,
      MdaReportTypes.PortfolioPerformance.id,
      MdaReportTypes.PortfolioPerformance.displayName
    );
  };

  const handleClickDateForPortfolioPerformance = (dates: DateRange) => {
    setDateRangePortfolioPerformanceParameter(dates);
  };

  const setBenchmark = (benchmark: SecurityBenchmark | null) => {
    setPortfolioPerformanceBenchmarkParameter(benchmark?.id ?? -1);
  };

  return (
    <Accordion
      onChange={expandPortfolioPerformance}
      expanded={parameters.selectedReports?.portfolioPerformance.expanded || false}
      elevation={0}
    >
      <AccordionSummary expandIcon={<ExpandMore />}>
        <FormControlLabel
          aria-label="Acknowledge"
          sx={{ flex: 1 }}
          onClick={(event) => event.stopPropagation()}
          onFocus={(event) => event.stopPropagation()}
          control={
            <WO2Checkbox
              color="primary"
              checked={parameters.selectedReports?.portfolioPerformance.selected}
              onChangeHandler={handleClickPortfolioPerformanceSelect}
            />
          }
          label="Portfolio Performance"
        />
        <div onClick={(e) => e.stopPropagation()}>
          <DateRangePicker
            sx={{ minWidth: 194 }}
            dateRange={{
              dateFrom: parameters?.selectedReports.portfolioPerformance?.fromDate,
              dateTo: parameters?.selectedReports.portfolioPerformance?.toDate,
              dateRangeType: parameters?.selectedReports.portfolioPerformance?.dateRangeType,
            }}
            setDateRange={handleClickDateForPortfolioPerformance}
            inceptionDate={parameters.inceptionDate}
          />
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container direction="column">
          <Grid item>
            <BenchmarkSelect
              setBenchmark={setBenchmark}
              selectedItem={
                benchmarks.find(
                  (benchmark) => benchmark.id === parameters.selectedReports.portfolioPerformance.benchmarkId
                ) ?? null
              }
              items={benchmarks}
            />
          </Grid>
          <Grid item container direction="row" alignItems="center" spacing={1} mt={1}>
            <Grid item container alignItems="center" sm={6} md={3} flexWrap="nowrap">
              <WO2ToggleSwitch
                checked={parameters.selectedReports.portfolioPerformance.excludeFrankingCredits}
                onChange={(e) =>
                  setPortfolioPerformanceHidingOption({
                    option: HidingOptionPortfolioPerformance.FrankingCredits,
                    value: e.target.checked,
                  })
                }
                color="primary"
                name="hidingOptions"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
              <Typography variant="h4">Exclude Franking Credits</Typography>
            </Grid>
            <Grid item container alignItems="center" sm={6} md={3} flexWrap="nowrap">
              <WO2ToggleSwitch
                checked={parameters.selectedReports.portfolioPerformance.showAnnualisedReturns}
                onChange={(e) =>
                  setPortfolioPerformanceHidingOption({
                    option: HidingOptionPortfolioPerformance.AnnualisedReturns,
                    value: e.target.checked,
                  })
                }
                color="primary"
                name="hidingOptions"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
              <Typography variant="h4">Show Annualised Returns</Typography>
            </Grid>
            <Grid item container alignItems="center" sm={6} md={3} flexWrap="nowrap">
              <WO2ToggleSwitch
                checked={parameters.selectedReports.portfolioPerformance.applyExclusionList}
                onChange={(e) =>
                  setPortfolioPerformanceHidingOption({
                    option: HidingOptionPortfolioPerformance.ExclusionList,
                    value: e.target.checked,
                  })
                }
                color="primary"
                name="hidingOptions"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
              <Typography variant="h4">Apply Exclusion List</Typography>
            </Grid>
            <Grid item container alignItems="center" sm={6} md={3} flexWrap="nowrap">
              <WO2ToggleSwitch
                checked={parameters.selectedReports.portfolioPerformance.includeAfterFeesReturn}
                onChange={(e) =>
                  setPortfolioPerformanceHidingOption({
                    option: HidingOptionPortfolioPerformance.AfterFeesReturn,
                    value: e.target.checked,
                  })
                }
                color="primary"
                name="hidingOptions"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
              <Typography variant="h4">Include After Fees Return</Typography>
            </Grid>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
