import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  addTrade,
  clearAllTrades,
  clearGroupTrades,
  clearTrade,
  initialiseTrades,
  sellAllTrades,
  updateAmountToTrade,
  updateDateLimit,
  updateExpiryType,
  updatePriceLimit,
  updatePriceType,
  updateTradeAction,
  updateTradeMethod,
  updateTrades,
} from './mutators';
import { importTradesAction } from './sagas/importSaga';
import {
  deleteRebalance,
  fetchRebalance,
  fetchSecurities,
  fetchSecurity,
  fetchWithdrawalDetailsData,
  validateRebalance,
} from './thunks';
import {
  AddTradePayload,
  CalculationMethod,
  GroupedByKey,
  Rebalance,
  RebalanceKey,
  RebalanceMode,
  RebalanceState,
  Security,
  SecuritySearch,
  SecuritySearchResults,
  SetAmountToTradePayload,
  SetExpiryDatePayload,
  SetExpiryTypePayload,
  SetMethodPayload,
  SetPriceLimitPayload,
  SetPriceTypePayload,
  SetTradePayload,
  Trade,
  ValidateRebalanceSuccessPayload,
  ValidationResult,
  Withdrawal,
  WithdrawalDetailsData,
} from './types';
import { getValidationResult } from '../utils/getValidationResult';

const initialState: RebalanceState = {
  rebalanceKey: {} as RebalanceKey,
  rebalance: {} as Rebalance,
  filter: '',
  groupedByKey: GroupedByKey.AssetClass,
  isPreApproved: false,
  validationMessages: {
    errors: [],
    information: [],
    warnings: [],
  },
  importErrors: [],
  securitySearch: {} as SecuritySearch,
  withdrawal: {
    rebalanceMode: RebalanceMode.RebalancePortfolio,
    ignoreMinimumTrade: false,
    saleMethod: CalculationMethod.WithdrawalRebalance,
    targetCash: '',
    withdrawalMethod: '%',
    withdrawCash: true,
    receivingAccount: null,
    withdrawValue: '',
    withdrawalDetailsData: {
      clientId: null,
      clientName: '',
      portfolioVersionId: null,
      portfolioId: null,
      portfolioName: '',
      minTradeValue: null,
      inProcessWithdrawalAmount: null,
      isRebalanceWithdrawalInProcess: false,
      minimumCma: null,
      currentPortofolioCma: null,
      avaliableCma: null,
      totalPortfolioHolding: 0,
      totalEstimatedValue: 0,
      withdrawalOption: CalculationMethod.WithdrawalRebalance,
      withdrawalPercent: null,
      withdrawalAud: 0,
      cashToWithdrawal: 0,
      isCashWithdrawal: false,
      adviserProceed: false,
      ignoreTradeParcel: false,
      accountId: null,
      externalAccountId: null,
    },
  } as Withdrawal,
};

export const rebalanceEditSlice = createSlice({
  name: '@@rebalance/edit',
  initialState,
  reducers: {
    resetRebalance: (state: RebalanceState) => {
      state.validationMessages = initialState.validationMessages;
      state.securitySearch = initialState.securitySearch;
      state.groupedByKey = initialState.groupedByKey;
      state.filter = initialState.filter;
      state.isPreApproved = initialState.isPreApproved;
      state.rebalance = initialState.rebalance;
      state.withdrawal = initialState.withdrawal;
      state.importErrors = initialState.importErrors;
      state.rebalanceKey = initialState.rebalanceKey;
    },
    fetchRebalanceKeySuccess: (state: RebalanceState, action: PayloadAction<RebalanceKey>) => {
      state.rebalanceKey = action.payload;
    },
    setRebalanceKey: (state: RebalanceState, action: PayloadAction<RebalanceKey>) => {
      state.rebalanceKey = action.payload;
    },
    fetchRebalanceKeyFailed: (state: RebalanceState, action: PayloadAction<ValidationResult>) => {
      const validation = getValidationResult(state, action.payload);
      state.validationMessages = validation;
      state.rebalance.validationResult = validation;
      state.isPreApproved = false;
    },
    setGroupedBy: (state: RebalanceState, action: PayloadAction<GroupedByKey>) => {
      state.groupedByKey = action.payload;
    },
    setFilter: (state: RebalanceState, action: PayloadAction<string>) => {
      state.filter = action.payload;
    },
    setClearAll: (state: RebalanceState) => {
      state.rebalance.trades = clearAllTrades(state.rebalance);
      state.isPreApproved = false;
    },
    setSellAll: (state: RebalanceState) => {
      state.rebalance.trades = sellAllTrades(state.rebalance);
      state.isPreApproved = false;
    },
    setMethod: (state: RebalanceState, action: PayloadAction<SetMethodPayload>) => {
      state.rebalance.trades = updateTradeMethod(state.rebalance, action.payload.id, action.payload.tradeMethod);
      state.isPreApproved = false;
    },
    setPriceType: (state: RebalanceState, action: PayloadAction<SetPriceTypePayload>) => {
      state.rebalance.trades = updatePriceType(state.rebalance, action.payload.id, action.payload.priceType);
      state.isPreApproved = false;
    },
    setExpiryType: (state: RebalanceState, action: PayloadAction<SetExpiryTypePayload>) => {
      state.rebalance.trades = updateExpiryType(state.rebalance, action.payload.id, action.payload.expiryType);
      state.isPreApproved = false;
    },
    setTrade: (state: RebalanceState, action: PayloadAction<SetTradePayload>) => {
      state.rebalance.trades = updateTradeAction(state.rebalance, action.payload.id, action.payload.tradeAction);
      state.isPreApproved = false;
    },
    addTrade: (state: RebalanceState, action: PayloadAction<AddTradePayload>) => {
      state.rebalance.trades = addTrade(state.rebalance, action.payload.security, action.payload.units);
      state.isPreApproved = false;
    },
    setClearTrade: (state: RebalanceState, action: PayloadAction<string>) => {
      state.rebalance.trades = clearTrade(state.rebalance, action.payload);
      state.isPreApproved = false;
    },
    resetIsPreApproved: (state: RebalanceState) => {
      state.isPreApproved = false;
    },
    setAmountToTrade: (state, action: PayloadAction<SetAmountToTradePayload>) => {
      state.rebalance.trades = updateAmountToTrade(state.rebalance, action.payload.id, action.payload.tradeAmount);
      state.isPreApproved = false;
    },
    setPriceLimit: (state, action: PayloadAction<SetPriceLimitPayload>) => {
      state.rebalance.trades = updatePriceLimit(state.rebalance, action.payload.id, action.payload.priceLimit);
      state.isPreApproved = false;
    },
    setExpiryDate: (state, action: PayloadAction<SetExpiryDatePayload>) => {
      state.rebalance.trades = updateDateLimit(state.rebalance, action.payload.id, action.payload.expiryDate);
      state.isPreApproved = false;
    },
    setClearGroupTrades: (state: RebalanceState, action: PayloadAction<Trade[]>) => {
      state.rebalance.trades = clearGroupTrades(state.rebalance, action.payload);
      state.isPreApproved = false;
    },
    setClearSecuritySearch: (state: RebalanceState) => {
      state.securitySearch = {} as SecuritySearch;
    },
    setRebalanceValidated: (state, action: PayloadAction<ValidateRebalanceSuccessPayload>) => {
      state.validationMessages = action.payload.validationResult;
      state.rebalance.validationResult = action.payload.validationResult;
      state.isPreApproved = true;
    },
    setRebalanceMode: (state: RebalanceState, action: PayloadAction<RebalanceMode>) => {
      state.withdrawal.rebalanceMode = action.payload;
    },
    setIgnoreMinimumTrade: (state: RebalanceState, action: PayloadAction<boolean>) => {
      state.withdrawal.ignoreMinimumTrade = action.payload;
      state.withdrawal.withdrawalDetailsData.ignoreTradeParcel = action.payload;
    },
    setSaleMethod: (state: RebalanceState, action: PayloadAction<string>) => {
      state.withdrawal.saleMethod = action.payload;
      state.withdrawal.withdrawalDetailsData.withdrawalOption = action.payload;
    },
    setTargetCash: (state: RebalanceState, action: PayloadAction<string>) => {
      state.withdrawal.targetCash = action.payload;
    },
    setWithdrawalMethod: (state: RebalanceState, action: PayloadAction<string>) => {
      state.withdrawal.withdrawalMethod = action.payload;
    },
    setWithdrawCash: (state: RebalanceState, action: PayloadAction<boolean>) => {
      state.withdrawal.withdrawCash = action.payload;
      state.withdrawal.withdrawalDetailsData.isCashWithdrawal = action.payload;
    },
    setReceivingAccount: (state: RebalanceState, action: PayloadAction<number | null>) => {
      state.withdrawal.receivingAccount = action.payload;
      state.withdrawal.withdrawalDetailsData.externalAccountId = action.payload;
    },
    setWithdrawValue: (state: RebalanceState, action: PayloadAction<string>) => {
      state.withdrawal.withdrawValue = action.payload;
      state.withdrawal.withdrawalDetailsData.cashToWithdrawal = Number(action.payload);
    },
    setWithdrawalAud: (state: RebalanceState, action: PayloadAction<number>) => {
      state.withdrawal.withdrawalDetailsData.withdrawalAud = action.payload;
    },
    setWithdrawalPercent: (state: RebalanceState, action: PayloadAction<number>) => {
      state.withdrawal.withdrawalDetailsData.withdrawalPercent = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(importTradesAction.fulfilled, (state, action: PayloadAction<Trade[]>) => {
      state.rebalance.trades = updateTrades(state.rebalance, action.payload);
      state.importErrors = [];
    });
    builder.addCase(importTradesAction.rejected, (state, action: PayloadAction<string[]>) => {
      state.importErrors = action.payload;
    });
    builder.addCase(fetchRebalance.fulfilled, (state, action: PayloadAction<Rebalance>) => {
      state.rebalance = action.payload;
      state.rebalance.trades = initialiseTrades(action.payload);
      state.rebalance.preApprovalValidationResult = action.payload.validationResult;
    });
    builder.addCase(fetchRebalance.rejected, (state, action) => {
      if (action.payload) {
        state.rebalance.preApprovalValidationResult = action.payload;
      }
      state.isPreApproved = false;
    });
    builder.addCase(fetchSecurities.fulfilled, (state, action: PayloadAction<SecuritySearchResults>) => {
      state.securitySearch.securitySearchResults = action.payload;
    });
    builder.addCase(fetchSecurities.rejected, (state, action) => {
      state.securitySearch.securitySearchResults = {} as SecuritySearchResults;
      if (action.payload) {
        state.validationMessages = action.payload;
      }
    });
    builder.addCase(fetchSecurity.fulfilled, (state, action: PayloadAction<Security>) => {
      state.securitySearch.security = action.payload;
    });
    builder.addCase(fetchWithdrawalDetailsData.fulfilled, (state, action: PayloadAction<WithdrawalDetailsData>) => {
      state.withdrawal.withdrawalDetailsData = action.payload;
    });
    builder.addCase(fetchSecurity.rejected, (state, action) => {
      state.securitySearch.security = {} as Security;
      if (action.payload) {
        state.validationMessages = action.payload;
      }
    });
    builder.addCase(deleteRebalance.fulfilled, (state) => {
      state.rebalanceKey = {} as RebalanceKey;
      state.rebalance = {} as Rebalance;
      state.filter = '';
      state.groupedByKey = GroupedByKey.AssetClass;
      state.isPreApproved = false;
      state.validationMessages = {} as ValidationResult;
      state.securitySearch = {} as SecuritySearch;
    });
    builder.addCase(deleteRebalance.rejected, (state, action) => {
      if (action.payload) {
        state.validationMessages = action.payload;
      }
    });
    builder.addCase(validateRebalance.fulfilled, (state, action: PayloadAction<ValidateRebalanceSuccessPayload>) => {
      const validation = getValidationResult(state, action.payload.validationResult);
      state.validationMessages = validation;
      state.rebalance.validationResult = validation;
      state.isPreApproved = validation.warnings.length === 0 && validation.errors.length === 0 && validation.information.length === 0;
    });
    builder.addCase(validateRebalance.rejected, (state, action) => {
      if (action.payload) {
        const validation = getValidationResult(state, action.payload);
        state.validationMessages = validation;
        state.rebalance.validationResult = validation;
      }
      state.isPreApproved = false;
    });
  },
});
