import { Card, Container } from '@mui/material';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React from 'react';
import { DateTimeFormat, getLocalDateTime } from 'src/common';
import { LoadingIndicator } from 'src/common/components/LoadingIndicator';
import { LoadingProgress } from '../../../../common/store/types';
import { theme } from '../../../../themes';
import { SecurityPricesChartItem } from '../store/types';

export interface Props {
  priceChartLoading: LoadingProgress;
  priceChart?: SecurityPricesChartItem[];
}

export const PriceChart = (props: Props): JSX.Element => {
  const { priceChartLoading, priceChart } = props;

  const categories = priceChart?.map((i) => getLocalDateTime(i.date, DateTimeFormat.ChartDayDate)) ?? [];

  const cumulativeValues = priceChart?.map((i) => i.price) ?? [0];

  const chartOptions = {
    chart: {
      zoomType: 'x',
    },
    plotOptions: {
      series: {
        allowPointSelect: false,
        groupPadding: 0,
        pointPadding: 0.1,
        borderWidth: 0,
      },
    },
    title: {
      text: null,
    },
    subtitle: {
      text: document.ontouchstart === undefined ? 'Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in',
    },
    xAxis: [
      {
        gridLineColor: 'transparent',
        lineColor: 'transparent',
        categories: categories,
        labels: {
          enabled: false,
        },
      },
    ],
    yAxis: [
      {
        title: { text: undefined },
        gridLineColor: 'transparent',
        lineColor: 'transparent',
        labels: {
          enabled: false,
        },
        plotLines: [
          {
            color: theme.palette.graphBackground?.main,
            width: 0.5,
            value: 0,
          },
        ],
      },
    ],
    series: [
      {
        color: theme.palette.primary.main,
        name: 'Price',
        type: 'line',
        data: cumulativeValues,
        zIndex: 100,
        states: {
          inactive: {
            opacity: 1,
          },
        },
        marker: {
          enabled: false,
        },
      },
    ],
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
  };

  return (
    <LoadingIndicator progress={priceChartLoading}>
      <Card square elevation={0} style={{ background: theme.palette.common.white, padding: '30px 0' }}>
        <Container fixed>
          {/* <Typography variant={'h4'} align={'center'}>
            Price at {latestPriceDate}
          </Typography> */}

          <HighchartsReact highcharts={Highcharts} options={chartOptions} />
        </Container>
      </Card>
    </LoadingIndicator>
  );
};
