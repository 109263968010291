import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
  transactionsContainer: {
    maxWidth: '1470px',
    overflowX: 'auto',
  },
  headerLeft: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    alignItems: 'center',
    padding: '0',
    margin: '10px 10px 0 25px',
  },
  headerRight: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    alignItems: 'center',
    margin: '0',
    padding: '20px 18px 20px 0',
    width: '100%',
  },
  cardContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: '10px',
  },
  cardItemContainer: {
    width: '337px',
    height: '140px',
    margin: '10px',
  },

  search: {
    position: 'relative',
    height: '50px',
    marginRight: '20px',
    maxWidth: '500px',
  },
  searchIcon: {
    width: '48px',
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  searchInput: {
    width: '100%',
  },
  pageName: {
    height: '48px',
    color: '#000a12',
    fontFamily: 'AvenirBlack',
    fontSize: '36px',
    fontWeight: 400,
    lineHeight: '48px',
    paddingRight: '20px',
    marginLeft: '11px',
  },
}));

export const searchStyle = {
  width: 400,
} as React.CSSProperties;
