import { Add, DeleteOutlined, EditOutlined } from '@mui/icons-material';
import { IconButton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useConfirmation } from 'src/common/components/dialogs';
import { ClientSideDataTable } from '../../../../../../common/components/dataTable/clientSide';
import { DatatableColumn, FilterDataType } from '../../../../../../common/components/dataTable/types';
import { LoadingProgress } from '../../../../../../common/store/types';
import { useStyles } from '../../../../../../themes/index';
import { AddressType } from '../../store/enums';
import { AddressDetails } from '../../store/types';
import Address from './address';

export interface AddressesProps {
  addresses: AddressDetails[];
  addressTypes: AddressType[];
  loadingProgress: LoadingProgress;
  savingProgress: LoadingProgress;
  selectedAddress?: AddressDetails | null;
  hideAllActions?: boolean;
  save: (address: AddressDetails) => void;
  delete: (addressId: number) => void;
  setAddEdit: (id: number | null) => void;
  cancel: () => void;
}

export const AddressTable = (props: AddressesProps): JSX.Element => {
  const confirm = useConfirmation();
  const classes = useStyles();
  const { addresses, loadingProgress, savingProgress, save, delete: deleteAddress, setAddEdit, selectedAddress, cancel, addressTypes, hideAllActions } = props;
  const [isAddressOpen, setAddressOpen] = useState(false);

  useEffect(() => {
    if (selectedAddress !== undefined) {
      setAddressOpen(true);
    } else {
      setAddressOpen(false);
    }
  }, [selectedAddress]);

  const onClose = () => {
    setAddressOpen(false);
    cancel();
  };

  const addressColumn = (dataIndex: number): React.ReactNode => (
    <>
      <Typography variant="h5">
        {props.addresses[dataIndex]?.line1 + (!!props.addresses[dataIndex]?.line2 ? ', ' + props.addresses[dataIndex]?.line2 : '')}
      </Typography>
      <Typography variant="h5">
        {props.addresses[dataIndex]?.suburb} {props.addresses[dataIndex]?.state} {props.addresses[dataIndex]?.postcode}
      </Typography>
    </>
  );

  const typeColumn = (dataIndex: number): React.ReactNode => {
    const address = props.addresses[dataIndex];
    return (
      <Typography variant="h5">
        {!!address?.addressTypeIds &&
          address.addressTypeIds
            .map((id) => AddressType.getById(id)?.displayName)
            .sort((a, b) => (a || '').localeCompare(b || ''))
            .join(', ')}
      </Typography>
    );
  };

  const addButton = (): React.ReactNode => {
    return (
      <IconButton
        className={classes.addToTable}
        disableFocusRipple
        disableRipple
        data-testid="contact-address-add"
        onClick={() => {
          setAddEdit(null);
        }}
      >
        <Add />
      </IconButton>
    );
  };

  const actionsColumn = (dataIndex: number): React.ReactNode => {
    return (
      <>
        <IconButton
          disableFocusRipple
          disableRipple
          data-testid="contact-address-edit"
          onClick={() => {
            setAddEdit(props.addresses[dataIndex].addressId);
          }}
        >
          <EditOutlined color={'primary'} />
        </IconButton>
        <IconButton
          disableFocusRipple
          disableRipple
          data-testid="contact-address-delete"
          onClick={() => {
            const addressId = props.addresses[dataIndex].addressId;
            if (addressId != null) {
              confirm({
                title: 'Delete address',
                description: 'Are you sure you wish to delete this address?',
                description2: 'This action is permanent, please ensure you have selected the correct contact before proceeding.',
              }).then(() => {
                deleteAddress(addressId);
              });
            }
          }}
        >
          <DeleteOutlined color={'primary'} />
        </IconButton>
      </>
    );
  };

  const columns: DatatableColumn[] = [
    {
      filterDataType: FilterDataType.string,
      name: 'address',
      label: 'ADDRESS',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex: number): React.ReactNode => addressColumn(dataIndex),
      },
    },
    {
      name: 'type',
      label: 'TYPE',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex: number): React.ReactNode => typeColumn(dataIndex),
      },
    },
    {
      name: 'actions',
      label: ' ',
      textAlign: 'right',
      options: {
        filter: false,
        sort: false,
        viewColumns: false,
        customBodyRenderLite: (dataIndex: number): React.ReactNode => actionsColumn(dataIndex),
      },
    },
  ];

  return (
    <div style={{ paddingTop: '20px' }}>
      <Typography variant="h4" gutterBottom>
        Addresses
      </Typography>
      <ClientSideDataTable
        loadingProgress={loadingProgress}
        columns={hideAllActions ? columns.filter((c) => c.name !== 'actions') : columns}
        data={addresses}
        options={{
          filter: false,
          pagination: true,
          customToolbar: () => !hideAllActions && addButton(),
        }}
        id="contact-address-datatable"
      ></ClientSideDataTable>
      {selectedAddress !== undefined && (
        <Address
          onSave={save}
          placeholder="Start typing address..."
          isOpen={isAddressOpen}
          handleCloseModal={() => onClose()}
          address={selectedAddress}
          availableAddressTypes={addressTypes}
          savingProgress={savingProgress}
        ></Address>
      )}
    </div>
  );
};
