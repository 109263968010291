import ArrowDropDownSharpIcon from '@mui/icons-material/ArrowDropDownSharp';
import GridOnIcon from '@mui/icons-material/GridOn';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import { Box, Container } from '@mui/material';
import 'date-fns';
import { DateTime } from 'luxon';
import { default as React, useCallback, useEffect, useState } from 'react';
import { usePDF } from 'react-to-pdf';
import { CSVDataType, convertToCSVFormat, downloadCsv } from 'src/common';
import { WO2Menu } from 'src/common/components/Menu';
import { moment } from 'src/common/types';
import { formatPercentage } from '../../../../../common';
import { EnumSelect } from '../../../../../common/components';
import DateRangePicker from '../../../../../common/components/DateRangePicker';
import { PagedRequest } from '../../../../../store';
import { theme } from '../../../../../themes';
import PdfFooter from '../../common/components/PdfFooter';
import PdfHeader from '../../common/components/PdfHeader';
import PortfolioSelect from '../../common/components/PortfolioSelect';
import { Props } from '../container';
import { CurrentTabType, Frequency, PerformanceParameters } from '../store/types';
import WO2Button from './../../../../../common/components/button/Button';
import { BenchmarkSelect } from './benchmarkSelect';
import { MonthlyPerformanceTable } from './monthlyPerformanceTable';
import { PairedButttons } from './pairedButtons';
import { PerformanceCards } from './performanceCards';
import { PerformanceChart } from './performanceChart';
import { PeriodicSummaryTable } from './periodicSummaryTable';
import { SecurityPerformanceTable } from './securityPerformanceTable';

export const PerformanceComponent = (props: Props): JSX.Element => {
  const {
    clientId,
    afslId,
    entityDetails,
    setPortfolioParameter,
    setBenchmarkParameter,
    setFrequencyParameter,
    setCurrentTab,
    setDateParameter,
    clearBenchmarkPerformanceResult,
    currentTab,
    clientPortfolios,
    parameters,
    benchmarks,
    monthlyPerformanceTableResults,
    isDataFromTriumph,
    fetchBenchmarks,
    fetchBenchmarkPerformance,
    fetchPeriodicSummary,
    fetchMonthlyPortfolioPerformance,
    fetchPortfolioSecurityPerformance,
    fetchPerformanceChart,
    performanceChartResults,
    periodicSummaryTableResults,
    portfolioSecurityPerformanceResults,
  } = props;

  const [searchedClientId, setSearchedClientId] = useState<number>(0);
  const [previousTriumphState, setPreviousTriumphState] = useState<boolean>(isDataFromTriumph);
  const [searchedFrequency, setSearchedFrequency] = useState<Frequency>(Frequency.Monthly);
  const [showPdfFooterAndHeader, setShowPdfFooterAndHeader] = useState(false);
  const [datesKnown, setDatesKnown] = useState(false);
  const { toPDF, targetRef } = usePDF({ filename: entityDetails?.name + '_Performance' });

  useEffect(() => {
    if (parameters.selectedPortfolio?.inceptionDate) {
      setDateParameter({
        dateFrom: parameters.selectedPortfolio?.inceptionDate,
        dateTo: parameters?.dates.dateTo,
        dateRangeType: parameters?.dates.dateRangeType,
      });
      setDatesKnown(true);
    }
  }, [parameters.selectedPortfolio?.inceptionDate]);

  useEffect(() => {
    if (showPdfFooterAndHeader) {
      toPDF();
      setShowPdfFooterAndHeader(false);
    }
  }, [showPdfFooterAndHeader]);

  const generatePdf = () => {
    setShowPdfFooterAndHeader(true);
  };

  useEffect(() => {
    if (!!clientId && datesKnown) {
      fetchPeriodicSummary({ clientId, parameters, isDataFromTriumph });
      fetchPortfolioSecurityPerformance({
        clientId,
        parameters: parameters,
        isDataFromTriumph: isDataFromTriumph,
      });
    }
  }, [datesKnown, clientId, isDataFromTriumph]);

  function generateMonthlySummaryCsv() {
    interface CsvRow {
      Aquisitions: number;
      Capital_Movement: number;
      Closing_Balance: number;
      Disposals: number;
      Expense: number;
      'Capital_Return($)': number;
      'Capital_Return(%)': string;
      'Income_Return($)': number;
      'Income_Return(%)': string;
      Investment: number;
      Opening_Balance: number;
      Other: number;
      Performance_Date: moment | undefined;
      Tax: number;
      'Total_Return($)': number;
      'Total_Return(%)': string;
    }
    const csvRow: CsvRow[] = [];

    monthlyPerformanceTableResults?.results.forEach((row) => {
      csvRow.push({
        Performance_Date: DateTime.fromISO(row.perfDate ?? '').toLocaleString(DateTime.DATE_SHORT),
        Opening_Balance: row.openingBalance,
        Aquisitions: row.aquisitions,
        Capital_Movement: row.capitalMovement,
        Disposals: row.disposals,
        Investment: row.investment,
        Expense: row.expense,
        Other: row.other,
        Tax: row.tax,
        Closing_Balance: row.closingBalance,
        'Capital_Return($)': row.growth,
        'Capital_Return(%)': formatPercentage(row.growthReturn, '0', 6, false),
        'Income_Return($)': row.income,
        'Income_Return(%)': formatPercentage(row.incomeReturn, '0', 6, false),
        'Total_Return($)': row.total,
        'Total_Return(%)': formatPercentage(row.totalReturn, '0', 6, false),
      });
    });

    const data: CSVDataType[] = convertToCSVFormat(csvRow);

    downloadCsv(data, entityDetails?.name + '_Performance_Monthly_Summary');
  }

  function generatePeriodicSummaryCsv() {
    interface CsvRow {
      Date: moment | undefined;
      Label: string;
      Income_Return: string | number;
      Capital_Return: string | number;
      Total_Return: string | number;
      Benchmark_Income_Return: string | number;
      Benchmark_Capital_Return: string | number;
      Benchmark_Total_Return: string | number;
      Portfolio_Vs_Benchmark_Income_Return: string | number;
      Portfolio_Vs_Benchmark_CapitalReturn: string | number;
      Portfolio_Vs_Benchmark_Total_Return: string | number;
    }

    const csvRow: CsvRow[] = [];

    periodicSummaryTableResults?.seriesData.forEach((row) => {
      csvRow.push({
        Date: DateTime.fromISO(row.date ?? '').toLocaleString(DateTime.DATE_SHORT),
        Label: row.label,
        Capital_Return: row.capitalReturn ? formatPercentage(row.capitalReturn, '0', 6, false) : '-',
        Income_Return: row.incomeReturn ? formatPercentage(row.incomeReturn, '0', 6, false) : '-',
        Total_Return: row.totalReturn ? formatPercentage(row.totalReturn, '0', 6, false) : '-',
        Benchmark_Capital_Return: row.benchmarkCapitalReturn
          ? formatPercentage(row.benchmarkCapitalReturn, '0', 6, false)
          : '-',
        Benchmark_Income_Return: row.benchmarkIncomeReturn
          ? formatPercentage(row.benchmarkIncomeReturn, '0', 6, false)
          : '-',
        Benchmark_Total_Return: row.benchmarkTotalReturn
          ? formatPercentage(row.benchmarkTotalReturn, '0', 6, false)
          : '-',
        Portfolio_Vs_Benchmark_CapitalReturn: row.portfolioVsBenchmarkCapitalReturn
          ? formatPercentage(row.portfolioVsBenchmarkCapitalReturn, '0', 6, false)
          : '-',
        Portfolio_Vs_Benchmark_Income_Return: row.portfolioVsBenchmarkIncomeReturn
          ? formatPercentage(row.portfolioVsBenchmarkIncomeReturn, '0', 6, false)
          : '-',
        Portfolio_Vs_Benchmark_Total_Return: row.portfolioVsBenchmarkTotalReturn
          ? formatPercentage(row.portfolioVsBenchmarkTotalReturn, '0', 6, false)
          : '-',
      });
    });

    const data: CSVDataType[] = convertToCSVFormat(csvRow);

    downloadCsv(data, entityDetails?.name + '_Performance_Periodic_Summary');
  }

  function generateChartData() {
    interface CsvRow {
      Date: moment | undefined;
      Return: number | null;
      Cumulative_Return: number | null;
    }
    const csvRow: CsvRow[] = [];

    performanceChartResults?.seriesData.forEach((row) => {
      csvRow.push({
        Date: DateTime.fromISO(row.date ?? '').toLocaleString(DateTime.DATE_SHORT),
        Return: row.return,
        Cumulative_Return: row.cumulativeReturn,
      });
    });

    const data: CSVDataType[] = convertToCSVFormat(csvRow);

    downloadCsv(data, entityDetails?.name + '_Performance_Chart');
  }

  function generateDetailCsv() {
    interface CsvRow {
      Asset_Class: string | null;
      Security_Code: string | null;
      Security_Name: string | null;
      Opening_Balance: number | null;
      Aquisitions: number | null;
      Disposals: number | null;
      Capital_Movement: number | null;
      Other: number | null;
      Closing_Balance: number | null;
      'Capital_Return($)': number;
      'Capital_Return(%)': string;
      'Income_Return($)': number;
      'Income_Return(%)': string;
      'Total_Return($)': number;
      'Total_Return(%)': string;
    }

    const csvRow: CsvRow[] = [];

    portfolioSecurityPerformanceResults?.groupItems.forEach((assetGroup) => {
      let assetClass = '';
      assetGroup.children.forEach((asset) => {
        assetClass = asset.groupName + ' - Total';
        csvRow.push({
          Asset_Class: asset.groupName,
          Security_Code: asset.securityCode,
          Security_Name: asset.securityName,
          Opening_Balance: asset.openingBalance,
          Aquisitions: asset.aquisitions,
          Disposals: asset.disposals,
          Capital_Movement: asset.capitalMovement,
          Other: asset.other,
          Closing_Balance: asset.closingBalance,
          'Capital_Return($)': asset.growth,
          'Capital_Return(%)': formatPercentage(asset.growthReturn, '0', 6, false),
          'Income_Return($)': asset.income,
          'Income_Return(%)': formatPercentage(asset.incomeReturn, '0', 6, false),
          'Total_Return($)': asset.total,
          'Total_Return(%)': formatPercentage(asset.totalReturn, '0', 6, false),
        });
      });
      csvRow.push({
        Asset_Class: assetClass,
        Security_Code: assetGroup.securityCode,
        Security_Name: assetGroup.securityName,
        Opening_Balance: assetGroup.openingBalance,
        Aquisitions: assetGroup.aquisitions,
        Disposals: assetGroup.disposals,
        Capital_Movement: assetGroup.capitalMovement,
        Other: assetGroup.other,
        Closing_Balance: assetGroup.closingBalance,
        'Capital_Return($)': assetGroup.growth,
        'Capital_Return(%)': formatPercentage(assetGroup.growthReturn, '0', 6, false),
        'Income_Return($)': assetGroup.income,
        'Income_Return(%)': formatPercentage(assetGroup.incomeReturn, '0', 6, false),
        'Total_Return($)': assetGroup.total,
        'Total_Return(%)': formatPercentage(assetGroup.totalReturn, '0', 6, false),
      });
    });

    const data: CSVDataType[] = convertToCSVFormat(csvRow);

    downloadCsv(data, entityDetails?.name + '_Performance_Details');
  }

  const exportButtons = [
    {
      icon: <PictureAsPdfOutlinedIcon />,
      label: 'Export to PDF',
      onClick: generatePdf,
      testId: `exportToPdf`,
    },
    {
      icon: <GridOnIcon />,
      label: 'Download Chart Data',
      onClick: generateChartData,
      testId: `generateChartData`,
    },
    {
      icon: <GridOnIcon />,
      label: 'Download Monthly Summary Data',
      onClick: generateMonthlySummaryCsv,
      testId: `generateMonthlySummaryData`,
    },
    {
      icon: <GridOnIcon />,
      label: 'Download Periodic Summary Data',
      onClick: generatePeriodicSummaryCsv,
      testId: `generatePeriodicSummaryData`,
    },
    {
      icon: <GridOnIcon />,
      label: 'Download Detail Data',
      onClick: generateDetailCsv,
      testId: `generateDetailData`,
    },
  ];

  const fetchTabData = useCallback(
    (pageNumber: number, searchParameters: PerformanceParameters, tab: string = CurrentTabType.MonthlySummary) => {
      const pagedRequest: PagedRequest = {
        pageNumber: pageNumber,
        pageSize: 25,
        queryFields: [],
      };

      if (tab === CurrentTabType.MonthlySummary && !!props.clientId) {
        fetchMonthlyPortfolioPerformance({
          clientId: clientId as number,
          parameters: searchParameters,
          pagedRequest,
          isDataFromTriumph: isDataFromTriumph,
        });
      } else if (tab === CurrentTabType.PeriodicSummary && !!clientId) {
        fetchPeriodicSummary({
          clientId: clientId,
          parameters: searchParameters,
          isDataFromTriumph: isDataFromTriumph,
        });
      } else if (tab === CurrentTabType.Detail && !!clientId) {
        fetchPortfolioSecurityPerformance({
          clientId,
          parameters: searchParameters,
          isDataFromTriumph: isDataFromTriumph,
        });
      }
    },
    [
      clientId,
      fetchMonthlyPortfolioPerformance,
      fetchPeriodicSummary,
      fetchPortfolioSecurityPerformance,
      isDataFromTriumph,
    ]
  );

  const handleClickSearch = useCallback(
    (searchParameters: PerformanceParameters, getTriumphData: boolean) => {
      setSearchedFrequency(searchParameters.frequency);
      if (datesKnown) {
        fetchTabData(1, searchParameters, currentTab);
        if (searchParameters?.selectedBenchmark && !!clientId) {
          fetchBenchmarkPerformance({ clientId, parameters: searchParameters });
        } else {
          clearBenchmarkPerformanceResult();
        }
        if (!!clientId) {
          fetchPerformanceChart({ clientId, parameters: searchParameters, isDataFromTriumph: getTriumphData });
        }
      }
    },
    [
      parameters.dates,
      datesKnown,
      clientId,
      currentTab,
      fetchTabData,
      fetchBenchmarkPerformance,
      fetchPerformanceChart,
      setSearchedFrequency,
      isDataFromTriumph,
    ]
  );

  const handleMonthlyPerformancePagination = (pagedRequest: PagedRequest) => {
    fetchTabData(pagedRequest.pageNumber, parameters, currentTab);
  };

  useEffect(() => {
    if (datesKnown && !!clientId && (clientId !== searchedClientId || previousTriumphState !== isDataFromTriumph)) {
      setSearchedClientId(clientId);
      setPreviousTriumphState(isDataFromTriumph);
      handleClickSearch(parameters, isDataFromTriumph);
    }
  }, [datesKnown, clientId, searchedClientId, parameters, handleClickSearch, isDataFromTriumph]);

  useEffect(() => {
    if (afslId) {
      fetchBenchmarks(afslId);
    }
  }, [afslId, fetchBenchmarks]);

  const handleClickOpenTab = (tabLabel: CurrentTabType) => {
    setCurrentTab(tabLabel);
    fetchTabData(1, parameters, tabLabel);
  };

  const monthlySummary = {
    label: CurrentTabType.MonthlySummary,
    backgroundSelected: theme.palette.primary.main,
  };
  const periodicSummary = {
    label: CurrentTabType.PeriodicSummary,
    backgroundSelected: theme.palette.primary.main,
  };
  const detail = {
    label: CurrentTabType.Detail,
    backgroundSelected: theme.palette.primary.main,
  };

  return (
    <>
      <Container style={{ paddingTop: '20px' }}>
        <Box display={'flex'} justifyContent={'space-between'}>
          <Box paddingRight={'10px'}>
            <PortfolioSelect
              items={clientPortfolios}
              selectedPortfolio={parameters?.selectedPortfolio}
              setPortfolio={setPortfolioParameter}
            />
          </Box>

          <Box display={'flex'} justifyContent={'space-between'}>
            <WO2Menu
              testId="export_button"
              buttonTitle="Export"
              buttonIcon={
                <div
                  style={{
                    paddingLeft: '10px',
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    backgroundColor: 'blue',
                    borderRadius: '25px',
                    padding: '10px 10px 10px 20px',
                    marginTop: '-7px',
                  }}
                >
                  <span style={{ color: '#ffffff', fontSize: '0.875rem' }}>EXPORT</span>
                  <ArrowDropDownSharpIcon sx={{ color: '#ffffff' }} />
                </div>
              }
              items={exportButtons}
            />
            <Box paddingRight={'10px'}>
              <EnumSelect
                label="Frequency"
                enumType={Frequency}
                value={parameters?.frequency}
                onChange={(value) => {
                  setFrequencyParameter(Frequency[value]);
                }}
              />
            </Box>
            <DateRangePicker
              dateRange={parameters?.dates}
              inceptionDate={parameters?.selectedPortfolio?.inceptionDate}
              setDateRange={setDateParameter}
            />
            <Box paddingRight={'10px'}>
              <BenchmarkSelect
                items={benchmarks}
                selectedItem={parameters?.selectedBenchmark ?? null}
                setBenchmark={setBenchmarkParameter}
              />
            </Box>
            <Box>
              <WO2Button
                color={'primary'}
                disableElevation
                onClick={() => handleClickSearch(parameters, isDataFromTriumph)}
                variant={'contained'}
                value="Search"
              >
                Search
              </WO2Button>
            </Box>
          </Box>
        </Box>
      </Container>

      <div ref={targetRef}>
        <Container style={{ paddingTop: '20px' }}>
          {showPdfFooterAndHeader && <PdfHeader clientName={props.entityDetails?.name + ' - Performance'} />}
          <PerformanceCards {...props} />
        </Container>
        <Container style={{ paddingLeft: '20px' }}>
          <Box paddingLeft={'20px'}>
            <p id="portfolioName"></p>
          </Box>
        </Container>
        <PerformanceChart {...props} frequency={searchedFrequency} />
        <Container style={{ paddingTop: '20px', paddingBottom: '20px' }}>
          <Box paddingBottom={'20px'}>
            <PairedButttons
              buttonLeft={monthlySummary}
              buttonMiddle={periodicSummary}
              buttonRight={detail}
              labelInitial={currentTab}
              width={180}
              handleClick={handleClickOpenTab}
            />
          </Box>
          {currentTab === CurrentTabType.MonthlySummary && !!monthlyPerformanceTableResults && (
            <MonthlyPerformanceTable
              {...props}
              handlePagedRequest={handleMonthlyPerformancePagination}
              pagedRequest={{
                pageNumber: monthlyPerformanceTableResults.pageNumber ?? 1,
                pageSize: 25,
                queryFields: [],
              }}
              pagedResults={monthlyPerformanceTableResults}
            />
          )}
          {currentTab === CurrentTabType.PeriodicSummary && <PeriodicSummaryTable {...props} />}
          {currentTab === CurrentTabType.Detail && <SecurityPerformanceTable {...props} />}
        </Container>
        {showPdfFooterAndHeader && <PdfFooter />}
      </div>
    </>
  );
};
