import { createSelector } from '@reduxjs/toolkit';
import { selectClientState } from '../../store/selectors';

export const selectCompositionState = createSelector(selectClientState, (modelState) => modelState.newReports);
export const selectParameters = createSelector(selectCompositionState, (listState) => listState.parameters);
export const selectReportGeneration = createSelector(selectCompositionState, (listState) => listState.reportGeneration);
export const selectSavedParametersList = createSelector(
  selectCompositionState,
  (listState) => listState.savedParametersList
);
export const selectclientFinancialYears = createSelector(
  selectCompositionState,
  (listState) => listState.clientFinancialYears
);
