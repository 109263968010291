import { combineReducers } from '@reduxjs/toolkit';
import { linkedAccountsSlice } from '../accounts/store';
import { constraintsSlice } from '../constraints/store';
import { detailsSlice } from '../details/store/slice';
import { targetsSlice } from '../targets/store/slice';
import { activationSlice } from './activation';
import { ConfigurationState } from './types';
import { exclusionsSlice } from '../report/store/slice';

export const configurationReducer = combineReducers<ConfigurationState>({
  details: detailsSlice.reducer,
  targets: targetsSlice.reducer,
  constraints: constraintsSlice.reducer,
  exclusions: exclusionsSlice.reducer,
  linkedAccounts: linkedAccountsSlice.reducer,
  activation: activationSlice.reducer,
});

export const initialState: ConfigurationState = {
  details: detailsSlice.getInitialState(),
  targets: targetsSlice.getInitialState(),
  constraints: constraintsSlice.getInitialState(),
  exclusions: exclusionsSlice.getInitialState(),
  linkedAccounts: linkedAccountsSlice.getInitialState(),
  activation: activationSlice.getInitialState(),
};
