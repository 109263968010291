import { FiberManualRecord as DotIcon } from '@mui/icons-material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Card, CardContent, Divider, Grid, ListItem, Typography } from '@mui/material';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { formatDollars, formatPercentage } from '../../../../../../common';
import { AssetGroup, AssetGroupItem, GainsLossesDetailItem, PortfolioParameters } from '../store/types';
import SecuriryCard from './SecurityCard';
import { SecurityList } from './SecurityListTable';

export interface Props {
  clientId: number | null;
  parameters: PortfolioParameters;
  portfolioAssetGroups: AssetGroup[];
  gainsLossesItems: GainsLossesDetailItem[];
  totalValue: number;
  viewFullPortfolio: (clientId: number) => void;
  progress: { hasErrors: boolean; isLoading: boolean };
}

export const PortfolioDetails = (props: Props): JSX.Element => {
  const { portfolioAssetGroups, gainsLossesItems, totalValue, parameters, progress } = props;

  const [selectedAssetGroup, setSelectedAssetGroup] = useState<AssetGroup | null>(null);
  const [colorIndex, setColorIndex] = useState(0);
  const [selectedSecurity, setSlectedSecurity] = useState<AssetGroupItem | null>(null);

  const groupingType = parameters.groupingType;
  const gainsLossesData = _.find(gainsLossesItems, (data) => {
    return data.groupName === selectedSecurity?.securityCode;
  });

  useEffect(() => {
    setSelectedAssetGroup(portfolioAssetGroups.length > 0 ? portfolioAssetGroups[0] : null);
  }, [portfolioAssetGroups]);

  const chartColors: string[] = [
    '#2c82be',
    '#429ff9',
    '#423cff',
    '#0080ff',
    '#000080',
    '#008ecc',
    '#008081',
    '#4482b4',
    '#111e6c',
    '#1d2951',
    '#dbecf8',
  ];

  let floatingProfitPositions = 0;
  let floatingLossPositions = 0;

  selectedAssetGroup?.items.map((security: AssetGroupItem) => {
    if (security.differenceValue && security.differenceValue > 0) {
      floatingProfitPositions += 1;
    } else if (security.differenceValue && security.differenceValue < 0) {
      floatingLossPositions += 1;
    }
  });

  const selectGroup = (group: AssetGroup | null, index: number) => {
    setColorIndex(index);
    setSelectedAssetGroup(group);
    setSlectedSecurity(null);
  };

  let filteredAssetGroupItems = selectedAssetGroup?.items;

  if (!parameters.showPositionsNotHeld) {
    filteredAssetGroupItems = selectedAssetGroup?.items.filter((i) => i.units !== 0);
  }

  if (!filteredAssetGroupItems?.length) {
    if (portfolioAssetGroups.length == 2) {
      filteredAssetGroupItems = portfolioAssetGroups[1]?.items;
    }
  }

  const chartData = portfolioAssetGroups
    .filter((i) => i.groupName !== 'All Assets')
    .map((i, index) => ({
      y: Math.abs(i.currentValue / totalValue),
      name: i.groupName,
      color: chartColors[index],
      sliced: i.groupName === selectedAssetGroup?.groupName,
      selected: i.groupName === selectedAssetGroup?.groupName,
      events: {
        select: function () {
          selectGroup(portfolioAssetGroups.find((j) => j.groupName === i.groupName) ?? null, index);
        },
        unselect: function () {
          selectGroup(portfolioAssetGroups[0], index);
        },
      },
    }));

  const chartText = `<div style="color:#000a12; font-family: 'AvenirHeavy'; font-weight: 400; font-size: 20px; line-height: 27px; height: 27px; width: 106px;">${formatDollars(
    totalValue
  )}</div>`;

  const chartOptions = {
    chart: {
      type: 'pie',
    },
    allowPointSelect: true,
    title: {
      verticalAlign: 'middle',
      floating: true,
      text: chartText,
    },
    colors: chartColors,
    subtitle: {
      text: '',
    },
    plotOptions: {
      pie: {
        innerSize: '60%',
        depth: 45,
        tooltip: {
          pointFormatter: function (this: Highcharts.TooltipFormatterContextObject) {
            return `<span style="color:${this.color}">●</span> <b>${this.percentage?.toFixed(2)}%</b><br/>`;
          },
        },
      },
    },
    series: [
      {
        allowPointSelect: true,
        name: '',
        data: chartData,
        dataLabels: {
          enabled: false,
        },
      },
    ],
    credits: {
      enabled: false,
    },
  };

  const showSecurityPieChart = (security: AssetGroupItem) => {
    setSlectedSecurity(security);
  };

  const groupRows = portfolioAssetGroups.map((group: AssetGroup, index: number) => {
    return (
      <ListItem
        key={index}
        divider
        selected={group.groupName === selectedAssetGroup?.groupName}
        button
        onClick={() =>
          group.groupName === selectedAssetGroup?.groupName
            ? selectGroup(portfolioAssetGroups[0], index)
            : selectGroup(group, index)
        }
      >
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={1}>
            <DotIcon fontSize={'large'} htmlColor={chartColors[index]} />
          </Grid>
          <Grid item xs={7}>
            <Typography noWrap variant={'h5'}>
              {group.groupName}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography noWrap variant={'h5'} color={'textPrimary'} style={{ textAlign: 'right' }}>
              {formatDollars(group.currentValue)}
            </Typography>
            <Typography noWrap variant={'h6'} color={'textSecondary'} style={{ textAlign: 'right' }}>
              {formatPercentage(group.currentWeightPercentage)}
            </Typography>
          </Grid>
        </Grid>
      </ListItem>
    );
  });

  return (
    <Card elevation={0}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            {!selectedSecurity && <HighchartsReact highcharts={Highcharts} options={chartOptions} />}
            {!!selectedSecurity && (
              <SecuriryCard
                selectedSecurity={selectedSecurity}
                gainsLossesData={gainsLossesData}
                selectGroup={selectGroup}
              />
            )}
            <ListItem>
              <Grid container spacing={1}>
                <Grid item xs={2}></Grid>
                <Grid item xs={6}>
                  <Typography variant="h6" color="textPrimary" style={{ textTransform: 'uppercase' }}>
                    {groupingType}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    variant="h6"
                    color="textPrimary"
                    style={{ textTransform: 'uppercase', textAlign: 'right' }}
                  >
                    VALUE &amp; WEIGHT
                  </Typography>
                </Grid>
              </Grid>
            </ListItem>
            <Divider />
            {groupRows}
          </Grid>
          <Grid item xs={8} style={{ width: '100%' }}>
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid item xs={1} style={{ paddingRight: 0 }}>
                <DotIcon fontSize={'large'} htmlColor={chartColors[colorIndex]} />
              </Grid>
              <Grid item xs={4} style={{ paddingLeft: 10 }}>
                <Typography variant="h3" color={'primary'}>
                  {!selectedAssetGroup ? parameters.groupingType : selectedAssetGroup.groupName}
                </Typography>

                <Box style={{ display: 'flex', flexFlow: 'nowrap' }}>
                  <Typography variant="h5" color={'textSecondary'}>
                    {floatingLossPositions}
                  </Typography>
                  <ExpandMoreIcon color="error" />
                  <Typography variant="h5" color={'textSecondary'}>
                    {floatingProfitPositions}
                  </Typography>
                  <ExpandLessIcon color="secondary" />
                </Box>
              </Grid>
              <Grid item xs={3} style={{ paddingRight: '0px' }}>
                <Typography noWrap variant={'h5'}>
                  <Box fontWeight={700} textAlign={'left'}>
                    {formatDollars(selectedAssetGroup?.currentValue)}
                  </Box>
                </Typography>
                <Typography noWrap variant={'h6'}>
                  <Box fontWeight={700} textAlign={'left'}>
                    {formatPercentage(selectedAssetGroup?.currentWeightPercentage)}
                  </Box>
                </Typography>
              </Grid>
              <Grid item xs={2} style={{ paddingRight: '5px' }}>
                <Typography variant={'h5'}>
                  <Box fontWeight={700} textAlign={'left'}>
                    {formatDollars(selectedAssetGroup?.targetValue)}
                  </Box>
                </Typography>
                <Typography variant={'h6'}>
                  <Box fontWeight={700} textAlign={'left'}>
                    {formatPercentage(selectedAssetGroup?.targetWeightPercentage)}
                  </Box>
                </Typography>
              </Grid>
              <Grid item xs={2} style={{ paddingRight: '5px' }}>
                <Typography variant={'h5'}>
                  <Box fontWeight={700} textAlign={'left'} paddingRight={'2px'}>
                    {formatDollars(selectedAssetGroup?.differenceValue)}
                  </Box>
                </Typography>
                <Typography variant={'h6'}>
                  <Box fontWeight={700} textAlign={'left'}>
                    {formatPercentage(selectedAssetGroup?.differenceWeightPercentage)}
                  </Box>
                </Typography>
              </Grid>
            </Grid>
            <SecurityList
              assetClassColor={chartColors[colorIndex]}
              data={filteredAssetGroupItems ?? []}
              progress={progress}
              showSecurityPieChart={showSecurityPieChart}
              parameters={parameters}
              assetClassTitle={!selectedAssetGroup ? parameters.groupingType : selectedAssetGroup.groupName}
              selectedAssetGroup={selectedAssetGroup}
              colorIndex={colorIndex}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
