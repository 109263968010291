/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'src/app/api';
import { ActivateInvestmentServiceVersionPayload, ActivateInvestmentServiceVersionResponse } from '.';
import { fetchInvestmentServiceVersionsByClient } from '../../../store/common/thunks';

export enum ValidationMessagesActionTypes {
  ActivateInvestmentServiceVersion = '@@client/investmentServices/configuration/ActivateInvestmentServiceVersion',
}

export enum ValidationMessagesApiEndpoints {
  ActivateInvestmentServiceVersion = '/investmentServices/ActivateInvestmentServiceVersion',
}

export const activateInvestmentServiceVersion = createAsyncThunk<ActivateInvestmentServiceVersionResponse, ActivateInvestmentServiceVersionPayload>(
  ValidationMessagesActionTypes.ActivateInvestmentServiceVersion,
  async (payload: ActivateInvestmentServiceVersionPayload, thunkApi) => {
    return await api
      .post<ActivateInvestmentServiceVersionResponse>(ValidationMessagesApiEndpoints.ActivateInvestmentServiceVersion, payload)
      .then(async (response) => {
        await thunkApi.dispatch(
          fetchInvestmentServiceVersionsByClient({
            clientId: payload.clientId,
            showLatestVersionOnly: false,
          })
        );

        if (!!response.data.messages && response.data.messages.length > 0) {
          return response.data;
        } else {
          return { ...response.data, message: 'Portfolio version activated' };
        }
      })
      .catch((e) => {
        throw e;
      });
  }
);
