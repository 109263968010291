import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, FormControlLabel, Grid, Typography } from '@mui/material';
import { WO2Checkbox, WO2ToggleSwitch } from 'src/common';
import {
  ClientReportsParameters,
  HidingOptionHoldingsVsTargetVariance,
  HidingOptionsRequest,
  MdaReportTypes,
} from '../../store/types';
import { ActionCreatorWithoutPayload, ActionCreatorWithPayload } from '@reduxjs/toolkit';
import React from 'react';
import { DateRange } from 'src/store';
import DateRangePicker from 'src/common/components/DateRangePicker';

export interface HoldingsVsTargetVarianceProps {
  parameters: ClientReportsParameters;
  setHoldingsVsTargetVarianceSelectedParameter: ActionCreatorWithPayload<boolean, string>;
  setAsAtDateHoldingsVsTargetVarianceParameter: ActionCreatorWithPayload<DateRange>;
  setHoldingsVsTargetVarianceHidingOptions: ActionCreatorWithPayload<
    HidingOptionsRequest<HidingOptionHoldingsVsTargetVariance>,
    string
  >;
  expandHoldingsVsTargetVariance: ActionCreatorWithoutPayload<string>;
  createReportSortRequest: (isAdd: boolean, id: number, text: string) => void;
}

export const HoldingsVsTargetVariance = ({
  parameters,
  setHoldingsVsTargetVarianceSelectedParameter,
  setAsAtDateHoldingsVsTargetVarianceParameter,
  setHoldingsVsTargetVarianceHidingOptions,
  createReportSortRequest,
  expandHoldingsVsTargetVariance,
}: HoldingsVsTargetVarianceProps) => {
  const handleClickHoldingsVsTargetVarianceSelect = () => {
    setHoldingsVsTargetVarianceSelectedParameter(!parameters.selectedReports?.holdingsVsTargetVariance.selected);
    createReportSortRequest(
      !parameters.selectedReports?.holdingsVsTargetVariance.selected,
      MdaReportTypes.HoldingsVsTargetVariance.id,
      MdaReportTypes.HoldingsVsTargetVariance.displayName
    );
  };
  const handleClickDateForHoldingsVsTargetVariance = (dates: DateRange) => {
    setAsAtDateHoldingsVsTargetVarianceParameter(dates);
  };
  return (
    <Accordion
      onChange={expandHoldingsVsTargetVariance}
      expanded={parameters.selectedReports?.holdingsVsTargetVariance.expanded || false}
      elevation={0}
    >
      <AccordionSummary expandIcon={<ExpandMore />}>
        <FormControlLabel
          sx={{ flex: 1 }}
          aria-label="Acknowledge"
          onClick={(event) => event.stopPropagation()}
          onFocus={(event) => event.stopPropagation()}
          control={
            <WO2Checkbox
              color="primary"
              checked={parameters.selectedReports?.holdingsVsTargetVariance.selected}
              onChangeHandler={handleClickHoldingsVsTargetVarianceSelect}
            />
          }
          label="Holdings vs Target Variance"
        />
        <div onClick={(e) => e.stopPropagation()}>
          <DateRangePicker
            sx={{ minWidth: 194 }}
            dateRange={{
              dateFrom: parameters?.selectedReports.holdingsVsTargetVariance?.asAtDate,
              dateTo: new Date().toISOString(),
              dateRangeType: parameters?.selectedReports.holdingsVsTargetVariance?.asAtDateType,
            }}
            setDateRange={handleClickDateForHoldingsVsTargetVariance}
            inceptionDate={parameters.inceptionDate}
            isDateOnly
          />
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={1} alignItems="center">
          <Grid item container xs={6} alignItems="center">
            <WO2ToggleSwitch
              onChange={(e) =>
                setHoldingsVsTargetVarianceHidingOptions({
                  value: e.target.checked,
                  option: HidingOptionHoldingsVsTargetVariance.ExclusionList,
                })
              }
              checked={parameters.selectedReports.holdingsVsTargetVariance.applyExclusionList}
              color="primary"
            />
            <Typography variant="h4">Apply Exclusion List</Typography>
          </Grid>
          <Grid item container xs={6} alignItems="center">
            <WO2ToggleSwitch
              onChange={(e) =>
                setHoldingsVsTargetVarianceHidingOptions({
                  value: e.target.checked,
                  option: HidingOptionHoldingsVsTargetVariance.Summary,
                })
              }
              checked={parameters.selectedReports.holdingsVsTargetVariance.includeSummary}
              color="primary"
            />
            <Typography variant="h4">Include Summary</Typography>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
