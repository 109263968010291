import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { activateInvestmentServiceVersion, ActivateInvestmentServiceVersionResponse, ActivationState } from '.';

export const initialState: ActivationState = {
  validationMessages: [],
  status: 'activating',
};

export const activationSlice = createSlice({
  name: '@common/clients/client/investments/configuration/activation',
  initialState,
  reducers: {
    clearValidationMessages: (state) => {
      state.validationMessages = [];
      state.status = 'pending';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(activateInvestmentServiceVersion.pending, (state) => {
      state.status = 'activating';
    });
    builder.addCase(activateInvestmentServiceVersion.fulfilled, (state, action: PayloadAction<ActivateInvestmentServiceVersionResponse>) => {
      state.validationMessages = action.payload?.messages || [];
      state.status = action.payload?.messages ? 'pending' : 'activated';
    });
    builder.addCase(activateInvestmentServiceVersion.rejected, (state) => {
      state.status = 'pending';
    });
  },
});
