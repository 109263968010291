import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React from 'react';
import { formatDollars } from '../../../../../common/utils/currencyFunctions';
import { SecurityItemWithPrice } from '../../../common/store/types';
import { Totals } from '../store/types';

interface Props {
  security: SecurityItemWithPrice | undefined;
  totals: Totals;
}

export default function AssetsSummary(props: Props): JSX.Element {
  const { security, totals } = props;

  return (
    <TableContainer component={Paper} elevation={0}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell variant={'body'}>
              <Typography variant="h3" color={'primary'} style={{ padding: '4px 0px' }}>
                Summary
              </Typography>
            </TableCell>
            <TableCell variant={'body'} align="left"></TableCell>
            <TableCell variant={'body'} align="left"></TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant={'head'}>
              <Typography variant="h6" color="textSecondary">
                SECURITY
              </Typography>
            </TableCell>
            <TableCell variant={'head'} align="center">
              <Typography variant="h6" color="textSecondary">
                ORDERS
              </Typography>
            </TableCell>
            <TableCell variant={'head'} align="right">
              <Typography variant="h6" color="textSecondary">
                VALUE
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow key={1}>
            <TableCell component="th" scope="row">
              <Typography variant="h5">{security?.code}</Typography>
            </TableCell>
            <TableCell align="center">
              <Typography variant="h5">{totals.totalOrders}</Typography>
            </TableCell>
            <TableCell align="right">
              <Typography variant="h5">{formatDollars(totals.totalOrderSize)}</Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
