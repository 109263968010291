import { connect, ConnectedProps } from 'react-redux';
import { hasErrorsSelectorFactory, isLoadingSelectorFactory } from '../../../common/store/selectors';
import { RootState } from '../../../reducers';
import { selectHasClientEditAdminOnly } from '../../../store/selectors';
import { ModelList } from './components/list';
import { selectModelList, selectParameters, selectStrategyList, selectStrategyParameters } from './store/selectors';
import { modelListSlice } from './store/slice';
import { fetchModelList, fetchStrategyList, ModelListActionTypes } from './store/thunks';

const mapStateToProps = (state: RootState) => ({
  parameters: selectParameters(state),
  strategyParameters: selectStrategyParameters(state),
  modelList: selectModelList(state),
  strategyList: selectStrategyList(state),
  isLoadingModelList: isLoadingSelectorFactory([ModelListActionTypes.FetchModelList])(state),
  hasErrorsModelList: hasErrorsSelectorFactory([ModelListActionTypes.FetchModelList])(state),
  isLoadingStrategyList: isLoadingSelectorFactory([ModelListActionTypes.FetchStrategyList])(state),
  hasErrorsStrategyList: hasErrorsSelectorFactory([ModelListActionTypes.FetchStrategyList])(state),
  hasClientEditAdminOnlyPermission: selectHasClientEditAdminOnly(state),
});

const mapDispatchToProps = {
  ...modelListSlice.actions,
  fetchModelList,
  fetchStrategyList,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector>;
export const ModelListStateContainer = connect(mapStateToProps, mapDispatchToProps)(ModelList);
