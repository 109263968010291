import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchHasEditModelAccess, fetchModelVersions } from './thunks';
import { ModelCommonPayload, ModelCommonState, ModelVersions } from './types';

export const initialState: ModelCommonState = {
  currentModelStatus: '',
  currentModelVersion: '',
  currentModelId: null,
  currentModelVersionId: null,
  modelVersions: [],
  hasEditModelAccess: false,
};

export const modelCommonSlice = createSlice({
  name: '@@model/common',
  initialState,
  reducers: {
    setModelVersion: (state, action: PayloadAction<ModelCommonPayload>) => {
      state.currentModelId = action.payload.modelId;
      state.currentModelVersion = action.payload.modelVersion;
      state.currentModelVersionId = action.payload.modelVersionId;
      state.currentModelStatus = action.payload.modelStatus;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchModelVersions.fulfilled, (state, action: PayloadAction<ModelVersions>) => {
      state.modelVersions = action.payload;
    });
    builder.addCase(fetchHasEditModelAccess.fulfilled, (state, action: PayloadAction<boolean>) => {
      state.hasEditModelAccess = action.payload;
    });
  },
});
