/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'src/app/api';
import { DataFeed } from '.';

export enum TemplateUserActionTypes {
  FetchDataFeeds = '@@store/userSettings/FetchDataFeeds',
}

export enum TemplateUserApiEndpoints {
  FetchDataFeeds = '/feedmanager/GetAdviserFeeds',
}

export const fetchDataFeeds = createAsyncThunk<DataFeed[]>(TemplateUserActionTypes.FetchDataFeeds, async () => {
  const response = await api.post<DataFeed[]>(TemplateUserApiEndpoints.FetchDataFeeds);
  return response.data.map<DataFeed>((a) => ({ vendor: a.vendor, isRegistered: a.isRegistered }));
});
