import { Autocomplete as MuiAutocomplete, TextField } from '@mui/material';
import React from 'react';
import { Enumeration } from '../types';

interface Props {
  label?: string;
  type: typeof Enumeration;
  value: string[];
  onChange: (value: string[]) => void;
}

export function EnumerationMultiSelect(props: Props): JSX.Element {
  const { label, value, type, onChange } = props;
  const options = Object.values(type)
    .filter((t) => t instanceof type)
    .map((val) => {
      const optionVal: Enumeration = val;
      return optionVal;
    });

  const enumerationValues: Enumeration[] = options.filter((i) => value.includes(i.name));

  const handleChange = (value: Enumeration[]) => {
    const stringValues = value.map((i) => i.name);
    onChange(stringValues);
  };

  return (
    <div>
      <MuiAutocomplete
        noOptionsText="No items to select"
        id="enumeration-multiselect"
        multiple
        fullWidth
        value={enumerationValues}
        options={options ?? []}
        getOptionLabel={(option: Enumeration) => option.displayName}
        onChange={(_e: React.SyntheticEvent<Element, Event>, value: Enumeration[]) => handleChange(value)}
        renderInput={(params) => <TextField {...params} label={label} margin="normal" variant="outlined" fullWidth />}
      />
    </div>
  );
}
