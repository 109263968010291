import ShowChartIcon from '@mui/icons-material/ShowChart';
import React, { Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { formatDollars, progressSelectorFactory } from '../../../../../common';
import CardComponent from '../../../../../common/components/CardComponent';
import { RootState } from '../../../../../reducers';
import { theme } from '../../../../../themes';
import { selectMonthlyPerformanceChartResults, selectParameters } from '../store/selectors';
import { DashboardActionTypes } from '../store/thunks';

class GainLossCard extends Component<Props> {
  render() {
    return (
      <CardComponent
        progress={this.props.loadingProgress}
        background={theme.palette.gradient2?.main}
        value={formatDollars(this.props.value)}
        subText={'TOTAL RETURN, ' + this.props.interval}
        icon={ShowChartIcon}
      />
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  value: selectMonthlyPerformanceChartResults(state)?.changeInValue,
  interval: selectParameters(state).monthlyPerformanceChartStartDate?.interval,
  loadingProgress: progressSelectorFactory([DashboardActionTypes.FetchMonthlyPerformanceChart])(state),
});

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

export default connector(GainLossCard);
