import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { editSlice } from '../../edit/store/slice';
import { validateBulkOrder } from '../../edit/store/thunks';
import { PreApprovalInvestmentService, ValidateOrderResultPayload } from '../../types';
import { confirmBulkOrder } from './thunks';
import { CheckoutState } from './types';

export const initialState: CheckoutState = {
  order: [] as PreApprovalInvestmentService[],
  comments: '',
  submitted: false,
  validationMessages: {
    errors: [],
    information: [],
    warnings: [],
  },
  rebalanceId: '',
  investmentServicesLockedByOrders: [],
};

export const checkoutSlice = createSlice({
  name: '@@bulk/order/checkout',
  initialState,
  reducers: {
    setComments: (state: CheckoutState, action: PayloadAction<string>) => {
      state.comments = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(editSlice.actions.setBulkOrderValidated, (state, action: PayloadAction<ValidateOrderResultPayload>) => {
      state.submitted = false;
      state.comments = '';
      state.validationMessages = action.payload.validationResult;
      state.investmentServicesLockedByOrders = action.payload.investmentServicesLockedByOrders;
      state.order = action.payload.order;
    });
    builder.addCase(validateBulkOrder.fulfilled, (state, action: PayloadAction<ValidateOrderResultPayload>) => {
      state.submitted = false;
      state.comments = '';
      state.validationMessages = action.payload.validationResult;
      state.investmentServicesLockedByOrders = action.payload.investmentServicesLockedByOrders;
      state.order = action.payload.order;
    });
    builder.addCase(confirmBulkOrder.rejected, (state, action) => {
      if (action.payload) {
        state.validationMessages = action.payload;
      }
      state.submitted = false;
    });
    builder.addCase(confirmBulkOrder.fulfilled, (state, action: PayloadAction<string>) => {
      state.rebalanceId = action.payload;
      state.submitted = true;
    });
  },
});
