import { Box, Breadcrumbs, Container, Link, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Props } from '../container';
import PreapprovedTrades from './preapprovedTrades';
import Summary from './summary';
import ValidationMessages from './validationMessages';

export default function CheckoutComponent(props: Props & RouteComponentProps<void>): JSX.Element {
  const { security, trades } = props;

  useEffect(() => {
    document.title = ' DASH | Bulk Order';

    const message = 'Warning!\n\nNavigating away from this page will delete your bulk order.';

    window.addEventListener('beforeunload', (e: BeforeUnloadEvent) => {
      e.returnValue = message;
      return message;
    });

    return () => {
      window.removeEventListener('beforeunload', (e: BeforeUnloadEvent) => {
        e.returnValue = message;
        return message;
      });
    };
  }, []);

  return (
    <Container style={{ paddingTop: '40px', paddingBottom: '40px' }}>
      <Typography variant="h2" style={{ display: 'inline-flex', width: '100%', height: '48px' }}>
        Bulk
      </Typography>
      <Breadcrumbs aria-label="breadcrumb">
        <Link color="inherit" href="/bulk/order">
          Bulk
        </Link>
        <Link color="inherit" href="/bulk/order/edit">
          Order
        </Link>
        <Typography color="textPrimary">Checkout</Typography>
      </Breadcrumbs>
      <ValidationMessages {...props} />
      <Box style={{ display: 'inline-flex' }} width={'100%'} paddingTop={'25px'}>
        <Box style={{ paddingRight: '40px', width: '100%' }}>
          <PreapprovedTrades
            key={1}
            trades={trades}
            security={security}
            hasRebalanceEnhancedTrading
            hasClientEditAdminOnlyPermission
          />
        </Box>
        <Box style={{ width: '450px' }}>
          <Summary {...props} />
        </Box>
      </Box>
    </Container>
  );
}
