import { Backdrop, Box, Card, CardHeader, Fade, Modal, Typography } from '@mui/material';
import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import { useStyles } from '../../../../../themes/index';
import WO2Button from './../../../../../common/components/button/Button';
export interface Props {
  handleImport: (file: File) => void;
  handleClose: () => void;
  isOpen: boolean;
  title: string;
}

export const UploadReportAttachment = (props: Props): JSX.Element => {
  const classes = useStyles();
  const [acceptedFiles, setAcceptedFiles] = useState<File[]>([]);
  const [importErrors, setImportErrors] = useState<string>();

  const file = acceptedFiles.map((selectedFile) => (
    <span key={selectedFile.name}>
      {selectedFile.name} - {selectedFile.size} bytes
    </span>
  ));

  const handleValidFile = (files: File[]) => {
    if (files[0].size / 1000000 > 50) {
      setImportErrors('Attachment has exceeded size limit (50MB)');
    } else if (files[0].name.indexOf('.pdf') < 0) {
      setImportErrors('Only PDF documents can be uploaded.');
    } else {
      setImportErrors('');
    }
    setAcceptedFiles(files);
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={props.isOpen}
      onClose={() => {
        props.handleClose();
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={props.isOpen}>
        <Card elevation={0} style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
          <CardHeader title={<Typography variant="h3">{props.title}</Typography>}> </CardHeader>
          <Dropzone onDrop={(files) => handleValidFile(files)} multiple={false}>
            {({ getRootProps, getInputProps }) => (
              <div data-testid="dropzone" {...getRootProps({ className: classes.dropzone })}>
                <input
                  {...getInputProps({
                    accept: '.pdf',
                    type: 'file',
                  })}
                />
                <p>Drag and drop your file here, or click to select file</p>
              </div>
            )}
          </Dropzone>
          <Box padding={'20px'}>
            <Typography>File: {file}</Typography>
            {importErrors && importErrors.length > 0 && <Typography color={'error'}>{importErrors}</Typography>}
          </Box>
          <Box display={'flex'} justifyContent={'flex-end'} padding={'20px'}>
            <WO2Button
              variant={'outlined'}
              value="Cancel"
              style={{ marginRight: '10px' }}
              onClick={() => {
                props.handleClose();
                setImportErrors('');
                acceptedFiles.length = 0;
                acceptedFiles.splice(0, acceptedFiles.length);
              }}
            >
              Cancel
            </WO2Button>
            <WO2Button
              variant="contained"
              value="Upload"
              color="primary"
              disabled={!acceptedFiles || acceptedFiles.length !== 1 || importErrors !== ''}
              onClick={() => {
                if (acceptedFiles && acceptedFiles.length === 1) {
                  props.handleImport(acceptedFiles[0]);
                  acceptedFiles.length = 0;
                  acceptedFiles.splice(0, acceptedFiles.length);
                }
              }}
            >
              Upload
            </WO2Button>
          </Box>
        </Card>
      </Fade>
    </Modal>
  );
};
