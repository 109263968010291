import { Container, Grid, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Select from '@mui/material/Select';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { useCallback, useEffect } from 'react';
import { LoadingIndicator } from 'src/common/components/LoadingIndicator';
import { formatPercentage } from '../../../../common';
import { DataSelect } from '../../../../common/components/DataSelect';
import { Props } from '../container';
import { StrategyVersion, StrategyVersionCompositionItem } from '../store/types';

export const CompositionComponent = ({
  loadingProgress,
  fetchStrategyComposition,
  setSelectedVersion,
  selectedVersion,
  versions,
  strategyComposition,
  match: { params },
}: Props): JSX.Element => {
  const fetch = useCallback(() => {
    if (params.strategyId) {
      fetchStrategyComposition({ strategyId: parseInt(params.strategyId) });
    }
  }, [params.strategyId, fetchStrategyComposition]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const chartColors = [
    '#2c82be',
    '#429ff9',
    '#423cff',
    '#0080ff',
    '#000080',
    '#008ecc',
    '#008081',
    '#4482b4',
    '#111e6c',
    '#1d2951',
    '#dbecf8',
    '#2c82be',
    '#429ff9',
    '#423cff',
    '#0080ff',
    '#000080',
    '#008ecc',
    '#008081',
    '#4482b4',
    '#111e6c',
    '#1d2951',
    '#dbecf8',
    '#2c82be',
    '#429ff9',
    '#423cff',
    '#0080ff',
    '#000080',
    '#008ecc',
    '#008081',
    '#4482b4',
    '#111e6c',
    '#1d2951',
    '#dbecf8',
  ];

  const chartData = selectedVersion?.strategyVersionCompositionItems.map((i: StrategyVersionCompositionItem, index: number) => ({
    y: Math.abs(i.allocation / 100),
    name: i.securityName,
    color: chartColors[index],
  }));
  const chartOptions = {
    chart: {
      type: 'pie',
    },
    allowPointSelect: true,
    title: {
      verticalAlign: 'middle',
      floating: true,
      text: '',
    },
    colors: chartColors,
    subtitle: {
      text: '',
    },
    plotOptions: {
      pie: {
        innerSize: '60%',
        depth: 45,
        tooltip: {
          pointFormatter: function (this: Highcharts.TooltipFormatterContextObject) {
            return `<span style="color:${this.color}">●</span> <b>${this.percentage?.toFixed(2)}%</b><br/>`;
          },
        },
      },
    },
    series: [
      {
        allowPointSelect: true,
        name: '',
        data: chartData,
        dataLabels: {
          enabled: false,
        },
      },
    ],
    credits: {
      enabled: false,
    },
  };

  const rightTableHeader = (
    <Grid container>
      <Grid item xs={3}>
        <Typography variant="h6" style={{ paddingLeft: '15px' }}>
          CODE
        </Typography>
      </Grid>
      <Grid item xs={5}>
        <Typography variant="h6" style={{ paddingLeft: '8px' }}>
          NAME
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant="h6" style={{ paddingRight: '10px' }}>
          SECURITY TYPE
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant="h6" align="right" style={{ paddingRight: '13px' }}>
          TARGET ALLOCATION
        </Typography>
      </Grid>
    </Grid>
  );

  const rightTableRows = versions?.map((row: StrategyVersionCompositionItem, index: number) => {
    return (
      <ListItem key={index} divider>
        <Grid container>
          <Grid item xs={3}>
            <Typography variant={'h5'}>{row.securityCode}</Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography variant={'h5'} color={'primary'}>
              {row.securityName}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant={'h5'} color={'textSecondary'}>
              {row.securityType}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant={'h5'} align="right">
              {formatPercentage(row.allocation / 100)}
            </Typography>
          </Grid>
        </Grid>
      </ListItem>
    );
  });

  return (
    <Container style={{ paddingTop: '20px', paddingBottom: '20px' }}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Card elevation={0}>
            <Grid container>
              <Typography variant="h3" align="left" style={{ padding: '10px' }}>
                All Securities
              </Typography>
            </Grid>
            <CardContent>
              <Grid item>
                <HighchartsReact highcharts={Highcharts} options={chartOptions} />
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={8}>
          <Grid container spacing={2} style={{ paddingBottom: '10px' }}>
            <Grid item xs={6}>
              <DataSelect
                value={selectedVersion?.majorVersion || null}
                label="VERSION"
                getId={(selectedVersion: StrategyVersion): number => selectedVersion.majorVersion}
                getName={(selectedVersion: StrategyVersion) => `Version ${selectedVersion.majorVersion} - ${selectedVersion.status}`}
                onChange={(value: number | string | null) => {
                  const version = (strategyComposition?.strategyVersions || []).find((v: StrategyVersion) => v.majorVersion === value);
                  if (version) {
                    setSelectedVersion(version);
                  }
                }}
                data={strategyComposition?.strategyVersions || []}
                onBlur={() => null}
                none={false}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl variant="outlined">
                <InputLabel htmlFor="outlined-age-native-simple">ASSET CLASS</InputLabel>
                <Select native value={strategyComposition?.assetClass} label="ASSET CLASS" disabled={true} variant="standard">
                  <option value={10}>{strategyComposition?.assetClass}</option>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Card elevation={0}>
            <CardContent>
              <LoadingIndicator progress={loadingProgress}>
                <>
                  <Grid>{rightTableHeader}</Grid>
                  <List>{rightTableRows}</List>
                </>
              </LoadingIndicator>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};
