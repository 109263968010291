import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  fetchApprovedProducts,
  fetchInvestmentServices,
  fetchInvestmentServiceVersions,
  fetchInvestmentServiceVersionsByClient,
  fetchPortfolioAssetClasses,
  fetchSecurities,
} from './thunks';
import {
  ApprovedProduct,
  CommonState,
  InvestmentServiceDetail,
  InvestmentServiceVersion,
  InvestmentServiceWithVersion,
  PortfolioDetailResult,
  SecurityItem,
  SetInvestmentServiceVersionPayload,
} from './types';

export const initialState: CommonState = {
  investmentServiceId: null,
  investmentServiceVersionId: null,
  securities: [],
  assetClasses: [],
  investmentServices: [],
  investmentServicesWithVersions: [],
  approvedProducts: [],
};

export const commonSlice = createSlice({
  name: '@common/clients/client/investments/common',
  initialState,
  reducers: {
    setInvestmentServiceVersion: (state, action: PayloadAction<SetInvestmentServiceVersionPayload>) => {
      state.investmentServiceId = action.payload.investmentServiceId;
      state.investmentServiceVersionId = action.payload.investmentServiceVersionId;
    },
    setInvestmentServiceVersionId: (state, action: PayloadAction<number>) => {
      state.investmentServiceVersionId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchInvestmentServices.fulfilled, (state, action: PayloadAction<InvestmentServiceDetail[]>) => {
      state.investmentServices = action.payload.map((investmentService) => ({
        investmentProgramId: investmentService.investmentProgramId,
        investmentServiceCode: investmentService.investmentServiceCode,
        investmentServiceName: investmentService.investmentServiceName,
        inceptionDate: investmentService.inceptionDate,

        investmentProgramVersionId: investmentService.investmentProgramVersionId,
        versions: [],
      }));
    });

    builder.addCase(fetchInvestmentServiceVersions.fulfilled, (state, action: PayloadAction<InvestmentServiceVersion[]>) => {
      // populate the versions for each investmentService
      state.investmentServices = state.investmentServices.map((investmentService) => ({
        ...investmentService,
        versions: action.payload.filter((v) => v.investmentServiceId === investmentService.investmentProgramId),
      }));
    });

    builder.addCase(fetchSecurities.fulfilled, (state, action: PayloadAction<SecurityItem[]>) => {
      state.securities = action.payload;
    });

    builder.addCase(fetchPortfolioAssetClasses.fulfilled, (state, action: PayloadAction<PortfolioDetailResult>) => {
      const assetClasses = action.payload.portfolioDetail.items.map((asset) => ({
        targetWeightPercentage: asset.targetWeightPercentage,
        groupName: asset.groupName,
      }));

      state.assetClasses = assetClasses;
    });

    builder.addCase(fetchInvestmentServiceVersionsByClient.fulfilled, (state, action: PayloadAction<InvestmentServiceWithVersion[]>) => {
      state.investmentServicesWithVersions = action.payload.sort((a, b) => (a.name || '').localeCompare(b.name || ''));

      if (action.payload.length > 0) {
        if (!action.payload.find((v) => v.investmentServiceVersionId === state.investmentServiceVersionId)) {
          state.investmentServiceId = state.investmentServicesWithVersions[0].investmentServiceId;
          state.investmentServiceVersionId = state.investmentServicesWithVersions[0].investmentServiceVersionId;
        }
      }
    });

    builder.addCase(fetchInvestmentServiceVersionsByClient.rejected, (state) => {
      state.investmentServicesWithVersions = [];
      state.investmentServiceId = null;
      state.investmentServiceVersionId = null;
    });

    builder.addCase(fetchApprovedProducts.fulfilled, (state, action: PayloadAction<ApprovedProduct[]>) => {
      state.approvedProducts = action.payload;
    });
  },
});
