import { Avatar, Paper, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import React, { ReactElement } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { getAvatarText } from '../../../../common';
import { theme } from '../../../../themes';

interface Props extends RouteComponentProps {
  tileText?: string;
}

export const TileComponent = (props: Props): ReactElement => {
  return (
    <Paper style={{ display: 'inline-flex', paddingBottom: '20px' }} elevation={0} square>
      <Avatar style={{ padding: '30px', margin: '30px 30px 0 0', backgroundColor: theme.palette.primary.main }}>{getAvatarText(props.tileText)}</Avatar>
      <Grid container style={{ margin: '30px 30px 0 0' }}>
        <Grid item xs={12}>
          <Typography variant="h3">{props.tileText}</Typography>
        </Grid>
        <Grid item xs={12}></Grid>
      </Grid>
    </Paper>
  );
};
