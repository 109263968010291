export interface FeatureFlagsState {
  featureFlags: FeatureFlags;
}

export enum FeatureNames {
  TopMenu = 'TopMenu',
}

export enum FeatureStrategyNames {
  default = 'default',
  userWithId = 'userWithId',
}

export interface FeatureFlags {
  version: number;
  features: Feature[];
}

export interface Feature {
  name: FeatureNames;
  description: string;
  enabled: boolean;
  strategies: FeatureStrategy[];
}

interface FeatureStrategy {
  name: FeatureStrategyNames;
  parameters: FeatureStrategyParameters;
}

interface FeatureStrategyParameters {
  userIdList?: string; // e.g. 123,221,998,
}
