import UpdateIcon from '@mui/icons-material/Update';
import { Tooltip, Typography } from '@mui/material';
import { MUIDataTableOptions } from 'mui-datatables';
import React, { useState } from 'react';
import { formatDollars, formatNumberCommaSeparated, formatPercentage } from '../../../../../../common';
import { ClientSideDataTable } from '../../../../../../common/components/dataTable/clientSide/clientSideDataTable';
import { DatatableColumn, FilterDataType } from '../../../../../../common/components/dataTable/types';
import { LoadingProgress } from '../../../../../../common/store/types';
import { theme } from '../../../../../../themes';
import { AssetGroup, AssetGroupItem, PortfolioParameters } from './../store/types';

export interface Props {
  progress: LoadingProgress;
  data: AssetGroupItem[];
  assetClassColor: string;
  showSecurityPieChart: (security: AssetGroupItem) => void;
  parameters: PortfolioParameters;
  assetClassTitle: string;
  selectedAssetGroup: AssetGroup | null;
  colorIndex: number;
}

export function SecurityList({ data, progress, showSecurityPieChart }: Props): JSX.Element {
  const [selectedSecurity, setSelectedSecurity] = useState<AssetGroupItem | null>(null);

  const selectSecurity = (security: AssetGroupItem) => {
    document.getElementById('groupByContainer')?.scrollIntoView();
    setSelectedSecurity(security);
    showSecurityPieChart(security);
  };

  const customTableHeaderOptions = {
    sort: true,
  };

  const columns: DatatableColumn[] = [
    {
      filterDataType: FilterDataType.string,
      label: 'NAME',
      name: 'securityCode',
      options: {
        ...customTableHeaderOptions,
        customBodyRenderLite: function customBodyRenderLite(dataIndex: number): React.ReactNode {
          return (
            <div
              onClick={() =>
                data[dataIndex].securityName === selectedSecurity?.securityName
                  ? selectSecurity(data[dataIndex])
                  : selectSecurity(data[dataIndex])
              }
              style={{ width: '180px' }}
            >
              <Typography
                variant={'h5'}
                color={'primary'}
                style={{
                  color: theme.palette.primary.main,
                  fontWeight: 'bold',
                  letterSpacing: '1px',
                  cursor: 'pointer',
                }}
              >
                {data[dataIndex]?.securityCode}
              </Typography>
              <Typography color={'textSecondary'} variant={'h6'}>
                {data[dataIndex]?.securityName}
              </Typography>
            </div>
          );
        },
        customHeadLabelRender: function customHeadLabelRender(): React.ReactNode {
          return (
            <div
              style={{
                textAlign: 'left',
                width: '180px',
              }}
            >
              <Typography
                variant="h6"
                style={{
                  textTransform: 'uppercase',
                  color: '#7d7d7d',
                  fontSize: '11px',
                }}
              >
                NAME
              </Typography>
            </div>
          );
        },
      },
    },
    {
      filterDataType: FilterDataType.string,
      name: 'units',
      label: 'UNITS / PRICE',
      textAlign: 'left',
      options: {
        ...customTableHeaderOptions,
        customBodyRenderLite: function customBodyRenderLite(dataIndex: number): React.ReactNode {
          return (
            <div
              onClick={() =>
                data[dataIndex].securityName === selectedSecurity?.securityName
                  ? selectSecurity(data[dataIndex])
                  : selectSecurity(data[dataIndex])
              }
              style={{ width: '90px' }}
            >
              <Typography variant={'h5'} align="right">
                {formatNumberCommaSeparated(data[dataIndex]?.units)}
              </Typography>
              <Typography color={'textSecondary'} variant={'h6'} align="right">
                {formatDollars(data[dataIndex]?.unitPrice)}
              </Typography>
            </div>
          );
        },
        customHeadLabelRender: function customHeadLabelRender(): React.ReactNode {
          return (
            <div
              style={{
                textAlign: 'right',
                width: '90px',
              }}
            >
              <Typography
                variant="h6"
                align="right"
                style={{
                  textAlign: 'right',
                  textTransform: 'uppercase',
                  color: '#7d7d7d',
                  fontSize: '11px',
                }}
              >
                UNITS / PRICE
              </Typography>
            </div>
          );
        },
      },
    },
    {
      filterDataType: FilterDataType.string,
      textAlign: 'left',
      name: 'currentValue',
      label: 'CURRENT',
      options: {
        ...customTableHeaderOptions,
        customBodyRenderLite: function customBodyRenderLite(dataIndex: number): React.ReactNode {
          return (
            <div
              onClick={() =>
                data[dataIndex].securityName === selectedSecurity?.securityName
                  ? selectSecurity(data[dataIndex])
                  : selectSecurity(data[dataIndex])
              }
              style={{ width: '90px' }}
            >
              <Typography variant={'h5'} align="right">
                {formatDollars(data[dataIndex]?.currentValue)}
              </Typography>
              <Typography color={'textSecondary'} variant={'h6'} align="right">
                {formatPercentage(data[dataIndex]?.currentWeightPercentage)}
              </Typography>
            </div>
          );
        },
        customHeadLabelRender: function customHeadLabelRender(): React.ReactNode {
          return (
            <div
              style={{
                textAlign: 'right',
                width: '90px',
              }}
            >
              <Typography
                variant="h6"
                align="right"
                style={{
                  textAlign: 'right',
                  textTransform: 'uppercase',
                  color: '#7d7d7d',
                  fontSize: '11px',
                }}
              >
                CURRENT
              </Typography>
            </div>
          );
        },
      },
    },
    {
      filterDataType: FilterDataType.string,
      textAlign: 'left',
      name: 'targetValue',
      label: 'TARGET',
      options: {
        ...customTableHeaderOptions,
        customBodyRenderLite: function customBodyRenderLite(dataIndex: number): React.ReactNode {
          return (
            <div
              onClick={() =>
                data[dataIndex].securityName === selectedSecurity?.securityName
                  ? selectSecurity(data[dataIndex])
                  : selectSecurity(data[dataIndex])
              }
              style={{ width: '90px' }}
            >
              <Typography variant={'h5'} align="right">
                {formatDollars(data[dataIndex]?.targetValue)}
              </Typography>
              <Typography color={'textSecondary'} variant={'h6'} align="right">
                {formatPercentage(data[dataIndex]?.targetWeightPercentage)}
              </Typography>
            </div>
          );
        },
        customHeadLabelRender: function customHeadLabelRender(): React.ReactNode {
          return (
            <div
              style={{
                textAlign: 'right',
                width: '90px',
              }}
            >
              <Typography
                variant="h6"
                align="right"
                style={{
                  textAlign: 'right',
                  textTransform: 'uppercase',
                  color: '#7d7d7d',
                  fontSize: '11px',
                }}
              >
                TARGET
              </Typography>
            </div>
          );
        },
      },
    },
    {
      filterDataType: FilterDataType.numeric,
      textAlign: 'left',
      name: 'differenceValue',
      label: 'DIFFERENCE',
      options: {
        ...customTableHeaderOptions,
        customBodyRenderLite: function customBodyRenderLite(dataIndex: number): React.ReactNode {
          let unsettledTooltipText = '';
          const buyOrSell = (data[dataIndex]?.unsettledUnits ?? 0) > 0 ? 'Buy' : 'Sale';
          if (data[dataIndex]?.assetClass !== 'Australian Cash') {
            unsettledTooltipText = `Unsettled ${buyOrSell} of ${formatNumberCommaSeparated(
              Math.abs(data[dataIndex]?.unsettledUnits ?? 0)
            )} x ${data[dataIndex]?.securityCode} for ${formatDollars(Math.abs(data[dataIndex]?.unsettledValue ?? 0))}`;
          } else {
            unsettledTooltipText = `Pending settlements of ${formatNumberCommaSeparated(
              Math.abs(data[dataIndex]?.unsettledUnits ?? 0)
            )} x ${data[dataIndex]?.securityCode}`;
          }

          return (
            <>
              <div
                onClick={() =>
                  data[dataIndex].securityName === selectedSecurity?.securityName
                    ? selectSecurity(data[dataIndex])
                    : selectSecurity(data[dataIndex])
                }
                style={{ width: '90px' }}
              >
                <Typography
                  variant={'h5'}
                  align="right"
                  color={
                    !data[dataIndex]?.differenceValue
                      ? 'textPrimary'
                      : data[dataIndex]?.differenceValue < 0
                      ? 'error'
                      : 'secondary'
                  }
                >
                  {formatDollars(data[dataIndex]?.differenceValue)}
                </Typography>
                <Typography color={'textSecondary'} variant={'h6'} align="right">
                  {formatPercentage(data[dataIndex]?.differenceWeightPercentage)}
                </Typography>
              </div>
              {!!data[dataIndex]?.unsettledValue && (
                <Tooltip title={unsettledTooltipText} aria-label="info">
                  <UpdateIcon color={'primary'} style={{ alignSelf: 'center', fontSize: '20px', paddingLeft: '2px' }} />
                </Tooltip>
              )}
            </>
          );
        },
        customHeadLabelRender: function customHeadLabelRender(): React.ReactNode {
          return (
            <div
              style={{
                textAlign: 'right',
                width: '90px',
              }}
            >
              <Typography
                variant="h6"
                align="right"
                style={{
                  textAlign: 'right',
                  textTransform: 'uppercase',
                  color: '#7d7d7d',
                  fontSize: '11px',
                }}
              >
                DIFFERENCE
              </Typography>
            </div>
          );
        },
      },
    },
  ];

  const options: MUIDataTableOptions = {
    download: false,
    filter: false,
    pagination: false,
    print: false,
    search: false,
    selectableRows: 'none',
    selectToolbarPlacement: 'none',
    viewColumns: false,
  };

  return (
    <ClientSideDataTable
      data={data}
      columns={columns}
      options={options}
      loadingProgress={progress}
      id="holdings-datatable"
    />
  );
}
