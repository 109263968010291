import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { progressSelectorFactory } from '../../../common';
import { RootState } from '../../../reducers';
import { IncomeComponent } from './components/IncomeComponent';
import { selectDividendDistributionsByPaymentDate, selectParameters, selectSecurityIncomeSummary } from './store/selectors';
import { securityIncomeSlice } from './store/slice';
import {
  downloadDividendDistributionsByPaymentDate,
  fetchDividendDistributionsByPaymentDate,
  fetchSecurityIncomeSummary,
  SecurityIncomeActionTypes,
} from './store/thunks';

const mapStateToProps = (state: RootState) => ({
  parameters: selectParameters(state),
  securityIncomeSummary: selectSecurityIncomeSummary(state),
  dividendDistributionsByPaymentDate: selectDividendDistributionsByPaymentDate(state),
  securityIncomeSummaryProgress: progressSelectorFactory([SecurityIncomeActionTypes.FetchSecurityIncomeSummary])(state),
  dividendDistributionsByPaymentDateProgress: progressSelectorFactory([SecurityIncomeActionTypes.FetchDividendDistributionsByPaymentDate])(state),
});

const mapDispatchToProps = {
  ...securityIncomeSlice.actions,
  fetchSecurityIncomeSummary,
  fetchDividendDistributionsByPaymentDate,
  downloadDividendDistributionsByPaymentDate,
};

interface MatchParams {
  securityId?: string;
  securityCode?: string;
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector> & RouteComponentProps<MatchParams>;
export const SecurityIncomeStateContainer = connect(mapStateToProps, mapDispatchToProps)(IncomeComponent);
