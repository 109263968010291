export class Enumeration {
  id: number;
  name: string;
  displayName: string;

  constructor(id: number, name: string, displayName: string) {
    this.id = id;
    this.name = name;
    this.displayName = displayName;
  }
}
