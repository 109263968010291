import { combineReducers } from 'redux';
import { reducer as oidcReducer } from 'redux-oidc';
import { commonReducers } from '../common';
import { bulkReducer } from '../features/bulk';
import { clientsReducer } from '../features/clients/store';
import { holdingsSlice } from '../features/holdings/store/slice';
import { modelReducer } from '../features/models/store/reducer';
import { rebalanceReducers } from '../features/orders/rebalance/store/reducers';
import { reportSlice } from '../features/report/store/slice';
import { securityReducer } from '../features/securities/store/reducer';
import { forexRateReducer } from '../features/forexrates/store/reducer';
import { strategyReducer } from '../features/strategy/store/reducer';
import { templatesReducer } from '../features/templates/store/reducer';
import { workflowsReducer } from '../features/workflows/store/reducer';
import { userSettingsSlice } from './userSettings/slice';

const rootReducer = combineReducers({
  oidc: oidcReducer,
  ...commonReducers,
  rebalance: rebalanceReducers,
  clients: clientsReducer,
  bulk: bulkReducer,
  holdings: holdingsSlice.reducer,
  model: modelReducer,
  strategy: strategyReducer,
  security: securityReducer,
  forexRate: forexRateReducer,
  report: reportSlice.reducer,
  workflows: workflowsReducer,
  templates: templatesReducer,
  userSettings: userSettingsSlice.reducer,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
