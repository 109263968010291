import { Autocomplete, Grid, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { NumericInput } from 'src/common';
import { NumericOperatorType } from '../store/types';

export interface Props {
  selectedOperator?: string;
  fromValue: number | null;
  toValue: number | null;
  setSelectedOperator: (operator: string) => void;
  setFromValue: (value: number | null) => void;
  setToValue: (value: number | null) => void;
}

const numericOperatorOptions: string[] = [
  NumericOperatorType.None.displayName,
  NumericOperatorType.Between.displayName,
  NumericOperatorType.EqualsTo.displayName,
  NumericOperatorType.GreaterThan.displayName,
  NumericOperatorType.GreaterThanEqualsTo.displayName,
  NumericOperatorType.LessThan.displayName,
  NumericOperatorType.LessThanEqualsTo.displayName,
];

export const NumericValueSelect = (props: Props): JSX.Element => {
  const { selectedOperator, fromValue, toValue, setSelectedOperator, setFromValue, setToValue } = props;
  const [showToValue, setShowToValue] = useState(false);
  const handleOperatorChange = (operatorSelected: string) => {
    setToValue(null);
    setSelectedOperator(operatorSelected);
  };
  useEffect(() => {
    setShowToValue(selectedOperator === NumericOperatorType.Between.displayName);
  }, [selectedOperator]);

  return (
    <>
      <Autocomplete
        noOptionsText="No item to select"
        id="operator-type-select"
        fullWidth
        value={selectedOperator}
        options={numericOperatorOptions ?? []}
        getOptionLabel={(option: string) => option}
        onChange={(_e, value) => handleOperatorChange(value as string)}
        renderInput={(params) => <TextField {...params} label="Operator" margin="normal" variant="outlined" fullWidth />}
      />
      <Grid container direction="row">
        <Grid item={true} xs={6} style={{ padding: '10px 0 10px 5px' }}>
          <NumericInput prefix="$" label={showToValue ? 'From' : 'Value'} value={fromValue} onChange={setFromValue} />
        </Grid>
        {showToValue && (
          <Grid item={true} xs={6} style={{ padding: '10px 0 10px 5px' }}>
            <NumericInput prefix="$" label="To" value={toValue} onChange={setToValue} />
          </Grid>
        )}
      </Grid>
    </>
  );
};
