import React, { useCallback, useEffect } from 'react';
import PulseLoader from 'react-spinners/PulseLoader';
import { TabControl } from '../../../../common';
import { theme } from '../../../../themes';
import { modelTabRouteLabels, modelTabRoutes } from '../../modelTabRoutes';
import { Props } from '../container';
import { TabRoute } from './../../../../store/types';
import { ModelVersionStatusType } from './../../store/types';
import { ModelVersion } from './../store/types';
import { TileComponent } from './TileComponent';

export const ModelTabs = ({
  dashboardDetail,
  compositionDetail,
  modelDetails,
  common,
  fetchModelVersions,
  fetchHasEditModelAccess,
  setModelVersion,
  ...props
}: Props): JSX.Element => {
  const modelName = dashboardDetail?.name
    ? dashboardDetail?.name
    : compositionDetail?.name
    ? compositionDetail?.name
    : modelDetails?.name
    ? modelDetails?.name
    : '';
  const modelId = parseInt(props.match.params.modelId, 10);
  const updateCommonModel = useCallback(() => {
    fetchModelVersions({ modelId }).then((response) => {
      const modelVersions = response.payload as ModelVersion[];

      if (!!modelVersions && !!modelVersions.length) {
        const isOnlyOneVersion = modelVersions.length === 1;
        const isMultipleVersions = modelVersions.length > 1;

        if (isOnlyOneVersion) {
          setModelVersion({
            modelId,
            modelStatus: modelVersions[0].status,
            modelVersion: modelVersions[0].version,
            modelVersionId: modelVersions[0].modelVersionId,
          });
        } else if (isMultipleVersions) {
          for (let i = modelVersions.length - 1; i !== -1; i--) {
            if (modelVersions[i].status === ModelVersionStatusType.active) {
              setModelVersion({
                modelId,
                modelStatus: modelVersions[i].status,
                modelVersion: modelVersions[i].version,
                modelVersionId: modelVersions[i].modelVersionId,
              });

              break;
            }
          }
        }
      }
    });
    fetchHasEditModelAccess({ modelId });
  }, [modelId, fetchModelVersions, fetchHasEditModelAccess, setModelVersion]);

  useEffect(() => {
    updateCommonModel();
  }, [updateCommonModel]);

  const filterModelTabsByStatus = (modelVersions: ModelVersion[], tabRoutes: TabRoute[]) => {
    if (!!modelVersions && !!modelVersions.length) {
      const isVersionOnePending = modelVersions.length === 1 && modelVersions[0].status === ModelVersionStatusType.pending;

      if (isVersionOnePending) {
        const filteredTabRoutes = tabRoutes.filter(
          (tabRoutes: TabRoute) => tabRoutes.label !== modelTabRouteLabels.DASHBOARD && tabRoutes.label !== modelTabRouteLabels.ASSOCIATED_CLIENTS
        );

        return filteredTabRoutes;
      }
    }

    return tabRoutes;
  };

  // Don't render tabs until data is returned (show loader instead)
  const filteredTabs = filterModelTabsByStatus(common.modelVersions, modelTabRoutes);

  if (!!filteredTabs && !!common.modelVersions.length) {
    return <TabControl {...props} tileText={modelName} tileComponent={TileComponent} tabRoutes={filteredTabs} />;
  }

  return (
    <div
      style={{
        width: '100%',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <PulseLoader size="12px" color={theme.palette.grey[400]} />
    </div>
  );
};
