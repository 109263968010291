import React, { useEffect, useState} from 'react';
import { DateTime } from 'luxon';
import { GreenIdStatusEnum } from '../../store/enums';
import {
    Grid,
    Paper,
  } from '@mui/material';
  import { Field, Form, Formik, FormikProps 
} from 'formik';
import * as yup from 'yup';
import {
    FormikKeyboardDatePicker,
    FormikEnumerationSelect,
    FormikTextField,
    FormikSwitch,
  } from '../../../../../../common/components/formik';
  import WO2Button from 'src/common/components/button/Button';
import { GreenidStatus, SubmitGreenIdPayload, UpdateGreenIdDetailsPayload } from '../../store/types';
import { isSubmitToDvsDisabled } from './common';

interface Props {
    greenidStatus: GreenidStatus;
    submitToGreenId?: (payload: SubmitGreenIdPayload) => void;
    updateGreenIdDetails?: (payload: UpdateGreenIdDetailsPayload) => void;
    clientId: number | null;
    contactId: number;
    onSave: (updateGreenIdStatus: GreenidStatus) => void;
    hasClientEditAdminOnlyPermission: boolean;
}

export function DvsStatusComponent({
    greenidStatus,
    submitToGreenId,
    updateGreenIdDetails,
    clientId,
    contactId,
    onSave,
    hasClientEditAdminOnlyPermission
  }: Props) {

    const initialFormValues: GreenidStatus = {
        dvsConsentReceived: greenidStatus.dvsConsentReceived,
        greenIdVerificationStatus: GreenIdStatusEnum.getByName('UNVERIFIED')?.id as number ?? 8,
        consentAcceptedDate: greenidStatus.consentAcceptedDate,
        greenIdVerificationId: greenidStatus.greenIdVerificationId
    }

    const [dvsStatusDetailsPayload, setDvsStatusDetailsPayload] = useState(initialFormValues);

    useEffect(() => {
        setDvsStatusDetailsPayload({
          ...greenidStatus,
          greenIdVerificationId: greenidStatus.greenIdVerificationId,
          greenIdVerificationStatus: greenidStatus.greenIdVerificationStatus as number ?? 8
        })
    }, [greenidStatus]);

    interface SaveButtondisabled {
        greenIdVerificationId?: string;
    }

    const isSaveButtondisabled = (errors: SaveButtondisabled) => {
        if(errors.greenIdVerificationId) {
            return true;
        }
        return false
    }

    const validationSchema = yup.object({
        greenIdVerificationId: yup.string().required('Enter a Registration Id')
    });
    
    return (
        <Paper
            elevation={3}
            style={{
            padding: '40px 40px 0 40px',
            marginTop: '20px'
            }}
        >
            <Formik<GreenidStatus>
                initialValues={dvsStatusDetailsPayload}
                onSubmit={async (details) => {
                    onSave({ ...details });
                }}
                enableReinitialize={true}
                validationSchema={validationSchema}
            >
                {(props: FormikProps<GreenidStatus>) => (
                <Form>
                    <fieldset style={{ border: 'none', padding: '0' }}>
                    <Grid container>
            <Grid item xs={6} style={{ paddingRight: '20px' }}>
                <Grid item xs={12} style={{ paddingBottom: '20px', minHeight: '90px' }}>
                    <Field
                        disabled={!hasClientEditAdminOnlyPermission}
                        component={FormikSwitch}
                        name="dvsConsentReceived"
                        label="DVS Consent"
                        fullWidth
                    />
                </Grid>
            </Grid>
            <Grid item xs={6}>
                <Grid item xs={12} style={{ paddingBottom: '20px', minHeight: '90px' }}>
                    <Field
                        disabled={!hasClientEditAdminOnlyPermission}
                        component={FormikEnumerationSelect}
                        type={GreenIdStatusEnum}
                        showNone={false}
                        name="greenIdVerificationStatus"
                        valueIsId={true}
                        label="ID VERIFICATION STATUS"
                        fullWidth
                        onChange={() => {
                            return;
                        }}
                        showRequiredAsterisk
                    />
                </Grid>
            </Grid>

            <Grid item xs={6} style={{ paddingRight: '20px' }}>
                <Grid item xs={12} style={{ paddingBottom: '20px', minHeight: '90px' }}>
                    <Field
                        disabled={!hasClientEditAdminOnlyPermission}
                        component={FormikKeyboardDatePicker}
                        name="consentAcceptedDate"
                        label="CONSENT DATE"
                        maxDate={DateTime.now()}
                        showRequiredAsterisk
                        fullWidth
                    />
                </Grid>
            </Grid>
            <Grid item xs={6}>
                <Grid item xs={12} style={{ paddingBottom: '20px', minHeight: '90px' }}>
                    <Field
                        disabled={!hasClientEditAdminOnlyPermission}
                        name="greenIdVerificationId"
                        component={FormikTextField}
                        label="REGISTRATION ID"
                        showRequiredAsterisk
                        fullWidth
                    ></Field>
                </Grid>
            </Grid>

            <Grid item xs={6} style={{ paddingRight: '20px' }}>
                <Grid item xs={12} style={{ paddingBottom: '20px', minHeight: '90px' }}></Grid>
            </Grid>
            
            <Grid item xs={6}>
                <Grid display="flex" flexDirection="row" justifyContent="flex-end" item xs={12} style={{ paddingBottom: '20px', minHeight: '90px' }}>
                <WO2Button
                    variant="contained"
                    disabled={!hasClientEditAdminOnlyPermission || isSaveButtondisabled(props.errors)}
                    color={'primary'}
                    onClick={() => {
                        if (updateGreenIdDetails) {
                            updateGreenIdDetails({
                                dvsConsentReceived: props.values.dvsConsentReceived as boolean ?? false,
                                consentAcceptedDate: props.values.consentAcceptedDate as string ?? '',
                                greenIdVerificationId: props.values.greenIdVerificationId as string ?? '',
                                greenIdStatus: props.values.greenIdVerificationStatus as number ?? 8,
                                contactId
                            })
                        }
                    }}
                    style={{ marginRight: '20px' }}
                >
                    Save
                </WO2Button>
                <WO2Button
                    variant="contained"
                    disabled={isSubmitToDvsDisabled(props.values)}
                    color={'primary'}
                    onClick={() => {
                        if (submitToGreenId && clientId) {
                            submitToGreenId({ clientId, contactId })
                        }
                    }}
                >
                    Submit to DVS
                </WO2Button>
                </Grid>
            </Grid>
            </Grid>
            </fieldset>
              </Form>
            )}
          </Formik>

          </Paper>
    )
}
