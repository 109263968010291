import { createSelector } from '@reduxjs/toolkit';
import { selectSecurity } from '../../store/selectors';

export const selectSecurityAssociatedClientsState = createSelector(selectSecurity, (associatedClientsState) => associatedClientsState.associatedClients);
export const selectParameters = createSelector(selectSecurityAssociatedClientsState, (state) => state.parameters);
export const selectConsolidatedSecurityHoldingsSummary = createSelector(
  selectSecurityAssociatedClientsState,
  (listState) => listState.consolidatedSecurityHoldingsSummary
);
export const selectSecurityExposure = createSelector(selectSecurityAssociatedClientsState, (state) => state.securityExposure);
