import ArrowDropDownSharpIcon from '@mui/icons-material/ArrowDropDownSharp';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import { Box, Container, Grid, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { DateTime } from 'luxon';
import React, { useCallback, useEffect, useState } from 'react';
import { usePDF } from 'react-to-pdf';
import PdfFooter from 'src/features/clients/client/common/components/PdfFooter';
import PdfHeader from 'src/features/clients/client/common/components/PdfHeader';
import { formatDollars, formatNumberCommaSeparated, formatPercentage, WO2Menu } from '../../../../common';
import CardComponent from '../../../../common/components/CardComponent';
import { theme } from '../../../../themes';
import { Props } from '../container';
import { AssetClassAllocations, ComponentAllocations, FetchModelDetailPayload } from '../store/types';
import { MonthlyPerformanceChartContainer } from './MonthlyPerformanceChartContainer';

const chartColors = [
  '#2c82be',
  '#429ff9',
  '#423cff',
  '#0080ff',
  '#000080',
  '#008ecc',
  '#008081',
  '#4482b4',
  '#111e6c',
  '#1d2951',
  '#dbecf8',
  '#2c82be',
  '#429ff9',
  '#423cff',
  '#0080ff',
  '#000080',
  '#008ecc',
  '#008081',
  '#4482b4',
  '#111e6c',
  '#1d2951',
  '#dbecf8',
  '#2c82be',
  '#429ff9',
  '#423cff',
  '#0080ff',
  '#000080',
  '#008ecc',
  '#008081',
  '#4482b4',
  '#111e6c',
  '#1d2951',
  '#dbecf8',
];

export const DashboardComponent = ({
  parameters,
  setSelectedGroupParameter,
  fetchModelDetail,
  fetchPerformanceDetails,
  modelDetail,
  modelGroupItems,
  match: { params },
  loadingProgress,
  modelVersionId,
  isDataFromTriumph,
}: Props): JSX.Element => {
  const callEndPoints = useCallback(() => {
    if (params.modelId && modelVersionId !== null) {
      const payload: FetchModelDetailPayload = {
        modelVersionId: modelVersionId,
        isDataFromTriumph: isDataFromTriumph,
      };
      fetchModelDetail(payload);

      fetchPerformanceDetails({
        modelVersionId: modelVersionId,
        startDate: DateTime.now().minus({ months: 12 }).startOf('month').toISODate(),
        endDate: DateTime.now().toISODate(),
      });
    }
  }, [fetchModelDetail, fetchPerformanceDetails, params.modelId, modelVersionId, isDataFromTriumph]);

  const [showPdfFooterAndHeader, setShowPdfFooterAndHeader] = useState(false);
  const { toPDF, targetRef } = usePDF({ filename: `${modelDetail?.name} - Summary.pdf` });

  useEffect(() => {
    if (showPdfFooterAndHeader) {
      toPDF();
      setShowPdfFooterAndHeader(false);
    }
  }, [showPdfFooterAndHeader]);

  const generatePdf = () => {
    setShowPdfFooterAndHeader(true);
  };

  useEffect(() => {
    callEndPoints();
  }, [callEndPoints]);

  const chartData = modelDetail?.groups
    .filter((i: AssetClassAllocations) => i.label !== 'ALL')
    .map((i: AssetClassAllocations, index: number) => ({
      y: Math.abs(i.weight / 100),
      name: i.label,
      color: chartColors[index],
      sliced: i.label === parameters.selectedGroup,
      selected: i.label === parameters.selectedGroup,
      events: {
        select: function () {
          setSelectedGroupParameter(i.label);
        },
        unselect: function () {
          setSelectedGroupParameter('ALL');
        },
      },
    }));

  const chartOptions = {
    chart: {
      type: 'pie',
    },
    allowPointSelect: true,
    title: {
      verticalAlign: 'middle',
      floating: true,
      text: '',
    },
    colors: chartColors,
    subtitle: {
      text: '',
    },
    plotOptions: {
      pie: {
        innerSize: '60%',
        depth: 45,
        tooltip: {
          pointFormatter: function (this: Highcharts.TooltipFormatterContextObject) {
            return `<span style="color:${this.color}">●</span> <b>${this.percentage?.toFixed(2)}%</b><br/>`;
          },
        },
      },
    },
    series: [
      {
        allowPointSelect: true,
        name: '',
        data: chartData,
        dataLabels: {
          enabled: false,
        },
      },
    ],
    credits: {
      enabled: false,
    },
  };

  const method: string = modelDetail?.method === 'Weighted' ? 'Weighted' : 'Fixed';

  const groupRows = modelDetail?.groups.map((row: AssetClassAllocations, index: number) => {
    return (
      <ListItem
        key={index}
        divider
        selected={row.label.toLowerCase() === parameters.selectedGroup.toLowerCase()}
        button
        onClick={() => {
          setSelectedGroupParameter(row.label);
        }}
      >
        <Grid container>
          <Grid item xs={2}>
            <Box
              style={{ backgroundColor: chartColors[index] }}
              marginTop={'5px'}
              display={'block'}
              width={'20px'}
              height={'20px'}
              borderRadius={'15px'}
            ></Box>
          </Grid>
          <Grid item xs={5}>
            <Typography variant="h5">{row.label}</Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography variant="h6" align="right">
              {formatPercentage(row.weight)}
            </Typography>
          </Grid>
        </Grid>
      </ListItem>
    );
  });

  let rightTableRows: React.ReactNode = <></>;
  let rightTableHeader: React.ReactNode = <Grid container></Grid>;

  if (method === 'Weighted') {
    rightTableHeader = (
      <Grid container>
        <Grid item xs={3}>
          <Typography variant="h6" style={{ paddingLeft: '15px' }}>
            CODE
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h6" style={{ paddingLeft: '8px' }}>
            NAME
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="h6" align="right" style={{ paddingRight: '10px' }}>
            UNITS & PRICE
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="h6" align="right" style={{ paddingRight: '10px' }}>
            VALUE
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="h6" align="right" style={{ paddingRight: '13px' }}>
            TARGET ALLOCATION
          </Typography>
        </Grid>
      </Grid>
    );

    rightTableRows = modelGroupItems.map((row: ComponentAllocations, index: number) => {
      return (
        <ListItem key={index} divider>
          <Grid container>
            <Grid item xs={3}>
              <Typography variant={'h5'} color={'primary'}>
                {row.componentCode}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant={'h5'}>{row.componentName}</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant={'h5'} align="right">
                {formatNumberCommaSeparated(row.units)}
              </Typography>
              <Typography variant={'h5'} color={'textSecondary'} align="right">
                {formatDollars(row.unitPrice)}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant={'h5'} align="right">
                {formatDollars(row.value)}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant={'h5'} align="right">
                {formatPercentage(row.weight)}
              </Typography>
            </Grid>
          </Grid>
        </ListItem>
      );
    });
  } else {
    rightTableHeader = (
      <Grid container>
        <Grid item xs={3}>
          <Typography variant="h6" style={{ paddingLeft: '15px' }}>
            CODE
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography variant="h6" style={{ paddingLeft: '8px' }}>
            NAME
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="h6" align="right" style={{ paddingRight: '10px' }}>
            LAST PRICE
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="h6" align="right" style={{ paddingRight: '13px' }}>
            TARGET ALLOCATION
          </Typography>
        </Grid>
      </Grid>
    );

    rightTableRows = modelGroupItems.map((row: ComponentAllocations, index: number) => {
      return (
        <ListItem key={index} divider>
          <Grid container>
            <Grid item xs={3}>
              <Typography variant={'h5'} color={'primary'}>
                {row.componentCode}
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography variant={'h5'}>{row.componentName}</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant={'h5'} align="right">
                {formatDollars(row.unitPrice)}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant={'h5'} align="right">
                {formatPercentage(row.weight)}
              </Typography>
            </Grid>
          </Grid>
        </ListItem>
      );
    });
  }

  const exportButtons = [
    {
      icon: <PictureAsPdfOutlinedIcon />,
      label: 'Export to PDF',
      onClick: generatePdf,
      testId: `exportSecurityPerformanceAction`,
    },
  ];

  return (
    <>
      <Box display={'flex'} justifyContent={'space-between'} style={{ padding: '10px 0' }}>
        <Box paddingRight={'10px'} />
        <Box display={'flex'} justifyContent={'space-between'}>
          <WO2Menu
            testId="export_button_mod_dashboard"
            buttonTitle="Export"
            buttonIcon={
              <div
                style={{
                  paddingLeft: '10px',
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  backgroundColor: 'blue',
                  borderRadius: '25px',
                  padding: '10px 10px 10px 20px',
                  marginTop: '-7px',
                }}
              >
                <span style={{ color: '#ffffff', fontSize: '0.875rem' }}>EXPORT</span>
                <ArrowDropDownSharpIcon sx={{ color: '#ffffff' }} />
              </div>
            }
            items={exportButtons}
          />
        </Box>
      </Box>
      <div ref={targetRef} style={{ padding: '0 20px' }}>
        {showPdfFooterAndHeader && <PdfHeader clientName={`${modelDetail?.name}`} />}
        <MonthlyPerformanceChartContainer />
        <Container style={{ paddingTop: '20px', paddingBottom: '20px' }}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <CardComponent
                progress={loadingProgress}
                background={theme.palette.gradient1?.main}
                value={formatDollars(modelDetail?.fua)}
                subText={'FUA'}
              />
            </Grid>
            <Grid item xs={3}>
              <CardComponent
                progress={loadingProgress}
                background={theme.palette.gradient2?.main}
                value={modelDetail?.portfolioCount}
                subText={'Portfolios'}
              />
            </Grid>
            <Grid item xs={3}>
              <CardComponent
                progress={loadingProgress}
                background={theme.palette.gradient1?.main}
                value={formatPercentage(modelDetail?.oneMonthReturn)}
                subText={'1 MONTH RETURN'}
              />
            </Grid>
            <Grid item xs={3}>
              <CardComponent
                progress={loadingProgress}
                background={theme.palette.gradient2?.main}
                value={formatPercentage(modelDetail?.inceptionReturn)}
                subText={'INCEPTION RETURN'}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Card elevation={0}>
                <Grid container>
                  <Grid item xs={6}>
                    <Typography variant="h3" align="left" style={{ padding: '10px' }}>
                      {parameters.selectedGroup === 'ALL' ? 'All Asset Classes' : parameters.selectedGroup}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="h3" color="primary" align="right" style={{ padding: '10px' }}>
                      {method === 'Fixed' ? 'Fixed' : 'Weighted'}
                    </Typography>
                  </Grid>
                </Grid>
                <CardContent>
                  <Grid item>
                    <HighchartsReact highcharts={Highcharts} options={chartOptions} />
                  </Grid>
                  <Grid container>
                    <Grid item xs={2}>
                      {' '}
                    </Grid>
                    <Grid item xs={5}>
                      <Typography variant="h6">ASSET CLASS</Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <Typography variant={'h6'} style={{ textAlign: 'right', paddingRight: '15px' }}>
                        VALUE & WEIGHT
                      </Typography>
                    </Grid>
                  </Grid>
                  <List>{groupRows}</List>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={8}>
              <Card elevation={0}>
                <CardContent>
                  {rightTableHeader}
                  <List>{rightTableRows}</List>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
        {showPdfFooterAndHeader && <PdfFooter />}
      </div>
    </>
  );
};
