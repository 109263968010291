import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  body: {
    margin: 'auto',
    width: '100%',
    height: '100%',
    position: 'absolute',
    display: 'flex',
  },
  error: {
    height: '25px',
    color: theme.palette.text.primary,
    fontSize: '14px',
    fontWeight: 900,
    lineHeight: '19px',
    margin: 'auto',
    paddingTop: '100px',
  },
}));
