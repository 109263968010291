import { TextField } from '@mui/material';
import React from 'react';

interface NumberFormatProps {
  format: string;
  mask: string;
  placeholder: string;
  isNumericString: boolean;
  name: string;
  label: string;
  fullWidth?: boolean;
}

export const WO2NumberFormatInput = ({ fullWidth, ...props }: NumberFormatProps): JSX.Element => (
  <TextField
    InputLabelProps={{
      shrink: true,
    }}
    variant="outlined"
    {...props}
    inputProps={{
      'data-testid': props.name,
    }}
    fullWidth={fullWidth}
  />
);
