import { Card } from '@mui/material';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { DateTime } from 'luxon';
import React from 'react';
import { LoadingIndicator } from 'src/common/components/LoadingIndicator';
import { DateTimeFormat, formatDollars, getLocalDateTime } from '../../../../common';
import { LoadingProgress } from '../../../../common/store/types';
import { theme } from '../../../../themes';
import {
  DailySecurityPricesChartItem,
  DailySecurityPricesChartThunkParameters,
  DashboardChartStartDateParameter,
  SecurityDashboardParameters,
} from '../store/types';
import MonthlyPerformanceChartButtons, { IIntervalButton } from './MonthlyPerformanceChartButtons';

interface Props {
  parameters: SecurityDashboardParameters;
  setChartDateParameter: (arg: DashboardChartStartDateParameter) => void;
  fetchDailySecurityPricesChart: (arg: DailySecurityPricesChartThunkParameters) => void;
  dailySecurityPricesChart: DailySecurityPricesChartItem[];
  dashoardChartLoading: LoadingProgress;
}

export const MonthlyPerformanceChartComponent = (props: Props): JSX.Element => {
  const { fetchDailySecurityPricesChart, dailySecurityPricesChart, dashoardChartLoading } = props;
  const categories = dailySecurityPricesChart?.map((i) => getLocalDateTime(i.date, DateTimeFormat.ChartMonthDate)) ?? [0];
  const cumulativeValues = dailySecurityPricesChart?.map((i) => i.price) ?? [0];

  const latestPrice = formatDollars(cumulativeValues[cumulativeValues.length - 1]);
  const latestPriceDate = getLocalDateTime(dailySecurityPricesChart[dailySecurityPricesChart.length - 1]?.date, DateTimeFormat.LetterDate);

  const chartOptions = {
    chart: {
      renderTo: 'container',
      backgroundColor: 'transparent',
      plotBackgroundColor: 'transparent',
      height: '200px',
    },
    plotOptions: {
      series: {
        allowPointSelect: false,
        groupPadding: 0,
        pointPadding: 0.1,
        borderWidth: 0,
        color: theme.palette.primary.main,
      },
      column: {
        color: '#FF0000',
        negativeColor: '#0088FF',
      },
    },
    title: {
      text: null,
    },
    subtitle: {
      text: null,
    },
    xAxis: [
      {
        gridLineColor: 'transparent',
        lineColor: 'transparent',
        categories: categories,
        labels: {
          enabled: false,
        },
      },
    ],
    yAxis: [
      {
        title: { text: undefined },
        gridLineColor: 'transparent',
        lineColor: 'transparent',
        labels: {
          enabled: false,
        },
        plotLines: [
          {
            color: theme.palette.graphBackground?.main,
            width: 0.5,
            value: 0,
          },
        ],
      },
    ],
    tooltip: {
      shared: true,
      formatter: function (this: Highcharts.TooltipFormatterContextObject) {
        return this.points?.reduce(function (s: string, point: Highcharts.TooltipFormatterContextObject) {
          return s + '<br/><span style="color:' + point.color + '">\u25CF</span>  ' + point.series.name + ': ' + formatDollars(point.y);
        }, '<b>' + this.x + '</b>');
      },
    },
    series: [
      {
        color: theme.palette.primary.main,
        name: 'Price',
        type: 'line',
        data: cumulativeValues,
        zIndex: 100,
        states: {
          inactive: {
            opacity: 1,
          },
        },
        marker: {
          enabled: false,
        },
      },
    ],
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
  };

  const securityId = window.location.pathname.split('/')[2];

  const intervalButtonClicked = (button: IIntervalButton) => {
    props.setChartDateParameter({ interval: button.name, startDate: button.startDate });

    fetchDailySecurityPricesChart({
      securityId: parseInt(securityId),
      fromDate: button.startDate,
      toDate: DateTime.now().toISODate(),
    });
  };

  return (
    <LoadingIndicator progress={dashoardChartLoading}>
      <Card className={'Card'} square elevation={0} style={{ background: theme.palette.gradientHero?.main, padding: '30px 0' }}>
        <Container fixed>
          <Typography variant={'h2'} align={'center'}>
            {latestPrice}
          </Typography>
          <Typography variant={'h4'} align={'center'}>
            Price at {latestPriceDate}
          </Typography>

          <HighchartsReact highcharts={Highcharts} options={chartOptions} style={{ border: 'medium dotted hotpink' }} />
          <MonthlyPerformanceChartButtons {...props} intervalButtonClicked={intervalButtonClicked} />
        </Container>
      </Card>
    </LoadingIndicator>
  );
};
