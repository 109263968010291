import {
  FiberManualRecord as DotIcon,
  FileDownloadRounded,
  MoreVertOutlined,
  PersonRounded,
} from '@mui/icons-material';
import { Avatar, Paper, Typography } from '@mui/material';
import React from 'react';
import { DateTimeFormat, formatDollars, getAvatarText, getLocalDateTime, WO2Menu } from '../../../../common';
import { ServerSideDataTable } from '../../../../common/components/dataTable/serverSide';
import { DatatableColumn, FilterDataType } from '../../../../common/components/dataTable/types';
import { LoadingProgress } from '../../../../common/store/types';
import { PagedRequest, PagedResult } from '../../../../store';
import { theme } from '../../../../themes';
import { ClientCsvRow, ClientItem } from '../store/types';

export interface Props {
  pagedResults?: PagedResult<ClientItem>;
  pagedRequest: PagedRequest;
  hasClientEditAdminOnlyPermission: boolean;
  handlePagedRequest: (pagedRequest: PagedRequest) => void;
  handleRowClick: (clientId: number, status: string) => void;
  handleDownload: (pagedRequest: PagedRequest, mapper: (clients: ClientItem[]) => ClientCsvRow[]) => void;
  downloadProposedOrders: (clientId: number) => void;
  progress: LoadingProgress;
  isDataFromTriumph: boolean;
}

export function ClientListTable(props: Props): JSX.Element {
  const {
    pagedResults,
    pagedRequest,
    handlePagedRequest,
    handleRowClick,
    handleDownload,
    downloadProposedOrders,
    progress,
    hasClientEditAdminOnlyPermission,
    isDataFromTriumph,
  } = props;

  function idicatorColor(dataIndex: number): string {
    if ((pagedResults?.results || [])[dataIndex] == null) return '';
    switch (pagedResults?.results[dataIndex].bandName) {
      case 'S0':
        return theme.palette.common.white;
      case 'S1':
        return theme.palette.green?.main;
      case 'S2':
        return theme.palette.secondary.main;
      case 'S3':
        return theme.palette.primary.main;
      case 'S4':
        return theme.palette.orange?.main;
      case 'S5':
        return theme.palette.error.main;
      case 'P1':
        return theme.palette.green?.main;
      case 'P2':
        return theme.palette.orange?.main;
      case 'P3':
        return theme.palette.error.main;
      default:
        return theme.palette.common.white;
    }
  }

  const outOfBalanceIndicatorColumn = (dataIndex: number): React.ReactNode => (
    <DotIcon htmlColor={idicatorColor(dataIndex)} />
  );

  const avatarColumn = (dataIndex: number): React.ReactNode => (
    <Avatar style={{ backgroundColor: theme.palette.primary.main }}>
      <Typography variant="h5">
        {(pagedResults?.results || [])[dataIndex] == null
          ? ''
          : getAvatarText(pagedResults?.results[dataIndex].accountName)}
      </Typography>
    </Avatar>
  );

  const accountNameColumn = (dataIndex: number): React.ReactNode => (
    <Typography variant="h5" data-testid={`accountName_${dataIndex}`}>
      {(pagedResults?.results || [])[dataIndex] == null ? '' : pagedResults?.results[dataIndex].accountName}
    </Typography>
  );

  const typeColumn = (dataIndex: number): React.ReactNode => {
    if ((pagedResults?.results || [])[dataIndex] == null) return '';

    const accountType = pagedResults?.results[dataIndex].accountType;
    const accountSubType = pagedResults?.results[dataIndex].accountSubType;

    let dspType: string | undefined = '';

    switch (accountType) {
      case 'Individual - Super': {
        dspType = 'Super';
        break;
      }
      case 'Individual - Pension': {
        dspType = 'Pension';
        break;
      }
      case 'Individual - Non Super': {
        dspType = 'Individual';
        break;
      }
      default: {
        dspType = accountType;
        break;
      }
    }

    if (accountSubType) {
      dspType = `${dspType} - ${accountSubType}`;
    }
    return (
      <Typography variant="h5" data-testid={`type_${dataIndex}`}>
        {!!dspType ? dspType : ''}
      </Typography>
    );
  };

  const executionDateColumn = (dataIndex: number): React.ReactNode => {
    if ((pagedResults?.results || [])[dataIndex] == null) return '';
    return (
      <Typography variant="h5" data-testid={`executionDate_${dataIndex}`}>
        {getLocalDateTime(pagedResults?.results[dataIndex].executionDate, DateTimeFormat.Short)}
      </Typography>
    );
  };

  const feeAniversaryDateColumn = (dataIndex: number): React.ReactNode => {
    if ((pagedResults?.results || [])[dataIndex] == null) return '';
    return (
      <Typography variant="h5" data-testid={`feeAniversaryDate_${dataIndex}`}>
        {getLocalDateTime(pagedResults?.results[dataIndex].feeAnniversaryDate, DateTimeFormat.Short)}
      </Typography>
    );
  };

  const adviserNameColumn = (dataIndex: number): React.ReactNode => (
    <Typography variant="h5" data-testid={`adviserName_${dataIndex}`}>
      {(pagedResults?.results || [])[dataIndex] == null ? '' : pagedResults?.results[dataIndex].adviserName}
    </Typography>
  );

  const superMemberNumberColumn = (dataIndex: number): React.ReactNode => (
    <>
      {isDataFromTriumph && (
        <Typography variant="h5" data-testid={`status_${dataIndex}`}>
          {(pagedResults?.results || [])[dataIndex] == null ? '' : pagedResults?.results[dataIndex].superMemberNumber}
        </Typography>
      )}
    </>
  );

  const statusColumn = (dataIndex: number): React.ReactNode => (
    <Typography variant="h5" data-testid={`status_${dataIndex}`}>
      {(pagedResults?.results || [])[dataIndex] == null ? '' : pagedResults?.results[dataIndex].status}
    </Typography>
  );

  const cashBalanceColumn = (dataIndex: number): React.ReactNode => {
    if ((pagedResults?.results || [])[dataIndex] == null) return '';

    const cashBalance = pagedResults?.results[dataIndex].cashBalance;

    return (
      <Typography variant="h5" data-testid={`cashBalance_${dataIndex}`}>
        {!!cashBalance ? formatDollars(cashBalance) : ''}
      </Typography>
    );
  };

  const marketValueColumn = (dataIndex: number): React.ReactNode => {
    if ((pagedResults?.results || [])[dataIndex] == null) return '';

    const marketValue = pagedResults?.results[dataIndex].marketValueWithPendingIncome;

    return (
      <Typography variant="h5" data-testid={`marketValue_${dataIndex}`}>
        {!!marketValue ? formatDollars(marketValue) : ''}
      </Typography>
    );
  };

  const actionsColumn = (dataIndex: number): React.ReactNode => {
    if ((pagedResults?.results || [])[dataIndex] == null) return <></>;

    const actionsList = [
      {
        icon: <PersonRounded color="primary" />,
        label: 'View Client',
        onClick: () => {
          const clientId = pagedResults?.results[dataIndex].clientId;
          const status = pagedResults?.results[dataIndex].status;
          if (!!clientId && !!status) {
            handleRowClick(clientId, status);
          }
        },
        testId: `view_client_${dataIndex}`,
      },
    ];

    if (pagedResults?.results[dataIndex].hasOrders) {
      actionsList.push({
        icon: <FileDownloadRounded color="primary" />,
        label: 'Download Proposed Orders',
        onClick: () => {
          downloadProposedOrders(pagedResults?.results[dataIndex].clientId);
        },
        testId: `download_proposed_orders_${dataIndex}`,
      });
    }

    return (
      <WO2Menu
        testId={`clientlistActionsButton_${dataIndex}`}
        buttonTitle="Client List Actions"
        buttonIcon={<MoreVertOutlined color="primary" />}
        items={actionsList}
      />
    );
  };

  let columns: DatatableColumn[] = [
    {
      name: 'outOfBalanceIndicator',
      label: ' ',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex: number) => outOfBalanceIndicatorColumn(dataIndex),
      },
    },
    {
      filterDataType: FilterDataType.string,
      name: 'avatar',
      label: ' ',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex) => avatarColumn(dataIndex),
      },
    },
    {
      filterDataType: FilterDataType.string,
      name: 'accountName',
      label: 'CLIENT NAME',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex: number) => accountNameColumn(dataIndex),
      },
    },
    {
      filterDataType: FilterDataType.string,
      name: 'superMemberNumber',
      label: 'SUPER MEMBER NO.',
      options: {
        filter: true,
        sort: true,
        display: isDataFromTriumph,
        customBodyRenderLite: (dataIndex: number) => superMemberNumberColumn(dataIndex),
      },
    },
    {
      filterDataType: FilterDataType.string,
      name: 'accountType',
      label: 'TYPE',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex: number) => typeColumn(dataIndex),
      },
    },
    {
      filterDataType: FilterDataType.date,
      textAlign: 'right',
      name: 'executionDate',
      label: 'SERVICES AGREEMENT DATE',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex: number) => executionDateColumn(dataIndex),
      },
    },
    {
      filterDataType: FilterDataType.date,
      textAlign: 'right',
      name: 'feeAnniversaryDate',
      label: 'REFERENCE DATE',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex: number) => feeAniversaryDateColumn(dataIndex),
      },
    },
    {
      filterDataType: FilterDataType.string,
      name: 'adviserName',
      label: 'ADVISER',
      options: { filter: true, sort: true, customBodyRenderLite: (dataIndex: number) => adviserNameColumn(dataIndex) },
    },
    {
      filterDataType: FilterDataType.string,
      name: 'status',
      label: 'STATUS',
      options: { filter: true, sort: true, customBodyRenderLite: (dataIndex: number) => statusColumn(dataIndex) },
    },
    {
      filterDataType: FilterDataType.numeric,
      textAlign: 'right',
      name: 'cashBalance',
      label: 'CASH BALANCE',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex: number) => cashBalanceColumn(dataIndex),
        // customBodyRenderLite: (dataIndex) => {
        //   if ((pagedResults?.results || [])[dataIndex] == null) return '';

        //   const cashBalance = pagedResults?.results[dataIndex].cashBalance;
        //   return !!cashBalance ? formatDollars(cashBalance) : '';
        // },
      },
    },
    {
      filterDataType: FilterDataType.numeric,
      textAlign: 'right',
      name: 'marketValue',
      label: 'MARKET VALUE',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex: number) => marketValueColumn(dataIndex),
      },
    },
    {
      name: 'actions',
      label: ' ',
      textAlign: 'right',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex: number): React.ReactNode => actionsColumn(dataIndex),
      },
    },
  ];

  // remove columns needs to be hidden (exclude actions)
  if (!hasClientEditAdminOnlyPermission) {
    columns = columns.filter((column: DatatableColumn) => column.name.toLowerCase() !== 'actions');
  }

  return (
    <Paper elevation={0}>
      <ServerSideDataTable
        loadingProgress={progress}
        columns={columns}
        pagedRequest={pagedRequest}
        pagedResult={pagedResults}
        csvDownload={() =>
          handleDownload(pagedRequest, (clients) =>
            clients.map((c) => {
              return {
                band: c.bandName,
                client: c.accountName,
                type: !!c.accountSubType ? `${c.accountType} - ${c.accountSubType}` : c.accountType,
                inceptionDate: getLocalDateTime(c.inceptionDate, DateTimeFormat.Short),
                advisor: c.adviserName,
                status: c.status,
                cashBalance: c.cashBalance,
                marketValue: c.marketValue,
                superMemberNumber: c.superMemberNumber,
              };
            })
          )
        }
        options={{
          filter: true,
          viewColumns: true,
        }}
        handlePagedRequest={handlePagedRequest}
        handleRowClick={(rowData: string[], rowMeta: { dataIndex: number; rowIndex: number }) => {
          const clientId = pagedResults?.results[rowMeta.rowIndex].clientId;
          const status = pagedResults?.results[rowMeta.rowIndex].status;
          if (!!clientId && !!status && !hasClientEditAdminOnlyPermission) {
            handleRowClick(clientId, status);
          }
        }}
      ></ServerSideDataTable>
    </Paper>
  );
}
