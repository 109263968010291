import { connect, ConnectedProps } from 'react-redux';
import { progressSelectorFactory } from '../../../../../../common';
import { RootState } from '../../../../../../reducers';
import { selectClientId } from '../../../common/store';
import { selectSuperMemberNumber } from '../store/selectors';
import { Beneficiaries } from './components/newBeneficiaries';
import { selectAccountClients, selectBeneficiariesRows } from './store/selectors';
import { beneficiariesSlice } from './store/slice';
import { BeneficiariesActionTypes, fetchAccountClients, fetchBeneficiaries, createOrUpdateBeneficiaries } from './store/thunks';
import { selectHasClientEditAdminOnly } from 'src/store/selectors';

const mapStateToProps = (state: RootState) => ({
  superMemberNumber: selectSuperMemberNumber(state),
  beneficiariesDetails: selectBeneficiariesRows(state),
  accountClients: selectAccountClients(state),
  clientId: selectClientId(state),
  loadingProgress: progressSelectorFactory([
    BeneficiariesActionTypes.FetchBeneficiaries,
    BeneficiariesActionTypes.FetchAccountClients,
  ])(state),
  savingPendingBeneficiariesProgress: progressSelectorFactory([BeneficiariesActionTypes.CreateOrUpdateBeneficiaries])(state),
  hasClientEditAdminOnly: selectHasClientEditAdminOnly(state)
});

const mapDispatchToProps = {
  ...beneficiariesSlice.actions,
  fetchBeneficiaries,
  fetchAccountClients,
  createOrUpdateBeneficiaries
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector>;
export const BeneficiariesContainer = connector(Beneficiaries);
