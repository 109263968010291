import { FieldProps, useField } from 'formik';
import React from 'react';
import { FormikErrorMessage } from '.';
import { Enumeration } from '../../types';
import { EnumerationSelect } from '../EnumerationSelect';

interface Props extends FieldProps {
  onChange?: (event: string | number | null) => void;
  label?: string;
  type: typeof Enumeration;
  showNone?: boolean;
  showRequiredAsterisk?: boolean;
  valueIsId?: boolean;
  disabled?: boolean;
}

export function FormikEnumerationSelect(props: Props): JSX.Element {
  const { label, disabled, showNone, showRequiredAsterisk, valueIsId } = props;

  const [field, meta, helpers] = useField(props.field.name);

  return (
    <>
      <EnumerationSelect
        disabled={disabled || false}
        type={props.type}
        datatestid={field.name}
        {...field}
        onChange={(e) => {
          helpers.setValue(e);
          if (props.onChange) {
            props.onChange(e);
          }
        }}
        onBlur={() => {
          helpers.setTouched(true, false);
        }}
        label={!!showRequiredAsterisk && !!label ? `* ${label}` : label}
        hasValidationError={!!meta.error && meta.touched}
        none={showNone !== null ? showNone : true}
        valueIsId={valueIsId}
      />
      <FormikErrorMessage name={field.name}></FormikErrorMessage>
    </>
  );
}
