import { Autocomplete, Chip, Grid, Paper, TextField, Typography } from '@mui/material';
import { Field, Form, Formik, FormikProps } from 'formik';
import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { ProgressButton, ToggleButtonItem } from '../../../../common/components';
import { FormikEnumerationSelect, FormikSelect, FormikSwitch, FormikTextField, FormikToggleButton } from '../../../../common/components/formik';
import { LoadingProgress } from '../../../../common/store/types';
import { ServiceTypesEnum } from '../../../../common/types';
import { RebalanceTypeEnum } from '../../store/enums';
import { BenchmarkItem, TemplateDetails } from '../store/types';

export interface Props {
  templateDetails: TemplateDetails | null;
  benchmarks: BenchmarkItem[];
  loadingProgress: LoadingProgress;
  onSave: (details: TemplateDetails) => Promise<void>;
  savingProgress: LoadingProgress;
}

interface TemplateDetailsFormValues {
  name: string;
  serviceTypeId: number | null;
  primaryBenchmarkId: number | null;
  secondaryBenchmarkId: number | null;
  portalBenchmarkIds: number[];
  minTradeValue: number;
  fortyFiveDayRule: boolean;
  oneYearRule: boolean;
  rebalanceTypeId: number;

  serviceTypeEnum: ServiceTypesEnum;
}

export const DetailsForm = ({ benchmarks, templateDetails, onSave, savingProgress }: Props): JSX.Element => {
  const [editMode, setEditMode] = useState<boolean>(true);
  const isCollapsible = false;

  const getRebalanceTypeToggleButtons = (rebalanceTypeId: number): ToggleButtonItem<number>[] => {
    return [
      {
        name: 'Monthly',
        value: RebalanceTypeEnum.Monthly.id,
        disabled: !editMode,
        selected: rebalanceTypeId === RebalanceTypeEnum.Monthly.id,
      },
      {
        name: 'Quarterly',
        value: RebalanceTypeEnum.Quarterly.id,
        disabled: !editMode,
        selected: rebalanceTypeId === RebalanceTypeEnum.Quarterly.id,
      },
      {
        name: 'Bi Yearly',
        value: RebalanceTypeEnum.BiYearly.id,
        disabled: !editMode,
        selected: rebalanceTypeId === RebalanceTypeEnum.BiYearly.id,
      },
      {
        name: 'Annual',
        value: RebalanceTypeEnum.Annual.id,
        disabled: !editMode,
        selected: rebalanceTypeId === RebalanceTypeEnum.Annual.id,
      },
    ];
  };

  const initialValues: TemplateDetailsFormValues = {
    name: '',
    serviceTypeId: null,
    primaryBenchmarkId: null,
    secondaryBenchmarkId: null,
    portalBenchmarkIds: [],
    minTradeValue: 0,
    fortyFiveDayRule: false,
    oneYearRule: false,
    rebalanceTypeId: RebalanceTypeEnum.Monthly.id,

    serviceTypeEnum: ServiceTypesEnum.IMA,
  };

  const [formValues, setFormValues] = useState<TemplateDetailsFormValues>(initialValues);

  const updateFormValues = (details: TemplateDetails) => {
    setFormValues({
      name: details.name || initialValues.name,
      minTradeValue: details.minTradeValue || initialValues.minTradeValue,
      serviceTypeId: details.serviceTypeId || initialValues.serviceTypeId,
      serviceTypeEnum: ServiceTypesEnum.getById(details.serviceTypeId) || initialValues.serviceTypeEnum,
      primaryBenchmarkId: details.primaryBenchmarkId || initialValues.primaryBenchmarkId,
      secondaryBenchmarkId: details.secondaryBenchmarkId || initialValues.secondaryBenchmarkId,
      portalBenchmarkIds: details.portalBenchmarkIds || initialValues.portalBenchmarkIds,
      fortyFiveDayRule: details.fortyFiveDayRule || initialValues.fortyFiveDayRule,
      oneYearRule: details.oneYearRule || initialValues.oneYearRule,
      rebalanceTypeId: details.rebalanceTypeId || initialValues.rebalanceTypeId,
    });
  };

  useEffect(() => {
    !!templateDetails && updateFormValues(templateDetails);
  }, [setFormValues, templateDetails]);

  return (
    <>
      <Typography variant="h4" style={{ paddingBottom: '10px' }}>
        Overview
      </Typography>
      <Formik<TemplateDetailsFormValues>
        enableReinitialize={true}
        initialValues={formValues}
        onSubmit={(details) => {
          setEditMode(false);
          onSave({
            ...templateDetails,
            ...details,
          }).then(() => {
            setEditMode(true);
          });
        }}
        validationSchema={yup.object({
          name: yup.string().required('Portfolio name is required'),
          investmentTemplate: yup.string(),
          serviceTypeId: yup.string().required('Service type is required'),
          primaryBenchmarkId: yup.number().nullable(),
          secondaryBenchmarkId: yup.number().nullable(),
          portalBenchmarkIds: yup.array().of(yup.number()),
          minTradeValue: yup
            .number()
            .nullable()
            .typeError('Must be a valid amount')
            .required('Minimum Trade Value is required')
            .min(500, 'Minimum trade value must be greater than ASX minimum ($500)'),
          rebalanceTypeId: yup.number().min(1, 'Rebalance type is required'),
          fortyFiveDayRule: yup.string().nullable(),
          oneYearRule: yup.string().nullable(),
        })}
      >
        {(formikProps: FormikProps<TemplateDetailsFormValues>) => (
          <Form>
            <fieldset style={{ border: 'none', padding: '0' }}>
              <Paper elevation={2} style={{ padding: '20px', marginBottom: '30px' }}>
                <Grid container spacing={isCollapsible ? 1 : 2} justifyContent="flex-start">
                  <Grid item xs={isCollapsible ? 12 : 6}>
                    <Field component={FormikTextField} disabled={!editMode} label="NAME *" fullWidth name="name" />
                  </Grid>
                  <Grid item xs={isCollapsible ? 12 : 6}>
                    <Field
                      component={FormikEnumerationSelect}
                      type={ServiceTypesEnum}
                      name="serviceTypeId"
                      showNone={false}
                      valueIsId={true}
                      disabled={!editMode}
                      label="SERVICE TYPE *"
                    />
                  </Grid>
                  <Grid item xs={isCollapsible ? 12 : 6} style={{ paddingTop: '32px' }}>
                    <Field
                      as={FormikSelect}
                      itemDisplayNameField={'name'}
                      fieldName="primaryBenchmarkId"
                      disabled={!editMode}
                      label="PRIMARY BENCHMARK"
                      data={benchmarks}
                    />
                  </Grid>
                  <Grid item xs={isCollapsible ? 12 : 6} style={{ paddingTop: '32px' }}>
                    <Field
                      as={FormikSelect}
                      itemDisplayNameField={'name'}
                      fieldName="secondaryBenchmarkId"
                      disabled={!editMode}
                      data={benchmarks}
                      label="SECONDARY BENCHMARK"
                    />
                  </Grid>
                  <Grid item xs={isCollapsible ? 12 : 6}>
                    <Autocomplete<BenchmarkItem, true>
                      multiple
                      data-testid="autocomplete_clientPortalBenchmarks"
                      value={benchmarks.filter((benchmark) => {
                        return !!formikProps.values.portalBenchmarkIds.find((b) => b === benchmark.id);
                      })}
                      noOptionsText="No benchmarks to select"
                      options={benchmarks}
                      getOptionLabel={(benchmark: BenchmarkItem) => benchmark.name}
                      renderInput={(renderParams) => (
                        <TextField
                          {...renderParams}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          label="CLIENT PORTAL BENCHMARKS"
                          margin="normal"
                          variant="outlined"
                          fullWidth
                        />
                      )}
                      renderTags={(benchmarks, getTagProps) => {
                        return benchmarks.map((benchmark, index) => <Chip label={benchmark.name} {...getTagProps({ index })} key={index} />);
                      }}
                      onChange={(e: React.SyntheticEvent<Element, Event>, benchmarks: BenchmarkItem[]) => {
                        formikProps.setFieldValue('clientPortalBenchmarks', benchmarks);
                      }}
                      disabled={!editMode}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={isCollapsible ? 12 : 6} style={{ paddingTop: '32px' }}>
                    <Field component={FormikTextField} disabled={!editMode} label="MINIMUM TRADE VALUE ($) *" fullWidth name="minTradeValue" />
                  </Grid>
                  <Grid item xs={isCollapsible ? 12 : 6}>
                    <Field name="fortyFiveDayRule" component={FormikSwitch} label="APPLY 45 DAY RULE?" disabled={!editMode}></Field>
                    <Field name="oneYearRule" component={FormikSwitch} label="APPLY 1 YEAR RULE?" disabled={!editMode}></Field>
                  </Grid>
                  <Grid item xs={isCollapsible ? 12 : 6}>
                    <Field
                      component={FormikToggleButton}
                      fullWidth="true"
                      disabled={!editMode}
                      exclusive={true}
                      buttons={getRebalanceTypeToggleButtons(formikProps.values.rebalanceTypeId)}
                      name="rebalanceTypeId"
                      label="Rebalance type"
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} container justifyContent="flex-end">
                  <ProgressButton variant="contained" progress={savingProgress} onClick={() => formikProps.submitForm()} style={{ marginLeft: '10px' }}>
                    Save
                  </ProgressButton>
                </Grid>
              </Paper>
            </fieldset>
          </Form>
        )}
      </Formik>
    </>
  );
};
