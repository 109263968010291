import { createSelector } from '@reduxjs/toolkit';
import { selectDetails } from '../../store/selectors';

export const selectAccounts = createSelector(selectDetails, (details) => details.accounts);
export const selectContacts = createSelector(selectDetails, (details) => details.contacts);
export const selectTradingAccounts = createSelector(selectAccounts, (accounts) => accounts.tradingAccounts);
export const selectTradingAccountItems = createSelector(
  selectTradingAccounts,
  (tradingAccounts) => tradingAccounts.items
);
export const selectedTradingAccount = createSelector(selectTradingAccounts, (tradingAccounts) => tradingAccounts.edit);

export const selectPendingAccountItems = createSelector(selectAccounts, (state) => state.pendingAccounts);
export const selectPendingSuperAccount = createSelector(selectAccounts, (state) => state.pendingSuperAccount);
export const selectContactRoleItems = createSelector(selectAccounts, (state) => state.contactRoles);
export const selectExternalAccounts = createSelector(selectAccounts, (accounts) => accounts.externalAccounts);
export const selectExternalAccountItems = createSelector(
  selectExternalAccounts,
  (externalAccounts) => externalAccounts.items
);
export const selectedExternalAccount = createSelector(
  selectExternalAccounts,
  (externalAccounts) => externalAccounts.edit
);
export const selectExternalInstitutions = createSelector(selectAccounts, (accounts) => accounts.externalInstitutions);
export const selectExistingAccounts = createSelector(selectAccounts, (accounts) => accounts.existingAccounts);
export const selectBpayAccountDetails = createSelector(selectAccounts, (accounts) => accounts.bPayAccountDetails);
export const selectNewAccounts = createSelector(selectAccounts, (accounts) => accounts.newAccounts);
export const selectAttachableCmaAccounts = createSelector(selectAccounts, (accounts) => accounts.attachableCmaAccounts);
export const selectAccountTypes = createSelector(selectAccounts, (Accounts) => {
  return Accounts.accountTypes;
});

export const selectMacquarieCashOnlinePortal = createSelector(selectAccounts, (Accounts) => {
  return Accounts.macquarieCashOnlinePortal;
});
