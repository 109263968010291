import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../../../../app/api';
import { buildEncodedQueryString } from '../../../../../../common';
import { InvestmentService } from './types';

export enum InvestmentServicesActionTypes {
  FetchInvestmentServices = '@@client/details/investmentServices/FetchInvestmentServices',
}

export enum InvestmentServicesApiEndpoints {
  FetchInvestmentServices = '/customers/GetClientInvestmentServices',
}

export const fetchInvestmentServices = createAsyncThunk(InvestmentServicesActionTypes.FetchInvestmentServices, async (clientId: number) => {
  const queryString = buildEncodedQueryString({
    clientId,
  });
  const response = await api.get<InvestmentService[]>(`${InvestmentServicesApiEndpoints.FetchInvestmentServices}${queryString}`);
  return response.data;
});
