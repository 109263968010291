import { Avatar, Grid, Paper, Typography } from '@mui/material';
import React, { ReactElement } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { ClientAccountSubType } from 'src/common/types';
import { getAvatarText } from '../../../../../common';
import { theme } from '../../../../../themes';
import { ClientMainDetails, EntityDetails } from '../store/types';

interface Props extends RouteComponentProps {
  entityDetails?: EntityDetails;
  clientMainDetails?: ClientMainDetails;
}

export const EntityDetailsCard = (props: Props): ReactElement => {
  const entityDetails = props.entityDetails;
  const clientMainDetails = props.clientMainDetails;
  const contactDetails = entityDetails?.contactDetails;
  const superMemberNumber = entityDetails?.superMemberNumber;

  return (
    <Paper style={{ display: 'inline-flex', paddingBottom: '20px' }} elevation={0} square>
      <Avatar style={{ padding: '30px', margin: '30px 30px 0 0', backgroundColor: theme.palette.primary.main }}>{getAvatarText(entityDetails?.name)}</Avatar>
      <Grid container style={{ margin: '30px 30px 0 0' }}>
        <Grid item xs={12}>
          <Typography variant="h3">{clientMainDetails?.name}</Typography>
        </Grid>
        <Grid item xs={12}>
          {!entityDetails?.subTypeId && <Typography variant="h4">{entityDetails?.type}</Typography>}
          {!!entityDetails?.subTypeId && (
            <Typography variant="h4">
              {entityDetails?.type} - {ClientAccountSubType.getById(entityDetails.subTypeId)?.displayName} 
              {superMemberNumber ? ` (${superMemberNumber})` : ''}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12}>
          <a href={`mailto:${contactDetails?.email}`}>{contactDetails?.email}</a>
        </Grid>
      </Grid>
    </Paper>
  );
};
