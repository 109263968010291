import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionSummary,
  FormControlLabel,
  AccordionDetails,
  TextField,
  Autocomplete,
  Grid,
  Typography,
} from '@mui/material';
import React from 'react';
import { WO2Checkbox, WO2ToggleSwitch } from 'src/common';
import { DateRange } from 'src/store';
import { ActionCreatorWithoutPayload, ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { ClientPortfolio } from '../../../common/store';
import { MdaReportTypes } from 'src/common/types';
import { ClientReportsParameters, HidingOptionAssetAllocation, HidingOptionsRequest } from '../../store/types';
import DateRangePicker from 'src/common/components/DateRangePicker';

export interface AssetAllocationProps {
  clientPortfolios: ClientPortfolio[];
  parameters: ClientReportsParameters;
  expandAssetAllocation: ActionCreatorWithoutPayload<string>;
  setAssetAllocationSelectedParameter: ActionCreatorWithPayload<boolean, string>;
  setAssetAllocationPortfolioParameter: ActionCreatorWithPayload<number, string>;
  setAsAtDateAssetAllocationParameter: ActionCreatorWithPayload<DateRange, string>;
  setAssetAllocationHidingOption: ActionCreatorWithPayload<HidingOptionsRequest<HidingOptionAssetAllocation>, string>;
  createReportSortRequest: (isAdd: boolean, id: number, text: string) => void;
}

export const AssetAllocation = ({
  setAsAtDateAssetAllocationParameter,
  setAssetAllocationSelectedParameter,
  setAssetAllocationHidingOption,
  setAssetAllocationPortfolioParameter,
  createReportSortRequest,
  clientPortfolios,
  expandAssetAllocation,
  parameters,
}: AssetAllocationProps) => {
  const handleClickDateForAssetAllocation = (dates: DateRange) => {
    setAsAtDateAssetAllocationParameter(dates);
  };

  const handleSelectPortfolio = (portfolio: ClientPortfolio | null) => {
    if (portfolio && portfolio.investmentProgramId) {
      setAssetAllocationPortfolioParameter(portfolio.investmentProgramId);
    }
  };

  const handleClickAssetAllocationSelect = () => {
    setAssetAllocationSelectedParameter(!parameters.selectedReports?.assetAllocation.selected);
    createReportSortRequest(
      !parameters.selectedReports?.assetAllocation.selected,
      MdaReportTypes.AssetAllocation.id,
      MdaReportTypes.AssetAllocation.displayName
    );
  };

  return (
    <Accordion
      onChange={expandAssetAllocation}
      expanded={parameters.selectedReports?.assetAllocation.expanded || false}
      elevation={0}
    >
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Grid container>
          <FormControlLabel
            sx={{ flex: 1 }}
            aria-label="Acknowledge"
            onClick={(event) => event.stopPropagation()}
            onFocus={(event) => event.stopPropagation()}
            control={
              <WO2Checkbox
                color="primary"
                checked={parameters.selectedReports?.assetAllocation.selected}
                onChangeHandler={handleClickAssetAllocationSelect}
              />
            }
            label="Asset Allocation"
          />
          <div onClick={(e) => e.stopPropagation()}>
            <DateRangePicker
              sx={{ minWidth: 194 }}
              dateRange={{
                dateFrom: '',
                dateTo: parameters?.selectedReports.assetAllocation?.asAtDate,
                dateRangeType: parameters?.selectedReports.assetAllocation?.asAtDateType,
              }}
              setDateRange={handleClickDateForAssetAllocation}
              isDateOnly
            />
          </div>
        </Grid>
      </AccordionSummary>

      <AccordionDetails>
        <Grid container direction="column">
          <Grid item>
            <Autocomplete
              noOptionsText="No items to select"
              id="investmentservice-multiselect"
              fullWidth
              options={clientPortfolios}
              value={clientPortfolios.find(
                (portfolio) => portfolio.investmentProgramId === parameters.selectedReports.assetAllocation.portfolioId
              )}
              getOptionLabel={(option: ClientPortfolio) => option.investmentServiceName}
              renderInput={(params) => <TextField {...params} label="Select a Portfolio" margin="normal" fullWidth />}
              onChange={(_e: React.SyntheticEvent<Element, Event>, value: ClientPortfolio | null) =>
                handleSelectPortfolio(value)
              }
              isOptionEqualToValue={(option: ClientPortfolio, value: ClientPortfolio) =>
                option.investmentProgramId === value.investmentProgramId
              }
            />
          </Grid>
          <Grid item container>
            <Grid item container xs={4} alignItems="center">
              <WO2ToggleSwitch
                onChange={(e) =>
                  setAssetAllocationHidingOption({
                    value: e.target.checked,
                    option: HidingOptionAssetAllocation.AssetCategory,
                  })
                }
                checked={parameters.selectedReports.assetAllocation.includeAssetCategory}
                color="primary"
              />
              <Typography variant="h4">Include Asset Category</Typography>
            </Grid>
            <Grid item container xs={4} alignItems="center">
              <WO2ToggleSwitch
                onChange={(e) =>
                  setAssetAllocationHidingOption({
                    value: e.target.checked,
                    option: HidingOptionAssetAllocation.DetailTable,
                  })
                }
                checked={parameters.selectedReports.assetAllocation.includeDetailTable}
                color="primary"
              />
              <Typography variant="h4">Include Detail Table</Typography>
            </Grid>
            <Grid item container xs={4} alignItems="center">
              <WO2ToggleSwitch
                onChange={(e) =>
                  setAssetAllocationHidingOption({
                    value: e.target.checked,
                    option: HidingOptionAssetAllocation.ExclusionList,
                  })
                }
                checked={parameters.selectedReports.assetAllocation.applyExclusionList}
                color="primary"
              />
              <Typography variant="h4">Apply Exclusion List</Typography>
            </Grid>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
