import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { selectHasClientEditAdminOnly, selectIsDataFromTriumph } from 'src/store/selectors';
import { progressSelectorFactory } from '../../../../common';
import { RootState } from '../../../../reducers';
import { selectAfslId, selectPortfoliosWithoutAll } from '../common/store/selectors';
import { ReportsComponent } from './components/ReportsComponent';
import {
  selectclientFinancialYears,
  selectParameters,
  selectReportGeneration,
  selectSavedParametersList,
} from './store/selectors';
import {
  ClientReportsActionTypes,
  fetchClientAccount,
  fetchClientReport,
  fetchReportParametersList,
  getCGTRecordsForClient,
  getReportParametersById,
  initiateReportGeneration,
  saveAppendixFile,
  saveCoverPageFile,
  saveReportParameters,
} from './store/thunks';
import { newReportsSlice } from './store/slice';
import { fetchBenchmarks } from '../performance/store/thunks';
import { selectBenchmarks } from '../performance/store/selectors';

const actionTypes = [
  ClientReportsActionTypes.FetchClientReport,
  ClientReportsActionTypes.FetchClientAccount,
  ClientReportsActionTypes.InitiateReportGeneration,
];

const reportParametersActionTypes = [
  ClientReportsActionTypes.GetReportParametersList,
  ClientReportsActionTypes.GetReportParametersById,
  ClientReportsActionTypes.SaveReportParameters,
];

const saveFileActionTypes = [ClientReportsActionTypes.SaveCoverPageFile, ClientReportsActionTypes.SaveAppendixFile];

const mapStateToProps = (state: RootState) => ({
  parameters: selectParameters(state),
  clientPortfolios: selectPortfoliosWithoutAll(state),
  generateProgress: progressSelectorFactory(actionTypes)(state),
  generateProgressReportParameters: progressSelectorFactory(reportParametersActionTypes)(state),
  saveFileProgress: progressSelectorFactory(saveFileActionTypes)(state),
  reportGeneration: selectReportGeneration(state),
  afslId: selectAfslId(state),
  savedParametersList: selectSavedParametersList(state),
  cgtRecords: selectclientFinancialYears(state),
  hasClientEditAdminOnlyPermission: selectHasClientEditAdminOnly(state),
  isDataFromTriumph: selectIsDataFromTriumph(state),
  benchmarks: selectBenchmarks(state),
});

const mapDispatchToProps = {
  ...newReportsSlice.actions,
  fetchClientReport,
  fetchClientAccount,
  initiateReportGeneration,
  fetchReportParametersList,
  saveReportParameters,
  getReportParametersById,
  getCGTRecordsForClient,
  fetchBenchmarks,
  saveCoverPageFile,
  saveAppendixFile,
};

interface MatchParams {
  id?: string;
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector> & RouteComponentProps<MatchParams>;
export const ReportsV2Container = connect(mapStateToProps, mapDispatchToProps)(ReportsComponent);
