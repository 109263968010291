import { createAsyncThunk } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';
import api from '../../../../../app/api';
import { SystemMinimumDate } from '../../../../../common';
import { buildEncodedQueryString } from '../../../../../common/utils';
import { PagedRequest, PagedResult } from '../../../../../store';
import { Transaction } from '../../transactions/store/types';
import { ClientPortfolioDetailsResult, MonthlyPerformanceChartResult, ThunkParameters } from './types';

export enum DashboardActionTypes {
  FetchMonthlyPerformanceChart = '@@client/dashboard/FetchMonthlyPerformanceChart',
  FetchClientPortfolioDetails = '@@client/dashboard/FetchClientPortfolioDetails',
  FetchRecentTransactionsList = '@@client/dashboard/FetchRecentTransactionsList',
}

export enum DashboardApi {
  FetchMonthlyPerformanceChart = '/bff/GetMonthlyPerformanceChart',
  FetchClientPortfolioDetails = '/bff/GetClientPortfolio',
  FetchRecentTransactionsList = '/transactions/GetTransactionsForAccounts',
}

export const fetchMonthlyPerformanceChart = createAsyncThunk(
  DashboardActionTypes.FetchMonthlyPerformanceChart,
  async (wrapper: ThunkParameters) => {
    const queryString = buildEncodedQueryString({
      clientId: wrapper.clientId,
      startDate: wrapper.parameters.monthlyPerformanceChartStartDate?.startDate,
      investmentServiceId: wrapper.parameters.selectedPortfolio?.investmentProgramId,
      isDataFromTriumph: wrapper.isDataFromTriumph,
    });
    const response = await api.get<MonthlyPerformanceChartResult>(
      `${DashboardApi.FetchMonthlyPerformanceChart}${queryString}`
    );
    return response.data;
  }
);

export const fetchClientPortfolioDetails = createAsyncThunk(
  DashboardActionTypes.FetchClientPortfolioDetails,
  async (wrapper: ThunkParameters) => {
    const queryString = buildEncodedQueryString({
      clientId: wrapper.clientId,
      inceptionDate:
        wrapper.parameters.monthlyPerformanceChartStartDate?.startDate ??
        DateTime.fromISO(SystemMinimumDate).toISODate(),
      endDate: DateTime.now().toISODate(),
      investmentServiceId: wrapper.parameters.selectedPortfolio?.investmentProgramId,
      isDataFromTriumph: wrapper.isDataFromTriumph,
    });
    const response = await api.get<ClientPortfolioDetailsResult>(
      `${DashboardApi.FetchClientPortfolioDetails}${queryString}`
    );
    return response.data;
  }
);

export const fetchRecentTransactionsList = createAsyncThunk(
  DashboardActionTypes.FetchRecentTransactionsList,
  async (wrapper: ThunkParameters) => {
    const pagedRequest: PagedRequest = {
      pageNumber: 1,
      pageSize: 10,
      queryFields: [
        {
          fieldName: 'tradeDate',
          descendingSortDirection: true,
          hasSearchTerm: false,
          isSortTerm: true,
          searchTerm: '',
          operator: '',
        },
      ],
    };
    const payload = {
      clientId: wrapper.clientId,
      accountIds: wrapper.parameters?.selectedPortfolio?.accountIdList,
      startDate: DateTime.fromISO(SystemMinimumDate).toISODate(),
      endDate: DateTime.now().plus({ days: 1 }).toISODate(),
      pagedRequest: pagedRequest,
    };

    const response = await api.post<PagedResult<Transaction>>(DashboardApi.FetchRecentTransactionsList, payload);
    return response.data?.results;
  }
);
