import SaveIcon from '@mui/icons-material/SaveOutlined';
import { Box } from '@mui/material';
import { FormikProps } from 'formik';
import React, { ReactNode } from 'react';
import { LoadingProgress } from 'src/common/store/types';
import { ProgressButton } from '../progressButton';
import WO2Button from './../button/Button';

export enum Mode {
  ResetUpdate,
  CancelSave,
  EditCancelSave,
}

export enum ButtonType {
  Edit = 1,
  Cancel = 2,
  Save = 4,
}

interface Props<T> {
  formikProps?: FormikProps<T>;
  mode: Mode;
  handleEditClick?: () => void;
  handleCancelClick: () => void;
  handleSaveClick?: () => void;
  readonly?: boolean;
  disabledButtonTypes?: ButtonType | undefined;
  isPristine?: boolean;
  saveProgress?: LoadingProgress;
  children?: ReactNode;
  saveButtonIsNotSubmit?: boolean;
  saveButtonText?: string;
  saveButtonTestID?: string;
  hideSaveButtonIcon?: boolean;
  isDisabled?: boolean;
}

export function EditCancelSaveButtons<T>(props: Props<T>): JSX.Element {
  const {
    formikProps,
    mode,
    readonly,
    disabledButtonTypes,
    saveProgress,
    saveButtonIsNotSubmit,
    handleEditClick,
    handleCancelClick,
    handleSaveClick,
    isPristine,
    children,
    saveButtonText,
    saveButtonTestID,
    hideSaveButtonIcon,
    isDisabled,
  } = {
    readonly: false,
    ...props,
  };

  return (
    <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
      {children}
      {mode === Mode.EditCancelSave && readonly && (
        <WO2Button
          disabled={!!disabledButtonTypes && (disabledButtonTypes & ButtonType.Edit) === ButtonType.Edit}
          type="button"
          data-testid="editButton"
          variant="contained"
          onClick={handleEditClick}
          style={{ marginRight: '10px' }}
        >
          Edit
        </WO2Button>
      )}
      {!readonly && (
        <WO2Button
          disabled={mode === Mode.ResetUpdate ? isPristine : false}
          type="button"
          data-testid="cancelButton"
          variant="outlined"
          onClick={handleCancelClick}
          style={{ marginRight: '10px' }}
        >
          {mode === Mode.ResetUpdate ? 'Reset' : 'Cancel'}
        </WO2Button>
      )}
      {!readonly && (
        <ProgressButton
          disabled={
            (!!disabledButtonTypes && (disabledButtonTypes & ButtonType.Save) === ButtonType.Save) || isDisabled
          }
          type={!!saveButtonIsNotSubmit ? 'button' : 'submit'}
          startIcon={!!hideSaveButtonIcon ? undefined : SaveIcon}
          hideicon={!!hideSaveButtonIcon}
          data-testid={saveButtonTestID === null || saveButtonTestID === undefined ? 'saveButton' : saveButtonTestID}
          variant={'contained'}
          progress={saveProgress}
          onClick={() => {
            if (!!formikProps && Object.keys(formikProps.errors).length > 0) {
              // output validation error to console
            }
            return handleSaveClick ? handleSaveClick() : undefined;
          }}
        >
          {saveButtonText === undefined ? 'Save' : saveButtonText}
        </ProgressButton>
      )}
    </Box>
  );
}
