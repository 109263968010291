import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../../../app/api';
import { buildEncodedQueryString, convertToCSVFormat, CSVDataType, downloadCsv } from '../../../../../common';
import { FetchPagedResults, PagedResult } from '../../../../../store';
import {
  DownloadTransactionsPayload,
  FetchTransactionDetailsParameters,
  FetchTransactionsPayload,
  ThunkParameters,
  Transaction,
  TransactionDetails,
  TransactionsTotals,
} from './types';

export enum TransactionActionTypes {
  FetchTransactionsForAccounts = '@@client/transaction/FetchTransactionsForAccounts',
  FetchTransactionTotals = '@@client/transaction/FetchTransactionTotals',
  FetchTransactionDetails = '@@client/transaction/FetchTransactionDetails',
  DownloadTransactions = '@@client/transaction/downloadTransactions',
}

export enum TransactionsApiEndpoints {
  fetchTransactions = '/transactions/GetTransactionsForAccounts',
  fetchTransactionTotals = '/transactions/GetTransactionTotals',
  fetchTransactionDetails = '/transactions/GetTransactionDetails',
}

export const fetchTransactions = createAsyncThunk(TransactionActionTypes.FetchTransactionsForAccounts, async (wrapper: FetchTransactionsPayload) => {
  const response = await getPagedTransactions(wrapper);

  return {
    results: response.data,
    pagination: wrapper.pagination,
  } as FetchPagedResults<Transaction>;
});

export const fetchTransactionTotals = createAsyncThunk(TransactionActionTypes.FetchTransactionTotals, async (wrapper: ThunkParameters) => {
  const queryString = buildEncodedQueryString({
    clientId: wrapper.clientId,
    accountIds: wrapper.parameters.accountIdList,
    startDate: wrapper.parameters.dates.dateFrom,
    endDate: wrapper.parameters.dates.dateTo,
  });

  const response = await api.get<TransactionsTotals>(`${TransactionsApiEndpoints.fetchTransactionTotals}${queryString}`);
  return response.data;
});

export const fetchTransactionDetails = createAsyncThunk(TransactionActionTypes.FetchTransactionDetails, async (wrapper: FetchTransactionDetailsParameters) => {
  const queryString = buildEncodedQueryString({
    clientId: wrapper.clientId,
    transactionId: wrapper.transactionId,
  });

  const response = await api.get<TransactionDetails>(`${TransactionsApiEndpoints.fetchTransactionDetails}${queryString}`);
  return response.data;
});

export const downloadTransactions = createAsyncThunk(TransactionActionTypes.DownloadTransactions, async (wrapper: DownloadTransactionsPayload) => {
  const response = await getPagedTransactions({ ...wrapper, pagination: { ...wrapper.pagination, pageNumber: 1, pageSize: 9999999 } });
  const data: CSVDataType[] = convertToCSVFormat(response.data.results);

  downloadCsv(data, `Client_transactions_`);

  return { message: 'CSV downloaded' };
});

const getPagedTransactions = async (request: FetchTransactionsPayload) => {
  const body = {
    clientId: request.inputs.clientId,
    accountIds: request.inputs.parameters.accountIdList,
    startDate: request.inputs.parameters.dates.dateFrom,
    endDate: request.inputs.parameters.dates.dateTo,
    pagedRequest: { ...request.pagination, pageNumber: request.pagination.pageNumber },
  };
  return await api.post<PagedResult<Transaction>>(TransactionsApiEndpoints.fetchTransactions, body);
};
