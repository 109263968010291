import { Grid } from '@mui/material';
import React from 'react';
import { Pagination } from '../../../../common';
import { PagedRequest, PagedResult } from '../../../../store';
import { ModelItem } from '../store/types';
import { Detailed } from './detailed';

export interface Props {
  pagedResult: PagedResult<ModelItem>;
  pagedRequest: PagedRequest;
  handleModelClick: (modelId: number, modelVersionId: number) => void;
  handlePagedRequest: (pagedRequest: PagedRequest) => void;
}

export const DetailedList = (props: Props): JSX.Element => {
  const { pagedResult, pagedRequest, handlePagedRequest } = props;

  return (
    <Grid container style={{ width: '100%', margin: '10px' }}>
      <Grid item xs={12}>
        <Grid container spacing={5}>
          {pagedResult.results.map((m) => (
            <Grid item key={m.modelId} xs={4}>
              <Detailed
                model={m}
                openModel={() => {
                  const modelId = m.modelId;
                  const modelVersionId = m.modelVersionId;
                  if (modelId && modelVersionId) {
                    props.handleModelClick(modelId, modelVersionId);
                  }
                }}
              ></Detailed>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={8}></Grid>
        <Grid item xs={4} style={{ paddingLeft: '10px' }}>
          <Pagination
            numberOfPages={pagedResult.totalNumberOfPages}
            handleClickPagination={(pageNumber) => handlePagedRequest({ ...pagedRequest, pageNumber })}
            pageNumber={props.pagedResult.pageNumber}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
