import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../../../../app/api';
import { buildEncodedQueryString } from '../../../../../../common';
import { AddressDetails, DeleteAddressesModel, UpdateAddressesModel } from '../../../common/store/types';

export enum AddressesActionTypes {
  Fetch = '@@client/details/addresses/FetchAddresses',
  Update = '@@client/details/addresses/UpdateAddress',
  Delete = '@@client/details/addresses/DeleteAddress',
}

export enum AddressesApiEndpoints {
  Fetch = '/customers/GetClientAddresses',
  Update = '/customers/UpdateAddressForClient',
  Create = '/customers/CreateAddressForClient',
  Delete = '/customers/DeleteAddressForClient',
}

export const fetchAddresses = createAsyncThunk(AddressesActionTypes.Fetch, async (clientId: number) => {
  const queryString = buildEncodedQueryString({
    clientId,
  });
  const response = await api.get<AddressDetails[]>(`${AddressesApiEndpoints.Fetch}${queryString}`);

  return response.data;
});

export const updateAddress = createAsyncThunk(AddressesActionTypes.Update, async (payload: UpdateAddressesModel, thunkApi) => {
  await api.post(`${!!payload.addressId ? AddressesApiEndpoints.Update : AddressesApiEndpoints.Create}`, payload);

  thunkApi.dispatch(fetchAddresses(payload.clientId));
  return { ...payload, message: 'Address saved' };
});

export const deleteAddress = createAsyncThunk(AddressesActionTypes.Delete, async (payload: DeleteAddressesModel) => {
  await api.post(`${AddressesApiEndpoints.Delete}`, payload);
  return { ...payload, message: 'Address deleted' };
});
