import React, { useEffect } from 'react';
import { Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import ProtectedRoute from 'src/ProtectedRoute';
import { RebalanceContainer } from 'src/features/clients/client/rebalance/container';
import { ConfigurationContainer } from '../configuration/container';
import { Props } from '../container';
import { OverviewContainer } from '../overview/container';

export const Portfolios = ({ clientId, fetchInvestmentServiceVersionsByClient }: Props): JSX.Element => {
  const match = useRouteMatch();
  const { pathname } = useLocation();

  useEffect(() => {
    if (!!clientId) {
      fetchInvestmentServiceVersionsByClient({ clientId, showLatestVersionOnly: false });
    }
  }, [clientId, fetchInvestmentServiceVersionsByClient]);

  return (
    <Switch>
      <Route exact path={`${match.path}/`} render={() => <Redirect to={`${pathname}/overview`} />} />
      <ProtectedRoute path={`${match.path}/overview`} component={OverviewContainer}></ProtectedRoute>
      <ProtectedRoute path={`${match.path}/configuration`} component={ConfigurationContainer}></ProtectedRoute>
      <ProtectedRoute path={`${match.path}/rebalance`} component={RebalanceContainer}></ProtectedRoute>
    </Switch>
  );
};
