import { Card, Container, Typography } from '@mui/material';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { useEffect } from 'react';
import { LoadingProgress } from 'src/common/store/types';
import { DateTimeFormat, formatDollars, formatPercentage, getLocalDateTime } from '../../../../../common';
import { LoadingIndicator } from '../../../../../common/components/LoadingIndicator';
import { theme } from '../../../../../themes';
import {
  CalculatedHoldingsForPortfolioViewModel,
  ClientPortfolioSelectModel,
  DashboardParameters,
  MonthlyPerformanceChartResult,
  MonthlyPerformanceChartStartDateParameter,
  ThunkParameters,
} from '../store/types';
import MonthlyPerformanceChartButtons, { IIntervalButton } from './MonthlyPerformanceChartButtons';
import { PortfolioSelectorComponent } from './PortfolioSelector';

interface Props {
  loadingProgress: LoadingProgress;
  clientId: number | null;
  isDataFromTriumph: boolean;
  portfolioValue: number;
  selectedPortfolio: ClientPortfolioSelectModel | null;
  clientPortfolios?: ClientPortfolioSelectModel[];
  parameters: DashboardParameters;
  result?: MonthlyPerformanceChartResult;
  holdings?: CalculatedHoldingsForPortfolioViewModel;
  fetchMonthlyPerformanceChart: (arg: ThunkParameters) => void;
  setMonthlyPerformanceChartStartDate: (parameters: MonthlyPerformanceChartStartDateParameter) => void;
  setSelectedPortfolio: (portfolio: ClientPortfolioSelectModel) => void;
}

export const MonthlyPerformanceChartComponent = (props: Props): JSX.Element => {
  const { loadingProgress, clientId, parameters, result, fetchMonthlyPerformanceChart, isDataFromTriumph } = props;
  const categories = result?.seriesData?.map((i) => getLocalDateTime(i.date, DateTimeFormat.ChartMonthDate)) ?? [0];
  const monthly = result?.seriesData?.map((i) => i.return) ?? [0];
  const monthlyValues = monthly.map((i) => ({
    y: i,
    color: i > 0 ? theme.palette.graphBackground?.main : theme.palette.lightBlue?.main,
  }));
  const cumulativeValues = result?.seriesData?.map((i) => i.cumulativeReturn) ?? [0];

  useEffect(() => {
    if (clientId && parameters?.selectedPortfolio && parameters.selectedPortfolio.accountIdList.length > 0) {
      fetchMonthlyPerformanceChart({ clientId, parameters, isDataFromTriumph });
    }
  }, [clientId, parameters, fetchMonthlyPerformanceChart, isDataFromTriumph]);

  const chartOptions = {
    chart: {
      renderTo: 'container',
      backgroundColor: 'transparent',
      plotBackgroundColor: 'transparent',
      height: '200px',
    },
    plotOptions: {
      series: {
        allowPointSelect: false,
        groupPadding: 0,
        pointPadding: 0.1,
        borderWidth: 0,
        color: theme.palette.primary.main,
      },
      column: {
        color: '#FF0000',
        negativeColor: '#0088FF',
      },
    },
    title: {
      text: null,
    },
    subtitle: {
      text: null,
    },
    xAxis: [
      {
        gridLineColor: 'transparent',
        lineColor: 'transparent',
        categories: categories,
        labels: {
          enabled: false,
        },
      },
    ],
    yAxis: [
      {
        title: { text: undefined },
        gridLineColor: 'transparent',
        lineColor: 'transparent',
        labels: {
          enabled: false,
        },
        plotLines: [
          {
            color: theme.palette.graphBackground?.main,
            width: 0.5,
            value: 0,
          },
        ],
      },
    ],
    tooltip: {
      shared: true,
      formatter: function (this: Highcharts.TooltipFormatterContextObject) {
        return this.points?.reduce(function (s: string, point: Highcharts.TooltipFormatterContextObject) {
          return (
            s +
            '<br/><span style="color:' +
            point.color +
            '">\u25CF</span>  ' +
            point.series.name +
            ': ' +
            formatPercentage(point.y)
          );
        }, '<b>' + this.x + '</b>');
      },
    },
    series: [
      {
        showInLegend: false,
        name: 'Monthly Return',
        type: 'column',
        data: monthlyValues,
        states: {
          inactive: {
            opacity: 1,
          },
        },
      },
      {
        color: theme.palette.primary.main,
        name: 'Cumulative Return',
        type: 'line',
        data: cumulativeValues,
        zIndex: 100,
        states: {
          inactive: {
            opacity: 1,
          },
        },
        marker: {
          enabled: false,
        },
      },
    ],
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
  };

  const intervalButtonClicked = (button: IIntervalButton) => {
    props.setMonthlyPerformanceChartStartDate({
      interval: button.name,
      startDate: button.startDate,
    } as MonthlyPerformanceChartStartDateParameter);
  };

  return (
    <LoadingIndicator progress={loadingProgress}>
      <Card
        className={'Card'}
        square
        elevation={0}
        style={{ background: theme.palette.gradientHero?.main, padding: '30px 0' }}
      >
        <Container fixed>
          <PortfolioSelectorComponent {...props} />

          <Typography variant={'h1'} align={'center'} style={{ paddingBottom: '20px' }}>
            {formatDollars(props.holdings?.marketValue)}
          </Typography>
          <Typography variant={'h5'} align={'center'}>
            Total Return
          </Typography>
          <Typography variant={'h5'} align={'center'}>
            {formatDollars(result?.changeInValue)} ({formatPercentage(result?.changeInValuePercentage)})
          </Typography>
          <HighchartsReact highcharts={Highcharts} options={chartOptions} />
          <MonthlyPerformanceChartButtons {...props} intervalButtonClicked={intervalButtonClicked} />
        </Container>
      </Card>
    </LoadingIndicator>
  );
};
