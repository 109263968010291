import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { Link, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { LoadingProgress } from 'src/common/store/types';
import { InvestmentServiceWithVersionGrouped } from '../../../store/common/types';
import { activationStatus, ValidationMessage } from '../../store/activation';
import { ValidationMessagesDialog } from './validationMessagesDialog';

export interface VersionActivatorProps {
  investmentService: InvestmentServiceWithVersionGrouped;
  fetchValidationMessages: () => Promise<void>;
  activationValidationMessages: ValidationMessage[];
  progress: LoadingProgress;
  clearValidationMessages: () => void;
  activationStatus: activationStatus;
}

export const VersionActivator = ({
  investmentService,
  fetchValidationMessages,
  activationValidationMessages,
  progress,
  clearValidationMessages,
  activationStatus,
}: VersionActivatorProps): JSX.Element => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  useEffect(() => {
    if (activationStatus === 'activated') {
      setDialogOpen(false);
      clearValidationMessages();
    }
  }, [activationStatus]);

  return (
    <>
      <Typography variant="body1" style={{ padding: '4px' }}>
        Version {investmentService.versions[investmentService.versions.length - 1].majorVersion}.
        {investmentService.versions[investmentService.versions.length - 1].minorVersion} is{' '}
        {investmentService.versions[investmentService.versions.length - 1].status}
        {'  '}
        {investmentService.versions[investmentService.versions.length - 1].status === 'Active' && (
          <CheckCircleOutlineIcon style={{ width: '30px' }}></CheckCircleOutlineIcon>
        )}
        {investmentService.versions[investmentService.versions.length - 1].status === 'Pending' && (
          <>
            {' -'}
            <Link onClick={() => setDialogOpen(true)} style={{ paddingLeft: '3px', fontSize: '0.825rem' }}>
              Activate portfolio
            </Link>
          </>
        )}
      </Typography>
      {investmentService.versions[investmentService.versions.length - 1].status === 'Pending' && (
        <WarningRoundedIcon style={{ width: '20px', color: 'orange' }}></WarningRoundedIcon>
      )}
      {dialogOpen && (
        <ValidationMessagesDialog
          activationValidationMessages={activationValidationMessages}
          fetchValidationMessages={fetchValidationMessages}
          progress={progress}
          onClose={() => {
            setDialogOpen(false);
            clearValidationMessages();
          }}
        ></ValidationMessagesDialog>
      )}
    </>
  );
};
