import { createSelector } from '@reduxjs/toolkit';
import { selectBulkOrder } from '../../edit/store/selectors';
import { selectOrder } from '../../selectors';
import { PreApprovalInvestmentService, PreApprovalTradeItem } from '../../types';
import {
  InitiateBulkOrderPadRequest,
  InitiateManualBulkInvestmentService,
  Totals,
  Trade,
  TradeAction,
  ValidationResult,
} from './types';

export const selectCheckout = createSelector(selectOrder, (orderState) => orderState.checkout);

export const selectPreApprovalInvestmentServices = createSelector(
  selectCheckout,
  (checkoutState) => checkoutState.order
);

export const selectBulkOrderPadRequest = createSelector(selectCheckout, (checkoutState) => {
  const investmentServices: InitiateManualBulkInvestmentService[] = [];
  checkoutState.order.forEach((item: PreApprovalInvestmentService) => {
    const investmentService: InitiateManualBulkInvestmentService = {
      investmentServiceId: item.investmentServiceId,
      tradeItems: item.preApprovalTradeItems,
      lockedByOrdersWarningIssued:
        checkoutState.investmentServicesLockedByOrders?.includes(item.investmentServiceId) ?? false,
    };
    investmentServices.push(investmentService);
  });

  const result: InitiateBulkOrderPadRequest = {
    comment: checkoutState.comments,
    investmentServices: investmentServices,
  };

  return result;
});

export const selectTrades = createSelector(selectPreApprovalInvestmentServices, (preApprovalInvestmentServices) => {
  const result: Trade[] = [];

  preApprovalInvestmentServices.forEach((preApprovalInvestmentService) => {
    preApprovalInvestmentService.preApprovalTradeItems.forEach((preApprovalTrade) => {
      result.push({
        clientName: preApprovalInvestmentService.clientName,
        portfolioName: preApprovalInvestmentService.InvestmentServiceName,
        price: preApprovalTrade.unitPrice,
        units: preApprovalTrade.calculatedTradeUnits,
        priceLimit: preApprovalTrade.priceLimit || 0,
        total: preApprovalTrade.calculatedTradeUnits * preApprovalTrade.unitPrice,
        action: preApprovalTrade.tradeAction,
        priceType: preApprovalTrade.priceType,
        expiryType: preApprovalTrade.expiryType,
        expiryDate: preApprovalTrade.expiryDate,
        assetClass: preApprovalTrade?.assetClass || '',
        assetClassId: preApprovalTrade?.assetClassId || 0,
      });
    });
  });
  return result;
});

export const selectSecurity = createSelector(selectBulkOrder, (bulkOrder) => bulkOrder?.security);

export const selectTotals = createSelector(selectPreApprovalInvestmentServices, (order) => {
  const totalSellsCash = order.reduce(
    (prev: number, next: PreApprovalInvestmentService) =>
      prev +
      next.preApprovalTradeItems.reduce(
        (prev: number, next: PreApprovalTradeItem) =>
          next.tradeAction === TradeAction.Sell.name ? prev + next.unitPrice * next.calculatedTradeUnits : prev,
        0
      ),
    0
  );

  const totalOrders = order.reduce(
    (prev: number, next: PreApprovalInvestmentService) =>
      prev + next.preApprovalTradeItems.reduce((prev: number) => prev + 1, 0),
    0
  );

  const totalBuysCash = order.reduce(
    (prev: number, next: PreApprovalInvestmentService) =>
      prev +
      next.preApprovalTradeItems.reduce(
        (prev: number, next: PreApprovalTradeItem) =>
          next.tradeAction === TradeAction.Buy.name ? prev + next.unitPrice * next.calculatedTradeUnits : prev,
        0
      ),
    0
  );

  const totalOrderSize = totalBuysCash - totalSellsCash;
  return { totalBuysCash, totalSellsCash, totalOrderSize, totalOrders } as Totals;
});

export const selectValidationMessages = createSelector(selectCheckout, (checkout) => {
  return { errors: checkout.validationMessages?.errors ?? [], information: [], warnings: [] } as ValidationResult;
});

export const selectIsSubmitted = createSelector(selectCheckout, (checkout) => {
  return checkout.submitted;
});

export const selectComments = createSelector(selectCheckout, (checkout) => {
  return checkout.comments;
});
