import { AttachmentOutlined } from '@mui/icons-material';
import { Chip, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Field, Form, Formik, FormikProps } from 'formik';
import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import * as yup from 'yup';
import { LoadingProgress } from '../../../../../../common/store/types';
import { WO2Modal } from './../../../../../../common';
import { EditCancelSaveButtons, FormikSelect, FormikTextField, Mode } from './../../../../../../common/components/formik';
import { toBase64 } from './../../../../../../common/utils';
import { DocumentType } from './../../../common/store/types';
import { SuperSimplifierUploadDocument } from './../store/types';
import { Acknowledgement } from './acknowledgement';

interface Props {
  attachmentTypeId: number | null;
  clientEmail: string | undefined;
  documentTypes: DocumentType[];
  onClose: () => void;
  onSave: (values: SuperSimplifierUploadDocument) => void;
  saveProgress: LoadingProgress;
  shouldOpenForm: boolean;
  shouldHideAcknowledgement?: boolean;
  title: string;
}

const useStyles = makeStyles(() => ({
  dropzone: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: '2px',
    borderRadius: '2px',
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    margin: '20px',
  },
}));

export const UploadApplicationForm = ({
  attachmentTypeId,
  clientEmail,
  shouldOpenForm,
  onClose,
  onSave,
  documentTypes,
  saveProgress,
  shouldHideAcknowledgement,
  title,
}: Props): JSX.Element => {
  const classes = useStyles();
  const [hasAcknowledged, setAcknowledgement] = useState<boolean>(false);

  if (shouldOpenForm && !hasAcknowledged && !shouldHideAcknowledgement) {
    return (
      <Acknowledgement
        shouldOpenForm={shouldOpenForm}
        hasAcknowledged={hasAcknowledged}
        shouldHideAcknowledgement={shouldHideAcknowledgement}
        setAcknowledgement={setAcknowledgement}
        clientEmail={clientEmail}
        onClose={onClose}
      />
    );
  }
  return (
    <WO2Modal
      MuiDialogProps={{
        open: shouldOpenForm,
        onClose: () => {
          onClose();
          setAcknowledgement(false);
        },
        maxWidth: 'md',
      }}
      title={<>{title}</>}
      actions={[]}
    >
      <Formik<SuperSimplifierUploadDocument>
        enableReinitialize={true}
        initialValues={{
          file: null,
          fileName: '',
          base64Content: null,
          attachmentTypeId,
        }}
        onSubmit={() => {
          /* VOID */
        }}
        validationSchema={yup.object({
          fileName: yup.string().required('Name required'),
          attachmentTypeId: yup.number().required('Type required'),
          file: yup.mixed().test('file test', 'File required', function (this: yup.TestContext, file: unknown): boolean {
            return !!file;
          }),
        })}
      >
        {(props: FormikProps<SuperSimplifierUploadDocument>) => (
          <Form>
            <Grid container style={{ padding: '28px 0 0 0' }} spacing={3}>
              <Grid item xs={6} style={{ marginBottom: '16px' }}>
                <Field component={FormikTextField} name="fileName" label="DOCUMENT NAME" fullWidth />
              </Grid>
              <Grid item xs={6} style={{ marginBottom: '16px' }}>
                {documentTypes !== null && (
                  <Field
                    disabled={true}
                    fieldName="attachmentTypeId"
                    as={FormikSelect}
                    itemDisplayNameField="name"
                    label="DOCUMENT TYPE"
                    id="typeIdSelect"
                    data={documentTypes}
                    fullWidth
                  />
                )}
              </Grid>
              <Grid item xs={12} style={{ marginBottom: '16px' }}>
                <Dropzone
                  disabled={false}
                  onDrop={async (files) => {
                    if (!!files.length && !!files[0]) {
                      const file = files[0];

                      await toBase64(file)
                        .then((base64Content) => {
                          props.setValues({ ...props.values, file, fileName: file.name, base64Content });
                        })
                        .catch((error) => {
                          console.error(`failed to upload document: ${JSON.stringify(error)}`);
                        });
                    }
                  }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div
                      data-testid="dropzone"
                      {...getRootProps({ className: classes.dropzone })}
                      style={{
                        borderColor: !!props.errors.file ? 'red' : 'gray',
                        marginTop: 10,
                        marginBottom: 20,
                        marginLeft: 0,
                        marginRight: 0,
                        minHeight: 80,
                      }}
                    >
                      <input
                        {...getInputProps({
                          // accept: '.*',
                          type: 'file',
                          accept: '.pdf',
                        })}
                      />
                      {!!props.values.file && (
                        <Chip
                          disabled={false}
                          label={props.values.file?.name}
                          onDelete={() => {
                            props.setValues({ ...props.values, file: null, fileName: '', base64Content: null });
                          }}
                          icon={<AttachmentOutlined />}
                        />
                      )}
                      {!props.values.file && (
                        <p style={{ color: !!props.errors.file ? 'red' : 'gray' }}>{'Drag and drop your file here, or click to select file'}</p>
                      )}
                    </div>
                  )}
                </Dropzone>
              </Grid>
            </Grid>

            <Grid container style={{ marginTop: '48px' }}>
              <Grid item xs={12} style={{ textAlign: 'right' }}>
                <EditCancelSaveButtons
                  mode={Mode.CancelSave}
                  handleCancelClick={() => {
                    onClose();
                    setAcknowledgement(false);
                  }}
                  handleSaveClick={() => {
                    onSave(props.values);
                  }}
                  saveProgress={saveProgress}
                />
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </WO2Modal>
  );
};
