import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { progressSelectorFactory } from 'src/common';
import { RootState } from '../../../../reducers';
import { selectClientRoles } from '../../client/common/store/selectors';
import { CommonActionTypes, fetchRoles } from '../../client/common/store/thunks';
import { selectPrevNextRoutePaths } from '../store/routeSelectors';
import {
  selectAccounts,
  selectAccountTypeValues,
  selectAdditionalContactsStartIndex,
  selectAttachContactList,
  selectContactAddressesItems,
  selectContactAvailableAddressTypes,
  selectContactEditAddress,
  selectContactForEdit,
  selectContactsAdditionalItems,
  selectContactsEditIndex,
  selectDocumentTypes,
  selectEditIdentification,
  selectId,
  selectIdentifications,
  selectIsSuperSimplifierOnboardingClient,
  selectParameters,
  selectTrustee,
} from '../store/selectors';
import { onboardSlice } from '../store/slice';
import {
  deleteContact,
  deleteDocument,
  downloadDocument,
  fetchContactIdentificationForEdit,
  fetchContactIdentifications,
  fetchContactsToAttach,
  fetchDocumentTypes,
  fetchExistingContact,
  OnboardActionTypes,
  saveContactIdentificationValues,
  saveContactInfo,
  saveDocument,
} from '../store/thunks';
import { Contacts } from './components/contacts';

const mapStateToProps = (state: RootState, routeComponentProps: RouteComponentProps<{ index: string }>) => {
  return {
    prevNextRoutePaths: selectPrevNextRoutePaths(state, routeComponentProps.location.pathname),
    contact: selectContactForEdit(state),
    contactsEditIndex: selectContactsEditIndex(state),
    addresses: selectContactAddressesItems(state),
    editAddress: selectContactEditAddress(state),
    availableAddressTypes: selectContactAvailableAddressTypes(state),
    contactsToAttach: selectAttachContactList(state),
    loadingContactsToAttach: progressSelectorFactory([OnboardActionTypes.FetchContactsToAttach])(state),
    contacts: selectContactsAdditionalItems(state),
    accountTypeValues: selectAccountTypeValues(state),
    roles: selectClientRoles(state),
    loadingRolesProgress: progressSelectorFactory([CommonActionTypes.FetchRoles])(state),
    clientId: selectId(state),
    accounts: selectAccounts(state),
    documentTypes: selectDocumentTypes(state),
    editIdentification: selectEditIdentification(state),
    identifications: selectIdentifications(state),
    parameters: selectParameters(state),
    loadingProgress: progressSelectorFactory([
      OnboardActionTypes.FetchDocumentTypes,
      OnboardActionTypes.FetchContactIdentifications,
    ])(state),
    saveIdentificationProgress: progressSelectorFactory([
      OnboardActionTypes.SaveDocument,
      OnboardActionTypes.SaveContactIdentification,
    ])(state),
    additionalContactsStartIndex: selectAdditionalContactsStartIndex(state),
    saveProgress: progressSelectorFactory([OnboardActionTypes.SaveOnboarding])(state),
    trustee: selectTrustee(state),
    clientIsSuperOrPension: selectIsSuperSimplifierOnboardingClient(state),
  };
};

const mapDispatchToProps = {
  setContactEditIndex: onboardSlice.actions.setContactEditIndex,
  setContactAddressEdit: onboardSlice.actions.setContactAddressEdit,
  saveAddress: onboardSlice.actions.saveContactAddress,
  removeAddress: onboardSlice.actions.removeContactAddress,
  clearAttachedContact: onboardSlice.actions.clearAttachedContact,
  setIdentificationAddMode: onboardSlice.actions.setIdentificationAddMode,
  cancelIdentificationAddEditMode: onboardSlice.actions.cancelIdentificationAddEditMode,
  setIdentificationEditId: onboardSlice.actions.setIdentificationEditId,
  removeIdentification: onboardSlice.actions.removeIdentification,
  fetchContactsToAttach,
  fetchExistingContact,
  fetchDocumentTypes,
  fetchContactIdentifications,
  fetchContactIdentificationForEdit,
  downloadDocument,
  deleteDocument,
  saveDocument,
  saveContactInfo,
  deleteContact,
  fetchRoles,
  saveContactIdentificationValues,
};

interface MatchParams {
  index?: string;
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector> & RouteComponentProps<MatchParams>;
export const ContactsContainer = connect(mapStateToProps, mapDispatchToProps)(Contacts);
