import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React from 'react';
import { ClientAccountSubType } from '../../../../../../../common/types';
import { SuperSimplifierDetails } from '../../../../common/store/types';

export interface Props {
  details: SuperSimplifierDetails;
}

export const MemberDetails = (props: Props): JSX.Element => {
  return (
    <>
      <Typography variant="h4" style={{ paddingBottom: '20px' }}>
        Member details
      </Typography>
      <Paper elevation={5}>
        <TableContainer component={Paper} elevation={0}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="left">
                  <Typography variant="h6" color="textSecondary">
                    SUPER MEMBER NUMBER
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography variant="h6" color="textSecondary">
                    SUB ACCOUNT TYPE
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="left">
                  <Typography data-testid="superMemberNumberData" variant="h5">
                    {props.details.superMemberNumber}
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography data-testid="subAccountTypeIdData" variant="h5">
                    {ClientAccountSubType.getById(props.details.subAccountTypeId || 0)?.displayName}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};
