import { createSelector } from '@reduxjs/toolkit';
import { selectOverview } from '../../store/selectors';
import { AssetGroup } from './types';

export const selectParameters = createSelector(selectOverview, (overview) => overview.parameters);

export const selectPortfolioDetailResult = createSelector(selectOverview, (overview) => overview.portfolioDetailResult);

export const selectGainsLossesDetail = createSelector(selectPortfolioDetailResult, (portfolioDetailResult) => portfolioDetailResult?.gainsLossesDetail);

export const selectPortfolioDetail = createSelector(selectPortfolioDetailResult, (portfolioDetailResult) => portfolioDetailResult?.portfolioDetail);

export const selectAvailableCash = createSelector(selectPortfolioDetail, (portfolioDetail) => portfolioDetail?.availableCash);

export const selectMarketValue = createSelector(selectPortfolioDetail, (portfolioDetail) => portfolioDetail?.marketValue ?? 0);

export const selectGainsLossesItems = createSelector(
  selectPortfolioDetailResult,
  (portfolioDetailResult) => portfolioDetailResult?.gainsLossesDetail?.items ?? []
);

export const selectPortfolioAssetGroups = createSelector(selectPortfolioDetail, selectMarketValue, (portfolioDetail, marketValue) => {
  const originalItems = portfolioDetail?.items ?? [];
  if (originalItems.length <= 0) {
    return [];
  }

  const allItems =
    originalItems.length > 1
      ? originalItems
          .map((i) => i.items)
          .reduce((a, b) => a.concat(b))
          .sort((a, b) => (a.securityName ?? '').localeCompare(b.securityName ?? ''))
      : [];

  const allItemsGroup: AssetGroup = {
    groupName: 'All Assets',
    currentValue: marketValue,
    currentWeightPercentage: 1,
    targetValue: marketValue,
    targetWeightPercentage: 1,
    items: allItems,
  } as AssetGroup;

  return [allItemsGroup].concat(originalItems);
});

export const selectTemplates = createSelector(selectOverview, (overview) => overview.templates);
