import { ContentCopyOutlined, RefreshRounded, RemoveRedEyeOutlined } from '@mui/icons-material';
import { IconButton, Paper, Tooltip, Typography } from '@mui/material';
import { default as React } from 'react';
import WO2Button from 'src/common/components/button/Button';
import { DateTimeFormat, getLocalDateTime } from '../../../../common';
import { ServerSideDataTable } from '../../../../common/components/dataTable/serverSide';
import { DatatableColumn, FilterDataType } from '../../../../common/components/dataTable/types';
import { LoadingProgress } from '../../../../common/store/types';
import { PagedRequest, PagedResult } from '../../../../store';
import { WorkflowActionRequest, WorkflowItem } from '../store/types';
import { WorkflowTasks } from './WorkflowTasks';

export interface Props {
  pagedResults?: PagedResult<WorkflowItem>;
  pagedRequest: PagedRequest;
  handlePagedRequest: (pagedRequest: PagedRequest) => void;
  progress: LoadingProgress;
  refreshGrid: () => void;
  hasAdminAccess: boolean;
  terminateWorkflow: (request: WorkflowActionRequest) => void;
  completeWorkflow: (request: WorkflowActionRequest) => void;
  resumeWorkflow: (request: WorkflowActionRequest) => void;
  discardWorkflow: (request: WorkflowActionRequest) => void;
  downloadWorkflowDocument: (workflow: WorkflowActionRequest) => void;
  setSelectedWorkflow: (workflow: WorkflowItem | undefined) => void;
  selectedWorkflow: WorkflowItem | undefined | null;
  workflowError: string | undefined;
  setWorkflowError: (message: string | undefined) => void;
}

export function WorkflowListTable(props: Props): JSX.Element {
  const {
    pagedResults,
    pagedRequest,
    progress,
    hasAdminAccess,
    selectedWorkflow,
    workflowError,
    refreshGrid,
    handlePagedRequest,
    terminateWorkflow,
    resumeWorkflow,
    discardWorkflow,
    setSelectedWorkflow,
    setWorkflowError,
    completeWorkflow,
    downloadWorkflowDocument,
  } = props;

  const columns: DatatableColumn[] = [
    {
      filterDataType: FilterDataType.string,
      name: 'name',
      label: 'NAME',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      filterDataType: FilterDataType.string,
      name: 'clientName',
      label: 'CLIENT',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          if ((pagedResults?.results || [])[dataIndex] == null) return '';
          const clientName = pagedResults?.results[dataIndex].clientName;
          return clientName === null || clientName === 'undefined'
            ? pagedResults?.results[dataIndex].entityId
            : clientName;
        },
      },
    },
    {
      filterDataType: FilterDataType.string,
      name: 'adviserName',
      label: 'ADVISER',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      filterDataType: FilterDataType.string,
      name: 'afslName',
      label: 'AFSL',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      filterDataType: FilterDataType.string,
      name: 'practiceName',
      label: 'PRACTICE',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      filterDataType: FilterDataType.string,
      name: 'businessStatus',
      label: 'STATUS',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          if ((pagedResults?.results || [])[dataIndex] == null) return '';
          return getBusinessStatus(dataIndex);
        },
      },
    },
    {
      name: 'createdOn',
      label: 'CREATED',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          if ((pagedResults?.results || [])[dataIndex] == null) return '';
          return getLocalDateTime(pagedResults?.results[dataIndex].createdOn, DateTimeFormat.DateTime);
        },
      },
    },
    {
      name: 'modifiedOn',
      label: 'LAST CHANGED',
      options: {
        filter: false,
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          if ((pagedResults?.results || [])[dataIndex] == null) return '';
          return getLocalDateTime(pagedResults?.results[dataIndex].modifiedOn, DateTimeFormat.DateTime);
        },
      },
    },
    {
      name: 'createdBy',
      label: 'CREATED BY',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'lastChangedBy',
      label: 'LAST CHANGED BY',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'id',
      label: 'ID',
      textAlign: 'center',
      options: {
        filter: true,
        sort: false,
        setCellHeaderProps: () => {
          return {
            style: {
              paddingTop: '2%',
              fontSize: '14px',
              fontWeight: 500,
              marginLeft: '1%',
              position: 'absolute',
              fontStretch: '100%',
              lineHeight: '24.5px',
              fontStyle: 'normal',
            },
          };
        },
        customBodyRenderLite: (dataIndex) => {
          if ((pagedResults?.results || [])[dataIndex] == null) return '';
          const workflowId = pagedResults?.results[dataIndex];
          return (
            <WO2Button
              color="inherit"
              type="button"
              data-testid={`copyWorkflowId_testId`}
              disableFocusRipple={false}
              disableRipple={false}
              onClick={() => {
                let id = '';
                if (!!workflowId) id = workflowId.id;
                navigator.clipboard.writeText(id);
              }}
            >
              <ContentCopyOutlined></ContentCopyOutlined>
            </WO2Button>
          );
        },
      },
    },
  ];

  if (hasAdminAccess) {
    columns.push({
      name: '',
      label: '',
      textAlign: 'center',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          if ((pagedResults?.results || [])[dataIndex] == null) return '';
          const workflow = pagedResults?.results[dataIndex];
          return (
            <WO2Button
              color="inherit"
              style={{ minWidth: '50px' }}
              type="button"
              data-testid={`copyWorkflowId`}
              disableFocusRipple={false}
              disableRipple={false}
              onClick={() => {
                if (!!workflow) setSelectedWorkflow(workflow);
              }}
            >
              <RemoveRedEyeOutlined></RemoveRedEyeOutlined>
            </WO2Button>
          );
        },
      },
    });
  }

  const getBusinessStatus = (dataIndex: number): React.ReactNode => {
    const errorMessage = pagedResults?.results[dataIndex].errorMessage;
    const name = pagedResults?.results[dataIndex].name;
    let businessStatus = pagedResults?.results[dataIndex].businessStatus;
    businessStatus = businessStatus ? businessStatus : 'Unspecified';
    //only show error message for super member data workflow for now
    return errorMessage && name?.includes('SuperSimplifierMemberDataUploadWorkflow')
      ? customStatusColumn(businessStatus, errorMessage)
      : businessStatus;
  };

  const customStatusColumn = (status: string, errorMessage: string): React.ReactNode => {
    return (
      <Tooltip
        title={
          <React.Fragment>
            <Typography color="inherit">{errorMessage}</Typography>
          </React.Fragment>
        }
      >
        <Typography style={{ color: 'red' }}>{status}</Typography>
      </Tooltip>
    );
  };
  const addButton = (): React.ReactNode => (
    <span>
      <Tooltip title="Refresh">
        <IconButton
          onClick={() => {
            refreshGrid();
          }}
        >
          <RefreshRounded style={{ cursor: 'pointer', color: '#757575' }}></RefreshRounded>
        </IconButton>
      </Tooltip>
    </span>
  );

  return (
    <>
      <Paper elevation={0}>
        <ServerSideDataTable
          loadingProgress={progress}
          columns={columns}
          pagedRequest={pagedRequest}
          pagedResult={pagedResults}
          options={{
            filter: true,
            viewColumns: true,
            customToolbar: () => addButton(),
          }}
          handlePagedRequest={handlePagedRequest}
        ></ServerSideDataTable>
      </Paper>
      {hasAdminAccess && selectedWorkflow !== undefined ? (
        <WorkflowTasks
          workflowDetail={selectedWorkflow}
          terminateWorkflow={terminateWorkflow}
          completeWorkflow={completeWorkflow}
          resumeWorkflow={resumeWorkflow}
          discardWorkflow={discardWorkflow}
          setSelectedWorkflow={setSelectedWorkflow}
          downloadWorkflowDocument={downloadWorkflowDocument}
          workflowError={workflowError}
          setWorkflowError={setWorkflowError}
        ></WorkflowTasks>
      ) : (
        <></>
      )}
    </>
  );
}
