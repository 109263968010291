import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React from 'react';
import { ClientAccountSubType, ClientAccountType } from '../../../../../../../common/types';
import { ClientMainDetails } from '../../../../common/store/types';
import { InvestmentService } from '../../../store/common';

export interface Props {
  clientId: number | null;
  clientDetails: ClientMainDetails | null | undefined;
  portfolioDetails: InvestmentService | null;
}

export const ConfigurationHeader = (props: Props): JSX.Element => {
  return (
    <>
      <Typography variant="h4" style={{ paddingBottom: '20px' }}>
        Main
      </Typography>
      <TableContainer component={Paper} elevation={0}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left">
                <Typography variant="h6" color="textSecondary">
                  CLIENT ID
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography variant="h6" color="textSecondary">
                  PORTFOLIO ID
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography variant="h6" color="textSecondary">
                  VERSION ID
                </Typography>
              </TableCell>
              {!!props.clientDetails && (
                <TableCell align="left">
                  <Typography variant="h6" color="textSecondary">
                    ACCOUNT TYPE
                  </Typography>
                </TableCell>
              )}
              {!!props.clientDetails?.subTypeId && (
                <TableCell align="left">
                  <Typography variant="h6" color="textSecondary">
                    SUB TYPE
                  </Typography>
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="left">
                <Typography variant="h5">{props.clientId}</Typography>
              </TableCell>
              <TableCell align="left">
                <Typography variant="h5">{props.portfolioDetails?.investmentServiceId}</Typography>
              </TableCell>
              <TableCell align="left">
                <Typography variant="h5">{props.portfolioDetails?.investmentServiceVersionId}</Typography>
              </TableCell>
              {!!props.clientDetails && (
                <TableCell align="left">
                  <Typography variant="h5">{ClientAccountType.getById(props.clientDetails.accountTypeId)?.displayName}</Typography>
                </TableCell>
              )}
              {!!props.clientDetails?.subTypeId && (
                <TableCell align="left">
                  <Typography variant="h5">{ClientAccountSubType.getById(props.clientDetails.subTypeId)?.displayName}</Typography>
                </TableCell>
              )}
              <TableCell align="left">
                <Typography variant="h5"></Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
