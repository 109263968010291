import { Autocomplete, Box, Button, Container, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { PagedRequest } from '../../../../store';
import { Props } from '../container';
import { PensionAccountVM, PensionRestartWorkflowRequest, SuperMemberDataRequest, WorkflowItem } from '../store/types';
import ImportMenu from './ImportMenu';
import { WorkflowListTable } from './workflowListTable';

export const WorkflowList = ({
  workflowsList,
  workflowsListProgress,
  parameters,
  fetchWorkflowList,
  hasAdminAccess,
  importErrors,
  isImporting,
  importUploadSuccess,
  resetModelUploadSuccessStatus,
  setSelectedWorkflow,
  setErrorOnFileImport,
  selectedWorkflow,
  workflowError,
  setWorkflowError,
  uploadSuperMemberDataCsv,
  terminateWorkflow,
  completeWorkflow,
  resumeWorkflow,
  discardWorkflow,
  downloadWorkflowDocument,
  getPensionAccounts,
  restartPensionWorkflow,
  pensionAccounts,
}: Props): JSX.Element => {
  const [isMenuOpen, setMenuState] = useState(false);
  const [accountsLoaded, setAccountsLoaded] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [disableExecute, setDisableExecute] = useState(true);
  const [selectedPensionAccount,  setSelectedPensionAccount] = useState<PensionAccountVM | null>();

  const fetchData = useCallback(() => {
    fetchWorkflowList({ pagination: parameters.pagination });
  }, [parameters, fetchWorkflowList]);
  
  useEffect(() => {
    fetchData();
  }, [parameters, fetchData, importUploadSuccess, refresh]);

  const fetchPensionAccounts = useCallback(() => {
    if (hasAdminAccess) {
      getPensionAccounts({ statusFlag: 2 });
    }
  }, [pensionAccounts, getPensionAccounts, refresh]);

  useEffect(() => {
    if (!accountsLoaded || refresh) {
      fetchPensionAccounts();
    }
    setAccountsLoaded(!!pensionAccounts && pensionAccounts.length > 0);
  }, [pensionAccounts, fetchPensionAccounts, refresh]);

  const handleGridActions = (pagedRequest: PagedRequest) => {
    fetchWorkflowList({
      pagination: pagedRequest,
    });
  };

  const handleSetSelectedWorkflow = (workflow: WorkflowItem | undefined) => {
    setSelectedWorkflow(workflow);
    setWorkflowError(undefined);
  };

  const onImportFailed = (errors: string[]) => {
    setErrorOnFileImport(errors);
  };

  const importSuperMemberData = (memberData: SuperMemberDataRequest[]) => {
    uploadSuperMemberDataCsv(memberData);
  };

  const handleChange = (value: PensionAccountVM | null) => {
    setSelectedPensionAccount(value);
    if (value != null && value.clientId > 0)
        setDisableExecute(false);
  };

  const handleStartClick = () => {
    if (selectedPensionAccount != undefined)
      {
        const request: PensionRestartWorkflowRequest = {
          clientId: selectedPensionAccount?.clientId || 0,
        };
        restartPensionWorkflow(request);
        setSelectedPensionAccount(null);
        setDisableExecute(true);
      }
  };
  
  return (
    <Container style={{ padding: '20px' }}>
      <Box justifyContent={'space-between'} alignItems={'center'}>
        <Typography variant="h2">Workflows</Typography>
        <Typography variant="h4">View and manage workflow tasks</Typography>
        <Box display={'flex'} justifyContent={'flex-end'} padding={'20px'}>
            <FormControl variant="outlined" style={{alignSelf:'center', paddingTop: '5px' }}>
              <InputLabel id="workflow-start-label">
                START A WORKFLOW
              </InputLabel>
              <Box paddingRight={'10px'}> 
                <Select
                  style={{ width: '420px',   }}
                  labelId="workflow-start-label"
                  id="workflow-start"
                  value={"Pension Restart"}
                  label={'START A WORKFLOW'}>
                  <MenuItem key={0} value={"Pension Restart"}>
                    Pension Restart
                  </MenuItem>
                </Select>
                </Box>        
            </FormControl>
          {hasAdminAccess && (
              <Box paddingRight={'10px'}>
                <Autocomplete<PensionAccountVM>
                  style={{ width: '420px'}}
                  value={selectedPensionAccount || null}
                  getOptionLabel={(option: PensionAccountVM) => option.name + ' (' + option.superMemberNumber + ')'}
                  isOptionEqualToValue={(option, value) => option.clientId === value.clientId}
                  loading={false}
                  renderInput={(params) => <TextField {...params} label="Client Account" margin="normal" variant="outlined" fullWidth />}
                  onChange={(_e: React.SyntheticEvent<Element, Event>, value: PensionAccountVM | null) => handleChange(value)}
                  options={Array.isArray(pensionAccounts) ? pensionAccounts : []}/>          
              </Box>
            )
          }
          <Box paddingRight={'10px'} alignContent={'center'}>
            <Button
              id="search_button"
              disabled={disableExecute}
              color={'primary'}
              variant="contained"
              onClick={() => handleStartClick()}>
            Start
            </Button>
          </Box>
        </Box>
      </Box>
      <Grid container spacing={2} style={{ padding: '20px 0' }}>
        <Grid item xs={12}>
          <WorkflowListTable
            pagedRequest={parameters.pagination}
            pagedResults={workflowsList || undefined}
            progress={workflowsListProgress}
            handlePagedRequest={handleGridActions}
            refreshGrid={() => setRefresh(!refresh)}
            hasAdminAccess={hasAdminAccess}
            terminateWorkflow={terminateWorkflow}
            completeWorkflow={completeWorkflow}
            resumeWorkflow={resumeWorkflow}
            discardWorkflow={discardWorkflow}
            downloadWorkflowDocument={downloadWorkflowDocument}
            selectedWorkflow={selectedWorkflow}
            setSelectedWorkflow={handleSetSelectedWorkflow}
            workflowError={workflowError}
            setWorkflowError={setWorkflowError}
          ></WorkflowListTable>
        </Grid>
      </Grid>
      {hasAdminAccess && (
        <ImportMenu
          importErrors={importErrors}
          isImporting={isImporting}
          isMenuOpen={isMenuOpen}
          onUploadSuccessHandler={() => {
            setMenuState(false);
            resetModelUploadSuccessStatus(false);
          }}
          importUploadSuccess={importUploadSuccess}
          setMenuState={setMenuState}
          importSuperMemberData={importSuperMemberData}
          onImportFailed={onImportFailed}
        />
      )}
    </Container>
  );
};
