import { connect, ConnectedProps } from 'react-redux';
import { progressSelectorFactory } from '../../../../../../common';
import { RootState } from '../../../../../../reducers';
import { selectClientId, selectIsSuperSimplifierClient, selectSuperSimplifierDetails } from '../../../common/store/selectors';
import { CommonActionTypes } from '../../../common/store/thunks';
import { BenefitStatement } from './components/benefitStatement';
import { selectOtherBenefitsDetails, selectPreservationComponentDetails, selectTaxationDetails } from './store/selectors';
import { benefitStatementSlice } from './store/slice';
import {
  BenefitStatmentActionTypes,
  fetchBenefitStatementDetailsByClientId,
  fetchBenefitStatementOtherBenefitsComponentByAccountId,
  fetchBenefitStatementPreservationComponentByAccountId,
  fetchBenefitStatementTaxationComponentByAccountId,
} from './store/thunks';

const mapStateToProps = (state: RootState) => ({
  clientId: selectClientId(state),
  details: selectSuperSimplifierDetails(state),
  loadingProgressMainDetails: progressSelectorFactory([CommonActionTypes.FetchSuperSimplifier])(state),
  loadingProgressBenefitStatementDetails: progressSelectorFactory([BenefitStatmentActionTypes.FetchBenefitStatementDetails])(state),
  loadingProgressPreservationComponentDetails: progressSelectorFactory([BenefitStatmentActionTypes.FetchBenefitStatementPreservationComponent])(state),
  loadingProgressTaxationComponentDetails: progressSelectorFactory([BenefitStatmentActionTypes.FetchBenefitStatementTaxationComponent])(state),
  loadingProgressOtherBenefitDetails: progressSelectorFactory([BenefitStatmentActionTypes.FetchBenefitStatementOtherBenefitsComponent])(state),
  preservationComponentDetails: selectPreservationComponentDetails(state),
  taxationDetails: selectTaxationDetails(state),
  otherBenefits: selectOtherBenefitsDetails(state),
  isSuperSimplifierClient: selectIsSuperSimplifierClient(state),
});

const mapDispatchToProps = {
  ...benefitStatementSlice.actions,
  fetchBenefitStatementDetailsByClientId,
  fetchBenefitStatementPreservationComponentByAccountId,
  fetchBenefitStatementTaxationComponentByAccountId,
  fetchBenefitStatementOtherBenefitsComponentByAccountId,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector>;
export const BenefitStatementContainer = connector(BenefitStatement);
