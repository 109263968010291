import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'src/app/api';
import { buildEncodedQueryString } from 'src/common';
import { ResultWrapper } from '../../benefitStatement/store/types';
import {
  DeathAndTpdInsurance,
  IncomeProtectionInsurance,
  PremiumDetails,
  ApplyMetlifeDetails,
  ContactRoles,
  SecureMetApplyToken,
} from './types';

export enum InsuranceDetailActionTypes {
  FetchDeathAndTpdInsuranceByAccountId = '@@client/details/supersimplifier/insuranceDetail/FetchDeathAndTpdInsurance',
  FetchIncomeProtectionInsuranceByAccountId = '@@client/details/supersimplifier/insuranceDetail/FetchIncomeProtectionInsurance',
  FetchPremiumDetailsByAccountId = '@@client/details/supersimplifier/insuranceDetail/FetchPremiumDetails',
  FetchMetApplyTokenByClientId = '@@client/details/supersimplifier/insuranceDetail/FetchMetApplyToken',
  FetchAuthorisedContactProgress = '@@client/details/contacts/FetchContacts',
}

export enum InsuranceDetailApiEndpoints {
  FetchDeathAndTpdInsurance = '/ssc/GetDeathAndTPD',
  FetchIncomeProtectionInsurance = '/ssc/GetIncomeProtectionDetails',
  FetchPremiumDetails = '/ssc/GetPremiumDetails',
  FetchMetApplyToken = '/ssc/GetMetApplyToken',
  FetchAuthorisedContact = 'customers/GetClientContacts',
}

export const fetchDeathAndTpdInsuranceByAccountId = createAsyncThunk(
  InsuranceDetailActionTypes.FetchDeathAndTpdInsuranceByAccountId,
  async (accountId: string) => {
    const queryString = buildEncodedQueryString({
      accountId,
    });
    const response = await api
      .get<DeathAndTpdInsurance>(`${InsuranceDetailApiEndpoints.FetchDeathAndTpdInsurance}${queryString}`)
      .then((result) => {
        const response: ResultWrapper<DeathAndTpdInsurance> = {
          responseData: result.data,
          status: result.status,
          statusText: result.statusText,
        };
        return response;
      })
      .catch((error) => {
        const response: ResultWrapper<null> = {
          responseData: null,
          status: error.status,
          statusText: error.statusText,
        };
        return response;
      });
    return response;
  }
);

export const fetchIncomeProtectionInsuranceByAccountId = createAsyncThunk(
  InsuranceDetailActionTypes.FetchIncomeProtectionInsuranceByAccountId,
  async (accountId: string) => {
    const queryString = buildEncodedQueryString({
      accountId,
    });
    const response = await api
      .get<IncomeProtectionInsurance>(`${InsuranceDetailApiEndpoints.FetchIncomeProtectionInsurance}${queryString}`)
      .then((result) => {
        const response: ResultWrapper<IncomeProtectionInsurance> = {
          responseData: result.data,
          status: result.status,
          statusText: result.statusText,
        };
        return response;
      })
      .catch((error) => {
        const response: ResultWrapper<null> = {
          responseData: null,
          status: error.status,
          statusText: error.statusText,
        };
        return response;
      });
    return response;
  }
);

export const fetchPremiumDetails = createAsyncThunk(
  InsuranceDetailActionTypes.FetchPremiumDetailsByAccountId,
  async (accountId: string) => {
    const queryString = buildEncodedQueryString({
      accountId,
    });
    const response = await api
      .get<PremiumDetails>(`${InsuranceDetailApiEndpoints.FetchPremiumDetails}${queryString}`)
      .then((result) => {
        const response: ResultWrapper<PremiumDetails> = {
          responseData: result.data,
          status: result.status,
          statusText: result.statusText,
        };
        return response;
      })
      .catch((error) => {
        const response: ResultWrapper<null> = {
          responseData: null,
          status: error.status,
          statusText: error.statusText,
        };
        return response;
      });
    return response;
  }
);

export const fetchMetApplyToken = createAsyncThunk(
  InsuranceDetailActionTypes.FetchMetApplyTokenByClientId,
  async (parameters: ApplyMetlifeDetails) => {
    const queryString = buildEncodedQueryString({
      isSmoker: parameters.isSmoker,
      clientId: parameters.clientId,
      accountId: parameters.accountId,
    });
    const response = await api
      .get<SecureMetApplyToken>(`${InsuranceDetailApiEndpoints.FetchMetApplyToken}${queryString}`)
      .then((result) => {
        const response: ResultWrapper<SecureMetApplyToken> = {
          responseData: result.data,
          status: result.status,
          statusText: result.statusText,
        };
        openMetlifeApplication(response.responseData?.token as string);
        return response;
      })
      .catch((error) => {
        const response: ResultWrapper<SecureMetApplyToken | null> = {
          responseData: error.data,
          status: error.status,
          statusText: error.message,
        };
        return response;
      });
    return response;
  }
);

//
export const fetchAuthorisedContact = createAsyncThunk(
  InsuranceDetailApiEndpoints.FetchAuthorisedContact,
  async (clientId: number) => {
    const queryString = buildEncodedQueryString({
      clientId,
    });
    const response = await api.get<ContactRoles[]>(
      `${InsuranceDetailApiEndpoints.FetchAuthorisedContact}${queryString}`
    );
    const contact = response.data.find((x) => x.isPrimary === true) as ContactRoles;
    return contact;
  }
);

export const launchMetlifeCalculator = createAsyncThunk('', async () => {
  openMetlifeCalculatorApplication();
});

function openMetlifeApplication(tokenValue: string) {
  const winName = 'metlifeWindow';
  const metlifeURL = process.env.REACT_APP_LAUNCH_METLIFE_URL as string;
  const form = document.createElement('form');
  form.setAttribute('method', 'post');
  form.setAttribute('action', metlifeURL);
  form.setAttribute('target', winName);
  const input1 = document.createElement('input');
  input1.type = 'hidden';
  input1.name = 'sess_secureId';
  input1.value = tokenValue;
  form.appendChild(input1);
  const input2 = document.createElement('input');
  input2.type = 'hidden';
  input2.name = 'fundid';
  input2.id = 'fundid';
  input2.value = 'DASH';
  form.appendChild(input2);
  document.body.appendChild(form);
  window.open('', winName);
  form.target = winName;
  form.submit();
  document.body.removeChild(form);
}

function openMetlifeCalculatorApplication() {
  const winName = 'metlifeCalculatorWindow';
  const metlifeURL = process.env.REACT_APP_LAUNCH_METLIFE_CALCULATOR as string;
  window.open(metlifeURL, winName);
}
