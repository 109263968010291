import { Autocomplete, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { AdviserItem, AfslItem, PracticeItem } from '../store/types';

interface Props {
  selectedAfsls: AfslItem[];
  selectedPractices: PracticeItem[];
  selectedAdvisers: AdviserItem[];
  data: AfslItem[];
  setSelectedAfsls: (ids: AfslItem[]) => void;
  setSelectedPractices: (ids: PracticeItem[]) => void;
  setSelectedAdvisers: (ids: AdviserItem[]) => void;
}

export const AfslPracticeAdviserSelect = (props: Props): JSX.Element => {
  const { setSelectedPractices, setSelectedAdvisers, setSelectedAfsls, selectedAfsls, selectedPractices, selectedAdvisers, data } = props;
  const [afsls, setAfsls] = useState<AfslItem[]>([]);
  const [practices, setPractices] = useState<PracticeItem[]>([]);
  const [filteredAdvisers, setFilteredAdvisers] = useState<AdviserItem[]>([]);

  useEffect(() => {
    setPracticesAndAdvisers(selectedAfsls.length == 0 ? data : selectedAfsls);
  }, [data]);

  const setPracticesAndAdvisers = (afslItems: AfslItem[]) => {
    const allPractices = afslItems.length > 0 ? afslItems.map((i) => i.practices).reduce((a, b) => a.concat(b)) : [];
    const allAdvisors = allPractices.length > 0 ? allPractices.map((i) => i.advisors).reduce((a, b) => a.concat(b)) : [];
    setAfsls(afslItems);
    setPractices(allPractices);
    setFilteredAdvisers(allAdvisors);
  };

  const handleAfslChange = (afslsSelected: AfslItem[]) => {
    setAfsls(afslsSelected);
    setSelectedAfsls(afslsSelected);
    setPracticesAndAdvisers(afslsSelected.length == 0 ? data : afslsSelected);
    setSelectedAdvisers([]);
    setSelectedPractices([]);
  };
  const handlePracticeChange = (practices: PracticeItem[]) => {
    setSelectedPractices(practices);
    if (practices.length === 0) {
      practices = afsls.length > 0 ? afsls.map((i) => i.practices).reduce((a, b) => a.concat(b)) : [];
    }
    const validAdvisers =
      practices.length < 1
        ? []
        : practices
            .map((i) => i.advisors)
            .reduce((a, b) => {
              return a.concat(b);
            });

    const validSelections = selectedAdvisers.filter((i) => validAdvisers.includes(i));
    setFilteredAdvisers(validAdvisers);
    setSelectedAdvisers(validSelections);
  };

  const handleAdviserChange = (advisers: AdviserItem[]) => {
    setSelectedAdvisers(advisers);
  };

  return (
    <>
      <Autocomplete
        noOptionsText="No items to select"
        id="afsl-multiselect"
        multiple
        fullWidth
        value={selectedAfsls}
        options={data ?? []}
        getOptionLabel={(option: AfslItem) => option.afslName}
        onChange={(e: React.SyntheticEvent<Element, Event>, value: AfslItem[]) => handleAfslChange(value)}
        renderInput={(params) => <TextField {...params} label="AFSL" margin="normal" variant="outlined" fullWidth />}
      />
      <Autocomplete
        noOptionsText="No items to select"
        id="practice-multiselect"
        multiple
        fullWidth
        value={selectedPractices}
        options={practices ?? []}
        getOptionLabel={(option: PracticeItem) => option.practiceName}
        onChange={(e: React.SyntheticEvent<Element, Event>, value: PracticeItem[]) => handlePracticeChange(value)}
        renderInput={(params) => <TextField {...params} label="Practice" margin="normal" variant="outlined" fullWidth />}
      />

      <Autocomplete
        noOptionsText="No items to select"
        id="adviser-multiselect"
        multiple
        fullWidth
        value={selectedAdvisers}
        options={filteredAdvisers ?? []}
        getOptionLabel={(option: AdviserItem) => option.advisorName}
        onChange={(e: React.SyntheticEvent<Element, Event>, value: AdviserItem[]) => handleAdviserChange(value)}
        renderInput={(params) => <TextField {...params} label="Adviser" margin="normal" variant="outlined" fullWidth />}
      />
    </>
  );
};
