import { FormControlLabel } from '@mui/material';
import { FieldInputProps, useField } from 'formik';
import React from 'react';
import { FormikErrorMessage } from '.';
import { WO2ToggleSwitch } from '../ToggleSwitch';

export interface FormikSwitchProps {
  field: FieldInputProps<never>;
  label: string | undefined;
  disabled: boolean;
  onChange?: (isChecked: boolean) => void;
}

export const FormikSwitch = (props: FormikSwitchProps): JSX.Element => {
  const { label, disabled, onChange } = props;

  const [field, , helpers] = useField(props.field.name);

  return (
    <>
      <FormControlLabel
        label={label ?? ''}
        control={
          <WO2ToggleSwitch
            type="checkbox"
            placeholder={props.label}
            {...field}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              helpers.setValue(event.target.checked);
              if (onChange) {
                onChange(event.target.checked);
              }
            }}
            onBlur={field.onBlur}
            disabled={disabled || false}
            checked={!!field.value}
            value={field.value}
            color={'primary'}
          />
        }
      ></FormControlLabel>
      <FormikErrorMessage name={field.name}></FormikErrorMessage>
    </>
  );
};
