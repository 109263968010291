import { Typography } from '@mui/material';
import React from 'react';
import { RoleDefinition } from 'src/features/clients/common/enums';
import { ClientSideDataTable } from '../../../../../common/components/dataTable/clientSide';
import { DatatableColumn } from '../../../../../common/components/dataTable/types';
import { identificationDocumentPoints } from '../../../client/common/store/types';
import { ContactInfoSummary } from '../../store/types';

export interface ContactSummaryTableProps {
  data: ContactInfoSummary[];
  hideColumns?: string[];
}

export const ContactSummaryTable = (props: ContactSummaryTableProps): JSX.Element => {
  const { data, hideColumns } = props;

  const nameColumn = (dataIndex: number): React.ReactNode => {
    return <Typography variant="h5">{data[dataIndex]?.name}</Typography>;
  };

  const rolesColumn = (dataIndex: number): React.ReactNode => {
    return <Typography variant="h5">{data[dataIndex]?.roles?.map((r) => RoleDefinition.getById(r.roleId)?.name).join(', ') ?? ''}</Typography>;
  };

  const pointsColumn = (dataIndex: number): React.ReactNode => {
    const idPoints = data[dataIndex]?.documentTypeIds.reduce((acc: number, currentId: number) => {
      return acc + (identificationDocumentPoints.find((documentPoint) => documentPoint.id === currentId)?.points || 0);
    }, 0);

    return (
      <Typography variant="h5" style={{ textAlign: 'right' }}>
        {data[dataIndex]?.name?.length > 0 && !!idPoints ? idPoints : ''}
      </Typography>
    );
  };

  let columns: DatatableColumn[] = [
    {
      name: 'name',
      label: 'NAME',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex): React.ReactNode => nameColumn(dataIndex),
        setCellProps: () => {
          return {
            style: {
              borderBottom: 'none',
              width: '33.33%',
            },
          };
        },
      },
    },
    {
      name: 'roles',
      label: 'ROLES',
      textAlign: 'left',
      options: {
        filter: false,
        sort: true,
        customBodyRenderLite: (dataIndex): React.ReactNode => rolesColumn(dataIndex),
        setCellProps: () => {
          return {
            style: {
              borderBottom: 'none',
              width: '33.33%',
            },
          };
        },
      },
    },
    {
      name: 'points',
      label: 'ID POINTS',
      textAlign: 'right',
      options: {
        filter: false,
        sort: true,
        customBodyRenderLite: (dataIndex): React.ReactNode => pointsColumn(dataIndex),
      },
    },
  ];

  // remove columns needs to be hidden (exclude actions)
  if (!!hideColumns && hideColumns.length > 0) {
    columns = columns.filter(
      (column: DatatableColumn) => hideColumns.map((hideColumn: string) => hideColumn.toLowerCase()).indexOf(column.name.toLowerCase()) === -1
    );
  }

  return (
    <ClientSideDataTable
      loadingProgress={{ isLoading: false, hasErrors: false }}
      columns={columns}
      data={data}
      options={{
        filter: false,
      }}
    ></ClientSideDataTable>
  );
};
