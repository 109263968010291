import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { selectIsDataFromTriumph } from 'src/store/selectors';
import { progressSelectorFactory } from '../../../common';
import { RootState } from '../../../reducers';
import { selectSecurityDetails } from '../common/store/selectors';
import { DetailsComponent } from './components/DetailsComponent';
import { selectConsolidatedSecurityHoldingsSummary } from './store/selectors';
import { securityDetailsSlice } from './store/slice';
import {
  fetchConsolidatedSecurityHoldingsSummary,
  fetchSecurityDetails,
  SecurityDetailsActionTypes,
} from './store/thunks';

const mapStateToProps = (state: RootState) => ({
  securityDetails: selectSecurityDetails(state),
  consolidatedSecurityHoldingsSummary: selectConsolidatedSecurityHoldingsSummary(state),
  securityDetailsProgress: progressSelectorFactory([SecurityDetailsActionTypes.FetchSecurityDetails])(state),
  consolidatedSecurityHoldingsSummaryProgress: progressSelectorFactory([
    SecurityDetailsActionTypes.FetchConsolidatedSecurityHoldingsSummary,
  ])(state),
  isDataFromTriumph: selectIsDataFromTriumph(state),
});

const mapDispatchToProps = {
  ...securityDetailsSlice.actions,
  fetchConsolidatedSecurityHoldingsSummary,
  fetchSecurityDetails,
};

interface MatchParams {
  securityId?: string;
  securityCode?: string;
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector> & RouteComponentProps<MatchParams>;
export const SecurityDetailsStateContainer = connect(mapStateToProps, mapDispatchToProps)(DetailsComponent);
