import { combineReducers } from '@reduxjs/toolkit';
import { workflowListSlice } from '../list/store/slice';
import { WorkflowListState } from '../list/store/types';

export interface WorkflowsState {
  list: WorkflowListState;
}

export const workflowsReducer = combineReducers<WorkflowsState>({
  list: workflowListSlice.reducer,
});
