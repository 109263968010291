import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { CorporateType } from 'src/features/clients/common/enums';
import { ClientAccountSubType, ClientAccountType, PensionType } from '../../../../../common/types';
import { TrusteeType, TrustType } from '../../../client/details/mainDetails/store';
import { AccountTypeValues } from '../../store/types';

interface AccountTypeHeaderProps {
  accountTypeValues: AccountTypeValues;
}

export const useStyles = makeStyles(() => ({
  legend: {
    fontFamily: 'AvenirMedium',
  },
  gridContainer: {
    margin: '20px 0 20px',
  },
  gridItem: {
    marginRight: '20px !important',
  },
  card: {
    borderRadius: '4px !important',
  },
  cardContent: {
    padding: '12px 16px !important',
    textAlign: 'left',
    '& h2': {
      fontWeight: 600,
      marginBottom: '8px',
    },
  },
}));

export const AccountTypeHeader = (props: AccountTypeHeaderProps): JSX.Element => {
  const { accountTypeValues } = props;
  const classes = useStyles();

  return (
    <Box>
      <Typography variant="h8">Adviser and account information</Typography>
      <Grid container className={classes.gridContainer}>
        <Grid item className={classes.gridItem}>
          <Card variant="outlined" className={classes.card}>
            <CardContent
              classes={{
                root: classes.cardContent,
              }}
            >
              <Typography variant="h5" component="h2">
                Adviser
              </Typography>
              <Typography variant="h5" component="p" noWrap>
                {accountTypeValues.advisor?.advisorName}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item className={classes.gridItem}>
          <Card variant="outlined" className={classes.card}>
            <CardContent
              classes={{
                root: classes.cardContent,
              }}
            >
              <Typography variant="h5" component="h2">
                Account type
              </Typography>
              <Typography variant="h5" component="p" noWrap>
                {accountTypeValues.clientAccount.accountTypeId === ClientAccountType.Individual.id
                  ? accountTypeValues.clientAccount.subTypeId === ClientAccountSubType.NonSuper.id
                    ? ClientAccountType.Individual.displayName
                    : ClientAccountSubType.getById(accountTypeValues.clientAccount.subTypeId)?.displayName
                  : ClientAccountType.getById(accountTypeValues.clientAccount.accountTypeId)?.displayName}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        {accountTypeValues.clientAccount.accountTypeId === ClientAccountType.Company.id && (
          <Grid item className={classes.gridItem}>
            <Card variant="outlined" className={classes.card}>
              <CardContent
                classes={{
                  root: classes.cardContent,
                }}
              >
                <Typography variant="h5" component="h2">
                  Company type
                </Typography>
                <Typography variant="h5" component="p" noWrap>
                  {CorporateType.getById(accountTypeValues.clientAccount.corporateTypeId)?.displayName}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        )}
        {accountTypeValues.clientAccount.accountTypeId === ClientAccountType.Individual.id &&
          accountTypeValues.clientAccount.subTypeId === ClientAccountSubType.Pension.id && (
            <Grid item className={classes.gridItem}>
              <Card variant="outlined" className={classes.card}>
                <CardContent
                  classes={{
                    root: classes.cardContent,
                  }}
                >
                  <Typography variant="h5" component="h2">
                    Pension type
                  </Typography>
                  <Typography variant="h5" component="p" noWrap>
                    {PensionType.getById(accountTypeValues.superSimplifierDetails?.pensionTypeId || null)?.displayName}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          )}
        {(accountTypeValues.clientAccount.accountTypeId === ClientAccountType.Smsf.id ||
          accountTypeValues.clientAccount.accountTypeId === ClientAccountType.Trust.id) && (
          <>
            <Grid item className={classes.gridItem}>
              <Card variant="outlined" className={classes.card}>
                <CardContent
                  classes={{
                    root: classes.cardContent,
                  }}
                >
                  <Typography variant="h5" component="h2">
                    Trustee type
                  </Typography>
                  <Typography variant="h5" component="p" noWrap>
                    {TrusteeType.getById(accountTypeValues.clientAccount.trusteeTypeId)?.displayName}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item className={classes.gridItem}>
              <Card variant="outlined" className={classes.card}>
                <CardContent
                  classes={{
                    root: classes.cardContent,
                  }}
                >
                  <Typography variant="h5" component="h2">
                    Type of trust
                  </Typography>
                  <Typography variant="h5" component="p" noWrap>
                    {TrustType.getById(accountTypeValues.clientAccount.typeOfTrustId)?.displayName}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};
