import {
  Alert,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React from 'react';
import { DateTimeFormat, formatNumberCommaSeparated, getLocalDateTime } from 'src/common';
import { ResultWrapper } from '../../benefitStatement/store/types';
import { BluedoorPaymentFrequency, PensionDetail } from '../store/types';

export interface Props {
  detail: ResultWrapper<PensionDetail | null>;
}

export const PensionDetails = ({ detail }: Props): JSX.Element => {
  let paymentFrequency = '';

  if (detail.status === 200 && detail?.responseData?.frequency) {
    const { frequency } = detail?.responseData;
    switch (frequency) {
      case BluedoorPaymentFrequency.Fortnightly: {
        paymentFrequency = 'Fortnightly';
        break;
      }
      case BluedoorPaymentFrequency.Monthly: {
        paymentFrequency = 'Monthly';
        break;
      }
      case BluedoorPaymentFrequency.Quarterly: {
        paymentFrequency = 'Quarterly';
        break;
      }
      case BluedoorPaymentFrequency.HalfYearly: {
        paymentFrequency = 'Half-Yearly';
        break;
      }
      case BluedoorPaymentFrequency.Yearly: {
        paymentFrequency = 'Yearly';
        break;
      }
      default: {
        paymentFrequency = '';
      }
    }
  }

  return (
    <>
      <Typography variant="h4" style={{ paddingBottom: '20px' }}>
        Pension Details
      </Typography>
      {detail.status === 200 ? (
        <Container sx={{px: 0, marginBottom: '30px'}} disableGutters>
          <Paper elevation={5}>
            <TableContainer component={Paper} elevation={0}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="left" style={{ width: '70%' }}>
                      <Typography variant="h6" color="textSecondary">
                        DESCRIPTION
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography variant="h6" color="textSecondary">
                        VALUE
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="left">
                      <Typography variant="h5">Pension Type</Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography data-testid="pensionDetailsPensionType" variant="h5">
                        {detail?.responseData?.pensionType}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left">
                      <Typography variant="h5">Payment Option</Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography data-testid="pensionDetailsReviewOption" variant="h5">
                        {detail?.responseData?.reviewOption}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left">
                      <Typography variant="h5">Payment Amount</Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography data-testid="pensionDetailsPaymentAmount" variant="h5">
                        ${`${formatNumberCommaSeparated(detail?.responseData?.paymentAmount || 0, 2)}`}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left">
                      <Typography variant="h5">&nbsp;</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left">
                      <Typography variant="h5">Next Payment Date</Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography data-testid="pensionDetailsNextPaymentDate" variant="h5">
                        {getLocalDateTime(detail?.responseData?.nextPaymentDate, DateTimeFormat.Short)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left">
                      <Typography variant="h5">Payment Amount</Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography data-testid="pensionDetailsPaymentAmountNext" variant="h5">
                        ${`${formatNumberCommaSeparated(detail?.responseData?.paymentAmount || 0, 2)}`}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left">
                      <Typography variant="h5">Payment Frequency</Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography data-testid="pensionDetailsFrequency" variant="h5">
                        {paymentFrequency}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left">
                      <Typography variant="h5">Total Paid Financial Year to Date</Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography data-testid="pensionDetailsTotalPeriodicPension" variant="h5">
                        ${`${formatNumberCommaSeparated(detail?.responseData?.totalPeriodicPension || 0, 2)}`}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left">
                      <Typography variant="h5">&nbsp;</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left">
                      <Typography variant="h5">Minimum Payment *</Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography data-testid="pensionDetailsMinimumPension" variant="h5">
                        ${`${formatNumberCommaSeparated(detail?.responseData?.minimumPension || 0, 2)}`}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left">
                      <Typography variant="h5">Maximum Payment *</Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography data-testid="pensionDetailsMaximumPension" variant="h5">
                        {detail?.responseData?.maximumPension !== undefined
                          ? `$${formatNumberCommaSeparated(detail?.responseData?.maximumPension || 0, 2)}`
                          : '$-'}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          <Typography style={{marginTop: '10px'}}>*Pro-rated for accounts opened in the current financial year.</Typography>
        </Container>
      ) : (
        <Alert
          style={{ marginRight: '20px', marginTop: '20px', marginBottom: '20px' }}
          variant="outlined"
          severity="info"
        >
          <Typography>
            There is currently no Pension information for this member. If the member has been recently established it
            may take a few days for this data to be populated.
          </Typography>
        </Alert>
      )}
    </>
  );
};
