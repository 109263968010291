import { Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import React from 'react';
import { formatDollars } from '../../../../../common';
import { PreapprovalGroupedTrades, PreApprovalTrade } from '../store/types';
import PreapprovedTrade from './preapprovedTrade';

interface Props {
  groupedTrades: PreapprovalGroupedTrades;
  hasRebalanceEnhancedTrading: boolean;
  hasClientEditAdminOnlyPermission: boolean;
}

export default function PreapprovedTrades(props: Props): JSX.Element {
  const { groupedTrades, hasRebalanceEnhancedTrading, hasClientEditAdminOnlyPermission } = props;

  return (
    <Paper elevation={0} style={{ marginBottom: '40px', overflow: 'auto' }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell variant={'body'} style={{ padding: '0', paddingLeft: '10px', width: '40%' }}>
              <Typography variant="h3" color={'primary'}>
                {groupedTrades.title}
              </Typography>
            </TableCell>
            <TableCell variant={'body'} align="left"></TableCell>
            <TableCell variant={'body'} align="left"></TableCell>
            {(hasRebalanceEnhancedTrading || hasClientEditAdminOnlyPermission) && <TableCell variant={'body'} align="left"></TableCell>}
            {(hasRebalanceEnhancedTrading || hasClientEditAdminOnlyPermission) && <TableCell variant={'body'} align="left"></TableCell>}
            {(hasRebalanceEnhancedTrading || hasClientEditAdminOnlyPermission) && <TableCell variant={'body'} align="left"></TableCell>}
            <TableCell variant={'body'} align="right">
              <Typography>{formatDollars(groupedTrades.totalCalculatedValue)}</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant={'head'}>
              <Typography variant="h6" color="textSecondary">
                NAME
              </Typography>
            </TableCell>
            <TableCell variant={'head'} align="left">
              <Typography variant="h6" color="textSecondary">
                UNITS & PRICE
              </Typography>
            </TableCell>
            <TableCell variant={'head'} align="center">
              <Typography variant="h6" color="textSecondary">
                BUY/SELL
              </Typography>
            </TableCell>
            <TableCell variant={'head'}>
              <Typography variant="h6" color="textSecondary">
                ORDER TYPE
              </Typography>
            </TableCell>
            <TableCell variant={'head'}>
              <Typography variant="h6" color="textSecondary">
                EXPIRY
              </Typography>
            </TableCell>
            <TableCell variant={'head'}>
              <Typography variant="h6" color="textSecondary">
                EXPIRY DATE
              </Typography>
            </TableCell>
            <TableCell variant={'head'} align="right">
              <Typography variant="h6" color="textSecondary">
                TOTAL
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {groupedTrades.trades.map((trade: PreApprovalTrade, index: number) => (
            <PreapprovedTrade key={index} trade={trade} index={index} hasRebalanceEnhancedTrading hasClientEditAdminOnlyPermission />
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
}
