import { Search as SearchIcon } from '@mui/icons-material';
import { Box, Container, Grid } from '@mui/material';
import 'date-fns';
import React, { useCallback, useEffect } from 'react';
import DateRangePicker from '../../../../../common/components/DateRangePicker';
import PortfolioSelect from '../../common/components/PortfolioSelect';
import { Props } from '../container';
import { GainType } from '../store/types';
import { WO2TextInput } from './../../../../../common';
import WO2Button from './../../../../../common/components/button/Button';
import CostBaseCard from './CostBaseCard';
import GainLossPercentageCard from './GainLossPercentageCard';
import GainLossValueCard from './GainLossValueCard';
import GainTypeSelect from './GainTypeSelect';
import GroupingSelect from './GroupingSelect';
import GainsLossesTable from './TableComponent';
import TotalValueCard from './TotalValueCard';

export const GainsLosses = (props: Props): JSX.Element => {
  const {
    loadingRealisedProgress,
    loadingUnrealisedProgress,
    parameters,
    fetchRealised,
    fetchUnrealised,
    actions,
    clientPortfolios,
    results,
  } = props;

  function onSearchClicked() {
    if (isRealisedGainType()) {
      fetchRealised();
    } else {
      fetchUnrealised();
    }
  }

  const fetch = useCallback(() => {
    if (parameters?.gainType === GainType.Realised) {
      fetchRealised();
    } else {
      fetchUnrealised();
    }
  }, [parameters, fetchRealised, fetchUnrealised]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  function isRealisedGainType() {
    return parameters?.gainType === GainType.Realised;
  }

  return (
    <Container style={{ paddingTop: '20px', paddingBottom: '20px' }}>
      <Box display={'flex'} justifyContent={'space-between'}>
        <Box paddingRight={'10px'}>
          <PortfolioSelect
            items={clientPortfolios}
            selectedPortfolio={parameters.selectedPortfolio}
            setPortfolio={actions.setPortfolioParameter}
          />
        </Box>
        <Box display={'flex'} justifyContent={'space-between'}>
          <Box paddingRight={'10px'}>
            <GainTypeSelect selectedItem={parameters?.gainType} setSelectedItem={actions.setGainTypeParameter} />
          </Box>
          <DateRangePicker
            dateRange={parameters?.dates}
            setDateRange={actions.setDateParameter}
            isDateOnly={!isRealisedGainType()}
          />
          <Box paddingRight={'10px'}>
            <GroupingSelect
              selectedItem={parameters?.groupingType}
              setSelectedItem={actions.setGroupingTypeParameter}
            />
          </Box>
          <Box display={'flex'} justifyContent={'flex-end'} style={{ marginRight: '10px' }}>
            <WO2TextInput
              placeholder={'Filter'}
              inputProps={{ 'aria-label': 'search' }}
              value={parameters.filter}
              InputProps={{
                startAdornment: <SearchIcon style={{ margin: '0 10px' }}>search</SearchIcon>,
              }}
              onChange={(event) => {
                actions.setFilterParameter(event.target.value);
              }}
            />
          </Box>
          <Box>
            <WO2Button
              color={'primary'}
              disableElevation
              onClick={onSearchClicked}
              variant={'contained'}
              value="Search"
            >
              Search
            </WO2Button>
          </Box>
        </Box>
      </Box>
      <Grid container spacing={2} style={{ paddingTop: '20px', paddingBottom: '20px' }}>
        <Grid item xs={3}>
          <TotalValueCard
            isLoading={loadingRealisedProgress.isLoading || loadingUnrealisedProgress.isLoading}
            hasErrors={loadingRealisedProgress.hasErrors || loadingUnrealisedProgress.hasErrors}
          />
        </Grid>
        <Grid item xs={3}>
          <CostBaseCard
            isLoading={loadingRealisedProgress.isLoading || loadingUnrealisedProgress.isLoading}
            hasErrors={loadingRealisedProgress.hasErrors || loadingUnrealisedProgress.hasErrors}
          />
        </Grid>
        <Grid item xs={3}>
          <GainLossValueCard
            isLoading={loadingRealisedProgress.isLoading || loadingUnrealisedProgress.isLoading}
            hasErrors={loadingRealisedProgress.hasErrors || loadingUnrealisedProgress.hasErrors}
          />
        </Grid>
        <Grid item xs={3}>
          <GainLossPercentageCard
            isLoading={loadingRealisedProgress.isLoading || loadingUnrealisedProgress.isLoading}
            hasErrors={loadingRealisedProgress.hasErrors || loadingUnrealisedProgress.hasErrors}
          />
        </Grid>
        <Grid item xs={12}>
          <GainsLossesTable
            groupBy={parameters.groupingType}
            isLoading={loadingRealisedProgress.isLoading || loadingUnrealisedProgress.isLoading}
            hasErrors={loadingRealisedProgress.hasErrors || loadingUnrealisedProgress.hasErrors}
            results={results ?? []}
            isRealisedGainType={isRealisedGainType()}
          />
        </Grid>
      </Grid>
    </Container>
  );
};
