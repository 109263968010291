import { ArrowBackOutlined } from '@mui/icons-material';
import { Breadcrumbs, Container, Grid, Link, Paper } from '@mui/material';
import 'date-fns';
import React, { useEffect } from 'react';
import { Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import ProtectedRoute from 'src/ProtectedRoute';
import { PortfolioVersionSelect } from '../../components/PortfolioVersionSelect';
import { Props } from '../container';
import { investmentsRoutes } from '../routes';
import { LeftMenu } from './components';
import { VersionActivator } from './components/versionActivator';

export const Configuration = ({
  history,
  fetchInvestmentServices,
  fetchInvestmentServiceVersions,
  fetchSecurities,
  setInvestmentServiceVersion,
  investmentServicesWithVersionsGrouped,
  investmentService,
  fetchApprovedProducts,
  activateInvestmentServiceVersion,
  currentInvestmentServiceVersionId,
  activationValidationMessages,
  activateInvestmentServiceVersionProgress,
  clearValidationMessages,
  activationStatus,
  clientDetails,
  afslId,
  hasClientEditAdminOnlyPermission
}: Props): JSX.Element => {
  const { path } = useRouteMatch();
  const { pathname } = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      if (!!clientDetails) {
        await fetchInvestmentServices({ clientId: clientDetails.clientId });
        await fetchInvestmentServiceVersions({ clientId: clientDetails.clientId });
        afslId && (await fetchApprovedProducts({ afslId, subTypeId: clientDetails.subTypeId }));
      }
    };
    fetchData();
  }, [afslId, fetchInvestmentServices, fetchInvestmentServiceVersions, clientDetails]);

  useEffect(() => {
    !!clientDetails && fetchSecurities('');
  }, [clientDetails, fetchSecurities]);

  return (
    <Container style={{ paddingTop: '20px' }}>
      <Grid container direction="row" spacing={3}>
        <Grid item={true} xs={3}>
          <Breadcrumbs aria-label="breadcrumb" style={{ paddingBottom: '20px' }}>
            <Link
              color="primary"
              onClick={() => {
                history.push(`../overview`);
              }}
              style={{ textDecoration: 'none' }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                }}
              >
                <ArrowBackOutlined />
                <span style={{ paddingLeft: '10px', paddingTop: '1px' }}>Back to dashboard</span>
              </div>
            </Link>
          </Breadcrumbs>
          <Paper elevation={0}>
            <LeftMenu routes={investmentsRoutes} />
          </Paper>
        </Grid>
        <Grid item={true} xs={9} style={{ paddingBottom: '20px' }}>
          <Grid container style={{ paddingBottom: '10px' }} spacing={3}>
            <Grid item={true} xs={12} container justifyContent="flex-end">
              <PortfolioVersionSelect
                items={investmentServicesWithVersionsGrouped}
                selectedInvestmentServiceVersion={investmentService || investmentServicesWithVersionsGrouped[0]}
                setInvestmentServiceId={(investmentServiceId) => {
                  const investmentService = investmentServicesWithVersionsGrouped.find((g) => g.investmentServiceId === investmentServiceId);

                  if (!!investmentService) {
                    setInvestmentServiceVersion({
                      investmentServiceId,
                      investmentServiceVersionId: investmentService.versions[investmentService.versions.length - 1].investmentServiceVersionId,
                    });
                  }
                }}
                optionForAll={false}
                hasClientEditAdminOnlyPermission={hasClientEditAdminOnlyPermission}
              />
            </Grid>
            <Grid item={true} xs={12} container justifyContent="flex-end" style={{ paddingTop: '0px' }}>
              {!!investmentService && (
                <VersionActivator
                  investmentService={investmentService}
                  fetchValidationMessages={async () => {
                    if (!!clientDetails && !!currentInvestmentServiceVersionId) {
                      await activateInvestmentServiceVersion({
                        clientId: clientDetails.clientId,
                        investmentServiceVersionId: currentInvestmentServiceVersionId,
                      });
                    }
                  }}
                  activationValidationMessages={activationValidationMessages}
                  progress={activateInvestmentServiceVersionProgress}
                  clearValidationMessages={() => clearValidationMessages()}
                  activationStatus={activationStatus}
                />
              )}
            </Grid>
          </Grid>
          <Paper elevation={0} style={{ padding: '20px', paddingTop: '0px' }}>
            <Switch>
              <Route exact path={`${path}/`} render={() => <Redirect to={`${pathname}/details`} />} />
              {investmentsRoutes.map((r) => (
                <ProtectedRoute key={r.menuIndex} path={`${path}/${r.path}`} component={r.component} />
              ))}
            </Switch>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};
