import { connect, ConnectedProps } from 'react-redux';
import { progressSelectorFactory } from 'src/common/store/selectors/loadingProgressSelector';
import { RootState } from 'src/store';
import {
  selectHasClientEditAdminOnly,
  selectHasRebalanceEnhancedTrading,
  selectIsRebalanceDataSourceTriumph,
} from 'src/store/selectors';
import { Edit } from './components/edit';
import {
  selectBulkOrder,
  selectEditResults,
  selectInvestmentServicesLockedByOrders,
  selectIsPreApproved,
  selectParameters,
  selectPortfolioIds,
  selectPreApprovalInvestmentServices,
  selectSecurities,
  selectSecurityHoldings,
  selectValidationMessages,
} from './store/selectors';
import { editSlice } from './store/slice';
import { fetchPortfolioSecurityHoldings, OrderEditActionTypes, validateBulkOrder } from './store/thunks';

const mapStateToProps = (state: RootState) => ({
  parameters: selectParameters(state),
  portfolioIds: selectPortfolioIds(state),
  securities: selectSecurities(state),
  results: selectEditResults(state),
  securityHoldings: selectSecurityHoldings(state),
  validationMessages: selectValidationMessages(state),
  isPreApproved: selectIsPreApproved(state),
  bulkOrder: selectBulkOrder(state),
  preApprovalInvestmentServices: selectPreApprovalInvestmentServices(state),
  investmentServicesLockedByOrders: selectInvestmentServicesLockedByOrders(state),
  isLoading: progressSelectorFactory([
    OrderEditActionTypes.FetchPortfolioSecurityHoldings,
    OrderEditActionTypes.ValidateBulkOrder,
  ])(state),
  hasRebalanceEnhancedTrading: selectHasRebalanceEnhancedTrading(state),
  hasClientEditAdminOnlyPermission: selectHasClientEditAdminOnly(state),
  isRebalanceDataFromTriumph: selectIsRebalanceDataSourceTriumph(state),
});

const mapDispatchToProps = {
  ...editSlice.actions,
  fetchPortfolioSecurityHoldings,
  validateBulkOrder,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector>;
export const EditContainer = connector(Edit);
