import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { selectHasClientEditAdminOnly } from 'src/store/selectors';
import { progressSelectorFactory } from '../../../common';
import { RootState } from '../../../reducers';
import { fetchRoles } from '../client/common/store/thunks';
import { Onboard } from './components';
import { selectPrevNextRoutePaths } from './store/routeSelectors';
import {
  selectAccountTypeValues,
  selectAddressItems,
  selectAdviceFeeEdit,
  selectAdviceFeesValues,
  selectAdvisor,
  selectAfslAuthorisation,
  selectClient,
  selectEstimatedFeesItems,
  selectStandardFees,
  selectSuperSimplifierDetailsValues,
  selectTieredFeeDetailsEdit,
  selectTieredFeeDetailsItems,
} from './store/selectors';
import { onboardSlice } from './store/slice';
import { fetchAfslAuthorisation, fetchOnboarding, OnboardActionTypes, saveOnboarding } from './store/thunks';

const mapStateToProps = (state: RootState, routeComponentProps: RouteComponentProps) => ({
  clientObject: selectClient(state),
  loadingClientProgress: progressSelectorFactory([OnboardActionTypes.FetchOnboarding])(state),
  prevNextRoutePaths: selectPrevNextRoutePaths(state, routeComponentProps.location.pathname),
  accountTypeValues: selectAccountTypeValues(state),
  afslAuthorisation: selectAfslAuthorisation(state),
  superSimplifierDetailsValues: selectSuperSimplifierDetailsValues(state),
  adviceFeesValues: selectAdviceFeesValues(state),
  selectedAdvisor: selectAdvisor(state),
  estimatedFeesItems: selectEstimatedFeesItems(state),
  selectedFeeItem: selectAdviceFeeEdit(state),
  selectedTieredFeeDetailsEdit: selectTieredFeeDetailsEdit(state),
  tieredFeeDetailsItems: selectTieredFeeDetailsItems(state),
  standardFees: selectStandardFees(state),
  addressesValues: selectAddressItems(state),
  loadingStandardFeesProgress: progressSelectorFactory([OnboardActionTypes.FetchFeesForAdviser])(state),
  savingProgress: progressSelectorFactory([OnboardActionTypes.SaveOnboarding])(state),
  hasClientEditAdminOnlyPermission: selectHasClientEditAdminOnly(state),
});

const mapDispatchToProps = {
  fetchOnboarding,
  fetchAfslAuthorisation,
  fetchRoles,
  saveOnboarding,
  ...onboardSlice.actions,
};

interface MatchParams {
  id?: string;
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector> & RouteComponentProps<MatchParams>;
export const OnboardContainer = connect(mapStateToProps, mapDispatchToProps)(Onboard);
