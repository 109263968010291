import { connect, ConnectedProps } from 'react-redux';
import { progressSelectorFactory } from '../../../../../../common/store/selectors/loadingProgressSelector';
import { RootState } from '../../../../../../store';
import { selectClientId } from '../../../common/store/selectors';
import { fetchConstraintsAndExcludeRebalance } from '../constraints/store';
import { fetchInvestmentService } from '../details/store';
import { ArchiveActionTypes, archivePortfolio } from '../store/archive';
import { fetchConfigurations, fetchStrategicAllocations } from '../targets/store';
import { CommonActionTypes, fetchPortfolioAssetClasses, selectAssetClasses, selectInvestmentService } from './../../store/common';
import { Review } from './components/review';

const mapStateToProps = (state: RootState) => ({
  assetClasses: selectAssetClasses(state),
  clientId: selectClientId(state),
  investmentService: selectInvestmentService(state),
  loadingProgress: progressSelectorFactory([CommonActionTypes.FetchInvestmentServices, CommonActionTypes.FetchInvestmentServiceVersions])(state),
  archivingProgress: progressSelectorFactory([ArchiveActionTypes.ArchivePortfolio])(state),
});

const mapDispatchToProps = {
  fetchConfigurations,
  fetchStrategicAllocations,
  fetchConstraintsAndExcludeRebalance,
  fetchInvestmentService,
  fetchPortfolioAssetClasses,
  archivePortfolio,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector>;
export const ReviewContainer = connector(Review);
