import React from 'react';
import { Container } from '@mui/material';
import { DateTimeFormat, getLocalDateTimeNow } from '../../../../../common';

const PdfFooter = () => {
  return (
    <Container>
      <div style={{ fontFamily: 'AvenirMedium', lineHeight: 1.167, fontSize: '14px', fontWeight: 'normal' }}>
        Disclaimer: DASH nor any of its directors, authorised representatives, employees, or agents, makes any
        representation or warranty as to the reliability, accuracy, or completeness of this document. Nor do they accept
        any liability or responsibility arising in any way, including negligence, for errors in, or omissions from this
        document.
      </div>
      <div
        style={{
          fontFamily: 'AvenirMedium',
          lineHeight: 1.167,
          fontSize: '14px',
          fontWeight: 'normal',
          paddingTop: '20px',
        }}
      >
        Created on {getLocalDateTimeNow(DateTimeFormat.DateTime)}
      </div>
    </Container>
  );
};

export default PdfFooter;
