import { Enumeration } from './enumeration';

export class ClientAccountStatus extends Enumeration {
  static Unknown = new ClientAccountStatus(0, 'Unknown', 'Unknown');
  static Pending = new ClientAccountStatus(1, 'Pending', 'Pending');
  static Active = new ClientAccountStatus(2, 'Active', 'Active');
  static WaitingForApproval = new ClientAccountStatus(4, 'WaitingForApproval', 'WaitingForApproval');

  static getArray = (): ClientAccountStatus[] => [
    ClientAccountStatus.Unknown,
    ClientAccountStatus.Pending,
    ClientAccountStatus.Active,
    ClientAccountStatus.WaitingForApproval,
  ];

  static getById = (id: number): ClientAccountStatus | undefined => {
    return Object.values(ClientAccountStatus)
      .filter((t) => t instanceof ClientAccountStatus)
      .find((t: ClientAccountStatus) => t.id === id);
  };
}
