import { createSelector } from '@reduxjs/toolkit';
import { SecurityState } from '.';
import { RootState } from '../../../reducers';

export const selectSecurity = createSelector(
  (state: RootState) => state,
  (state) => state.security as SecurityState
);

export const selectSecurityIncomeState = createSelector(selectSecurity, (securityState) => securityState.income);

export const selectSecurityPrice = createSelector(selectSecurity, (securityState) => securityState.price);
