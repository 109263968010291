import { Search as SearchIcon, Settings } from '@mui/icons-material';
import { Box, Container, Grid, Stack, Typography } from '@mui/material';
import 'date-fns';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { WO2TextInput } from 'src/common';
import DateRangePicker from 'src/common/components/DateRangePicker';
import WO2Button from '../../../../../../common/components/button/Button';
import { Props } from '../container';
import { GainType, RealisedGainsAndLossesRequest, UnrealisedGainsAndLossesRequest } from '../store/types';
import CapitalRealisedGainsLossesTable from './CapitalRealisedGainsLossesTable';
import CapitalUnrealisedGainsLossesTable from './CapitalUnrealisedGainsLossesTable';
import CostBaseCard from './CostBaseCard';
import GainLossPercentageCard from './GainLossPercentageCard';
import GainLossValueCard from './GainLossValueCard';
import GainTypeSelect from './GainTypeSelect';
import TotalValueCard from './TotalValueCard';
import ArrowDropDownSharpIcon from '@mui/icons-material/ArrowDropDownSharp';
import GridOnIcon from '@mui/icons-material/GridOn';
import { WO2Menu } from 'src/common/components/Menu';
import { usePDF } from 'react-to-pdf';
import PdfFooter from '../../../common/components/PdfFooter';
import PdfHeader from '../../../common/components/PdfHeader';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';

export const CapitalGainsLosses = (props: Props): JSX.Element => {
  const {
    history,
    loadingRealisedProgress,
    loadingUnrealisedProgress,
    parameters,
    clientId,
    fetchRealisedCapitalGainsAndLosses,
    fetchUnrealisedCapitalGainsAndLosses,
    realisedResults,
    unrealisedResults,
    setDateParameter,
    setGainTypeParameter,
    setFilterParameter,
    entityDetails,
  } = props;

  const [showPdfFooterAndHeader, setShowPdfFooterAndHeader] = useState(false);

  const [generateUnrealisedCsv, setGenerateUnrealisedCsv] = useState(false);
  const [generateRealisedCsv, setGenerateRealisedCsv] = useState(false);

  function isRealisedGainType() {
    return parameters?.gainType === GainType.Realised;
  }

  const [realisedGainType, setRealisedGainType] = useState('Unrealised');

  useEffect(() => {
    if (isRealisedGainType()) {
      setRealisedGainType('Realised');
    } else {
      setRealisedGainType('Unrealised');
    }
  }, [isRealisedGainType()]);

  const pdfFileName = (entityDetails?.name ?? '') + '_' + realisedGainType + '_Capital_Gains_and_Losses';

  const { toPDF, targetRef } = usePDF({
    filename: pdfFileName,
  });

  useEffect(() => {
    if (showPdfFooterAndHeader) {
      toPDF();
      setShowPdfFooterAndHeader(false);
    }
  }, [showPdfFooterAndHeader]);

  const generatePdf = () => {
    setShowPdfFooterAndHeader(true);
  };

  useEffect(() => {
    if (generateUnrealisedCsv) {
      setGenerateUnrealisedCsv(false);
    }
  }, [generateUnrealisedCsv]);

  const generateUnrealisedCsvClick = () => {
    setGenerateUnrealisedCsv(true);
  };

  useEffect(() => {
    if (generateRealisedCsv) {
      setGenerateRealisedCsv(false);
    }
  }, [generateRealisedCsv]);

  const generateRealisedCsvClick = () => {
    setGenerateRealisedCsv(true);
  };

  const location = useLocation();
  const locationClientId = location.pathname.split('/')[2];

  useEffect(() => {
    fetchRealisedCapitalGainsAndLosses({
      clientId: clientId ?? locationClientId,
      dateFrom: parameters.dates.dateFrom,
      dateTo: parameters.dates.dateTo,
    } as RealisedGainsAndLossesRequest);
  }, []);

  useEffect(() => {
    fetchRealisedCapitalGainsAndLosses({
      clientId: clientId ?? locationClientId,
      dateFrom: parameters.dates.dateFrom,
      dateTo: parameters.dates.dateTo,
    } as RealisedGainsAndLossesRequest);
  }, []);

  function onSearchClicked() {
    if (isRealisedGainType()) {
      fetchRealisedCapitalGainsAndLosses({
        clientId: clientId ?? locationClientId,
        dateFrom: parameters.dates.dateFrom,
        dateTo: parameters.dates.dateTo,
      } as RealisedGainsAndLossesRequest);
    } else {
      fetchUnrealisedCapitalGainsAndLosses({
        clientId: clientId ?? locationClientId,
        dateTo: parameters.dates.dateTo,
      } as UnrealisedGainsAndLossesRequest);
    }
  }

  const fetch = useCallback(() => {
    if (isRealisedGainType()) {
      fetchRealisedCapitalGainsAndLosses({
        clientId: clientId ?? locationClientId,
        dateFrom: parameters.dates.dateFrom,
        dateTo: parameters.dates.dateTo,
      } as RealisedGainsAndLossesRequest);
    } else {
      fetchUnrealisedCapitalGainsAndLosses({
        clientId: clientId ?? locationClientId,
        dateTo: parameters.dates.dateTo,
      } as UnrealisedGainsAndLossesRequest);
    }
  }, [parameters, fetchRealisedCapitalGainsAndLosses, fetchUnrealisedCapitalGainsAndLosses]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const getTotalValue = (): number => {
    let totalValue = 0;
    if (isRealisedGainType()) {
      if (realisedResults?.total?.adjustedCostBase && realisedResults?.total?.baseGainLoss) {
        totalValue = realisedResults?.total?.adjustedCostBase + realisedResults?.total?.baseGainLoss;
      }
    } else {
      if (unrealisedResults?.total?.adjustedCostBase && unrealisedResults?.total?.baseGainLoss) {
        totalValue = unrealisedResults?.total?.adjustedCostBase + unrealisedResults?.total?.baseGainLoss;
      }
    }

    return totalValue;
  };

  const getCostBaseValue = (): number => {
    return isRealisedGainType()
      ? realisedResults?.total?.adjustedCostBase ?? 0
      : unrealisedResults?.total?.adjustedCostBase ?? 0;
  };

  const getGainLossValue = (): number => {
    return isRealisedGainType()
      ? realisedResults?.total?.baseGainLoss ?? 0
      : unrealisedResults?.total?.baseGainLoss ?? 0;
  };

  const getGainLossPercentageValue = (): number => {
    let gainLossPercentage = 0;

    if (isRealisedGainType()) {
      if (realisedResults?.total?.baseGainLoss && realisedResults?.total?.acquisitionCostBase) {
        gainLossPercentage = realisedResults?.total?.baseGainLoss / realisedResults?.total?.acquisitionCostBase;
      }
    } else {
      if (unrealisedResults?.total?.baseGainLoss && unrealisedResults?.total?.acquisitionCostBase) {
        gainLossPercentage = unrealisedResults?.total?.baseGainLoss / unrealisedResults?.total?.acquisitionCostBase;
      }
    }

    return gainLossPercentage;
  };

  const labelCsv = 'Download CSV Data';
  let onClickCsv = generateUnrealisedCsvClick;
  let testIdCsv = 'downloadUnrealisedData';

  if (isRealisedGainType()) {
    onClickCsv = generateRealisedCsvClick;
    testIdCsv = 'downloadRealisedData';
  } else {
    onClickCsv = generateUnrealisedCsvClick;
    testIdCsv = 'downloadUnrealisedData';
  }

  const exportButtons = [
    {
      icon: <PictureAsPdfOutlinedIcon />,
      label: 'Export to PDF',
      onClick: generatePdf,
      testId: `exportToPdf`,
    },
    {
      icon: <GridOnIcon />,
      label: labelCsv,
      onClick: onClickCsv,
      testId: testIdCsv,
    },
  ];

  return (
    <Container style={{ paddingTop: '20px', paddingBottom: '20px' }}>
      <Box display={'flex'} justifyContent={'space-between'}>
        <Grid item xs={12}>
          <Stack direction="row">
            <Box style={{ width: '50%', display: 'flex', justifyContent: 'flex-start' }}>
              <WO2Button
                color={'inherit'}
                type="button"
                data-testid={`cgtOverviewTestButton`}
                onClick={() => {
                  history.push(`./configuration`);
                }}
                disabled={false}
              >
                <Settings></Settings>
                <Typography variant="inherit" style={{ marginLeft: '10px' }}>
                  CONFIGURE
                </Typography>
              </WO2Button>
            </Box>

            <Box style={{ width: '50%', display: 'flex', justifyContent: 'right' }}>
              <WO2Menu
                testId="export_button"
                buttonTitle="Export"
                buttonIcon={
                  <div
                    style={{
                      paddingLeft: '10px',
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      backgroundColor: 'blue',
                      borderRadius: '25px',
                      padding: '10px 10px 10px 20px',
                      marginTop: '-7px',
                    }}
                  >
                    <span style={{ color: '#ffffff', fontSize: '0.875rem' }}>EXPORT</span>
                    <ArrowDropDownSharpIcon sx={{ color: '#ffffff' }} />
                  </div>
                }
                items={exportButtons}
              />
              <Box style={{ marginLeft: '10px' }}>
                <GainTypeSelect selectedItem={parameters?.gainType} setSelectedItem={setGainTypeParameter} />
              </Box>
              <Box style={{ marginLeft: '10px' }}>
                <DateRangePicker
                  dateRange={parameters?.dates}
                  setDateRange={setDateParameter}
                  isDateOnly={!isRealisedGainType()}
                />
              </Box>
              <Box>
                <WO2TextInput
                  placeholder={'Filter'}
                  inputProps={{ 'aria-label': 'search' }}
                  value={parameters.filter}
                  InputProps={{
                    startAdornment: <SearchIcon style={{ margin: '0 10px' }}>search</SearchIcon>,
                  }}
                  onChange={(event) => {
                    setFilterParameter(event.target.value);
                  }}
                />
              </Box>
              <Box style={{ marginLeft: '10px' }}>
                <WO2Button
                  color={'primary'}
                  disableElevation
                  onClick={onSearchClicked}
                  variant={'contained'}
                  value="Search"
                >
                  Search
                </WO2Button>
              </Box>
            </Box>
          </Stack>
        </Grid>
      </Box>
      <div ref={targetRef} style={{ padding: '10px' }}>
        {showPdfFooterAndHeader && <PdfHeader clientName={props.entityDetails?.name + ' - Capital Gains and Losses'} />}
        <Grid container spacing={2} style={{ paddingTop: '20px', paddingBottom: '20px' }}>
          <Grid item xs={3}>
            <TotalValueCard
              isLoading={loadingRealisedProgress.isLoading || loadingUnrealisedProgress.isLoading}
              hasErrors={loadingRealisedProgress.hasErrors || loadingUnrealisedProgress.hasErrors}
              totalValue={getTotalValue()}
            />
          </Grid>
          <Grid item xs={3}>
            <CostBaseCard
              isLoading={loadingRealisedProgress.isLoading || loadingUnrealisedProgress.isLoading}
              hasErrors={loadingRealisedProgress.hasErrors || loadingUnrealisedProgress.hasErrors}
              totalValue={getCostBaseValue()}
            />
          </Grid>
          <Grid item xs={3}>
            <GainLossValueCard
              isLoading={loadingRealisedProgress.isLoading || loadingUnrealisedProgress.isLoading}
              hasErrors={loadingRealisedProgress.hasErrors || loadingUnrealisedProgress.hasErrors}
              totalValue={getGainLossValue()}
            />
          </Grid>
          <Grid item xs={3}>
            <GainLossPercentageCard
              isLoading={loadingRealisedProgress.isLoading || loadingUnrealisedProgress.isLoading}
              hasErrors={loadingRealisedProgress.hasErrors || loadingUnrealisedProgress.hasErrors}
              totalValue={getGainLossPercentageValue()}
            />
          </Grid>
          <Grid item xs={12}>
            {isRealisedGainType() ? (
              <CapitalRealisedGainsLossesTable
                isLoading={loadingRealisedProgress.isLoading || loadingUnrealisedProgress.isLoading}
                hasErrors={loadingRealisedProgress.hasErrors || loadingUnrealisedProgress.hasErrors}
                results={realisedResults}
                generateRealisedCsv={generateRealisedCsv}
              />
            ) : (
              <CapitalUnrealisedGainsLossesTable
                isLoading={loadingRealisedProgress.isLoading || loadingUnrealisedProgress.isLoading}
                hasErrors={loadingRealisedProgress.hasErrors || loadingUnrealisedProgress.hasErrors}
                results={unrealisedResults}
                generateUnrealisedCsv={generateUnrealisedCsv}
              />
            )}
          </Grid>
        </Grid>
        {showPdfFooterAndHeader && <PdfFooter />}
      </div>
    </Container>
  );
};
