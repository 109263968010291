import { combineReducers } from '@reduxjs/toolkit';
import { commonSlice } from './common/store/slice';
import { emailReducer } from './email/reducer';
import { orderReducer } from './order/reducer';
import { reportReducer } from './report/reducer';
import { BulkState } from './types';

export const bulkReducer = combineReducers<BulkState>({
  common: commonSlice.reducer,
  order: orderReducer,
  email: emailReducer,
  report: reportReducer,
});
