import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { progressSelectorFactory } from '../../../common';
import { RootState } from '../../../reducers';
import { selectSecurityDetails } from '../common/store/selectors';
import { PerformanceComponent } from './components/PerformanceComponent';
import {
  selectDailyBenchmarkPerformance,
  selectDailyPerformanceChart,
  selectMonthlyBenchmarkPerformance,
  selectMonthlyPerformanceChart,
  selectParameters,
  selectSecurityPeriodicSummary,
} from './store/selectors';
import { securityPerformanceSlice } from './store/slice';
import {
  fetchDailyBenchmarkPerformance,
  fetchDailyPerformanceChart,
  fetchMonthlyBenchmarkPerformance,
  fetchMonthlyPerformanceChart,
  fetchSecurityBenchmarks,
  fetchSecurityPeriodicSummary,
  SecurityPerformanceActionTypes,
} from './store/thunks';

const mapStateToProps = (state: RootState) => ({
  parameters: selectParameters(state),
  securityDetails: selectSecurityDetails(state),
  monthlyPerformanceChart: selectMonthlyPerformanceChart(state),
  dailyPerformanceChart: selectDailyPerformanceChart(state),
  securityPeriodicSummary: selectSecurityPeriodicSummary(state),
  monthlyBenchmarkPerformance: selectMonthlyBenchmarkPerformance(state),
  dailyBenchmarkPerformance: selectDailyBenchmarkPerformance(state),
  monthlyPerformanceChartProgress: progressSelectorFactory([SecurityPerformanceActionTypes.FetchMonthlyPerformanceChart])(state),
  dailyPerformanceChartProgress: progressSelectorFactory([SecurityPerformanceActionTypes.FetchDailyPerformanceChart])(state),
  securityPeriodicSummaryProgress: progressSelectorFactory([SecurityPerformanceActionTypes.FetchSecurityPeriodicSummary])(state),
  securityBenchmarksProgress: progressSelectorFactory([SecurityPerformanceActionTypes.FetchSecurityBenchmarks])(state),
  monthlyBenchmarkPerformanceProgress: progressSelectorFactory([SecurityPerformanceActionTypes.FetchMonthlyBenchmarkPerformance])(state),
  dailyBenchmarkPerformanceProgress: progressSelectorFactory([SecurityPerformanceActionTypes.FetchDailyBenchmarkPerformance])(state),
});

const mapDispatchToProps = {
  ...securityPerformanceSlice.actions,
  fetchMonthlyPerformanceChart,
  fetchSecurityPeriodicSummary,
  fetchSecurityBenchmarks,
  fetchMonthlyBenchmarkPerformance,
  fetchDailyBenchmarkPerformance,
  fetchDailyPerformanceChart,
};

interface MatchParams {
  securityId?: string;
  securityCode?: string;
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector> & RouteComponentProps<MatchParams>;
export const SecurityPerformanceStateContainer = connect(mapStateToProps, mapDispatchToProps)(PerformanceComponent);
