import { TabRoute } from '../../store';
import { StrategyAssociationsStateContainer } from './associations/container';
import { StrategyCompositionStateContainer } from './composition/container';
import { StrategyDashboardStateContainer } from './dashboard/container';
import { StrategyDetailsStateContainer } from './details/container';
import { StrategyPerformanceStateContainer } from './performance/container';

export const strategyTabRoutes: TabRoute[] = [
  {
    label: 'DASHBOARD',
    order: 0,
    path: '/dashboard',
    component: StrategyDashboardStateContainer,
    visible: (): boolean => true,
  },
  {
    label: 'PERFORMANCE',
    order: 1,
    path: '/performance',
    component: StrategyPerformanceStateContainer,
    visible: (): boolean => true,
  },
  {
    label: 'COMPOSITION',
    order: 2,
    path: '/composition',
    component: StrategyCompositionStateContainer,
    visible: (): boolean => true,
  },
  {
    label: 'ASSOCIATED CLIENTS',
    order: 3,
    path: '/associated-clients',
    component: StrategyAssociationsStateContainer,
    visible: (): boolean => true,
  },
  {
    label: 'DETAILS',
    order: 4,
    path: '/details',
    component: StrategyDetailsStateContainer,
    visible: (): boolean => true,
  },
];
