import { createSelector } from '@reduxjs/toolkit';
import { selectAccounts } from '../../common/store/selectors';
import { ClientAccount, ClientAccountSelectModel } from '../../common/store/types';
import { selectClientState } from '../../store/selectors';

export const selectTransactionsState = createSelector(selectClientState, (clientState) => clientState.transactions);
export const selectParameters = createSelector(selectTransactionsState, (transactionsState) => transactionsState.parameters);
export const selectTransactionsTotals = createSelector(selectTransactionsState, (transactionsState) => transactionsState.totals);
export const selectTransactionResults = createSelector(selectTransactionsState, (transactionsState) => transactionsState?.transactions);
export const selectSelectedTransaction = createSelector(selectTransactionsState, (transactionsState) => transactionsState.selectedTransaction);

export const selectPortfolioId = createSelector(selectParameters, (parameters) => parameters.portfolio?.investmentProgramId ?? null);

export const selectAccountsForPortfolio = createSelector(selectPortfolioId, selectAccounts, (portfolioId: number | null, clientAccounts: ClientAccount[]) => {
  const accounts = portfolioId !== null ? clientAccounts.filter((clientAccount) => clientAccount.investmentProgramIds.includes(portfolioId)) : clientAccounts;

  const accountsSelectList: ClientAccountSelectModel[] = accounts.map((i) => {
    return {
      accountId: i.accountId,
      accountName: i.accountName,
      accountNumber: i.accountNumber,
      accountIdList: [i.accountId],
    };
  });

  // add the all accounts item
  const allAccountIds = accountsSelectList.map((a) => a.accountId as number);
  accountsSelectList
    .sort((a, b) => a.accountName.localeCompare(b.accountName))
    .unshift({ accountId: null, accountNumber: '', accountName: 'All Accounts', accountIdList: allAccountIds });

  return accountsSelectList;
});

export const selectPagination = createSelector(selectParameters, (parameters) => parameters.pagination);
