import { Enumeration } from './enumeration';

export class Gender extends Enumeration {
  static Male = new Gender(1, 'Male', 'Male');
  static Female = new Gender(2, 'Female', 'Female');
  static Unspecified = new Gender(3, 'Unspecified', 'Indeterminate/Intersex/Unspecified');

  static getByName = (name: string): Gender | undefined => {
    return Object.values(Gender)
      .filter((t) => t instanceof Gender)
      .find((t: Gender) => t.name.toLowerCase() === name.toLowerCase());
  };
}
