import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { progressSelectorFactory } from 'src/common/store/selectors';
import { RootState } from 'src/store';
import { fetchApprovedProducts, TemplateEditActionTypes } from '../edit/store';
import { Portfolios } from './components/portfolios';
import {
  createTemplate,
  deleteTemplate,
  fetchAfsls,
  fetchTemplates,
  portfoliosSlice,
  selectAfslId,
  selectAfsls,
  selectTemplates,
  TemplatePortfoliosActionTypes,
} from './store';

const mapStateToProps = (state: RootState) => ({
  afslId: selectAfslId(state),
  afsls: selectAfsls(state),
  templates: selectTemplates(state),
  fetchTemplatesProgress: progressSelectorFactory([TemplatePortfoliosActionTypes.FetchTemplates])(state),
  creatingProgress: progressSelectorFactory([TemplatePortfoliosActionTypes.CreateTemplate])(state),
  fetchApprovedProductsProgress: progressSelectorFactory([TemplateEditActionTypes.FetchApprovedProducts])(state),
});

interface MatchParams {
  afslId: string;
}

const mapDispatchToProps = {
  fetchAfsls,
  fetchTemplates,
  deleteTemplate,
  createTemplate,
  fetchApprovedProducts,
  ...portfoliosSlice.actions,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector> & RouteComponentProps<MatchParams>;
export const PortfoliosContainer = connect(mapStateToProps, mapDispatchToProps)(Portfolios);
