import { CloudDownload } from '@mui/icons-material';
import { Box, Button, IconButton, Paper } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { convertToCSVFormat, CSVDataType, downloadCsv, useStyles } from 'src/common';
import { ClientSideDataTable } from 'src/common/components/dataTable/clientSide';
import { Props } from '../resultsContainer';
import { getResultColumns } from './resultColumns';

export const Results = (props: Props): JSX.Element => {
  const [selected, setSelected] = useState<number[]>([]);
  const { results, setSelectedResults, history, selectedResults, isLoadingProgress } = props;
  const { path } = useRouteMatch();
  const classes = useStyles();

  useEffect(() => {
    setSelected(selectedResults);
  }, [selectedResults]);

  const handleNext = () => {
    setSelectedResults(selected ?? []);
    history.push(`${path}/edit`);
  };
  const downloadData = () => {
    const csvData: CSVDataType[] = convertToCSVFormat(results);
    downloadCsv(csvData, `Clients_List_`);
  };
  const downloadButton = (): React.ReactNode => {
    return !!results ? (
      <IconButton
        disableFocusRipple
        disableRipple
        className={classes.addToTable}
        data-testid="downloadButton"
        disabled={results.length === 0}
        onClick={() => {
          downloadData();
        }}
        title="Download CSV"
      >
        <CloudDownload />
      </IconButton>
    ) : (
      <></>
    );
  };
  return (
    <Paper elevation={0}>
      <Box display="flex" flexDirection="row" justifyContent="flex-end" padding="24px">
        <Button
          disabled={selected.length <= 0}
          disableElevation
          color={'primary'}
          onClick={() => selected.length && handleNext()}
          variant="contained"
          id="next_button"
        >
          Next
        </Button>
      </Box>
      <ClientSideDataTable
        loadingProgress={isLoadingProgress}
        columns={getResultColumns(results)}
        data={results}
        options={{
          filter: false,
          selectableRows: 'multiple',
          selectableRowsHideCheckboxes: false,
          onRowSelectionChange: (currentRowsSelected, allRowsSelected, rowsSelected) => {
            setSelected(rowsSelected ?? []);
          },
          customToolbar: () => downloadButton(),
          rowsSelected: selected,
        }}
        enablePagination
      />
      <Box display="flex" flexDirection="row" justifyContent="flex-end" padding="24px">
        <Button
          disabled={selected.length <= 0}
          disableElevation
          color={'primary'}
          onClick={() => selected.length && handleNext()}
          variant="contained"
        >
          Next
        </Button>
      </Box>
    </Paper>
  );
};
