import { createSelector } from '@reduxjs/toolkit';
import { selectEditState } from '../../store/selectors';
import { TemplateDetails, UpdateTemplateRequest } from './types';

export const selectDetails = createSelector(selectEditState, (edit) => edit?.details);
export const selectSecurityToleranceBands = createSelector(selectEditState, (edit) => edit?.securityToleranceBands);
export const selectAssetClassToleranceBands = createSelector(selectEditState, (edit) => edit?.assetClassToleranceBands);
export const selectConfigurations = createSelector(selectEditState, (edit) => edit?.configurations);
export const selectStrategicAssetAllocations = createSelector(selectEditState, (edit) => edit?.strategicAssetAllocations);
export const selectSecurityConstraints = createSelector(selectEditState, (edit) => edit?.securityConstraints);

export const selectSaveTemplatePayload = createSelector(selectEditState, (edit): UpdateTemplateRequest => {
  const ret: UpdateTemplateRequest = {
    investmentServiceTemplateId: edit.investmentServiceTemplateId || null,
    ...(edit.details as TemplateDetails),
    securityToleranceBands: edit.securityToleranceBands,
    assetClassToleranceBands: edit.assetClassToleranceBands,
    configurations: edit.configurations,
    strategicAssetAllocations: edit.strategicAssetAllocations,
    securityConstraints: edit.securityConstraints,
    afslId: edit.afslId || null,
  };
  return ret;
});

export const selectBenchmarks = createSelector(selectEditState, (edit) => edit.benchmarks);
export const selectApprovedProducts = createSelector(selectEditState, (edit) => edit.approvedProducts);

export const selectCashAccountProduct = createSelector(
  selectApprovedProducts,
  (approvedProducts) => approvedProducts.find((p) => p.code === 'MacqBankCMA.ADI') || undefined
);
