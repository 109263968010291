import { createSelector } from '@reduxjs/toolkit';
import jwt_decode from 'jwt-decode';
import { RootState } from './reducer';
import { UserToken } from './types/UserToken';

export const selectOidc = createSelector(
  (state: RootState) => state,
  (state) => state.oidc
);

export const selectUser = createSelector(selectOidc, (oidc) => oidc.user || undefined);

export const selectToken = createSelector(selectUser, (user) => user?.access_token);

export const selectTokenDecoded = createSelector(selectToken, (token) =>
  !!token ? jwt_decode<UserToken>(token) : undefined
);

export const selectHasClientEdit = createSelector(selectTokenDecoded, (token: UserToken | undefined) => {
  if (!!token) {
    if (!!token['https://api.wealtho2.com.au/scope']) {
      const scope = token['https://api.wealtho2.com.au/scope'];
      return Array.isArray(scope) && scope.indexOf('client:edit') !== -1;
    } else {
      return false;
    }
  } else {
    return process.env.REACT_APP_CLIENT_EDIT === 'true';
  }
});

export const selectHasClientEditAdminOnly = createSelector(selectTokenDecoded, (token: UserToken | undefined) => {
  if (!!token) {
    if (!!token['https://api.wealtho2.com.au/scope']) {
      const scope = token['https://api.wealtho2.com.au/scope'];
      return Array.isArray(scope) && scope.indexOf('client:edit:adminonly') !== -1;
    } else {
      return false;
    }
  } else {
    return process.env.REACT_APP_CLIENT_EDIT === 'true';
  }
});

export const selectHasRebalanceEnhancedTrading = createSelector(selectTokenDecoded, (token: UserToken | undefined) => {
  if (!!token) {
    if (!!token['https://api.wealtho2.com.au/scope']) {
      const scope = token['https://api.wealtho2.com.au/scope'];
      return Array.isArray(scope) && scope.indexOf('rebalance:enhanced-trading') !== -1;
    } else {
      return false;
    }
  } else {
    return process.env.REACT_APP_CLIENT_EDIT === 'true';
  }
});

export const selectHasPortfolioEdit = createSelector(selectTokenDecoded, (token: UserToken | undefined) => {
  if (!!token) {
    if (!!token['https://api.wealtho2.com.au/scope']) {
      const scope = token['https://api.wealtho2.com.au/scope'];
      return Array.isArray(scope) && scope.indexOf('portfolio:edit') !== -1;
    } else {
      return false;
    }
  } else {
    return process.env.REACT_APP_CLIENT_EDIT === 'true';
  }
});

export const selectHasDashEmail = createSelector(selectTokenDecoded, (token: UserToken | undefined) => {
  const approvedUsers = [
    'hong.nguyen@dash.com.au',
    'hong.nguyen@wealtho2.com.au',
    'james.barclay@dash.com.au',
    'james.barclay@wealtho2.com.au',
    'yen.chew@dash.com.au',
    'tim.mcguigan@dash.com.au',
    'nick.nie@dash.com.au',
    'valentina.sanzone@dash.com.au',
  ];

  if (!!token) {
    if (!!token['https://app.mdaoperator.com.au/email']) {
      const scope = token['https://app.mdaoperator.com.au/email'];
      return approvedUsers.some((i) => scope.indexOf(i) !== -1);
    } else {
      return false;
    }
  } else {
    return false;
  }
});

export const selectTemplatesState = createSelector(
  (state: RootState) => state,
  (state) => state.templates
);

export const selectUserSettingsState = createSelector(
  (state: RootState) => state,
  (state) => state.userSettings
);

export const selectIsDataFromTriumph = createSelector(
  selectUserSettingsState,
  (userSetting) => userSetting?.isDataFromTriumph ?? true
);

export const selectIsRebalanceDataSourceTriumph = createSelector(
  selectUserSettingsState,
  (userSetting) => userSetting?.rebalanceFromTriumph ?? true
);

export const selectIsNonProdEnv = () => {
  return process.env.REACT_APP_NONPROD_ENV === 'true';
};
