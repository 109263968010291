import { Box, Grid, Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AnyAction } from '@reduxjs/toolkit';
import { Field, Form, Formik, FormikProps } from 'formik';
import { default as React, useCallback, useEffect, useState } from 'react';
import { AddressTable } from 'src/features/clients/client/common/components/contactDetails/addressTable';
import { AddressType } from 'src/features/clients/client/common/store/enums';
import { AddressDetails } from 'src/features/clients/client/common/store/types';
import * as yup from 'yup';
import {
  FormikEnumerationSelect,
  FormikSwitch,
  FormikTextField,
  yupValidateAbn,
  yupValidateAcn,
  yupValidateTfn,
} from '../../../../../common/components/formik';
import { LoadingProgress } from '../../../../../common/store/types/loadingProgress';
import history from '../../../../../history';
import { CorporateType, TfnExemptions } from '../../../common/enums';
import { PageBackNextButtons } from '../../components/common/pageBackNextButtons';
import { PrevNextRoutePaths, Trustee as TrusteeType } from '../../store/types';

const useStyles = makeStyles(() => ({
  root: {
    '& .trusteeTypeSelection': {
      '& button': {
        height: '36px',
        minHeight: '36px',
      },
    },
  },
}));

export interface TrusteeFormDetails {
  name: string;
  abn: string;
  corporateTypeId: number | null;
  acn: string;
  natureOfBusiness: string;
  tfn: string;
  industryType: string;
  tfnExemptionId: number | null;
  isResident: boolean;
}

export interface TrusteeProps {
  id: string | null;
  trustee?: TrusteeType;
  saveProgress: LoadingProgress;
  prevNextRoutePaths: PrevNextRoutePaths;
  saveTrustee: (trustee?: TrusteeType) => Promise<AnyAction>;
}

export const Trustee = (props: TrusteeProps): JSX.Element => {
  const { id, prevNextRoutePaths, trustee, saveProgress, saveTrustee } = props;
  const [editAddressIndex, setEditAddressIndex] = useState<number | null | undefined>(undefined);

  const classes = useStyles();

  const initialFormValues: TrusteeFormDetails = {
    name: '',
    abn: '',
    corporateTypeId: null,
    acn: '',
    natureOfBusiness: '',
    tfn: '',
    industryType: '',
    tfnExemptionId: null,
    isResident: false,
  };

  const [formValues, setFormValues] = useState<TrusteeFormDetails>(initialFormValues);
  const [addresses, setAddresses] = useState<AddressDetails[]>([]);
  const [availableAddressTypes, setAvailableAddressTypes] = useState<AddressType[]>(AddressType.getArray());

  useEffect(() => {
    if (!!trustee) {
      setFormValues({ ...trustee });
      setAddresses(trustee.addresses);
    }
  }, [trustee]);

  useEffect(() => {
    let addressTypes = AddressType.getArray();
    addresses.forEach((address) => {
      if (!!address.addressTypeIds) {
        address.addressTypeIds.forEach((addressTypeId) => {
          const type = AddressType.getById(addressTypeId);
          addressTypes = addressTypes.filter((item) => item !== type);
        });
      }
    });
    setAvailableAddressTypes(addressTypes);
  }, [addresses, setAvailableAddressTypes]);

  const handleSaveAddress = useCallback(
    (address: AddressDetails) => {
      if (editAddressIndex === null) {
        // append new address
        setAddresses([...addresses, address]);
      } else {
        // update existing address
        setAddresses(addresses.map((a) => (a.addressId === address.addressId ? address : a)));
      }

      // close add/edit dialog
      setEditAddressIndex(undefined);
    },
    [setAddresses, editAddressIndex, setEditAddressIndex]
  );

  return (
    <Grid container>
      <Grid item xs={12}>
        <div className={classes.root}>
          <Formik<TrusteeFormDetails>
            enableReinitialize={true}
            initialValues={formValues}
            onSubmit={async (submittedTrustee: TrusteeFormDetails) => {
              await saveTrustee({ ...submittedTrustee, addresses });
              if (prevNextRoutePaths.nextRoutePath) {
                history.push(prevNextRoutePaths.nextRoutePath + (!!id ? `?id=${id}` : ''));
              }
            }}
            validationSchema={yup.object({
              name: yup.string().required('Name is required'),
              corporateTypeId: yup.number().nullable().required('Corporate type is required'),
              abn: yup.string().nullable().test('test-abn', 'Not a valid ABN Number', yupValidateAbn),
              acn: yup
                .string()
                .nullable()
                .test('test-acn', 'Not a valid ACN', function (this: yup.TestContext, acn: string | null | undefined): boolean {
                  return !acn || yupValidateAcn(acn);
                }),
              tfn: yup.string().test('test-tfn', 'Not a valid Tax File Number', function (this: yup.TestContext, tfn: string | null | undefined): boolean {
                if (!this.parent.tfnExemptionId) {
                  return !tfn || yupValidateTfn(tfn);
                }
                return !!tfn && TfnExemptions.isValidExemptionCode(tfn);
              }),
              tfnExemptionId: yup.number().nullable(),
              natureOfBusiness: yup.string(),
              industryType: yup.string(),
              isResident: yup.boolean(),
            })}
          >
            {(formikProps: FormikProps<TrusteeType>) => (
              <Form>
                <fieldset style={{ border: 'none' }}>
                  <Box paddingBottom="10px" marginBottom="30px">
                    <Typography variant="h1" style={{ fontSize: '26px', fontWeight: '500', lineHeight: 1.3 }}>
                      Let us know about the Trustee for this account
                    </Typography>
                  </Box>
                  <Typography variant="h4" style={{ marginBottom: '30px' }}>
                    Corporate Trustee
                  </Typography>
                  <Paper elevation={5}>
                    <Grid container>
                      <Grid item xs={6}>
                        <Grid item xs={12} style={{ margin: '20px 10px' }}>
                          <Field component={FormikTextField} name="name" label="* NAME" fullWidth />
                        </Grid>
                        <Grid item xs={12} style={{ margin: '20px 10px' }}>
                          <Field component={FormikEnumerationSelect} type={CorporateType} name="corporateTypeId" label="* CORPORATE TYPE" valueIsId={true} />
                        </Grid>
                        <Grid item xs={12} style={{ margin: '20px 10px' }}>
                          <Field component={FormikTextField} name="natureOfBusiness" label="NATURE OF BUSINESS" fullWidth />
                        </Grid>
                        <Grid item xs={12} style={{ margin: '20px 10px' }}>
                          <Field component={FormikTextField} name="industryType" label="INDUSTRY TYPE" fullWidth />
                        </Grid>

                        <Grid item xs={12} style={{ margin: '20px 10px' }}>
                          <Field name={'isResident'} component={FormikSwitch} label="RESIDENT"></Field>
                        </Grid>
                      </Grid>
                      <Grid item xs={6}>
                        <Grid item xs={12} style={{ margin: '20px 10px' }}>
                          <Field mask="99 999 999 999" component={FormikTextField} name="abn" label="AUSTRALIAN BUSINESS NUMBER (ABN)" fullWidth />
                        </Grid>
                        <Grid item xs={12} style={{ margin: '20px 10px' }}>
                          <Field mask="999 999 999" component={FormikTextField} name="acn" label="AUSTRALIAN COMPANY NUMBER (ACN)" fullWidth />
                        </Grid>
                        <Grid item xs={12} style={{ margin: '20px 10px' }}>
                          <Field
                            component={FormikEnumerationSelect}
                            type={TfnExemptions}
                            name="tfnExemptionId"
                            label="TFN EXEMPTION"
                            showNone={true}
                            valueIsId={true}
                            onChange={(value: number | null) => {
                              if (value === null) {
                                if (formikProps.values.tfn !== null && TfnExemptions.isValidExemptionCode(formikProps.values.tfn)) {
                                  formikProps.setFieldValue('tfn', null);
                                }
                              } else {
                                const tfnExemptionsCode = TfnExemptions.getTfnExemptionCodeById(value);
                                if (!!tfnExemptionsCode) {
                                  formikProps.setFieldValue('tfn', tfnExemptionsCode.code);
                                  formikProps.setFieldTouched('tfn', false, false);
                                }
                              }
                              formikProps.setFieldTouched('tfnExemptionId', false, false);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} style={{ margin: '20px 10px' }}>
                          <Field
                            component={FormikTextField}
                            name="tfn"
                            label="TAX FILE NUMBER (TFN)"
                            mask="999 999 999"
                            disabled={!!formikProps.values.tfnExemptionId}
                            fullWidth
                            type="password"
                            autocomplete="new-password"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Paper>
                  <Box>
                    <AddressTable
                      addresses={addresses.map((a, index) => {
                        return { ...a, addressId: index };
                      })}
                      selectedAddress={editAddressIndex === undefined ? undefined : editAddressIndex === null ? null : addresses[editAddressIndex]}
                      loadingProgress={{ isLoading: false, hasErrors: false }}
                      savingProgress={saveProgress}
                      addressTypes={availableAddressTypes}
                      save={(address) => handleSaveAddress(address)}
                      delete={async (addressIndex: number) => {
                        setAddresses(addresses.filter((a, index) => index !== addressIndex));
                      }}
                      cancel={() => setEditAddressIndex(undefined)}
                      setAddEdit={(index) => {
                        setEditAddressIndex(index);
                      }}
                    ></AddressTable>
                  </Box>
                  <PageBackNextButtons<TrusteeFormDetails>
                    onBackButtonClick={async () => {
                      await saveTrustee({ ...formikProps.values, addresses });
                      history.push(prevNextRoutePaths.prevRoutePath + (!!id ? `?id=${id}` : ''));
                    }}
                    onNextButtonClick={formikProps.submitForm}
                    onQuitButtonClick={async () => {
                      await saveTrustee({ ...formikProps.values, addresses });
                      history.push('/client/list?mode=onboard');
                    }}
                    progress={saveProgress}
                  />
                </fieldset>
              </Form>
            )}
          </Formik>
        </div>
      </Grid>
    </Grid>
  );
};
