import { FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Paper, Select, Typography } from '@mui/material';
import { Field, Form, Formik, FormikProps } from 'formik';
import React, { useEffect, useState } from 'react';
import { CorporateType, TfnExemptions } from 'src/features/clients/common/enums';
import * as yup from 'yup';
import {
  EditCancelSaveButtons,
  FormikEnumerationSelect,
  FormikKeyboardDatePicker,
  FormikSwitch,
  FormikTextField,
  Mode,
  yupValidateAbn,
  yupValidateAcn,
  yupValidateTfn,
} from '../../../../../../common/components/formik';
import { LoadingIndicator } from '../../../../../../common/components/LoadingIndicator';
import { LoadingProgress } from '../../../../../../common/store/types';
import { ClientAccountSubType, ClientAccountType } from '../../../../../../common/types';
import { MainDetails } from '../../../common/store/types';
import { SourceOfFunds, TrusteeType, TrustType } from '../store/enums';

export interface Props {
  hasClientEditPermission: boolean;
  details: MainDetails;
  loadingProgress: LoadingProgress;
  onSave: (details: MainDetails) => void;
  saveProgress: LoadingProgress;
}

type FormValues = Partial<MainDetails>;

interface SourceOfFundsItem {
  id: number;
  name: string;
  displayName: string;
}

export const DetailsForm = (props: Props): JSX.Element => {
  const { onSave, details, loadingProgress, saveProgress, hasClientEditPermission } = props;
  const [readonly, setReadonly] = useState<boolean>(true);

  const [sourceOfFundsItems] = useState<SourceOfFundsItem[]>(
    SourceOfFunds.getArray().map((s) => {
      return { id: s.id, name: s.name, displayName: s.displayName };
    })
  );

  const [accountType, setAccountType] = useState<ClientAccountType>();

  useEffect(() => {
    setAccountType(ClientAccountType.getById(details.accountTypeId));
  }, [details]);
  return (
    <>
      <Typography variant="h4" style={{ padding: '20px 0' }}>
        Other Details
      </Typography>
      <Paper elevation={5}>
        <LoadingIndicator progress={loadingProgress}>
          <Formik<FormValues>
            enableReinitialize={true}
            initialValues={details}
            onSubmit={async (details) => {
              const mainDetails: MainDetails = { ...(details as MainDetails) };
              mainDetails.tfn = (mainDetails.tfn || '').replace(/[\s-_]/g, '');
              onSave(mainDetails);
              setReadonly(true);
            }}
            validationSchema={yup.object({
              accountTypeId: yup.number(),
              trusteeTypeId: yup
                .string()
                .nullable()
                .when('accountTypeId', {
                  is: (accountTypeId) => accountTypeId === ClientAccountType.Trust.id || accountTypeId === ClientAccountType.Smsf.id,
                  then: yup.string().required('Required'),
                }),
              typeOfTrustId: yup
                .string()
                .nullable()
                .when('accountTypeId', {
                  is: (accountTypeId) => accountTypeId === ClientAccountType.Trust.id || accountTypeId === ClientAccountType.Smsf.id,
                  then: yup.string().required('Required'),
                }),
              corporateTypeId: yup
                .number()
                .nullable()
                .when('accountTypeId', {
                  is: (accountTypeId) => accountTypeId === ClientAccountType.Company.id,
                  then: yup.number().required('Required'),
                }),
              natureOfBusiness: yup
                .string()
                .nullable()
                .when('accountTypeId', {
                  is: (accountTypeId) => accountTypeId === ClientAccountType.Company.id,
                  then: yup.string().required('Required'),
                }),
              industryType: yup
                .string()
                .nullable()
                .when('accountTypeId', {
                  is: (accountTypeId) => accountTypeId === ClientAccountType.Company.id,
                  then: yup.string().required('Required'),
                }),
              name: yup.string().required('Required'),
              preferredName: yup.string(),
              designationAccount: yup.string(),
              inceptionDate: yup.date().nullable().typeError('Must be a valid date'),
              servicesAgreementDate: yup.date().nullable().typeError('Must be a valid date'),
              riskProfile: yup.string(),
              sourceOfFunds: yup.string().nullable(),
              tfn: yup
                .string()
                .nullable()
                .test('test-tfn', 'Not a valid Tax File Number', function (this: yup.TestContext, tfn: string | null | undefined): boolean {
                  if (this.parent.subTypeId === ClientAccountSubType.Super.id || this.parent.subTypeId === ClientAccountSubType.Pension.id) {
                    return true;
                  }

                  if (this.parent.tfnExemptionId) {
                    return (!!tfn && TfnExemptions.isValidExemptionCode(tfn)) || yupValidateTfn(tfn);
                  }

                  return !tfn || yupValidateTfn(tfn);
                }),
              tfnExemptionId: yup.string().nullable(),
              abn: yup.string().nullable().test('test-abn', 'Not a valid ABN Number', yupValidateAbn),
              acn: yup
                .string()
                .nullable()
                .when('accountTypeId', {
                  is: (accountTypeId) => accountTypeId === ClientAccountType.Company.id,
                  then: yup.string().required('ACN is required'),
                })
                .test('test-acn', 'Not a valid ACN', function (this: yup.TestContext, acn: string | null | undefined): boolean {
                  return !acn || yupValidateAcn(acn);
                }),
              isResident: yup.boolean().required('Required'),
            })}
          >
            {(formikProps: FormikProps<FormValues>) => (
              <Form>
                <fieldset style={{ border: 'none' }}>
                  <Grid container>
                    <Grid item xs={6}>
                      {(accountType === ClientAccountType.Trust || accountType === ClientAccountType.Smsf) && (
                        <Grid item xs={12} style={{ margin: '20px 10px' }}>
                          <Field
                            disabled={readonly}
                            component={FormikEnumerationSelect}
                            type={TrusteeType}
                            valueIsId={true}
                            name="trusteeTypeId"
                            label="TRUSTEE TYPE"
                          />
                        </Grid>
                      )}
                      {(accountType === ClientAccountType.Trust || accountType === ClientAccountType.Smsf) && (
                        <Grid item xs={12} style={{ margin: '20px 10px' }}>
                          <Field
                            disabled={readonly}
                            component={FormikEnumerationSelect}
                            type={TrustType}
                            name="typeOfTrustId"
                            valueIsId={true}
                            label={'TYPE OF TRUST'}
                          />
                        </Grid>
                      )}
                      {accountType === ClientAccountType.Company && (
                        <Grid item xs={12} style={{ margin: '20px 10px' }}>
                          <Field
                            disabled={readonly}
                            component={FormikEnumerationSelect}
                            valueIsId={true}
                            type={CorporateType}
                            name="corporateTypeId"
                            label="CORPORATE TYPE"
                          />
                        </Grid>
                      )}
                      {accountType === ClientAccountType.Company && (
                        <Grid item xs={12} style={{ margin: '20px 10px' }}>
                          <Field
                            disabled={readonly}
                            component={FormikTextField}
                            name="natureOfBusiness"
                            label="NATURE OF BUSINESS"
                            showRequiredAsterisk={true}
                            fullWidth
                          />
                        </Grid>
                      )}
                      {accountType === ClientAccountType.Company && (
                        <Grid item xs={12} style={{ margin: '20px 10px' }}>
                          <Field
                            disabled={readonly}
                            component={FormikTextField}
                            name="industryType"
                            label="INDUSTRY TYPE"
                            showRequiredAsterisk={true}
                            fullWidth
                          />
                        </Grid>
                      )}
                      <Grid item xs={12} style={{ margin: '20px 10px' }}>
                        <Field disabled={readonly} component={FormikTextField} name="name" label="NAME" fullWidth />
                      </Grid>
                      <Grid item xs={12} style={{ margin: '20px 10px' }}>
                        <Field disabled={readonly} component={FormikTextField} name="preferredName" label="PREFERRED NAME" fullWidth />
                      </Grid>
                      <Grid item xs={12} style={{ margin: '20px 10px' }}>
                        <Field disabled={readonly} component={FormikTextField} name="designationAccount" label="DESIGNATION ACCOUNT" fullWidth />
                      </Grid>
                      <Grid item xs={12} style={{ margin: '20px 10px' }}>
                        <Field
                          disabled={readonly}
                          component={FormikKeyboardDatePicker}
                          name="inceptionDate"
                          label="INCEPTION DATE"
                          maxDateMessage="From Date should not be after To Date"
                          variant="outlined"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} style={{ margin: '20px 10px' }}>
                        <Field
                          disabled={readonly}
                          component={FormikKeyboardDatePicker}
                          name="servicesAgreementDate"
                          label="SERVICES AGREEMENT DATE"
                          maxDateMessage="From Date should not be after To Date"
                          variant="outlined"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} style={{ margin: '20px 10px' }}>
                        <Field disabled={readonly} component={FormikTextField} name="riskProfile" label="RISK PROFILE" fullWidth />
                      </Grid>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid item xs={12} style={{ margin: '20px 10px' }}>
                        <Field disabled={readonly} name="sourceOfFunds">
                          {(): React.ReactElement => (
                            <FormControl variant="outlined" fullWidth>
                              <InputLabel shrink id="sourceOfFundsLabel" htmlFor="sourceOfFundsInput">
                                SOURCE OF FUNDS
                              </InputLabel>
                              <Select
                                disabled={readonly}
                                labelId="sourceOfFundsLabel"
                                id="sourceOfFunds"
                                value={formikProps.values.sourceOfFunds}
                                onChange={(_event) => {
                                  formikProps.setFieldValue('sourceOfFunds', _event.target.value);
                                }}
                                fullWidth
                                input={<OutlinedInput label="SOURCE OF FUNDS" notched name={'sourceOfFunds'} id="sourceOfFundsInput" />}
                                variant="standard"
                              >
                                {formikProps.initialValues.sourceOfFunds !== '(None)' && <MenuItem value={'(None)'}>(None)</MenuItem>}
                                {!sourceOfFundsItems.find((s) => s.name === formikProps.initialValues.sourceOfFunds) && (
                                  <MenuItem value={formikProps.initialValues.sourceOfFunds}>{formikProps.initialValues.sourceOfFunds}</MenuItem>
                                )}
                                {sourceOfFundsItems.map((s, index) => {
                                  return (
                                    <MenuItem key={index} value={s.name}>
                                      {s.displayName}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          )}
                        </Field>
                      </Grid>
                      {!(details.subTypeId === ClientAccountSubType.Super.id || details.subTypeId === ClientAccountSubType.Pension.id) && (
                        <>
                          <Grid item xs={12} style={{ margin: '20px 10px' }}>
                            <Field
                              component={FormikEnumerationSelect}
                              type={TfnExemptions}
                              name="tfnExemptionId"
                              label="TFN EXEMPTION"
                              showNone={true}
                              disabled={readonly}
                              valueIsId={true}
                              onChange={(value: number | null) => {
                                if (value === null) {
                                  if (!!formikProps.values.tfn && TfnExemptions.isValidExemptionCode(formikProps.values.tfn)) {
                                    formikProps.setFieldValue('tfn', null);
                                  }
                                } else {
                                  const tfnExemptionsCode = TfnExemptions.getTfnExemptionCodeById(value);
                                  if (!!tfnExemptionsCode) {
                                    formikProps.setFieldValue('tfn', tfnExemptionsCode.code);
                                    formikProps.setFieldTouched('tfn', false, false);
                                  }
                                }
                                formikProps.setFieldTouched('tfnExemptionId', false, false);
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} style={{ margin: '20px 10px' }}>
                            <Field
                              disabled={readonly || !!formikProps.values.tfnExemptionId}
                              mask="999 999 999"
                              component={FormikTextField}
                              name="tfn"
                              label="TAX FILE NUMBER (TFN)"
                              fullWidth
                              type="password"
                              autocomplete="new-password"
                            />
                          </Grid>
                        </>
                      )}
                      {(accountType === ClientAccountType.Company || accountType === ClientAccountType.Smsf || accountType === ClientAccountType.Trust) && (
                        <Grid item xs={12} style={{ margin: '20px 10px' }}>
                          <Field
                            disabled={readonly}
                            mask="99 999 999 999"
                            component={FormikTextField}
                            name="abn"
                            label="AUSTRALIAN BUSINESS NUMBER (ABN)"
                            fullWidth
                          />
                        </Grid>
                      )}
                      {accountType === ClientAccountType.Company && (
                        <Grid item xs={12} style={{ margin: '20px 10px' }}>
                          <Field
                            mask="999 999 999"
                            disabled={readonly}
                            component={FormikTextField}
                            name="acn"
                            label="AUSTRALIAN COMPANY NUMBER (ACN)"
                            showRequiredAsterisk={true}
                            fullWidth
                          />
                        </Grid>
                      )}
                      <Grid item xs={12} style={{ margin: '20px 10px' }}>
                        <Field disabled={readonly} name={'isResident'} component={FormikSwitch} label="RESIDENT"></Field>
                      </Grid>
                    </Grid>
                  </Grid>
                  {hasClientEditPermission && (
                    <EditCancelSaveButtons
                      formikProps={formikProps}
                      mode={Mode.EditCancelSave}
                      readonly={readonly}
                      handleEditClick={() => setReadonly(!readonly)}
                      handleCancelClick={() => {
                        setReadonly(!readonly);
                        formikProps.setValues(formikProps.initialValues);
                      }}
                      saveProgress={saveProgress}
                    ></EditCancelSaveButtons>
                  )}
                </fieldset>
              </Form>
            )}
          </Formik>
        </LoadingIndicator>
      </Paper>
    </>
  );
};
