import { Grid, Typography } from '@mui/material';
import { AnyAction } from '@reduxjs/toolkit';
import { Field, Form, Formik, FormikProps } from 'formik';
import React, { useCallback, useEffect } from 'react';
import { TrustType } from 'src/features/clients/client/details/mainDetails/store';
import * as yup from 'yup';
import { AfslItem, PracticeItem } from '../../../../bulk/common/store/types';
import { AdviserItem } from '../../../onboard/store/types';
import { WO2Modal } from './../../../../../common';
import WO2Button from './../../../../../common/components/button/Button';
import { EditCancelSaveButtons, FormikSelect, FormikTextField, Mode } from './../../../../../common/components/formik';
import { LoadingProgress } from './../../../../../common/store/types';
import { ClientAccountSubType, ClientAccountType, EntityType } from './../../../../../common/types';
import { QuickOnboardClient } from './../../store/types';
import { CompanyForm } from './companyForm';
import { PensionForm } from './pensionForm';
import { TrustForm } from './trustForm';
import { AccountTypeButton } from './types';

export interface QuickAddClientProps {
  onSave: (payload: QuickOnboardClient) => Promise<AnyAction | void>;
  fetchAfsls: () => void;
  onClose: () => void;
  loadingProgress: LoadingProgress;
  afsls: AfslItem[] | null;
}

export const QuickAddClient = ({ afsls, loadingProgress, onSave, onClose, fetchAfsls }: QuickAddClientProps): JSX.Element => {
  const callAdviserList = useCallback(() => {
    fetchAfsls();
  }, [fetchAfsls]);
  useEffect(() => {
    callAdviserList();
  }, [callAdviserList]);

  const AccountTypeButtons: AccountTypeButton[] = [
    { label: ClientAccountType.Company.displayName, accountTypeId: ClientAccountType.Company.id, accountSubTypeId: null },
    { label: ClientAccountType.Smsf.displayName, accountTypeId: ClientAccountType.Smsf.id, accountSubTypeId: null },
    { label: ClientAccountType.Individual.displayName, accountTypeId: ClientAccountType.Individual.id, accountSubTypeId: ClientAccountSubType.NonSuper.id },
    { label: 'Super', accountTypeId: ClientAccountType.Individual.id, accountSubTypeId: ClientAccountSubType.Super.id },
    { label: ClientAccountType.Joint.displayName, accountTypeId: ClientAccountType.Joint.id, accountSubTypeId: null },
    { label: ClientAccountType.Trust.displayName, accountTypeId: ClientAccountType.Trust.id, accountSubTypeId: null },
    { label: 'Pension', accountTypeId: ClientAccountType.Individual.id, accountSubTypeId: ClientAccountSubType.Pension.id },
  ];
  const initialFormValues: QuickOnboardClient = {
    entityName: '',
    entityType: EntityType.Client.id,
    adviserId: null,
    accountTypeId: null,
    accountSubTypeId: null,
    corporationTypeId: null,
    typeOfTrustId: null,
    trusteeTypeId: null,
    pensionTypeId: null,
  };

  // form data
  const clearUnusedFields = (accountTypeId: number, props: FormikProps<QuickOnboardClient>) => {
    // clear any fields that have values that shouldn't now because accountTypeId was changed
    props.setFieldValue('corporationTypeId', accountTypeId === ClientAccountType.Company.id ? props.values.corporationTypeId : null);

    props.setFieldValue(
      'trusteeTypeId',
      accountTypeId === ClientAccountType.Smsf.id || accountTypeId === ClientAccountType.Trust.id ? props.values.trusteeTypeId : null
    );

    props.setFieldValue(
      'typeOfTrustId',
      accountTypeId === ClientAccountType.Trust.id
        ? props.values.typeOfTrustId
        : accountTypeId === ClientAccountType.Smsf.id
        ? TrustType.Superannuation.id
        : null
    );

    if (accountTypeId !== ClientAccountType.Individual.id) {
      props.setFieldValue('accountSubTypeId', null);
    }
  };
  const allAdvisers =
    Array.isArray(afsls) && afsls.length > 0
      ? afsls.reduce((acc: AdviserItem[], afsl: AfslItem) => {
          const advisors =
            Array.isArray(afsl.practices) && afsl.practices.length > 0
              ? afsl.practices
                  .map((practice: PracticeItem) => practice.advisors)
                  .reduce((practiceA, practiceB) => practiceA.concat(practiceB))
                  .filter((advisor, index, self) => index === self.findIndex((t) => t.advisorId === advisor.advisorId))
                  .map((advisor) => ({ afslId: afsl.afslId, ...advisor }))
              : [];
          const allAdvisors = [...acc, ...advisors].sort((a, b) => a.advisorName.localeCompare(b.advisorName));
          return allAdvisors;
        }, [])
      : [];

  return (
    <WO2Modal
      MuiDialogProps={{
        open: true,
        maxWidth: 'md',
        onClose: (event, reason) => {
          if (reason !== 'backdropClick') {
            onClose();
          }
        },
      }}
      title={<>Create New Client</>}
      actions={[]}
    >
      <Formik<QuickOnboardClient>
        enableReinitialize={true}
        initialValues={initialFormValues}
        onSubmit={async (details: QuickOnboardClient) => {
          onSave(details).then(() => {
            onClose();
          });
        }}
        validationSchema={yup.object({
          entityName: yup.string().required('Entity name is required'),
          adviserId: yup.number().typeError('Adviser is required'),
          accountTypeId: yup.number().required('Account type is required'),
          accountSubTypeId: yup.number().nullable(),
          trusteeTypeId: yup
            .number()
            .nullable()
            .when('accountTypeId', {
              is: (accountTypeId) => accountTypeId === ClientAccountType.Trust.id || accountTypeId === ClientAccountType.Smsf.id,
              then: yup.number().required('Trustee type is required'),
            }),
          typeOfTrustId: yup
            .number()
            .nullable()
            .when('accountTypeId', {
              is: (accountTypeId) => accountTypeId === ClientAccountType.Trust.id,
              then: yup.number().required('Type of trust is required'),
            }),
          corporationTypeId: yup
            .number()
            .nullable()
            .when('accountTypeId', {
              is: (accountTypeId) => accountTypeId === ClientAccountType.Company.id,
              then: yup.number().required('Company type is required'),
            }),
          pensionTypeId: yup
            .number()
            .nullable()
            .when('accountSubTypeId', {
              is: (accountSubTypeId) => accountSubTypeId && accountSubTypeId === ClientAccountSubType.Pension.id,
              then: yup.number().required('Pension type is required'),
            }),
        })}
      >
        {(props: FormikProps<QuickOnboardClient>) => (
          <Form>
            <Grid container style={{ padding: '28px 0 0 0' }}>
              <Grid item xs={12} style={{ marginBottom: '16px' }}>
                <Field component={FormikTextField} name="entityName" label="ENTITY NAME" fullWidth />
              </Grid>
              <Grid item xs={12} style={{ marginBottom: '16px' }}>
                <Typography variant={'h5'}>
                  * For individual, super & pension clients please enter the First and Last name only as a corresponding member contact will be created. You can
                  later amend the entity label and add middle names to the contact.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {afsls !== null && (
                  <Field
                    fieldName="adviserId"
                    as={FormikSelect}
                    itemDisplayNameField="name"
                    label="ADVISER"
                    valueIsId={true}
                    data={allAdvisers.map((adviserItem: AdviserItem) => ({ id: adviserItem.advisorId, name: adviserItem.advisorName }))}
                    onChange={(advisorId: number | null) => {
                      if (!!advisorId) {
                        const selectedAdviser = allAdvisers.find((a: AdviserItem) => a.advisorId === advisorId);

                        if (!!selectedAdviser) {
                          props.setFieldValue('adviserId', selectedAdviser.advisorId);
                        }
                      }
                    }}
                    fullWidth
                  ></Field>
                )}
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <h3>Account type</h3>
              </Grid>
              {AccountTypeButtons.map((button: AccountTypeButton, index: number) => (
                <Grid item xs={3} key={index}>
                  <WO2Button
                    type="button"
                    variant="outlined"
                    fullWidth
                    selected={button.accountTypeId === props.values.accountTypeId && button.accountSubTypeId === props.values.accountSubTypeId}
                    onClick={() => {
                      props.setFieldValue('accountTypeId', button.accountTypeId);
                      props.setFieldValue('accountSubTypeId', button.accountSubTypeId);

                      clearUnusedFields(button.accountTypeId, props);
                      props.validateForm();
                    }}
                    data-testid={`quickAddClientAccountType_${button.label.replace(/\s/g, '')}`}
                  >
                    {button.label}
                  </WO2Button>
                </Grid>
              ))}
            </Grid>
            {props.values.accountTypeId === ClientAccountType.Company.id && <CompanyForm />}
            {(props.values.accountTypeId === ClientAccountType.Trust.id || props.values.accountTypeId === ClientAccountType.Smsf.id) && (
              <TrustForm isSmsf={props.values.accountTypeId === ClientAccountType.Smsf.id} />
            )}
            {props.values.accountSubTypeId === ClientAccountSubType.Pension.id && <PensionForm />}
            <Grid container style={{ marginTop: '48px' }}>
              <Grid item xs={12} style={{ textAlign: 'right' }}>
                <EditCancelSaveButtons mode={Mode.CancelSave} handleCancelClick={onClose} saveProgress={loadingProgress} />
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </WO2Modal>
  );
};
