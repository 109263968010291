import CloseOutlined from '@mui/icons-material/CloseOutlined';
import { Dialog, DialogActions, DialogContent, DialogProps, DialogTitle } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Breakpoint } from '@mui/system';
import { default as React } from 'react';
import { theme } from './../../themes';
import WO2Button from './button/Button';

export interface DialogActionButton {
  label: string;
  onClickHandler: (value?: number | string) => void;
  type?: 'text' | 'outlined' | 'contained' | undefined;
  disabled?: boolean | undefined;
}
export interface ModalProps {
  children: React.ReactElement;
  MuiDialogProps: DialogProps;
  title?: React.ReactElement;
  actions: DialogActionButton[];
  maxWidth?: Breakpoint | false;
}

export interface DialogActionButton {
  label: string;
  type?: 'text' | 'outlined' | 'contained' | undefined;
  buttonType?: 'button' | 'reset' | 'submit';
}

export function WO2Modal({ actions, children, MuiDialogProps, title, maxWidth }: ModalProps): JSX.Element {
  const useStyles = makeStyles(() => ({
    MuiDialogPaper: {
      padding: '12px',
      width: '100vw',
    },
    MuiDialogTitleRoot: {
      backgroundColor: theme.palette.lightGrey.main,

      '&.MuiDialogTitle-root': {
        fontSize: '1rem',
      },
    },
    MuiDialogActionsRoot: {
      padding: '0 24px 24px',
    },
    MuiDialogContentRoot: {
      padding: '24px 24px 12px',
    },
  }));
  const classes = useStyles();

  return (
    <Dialog
      onClose={MuiDialogProps.onClose}
      {...MuiDialogProps}
      {...(!!maxWidth && { maxWidth })}
      classes={{
        paper: classes.MuiDialogPaper,
      }}
    >
      <DialogTitle
        classes={{
          root: classes.MuiDialogTitleRoot,
        }}
      >
        {!!title && title}
        <CloseOutlined
          style={{ float: 'right', cursor: 'pointer' }}
          onClick={(event) => !!MuiDialogProps.onClose && MuiDialogProps.onClose(event, 'escapeKeyDown')}
        />
      </DialogTitle>
      <DialogContent
        classes={{
          root: classes.MuiDialogActionsRoot,
        }}
      >
        {children}
      </DialogContent>
      <DialogActions
        classes={{
          root: classes.MuiDialogContentRoot,
        }}
      >
        {actions.map((action: DialogActionButton, position: number) => (
          <WO2Button
            key={action.label}
            disableElevation={true}
            variant={action.type ? action.type : 'outlined'}
            value={action.label}
            onClick={() => action.onClickHandler()}
            type={action.buttonType}
            data-testid={`wo2Modal_action_${position}`}
            style={{ paddingRight: '12px' }}
            disabled={action.disabled ?? false}
          >
            {action.label}
          </WO2Button>
        ))}
      </DialogActions>
    </Dialog>
  );
}
