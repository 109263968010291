import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { progressSelectorFactory } from '../../../../common';
import { RootState } from '../../../../reducers';
import { selectMonthlyDashboardChart, selectParameters } from '../store/selectors';
import { strategyDashboardSlice } from '../store/slice';
import { fetchMonthlyDashboardChart, StrategyDashboardActionTypes } from '../store/thunks';
import { MonthlyPerformanceChartComponent } from './MonthlyPerformanceChartComponent';

const actionTypes = [StrategyDashboardActionTypes.FetchMonthlyDashboardChart];

const mapStateToProps = (state: RootState) => ({
  loadingProgress: progressSelectorFactory(actionTypes)(state),
  parameters: selectParameters(state),
  monthlyDashboardChart: selectMonthlyDashboardChart(state),
});

const mapDispatchToProps = {
  fetchMonthlyDashboardChart,
  setChartDateParameter: strategyDashboardSlice.actions.setChartDateParameter,
};

interface MatchParams {
  strategyId?: string;
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector> & RouteComponentProps<MatchParams>;
export const MonthlyPerformanceChartContainer = connect(mapStateToProps, mapDispatchToProps)(MonthlyPerformanceChartComponent);
