import { Box } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { EnumerationSelect } from 'src/common/components/EnumerationSelect';
import { Enumeration } from 'src/common/types/enumeration';
import { FilterOperator, getOperator, NumericFilterValue, OperatorSelect, StringFilterValue } from '..';
import { useStyles } from '../../../themes/dataTableStyles';
import { FilterDataType } from '../../types';
import { filterOperator } from '../filterOperators';

interface Props {
  dataType: FilterDataType;
  defaultStringFilterOperator?: string;
  defaultNumericFilterOperator?: string;
  operator?: FilterOperator;
  value?: string;
  fieldLabel: string;
  onFilterChange: (operator: FilterOperator, value: string) => void;
  enumerationType?: typeof Enumeration;
}

export const Filter = (props: Props): JSX.Element => {
  const classes = useStyles();
  const defaultTypeOperator = (dataType: FilterDataType, defaultStringFilterOperator?: string, defaultNumericOperator?: string): string => {
    switch (dataType) {
      case FilterDataType.numeric:
        return defaultNumericOperator || filterOperator.isEqual;
      case FilterDataType.string:
        return defaultStringFilterOperator || filterOperator.contains;
      case FilterDataType.enumeration:
        return defaultStringFilterOperator || filterOperator.contains;
      default:
        return filterOperator.isEqual;
    }
  };

  const defaultOperator = getOperator(defaultTypeOperator(props.dataType, props.defaultStringFilterOperator, props.defaultNumericFilterOperator));

  const [operator, setOperator] = useState<FilterOperator>(defaultOperator);
  const [value, setValue] = useState<string | number>('');

  const onOperatorChange = useCallback(() => {
    setOperator(props.operator || defaultOperator);
  }, [props.operator, defaultOperator]);

  useEffect(onOperatorChange, [props.operator]);

  useEffect(() => {
    setValue(props.value || '');
  }, [props.value]);

  const getValueComponent = (props: Props): JSX.Element => {
    switch (props.dataType) {
      case FilterDataType.numeric:
        return (
          <NumericFilterValue
            value={value.toString()}
            onChange={(value: string) => {
              setValue(value);
              props.onFilterChange(operator, value);
            }}
          ></NumericFilterValue>
        );
      case FilterDataType.enumeration:
        return !!props.enumerationType ? (
          <Box sx={{ minWidth: '180px' }}>
            <EnumerationSelect
              valueIsId={true}
              type={props.enumerationType}
              value={value}
              onChange={(itemValue: number | string | null) => {
                const val = itemValue || '';
                setValue(val);
                props.onFilterChange(operator, val.toString());
              }}
            ></EnumerationSelect>
          </Box>
        ) : (
          <div>please supply enumerationType</div>
        );
      case FilterDataType.string:
      default:
        return (
          <StringFilterValue
            value={value.toString()}
            onChange={(value: string) => {
              setValue(value);
              props.onFilterChange(operator, value);
            }}
          ></StringFilterValue>
        );
    }
  };

  return (
    <div className={classes.filterContainer}>
      <div className={classes.filterLabel}>{props.fieldLabel}</div>
      <OperatorSelect
        dataType={props.dataType}
        operator={operator}
        onChange={(operator: FilterOperator) => {
          setOperator(operator);
          props.onFilterChange(operator, value.toString());
        }}
      ></OperatorSelect>
      {getValueComponent(props)}
    </div>
  );
};
