import { Box, TableCell, TableFooter, TableRow } from '@mui/material';
import MUIDataTable, { MUIDataTableColumn, MUIDataTableColumnOptions, MUIDataTableOptions } from 'mui-datatables';
import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import PulseLoader from 'react-spinners/PulseLoader';
import { theme } from 'src/themes';
import { LoadingProgress } from '../../../store/types';
import { Pagination } from '../../pagination';
import { useStyles } from '../../themes/dataTableStyles';
import { Filter, FilterOperator, filterOperator, getOperator } from '../common';
import { DatatableColumn, FilterDataType } from '../types';
import Button from './../../button/Button';
import { getColumnWidth, getTotalColumnWidth } from './../functions';

export interface ClientSideDataTableProps<T> {
  data: T[];
  fixedWidth?: boolean;
  columns: DatatableColumn[];
  defaultNumericFilterOperator?: string;
  defaultStringFilterOperator?: string;
  options: MUIDataTableOptions;
  loadingProgress: LoadingProgress;
  className?: string;
  id?: string;
  handleRowClick?: (rowData: string[], rowMeta: { dataIndex: number; rowIndex: number }) => void;
  enablePagination?: boolean;
  noDataLabel?: string;
}

export function ClientSideDataTable<T>(props: ClientSideDataTableProps<T>): JSX.Element {
  const classes = useStyles();
  const { loadingProgress, id, defaultNumericFilterOperator, defaultStringFilterOperator, options, enablePagination } =
    props;
  const isFilterEnabled = !!options.filter;

  const [mUIDataTableColumnDefs, setMUIDataTableColumnDefs] = useState<DatatableColumn[]>([]);
  const noDataLabel = props.noDataLabel ? props.noDataLabel : 'No data to display';

  const [icon, setIcon] = useState<ReactNode | null>(null);

  const handleFilterDisplay = useCallback(
    (
      filterList: string[][],
      onChange: (val: string | string[], index: number, column: MUIDataTableColumn) => void,
      index: number,
      displayColumn: MUIDataTableColumn,
      column: DatatableColumn
    ) => {
      return (
        <Filter
          dataType={column.filterDataType ?? FilterDataType.string}
          enumerationType={column.enumerationType}
          operator={getOperator((filterList[index] as string[])[0])}
          value={(filterList[index] as string[])[1]}
          fieldLabel={displayColumn.label ?? ''}
          defaultStringFilterOperator={defaultStringFilterOperator}
          defaultNumericFilterOperator={defaultNumericFilterOperator}
          onFilterChange={(operator: FilterOperator, value: string) => {
            filterList[index] = !!value ? [operator.name, value, displayColumn?.label ?? ''] : [];
            onChange(filterList[index], index, displayColumn);
          }}
        />
      );
    },
    [defaultNumericFilterOperator, defaultStringFilterOperator]
  );

  const handleFilterLogic = useCallback(
    (prop: string | null, filterValue: string[]) => {
      let result = true;
      if (prop === null) {
        return true;
      } else if (prop === undefined) {
        return false;
      } else if (filterValue.length > 1) {
        const comparedValue = prop.toString().trim().toLowerCase();
        const filterKeyword = filterValue[1]?.toLowerCase();
        switch (filterValue[0]) {
          case filterOperator.isEqual:
            result = filterKeyword === comparedValue;
            break;
          case filterOperator.notEqual:
            result = filterKeyword !== comparedValue;
            break;
          case filterOperator.contains:
            result = !!comparedValue && comparedValue.includes(filterKeyword);
            break;
          case filterOperator.doesNotContain:
            result = !!comparedValue && !comparedValue.includes(filterKeyword);
            break;
          case filterOperator.greaterThan:
            result = comparedValue > filterKeyword;
            break;
          case filterOperator.greaterThanOrEqualTo:
            result = comparedValue >= filterKeyword;
            break;
          case filterOperator.lessThan:
            result = comparedValue < filterKeyword;
            break;
          case filterOperator.lessOrEqualTo:
            result = comparedValue <= filterKeyword;
            break;
          default:
            break;
        }
      }
      return !result;
    },
    [filterOperator]
  );

  useEffect(() => {
    if (loadingProgress?.isLoading) {
      setIcon(
        <div className="LoadingIndicator" style={{ paddingTop: '14px' }}>
          <PulseLoader size="12px" margin="5px" color={theme.palette.grey[400]} />
        </div>
      );
    } else {
      setIcon(<></>);
    }
  }, [loadingProgress]);

  useEffect(() => {
    setMUIDataTableColumnDefs(
      props.columns.map((column: DatatableColumn) => {
        // merge the incoming columns with settings below
        const baseOptions: MUIDataTableColumnOptions = {
          setCellProps: () => {
            if (props.fixedWidth) {
              const totalColumnWidth = getTotalColumnWidth(props.columns);
              const columnWidth = getColumnWidth((column.label || '').length, totalColumnWidth);

              return {
                style: {
                  textAlign: column.textAlign || 'left',
                  borderBottom: 'none',
                  width: columnWidth + '%',
                },
              };
            }

            return {
              style: {
                textAlign: column.textAlign || 'left',
                borderBottom: 'none',
              },
            };
          },
          setCellHeaderProps: () => {
            if (column.options?.sort === true) {
              if (column.textAlign === 'right') {
                return {
                  className: classes.headerHorizontalAlignRight,
                };
              } else if (column.textAlign === 'center') {
                return {
                  className: 'CenterAlign',
                };
              }
            }
            if (column.options?.sort === false && (column.textAlign === 'right' || column.textAlign === 'center')) {
              return {
                style: { textAlign: column.textAlign },
              };
            }
            return {};
          },
          ...(isFilterEnabled && {
            display: true,
            filterType: 'custom',
            filterOptions: {
              fullWidth: true,
              display: (
                filterList: string[][],
                onChange: (val: string | string[], index: number, column: MUIDataTableColumn) => void,
                index: number,
                displayColumn: MUIDataTableColumn
              ) => handleFilterDisplay(filterList, onChange, index, displayColumn, column),
              logic: (prop: string, filterValue: string[]) => handleFilterLogic(prop, filterValue),
            },
            customFilterListOptions: {
              render: (filter: string[]) => {
                const filterColumn = props.columns.find((c) => c.label === filter[2]);
                const value = !!filterColumn?.renderFilterChipValue
                  ? filterColumn.renderFilterChipValue(filter[1])
                  : filter[1];
                return `${filter[2]} ${getOperator(filter[0])?.chipLabel} ${value}`;
              },
              update: (filterList: string[][], _filterPos: number, index: number) => {
                if (index >= 0) {
                  filterList[index] = [];
                }
                return filterList;
              },
            },
          }),
        };

        return {
          ...column,
          options: {
            ...baseOptions,
            ...column.options,
          },
        };
      })
    );
  }, [props.columns, props.fixedWidth, handleFilterDisplay, handleFilterLogic]);

  const updatedOptions: MUIDataTableOptions = {
    elevation: 5,
    download: false,
    print: false,
    selectableRows: 'none',
    selectableRowsHideCheckboxes: true,
    selectToolbarPlacement: 'none',
    fixedHeader: true,
    responsive: 'vertical',
    serverSide: false,
    pagination: enablePagination,
    rowsPerPageOptions: [5, 10, 20],
    rowsPerPage: 25,
    search: false,
    fixedSelectColumn: false,
    ...(isFilterEnabled && {
      filterType: 'multiselect',
      confirmFilters: true,
      customFilterDialogFooter: function renderFilterDialogFooter(_currentFilterList, applyNewFilters) {
        return (
          <Box marginTop="40px">
            <Button
              className={classes.apply}
              style={{ width: 'auto' }}
              variant="contained"
              color={'primary'}
              onClick={() => {
                if (!!applyNewFilters) {
                  applyNewFilters();
                }
              }}
            >
              Apply Filters
            </Button>
          </Box>
        );
      },
    }),
    setRowProps: () => {
      return {
        className: !!props.handleRowClick ? classes.trClickable : classes.tr,
      };
    },
    onRowClick: props.handleRowClick || undefined,
    customFooter:
      props.options.customFooter ||
      function renderFooter(
        rowCount: number,
        page: number,
        rowsPerPage: number,
        changeRowsPerPage: (page: number) => void,
        changePage: (newPage: number) => void
      ) {
        return rowCount > rowsPerPage ? (
          <TableFooter>
            <TableRow>
              <TableCell className={classes.footer}>
                <Pagination
                  numberOfPages={Math.ceil(rowCount / rowsPerPage)}
                  handleClickPagination={(page) => changePage(page - 1)}
                  pageNumber={page + 1}
                />
              </TableCell>
            </TableRow>
          </TableFooter>
        ) : (
          <TableFooter></TableFooter>
        );
      },
    textLabels: {
      ...props.options.textLabels,
      body: { ...props.options.textLabels?.body, noMatch: noDataLabel },
    },
    ...props.options,
    customToolbar: function customToolbar() {
      return (
        <>
          {icon}
          {!!props.options.customToolbar ? props.options.customToolbar({ displayData: [] }) : null}
        </>
      );
    },
  };

  return (
    <Box overflow={'initial'} className={props.className} data-testid={id}>
      <MUIDataTable
        title={''}
        data={(props.data as Record<string, unknown>[]) || []}
        columns={mUIDataTableColumnDefs}
        options={updatedOptions}
      />
    </Box>
  );
}
