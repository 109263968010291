import _ from 'lodash';
import { SuperMemberData } from '../store/types';

export const checkDuplicateMemberRecords = (membersData: SuperMemberData[]): string => {
  let errors = '';

  const duplicates: SuperMemberData[] = _.flow([
    (memberNumber) => _.groupBy(memberNumber, 'MemberNumber'),
    (groupedByMemberNumber) => _.filter(groupedByMemberNumber, (group) => group.length > 1),
    _.flatten,
  ])(membersData);
  if (duplicates && duplicates.length > 0) {
    errors = `Duplicate member records found: ${JSON.stringify(duplicates)}`;
  }
  return errors;
};
