import { Route } from '../../../store';
import RebalanceCheckoutContainer from './checkout/index';
import RebalanceEditContainer from './edit/index';
import RebalanceLoadingContainer from './loading/index';

export const rebalanceRoutes: Route[] = [
  {
    path: '/rebalance/loading',
    component: RebalanceLoadingContainer,
    protected: false,
    exact: true,
  },
  {
    path: '/rebalance',
    component: RebalanceEditContainer,
    protected: true,
    exact: true,
  },
  {
    path: '/rebalance/checkout',
    component: RebalanceCheckoutContainer,
    protected: true,
    exact: true,
  },
];
