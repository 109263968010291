import { DatatableColumn } from './types';

export const getColumnWidth = (columnLabelLength: number, totalColumnLength: number): number => (columnLabelLength / totalColumnLength) * 100;

export const getTotalColumnWidth = (columns: Array<DatatableColumn>): number => {
  let totalColumnWidth = 0;

  columns.forEach((column) => {
    totalColumnWidth = totalColumnWidth + (column.label || '').length || 0;
  });

  return totalColumnWidth;
};
