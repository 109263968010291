import { DeleteOutlined, EditOutlined, FileDownloadOutlined, VisibilityOutlined } from '@mui/icons-material';
import AddOutlined from '@mui/icons-material/AddOutlined';
import { Grid, IconButton, Typography } from '@mui/material';
import { default as React, useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { useConfirmation } from 'src/common/components/dialogs';
import { DateTimeFormat, getLocalDateTime } from 'src/common/utils/dateFunctions';
import { AttachmentType } from 'src/features/clients/common/enums';
import { filterOperator } from '../../../../../../common/components/dataTable/common';
import { ServerSideDataTable } from '../../../../../../common/components/dataTable/serverSide';
import { DatatableColumn, FilterDataType } from '../../../../../../common/components/dataTable/types';
import { LoadingProgress } from '../../../../../../common/store/types';
import { PagedRequest, PagedResult } from '../../../../../../store';
import { theme } from '../../../../../../themes';
import { useStyles as commonStyles } from '../../../../../../themes/index';
import { AttachmentExtension } from '../../store/enums';
import {
  DocumentDetails,
  DocumentType,
  identificationDocumentPoints,
  identificationDocumentTypes,
  SaveDocumentDetails,
} from '../../store/types';
import { useStyles } from '../themes/light/documentsStyles';
import { Document } from './document';

export interface DocumentsTableProps {
  type: 'Documents' | 'Identifications';
  documentTypes: DocumentType[];
  documents: PagedResult<DocumentDetails>;
  pagination: PagedRequest;
  selectedDocument?: DocumentDetails | null;
  hideAllActions?: boolean;
  hideTypeTitle?: boolean;
  hideDocumentUpload?: boolean;
  showRequiredAsterisk?: boolean;
  hideColumns?: string[];
  progress: LoadingProgress;
  saveProgress: LoadingProgress;
  handleGridActions: (pagedRequest: PagedRequest) => void;
  handleDownloadDocument: (attachmentId: number, filename: string) => void;
  onSave: (document: SaveDocumentDetails) => void;
  onAddEdit: (id: number | null | undefined) => void;
  onDelete: (documentId: number) => void;
  fetchDocumentTypes: () => void;
  hasClientEditAdminOnlyPermission: boolean | undefined;
}

enum ApplicationType {
  Uxapp = 42,
  SuperSimplifierPension = 44,
  SuperSimplifierSuper = 45,
}

export const DocumentsTable = (props: DocumentsTableProps): JSX.Element => {
  const confirm = useConfirmation();
  const classes = useStyles();
  const commonClasses = commonStyles();
  const {
    type,
    documentTypes,
    documents,
    pagination,
    selectedDocument,
    onSave,
    onAddEdit,
    onDelete,
    handleGridActions,
    handleDownloadDocument,
    progress,
    saveProgress,
    hideAllActions,
    hideTypeTitle,
    hideColumns,
    hideDocumentUpload,
    showRequiredAsterisk,
    fetchDocumentTypes,
    hasClientEditAdminOnlyPermission,
  } = props;

  const [rows, setRows] = useState<DocumentDetails[]>([]);

  useEffect(() => {
    setRows(!!documents ? documents.results : []);
  }, [documents]);

  const blankDocument: DocumentDetails = {
    id: null,
    name: '',
    fileName: '',
    typeId: null,
    extensionId: null,
    issueDate: null,
    expiryDate: null,
    number: '',
    placeOfIssue: '',
    isPhotoShown: null,
    isEditable: true,
    type: '',
  };

  const extensionAndNameColumn = (dataIndex: number): React.ReactNode => {
    const extension = !!rows[dataIndex]?.extensionId
      ? AttachmentExtension.getById(rows[dataIndex].extensionId as number)
      : null;
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ display: 'inline-flex' }}>
          <Grid
            container
            style={{
              width: 'auto',
              height: '50px',
              borderRadius: '50%',
              justifyContent: 'center',
              alignItems: 'center',
              marginRight: '10px',
              color: theme.palette.text.primary,
            }}
          >
            {!!extension && <extension.icon></extension.icon>}
          </Grid>
        </div>
        <Typography style={{ fontWeight: 'normal', display: 'inline-flex', color: theme.palette.text.primary }}>
          {rows[dataIndex]?.name}
        </Typography>
      </div>
    );
  };

  const typeColumn = (dataIndex: number): React.ReactNode => {
    return (
      <Typography variant="h5">
        {AttachmentType.getById(rows[dataIndex]?.typeId)?.displayName || rows[dataIndex]?.type}
      </Typography>
    );
  };

  const dateCreatedColumn = (dataIndex: number): React.ReactNode => {
    return (
      <Typography variant="h5">{getLocalDateTime(rows[dataIndex]?.dateCreated, DateTimeFormat.DateTime)}</Typography>
    );
  };

  const expiryDateColumn = (dataIndex: number): React.ReactNode => {
    return (
      <Typography variant="h5">
        {getLocalDateTime(rows[dataIndex]?.expiryDate || undefined, DateTimeFormat.Short)}
      </Typography>
    );
  };

  const pointsColumn = (dataIndex: number): React.ReactNode => {
    const documentPoints = identificationDocumentPoints.find(
      (documentPoint) => documentPoint.id === (rows[dataIndex]?.typeId || 0)
    );

    return (
      <Typography variant="h5" style={{ textAlign: 'center' }}>
        {documentPoints?.points || 0}
      </Typography>
    );
  };

  const getFileNameWithExtension = (dataIndex: number) => {
    let filename = rows[dataIndex].fileName;
    if (filename === null || filename === undefined || filename?.lastIndexOf('.') === -1) {
      filename = rows[dataIndex].name;
    }
    const extensionIndex = filename?.lastIndexOf('.');
    return extensionIndex !== -1 ? filename : `${filename}.${AttachmentExtension.Pdf.name}`;
  };
  const downloadColumn = (dataIndex: number): React.ReactNode => {
    let downloadDocument = true;
    const uxappDate = DateTime.fromFormat('03/31/2023 00:00:00.000 AM', 'D hh:mm:ss.SSS a').toMillis();
    const superSimplifierPensionDate = DateTime.fromFormat('07/10/2022 00:00:00.000 AM', 'D hh:mm:ss.SSS a').toMillis();
    const superSimplifierSuperDate = DateTime.fromFormat('07/10/2022 00:00:00.000 AM', 'D hh:mm:ss.SSS a').toMillis();
    const dateCreatedMillis = DateTime.fromISO(rows[dataIndex]?.dateCreated || '').toMillis();

    if (rows[dataIndex]?.typeId === ApplicationType.Uxapp && dateCreatedMillis < uxappDate) {
      downloadDocument = false;
    }
    if (
      rows[dataIndex]?.typeId === ApplicationType.SuperSimplifierPension &&
      dateCreatedMillis < superSimplifierPensionDate
    ) {
      downloadDocument = false;
    }
    if (
      rows[dataIndex]?.typeId === ApplicationType.SuperSimplifierSuper &&
      dateCreatedMillis < superSimplifierSuperDate
    ) {
      downloadDocument = false;
    }

    return (
      <Grid container className={classes.download}>
        {!!rows[dataIndex] && rows[dataIndex].id && (downloadDocument || hasClientEditAdminOnlyPermission) && (
          <IconButton
            disableFocusRipple
            disableRipple
            onClick={() => {
              const id = rows[dataIndex].id;
              const filename = getFileNameWithExtension(dataIndex);
              if (!!id) {
                handleDownloadDocument(id, `${filename}`);
              }
            }}
          >
            <FileDownloadOutlined className={classes.downloadIcon}></FileDownloadOutlined>
          </IconButton>
        )}
      </Grid>
    );
  };

  const actionsColumn = (dataIndex: number): React.ReactNode => {
    return (
      <>
        {!!rows[dataIndex] && rows[dataIndex].isEditable && (
          <>
            <IconButton
              data-testid={`editButton_${dataIndex}`}
              disableFocusRipple
              disableRipple
              onClick={() => {
                onAddEdit(documents.results[dataIndex].id);
              }}
            >
              <EditOutlined color={'primary'} />
            </IconButton>
            <IconButton
              data-testid={`deleteButton_${dataIndex}`}
              disableFocusRipple
              disableRipple
              onClick={() => {
                confirm({
                  title: 'Delete document',
                  description: 'Are you sure you wish to delete this identification record?',
                  description2:
                    'This action is permanent, please ensure you have selected the correct contact before proceeding.',
                }).then(() => {
                  const id = documents.results[dataIndex].id;
                  !!id && onDelete(id);
                });
              }}
            >
              <DeleteOutlined color={'primary'} />
            </IconButton>
          </>
        )}
        {!!rows[dataIndex] && !rows[dataIndex].isEditable && (
          <IconButton
            data-testid={`viewButton_${dataIndex}`}
            disableFocusRipple
            disableRipple
            onClick={() => {
              onAddEdit(documents.results[dataIndex].id);
            }}
          >
            <VisibilityOutlined color={'primary'} />
          </IconButton>
        )}
      </>
    );
  };

  const addButton = (): React.ReactNode => {
    return (
      <IconButton
        className={commonClasses.addToTable}
        disableFocusRipple
        disableRipple
        onClick={() => {
          onAddEdit(null);
        }}
      >
        <AddOutlined />
      </IconButton>
    );
  };

  let columns: DatatableColumn[] = [
    {
      filterDataType: FilterDataType.string,
      name: 'name',
      label: 'NAME',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex): React.ReactNode => extensionAndNameColumn(dataIndex),
        setCellProps: () => {
          return {
            className: classes.td,
          };
        },
      },
    },
    {
      filterDataType: FilterDataType.string,
      name: 'type',
      label: 'DOCUMENT TYPE',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex): React.ReactNode => typeColumn(dataIndex),
        setCellProps: () => {
          return {
            className: classes.td,
          };
        },
      },
    },
    {
      filterDataType: FilterDataType.date,
      textAlign: 'left',
      name: 'created',
      label: 'DATE CREATED',
      options: {
        filter: false,
        sort: true,
        customBodyRenderLite: (dataIndex): React.ReactNode => dateCreatedColumn(dataIndex),
        setCellProps: () => {
          return {
            className: classes.td,
          };
        },
      },
    },
    {
      filterDataType: FilterDataType.date,
      textAlign: 'left',
      name: 'expiry',
      label: 'EXPIRY DATE',
      options: {
        filter: false,
        sort: true,
        customBodyRenderLite: (dataIndex): React.ReactNode => expiryDateColumn(dataIndex),
        setCellProps: () => {
          return {
            className: classes.td,
          };
        },
      },
    },
    {
      name: 'points',
      label: 'POINTS',
      textAlign: 'center',
      options: {
        filter: false,
        sort: true,
        customBodyRenderLite: (dataIndex): React.ReactNode => pointsColumn(dataIndex),
        setCellProps: () => {
          return {
            className: classes.td,
          };
        },
      },
    },
    {
      textAlign: 'center',
      name: 'DOWNLOAD',
      label: '',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex): React.ReactNode => downloadColumn(dataIndex),
        setCellProps: () => {
          return {
            className: classes.td,
          };
        },
      },
    },
    {
      name: 'actions',
      label: 'ACTIONS',
      textAlign: 'center',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex: number): React.ReactNode => actionsColumn(dataIndex),
      },
    },
  ];

  // remove columns needs to be hidden (exclude actions)
  if (hideColumns && hideColumns.length > 0) {
    columns = columns.filter(
      (column: DatatableColumn) =>
        hideColumns.map((hideColumn: string) => hideColumn.toLowerCase()).indexOf(column.name.toLowerCase()) === -1
    );
  }

  return (
    <>
      {!hideTypeTitle && (
        <Typography variant="h4" gutterBottom style={{ paddingTop: '20px' }}>
          {type}
        </Typography>
      )}
      <ServerSideDataTable
        loadingProgress={progress}
        columns={hideAllActions ? columns.filter((c) => c.name !== 'actions') : columns}
        pagedRequest={pagination}
        pagedResult={documents || undefined}
        handlePagedRequest={handleGridActions}
        options={{
          customToolbar: () => !hideAllActions && addButton(),
        }}
        defaultStringFilterOperator={filterOperator.contains}
        defaultNumericFilterOperator={filterOperator.isEqual}
      ></ServerSideDataTable>
      {selectedDocument !== undefined && (
        <Document
          type={type}
          documentTypes={documentTypes.filter((d) =>
            // filter in or out the identificationDocumentTypes
            type === 'Documents'
              ? !identificationDocumentTypes.includes(d.id)
              : identificationDocumentTypes.includes(d.id)
          )}
          onSave={onSave}
          handleCloseModal={() => onAddEdit(undefined)}
          document={selectedDocument || blankDocument}
          fetchDocumentTypes={fetchDocumentTypes}
          saveProgress={saveProgress}
          hideDocumentUpload={hideDocumentUpload}
          showRequiredAsterisk={!!showRequiredAsterisk}
        ></Document>
      )}
    </>
  );
};
