import _ from 'lodash';
import { RootState } from '../../../store';

export const hasErrorsSelector = (state: RootState, actionTypes: string[]): boolean => {
  // returns true only when all actions is not loading
  return _(actionTypes).some((actionType) => {
    const matches = /(.*)\/(Request|pending|Success|fulfilled|Failed|rejected)/.exec(actionType);

    if (!matches) return false;

    const [, requestName] = matches;

    return state.messages?.hasOwnProperty(requestName) ? state.messages[requestName] != null : false;
  });
};

export default hasErrorsSelector;
