import { Container, Grid } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { usePDF } from 'react-to-pdf';
import { RouteComponentProps } from 'react-router-dom';
import { ClientPortfolioDetailsContainer } from './ClientPortfolioDetailsContainer';
import GainLossCard from './GainLossCard';
import { MonthlyPerformanceChartContainer } from './MonthlyPerformanceChartContainer';
import PortfolioValueCard from './PortfolioValueCard';
import { RecentTransactionsContainer } from './RecentTransactionsContainer';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import WO2Button from 'src/common/components/button/Button';
import PdfFooter from '../../common/components/PdfFooter';
import { EntityDetails } from '../../common/store/types';
import PdfHeader from '../../common/components/PdfHeader';

interface Props extends RouteComponentProps {
  entityDetails?: EntityDetails;
}

export const DashboardComponent = (props: Props): JSX.Element => {
  const [showPdfFooterAndHeader, setShowPdfFooterAndHeader] = useState(false);
  const { toPDF, targetRef } = usePDF({ filename: props.entityDetails?.name + '_Dashboard' });

  useEffect(() => {
    if (showPdfFooterAndHeader) {
      toPDF();
      setShowPdfFooterAndHeader(false);
    }
  }, [showPdfFooterAndHeader]);

  const generatePdf = () => {
    setShowPdfFooterAndHeader(true);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={11}>
          {' '}
        </Grid>
        <Grid item xs={1}>
          <WO2Button
            type="button"
            data-testid="exportPriceButton"
            variant={'contained'}
            startIcon={<PictureAsPdfOutlinedIcon />}
            onClick={generatePdf}
            style={{ marginTop: '5px', marginBottom: '5px', paddingTop: '5px', paddingBottom: '5px' }}
          >
            Export
          </WO2Button>
        </Grid>
      </Grid>

      <div ref={targetRef}>
        {showPdfFooterAndHeader && <PdfHeader clientName={props.entityDetails?.name + ' - Dashboard'} />}
        <MonthlyPerformanceChartContainer />
        <Container style={{ paddingTop: '20px', paddingBottom: '20px' }}>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <PortfolioValueCard />
                </Grid>
                <Grid item xs={6}>
                  <GainLossCard />
                </Grid>
                <Grid item xs={12}>
                  <ClientPortfolioDetailsContainer {...props} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <RecentTransactionsContainer {...props} />
            </Grid>
          </Grid>
        </Container>
        {showPdfFooterAndHeader && <PdfFooter />}
      </div>
    </>
  );
};
