import { Add as AddIcon, Cancel as CancelIcon, Delete as DeleteIcon, Edit as EditIcon, Save as SaveIcon } from '@mui/icons-material';
import { Box, IconButton, LinearProgress, Paper, Stack, styled, Typography } from '@mui/material';
import { GridToolbarContainer } from '@mui/x-data-grid';
import {
  DataGridPro,
  GridActionsCellItem,
  GridColumns,
  gridEditRowsStateSelector,
  GridPreProcessEditCellProps,
  GridRenderCellParams,
  GridRowModes,
  GridRowParams,
  GridValueGetterParams,
  MuiBaseEvent,
  MuiEvent,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import React, { useCallback, useEffect, useState } from 'react';
import PulseLoader from 'react-spinners/PulseLoader';
import { AssetClassEnum } from 'src/common/enums/assetClasses';
import { LoadingProgress } from 'src/common/store/types';
import * as yup from 'yup';
import { theme } from '../../../../themes';
import { StrategicAssetAllocation } from '../../store/types';

const StyledBox = styled(Box)(() => ({
  width: '100%',
  '& .MuiDataGrid-cell--editing': {
    backgroundColor: 'rgb(255,215,115, 0.19)',
    color: '#1a3e72',
    '& .MuiInputBase-root': {
      height: '100%',
    },
  },
  '& .Mui-error': {
    backgroundColor: `#FFCCCC`,
    color: 'red',
    borderRadius: '4px',
    borderColor: 'red',
    borderStyle: 'solid',
    borderWidth: '1px',
  },
  '& .MuiDataGrid-editInputCell': {
    borderWidth: '2px',
  },
  '& input[type=number]': {
    textAlign: 'right',
    paddingRight: '0px',
  },
  '& .MuiDataGrid-toolbarContainer': {
    justifyContent: 'flex-end',
  },
}));

export interface StrategicAllocationTableProps {
  items: StrategicAssetAllocation[];
  itemLoadingProgress: LoadingProgress;
  onSave?: (strategicAllocationItems: StrategicAssetAllocation[]) => Promise<void>;
  savingProgress: LoadingProgress;
  hideEditControls?: boolean;
}

interface DataRow {
  id: string;
  assetClassId: number | null | undefined;
  allocation: number;
}

export const StrategicAllocationTable = (props: StrategicAllocationTableProps): JSX.Element => {
  const { items, itemLoadingProgress, onSave, savingProgress, hideEditControls } = props;
  const [dataRows, setDataRows] = useState<DataRow[]>([]);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [unallocatedAllocation, setUnallocatedAllocation] = useState<number>(0);
  const [totalAllocationValidationMessage, setTotalAllocationValidationMessage] = useState<string>('');

  const apiRef = useGridApiRef();

  const allocationValidationSchema = yup.number().required().min(0).max(100);
  const validationSchema = yup.object({
    unallocatedAllocation: yup.number(),
    strategicAllocations: yup
      .array()
      .of(
        yup.object().shape({
          id: yup.string(),
          assetClassId: yup.number().nullable().required('Asset class is required'),
          allocation: allocationValidationSchema,
        })
      )
      .test('test-total-allocation', 'Total allocation is not 100%', (strategicAllocations: (DataRow | undefined)[] | null | undefined): boolean => {
        if (!strategicAllocations) return false;
        const totalAllocations = strategicAllocations
          .filter((a) => !!a)
          .map((a) => a?.allocation || 0)
          .reduce((partialSum, a) => partialSum + a, 0);
        return !!strategicAllocations && totalAllocations + unallocatedAllocation === 100;
      }),
  });

  useEffect(() => {
    resetDataRows();
  }, [items]);

  const resetDataRows = useCallback(() => {
    const itemsToProcess = items
      .map((i, index) => ({
        id: (index + 1).toString(),
        assetClassId: i.assetClassId,
        allocation: i.allocation,
      }))
      .filter((i) => i.assetClassId !== AssetClassEnum.Unallocated.id);

    setDataRows([...itemsToProcess]);

    updateUnallocatedWeight(itemsToProcess.map((i) => i.allocation));
  }, [items, dataRows]);

  const columns: GridColumns = [
    {
      field: 'assetClassId',
      headerName: 'ASSET CLASS',
      type: 'singleSelect',
      flex: 2,
      editable: true,
      sortable: false,
      renderCell: (params: GridRenderCellParams<number>) => {
        return <div>{AssetClassEnum.getById(params.value)?.displayName}</div>;
      },
      valueOptions: (params) => {
        const assetClasseIdsInUse = Object.entries(gridEditRowsStateSelector(apiRef.current.state))
          .filter((r) => r[0] !== params.id)
          .map((r) => r[1].assetClassId.value);

        return [...AssetClassEnum.getAll()]
          .filter((e) => !assetClasseIdsInUse.includes(e.id))
          .map((c) => ({ value: c.id, label: c.displayName }))
          .sort((a, b) => a.label.localeCompare(b.label));
      },
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        return { ...params.props, error: params.props.value === '' };
      },
      valueGetter: (params: GridValueGetterParams) => {
        return params.value !== null ? params.value : '';
      },
    },
    {
      field: 'allocation',
      type: 'number',
      headerName: 'WEIGHT (%)',
      flex: 1,
      width: 220,
      editable: true,
      sortable: false,
      preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
        const allocations = Object.entries(gridEditRowsStateSelector(apiRef.current.state)).map((r) =>
          r[0] === params.id ? +params.props.value : +r[1].allocation.value
        );

        updateUnallocatedWeight(allocations);
        const valid = allocationValidationSchema.isValidSync(params.props.value);
        return { ...params.props, error: !valid };
      },
    },
    {
      field: 'actions',
      type: 'actions',
      sortable: false,
      width: 80,
      getActions: (params) => {
        return [
          <GridActionsCellItem key="1" icon={<DeleteIcon />} label="Delete" onClick={deleteRow(params.id.toString())} disabled={savingProgress.isLoading} />,
        ];
      },
    },
  ];

  const updateUnallocatedWeight = React.useCallback(
    (allocations: number[]) => {
      const totalAllocations = allocations.reduce((partialSum, a) => partialSum + a, 0);

      const correctUnallocated = 100 - totalAllocations <= 100 && 100 - totalAllocations >= 0 ? 100 - totalAllocations : 0;
      setUnallocatedAllocation(correctUnallocated);
    },
    [gridEditRowsStateSelector, apiRef]
  );

  const calculateTotal = React.useCallback(() => {
    if (editMode) {
      return (
        Object.entries(gridEditRowsStateSelector(apiRef.current.state))
          .map((r) => r[1].allocation.value)
          .reduce((partialSum, a) => partialSum + a, 0) + unallocatedAllocation
      );
    } else {
      return (
        apiRef.current
          .getAllRowIds()
          .map((id) => apiRef.current.getRow(id))
          .map((row) => row?.allocation || 0)
          .reduce((partialSum, a) => partialSum + a, 0) + unallocatedAllocation
      );
    }
  }, [editMode, unallocatedAllocation, apiRef]);

  const validateTotalAllocations = React.useCallback(() => {
    const allocations: DataRow[] = Object.entries(gridEditRowsStateSelector(apiRef.current.state))
      .map((r) => r[1])
      .map((r, index) => ({
        id: index.toString(),
        assetClassId: r.assetClassId.value !== '' ? r.assetClassId.value : null,
        allocation: r.allocation.value,
      }));

    validationSchema
      .validate({ unallocatedAllocation, strategicAllocations: allocations })
      .then(() => {
        setTotalAllocationValidationMessage('');
      })
      .catch((e) => {
        setTotalAllocationValidationMessage(e.message);
      });
  }, [apiRef.current.state, unallocatedAllocation]);

  const handleAddClick = React.useCallback(() => {
    const id = (apiRef.current.getAllRowIds().length + 1).toString();

    const newRow: DataRow = {
      id,
      assetClassId: null,
      allocation: 0,
    };
    apiRef.current.updateRows([{ ...newRow, id, isNew: true }]);
    apiRef.current.setRowMode(id, GridRowModes.Edit);
    apiRef.current.setEditCellValue({ id, field: 'assetClassId', value: '' });

    // Wait for the grid to render with the new row
    setTimeout(() => {
      apiRef.current.scrollToIndexes({
        rowIndex: apiRef.current.getRowsCount() - 1,
      });

      apiRef.current.setCellFocus(-1, 'name');
    });
  }, [apiRef]);

  const handleEditClick = React.useCallback(() => {
    setEditMode(true);
    apiRef.current.getAllRowIds().forEach((id) => {
      apiRef.current.setRowMode(id, GridRowModes.Edit);
    });

    const allocations = Object.entries(gridEditRowsStateSelector(apiRef.current.state)).map((r) => +r[1].allocation.value);
    updateUnallocatedWeight(allocations);
  }, [apiRef, unallocatedAllocation]);

  const handleCancelClick = React.useCallback(() => {
    setEditMode(false);
    apiRef.current.getAllRowIds().forEach((id) => {
      const rowMode = apiRef.current.getRowMode(id);
      if (rowMode === 'edit') {
        apiRef.current.setRowMode(id, GridRowModes.View);
      }
      const row = apiRef.current.getRow(id);
      if (!!row && row.isNew) {
        apiRef.current.updateRows([{ id, _action: 'delete' }]);
      }
    });

    resetDataRows();
  }, [apiRef, items]);

  const handleSave = useCallback(async () => {
    if (!!onSave) {
      const editModels = gridEditRowsStateSelector(apiRef.current.state);
      handleCancelClick();

      onSave([
        ...Object.entries(editModels).map((m) => ({
          assetClassId: m[1].assetClassId.value,
          allocation: m[1].allocation.value,
        })),
        {
          assetClassId: AssetClassEnum.Unallocated.id,
          allocation: unallocatedAllocation,
        },
      ]);
    }
  }, [onSave, apiRef.current, unallocatedAllocation]);

  const deleteRow = React.useCallback(
    (id: string) => () => {
      apiRef.current.setRowMode(id, GridRowModes.View);
      apiRef.current.updateRows([{ id, _action: 'delete' }]);

      const allocations = Object.entries(gridEditRowsStateSelector(apiRef.current.state)).map((r) => +r[1].allocation.value);
      updateUnallocatedWeight(allocations);
    },
    [apiRef, dataRows]
  );

  return (
    <>
      <Typography variant="h4" style={{ paddingBottom: '15px', paddingTop: '30px' }}>
        Strategic Asset Allocation
      </Typography>
      <div>
        <Paper elevation={3}>
          <StyledBox>
            <DataGridPro
              editMode="row"
              apiRef={apiRef}
              rows={savingProgress.isLoading ? [] : dataRows}
              columns={columns}
              columnVisibilityModel={{
                actions: editMode,
              }}
              pageSize={items.length}
              disableColumnMenu
              disableColumnReorder={true}
              style={{ height: '400px' }}
              components={{
                LoadingOverlay: LinearProgress,
                Toolbar: () => {
                  validateTotalAllocations();

                  return (
                    <GridToolbarContainer>
                      <Stack direction="row" alignItems="center">
                        {savingProgress.isLoading && (
                          <div className="LoadingIndicator" style={{ padding: '7px' }}>
                            <PulseLoader size="9px" margin="5px" color={theme.palette.grey[400]} />
                          </div>
                        )}
                        {!hideEditControls && editMode && <Typography color={'error'}>{totalAllocationValidationMessage}</Typography>}
                        {!hideEditControls && editMode && !savingProgress.isLoading && (
                          <IconButton disableFocusRipple disableRipple data-testid="addButton" onClick={handleAddClick} color={'primary'}>
                            <AddIcon style={{ height: '24px' }} />
                          </IconButton>
                        )}
                        {!hideEditControls && !editMode && (
                          <IconButton disableFocusRipple disableRipple data-testid="editButton" onClick={handleEditClick} color={'primary'}>
                            <EditIcon style={{ height: '24px' }} />
                          </IconButton>
                        )}
                        {!hideEditControls && editMode && !savingProgress.isLoading && (
                          <IconButton disableFocusRipple disableRipple data-testid="cancelButton" onClick={handleCancelClick} color={'primary'}>
                            <CancelIcon style={{ height: '24px' }} />
                          </IconButton>
                        )}
                        {!hideEditControls && editMode && !savingProgress.isLoading && (
                          <IconButton
                            disableFocusRipple
                            disableRipple
                            data-testid="saveButton"
                            onClick={handleSave}
                            color={'primary'}
                            disabled={totalAllocationValidationMessage !== ''}
                          >
                            <SaveIcon style={{ height: '24px' }} />
                          </IconButton>
                        )}
                      </Stack>
                    </GridToolbarContainer>
                  );
                },
                Footer: () => {
                  const totalAllocations = calculateTotal();

                  return (
                    <div style={{ paddingRight: '20px', paddingBottom: '20px', paddingTop: '10px' }}>
                      <Typography
                        variant="h4"
                        style={{
                          color: unallocatedAllocation > 0 ? 'red' : 'darkgray',
                          display: 'flex',
                          justifyContent: 'right',
                          fontWeight: 'bold',
                        }}
                      >
                        Unallocated: {unallocatedAllocation}%
                      </Typography>

                      <Typography
                        variant="h4"
                        style={{
                          color: totalAllocations !== 100 ? 'red' : 'darkgray',
                          display: 'flex',
                          justifyContent: 'right',
                          paddingTop: '5px',
                          fontWeight: 'bold',
                        }}
                      >
                        Total: {totalAllocations}%
                      </Typography>
                    </div>
                  );
                },
              }}
              loading={itemLoadingProgress.isLoading || savingProgress.isLoading}
              onRowEditStop={(_params: GridRowParams, event: MuiEvent<MuiBaseEvent>) => {
                // we don't want to end editting unless they click save
                event.defaultMuiPrevented = true;
              }}
              onRowEditStart={(_params: GridRowParams, event: MuiEvent<MuiBaseEvent>) => {
                // we don't want to end editting unless they click edit
                event.defaultMuiPrevented = true;
              }}
            />
          </StyledBox>
        </Paper>
      </div>
    </>
  );
};
