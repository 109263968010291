import { Card, Container } from '@mui/material';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React from 'react';
import { DateTimeFormat, formatPercentage, getLocalDateTime } from '../../../../common';
import { LoadingIndicator } from '../../../../common/components/LoadingIndicator';
import { LoadingProgress } from '../../../../common/store/types';
import { theme } from '../../../../themes';
import { DailyPerformanceChart, Frequency, MonthlyPerformanceChart } from '../store/types';

export interface Props {
  performanceChartProgress: LoadingProgress;
  frequency: Frequency;
  dailyPerformanceChart?: DailyPerformanceChart | null;
  monthlyPerformanceChart?: MonthlyPerformanceChart | null;
}

export const PerformanceChart = (props: Props): JSX.Element => {
  const { performanceChartProgress, dailyPerformanceChart, monthlyPerformanceChart, frequency } = props;
  const dateFormat = frequency === Frequency.Daily ? DateTimeFormat.ChartDayDate : DateTimeFormat.ChartMonthDate;
  const categories =
    frequency === Frequency.Daily
      ? dailyPerformanceChart?.seriesData?.map((i) => getLocalDateTime(i.date, dateFormat)) ?? []
      : monthlyPerformanceChart?.seriesData?.map((i) => getLocalDateTime(i.date, dateFormat)) ?? [];
  const returns =
    frequency === Frequency.Daily
      ? dailyPerformanceChart?.seriesData?.map((i) => i.return) ?? [0]
      : monthlyPerformanceChart?.seriesData?.map((i) => i.return) ?? [0];
  const returnValues = returns.map((i) => ({ y: i, color: i > 0 ? theme.palette.secondary.main : theme.palette.error.main }));

  const cumulativeValues =
    frequency === Frequency.Daily
      ? dailyPerformanceChart?.seriesData?.map((i) => i.cumulativeReturn) ?? [0]
      : monthlyPerformanceChart?.seriesData?.map((i) => i.cumulativeReturn) ?? [0];

  const chartOptions = {
    chart: {
      renderTo: 'container',
      backgroundColor: 'white',
      plotBackgroundColor: 'transparent',
      height: '400px',
      marginLeft: 50,
    },
    plotOptions: {
      series: {
        allowPointSelect: false,
        groupPadding: 0,
        pointPadding: 0.1,
        borderWidth: 0,
      },
    },
    title: {
      text: 'Strategy Performance',
      align: 'left',
      style: {
        fontFamily: theme.typography.fontFamily,
        fontSize: '22px',
        fontWeight: 400,
        fill: '#000',
      },
    },
    subtitle: {
      text: `<p style="margin-bottom: 24px; margin-top: 0">Total return: ${formatPercentage(dailyPerformanceChart?.totalReturn)}</p>`,
      align: 'left',
      useHTML: true,
      style: {
        fontFamily: theme.typography.fontFamily,
        fontSize: '18px',
        fontWeight: 400,
      },
    },
    xAxis: [
      {
        gridLineColor: 'transparent',
        categories,
      },
    ],
    yAxis: [
      {
        title: { text: undefined },
        gridLineColor: theme.palette.lightGrey.main,
        gridLineWidth: 1,
        lineColor: 'transparent',
        opposite: true,
        labels: {
          formatter: function (this: { value: string }) {
            const percentageAsNumber = parseFloat(this.value);

            return formatPercentage(percentageAsNumber);
          },
        },
        plotLines: [
          {
            color: theme.palette.graphBackground?.main,
            width: 0.5,
            value: 0,
          },
        ],
      },
    ],
    tooltip: {
      shared: true,
      useHTML: true,
      formatter: function (this: Highcharts.TooltipFormatterContextObject) {
        const points = this.points?.reduce(function (s: string, point: Highcharts.TooltipFormatterContextObject) {
          return s + '<br/><span style="color:' + point.color + '">\u25CF</span>  ' + point.series.name + ': ' + formatPercentage(point.y);
        }, '<b>' + this.x + '</b>');

        return points;
      },
    },
    series: [
      {
        color: theme.palette.primary.main,
        name: 'Cumulative Return',
        type: 'line',
        dashStyle: 'Solid',
        data: cumulativeValues,
        zIndex: 100,
        states: {
          inactive: {
            opacity: 1,
          },
        },
        marker: {
          enabled: false,
        },
      },
      {
        showInLegend: false,
        name: 'Return',
        type: 'column',
        data: returnValues,
        states: {
          inactive: {
            opacity: 1,
          },
        },
      },
    ],
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
  };

  return (
    <LoadingIndicator progress={performanceChartProgress}>
      <Card square elevation={0} style={{ background: theme.palette.common.white, padding: '30px 0' }}>
        <Container fixed>
          <HighchartsReact highcharts={Highcharts} options={chartOptions} />
        </Container>
      </Card>
    </LoadingIndicator>
  );
};
