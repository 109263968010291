import { ButtonGroup } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { theme } from '../../../../../themes';
import { CurrentTabType } from '../store/types';
import WO2Button from './../../../../../common/components/button/Button';

interface ButtonProps {
  backgroundSelected: string;
  label: CurrentTabType;
}

interface Props {
  labelInitial: CurrentTabType;
  buttonLeft: ButtonProps;
  buttonMiddle: ButtonProps;
  buttonRight: ButtonProps;
  width: number;
  selectedLabel?: string;
  handleClick: (tabLabel: CurrentTabType) => void;
}

export const PairedButttons = (props: Props): JSX.Element => {
  const left =
    props.labelInitial === props.buttonLeft.label
      ? {
          backgroundColor: `${props.buttonLeft.backgroundSelected} !important`,
          color: `${theme.palette.common.white} !important`,
        }
      : {};

  const middle =
    props.labelInitial === props.buttonMiddle.label
      ? {
          backgroundColor: `${props.buttonRight.backgroundSelected} !important`,
          color: `${theme.palette.common.white} !important`,
        }
      : {};

  const right =
    props.labelInitial === props.buttonRight.label
      ? {
          backgroundColor: `${props.buttonRight.backgroundSelected} !important`,
          color: `${theme.palette.common.white} !important`,
        }
      : {};

  const classes = makeStyles(() => ({
    left: props.width ? { ...left, width: `${props.width}px`, minWidth: `${props.width}px` } : left,
    middle: props.width ? { ...middle, width: `${props.width}px`, minWidth: `${props.width}px` } : middle,
    right: props.width ? { ...right, width: `${props.width}px`, minWidth: `${props.width}px` } : right,
  }))();

  const setRadioState = (label: CurrentTabType) => {
    props.handleClick(label);
  };

  return (
    <ButtonGroup color="primary" aria-label="outlined primary button group">
      <WO2Button className={classes.left} onClick={() => setRadioState(props.buttonLeft.label)}>
        {props.buttonLeft.label}
      </WO2Button>
      <WO2Button className={classes.middle} onClick={() => setRadioState(props.buttonMiddle.label)}>
        {props.buttonMiddle.label}
      </WO2Button>
      <WO2Button className={classes.right} onClick={() => setRadioState(props.buttonRight.label)}>
        {props.buttonRight.label}
      </WO2Button>
    </ButtonGroup>
  );
};
