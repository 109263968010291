import { Enumeration } from '../../../../../../common/types';

export class TrusteeType extends Enumeration {
  static Individual = new TrusteeType(1, 'Individual', 'Individual');
  static Corporate = new TrusteeType(2, 'Corporate', 'Corporate');

  static getArray = (): TrusteeType[] => [TrusteeType.Individual, TrusteeType.Corporate];

  static getById = (status: number | null): TrusteeType | undefined => {
    return Object.values(TrusteeType)
      .filter((t) => t instanceof TrusteeType)
      .find((t: TrusteeType) => t.id === status);
  };
}

export class TrustType extends Enumeration {
  static FamilyTrust = new TrustType(1, 'FamilyTrust', 'Family Trust');
  static UnitTrust = new TrustType(2, 'UnitTrust', 'Unit Trust');
  static Superannuation = new TrustType(3, 'Superannuation', 'Superannuation');

  static getArray = (): TrustType[] => [TrustType.FamilyTrust, TrustType.UnitTrust, TrustType.Superannuation];

  static getById = (status: number | null): TrustType | undefined => {
    return Object.values(TrustType)
      .filter((t) => t instanceof TrustType)
      .find((t: TrustType) => t.id === status);
  };
}

export class Status extends Enumeration {
  static Pending = new Status(1, 'Pending', 'Pending');
  static Active = new Status(2, 'Active', 'Active');
  static Terminated = new Status(3, 'Terminated', 'Terminated');
  static WaitingForApproval = new Status(4, 'WaitingForApproval', 'Waiting for Approval');
  static Stalled = new Status(5, 'Stalled', 'Stalled');
  static DatafeedActive = new Status(6, 'DatafeedActive', 'datafeedActive');

  static getById = (status: number): Status | undefined => {
    return Object.values(Status)
      .filter((t) => t instanceof Status)
      .find((t: Status) => t.id === status);
  };
}

export class SourceOfFunds extends Enumeration {
  static SalaryWages = new SourceOfFunds(1, 'SalaryWages', 'Salary/Wages');
  static BusinessIncome = new SourceOfFunds(2, 'BusinessIncome', 'Business income/earnings');
  static InvestmentIncome = new SourceOfFunds(3, 'InvestmentIncome', 'Investment income/earnings');
  static RentalIncome = new SourceOfFunds(4, 'RentalIncome', 'Rental income');
  static CompensationPayment = new SourceOfFunds(5, 'CompensationPayment', 'Compensation payment');
  static SaleOfAssets = new SourceOfFunds(6, 'SaleOfAssets', 'Sale of assets');
  static Gift = new SourceOfFunds(7, 'Gift', 'Gift/Donation');
  static Gov = new SourceOfFunds(8, 'Gov', 'Government Benefits');
  static Inheritance = new SourceOfFunds(9, 'Inheritance', 'Inheritance');
  static SuperannuationPension = new SourceOfFunds(10, 'SuperannuationPension', 'Superannuation/pension');
  static Windfall = new SourceOfFunds(11, 'Windfall', 'Windfall');

  static getArray = (): SourceOfFunds[] => [
    SourceOfFunds.SalaryWages,
    SourceOfFunds.BusinessIncome,
    SourceOfFunds.InvestmentIncome,
    SourceOfFunds.RentalIncome,
    SourceOfFunds.CompensationPayment,
    SourceOfFunds.SaleOfAssets,
    SourceOfFunds.Gift,
    SourceOfFunds.Gov,
    SourceOfFunds.Inheritance,
    SourceOfFunds.SuperannuationPension,
    SourceOfFunds.Windfall,
  ];
}
