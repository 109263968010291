import { InfoOutlined as InfoIcon } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, OutlinedInput, Paper, TableCell, TableRow, Tooltip, Typography, /*useMediaQuery,*/ TextField } from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import React, { useCallback, useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { DateTimeFormat, formatDollars, formatPercentage, getLocalDateTime } from '../../../../../common';
import { useDebounce } from '../../../../../common/hooks';
import {
  MarketCode,
  SecurityType,
  SetAmountToTradePayload,
  SetPriceLimitPayload,
  SetExpiryDatePayload,
  SetMethodPayload,
  SetPriceTypePayload,
  SetExpiryTypePayload,
  SetTradePayload,
  Trade as GroupedTrade,
  TradeAction,
  TradeMethod,
  PriceType,
  ExpiryType,
} from '../store/types';
import { isCashTrade } from '../utils';
import decimalPlacesByMarketCode from '../utils/decimalPlacesByMarketCode';
import { DesktopDatePicker } from '@mui/lab';
import { DateTime } from 'luxon';

interface Props {
  trade: GroupedTrade;
  validationMessage: string;
  setAmountToTrade: (payload: SetAmountToTradePayload) => void;
  setPriceLimit: (payload: SetPriceLimitPayload) => void;
  setExpiryDate: (payload: SetExpiryDatePayload) => void;
  setPriceType: (payload: SetPriceTypePayload) => void;
  setExpiryType: (payload: SetExpiryTypePayload) => void;
  setMethod: (payload: SetMethodPayload) => void;
  setTrade: (payload: SetTradePayload) => void;
  setClearTrade: (payload: string) => void;
  index: number;
  hasRebalanceEnhancedTrading: boolean;
  hasClientEditAdminOnlyPermission: boolean;
}

export default function Trade(props: Props): JSX.Element {
  const {
    trade,
    validationMessage,
    setAmountToTrade,
    setPriceLimit,
    setExpiryDate,
    setMethod,
    setPriceType,
    setExpiryType,
    setTrade,
    setClearTrade,
    index,
    hasRebalanceEnhancedTrading,
    hasClientEditAdminOnlyPermission,
  } = props;
  const [amount, setAmount] = useState<number | undefined>(trade.amountToTrade);
  const onAmountToTradeChange = useDebounce<number | undefined>(amount, 500);
  // const [limitDate, setLimitDate] = useState<DateTime | null>(DateTime.now());
  const [limitDate, setLimitDate] = useState<DateTime | null>(null);

  const onAmountToTradeChanged = useCallback(() => {
    if (amount !== undefined && amount !== trade.amountToTrade) {
      setAmountToTrade({ id: trade.id, tradeAmount: amount });
    }
  }, [setAmountToTrade, trade.id, amount, trade.amountToTrade]);

  useEffect(onAmountToTradeChanged, [onAmountToTradeChange]);

  const amountToTradeChanged = useCallback(() => {
    if (amount !== undefined && amount !== trade.amountToTrade) {
      setAmount(trade.amountToTrade);
    }
  }, [setAmount, amount, trade.amountToTrade]);

  useEffect(amountToTradeChanged, [trade.amountToTrade]);
  // const matches = useMediaQuery('(min-width:1400px)');

  const handleDateChange = (materialUiPickersDate: DateTime | null, setFunction: (date: DateTime | null) => void) => {
    setFunction(materialUiPickersDate ?? null);
    setExpiryDate({ id: trade.id, expiryDate: materialUiPickersDate === null ? null : materialUiPickersDate.toISODate() });
  };

  return (
    <TableRow key={index}>
      <TableCell style={{ padding: '0', width: '0' }} />

      <Tooltip
        style={{ padding: 0, margin: '20px' }}
        placement="right"
        title={
          <React.Fragment>
            <Paper square style={{ padding: '10px', margin: 0, display: 'flex', justifyContent: 'space-between', width: 620 }}>
              <Box display={'inline-flex'} width="100%">
                <Box display={'inline-block'} width="100%">
                  <Typography variant="h5" color={'primary'}>
                    {trade.securityCode}
                  </Typography>
                  <Typography variant="h5">{trade.securityName}</Typography>
                </Box>
                <Box width="130px" minWidth="130px">
                  <Typography variant="h5">{formatDollars(trade.currentUnitPrice)} AUD</Typography>
                  <Typography variant="h5" color={'textSecondary'}>
                    As of {getLocalDateTime(trade.currentUnitPriceTime, DateTimeFormat.DailyDate)}
                  </Typography>
                </Box>
              </Box>
            </Paper>
          </React.Fragment>
        }
      >
        <TableCell style={{ width: '260px', minWidth: '260px', maxWidth: '260px' }}>
          <Typography variant="h5" noWrap color={'primary'}>
            {trade.securityCode}
          </Typography>
          <Typography variant="h5" noWrap color={'textSecondary'}>
            {trade.securityName}
          </Typography>
        </TableCell>
      </Tooltip>

      <TableCell align="left" style={{ minWidth: '90px', width: '7%', maxWidth: '7%' }}>
        <Typography variant="h5">{Intl.NumberFormat('en-AU').format(trade.currentUnits)}</Typography>
        <Typography variant="h6" color={'textSecondary'}>
          @ {formatDollars(trade.unitPrice)}
        </Typography>
      </TableCell>

      <TableCell align="left" style={{ minWidth: '162px', width: '10%', maxWidth: '10%' }}>
        {!isCashTrade(trade) && (
          <ToggleButtonGroup size="small" exclusive aria-label="small outlined button group">
            <ToggleButton
              name={'BuyButton'}
              className={'Green'}
              color={'primary'}
              value={TradeAction.Buy}
              onClick={() => {
                trade.tradeAction !== TradeAction.Buy && setTrade({ id: trade.id, tradeAction: TradeAction.Buy });
              }}
              selected={trade.tradeAction === TradeAction.Buy}
            >
              Buy
            </ToggleButton>
            {trade.securityType !== SecurityType.TermDeposit && (
              <ToggleButton
                name={'SellButton'}
                className={'Red'}
                value={TradeAction.Sell}
                onClick={() => {
                  trade.tradeAction !== TradeAction.Sell && setTrade({ id: trade.id, tradeAction: TradeAction.Sell });
                }}
                selected={trade.tradeAction === TradeAction.Sell || trade.tradeAction === TradeAction.All}
              >
                Sell
              </ToggleButton>
            )}
            {trade.securityType !== SecurityType.TermDeposit && (
              <ToggleButton
                name={'SellAllButton'}
                className={'Red'}
                value={TradeAction.All}
                onClick={() => {
                  trade.tradeAction !== TradeAction.All && setTrade({ id: trade.id, tradeAction: TradeAction.All });
                }}
                selected={trade.tradeAction === TradeAction.All}
              >
                All
              </ToggleButton>
            )}
          </ToggleButtonGroup>
        )}
      </TableCell>

      <TableCell align="left" style={{ minWidth: '162px', width: '10%', maxWidth: '10%' }}>
        {trade.marketCode !== MarketCode.ADI && (
          <ToggleButtonGroup size="small" exclusive aria-label="small outlined button group">
            <ToggleButton
              name={'DollarButton'}
              value={TradeMethod.Dollar}
              disabled={trade.tradeAction === TradeAction.All}
              onClick={() => {
                trade.method !== TradeMethod.Dollar && setMethod({ id: trade.id, tradeMethod: TradeMethod.Dollar });
              }}
              selected={trade.method === TradeMethod.Dollar}
            >
              $
            </ToggleButton>
            <ToggleButton
              name={'PercentageButton'}
              value={TradeMethod.Percentage}
              disabled={trade.tradeAction === TradeAction.All}
              onClick={() => {
                trade.method !== TradeMethod.Percentage && setMethod({ id: trade.id, tradeMethod: TradeMethod.Percentage });
              }}
              selected={trade.method === TradeMethod.Percentage}
            >
              %
            </ToggleButton>
            <ToggleButton
              name={'HashButton'}
              value={TradeMethod.Hash}
              onClick={() => {
                trade.method !== TradeMethod.Hash && setMethod({ id: trade.id, tradeMethod: TradeMethod.Hash });
              }}
              selected={trade.method === TradeMethod.Hash}
            >
              #
            </ToggleButton>
          </ToggleButtonGroup>
        )}
      </TableCell>

      <TableCell align="left" style={{ minWidth: '130px', width: '130px', maxWidth: '130px' }}>
        {trade.marketCode !== MarketCode.ADI && (
          <NumberFormat
            value={amount}
            customInput={OutlinedInput}
            type="text"
            data-id={trade.id}
            disabled={trade.tradeAction === TradeAction.All}
            thousandSeparator
            fixedDecimalScale
            allowNegative={false}
            decimalScale={trade.method === TradeMethod.Dollar || trade.method === TradeMethod.Percentage ? 2 : decimalPlacesByMarketCode(trade.marketCode)}
            isAllowed={(values) =>
              !values.floatValue
                ? true
                : trade.tradeAction === TradeAction.Buy
                ? true
                : trade.method === TradeMethod.Dollar && values.floatValue <= trade.currentValue
                ? true
                : trade.method === TradeMethod.Percentage && values.floatValue <= trade.currentPercent
                ? true
                : trade.method === TradeMethod.Hash && values.floatValue <= trade.currentUnits
                ? true
                : false
            }
            onValueChange={(values) => {
              if (values.floatValue !== trade.amountToTrade) {
                setAmount(values.floatValue);
              }
            }}
            onBlur={() => {
              if (!amount) {
                setAmount(trade.amountToTrade);
              }
            }}
          />
        )}
      </TableCell>

      <TableCell align="left" style={{ minWidth: '92px', width: '7%', maxWidth: '7%' }}>
        <Typography variant="h5" color={!trade.currentPercent ? 'textPrimary' : trade.currentPercent < 0 ? 'error' : 'secondary'}>
          {formatPercentage(trade.currentPercent / 100)}
        </Typography>
        <Typography variant="h6" color={'textSecondary'}>
          {formatDollars(trade.currentValue)}
        </Typography>
      </TableCell>

      <TableCell align="left" style={{ minWidth: '92px', width: '7%', maxWidth: '7%' }}>
        <Typography variant="h5">{formatPercentage(trade.targetPercent / 100)}</Typography>
        <Typography variant="h6" color={'textSecondary'}>
          {formatDollars(trade.targetValue)}
        </Typography>
      </TableCell>

      <TableCell align="left" style={{ minWidth: '92px', width: '7%', maxWidth: '7%' }}>
        <Typography variant="h5">{formatPercentage(Math.max(0, trade.proposedPercent / 100))}</Typography>
        <Typography variant="h6" color={'textSecondary'}>
          {formatDollars(Math.max(0, trade.proposedValue))}
        </Typography>
      </TableCell>

      {trade.marketCode === MarketCode.ASX && (hasRebalanceEnhancedTrading || hasClientEditAdminOnlyPermission) && (
        <TableCell align="left" style={{ minWidth: '100px', width: '10%', maxWidth: '10%' }}>
          <ToggleButtonGroup size="small" exclusive aria-label="small outlined button group">
            <Tooltip title={'Market to Limit'} arrow>
              <ToggleButton
                name={'MarketButton'}
                value={PriceType.Market}
                disabled={false}
                style={{ width: '45px' }}
                onClick={() => {
                  trade.priceType !== PriceType.Market && setPriceType({ id: trade.id, priceType: PriceType.Market });
                  setPriceLimit({ id: trade.id, priceLimit: null });
                }}
                selected={trade.priceType === PriceType.Market || trade.priceType === null}
              >
                MKT
              </ToggleButton>
            </Tooltip>
            <Tooltip title={'Limit'} arrow>
              <ToggleButton
                name={'LimitButton'}
                value={PriceType.Limit}
                disabled={false}
                style={{ width: '45px' }}
                onClick={() => {
                  trade.priceType !== PriceType.Limit && setPriceType({ id: trade.id, priceType: PriceType.Limit });
                }}
                selected={trade.priceType === PriceType.Limit}
              >
                LMT
              </ToggleButton>
            </Tooltip>
          </ToggleButtonGroup>
        </TableCell>
      )}

      {trade.marketCode === MarketCode.ASX && (hasRebalanceEnhancedTrading || hasClientEditAdminOnlyPermission) && (
        <TableCell align="left" style={{ minWidth: '115px', width: '10%', maxWidth: '10%' }}>
          <NumberFormat
            value={trade.priceType === PriceType.Market || trade.priceType === null ? '' : trade.priceLimit}
            customInput={OutlinedInput}
            placeholder="Limit price"
            type="text"
            data-id={trade.id}
            disabled={trade.priceType === PriceType.Market || trade.priceType === null}
            thousandSeparator
            fixedDecimalScale
            allowNegative={false}
            decimalScale={3}
            isAllowed={() => true}
            onValueChange={(values) => {
              if (values.floatValue !== trade.priceLimit) {
                setPriceLimit({ id: trade.id, priceLimit: values.floatValue ?? null });
              }
            }}
            onBlur={() => {
              if (!amount) {
                setPriceLimit({ id: trade.id, priceLimit: trade.priceLimit ?? null });
              }
            }}
          />
        </TableCell>
      )}

      {trade.marketCode === MarketCode.ASX && (hasRebalanceEnhancedTrading || hasClientEditAdminOnlyPermission) && (
        <TableCell align="left" style={{ minWidth: '100px', width: '10%', maxWidth: '10%' }}>
          <ToggleButtonGroup size="small" exclusive aria-label="small outlined button group">
            <Tooltip title={'Good For Day'} arrow>
              <ToggleButton
                name={'ExpiryTypeButton'}
                value={ExpiryType.GFD}
                style={{ width: '45px' }}
                onClick={() => {
                  trade.expiryType !== ExpiryType.GFD && setExpiryType({ id: trade.id, expiryType: ExpiryType.GFD });
                  handleDateChange(null, setLimitDate);
                }}
                selected={trade.expiryType === ExpiryType.GFD || trade.expiryType === null}
              >
                GFD
              </ToggleButton>
            </Tooltip>
            <Tooltip title={'Good Till Cancelled'} arrow>
              <ToggleButton
                name={'ExpiryTypeButton'}
                value={ExpiryType.GTC}
                style={{ width: '45px' }}
                onClick={() => {
                  trade.expiryType !== ExpiryType.GTC && setExpiryType({ id: trade.id, expiryType: ExpiryType.GTC });
                  handleDateChange(null, setLimitDate);
                }}
                selected={trade.expiryType === ExpiryType.GTC}
              >
                GTC
              </ToggleButton>
            </Tooltip>
            <Tooltip title={'Good Till Date'} arrow>
              <ToggleButton
                name={'ExpiryTypeButton'}
                value={ExpiryType.GTD}
                style={{ width: '45px' }}
                onClick={() => {
                  trade.expiryType !== ExpiryType.GTD && setExpiryType({ id: trade.id, expiryType: ExpiryType.GTD });
                  handleDateChange(DateTime.now(), setLimitDate);
                }}
                selected={trade.expiryType === ExpiryType.GTD}
              >
                GTD
              </ToggleButton>
            </Tooltip>
          </ToggleButtonGroup>
        </TableCell>
      )}

      {trade.marketCode === MarketCode.ASX && (hasRebalanceEnhancedTrading || hasClientEditAdminOnlyPermission) && (
        <TableCell align="left" style={{ minWidth: '160px', width: '10%', maxWidth: '10%' }}>
          <DesktopDatePicker
            inputFormat={DateTimeFormat.Short.displayName}
            disabled={!(trade.expiryType === ExpiryType.GTD)}
            minDate={DateTime.now()}
            maxDate={DateTime.now().plus({ weeks: 4 })}
            value={trade.expiryType === ExpiryType.GFD || trade.expiryType === ExpiryType.GTC ? null : limitDate}
            onChange={(e) => {
              handleDateChange(e, setLimitDate);
            }}
            OpenPickerButtonProps={{
              'aria-label': 'change date',
            }}
            renderInput={(params) => <TextField {...params} variant="outlined" />}
          />
        </TableCell>
      )}

      {trade.marketCode !== MarketCode.ASX && (hasRebalanceEnhancedTrading || hasClientEditAdminOnlyPermission) && (
        <TableCell align="right" style={{ minWidth: '100px', width: '10%', maxWidth: '10%' }}></TableCell>
      )}
      {trade.marketCode !== MarketCode.ASX && (hasRebalanceEnhancedTrading || hasClientEditAdminOnlyPermission) && (
        <TableCell align="right" style={{ minWidth: '115px', width: '10%', maxWidth: '10%' }}></TableCell>
      )}
      {trade.marketCode !== MarketCode.ASX && (hasRebalanceEnhancedTrading || hasClientEditAdminOnlyPermission) && (
        <TableCell align="right" style={{ minWidth: '100px', width: '10%', maxWidth: '10%' }}></TableCell>
      )}
      {trade.marketCode !== MarketCode.ASX && (hasRebalanceEnhancedTrading || hasClientEditAdminOnlyPermission) && (
        <TableCell align="right" style={{ minWidth: '160px', width: '10%', maxWidth: '10%' }}></TableCell>
      )}

      <TableCell align="right">
        <div style={{ display: 'inline-flex' }}>
          <div>
            <Typography variant="h5">
              {(trade.tradeAction === TradeAction.Sell || trade.tradeAction === TradeAction.All) && trade.newCalculatedValue ? '-' : ''}
              {formatDollars(trade.newCalculatedValue)}
            </Typography>
            {trade.marketCode !== MarketCode.ADI && (
              <Typography variant="h6" color={'textSecondary'}>
                {(trade.tradeAction === TradeAction.Sell || trade.tradeAction === TradeAction.All) && trade.newCalculatedUnits ? '-' : ''}
                {Intl.NumberFormat('en-AU').format(trade.newCalculatedUnits)}
              </Typography>
            )}
          </div>
          {validationMessage && (
            <Tooltip title={validationMessage} aria-label="info">
              <InfoIcon color={'primary'} style={{ alignSelf: 'center', fontSize: '20px', paddingLeft: '2px' }} />
            </Tooltip>
          )}
        </div>
      </TableCell>

      <TableCell align="left" style={{ padding: '0', width: '30px', minWidth: '30px' }}>
        {trade.marketCode !== MarketCode.ADI && (
          <IconButton
            name="clearTradeButton"
            key="close"
            aria-label="close"
            color="inherit"
            onClick={() => {
              setClearTrade(trade.id);
            }}
          >
            <CloseIcon style={{ fontSize: '16px' }} />
          </IconButton>
        )}
      </TableCell>
    </TableRow>
  );
}
