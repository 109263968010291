import { Autocomplete, Backdrop, Box, Fade, Grid, Modal, Paper, TextField, Typography } from '@mui/material';
import { Form, Formik, FormikProps } from 'formik';
import React, { useState } from 'react';
import { EditCancelSaveButtons, FormikErrorMessage, Mode } from 'src/common/components/formik';
import { LoadingProgress } from 'src/common/store/types';
import * as yup from 'yup';
import { AttachableTrustee } from '../../store/types';

export interface AttachTrusteeDialogPayload {
  trusteeId: number;
}

export interface AttachTrusteeProps {
  trustees: AttachableTrustee[];
  trusteesProgress: LoadingProgress;
  isOpen: boolean;
  onSave: (payload: AttachTrusteeDialogPayload) => void;
  handleCloseModal: () => void;
  fetchTrusteesToAttach: (searchText: string) => void;
  savingAttachProgress: LoadingProgress;
}

export const AttachTrustee = (props: AttachTrusteeProps): JSX.Element => {
  const { trustees, isOpen, onSave, handleCloseModal, trusteesProgress, fetchTrusteesToAttach, savingAttachProgress } = {
    ...props,
  };

  const [details] = useState<AttachTrusteeDialogPayload>({
    trusteeId: -1,
  });

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={isOpen}
      onClose={handleCloseModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={isOpen}>
        <Paper
          elevation={0}
          style={{
            width: '800px',
            minHeight: '300px',
            maxHeight: '400px',
            padding: '40px 40px',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <Formik<AttachTrusteeDialogPayload>
            initialValues={details}
            enableReinitialize={true}
            onSubmit={async (payload: AttachTrusteeDialogPayload) => {
              onSave(payload);
            }}
            validationSchema={yup.object({
              trusteeId: yup.number().moreThan(0, 'Trustee is required'),
            })}
          >
            {(formikProps: FormikProps<AttachTrusteeDialogPayload>) => (
              <Form>
                <fieldset style={{ border: 'none', padding: '0' }}>
                  <Typography variant="h3" style={{ padding: '20px 0 40px 0' }}>
                    Attach Trustee
                  </Typography>
                  <Grid container>
                    <Grid item xs={12} style={{ paddingRight: '20px' }}>
                      <Grid item xs={12} style={{ paddingBottom: '20px' }}>
                        <Autocomplete<AttachableTrustee>
                          data-testid="trusteeAutocomplete"
                          loading={trusteesProgress.isLoading}
                          noOptionsText="Start typing trustee name..."
                          fullWidth
                          options={trustees}
                          getOptionLabel={(trustee: AttachableTrustee) => trustee.name}
                          onChange={(e: React.SyntheticEvent<Element, Event>, trustee: AttachableTrustee | null) => {
                            formikProps.setFieldValue('trusteeId', trustee?.corporationId || -1);
                          }}
                          onInputChange={(e, newInputValue) => {
                            fetchTrusteesToAttach(newInputValue);
                          }}
                          renderInput={(params) => <TextField {...params} label="TRUSTEE" margin="normal" variant="outlined" fullWidth />}
                        />
                        <FormikErrorMessage name="trusteeId"></FormikErrorMessage>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Box display={'flex'} justifyContent={'flex-end'} paddingTop={'10px'} paddingLeft={'10px'}>
                        <EditCancelSaveButtons
                          mode={Mode.CancelSave}
                          handleCancelClick={handleCloseModal}
                          saveProgress={savingAttachProgress}
                        ></EditCancelSaveButtons>
                      </Box>
                    </Grid>
                  </Grid>
                </fieldset>
              </Form>
            )}
          </Formik>
        </Paper>
      </Fade>
    </Modal>
  );
};
