import { PersonAddAlt, Search as SearchIcon } from '@mui/icons-material';
import ArrowDropDownSharpIcon from '@mui/icons-material/ArrowDropDownSharp';
import { Box, Container, Grid, Typography } from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { AnyAction } from '@reduxjs/toolkit';
import queryString from 'query-string';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { usePDF } from 'react-to-pdf';
import { WO2Menu } from 'src/common/components/Menu';
import { useDebounce } from 'src/common/hooks';
import { Title } from 'src/common/types';
import { DateTimeFormat, getLocalDateTime } from 'src/common/utils';
import CardComponent from '../../../../common/components/CardComponent';
import { ClientAccountSubType, ClientAccountType } from '../../../../common/types';
import { PagedRequest } from '../../../../store';
import { theme } from '../../../../themes';
// import { resetClientStateAction } from '../../client/store';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import { onboardRoutes } from '../../onboard/components/onboardRoutes';
import { Props } from '../container';
import { ClientListTabName } from '../store/enum';
import {
  ClientCsvRow,
  ClientItem,
  OnboardingWizards,
  OnboardingWizardsModel,
  QuickOnboardClient,
} from '../store/types';
import { LoadingIndicator, WO2TextInput } from './../../../../common';
import WO2Button from './../../../../common/components/button/Button';
import { ClientListTable } from './clientListTable';
import { QuickAddClient } from './onboardQuick/quickAddClient';
import { OnboardingTable } from './onboardingTable';
// import GridOnIcon from '@mui/icons-material/GridOn';
import PdfFooter from '../../client/common/components/PdfFooter';
import PdfHeader from '../../client/common/components/PdfHeader';

export const ClientList = ({
  addQuickClientLoadingProgress,
  afsls,
  clientList,
  clientListProgress,
  createQuickOnboardClient,
  deleteOnboardingWizard,
  downloadClientList,
  downloadProposedOrders,
  fetchAfsls,
  fetchClientList,
  fetchClientsSummary,
  fetchOnboardingWizardsModels,
  history,
  loadingProgress,
  onboardings,
  onboardingsLoadProgress,
  parameters,
  setSearchStringParameter,
  totals,
  hasClientEditAdminOnlyPermission,
  resubmitClient,
  resubmitProgress,
  resetStatus,
  resetStatusProgress,
  resetClientStateAction,
  isDataFromTriumph,
}: Props): JSX.Element => {
  const location = useLocation();
  const queryParams = queryString.parse(location.search);

  const [selectedTab, setSelectedTab] = React.useState(
    queryParams.mode === 'onboard' ? ClientListTabName.Onboarding : ClientListTabName.Clients
  );
  const [showOnboardingQuick, setShowOnboardingQuick] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');
  const debouncedSearchText = useDebounce<string>(searchText.trim(), 500);

  const fetchData = useCallback(() => {
    if (selectedTab === ClientListTabName.Clients) {
      fetchClientsSummary(isDataFromTriumph);
      fetchClientList({ inputs: parameters, pagination: parameters.pagination, isDataFromTriumph: isDataFromTriumph });
    } else if (selectedTab === ClientListTabName.Onboarding) {
      fetchOnboardingWizardsModels();
    }
  }, [parameters, selectedTab, isDataFromTriumph, fetchClientsSummary, fetchClientList, fetchOnboardingWizardsModels]);

  const [showPdfFooterAndHeader, setShowPdfFooterAndHeader] = useState(false);
  const { toPDF, targetRef } = usePDF({ filename: 'Clients' });

  useEffect(() => {
    if (showPdfFooterAndHeader) {
      toPDF();
      setShowPdfFooterAndHeader(false);
    }
  }, [showPdfFooterAndHeader]);

  const generatePdf = () => {
    setShowPdfFooterAndHeader(true);
  };

  const exportButtons = [
    {
      icon: <PictureAsPdfOutlinedIcon />,
      label: 'Export to PDF',
      onClick: generatePdf,
      testId: `accountAction_detach_`,
    },
    // {
    //   icon: <GridOnIcon />,
    //   label: 'Export to CSV',
    //   onClick: generatePdf,
    //   testId: `accountAction_detach_`,
    // },
  ];

  useEffect(() => {
    if (
      (debouncedSearchText.length >= 3 || debouncedSearchText.length === 0) &&
      selectedTab === ClientListTabName.Clients
    ) {
      setSearchStringParameter(debouncedSearchText);
    }
  }, [debouncedSearchText, setSearchStringParameter]);

  useEffect(() => {
    fetchData();
  }, [parameters, selectedTab, fetchData, isDataFromTriumph]);

  const handleTabChange = (_event: ChangeEvent<unknown>, newTab: number) => {
    setSelectedTab(newTab);
  };

  const handleGridActions = (pagedRequest: PagedRequest) => {
    fetchClientList({
      inputs: {
        pagination: pagedRequest,
        clientNameSearch: parameters.clientNameSearch,
      },
      pagination: pagedRequest,
    });
  };

  const handleCreateQuickOnboardingClientSave = (newClient: QuickOnboardClient): Promise<AnyAction | void> =>
    createQuickOnboardClient(newClient).then((response: AnyAction) => {
      history.push(`/client/${response.payload.clientId}/details`);
    });

  const handleRowClick = (clientId: number, status: string) => {
    resetClientStateAction();
    if (status === 'Waiting For Approval') {
      history.push(`/client/${clientId}/details`);
    } else {
      history.push(`/client/${clientId}/dashboard`);
    }
  };

  const handleNewOnboardingButtonClick = useCallback(() => {
    history.push('/client/onboard/accountType?new');
  }, [history]);

  const resumeOnboardingWizard = (client: OnboardingWizards) => {
    const route = onboardRoutes.find((onboardRoute) => onboardRoute.key === client.stepKey);
    history.push(`onboard/${route?.url || ''}?id=${client.id}`);
  };

  const convertOnboardingWizardData = useCallback(
    (onboardingModels: OnboardingWizardsModel[]): OnboardingWizards[] => {
      return onboardingModels
        .filter(
          (onboardingModel: OnboardingWizardsModel) =>
            searchText.length === 0 ||
            (!!onboardingModel.name && onboardingModel.name.toLowerCase().includes(searchText.toLowerCase()))
        )
        .sort((onboardingModelA: OnboardingWizardsModel, onboardingModelB: OnboardingWizardsModel) =>
          (onboardingModelB?.lastUpdatedOn || '').localeCompare(onboardingModelA?.lastUpdatedOn || '')
        )
        .map((onboardingModel: OnboardingWizardsModel) => {
          const accountSubType = ClientAccountSubType.getById(onboardingModel?.accountSubTypeId ?? null);
          const title = Title.getById(onboardingModel?.primaryContactTitleId || null)?.displayName;
          const contact =
            (!!title ? `${title} ` : '') +
            `${onboardingModel?.primaryContactFirstName} ${onboardingModel?.primaryContactLastName}`;
          return {
            id: onboardingModel.id,
            clientId: onboardingModel.clientId,
            stepKey: onboardingModel.stepKey,
            name: onboardingModel.name,
            type:
              onboardingModel.accountTypeId === ClientAccountType.Individual.id &&
              onboardingModel.accountSubTypeId !== ClientAccountSubType.NonSuper.id &&
              !!accountSubType
                ? accountSubType.displayName
                : ClientAccountType.getById(onboardingModel?.accountTypeId ?? null)?.displayName ?? '',
            contact: contact.trim(),
            adviserName: onboardingModel.advisorName ?? '',
            createdOn: !!onboardingModel?.createdOn
              ? getLocalDateTime(onboardingModel.createdOn, DateTimeFormat.DateTimeShort)
              : '',
            lastUpdatedOn: !!onboardingModel?.lastUpdatedOn
              ? getLocalDateTime(onboardingModel.lastUpdatedOn, DateTimeFormat.DateTimeShort)
              : '',
            statusId: onboardingModel.statusId,
          };
        });
    },
    [searchText]
  );

  return (
    <Container style={{ padding: '20px 0' }}>
      <Tabs
        value={selectedTab}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleTabChange}
        style={{ padding: '0 24px 10px' }}
        TabIndicatorProps={{ style: { height: '4px' } }}
      >
        <Tab label="Clients" data-testid="tabClients" />
        {<Tab label="Onboarding" data-testid="tabOnboarding" />}
      </Tabs>
      <Container>
        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
          <Typography variant="h2">
            {selectedTab === ClientListTabName.Onboarding ? 'Onboarding' : 'Clients'}
          </Typography>
          <Box display={'flex'} justifyContent={'space-between'} paddingTop="10px">
            <Box paddingRight={'10px'}>
              <WO2Menu
                testId="export_button"
                buttonTitle="Export"
                buttonIcon={
                  <div
                    style={{
                      paddingLeft: '10px',
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      backgroundColor: 'blue',
                      borderRadius: '25px',
                      padding: '10px 10px 10px 20px',
                      marginTop: '-7px',
                    }}
                  >
                    <span style={{ color: '#ffffff', fontSize: '0.875rem' }}>EXPORT</span>
                    <ArrowDropDownSharpIcon sx={{ color: '#ffffff' }} />
                  </div>
                }
                items={exportButtons}
              />
            </Box>
            <LoadingIndicator progress={onboardingsLoadProgress}>
              <WO2TextInput
                placeholder={'Client Search'}
                inputProps={{ 'aria-label': 'search' }}
                onChange={(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                  const searchText = event.target.value;
                  setSearchText(searchText);
                }}
                InputProps={{
                  startAdornment: <SearchIcon style={{ margin: '0 10px' }}>search</SearchIcon>,
                }}
                value={searchText}
                disabled={selectedTab === ClientListTabName.Onboarding && onboardingsLoadProgress.isLoading}
              />
            </LoadingIndicator>
            {selectedTab === ClientListTabName.Onboarding && (
              <WO2Button
                color={'primary'}
                style={{ marginLeft: '20px', padding: '15px 28px' }}
                disableElevation
                variant="contained"
                value="New Onboarding"
                data-testid="newOnboardingButton"
                onClick={handleNewOnboardingButtonClick}
              >
                <PersonAddAlt />
                <Typography variant="inherit" style={{ marginLeft: '20px' }}>
                  New Onboarding
                </Typography>
              </WO2Button>
            )}
          </Box>
        </Box>
      </Container>
      {selectedTab === ClientListTabName.Clients && (
        <Container>
          {!!showOnboardingQuick && (
            <QuickAddClient
              onClose={() => {
                setShowOnboardingQuick(false);
              }}
              fetchAfsls={() => fetchAfsls(isDataFromTriumph)}
              afsls={afsls}
              onSave={handleCreateQuickOnboardingClientSave}
              loadingProgress={addQuickClientLoadingProgress}
            />
          )}
          <div ref={targetRef} style={{ padding: '0 20px' }}>
            {showPdfFooterAndHeader && <PdfHeader clientName={'Clients'} />}
            <Grid container spacing={2} style={{ padding: '20px 0' }}>
              <Grid item xs={3}>
                <CardComponent
                  progress={loadingProgress}
                  background={theme.palette.gradient1?.main}
                  value={totals?.all}
                  subText={'All'}
                />
              </Grid>
              <Grid item xs={3}>
                <CardComponent
                  progress={loadingProgress}
                  background={theme.palette.gradient2?.main}
                  value={totals?.active}
                  subText={'Active'}
                />
              </Grid>
              <Grid item xs={3}>
                <CardComponent
                  progress={loadingProgress}
                  background={theme.palette.gradient1?.main}
                  value={totals?.pending}
                  subText={'Pending'}
                />
              </Grid>
              <Grid item xs={3}>
                <CardComponent
                  progress={loadingProgress}
                  background={theme.palette.gradient2?.main}
                  value={totals?.outOfBalance}
                  subText={'Out of Balance'}
                />
              </Grid>
              <Grid item xs={12}>
                <ClientListTable
                  pagedRequest={parameters.pagination}
                  pagedResults={clientList || undefined}
                  progress={clientListProgress}
                  hasClientEditAdminOnlyPermission={hasClientEditAdminOnlyPermission}
                  handlePagedRequest={handleGridActions}
                  downloadProposedOrders={downloadProposedOrders}
                  handleRowClick={handleRowClick}
                  handleDownload={(pagedRequest: PagedRequest, mapper: (cs: ClientItem[]) => ClientCsvRow[]) =>
                    downloadClientList({
                      inputs: parameters,
                      pagination: pagedRequest,
                      isDataFromTriumph: isDataFromTriumph,
                      mapper,
                    })
                  }
                  isDataFromTriumph={isDataFromTriumph}
                ></ClientListTable>
              </Grid>
              {showPdfFooterAndHeader && <PdfFooter />}
            </Grid>
          </div>
        </Container>
      )}
      {selectedTab === ClientListTabName.Onboarding && (
        <Container>
          <div ref={targetRef} style={{ padding: '0 20px' }}>
            {showPdfFooterAndHeader && <PdfHeader clientName={'Clients'} />}
            <OnboardingTable
              onboardings={convertOnboardingWizardData(onboardings)}
              loadingProgress={onboardingsLoadProgress}
              hideAllActions={false}
              deleteOnboarding={(id: string) => deleteOnboardingWizard({ id })}
              resumeOnboarding={(client: OnboardingWizards) => resumeOnboardingWizard(client)}
              resubmitClient={resubmitClient}
              resubmitProgress={resubmitProgress}
              hasClientEditAdminOnlyPermission={hasClientEditAdminOnlyPermission}
              resetStatus={resetStatus}
              resetStatusProgress={resetStatusProgress}
              fetchOnboardingWizardsModels={fetchOnboardingWizardsModels}
            />
            {showPdfFooterAndHeader && <PdfFooter />}
          </div>
        </Container>
      )}
    </Container>
  );
};
