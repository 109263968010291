import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React from 'react';
import { ClientAccountSubType } from 'src/common/types';
import { SuperSimplifierDetails } from 'src/features/clients/client/common/store';

export interface Props {
  details: SuperSimplifierDetails | null | undefined;
}

export const MemberDetails = (props: Props): JSX.Element => {
  return (
    <>
      <Paper elevation={5} style={{ marginBottom: '30px' }}>
        <TableContainer component={Paper} elevation={0}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="left" style={{ width: '70%' }}>
                  <Typography variant="h6" color="textSecondary">
                    SUPER MEMBER NUMBER
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography variant="h6" color="textSecondary">
                    SUB ACCOUNT TYPE
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow style={{ fontWeight: 'bold' }}>
                <TableCell align="left">
                  <Typography data-testid="superMemberNumberData" variant="h5">
                    {props.details?.superMemberNumber}
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography data-testid="subAccountTypeIdData" variant="h5">
                    {ClientAccountSubType.getById(props.details?.subAccountTypeId || 0)?.displayName}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};
