import { Checkbox as MuiCheckbox, CheckboxProps as MUICheckboxProps, FormControlLabel } from '@mui/material';
import { default as React } from 'react';

interface CheckboxProps extends MUICheckboxProps {
  label?: string;
  onChangeHandler: (value: boolean) => void;
}

export const WO2Checkbox = ({ label, onChangeHandler, ...props }: CheckboxProps): JSX.Element => {
  const checkboxComponent = <MuiCheckbox {...props} onChange={(event, val) => onChangeHandler(val)} />;

  if (!label) {
    return checkboxComponent;
  }
  return <FormControlLabel control={checkboxComponent} label={label} />;
};
