import { Enumeration } from '../types';

export class AssetClassEnum extends Enumeration {
  static Unallocated = new AssetClassEnum(0, 'Unallocated', 'Unallocated');
  static Alternatives = new AssetClassEnum(1, 'Alternatives', 'Alternatives');
  static AustralianCash = new AssetClassEnum(2, 'AustralianCash', 'Australian Cash');
  static AustralianFixedIncome = new AssetClassEnum(3, 'AustralianFixedIncome', 'Australian Fixed Income');
  static AustralianREITs = new AssetClassEnum(4, 'AustralianREITs', "Australian REIT's");
  static AustralianShares = new AssetClassEnum(5, 'AustralianShares', 'Australian Shares');
  static Collectibles = new AssetClassEnum(6, 'Collectibles', 'Collectibles');
  static Commodity = new AssetClassEnum(7, 'Commodity', 'Commodity');
  static Currency = new AssetClassEnum(8, 'Currency', 'Currency');
  static DirectProperty = new AssetClassEnum(9, 'DirectProperty', 'Direct Property');
  static Infrastructure = new AssetClassEnum(10, 'Infrastructure', 'Infrastructure');
  static InternationalCash = new AssetClassEnum(11, 'InternationalCash', 'International Cash');
  static InternationalFixedIncome = new AssetClassEnum(12, 'InternationalFixedIncome', 'International Fixed Income');
  static InternationalListedProperty = new AssetClassEnum(13, 'InternationalListedProperty', 'International Listed Property');
  static InternationalShares = new AssetClassEnum(14, 'InternationalShares', 'International Shares');
  static Mixed = new AssetClassEnum(15, 'Mixed', 'Mixed');
  static Other = new AssetClassEnum(16, 'Other', 'Other');
  static Protection = new AssetClassEnum(17, 'Protection', 'Protection');
  static TermDeposit = new AssetClassEnum(19, 'TermDeposit', 'Term Deposit');
  static AtCall = new AssetClassEnum(20, 'AtCall', 'At Call');
  static Deposit = new AssetClassEnum(37, 'Deposit', 'Deposit');
  static DevelopedMarketEquities = new AssetClassEnum(38, 'DevelopedMarketEquities', 'Developed Market Equities');
  static EmergingMarketEquities = new AssetClassEnum(39, 'EmergingMarketEquities', 'Emerging Market Equities');
  static AtRiskDebt = new AssetClassEnum(40, 'AtRiskDebt', 'At Risk Debt');
  static SecureDebt = new AssetClassEnum(41, 'SecureDebt', 'Secure Debt');
  static Satellite = new AssetClassEnum(44, 'Satellite', 'Satellite');
  static Hedge = new AssetClassEnum(45, 'Hedge', 'Hedge');
  static AsiaExJapanEquities = new AssetClassEnum(46, 'AsiaExJapanEquities', 'Asia ex Japan Equities');
  static InternationalExUSEquities = new AssetClassEnum(47, 'InternationalExUSEquities', 'International ex US Equities');
  static USEquities = new AssetClassEnum(48, 'USEquities', 'US Equities');
  static HedgeFundsAndAlternativies = new AssetClassEnum(49, 'HedgeFundsAndAlternativies', 'Hedge Funds and Alternativies');
  static HighYieldFixedInterest = new AssetClassEnum(50, 'HighYieldFixedInterest', 'High Yield Fixed Interest');
  static InternationalREITs = new AssetClassEnum(51, 'InternationalREITs', 'International REITs');
  static Property = new AssetClassEnum(52, 'Property', 'Property');
  static Equity = new AssetClassEnum(53, 'Equity', 'Equity');
  static FixedInterest = new AssetClassEnum(54, 'FixedInterest', 'Fixed Interest');
  static CommodityEnergy = new AssetClassEnum(55, 'CommodityEnergy', 'Commodity/Energy');
  static AtCall2 = new AssetClassEnum(56, 'AtCall2', 'AtCall');
  static AUD = new AssetClassEnum(57, 'AUD', 'AUD');
  static MoneyMarket = new AssetClassEnum(58, 'MoneyMarket', 'Money Market');
  static MixedAsset = new AssetClassEnum(59, 'MixedAsset', 'Mixed Asset');
  static RealIncome = new AssetClassEnum(60, 'RealIncome', 'Real Income');
  static DiversifiedIncome = new AssetClassEnum(61, 'DiversifiedIncome', 'Diversified Income');
  static AlternativeDefensive = new AssetClassEnum(62, 'AlternativeDefensive', 'Alternative Defensive');
  static AlternativeGrowth = new AssetClassEnum(63, 'AlternativeGrowth', 'Alternative Growth');
  static SelfDirected = new AssetClassEnum(64, 'SelfDirected', 'Self Directed');
  static PropertyAndInfrastructure = new AssetClassEnum(65, 'Property&Infrastructure', 'Property & Infrastructure');
  static InternationalListedinfrastructure = new AssetClassEnum(66, 'InternationalListedinfrastructure', 'International Listed infrastructure');
  static AustralianProperty = new AssetClassEnum(67, 'AustralianProperty', 'Australian Property');
  static InternationalProperty = new AssetClassEnum(68, 'InternationalProperty', 'International Property');
  static AustralianEquities = new AssetClassEnum(69, 'AustralianEquities', 'Australian Equities');
  static AustralianFixedInterest = new AssetClassEnum(70, 'AustralianFixedInterest', 'Australian Fixed Interest');
  static GlobalEquities = new AssetClassEnum(71, 'GlobalEquities', 'Global Equities');
  static GlobalEquitiesHedgedAUD = new AssetClassEnum(72, 'GlobalEquities(HedgedAUD)', 'Global Equities (Hedged AUD)');
  static EmergingMarkets = new AssetClassEnum(73, 'EmergingMarkets', 'Emerging Markets');
  static GlobalFixedInterestHedgedAUD = new AssetClassEnum(74, 'GlobalFixedInterest(HedgedAUD)', 'Global Fixed Interest (Hedged AUD)');
  static GlobalInfrastructure = new AssetClassEnum(75, 'GlobalInfrastructure', 'Global Infrastructure');
  static AlternativesFixedInterest = new AssetClassEnum(76, 'Alternatives-FixedInterest', 'Alternatives - Fixed Interest');
  static GlobalREITs = new AssetClassEnum(77, 'GlobalREITs', 'Global REITs');
  static AustralianBonds = new AssetClassEnum(78, 'AustralianBonds', 'Australian Bonds');
  static GlobalPropertySecuritiesAndInfrastructure = new AssetClassEnum(79, 'GlobalPropertySecurities&Infrastructure', 'Global Property Securities & Infrastructure');
  static InternationalBonds = new AssetClassEnum(80, 'InternationalBonds', 'International Bonds');
  static Cash = new AssetClassEnum(81, 'Cash', 'Cash');
  static RealAssets = new AssetClassEnum(82, 'RealAssets', 'Real Assets');
  static UncorrelatedStrategy = new AssetClassEnum(83, 'UncorrelatedStrategy', 'Uncorrelated Strategy');
  static Credit = new AssetClassEnum(84, 'Credit', 'Credit');
  static GovtBonds = new AssetClassEnum(85, 'GovtBonds', 'Govt Bonds');
  static Agriculture = new AssetClassEnum(86, 'Agriculture', 'Agriculture');
  static PrivateEquity = new AssetClassEnum(87, 'PrivateEquity', 'Private Equity');
  static GlobalProperty = new AssetClassEnum(88, 'GlobalProperty', 'Global Property');
  static ListedInfrastructure = new AssetClassEnum(89, 'ListedInfrastructure', 'Listed Infrastructure');
  static GrowthAlternatives = new AssetClassEnum(90, 'GrowthAlternatives', 'Growth Alternatives');
  static CreditIncome = new AssetClassEnum(91, 'CreditIncome', 'Credit Income');
  static CashAndDefensiveIncome = new AssetClassEnum(92, 'Cashanddefensiveincome', 'Cash and defensive income');
  static PreciousMetals = new AssetClassEnum(93, 'PreciousMetals', 'Precious Metals');
  static FoodOrHealth = new AssetClassEnum(94, 'Food/Health', 'Food/Health');
  static InterestBearingSecurities = new AssetClassEnum(95, 'InterestBearingSecurities', 'Interest Bearing Securities');
  static EthicalOrSRIFixedInterest = new AssetClassEnum(96, 'Ethical/SRIFixedInterest', 'Ethical / SRI Fixed Interest');
  static EthicalOrSRIProperty = new AssetClassEnum(97, 'Ethical/SRIProperty', 'Ethical / SRI Property');
  static EthicalOrSRIAustralianShares = new AssetClassEnum(98, 'Ethical/SRIAustralianShares', 'Ethical / SRI Australian Shares');
  static EthicalOrSRIInternationalShares = new AssetClassEnum(99, 'Ethical/SRIInternationalShares', 'Ethical / SRI International Shares');
  static InterestRateSecurities = new AssetClassEnum(100, 'InterestRateSecurities', 'Interest Rate Securities');
  static Hybrids = new AssetClassEnum(101, 'Hybrids', 'Hybrids');
  static AustralianSmallCaps = new AssetClassEnum(102, 'AustralianSmallCaps', 'Australian Small Caps');
  static GlobalSmallCaps = new AssetClassEnum(103, 'GlobalSmallCaps', 'Global Small Caps');
  static GlobalCredit = new AssetClassEnum(104, 'GlobalCredit', 'Global Credit');

  static getById = (id: number | null | undefined): AssetClassEnum | undefined => {
    return Object.values(AssetClassEnum)
      .filter((t) => !!t['name'])
      .find((t: AssetClassEnum) => t.id === id);
  };

  static getByName = (name: string): AssetClassEnum | undefined => {
    return Object.values(AssetClassEnum)
      .filter((t) => t instanceof AssetClassEnum)
      .find((t: AssetClassEnum) => t.name.toLowerCase() === name.toLowerCase());
  };

  static getAll = (): AssetClassEnum[] => {
    return Object.values(AssetClassEnum)
      .filter((t) => !!t['name'])
      .map((val) => {
        return { id: val.id, name: val.name, displayName: val.displayName };
      });
  };
}
