import { Typography } from '@mui/material';
import { MUIDataTableOptions } from 'mui-datatables';
import React from 'react';
import { formatDollars } from '../../../../common';
import { ClientSideDataTable } from '../../../../common/components/dataTable/clientSide/clientSideDataTable';
import { DatatableColumn, FilterDataType } from '../../../../common/components/dataTable/types';
import { LoadingProgress } from '../../../../common/store/types';
import { theme } from '../../../../themes';
import { StrategyAssociationItem } from '../store/types';

export interface Props {
  progress: LoadingProgress;
  data: StrategyAssociationItem[];
}
export function AssociationsTable({ progress, data }: Props): JSX.Element {
  const defaultItems: StrategyAssociationItem[] = [];
  const columns: DatatableColumn[] = [
    {
      label: 'PORTFOLIO ID',
      name: 'investmentServiceCode',
      options: {
        customBodyRenderLite: function customBodyRenderLite(dataIndex): React.ReactNode {
          return (
            <Typography
              variant={'h5'}
              color={'primary'}
              style={{
                color: theme.palette.primary.main,
                fontWeight: 'bold',
                letterSpacing: '1px',
              }}
            >
              {data[dataIndex] !== undefined ? data[dataIndex]?.investmentServiceCode : ''}
            </Typography>
          );
        },
        sort: false,
        viewColumns: false,
      },
    },
    {
      name: 'clientName',
      label: 'CLIENT NAME',
      textAlign: 'left',
      options: {
        customBodyRenderLite: function customBodyRenderLite(dataIndex): React.ReactNode {
          return (
            <Typography variant={'h5'} align="left">
              {data[dataIndex] !== undefined ? data[dataIndex]?.clientName : ''}
            </Typography>
          );
        },
        sort: true,
      },
      filterDataType: FilterDataType.string,
    },
    {
      name: 'portfolioName',
      label: 'PORTFOLIO NAME',
      textAlign: 'left',
      options: {
        customBodyRenderLite: function customBodyRenderLite(dataIndex): React.ReactNode {
          return (
            <Typography variant={'h5'} align="left">
              {data[dataIndex] !== undefined ? data[dataIndex]?.investmentServiceName : ''}
            </Typography>
          );
        },
        sort: true,
      },
      filterDataType: FilterDataType.string,
    },
    {
      name: 'value',
      label: 'VALUE',
      textAlign: 'right',
      options: {
        customBodyRenderLite: (dataIndex: number) =>
          formatDollars(data[dataIndex] !== undefined ? data[dataIndex]?.value : 0),
        sort: true,
      },
      filterDataType: FilterDataType.numeric,
    },
  ];
  const options: MUIDataTableOptions = {
    search: true,
    download: true,
    filter: false,
  };
  return (
    <ClientSideDataTable
      data={data != null && data.length > 0 && !progress.isLoading ? data : defaultItems}
      columns={columns}
      options={options}
      loadingProgress={progress}
    />
  );
}
