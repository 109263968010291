import { Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';
import { Box, Container, Stack, Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { DataGridPro, GridActionsCellItem, GridColumns } from '@mui/x-data-grid-pro';
import React, { useCallback, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ProgressButton, WO2Select } from 'src/common/components';
import { useConfirmation } from 'src/common/components/dialogs/confirmationService';
import { ServiceTypesEnum } from '../../../../common/types';
import { RebalanceTypeEnum } from '../../store/enums';
import { Props } from '../container';

export const Portfolios = ({
  match,
  setAfslId,
  afslId,
  fetchAfsls,
  afsls,
  templates,
  fetchTemplates,
  deleteTemplate,
  createTemplate,
  creatingProgress,
  fetchApprovedProducts,
  fetchTemplatesProgress,
  fetchApprovedProductsProgress,
}: Props): JSX.Element => {
  const confirm = useConfirmation();
  const history = useHistory();
  const { pathname } = useLocation();
  const [pageSize, setPageSize] = React.useState<number>(10);

  useEffect(() => {
    setAfslId(+match.params.afslId);
  }, [match.params.afslId]);

  useEffect(() => {
    fetchAfsls();
  }, [fetchAfsls]);

  useEffect(() => {
    if (afslId) {
      fetchTemplates({ afslId });
      fetchApprovedProducts({ afslId });
    }
  }, [afslId]);

  const deleteRow = useCallback(
    (investmentServiceTemplateId: number) => {
      confirm({
        title: 'Delete template',
        description: 'Are you sure you wish to delete this template?',
        description2: 'This action is permanent, please ensure you have selected the correct template before proceeding.',
      }).then(() => {
        afslId && deleteTemplate({ afslId, investmentServiceTemplateId });
      });
    },
    [deleteTemplate, afslId]
  );

  const editRow = useCallback(
    (id: string) => {
      if (!!match.params.afslId) {
        history.push(`${pathname}/${id}`);
      } else {
        history.push(`${pathname}/${afslId}/${id}`);
      }
    },
    [match.params.afslId, afslId, pathname, history]
  );

  const handleCreateTemplate = useCallback(async () => {
    !!afslId &&
      (await createTemplate({
        afslId,
        name: 'new template',
        serviceTypeId: ServiceTypesEnum.IMA.id,
        primaryBenchmarkProductId: null,
        secondaryBenchmarkProductId: null,
        portalBenchmarkIds: [],
        minTradeValue: 0,
        fortyFiveDayRule: false,
        oneYearRule: false,
        rebalanceTypeId: RebalanceTypeEnum.Monthly.id,
        assetClassToleranceBands: [
          { bandName: 'Green', bandMinValue: 0, bandMaxValue: 4.99 },
          { bandName: 'Amber', bandMinValue: 5, bandMaxValue: 9.99 },
          { bandName: 'Red', bandMinValue: 10, bandMaxValue: null },
        ],
        securityToleranceBands: [
          { bandName: 'Green', bandMinValue: 0, bandMaxValue: 10 },
          { bandName: 'LightGreen', bandMinValue: 10, bandMaxValue: 20 },
          { bandName: 'Blue', bandMinValue: 20, bandMaxValue: 30 },
          { bandName: 'Amber', bandMinValue: 30, bandMaxValue: 40 },
          { bandName: 'Red', bandMinValue: 40, bandMaxValue: null },
        ],
        configurations: [],
        strategicAssetAllocations: [],
        securityConstraints: [],
      }));
  }, [afslId, createTemplate]);

  const columns: GridColumns = [
    {
      field: 'name',
      headerName: 'NAME',
      flex: 4,
    },
    {
      field: 'description',
      headerName: 'DESCRIPTION',
      flex: 5,
    },
    {
      field: 'actions',
      type: 'actions',
      sortable: false,
      width: 80,
      getActions: React.useCallback(
        (params) => {
          return [
            <GridActionsCellItem
              key="1"
              icon={<EditIcon />}
              label="Edit"
              onClick={() => editRow(params.row.investmentServiceTemplateId.toString())}
              color="primary"
            />,
            <GridActionsCellItem
              key="1"
              icon={<DeleteIcon />}
              label="Delete"
              onClick={() => {
                deleteRow(params.row.investmentServiceTemplateId);
              }}
              color="primary"
            />,
          ];
        },
        [deleteRow, editRow, afslId]
      ),
    },
  ];

  return (
    <Box style={{ paddingTop: '20px' }}>
      <Container>
        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
          <Typography variant="h2">Templates</Typography>
        </Box>
      </Container>
      <Stack direction="row" width={'100%'}>
        <Box display={'flex'} justifyContent={'space-between'} paddingTop="30px" paddingRight={'10px'} width="70%">
          {afsls.length > 1 && (
            <FormControl variant="outlined" style={{ width: '260px' }}>
              <InputLabel id="afsl-select-label">Afsl</InputLabel>
              <WO2Select
                labelId="afsl-select-label"
                id="afsl-select"
                value={afslId || ''}
                onChange={(event) => {
                  setAfslId(+`${event.target.value}`);
                }}
                label={'Portfolio'}
                style={{ width: '600px' }}
              >
                {afsls.map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </WO2Select>
            </FormControl>
          )}
        </Box>
        <Box display={'flex'} justifyContent={'space-between'} paddingTop="30px" paddingRight={'10px'} width="30%" style={{ justifyContent: 'end' }}>
          <ProgressButton
            color={'primary'}
            style={{ borderRadius: 24, height: 48 }}
            disableElevation
            disabled={!afslId || fetchApprovedProductsProgress.isLoading}
            progress={creatingProgress}
            onClick={handleCreateTemplate}
            variant={'contained'}
            value="SaveReportingParameters"
          >
            ADD TEMPLATE
          </ProgressButton>
        </Box>
      </Stack>
      <div style={{ width: '100%', paddingTop: '30px' }}>
        <DataGridPro
          rows={templates.map((t, index) => ({ id: index, ...t }))}
          columns={columns}
          initialState={{
            sorting: {
              sortModel: [{ field: 'name', sort: 'asc' }],
            },
          }}
          loading={fetchTemplatesProgress.isLoading}
          autoHeight
          pagination
          pageSize={pageSize}
          rowsPerPageOptions={[5, 10, 20]}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          disableSelectionOnClick
        />
      </div>
    </Box>
  );
};
