import { connect, ConnectedProps } from 'react-redux';
import { progressSelectorFactory } from '../../../../../common';
import { RootState } from '../../../../../reducers';
import { selectHasClientEdit } from '../../../../../store/selectors';
import { selectClientId } from '../../common/store/selectors';
import { MainDetails } from './components/mainDetails';
import { selectMainDetailsItem } from './store/selectors';
import { mainDetailsSlice } from './store/slice';
import { fetchClientAccount, MainDetailsActionTypes, updateClientAccount } from './store/thunks';

const mapStateToProps = (state: RootState) => ({
  hasClientEditPermission: selectHasClientEdit(state),
  clientId: selectClientId(state),
  details: selectMainDetailsItem(state),
  isLoadingProgress: progressSelectorFactory([MainDetailsActionTypes.Fetch])(state),
  saveProgress: progressSelectorFactory([MainDetailsActionTypes.Update])(state),
});

const mapDispatchToProps = {
  ...mainDetailsSlice.actions,
  updateClientAccount,
  fetchClientAccount,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector>;
export const MainDetailsContainer = connector(MainDetails);
