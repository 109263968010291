import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { hasErrorsSelectorFactory, isLoadingSelectorFactory } from '../../../../common';
import { RootState } from '../../../../store';
import { accountSearchResult, accountSelected } from '../search/store/selectors';
import { searchSlice } from '../search/store/slice';
import { fetchEmailTemplates } from './../../common/store/thunks';
import { SendEmail } from './components/sendemail';
import { clients, emailTemplates, parameters, selectSender } from './store/selectors';
import { sendSlice } from './store/slice';
import { EmailTemplateSendTypes, fetchUser, sendEmail } from './store/thunks';

const actionTypes = [EmailTemplateSendTypes.SendEmail];

const mapStateToProps = (state: RootState) => ({
  parameters: parameters(state),
  emailTemplates: emailTemplates(state),
  clients: clients(state),
  accountSelected: accountSelected(state),
  accountList: accountSearchResult(state),
  sender: selectSender(state),
  isLoading: isLoadingSelectorFactory(actionTypes)(state),
  hasErrors: hasErrorsSelectorFactory(actionTypes)(state),
});
const mapDispatchToProps = {
  ...sendSlice.actions,
  setAccountSelected: searchSlice.actions.setAccountSelected,
  fetchEmailTemplates,
  fetchUser,
  sendEmail,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector> & RouteComponentProps;
export const SendEmailContainer = connector(SendEmail);
