import { Pagination as MuiPagination } from '@mui/material/';
import React from 'react';
import { useStyles } from './themes/paginationStyles';

interface Props {
  numberOfPages: number;
  pageNumber: number;
  handleClickPagination: (page: number) => void;
}

export const Pagination = (props: Props): JSX.Element => {
  const classes = useStyles();
  const { numberOfPages, pageNumber, handleClickPagination } = props;

  return (
    <>
      {numberOfPages > 1 && (
        <div className={classes.root} style={{ paddingBottom: '20px' }}>
          <MuiPagination
            count={numberOfPages}
            color="primary"
            onChange={(_event: React.ChangeEvent<unknown>, page: number) => {
              handleClickPagination(page);
            }}
            page={pageNumber}
          />
        </div>
      )}
    </>
  );
};
