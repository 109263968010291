import { TriumphBaseRequest } from 'src/features/clients/client/portfolio/overview/store/types';
import { Enumeration } from '../../../common/types/enumeration';
import { SecurityItemWithPrice } from '../common/store/types';

export interface BulkOrder {
  security: SecurityItemWithPrice;
  action: string;
  orders: Order[];
  comments: string;
}

export interface Order {
  portfolioId: number;
  units: number;
}

export interface ValidationResult {
  errors: string[];
  information: string[];
  warnings: string[];
}

export class TradeAction extends Enumeration {
  static Buy = new TradeAction(1, 'Buy', 'Buy');
  static Sell = new TradeAction(2, 'Sell', 'Sell');
  static SellAll = new TradeAction(3, 'SellAll', 'Sell All');
}

export interface ValidateOrderResultPayload extends TriumphBaseRequest {
  order: PreApprovalInvestmentService[];
  validationResult: ValidationResult;
  investmentServicesLockedByOrders: number[];
}

export interface ValidationOrderResult {
  warnings: string[];
  errors: string[];
  investmentServicesLockedByOrders: number[];
}

export interface GetPreApprovalBulkValidationRequest extends TriumphBaseRequest {
  preApprovalInvestmentServices: PreApprovalInvestmentService[];
}

export interface PreApprovalInvestmentService {
  clientId: number;
  clientName: string;
  investmentServiceId: number;
  InvestmentServiceName: string;
  preApprovalTradeItems: PreApprovalTradeItem[];
}

export interface PreApprovalTradeItem {
  calculatedTradeUnits: number;
  securityId: number;
  tradeAction: string;
  unitPrice: number;
  priceType: string | null;
  priceLimit: number | null;
  expiryType: string | null;
  expiryDate: string | null;
  assetClass?: string | null;
  assetClassId?: number | null;
}
