import React from 'react';
import { ADVISER_CONTACT_EMAIL } from 'src/common/utils/dashContactDetails';

export interface Props {
  details: string | '';
}

export const MetlifeErrorMessage = ({ details='' }: Props): JSX.Element => {
  return (
    <p>
      <ul>
        We couldn&apos;t launch the Metlife portal at this time.
        <br />
        Please try again later or contact us on 1300 726 008 or email {ADVISER_CONTACT_EMAIL}. ? &nbsp;
      </ul>
      {details}
    </p>
  );
};
