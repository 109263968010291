import { Enumeration } from 'src/common/types/enumeration';

export enum ClientListTabName {
  Clients = 0,
  Onboarding = 1,
}

export class OnboardingWizardStatus extends Enumeration {
  static Active = new OnboardingWizardStatus(1, 'Active', 'Active');
  static Submitted = new OnboardingWizardStatus(2, 'Submitted', 'Submitted');
  static Deleted = new OnboardingWizardStatus(3, 'Deleted', 'Deleted');
  static Processing = new OnboardingWizardStatus(4, 'Processing', 'Processing');
  static Completed = new OnboardingWizardStatus(5, 'Completed', 'Completed');
  static Error = new OnboardingWizardStatus(6, 'Error', 'Error');

  static getArray = (): OnboardingWizardStatus[] => [
    OnboardingWizardStatus.Active,
    OnboardingWizardStatus.Submitted,
    OnboardingWizardStatus.Deleted,
    OnboardingWizardStatus.Processing,
    OnboardingWizardStatus.Completed,
    OnboardingWizardStatus.Error,
  ];

  static getById = (id: number): OnboardingWizardStatus | undefined => {
    return Object.values(OnboardingWizardStatus)
      .filter((t) => t instanceof OnboardingWizardStatus)
      .find((t: OnboardingWizardStatus) => t.id === id);
  };
}
