import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';
import { DateRange } from '../../../../store';
import { fetchDividendDistributionsByPaymentDate, fetchSecurityIncomeSummary } from './thunks';
import { DividendDistributionsByPaymentDateItem, fetchPagedResults, SecurityIncomeState, SecurityIncomeSummary } from './types';

export const initialState: SecurityIncomeState = {
  parameters: {
    dates: { dateFrom: DateTime.now().minus({ years: 1 }).toISODate(), dateTo: DateTime.now().toISODate() },
    pagination: {
      pageNumber: 1,
      pageSize: 25,
      queryFields: [
        {
          fieldName: 'paymentDate',
          descendingSortDirection: true,
          operator: '',
          hasSearchTerm: false,
          isSortTerm: true,
          searchTerm: '',
        },
        {
          fieldName: 'isLockedForPushdown',
          descendingSortDirection: true,
          operator: 'IsEqual',
          hasSearchTerm: true,
          isSortTerm: false,
          searchTerm: 'false',
        },
      ],
    },
  },
  securityIncomeSummary: {
    oneYearGrossIncomeYield: 0,
    oneYearNetIncomePerUnit: 0,
    oneYearNetIncomeYield: 0,
    latestNetIncomePerUnit: 0,
  },
  dividendDistributionsByPaymentDate: {
    pageNumber: 1,
    pageSize: 25,
    totalNumberOfPages: 100,
    totalNumberOfRecords: 10000,
    results: [],
  },
};

export const securityIncomeSlice = createSlice({
  name: '@@security/income',
  initialState,
  reducers: {
    setDateParameter: (state, action: PayloadAction<DateRange>) => {
      state.parameters.dates = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSecurityIncomeSummary.fulfilled, (state, action: PayloadAction<SecurityIncomeSummary>) => {
      state.securityIncomeSummary = action.payload;
    });
    builder.addCase(
      fetchDividendDistributionsByPaymentDate.fulfilled,
      (state, action: PayloadAction<fetchPagedResults<DividendDistributionsByPaymentDateItem>>) => {
        state.dividendDistributionsByPaymentDate = action.payload.results;
        state.parameters.pagination = action.payload.pagination;
      }
    );
  },
});
