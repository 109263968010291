import { moment } from 'src/common/types';
import { ClientPortfolio } from '../../common/store/types';
import { TriumphBaseRequest } from '../../portfolio/overview/store';
import { Transaction } from '../../transactions/store/types';

export interface DashboardState {
  parameters: DashboardParameters;
  monthlyPerformanceChartResult?: MonthlyPerformanceChartResult;
  clientPortfolioDetailResult?: ClientPortfolioDetailsResult;
  recentTransactionsListResult?: Transaction[];
}

export interface RecentTransactionsByDate {
  [index: string]: Transaction[];
}

export interface DashboardParameters {
  selectedPortfolio?: ClientPortfolioSelectModel | null;
  monthlyPerformanceChartStartDate?: MonthlyPerformanceChartStartDateParameter;
  selectedHolding: string | null;
}

export interface ThunkParameters extends TriumphBaseRequest {
  clientId: number;
  parameters: DashboardParameters;
}

// Monthly Performance Chart
export interface ClientPortfolioSelectModel extends ClientPortfolio {
  accountIdList: number[];
}

export interface MonthlyPerformanceChartResult {
  id: number;
  portfolioValue: number;
  changeInValue: number;
  changeInValuePercentage: number;
  seriesData: MonthlyPerformanceChartSeriesItem[];
}

export interface MonthlyPerformanceChartSeriesItem {
  date: moment;
  return: number;
  cumulativeReturn: number;
}

export interface MonthlyPerformanceChartStartDateParameter {
  startDate: moment;
  interval: PerformanceChartInterval;
}

export enum PerformanceChartInterval {
  OneMonth = '1M',
  ThreeMonths = '3M',
  SixMonths = '6M',
  OneYear = '1Y',
  ThreeYears = '3Y',
  Inception = 'ALL',
}

// Client Portfolio Detail -- Display
export interface AssetGroup {
  name: string;
  value: number;
  gainValue: number;
  gainPercentage: number;
  items: AssetGroupItem[];
}

export interface AssetGroupItem {
  securityName: string | null;
  securityCode: string | null;
  units: number | null;
  unitPrice: number | null;
  value: number;
}

// Client Portfolio Detail -- API
export interface ClientPortfolioDetailsResult {
  clientId: number;
  performance?: AssetClassPerformanceReportViewModel[];
  holdings?: CalculatedHoldingsForPortfolioViewModel;
}

export interface AssetClassPerformanceReportViewModel {
  assetClass: string;
  totalReturnAmount: number;
  totalReturnPercentage: number;
}

export interface CalculatedHoldingsForPortfolioViewModel {
  clientId: number;
  investmentServiceId: number | null;
  marketValue: number;
  availableCash: number;
  items: CalculatedHoldingsForPortfolioItem[];
}

export interface CalculatedHoldingsForPortfolioItem {
  isGroup: boolean;
  groupName: string | null;
  assetClassId: number | null;
  assetClass: string | null;
  securityId: number | null;
  securityCode: string | null;
  securityName: string | null;
  units: number | null;
  unitPrice: number | null;
  currentValue: number;
  items: CalculatedHoldingsForPortfolioItem[];

  currentWeightPercentage: number;
  targetValue: number;
  targetWeightPercentage: number;
  differenceValue: number;
  differenceWeightPercentage: number;
}

// Recent Transactions
export interface RecentTransactionsListResultItem {
  id: number;
  accountId: number;
  securityId: number;
  securityCode: string;
  tradeDate: string;
  units: number;
  unitPrice: number;
  transactionValue: number;
  transactionType: string;
  comments: string;
}
