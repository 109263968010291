import { TableCell, TableRow, Typography } from '@mui/material';
import React from 'react';
import { formatDollars } from '../../../../../common';
import { PreapprovalGroupedTrades } from '../store/types';

interface Props {
  groupedTrades: PreapprovalGroupedTrades;
  index: number;
}

export default function AssetSummary(props: Props): JSX.Element {
  const { groupedTrades, index } = props;

  return (
    <TableRow key={index}>
      <TableCell component="th" scope="row">
        <Typography variant="h5">{groupedTrades.title}</Typography>
      </TableCell>
      <TableCell align="center">
        <Typography variant="h5">{groupedTrades.totalBuys + groupedTrades.totalSells}</Typography>
      </TableCell>
      <TableCell align="right">
        <Typography variant="h5">{formatDollars(groupedTrades.totalCalculatedValue)}</Typography>
      </TableCell>
    </TableRow>
  );
}
