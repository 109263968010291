import { createSelector } from '@reduxjs/toolkit';
import { selectCGTConfiguration } from '../../store';

export const selectCarriedForwardLossesState = createSelector(selectCGTConfiguration, (cgtConfiguration) => {
  return cgtConfiguration.carriedForwardLossesState;
});

export const selectPreviousFinancialYears = createSelector(selectCarriedForwardLossesState, (financialYears) => {
  return financialYears.previousFinancialYears;
});
