import { createSelector } from '@reduxjs/toolkit';
import { selectSecurity } from '../../store/selectors';

export const selectSecurityDashboardState = createSelector(selectSecurity, (securityState) => securityState.dashboard);
export const selectParameters = createSelector(selectSecurityDashboardState, (listState) => listState.parameters);
export const selectConsolidatedSecurityHoldingsSummary = createSelector(
  selectSecurityDashboardState,
  (listState) => listState.consolidatedSecurityHoldingsSummary
);
export const selectSecurityPrices = createSelector(selectSecurityDashboardState, (listState) => listState.securityPrices);
export const selectSecurityDashboard = createSelector(selectSecurityDashboardState, (listState) => listState.securityDashboard);
export const selectDailySecurityPricesChart = createSelector(selectSecurityDashboardState, (listState) => listState.dailySecurityPricesChart);
