import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Typography,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { formatDollars } from '../../../../../common';
import history from '../../../../../history';
import { Rebalance, RebalanceDeletionRequestPayload, RebalanceValidationRequest, Summary } from '../store/types';
import WO2Button from './../../../../../common/components/button/Button';
import PortfolioValue from './portfolioValue';

interface Props {
  summary: Summary;
  deleteRebalance: (payload: RebalanceDeletionRequestPayload) => void;
  validateRebalance: (payload: RebalanceValidationRequest) => void;
  setSellAll: () => void;
  rebalance: Rebalance;
  isPreApproved: boolean;
  rebalanceValidationRequest: RebalanceValidationRequest;
  totalValue: number;
}

export default function Checkout(props: Props): JSX.Element {
  const {
    summary,
    deleteRebalance,
    validateRebalance,
    rebalance,
    totalValue,
    isPreApproved,
    rebalanceValidationRequest,
  } = props;
  const [isDialogOpen, setDialogOpen] = useState<boolean>(false);
  const [isValidateRequested, setValidateRequest] = useState<boolean>(false);
  const isInitialValidation = useRef(true);

  useEffect(() => {
    if (isInitialValidation.current) {
      isInitialValidation.current = false;
      return;
    }
    if (isPreApproved) {
      history.push('/rebalance/checkout');
    }
  }, [isPreApproved]);

  useEffect(() => {
    if (isValidateRequested && !isPreApproved) {
      setValidateRequest(false);
      validateRebalance(rebalanceValidationRequest);
      window.scrollTo(0, 0);
    }
    if (isValidateRequested && isPreApproved) {
      setValidateRequest(false);
      history.push('/rebalance/checkout');
    }
  }, [isValidateRequested, validateRebalance, rebalanceValidationRequest, isPreApproved]);

  return (
    <Paper elevation={0}>
      <PortfolioValue totalValue={totalValue}></PortfolioValue>

      <div
        style={{
          width: '100%',
          display: 'flex',
          marginBottom: '40px',
          padding: '0px 20px 20px 20px',
        }}
      >
        <Box width={'100%'} display={'inline-flex'}>
          <div style={{ marginRight: '10px' }}>
            <Typography variant="h3">{formatDollars(summary.openingCash)}</Typography>
            <Typography variant="h6" color={'textSecondary'} style={{ marginRight: '10px' }}>
              OPENING CASH
            </Typography>
          </div>
          <Typography variant="h3" style={{ marginRight: '10px' }}>
            -
          </Typography>
          <div style={{ marginRight: '10px' }}>
            <Typography variant="h3">{formatDollars(summary.totalBuysCash)}</Typography>
            <Typography variant="h6" color={'textSecondary'} style={{ marginRight: '10px' }}>
              TOTAL BUYS
            </Typography>
          </div>
          <Typography variant="h3" style={{ marginRight: '10px' }}>
            +
          </Typography>
          <div style={{ marginRight: '10px' }}>
            <Typography variant="h3">{formatDollars(summary.totalSellsCash)}</Typography>
            <Typography variant="h6" color={'textSecondary'}>
              TOTAL SELLS
            </Typography>
          </div>
          <Typography variant="h3" style={{ marginRight: '10px' }}>
            =
          </Typography>
          <div style={{ marginRight: '10px' }}>
            <Typography variant="h3">{formatDollars(summary.proposedCash)}</Typography>
            <Typography variant="h6" color={'textSecondary'}>
              PROPOSED CASH
            </Typography>
          </div>
        </Box>
        <Box width={'100%'} display={'inline-flex'} justifyContent={'flex-end'}>
          <WO2Button
            style={{ marginRight: '10px' }}
            disableElevation
            color={'primary'}
            onClick={() => setDialogOpen(true)}
            variant="outlined"
            value="Cancel and close"
            data-testid="cancelAndClose"
          >
            Cancel and close
          </WO2Button>

          <Dialog
            open={isDialogOpen}
            onClose={() => setDialogOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle>{'Cancel Rebalance'}</DialogTitle>
            <DialogContent>
              <DialogContentText>Are you sure you want to cancel the rebalance?</DialogContentText>
            </DialogContent>
            <DialogActions>
              <WO2Button disableElevation variant={'outlined'} color={'primary'} onClick={() => setDialogOpen(false)}>
                No
              </WO2Button>
              <WO2Button
                autoFocus
                value="Yes"
                disableElevation
                variant={'contained'}
                color={'primary'}
                onClick={() => {
                  deleteRebalance({
                    investmentServiceRebalanceId: rebalance.investmentServiceRebalanceId,
                    rebalanceId: rebalance.rebalanceId,
                  });
                  const location = Object.assign({}, history.location);
                  location.search = '';
                  history.push(location);
                  setDialogOpen(false);
                }}
              >
                Yes
              </WO2Button>
            </DialogActions>
          </Dialog>

          <WO2Button
            color={'primary'}
            disableElevation
            onClick={() => setValidateRequest(true)}
            variant={'contained'}
            value="Review orders"
            data-testid="reviewOrders"
          >
            Review orders
          </WO2Button>
        </Box>
      </div>
    </Paper>
  );
}
