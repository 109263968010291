import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MainDetails, MainDetailsState } from '../../../common/store/types';
import { fetchClientAccount } from './thunks';
import { uploadSuperSimplifierApplicationForm, sendApplicationForm } from '../../accounts/store/thunks';

export const initialState: MainDetailsState = {
  item: undefined,
};

export const mainDetailsSlice = createSlice({
  name: 'mainDetails',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchClientAccount.fulfilled, (state, action: PayloadAction<MainDetails>) => {
      state.item = action.payload;
    });
    builder.addCase(uploadSuperSimplifierApplicationForm.fulfilled, (state) => {
      state.item = {...state.item, status: 4} as MainDetails;
    });
    builder.addCase(sendApplicationForm.fulfilled, (state) => {
      state.item = {...state.item, status: 4} as MainDetails;
    });
  },
});
