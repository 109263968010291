import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../reducers';
import { Report } from './components/report';
import { selectMyReport, selectParameters, selectReportList } from './store/selectors';
import { reportSlice } from './store/slice';
import { fetchMyReport, fetchReportList } from './store/thunks';

const mapStateToProps = (state: RootState) => ({
  parameters: selectParameters(state),
  myReport: selectMyReport(state),
  reportList: selectReportList(state),
});

const mapDispatchToProps = {
  ...reportSlice.actions,
  fetchReportList,
  fetchMyReport,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector>;
export const ReportContainer = connector(Report);
