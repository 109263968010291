import { Container, Grid, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { DateTime } from 'luxon';
import React, { useCallback, useEffect } from 'react';
import { LoadingIndicator } from 'src/common/components/LoadingIndicator';
import {
  DateTimeFormat,
  formatDollars,
  formatNumberCommaSeparated,
  formatPercentage,
  getLocalDateTime,
} from '../../../../common';
import CardComponent from '../../../../common/components/CardComponent';
import { theme } from '../../../../themes';
import { Props } from '../container';
import {
  ConsolidatedSecurityHoldingsSummaryThunkParameters,
  SecurityDashboardThunkParameters,
  SecurityPricesItem,
} from '../store/types';
import { MonthlyPerformanceChartContainer } from './MonthlyPerformanceChartContainer';

export const DashboardComponent = (props: Props): JSX.Element => {
  const {
    parameters,
    consolidatedSecurityHoldingsSummary,
    securityDashboard,
    securityPrices,
    fetchSecurityPrices,
    fetchConsolidatedSecurityHoldingsSummary,
    fetchSecurityDashboard,
    fetchDailySecurityPricesChart,
    consolidatedSecurityHoldingsSummaryProgress,
    SecurityPricesProgress,
    SecurityDashboardProgress,
    isDataFromTriumph,
    match: { params },
  } = props;

  const fetch = useCallback(() => {
    if (params.securityId) {
      const requestPayload: ConsolidatedSecurityHoldingsSummaryThunkParameters = {
        securityId: parseInt(params.securityId),
        isDataFromTriumph: isDataFromTriumph,
      };
      fetchConsolidatedSecurityHoldingsSummary(requestPayload);
      fetchSecurityPrices({
        pagination: parameters.pagination,
        securityId: parseInt(params.securityId),
      });
      fetchDailySecurityPricesChart({
        securityId: parseInt(params.securityId),
        fromDate: DateTime.now().minus({ months: 1 }).startOf('month').toISODate(),
        toDate: DateTime.now().toISODate(),
      });
    }

    if (params.securityId && params.securityCode) {
      const requestPayload2: SecurityDashboardThunkParameters = {
        securityCode: params.securityCode,
        securityId: parseInt(params.securityId),
      };
      fetchSecurityDashboard(requestPayload2);
    }
  }, [
    fetchConsolidatedSecurityHoldingsSummary,
    fetchSecurityPrices,
    fetchDailySecurityPricesChart,
    fetchSecurityDashboard,
    params.securityCode,
    params.securityId,
    parameters.pagination,
    isDataFromTriumph,
  ]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const recentPricesRows = securityPrices?.results.map((row: SecurityPricesItem, index: number) => {
    return (
      <ListItem key={index} divider>
        <Grid container>
          <Grid item xs={3}>
            <Typography variant={'h5'}>{getLocalDateTime(row.date, DateTimeFormat.Short)}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant={'h5'} align="right">
              {formatDollars(row.price)}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant={'h5'} align="right" color={row.priceChange >= 0 ? 'secondary' : 'error'}>
              {formatDollars(row.priceChange)}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant={'h5'} align="right" color={row.priceChangePercentage >= 0 ? 'secondary' : 'error'}>
              {formatPercentage(row.priceChangePercentage)}
            </Typography>
          </Grid>
        </Grid>
      </ListItem>
    );
  });

  return (
    <Container style={{ paddingTop: '20px', paddingBottom: '20px' }}>
      <MonthlyPerformanceChartContainer />
      <Grid container spacing={2} style={{ paddingTop: '10px' }}>
        <Grid item xs={3}>
          <CardComponent
            {...props}
            progress={consolidatedSecurityHoldingsSummaryProgress}
            background={theme.palette.gradient1?.main}
            value={formatDollars(consolidatedSecurityHoldingsSummary?.fua)}
            subText={'FUA'}
          />
        </Grid>
        <Grid item xs={3}>
          <CardComponent
            {...props}
            progress={consolidatedSecurityHoldingsSummaryProgress}
            background={theme.palette.gradient2?.main}
            value={formatNumberCommaSeparated(consolidatedSecurityHoldingsSummary?.portfolioCount || 0)}
            subText={'Portfolios'}
          />
        </Grid>
        <Grid item xs={3}>
          <CardComponent
            {...props}
            progress={SecurityDashboardProgress}
            background={theme.palette.gradient1?.main}
            value={formatPercentage(securityDashboard?.oneMonthTotalReturn)}
            subText={'1 Month Return'}
          />
        </Grid>
        <Grid item xs={3}>
          <CardComponent
            {...props}
            progress={SecurityDashboardProgress}
            background={theme.palette.gradient2?.main}
            value={formatPercentage(securityDashboard?.oneYearTotalReturn)}
            subText={'1 Year Return'}
          />
        </Grid>
      </Grid>

      <Grid item xs={12} style={{ paddingTop: '10px' }}>
        <LoadingIndicator progress={SecurityPricesProgress}>
          <Card elevation={0}>
            <CardContent>
              <Grid container style={{ paddingBottom: '10px' }}>
                <Grid item xs={3}>
                  <Typography variant={'h3'} color={'textPrimary'} style={{ paddingLeft: '14px' }}>
                    Recent Prices
                  </Typography>
                </Grid>
                <Grid item xs={9}></Grid>
              </Grid>
              <ListItem key={1} divider>
                <Grid container>
                  <Grid item xs={3}>
                    <Typography variant="h6">DATE</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="h6" align="right" style={{ paddingLeft: '8px' }}>
                      PRICE ($)
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="h6" align="right">
                      CHANGE ($)
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="h6" align="right">
                      CHANGE (%)
                    </Typography>
                  </Grid>
                </Grid>
              </ListItem>
              <List>{recentPricesRows}</List>
            </CardContent>
          </Card>
        </LoadingIndicator>
      </Grid>

      <Grid item xs={12} style={{ paddingTop: '10px' }}>
        <LoadingIndicator progress={SecurityDashboardProgress}>
          <Card elevation={0}>
            <CardContent>
              <Grid container style={{ paddingBottom: '10px' }}>
                <ListItem key={20} divider>
                  <Grid item xs={3}>
                    <Typography variant={'h3'} color={'textPrimary'}>
                      Key Statistics
                    </Typography>
                  </Grid>
                  <Grid item xs={9}></Grid>
                </ListItem>
              </Grid>
              <ListItem key={1} divider>
                <Grid container>
                  <Grid item xs={4}>
                    <Typography variant="h3">{formatDollars(securityDashboard?.lastPrice)}</Typography>
                    <Typography variant="h6">
                      LAST PRICE ({getLocalDateTime(securityDashboard?.lastPriceDate, DateTimeFormat.Short)})
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="h3">
                      {parseFloat(securityDashboard?.oneYearIncome.toFixed(4) || '')}
                    </Typography>
                    <Typography variant="h6">1 YR INCOME (CPU)</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="h3">{formatPercentage(securityDashboard?.oneYearDividendYield)}</Typography>
                    <Typography variant="h6">EST DIVIDENT YIELD</Typography>
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem key={2} divider>
                <Grid container>
                  <Grid item xs={4}>
                    <Typography variant="h3">
                      {formatDollars(securityDashboard?.oneYearRange.from)} -{' '}
                      {formatDollars(securityDashboard?.oneYearRange.to)}
                    </Typography>
                    <Typography variant="h6">1 YR RANGE</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="h3">
                      {parseFloat(securityDashboard?.oneYearFranking.toFixed(4) || '')}
                    </Typography>
                    <Typography variant="h6">1 YR FRANKING (CPU)</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="h3">{formatPercentage(securityDashboard?.oneYearGrossYield)}</Typography>
                    <Typography variant="h6">EST GROSS YIELD (INC FRANKING)</Typography>
                  </Grid>
                </Grid>
              </ListItem>
            </CardContent>
          </Card>
        </LoadingIndicator>
      </Grid>

      <Grid item xs={12} style={{ paddingTop: '10px' }}>
        <LoadingIndicator progress={SecurityDashboardProgress}>
          <Card elevation={0}>
            <CardContent>
              <Grid container style={{ paddingBottom: '10px' }}>
                <ListItem key={20} divider>
                  <Grid item xs={3}>
                    <Typography variant={'h3'} color={'textPrimary'}>
                      Key Details
                    </Typography>
                  </Grid>
                  <Grid item xs={9}></Grid>
                </ListItem>
              </Grid>
              <ListItem key={1} divider>
                <Grid container>
                  <Grid item xs={4}>
                    <Typography variant="h3">{securityDashboard?.type}</Typography>
                    <Typography variant="h6">SECURITY TYPE</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="h3">{securityDashboard?.market}</Typography>
                    <Typography variant="h6">MARKET</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="h3">{securityDashboard?.category}</Typography>
                    <Typography variant="h6">SECURITY CATEGORY</Typography>
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem key={2} divider>
                <Grid container>
                  <Grid item xs={4}>
                    <Typography variant="h3">{securityDashboard?.gicsSector}</Typography>
                    <Typography variant="h6">GICS SECTOR</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="h3">{securityDashboard?.gicsSubGroup}</Typography>
                    <Typography variant="h6">GICS INDUSTRY GROUP</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="h3">{securityDashboard?.currency}</Typography>
                    {/*  */}
                    <Typography variant="h6">CURRENCY</Typography>
                  </Grid>
                </Grid>
              </ListItem>
            </CardContent>
          </Card>
        </LoadingIndicator>
      </Grid>
    </Container>
  );
};
