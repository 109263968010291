import { Enumeration } from "src/common/types";

export class RelationshipTypeEnum extends Enumeration {
    static child = new RelationshipTypeEnum(0, 'CHILD', 'Child');
    static finDependant = new RelationshipTypeEnum(1, 'FINDEPEND', 'Financial Dependant');
    static interDependant = new RelationshipTypeEnum(2, 'INTERDEP', 'Interdependent');
    static lpr = new RelationshipTypeEnum(3, 'LPR', 'Legal Representative');
    static other = new RelationshipTypeEnum(4, 'OTHER', 'Other');
    static spouse = new RelationshipTypeEnum(5, 'SPOUSE', 'Spouse');

    static getById = (id: number | null | undefined): RelationshipTypeEnum | undefined => {
        return Object.values(RelationshipTypeEnum)
          .filter((t) => !!t['name'])
          .find((t: RelationshipTypeEnum) => t.id === id);
    };

    static getFirstEnumMatchingDesc = (desc: string): RelationshipTypeEnum | undefined => {
      return Object.values(RelationshipTypeEnum)
          .filter((t) => t instanceof RelationshipTypeEnum)
          .find((t: RelationshipTypeEnum) => t.displayName.toLowerCase() === desc.toLowerCase());
    };
    
    static getByName = (name: string): RelationshipTypeEnum | undefined => {
      return Object.values(RelationshipTypeEnum)
        .filter((t) => t instanceof RelationshipTypeEnum)
        .find((t: RelationshipTypeEnum) => t.name.toLowerCase() === name.toLowerCase());
    };
  
    static getAll = (): RelationshipTypeEnum[] => {
      return Object.values(RelationshipTypeEnum)
        .filter((t) => !!t['name'])
        .map((val) => {
          return { id: val.id, name: val.name, displayName: val.displayName };
        });
    };
}