import { FormControl, InputLabel, MenuItem, OutlinedInput } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useRef } from 'react';
import { WO2Select } from './../../common';

export interface DataSelectProps<T> {
  id?: string;
  label?: string;
  value: number | null;
  data: T[];
  none?: boolean;
  disabled?: boolean;
  hasValidationError?: boolean;
  showRequiredAsterisk?: boolean;
  getName: (item: T) => T[keyof T];
  getId: (item: T) => number;
  onChange: (value: number | null) => void;
  onBlur: () => void;
}

const useStyles = makeStyles(() => ({
  formControl: {
    minWidth: 120,
    marginLeft: 0,
    width: '100%',
  },
  select: {
    height: 50,
    width: '100%',
    borderColor: 'red',
  },
}));

export function DataSelect<T>(props: DataSelectProps<T>): JSX.Element {
  const classes = useStyles();
  const inputLabel: React.MutableRefObject<HTMLLabelElement | null> = useRef(null);
  const { label, hasValidationError, showRequiredAsterisk, data, value, none, getId, getName, onChange, onBlur, id, disabled } = props;
  const showNone = none ?? true;
  const labelValue = !!showRequiredAsterisk ? `* ${label}` : label;

  const handleChange = (value: string | number | null) => {
    onChange(value === '-1' ? null : Number(value));
  };

  return (
    <FormControl variant="outlined" fullWidth error={hasValidationError || false}>
      {label && (
        <InputLabel shrink ref={inputLabel} id="data-select-label" htmlFor="outlined-age-always-notched">
          {labelValue}
        </InputLabel>
      )}
      <WO2Select
        className={classes.select}
        labelId="data-select-label"
        id={id || 'date-select'}
        value={value || '-1'}
        onChange={(event) => {
          handleChange(event.target.value as string | number);
        }}
        onBlur={onBlur}
        disabled={disabled || false}
        input={<OutlinedInput notched label={labelValue} name={label} id="outlined-age-always-notched" />}
        data-testid={id}
      >
        {showNone && <MenuItem value="-1">(None)</MenuItem>}
        {data.map((item: T, index: number) => {
          const id = getId(item);
          const name = getName(item);
          return (
            <MenuItem key={index} value={id}>
              {name}
            </MenuItem>
          );
        })}
      </WO2Select>
    </FormControl>
  );
}
