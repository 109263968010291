import { Container, Divider, Grid, Link, List, ListItem, ListItemText, Paper, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { Autocomplete, ToggleButtonItem, WO2Checkbox, WO2ToggleButtonGroup } from '../../../common';
import DateRangePicker from '../../../common/components/DateRangePicker';
import { Client } from '../../bulk/common/store/types';
import { Props } from '../container';
import { ReportFormat } from '../store/types';

const reportFormatToggleButtons: ToggleButtonItem<string>[] = [
  { name: ReportFormat.EXCEL.displayName, value: ReportFormat.EXCEL.name },
  { name: ReportFormat.WORD.displayName, value: ReportFormat.WORD.name },
  { name: ReportFormat.PDF.displayName, value: ReportFormat.PDF.name },
];

export const Parameters = (props: Props): JSX.Element => {
  const { parameters, setClient, myReport, setReportFormat, setDates, setCoverPage, setAddAppendix, setMergeReports, fetchMyReport } = props;

  useEffect(() => {
    fetchMyReport();
  }, [fetchMyReport]);

  const renderMyReport = () => {
    return myReport.map((item) => (
      <div key={item.id}>
        <ListItem>
          <Typography style={{ marginBottom: 0 }} variant="subtitle1" display="block" gutterBottom>
            {item.name}
            <br />
            <span style={{ color: '#bdbdbd', fontSize: 12 }}>{item.type}</span>
            <br />
            <Link href="#" style={{ textDecoration: 'underline' }}>
              Download
            </Link>
            <Link href="#" style={{ textDecoration: 'underline', marginLeft: 32 }}>
              Privew
            </Link>
          </Typography>
        </ListItem>
        <Divider light />
      </div>
    ));
  };

  return (
    <Container style={{ width: 600, padding: 0 }}>
      <Paper elevation={0}>
        <Container style={{ position: 'relative', padding: 26 }}>
          <Typography style={{ fontSize: '1rem', fontWeight: 400 }}>Report Criteria</Typography>
          <Autocomplete<Client> label="Client" value={parameters.client} optionLabel={(i) => i.name} options={[]} loading={false} onOptionChange={setClient} />
          <Grid>
            <WO2Checkbox label="Cover page" value={parameters.coverPage} onChangeHandler={setCoverPage} />
            <br />
            <Link href="#" style={{ textDecoration: 'underline', marginLeft: 32 }}>
              Browse
            </Link>
          </Grid>
          <Grid>
            <WO2Checkbox label="Add appendix" value={parameters.addAppendix} onChangeHandler={setAddAppendix} />
          </Grid>
          <Grid>
            <WO2Checkbox label="Merge reports" value={parameters.mergeReports} onChangeHandler={setMergeReports} />
          </Grid>
          <Grid style={{ textAlign: 'justify', paddingTop: '10px' }}>
            <Typography variant="h5">Report Format</Typography>
            <Grid container direction="column" alignItems="flex-start" style={{ paddingBottom: '10px', paddingTop: '10px' }}>
              <Grid>
                <WO2ToggleButtonGroup buttons={reportFormatToggleButtons} value={parameters.reportFormat} onChangeHandler={setReportFormat} />
              </Grid>
            </Grid>
          </Grid>
          <Grid style={{ textAlign: 'justify', paddingTop: '10px' }}>
            <Typography variant={'h5'} style={{ paddingBottom: '10px' }}>
              Date Range
            </Typography>
            <DateRangePicker dateRange={parameters.dates} setDateRange={setDates} />
          </Grid>
        </Container>
      </Paper>
      <Paper elevation={0} style={{ marginTop: 30 }}>
        <Container style={{ position: 'relative', padding: '10px' }}>
          <List component="nav" aria-label="mailbox folders">
            <ListItem>
              <ListItemText primary="My Reports" />
            </ListItem>
            <Divider light />
            {renderMyReport()}
          </List>
        </Container>
      </Paper>
    </Container>
  );
};
