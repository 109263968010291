import { TabRoute } from '../../store';
import { SecurityAssociatedClientsStateContainer } from './associatedClients/container';
import { SecurityDashboardStateContainer } from './dashboard/container';
import { SecurityDetailsStateContainer } from './details/container';
import { SecurityIncomeStateContainer } from './income/container';
import { SecurityPerformanceStateContainer } from './performance/container';
import { SecurityPriceStateContainer } from './price/container';

export const securityTabRoutes: TabRoute[] = [
  {
    label: 'DASHBOARD',
    order: 0,
    path: '/dashboard',
    component: SecurityDashboardStateContainer,
    visible: (): boolean => true,
  },
  {
    label: 'PRICE',
    order: 1,
    path: '/price',
    component: SecurityPriceStateContainer,
    visible: (): boolean => true,
  },
  {
    label: 'INCOME',
    order: 2,
    path: '/income',
    component: SecurityIncomeStateContainer,
    visible: (): boolean => true,
  },
  {
    label: 'ASSOCIATED CLIENTS',
    order: 3,
    path: '/associatedclients',
    component: SecurityAssociatedClientsStateContainer,
    visible: (): boolean => true,
  },
  {
    label: 'PERFORMANCE',
    order: 4,
    path: '/performance',
    component: SecurityPerformanceStateContainer,
    visible: (): boolean => true,
  },
  {
    label: 'DETAILS',
    order: 5,
    path: '/details',
    component: SecurityDetailsStateContainer,
    visible: (): boolean => true,
  },
];
