import { Enumeration, moment } from 'src/common/types';
import { TriumphBaseRequest } from 'src/features/clients/client/portfolio/overview/store';
import { SimpleSecurityItem } from '../../../common/store/types';
import { ValidationResult } from '../../types';
export { TradeAction } from '../../types';
export type { BulkOrder, Order, PreApprovalInvestmentService, ValidationResult } from '../../types';

export interface OrderEditState {
  parameters: OrderEditParameters;
  securityHoldings: PortfolioSecurityHoldingResult | null;
  isPreApproved: boolean;
  validationMessages: ValidationResult;
  investmentServicesLockedByOrders: number[];
}

export interface HoldingAmount {
  amount: number | undefined;
  portfolioId: number;
}

export interface OrderAmount {
  portfolioId: number;
  amount: number;
}

export enum PriceType {
  Market = 'Market',
  Limit = 'Limit',
}

export enum ExpiryType {
  GTC = 'GoodTillCancelled',
  GFD = 'GoodForDay',
  GTD = 'GoodTillDate',
}

export enum WeightsType {
  Portfolio = 'Portfolio',
  AssetClass = 'Asset Class',
}

export interface OrderEditParameters {
  securitySearch: string | null;
  security: SimpleSecurityItem | null;
  tradeAction: string;
  tradeMethod: string;
  amount: number | null;
  priceType: string | null;
  priceLimit: number | null;
  expiryType: string | null;
  expiryDate: string | null;
  weightsType: WeightsType;
}

export interface OrderEditResult {
  clientId: number;
  clientName: string;
  portfolioId: number;
  portfolioName: string;
  marketValue: number;
  amount: number;
  tradeValue: number;
  tradeUnits: number;
  currentValue: number;
  currentPercentage: number;
  currentUnits: number;
  proposedValue: number;
  proposedPercentage: number;
  cashBalance: number;
  cashPercentage: number;
  proposedCash: number;
  proposedCashPercentage: number;
  targetPercentage: number;
}

export interface FetchPortfolioSecurityHoldingsParameters extends TriumphBaseRequest {
  securityId: number;
  portfolioIds: number[];
}

export interface PortfolioSecurityHoldingResult {
  securityId: number;
  code: string;
  name: string;
  tradeMethod: string;
  tradeAction: string;
  unitPrice: number;
  priceDate: moment;
  holdings: PortfolioSecurityHoldingItem[];
}

export interface PortfolioSecurityHoldingItem {
  portfolioId: number;
  units: number;
  newUnits: number;
  amount: number;
  targetPercentage: number;
  value: number;
  portfolioValue: number;
  securityCurrentPercentageAssetClass: number;
  securityCurrentPercentagePortfolio: number;
  assetClassTotal: number;
  assetClassId: number | null;
  assetClass: string | null;
}

export class TradeMethod extends Enumeration {
  static Amount = new TradeMethod(1, 'Amount', 'Amount');
  static Units = new TradeMethod(2, 'Units', 'Units');
  static Percentage = new TradeMethod(3, 'Percentage', 'Percentage');
  static Target = new TradeMethod(4, 'Target', 'Target');
}
