import AdjustIcon from '@mui/icons-material/Adjust';
import ArrowDropDownSharpIcon from '@mui/icons-material/ArrowDropDownSharp';
import CloseIcon from '@mui/icons-material/Close';
import GridOnIcon from '@mui/icons-material/GridOn';
import {
  Box,
  Button,
  IconButton,
  OutlinedInput,
  Paper,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import NumberFormat from 'react-number-format';
import {
  CSVDataType,
  LoadingIndicator,
  arrayUnion,
  convertToCSVFormat,
  decimalPlacesByMarketCode,
  downloadCsv,
  formatDollars,
  formatPercentage,
  nameof,
  roundUnitsByMarketCode,
} from 'src/common';
import { WO2Menu } from 'src/common/components/Menu';
import { ClientSideDataTable } from 'src/common/components/dataTable/clientSide';
import { DatatableColumn } from 'src/common/components/dataTable/types';
import { useDebounce } from 'src/common/hooks';
import history from '../../../../../history';
import { Props } from '../container';
import { HoldingAmount, OrderEditResult, TradeAction, TradeMethod } from '../store/types';

export const Results = (props: Props): JSX.Element => {
  const {
    results,
    validateBulkOrder,
    isPreApproved,
    bulkOrder,
    setIsPreApproved,
    securityHoldings,
    setAmounts,
    setClearAmount,
    preApprovalInvestmentServices,
    isLoading,
    parameters,
    isRebalanceDataFromTriumph,
  } = props;
  const [isValidateRequested, setValidateRequest] = useState<boolean>(false);
  const [amount, setAmount] = useState<HoldingAmount[]>([]);
  const isInitialValidation = useRef(true);
  const onAmountToTradeChange = useDebounce<HoldingAmount[]>(amount, 500);

  function isHoldingsEqual(holdings1: HoldingAmount, holdings2: HoldingAmount) {
    return holdings1.amount === holdings2.amount && holdings1.portfolioId === holdings2.portfolioId;
  }

  const onAmountToTradeChanged = useCallback(() => {
    const holdingsAmount: HoldingAmount[] = results.map((result) => {
      return { amount: result.amount, portfolioId: result.portfolioId };
    });
    const union = arrayUnion(amount, holdingsAmount, isHoldingsEqual);
    if (
      amount?.filter((holdingAmount) => holdingAmount.amount === undefined).length === 0 &&
      union.length === amount.length + 1
    ) {
      setAmounts(amount);
    }
  }, [amount, results, setAmounts]);

  useEffect(onAmountToTradeChanged, [onAmountToTradeChange]);

  useEffect(() => {
    const holdingsAmount: HoldingAmount[] = results.map((result) => {
      return { amount: result.amount, portfolioId: result.portfolioId };
    });
    setAmount(holdingsAmount);
  }, [results]);

  useEffect(() => {
    if (isInitialValidation.current) {
      isInitialValidation.current = false;
      return;
    }
    if (isPreApproved) {
      history.push('/bulk/order/checkout');
    }
  }, [isPreApproved]);

  useEffect(() => {
    if (isValidateRequested && !isPreApproved && bulkOrder !== null && preApprovalInvestmentServices !== null) {
      setValidateRequest(false);
      validateBulkOrder({
        preApprovalInvestmentServices: preApprovalInvestmentServices,
        isDataFromTriumph: isRebalanceDataFromTriumph,
      });
      window.scrollTo(0, 0);
    }
    if (isValidateRequested && isPreApproved) {
      setValidateRequest(false);
      history.push('/bulk/order/checkout');
    }
  }, [isValidateRequested, isPreApproved, bulkOrder, preApprovalInvestmentServices, validateBulkOrder]);

  const clientPortfolioNameColumn = (dataIndex: number, results: OrderEditResult[]): React.ReactNode => (
    <>
      <Typography variant="h5">{results[dataIndex]?.clientName}</Typography>
      <Typography variant="h5" color={'textSecondary'}>
        {results[dataIndex]?.portfolioName}
      </Typography>
    </>
  );

  interface CsvRow {
    'Trade Action': string;
    'Trade Method': string;
    Amount: string;
    'Client Name': string;
    portfolioId: number;
    'Portfolio Name': string;
    'Portfolio Value': string;
    'Trade Value': string;
    'Trade Units': number;
    'Current Value': string;
    'Current Units': number;
    'Proposed Value': string;
    'Cash Balance': string;
    'Proposed Cash': string;
    'Target Percentage': string;
    'Current Percentage': string;
    'Proposed Percentage': string;
    'Cash Percentage': string;
    'Proposed Cash Percentage': string;
  }

  const csvRow: CsvRow[] = [];

  const anountFormat = (tradeMethod: string, amount: string | number | null | undefined) => {
    if (tradeMethod == 'Amount') {
      return formatDollars((amount as number | null | undefined) ?? 0);
    } else if (tradeMethod == 'Percentage') {
      return formatPercentage((amount as number | null | undefined) ?? 0, '0', 6, false);
    } else if (tradeMethod == 'Units') {
      return amount;
    } else if (tradeMethod == 'Target') {
      return amount;
    } else {
      return amount;
    }
  };

  results.forEach((lineItem) => {
    csvRow.push({
      'Client Name': lineItem.clientName,
      'Portfolio Name': lineItem.portfolioName,
      'Trade Action': parameters.tradeAction,
      'Trade Method': parameters.tradeMethod,
      Amount: anountFormat(parameters.tradeMethod, lineItem.amount ?? 0) as string,
      portfolioId: lineItem.portfolioId,
      'Trade Value': formatDollars(lineItem.tradeValue ?? 0),
      'Trade Units': lineItem.tradeUnits ?? 0,
      'Target Percentage': formatPercentage(lineItem.targetPercentage ?? '', '0', 6, false),
      'Current Value': formatDollars(lineItem.currentValue ?? 0),
      'Current Percentage': formatPercentage(lineItem.currentPercentage ?? '', '0', 6, false),
      'Current Units': lineItem.currentUnits ?? 0,
      'Proposed Value': formatDollars(lineItem.proposedValue ?? 0),
      'Proposed Percentage': formatPercentage(lineItem.proposedPercentage ?? '', '0', 6, false),
      'Cash Balance': formatDollars(lineItem.cashBalance ?? 0),
      'Cash Percentage': formatPercentage(lineItem.cashPercentage ?? '', '0', 6, false),
      'Proposed Cash': formatDollars(lineItem.proposedCash ?? 0),
      'Proposed Cash Percentage': formatPercentage(lineItem.proposedCashPercentage ?? '', '0', 6, false),
      'Portfolio Value': formatDollars(lineItem.marketValue ?? 0),
    });
  });

  const tradeActionColumn = (dataIndex: number): React.ReactNode => {
    let csvRowAction = '';

    if (csvRow[dataIndex] && csvRow[dataIndex]['Trade Action']) {
      if (securityHoldings?.tradeAction === TradeAction.Buy.name) {
        csvRowAction = 'Buy';
      } else if (
        securityHoldings?.tradeAction === TradeAction.Sell.name ||
        securityHoldings?.tradeAction === TradeAction.SellAll.name
      ) {
        csvRowAction = 'Sell';
      } else if (securityHoldings?.tradeAction === TradeAction.SellAll.name) {
        csvRowAction = 'Sell All';
      }

      csvRow[dataIndex]['Trade Action'] = csvRowAction;
    }

    return (
      <ToggleButtonGroup size="small" exclusive aria-label="small outlined button group">
        <ToggleButton
          disabled={securityHoldings?.tradeAction !== TradeAction.Buy.name}
          className={'Green'}
          color={'primary'}
          value={TradeAction.Buy.displayName}
          selected={securityHoldings?.tradeAction === TradeAction.Buy.name}
        >
          Buy
        </ToggleButton>
        <ToggleButton
          className={'Red'}
          value={TradeAction.Sell.displayName}
          selected={
            securityHoldings?.tradeAction === TradeAction.Sell.name ||
            securityHoldings?.tradeAction === TradeAction.SellAll.name
          }
          disabled={securityHoldings?.tradeAction === TradeAction.Buy.name}
        >
          Sell
        </ToggleButton>
        <ToggleButton
          disabled={securityHoldings?.tradeAction !== TradeAction.SellAll.name}
          className={'Red'}
          value={TradeAction.SellAll.displayName}
          selected={securityHoldings?.tradeAction === TradeAction.SellAll.name}
        >
          All
        </ToggleButton>
      </ToggleButtonGroup>
    );
  };

  const tradeMethodColumn = (dataIndex: number): React.ReactNode => {
    let csvRowTradeMethod = '';

    if (csvRow[dataIndex] && csvRow[dataIndex]['Trade Method']) {
      if (securityHoldings?.tradeMethod === TradeMethod.Amount.name) {
        csvRowTradeMethod = 'Amount';
      } else if (securityHoldings?.tradeMethod === TradeMethod.Percentage.name) {
        csvRowTradeMethod = 'Percentage';
      } else if (securityHoldings?.tradeMethod === TradeMethod.Units.name) {
        csvRowTradeMethod = 'Units';
      } else if (securityHoldings?.tradeMethod === TradeMethod.Target.name) {
        csvRowTradeMethod = 'Target';
      }

      csvRow[dataIndex]['Trade Method'] = csvRowTradeMethod;
    }

    return (
      <ToggleButtonGroup size="small" exclusive aria-label="small outlined button group">
        <ToggleButton
          disabled={securityHoldings?.tradeMethod !== TradeMethod.Amount.name}
          value={TradeMethod.Amount.displayName}
          selected={securityHoldings?.tradeMethod === TradeMethod.Amount.name}
        >
          $
        </ToggleButton>
        <ToggleButton
          disabled={securityHoldings?.tradeMethod !== TradeMethod.Percentage.name}
          value={TradeMethod.Percentage.displayName}
          selected={securityHoldings?.tradeMethod === TradeMethod.Percentage.name}
        >
          %
        </ToggleButton>
        <ToggleButton
          disabled={securityHoldings?.tradeMethod !== TradeMethod.Units.name}
          value={TradeMethod.Units.displayName}
          selected={securityHoldings?.tradeMethod === TradeMethod.Units.name}
        >
          #
        </ToggleButton>
        <ToggleButton
          disabled={securityHoldings?.tradeMethod !== TradeMethod.Target.name}
          value={TradeMethod.Target.displayName}
          selected={securityHoldings?.tradeMethod === TradeMethod.Target.name}
        >
          <AdjustIcon />
        </ToggleButton>
      </ToggleButtonGroup>
    );
  };

  const amountColumn = (dataIndex: number, results: OrderEditResult[]): React.ReactNode => {
    const csvRowAmount = amount.find((a) => a.portfolioId === results[dataIndex]?.portfolioId)?.amount ?? 0;

    if (csvRow[dataIndex] && csvRow[dataIndex].Amount) {
      csvRow[dataIndex].Amount = formatDollars(csvRowAmount);
    }

    return (
      <NumberFormat
        value={amount.find((a) => a.portfolioId === results[dataIndex]?.portfolioId)?.amount}
        customInput={OutlinedInput}
        type="text"
        data-id={'editamount'}
        disabled={securityHoldings?.tradeAction === TradeAction.SellAll.name}
        thousandSeparator
        fixedDecimalScale
        allowNegative={false}
        decimalScale={
          securityHoldings?.tradeMethod === TradeMethod.Amount.name ||
          securityHoldings?.tradeMethod === TradeMethod.Percentage.name
            ? 2
            : decimalPlacesByMarketCode(securityHoldings?.code.substring(securityHoldings?.code.lastIndexOf('.') + 1))
        }
        isAllowed={(values) =>
          !values.floatValue ||
          securityHoldings?.tradeAction === TradeAction.Buy.name ||
          ((securityHoldings?.tradeMethod === TradeMethod.Amount.name ||
            securityHoldings?.tradeMethod === TradeMethod.Target.name) &&
            values.floatValue <= results[dataIndex]?.currentValue) ||
          (securityHoldings?.tradeMethod === TradeMethod.Percentage.name &&
            roundUnitsByMarketCode(
              ((values.floatValue / 100) * results[dataIndex]?.marketValue) / securityHoldings?.unitPrice,
              securityHoldings?.code.substring(securityHoldings?.code.lastIndexOf('.') + 1)
            ) <= results[dataIndex]?.currentUnits) ||
          (securityHoldings?.tradeMethod === TradeMethod.Units.name &&
            values.floatValue <= results[dataIndex]?.currentUnits)
        }
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          const value = Number(event.target.value.replaceAll(',', ''));
          if (value !== amount[dataIndex]?.amount) {
            const index = amount.findIndex((a) => a.portfolioId === results[dataIndex]?.portfolioId);
            const updateAmount = [...amount];
            updateAmount[index].amount = value;
            setAmount(updateAmount);
          }
        }}
        onBlur={() => {
          if (!amount.find((a) => a.portfolioId === results[dataIndex]?.portfolioId)?.amount) {
            const index = amount.findIndex((a) => a.portfolioId === results[dataIndex]?.portfolioId);
            const updateAmount = [...amount];
            updateAmount[index].amount = results[dataIndex]?.amount;
            setAmount(updateAmount);
          }
        }}
      />
    );
  };

  const tradeValueColumn = (dataIndex: number, results: OrderEditResult[]): React.ReactNode => (
    <>
      <Typography variant="h5">{formatDollars(results[dataIndex]?.tradeValue)}</Typography>
      <Typography variant="h5" color={'textSecondary'} style={{ fontStyle: 'italic', paddingRight: '2px' }}>
        {roundUnitsByMarketCode(
          results[dataIndex]?.tradeUnits,
          securityHoldings?.code.substring(securityHoldings?.code.lastIndexOf('.') + 1) ?? ''
        )}
      </Typography>
    </>
  );

  const securityCurrentColumn = (dataIndex: number, results: OrderEditResult[]): React.ReactNode => (
    <>
      <Typography variant="h5">{formatDollars(results[dataIndex]?.currentValue)}</Typography>
      <Typography variant="h5" color={'textSecondary'} style={{ fontStyle: 'italic', paddingRight: '2px' }}>
        {formatPercentage(results[dataIndex]?.currentPercentage)}
      </Typography>
    </>
  );

  const securityProposedColumn = (dataIndex: number, results: OrderEditResult[]): React.ReactNode => (
    <>
      <Typography variant="h5">{formatDollars(results[dataIndex]?.proposedValue)}</Typography>
      <Typography variant="h5" color={'textSecondary'} style={{ fontStyle: 'italic', paddingRight: '2px' }}>
        {formatPercentage(results[dataIndex]?.proposedPercentage)}
      </Typography>
    </>
  );

  const cashCurrentColumn = (dataIndex: number, results: OrderEditResult[]): React.ReactNode => (
    <>
      <Typography variant="h5">{formatDollars(results[dataIndex]?.cashBalance)}</Typography>
      <Typography variant="h5" color={'textSecondary'} style={{ fontStyle: 'italic', paddingRight: '2px' }}>
        {formatPercentage(results[dataIndex]?.cashPercentage)}
      </Typography>
    </>
  );

  const cashProposedColumn = (dataIndex: number, results: OrderEditResult[]): React.ReactNode => (
    <>
      <Typography variant="h5">{formatDollars(results[dataIndex]?.proposedCash)}</Typography>
      <Typography variant="h5" color={'textSecondary'} style={{ fontStyle: 'italic', paddingRight: '2px' }}>
        {formatPercentage(results[dataIndex]?.proposedCashPercentage)}
      </Typography>
    </>
  );

  const portfolioValueColumn = (dataIndex: number, results: OrderEditResult[]): React.ReactNode => (
    <Typography variant="h5">{formatDollars(results[dataIndex]?.marketValue)}</Typography>
  );

  const resetAmountColumn = (dataIndex: number, results: OrderEditResult[]): React.ReactNode => (
    <IconButton
      key="close"
      aria-label="close"
      color="inherit"
      onClick={() => {
        if (results[dataIndex]) {
          setClearAmount(results[dataIndex].portfolioId);
        }
      }}
    >
      <CloseIcon style={{ fontSize: '16px' }} />
    </IconButton>
  );

  const securityCodeName = parameters.security?.securityCode.split('.')[0] ?? '';

  const getResultColumns = (results: OrderEditResult[]): DatatableColumn[] => [
    {
      textAlign: 'left',
      name: nameof((i: OrderEditResult) => i.clientName),
      label: 'CLIENT & PORTFOLIO NAME',
      options: {
        filter: false,
        sort: true,
        customBodyRenderLite: (dataIndex: number) => clientPortfolioNameColumn(dataIndex, results),
      },
    },
    {
      textAlign: 'center',
      name: nameof((i: OrderEditResult) => i.amount),
      label: 'BUY/SELL',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex) => tradeActionColumn(dataIndex),
      },
    },
    {
      textAlign: 'center',
      name: nameof((i: OrderEditResult) => i.amount),
      label: 'METHOD',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex) => tradeMethodColumn(dataIndex),
      },
    },
    {
      textAlign: 'left',
      name: nameof((i: OrderEditResult) => i.amount),
      label: 'AMOUNT',
      options: {
        filter: false,
        sort: true,
        customBodyRenderLite: (dataIndex) => amountColumn(dataIndex, results),
      },
    },
    {
      textAlign: 'right',
      name: nameof((i: OrderEditResult) => i.tradeValue),
      label: 'CALCULATED',
      options: {
        filter: false,
        sort: true,
        customBodyRenderLite: (dataIndex) => tradeValueColumn(dataIndex, results),
      },
    },
    {
      textAlign: 'right',
      name: nameof((i: OrderEditResult) => i.currentValue),
      label: 'CURRENT' + ' ' + securityCodeName,
      options: {
        filter: false,
        sort: true,
        customBodyRenderLite: (dataIndex) => securityCurrentColumn(dataIndex, results),
      },
    },
    {
      textAlign: 'right',
      name: nameof((i: OrderEditResult) => i.currentValue),
      label: 'PROPOSED' + ' ' + securityCodeName,
      options: {
        filter: false,
        sort: true,
        customBodyRenderLite: (dataIndex) => securityProposedColumn(dataIndex, results),
      },
    },
    {
      textAlign: 'right',
      name: nameof((i: OrderEditResult) => i.cashBalance),
      label: 'CURRENT CASH',
      options: {
        filter: false,
        sort: true,
        customBodyRenderLite: (dataIndex) => cashCurrentColumn(dataIndex, results),
      },
    },
    {
      textAlign: 'right',
      name: nameof((i: OrderEditResult) => i.cashBalance),
      label: 'PROPOSED CASH',
      options: {
        filter: false,
        sort: true,
        customBodyRenderLite: (dataIndex) => cashProposedColumn(dataIndex, results),
      },
    },
    {
      textAlign: 'right',
      name: nameof((i: OrderEditResult) => i.marketValue),
      label: 'PORTFOLIO VALUE',
      options: {
        filter: false,
        sort: true,
        customBodyRenderLite: (dataIndex) => portfolioValueColumn(dataIndex, results),
      },
    },
    {
      textAlign: 'right',
      name: '',
      label: '',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex) => resetAmountColumn(dataIndex, results),
      },
    },
  ];

  function generateBulkOrderCsv() {
    csvRow.forEach((lineItem) => {
      Reflect.deleteProperty(lineItem, 'portfolioId');
    });

    const data: CSVDataType[] = convertToCSVFormat(csvRow);

    downloadCsv(data, '_Bulk_Order_Edit');
  }

  const exportButtons = [
    {
      icon: <GridOnIcon />,
      label: 'Download Bulk Order Data',
      onClick: generateBulkOrderCsv,
      testId: 'downloadBulkOrderData',
    },
  ];

  return (
    <Paper elevation={0}>
      <Box display="flex" flexDirection="row" justifyContent="flex-end" padding="24px">
        <WO2Menu
          testId="export_button"
          buttonTitle="Export"
          buttonIcon={
            <div
              style={{
                paddingLeft: '10px',
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
                backgroundColor: 'blue',
                borderRadius: '25px',
                padding: '10px 10px 10px 20px',
                marginTop: '-7px',
              }}
            >
              <span style={{ color: '#ffffff', fontSize: '0.875rem' }}>EXPORT</span>
              <ArrowDropDownSharpIcon sx={{ color: '#ffffff' }} />
            </div>
          }
          items={exportButtons}
        />
      </Box>
      <LoadingIndicator progress={isLoading} spinner={false}>
        <ClientSideDataTable
          loadingProgress={isLoading}
          columns={getResultColumns(results)}
          data={results}
          options={{ filter: false }}
          enablePagination
        />
      </LoadingIndicator>
      <Box display="flex" flexDirection="row" justifyContent="flex-end" padding="24px">
        <Button
          disableElevation
          color={'primary'}
          variant="outlined"
          onClick={() => {
            setIsPreApproved();
            history.goBack();
          }}
          style={{ marginRight: '20px' }}
        >
          Back
        </Button>
        <Button
          disabled={bulkOrder === null}
          disableElevation
          color={'primary'}
          variant="contained"
          onClick={() => bulkOrder && setValidateRequest(true)}
        >
          Next
        </Button>
      </Box>
    </Paper>
  );
};
