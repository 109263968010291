export const feesGridGstText = '* All fee values are displayed and calculated inclusive of GST';
export const feeGridHelpText = 'Advice fees that are being paid to the AFSL/Adviser are to be entered here.';
export const feesGridTooltipTexts = [
  '* These fees are needed to complete the Super Simplifier application form in order to comply with the Corporations Act 2001 and requirements are set out in ASIC Corporations (Consents to Deductions - Ongoing Fee Arrangements) Instrument 2021/124.',
  '# All fees to be deducted from members accounts need to be authorised by the member (incl those not payable to adviser). All those payable to the Adviser have to be captured in this application form.',
];
export const onboardingFeeGridHelpText = 'Only fees paid to the Adviser or AFSL should be included below.';
export const onboardingFeesGridTooltipText =
  'Based on the latest fee consent legislation, you must have the member’s consent to deduct any fees from their Super account.';

export const maxDollarFeeValue = 999999999;
