import { Box, Container, Paper, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { RouteComponentProps, Switch, useRouteMatch } from 'react-router-dom';
import { TabControl } from 'src/common';
import ProtectedRoute from '../../../../ProtectedRoute';
import { Route } from 'src/store';
import { Props } from '../../container';
import { EmailSearchResultsContainer } from '../../email/search/resultsContainer';
import { OrderSearchResultsContainer } from '../../order/search/resultsContainer';
import { RebalanceSearchResultsContainer } from '../../rebalance/search/resultsContainer';
import { ReportSearchResultsContainer } from '../../report/search/resultsContainer';
import { bulkTabRoutes } from './tabRoutes';

const routes: Route[] = [
  { path: '/report', component: ReportSearchResultsContainer },
  { path: '/email', component: EmailSearchResultsContainer },
  { path: '/order', component: OrderSearchResultsContainer },
  { path: '/rebalance', component: RebalanceSearchResultsContainer },
];

export const Bulk = (props: Props & RouteComponentProps): JSX.Element => {
  const { fetchAfsls, hasClientEditAdminOnlyPermission, fetchSecurities } = props;
  const { path } = useRouteMatch();

  useEffect(() => {
    fetchAfsls();
    fetchSecurities();
  }, [fetchAfsls, fetchSecurities]);

  return (
    <Container style={{ paddingTop: '40px', paddingBottom: '40px' }}>
      <Typography variant="h2">Bulk</Typography>
      <Box display="flex" flexDirection="row" paddingTop="40px">
        <Box marginRight={'20px'}>
          <Paper elevation={0} style={{ padding: '20px' }}>
            <TabControl
              {...props}
              tabRoutes={bulkTabRoutes.filter((r) => r.visible(hasClientEditAdminOnlyPermission))}
              minWidth={'50px'}
            />
          </Paper>
        </Box>
        <Box marginLeft={'20px'} width={'100%'}>
          <Switch>
            {routes.map((route: Route) => (
              <ProtectedRoute path={`${path}${route.path}`} component={route.component} key={`${path}${route.path}`} />
            ))}
          </Switch>
        </Box>
      </Box>
    </Container>
  );
};
