import { createAsyncThunk } from '@reduxjs/toolkit';
import { ReportTemplate, SaveReportTemplateFile } from './types';
import api from 'src/app/api';
import { buildEncodedQueryString } from 'src/common';
import FileSaver from 'file-saver';

export enum TemplateReportsActionTypes {
  FetchReportTemplates = '@@client/templates/reports/FetchReportTemplates',
  SaveReportTemplateFile = '@@client/templates/reports/SaveReportTemplateFile',
  DownloadReportTemplateFile = '@@client/templates/reports/DownloadReportTemplateFile',
}

export enum TemplateReportsApiEndpoints {
  FetchReportTemplates = '/reporting/GetReportTemplateList',
  SaveReportTemplateFile = '/reporting/SaveReportTemplateFile',
  DownloadReportTemplateFile = '/reporting/DownloadReportTemplateById',
}

export const fetchReportTemplates = createAsyncThunk<ReportTemplate[]>(
  TemplateReportsActionTypes.FetchReportTemplates,
  async () => {
    const response = await api.get<ReportTemplate[]>(TemplateReportsApiEndpoints.FetchReportTemplates);
    return response.data;
  }
);

export const downloadReportTemplateFile = createAsyncThunk(
  TemplateReportsActionTypes.DownloadReportTemplateFile,
  async (payload: ReportTemplate) => {
    const queryString = buildEncodedQueryString({
      id: payload.id,
    });
    const response = await api.get<File>(`${TemplateReportsApiEndpoints.DownloadReportTemplateFile}${queryString}`, {
      responseType: 'blob',
    });
    const fileName = response.headers['content-disposition'];
    FileSaver.saveAs(new Blob([response.data]), fileName || `${payload.name}.docx`);
  }
);

export const saveReportTemplateFile = createAsyncThunk<ReportTemplate, SaveReportTemplateFile>(
  TemplateReportsActionTypes.SaveReportTemplateFile,
  async (payload: SaveReportTemplateFile, thunkApi) => {
    const form = new FormData();
    if (payload.file) {
      form.append('file', payload.file);
    }
    form.append('id', payload.id || '');
    form.append('name', payload.name);
    return api
      .post<ReportTemplate>(TemplateReportsApiEndpoints.SaveReportTemplateFile, form)
      .then((response) => {
        return response.data;
      })
      .catch(() => {
        return thunkApi.rejectWithValue({
          message: 'Could not upload report template file at this time.',
          variant: 'error',
        });
      });
  }
);
