import { DateTime } from 'luxon';
import { combineReducers } from 'redux';
import { cgtConfigurationSlice } from '../configuration/store';
import { overviewSlice } from '../overview/store/slice';
import { GainType } from '../overview/store/types';
import { CapitalGainsLossesState } from './types';

export const capitalGainsAndLossesReducer = combineReducers<CapitalGainsLossesState>({
  overviewState: overviewSlice.reducer,
  configurationState: cgtConfigurationSlice.reducer,
});

export const initialState: CapitalGainsLossesState = {
  configurationState: cgtConfigurationSlice.getInitialState(),
  overviewState: {
    parameters: {
      gainType: GainType.Unrealised,
      dates: {
        dateFrom: DateTime.now().toISODate(),
        dateTo: DateTime.now().toISODate(),
      },
      filter: '',
    },
    unrealisedResult: undefined,
    realisedResult: undefined,
  },
};
