import { Grid, Typography } from '@mui/material';
import { FieldProps, useField } from 'formik';
import React from 'react';
import { FormikErrorMessage } from '.';
import { ToggleButtonItem } from '../ToggleButton';
import { WO2ToggleButtonGroup } from '../ToggleButtonGroup';

interface Props<T> extends FieldProps {
  label?: string;
  buttons: ToggleButtonItem<T>[];
  exclusive?: boolean;
  showRequiredAsterisk?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  onChangeHandler?: (values: T[] | T) => void;
}

export const FormikToggleButton = <T extends string | number>({
  label,
  buttons,
  exclusive,
  showRequiredAsterisk,
  fullWidth,
  ...props
}: Props<T>): JSX.Element => {
  const [field, , helpers] = useField(props.field.name);

  return (
    <Grid container direction="column" alignItems={!!fullWidth ? 'stretch' : 'flex-start'}>
      {label && (
        <Typography variant={'h5'} style={{ paddingBottom: '10px', alignSelf: 'flex-start', color: props.disabled ? '#a6a6a6' : undefined }}>
          {!!showRequiredAsterisk ? `* ${label}` : label}
        </Typography>
      )}
      <Grid>
        <WO2ToggleButtonGroup
          {...props}
          fullWidth={fullWidth}
          exclusive={exclusive || true}
          value={props.field.value}
          onChangeHandler={(value) => {
            const convertedValue = buttons[0].value === 'number' ? parseInt(value) : value;
            let newArray: T[] = [];
            if (!exclusive) {
              newArray = !!field.value ? [...field.value] : [];
              const indexItem = newArray.indexOf(convertedValue);
              indexItem === -1 ? newArray.push(convertedValue) : newArray.splice(indexItem, 1);
              helpers.setValue(newArray, false);
            } else {
              newArray = [convertedValue];
              helpers.setValue(convertedValue, false);
            }
            if (!!props.onChangeHandler) {
              props.onChangeHandler(!exclusive ? newArray : newArray[0]);
            }
          }}
          id={props.field.name}
          buttons={buttons}
        />
        <FormikErrorMessage name={props.field.name}></FormikErrorMessage>
      </Grid>
    </Grid>
  );
};
