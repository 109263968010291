import { calculateAmountAndPercentage } from 'src/features/clients/common/utils/calculation';
import { FeeCalculationType, FeeFrequency, FeeMethod } from '../../../common/enums';
import {
  AdviceEstimatedFee,
  AdviceTieredFeeDetails,
  CreateClientFeeRequest,
  FeeForClientGroupModel,
  FeeForClientModel,
  PendingFeeGroup,
  PendingFee,
  UpdateClientFeeRequest,
} from './store/types';

export const AdviceFeesService = (() => {
  const MapPendingFeesToAdviceEstimatedFees = (pendingFeeGroups: PendingFeeGroup[] | undefined) => {
    return !!pendingFeeGroups
      ? pendingFeeGroups
          .filter((pendingFeeGroup: PendingFeeGroup) => Array.isArray(pendingFeeGroup.fees) && pendingFeeGroup.fees.length > 0)
          .reduce((allFees: ({ action: string } & PendingFee)[][], currentGroup) => {
            return allFees.concat(
              currentGroup.fees.reduce((fees: ({ action: string } & PendingFee)[][], currentFeeAction) => {
                if (currentFeeAction.fee.feeMethodId === FeeMethod.Tiered.id) {
                  const tieredFees = fees.find((fees) => fees[0].feeMethodId === FeeMethod.Tiered.id);
                  if (tieredFees) {
                    tieredFees.push({ ...currentFeeAction.fee, action: currentFeeAction.action });
                  } else {
                    fees.push([{ ...currentFeeAction.fee, action: currentFeeAction.action }]);
                  }
                } else {
                  fees.push([{ ...currentFeeAction.fee, action: currentFeeAction.action }]);
                }
                return fees;
              }, [])
            );
          }, [])
          .map((fees: ({ action: string } & PendingFee)[], index) => {
            const returnValue: AdviceEstimatedFee = {
              index,
              feeId: fees[0].id,
              statusId: fees[0].feeStatusId,
              templateCode: fees[0].templateCode,
              name: fees[0].name ?? fees[0].feeCalculationType ?? (FeeCalculationType.getById(fees[0].feeCalculationTypeId)?.displayName || ''),
              calculationTypeId: fees[0].feeCalculationTypeId,
              methodId: fees[0].feeMethodId,
              frequencyId: fees[0].feeFrequencyId,
              amount: fees[0].feeMethodId === FeeMethod.Dollar.id && fees[0].value ? fees[0].value : 0,
              percentageOfValue: fees[0].feeMethodId === FeeMethod.Percentage.id && fees[0].percentage ? +fees[0].percentage.toFixed(4) : 0,
              tieredFeeDetails: {
                items:
                  fees[0].feeMethodId === FeeMethod.Tiered.id
                    ? fees
                        .sort((pendingFeeA: PendingFee, pendingFeeB: PendingFee) => (pendingFeeA.from ?? 0) - (pendingFeeB.from ?? 0))
                        .map((pendingFee: PendingFee, mapIndex: number) => {
                          return {
                            feeId: pendingFee.id,
                            id: mapIndex + 1,
                            from: pendingFee.from ?? 0,
                            to: pendingFee.to ?? 0,
                            amount: pendingFee.value ?? 0,
                            percentage: pendingFee.percentage ? +pendingFee.percentage.toFixed(4) : 0,
                          };
                        })
                    : [],
              },
              isPending: true,
              pendingAction: fees[0].action,
              isValid: false,
              hasEverBeenActive: fees[0].hasEverBeenActive,
              isUserDefined: fees[0].isUserDefined,
              feeSource: fees[0].feeSource,
              feeSourceId: fees[0].feeSourceId,
            };
            return returnValue;
          })
      : [];
  };

  const MapFeesForClientGroupModelToAdviceEstimatedFees = (feeForClientGroupModels: FeeForClientGroupModel[]) => {
    return feeForClientGroupModels
      .filter((feeGroupModel: FeeForClientGroupModel) => Array.isArray(feeGroupModel.fees) && feeGroupModel.fees.length > 0)
      .map((feeGroupModel: FeeForClientGroupModel, index) => {
        const returnValue: AdviceEstimatedFee = {
          index,
          feeId: feeGroupModel.fees[0].id,
          statusId: feeGroupModel.fees[0].feeStatusId,
          templateCode: feeGroupModel.fees[0].templateCode,
          name:
            feeGroupModel.fees[0].name ??
            feeGroupModel.fees[0].feeCalculationType ??
            (FeeCalculationType.getById(feeGroupModel.fees[0].feeCalculationTypeId)?.displayName || ''),
          calculationTypeId: feeGroupModel.fees[0].feeCalculationTypeId,
          methodId: feeGroupModel.fees[0].feeMethodId,
          frequencyId: feeGroupModel.fees[0].feeFrequencyId,
          amount: feeGroupModel.fees[0].feeMethodId === FeeMethod.Dollar.id && feeGroupModel.fees[0].value ? feeGroupModel.fees[0].value : 0,
          percentageOfValue:
            feeGroupModel.fees[0].feeMethodId === FeeMethod.Percentage.id && feeGroupModel.fees[0].percentage
              ? +feeGroupModel.fees[0].percentage.toFixed(4)
              : 0,
          tieredFeeDetails: {
            items:
              feeGroupModel.fees[0].feeMethodId === FeeMethod.Tiered.id
                ? feeGroupModel.fees
                    .sort((feeClientModelA: FeeForClientModel, feeClientModelB: FeeForClientModel) => (feeClientModelA.from ?? 0) - (feeClientModelB.from ?? 0))
                    .map((feeClientModel: FeeForClientModel, mapIndex: number) => {
                      return {
                        feeId: feeClientModel.id,
                        id: mapIndex + 1,
                        from: feeClientModel.from ?? 0,
                        to: feeClientModel.to ?? 0,
                        amount: feeClientModel.value ?? 0,
                        percentage: feeClientModel.percentage ? +feeClientModel.percentage.toFixed(4) : 0,
                      };
                    })
                : [],
          },
          isPending: false,
          pendingAction: '',
          isValid: false,
          hasEverBeenActive: feeGroupModel.fees[0].hasEverBeenActive,
          isUserDefined: feeGroupModel.fees[0].isUserDefined,
          feeSource: feeGroupModel.fees[0].feeSource,
          feeSourceId: feeGroupModel.fees[0].feeSourceId,
        };
        return returnValue;
      });
  };

  const MapEstimatedFeesToUpdateAdviceFeeRequests = (
    estimatedFee: AdviceEstimatedFee,
    totalRolloverAmount: number,
    contributionsAmount: number,
    clientId: number
  ): UpdateClientFeeRequest[] => {
    return MapEstimatedFeesToCreateAdviceFeeRequestsType<UpdateClientFeeRequest>(
      estimatedFee,
      totalRolloverAmount,
      contributionsAmount,
      clientId,
      (request: CreateClientFeeRequest, currentFee: { feeId: number }) => {
        return {
          ...request,
          clientFeeId: currentFee.feeId,
        };
      }
    );
  };

  const MapEstimatedFeesToCreateAdviceFeeRequests = (
    estimatedFee: AdviceEstimatedFee,
    totalRolloverAmount: number,
    contributionsAmount: number,
    clientId: number
  ): CreateClientFeeRequest[] => {
    return MapEstimatedFeesToCreateAdviceFeeRequestsType<CreateClientFeeRequest>(
      estimatedFee,
      totalRolloverAmount,
      contributionsAmount,
      clientId,
      (request: CreateClientFeeRequest) => request
    );
  };

  const MapEstimatedFeesToCreateAdviceFeeRequestsType = <T extends CreateClientFeeRequest>(
    estimatedFee: AdviceEstimatedFee,
    totalRolloverAmount: number,
    contributionsAmount: number,
    clientId: number,
    setCreateClientFeeRequestValues: (request: CreateClientFeeRequest, currentFee: { feeId: number }) => T
  ): T[] => {
    const calculatedAmountAndPercentage = calculateAmountAndPercentage(estimatedFee, totalRolloverAmount, contributionsAmount, false);
    const feeItem = {
      ...estimatedFee,
      feeAmount: estimatedFee?.methodId === FeeMethod.Dollar.id ? calculatedAmountAndPercentage.amount ?? 0 : 0,
      percentageOfValue: estimatedFee?.methodId === FeeMethod.Percentage.id ? calculatedAmountAndPercentage.percentageOfValue ?? 0 : 0,
    };

    if (feeItem.methodId === FeeMethod.Tiered.id && !!feeItem.tieredFeeDetails.items && feeItem.tieredFeeDetails.items.length > 0) {
      return [
        ...feeItem.tieredFeeDetails.items.map((tieredFeeItem: AdviceTieredFeeDetails): T => {
          return setCreateClientFeeRequestValues(
            {
              tiered: true,
              clientId: clientId,
              name: feeItem.name ?? '',
              feeFrequencyId: FeeFrequency.NotDefined.id,
              feeCalculationTypeId: feeItem.calculationTypeId ?? 0,
              feeMethodId: feeItem.methodId ?? 0,
              from: tieredFeeItem.from ?? 0,
              to: tieredFeeItem.to ?? 0,
              amount: tieredFeeItem.amount ?? 0,
              percentage: tieredFeeItem.percentage ? +tieredFeeItem.percentage.toFixed(4) : 0,
              templateCode: feeItem.templateCode ?? '',
            },
            tieredFeeItem
          );
        }),
      ];
    }

    return [
      setCreateClientFeeRequestValues(
        {
          tiered: feeItem.methodId === FeeMethod.Tiered.id,
          feeFrequencyId: feeItem.frequencyId ?? FeeFrequency.NotDefined.id,
          feeMethodId: feeItem.methodId ?? 0,
          feeCalculationTypeId: feeItem.calculationTypeId ?? 0,
          from: 0,
          to: 0,
          amount: feeItem.methodId === FeeMethod.Percentage.id ? 0 : feeItem.feeAmount,
          percentage: feeItem.methodId === FeeMethod.Percentage.id ? +feeItem.percentageOfValue.toFixed(4) : 0,
          templateCode: feeItem.templateCode ?? '',
          clientId: clientId,
          name: feeItem.name ?? '',
        },
        feeItem
      ),
    ];
  };

  return {
    MapPendingFeesToAdviceEstimatedFees: MapPendingFeesToAdviceEstimatedFees,
    MapFeesForClientGroupModelToAdviceEstimatedFees: MapFeesForClientGroupModelToAdviceEstimatedFees,
    MapEstimatedFeesToCreateAdviceFeeRequests: MapEstimatedFeesToCreateAdviceFeeRequests,
    MapEstimatedFeesToUpdateAdviceFeeRequests: MapEstimatedFeesToUpdateAdviceFeeRequests,
  };
})();
