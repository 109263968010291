import { TemplatesContainer } from 'src/features/templates/container';
import { bulkRoutes } from '../features/bulk/routes';
import { clientRoutes } from '../features/clients/clientRoutes';
import { holdingsRoutes } from '../features/holdings/routes';
import { modelRoutes } from '../features/models/modelRoutes';
import { rebalanceRoutes } from '../features/orders/rebalance/routes';
import { reportRoutes } from '../features/report/routes';
import { securityRoutes } from '../features/securities/securityRoutes';
import { strategyRoutes } from '../features/strategy/strategyRoutes';
import { workflowRoutes } from '../features/workflows/workflowRoutes';
import { forexRateRoutes } from '../features/forexrates/forexrateRoutes';

const routes = [
  ...clientRoutes,
  ...rebalanceRoutes,
  ...bulkRoutes,
  ...holdingsRoutes,
  ...modelRoutes,
  ...securityRoutes,
  ...reportRoutes,
  ...strategyRoutes,
  ...workflowRoutes,
  ...forexRateRoutes,
  {
    path: '/templates',
    component: TemplatesContainer,
    protected: true,
    exact: false,
  },
];

export default routes;
