import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { progressSelectorFactory } from 'src/common';
import { RootState } from '../../../../reducers';
import { selectPrevNextRoutePaths } from '../store/routeSelectors';
import {
  selectAccountTypeValues,
  selectAdditionalInformationValues,
  selectAfslAuthorisation,
  selectAttachContactList,
  selectContacts,
  selectId,
} from '../store/selectors';
import { onboardSlice } from '../store/slice';
import {
  fetchContactsToAttach,
  fetchExistingContact,
  fetchOnboarding,
  OnboardActionTypes,
  saveAdditionalInfoValues,
  setAdditionalInformationValuesThunk,
} from '../store/thunks';
import { AdditionalInfo } from './components/additionalInfo';

const mapStateToProps = (state: RootState, routeComponentProps: RouteComponentProps) => ({
  id: selectId(state),
  accountTypeValues: selectAccountTypeValues(state),
  additionalInformationValues: selectAdditionalInformationValues(state),
  prevNextRoutePaths: selectPrevNextRoutePaths(state, routeComponentProps.location.pathname),
  afslAuthorisation: selectAfslAuthorisation(state),
  contacts: selectContacts(state),
  isLoadingContactDetail: progressSelectorFactory([OnboardActionTypes.FetchExistingContact])(state),
  contactsToAttach: selectAttachContactList(state),
  loadingContactsToAttach: progressSelectorFactory([OnboardActionTypes.FetchContactsToAttach])(state),
  loadingExistingContactProgress: progressSelectorFactory([OnboardActionTypes.FetchExistingContact])(state),
  saveProgress: progressSelectorFactory([OnboardActionTypes.SaveOnboarding])(state),
});

const mapDispatchToProps = {
  clearAttachedContact: onboardSlice.actions.clearAttachedContact,
  fetchOnboarding,
  fetchContactsToAttach,
  fetchExistingContact,
  saveAdditionalInfoValues,
  setAdditionalInformationValues: setAdditionalInformationValuesThunk,
};

interface MatchParams {
  id?: string;
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector> & RouteComponentProps<MatchParams>;
export const AdditionalInfoContainer = connect(mapStateToProps, mapDispatchToProps)(AdditionalInfo);
