import { moment } from 'src/common/types';
import { DateRange } from '../../../../../store';
import { ClientPortfolio } from '../../common/store/types';

export interface GainsLossesState {
  parameters: GainsLossesParameters;
  results?: GainsLossesResult;
}

export enum GroupingType {
  Security = 'Security',
  Account = 'Account',
}

export enum GainType {
  Unrealised = 'Unrealised',
  Realised = 'Realised',
}

export interface GainsLossesParameters {
  selectedPortfolio: ClientPortfolio | null;
  gainType: GainType;
  groupingType: GroupingType;
  dates: DateRange; // daterange for realised
  filter: string;
}

export interface GainsLossesResult {
  clientId: number;
  investmentServiceId: number;
  totalValue: number;
  costBase: number;
  gainLossValue: number;
  gainLossPercentage: number;
  items: GainsLossesResultItem[];
}

export interface ExpandedRow {
  [key: number]: number[];
}

export interface GainsLossesResultItem {
  isGroup: boolean;
  groupName: string;
  groupSubName: string;
  tradeDate: moment;
  acquisitionDate: moment;
  units: number;
  costBase: number;
  marketValue: number;
  unitPrice: number;
  gainLossValue: number;
  gainLossPercentage: number;
  otherGainValue: number;
  otherGainPercentage: number;
  discountableGainValue: number;
  discountableGainPercentage: number;
  capitalLossValue: number;
  capitalLossPercentage: number;
  items: GainsLossesResultItem[];
}

export interface GainsLossesResultItemCSVRow {
  isGroup: string;
  securityCode: string;
  securityName: string;
  tradeDate: moment;
  acquisitionDate: moment;
  units: number;
  costBase: string;
  marketValue: string;
  unitPrice: string;
  gainLossValue: string;
  otherGainValue: string;
  discountableGainValue: string;
  capitalLossValue: string;
}
