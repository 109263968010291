import { Grid, Typography } from '@mui/material';
import { default as React, useCallback, useEffect } from 'react';
import { ServiceTypesEnum } from 'src/common/types';
import { InvestmentService } from '../../../store/common';
import { Props } from '../container';
import { AssetClassTolerances } from './assetClassTolerances';
import { ConfigurationHeader } from './configurationHeader';
import { DetailsForm } from './detailsForm';
import { SecurityTolerances } from './securityTolerances';

export const Details = ({
  afslId,
  clientId,
  details,
  clientMainDetails,
  isLoadingProgress,
  currentInvestmentServiceVersionId,
  fetchInvestmentService,
  updateInvestmentService,
  fetchBenchmarks,
  benchmarks,
  updateInvestmentServiceSecurityTolerances,
  updateInvestmentServiceAssetClassTolerances,
  savingProgress,
  savingSecurityTolerancesProgress,
  savingAssetClassTolerancesProgress,
}: Props): JSX.Element => {
  const init = useCallback(() => {
    if (!!currentInvestmentServiceVersionId && clientId !== null && afslId !== undefined) {
      fetchInvestmentService({ clientId, investmentServiceVersionId: currentInvestmentServiceVersionId });
      fetchBenchmarks(afslId);
    }
  }, [fetchInvestmentService, currentInvestmentServiceVersionId, clientId, fetchBenchmarks, afslId]);

  useEffect(() => {
    init();
  }, [currentInvestmentServiceVersionId, clientId, init, afslId]);

  const onSave = async (details: InvestmentService) => {
    if (!!clientId) {
      await updateInvestmentService({
        clientId,
        investmentServiceVersionId: details.investmentServiceVersionId,
        name: details.portfolioName,
        serviceTypeId: ServiceTypesEnum.getByName(details.serviceType)?.id || ServiceTypesEnum.IMA.id,
        inceptionDate: details.portfolioInceptionDate,
        primaryBenchmarkId: details.primaryBenchmarkProductId,
        secondaryBenchmarkId: details.secondaryBenchmarkProductId,
        portalBenchmarkIds: details.clientPortalBenchmarks.map((b) => b.id),
        minTradeValue: details.minTradeValue,
        fortyFiveDayRule: details.fortyFiveDayRule,
        oneYearRule: details.oneYearRule,
        rebalanceTypeId: details.rebalanceTypeId,
      });
    }
  };

  return (
    <>
      <Typography variant="h3" style={{ paddingBottom: '20px' }}>
        Basic Details
      </Typography>
      <Typography variant="h5" style={{ paddingBottom: '30px' }}>
        View basic information relating to your client&apos;s portfolio.
      </Typography>
      <ConfigurationHeader clientDetails={clientMainDetails} portfolioDetails={details} clientId={clientId}></ConfigurationHeader>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DetailsForm
            investmentService={details}
            loadingProgress={isLoadingProgress}
            onSave={onSave}
            benchmarks={benchmarks || []}
            savingProgress={savingProgress}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <SecurityTolerances
            securityRebalanceToleranceBands={details?.securityRebalanceToleranceBands || []}
            loadingProgress={isLoadingProgress}
            onSave={async (bands) => {
              if (!!clientId && !!currentInvestmentServiceVersionId) {
                await updateInvestmentServiceSecurityTolerances({
                  clientId,
                  investmentServiceVersionId: currentInvestmentServiceVersionId,
                  securityToleranceBands: bands.map((b) => ({
                    bandName: b.color,
                    bandMinValue: b.min || 0,
                    bandMaxValue: b.max || null,
                  })),
                });
              }
            }}
            savingProgress={savingSecurityTolerancesProgress}
          />
        </Grid>
        <Grid item xs={6}>
          <AssetClassTolerances
            assetClassToleranceBands={details?.assetClassRebalanceToleranceBands || []}
            loadingProgress={isLoadingProgress}
            onSave={async (bands) => {
              if (!!clientId && !!currentInvestmentServiceVersionId) {
                await updateInvestmentServiceAssetClassTolerances({
                  clientId,
                  investmentServiceVersionId: currentInvestmentServiceVersionId,
                  assetClassToleranceBands: bands.map((b) => ({
                    bandName: b.color,
                    bandMinValue: b.min || 0,
                    bandMaxValue: b.max || null,
                  })),
                });
              }
            }}
            savingProgress={savingAssetClassTolerancesProgress}
          />
        </Grid>
      </Grid>
    </>
  );
};
