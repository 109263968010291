import { Box, Grid, Typography } from '@mui/material';
import { Field, Formik } from 'formik';
import React from 'react';
import { ClientAccountType } from 'src/common/types';
import { TfnExemptions } from 'src/features/clients/common/enums';
import { FormikKeyboardDatePicker, FormikTextField } from '../../../../../common/components/formik';
import { ReviewContact, ReviewValues } from '../../store/types';

export interface AdditionalInfoProps {
  reviewValues: ReviewValues;
}

interface AdditionalInfoValues {
  contactDetails: ReviewContact[];
  entityName: string;
  tfn: string;
  tfnExemption: string;
  abn: string;
}

export const AdditionalInfo = (props: AdditionalInfoProps): JSX.Element => {
  const { reviewValues } = props;
  const { accountTypeValues, entityName, tfn, tfnExemptionId, abn } = reviewValues;
  const accountTypeId = accountTypeValues.clientAccount.accountTypeId;

  const initialFormValues: AdditionalInfoValues = {
    contactDetails: reviewValues.contactDetails,
    entityName,
    tfn,
    tfnExemption: TfnExemptions.getById(tfnExemptionId)?.displayName ?? '',
    abn,
  };

  return (
    <Box width="100%" margin="30px 0" maxWidth="800px">
      <Typography variant="h4" style={{ marginBottom: '30px' }}>
        Primary Contact
      </Typography>
      <Formik<AdditionalInfoValues>
        enableReinitialize={true}
        initialValues={initialFormValues}
        onSubmit={() => {
          /* VOID */
        }}
      >
        <Box>
          <Grid container spacing={2} style={{ marginBottom: '30px' }}>
            <Grid item xs={6}>
              <Field component={FormikTextField} name="contactDetails[0].title" label="TITLE" showRequiredAsterisk={true} disabled={true} fullWidth />
            </Grid>
            <Grid item xs={6}>
              <Field component={FormikTextField} name="contactDetails[0].firstName" label="FIRST NAME" showRequiredAsterisk={true} disabled={true} fullWidth />
            </Grid>
            <Grid item xs={6}>
              <Field component={FormikTextField} name="contactDetails[0].middleName" label="MIDDLE NAME" disabled={true} fullWidth />
            </Grid>
            <Grid item xs={6}>
              <Field component={FormikTextField} name="contactDetails[0].lastName" label="LAST NAME" showRequiredAsterisk={true} disabled={true} fullWidth />
            </Grid>
            <Grid item xs={6}>
              <Field
                component={FormikKeyboardDatePicker}
                variant="inline"
                name="contactDetails[0].dateOfBirth"
                label="DATE OF BIRTH"
                showRequiredAsterisk={true}
                disabled={true}
                fullWidth
              />
            </Grid>
          </Grid>

          {accountTypeId === ClientAccountType.Joint.id && (
            <Box>
              <Typography variant="h4" style={{ marginBottom: '30px' }}>
                Additional Contact
              </Typography>
              <Grid container spacing={2} style={{ marginBottom: '30px' }}>
                <Grid item xs={6}>
                  <Field component={FormikTextField} name="contactDetails[1].title" label="TITLE" showRequiredAsterisk={true} disabled={true} fullWidth />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    component={FormikTextField}
                    name="contactDetails[1].firstName"
                    label="FIRST NAME"
                    showRequiredAsterisk={true}
                    disabled={true}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field component={FormikTextField} name="contactDetails[1].middleName" label="MIDDLE NAME" disabled={true} fullWidth />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    component={FormikTextField}
                    name="contactDetails[1].lastName"
                    label="LAST NAME"
                    showRequiredAsterisk={true}
                    disabled={true}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    component={FormikKeyboardDatePicker}
                    variant="inline"
                    name="contactDetails[1].dateOfBirth"
                    label="DATE OF BIRTH"
                    showRequiredAsterisk={true}
                    disabled={true}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Box>
          )}
          <Box>
            <Typography variant="h4" style={{ marginBottom: '30px' }}>
              Other Information
            </Typography>
            <Grid container spacing={2} style={{ marginBottom: '30px' }}>
              <Grid item xs={6}>
                <Field component={FormikTextField} name="entityName" label="ENTITY NAME" disabled={true} showRequiredAsterisk={true} fullWidth />
              </Grid>
              {(accountTypeId === ClientAccountType.Company.id ||
                accountTypeId === ClientAccountType.Trust.id ||
                accountTypeId === ClientAccountType.Smsf.id) && (
                <>
                  <Grid item xs={6}>
                    <Field
                      component={FormikTextField}
                      name="tfn"
                      label="TAX FILE NUMBER (TFN)"
                      mask="999 999 999"
                      disabled={true}
                      showRequiredAsterisk={true}
                      fullWidth
                      type="password"
                      autocomplete="new-password"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field component={FormikTextField} name="tfnExemption" label="TFN EXEMPTION" disabled={true} showRequiredAsterisk={true} fullWidth />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      component={FormikTextField}
                      name="abn"
                      label="ABN"
                      mask="99 999 999 999"
                      disabled={true}
                      showRequiredAsterisk={accountTypeId === ClientAccountType.Company.id || accountTypeId === ClientAccountType.Trust.id}
                      fullWidth
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </Box>
        </Box>
      </Formik>
    </Box>
  );
};
