import { Avatar, Box, Typography } from '@mui/material';
import React from 'react';
import { getAvatarText, nameof } from 'src/common';
import { DatatableColumn } from 'src/common/components/dataTable/types';
import { theme } from 'src/themes';
import { AccountSearchResult } from '../store/types';

const clientColumn = (dataIndex: number, results: AccountSearchResult[]): React.ReactNode => (
  <div style={{ display: 'flex', flexDirection: 'row' }}>
    <Avatar style={{ backgroundColor: theme.palette.primary.main, marginRight: '10px' }}>
      <Typography variant="h5"> {getAvatarText(`${results[dataIndex]?.clientName}`)}</Typography>
    </Avatar>
    <Box marginLeft={'20px'}>
      <Typography variant="h4">{results[dataIndex]?.clientName}</Typography>
      <Typography variant="h5" color={'textSecondary'}>
        {results[dataIndex]?.clientType}
      </Typography>
    </Box>
  </div>
);

export const getResultColumns = (results: AccountSearchResult[]): DatatableColumn[] => [
  {
    textAlign: 'left',
    name: nameof((i: AccountSearchResult) => i.clientName),
    label: 'ENTITY & ACCOUNT TYPE',
    options: {
      filter: false,
      sort: false,
      customBodyRenderLite: (dataIndex: number) => clientColumn(dataIndex, results),
    },
  },
  {
    textAlign: 'left',
    name: 'email',
    label: 'EMAIL ADDRESS',
    options: {
      filter: false,
      sort: false,
    },
  },
];
