import { createSelector } from '@reduxjs/toolkit';
import { selectModel } from '../../store/selectors';
import { ComponentAllocations } from './types';

export const selectDashBoardState = createSelector(selectModel, (modelState) => modelState.modelDashboard);
export const selectParameters = createSelector(selectDashBoardState, (listState) => listState.parameters);
export const selectModelDetail = createSelector(selectDashBoardState, (listState) => listState.modelDetail);
export const selectModelGroupItems = createSelector(selectDashBoardState, (state) => {
  let selectedGroupItems: ComponentAllocations[] = [];
  state.modelDetail?.groups.forEach((group) => {
    if (group.label.toLowerCase() === state.parameters.selectedGroup.toLowerCase()) {
      selectedGroupItems = group.componentAllocations;
    }
  });

  return selectedGroupItems;
});
export const selectPerformanceDetails = createSelector(selectDashBoardState, (listState) => listState.performanceDetails);
