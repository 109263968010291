import { Autocomplete, Box, Chip, Grid, TextField, Typography } from '@mui/material';
import { Field, FieldProps, Formik } from 'formik';
import React from 'react';
import { FormikNumberFormat, FormikSelect } from 'src/common/components/formik';
import { Position, TfnExemptions } from 'src/features/clients/common/enums';
import { FormikEnumerationSelect, FormikSwitch, FormikTextField } from '../../../../../common/components/formik';
import { EmailType } from '../../../client/common/store/enums';
import {
  BeneficialOwnerRoleId,
  BeneficiaryRoleId,
  DirectorCompanyOfficerRoleId,
  PercentageRoles,
  Role,
} from '../../../client/common/store/types';
import { ReviewContact } from '../../store/types';

export interface ContactDetailsProps {
  contactIndex: number;
  contactDetailsValues: ReviewContact;
  roles: Role[];
  isSuperOrPension: boolean;
}

interface ContactDetailsValues {
  roleIds: number[];
  beneficialOwnerPercentage: number | null;
  beneficiaryPercentage: number | null;
  directorCompanyOfficerPercentage: number | null;
  occupation: string;
  position: string | null;
  positionId: number | null;
  tfn: string;
  tfnExemptionId: number | null;
  mobileNumber: string | null;
  emailAddress: string | null;
  emailType: string | null;
  isAustralianResident: boolean;
  isForeignResident: boolean;
}

export const ContactDetails = (props: ContactDetailsProps): JSX.Element => {
  const { contactIndex, contactDetailsValues, roles, isSuperOrPension } = props;

  const initialFormValues: ContactDetailsValues = {
    roleIds: contactDetailsValues.clientRoleIds,
    beneficialOwnerPercentage: contactDetailsValues.clientBeneficialOwnerPercentage,
    beneficiaryPercentage: contactDetailsValues.clientBeneficiaryPercentage,
    directorCompanyOfficerPercentage: contactDetailsValues.clientDirectorCompanyOfficerPercentage,
    occupation: contactDetailsValues.occupation,
    position: contactDetailsValues.occupation === null ? null : contactDetailsValues.position,
    positionId: Position.getByName(contactDetailsValues.position)?.id ?? null,
    tfn: contactDetailsValues.tfn,
    tfnExemptionId: contactDetailsValues.tfnExemptionId,
    mobileNumber: contactDetailsValues.mobileNumber?.number ?? null,
    emailAddress: contactDetailsValues.emailAddress?.email ?? null,
    emailType: EmailType.getById(contactDetailsValues.emailAddress?.typeId ?? 0)?.displayName || '',
    isAustralianResident: contactDetailsValues.isAustralianResident,
    isForeignResident: contactDetailsValues.isForeignResident,
  };

  return (
    <Box style={{ marginBottom: '30px', maxWidth: '800px' }}>
      <Typography variant="h4" style={{ marginBottom: '20px' }}>
        {contactIndex === 1 ? 'Additional Contact Details' : 'Contact Details'}
      </Typography>
      <Formik<ContactDetailsValues>
        enableReinitialize={true}
        initialValues={initialFormValues}
        onSubmit={() => {
          /* VOID */
        }}
      >
        <Box>
          <Grid container>
            <Grid item xs={12} style={{ pointerEvents: 'none', paddingBottom: '20px' }}>
              <Autocomplete<Role, true>
                multiple
                data-testid="autocomplete_roles"
                value={roles.filter((role) => {
                  return !!initialFormValues.roleIds.find((cr) => cr === role.id);
                })}
                noOptionsText="No roles to select"
                options={[]}
                getOptionLabel={(role: Role) => role.name}
                renderInput={(renderParams) => (
                  <TextField {...renderParams} label="* ROLES" margin="normal" variant="outlined" fullWidth />
                )}
                renderTags={(tagValue, getTagProps) =>
                  tagValue.map((option, index) => <Chip label={option.name} {...getTagProps({ index })} key={index} />)
                }
                disabled
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container>
            {initialFormValues.roleIds
              .filter(
                (roleId: number) =>
                  [BeneficiaryRoleId, BeneficialOwnerRoleId, DirectorCompanyOfficerRoleId].indexOf(roleId) !== -1
              )
              .map((roleId: number, index: number) => {
                const foundPercentageRole = PercentageRoles.find((percentageRole) => percentageRole.id === roleId);
                const fieldName = foundPercentageRole?.fieldName ?? '';
                const label = foundPercentageRole?.label ?? '';

                return (
                  <Grid
                    item
                    xs={6}
                    key={`percentage_field_${roleId}`}
                    style={{ padding: index % 2 === 0 ? '0 10px 20px 0' : '0 0 20px 10px' }}
                  >
                    <Field name={fieldName} fullWidth>
                      {(fieldProps: FieldProps) => {
                        return (
                          <FormikNumberFormat
                            dataTestid={fieldName}
                            formikFieldProps={fieldProps}
                            numberFormatProps={{
                              placeholder: '%',
                              isNumericString: true,
                              allowNegative: false,
                              decimalScale: 2,
                              thousandSeparator: true,
                              suffix: '%',
                              name: fieldProps.field.name,
                              disabled: true,
                              label: label,
                            }}
                            showRequiredAsterisk={true}
                            isFloatValue={true}
                            fullWidth={true}
                          />
                        );
                      }}
                    </Field>
                  </Grid>
                );
              })}
          </Grid>
          <Grid container spacing={2}>
            {!isSuperOrPension && (
              <>
                <Grid item xs={6}>
                  <Field component={FormikTextField} name="occupation" label="OCCUPATION" disabled={true} fullWidth />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    component={FormikSelect}
                    data={Position.getByOccupation(initialFormValues.occupation)}
                    itemDisplayNameField="displayName"
                    fieldName="positionId"
                    valueIsId={false}
                    label="POSITION"
                    disabled
                  />
                </Grid>
              </>
            )}
            <Grid item xs={6}>
              <Field
                component={FormikTextField}
                name="tfn"
                type="password"
                autocomplete="new-password"
                label="TAX FILE NUMBER"
                mask="999 999 999"
                disabled={true}
                showRequiredAsterisk={true}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                component={FormikEnumerationSelect}
                type={TfnExemptions}
                showNone={false}
                name="tfnExemptionId"
                label="TFN EXEMPTION"
                valueIsId={true}
                disabled
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                component={FormikTextField}
                name="mobileNumber"
                label="MOBILE NUMBER"
                disabled={true}
                showRequiredAsterisk={true}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                component={FormikTextField}
                name="emailAddress"
                label="EMAIL ADDRESS"
                disabled={true}
                showRequiredAsterisk={true}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                component={FormikTextField}
                name="emailType"
                label="EMAIL TYPE"
                disabled={true}
                showRequiredAsterisk={true}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container style={{ marginTop: 2 }}>
            <Grid item xs={6} style={{ paddingRight: 1 }}>
              <Field
                component={FormikSwitch}
                name="isAustralianResident"
                label="Australian Tax Resident"
                disabled
              ></Field>
            </Grid>
            <Grid item xs={6} style={{ paddingLeft: 1 }}>
              <Field
                component={FormikSwitch}
                name="isForeignResident"
                label="Foreign Tax Resident"
                disabled={true}
                fullWidth
              />
            </Grid>
          </Grid>
        </Box>
      </Formik>
    </Box>
  );
};
