import { Clear } from '@mui/icons-material';
import { Card, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { SecurityModelAssociation } from '../store/types';

export interface Props {
  associations: SecurityModelAssociation[];
  removeAssociation: (association: SecurityModelAssociation) => void;
}

export const Associations = (props: Props): JSX.Element => {
  const { associations, removeAssociation } = props;

  const removeButton = (index: number): React.ReactNode => (
    <Tooltip title="Remove">
      <Clear
        style={{ cursor: 'pointer', color: '#757575' }}
        onClick={() => {
          removeAssociation(associations[index]);
        }}
      ></Clear>
    </Tooltip>
  );

  const conditionColumn = (dataIndex: number): React.ReactNode => {
    if (associations.length === 0 || !!!associations[dataIndex]) return <></>;

    return <Typography>{!!associations[dataIndex].isHeld ? 'Contains' : 'Does not contain'}</Typography>;
  };

  if (associations.length === 0) return <></>;
  return (
    <>
      <TableContainer component={Card} elevation={0}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>SECURITY/MODEL</TableCell>
              <TableCell>CONDITION</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {associations.map((row, index) => (
              <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell>{conditionColumn(index)}</TableCell>
                <TableCell align="right">{removeButton(index)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
