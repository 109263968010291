import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchPagedTransactions } from './thunks';
import { fetchPagedResults, SSTransaction, SSTransactionsState } from './types';

export const initialState: SSTransactionsState = {
  parameters: {
    accountId: '',
    pagination: {
      pageNumber: 1,
      pageSize: 25,
      queryFields: [],
    },
  },
  transactions: {
    pageNumber: 1,
    pageSize: 25,
    results: [],
    totalNumberOfPages: 0,
    totalNumberOfRecords: 0,
  },
};

export const transactionSlice = createSlice({
  name: '@@client/SuperSimplifier/Transactions',
  initialState,
  reducers: {
    setAccountId: (state, action: PayloadAction<string | null>) => {
      state.parameters.accountId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPagedTransactions.fulfilled, (state, action: PayloadAction<fetchPagedResults<SSTransaction>>) => {
      state.transactions = action.payload.results;
      state.parameters.pagination = action.payload.pagination;
    });
  },
});
