import { Enumeration } from 'src/common/types';
import { TriumphBaseRequest } from 'src/features/clients/client/portfolio/overview/store';
import { AdviserItem, AfslItem, ModelItem, PracticeItem, SimpleSecurityItem } from '../../../common/store/types';

export interface AccountSearchResult {
  clientId: number;
  clientName: string;
  clientType: string;
  email: string;
}

export interface EmailSearchState {
  parameters: AccountSearchParameters;
  models: ModelItem[];
  accountSearchResult: AccountSearchResult[];
  accountSelected: AccountSearchResult[];
}

export interface AccountSearchParameters extends TriumphBaseRequest {
  afsls: AfslItem[];
  practices: PracticeItem[];
  advisers: AdviserItem[];
  accountType: string[];
  serviceTypeId: number | null;
  portfolioValueFrom: number | null;
  portfolioValueTo: number | null;
  availableCashBalanceFrom: number | null;
  availableCashBalanceTo: number | null;
  securityHeld: string;
  security: SimpleSecurityItem | null;
  securitySearch: string | null;
  includeZeroHoldings: boolean;
  model: ModelItem | null;
}

export class SecurityHeld extends Enumeration {
  static Held = new SecurityHeld(1, 'Held', 'Hold');
  static NotHeld = new SecurityHeld(2, 'NotHeld', "Doesn't hold");
}
