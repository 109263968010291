import { combineReducers } from '@reduxjs/toolkit';
import { editSlice } from '../edit/store';
import { portfoliosSlice } from '../portfolios/store';
import { TemplatesState } from './types';
import { reportsSlice } from '../reports/store';

export const templatesReducer = combineReducers<TemplatesState>({
  portfolios: portfoliosSlice.reducer,
  reportsTemplate: reportsSlice.reducer,
  edit: editSlice.reducer,
});
