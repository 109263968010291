import WarningIcon from '@mui/icons-material/Warning';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { ReactNode, useEffect, useState } from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';
import ClipLoader from 'react-spinners/ClipLoader';
import { theme } from '../../themes';
import { LoadingProgress } from '../store/types/loadingProgress';

export interface LoadingIndicatorProps {
  progress: LoadingProgress;
  children?: ReactNode;
  spinner?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  warningIcon: {
    fontSize: '40px',
    color: theme.palette.common.white,
  },
}));

export const LoadingIndicator = (props: LoadingIndicatorProps): JSX.Element => {
  const classes = useStyles();
  const { progress, children, spinner } = props;

  const [icon, setIcon] = useState<ReactNode | null>(null);

  useEffect(() => {
    if (progress.hasErrors) {
      setIcon(<WarningIcon className={classes.warningIcon} />);
    } else if (spinner === false) {
      setIcon(null);
    } else {
      setIcon(<ClipLoader color={theme.palette.common.white} size="50px" />);
    }
  }, [progress, spinner, classes.warningIcon]);

  return (
    <LoadingOverlay
      spinner={icon || false}
      active={progress.isLoading || progress.hasErrors}
      styles={{
        overlay: (base) => ({
          ...base,
          background: 'rgba(0, 0, 0, 0.35)',
          borderRadius: '7px',
        }),
        wrapper: (base) => ({
          ...base,
        }),
        content: (base) => ({
          ...base,
        }),
        spinner: (base) => ({
          ...base,
        }),
      }}
    >
      {children}
    </LoadingOverlay>
  );
};
