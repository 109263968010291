import { createAsyncThunk } from '@reduxjs/toolkit';
import FileSaver from 'file-saver';
import { unparse } from 'papaparse';
import {
  DeleteFxDailyRateItem,
  DownloadFxDailyRatesPayload,
  FxDailyListParameters,
  FxDailyRateItem,
  FxDailyRateRequest,
  PostFxDailyRateItem,
  PutFxDailyRateItem,
} from './types';
import { buildEncodedQueryString, convertToCSVFormat, CSVDataType, downloadCsv } from 'src/common';
import api from 'src/app/api';
import { PagedResult } from 'src/store';

export enum SecurityPriceActionTypes {
  FetchFxDailyRates = '@@forexrates/rate/FetchFxDailyRates',
  DownloadFxDailyRates = '@@forexrates/rate/DownloadFxDailyRates',
  FetchFxDailyRatesDownloadAll = '@@forexrates/rate/FetchFxDailyRatesDownloadAll',
  CreateDailyRate = '@@forexrates/rate/CreateDailyRate',
  FetchDailyRate = '@@forexrates/rate/GetDailyRate',
  UpdateDailyRate = '@@forexrates/rate/UpdateDailyRate',
  DeleteDailyRate = '@@forexrates/rate/DeleteDailyRate',
}

export enum FxDailyRatesApiEndpoints {
  fetchFxDailyRates = '/securities/GetForexCurrencyRateList',
  createDailyRate = '/securities/CreateForexCurrencyRate',
  fetchDailyRate = '/securities/GetForexCurrencyRate',
  updateDailyRate = '/securities/UpdateForexCurrencyRate',
  deleteDailyRate = '/securities/DeleteForexCurrencyRate',
}

export const fetchFxDailyRates = createAsyncThunk(
  SecurityPriceActionTypes.FetchFxDailyRates,
  async (wrapper: FxDailyListParameters) => {
    const response = await getFxDailyRates(wrapper);
    return {
      results: response.data,
      pagination: wrapper.pagination,
    };
  }
);

export const fetchFxDailyRatesExport = createAsyncThunk(
  SecurityPriceActionTypes.FetchFxDailyRatesDownloadAll,
  async (wrapper: FxDailyListParameters) => {
    const response = await getFxDailyRates(wrapper);

    if (response) {
      const csv = unparse(
        response.data.results?.map((l) => {
          return { date: l.date, currencyRate: l.currencyRate };
        })
      );
      const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      FileSaver.saveAs(csvData, 'Dash_FxDaily_Rates.csv');
    }
  }
);

export const fetchDailyRate = createAsyncThunk(
  SecurityPriceActionTypes.FetchDailyRate,
  async (request: FxDailyRateRequest) => {
    const query = buildEncodedQueryString({
      baseCurrencyId: request.baseCurrencyId,
      quoteCurrencyId: request.quoteCurrencyId,
      date: request.date,
    });
    try {
      const response = await api.get<FxDailyRateItem>(`${FxDailyRatesApiEndpoints.fetchDailyRate}${query}`);
      return response.data;
    } catch (e) {}
  }
);

const getFxDailyRates = async (request: FxDailyListParameters) => {
  const body = {
    baseCurrencyId: request.baseCurrencyId,
    quoteCurrencyId: request.quoteCurrencyId,
    pagedRequest: request.pagination,
    dateFrom: request.dates?.dateFrom,
    dateTo: request.dates?.dateTo,
  };
  return await api.post<PagedResult<FxDailyRateItem>>(`${FxDailyRatesApiEndpoints.fetchFxDailyRates}`, body);
};

export const downloadFxDailyRates = createAsyncThunk(
  SecurityPriceActionTypes.DownloadFxDailyRates,
  async (wrapper: DownloadFxDailyRatesPayload) => {
    const response = await getFxDailyRates({
      ...wrapper,
      pagination: { ...wrapper.pagination, pageNumber: 1, pageSize: 9999999 },
    });
    const data: CSVDataType[] = convertToCSVFormat(
      response.data.results.map((l) => {
        return { date: l.date, currencyRate: l.currencyRate };
      })
    );

    downloadCsv(data, `FxDailyRate_`);
    return { message: 'CSV downloaded' };
  }
);

export const createRate = createAsyncThunk(
  SecurityPriceActionTypes.CreateDailyRate,
  async (payload: PostFxDailyRateItem) => {
    await api.post(`${FxDailyRatesApiEndpoints.createDailyRate}`, payload);
    return { ...payload, message: 'Rate Created' };
  }
);

export const updateRate = createAsyncThunk(
  SecurityPriceActionTypes.UpdateDailyRate,
  async (payload: PutFxDailyRateItem) => {
    await api.put(`${FxDailyRatesApiEndpoints.updateDailyRate}`, payload);
    return { ...payload, message: 'Rate saved' };
  }
);

export const deleteRate = createAsyncThunk(
  SecurityPriceActionTypes.DeleteDailyRate,
  async (payload: DeleteFxDailyRateItem) => {
    await api.delete(`${FxDailyRatesApiEndpoints.deleteDailyRate}`, { data: payload });
    return { ...payload, message: 'Rate deleted' };
  }
);
