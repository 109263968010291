import { RebalanceMode, ValidationResult } from '../store/types';
import { getRebalanceSummary } from './getRebalanceSummary';
import { RebalanceState } from './../store/types';

export function getValidationResult(state: RebalanceState, validationResult: ValidationResult): ValidationResult {
  const summary = getRebalanceSummary(state);
  const validation = validationResult;
 
  if (validation && state.withdrawal.rebalanceMode === RebalanceMode.SellDownToCash &&  summary.proposedCash < state.withdrawal.withdrawalDetailsData.withdrawalAud) {
    validation.warnings.push('Due to order adjustments or minimum order values, the proposed cash is less than cash requested. Please review and update orders if additional cash is required.');
  }

  return validation;
}
