import { Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { LoadingIndicator } from 'src/common/components/LoadingIndicator';
import { Props } from '../container';
import { PensionBankDetails } from './pensionBankDetail';
import { PensionDetails } from './pensionDetail';

export const PensionDetailStatement = (props: Props): JSX.Element => {
  const {
    clientId,
    accountId,
    pensionDetails,
    pensionBankDetails,
    loadingProgressPensionDetails,
    loadingProgressPensionBankDetails,
    fetchPensionDetailByAccountId,
    fetchPensionBankDetailByClientId,
  } = props;

  useEffect(() => {
    if (!!clientId) {
      fetchPensionBankDetailByClientId(clientId);
    }
  }, [fetchPensionBankDetailByClientId, clientId]);

  useEffect(() => {
    if (!!accountId) {
      fetchPensionDetailByAccountId(accountId);
    }
  }, [fetchPensionDetailByAccountId, accountId]);

  return (
    <>
      <Typography variant="h3" style={{ padding: '20px 0' }}>
        Pension Details
      </Typography>

      <LoadingIndicator progress={loadingProgressPensionDetails}>
        <PensionDetails detail={pensionDetails}></PensionDetails>
      </LoadingIndicator>
      <LoadingIndicator progress={loadingProgressPensionBankDetails}>
        <PensionBankDetails detail={pensionBankDetails}></PensionBankDetails>
      </LoadingIndicator>
    </>
  );
};
