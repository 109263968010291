import { connect, ConnectedProps } from 'react-redux';
import { progressSelectorFactory } from 'src/common';
import { RootState } from 'src/store';
import { selectClientId, selectAfslId, selectInvestmentServiceId, clientSubTypeId } from '../../../common/store/selectors';
import { ApprovedProductsActionTypes, fetchSecurities, selectApprovedSecurities, selectCurrentInvestmentVersionId } from './../../store/common';
import { ReportSettings } from './components/reportSettings';
import { selectSecurityExclusions } from './store/selectors';
import { exclusionsSlice } from './store/slice';
import { SecurityExclusionActionTypes, fetchSecurityExclusion, createOrUpdateSecurityExclusion, deleteSecurityExclusion } from './store/thunks';


const mapStateToProps = (state: RootState) => ({
  clientId: selectClientId(state),
  securityExclusions: selectSecurityExclusions(state),
  afslId: selectAfslId(state),
  currentInvestmentVersionId: selectCurrentInvestmentVersionId(state),
  investmentServiceId : selectInvestmentServiceId(state), 
  isLoadingProgress: progressSelectorFactory([SecurityExclusionActionTypes.FetchSecurityExclusion])(state),
  securities: selectApprovedSecurities(state),
  securitiesLoadingProgress: progressSelectorFactory([ApprovedProductsActionTypes.FetchApprovedProducts])(state),
  savingProgressCreate: progressSelectorFactory([SecurityExclusionActionTypes.CreateOrUpdateSecurityExclusion])(state),
  savingProgressDelete: progressSelectorFactory([SecurityExclusionActionTypes.DeleteSecurityExclusion])(state),
  clientsubTypeId: clientSubTypeId(state)
});

const mapDispatchToProps = {
  ...exclusionsSlice.actions,

  fetchSecurityExclusion,
  createOrUpdateSecurityExclusion,
  deleteSecurityExclusion,
  fetchSecurities,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector>;
export const ReportContainer = connector(ReportSettings);
