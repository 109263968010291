import { createSelector } from '@reduxjs/toolkit';
import { selectTemplatesState } from 'src/store/selectors';

export const selectReportsState = createSelector(
  selectTemplatesState,
  (reportsTemplateState) => reportsTemplateState.reportsTemplate
);

export const selectPortfoliosState = createSelector(
  selectTemplatesState,
  (portfoliosState) => portfoliosState.portfolios
);

export const selectEditState = createSelector(selectTemplatesState, (portfoliosState) => portfoliosState.edit);
