import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../../app/api';
import { SecurityDetails } from './types';

export enum CommonActionTypes {
  FetchSecurityDetails = '@@security/common/GetSecurityDetails',
}

export enum CommonApi {
  FetchSecurityDetails = '/securities/GetSecurityDetails',
}

export const fetchSecurityDetails = createAsyncThunk(CommonActionTypes.FetchSecurityDetails, async (securityId: number) => {
  const response = await api.get<SecurityDetails>(`${CommonApi.FetchSecurityDetails}?securityId=${securityId}`);
  return response.data;
});
