import { User } from 'oidc-client';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import history from '../history';
import { userManager } from '../userManager';

class Callback extends Component {
  successCallback(user: User) {
    const redirectPath = user.state.path;
    history.push(redirectPath);
  }

  errorCallback() {
    history.push('/');
  }

  componentDidMount() {
    userManager
      .signinRedirectCallback()
      .then((user) => this.successCallback(user))
      .catch(() => this.errorCallback());
  }

  render() {
    return <div>Loading...</div>;
  }
}

export default connect()(Callback);
