import { createSelector } from '@reduxjs/toolkit';
import { selectModel } from '../../store/selectors';
import { ModelCompositionState } from './../store/types';
import { ComponentAllocations } from './types';

export const selectCompositionState = createSelector(selectModel, (modelState) => modelState.modelComposition);
export const selectParameters = createSelector(selectCompositionState, (listState) => listState.parameters);
export const selectCompositionDetail = createSelector(selectCompositionState, (listState) => listState.compositionDetail);
export const selectModelGroupItems = createSelector(selectCompositionState, (state) => {
  let selectedGroupItems: ComponentAllocations[] = [];
  state.compositionDetail?.groups.forEach((group) => {
    if (group.label.toLowerCase() === state.parameters.selectedGroup.toLowerCase()) {
      selectedGroupItems = group.componentAllocations;
    }
  });

  return selectedGroupItems;
});

export const selectModelVersions = createSelector(selectModel, (modelState) => modelState.common.modelVersions);
export const selectImportErrors = createSelector(
  selectCompositionState,
  (selectCompositionState: ModelCompositionState) => selectCompositionState.importErrors
);

export const selectModelId = createSelector(selectModel, (modelState) => modelState.common.currentModelId);
export const selectModelUploadSuccess = createSelector(selectModel, (modelState) => modelState.modelComposition.modelUploadSuccess);
