import { Box, Container, Paper, Tab, Tabs } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import ProtectedRoute from '../../ProtectedRoute';
import { TabRoute } from '../../store';

export interface Props extends RouteComponentProps {
  tileComponent?: React.ElementType;
  tabRoutes: TabRoute[];
  minWidth?: string;
  tileText?: string;
  tileSubText?: string;
}

export const TabControl = ({ tabRoutes, history, ...props }: Props): JSX.Element => {
  const { path, url } = useRouteMatch();
  const { pathname } = useLocation();
  const [value, setValue] = useState<number>(0);
  const setSelectedTab = useCallback(() => {
    setValue(tabRoutes.sort((a, b) => a.order - b.order).findIndex((r: TabRoute) => pathname.indexOf(`${url}${r.path}`) === 0));
  }, [tabRoutes, pathname, url]);

  const setNewTabIndex = (newTabIndex: number) => {
    const newTab = tabRoutes.sort((a, b) => a.order - b.order).find((r: TabRoute, index: number) => index === newTabIndex);

    if (!!newTab) {
      history.push(`${url}${newTab.path}`);

      setValue(newTabIndex);
    }
  };

  useEffect(() => {
    setSelectedTab();
  }, [tabRoutes, setSelectedTab]);

  return (
    <>
      <Paper square elevation={0}>
        <Container>
          <Box display={'inline-flex'} justifyContent={'space-between'}>
            {!!props.tileComponent && <props.tileComponent {...props} />}
          </Box>
          <Box display={'inline-flex'} justifyContent={'space-between'}>
            <Tabs
              value={value}
              indicatorColor="primary"
              textColor="primary"
              onChange={(_event, value) => {
                setNewTabIndex(value);
              }}
              aria-label="menu"
              TabIndicatorProps={{ style: { height: '4px' } }}
            >
              {tabRoutes
                .sort((a, b) => a.order - b.order)
                .map((route: TabRoute, index: number) => (
                  <Tab key={index} label={route.label} disabled={route?.component == null} />
                ))}
            </Tabs>
          </Box>
        </Container>
      </Paper>
      <Switch>
        {tabRoutes.map((route: TabRoute) => (
          <ProtectedRoute path={`${path}${route.path}`} component={route.component} key={`${path}${route.path}`} />
        ))}
      </Switch>
    </>
  );
};
