import { Enumeration } from 'src/common/types';
import { AdviserItem, AfslItem, ModelItem, PracticeItem, SimpleSecurityItem } from '../../../common/store/types';

export interface OrderSearchState {
  parameters: OrderSearchParameters;
  securities: SimpleSecurityItem[];
  results: OrderSearchResult[];
  selectedResults: number[];
  models: ModelItem[];
}

export interface OrderSearchParameters {
  afsls: AfslItem[];
  practices: PracticeItem[];
  advisers: AdviserItem[];
  clientTypes: string[];
  serviceType: string;
  portfolioValueFrom: number | null;
  portfolioValueTo: number | null;
  availableCashBalanceFrom: number | null;
  availableCashBalanceTo: number | null;
  securityHeld: string;
  security: SimpleSecurityItem | null;
  securitySearch: string | null;
  model: ModelItem | null;
  isRebalanceDataSourceTriumph: boolean;
}

export interface OrderSearchResult {
  clientId: number;
  clientName: string;
  clientType: string;
  portfolioId: number;
  portfolioName: string;
  serviceType: string;
  cashBalance: number;
  marketValue: number;
  outOfBalanceRating: number;
  portfolioPercentage: number;
  assetClassPercentage: number;
  cashAssetAllocationTotal: number;
}

export class SecurityHeld extends Enumeration {
  static Held = new SecurityHeld(1, 'Held', 'Held');
  static NotHeld = new SecurityHeld(2, 'NotHeld', "Doesn't hold");
}
