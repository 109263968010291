import { useField } from 'formik';
import React from 'react';
import { DataSelect } from '..';

export interface FormikSelectProps<T extends { id: number; name: string }> {
  fieldName: string;
  itemDisplayNameField: keyof T;
  data: T[];
  disabled?: boolean;
  showRequiredAsterisk?: boolean;
  onChangeValue?: (id: number | string | null) => void;
}

export const FormikSelect = <T extends { id: number; name: string }>(props: FormikSelectProps<T>): JSX.Element => {
  const { itemDisplayNameField, data, fieldName, disabled, onChangeValue } = props;

  const [field, meta, helpers] = useField(fieldName);

  return !!data ? (
    <>
      <DataSelect<T>
        {...props}
        {...field}
        value={data.length > 0 ? field.value || '' : ''}
        getId={(t: T): number => t.id}
        getName={(t: T): T[keyof T] => t[itemDisplayNameField]}
        onChange={(value: number | string | null) => {
          helpers.setValue(value, true);
          if (!!onChangeValue) {
            onChangeValue(value);
          }
        }}
        onBlur={() => {
          helpers.setTouched(true);
          field.onBlur(fieldName);
        }}
        disabled={disabled}
        hasValidationError={!!meta.error && !!meta.touched}
        id={fieldName}
      />
    </>
  ) : (
    <></>
  );
};
