import AdjustIcon from '@mui/icons-material/Adjust';
import { DesktopDatePicker } from '@mui/lab';
import {
  Box,
  Button,
  Grid,
  OutlinedInput,
  Paper,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import {
  DateTimeFormat,
  decimalPlacesByMarketCode,
  formatDollars,
  getLocalDateTime,
  VirtualizedAutocomplete,
} from 'src/common';
import { SimpleSecurityItem } from '../../../common/store/types';
import { Props } from '../container';
import {
  ExpiryType,
  PortfolioSecurityHoldingResult,
  PriceType,
  TradeAction,
  TradeMethod,
  WeightsType,
} from '../store/types';

export const Parameters = (props: Props): JSX.Element => {
  const {
    parameters,
    securities,
    securityHoldings,
    portfolioIds,
    setSecurity,
    fetchPortfolioSecurityHoldings,
    setTradeAction,
    setTradeMethod,
    setAmount,
    setPriceType,
    setPriceLimit,
    setExpiryType,
    setWeightsType,
    setExpiryDate,
    setCalculateOrder,
    isLoading,
    hasRebalanceEnhancedTrading,
    hasClientEditAdminOnlyPermission,
    isRebalanceDataFromTriumph,
  } = props;

  const [limitDate, setLimitDate] = useState<DateTime | null>(null);

  useEffect(() => {
    handleSetSecurity(parameters.security);
  }, [isRebalanceDataFromTriumph]);

  const handleSetSecurity = (item: SimpleSecurityItem | null) => {
    setSecurity(item);
    if (item) {
      fetchPortfolioSecurityHoldings({
        securityId: item.securityId,
        portfolioIds: portfolioIds,
        isDataFromTriumph: isRebalanceDataFromTriumph,
      });
    }
  };

  const securityPriceSection = (securityHoldings: PortfolioSecurityHoldingResult) => {
    return (
      <Box display={'inline-flex'}>
        <Typography variant="h5">{formatDollars(securityHoldings.unitPrice)} AUD</Typography>
        <Typography variant="h5" color="secondary" style={{ marginLeft: 5 }}>
          As of {getLocalDateTime(securityHoldings.priceDate, DateTimeFormat.DailyDate)}
        </Typography>
      </Box>
    );
  };

  const handleDateChange = (materialUiPickersDate: DateTime | null, setFunction: (date: DateTime | null) => void) => {
    setFunction(materialUiPickersDate ?? null);
    setExpiryDate(materialUiPickersDate === null ? null : materialUiPickersDate.toISODate());
  };

  return (
    <Paper elevation={0} style={{ marginTop: '40px', paddingTop: '30px' }}>
      <Grid container style={{ padding: '5px 20px' }}>
        <Box style={{ maxWidth: '600px', minWidth: '300px', width: '20%', paddingBottom: '5px' }}>
          <VirtualizedAutocomplete<SimpleSecurityItem>
            selectedValue={parameters.security}
            options={securities}
            onOptionChange={handleSetSecurity}
            filterOptionStringLogic={(s: SimpleSecurityItem) => s.securityCode + s.securityName}
            selectedOptionRenderedLabel={(s: SimpleSecurityItem) => `${s.securityCode} - ${s.securityName}`}
          />
        </Box>

        <ToggleButtonGroup
          size="small"
          exclusive
          aria-label="small outlined button group"
          style={{ padding: '0 20px' }}
        >
          <ToggleButton
            className={'Green'}
            color={'primary'}
            value={TradeAction.Buy.displayName}
            onClick={() => {
              parameters.tradeAction !== TradeAction.Buy.name && setTradeAction(TradeAction.Buy.name);
            }}
            selected={parameters.tradeAction === TradeAction.Buy.name}
          >
            Buy
          </ToggleButton>
          <ToggleButton
            className={'Red'}
            value={TradeAction.Sell.displayName}
            onClick={() => {
              parameters.tradeAction !== TradeAction.Sell.name && setTradeAction(TradeAction.Sell.name);
            }}
            selected={
              parameters.tradeAction === TradeAction.Sell.name || parameters.tradeAction === TradeAction.SellAll.name
            }
          >
            Sell
          </ToggleButton>
          <ToggleButton
            className={'Red'}
            value={TradeAction.SellAll.displayName}
            onClick={() => {
              parameters.tradeAction !== TradeAction.SellAll.name &&
                setTradeAction(TradeAction.SellAll.name) &&
                setTradeMethod(TradeMethod.Units.name) &&
                setAmount(0);
            }}
            selected={parameters.tradeAction === TradeAction.SellAll.name}
          >
            All
          </ToggleButton>
        </ToggleButtonGroup>

        <ToggleButtonGroup size="small" exclusive aria-label="small outlined button group">
          <Tooltip title={'Dollar'} arrow>
            <ToggleButton
              value={TradeMethod.Amount.displayName}
              disabled={parameters.tradeAction === TradeAction.SellAll.name}
              onClick={() => {
                parameters.tradeMethod !== TradeMethod.Amount.name && setTradeMethod(TradeMethod.Amount.name);
              }}
              selected={parameters.tradeMethod === TradeMethod.Amount.name}
            >
              $
            </ToggleButton>
          </Tooltip>
          <Tooltip title={'Percentage'} arrow>
            <ToggleButton
              value={TradeMethod.Percentage.displayName}
              disabled={parameters.tradeAction === TradeAction.SellAll.name}
              onClick={() => {
                parameters.tradeMethod !== TradeMethod.Percentage.name && setTradeMethod(TradeMethod.Percentage.name);
              }}
              selected={parameters.tradeMethod === TradeMethod.Percentage.name}
            >
              %
            </ToggleButton>
          </Tooltip>
          <Tooltip title={'Units'} arrow>
            <ToggleButton
              value={TradeMethod.Units.displayName}
              onClick={() => {
                parameters.tradeMethod !== TradeMethod.Units.name && setTradeMethod(TradeMethod.Units.name);
              }}
              selected={parameters.tradeMethod === TradeMethod.Units.name}
            >
              #
            </ToggleButton>
          </Tooltip>

          <Tooltip title={'Target'} arrow>
            <ToggleButton
              value={TradeMethod.Target.displayName}
              disabled={parameters.tradeAction === TradeAction.SellAll.name}
              onClick={() => {
                parameters.tradeMethod !== TradeMethod.Target.name &&
                  setTradeMethod(TradeMethod.Target.name) &&
                  setAmount(0);
              }}
              selected={parameters.tradeMethod === TradeMethod.Target.name}
            >
              <AdjustIcon />
            </ToggleButton>
          </Tooltip>
        </ToggleButtonGroup>
        <Box style={{ paddingLeft: '10px', paddingRight: '10px' }}>
          {parameters.tradeAction !== TradeAction.SellAll.name && parameters.tradeMethod !== TradeMethod.Target.name && (
            <NumberFormat
              value={parameters.amount ?? 0}
              customInput={OutlinedInput}
              type="text"
              data-id={'edit-amount'}
              disabled={
                parameters.tradeAction === TradeAction.SellAll.name ||
                parameters.tradeMethod === TradeMethod.Target.name
              }
              thousandSeparator
              fixedDecimalScale
              allowNegative={false}
              decimalScale={
                parameters.tradeMethod === TradeMethod.Amount.name ||
                parameters.tradeMethod === TradeMethod.Percentage.name
                  ? 2
                  : decimalPlacesByMarketCode(securityHoldings?.code)
              }
              onValueChange={(values) => {
                if (values.floatValue !== parameters.amount) {
                  setAmount(values.floatValue ?? 0);
                }
              }}
            />
          )}
        </Box>

        {(hasRebalanceEnhancedTrading || hasClientEditAdminOnlyPermission) && (
          <Box style={{ paddingLeft: '10px', paddingRight: '10px' }}>
            <ToggleButtonGroup size="small" exclusive aria-label="small outlined button group">
              <Tooltip title={'Market to Limit'} arrow>
                <ToggleButton
                  name={'MarketButton'}
                  value={PriceType.Market}
                  disabled={false}
                  style={{ width: '45px' }}
                  onClick={() => {
                    setPriceType(PriceType.Market);
                  }}
                  selected={parameters.priceType === PriceType.Market || parameters.priceType === null}
                >
                  MKT
                </ToggleButton>
              </Tooltip>
              <Tooltip title={'Limit'} arrow>
                <ToggleButton
                  name={'LimitButton'}
                  value={PriceType.Limit}
                  disabled={false}
                  style={{ width: '45px' }}
                  onClick={() => {
                    setPriceType(PriceType.Limit);
                  }}
                  selected={parameters.priceType === PriceType.Limit}
                >
                  LMT
                </ToggleButton>
              </Tooltip>
            </ToggleButtonGroup>
          </Box>
        )}
        {(hasRebalanceEnhancedTrading || hasClientEditAdminOnlyPermission) && (
          <NumberFormat
            value={
              parameters.priceType === PriceType.Market || parameters.priceType === null ? '' : parameters.priceLimit
            }
            customInput={OutlinedInput}
            placeholder="Limit price"
            type="text"
            disabled={parameters.priceType === PriceType.Market || parameters.priceType === null}
            thousandSeparator
            fixedDecimalScale
            allowNegative={false}
            decimalScale={3}
            isAllowed={() => true}
            onValueChange={(values) => {
              if (values.floatValue !== parameters.priceLimit) {
                setPriceLimit(values.floatValue ?? null);
              }
            }}
          />
        )}
        {(hasRebalanceEnhancedTrading || hasClientEditAdminOnlyPermission) && (
          <Box style={{ paddingLeft: '10px', paddingRight: '10px' }}>
            <ToggleButtonGroup size="small" exclusive aria-label="small outlined button group">
              <Tooltip title={'Good For Day'} arrow>
                <ToggleButton
                  name={'ExpiryTypeButton'}
                  value={ExpiryType.GFD}
                  style={{ width: '45px' }}
                  onClick={() => {
                    parameters.expiryType !== ExpiryType.GFD && setExpiryType(ExpiryType.GFD);
                    handleDateChange(null, setLimitDate);
                  }}
                  selected={parameters.expiryType === ExpiryType.GFD || parameters.expiryType === null}
                >
                  GFD
                </ToggleButton>
              </Tooltip>
              <Tooltip title={'Good Till Cancelled'} arrow>
                <ToggleButton
                  name={'ExpiryTypeButton'}
                  value={ExpiryType.GTC}
                  style={{ width: '45px' }}
                  onClick={() => {
                    parameters.expiryType !== ExpiryType.GTC && setExpiryType(ExpiryType.GTC);
                    handleDateChange(null, setLimitDate);
                  }}
                  selected={parameters.expiryType === ExpiryType.GTC}
                >
                  GTC
                </ToggleButton>
              </Tooltip>
              <Tooltip title={'Good Till Date'} arrow>
                <ToggleButton
                  name={'ExpiryTypeButton'}
                  value={ExpiryType.GTD}
                  style={{ width: '45px' }}
                  onClick={() => {
                    parameters.expiryType !== ExpiryType.GTD && setExpiryType(ExpiryType.GTD);
                    handleDateChange(DateTime.now(), setLimitDate);
                  }}
                  selected={parameters.expiryType === ExpiryType.GTD}
                >
                  GTD
                </ToggleButton>
              </Tooltip>
            </ToggleButtonGroup>
          </Box>
        )}
        {(hasRebalanceEnhancedTrading || hasClientEditAdminOnlyPermission) && (
          <Box style={{ paddingLeft: '0', paddingRight: '10px' }}>
            <DesktopDatePicker
              inputFormat={DateTimeFormat.Short.displayName}
              disabled={!(parameters.expiryType === ExpiryType.GTD)}
              minDate={DateTime.now()}
              maxDate={DateTime.now().plus({ weeks: 4 })}
              value={
                parameters.expiryType === ExpiryType.GFD || parameters.expiryType === ExpiryType.GTC ? null : limitDate
              }
              onChange={(e) => {
                handleDateChange(e, setLimitDate);
              }}
              OpenPickerButtonProps={{
                'aria-label': 'change date',
              }}
              renderInput={(params) => <TextField {...params} variant="outlined" />}
            />
          </Box>
        )}

        <Button
          color={'primary'}
          onClick={() => setCalculateOrder()}
          variant="contained"
          disableElevation
          disabled={parameters.security === null || isLoading.isLoading}
        >
          Calculate
        </Button>
      </Grid>

      <Grid container style={{ padding: '0px 20px' }}>
        <Grid item xs={12}>
          <hr></hr>
        </Grid>
        <Grid item xs={9}></Grid>
        <Grid item xs={3} style={{ paddingLeft: '18px' }}>
          <Box style={{ width: '100%' }}>
            View weights as proportion of &nbsp;
            <ToggleButtonGroup size="small" exclusive aria-label="small outlined button group">
              <ToggleButton
                name={'WeightsTypeButton'}
                value={WeightsType.Portfolio}
                disabled={false}
                onClick={() => {
                  setWeightsType(WeightsType.Portfolio);
                }}
                selected={parameters.weightsType === WeightsType.Portfolio}
              >
                Portfolio
              </ToggleButton>

              <ToggleButton
                name={'WeightsTypeButton'}
                value={WeightsType.AssetClass}
                disabled={false}
                onClick={() => {
                  setWeightsType(WeightsType.AssetClass);
                }}
                selected={parameters.weightsType === WeightsType.AssetClass}
              >
                Asset Class
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
        </Grid>
      </Grid>

      <Box padding={'5px 20px'}>{securityHoldings && securityPriceSection(securityHoldings)}</Box>
    </Paper>
  );
};
