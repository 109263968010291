import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/system';

export const useStyles = makeStyles((theme: Theme) => ({
  documentTypeIcon: {
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
  documentTypeIconGrid: {
    justifyContent: 'end',
    display: 'flex',
  },
  downloadIcon: {
    color: theme.palette.primary.main,
  },
  downloadButton: {
    textTransform: 'none',
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
  title: {
    textAlign: 'left',
  },
  paper: {
    width: 'auto',
    overflowX: 'auto',
    marginBottom: '15px',
    marginTop: '38px',
  },
  td: {
    borderBottom: 'none',
  },
  documentDate: {
    color: theme.palette.text.primary,
  },
  documentName: {
    fontWeight: 900,
    fontSize: '16px',
  },
  documentType: {
    color: theme.palette.text.primary,
  },
  download: {
    justifyContent: 'center',
  },
}));
