import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Typography,
} from '@mui/material';
import { WO2Checkbox, WO2Select, WO2ToggleSwitch } from 'src/common';
import {
  ClientReportsParameters,
  GroupBySecurityContribution,
  HideBySecuriyContribution,
  HidingOptionsRequest,
  MdaReportTypes,
} from '../../store/types';
import { ActionCreatorWithoutPayload, ActionCreatorWithPayload } from '@reduxjs/toolkit';
import React from 'react';
import { DateRange } from 'src/store';
import DateRangePicker from 'src/common/components/DateRangePicker';

export interface SecurityContributionProps {
  parameters: ClientReportsParameters;
  expandSecurityContribution: ActionCreatorWithoutPayload<string>;
  setSecurityContributionSelectedParameter: ActionCreatorWithPayload<boolean, string>;
  setDateRangeSecurityContributionParameter: ActionCreatorWithPayload<DateRange>;
  setSecurityContributionHidingOptionParameter: ActionCreatorWithPayload<
    HidingOptionsRequest<HideBySecuriyContribution>,
    string
  >;
  setSecurityContributionGroupByParamater: ActionCreatorWithPayload<GroupBySecurityContribution, string>;
  isDatesLessThanFullYear: (dates: DateRange) => boolean;
  createReportSortRequest: (isAdd: boolean, id: number, text: string) => void;
}

export const SecurityContribution = ({
  parameters,
  setSecurityContributionSelectedParameter,
  setDateRangeSecurityContributionParameter,
  setSecurityContributionHidingOptionParameter,
  setSecurityContributionGroupByParamater,
  expandSecurityContribution,
  isDatesLessThanFullYear,
  createReportSortRequest,
}: SecurityContributionProps) => {
  const handleClickSecurityContributionSelect = () => {
    setSecurityContributionSelectedParameter(!parameters.selectedReports?.securityContribution.selected);
    createReportSortRequest(
      !parameters.selectedReports?.securityContribution.selected,
      MdaReportTypes.SecurityContribution.id,
      MdaReportTypes.SecurityContribution.displayName
    );
  };

  const handleClickDateForSecurityContribution = (dates: DateRange) => {
    setDateRangeSecurityContributionParameter(dates);
    if (isDatesLessThanFullYear(dates)) {
      setSecurityContributionHidingOptionParameter({
        option: HideBySecuriyContribution.AnnualisedReturn,
        value: false,
      });
    }
  };
  return (
    <Accordion
      onChange={expandSecurityContribution}
      expanded={parameters.selectedReports?.securityContribution.expanded || false}
      elevation={0}
    >
      <AccordionSummary expandIcon={<ExpandMore />}>
        <FormControlLabel
          sx={{ flex: 1 }}
          aria-label="Acknowledge"
          onClick={(event) => event.stopPropagation()}
          onFocus={(event) => event.stopPropagation()}
          control={
            <WO2Checkbox
              color="primary"
              checked={parameters.selectedReports?.securityContribution.selected}
              onChangeHandler={handleClickSecurityContributionSelect}
            />
          }
          label="Security Contribution"
        />
        <div onClick={(e) => e.stopPropagation()}>
          <DateRangePicker
            dateRange={{
              dateFrom: parameters?.selectedReports.securityContribution?.fromDate,
              dateTo: parameters?.selectedReports.securityContribution?.toDate,
              dateRangeType: parameters?.selectedReports.securityContribution?.dateRangeType,
            }}
            setDateRange={handleClickDateForSecurityContribution}
            inceptionDate={parameters.inceptionDate}
          />
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container direction="column">
          <Grid container spacing={1} alignItems="center">
            <Grid item flex={1}>
              <FormControl fullWidth>
                <InputLabel id="group-by-id">Group by</InputLabel>
                <WO2Select
                  labelId="group-by-id"
                  label="group by"
                  onChange={(e) =>
                    setSecurityContributionGroupByParamater(e.target.value as GroupBySecurityContribution)
                  }
                  value={parameters.selectedReports.securityContribution.groupBy}
                  defaultValue={GroupBySecurityContribution.AssetClass}
                >
                  <MenuItem value={GroupBySecurityContribution.AssetClass}>Asset Class</MenuItem>
                  <MenuItem value={GroupBySecurityContribution.Gics}>GICS</MenuItem>
                </WO2Select>
              </FormControl>
            </Grid>
          </Grid>

          <Grid item container direction="row" alignItems="center" spacing={1}>
            <Grid item container alignItems="center" sm={6} md={3} flexWrap="nowrap">
              <WO2ToggleSwitch
                checked={parameters.selectedReports.securityContribution.excludeFrankingCredits}
                onChange={(e) =>
                  setSecurityContributionHidingOptionParameter({
                    value: e.target.checked,
                    option: HideBySecuriyContribution.FrankingCredit,
                  })
                }
                color="primary"
                name="hidingOptions"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
              <Typography variant="h4">Exclude Franking Credits</Typography>
            </Grid>
            <Grid item container alignItems="center" sm={6} md={3} flexWrap="nowrap">
              <WO2ToggleSwitch
                checked={parameters.selectedReports.securityContribution.showAnnualisedReturns}
                onChange={(e) =>
                  setSecurityContributionHidingOptionParameter({
                    value: e.target.checked,
                    option: HideBySecuriyContribution.AnnualisedReturn,
                  })
                }
                color="primary"
                name="hidingOptions"
                inputProps={{ 'aria-label': 'primary checkbox' }}
                disabled={isDatesLessThanFullYear({
                  dateFrom: parameters?.selectedReports.securityContribution?.fromDate,
                  dateTo: parameters?.selectedReports.securityContribution?.toDate,
                  dateRangeType: parameters?.selectedReports.securityContribution?.dateRangeType,
                })}
              />
              <Typography variant="h4">Show Annualised Returns</Typography>
            </Grid>
            <Grid item container alignItems="center" sm={6} md={3} flexWrap="nowrap">
              <WO2ToggleSwitch
                checked={parameters.selectedReports.securityContribution.applyExclusionList}
                onChange={(e) =>
                  setSecurityContributionHidingOptionParameter({
                    value: e.target.checked,
                    option: HideBySecuriyContribution.ExclusionList,
                  })
                }
                color="primary"
                name="hidingOptions"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
              <Typography variant="h4">Apply Exclusion List</Typography>
            </Grid>
            <Grid item container alignItems="center" sm={6} md={3} flexWrap="nowrap">
              <WO2ToggleSwitch
                checked={parameters.selectedReports.securityContribution.excludeCapitalIncomeReturns}
                onChange={(e) =>
                  setSecurityContributionHidingOptionParameter({
                    value: e.target.checked,
                    option: HideBySecuriyContribution.CapitalIncomeReturn,
                  })
                }
                color="primary"
                name="hidingOptions"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
              <Typography variant="h4">Exclude Capital/Income Returns</Typography>
            </Grid>
            <Grid item container alignItems="center" sm={6} md={3} flexWrap="nowrap">
              <WO2ToggleSwitch
                checked={parameters.selectedReports.securityContribution.includeDollarReturns}
                onChange={(e) =>
                  setSecurityContributionHidingOptionParameter({
                    value: e.target.checked,
                    option: HideBySecuriyContribution.DollarReturn,
                  })
                }
                color="primary"
                name="hidingOptions"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
              <Typography variant="h4">Include Dollar Returns</Typography>
            </Grid>
            <Grid item container alignItems="center" sm={6} md={3} flexWrap="nowrap">
              <WO2ToggleSwitch
                checked={parameters.selectedReports.securityContribution.includeDetailTable}
                onChange={(e) =>
                  setSecurityContributionHidingOptionParameter({
                    value: e.target.checked,
                    option: HideBySecuriyContribution.DetailTable,
                  })
                }
                color="primary"
                name="hidingOptions"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
              <Typography variant="h4">Include Detail Table</Typography>
            </Grid>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
