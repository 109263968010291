import React from 'react';
import Importer from '../../../../../common/components/importer';
import { ImportRequestPayload, MenuOptions } from '../store/types';

interface Props {
  importTrades: (payload: ImportRequestPayload) => void;
  displayMenuOption: MenuOptions;
  setMenuOption: React.Dispatch<React.SetStateAction<MenuOptions>>;
  isImporting: boolean;
  importErrors: string[];
}

export default function Import(props: Props): JSX.Element {
  const { importTrades, setMenuOption, displayMenuOption, isImporting, importErrors } = props;

  function onImport(file: File) {
    importTrades({ file: file });
  }

  function onClose() {
    setMenuOption(MenuOptions.None);
  }

  return (
    <Importer
      fileType=".csv"
      isOpen={displayMenuOption === MenuOptions.Import}
      isImporting={isImporting}
      errors={importErrors}
      handleClose={onClose}
      handleImport={onImport}
    />
  );
}
