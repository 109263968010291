import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { RootState } from '../../../reducers';
import { selectFxCommon } from '../rate/store/selectors';
import { selectFxRate } from '../store/selectors';
import { FxTabs } from './components/FxTabs';
import { fetchFxDetails } from './store/thunks';

const mapStateToProps = (state: RootState) => ({
  securityPrice: selectFxRate(state),
  securityCommon: selectFxCommon(state),
});

const mapDispatchToProps = {
  fetchFxDetails,
};

interface MatchParams {
  baseCurrencyId?: string;
  quoteCurrencyId?: string;
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector> & RouteComponentProps<MatchParams>;
export const CommonContainer = connect(mapStateToProps, mapDispatchToProps)(FxTabs);
