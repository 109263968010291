import { Box, Fade, Grid, Link, Typography } from '@mui/material';
import { Field, FieldProps, Form, Formik, FormikProps } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { AccountInstitutionType } from 'src/common/components/accounts/types';
import { ClientAccountSubType, ClientAccountType } from 'src/common/types';
import * as yup from 'yup';
import {
  FormikEnumerationSelect,
  FormikNumberFormat,
  FormikSelect,
  FormikSwitch,
  FormikTextField,
} from '../../../../../common/components/formik';
import { AccountInstitutionItem } from '../../components/common/accountInstitutionItem';
import { PageBackNextButtons } from '../../components/common/pageBackNextButtons';
import { SourceOfFunds } from '../../store/enums';
import { AccountInfo, ExistingAccount } from '../../store/types';
import { Props } from '../container';
import { AccountAmmDocumentDialog } from './accountAmmDocumentDialog';
import { AccountEditModal } from './accountEditModal';

interface FormValues extends AccountInfo {
  hasExternalAccount: boolean;
}

export const Accounts = (props: Props): JSX.Element => {
  const {
    id,
    history,
    prevNextRoutePaths,
    clientAccount,
    accountInfo,
    existingAccounts,
    externalInstitutions,
    newAccounts,
    setExistingAccount,
    removeExistingAccount,
    toggleNewAccount,
    fetchExternalInstitutions,
    fetchApprovedInstitutionIds,
    saveAccountInfo,
    saveProgress,
    approvedInstitutionIds,
    approvedInstitutionProgress,
    advisor,
    optionalSuperInstitutions,
  } = props;

  const [editInstitutionId, setEditInstitutionId] = useState<number | null>(null);
  const [ammDocumentDialogOpen, setAmmDocumentDialogOpen] = useState(false);

  const isSuperType =
    clientAccount.accountTypeId === ClientAccountType.Individual.id &&
    clientAccount.subTypeId === ClientAccountSubType.Super.id;
  const isPensionType =
    clientAccount.accountTypeId === ClientAccountType.Individual.id &&
    clientAccount.subTypeId === ClientAccountSubType.Pension.id;
  const allAccountInstitutions = AccountInstitutionType.getAllPendingInstitutionTypes();

  const institutions = externalInstitutions
    .filter((institution) => !!institution.description)
    .map((institution) => ({ id: institution.institutionId, name: institution.description }));

  const initialFormValues: FormValues = {
    ...accountInfo,
    isHinTransfer: !!accountInfo.isHinTransfer,
    externalAccount: accountInfo.externalAccount ?? {
      accountName: '',
      accountNumber: '',
      bsb: '',
      institutionId: null,
    },
    hasExternalAccount: isPensionType && (!!accountInfo.externalAccount?.accountName ?? false),
  };

  useEffect(() => {
    if (isPensionType) {
      fetchExternalInstitutions();
    }
  }, [isPensionType]);

  useEffect(() => {
    if (!!advisor?.afslId) {
      fetchApprovedInstitutionIds({ afslId: advisor.afslId });
    }
  }, [advisor, fetchApprovedInstitutionIds]);

  const handleCloseEditModal = () => {
    setEditInstitutionId(null);
  };

  const handleSaveExistingAccount = async (accountInfo: ExistingAccount) => {
    setExistingAccount(accountInfo);
    setEditInstitutionId(null);
  };

  const handleRemoveExistingAccount = (institutionId: number | null) => {
    if (!!institutionId) {
      removeExistingAccount({ institutionId });
      setEditInstitutionId(null);
    }
  };

  const isNewAccountSelected = (types: AccountInstitutionType[]): boolean => {
    return newAccounts.map((a) => a.institutionId).some((id) => types.map((t) => t.id).includes(id));
  };

  const save = useCallback(
    async (formValues: FormValues) => {
      const isHinTransferEnabled =
        !(isSuperType || isPensionType) &&
        isNewAccountSelected(AccountInstitutionType.getAccountInstitutionsNeedHinTransfer()) &&
        !!formValues.isHinTransfer;

      await saveAccountInfo({
        riskProfile: formValues.riskProfile,
        sourceOfFunds: formValues.sourceOfFunds,
        isHinTransfer: isHinTransferEnabled,
        hin: isHinTransferEnabled ? formValues.hin : '',
        pid: isHinTransferEnabled ? formValues.pid : '',
        externalAccount: isPensionType && formValues.hasExternalAccount ? formValues.externalAccount : null,
      });
    },
    [saveAccountInfo, newAccounts]
  );

  const onBackButtonClick = useCallback(
    async (formValues: FormValues) => {
      const { prevRoutePath } = prevNextRoutePaths;
      if (prevRoutePath) {
        save(formValues).then(() => {
          history.push(prevRoutePath + (!!id ? `?id=${id}` : ''));
        });
      }
    },
    [id, history, prevNextRoutePaths, save]
  );

  return (
    <Grid container>
      <Grid item xs={12}>
        <Formik<FormValues>
          enableReinitialize={true}
          initialValues={initialFormValues}
          onSubmit={async (accountInfoValues: FormValues) => {
            const { nextRoutePath } = prevNextRoutePaths;
            await save(accountInfoValues);
            if (nextRoutePath) {
              history.push(nextRoutePath + (!!id ? `?id=${id}` : ''));
            }
          }}
          validationSchema={yup.object({
            riskProfile: yup.string().nullable(),
            sourceOfFunds: yup.string().nullable(),
            isHinTransfer: yup.boolean(),
            hin:
              !(isSuperType || isPensionType) &&
              isNewAccountSelected(AccountInstitutionType.getAccountInstitutionsNeedHinTransfer())
                ? yup
                    .string()
                    .nullable()
                    .when('isHinTransfer', {
                      is: true,
                      then: yup.string().required('HIN is required'),
                    })
                : yup.string().nullable(),
            pid:
              !(isSuperType || isPensionType) &&
              isNewAccountSelected(AccountInstitutionType.getAccountInstitutionsNeedHinTransfer())
                ? yup
                    .string()
                    .nullable()
                    .when('isHinTransfer', {
                      is: true,
                      then: yup.string().required('PID is required'),
                    })
                : yup.string().nullable(),
            hasExternalAccount: yup.boolean(),
            externalAccount: yup.object().when('hasExternalAccount', {
              is: true,
              then: yup.object({
                accountName: yup.string().required('Account name is required'),
                accountNumber: yup.string().required('Account number is required'),
                bsb: yup
                  .string()
                  .required('BSB number is required')
                  .matches(/^[0-9]{6}$/, 'BSB must be 6 digits'),
                institutionId: yup.number().typeError('Institution is required'),
              }),
            }),
          })}
        >
          {(formikProps: FormikProps<FormValues>) => {
            return (
              <Form>
                <Box paddingBottom="10px" marginBottom="30px">
                  <Typography variant="h7">Which accounts will you be using for this client?</Typography>
                </Box>
                <Box>
                  {!approvedInstitutionProgress.isLoading && !isSuperType && !isPensionType && (
                    <Box width="100%" marginBottom="40px">
                      <Typography variant="h8">Link an already existing account to your client</Typography>
                      <Box display="flex" data-testid="linkExistingAccountBox">
                        {!approvedInstitutionProgress.isLoading &&
                          allAccountInstitutions
                            .filter(
                              (accountInstitution) =>
                                accountInstitution.name !== AccountInstitutionType.ManagedFunds.name &&
                                approvedInstitutionIds.includes(accountInstitution.id)
                            )
                            .map((accountInstitution) => {
                              return (
                                <AccountInstitutionItem
                                  key={`accountExistingInstitutionItem_${accountInstitution.id}`}
                                  accountInstitution={accountInstitution}
                                  isSelected={!!existingAccounts.find((a) => a.institutionId === accountInstitution.id)}
                                  isAvailable={!newAccounts.find((a) => a.institutionId === accountInstitution.id)}
                                  onClickHandler={setEditInstitutionId}
                                />
                              );
                            })}
                        {!!editInstitutionId && (
                          <AccountEditModal
                            institutionId={editInstitutionId}
                            institutionsType={AccountInstitutionType}
                            onSave={handleSaveExistingAccount}
                            onRemove={handleRemoveExistingAccount}
                            handleCloseModal={handleCloseEditModal}
                            existingAccount={existingAccounts.find((a) => a.institutionId === editInstitutionId)}
                          ></AccountEditModal>
                        )}
                        {allAccountInstitutions.filter(
                          (accountInstitution) =>
                            accountInstitution.name !== AccountInstitutionType.ManagedFunds.name &&
                            approvedInstitutionIds.includes(accountInstitution.id)
                        ).length === 0 && (
                          <Typography variant="body1">This afsl has no authorised account institutions</Typography>
                        )}
                      </Box>
                    </Box>
                  )}
                  <Box width="100%" marginBottom="40px">
                    <Typography variant="h8">Select what new accounts you are opening for this client</Typography>
                    <>
                      <Box display="flex" data-testid="selectNewAccountBox">
                        {/* {Non Super Or Pension} */}
                        {!approvedInstitutionProgress.isLoading && !isSuperType && !isPensionType && (
                          <Box display="flex" data-testid="selectNewAccountBox">
                            {allAccountInstitutions
                              .filter((accountInstitution) => approvedInstitutionIds.includes(accountInstitution.id))
                              .map((accountInstitution) => {
                                return (
                                  <AccountInstitutionItem
                                    key={`accountNewInstitutionItem_${accountInstitution.id}`}
                                    accountInstitution={accountInstitution}
                                    isSelected={!!newAccounts.find((a) => a.institutionId === accountInstitution.id)}
                                    isAvailable={
                                      !existingAccounts.find((a) => a.institutionId === accountInstitution.id)
                                    }
                                    onClickHandler={(institutionId: number) => toggleNewAccount({ institutionId })}
                                  />
                                );
                              })}
                          </Box>
                        )}
                        {/* {Super Or Pension } */}
                        {!approvedInstitutionProgress.isLoading && (isSuperType || isPensionType) && (
                          <>
                            <div style={{ width: '100%', display: 'block' }}>
                              <Typography variant="h4">
                                {
                                  'All Super Simplifier clients will have the following DASH custodial accounts created:'
                                }
                              </Typography>
                              <ul style={{ alignItems: 'left' }}>
                                <li>Cash Account</li>
                                <li>Listed Equities ASX Account</li>
                                <li>Managed Funds Account</li>
                              </ul>
                              <Typography variant="h4">
                                Do you also want to open an Australian Money Market Account for this client?
                              </Typography>
                              {allAccountInstitutions
                                .filter((accountInstitution) =>
                                  optionalSuperInstitutions.includes(accountInstitution.id)
                                )
                                .map((accountInstitution) => {
                                  return (
                                    <AccountInstitutionItem
                                      key={`accountNewInstitutionItem_${accountInstitution.id}`}
                                      accountInstitution={accountInstitution}
                                      isSelected={!!newAccounts.find((a) => a.institutionId === accountInstitution.id)}
                                      isAvailable={
                                        !existingAccounts.find((a) => a.institutionId === accountInstitution.id)
                                      }
                                      onClickHandler={(institutionId: number) => toggleNewAccount({ institutionId })}
                                    />
                                  );
                                })}
                            </div>
                          </>
                        )}
                      </Box>
                      <Box minHeight="300px" paddingTop="40px">
                        <Fade in={true} timeout={{ enter: 600 }}>
                          <Box>
                            <fieldset style={{ border: 'none', margin: '0', padding: '0' }}>
                              {!(isSuperType || isPensionType) &&
                                (isNewAccountSelected(AccountInstitutionType.getAccountInstitutionsNeedHinTransfer()) ||
                                  isNewAccountSelected(
                                    AccountInstitutionType.getAccountInstitutionsNeedRiskProfile()
                                  )) && (
                                  <Typography variant="h8">Additional information for account providers</Typography>
                                )}
                              {!(isSuperType || isPensionType) &&
                                isNewAccountSelected(
                                  AccountInstitutionType.getAccountInstitutionsNeedHinTransfer()
                                ) && (
                                  <>
                                    <Grid container>
                                      <Grid item style={{ marginBottom: '30px' }}>
                                        <Field
                                          name="isHinTransfer"
                                          component={FormikSwitch}
                                          label="HIN Transfer (select this if you would like to transfer an existing HIN to the new broker account)"
                                          fullWidth
                                          onChange={(isChecked: boolean) => {
                                            if (!isChecked) {
                                              formikProps.setFieldValue('hin', '');
                                            }
                                          }}
                                        ></Field>
                                      </Grid>
                                      {!!formikProps.values.isHinTransfer && (
                                        <Grid container style={{ marginBottom: '30px' }}>
                                          <Grid item>
                                            <Field
                                              name="hin"
                                              showRequiredAsterisk={true}
                                              component={FormikTextField}
                                              label="HIN"
                                              fullWidth
                                            ></Field>
                                          </Grid>
                                          <Grid item style={{ marginLeft: '15px' }}>
                                            <Field
                                              name="pid"
                                              showRequiredAsterisk={true}
                                              component={FormikTextField}
                                              label="HIN transfer from (PID)"
                                              fullWidth
                                            ></Field>
                                          </Grid>
                                        </Grid>
                                      )}
                                    </Grid>
                                  </>
                                )}
                              <Grid container>
                                <Grid item xs={12}>
                                  <Grid item style={{ marginBottom: '30px', width: '350px' }}>
                                    <Field
                                      name="riskProfile"
                                      component={FormikTextField}
                                      label="RISK PROFILE"
                                      fullWidth
                                    ></Field>
                                  </Grid>
                                  {!(isSuperType || isPensionType) && (
                                    <Grid item style={{ width: '350px', marginBottom: '30px' }}>
                                      <Field
                                        component={FormikEnumerationSelect}
                                        type={SourceOfFunds}
                                        valueIsId={false}
                                        name="sourceOfFunds"
                                        label="SOURCE OF FUNDS"
                                        noneIsEmptyString={true}
                                      />
                                    </Grid>
                                  )}
                                  {isNewAccountSelected(
                                    AccountInstitutionType.getAccountInstitutionsNeedAdditionalDetails()
                                  ) && (
                                    <Grid item>
                                      <Typography variant="body1" style={{ width: '100%' }}>
                                        {
                                          'Please note there are additional requirements for investing with particular financial institutions under specific circumstances. AMM or DASH will contact the adviser at the time of investment if required. Click '
                                        }
                                        <Link
                                          id="AdditionalDetails"
                                          variant="body2"
                                          onClick={() => setAmmDocumentDialogOpen(true)}
                                          style={{ fontFamily: 'inherit', textDecoration: 'underline' }}
                                        >
                                          here
                                        </Link>
                                        {' for additional details.'}
                                      </Typography>
                                      <AccountAmmDocumentDialog
                                        isOpen={ammDocumentDialogOpen}
                                        handleClose={setAmmDocumentDialogOpen}
                                      />
                                    </Grid>
                                  )}
                                  {isPensionType && (
                                    <Box data-testid="externalAccountInfoBox">
                                      <Grid container>
                                        <Box margin="30px 0">
                                          <Typography
                                            variant="h2"
                                            gutterBottom
                                            style={{ fontSize: '18px', fontWeight: '400', lineHeight: 1 }}
                                          >
                                            {"Where will the member's pension payments be paid to?"}
                                          </Typography>

                                          <Grid item xs={12}>
                                            <Field
                                              name="hasExternalAccount"
                                              component={FormikSwitch}
                                              label="Add External Account"
                                            />
                                          </Grid>
                                        </Box>
                                      </Grid>
                                      {formikProps.values.hasExternalAccount && (
                                        <Grid container style={{ marginTop: '30px' }}>
                                          <Grid container spacing={2}>
                                            <Grid item xs={6} style={{ minHeight: '90px' }}>
                                              <Field
                                                name="externalAccount.accountName"
                                                component={FormikTextField}
                                                label="ACCOUNT NAME"
                                                showRequiredAsterisk={true}
                                                fullWidth
                                              />
                                            </Grid>
                                            <Grid item xs={6} style={{ minHeight: '90px' }}>
                                              <Field
                                                fieldName="externalAccount.institutionId"
                                                as={FormikSelect}
                                                itemDisplayNameField="name"
                                                label="INSTITUTION"
                                                data={institutions.length > 0 ? institutions : undefined}
                                                valueIsId={true}
                                                showRequiredAsterisk={true}
                                                onChange={() => {
                                                  return;
                                                }}
                                                fullWidth
                                              />
                                            </Grid>
                                            <Grid item xs={6} style={{ minHeight: '90px' }}>
                                              <Field
                                                name="externalAccount.accountNumber"
                                                component={FormikTextField}
                                                label="ACCOUNT NUMBER"
                                                showRequiredAsterisk={true}
                                                fullWidth
                                              />
                                            </Grid>
                                            <Grid item xs={6} style={{ minHeight: '90px' }}>
                                              <Field name="externalAccount.bsb" label="BSB" fullWidth>
                                                {(fieldProps: FieldProps) => {
                                                  return (
                                                    <FormikNumberFormat
                                                      formikFieldProps={fieldProps}
                                                      numberFormatProps={{
                                                        format: '###-###',
                                                        mask: '_',
                                                        placeholder: '',
                                                        name: fieldProps.field.name,
                                                        label: 'BSB NUMBER',
                                                        isNumericString: true,
                                                      }}
                                                      showRequiredAsterisk={true}
                                                      fullWidth={true}
                                                    />
                                                  );
                                                }}
                                              </Field>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      )}
                                    </Box>
                                  )}
                                </Grid>
                              </Grid>
                            </fieldset>
                          </Box>
                        </Fade>
                      </Box>
                    </>
                  </Box>
                </Box>
                <PageBackNextButtons<FormValues>
                  onBackButtonClick={() => onBackButtonClick(formikProps.values)}
                  onNextButtonClick={() => formikProps.submitForm()}
                  onQuitButtonClick={async () => {
                    await formikProps.submitForm();
                    history.push('/client/list?mode=onboard');
                  }}
                  progress={saveProgress}
                  formikProps={formikProps}
                />
              </Form>
            );
          }}
        </Formik>
      </Grid>
    </Grid>
  );
};
