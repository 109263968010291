import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InvestmentService } from '../../../store/common';
import { fetchBenchmarks, fetchInvestmentService } from './thunks';
import { BenchmarkItem, DetailsState } from './types';

export const initialState: DetailsState = {
  detail: null,
  versions: [],
  benchmarks: [],
};

export const detailsSlice = createSlice({
  name: '@@investmentServices/detail',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchInvestmentService.fulfilled, (state, action: PayloadAction<InvestmentService>) => {
      state.detail = action.payload;
    });
    builder.addCase(fetchBenchmarks.fulfilled, (state, action: PayloadAction<BenchmarkItem[]>) => {
      state.benchmarks = action.payload;
    });
  },
});
