import { TableCell, TableRow, Typography } from '@mui/material';
import React from 'react';
import { formatDollars } from '../../../../../common';
import { Trade, TradeAction } from '../store/types';
import { ExpiryType, PriceType } from '../../edit/store/types';

interface Props {
  trade: Trade;
  index: number;
  hasRebalanceEnhancedTrading: boolean;
  hasClientEditAdminOnlyPermission: boolean;
}

export default function PreapprovedTrade(props: Props): JSX.Element {
  const { trade, index, hasRebalanceEnhancedTrading, hasClientEditAdminOnlyPermission } = props;

  const priceType = trade.priceType ? trade.priceType : 'Market';
  let expiryType = 'Good For Day';

  switch (trade.expiryType) {
    case ExpiryType.GTC:
      expiryType = 'Good Till Cancelled';
      break;
    case ExpiryType.GTD:
      expiryType = 'Good Till Date';
      break;
    case ExpiryType.GFD:
      expiryType = 'Good For Day';
      break;
    default:
      expiryType = 'Good For Day';
      break;
  }

  const price = !trade.priceType || trade.priceType === PriceType.Market ? formatDollars(trade.price) : formatDollars(trade.priceLimit);

  return (
    <TableRow key={index}>
      <TableCell>
        <Typography variant="h5" color={'primary'}>
          {trade.clientName}
        </Typography>
        <Typography variant="h5">{trade.portfolioName}</Typography>
      </TableCell>
      <TableCell align="left">
        <Typography variant="h5">{Intl.NumberFormat('en-AU').format(trade.units)}</Typography>
        <Typography variant="h6" color={'textSecondary'}>
          @ {price}
        </Typography>
      </TableCell>
      <TableCell align="center">
        {TradeAction.Buy.name === trade.action ? (
          <Typography variant="h5" color="secondary">
            BUY
          </Typography>
        ) : (
          <Typography variant="h5" color="error">
            SELL
          </Typography>
        )}
      </TableCell>
      {(hasRebalanceEnhancedTrading || hasClientEditAdminOnlyPermission) && (
        <TableCell>
          <Typography variant="h5">{priceType}</Typography>
        </TableCell>
      )}
      {(hasRebalanceEnhancedTrading || hasClientEditAdminOnlyPermission) && (
        <TableCell>
          <Typography variant="h5">{expiryType}</Typography>
        </TableCell>
      )}
      {(hasRebalanceEnhancedTrading || hasClientEditAdminOnlyPermission) && (
        <TableCell>
          <Typography variant="h5">{trade.expiryDate}</Typography>
        </TableCell>
      )}
      <TableCell align="right">
        <Typography variant="h5">
          {(trade.action === TradeAction.Sell.name || trade.action === TradeAction.SellAll.name) && trade.total ? '-' : ''}
          {formatDollars(trade.total)}
        </Typography>
      </TableCell>
    </TableRow>
  );
}
