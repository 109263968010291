import { ToggleButton as MuiToggleButton, Tooltip as MuiTooltip } from '@mui/material';
import React from 'react';

export interface ToggleButtonItem<T> {
  disabled?: boolean;
  name: string;
  value: T;
  className?: string;
  color?: string;
  onClick?: (value: T) => void;
  tooltip?: string;
  childNode?: React.ReactNode;
  selected?: boolean;
  fullWidth?: boolean;
}

interface Props<T> extends ToggleButtonItem<T> {
  fullFlexBasis: boolean;
  onChangeHandler?: (value: T) => void;
  selected: boolean;
}

export const WO2ToggleButton = <T extends string | number | boolean>({
  value,
  className,
  fullFlexBasis,
  disabled,
  selected,
  color,
  onClick,
  tooltip,
  onChangeHandler,
  childNode,
  name,
}: Props<T>): JSX.Element => {
  const component: JSX.Element = (
    <MuiToggleButton
      className={`${className} ${fullFlexBasis ? 'Mui-fullFlexBasis' : ''}`}
      disabled={disabled}
      disableFocusRipple={true}
      disableRipple={true}
      value={value}
      selected={selected}
      color={
        color &&
        (color === 'standard' ||
          color === 'primary' ||
          color === 'secondary' ||
          color === 'error' ||
          color === 'info' ||
          color === 'success' ||
          color === 'warning')
          ? color
          : undefined
      }
      onClick={() => {
        if (!!onClick) {
          onClick(value);
        } else if (onChangeHandler) {
          onChangeHandler(value);
        }
      }}
    >
      {name}
      {childNode}
    </MuiToggleButton>
  );

  return tooltip ? (
    <MuiTooltip title={tooltip} arrow>
      {component}
    </MuiTooltip>
  ) : (
    component
  );
};
