import api from '../../../../../app/api';
import { PreApprovalRebalance, ValidationResult } from './types';

export async function submitRebalanceService(preApprovalRebalance: PreApprovalRebalance): Promise<ValidationResult> {
  const payload = {
    rebalanceId: preApprovalRebalance.rebalanceId,
    investmentServiceId: preApprovalRebalance.investmentServiceId,
    isClientApprovalRequired: preApprovalRebalance.isClientApprovalRequired,
    comment: preApprovalRebalance.comments,
    checkoutTradeItems: preApprovalRebalance.preApprovalTradeItems,
  };

  const response = await api.post('rebalancesmanual/submitmanualrebalance', payload);
  return Promise.resolve<ValidationResult>(response.data);
}
