import { FormControl, InputLabel, MenuItem } from '@mui/material';
import _ from 'lodash';
import { default as React, useEffect, useRef } from 'react';
import { ClientPortfolio } from '../store/types';
import { WO2Select } from './../../../../../common';

interface Props {
  items: ClientPortfolio[];
  selectedPortfolio: ClientPortfolio | null;
  setPortfolio: (clientPortfolio: ClientPortfolio) => void;
}

function PortfolioSelect(props: Props): JSX.Element {
  const { selectedPortfolio, items, setPortfolio } = props;
  const inputLabel: React.MutableRefObject<HTMLLabelElement | null> = useRef(null);
  const portfolios = items || [];

  useEffect(() => {
    if (!selectedPortfolio && items) {
      setPortfolio(items[0]);
    }
  }, [selectedPortfolio, items, setPortfolio]);

  function handleSetPortfolio(id: number | null) {
    const portfolio = _.find(portfolios, { investmentProgramId: id }) ?? _.find(portfolios, { investmentProgramId: null });
    if (portfolio) {
      setPortfolio(portfolio);
    }
  }

  return (
    <FormControl variant="outlined" style={{ width: '260px' }}>
      <InputLabel ref={inputLabel} id="portfolio-select-label">
        Portfolio
      </InputLabel>
      <WO2Select
        labelId="portfolio-select-label"
        id="portfolio-select"
        value={portfolios && portfolios.length > 0 ? selectedPortfolio?.investmentProgramId || '-1' : ''}
        onChange={(event) => handleSetPortfolio(Number(event.target.value))}
        label={'Portfolio'}
      >
        {portfolios &&
          portfolios.map((item) => (
            <MenuItem key={item.investmentProgramId ?? 0} value={item.investmentProgramId || '-1'}>
              {item.investmentServiceName}
            </MenuItem>
          ))}
      </WO2Select>
    </FormControl>
  );
}

export default PortfolioSelect;
