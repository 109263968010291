import { Paper, TableCell, TableRow, Typography } from '@mui/material';
import React from 'react';
import { allValuesEqual, formatDollars, formatPercentage, uuidv4 } from '../../../../../common';
import { ClientSideDataTable } from '../../../../../common/components/dataTable/clientSide';
import { DatatableColumn } from '../../../../../common/components/dataTable/types';
import { LoadingProgress } from '../../../../../common/store/types';
import { PortfolioSecurityPerformanceTableGroupItem, PortfolioSecurityPerformanceTableResult } from '../store/types';

export interface SecurityPerformanceTableProps {
  portfolioSecurityPerformanceProgress: LoadingProgress;
  portfolioSecurityPerformanceResults?: PortfolioSecurityPerformanceTableResult;
  rowsExpanded?: number[];
}

export const SecurityPerformanceTable = (props: SecurityPerformanceTableProps): JSX.Element => {
  const { portfolioSecurityPerformanceResults, portfolioSecurityPerformanceProgress } = props;

  const assetColumn = (dataIndex: number): React.ReactNode => {
    return (
      <Typography variant="h5" color={'primary'}>
        {portfolioSecurityPerformanceResults?.groupItems[dataIndex].groupName}
      </Typography>
    );
  };

  const openColumn = (dataIndex: number): React.ReactNode => {
    return (
      <Typography variant="h5">
        {formatDollars(portfolioSecurityPerformanceResults?.groupItems[dataIndex].openingBalance)}
      </Typography>
    );
  };

  const aquisitionsColumn = (dataIndex: number): React.ReactNode => {
    return (
      <Typography variant="h5">
        {formatDollars(portfolioSecurityPerformanceResults?.groupItems[dataIndex].aquisitions)}
      </Typography>
    );
  };

  const disposalsColumn = (dataIndex: number): React.ReactNode => {
    return (
      <Typography variant="h5">
        {formatDollars(portfolioSecurityPerformanceResults?.groupItems[dataIndex].disposals)}
      </Typography>
    );
  };

  const capitalMovementColumn = (dataIndex: number): React.ReactNode => {
    return (
      <Typography variant="h5">
        {formatDollars(portfolioSecurityPerformanceResults?.groupItems[dataIndex].capitalMovement)}
      </Typography>
    );
  };

  const otherColumn = (dataIndex: number): React.ReactNode => {
    return (
      <Typography variant="h5">
        {formatDollars(portfolioSecurityPerformanceResults?.groupItems[dataIndex].other)}
      </Typography>
    );
  };

  const closingBalanceColumn = (dataIndex: number): React.ReactNode => {
    return (
      <Typography variant="h5">
        {formatDollars(portfolioSecurityPerformanceResults?.groupItems[dataIndex].closingBalance)}
      </Typography>
    );
  };

  const growthColumn = (dataIndex: number): React.ReactNode => {
    return (
      <>
        <Typography
          variant="h5"
          align="right"
          color={
            !portfolioSecurityPerformanceResults?.groupItems[dataIndex].growth
              ? 'textPrimary'
              : portfolioSecurityPerformanceResults?.groupItems[dataIndex].growth < 0
              ? 'error'
              : 'secondary'
          }
        >
          {formatDollars(portfolioSecurityPerformanceResults?.groupItems[dataIndex].growth)}
        </Typography>
        <Typography variant="h6" align="right" color={'textSecondary'}>
          {formatPercentage(portfolioSecurityPerformanceResults?.groupItems[dataIndex].growthReturn)}
        </Typography>
      </>
    );
  };

  const incomeColumn = (dataIndex: number): React.ReactNode => {
    return (
      <>
        <Typography
          variant="h5"
          align="right"
          color={
            !portfolioSecurityPerformanceResults?.groupItems[dataIndex].income
              ? 'textPrimary'
              : portfolioSecurityPerformanceResults?.groupItems[dataIndex].income < 0
              ? 'error'
              : 'secondary'
          }
        >
          {formatDollars(portfolioSecurityPerformanceResults?.groupItems[dataIndex].income)}
        </Typography>
        <Typography variant="h6" align="right" color={'textSecondary'}>
          {formatPercentage(portfolioSecurityPerformanceResults?.groupItems[dataIndex].incomeReturn)}
        </Typography>
      </>
    );
  };

  const totalColumn = (dataIndex: number): React.ReactNode => {
    return (
      <>
        <Typography
          variant="h5"
          align="right"
          color={
            !portfolioSecurityPerformanceResults?.groupItems[dataIndex].total
              ? 'textPrimary'
              : portfolioSecurityPerformanceResults?.groupItems[dataIndex].total < 0
              ? 'error'
              : 'secondary'
          }
        >
          {formatDollars(portfolioSecurityPerformanceResults?.groupItems[dataIndex].total)}
        </Typography>
        <Typography variant="h6" align="right" color={'textSecondary'}>
          {formatPercentage(portfolioSecurityPerformanceResults?.groupItems[dataIndex].totalReturn)}
        </Typography>
      </>
    );
  };

  const detailedColumn = (
    rowData: string[],
    rowMeta: {
      dataIndex: number;
      rowIndex: number;
    }
  ): React.ReactNode => {
    const rootGroupItem = portfolioSecurityPerformanceResults?.groupItems[rowMeta.dataIndex];
    if (allValuesEqual<PortfolioSecurityPerformanceTableGroupItem, number>('number', 0, rootGroupItem)) {
      return <></>;
    }
    return (
      <>
        {rootGroupItem?.children
          .filter((item) => !allValuesEqual<PortfolioSecurityPerformanceTableGroupItem, number>('number', 0, item))
          .map((item) => (
            <TableRow key={uuidv4()}>
              <TableCell style={{ padding: '0' }} />
              <TableCell>
                <>
                  <Typography variant="h5" color={'primary'} noWrap style={{ minWidth: '119px' }}>
                    {item.securityCode.substring(0, item.securityCode.indexOf('.'))}
                  </Typography>
                  <Typography variant="h6" color={'textSecondary'} noWrap style={{ minWidth: '119px' }}>
                    {item.securityName}
                  </Typography>
                </>
              </TableCell>
              <TableCell>
                <Typography variant="h5" align={'right'}>
                  {formatDollars(item.openingBalance)}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h5" align={'right'}>
                  {formatDollars(item.aquisitions)}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h5" align={'right'}>
                  {formatDollars(item.disposals)}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h5" align={'right'}>
                  {formatDollars(item.capitalMovement)}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h5" align={'right'}>
                  {formatDollars(item.other)}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h5" align={'right'}>
                  {formatDollars(item.closingBalance)}
                </Typography>
              </TableCell>
              <TableCell>
                <>
                  <Typography
                    variant="h5"
                    align="right"
                    color={!item?.growth ? 'textPrimary' : item?.growth < 0 ? 'error' : 'secondary'}
                  >
                    {formatDollars(item.growth)}
                  </Typography>
                  <Typography variant="h6" align="right" color={'textSecondary'}>
                    {formatPercentage(item.growthReturn)}
                  </Typography>
                </>
              </TableCell>
              <TableCell>
                <>
                  <Typography
                    variant="h5"
                    align="right"
                    color={!item?.income ? 'textPrimary' : item?.income < 0 ? 'error' : 'secondary'}
                  >
                    {formatDollars(item.income)}
                  </Typography>
                  <Typography variant="h6" align="right" color={'textSecondary'}>
                    {formatPercentage(item.incomeReturn)}
                  </Typography>
                </>
              </TableCell>
              <TableCell>
                <>
                  <Typography
                    variant="h5"
                    align="right"
                    color={!item.total ? 'textPrimary' : item.total < 0 ? 'error' : 'secondary'}
                  >
                    {formatDollars(item.total)}
                  </Typography>
                  <Typography variant="h6" align="right" color={'textSecondary'}>
                    {formatPercentage(item.totalReturn)}
                  </Typography>
                </>
              </TableCell>
            </TableRow>
          ))}
      </>
    );
  };

  const columns: DatatableColumn[] = [
    {
      name: 'ASSETCLASSSECURITY',
      label: 'ASSET CLASS & SECURITY',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex: number): React.ReactNode => assetColumn(dataIndex),
      },
    },
    {
      name: 'OPENINGVALUE',
      label: 'OPENING VALUE',
      textAlign: 'right',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex: number): React.ReactNode => openColumn(dataIndex),
        setCellHeaderProps: () => {
          return {
            style: { textAlign: 'right' },
          };
        },
      },
    },
    {
      name: 'PURCHASES',
      label: 'PURCHASES',
      textAlign: 'right',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex: number): React.ReactNode => aquisitionsColumn(dataIndex),
        setCellHeaderProps: () => {
          return {
            style: { textAlign: 'right' },
          };
        },
      },
    },
    {
      name: 'SALES',
      label: 'SALES',
      textAlign: 'right',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex: number): React.ReactNode => disposalsColumn(dataIndex),
        setCellHeaderProps: () => {
          return {
            style: { textAlign: 'right' },
          };
        },
      },
    },
    {
      name: 'TRANSFERSCORPACTIONS',
      label: 'TRANSFERS & CORP ACTIONS',
      textAlign: 'right',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex: number): React.ReactNode => capitalMovementColumn(dataIndex),
        setCellHeaderProps: () => {
          return {
            style: { textAlign: 'right' },
          };
        },
      },
    },
    {
      name: 'OTHER',
      label: 'OTHER',
      textAlign: 'right',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex: number): React.ReactNode => otherColumn(dataIndex),
        setCellHeaderProps: () => {
          return {
            style: { textAlign: 'right' },
          };
        },
      },
    },
    {
      name: 'CLOSINGVALUE',
      label: 'CLOSING VALUE',
      textAlign: 'right',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex: number): React.ReactNode => closingBalanceColumn(dataIndex),
        setCellHeaderProps: () => {
          return {
            style: { textAlign: 'right' },
          };
        },
      },
    },
    {
      name: 'CAPITALGAIN/LOSSES',
      label: 'CAPITAL GAIN/LOSSES',
      textAlign: 'right',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex: number): React.ReactNode => growthColumn(dataIndex),
        setCellHeaderProps: () => {
          return {
            style: { textAlign: 'right' },
          };
        },
      },
    },
    {
      name: 'INCOME',
      label: 'INCOME',
      textAlign: 'right',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex: number): React.ReactNode => incomeColumn(dataIndex),
        setCellHeaderProps: () => {
          return {
            style: { textAlign: 'right' },
          };
        },
      },
    },
    {
      name: 'TOTAL RETURN',
      label: 'TOTAL RETURN',
      textAlign: 'right',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex: number): React.ReactNode => totalColumn(dataIndex),
        setCellHeaderProps: () => {
          return {
            style: { textAlign: 'right' },
          };
        },
      },
    },
  ];

  return (
    <Paper elevation={0} style={{ marginBottom: '40px' }}>
      <ClientSideDataTable
        loadingProgress={portfolioSecurityPerformanceProgress}
        columns={columns}
        data={portfolioSecurityPerformanceResults?.groupItems ?? []}
        options={{
          filter: false,
          expandableRows: true,
          expandableRowsOnClick: true,
          rowsExpanded: props.rowsExpanded,
          renderExpandableRow: (rowData, rowMeta) => detailedColumn(rowData, rowMeta),
        }}
        id="client-performance-datatable"
      ></ClientSideDataTable>
    </Paper>
  );
};
