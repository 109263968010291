import { GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid-pro';
import React from 'react';
import NumberFormat, { NumberFormatValues } from 'react-number-format';

export function AllocationInput(props: GridRenderEditCellParams) {
  const { id, value: valueProp, field } = props;
  const [value, setValue] = React.useState(valueProp);
  const apiRef = useGridApiContext();

  const handleValueChange = (values: NumberFormatValues) => {
    if (values.value !== Number(value).toFixed(2)) {
      apiRef.current.setEditCellValue({ id, field, value: values.value, debounceMs: 200 });
      setValue(values.value);
    }
  };

  React.useEffect(() => {
    setValue(valueProp);
  }, [valueProp]);

  return (
    <NumberFormat
      value={value}
      style={{
        width: '100%',
        height: '100%',
        borderRadius: '4px',
        textAlign: 'right',
        paddingRight: '10px',
        borderStyle: 'solid',
        borderColor: 'lightgray',
        borderWidth: '1px',
      }}
      isAllowed={(params: NumberFormatValues) => {
        return params.floatValue !== undefined && params.floatValue <= 100;
      }}
      decimalScale={2}
      fixedDecimalScale={true}
      decimalSeparator="."
      allowNegative={false}
      onValueChange={handleValueChange}
    />
  );
}
