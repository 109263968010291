import { Modal } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { default as React } from 'react';
import { ReportToSort } from 'src/features/clients/client/reports/store/types';
import ItemOrderingModal from './ItemOrderingModal';

interface Props {
  itemsToSort: ReportToSort[];
  isOpen: boolean;
  setSelectedReport: (sortedItems: ReportToSort[]) => void;
  generateReport: () => void;
  setShowItemOrderingModal: (showModal: boolean) => void;
}

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export default function ItemOrdering(props: Props): JSX.Element {
  const classes = useStyles();
  const { itemsToSort, isOpen, setSelectedReport, generateReport, setShowItemOrderingModal } = props;

  const handleCloseModal = () => {
    setShowItemOrderingModal(!isOpen);
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={isOpen}
        onClose={handleCloseModal}
        closeAfterTransition
      >
        <div>{<ItemOrderingModal itemsToSort={itemsToSort} continueReportProcess={generateReport} setReportOrder={setSelectedReport} />}</div>
      </Modal>
    </div>
  );
}
