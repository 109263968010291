import { combineReducers } from '@reduxjs/toolkit';
import { clientRootReducer, ClientState } from '../client/store/reducer';
import { clientListSlice } from '../list/store/slice';
import { ClientListState } from '../list/store/types';
import { onboardSlice } from '../onboard/store/slice';
import { OnboardState } from '../onboard/store/types';

export enum ClientReducerActionTypes {
  ResetClientState = '@@client/ResetClientState',
}

export interface ClientsState {
  list: ClientListState;
  client: ClientState;
  onboard: OnboardState;
}

export const clientsReducer = combineReducers<ClientsState>({
  client: clientRootReducer,
  list: clientListSlice.reducer,
  onboard: onboardSlice.reducer,
});
