import { connect, ConnectedProps } from 'react-redux';
import { hasErrorsSelectorFactory, isLoadingSelectorFactory } from '../../../../../common';
import { RootState } from '../../../../../reducers';
import { selectClientId } from '../../common/store/selectors';
import { InvestmentServices } from './components/investmentServices';
import { selectInvestmentServices } from './store/selectors';
import { investmentServicesSlice } from './store/slice';
import { fetchInvestmentServices, InvestmentServicesActionTypes } from './store/thunks';

const actionTypes = [InvestmentServicesActionTypes.FetchInvestmentServices];

const mapStateToProps = (state: RootState) => ({
  clientId: selectClientId(state),
  investmentServices: selectInvestmentServices(state),
  isLoading: isLoadingSelectorFactory(actionTypes)(state),
  hasErrors: hasErrorsSelectorFactory(actionTypes)(state),
});

const mapDispatchToProps = {
  ...investmentServicesSlice.actions,
  fetchInvestmentServices,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector>;
export const InvestmentServicesContainer = connector(InvestmentServices);
