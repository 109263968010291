import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Collapse, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useRouteMatch } from 'react-router-dom';
import { ClientAccountSubType, ClientAccountType } from '../../../../../../common/types';
import { MenuRoute } from '../../../../../clients/common/types';
import { detailsRoutes } from '../../detailsRoutes';
import { TrusteeType } from '../../mainDetails/store';
import { superSimplifierRoutes } from '../../superSimplifier/superSimplifierRoutes';

export interface Props {
  accountTypeId: number;
  accountSubTypeId: number | null;
  isSuperSimplifierClient: boolean;
  trusteeTypeId: number | null;
  hasClientEditAdminOnlyPermission: boolean;
}

export const LeftMenu = (props: Props): JSX.Element => {
  const { accountTypeId, accountSubTypeId, trusteeTypeId, isSuperSimplifierClient, hasClientEditAdminOnlyPermission } =
    props;
  const { pathname } = useLocation();
  const { url } = useRouteMatch();

  const [routes, setRoutes] = useState<MenuRoute[]>([]);

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const createListItem = (menuItem: MenuRoute, subString = '') => {
    const toUrl = !!subString ? `${url}/${subString}/${menuItem.path}` : `${url}/${menuItem.path}`;
    return (
      <ListItem
        className={'LeftMenu'}
        selected={pathname.endsWith(toUrl)}
        data-testid={toUrl}
        disableRipple
        key={menuItem.menuIndex}
        button
        style={{ paddingLeft: '30px', paddingRight: '25px' }}
        component={Link}
        to={toUrl}
      >
        <ListItemIcon>{!!menuItem.icon && <menuItem.icon></menuItem.icon>}</ListItemIcon>
        <ListItemText primary={menuItem.label} primaryTypographyProps={{ color: 'textSecondary', variant: 'h5' }} />
      </ListItem>
    );
  };

  const filterSuperSimplifierSubMenu = (
    menuItems: MenuRoute[],
    accountType: ClientAccountType | undefined,
    accountSubType: ClientAccountSubType | undefined,
    trusteeType: TrusteeType | undefined
  ) => {
    const index = menuItems.findIndex((menu) => menu.label === 'Super Simplifier' && menu.path === 'superSimplifier');
    if (index === -1) return menuItems;

    menuItems[index].subMenuComponents = superSimplifierRoutes?.filter((subComp) =>
      subComp.visible(accountType, accountSubType, trusteeType)
    );
    return menuItems;
  };

  useEffect(() => {
    const accountType: ClientAccountType | undefined = !!accountTypeId
      ? ClientAccountType.getById(accountTypeId) || undefined
      : undefined;
    const accountSubType: ClientAccountSubType | undefined = !!accountSubTypeId
      ? ClientAccountSubType.getById(accountSubTypeId) || undefined
      : undefined;
    const trusteeType: TrusteeType | undefined = !!trusteeTypeId
      ? TrusteeType.getById(trusteeTypeId) || undefined
      : undefined;

    let filteredRoutes = isSuperSimplifierClient
      ? detailsRoutes.filter((route) => route.path !== 'addresses')
      : detailsRoutes;

    filteredRoutes = isSuperSimplifierClient
      ? filterSuperSimplifierSubMenu(filteredRoutes, accountType, accountSubType, trusteeType)
      : filteredRoutes;

    setRoutes(
      filteredRoutes.filter((r) =>
        r.visible(accountType, accountSubType, trusteeType, hasClientEditAdminOnlyPermission)
      )
    );
  }, [accountTypeId, accountSubTypeId, isSuperSimplifierClient, trusteeTypeId, hasClientEditAdminOnlyPermission]);

  useEffect(() => {
    routes.forEach((rootRoute) => {
      if (rootRoute.subMenuComponents) {
        for (const nestedRoute of rootRoute.subMenuComponents) {
          if (pathname.endsWith(nestedRoute.path ?? '*')) {
            setOpen(true);
            break;
          }
        }
      }
    });
  });

  return (
    <List>
      {routes.map((r: MenuRoute) => (
        <div key={r.menuIndex}>
          {!!r.subMenuComponents ? (
            <>
              <ListItemButton style={{ padding: '0' }} onClick={handleClick}>
                <ListItem
                  data-testid={`${r.path}ListItemInput`}
                  className={'LeftMenu'}
                  disableRipple
                  button
                  style={{ paddingLeft: '30px', paddingRight: '25px' }}
                  component={Link}
                  to={`${url}/${r.path}`}
                >
                  <ListItemIcon>{!!r.icon && <r.icon></r.icon>}</ListItemIcon>
                  <ListItemText primary={r.label} primaryTypographyProps={{ color: 'textSecondary', variant: 'h5' }} />
                </ListItem>
                {open ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <List>
                  {r.subMenuComponents.map((subMenu: MenuRoute) => (
                    <div key={subMenu.menuIndex} style={{ paddingLeft: '15px' }}>
                      {createListItem(subMenu, r.path)}
                    </div>
                  ))}
                </List>
              </Collapse>
            </>
          ) : (
            createListItem(r)
          )}
        </div>
      ))}
    </List>
  );
};
