/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PagedRequest } from '../../../../store';
import { AfslItem } from './../../../bulk/common/store/types';
import { OnboardingWizardStatus } from './enum';
import { fetchAfsls, fetchClientList, fetchClientsSummary, fetchOnboardingWizardsModels, resetStatus } from './thunks';
import { ClientItem, ClientListState, ClientsSummary, fetchPagedResults, OnboardingWizardsModel, ResetStatusPayload } from './types';

export const initialState: ClientListState = {
  parameters: {
    clientNameSearch: '',
    pagination: {
      pageNumber: 1,
      pageSize: 25,
      queryFields: [{ fieldName: 'accountName', descendingSortDirection: false, hasSearchTerm: false, isSortTerm: true, searchTerm: '', operator: '' }],
    },
  },
  totals: null,
  clients: null,
  onboardings: [],
  quickOnboarding: {
    client: {
      entityName: '',
      entityType: 1,
      adviserId: 0,
      accountTypeId: 0,
      accountSubTypeId: 0,
      corporationTypeId: null,
      typeOfTrustId: null,
      trusteeTypeId: null,
      pensionTypeId: null,
    },
    afsls: [],
  },
};

export const clientListSlice = createSlice({
  name: '@@client/list',
  initialState,
  reducers: {
    setSearchStringParameter: (state, action: PayloadAction<string | null>) => {
      state.parameters.clientNameSearch = action.payload;
    },
    setPaginationParameter: (state, action: PayloadAction<PagedRequest>) => {
      state.parameters.pagination = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAfsls.fulfilled, (state, action: PayloadAction<AfslItem[]>) => {
      state.quickOnboarding.afsls = action.payload;
    });
    builder.addCase(fetchClientsSummary.fulfilled, (state, action: PayloadAction<ClientsSummary>) => {
      state.totals = action.payload;
    });
    builder.addCase(fetchClientList.fulfilled, (state, action: PayloadAction<fetchPagedResults<ClientItem>>) => {
      state.clients = action.payload.results;
      state.parameters.pagination = action.payload.pagination;
    });
    builder.addCase(fetchOnboardingWizardsModels.fulfilled, (state, action: PayloadAction<OnboardingWizardsModel[]>) => {
      state.onboardings = action.payload;
    });
    builder.addCase(resetStatus.fulfilled, (state, action: PayloadAction<ResetStatusPayload>) => {
      const index = state.onboardings.findIndex((o) => o.id === action.payload.id);
      if (index >= 0) {
        state.onboardings[index].statusId = OnboardingWizardStatus.Active.id;
      }
    });
  },
});
