import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchStrategyDashboard } from './thunks';
import { StrategyCommonState, StrategyDashboard } from './types';

export const initialState: StrategyCommonState = {
  strategyDashboard: null,
};

export const strategyCommonSlice = createSlice({
  name: '@@strategy/common',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchStrategyDashboard.fulfilled, (state, action: PayloadAction<StrategyDashboard>) => {
      state.strategyDashboard = action.payload;
    });
  },
});
