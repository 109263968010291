import { Tooltip, Typography } from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import React from 'react';

interface Props {
  className?: string | null;
  value: string | null;
  hoverValue?: string;
}

const LargeTooltip = withStyles(() => ({
  tooltip: {
    fontSize: 14,
  },
}))(Tooltip);

const useStyles = makeStyles(() => ({
  truncatableText: {
    overflow: 'hidden',
  },
}));

export const TruncatableText = (props: Props): JSX.Element => {
  const classes = useStyles();
  const { value, hoverValue } = props;

  const className = props.className ? `${classes.truncatableText} ${props.className}` : classes.truncatableText;

  return (
    <LargeTooltip id="truncatable-text-tooltip" title={hoverValue ?? value ?? ''} arrow>
      <Typography noWrap className={className}>
        {value}
      </Typography>
    </LargeTooltip>
  );
};
