import { SnackbarKey, useSnackbar } from '@dash/notistack';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentMessages } from '../../common';
import { dismissMessage } from '../../common/store/reducers/actions';
import { Message } from '../../common/store/reducers/types';
import { RootState } from '../../store';

let displayed: number[] = [];

export const Notifier = (): JSX.Element => {
  const dispatch = useDispatch();

  const messages = useSelector((store: RootState) => selectCurrentMessages(store));

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const storeDisplayed = (id: number) => {
    displayed = [...displayed, id];
  };

  const removeDisplayed = (id: number) => {
    displayed = [...displayed.filter((key) => id !== key)];
  };

  useEffect(() => {
    messages.forEach((value) => {
      if (value[1] === null) {
        return;
      }
      const message: Message = value[1];

      if (message.dismissed) {
        // dismiss snackbar using notistack
        closeSnackbar(message.key);
        return;
      }

      // do nothing if snackbar is already displayed
      if (displayed.includes(message.key)) return;

      // display snackbar using notistack
      enqueueSnackbar(message.message, {
        key: message.key,
        variant: message.variant,
        autoHideDuration: 3000,
        onExited: (node: HTMLElement, myKey: SnackbarKey) => {
          dispatch(dismissMessage(myKey as number));
          removeDisplayed(myKey as number);
        },
        onClick: () => {
          closeSnackbar(message.key);
        },
      });

      // keep track of snackbars that we've displayed
      storeDisplayed(message.key);
    });
  }, [messages, closeSnackbar, dispatch, enqueueSnackbar]);

  return <></>;
};
