import { createSelector } from '@reduxjs/toolkit';
import { selectModel } from '../../store/selectors';

export const selectModelPerformanceState = createSelector(selectModel, (modelState) => modelState.modelPerformance);
export const selectParameters = createSelector(selectModelPerformanceState, (performance) => performance.parameters);
export const selectModelPeriodicSummary = createSelector(selectModelPerformanceState, (PerformanceState) => PerformanceState.modelPeriodicSummary);
export const selectModelDetail = createSelector(selectModelPerformanceState, (state) => state.modelDetail);
export const selectModelPerformancechartWithBenchmark = createSelector(
  selectModelPerformanceState,
  (PerformanceState) => PerformanceState.modelPerformancechartWithBenchmark
);
export const selectBenchmarks = createSelector(selectParameters, (parameters) => parameters.modelBenchmarks ?? []);
