import { Enumeration } from "src/common/types";

export class PendingBeneficiariesNominationTypeEnum extends Enumeration{
    static NonbindingNonLapsing = new PendingBeneficiariesNominationTypeEnum(0, 'Non-binding (Non-Lapsing)', 'Non-binding (Non-Lapsing)');

    static getById = (id: number | null | undefined): PendingBeneficiariesNominationTypeEnum | undefined => {
        return Object.values(PendingBeneficiariesNominationTypeEnum)
          .filter((t) => !!t['name'])
          .find((t: PendingBeneficiariesNominationTypeEnum) => t.id === id);
      };
    
      static getByName = (name: string): PendingBeneficiariesNominationTypeEnum | undefined => {
        return Object.values(PendingBeneficiariesNominationTypeEnum)
          .filter((t) => t instanceof PendingBeneficiariesNominationTypeEnum)
          .find((t: PendingBeneficiariesNominationTypeEnum) => t.name.toLowerCase() === name.toLowerCase());
      };
    
      static getAll = (): PendingBeneficiariesNominationTypeEnum[] => {
        return Object.values(PendingBeneficiariesNominationTypeEnum)
          .filter((t) => !!t['name'])
          .map((val) => {
            return { id: val.id, name: val.name, displayName: val.displayName };
          });
      };
}