import { Enumeration } from './enumeration';

export class ClientAccountType extends Enumeration {
  static Trust = new ClientAccountType(1, 'Trust', 'Trust');
  static Smsf = new ClientAccountType(2, 'SMSF', 'SMSF');
  static Individual = new ClientAccountType(9, 'Individual', 'Individual');
  static Joint = new ClientAccountType(10, 'Joint', 'Joint');
  static Company = new ClientAccountType(11, 'Company', 'Company');

  static getByName = (name: string): ClientAccountType | undefined => {
    return Object.values(ClientAccountType)
      .filter((t) => t instanceof ClientAccountType)
      .find((t: ClientAccountType) => t.name.toLowerCase() === name.toLowerCase());
  };

  static getById = (id: number | null): ClientAccountType | undefined => {
    return Object.values(ClientAccountType)
      .filter((t) => t instanceof ClientAccountType)
      .find((t: ClientAccountType) => t.id === id);
  };
}
