import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../../app/api';
import { buildEncodedQueryString, convertToCSVFormat, CSVDataType, downloadCsv } from '../../../../common';
import { PagedResult } from '../../../../store';
import {
  DividendDistributionsByPaymentDateItem,
  DividendDistributionsByPaymentDateThunkParameters,
  DownloadDividendDistributionsByPaymentDatePayload,
  SecurityIncomeSummary,
  SecurityIncomeSummaryThunkParameters,
} from './types';

export enum SecurityIncomeActionTypes {
  FetchSecurityIncomeSummary = '@@security/income/FetchSecurityIncomeSummary',
  FetchDividendDistributionsByPaymentDate = '@@security/income/FetchDividendDistributionsByPaymentDate',
  DownloadDividendDistributionsByPaymentDate = '@@security/income/DownloadDividendDistributionsByPaymentDate',
}

export enum SecurityIncomeApiEndpoints {
  fetchSecurityIncomeSummary = '/bff/GetSecurityIncomeSummary',
  fetchDividendDistributionsByPaymentDate = '/corporateactions/GetDividendDistributionsByPaymentDate',
}

export const fetchSecurityIncomeSummary = createAsyncThunk(
  SecurityIncomeActionTypes.FetchSecurityIncomeSummary,
  async (wrapper: SecurityIncomeSummaryThunkParameters) => {
    const response = await api.get<SecurityIncomeSummary>(`${SecurityIncomeApiEndpoints.fetchSecurityIncomeSummary}?securityCode=${wrapper.securityCode}`);
    return response.data;
  }
);

const getPagedDividendDistributionsByPaymentDates = async (request: DividendDistributionsByPaymentDateThunkParameters) => {
  const queryString = buildEncodedQueryString({
    pagedRequest: request.pagination,
    securityCode: request.securityCode,
    fromDate: request.fromDate,
    toDate: request.toDate,
  });
  return await api.get<PagedResult<DividendDistributionsByPaymentDateItem>>(
    `${SecurityIncomeApiEndpoints.fetchDividendDistributionsByPaymentDate}${queryString}`
  );
};

export const fetchDividendDistributionsByPaymentDate = createAsyncThunk(
  SecurityIncomeActionTypes.FetchDividendDistributionsByPaymentDate,
  async (wrapper: DividendDistributionsByPaymentDateThunkParameters) => {
    const response = await getPagedDividendDistributionsByPaymentDates(wrapper);
    return {
      results: response.data,
      pagination: wrapper.pagination,
    };
  }
);

export const downloadDividendDistributionsByPaymentDate = createAsyncThunk(
  SecurityIncomeActionTypes.DownloadDividendDistributionsByPaymentDate,
  async (wrapper: DownloadDividendDistributionsByPaymentDatePayload) => {
    const response = await getPagedDividendDistributionsByPaymentDates({ ...wrapper, pagination: { ...wrapper.pagination, pageNumber: 1, pageSize: 9999999 } });
    const data: CSVDataType[] = convertToCSVFormat(response.data.results);

    downloadCsv(data, `DividendDistributionsByPaymentDate_`);
    return { message: 'CSV downloaded' };
  }
);
