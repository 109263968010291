import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { rebalanceEditSlice } from '../../edit/store/slice';
import { validateRebalance } from '../../edit/store/thunks';
import { ValidateRebalanceSuccessPayload } from '../../store/types';
import { confirmRebalance } from './thunks';
import { PreApprovalRebalance, RebalanceState, ValidationResult } from './types';

const initialState: RebalanceState = {
  rebalance: {} as PreApprovalRebalance,
  submitted: false,
};

export const rebalanceCheckoutSlice = createSlice({
  name: '@@rebalance/checkout',
  initialState,
  reducers: {
    setRebalanceComments: (state: RebalanceState, action: PayloadAction<string>) => {
      state.rebalance.comments = action.payload;
    },
    setClientApprovalRequired: (state: RebalanceState, action: PayloadAction<boolean>) => {
      state.rebalance.isClientApprovalRequired = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(rebalanceEditSlice.actions.setRebalanceValidated, (state, action: PayloadAction<ValidateRebalanceSuccessPayload>) => {
      state.rebalance.clientName = action.payload.rebalanceValidationRequest.clientName;
      state.rebalance.investmentServiceId = action.payload.rebalanceValidationRequest.investmentServiceId;
      state.rebalance.isClientApprovalRequired = action.payload.rebalanceValidationRequest.isClientApprovalRequired;
      state.rebalance.preApprovalTradeItems = action.payload.rebalanceValidationRequest.preApprovalTradeItems;
      state.rebalance.rebalanceId = action.payload.rebalanceValidationRequest.rebalanceId;
      state.rebalance.validationResult = action.payload.validationResult;
    });
    builder.addCase(validateRebalance.fulfilled, (state, action: PayloadAction<ValidateRebalanceSuccessPayload>) => {
      state.rebalance.clientName = action.payload.rebalanceValidationRequest.clientName;
      state.rebalance.investmentServiceId = action.payload.rebalanceValidationRequest.investmentServiceId;
      state.rebalance.isClientApprovalRequired = action.payload.rebalanceValidationRequest.isClientApprovalRequired;
      state.rebalance.preApprovalTradeItems = action.payload.rebalanceValidationRequest.preApprovalTradeItems;
      state.rebalance.rebalanceId = action.payload.rebalanceValidationRequest.rebalanceId;
      state.rebalance.validationResult = action.payload.validationResult;
    });
    builder.addCase(confirmRebalance.rejected, (state, action) => {
      if (action.payload) {
        state.rebalance.validationResult = action.payload;
      }
      state.submitted = false;
    });
    builder.addCase(confirmRebalance.fulfilled, (state, action: PayloadAction<ValidationResult>) => {
      state.rebalance.validationResult = action.payload;
      state.submitted = true;
    });
  },
});
