import { Box, Grid, Paper, TextField, Typography } from '@mui/material';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { formatDollars } from '../../../../../common';
import history from '../../../../../history';
import { theme } from '../../../../../themes';
import { SecurityItemWithPrice } from '../../../common/store/types';
import { InitiateBulkOrderPadRequest, Totals } from '../store/types';
import WO2Button from './../../../../../common/components/button/Button';
import AssetsSummary from './assetsSummary';

interface Props extends RouteComponentProps<void> {
  confirmBulkOrder: (payload: InitiateBulkOrderPadRequest) => void;
  setComments: (payload: string) => void;
  order: InitiateBulkOrderPadRequest;
  comments: string;
  isSubmitted: boolean;
  totals: Totals;
  security: SecurityItemWithPrice | undefined;
  isRebalanceDataSourceTriumph: boolean;
}

export default function Summary(props: Props): JSX.Element {
  const { order, comments, isSubmitted, totals, confirmBulkOrder, setComments, isRebalanceDataSourceTriumph } = props;

  return (
    <Paper elevation={0}>
      <AssetsSummary {...props} />

      <Grid container spacing={2} style={{ paddingTop: '20px', paddingBottom: '20px' }}>
        <Grid item xs={6}>
          <Typography variant={'h3'} align={'center'}>
            <Box fontWeight={700}>{formatDollars(totals.totalBuysCash)}</Box>
          </Typography>
          <Typography variant={'h6'} align={'center'} style={{ textTransform: 'uppercase' }}>
            TOTAL BUYS
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant={'h3'} align={'center'}>
            <Box fontWeight={700}>
              {totals.totalSellsCash ? '-' : ''}
              {formatDollars(totals.totalSellsCash)}
            </Box>
          </Typography>
          <Typography variant={'h6'} align={'center'} style={{ textTransform: 'uppercase' }}>
            TOTAL SELLS
          </Typography>
        </Grid>
      </Grid>

      <div
        style={{
          backgroundColor: theme.palette.secondary.light,
          padding: '20px',
        }}
      >
        <Typography variant={'h3'} align={'center'}>
          {formatDollars(totals.totalOrderSize)}
        </Typography>
        <Typography variant={'h6'} align={'center'}>
          TOTAL ORDER SIZE
        </Typography>
      </div>

      <Box padding={'20px'}>
        <TextField
          id="outlined-multiline-static"
          label="Comments"
          multiline
          rows={4}
          fullWidth
          variant="outlined"
          value={comments}
          onChange={(event) => {
            setComments(event.target.value);
          }}
          name="commentsTextField"
        />

        <Box display={'flex'} justifyContent={'center'} paddingTop={'10px'}>
          <WO2Button
            id="amend_button"
            variant={'outlined'}
            disabled={isSubmitted}
            disableElevation
            onClick={() => {
              !isSubmitted && history.goBack();
            }}
            style={{ marginRight: '20px' }}
          >
            Amend
          </WO2Button>
          <WO2Button
            id="submit_button"
            variant={'contained'}
            color="primary"
            disableElevation
            disabled={isSubmitted}
            onClick={() => {
              order.isDataFromTriumph = isRebalanceDataSourceTriumph;
              !isSubmitted && confirmBulkOrder(order);
            }}
          >
            Submit
          </WO2Button>
        </Box>

        <Typography variant={'h5'} align={'left'}>
          <p>
            This order is subject to the
            <a
              href="https://app.mdaoperator.com.au/Documents/DownLoad?documentId=34113"
              target="_blank"
              rel="noopener noreferrer"
            >
              {' '}
              DASH Order Management Policy
            </a>{' '}
            as well as the specific trading rules of the underlying markets that the securities trade on and the minimum
            trade limits for each security.
          </p>
          <p>
            You understand that the orders are based upon last close prices and current known holdings. You take full
            responsibility for required liquidity and any settlement failure costs of these orders.
          </p>
          <p>
            You acknowledge that orders for Super Simplifier clients, the proposed values of the orders meet the
            investment holdings limits outlined in the{' '}
            <a href="https://supersimplifier.com.au/documents/" target="_blank" rel="noopener noreferrer">
              Super Simplifier PDS
            </a>
            {'.'}
          </p>
          <p>
            Please contact DASH on 1300 726 008 or <a href="mailto:trading@dash.com.au">trading@dash.com.au</a> if you
            require any support.
          </p>
        </Typography>
      </Box>
    </Paper>
  );
}
