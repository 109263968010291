import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../app/api';
import { Report } from './types';

export enum ReportTypes {
  FetchReportList = '@@bulk/report/fetchReportList',
  FetchMyReport = '@@bulk/report/myReport',
}

export enum ReportApi {
  fetchReportList = '/reports',
  fetchMyReport = '/myReport',
}

export const fetchReportList = createAsyncThunk(ReportTypes.FetchReportList, async () => {
  const response = await api.get<Report[]>(`${ReportApi.fetchReportList}`);
  return response.data;
});

export const fetchMyReport = createAsyncThunk(ReportTypes.FetchMyReport, async () => {
  const response = await api.get<[]>(`${ReportApi.fetchMyReport}`);
  return response.data;
});
