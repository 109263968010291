import { Route } from '../../store';
import { BulkContainer } from './container';
import { SendEmailContainer } from './email/send/container';
import { CheckoutContainer } from './order/checkout/container';
import { EditContainer } from './order/edit/container';

export const bulkRoutes: Route[] = [
  {
    path: '/bulk/order/edit',
    component: EditContainer,
    exact: true,
  },
  {
    path: '/bulk/order/checkout',
    component: CheckoutContainer,
    exact: true,
  },
  {
    path: '/bulk/email/create',
    component: SendEmailContainer,
    exact: true,
  },
  {
    path: '/bulk',
    component: BulkContainer,
  },
];
