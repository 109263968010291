import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { progressSelectorFactory } from 'src/common';
import { RootState } from 'src/store';
import { Results } from './components/results';
import { accountSearchResult, accountSelected } from './store/selectors';
import { searchSlice } from './store/slice';
import { EmailSearchTypes } from './store/thunks';
import { selectSecurities } from 'src/features/bulk/order/edit/store/selectors';

const mapStateToProps = (state: RootState) => ({
  accountList: accountSearchResult(state),
  accountSelected: accountSelected(state),
  isLoadingProgress: progressSelectorFactory([EmailSearchTypes.FetchAccountResults])(state),
  securities: selectSecurities(state),
});

const mapDispatchToProps = {
  ...searchSlice.actions,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector> & RouteComponentProps;
export const EmailSearchResultsContainer = connector(Results);
