import { connect, ConnectedProps } from 'react-redux';
import { progressSelectorFactory } from '../../../../../common';
import { RootState } from '../../../../../reducers';
import { selectHasClientEdit } from '../../../../../store/selectors';
import { selectClientId } from '../../common/store/selectors';
import { Addresses } from './components/addresses';
import { selectAddressItems, selectAvailableAddressTypes, selectEditAddress } from './store/selectors';
import { addressesSlice } from './store/slice';
import { AddressesActionTypes, deleteAddress, fetchAddresses, updateAddress } from './store/thunks';

const mapStateToProps = (state: RootState) => ({
  hasClientEditPermission: selectHasClientEdit(state),
  clientId: selectClientId(state),
  addresses: selectAddressItems(state),
  loadingProgress: progressSelectorFactory([AddressesActionTypes.Fetch])(state),
  savingProgress: progressSelectorFactory([AddressesActionTypes.Update, AddressesActionTypes.Delete])(state),
  selectedAddress: selectEditAddress(state),
  availableAddressTypes: selectAvailableAddressTypes(state),
});

const mapDispatchToProps = {
  ...addressesSlice.actions,
  fetchAddresses,
  updateAddress,
  deleteAddress,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector>;
export const AddressesContainer = connector(Addresses);
