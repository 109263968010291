import { Grid } from '@mui/material';
import { Field } from 'formik';
import React from 'react';
import { ToggleButtonItem } from 'src/common';
import { FormikToggleButton } from 'src/common/components/formik';
import { CorporateType } from 'src/features/clients/common/enums';

export const CompanyForm = (): JSX.Element => {
  const corporateTypeToggleButtons: ToggleButtonItem<number>[] = CorporateType.getArray().map((corporateType: CorporateType) => ({
    name: corporateType.displayName,
    value: corporateType.id,
  }));

  return (
    <>
      <Grid container style={{ marginTop: '24px' }}>
        <Grid item xs={12}>
          <Field
            component={FormikToggleButton}
            exclusive={true}
            buttons={corporateTypeToggleButtons}
            name="corporationTypeId"
            label="What is the Company Type?"
          />
        </Grid>
      </Grid>
    </>
  );
};
