import CloseIcon from '@mui/icons-material/Close';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IconButton, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import React from 'react';
import { formatDollars, formatPercentage } from '../../../../../common';
import {
  GroupedByKey,
  GroupedTrades,
  SetAmountToTradePayload,
  SetPriceLimitPayload,
  SetExpiryDatePayload,
  SetPriceTypePayload,
  SetExpiryTypePayload,
  SetMethodPayload,
  SetTradePayload,
  Trade,
} from '../store/types';
import RebalanceTrade from './trade';

interface Props {
  groupedTrades: GroupedTrades;
  groupedByKey: GroupedByKey;
  setClearGroupTrades: (payload: Trade[]) => void;
  setAmountToTrade: (payload: SetAmountToTradePayload) => void;
  setPriceLimit: (payload: SetPriceLimitPayload) => void;
  setExpiryDate: (payload: SetExpiryDatePayload) => void;
  setPriceType: (payload: SetPriceTypePayload) => void;
  setExpiryType: (payload: SetExpiryTypePayload) => void;
  setMethod: (payload: SetMethodPayload) => void;
  setTrade: (payload: SetTradePayload) => void;
  setClearTrade: (payload: string) => void;
  validationResults: string[];
  hasRebalanceEnhancedTrading: boolean;
  hasClientEditAdminOnlyPermission: boolean;
}

export default function Trades(props: Props): JSX.Element {
  const { groupedTrades, setClearGroupTrades, validationResults, hasRebalanceEnhancedTrading, hasClientEditAdminOnlyPermission } = props;

  return (
    <Paper elevation={0} style={{ marginBottom: '40px', overflow: 'auto' }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell variant={'body'} style={{ padding: '0' }} />
            <TableCell variant={'body'} colSpan={5}>
              <Typography variant="h3" color={'primary'}>
                {groupedTrades.title}
              </Typography>
              <div style={{ display: 'flex', flexFlow: 'row wrap' }}>
                <Typography variant="h5" color={'textSecondary'}>
                  {Intl.NumberFormat('en-AU').format(groupedTrades.totalSells)}
                </Typography>
                <ExpandMoreIcon color="error" />
                <Typography variant="h5" color={'textSecondary'}>
                  {Intl.NumberFormat('en-AU').format(groupedTrades.totalBuys)}
                </Typography>
                <ExpandLessIcon color="secondary" />
              </div>
            </TableCell>
            <TableCell variant={'body'} align="left">
              <Typography variant="h5" color="secondary">
                {formatPercentage(groupedTrades.totalCurrentPercent / 100)}
              </Typography>
              <Typography variant="h6" color="textSecondary">
                {formatDollars(groupedTrades.totalCurrentValue)}
              </Typography>
            </TableCell>
            <TableCell variant={'body'} align="left">
              <Typography variant="h5">{formatPercentage(groupedTrades.totalTargetPercent / 100)}</Typography>
              <Typography variant="h6" color="textSecondary">
                {formatDollars(groupedTrades.totalTargetValue)}
              </Typography>
            </TableCell>
            <TableCell variant={'body'} align="left">
              <Typography variant="h5">{formatPercentage(groupedTrades.totalProposedPercent / 100)}</Typography>
              <Typography variant="h6" color="textSecondary">
                {formatDollars(groupedTrades.totalProposedValue)}
              </Typography>
            </TableCell>
            {(hasRebalanceEnhancedTrading || hasClientEditAdminOnlyPermission) && <TableCell variant={'body'} align="left"></TableCell>}
            {(hasRebalanceEnhancedTrading || hasClientEditAdminOnlyPermission) && <TableCell variant={'body'} align="left"></TableCell>}
            {(hasRebalanceEnhancedTrading || hasClientEditAdminOnlyPermission) && <TableCell variant={'body'} align="left"></TableCell>}
            {(hasRebalanceEnhancedTrading || hasClientEditAdminOnlyPermission) && <TableCell variant={'body'} align="left"></TableCell>}

            <TableCell variant={'body'} align="right">
              <Typography variant="h5" style={{ textAlign: 'right' }}>
                {formatDollars(groupedTrades.totalNewCalculatedValue)}
              </Typography>
              <Typography variant="h6" color="textSecondary" style={{ textAlign: 'right' }}>
                TOTAL CALCULATED
              </Typography>
            </TableCell>
            <TableCell
              variant={'body'}
              align="left"
              style={{ padding: '0', width: '30px', minWidth: '30px' }}
              onClick={() => setClearGroupTrades(groupedTrades.trades)}
            >
              <IconButton key="close" aria-label="close" color="inherit">
                <CloseIcon style={{ fontSize: '16px' }} />
              </IconButton>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant={'head'} style={{ padding: '0' }} />
            <TableCell variant={'head'}>
              <Typography variant="h6" color="textSecondary">
                NAME
              </Typography>
            </TableCell>
            <TableCell variant={'head'} align="left">
              <Typography variant="h6" color="textSecondary">
                UNITS & PRICE
              </Typography>
            </TableCell>
            <TableCell variant={'head'} align="left">
              <Typography variant="h6" color="textSecondary">
                BUY/SELL
              </Typography>
            </TableCell>
            <TableCell variant={'head'} align="left">
              <Typography variant="h6" color="textSecondary">
                METHOD
              </Typography>
            </TableCell>
            <TableCell variant={'head'} align="left">
              <Typography variant="h6" color="textSecondary">
                AMOUNT
              </Typography>
            </TableCell>
            <TableCell variant={'head'} align="left">
              <Typography variant="h6" color="textSecondary">
                CURRENT
              </Typography>
            </TableCell>
            <TableCell variant={'head'} align="left">
              <Typography variant="h6" color="textSecondary">
                TARGET
              </Typography>
            </TableCell>
            <TableCell variant={'head'} align="left">
              <Typography variant="h6" color="textSecondary">
                PROPOSED
              </Typography>
            </TableCell>
            {(hasRebalanceEnhancedTrading || hasClientEditAdminOnlyPermission) && (
              <TableCell variant={'head'} align="left">
                <Typography variant="h6" color="textSecondary">
                  PRICE TYPE
                </Typography>
              </TableCell>
            )}
            {(hasRebalanceEnhancedTrading || hasClientEditAdminOnlyPermission) && (
              <TableCell variant={'head'} align="left">
                <Typography variant="h6" color="textSecondary">
                  LIMIT PRICE
                </Typography>
              </TableCell>
            )}
            {(hasRebalanceEnhancedTrading || hasClientEditAdminOnlyPermission) && (
              <TableCell variant={'head'} align="left">
                <Typography variant="h6" color="textSecondary">
                  EXPIRY
                </Typography>
              </TableCell>
            )}
            {(hasRebalanceEnhancedTrading || hasClientEditAdminOnlyPermission) && (
              <TableCell variant={'head'} align="left">
                <Typography variant="h6" color="textSecondary">
                  EXPIRY DATE
                </Typography>
              </TableCell>
            )}
            <TableCell variant={'head'} align="right">
              <Typography variant="h6" color="textSecondary">
                CALCULATED VALUE & UNITS
              </Typography>
            </TableCell>
            <TableCell variant={'head'} align="left" style={{ padding: '0' }} />
          </TableRow>
        </TableHead>

        <TableBody>
          {groupedTrades.trades &&
            groupedTrades.trades.map((trade: Trade, index: number) => (
              <RebalanceTrade
                {...props}
                key={index}
                index={index}
                trade={trade}
                validationMessage={validationResults.find((message) => message.includes(trade.securityCode)) ?? ''}
              />
            ))}
        </TableBody>
      </Table>
    </Paper>
  );
}
