import { downloadCsv } from '../../../../common';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const downloadSuperMemberDataTemplate = () => {
  const data: Record<string, unknown>[] = [];
  data.push({
    MemberNumber: 'S0000',
    BeneficiaryName1: 'John Doe',
    BeneficiaryRelationship1: 'Spouse',
    BeneficiaryPercent1: 100,
    BeneficiaryBinding1: 'NON-BINDING NOMINATION',
    BeneficiaryName2: null,
    BeneficiaryRelationship2: null,
    BeneficiaryPercent2: null,
    BeneficiaryBinding2: null,
    BeneficiaryName3: null,
    BeneficiaryRelationship3: null,
    BeneficiaryPercent3: null,
    BeneficiaryBinding3: null,
    BeneficiaryName4: null,
    BeneficiaryRelationship4: null,
    BeneficiaryPercent4: null,
    BeneficiaryBinding4: null,
    BeneficiaryName5: null,
    BeneficiaryRelationship5: null,
    BeneficiaryPercent5: null,
    BeneficiaryBinding5: null,
    BeneficiaryName6: null,
    BeneficiaryRelationship6: null,
    BeneficiaryPercent6: null,
    BeneficiaryBinding6: null,
    BeneficiaryName7: null,
    BeneficiaryRelationship7: null,
    BeneficiaryPercent7: null,
    BeneficiaryBinding7: null,
    BeneficiaryName8: null,
    BeneficiaryRelationship8: null,
    BeneficiaryPercent8: null,
    BeneficiaryBinding8: null,
    BeneficiaryName9: null,
    BeneficiaryRelationship9: null,
    BeneficiaryPercent9: null,
    BeneficiaryBinding9: null,
    BeneficiaryName10: null,
    BeneficiaryRelationship10: null,
    BeneficiaryPercent10: null,
    BeneficiaryBinding10: null,
    DeathPremium: null,
    TBDPremium: null,
    IncomeProtection: null,
    PensionPaymentValue: null,
    PensionPaymentFrequency: null,
    NextPensionPaymentDate: null,
    ConcessionalContribution: null,
    NonConcessionalContribution: null,
    UnrestrictedNonPreserved: null,
    RestrictedNonPreserved: null,
    Preserved: null,
    Taxable: null,
    TaxFree: null,
    TaxLiability: null,
  });
  downloadCsv(data, `Super_MemberData_Template`);
  return {
    message: 'CSV downloaded',
  };
};
