import { Close, ContentCopyOutlined, ExpandMoreOutlined } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  Fade,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { WO2Select, WO2TextInput } from 'src/common';
import WO2Button from 'src/common/components/button/Button';
import {
  WorkflowAction,
  WorkflowActionDescription,
  WorkflowActionRequest,
  WorkflowItem,
  completeWorkflowDescription,
  discardWorkflowDescription,
  downloadWorkflowDocumentDescription,
  resumeWorkflowDescription,
  terminateWorkflowDescription,
} from '../store/types';

export interface WorkflowTasksProperties {
  workflowDetail: WorkflowItem | undefined | null;
  workflowError: string | undefined;
  setSelectedWorkflow: (workflow: WorkflowItem | undefined) => void;
  terminateWorkflow: (workflow: WorkflowActionRequest) => void;
  completeWorkflow: (workflow: WorkflowActionRequest) => void;
  resumeWorkflow: (workflow: WorkflowActionRequest) => void;
  discardWorkflow: (workflow: WorkflowActionRequest) => void;
  downloadWorkflowDocument: (workflow: WorkflowActionRequest) => void;
  setWorkflowError: (message: string | undefined) => void;
}

export const WorkflowTasks = (props: WorkflowTasksProperties): JSX.Element => {
  const inputLabel: React.MutableRefObject<HTMLLabelElement | null> = useRef(null);
  const {
    setSelectedWorkflow,
    setWorkflowError,
    terminateWorkflow,
    resumeWorkflow,
    discardWorkflow,
    completeWorkflow,
    downloadWorkflowDocument,
    workflowDetail,
    workflowError,
  } = props;

  const actionsDescriptions: WorkflowActionDescription[] = [
    terminateWorkflowDescription,
    resumeWorkflowDescription,
    discardWorkflowDescription,
    completeWorkflowDescription,
    downloadWorkflowDocumentDescription,
  ];

  const [actionIsInvalid, setActionIsInvalid] = useState<boolean>(
    terminateWorkflowDescription.validation(workflowDetail)
  );
  const [showDebugDetails, setShowDebugDetails] = useState<boolean>(true);
  const [showWorkflowAction, setShowWorkflowAction] = useState<boolean>(false);
  const [selectedAction, setSelectedAction] = useState<WorkflowAction | undefined>(terminateWorkflowDescription.action);
  const [workflowActionDescription, setWorkflowActionDescription] = useState<WorkflowActionDescription | undefined>(
    terminateWorkflowDescription
  );

  useEffect(() => {
    if (!!workflowDetail && !!workflowActionDescription) {
      setActionIsInvalid(workflowActionDescription.validation(workflowDetail));
    }
  }, [workflowDetail, workflowError]);

  const handleClose = () => {
    setSelectedWorkflow(undefined);
  };

  const hasErrors = (input: string | undefined) => {
    if (!input) return true;

    if (!!input && input.length > 0) return false;
    return true;
  };

  const performAction = () => {
    if (!!workflowDetail) {
      const request: WorkflowActionRequest = {
        workflowId: workflowDetail.id,
      };
      if (selectedAction === WorkflowAction.TerminateWorkflow) {
        terminateWorkflow(request);
      } else if (selectedAction === WorkflowAction.ResumeWorkflow) {
        resumeWorkflow(request);
      } else if (selectedAction === WorkflowAction.DiscardWorkflow) {
        discardWorkflow(request);
      } else if (selectedAction === WorkflowAction.CompleteWorkflow) {
        completeWorkflow(request);
      } else if (selectedAction === WorkflowAction.DownloadWorkflowDocument) {
        downloadWorkflowDocument(request);
      }
    }
  };

  const handleSetSelectedAction = (action: WorkflowAction) => {
    setSelectedAction(action);
    setWorkflowError(undefined);
    const entry = actionsDescriptions.find((x: WorkflowActionDescription) => x.action === action);
    if (!!entry && !!workflowDetail) {
      setWorkflowActionDescription(entry);
      setActionIsInvalid(entry.validation(workflowDetail));
    }
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={true}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={true}>
          <Paper
            elevation={0}
            style={{
              width: '1060px',
              padding: '40px',
              maxHeight: '100%',
              position: 'absolute',
              minHeight: '600px',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            <TableContainer component={Paper} elevation={0}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">
                      <Typography variant="h3" color="textPrimary">
                        Workflow Details - {workflowDetail?.id}
                      </Typography>
                    </TableCell>
                    <TableCell align="right" onClick={handleClose}>
                      <Typography variant="h6" color="textSecondary">
                        <Close></Close>
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <tbody>
                  <TableRow style={{ width: '100%', borderBottom: 'none', borderBottomColor: 'transparent' }}>
                    <TableCell
                      style={{
                        paddingTop: '10px',
                        paddingBottom: '10px',
                        paddingLeft: 0,
                        paddingRight: 0,
                        borderBottom: 'none',
                        borderBottomColor: 'transparent',
                      }}
                      colSpan={2}
                    >
                      <Paper elevation={3}>
                        <Accordion
                          expanded={showDebugDetails}
                          style={{ boxShadow: 'unset' }}
                          onChange={() => setShowDebugDetails(!showDebugDetails)}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreOutlined />}
                            aria-controls="debugDetails-content"
                            id="debugDetails-header"
                          >
                            <Typography sx={{ width: '33%', flexShrink: 0 }}>Debug Details</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Grid container>
                              <Grid item xs={3} style={{ paddingBottom: '20px' }}>
                                <WO2TextInput
                                  id="workflowEntityId"
                                  label="CLIENT ID"
                                  value={workflowDetail?.entityId}
                                  disabled
                                ></WO2TextInput>
                              </Grid>
                              <Grid item xs={3} style={{ paddingBottom: '20px' }}>
                                <WO2TextInput
                                  id="workflowAdviserId"
                                  data-testid="workflowAdviserId"
                                  label="ADVISER ID"
                                  value={workflowDetail?.adviserId}
                                  disabled
                                ></WO2TextInput>
                              </Grid>
                              <Grid item xs={3} style={{ paddingBottom: '20px' }}>
                                <WO2TextInput
                                  id="workflowPracticeId"
                                  label="PRACTICE ID"
                                  value={workflowDetail?.practiceId}
                                  disabled
                                ></WO2TextInput>
                              </Grid>
                              <Grid item xs={3} style={{ paddingBottom: '20px' }}>
                                <WO2TextInput
                                  id="workflowAfslId"
                                  label="AFSL ID"
                                  value={workflowDetail?.afslId}
                                  disabled
                                ></WO2TextInput>
                              </Grid>
                            </Grid>
                            <Grid container>
                              <Grid item xs={11} style={{ paddingBottom: '20px' }}>
                                <WO2TextInput
                                  style={{ width: '100%' }}
                                  id="workflowErrorMessage"
                                  label="ERROR"
                                  value={workflowDetail?.errorMessage ?? ' '}
                                  disabled
                                  multiline={true}
                                ></WO2TextInput>
                              </Grid>
                              <Grid item xs={1} style={{ paddingBottom: '20px' }}>
                                <WO2Button
                                  color="inherit"
                                  style={{ float: 'right', color: 'gray' }}
                                  type="button"
                                  data-testid={`copyWorkflowError`}
                                  disableFocusRipple={false}
                                  disableRipple={false}
                                  disabled={hasErrors(workflowDetail?.errorMessage)}
                                  onClick={() => {
                                    if (!!workflowDetail?.errorMessage)
                                      navigator.clipboard.writeText(workflowDetail?.errorMessage);
                                  }}
                                >
                                  <ContentCopyOutlined></ContentCopyOutlined>
                                </WO2Button>
                              </Grid>
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                      </Paper>
                    </TableCell>
                  </TableRow>

                  <TableRow style={{ width: '100%', borderBottom: 'none', borderBottomColor: 'transparent' }}>
                    <TableCell
                      style={{
                        paddingTop: '10px',
                        paddingBottom: '10px',
                        paddingLeft: 0,
                        paddingRight: 0,
                        borderBottom: 'none',
                        borderBottomColor: 'transparent',
                      }}
                      colSpan={2}
                    >
                      <Paper elevation={3}>
                        <Accordion
                          expanded={showWorkflowAction}
                          style={{ boxShadow: 'unset' }}
                          onChange={() => setShowWorkflowAction(!showWorkflowAction)}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreOutlined />}
                            aria-controls="adminActions-content"
                            id="adminActions-header"
                          >
                            <Typography sx={{ width: '33%', flexShrink: 0 }}>Admin Actions</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Grid container>
                              <Grid item xs={5} style={{ paddingBottom: '20px', paddingRight: '20px' }}>
                                <WO2TextInput
                                  id="businessStatus"
                                  data-testid="businessStatus"
                                  fullWidth={true}
                                  label="STATUS"
                                  value={workflowDetail?.businessStatus}
                                  disabled
                                ></WO2TextInput>
                              </Grid>

                              <Grid item xs={5} style={{ paddingBottom: '20px', paddingRight: '20px' }}>
                                <FormControl variant="outlined" style={{ width: '100%' }}>
                                  <InputLabel ref={inputLabel} id="workflowAction-select-label">
                                    WORKFLOW ACTION
                                  </InputLabel>
                                  <WO2Select
                                    labelId="workflowAction-select-label"
                                    id="workflowAction"
                                    data-testid="workflowAction"
                                    fullWidth={true}
                                    value={selectedAction}
                                    label={'WORKFLOW ACTION'}
                                    onChange={(event) => {
                                      handleSetSelectedAction(event.target.value as WorkflowAction);
                                    }}
                                  >
                                    {!!workflowDetail &&
                                      actionsDescriptions.map(
                                        (desc: WorkflowActionDescription, index: number) =>
                                          !!desc.visible(workflowDetail) && (
                                            <MenuItem key={index} value={desc.action}>
                                              {desc.displayName}
                                            </MenuItem>
                                          )
                                      )}
                                  </WO2Select>
                                </FormControl>
                              </Grid>
                              <Grid item xs={2} style={{ paddingBottom: '20px' }}>
                                <WO2Button
                                  color="primary"
                                  style={{ marginLeft: '20px', padding: '15px 28px' }}
                                  disableElevation
                                  variant="contained"
                                  disableFocusRipple={false}
                                  disableRipple={false}
                                  data-testid="performWorkflowAction"
                                  disabled={actionIsInvalid}
                                  onClick={performAction}
                                >
                                  <Typography variant="inherit" style={{ marginLeft: '10px' }}>
                                    {selectedAction === WorkflowAction.DownloadWorkflowDocument ? 'Download' : 'Update'}
                                  </Typography>
                                </WO2Button>
                              </Grid>
                            </Grid>
                            <Grid container>
                              <Grid item xs={12} style={{ paddingBottom: '20px' }}>
                                <WO2TextInput
                                  style={{ width: '100%' }}
                                  id="actionDescriptionMessage"
                                  label="ACTION DESCRIPTION"
                                  value={workflowActionDescription?.description ?? ' '}
                                  disabled
                                  multiline={true}
                                ></WO2TextInput>
                              </Grid>
                              {workflowError !== undefined ? (
                                <Grid item xs={12} style={{ paddingBottom: '20px' }}>
                                  <WO2TextInput
                                    style={{ width: '100%' }}
                                    id="workflowErrorMessage"
                                    label="WORKFLOW ERROR MESSAGE"
                                    value={workflowError}
                                    disabled
                                    multiline={false}
                                  ></WO2TextInput>
                                </Grid>
                              ) : (
                                <></>
                              )}
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                      </Paper>
                    </TableCell>
                  </TableRow>
                </tbody>
              </Table>
            </TableContainer>
            <Grid container>
              <Grid
                xs={12}
                item
                justifyContent="flex-end"
                style={{ position: 'absolute', bottom: 0, right: 0, paddingRight: '10px', paddingBottom: '10px' }}
              >
                <WO2Button type="button" data-testid="closeWorkflowDetails" variant="outlined" onClick={handleClose}>
                  CLOSE
                </WO2Button>
              </Grid>
            </Grid>
          </Paper>
        </Fade>
      </Modal>
    </>
  );
};
