import {
  Accordion,
  AccordionSummary,
  FormControlLabel,
  AccordionDetails,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
} from '@mui/material';
import React from 'react';
import { WO2Checkbox, WO2Select, WO2ToggleSwitch } from 'src/common';
import DateRangePicker from 'src/common/components/DateRangePicker';
import { ActionCreatorWithoutPayload, ActionCreatorWithPayload } from '@reduxjs/toolkit';
import {
  ClientReportsParameters,
  GroupByTransactionHistory,
  HideByTransactionHistory,
  HidingOptionsRequest,
  MdaReportTypes,
  SortByTransactionHistory,
} from '../../store/types';
import { ExpandMore } from '@mui/icons-material';
import { DateRange } from 'src/store';

export interface TransactionHistoryProps {
  parameters: ClientReportsParameters;
  expandTransactions: ActionCreatorWithoutPayload<string>;
  setTransactionsGroupByParameter: ActionCreatorWithPayload<GroupByTransactionHistory, string>;
  setTransactionsSortByParameter: ActionCreatorWithPayload<SortByTransactionHistory, string>;
  setTransactionsSelectedParameter: ActionCreatorWithPayload<boolean, string>;
  setDateRangeTransactionsParameter: ActionCreatorWithPayload<DateRange, string>;
  setTransactionsHidingOptionsParameter: ActionCreatorWithPayload<
    HidingOptionsRequest<HideByTransactionHistory>,
    string
  >;
  createReportSortRequest: (isAdd: boolean, id: number, text: string) => void;
}

export const TransactionHistory = ({
  parameters,
  setTransactionsSortByParameter,
  setTransactionsSelectedParameter,
  setDateRangeTransactionsParameter,
  setTransactionsHidingOptionsParameter,
  setTransactionsGroupByParameter,
  expandTransactions,
  createReportSortRequest,
}: TransactionHistoryProps): JSX.Element => {
  const handleClickTransactionsSelect = () => {
    setTransactionsSelectedParameter(!parameters.selectedReports?.transactionHistory.selected);
    createReportSortRequest(
      !parameters.selectedReports?.transactionHistory.selected,
      MdaReportTypes.TransactionHistory.id,
      MdaReportTypes.TransactionHistory.displayName
    );
  };

  const handleClickReportDateTransactions = (dates: DateRange) => {
    setDateRangeTransactionsParameter(dates);
  };

  return (
    <Accordion
      onChange={expandTransactions}
      expanded={parameters.selectedReports?.transactionHistory.expanded || false}
      elevation={0}
    >
      <AccordionSummary expandIcon={<ExpandMore />}>
        <FormControlLabel
          sx={{ flex: 1 }}
          aria-label="Acknowledge"
          onClick={(event) => event.stopPropagation()}
          onFocus={(event) => event.stopPropagation()}
          control={
            <WO2Checkbox
              color="primary"
              checked={parameters.selectedReports?.transactionHistory.selected}
              onChangeHandler={handleClickTransactionsSelect}
            />
          }
          label="Transaction History"
        />
        <div onClick={(e) => e.stopPropagation()}>
          <DateRangePicker
            dateRange={{
              dateFrom: parameters?.selectedReports.transactionHistory?.fromDate,
              dateTo: parameters?.selectedReports.transactionHistory?.toDate,
              dateRangeType: parameters?.selectedReports.transactionHistory?.dateRangeType,
            }}
            setDateRange={handleClickReportDateTransactions}
            inceptionDate={parameters.inceptionDate}
          />
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={1} alignItems="center">
          <Grid item flex={1}>
            <FormControl fullWidth>
              <InputLabel id="sort-by-id">Sort by</InputLabel>
              <WO2Select
                labelId="sort-by-id"
                label="sort by"
                onChange={(e) => setTransactionsSortByParameter(e.target.value as SortByTransactionHistory)}
                value={parameters.selectedReports.transactionHistory.sortBy}
                defaultValue={SortByTransactionHistory.SecurityName}
              >
                <MenuItem value={SortByTransactionHistory.Account}>Account</MenuItem>
                <MenuItem value={SortByTransactionHistory.TradeDate}>Trade Date</MenuItem>
                <MenuItem value={SortByTransactionHistory.SecurityName}>Security Name</MenuItem>
              </WO2Select>
            </FormControl>
          </Grid>
          <Grid item flex={1}>
            <FormControl fullWidth>
              <InputLabel id="group-by-id">Group by</InputLabel>
              <WO2Select
                labelId="group-by-id"
                label="group by"
                onChange={(e) => setTransactionsGroupByParameter(e.target.value as GroupByTransactionHistory)}
                value={parameters.selectedReports.transactionHistory.groupBy}
                defaultValue={GroupByTransactionHistory.Account}
              >
                <MenuItem value={GroupByTransactionHistory.Account}>Account</MenuItem>
                <MenuItem value={GroupByTransactionHistory.TradeDate}>Trade Date</MenuItem>
                <MenuItem value={GroupByTransactionHistory.SecurityName}>Security Name</MenuItem>
              </WO2Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item container xs={4} alignItems="center">
            <WO2ToggleSwitch
              checked={parameters.selectedReports.transactionHistory.excludeCash}
              onChange={(e) =>
                setTransactionsHidingOptionsParameter({
                  value: e.target.checked,
                  option: HideByTransactionHistory.Cash,
                })
              }
              color="primary"
              name="hidingOptions"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
            <Typography variant="h4">Exclude Cash</Typography>
          </Grid>
          <Grid item container xs={4} alignItems="center">
            <WO2ToggleSwitch
              checked={parameters.selectedReports.transactionHistory.includeDistribution}
              onChange={(e) =>
                setTransactionsHidingOptionsParameter({
                  value: e.target.checked,
                  option: HideByTransactionHistory.Distribution,
                })
              }
              color="primary"
              name="hidingOptions"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
            <Typography variant="h4">Include Distribution</Typography>
          </Grid>
          <Grid item container xs={4} alignItems="center">
            <WO2ToggleSwitch
              checked={parameters.selectedReports.transactionHistory.excludeAssetDescription}
              onChange={(e) =>
                setTransactionsHidingOptionsParameter({
                  value: e.target.checked,
                  option: HideByTransactionHistory.AssetDescription,
                })
              }
              color="primary"
              name="hidingOptions"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
            <Typography variant="h4">Exclude Asset Description</Typography>
          </Grid>
          <Grid item container xs={4} alignItems="center">
            <WO2ToggleSwitch
              checked={parameters.selectedReports.transactionHistory.excludeAccounts}
              onChange={(e) =>
                setTransactionsHidingOptionsParameter({
                  value: e.target.checked,
                  option: HideByTransactionHistory.Accounts,
                })
              }
              color="primary"
              name="hidingOptions"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
            <Typography variant="h4">Exclude Accounts</Typography>
          </Grid>
          <Grid item container xs={4} alignItems="center">
            <WO2ToggleSwitch
              checked={parameters.selectedReports.transactionHistory.includeBrokerage}
              onChange={(e) =>
                setTransactionsHidingOptionsParameter({
                  value: e.target.checked,
                  option: HideByTransactionHistory.Brokerage,
                })
              }
              color="primary"
              name="hidingOptions"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
            <Typography variant="h4">Include Brokerage</Typography>
          </Grid>
          <Grid item container xs={4} alignItems="center">
            <WO2ToggleSwitch
              checked={parameters.selectedReports.transactionHistory.includeGST}
              onChange={(e) =>
                setTransactionsHidingOptionsParameter({
                  value: e.target.checked,
                  option: HideByTransactionHistory.Gst,
                })
              }
              color="primary"
              name="hidingOptions"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
            <Typography variant="h4">Include GST</Typography>
          </Grid>
          <Grid item container xs={4} alignItems="center">
            <WO2ToggleSwitch
              checked={parameters.selectedReports.transactionHistory.includeOtherCharges}
              onChange={(e) =>
                setTransactionsHidingOptionsParameter({
                  value: e.target.checked,
                  option: HideByTransactionHistory.OtherCharges,
                })
              }
              color="primary"
              name="hidingOptions"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
            <Typography variant="h4">Include Other Charges</Typography>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
