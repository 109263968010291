import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React from 'react';
import { DateTimeFormat, formatNumberCommaSeparated, getLocalDateTime } from 'src/common';
import { TaxationDetails } from './../store/types';

export interface Props {
  details: TaxationDetails | null | undefined;
}

export const TaxationComponents = (props: Props): JSX.Element => {
  return (
    <>
      <Typography variant="h4" style={{ padding: '20px 0' }}>
        <b>Taxation Components</b>
      </Typography>
      <Paper elevation={5} style={{ marginBottom: '30px' }}>
        <TableContainer component={Paper} elevation={0}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="left" style={{ width: '70%' }}>
                  <Typography variant="h6" color="textSecondary">
                    DESCRIPTION
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography variant="h6" color="textSecondary">
                    VALUE ($)
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="left">
                  <Typography variant="h5">Eligible Service Date</Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography data-testid="eligibleServiceDateData" variant="h5">
                    {getLocalDateTime(props.details?.eligibleServiceDate, DateTimeFormat.Short)}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">
                  <Typography variant="h5">Tax Free Component</Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography data-testid="taxFreeData" variant="h5">
                    ${`${formatNumberCommaSeparated(props.details?.taxFree || 0, 2)}`}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">
                  <Typography variant="h5">Taxable Component (Untaxed)</Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography data-testid="taxableUntaxedData" variant="h5">
                    ${`${formatNumberCommaSeparated(props.details?.taxableUntaxed || 0, 2)}`}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">
                  <Typography variant="h5">Taxable Component (Taxed)</Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography data-testid="taxableTaxedData" variant="h5">
                    ${`${formatNumberCommaSeparated(props.details?.taxableTaxed || 0, 2)}`}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">
                  <Typography variant="h5">
                    <b>Total</b>
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography data-testid="totalData" variant="h5">
                    <b>${`${formatNumberCommaSeparated(props.details?.total || 0, 2)}`}</b>
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};
