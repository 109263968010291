import GetAppIcon from '@mui/icons-material/GetApp';
import React, { useEffect, useState } from 'react';
import { FabMenu, FabMenuItem, WO2Snackbar } from '../../../../common/components';
import Importer from '../../../../common/components/importer';
import { MenuOptions, SuperMemberData, SuperMemberDataRequest } from '../store/types';
import { downloadSuperMemberDataTemplate } from '../utils/downloadTemplate';
import * as importUtils from './../utils/importMemberData';

export interface Props {
  importUploadSuccess: boolean;
  isImporting: boolean;
  importErrors: string[];
  onUploadSuccessHandler: () => void;
  isMenuOpen: boolean;
  setMenuState: (isMenuOpen: boolean) => void;
  onImportFailed: (errors: string[]) => void;
  importSuperMemberData: (memberData: SuperMemberDataRequest[]) => void;
}

export default function ImportMenu({
  importErrors,
  isMenuOpen,
  importUploadSuccess,
  isImporting,
  onUploadSuccessHandler,
  setMenuState,
  onImportFailed,
  importSuperMemberData,
}: Props): JSX.Element {
  const [displaySnackbar, setSnackbarState] = useState<boolean>(false);
  const [displayMenuOption, setMenuOption] = useState<MenuOptions>(MenuOptions.None);

  const onClose = () => {
    setMenuOption(MenuOptions.None);
  };
  const onImport = (file: File) => {
    //parse File

    importUtils.parseImportFile(file).then((result) => {
      // decode data
      const decodedData = importUtils.decodeData(result.data as SuperMemberData[]);
      if (decodedData.errors && decodedData.errors.length > 0) {
        onImportFailed(decodedData.errors);
        return;
      }
      // validate rules
      const validatedData = importUtils.validateData(decodedData.membersData);
      if (validatedData.errors && validatedData.errors.length > 0) {
        onImportFailed(validatedData.errors);
        return;
      }

      const convertedMembers = importUtils.convertData(validatedData.memberData);
      importSuperMemberData(convertedMembers);
    });
  };

  useEffect(() => {
    const hasCompletedUploadSuccessfully = importUploadSuccess && importErrors.length === 0;

    if (hasCompletedUploadSuccessfully) {
      onClose();
      setSnackbarState(true);
      onUploadSuccessHandler();
    }
  }, [importErrors, importUploadSuccess, setSnackbarState, onUploadSuccessHandler]);

  const createMenuItems = (): FabMenuItem[] => {
    const items: FabMenuItem[] = [];

    items.push({
      label: 'Download Template',
      onClick: () => {
        setMenuOption(MenuOptions.DownloadTemplate);
        downloadSuperMemberDataTemplate();
        setMenuState(!isMenuOpen);
      },
      icon: <GetAppIcon />,
    });

    items.push({
      label: 'Import Super Member Data',
      onClick: () => {
        setMenuOption(MenuOptions.Import);
        setMenuState(!isMenuOpen);
      },
      icon: <GetAppIcon style={{ transform: 'rotate(180deg)' }} />,
    });

    return items;
  };

  const menuItems = createMenuItems();

  if (menuItems.length === 0) {
    return <></>;
  }

  return (
    <>
      <WO2Snackbar
        MUISnackbarProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
          open: displaySnackbar,
          onClose: () => {
            setSnackbarState(false);
          },
          message: 'Super Member data import workflow has been initiated.',
        }}
      />
      <FabMenu
        isMenuOpen={isMenuOpen}
        name=""
        toggleMenu={() => {
          setMenuState(!isMenuOpen);
        }}
        menuItems={menuItems}
      />
      <Importer
        fileType=".csv"
        isOpen={displayMenuOption === MenuOptions.Import}
        isImporting={isImporting}
        errors={importErrors}
        handleClose={onClose}
        handleImport={onImport}
      />
    </>
  );
}
