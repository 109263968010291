import React from 'react';
import { formatDollars } from '../../../../../../common';
import CardComponent from '../../../../../../common/components/CardComponent';
import { theme } from '../../../../../../themes';

interface Props {
  isLoading: boolean;
  hasErrors: boolean;
  totalValue: number;
}

const TotalValueCard = (props: Props): JSX.Element => {
  const { isLoading, hasErrors, totalValue } = props;

  return (
    <CardComponent
      progress={{ isLoading, hasErrors }}
      background={theme.palette.gradient1?.main}
      value={formatDollars(totalValue)}
      subText={'TOTAL VALUE'}
    />
  );
};

export default TotalValueCard;
