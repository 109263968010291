import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../../app/api';
import { fetchModelDetailsPayload, ModelDetails } from './types';

export enum ModelDetailsActionTypes {
  FetchModelDetails = '@@model/details/FetchModelDetails',
}

export enum ModelDetailsApiEndpoints {
  fetchModelDetails = '/bff/GetModelDetails',
}

export const fetchModelDetails = createAsyncThunk(
  ModelDetailsActionTypes.FetchModelDetails,
  async (wrapper: fetchModelDetailsPayload) => {
    const response = await api.get<ModelDetails>(
      `${ModelDetailsApiEndpoints.fetchModelDetails}?modelVersionId=${wrapper.modelVersionId}&isDataFromTriumph=${wrapper.isDataFromTriumph}`
    );
    return response.data;
  }
);
