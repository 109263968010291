import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';
import { DateRange } from '../../../../store';
import { fetchSecurityPriceChart, fetchSecurityPrices, fetchSecurityPriceStatistics } from './thunks';
import { fetchPagedResults, SecurityPriceItem, SecurityPricesChartItem, SecurityPriceState, SecurityPriceStatistics } from './types';

export const initialState: SecurityPriceState = {
  parameters: {
    dates: { dateFrom: DateTime.now().minus({ years: 1 }).toISODate(), dateTo: DateTime.now().toISODate() },
    pagination: {
      pageNumber: 1,
      pageSize: 25,
      queryFields: [
        {
          fieldName: 'date',
          descendingSortDirection: true,
          operator: '',
          hasSearchTerm: false,
          isSortTerm: true,
          searchTerm: '',
        },
      ],
    },
  },
  securityPriceStatistics: {
    capitalReturn: 0,
    lastPrice: 0,
    maximumPrice: 0,
    minimumPrice: 0,
  },
  securityPrices: {
    pageNumber: 1,
    pageSize: 25,
    totalNumberOfPages: 100,
    totalNumberOfRecords: 10000,
    results: [],
  },
  dailySecurityPricesChart: [],
};

export const securityPriceSlice = createSlice({
  name: '@@security/price',
  initialState,
  reducers: {
    setDateParameter: (state, action: PayloadAction<DateRange>) => {
      state.parameters.dates = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSecurityPriceStatistics.fulfilled, (state, action: PayloadAction<SecurityPriceStatistics>) => {
      state.securityPriceStatistics = action.payload;
    });
    builder.addCase(fetchSecurityPriceChart.fulfilled, (state, action: PayloadAction<SecurityPricesChartItem[]>) => {
      state.dailySecurityPricesChart = action.payload;
    });
    builder.addCase(fetchSecurityPrices.fulfilled, (state, action: PayloadAction<fetchPagedResults<SecurityPriceItem>>) => {
      state.securityPrices = action.payload.results;
      state.parameters.pagination = action.payload.pagination;
    });
  },
});
