import { Container, FormControlLabel, FormGroup, Grid, Switch } from '@mui/material';
import React, { Component } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { RootState } from 'src/reducers';
import {
  selectHasClientEditAdminOnly,
  selectHasDashEmail,
  selectIsDataFromTriumph,
  selectIsNonProdEnv,
  selectIsRebalanceDataSourceTriumph,
} from 'src/store/selectors';
import { userSettingsSlice } from 'src/store/userSettings';
import { userManager } from 'src/userManager';

class Header extends Component<Props> {
  render() {
    const isDataFromTriumph: boolean = this.props.isDataFromTriumph;
    const isRebalanceFromTriumph: boolean = this.props.isRebalanceDataFromTriumph;

    const setLogOut = () => {
      if (this.props.user) {
        userManager.signoutRedirect({ id_token_hint: this.props.user.id_token });
      } else {
        userManager.signoutRedirect();
      }
      userManager.removeUser();
    };

    const setUpdateIsDataFromTriumph = () => {
      this.props.setDataFromTriumph(!isDataFromTriumph);
    };

    const setUpdateIsRebalanceFromTriumph = () => {
      this.props.setRebalanceFromTriumph(!isRebalanceFromTriumph);
    };

    const someStyle = { padding: '10px' };
    return (
      <Container>
        <Grid container>
          <Grid item xs={6}>
            {this.props.isNonProdEnv && (
              <Grid>
                <Grid>
                  <Link to="/client/644/dashboard" className="item" style={someStyle}>
                    [Client]
                  </Link>
                  <Link to="/client/8418/dashboard" className="item" style={someStyle}>
                    [Super Simplifier Client]
                  </Link>
                  <Link
                    to="/rebalance?investmentServiceId=79&rebalanceId=5ea901f78e0f820001caa58e"
                    className="item"
                    style={someStyle}
                  >
                    [Rebalance]
                  </Link>
                  <button onClick={setLogOut} style={someStyle}>
                    Logout
                  </button>
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid item xs={6}>
            {this.props.isAdmin && (
              <Grid style={{ float: 'right' }}>
                <FormGroup>
                  <FormControlLabel
                    control={<Switch checked={isDataFromTriumph} onClick={setUpdateIsDataFromTriumph} />}
                    label="Triumph as datasource"
                  />
                </FormGroup>
              </Grid>
            )}
            {this.props.hasDashEmail && (
              <Grid style={{ float: 'right' }}>
                <FormGroup>
                  <FormControlLabel
                    control={<Switch checked={isRebalanceFromTriumph} onClick={setUpdateIsRebalanceFromTriumph} />}
                    label="Triumph as Rebalance Datasource"
                  />
                </FormGroup>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Container>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  user: state.oidc.user,
  isAdmin: selectHasClientEditAdminOnly(state),
  isDataFromTriumph: selectIsDataFromTriumph(state),
  hasDashEmail: selectHasDashEmail(state),
  isRebalanceDataFromTriumph: selectIsRebalanceDataSourceTriumph(state),
  isNonProdEnv: selectIsNonProdEnv(),
});

const mapDispatchToProps = {
  ...userSettingsSlice,
  setDataFromTriumph: userSettingsSlice.actions.setDataFromTriumph,
  setRebalanceFromTriumph: userSettingsSlice.actions.setRebalanceFromTriumph,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;
export default connector(Header);
