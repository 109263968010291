import { FormControl, InputLabel, MenuItem } from '@mui/material';
import _ from 'lodash';
import React, { useCallback, useEffect, useRef } from 'react';
import { ClientAccountSelectModel } from '../../common/store/types';
import { WO2Select } from './../../../../../common';

interface Props {
  items: ClientAccountSelectModel[];
  selectedAccountId: number | null;
  setAccount: (clientAccount: ClientAccountSelectModel) => void;
}

export function AccountSelect(props: Props): JSX.Element {
  const inputLabel: React.MutableRefObject<HTMLLabelElement | null> = useRef(null);
  const accounts = props.items || [];
  const { setAccount, selectedAccountId } = props;

  const selectAccount = useCallback(
    (id: number | null) => {
      const account = _.find(accounts, { accountId: id }) ?? _.find(accounts, { accountId: null });
      if (account) {
        setAccount(account);
      }
    },
    [accounts, setAccount]
  );

  useEffect(() => {
    if (accounts && accounts.length > 0 && (!selectedAccountId || !accounts.find((i) => i.accountId === selectedAccountId))) {
      selectAccount(accounts[0].accountId);
    }
  }, [accounts, selectAccount, selectedAccountId]);

  return (
    <FormControl variant="outlined">
      <InputLabel ref={inputLabel} id="account-select-label">
        Account
      </InputLabel>
      <WO2Select
        labelId="account-select-label"
        id="account-select"
        value={props.items && props.items.length > 0 && props.items.find((i) => i.accountId === props.selectedAccountId) ? props.selectedAccountId || '-1' : ''}
        onChange={(event) => selectAccount(Number(event.target.value))}
        label={'Account'}
      >
        {accounts &&
          accounts.map((item) => (
            <MenuItem key={item.accountId ?? 0} value={item.accountId ?? '-1'}>
              {item.accountNumber}
              {item.accountNumber && item.accountName ? ' - ' : ''}
              {item.accountName}
            </MenuItem>
          ))}
      </WO2Select>
    </FormControl>
  );
}

export default AccountSelect;
