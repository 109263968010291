import {
  Backdrop,
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Fade,
  Modal,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import WO2Button from 'src/common/components/button/Button';
import { useStyles } from 'src/themes';
import { ReportTemplate } from '../store';

export interface Props {
  row: ReportTemplate | null;
  handleImport: (name: string, file?: File) => void;
  handleClose: () => void;
  isOpen: boolean;
}

export const ReportTemplateModal = (props: Props): JSX.Element => {
  const [acceptedFiles, setAcceptedFiles] = useState<File[]>([]);
  const [importErrors, setImportErrors] = useState<string>();
  const [name, setName] = useState<string>('');
  const classes = useStyles();

  const file = acceptedFiles.map((selectedFile) => (
    <span key={selectedFile.name}>
      {selectedFile.name} - {selectedFile.size} bytes
    </span>
  ));

  const handleValidFile = (files: File[]) => {
    if (files[0].size / 1000000 > 50) {
      setImportErrors('Attachment has exceeded size limit (50MB)');
    } else if (files[0].name.indexOf('.docx') < 0) {
      setImportErrors('Only docx documents can be uploaded.');
    } else {
      setImportErrors('');
    }
    setAcceptedFiles(files);
  };

  useEffect(() => {
    setAcceptedFiles([]);
    setImportErrors('');
  }, [props.isOpen]);

  useEffect(() => {
    setName(props.row?.name ?? '');
  }, [props.row, props.isOpen]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={props.isOpen}
      onClose={() => {
        props.handleClose();
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={props.isOpen}>
        <Card
          elevation={0}
          sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', padding: 1 }}
        >
          <CardHeader title={<Typography variant="h3">{props.row ? 'Update' : 'Upload'} Report Template</Typography>} />
          <CardContent>
            <Box display="flex" flexDirection="column" gap={2}>
              <TextField placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} />

              <Dropzone onDrop={(files) => handleValidFile(files)} multiple={false}>
                {({ getRootProps, getInputProps }) => (
                  <Box
                    component="div"
                    data-testid="dropzone"
                    {...getRootProps({ className: classes.dropzone })}
                    margin={0}
                  >
                    <input
                      {...getInputProps({
                        accept: '.docx',
                        type: 'file',
                      })}
                    />
                    <p>Drag and drop your file here, or click to select file</p>
                  </Box>
                )}
              </Dropzone>
              {acceptedFiles.length > 0 && (
                <Box display="flex" flexDirection="column" gap={1}>
                  <Typography>File: {file}</Typography>
                  {importErrors && importErrors.length > 0 && <Typography color={'error'}>{importErrors}</Typography>}
                </Box>
              )}
            </Box>
          </CardContent>
          <CardActions>
            <Box sx={{ flex: 1 }} />
            <Box display="flex" gap={2}>
              <WO2Button
                variant="outlined"
                onClick={() => {
                  props.handleClose();
                  setImportErrors('');
                  setAcceptedFiles([]);
                }}
              >
                Cancel
              </WO2Button>
              <WO2Button
                variant="contained"
                color="primary"
                disabled={acceptedFiles.length < 1 || name.length === 0}
                onClick={() => {
                  if (acceptedFiles && acceptedFiles.length === 1) {
                    const file = acceptedFiles.length === 1 ? acceptedFiles[0] : undefined;
                    props.handleImport(name, file);
                    acceptedFiles.length = 0;
                    acceptedFiles.splice(0, acceptedFiles.length);
                  }
                }}
              >
                {props.row ? 'Update' : 'Upload'}
              </WO2Button>
            </Box>
          </CardActions>
        </Card>
      </Fade>
    </Modal>
  );
};
