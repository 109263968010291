import { Typography } from '@mui/material';
import React, { useState } from 'react';
import { LoadingIndicator } from 'src/common/components/LoadingIndicator';
import { SuperSimplifierDetails } from 'src/features/clients/client/common/store/types';
import { Props } from '../../container';
import { DetailsForm } from './detailsForm';
import { MemberDetails } from './memberDetails';

export const SuperSimplifierSummary = ({
  clientId,
  details,
  hasClientEditPermission,
  hasClientEditAdminOnlyPermission,
  loadingProgress,
  updateSuperSimplifier,
  savingProgress,
  status,
}: Props): JSX.Element => {
  const [readonly, setReadonly] = useState<boolean>(true);

  const onSave = (details: SuperSimplifierDetails) => {
    if (!!clientId) {
      updateSuperSimplifier({ details: { ...details, clientId: clientId }, message: 'Super Simplifier details saved' }).then(() => {
        setReadonly(true);
      });
    }
  };

  return (
    <>
      <Typography variant="h3" style={{ padding: '20px 0' }}>
        Super Simplifier Details
      </Typography>
      <LoadingIndicator progress={loadingProgress}>
        {!!details && (
          <>
            <MemberDetails data-testid="memberDetailsComponent" details={details}></MemberDetails>
            <DetailsForm
              data-testid="detailsFormComponent"
              details={details}
              readonly={readonly}
              savingProgress={savingProgress}
              hasClientEditPermission={hasClientEditPermission}
              hasClientEditAdminOnlyPermission={hasClientEditAdminOnlyPermission}
              status={status}
              onSave={onSave}
              onToggleEditMode={() => setReadonly(!readonly)}
            ></DetailsForm>
          </>
        )}
      </LoadingIndicator>
    </>
  );
};
