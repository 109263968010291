import { createSelector } from '@reduxjs/toolkit';
import { selectWorkflowsState } from '../../store/selectors';

export const selectListState = createSelector(selectWorkflowsState, (workflowsState) => workflowsState.list);
export const selectParameters = createSelector(selectListState, (listState) => listState.parameters);
export const selectWorkflowListResults = createSelector(selectListState, (listState) => listState?.workflows);
export const selectPagination = createSelector(selectParameters, (parameters) => parameters.pagination);
export const selectImportErrors = createSelector(selectListState, (workflowsState) => workflowsState.importErrors);
export const selectImportUploadSuccess = createSelector(
  selectListState,
  (workflowsState) => workflowsState.importUploadSuccess
);
export const selectIsImporting = createSelector(selectListState, (workflowsState) => workflowsState.isImporting);
export const selectedWorkflow = createSelector(selectListState, (workflowsState) => workflowsState.selectedWorkflow);
export const selectedWorkflowError = createSelector(selectListState, (workflowsState) => workflowsState.workflowError);
export const selectPensionAccounts = createSelector(selectListState, (workflowsState) => workflowsState.pensionAccounts);
