import { createSelector } from '@reduxjs/toolkit';
import { selectEmail } from '../../selectors';

export const sendEmail = createSelector(selectEmail, (emailState) => emailState.send);
export const parameters = createSelector(sendEmail, (sendState) => sendState.parameters);
export const topParameters = createSelector(sendEmail, (sendState) => {
  return sendState.parameters.slice(0, 2);
});
export const emailTemplates = createSelector(sendEmail, (emailTemplate) => emailTemplate.emailTemplates);
export const clients = createSelector(sendEmail, (emailTemplate) => emailTemplate.clients);
export const clientCounts = createSelector(sendEmail, (emailTemplate) => emailTemplate.clients.length);
export const topClients = createSelector(sendEmail, (emailTemplate) => {
  return emailTemplate.clients.slice(0, 2);
});
export const selectSender = createSelector(sendEmail, (sendState) => sendState.sender);
