import { Backdrop, Fade, Grid, Modal, Paper, Typography } from '@mui/material';
import { Field, FieldProps, Form, Formik, FormikProps } from 'formik';
import React, { useEffect, useState } from 'react';
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';
import { ToggleButtonItem } from 'src/common';
import { LoadingProgress } from 'src/common/store/types';
import * as yup from 'yup';
import { EditCancelSaveButtons, FormikNumberFormat, FormikTextField, FormikToggleButton, Mode } from '../../../../../../common/components/formik';
import { AddressType } from '../../store/enums';
import { AddressDetails } from '../../store/types';
interface Props {
  address: AddressDetails | null;
  placeholder?: string;
  availableAddressTypes: AddressType[];
  isOpen: boolean;
  savingProgress: LoadingProgress;
  onSave: (details: AddressDetails) => void;
  handleCloseModal: () => void;
}

export default function Address(props: Props): JSX.Element {
  const { address, placeholder, isOpen, onSave, handleCloseModal, availableAddressTypes, savingProgress } = props;

  const [selectedAddress, setAddress] = useState<string>('');
  const [selectedAddressTypes, setSelectedAddressTypes] = useState<number[]>(address?.addressTypeIds || []);
  const [addressDetails, setAddressDetails] = useState<AddressDetails>(
    (address as AddressDetails) ??
      ({
        addressId: null,
        line1: '',
        line2: '',
        suburb: '',
        state: '',
        postcode: '',
        country: '',
        addressTypeIds: [],
      } as AddressDetails)
  );
  const addressTypeToggleButtons: ToggleButtonItem<number>[] = AddressType.getArray().map((addressType) => ({
    value: addressType.id,
    selected: selectedAddressTypes.includes(addressType.id),
    disabled:
      !availableAddressTypes.includes(addressType) &&
      !selectedAddressTypes.includes(addressType.id) &&
      !(address?.addressTypeIds || []).includes(addressType.id),
    name: addressType.name,
  }));

  useEffect(() => {
    if (address) {
      setAddressDetails(address);
      setAddress(address.line1);
    }
  }, [address]);

  // FIXME: Declare react-google-places-autocomplete types
  type GooglePlacesAutoCompleteAddress = {
    value: {
      place_id: string;
    };
  } & AddressDetails;
  const handleSelect = async (address: GooglePlacesAutoCompleteAddress, details: AddressDetails) => {
    geocodeByPlaceId(address.value.place_id)
      .then((results) => {
        setAddress(address.line1);
        setAddressDetails({
          addressId: addressDetails.addressId,
          line1: [
            results[0].address_components.find((c) => c.types.includes('street_number'))?.long_name || '',
            ' ',
            results[0].address_components.find((c) => c.types.includes('route'))?.long_name || '',
          ].join(''),
          line2: '',
          suburb: results[0].address_components.find((c) => c.types.includes('locality'))?.long_name || '',
          state: results[0].address_components.find((c) => c.types.includes('administrative_area_level_1'))?.short_name || '',
          postcode: results[0].address_components.find((c) => c.types.includes('postal_code'))?.long_name || '',
          country: results[0].address_components.find((c) => c.types.includes('country'))?.long_name || '',
          addressTypeIds: details.addressTypeIds,
        });
      })
      .catch((error) => console.error(error));
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={isOpen}
      onClose={handleCloseModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={isOpen}>
        <Paper
          elevation={0}
          style={{
            width: '800px',
            minHeight: '70vh',
            maxHeight: '600px',
            padding: '40px 40px',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <Formik<AddressDetails>
            initialValues={addressDetails}
            onSubmit={(details) => {
              onSave(details);
            }}
            enableReinitialize={true}
            validationSchema={yup.object({
              line1: yup.string().required('Line 1 required'),
              state: yup.string().required('State required'),
              line2: yup.string().nullable(),
              postcode: yup
                .string()
                .required('Postcode required')
                .matches(/^[0-9]{4}$/, 'Postcode must be 4 digits'),
              suburb: yup.string().required('Suburb required'),
              country: yup.string().required('Country required'),
              addressTypeIds: yup.array().required('Address type required'),
            })}
          >
            {(props: FormikProps<AddressDetails>) => (
              <Form>
                <fieldset style={{ border: 'none', padding: '0' }}>
                  <Typography variant="h3" style={{ padding: '20px 0 40px 0' }}>
                    Address
                  </Typography>
                  <Grid container>
                    <Grid item xs={12} style={{ paddingBottom: '20px', minHeight: '90px' }}>
                      <GooglePlacesAutocomplete
                        apiKey="AIzaSyAx6ksqLHo2EB5cn5nTIpw82NEBw1sE03A"
                        withSessionToken
                        autocompletionRequest={{
                          types: ['address'],
                          componentRestrictions: {
                            country: ['au'],
                          },
                        }}
                        selectProps={{
                          value: selectedAddress,
                          placeholder,
                          onChange: (newAddress: GooglePlacesAutoCompleteAddress) => handleSelect(newAddress, props.values),
                          styles: {
                            valueContainer: (provided: React.CSSProperties) => ({
                              ...provided,
                              height: '48px',
                            }),
                            menu: (provided: React.CSSProperties) => ({
                              ...provided,
                              zIndex: 2,
                            }),
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={6} style={{ paddingRight: '20px' }}>
                      <Grid item xs={12} style={{ paddingBottom: '20px', minHeight: '90px' }}>
                        <Field name="line1" component={FormikTextField} label="LINE 1" fullWidth></Field>
                      </Grid>
                      <Grid item xs={12} style={{ paddingBottom: '20px', minHeight: '90px' }}>
                        <Field name={'line2'} component={FormikTextField} label="LINE 2" fullWidth></Field>
                      </Grid>
                      <Grid item xs={12} style={{ paddingBottom: '20px', minHeight: '90px' }}>
                        <Field name={'suburb'} component={FormikTextField} label="SUBURB" fullWidth></Field>
                      </Grid>
                      <Grid item xs={12} style={{ paddingLeft: '10px', minHeight: '90px' }}>
                        <Field
                          component={FormikToggleButton}
                          exclusive={false}
                          buttons={addressTypeToggleButtons}
                          name="addressTypeIds"
                          onChangeHandler={(addressTypeIds: number[]) => {
                            setSelectedAddressTypes(addressTypeIds);
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid item xs={12} style={{ paddingBottom: '20px', minHeight: '90px' }}>
                        <Field name="state" component={FormikTextField} label="STATE" fullWidth></Field>
                      </Grid>
                      <Grid item xs={12} style={{ paddingBottom: '20px', minHeight: '90px' }}>
                        <Field name="postcode" label="POSTCODE" fullWidth>
                          {(fieldProps: FieldProps) => {
                            return (
                              <FormikNumberFormat
                                formikFieldProps={fieldProps}
                                fullWidth={true}
                                numberFormatProps={{
                                  format: '####',
                                  mask: '_',
                                  placeholder: '',
                                  name: 'postcode',
                                  label: 'POSTCODE',
                                  isNumericString: true,
                                }}
                              ></FormikNumberFormat>
                            );
                          }}
                        </Field>
                      </Grid>
                      <Grid item xs={12} style={{ paddingBottom: '20px', minHeight: '90px' }}>
                        <Field name={'country'} component={FormikTextField} label="COUNTRY" fullWidth></Field>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <EditCancelSaveButtons mode={Mode.CancelSave} handleCancelClick={handleCloseModal} saveProgress={savingProgress}></EditCancelSaveButtons>
                    </Grid>
                  </Grid>
                </fieldset>
              </Form>
            )}
          </Formik>
        </Paper>
      </Fade>
    </Modal>
  );
}
