import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../reducers';

export const selectStrategy = createSelector(
  (state: RootState) => state,
  (state) => state.strategy
);

export const selectStrategyAssociationsState = createSelector(selectStrategy, (strategyState) => strategyState);

export const selectStrategyAssociations = createSelector(selectStrategyAssociationsState, (listState) => listState.strategyAssociations);

export const selectStrategyCompositionState = createSelector(selectStrategy, (state) => state.strategyComposition);
