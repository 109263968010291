export function validateTfn(tfn: string | null | undefined): boolean {
  if (!tfn || tfn === '') {
    return false;
  }

  // remove spaces
  // remove hyphens
  const rawValue = tfn.replace(/[\s-]/g, '');

  // validation logic:
  //   * multiply each digit in the TFN by the position weight (these weightings are different for TFNs in 8-digit vs 9-digit format)
  //   * sum the result of each multiplication to get the total
  //   * divide the total by 11
  //     * if the result is a whole number, then the TFN is valid
  //     * if the result is not a whole number, then the TFN is invalid

  let weight: number[];

  // which TFN format are we dealing with?
  if (rawValue.length === 8) {
    // legacy format (8 digits)

    weight = [10, 7, 8, 4, 6, 3, 5, 1];
  } else if (rawValue.length === 9) {
    // modern format (9 digits)

    weight = [1, 4, 3, 7, 5, 8, 6, 9, 10];
  } else {
    return false; // invalid or unsupported format (wrong number of digits)
  }

  const digits = rawValue.split('');
  let sumOfNumbers = 0;

  const hasNonNumericCharacter = digits.some((value: string, position: number) => {
    const toDigit = parseInt(value, 10);
    if (isNaN(toDigit)) {
      return true; // non-numeric character
    }

    // multiply number by weighted position
    sumOfNumbers += toDigit * weight[position];
  });

  if (hasNonNumericCharacter) {
    return false;
  }

  // sum of numbers must be evenly divisible by 11
  if (sumOfNumbers % 11 !== 0) {
    return false;
  }

  return true;
}
