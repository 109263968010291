import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { progressSelectorFactory } from 'src/common/store/selectors/loadingProgressSelector';
import { RootState } from '../../../../reducers';
import { Results } from './components/results';
import { selectSearchResults, selectSearchSelectedResults } from './store/selectors';
import { searchSlice } from './store/slice';
import { OrderSearchActionTypes } from './store/thunks';

const mapStateToProps = (state: RootState) => ({
  results: selectSearchResults(state),
  selectedResults: selectSearchSelectedResults(state),
  isLoadingProgress: progressSelectorFactory([OrderSearchActionTypes.FetchResults])(state),
});

const mapDispatchToProps = {
  setSelectedResults: searchSlice.actions.setSelectedResults,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector> & RouteComponentProps;
export const OrderSearchResultsContainer = connector(Results);
