import { FormControl, InputLabel, MenuItem, OutlinedInput } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { Enumeration } from '../types';
import { WO2Select } from './../components/Select';

interface Props {
  label?: string;
  type: typeof Enumeration;
  value: string | number | null;
  none?: boolean;
  onChange: (value: string | number | null) => void;
  disabled?: boolean;
  datatestid?: string;
  onBlur?: () => void;
  hasValidationError?: boolean;
  valueIsId?: boolean;
}
interface EnumListItem {
  id: number;
  name: string;
  label: string;
}

export function EnumerationSelect(props: Props): JSX.Element {
  const inputLabel: React.MutableRefObject<HTMLLabelElement | null> = useRef(null);
  const { label, value, type, onChange, none, datatestid, onBlur, hasValidationError, valueIsId } = props;
  const showNone = none ?? true;

  const [items, setItems] = useState<EnumListItem[]>([]);

  useEffect(() => {
    setItems(
      Object.values(props.type)
        .filter((t) => t instanceof props.type)
        .map((val) => {
          return { id: val.id, name: val.name, label: val.displayName };
        })
    );
  }, [props.type]);

  const handleChange = (value: string | number | null) => {
    if (value?.toString() === '') {
      value = null;
    }
    onChange(value);
  };

  return (
    <FormControl variant="outlined" fullWidth error={hasValidationError || false}>
      <InputLabel shrink ref={inputLabel} id="enumeration-select-label" htmlFor="outlined-age-always-notched" disabled={props.disabled || false}>
        {label}
      </InputLabel>
      <WO2Select
        displayEmpty
        name={`enumeration-select-${type.name}`}
        labelId="enumeration-select-label"
        value={items.length > 0 ? value || '' : ''}
        onChange={(event) => {
          const item = valueIsId ? items.find((i) => i.id === Number(event.target.value)) : items.find((i) => i.name === event.target.value);

          if (!!item) {
            const itemValue = valueIsId ? item.id : item.name;
            handleChange(itemValue);
          } else {
            handleChange(null);
          }
        }}
        id={type.name}
        onBlur={onBlur}
        disabled={props.disabled || false}
        inputProps={{
          'data-testid': datatestid || `enumeration-select-${type.name}`,
        }}
        input={<OutlinedInput notched label={label} name={label} id="outlined-age-always-notched" />}
      >
        {showNone && <MenuItem value="">(None)</MenuItem>}
        {items.map((val, index) => {
          return (
            <MenuItem key={index} value={valueIsId ? val.id : val.name}>
              {val.label}
            </MenuItem>
          );
        })}
      </WO2Select>
    </FormControl>
  );
}
