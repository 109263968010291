import { Add, Info, Sync, WarningRounded } from '@mui/icons-material';
import { Alert, Box, Card, CardContent, FormControl, Grid, InputLabel, MenuItem, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useCallback, useEffect, useState } from 'react';
import { formatNumberCommaSeparated, WO2Select } from 'src/common';
import { LoadingIndicator } from 'src/common/components/LoadingIndicator';
import { ClientAccountStatus } from 'src/common/types/clientAccountStatus';
import { ClientAccountSubType } from 'src/common/types/clientAccountSubType';
import { MonthType } from 'src/common/types/monthType';
import WO2Button from '../../../../../../common/components/button/Button';
import {
  calculateActiveAdviceFeesItems,
  calculateAdviceEstimatedFeesItems,
} from '../../../../common/utils/calculation';
import { feeConsentDocumentTypes } from '../../../common/store/types';
import { AdviceFeesService } from '../adviceFeesService';
import { Props } from '../container';
import {
  AdviceEstimatedFee,
  FeeConsentMethod,
  PendingFeeActionType,
  PendingFeesChangeSetStatus,
  UploadFeeConsentValues,
} from '../store/types';
import { AdviceFeeDetailsEdit } from './adviceFeeDetailsEdit';
import { AdviceFeesTable } from './adviceFeesTable';
import { UploadFeeConsent } from './uploadFeeConsent';
import { AdviceFeesConsent } from './adviceFeesConsent';

export const useStyles = makeStyles(() => ({
  gridContainer: {
    margin: '20px 0 20px',
  },
  gridItem: {
    marginRight: '20px !important',
  },
  card: {
    borderRadius: '4px !important',
  },
  cardContent: {
    padding: '12px 16px !important',
    textAlign: 'left',
    '& h2': {
      fontWeight: 600,
      marginBottom: '8px',
    },
  },
}));

export const AdviceFees = (props: Props): JSX.Element => {
  const {
    clientId,
    superSimplifierItem,
    clientPortfolioDetail,
    pendingFeesChangeSet,
    pendingFeesItems,
    activeFeesItems,
    selectedFeeItem,
    tieredFeeDetailsItems,
    selectedTieredFeeDetailsEdit,
    setPendingFeeEdit,
    setActiveFeeEdit,
    setTieredFeeDetailsEditId,
    setTieredFeeDetailsAdd,
    saveEditingTieredFeeDetails,
    removeEditingTieredFeeDetails,
    clearEditingTieredFeeDetails,
    toggleAdviceFeeConsent,
    hasClientEditAdminOnlyPermission,
    clientMainDetails,
    documentTypes,
    loadingProgress,
    isDataFromTriumph,
    adviceServicesList,
    isEditingAdviceServiceList,
    afslFeesList,
    afslId,
    fetchPendingAdviceFees,
    fetchActiveAdviceFees,    
    loadAdviceFees,
    createAdviceFee,
    updateAdviceFee,
    deleteAdviceFee,
    removePendingFee,
    fetchClientPortfolioDetails,
    fetchDocumentTypes,
    activateFeeApplicationDocusign,
    activateFeeApplicationUpload,
    activateFeeRenewalDocusign,
    activateFeeRenewalUpload,
    activateFees,
    cancelDocusignFeesApplicationActivation,
    cancelDocusignFeesRenewalActivation,
    downloadSuperSimplifierOngoingFeeConsentRenewal,
    downloadSuperSimplifierApplicationFeeConsent,
    fetchAdviceServicesList,
    toggleEditingAdviceServiceList,
    updateSuperSimplifierClientAdviceService,
    fetchAFSLFeesList,
    createFee,
    advisor
  } = props;

  const classes = useStyles();
  const [isViewMode, setViewMode] = useState<boolean>(false);
  const [feeDetailsEditOpen, setFeeDetailsEditOpen] = useState<boolean>(false);
  const [feeConsentFormOpen, setFeeConsentFormOpen] = useState<boolean>(false);
  const [hasLoadedFees, setHasLoadedFees] = useState<boolean>(false);
  const [unavailableFeeCalculationTypes, setUnavailableFeeCalculationTypes] = useState<number[]>([]);
  const [resetSelectedFeeFields, setResetSelectedFeeFields] = useState<boolean>(false);
  const [feeDetailsCreateOpen, setFeeDetailsCreateOpen] = useState<boolean>(false);
  const isSuperOrPension = !!clientMainDetails ? [2, 3].includes(clientMainDetails.subTypeId ?? 0) : undefined;
  const [selectedFeeId, setSelectedFeeId] = useState<number>();
  const [disableAdd, setDisableAdd] = useState(true);

  useEffect(() => {
    if (!!clientId) {
      fetchPendingAdviceFees({ clientId });
      fetchActiveAdviceFees({ clientId });
      fetchClientPortfolioDetails({
        clientId: clientId,
        parameters: {
          selectedHolding: null,
        },
        isDataFromTriumph: isDataFromTriumph,
      });
      if (isSuperOrPension) {
        fetchAdviceServicesList({ clientId });
      }
      fetchDocumentTypes();
    }
  }, [
    fetchPendingAdviceFees,
    fetchActiveAdviceFees,
    fetchAdviceServicesList,
    fetchClientPortfolioDetails,
    fetchDocumentTypes,
    fetchAFSLFeesList,
    clientId,
    isDataFromTriumph,
    isSuperOrPension
  ]);

  useEffect(() => {
    fetchAFSLFeesList({afslId: afslId ?? 0});
  }, [afslId, fetchAFSLFeesList]);

  useEffect(() => {
    const removedActiveFeeTypes = pendingFeesItems
      .filter((item) => item.pendingAction === PendingFeeActionType.Delete.name)
      .map((item) => item.calculationTypeId);
    const pendingFeeTypes = pendingFeesItems
      .filter(
        (item) => selectedFeeItem?.feeId !== item.feeId && item.pendingAction === PendingFeeActionType.Create.name
      )
      .map((item) => item.calculationTypeId ?? 0);
    const activeFeeTypes = activeFeesItems
      .filter((item) => !removedActiveFeeTypes.includes(item.calculationTypeId))
      .map((item) => item.calculationTypeId ?? 0);
    setUnavailableFeeCalculationTypes([...pendingFeeTypes, ...activeFeeTypes]);
  }, [selectedFeeItem, activeFeesItems, pendingFeesItems, setUnavailableFeeCalculationTypes]);

  const currentDayOfMonth = new Date().getDate();
  const currentMonth = MonthType.getById(new Date().getMonth());
  const previousMonth = MonthType.getById(currentMonth !== null && currentMonth.id > 0 ? currentMonth.id - 1 : 11);
  const isActiveClient = !!clientMainDetails ? clientMainDetails.status === 2 : undefined;
  const isPendingSuperPensionClient = (!isActiveClient && isSuperOrPension)

  const isPendingFeesInProgress = [
    '' + PendingFeesChangeSetStatus.InProgress,
    '' + PendingFeesChangeSetStatus.Locked,
  ].includes(pendingFeesChangeSet?.status ?? '');

  const isAdvisor = !isSuperOrPension && advisor && advisor.advisorId && !hasClientEditAdminOnlyPermission;

  const totalRolloverAmount =
    (superSimplifierItem?.rolloverAmount ?? 0) +
    (superSimplifierItem?.rolloverAmountSecond ?? 0) +
    (superSimplifierItem?.rolloverAmountThird ?? 0);
  const contributionsAmount = superSimplifierItem?.contributionsAmount ?? 0;
  const marketValue = clientPortfolioDetail?.holdings?.marketValue ?? 0;
  const availableCash = clientPortfolioDetail?.holdings?.availableCash ?? 0;

  useEffect(() => {
    if (!hasLoadedFees && !!pendingFeesChangeSet && clientId && !isPendingFeesInProgress) {
      loadAdviceFees(clientId);
      setHasLoadedFees(true);
    }
  }, [setHasLoadedFees, loadAdviceFees, clientId, isPendingFeesInProgress, pendingFeesChangeSet]);

  const handleCancelFeeEditClick = useCallback(() => {
    setPendingFeeEdit(undefined);
    setFeeDetailsCreateOpen(false);
  }, [setPendingFeeEdit]);
  
  const handelDeleteFeeClick = (adviceEstimatedFee: AdviceEstimatedFee) => {
    if (!!clientId) {
      deleteAdviceFee({ adviceEstimatedFee: adviceEstimatedFee, clientId: clientId, message: 'Advice Fee deleted' });
    }
  };

  const handelRemoveFeeClick = (adviceEstimatedFee: AdviceEstimatedFee) => {
    if (!!clientId) {
      if (adviceEstimatedFee.hasEverBeenActive) {
        removePendingFee({
          adviceEstimatedFee: adviceEstimatedFee,
          clientId: clientId,
          message: 'Pending Fee removed',
        });
      } else {
        deleteAdviceFee({ adviceEstimatedFee: adviceEstimatedFee, clientId: clientId, message: 'Pending Fee deleted' });
      }
    }
  };

  const handleSaveFeeEditClick = async (feeDetails: AdviceEstimatedFee) => {
    if (!!clientId) {
      if (!!selectedFeeItem && !resetSelectedFeeFields) {
        updateAdviceFee({
          clientId: clientId,
          requests: AdviceFeesService.MapEstimatedFeesToUpdateAdviceFeeRequests(
            feeDetails,
            totalRolloverAmount,
            contributionsAmount,
            clientId
          ),
          message: 'Advice Fee updated',
        });
      } else {
        createAdviceFee({
          clientId: clientId,
          requests: AdviceFeesService.MapEstimatedFeesToCreateAdviceFeeRequests(
            feeDetails,
            totalRolloverAmount,
            contributionsAmount,
            clientId
          ),
          message: 'Advice Fee added',
        });
      }
    }
  };

  const handleAddClick = () => {    
    if (!!clientId) {
        createFee({
          clientId: clientId,
          feeId: Number(selectedFeeId)
        });
    }
  };

  const handleActivatePendingFeesClick = async (uploadFeeConsentValues: UploadFeeConsentValues) => {
    if (!!clientId && !!clientMainDetails && !!pendingFeesItems && pendingFeesItems.length > 0) {
      if (!uploadFeeConsentValues.isSuperOrPension) {
        await activateFees({
          clientId: clientId,
          feeChangeSetId: pendingFeesChangeSet?.changeSetId ?? 0,
          message: 'Pending fees activated',
        });
      } else if (uploadFeeConsentValues.feeConsentTypeId === FeeConsentMethod.SendViaDocusign.id) {
        if (clientMainDetails.status == ClientAccountStatus.Pending.id) {
          await activateFeeApplicationDocusign({
            clientId: clientId,
            isIndividualSuper: clientMainDetails.subTypeId === ClientAccountSubType.Super.id,
            isIndividualPension: clientMainDetails.subTypeId === ClientAccountSubType.Pension.id,
            message: 'Pending fees activation process started',
          });
        } else {
          await activateFeeRenewalDocusign({ clientId: clientId, message: 'Pending fees activation process started' });
        }
      } else if (uploadFeeConsentValues.feeConsentTypeId === FeeConsentMethod.UploadDocument.id) {
        if (clientMainDetails.status == ClientAccountStatus.Pending.id) {
          await activateFeeApplicationUpload({
            clientId: clientId,
            isIndividualSuper: clientMainDetails.subTypeId === ClientAccountSubType.Super.id,
            isIndividualPension: clientMainDetails.subTypeId === ClientAccountSubType.Pension.id,
            feeChangeSetId: pendingFeesChangeSet?.changeSetId ?? 0,
            uploadFeeConsentValues: uploadFeeConsentValues,
            message: 'Pending fees activation process started',
          });
        } else {
          await activateFeeRenewalUpload({
            clientId: clientId,
            feeChangeSetId: pendingFeesChangeSet?.changeSetId ?? 0,
            uploadFeeConsentValues: uploadFeeConsentValues,
            message: 'Pending fees activated',
          });
        }
      }
    }
  };

  const handleClickSaveAdviceServiceList = (adviceServices: string, adviceServicesOtherText?: string, saveQuietly?: boolean) => {
    if (!clientId || !isSuperOrPension) {
      return;
    }
    updateSuperSimplifierClientAdviceService({ 
      clientId: clientId, 
      adviceServices: adviceServices, 
      adviceServicesOtherText: adviceServicesOtherText,
      saveQuietly: saveQuietly 
    });
  };

  const handleCancelPendingFeesClick = async () => {
    if (!!clientId) {
      if (isActiveClient) {
        await cancelDocusignFeesRenewalActivation({ clientId: clientId, message: 'Advice Fees activation canceled' });
      } else {
        await cancelDocusignFeesApplicationActivation({
          clientId: clientId,
          message: 'Advice Fees activation canceled',
        });
      }
    }
  };

  const getCalculatedFeeItems = (fees: AdviceEstimatedFee[]): AdviceEstimatedFee[] => {
    return isActiveClient
      ? calculateActiveAdviceFeesItems(fees, marketValue, availableCash, isSuperOrPension === true, false)
      : calculateAdviceEstimatedFeesItems(
          fees,
          totalRolloverAmount,
          contributionsAmount,
          isSuperOrPension === true,
          false
        );
  };

  const getPendingFeeItems = (): void => {
    const removedActiveFeeTypes = pendingFeesItems
      .filter((item) => item.pendingAction === PendingFeeActionType.Delete.name)
      .map((item) => item.calculationTypeId);

    const pendingFeeTypes = pendingFeesItems
      .filter(
        (item) => selectedFeeItem?.feeId !== item.feeId && item.pendingAction === PendingFeeActionType.Create.name
      )
      .map((item) => item.calculationTypeId ?? 0);

    if (feeDetailsCreateOpen) {
      pendingFeeTypes.push(selectedFeeItem?.calculationTypeId as number);
    } else {
      const index = pendingFeeTypes.indexOf(selectedFeeItem?.calculationTypeId as number);
      if (index > -1) {
        pendingFeeTypes.splice(index, 1);
      }
    }
    const activeFeeTypes = activeFeesItems
      .filter((item) => !removedActiveFeeTypes.includes(item.calculationTypeId))
      .map((item) => item.calculationTypeId ?? 0);
    setUnavailableFeeCalculationTypes([...pendingFeeTypes, ...activeFeeTypes]);
    setFeeDetailsCreateOpen(false);
  };

  const calculatedPendingFeeItems = getCalculatedFeeItems(pendingFeesItems);
  const hasValidPendingFees =
    calculatedPendingFeeItems.length > 0 && !calculatedPendingFeeItems.find((fee) => !fee.isValid);

  const handleAdviceFeeConsentToggle = (id: number) => {
    toggleAdviceFeeConsent(id)
  }

  const toggleAdviceServiceList = () => toggleEditingAdviceServiceList();

  return (
    <div style={{ width: '100%', maxWidth: '1120px' }}>
      <Typography variant="h3" style={{ padding: '20px 0' }}>
        Advice Fees
      </Typography>
      <LoadingIndicator progress={loadingProgress}>
        <>
          <Box
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
            margin={'60px 0 30px'}
            minHeight={'50px'}
          >
            <Box>
              <Typography variant="inherit">Estimated rollover &amp; contribution amounts</Typography>
              {isActiveClient === false && (
                <>
                  <Grid container className={classes.gridContainer}>
                    <Grid item className={classes.gridItem} data-testid="totalEstimatedRollover">
                      <Card variant="outlined" className={classes.card}>
                        <CardContent
                          classes={{
                            root: classes.cardContent,
                          }}
                        >
                          <Typography variant="h5" component="h2">
                            Total Estimated Rollover
                          </Typography>
                          <Typography variant="h5" component="p" noWrap>
                            ${formatNumberCommaSeparated(totalRolloverAmount, 2)}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item className={classes.gridItem} data-testid="estimatedAnnualContributions">
                      <Card variant="outlined" className={classes.card}>
                        <CardContent
                          classes={{
                            root: classes.cardContent,
                          }}
                        >
                          <Typography variant="h5" component="h2">
                            Estimated Annual Contributions
                          </Typography>
                          <Typography variant="h5" component="p" noWrap>
                            ${formatNumberCommaSeparated(contributionsAmount, 2)}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </>
              )}
              {isActiveClient && (
                <Grid container className={classes.gridContainer}>
                  <Grid item className={classes.gridItem} data-testid="totalMarketValue">
                    <Card variant="outlined" className={classes.card}>
                      <CardContent
                        classes={{
                          root: classes.cardContent,
                        }}
                      >
                        <Typography variant="h5" component="h2">
                          Total Market Value
                        </Typography>
                        <Typography variant="h5" component="p" noWrap>
                          ${formatNumberCommaSeparated(marketValue, 2)}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              )}
            </Box>
            <Box>
              <Alert severity="info" style={{ margin: '10px' }} icon={<Info></Info>}>
                <Typography variant="h5">Portfolio and ongoing fees are charged in whole months only.</Typography>
              </Alert>
            </Box>             
          </Box>
          {isSuperOrPension && (
            <AdviceFeesConsent
              hasPendingFees={pendingFeesItems.some(fee => fee.pendingAction === PendingFeeActionType.Create.name)}
              clientId={clientId}
              isEditingAdviceServiceList={isEditingAdviceServiceList}
              handleAdviceFeeConsentToggle={handleAdviceFeeConsentToggle}
              adviceServicesList={adviceServicesList}
              toggleAdviceServiceList={toggleAdviceServiceList}
              handleClickSaveAdviceServiceList={handleClickSaveAdviceServiceList}
            />
          )}
          {!isAdvisor && (
            <>
              <Box
                display={'flex'}
                justifyContent={'space-between'}
                alignItems={'center'}
                margin={'60px 0 30px'}
                minHeight={'50px'}
              >
                <Box>
                  {!!pendingFeesItems && pendingFeesItems.length > 0 && (
                    <Typography variant="h2" style={{ fontSize: '18px' }} gutterBottom>
                      Pending Fees (incl. GST)
                    </Typography>
                  )}
                </Box>
                {(
                  <Box data-testid="feeButtonsBox">
                    {hasClientEditAdminOnlyPermission && isPendingFeesInProgress && (
                      <WO2Button
                        color={'primary'}
                        style={{ padding: '0 30px' }}
                        variant={'contained'}
                        value="Cancel DocuSign"
                        type={'button'}
                        data-testid="cancelFeesButton"
                        onClick={handleCancelPendingFeesClick}
                      >
                        <Sync></Sync>
                        <Typography variant="inherit" style={{ marginLeft: '10px' }}>
                          Cancel DocuSign
                        </Typography>
                      </WO2Button>
                    )}
                    {!isPendingSuperPensionClient && (
                      <WO2Button
                        disabled={!hasValidPendingFees}
                        color={'primary'}
                        style={{ padding: '0 30px' }}
                        variant={'contained'}
                        value="Activate Fees"
                        type={'button'}
                        data-testid="activateFeesButton"
                        onClick={() => {
                          setViewMode(false);
                          setFeeConsentFormOpen(true);
                        }}
                      >
                        <Sync></Sync>
                        <Typography variant="inherit" style={{ marginLeft: '10px' }}>
                          Activate
                        </Typography>
                      </WO2Button>
                    )}
                    <FormControl variant="outlined" style={{ marginLeft: '50px', width: '450px' }}>
                      <InputLabel id="afsl-select-label" >Fee Name</InputLabel>
                      <WO2Select
                            id="afsl-select"
                            labelId={'afsl-select-label'}
                            onChange={(event) => {
                              const selectedItem = afslFeesList.find((v) => v.feeCode === event.target.value);
                              setSelectedFeeId(selectedItem?.feeId);
                              if (selectedItem?.feeId !== undefined || selectedItem?.feeId !== null)
                                setDisableAdd(false);
                            }}
                            label={'Fee Name'}>
                            {afslFeesList.map((item) => (
                              <MenuItem key={item.feeId} value={item.feeCode}>
                                {item.feeName} ({item.feeCode})
                                  </MenuItem>
                          ))}
                      </WO2Select>
                    </FormControl>
                      <WO2Button
                      disabled={disableAdd}
                      style={{marginLeft: '30px', padding: '0 30px' }}
                      variant={'outlined'}
                      value="Create Fee"
                      type={'button'}
                      data-testid="createFeeButton"
                      onClick={() => {handleAddClick()}}
                    >
                      <Add></Add>
                      <Typography variant="inherit" style={{ marginLeft: '10px' }}>
                        ADD
                      </Typography>
                    </WO2Button>
                  </Box>
                )}
              </Box>
              {!!pendingFeesItems && pendingFeesItems.length > 0 && (
                <>
                  {currentDayOfMonth >= 1 && currentDayOfMonth <= 6 && (
                    <Alert severity="warning" style={{ margin: '10px' }} icon={<WarningRounded></WarningRounded>}>
                      <Typography variant="h5">
                        Monthly fee calculations are in progress. Changes activated before 6th{' '}
                        {currentMonth?.displayName} will take effect for the {previousMonth?.displayName} fee run.
                      </Typography>
                    </Alert>
                  )}
                  <AdviceFeesTable
                    estimatedFeesItems={getCalculatedFeeItems(pendingFeesItems)}
                    loadingProgress={{ isLoading: false, hasErrors: false }}
                    onDelete={isPendingFeesInProgress ? undefined : handelRemoveFeeClick}
                    onAddEdit={
                      isPendingFeesInProgress
                        ? undefined
                        : (FeeId) => {
                            setPendingFeeEdit(FeeId);
                            setViewMode(true);
                            setFeeDetailsEditOpen(true);
                            setResetSelectedFeeFields(false);
                            setFeeDetailsCreateOpen(false);
                          }
                    }
                    isSuperOrPension={isSuperOrPension}
                  />
                </>
              )}
              <Box
                display={'flex'}
                justifyContent={'space-between'}
                alignItems={'center'}
                margin={'60px 0 30px'}
                minHeight={'50px'}
              >
                <Box>
                  <Typography variant="h2" style={{ fontSize: '18px' }} gutterBottom>
                    Active Fees (incl. GST)
                  </Typography>
                </Box>
              </Box>
              <AdviceFeesTable
                estimatedFeesItems={getCalculatedFeeItems(activeFeesItems)}
                loadingProgress={{ isLoading: false, hasErrors: false }}
                onDelete={isPendingFeesInProgress ? undefined : handelDeleteFeeClick}
                onAddEdit={(FeeId, isViewMode) => {
                  setActiveFeeEdit(FeeId);
                  setViewMode(isViewMode);
                  setFeeDetailsEditOpen(true);
                }}
                isSuperOrPension={isSuperOrPension}
              />
              {feeDetailsEditOpen && (
                <AdviceFeeDetailsEdit
                  isActive={isActiveClient}
                  isSuperOrPension={isSuperOrPension}
                  totalRolloverAmount={totalRolloverAmount}
                  contributionsAmount={contributionsAmount}
                  marketValue={marketValue}
                  availableCash={availableCash}
                  unavailableFeeCalculationTypes={unavailableFeeCalculationTypes}
                  estimatedFeesItems={pendingFeesItems}
                  selectedFeeItem={selectedFeeItem}
                  tieredFeeDetailsItems={tieredFeeDetailsItems}
                  selectedTieredFeeDetailsEdit={selectedTieredFeeDetailsEdit}
                  isViewMode={isViewMode}
                  onSave={handleSaveFeeEditClick}
                  onCancel={handleCancelFeeEditClick}
                  handleCloseModal={() => {
                    clearEditingTieredFeeDetails();
                    setFeeDetailsEditOpen(false);
                    setFeeDetailsCreateOpen(false);
                  }}
                  setTieredFeeDetailsEditId={setTieredFeeDetailsEditId}
                  setTieredFeeDetailsAdd={setTieredFeeDetailsAdd}
                  saveEditingTieredFeeDetails={saveEditingTieredFeeDetails}
                  removeEditingTieredFeeDetails={removeEditingTieredFeeDetails}
                  resetSelectedFeeFields={resetSelectedFeeFields}
                  getPendingFeeItems={getPendingFeeItems}
                />
              )}
              {feeConsentFormOpen && (
                <UploadFeeConsent
                  isViewMode={isViewMode}
                  isSuperOrPension={isSuperOrPension === true}
                  documentTypes={documentTypes.filter((documentType) =>
                    feeConsentDocumentTypes.includes(documentType.id)
                  )}
                  onSave={handleActivatePendingFeesClick}
                  handleCloseModal={() => {
                    setFeeConsentFormOpen(false);
                  }}
                  downloadFeeConsent={() => {
                    if (!!clientId && !!clientMainDetails) {
                      if (clientMainDetails.status === ClientAccountStatus.Pending.id) {
                        downloadSuperSimplifierApplicationFeeConsent({ clientId });
                      } else {
                        downloadSuperSimplifierOngoingFeeConsentRenewal({ clientId });
                      }
                    }
                  }}
                />
              )}
            </>
          )}
        </>
      </LoadingIndicator>
    </div>
  );
};
