import { Typography } from '@mui/material';
import { ErrorMessage, useField } from 'formik';
import React from 'react';

export interface FormikErrorMessageProps {
  name: string;
}

export const FormikErrorMessage = (props: FormikErrorMessageProps): JSX.Element => {
  const { name } = props;

  const [field] = useField(name);

  return (
    <ErrorMessage name={field.name}>
      {(msg) => (
        <Typography variant={'body1'} color={'error'} style={{ paddingTop: '2px' }}>
          {msg}
        </Typography>
      )}
    </ErrorMessage>
  );
};
