import { Paper, Typography } from '@mui/material';
import React from 'react';
import { DateTimeFormat, formatDollars, getLocalDateTime } from 'src/common';
import { ServerSideDataTable } from 'src/common/components/dataTable/serverSide';
import { DatatableColumn, FilterDataType } from 'src/common/components/dataTable/types';
import { LoadingProgress } from 'src/common/store/types';
import { PagedRequest, PagedResult } from 'src/store';
import { SSTransaction, SSTransactionCsvRow } from '../store/types';

export interface Props {
  pagedResults?: PagedResult<SSTransaction>;
  pagedRequest: PagedRequest;
  handlePagedRequest: (pagedRequest: PagedRequest) => void;
  handleDownload: (pagedRequest: PagedRequest, mapper: (transactions: SSTransaction[]) => SSTransactionCsvRow[]) => void;
  progress: LoadingProgress;
}

export function TransactionTable(props: Props): JSX.Element {
  const { pagedResults, pagedRequest, progress, handleDownload, handlePagedRequest } = props;

  const defaultValue = '$0.00';

  const transactionDateColumn = (dataIndex: number): React.ReactNode => {
    const date = (pagedResults?.results || [])[dataIndex] == null ? '' : pagedResults?.results[dataIndex].date;
    return (
      <Typography variant="h5" data-testid={`transactionDate${dataIndex}`}>
        {getLocalDateTime(date, DateTimeFormat.Short)}
      </Typography>
    );
  };

  const transactionDescriptionColumn = (dataIndex: number): React.ReactNode => (
    <Typography variant="h5" data-testid={`transactionDescription${dataIndex}`}>
      {(pagedResults?.results || [])[dataIndex] == null ? '' : pagedResults?.results[dataIndex].description}
    </Typography>
  );

  const transactionNetAmountColumn = (dataIndex: number): React.ReactNode => {
    if ((pagedResults?.results || [])[dataIndex] == null) return defaultValue;

    const netAmount = pagedResults?.results[dataIndex].netAmount;

    return (
      <Typography variant="h5" data-testid={`netAmount${dataIndex}`}>
        {!!netAmount ? formatDollars(netAmount) : defaultValue}
      </Typography>
    );
  };

  const transactionTaxColumn = (dataIndex: number): React.ReactNode => {
    if ((pagedResults?.results || [])[dataIndex] == null) return defaultValue;

    const tax = pagedResults?.results[dataIndex].tax;

    return (
      <Typography variant="h5" data-testid={`tax${dataIndex}`}>
        {!!tax ? formatDollars(tax) : defaultValue}
      </Typography>
    );
  };

  const columns: DatatableColumn[] = [
    {
      filterDataType: FilterDataType.date,
      name: 'date',
      label: 'DATE',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex: number) => transactionDateColumn(dataIndex),
      },
    },
    {
      filterDataType: FilterDataType.string,
      name: 'description',
      label: 'DESCRIPTION',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex: number) => transactionDescriptionColumn(dataIndex),
      },
    },
    {
      filterDataType: FilterDataType.numeric,
      name: 'tax',
      label: 'TAX',
      textAlign: 'right',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex: number) => transactionTaxColumn(dataIndex),
      },
    },
    {
      filterDataType: FilterDataType.numeric,
      name: 'netAmount',
      label: 'NET AMOUNT',
      textAlign: 'right',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex: number) => transactionNetAmountColumn(dataIndex),
      },
    }
  ];

  return (
    <Paper elevation={0}>
      <ServerSideDataTable
        loadingProgress={progress}
        columns={columns}
        pagedRequest={pagedRequest}
        pagedResult={pagedResults}
        csvDownload={() =>
          handleDownload(pagedRequest, (transactions) =>
            transactions.map((t) => {
              return {
                date: getLocalDateTime(t.date, DateTimeFormat.Short),
                description: t.description,
                tax: t.tax,
                netAmount: t.netAmount,
              };
            })
          )
        }
        options={{
          filter: true,
          viewColumns: true,
        }}
        handlePagedRequest={handlePagedRequest}
      ></ServerSideDataTable>
    </Paper>
  );
}
