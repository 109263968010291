import React, { useCallback, useEffect } from 'react';
import { TabControl } from '../../../../common';
import { forexRateTabRoutes } from '../../forexrateTabRoutes';
import { Props } from '../container';
import { EntityDetailsCard } from './EntityDetailsCard';

export const FxTabs = (props: Props): JSX.Element => {
  const {
    fetchFxDetails,
    match: { params },
  } = props;

  const baseCurrencyId = Number(params.baseCurrencyId ?? 0);
  const quoteCurrencyId = Number(params.quoteCurrencyId ?? 0);

  const fetch = useCallback(
    (baseCurrencyId: number, quoteCurrencyId: number) => {
      fetchFxDetails({ baesCurrencyId: baseCurrencyId, quoteCurrencyId: quoteCurrencyId });
    },
    [fetchFxDetails]
  );

  useEffect(() => {
    fetch(baseCurrencyId, quoteCurrencyId);
  }, [fetch, baseCurrencyId, quoteCurrencyId]);

  return (
    <TabControl
      {...props}
      tileText={props.securityCommon.fxDetails.currencyPair}
      tileSubText={props.securityCommon.fxDetails.baseCurrencyName + ' to ' + props.securityCommon.fxDetails.quoteCurrencyName}
      tileComponent={EntityDetailsCard}
      tabRoutes={forexRateTabRoutes.filter((r) => r.visible())}
    />
  );
};
