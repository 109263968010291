import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';
import { DateRange } from '../../../../store';
import {
  fetchDailyBenchmarkPerformance,
  fetchDailyPerformanceChart,
  fetchMonthlyBenchmarkPerformance,
  fetchMonthlyPerformanceChart,
  fetchSecurityBenchmarks,
  fetchSecurityPeriodicSummary,
} from './thunks';
import {
  DailyBenchmarkPerformance,
  DailyPerformanceChart,
  Frequency,
  MonthlyBenchmarkPerformance,
  MonthlyPerformanceChart,
  SecurityBenchmark,
  SecurityPerformanceState,
  SecurityPeriodicSummary,
} from './types';

export const initialState: SecurityPerformanceState = {
  parameters: {
    dates: { dateFrom: DateTime.now().minus({ years: 1 }).toISODate(), dateTo: DateTime.now().toISODate() },
    frequency: Frequency.Daily,
    securityBenchmarks: null,
    securityBenchmarkSelected: {
      id: 1014,
      code: 'XJO',
      name: 'S&P/ASX 200 Index',
    },
  },
  monthlyPerformanceChart: null,
  dailyPerformanceChart: null,
  securityPeriodicSummary: null,
  monthlyBenchmarkPerformance: null,
  dailyBenchmarkPerformance: null,
};

export const securityPerformanceSlice = createSlice({
  name: '@@security/performance',
  initialState,
  reducers: {
    setDateParameter: (state, action: PayloadAction<DateRange>) => {
      state.parameters.dates = action.payload;
    },
    setFrequencyParameter: (state, action: PayloadAction<Frequency>) => {
      state.parameters.frequency = action.payload;
    },
    setBenchmarkParameter: (state, action: PayloadAction<SecurityBenchmark | null>) => {
      state.parameters.securityBenchmarkSelected = action.payload;

      if (!action.payload?.id) {
        state.monthlyBenchmarkPerformance = null;
        state.dailyBenchmarkPerformance = null;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMonthlyPerformanceChart.fulfilled, (state, action: PayloadAction<MonthlyPerformanceChart>) => {
      state.monthlyPerformanceChart = action.payload;
    });
    builder.addCase(fetchDailyPerformanceChart.fulfilled, (state, action: PayloadAction<DailyPerformanceChart>) => {
      state.dailyPerformanceChart = action.payload;
    });
    builder.addCase(fetchSecurityPeriodicSummary.fulfilled, (state, action: PayloadAction<SecurityPeriodicSummary>) => {
      state.securityPeriodicSummary = action.payload;
    });
    builder.addCase(fetchSecurityBenchmarks.fulfilled, (state, action: PayloadAction<SecurityBenchmark[]>) => {
      state.parameters.securityBenchmarks = action.payload;
    });
    builder.addCase(fetchMonthlyBenchmarkPerformance.fulfilled, (state, action: PayloadAction<MonthlyBenchmarkPerformance>) => {
      state.monthlyBenchmarkPerformance = action.payload;
    });
    builder.addCase(fetchDailyBenchmarkPerformance.fulfilled, (state, action: PayloadAction<DailyBenchmarkPerformance>) => {
      state.dailyBenchmarkPerformance = action.payload;
    });
  },
});
