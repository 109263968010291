import CloseIcon from '@mui/icons-material/Close';
import { Box, Dialog, DialogContent, IconButton, List, ListItem, ListItemText, Typography } from '@mui/material';
import React from 'react';

export interface AccountAmmDocumentDialogProps {
  isOpen: boolean;
  handleClose: (status: boolean) => void;
}

export const AccountAmmDocumentDialog = (props: AccountAmmDocumentDialogProps): JSX.Element => {
  const { isOpen, handleClose } = props;

  const handleCloseDialog = () => {
    handleClose(false);
  };

  return (
    <div>
      <Dialog aria-labelledby="customized-dialog-title" open={isOpen} onClose={handleCloseDialog} maxWidth={'md'}>
        <Box style={{ position: 'relative', height: '90px' }}>
          <IconButton aria-label="close" onClick={handleCloseDialog} style={{ position: 'absolute', right: '8px', top: '0px' }}>
            <CloseIcon />
          </IconButton>
        </Box>
        <DialogContent dividers>
          <Typography variant="h4" style={{ fontWeight: 900, marginBottom: '30px' }}>
            Exceptions for Certified Individuals and Entities
          </Typography>
          <Typography gutterBottom variant="h5" style={{ fontWeight: 600, marginBottom: '10px' }}>
            Please note the following exceptions to the standard Certified Identification for Individuals.
          </Typography>
          <List disablePadding={true}>
            <ListItem style={{ paddingTop: 0, paddingBottom: 0 }}>
              <ListItemText>
                1) Foreign National ID Card – the following banks do not accept this: AMP, Arab Bank, AUSWIDE, Bank of Queensland, ING
              </ListItemText>
            </ListItem>
            <ListItem style={{ paddingTop: 0, paddingBottom: 0 }}>
              <ListItemText>
                2) Foreign Citizenship Certificate – the following banks do not accept: Westpac, Macquarie, ING, Bank of Sydney, AMP, Auswide, and Bank of
                Queensland
              </ListItemText>
            </ListItem>
            <ListItem style={{ paddingTop: 0, paddingBottom: 0 }}>
              <ListItemText>
                3) Foreign Birth Certificate – the following banks do not accept: Westpac, Macquarie, ING, Bank of Sydney, AMP, Auswide, and Bank of Queensland
              </ListItemText>
            </ListItem>
            <ListItem style={{ paddingTop: 0, paddingBottom: 0 }}>
              <ListItemText>4) Healthcare Card - the following banks do not accept: ING, Auswide and Macquarie</ListItemText>
            </ListItem>
            <ListItem style={{ paddingTop: 0, paddingBottom: 0 }}>
              <ListItemText>5) Commonwealth Seniors Healthcare card - the following banks do not accept: ING, Auswide and Macquarie</ListItemText>
            </ListItem>
            <ListItem style={{ paddingTop: 0, paddingBottom: 0 }}>
              <ListItemText>6) Interim Concession Card - the following banks do not accept: ANZ, ING and ANZ Share Trading</ListItemText>
            </ListItem>
            <ListItem style={{ paddingTop: 0, paddingBottom: 0 }}>
              <ListItemText>7) Rabo Bank require Will & Probate and Trust Deed only if available</ListItemText>
            </ListItem>
            <ListItem style={{ paddingTop: 0, paddingBottom: 0 }}>
              <ListItemText>8) ANZ do not accept Proof of Age Cards that do not have card/reference numbers</ListItemText>
            </ListItem>
          </List>
          <Typography gutterBottom variant="h5" style={{ fontWeight: 600, marginTop: '20px', marginBottom: '10px' }}>
            Please note additional requirements for particular financial institutions under specific circumstances:
          </Typography>
          <List disablePadding={true}>
            <ListItem style={{ paddingTop: 0, paddingBottom: 0 }}>
              <ListItemText>
                9) QBank requires two forms of ID for individuals from the following options: Drivers Licence, Australian Passport, Australian Visa, Medicare
                Card
              </ListItemText>
            </ListItem>
            <ListItem style={{ paddingTop: 0, paddingBottom: 0 }}>
              <ListItemText>10) BOQ and Rabo require members of the Super Fund to be in the deed or certified signed application for membership</ListItemText>
            </ListItem>
            <ListItem style={{ paddingTop: 0, paddingBottom: 0 }}>
              <ListItemText>
                11) BOQ, Westpac and AMP require ASIC company extracts for company accounts and corporate trustees of Trusts – this includes beneficial owners
                that are companies
              </ListItemText>
            </ListItem>
            <ListItem style={{ paddingTop: 0, paddingBottom: 0 }}>
              <ListItemText>
                12) Auswide require ASIC company extracts for company accounts and corporate trustees of Trusts and Super Funds -this includes beneficial owners
                that are companies
              </ListItemText>
            </ListItem>
            <ListItem style={{ paddingTop: 0, paddingBottom: 0 }}>
              <ListItemText>
                13) Arab Bank require at least one form of Primary Identification and one form of Secondary Identifications. At least one form of ID must verify
                Name, Address and Date of Birth
              </ListItemText>
            </ListItem>
            <ListItem style={{ paddingTop: 0, paddingBottom: 0 }}>
              <ListItemText>14) BOQ Specialist requires two forms of Identification</ListItemText>
            </ListItem>
            <ListItem style={{ paddingTop: 0, paddingBottom: 0 }}>
              <ListItemText>15) Macquarie Bank do not accept simply ‘Director’ or ‘Manager’ as an occupation and must be more specific</ListItemText>
            </ListItem>
            <ListItem style={{ paddingTop: 0, paddingBottom: 0 }}>
              <ListItemText>16) Macquarie Bank require a mobile number and email address in order to set up an account</ListItemText>
            </ListItem>
            <ListItem style={{ paddingTop: 0, paddingBottom: 0 }}>
              <ListItemText>
                17) The majority of our providers consider the appointer of the Trust to be the controlling person and as such will need to be identified.
              </ListItemText>
            </ListItem>
            <ListItem style={{ paddingTop: 0, paddingBottom: 0 }}>
              <ListItemText>
                18) Majority of our providers require the residential address provided on the drivers licence to reflect the same address on the application.
              </ListItemText>
            </ListItem>
            <ListItem style={{ paddingTop: 0, paddingBottom: 0 }}>
              <ListItemText>
                19) Company ID in ‘Other Entity Types’ – Bank of Sydney, ING, QBank, Auswide Bank & Rabo Bank require certified ID for ALL Directors of a
                company or corporate trustee. All other institutions require personal details only.
              </ListItemText>
            </ListItem>
            <ListItem style={{ paddingTop: 0, paddingBottom: 0 }}>
              <ListItemText>
                20) Bank of Sydney, Macquarie & Rabo require ID & personal details for all beneficiaries of Trusts
                <ul>
                  <li>
                    If the beneficiary is a minor Rabo require a certified birth certificate and a notice issued by a School Principal with name and address of
                    the person and the period of time that person attended the school (Less than 3 months old)
                  </li>
                </ul>
              </ListItemText>
            </ListItem>
            <ListItem style={{ paddingTop: 0, paddingBottom: 0 }}>
              <ListItemText>
                21) The majority of our providers will require personal details & ID for the ‘Settlor’ if the settlement sum is over $1,000.00. Adoption Papers
              </ListItemText>
            </ListItem>
            <ListItem style={{ paddingTop: 0, paddingBottom: 0 }}>
              <ListItemText>
                22) Rabo Bank will accept the following licence as primary photo ID:
                <ul>
                  <li>Australian firearms/shooting licence</li>
                </ul>
              </ListItemText>
            </ListItem>
            <ListItem style={{ paddingTop: 0, paddingBottom: 0 }}>
              <ListItemText>
                23) ANZ & ING do not accept Statutory Declarations to support differences in name. One of the following ID Types must be provided:
                <ul>
                  <li>Marriage Certificate, issued by Births, Deaths & Marriages</li>
                  <li>Birth Certificate with Endorsement, issued by Births, Deaths & Marriages</li>
                  <li>Change of Name Certificate (Deed Poll)</li>
                  <li>Certificate of Divorce</li>
                  <li>Adoption Papers</li>
                </ul>
              </ListItemText>
            </ListItem>
          </List>
          <Typography variant="h4" style={{ fontWeight: 900, margin: '20px 0' }}>
            Foreign Account Tax Compliance Act (FATCA)
          </Typography>
          <Typography gutterBottom variant="h5" style={{ fontWeight: 600, marginBottom: '10px' }}>
            Foreign Account Tax Compliance Act (FATCA)
          </Typography>
          <Typography gutterBottom variant="body1" style={{ marginBottom: '6px' }}>
            In accordance with Australian legislation and under an agreement between the Australian and United States governments, AMM is required to confirm if
            individuals including directors, trustees, office bearers, or controlling persons are citizens or residents in the US for tax purposes.
          </Typography>
          <Typography gutterBottom variant="body1" style={{ marginBottom: '6px' }}>
            You will be required to provide a unique identifier number known as TIN for individuals.
          </Typography>
          <Typography gutterBottom variant="body1" style={{ marginBottom: '10px' }}>
            Please note that AMM does not provide a service to entities incorporated in the US.
          </Typography>
          <Typography gutterBottom variant="h5" style={{ fontWeight: 600, marginBottom: '10px' }}>
            Common Reporting Standard (CRS)
          </Typography>
          <Typography gutterBottom variant="body1" style={{ marginBottom: '6px' }}>
            In accordance with Australian legislation, AMM is required to confirm if individuals including directors, trustees, office bearers, or controlling
            persons are citizens or residents outside of Australia for tax purposes.
          </Typography>
          <Typography gutterBottom variant="body1" style={{ marginBottom: '6px' }}>
            You will be required to provide a unique identifier number known as TIN for individuals.
          </Typography>
          <Typography gutterBottom variant="body1" style={{ marginBottom: '30px' }}>
            Please note that AMM does not provide a service to entities incorporated outside Australia.
          </Typography>
          <Typography variant="h5" style={{ fontSize: '11px', fontWeight: 600, marginBottom: '20px', fontStyle: 'italic' }}>
            * Please note, this information is subject to change and based on the Australian Money Market Certified Identification Requirements for Other Entity
            Types documentation - provided on 16th March 2021.
          </Typography>
        </DialogContent>
      </Dialog>
    </div>
  );
};
