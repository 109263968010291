import { ArrowBackOutlined } from '@mui/icons-material';
import { Grid, IconButton, Typography } from '@mui/material';
import { Action, AnyAction, PayloadAction } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { EditableCollection, LoadingProgress } from 'src/common/store/types';
import { Title } from '../../../../../../../common/types';
import { PagedRequest } from '../../../../../../../store';
import { AddressTable, EmailTable } from '../../../../common/components/contactDetails';
import { PhoneNumberTable } from '../../../../common/components/contactDetails/phoneNumberTable';
import { DocumentsTable } from '../../../../common/components/documents/documentsTable';
import { DvsStatusComponent } from '../../identifications/dvsStatusComponent';
import { IdentificationTable } from '../../identifications/identificationTable';

import {
  AddressDetails,
  ContactDetails,
  DeleteAddressPayload,
  DeleteContactDocumentPayload,
  DeleteEmailAddressPayload,
  DeletePhoneNumberPayload,
  DocumentsParameters,
  DownloadDocumentPayload,
  EditDocuments,
  EmailAddress,
  FetchContactDocumentPayload,
  FetchContactDocumentsPayload,
  FetchContactPayload,
  IdentificationDetails,
  PhoneNumber,
  Role,
  SaveAddressPayload,
  SaveContacIdentificationPayload,
  SaveContactDocumentPayload,
  SaveContactPayload,
  SaveEmailAddressPayload,
  SavePhoneNumberPayload,
  SubmitGreenIdPayload,
  UpdateGreenIdDetailsPayload,
} from '../../../../common/store/types';
import { AddressType, GreenIdStatusEnum } from '../../../store/enums';
import { DocumentDetails, DocumentType, SaveDocumentDetails, SaveIdentificationDetails } from '../../../store/types';
import { DetailsForm } from './detailsForm';

interface EditRouteParams {
  contactId?: string;
}

export interface EditProps {
  parentId: number | null; // could be clientId or trusteeId etc
  onBackClick: (createMode: boolean) => void;

  contact: ContactDetails | undefined;
  fetchContact: (payload: FetchContactPayload) => Promise<AnyAction>;
  loadingContactProgress: LoadingProgress;
  saveContactProgress: LoadingProgress;
  saveContact: (payload: SaveContactPayload) => void;
  submitToGreenId?: (payload: SubmitGreenIdPayload) => void;
  updateGreenIdDetails?: (payload: UpdateGreenIdDetailsPayload) => void;
  hasClientEditAdminOnlyPermission?: boolean;
  updateContact: (payload: SaveContactPayload) => Promise<AnyAction>;
  fetchRoles: () => void;
  roles: Role[];
  loadingRolesProgress: LoadingProgress;

  addresses: AddressDetails[];
  fetchAddresses: (contactId: number) => Promise<AnyAction>;
  availableAddressTypes: AddressType[];
  saveAddress: (payload: SaveAddressPayload) => Promise<AnyAction>;
  setAddressEdit: (payload: number | null) => PayloadAction<number | null>;
  clearAddressEdit: () => Action;
  loadingAddressesProgress: LoadingProgress;
  savingAddressProgress: LoadingProgress;
  deleteAddress: (payload: DeleteAddressPayload) => Promise<AnyAction>;

  documents?: EditDocuments;
  fetchDocuments: (payload: FetchContactDocumentsPayload) => Promise<AnyAction>;
  loadingDocumentsProgress: LoadingProgress;
  downloadDocument: (payload: DownloadDocumentPayload) => Promise<AnyAction>;
  documentTypes: DocumentType[];
  editDocument: DocumentDetails | null | undefined;
  setDocumentAddMode: () => Action;
  cancelDocumentAddEditMode: () => Action;
  fetchDocumentForEdit: (payload: FetchContactDocumentPayload) => Promise<AnyAction>;
  fetchDocumentTypes: () => Promise<AnyAction>;
  saveDocument: (payload: SaveContactDocumentPayload) => Promise<AnyAction>;
  saveIdentification?: (payload: SaveContacIdentificationPayload) => Promise<AnyAction>;
  saveDocumentProgress: LoadingProgress;
  deleteDocument: (payload: DeleteContactDocumentPayload) => Promise<AnyAction>;
  documentsParameters?: DocumentsParameters;

  identifications?: EditDocuments;
  editIdentification?: SaveIdentificationDetails | DocumentDetails | IdentificationDetails | null;
  loadingIdentificationsProgress: LoadingProgress;
  fetchIdentifications: (payload: FetchContactDocumentsPayload) => Promise<AnyAction>;
  setIdentificationAddMode: () => Action;
  cancelIdentificationAddEditMode: () => Action;
  fetchIdentificationForEdit: (payload: FetchContactDocumentPayload) => Promise<AnyAction>;
  identificationsParameters?: DocumentsParameters;

  loadingContactDetailsProgress: LoadingProgress;
  fetchContactDetails: (contactId: number) => Promise<AnyAction>;

  phoneNumbers?: EditableCollection<PhoneNumber, number>;
  savePhoneNumber: (payload: SavePhoneNumberPayload) => Promise<AnyAction>;
  phoneNumberEditId: number | null | undefined;
  setPhoneNumberEditId: (payload: number | null | undefined) => Action;
  emailAddresses?: EditableCollection<EmailAddress, number>;
  setPhoneNumberAdd: (payload: PhoneNumber) => Action;
  deletePhoneNumber: (payload: DeletePhoneNumberPayload) => Promise<AnyAction>;

  emailAddressEditId: number | null | undefined;
  setEmailAddressAdd: (payload: EmailAddress) => Action;
  setEmailAddressEditId: (payload: number | null | undefined) => Action;
  saveEmailAddress: (payload: SaveEmailAddressPayload) => Promise<AnyAction>;
  deleteEmailAddress: (payload: DeleteEmailAddressPayload) => Promise<AnyAction>;
  editAddress: AddressDetails | null | undefined;
}

export const Edit = (props: EditProps): JSX.Element => {
  const {
    onBackClick,
    parentId,

    contact,
    fetchContact,
    loadingContactProgress,
    saveContactProgress,
    saveContact,
    submitToGreenId,
    updateGreenIdDetails,
    updateContact,
    fetchRoles,
    roles,
    loadingRolesProgress,

    addresses,
    fetchAddresses,
    availableAddressTypes,
    saveAddress,
    setAddressEdit,
    clearAddressEdit,
    loadingAddressesProgress,
    savingAddressProgress,
    deleteAddress,

    documents,
    fetchDocuments,
    loadingDocumentsProgress,
    downloadDocument,
    documentTypes,
    editDocument,
    setDocumentAddMode,
    cancelDocumentAddEditMode,
    fetchDocumentForEdit,
    fetchDocumentTypes,
    saveDocument,
    saveIdentification,
    saveDocumentProgress,
    deleteDocument,
    documentsParameters,

    identifications,
    editIdentification,
    loadingIdentificationsProgress,
    fetchIdentifications,
    setIdentificationAddMode,
    cancelIdentificationAddEditMode,
    fetchIdentificationForEdit,
    identificationsParameters,

    loadingContactDetailsProgress,
    fetchContactDetails,

    phoneNumbers,
    savePhoneNumber,
    phoneNumberEditId,
    setPhoneNumberEditId,
    emailAddresses,
    setPhoneNumberAdd,
    deletePhoneNumber,

    emailAddressEditId,
    setEmailAddressAdd,
    setEmailAddressEditId,
    saveEmailAddress,
    deleteEmailAddress,
    editAddress,

    hasClientEditAdminOnlyPermission,
  } = props;

  const hideColumns = ['points', 'expiry'];

  const routeParams = useParams<EditRouteParams>();
  const contactId = !!routeParams.contactId ? parseInt(routeParams.contactId, 10) : null;

  useEffect(() => {
    if (!!parentId) {
      fetchRoles();
      if (!!parentId && !!contactId) {
        fetchContact({ clientId: parentId, contactId });
        fetchAddresses(contactId);
        fetchDocumentTypes();
        !!documentsParameters && fetchDocuments({ clientId: parentId, contactId, parameters: documentsParameters });
        !!identificationsParameters &&
          fetchIdentifications({ clientId: parentId, contactId, parameters: identificationsParameters });
        fetchContactDetails(contactId);
      }
    }
  }, [
    fetchContactDetails,
    fetchContact,
    fetchAddresses,
    fetchDocuments,
    fetchDocumentTypes,
    fetchIdentifications,
    contactId,
    parentId,
    fetchRoles,
  ]);

  const onSaveContact = (contactDetails: ContactDetails) => {
    if (parentId !== null) {
      const payload: SaveContactPayload = { ...contactDetails };
      payload.tfn = (payload.tfn || '').replace(/[\s-_]/g, '');

      if (payload.id !== null) {
        updateContact(payload);
      } else {
        saveContact(payload);
      }
    }
  };

  const handleSaveAddress = (address: AddressDetails) => {
    if (contactId !== null) {
      saveAddress({ address, contactId });
    }
  };

  const addEditAddress = (id: number | null) => {
    setAddressEdit(id);
  };

  const clearAddress = () => {
    clearAddressEdit();
  };

  const handleDeleteAddress = (addressId: number) => {
    if (contactId !== null) {
      deleteAddress({ contactId, addressId });
    }
  };

  const handleSavePhoneNumber = (phoneNumber: PhoneNumber) => {
    if (contactId !== null) {
      savePhoneNumber({
        contactId,
        phoneNumber,
      });
    }
  };

  const handleDeletePhoneNumber = (id: number) => {
    if (contactId !== null) {
      deletePhoneNumber({
        contactId,
        contactDetailId: id,
      });
    }
  };

  const handleSaveEmailAddress = (emailAddress: EmailAddress) => {
    if (contactId !== null) {
      saveEmailAddress({
        contactId,
        emailAddress,
      });
    }
  };

  const handleDeleteEmailAddress = (id: number) => {
    if (contactId !== null) {
      deleteEmailAddress({
        contactId,
        contactDetailId: id,
      });
    }
  };

  const onAddEditDocument = (documentId: number | null | undefined) => {
    if (!!documentId && contactId !== null) {
      // load document from backend
      fetchDocumentForEdit({ contactId, documentId });
    } else if (documentId === null) {
      setDocumentAddMode();
    } else {
      cancelDocumentAddEditMode();
    }
  };

  const onAddEditIdentification = (documentId: number | null | undefined) => {
    if (!!documentId && contactId !== null) {
      // load identification from backend
      fetchIdentificationForEdit({ contactId, documentId });
    } else if (documentId === null) {
      setIdentificationAddMode();
    } else {
      cancelIdentificationAddEditMode();
    }
  };

  const onSaveDocument = (document: SaveDocumentDetails) => {
    if (parentId !== null && contactId !== null && !!documentsParameters) {
      saveDocument({
        clientId: parentId,
        contactId,
        document,
        fetchPayload: { clientId: parentId, contactId, parameters: documentsParameters },
      }).then(() => {
        fetchDocuments({ clientId: parentId, contactId, parameters: documentsParameters });
      });
    }
  };

  const onDeleteDocument = (documentId: number) => {
    if (parentId !== null && contactId !== null && !!documentsParameters) {
      deleteDocument({
        clientId: parentId,
        contactId,
        attachmentId: documentId,
        fetchPayload: {
          clientId: parentId,
          contactId,
          parameters: documentsParameters,
        },
      }).then(() => {
        fetchDocuments({ clientId: parentId, contactId, parameters: documentsParameters });
      });
    }
  };

  const onSaveIdentification = (document: SaveIdentificationDetails) => {
    if (parentId !== null && contactId !== null && !!identificationsParameters && !!saveIdentification) {
      saveIdentification({
        clientId: parentId,
        contactId,
        document,
        fetchPayload: { clientId: parentId, contactId, parameters: identificationsParameters },
      }).then(() => {
        fetchIdentifications({ clientId: parentId, contactId, parameters: identificationsParameters });
      });
    }
  };

  interface GreenidStatus {
    dvsConsentReceived?: boolean;
    greenIdVerificationId?: string;
    greenIdVerificationStatus?: number;
    consentAcceptedDate?: string;
  }

  const greenidStatusInitial: GreenidStatus = {
    dvsConsentReceived: false,
    greenIdVerificationId: '',
    greenIdVerificationStatus: (GreenIdStatusEnum.getByName('UNVERIFIED')?.id as number) ?? 8,
    consentAcceptedDate: '',
  };

  const [greenidStatus, setGreenidStatus] = useState(greenidStatusInitial);

  useEffect(() => {
    if (contact) {
      setGreenidStatus({
        dvsConsentReceived: contact.dvsConsentReceived,
        greenIdVerificationId: contact.greenIdVerificationId,
        greenIdVerificationStatus:
          (contact.greenIdVerificationStatus === 0 ? 8 : contact.greenIdVerificationStatus) ?? 8,
        consentAcceptedDate: contact.consentAcceptedDate,
      });
    }
  }, [contact]);

  const onSubmitToGreenId = (payload: SubmitGreenIdPayload) => {
    if (submitToGreenId && parentId !== null) {
      submitToGreenId(payload);
    }
  };

  const onUpdateGreenIdDetails = (payload: UpdateGreenIdDetailsPayload) => {
    if (updateGreenIdDetails && parentId !== null) {
      updateGreenIdDetails(payload);
    }
  };

  const onDeleteIdentification = (documentId: number) => {
    if (parentId !== null && contactId !== null && !!identificationsParameters) {
      deleteDocument({
        clientId: parentId,
        contactId,
        attachmentId: documentId,
        fetchPayload: {
          clientId: parentId,
          contactId,
          parameters: identificationsParameters,
        },
      }).then(() => {
        fetchIdentifications({ clientId: parentId, contactId, parameters: identificationsParameters });
      });
    }
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <IconButton
            aria-label="contacts list"
            color="primary"
            data-testid={`cancelButton`}
            onClick={() => {
              onBackClick(!contactId);
            }}
            style={{ display: 'inline' }}
          >
            <ArrowBackOutlined />
          </IconButton>
          <Typography variant="h4" style={{ padding: '0 0 20px', display: 'inline' }}>
            {contactId !== null && !!contact && Title.getById(contact?.titleId)?.displayName} {contact?.firstName}{' '}
            {contact?.lastName}
            {contactId === null && 'New Contact'}
          </Typography>
        </Grid>
        <Grid item xs={6}></Grid>
      </Grid>
      <DetailsForm
        details={contact}
        loadingProgress={loadingContactProgress}
        saveContactProgress={saveContactProgress}
        onSave={onSaveContact}
        roles={roles}
        rolesLoadingProgress={loadingRolesProgress}
      ></DetailsForm>
      {!!contactId && (
        <>
          <AddressTable
            selectedAddress={editAddress}
            setAddEdit={addEditAddress}
            cancel={clearAddress}
            addresses={addresses}
            save={handleSaveAddress}
            delete={handleDeleteAddress}
            addressTypes={availableAddressTypes}
            loadingProgress={loadingAddressesProgress}
            savingProgress={savingAddressProgress}
          ></AddressTable>
          {!!documents && (
            <DocumentsTable
              type="Documents"
              hideColumns={hideColumns}
              fetchDocumentTypes={fetchDocumentTypes}
              documentTypes={documentTypes}
              documents={documents.results.items}
              pagination={documents.parameters.pagination}
              onSave={onSaveDocument}
              onDelete={onDeleteDocument}
              progress={loadingDocumentsProgress}
              saveProgress={saveDocumentProgress}
              handleDownloadDocument={(attachmentId: number, filename: string) => {
                if (parentId !== null) {
                  downloadDocument({
                    clientId: contactId,
                    attachmentId,
                    filename,
                  });
                }
              }}
              handleGridActions={(pagedRequest: PagedRequest) => {
                if (parentId !== null) {
                  fetchDocuments({
                    clientId: parentId,
                    contactId,
                    parameters: { ...documents.parameters, pagination: pagedRequest },
                  });
                }
              }}
              onAddEdit={onAddEditDocument}
              selectedDocument={editDocument}
              hasClientEditAdminOnlyPermission={true}
            ></DocumentsTable>
          )}
          {!!identifications && (
            <>
              <Typography variant="h4" style={{ padding: '20px 0 0px 0', margin: 0 }}>
                Identifications
              </Typography>

              <DvsStatusComponent
                greenidStatus={greenidStatus}
                submitToGreenId={onSubmitToGreenId}
                clientId={parentId}
                contactId={contactId}
                onSave={() => console.log('onSave')}
                updateGreenIdDetails={onUpdateGreenIdDetails}
                hasClientEditAdminOnlyPermission={hasClientEditAdminOnlyPermission ?? false}
              />

              <IdentificationTable
                greenidStatus={greenidStatus}
                type="Identifications"
                hideColumns={hideColumns}
                fetchDocumentTypes={fetchDocumentTypes}
                documentTypes={documentTypes}
                documents={identifications.results.items}
                pagination={identifications.parameters.pagination}
                onSave={onSaveIdentification}
                onDelete={onDeleteIdentification}
                progress={loadingIdentificationsProgress}
                saveProgress={saveDocumentProgress}
                handleDownloadDocument={(attachmentId: number, filename: string) => {
                  if (parentId !== null) {
                    downloadDocument({
                      clientId: contactId,
                      attachmentId,
                      filename,
                    });
                  }
                }}
                handleGridActions={(pagedRequest: PagedRequest) => {
                  if (parentId !== null) {
                    fetchIdentifications({
                      clientId: parentId,
                      contactId,
                      parameters: { ...identifications.parameters, pagination: pagedRequest },
                    });
                  }
                }}
                onAddEdit={onAddEditIdentification}
                selectedDocument={editIdentification}
                hasClientEditAdminOnlyPermission={true}
              ></IdentificationTable>
            </>
          )}
          {!!phoneNumbers && (
            <PhoneNumberTable
              items={phoneNumbers.items}
              editId={phoneNumberEditId}
              onSave={handleSavePhoneNumber}
              onDelete={handleDeletePhoneNumber}
              onSelectEditId={setPhoneNumberEditId}
              onStartAddItem={setPhoneNumberAdd}
              progress={loadingContactDetailsProgress}
            ></PhoneNumberTable>
          )}
          {!!emailAddresses && (
            <EmailTable
              items={emailAddresses.items}
              editId={emailAddressEditId}
              onSave={handleSaveEmailAddress}
              onDelete={handleDeleteEmailAddress}
              onSelectEditId={setEmailAddressEditId}
              onStartAddItem={setEmailAddressAdd}
              progress={loadingContactDetailsProgress}
              hasMoneysoftAccess={contact?.hasMoneysoftAccess ?? false}
            ></EmailTable>
          )}
        </>
      )}
    </>
  );
};
