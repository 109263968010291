import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';
import { DateRange } from '../../../../store';
import { fetchConsolidatedSecurityHoldingsSummary, fetchDailySecurityPricesChart, fetchSecurityDashboard, fetchSecurityPrices } from './thunks';
import {
  ConsolidatedSecurityHoldingsSummary,
  DailySecurityPricesChartItem,
  DashboardChartInterval,
  DashboardChartStartDateParameter,
  fetchPagedResults,
  SecurityDashboard,
  SecurityDashboardState,
  SecurityPricesItem,
} from './types';

export const initialState: SecurityDashboardState = {
  parameters: {
    dates: { dateFrom: DateTime.now().minus({ years: 1 }).toISODate(), dateTo: DateTime.now().toISODate() },
    dailySecurityPricesChartStartDate: {
      startDate: DateTime.now().minus({ months: 1 }).toISODate(),
      interval: DashboardChartInterval.OneMonth,
    },
    selectedGroup: '1M',
    pagination: {
      pageNumber: 1,
      pageSize: 5,
      queryFields: [
        {
          fieldName: 'date',
          descendingSortDirection: true,
          operator: '',
          hasSearchTerm: false,
          isSortTerm: true,
          searchTerm: '',
        },
      ],
    },
  },
  consolidatedSecurityHoldingsSummary: {
    securityId: 0,
    fua: 0,
    portfolioCount: 0,
  },
  securityPrices: {
    pageNumber: 1,
    pageSize: 5,
    totalNumberOfPages: 1,
    totalNumberOfRecords: 5,
    results: [],
  },
  securityDashboard: {
    securityId: 0,
    category: '',
    currency: '',
    gicsSector: '',
    gicsSubGroup: '',
    market: '',
    type: '',
    lastPrice: 0,
    lastPriceDate: undefined,
    oneMonthTotalReturn: 0,
    oneYearRange: {
      from: 0,
      to: 0,
    },
    oneYearDividendYield: 0,
    oneYearFranking: 0,
    oneYearGrossYield: 0,
    oneYearIncome: 0,
    oneYearTotalReturn: 0,
  },
  dailySecurityPricesChart: [],
};

export const securityDashboardSlice = createSlice({
  name: '@@security/dashboard',
  initialState,
  reducers: {
    setDateParameter: (state, action: PayloadAction<DateRange>) => {
      state.parameters.dates = action.payload;
    },
    setChartDateParameter: (state, action: PayloadAction<DashboardChartStartDateParameter>) => {
      state.parameters.dailySecurityPricesChartStartDate = action.payload;
    },
    setSelectedGroupParameter: (state, action: PayloadAction<string>) => {
      state.parameters.selectedGroup = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchConsolidatedSecurityHoldingsSummary.fulfilled, (state, action: PayloadAction<ConsolidatedSecurityHoldingsSummary>) => {
      state.consolidatedSecurityHoldingsSummary = action.payload;
    });
    builder.addCase(fetchSecurityPrices.fulfilled, (state, action: PayloadAction<fetchPagedResults<SecurityPricesItem>>) => {
      state.securityPrices = action.payload.results;
      state.parameters.pagination = action.payload.pagination;
    });
    builder.addCase(fetchSecurityDashboard.fulfilled, (state, action: PayloadAction<SecurityDashboard>) => {
      state.securityDashboard = action.payload;
    });
    builder.addCase(fetchDailySecurityPricesChart.fulfilled, (state, action: PayloadAction<DailySecurityPricesChartItem[]>) => {
      state.dailySecurityPricesChart = action.payload;
    });
  },
});
