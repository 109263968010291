import { Box, FormControlLabel } from '@mui/material';
import React from 'react';
import { WO2Checkbox } from 'src/common';
import { DateRange } from 'src/store';
import DateRangePicker from 'src/common/components/DateRangePicker';
import { ClientReportsParameters, MdaReportTypes } from '../../store/types';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';

export interface AdditionsAndWithdrawalsProps {
  parameters: ClientReportsParameters;
  setDateRangeAdditionsAndWithdrawalsParameter: ActionCreatorWithPayload<DateRange, string>;
  setAdditionsAndWithdrawalsSelectedParameter: ActionCreatorWithPayload<boolean, string>;
  createReportSortRequest: (isAdd: boolean, id: number, text: string) => void;
}

export const AdditionsAndWithdrawals = ({
  parameters,
  setDateRangeAdditionsAndWithdrawalsParameter,
  setAdditionsAndWithdrawalsSelectedParameter,
  createReportSortRequest,
}: AdditionsAndWithdrawalsProps) => {
  const handleClickAdditionsAndWithdrawalSelect = () => {
    setAdditionsAndWithdrawalsSelectedParameter(!parameters.selectedReports?.additionsAndWithdrawals.selected);
    createReportSortRequest(
      !parameters.selectedReports?.additionsAndWithdrawals.selected,
      MdaReportTypes.AdditionsAndWithdrawals.id,
      MdaReportTypes.AdditionsAndWithdrawals.displayName
    );
  };

  const handleClickDateRangeForAdditionsAndWithdrawals = (dates: DateRange) => {
    setDateRangeAdditionsAndWithdrawalsParameter(dates);
  };

  return (
    <Box ml={6} display="flex" alignItems="center" mt={1} mb={1}>
      <FormControlLabel
        aria-label="Acknowledge"
        onClick={(event) => event.stopPropagation()}
        onFocus={(event) => event.stopPropagation()}
        control={
          <WO2Checkbox
            color="primary"
            checked={parameters.selectedReports?.additionsAndWithdrawals.selected}
            onChangeHandler={handleClickAdditionsAndWithdrawalSelect}
          />
        }
        sx={{ flex: 1 }}
        label="Additions & Withdrawals"
      />
      <DateRangePicker
        dateRange={{
          dateFrom: parameters?.selectedReports.additionsAndWithdrawals?.fromDate,
          dateTo: parameters?.selectedReports.additionsAndWithdrawals?.toDate,
          dateRangeType: parameters?.selectedReports.additionsAndWithdrawals?.dateRangeType,
        }}
        setDateRange={handleClickDateRangeForAdditionsAndWithdrawals}
        inceptionDate={parameters.inceptionDate}
      />
    </Box>
  );
};
