import { Grid } from '@mui/material';
import { Field } from 'formik';
import React from 'react';
import { ToggleButtonItem } from 'src/common';
import { FormikToggleButton } from 'src/common/components/formik';
import { TrusteeType, TrustType } from 'src/features/clients/client/details/mainDetails/store';

export interface TrustFormProps {
  isSmsf: boolean;
}

export const TrustForm = ({ isSmsf }: TrustFormProps): JSX.Element => {
  const trusteeTypeToggleButtons: ToggleButtonItem<number>[] = TrusteeType.getArray().map((trusteeType: TrusteeType) => ({
    name: trusteeType.displayName,
    value: trusteeType.id,
  }));
  const trustTypeToggleButtons: ToggleButtonItem<number>[] = TrustType.getArray().map((trustType: TrustType) => ({
    name: trustType.displayName,
    value: trustType.id,
  }));

  return (
    <>
      <Grid container style={{ marginTop: '24px' }}>
        <Grid item xs={12}>
          <Field
            component={FormikToggleButton}
            exclusive={true}
            buttons={trusteeTypeToggleButtons}
            name="trusteeTypeId"
            label="What is the Trustee Type of the Trust"
          />
        </Grid>

        {!isSmsf && (
          <Grid item xs={12}>
            <Field
              component={FormikToggleButton}
              exclusive={true}
              buttons={trustTypeToggleButtons}
              name="typeOfTrustId"
              label="What is the Trust Type of the Trust?"
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};
