export interface FilterOperator {
  name: string;
  label: string;
  chipLabel: string;
  sortIndex: number;
}

export const filterOperator = {
  isEqual: 'IsEqual',
  notEqual: 'NotEqual',
  contains: 'Contains',
  doesNotContain: 'DoesNotContain',
  greaterThan: 'GreaterThan',
  greaterThanOrEqualTo: 'GreaterOrEqualTo',
  lessThan: 'LessThan',
  lessOrEqualTo: 'LessOrEqualTo',
};

export const getFilterOperators = (): FilterOperator[] => {
  return [
    {
      name: filterOperator.isEqual,
      label: 'is equal',
      chipLabel: '=',
      sortIndex: 0,
    },
    {
      name: filterOperator.notEqual,
      label: 'not equal',
      chipLabel: '!=',
      sortIndex: 1,
    },
    {
      name: filterOperator.contains,
      label: 'contains',
      chipLabel: 'contains',
      sortIndex: 2,
    },
    {
      name: filterOperator.doesNotContain,
      label: 'does not contain',
      chipLabel: '!contains',
      sortIndex: 3,
    },
    {
      name: filterOperator.greaterThan,
      label: 'greater than',
      chipLabel: '>',
      sortIndex: 4,
    },
    {
      name: filterOperator.greaterThanOrEqualTo,
      label: 'greater or equal',
      chipLabel: '>=',
      sortIndex: 5,
    },
    {
      name: filterOperator.lessThan,
      label: 'less than',
      chipLabel: '<',
      sortIndex: 6,
    },
    {
      name: filterOperator.lessOrEqualTo,
      label: 'less or equal',
      chipLabel: '<=',
      sortIndex: 7,
    },
  ].sort((f) => f.sortIndex);
};

export const getOperator = (name: string): FilterOperator => getFilterOperators().find((f) => f.name === name) as FilterOperator;
