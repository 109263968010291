import { Close } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import { Box, List, Theme } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { NavigationStateProps } from './container';
import leftMenuItems from './leftMenuItems';

export default function Navigation(props: NavigationStateProps): JSX.Element {
  const [hasMoneysoft, setHasMoneysoft] = useState(false);

  useEffect(() => {
    props.fetchDataFeeds();
  }, [props.fetchDataFeeds]);

  useEffect(() => {
    const moneysoftFeed = props.dataFeeds ? props.dataFeeds.find((d) => d.vendor === 'Moneysoft') : null;
    setHasMoneysoft(moneysoftFeed ? moneysoftFeed.isRegistered : false);
  }, [props.dataFeeds]);

  const location = useLocation();
  const drawerWidth = 240;
  const useStyles = makeStyles((theme: Theme) => ({
    root: {
      display: 'flex',
    },
    // appBar: {
    //   paddingLeft: '75px',
    //   marginLeft: '0px',
    //   zIndex: theme.zIndex.drawer ? theme.zIndex.drawer - 1 : 0,
    //   transition: theme.transitions.create(['width', 'margin', 'padding'], {
    //     easing: theme.transitions.easing.sharp,
    //     duration: theme.transitions.duration.leavingScreen,
    //   }),
    // },
    // appBarShift: {
    //   paddingLeft: '0px',
    //   marginLeft: drawerWidth,
    //   width: () => (isMenuOpen ? '20% !important' : `calc(100% - ${drawerWidth}px) !important`),
    //   transition: theme.transitions.create(['width', 'margin', 'padding'], {
    //     easing: theme.transitions.easing.sharp,
    //     duration: theme.transitions.duration.enteringScreen,
    //   }),
    // },
    menuButton: {
      margin: isMenuOpen ? '36px' : '0 auto !important',
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 40,
      whiteSpace: 'nowrap',
    },
    drawerOpen: {
      width: drawerWidth,
      minWidth: theme.spacing(7),
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      [theme.breakpoints.up('sm')]: {
        minWidth: theme.spacing(9),
      },
      boxShadow: '0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 1px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.14)',
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(7),
      minWidth: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
        minWidth: theme.spacing(9),
      },
      boxShadow: '0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 1px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.14)',
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 40,
    },
  }));

  const [open, setOpen] = React.useState(false);
  const isMenuOpen = false;
  const classes = useStyles({ isMenuOpen: open });

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box display={'flex'}>
      <CssBaseline />
      {/* <AppBar
        elevation={0}
        position="fixed"
        color={'default'}
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <Box color={'textSecondary'}>
            {location.pathname.indexOf('rebalance') === -1 && (
              <IconButton aria-label="open drawer" onClick={open ? handleDrawerClose : handleDrawerOpen} edge="start" className={classes.menuButton}>
                <MenuIcon color={'disabled'} />
              </IconButton>
            )}
          </Box>
        </Toolbar>
      </AppBar> */}
      <Drawer
        elevation={1}
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <Box color={'textSecondary'} style={{ textAlign: open ? 'right' : 'center' }}>
          {location.pathname.indexOf('rebalance') === -1 && (
            <IconButton aria-label="open drawer" onClick={open ? handleDrawerClose : handleDrawerOpen} edge="start" className={classes.menuButton}>
              {!open && <MenuIcon color={'disabled'} />}
              {open && <Close color={'disabled'} />}
            </IconButton>
          )}
        </Box>
        <div className={classes.toolbar}>{/* <Avatar alt="W" src={Logo} /> */}</div>

        {location.pathname.indexOf('rebalance') === -1 && <List>{leftMenuItems(location.pathname, props.hasClientEditAdminOnlyPermission, hasMoneysoft)}</List>}
      </Drawer>

      <main className={classes.content}>{props.children}</main>
    </Box>
  );
}
