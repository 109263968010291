import { createSelector } from '@reduxjs/toolkit';
import _ from 'lodash';
import { RootState } from '../../../store';
import { Message } from '../reducers/types';
import { Selector } from './../types/redux';

export const selectMessages = createSelector(
  (state: Partial<RootState>) => state,
  (state) => state.messages
);

export const selectCurrentMessages = createSelector(selectMessages, (messages) => {
  return Object.entries(messages || {}).filter((e) => {
    const msg: Message = e[1] as Message;
    return !!msg && !msg.dismissed;
  });
});

export const hasMessagesSelectorFactory = (actionTypes: string[]): Selector<boolean> => {
  return createSelector(selectMessages, (messages) => {
    return _(actionTypes).some((actionType) => {
      const matches = /(.*)\/(pending|fulfilled|rejected)/.exec(actionType);

      let requestName = actionType;
      if (matches) {
        const [, splitRequestName] = matches;
        requestName = splitRequestName;
      }

      return messages?.hasOwnProperty(requestName) ? messages[requestName] != null : false;
    });
  });
};

export const hasErrorsSelectorFactory = (actionTypes: string[]): Selector<boolean> => {
  return createSelector(selectMessages, (messages) => {
    return _(actionTypes).some((actionType) => {
      const matches = /(.*)\/(pending|fulfilled|rejected)/.exec(actionType);

      let requestName = actionType;
      if (matches) {
        const [, splitRequestName] = matches;
        requestName = splitRequestName;
      }

      return messages?.hasOwnProperty(requestName) ? messages[requestName] != null && messages[requestName]?.variant === 'error' : false;
    });
  });
};
