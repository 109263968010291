import { FormControl, InputLabel, MenuItem } from '@mui/material';
import React from 'react';
import { GroupingType } from '../store/types';
import { WO2Select } from './../../../../../common';

interface Props {
  selectedItem: GroupingType;
  setSelectedItem: (value: GroupingType) => void;
}

function GroupingSelect(props: Props): JSX.Element {
  return (
    <div>
      <FormControl variant="outlined">
        <InputLabel id="grouping-select-label">Group By</InputLabel>
        <WO2Select
          labelId="grouping-select-label"
          label="Group By"
          id="grouping-select"
          value={props.selectedItem}
          onChange={(event) => {
            props.setSelectedItem(event.target.value as GroupingType);
          }}
        >
          <MenuItem key={1} value={GroupingType.Security}>
            Security
          </MenuItem>
          <MenuItem key={2} value={GroupingType.Account}>
            Account
          </MenuItem>
        </WO2Select>
      </FormControl>
    </div>
  );
}

export default GroupingSelect;
