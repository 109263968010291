/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';
import { SystemMinimumDate } from '../../../../../common';
import { DateRange, FetchPagedResults, PagedRequest } from '../../../../../store';
import { ClientAccountSelectModel, ClientPortfolio } from '../../common/store/types';
import { fetchTransactionDetails, fetchTransactions, fetchTransactionTotals } from './thunks';
import { Transaction, TransactionDetails, TransactionsState, TransactionsTotals } from './types';

export const initialState: TransactionsState = {
  parameters: {
    portfolio: null,
    dates: { dateFrom: DateTime.fromISO(SystemMinimumDate).toISODate(), dateTo: DateTime.now().toISODate() },
    pagination: {
      pageNumber: 1,
      pageSize: 25,
      queryFields: [
        {
          fieldName: 'tradeDate',
          descendingSortDirection: true,
          hasSearchTerm: false,
          isSortTerm: true,
          searchTerm: '',
          operator: '',
        },
      ],
    },
    transactionId: null,
    selectedAccountId: null,
    accountIdList: [],
  },
  totals: null,
  transactions: null,
  selectedTransaction: null,
};

export const transactionsSlice = createSlice({
  name: '@@client/transactions',
  initialState,
  reducers: {
    setPortfolioParameter: (state, action: PayloadAction<ClientPortfolio>) => {
      state.parameters.portfolio = action.payload;
    },
    setAccountParameter: (state, action: PayloadAction<ClientAccountSelectModel>) => {
      state.parameters.selectedAccountId = action.payload.accountId;
      state.parameters.accountIdList = action.payload.accountIdList;
    },
    setDateParameter: (state, action: PayloadAction<DateRange>) => {
      state.parameters.dates = action.payload;
    },
    setTransactionIdParameter: (state, action: PayloadAction<number | null>) => {
      state.selectedTransaction = null;
      state.parameters.transactionId = action.payload;
    },
    setPaginationParameter: (state, action: PayloadAction<PagedRequest>) => {
      state.parameters.pagination = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTransactions.fulfilled, (state, action: PayloadAction<FetchPagedResults<Transaction>>) => {
      state.transactions = action.payload.results;
      state.parameters.pagination = action.payload.pagination;
    });
    builder.addCase(fetchTransactionTotals.fulfilled, (state, action: PayloadAction<TransactionsTotals>) => {
      state.totals = action.payload;
    });
    builder.addCase(fetchTransactionDetails.fulfilled, (state, action: PayloadAction<TransactionDetails>) => {
      state.selectedTransaction = action.payload;
    });
  },
});
