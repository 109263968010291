import { DateRange } from '../../../../store';

export enum Frequency {
  Daily = 'Daily',
  Monthly = 'Monthly',
}

export interface BenchmarkItem {
  id: number;
  code: string;
  name: string;
}

export interface SeriesData {
  date: string;
  return: number;
  cumulativeReturn: number;
}

export interface ComponentAllocations {
  componentType: string;
  componentId?: number;
  componentKey?: number;
  componentCode: string;
  componentName: string;
  unitPrice: number;
  units: number;
  value: number;
  weight: number;
}

export interface AssetClassAllocations {
  label: string;
  weight: number;
  value: number;
  componentAllocations: ComponentAllocations[];
}

export interface ModelDetail {
  modelId: number;
  modelVersionId: number;
  method: string;
  name: string;
  fua: number;
  portfolioCount: number;
  oneMonthReturn: number;
  inceptionReturn: number;
  groups: AssetClassAllocations[];
}

export interface PeriodicSummarySeriesData {
  date: Date;
  label: string;
  incomeReturn: number;
  capitalReturn: number;
  totalReturn: number;
  benchmarkIncomeReturn: number;
  benchmarkCapitalReturn: number;
  benchmarkTotalReturn: number;
  modelVsBenchmarkIncomeReturn: number;
  modelVsBenchmarkCapitalReturn: number;
  modelVsBenchmarkTotalReturn: number;
}

export interface ModelBenchmark {
  id: number;
  code: string;
  name: string;
}

export interface ModelPerformanceParameters {
  dates: DateRange;
  frequency: Frequency;
  modelBenchmarks: ModelBenchmark[] | null;
  modelBenchmarkSelected: ModelBenchmark | null;
}

export interface ModelPerformancechartWithBenchmark {
  modelVersionId: number;
  primaryBenchmarkProductId: number;
  secondaryBenchmarkProductId: number;
  startDate: string;
  endDate: string;
  capitalReturn: number;
  incomeReturn: number;
  totalReturn: number;
  bestDayReturn: number;
  bestDayDate: string;
  worstDayReturn: number;
  worstDayDate: string;
  annualVolatility: number;
  seriesData: SeriesData[];
  benchmarkSeriesData: SeriesData[];
}

export interface PeriodicSummarySeriesData {
  date: Date;
  label: string;
  incomeReturn: number;
  capitalReturn: number;
  totalReturn: number;
  benchmarkIncomeReturn: number;
  benchmarkCapitalReturn: number;
  benchmarkTotalReturn: number;
  modelVsBenchmarkIncomeReturn: number;
  modelVsBenchmarkCapitalReturn: number;
  modelVsBenchmarkTotalReturn: number;
}

export interface ModelPeriodicSummary {
  modelVersionId: number;
  inceptionDate: string;
  seriesData: PeriodicSummarySeriesData[];
}

export interface ModelPerformanceState {
  parameters: ModelPerformanceParameters;
  modelPerformancechartWithBenchmark: ModelPerformancechartWithBenchmark | null;
  modelPeriodicSummary: ModelPeriodicSummary | null;
  modelDetail: ModelDetail | null;
}

export interface FetchModelPerformancechartWithBenchmarkPayload {
  modelVersionId: number;
  startDate: string;
  endDate: string;
  frequency: string;
  strategyId: number;
}

export interface FetchModelPeriodicSummaryPayload {
  modelVersionId: number;
  benchmarkStrategyId: number;
  endDate: string;
}
