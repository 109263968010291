import { MarketCode } from '../store/types';

export default function decimalPlacesByMarketCode(marketCode: string | undefined): number {
  switch (marketCode) {
    case MarketCode.ADI:
    case MarketCode.ADM:
      return 2;
    case MarketCode.OffMarket:
      return 8;
    default:
      return 0;
  }
}
