import { createAsyncThunk } from '@reduxjs/toolkit';
import { submitRebalanceService } from './services';
import { PreApprovalRebalance, ValidationResult } from './types';

export enum RebalanceCheckoutActionTypes {
  ConfirmRebalance = '@@rebalance/checkout/confirmRebalance',
}

export const confirmRebalance = createAsyncThunk<ValidationResult, PreApprovalRebalance, { rejectValue: ValidationResult }>(
  RebalanceCheckoutActionTypes.ConfirmRebalance,
  async (payload: PreApprovalRebalance, thunkApi) => {
    try {
      return await submitRebalanceService(payload);
    } catch (e) {
      const result: ValidationResult = { errors: [`${e.status} - ${e.statusText}`], warnings: [], information: [] };
      return thunkApi.rejectWithValue(result);
    }
  }
);
