import { Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import React from 'react';
import { formatPercentage, uuidv4 } from '../../../../common';
import { LoadingIndicator } from '../../../../common/components/LoadingIndicator';
import { LoadingProgress } from '../../../../common/store/types';
import { StrategyDashboard } from '../../common/store/types';
import { PeriodicSummary, StrategyCompositionParameters } from '../store/types';

export interface PeriodicSummaryTableProps {
  parameters: StrategyCompositionParameters;
  strategyDashboard: StrategyDashboard | null;
  periodicSummary: PeriodicSummary | null;
  loadingProgresPeriodicSummary: LoadingProgress;
}

export const PeriodicSummaryTable = (props: PeriodicSummaryTableProps): JSX.Element => {
  const { periodicSummary, loadingProgresPeriodicSummary, strategyDashboard } = props;

  const results = periodicSummary?.seriesData;
  const rows = results ?? [];

  return (
    <LoadingIndicator progress={loadingProgresPeriodicSummary}>
      <Paper elevation={0} style={{ marginBottom: '40px', marginTop: '10px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell variant={'body'} style={{ padding: '0' }} />
              <TableCell variant={'body'} colSpan={9}>
                <Typography variant="h3" color={'primary'}>
                  {strategyDashboard?.strategyName}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant={'head'} style={{ padding: '0' }} />
              <TableCell variant={'head'}>
                <Typography variant="h6" color="textSecondary">
                  CAPITAL
                </Typography>
              </TableCell>
              <TableCell variant={'head'}>
                <Typography variant="h6" color="textSecondary">
                  INCOME
                </Typography>
              </TableCell>
              <TableCell variant={'head'}>
                <Typography variant="h6" color="textSecondary">
                  TOTAL
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={uuidv4()}>
                <TableCell>
                  <Typography variant="h5">{row.label}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h5" color={!row.capitalReturn ? 'textPrimary' : row.capitalReturn < 0 ? 'error' : 'secondary'}>
                    {formatPercentage(row.capitalReturn, '-')}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h5" color={!row.incomeReturn ? 'textPrimary' : row.incomeReturn < 0 ? 'error' : 'secondary'}>
                    {formatPercentage(row.incomeReturn, '-')}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h5" color={!row.totalReturn ? 'textPrimary' : row.totalReturn < 0 ? 'error' : 'secondary'}>
                    {formatPercentage(row.totalReturn, '-')}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </LoadingIndicator>
  );
};
