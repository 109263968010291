/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../../../../app/api';
import { buildEncodedQueryString } from '../../../../../../common/utils';
import { PagedResult } from '../../../../../../store/types';
import { SecurityItem } from '../../../../../bulk/common/store/types';
import { SecurityTypeEnum } from './enums';
import {
  ApprovedProduct,
  FetchApprovedProductsPayload,
  FetchInvestmentServiceVersionsByClientPayload,
  GetAssetClassesPayload,
  InvestmentServiceDetail,
  InvestmentServicesPayload,
  InvestmentServiceVersion,
  InvestmentServiceVersionsPayload,
  InvestmentServiceWithVersion,
} from './types';

export enum CommonActionTypes {
  SetSelectedInvestmentService = '@@client/investmentServices/SetSelectedInvestmentService',
  FetchInvestmentServicesCommonSecurities = '@@client/investmentServices/FetchInvestmentServicesCommonSecurities',
  FetchInvestmentServices = '@@client/investmentServices/GetInvestmentServices',
  FetchInvestmentServiceVersions = '@@client/investmentServices/GetInvestmentServiceVersions',
  FetchInvestmentServicesCommonAssetClasses = '@@client/investmentServices/FetchInvestmentServicesCommonAssetClasses',
  FetchInvestmentServiceVersionsByClient = '@@client/portfolio/FetchInvestmentServiceVersionsByClient',
}

export enum CommonApiEndpoints {
  FetchSecurities = '/securities/GetSecuritiesBySearchTerm',
  FetchInvestmentServices = '/investmentservices/GetInvestmentServicesByClient',
  FetchInvestmentServiceAssetClasses = '/bff/GetPortfolioDetail',
  FetchInvestmentServiceVersionsByClient = '/investmentservices/GetInvestmentServiceVersionsByClient',
}

export enum ApprovedProductsActionTypes {
  FetchApprovedProducts = '@@client/portfolio/FetchApprovedProducts',
}

export enum ApprovedProductsApiEndpoints {
  FetchApprovedProducts = '/investmentservices/GetApprovedProductListByAfsl',
}

export const fetchInvestmentServices = createAsyncThunk(CommonActionTypes.FetchInvestmentServices, async ({ clientId }: InvestmentServicesPayload) => {
  const queryString = buildEncodedQueryString({
    clientId,
  });

  const response = await api.get<InvestmentServiceDetail[]>(`${CommonApiEndpoints.FetchInvestmentServices}${queryString}`);

  return response.data;
});

export const fetchInvestmentServiceVersions = createAsyncThunk(
  CommonActionTypes.FetchInvestmentServiceVersions,
  async ({ clientId }: InvestmentServiceVersionsPayload) => {
    const queryString = buildEncodedQueryString({
      clientId,
    });

    const response = await api.get<InvestmentServiceVersion[]>(`${CommonApiEndpoints.FetchInvestmentServiceVersionsByClient}${queryString}`);
    return response.data;
  }
);

export const fetchSecurities = createAsyncThunk(CommonActionTypes.FetchInvestmentServicesCommonSecurities, async (searchTerm: string) => {
  const payload = {
    pagedRequest: {
      pageNumber: 1,
      pageSize: 200,
      queryFields: [
        {
          fieldName: 'name',
          hasSearchTerm: true,
          searchTerm: searchTerm,
          operator: 'Contains',
          isSortTerm: true,
          descendingSortDirection: false,
        },
        {
          fieldName: 'code',
          hasSearchTerm: true,
          searchTerm: searchTerm,
          operator: 'Contains',
          isSortTerm: true,
          descendingSortDirection: false,
        },
      ],
    },
  };

  const response = await api.post<PagedResult<SecurityItem>>(CommonApiEndpoints.FetchSecurities, payload);
  return response.data.results;
});

export const fetchPortfolioAssetClasses = createAsyncThunk(
  CommonActionTypes.FetchInvestmentServicesCommonAssetClasses,
  async ({ clientId, effectiveDate, groupingType, investmentServiceId }: GetAssetClassesPayload) => {
    const queryString = buildEncodedQueryString({
      clientId,
      groupingType,
      effectiveDate,
      investmentServiceId,
    });

    const response = await api.get<any>(`${CommonApiEndpoints.FetchInvestmentServiceAssetClasses}${queryString}`);

    // TODO: change backend call to return id's
    return response.data.map((c: any) => ({
      ...c,
      typeId: SecurityTypeEnum.getByName(c.type)?.id,
      assetClassIds: c.assetClasses.map((v: any) => v.id),
    }));
  }
);

export const fetchInvestmentServiceVersionsByClient = createAsyncThunk(
  CommonActionTypes.FetchInvestmentServiceVersionsByClient,
  async ({ clientId, showLatestVersionOnly }: FetchInvestmentServiceVersionsByClientPayload) => {
    const queryString = buildEncodedQueryString({
      clientId,
      showLatestVersionOnly,
    });

    const response = await api.get<InvestmentServiceWithVersion[]>(`${CommonApiEndpoints.FetchInvestmentServiceVersionsByClient}${queryString}`);
    return response.data;
  }
);

export const fetchApprovedProducts = createAsyncThunk(ApprovedProductsActionTypes.FetchApprovedProducts, async (payload: FetchApprovedProductsPayload) => {
  const queryString = buildEncodedQueryString({
    afslId: payload.afslId,
    subTypeId: payload.subTypeId,
  });

  const response = await api.get<ApprovedProduct[]>(`${ApprovedProductsApiEndpoints.FetchApprovedProducts}${queryString}`);
  return response.data;
});
