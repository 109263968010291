import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import React from 'react';
import { DateTimeFormat, formatDollars, formatPercentage, getLocalDateTime } from '../../../../common';
import { ServerSideDataTable } from '../../../../common/components/dataTable/serverSide';
import { DatatableColumn, FilterDataType } from '../../../../common/components/dataTable/types';
import { LoadingProgress } from '../../../../common/store/types';
import { PagedRequest, PagedResult } from '../../../../store';
import { SecurityPriceCsvRow, SecurityPriceItem } from '../store/types';

export interface Props {
  pagedResults?: PagedResult<SecurityPriceItem>;
  pagedRequest: PagedRequest;
  handlePagedRequest: (pagedRequest: PagedRequest) => void;
  progressPrices: LoadingProgress;
  handleDownload: (pagedRequest: PagedRequest, mapper: (dividendDistributionsByPaymentDateItems: SecurityPriceItem[]) => SecurityPriceCsvRow[]) => void;
}

export function SecurityListTable(props: Props): JSX.Element {
  const { pagedResults, progressPrices, handleDownload, pagedRequest } = props;

  const columns: DatatableColumn[] = [
    {
      filterDataType: FilterDataType.string,
      name: 'date',
      label: 'DATE',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: function customBodyRenderLite(dataIndex): React.ReactNode {
          return <Typography variant="h5">{getLocalDateTime(pagedResults?.results[dataIndex]?.date, DateTimeFormat.Short)}</Typography>;
        },
      },
    },
    {
      filterDataType: FilterDataType.string,
      textAlign: 'right',
      name: 'price',
      label: 'PRICE',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: function customBodyRenderLite(dataIndex): React.ReactNode {
          return <Typography variant="h5">{formatDollars(pagedResults?.results[dataIndex]?.price)}</Typography>;
        },
      },
    },
    {
      filterDataType: FilterDataType.string,
      textAlign: 'right',
      name: 'priceChange',
      label: 'CHANGE ($)',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: function customBodyRenderLite(dataIndex): React.ReactNode {
          return (
            <Typography variant="h5" color={(pagedResults?.results[dataIndex]?.priceChange ?? 0) >= 0 ? 'secondary' : 'error'}>
              {formatDollars(pagedResults?.results[dataIndex]?.priceChange)}
            </Typography>
          );
        },
      },
    },
    {
      filterDataType: FilterDataType.string,
      textAlign: 'right',
      name: 'priceChangePercentage',
      label: 'CHANGE (%)',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: function customBodyRenderLite(dataIndex): React.ReactNode {
          return (
            <Typography variant="h5" color={(pagedResults?.results[dataIndex]?.priceChangePercentage ?? 0) >= 0 ? 'secondary' : 'error'}>
              {formatPercentage(pagedResults?.results[dataIndex]?.priceChangePercentage)}
            </Typography>
          );
        },
      },
    },
  ];

  return (
    <Grid container style={{ width: '100%', margin: '10px' }}>
      <Grid item xs={12}>
        <ServerSideDataTable
          loadingProgress={progressPrices}
          columns={columns}
          pagedRequest={props.pagedRequest}
          pagedResult={props.pagedResults}
          options={{}}
          csvDownload={() =>
            handleDownload(pagedRequest, (securityPriceItems) =>
              securityPriceItems.map((t) => {
                return {
                  date: getLocalDateTime(t.date, DateTimeFormat.Short),
                  price: t.price,
                  priceChange: t.priceChange,
                  priceChangePercentage: t.priceChangePercentage,
                };
              })
            )
          }
          handlePagedRequest={props.handlePagedRequest}
        ></ServerSideDataTable>
      </Grid>
    </Grid>
  );
}
