import { Select as MuiSelect, SelectProps } from '@mui/material';
import React, { ChangeEvent, ReactNode } from 'react';

interface Props extends SelectProps {
  style?: React.CSSProperties;
  children?: ReactNode;
}

export type WO2SelectChangeEvent = ChangeEvent<{ name?: string; value: unknown }>;

export function WO2Select(props: Props): JSX.Element {
  return <MuiSelect {...props} value={props.children ? props.value : ''} label={props.label} variant="outlined" />;
}
