import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import React from 'react';
import { formatNumberCommaSeparated } from '../../../../../common';
import { calculateEstimatedFeesItems } from '../../../common/utils/calculation';
import { AdviceFeesTable } from '../../adviceFees/components/adviceFeesTable';
import { AdviceFees as AdviceFeesType, SuperSimplifierDetails } from '../../store/types';

export interface AdviceFeesProps {
  superSimplifierDetails: SuperSimplifierDetails | undefined;
  adviceFeesValues: AdviceFeesType | undefined;
}

export const AdviceFees = (props: AdviceFeesProps): JSX.Element => {
  const { superSimplifierDetails, adviceFeesValues } = props;
  const totalRolloverAmount =
    (superSimplifierDetails?.rolloverAmount ?? 0) + (superSimplifierDetails?.rolloverAmountSecond ?? 0) + (superSimplifierDetails?.rolloverAmountThird ?? 0);
  const contributionsAmount = superSimplifierDetails?.contributionsAmount ?? 0;

  return (
    <Box>
      <Box marginBottom={'40px'}>
        <Typography variant="h4" gutterBottom>
          Advice Fees
        </Typography>
        <Grid container style={{ marginTop: '20px' }}>
          <Grid item style={{ marginRight: '20px' }}>
            <Card variant="outlined" style={{ textAlign: 'left' }} data-testid="totalEstimatedRollover">
              <CardContent style={{ padding: '12px 16px' }}>
                <Typography variant="h5" component="h2" style={{ fontWeight: 600, marginBottom: '8px' }}>
                  TOTAL ESTIMATED ROLLOVER
                </Typography>
                <Typography variant="h5" component="p">
                  {`$${formatNumberCommaSeparated(totalRolloverAmount, 2)}`}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item>
            <Card variant="outlined" style={{ textAlign: 'left' }} data-testid="estimatedAnnualContributions">
              <CardContent style={{ padding: '12px 16px' }}>
                <Typography variant="h5" component="h2" style={{ fontWeight: 600, marginBottom: '8px' }}>
                  ESTIMATED ANNUAL CONTRIBUTIONS
                </Typography>
                <Typography variant="h5" component="p">
                  {`$${formatNumberCommaSeparated(contributionsAmount, 2)}`}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        {!!adviceFeesValues && adviceFeesValues.estimatedFees.items.length > 0 && (
          <Box marginTop={'30px'}>
            <AdviceFeesTable
              estimatedFeesItems={calculateEstimatedFeesItems(adviceFeesValues.estimatedFees.items, totalRolloverAmount, contributionsAmount)}
              loadingProgress={{ isLoading: false, hasErrors: false }}
              hideColumns={['actions', 'validation']}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};
