import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { selectHasClientEditAdminOnly } from 'src/store/selectors';
import { progressSelectorFactory } from '../../../common/store/selectors';
import { RootState } from '../../../reducers';
import { WorkflowList } from './components/list';
import {
  selectedWorkflow,
  selectedWorkflowError,
  selectImportErrors,
  selectImportUploadSuccess,
  selectIsImporting,
  selectParameters,
  selectPensionAccounts,
  selectWorkflowListResults,
} from './store/selectors';
import { workflowListSlice } from './store/slice';
import {
  completeWorkflow,
  discardWorkflow,
  downloadWorkflowDocument,
  fetchWorkflowList,
  resumeWorkflow,
  terminateWorkflow,
  uploadSuperMemberDataCsv,
  WorkflowListActionTypes,
  getPensionAccounts,
  restartPensionWorkflow,
} from './store/thunks';

const mapStateToProps = (state: RootState) => ({
  workflows: selectWorkflowListResults(state),
  parameters: selectParameters(state),
  selectedWorkflow: selectedWorkflow(state),
  loadingProgress: progressSelectorFactory([WorkflowListActionTypes.FetchWorkflowList])(state),
  workflowsList: selectWorkflowListResults(state),
  workflowsListProgress: progressSelectorFactory([WorkflowListActionTypes.FetchWorkflowList])(state),
  hasAdminAccess: selectHasClientEditAdminOnly(state),
  importErrors: selectImportErrors(state),
  importUploadSuccess: selectImportUploadSuccess(state),
  isImporting: selectIsImporting(state),
  workflowError: selectedWorkflowError(state),
  pensionAccounts: selectPensionAccounts(state),
  pensionAccountsProgress: progressSelectorFactory([WorkflowListActionTypes.FetchPensionAccounts])(state),
});

const mapDispatchToProps = {
  ...workflowListSlice.actions,
  fetchWorkflowList,
  uploadSuperMemberDataCsv,
  terminateWorkflow,
  resumeWorkflow,
  discardWorkflow,
  completeWorkflow,
  downloadWorkflowDocument,
  getPensionAccounts,
  restartPensionWorkflow,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector> & RouteComponentProps;
export const WorkflowListStateContainer = connector(WorkflowList);
