import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Templates } from './components/templates';
import { RootState } from 'src/store';
import { selectHasDashEmail } from 'src/store/selectors';

const mapStateToProps = (state: RootState) => ({
  hasDashEmail: selectHasDashEmail(state),
});

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface MatchParams {}

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector> & RouteComponentProps<MatchParams>;
export const TemplatesContainer = connect(mapStateToProps, mapDispatchToProps)(Templates);
