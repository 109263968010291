import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { progressSelectorFactory } from 'src/common/store/selectors';
import { RootState } from 'src/reducers';
import { selectAfslId } from '../portfolios/store';
import { selectEditState } from '../store/selectors';
import { Edit } from './components/edit';
import {
  editSlice,
  fetchApprovedProducts,
  fetchBenchmarks,
  fetchTemplate,
  selectApprovedProducts,
  selectAssetClassToleranceBands,
  selectBenchmarks,
  selectCashAccountProduct,
  selectConfigurations,
  selectDetails,
  selectSaveTemplatePayload,
  selectSecurityConstraints,
  selectSecurityToleranceBands,
  selectStrategicAssetAllocations,
  TemplateEditActionTypes,
  updateTemplate,
} from './store';

const mapStateToProps = (state: RootState) => ({
  templateDetails: selectDetails(state),
  securityToleranceBands: selectSecurityToleranceBands(state),
  assetClassToleranceBands: selectAssetClassToleranceBands(state),
  configurations: selectConfigurations(state),
  strategicAssetAllocations: selectStrategicAssetAllocations(state),
  securityConstraints: selectSecurityConstraints(state),

  benchmarks: selectBenchmarks(state),
  isLoadingProgress: progressSelectorFactory([TemplateEditActionTypes.FetchTemplate])(state),
  savingProgress: progressSelectorFactory([TemplateEditActionTypes.UpdateTemplate])(state),
  afslId: selectAfslId(state),
  editState: selectEditState(state),
  saveTemplatePayload: selectSaveTemplatePayload(state),
  approvedProducts: selectApprovedProducts(state),
  approvedProductsLoadingProgress: progressSelectorFactory([TemplateEditActionTypes.FetchApprovedProducts])(state),
  cashAccountProduct: selectCashAccountProduct(state),
});

interface MatchParams {
  afslId: string;
  id: string;
}

const mapDispatchToProps = {
  fetchTemplate,
  fetchApprovedProducts,
  updateTemplate,
  fetchBenchmarks,
  ...editSlice.actions,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector> & RouteComponentProps<MatchParams>;
export const EditContainer = connect(mapStateToProps, mapDispatchToProps)(Edit);
