import {
  Accordion,
  AccordionSummary,
  FormControlLabel,
  AccordionDetails,
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Typography,
} from '@mui/material';
import React from 'react';
import { WO2Checkbox, WO2Select, WO2ToggleSwitch } from 'src/common';
import DateRangePicker from 'src/common/components/DateRangePicker';
import { ActionCreatorWithoutPayload, ActionCreatorWithPayload } from '@reduxjs/toolkit';
import {
  ClientReportsParameters,
  GroupBySecurityPerformance,
  HidingOptionSecurityPerformance,
  HidingOptionsRequest,
  MdaReportTypes,
} from '../../store/types';
import { ExpandMore } from '@mui/icons-material';
import { DateRange } from 'src/store';

export interface SecurityPerformanceProps {
  parameters: ClientReportsParameters;
  expandSecurityPerformance: ActionCreatorWithoutPayload<string>;
  setSecurityPerformanceSelectedParameter: ActionCreatorWithPayload<boolean, string>;
  setDateRangeSecurityPerformanceParameter: ActionCreatorWithPayload<DateRange, string>;
  setSecurityPerformanceGroupByParameter: ActionCreatorWithPayload<GroupBySecurityPerformance, string>;
  setSecurityPerformanceHidingOptions: ActionCreatorWithPayload<
    HidingOptionsRequest<HidingOptionSecurityPerformance>,
    string
  >;
  isDatesLessThanFullYear: (dates: DateRange) => boolean;
  createReportSortRequest: (isAdd: boolean, id: number, text: string) => void;
}

export const SecurityPerformance = ({
  parameters,
  setDateRangeSecurityPerformanceParameter,
  setSecurityPerformanceSelectedParameter,
  setSecurityPerformanceGroupByParameter,
  setSecurityPerformanceHidingOptions,
  expandSecurityPerformance,
  isDatesLessThanFullYear,
  createReportSortRequest,
}: SecurityPerformanceProps): JSX.Element => {
  const handleClickSecurityPerformanceSelect = () => {
    setSecurityPerformanceSelectedParameter(!parameters.selectedReports?.securityPerformance.selected);
    createReportSortRequest(
      !parameters.selectedReports?.securityPerformance.selected,
      MdaReportTypes.SecurityPerformance.id,
      MdaReportTypes.SecurityPerformance.displayName
    );
  };

  const handleClickReportDateSecurityPerformance = (dates: DateRange) => {
    setDateRangeSecurityPerformanceParameter(dates);
    if (isDatesLessThanFullYear(dates)) {
      setSecurityPerformanceHidingOptions({ option: HidingOptionSecurityPerformance.AnnualisedReturns, value: false });
    }
  };

  return (
    <Accordion
      onChange={expandSecurityPerformance}
      expanded={parameters.selectedReports?.securityPerformance.expanded || false}
      elevation={0}
    >
      <AccordionSummary expandIcon={<ExpandMore />}>
        <FormControlLabel
          sx={{ flex: 1 }}
          aria-label="Acknowledge"
          onClick={(event) => event.stopPropagation()}
          onFocus={(event) => event.stopPropagation()}
          control={
            <WO2Checkbox
              color="primary"
              checked={parameters.selectedReports?.securityPerformance.selected}
              onChangeHandler={handleClickSecurityPerformanceSelect}
            />
          }
          label="Security Performance"
        />
        <div onClick={(e) => e.stopPropagation()}>
          <DateRangePicker
            dateRange={{
              dateFrom: parameters?.selectedReports.securityPerformance?.fromDate,
              dateTo: parameters?.selectedReports.securityPerformance?.toDate,
              dateRangeType: parameters?.selectedReports.securityPerformance?.dateRangeType,
            }}
            setDateRange={handleClickReportDateSecurityPerformance}
            inceptionDate={parameters.inceptionDate}
          />
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container direction="column">
          <Grid container spacing={1}>
            <Grid item>
              <FormControl>
                <InputLabel id="group-by-id">Group by</InputLabel>
                <WO2Select
                  labelId="group-by-id"
                  label="group by"
                  onChange={(e) => setSecurityPerformanceGroupByParameter(e.target.value as GroupBySecurityPerformance)}
                  value={parameters.selectedReports.securityPerformance.groupBy}
                  defaultValue={GroupBySecurityPerformance.AssetClass}
                >
                  <MenuItem value={GroupBySecurityPerformance.AssetClass}>Asset Class</MenuItem>
                  <MenuItem value={GroupBySecurityPerformance.Gics}>GICS</MenuItem>
                </WO2Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item container direction="row" alignItems="center" spacing={1} mt={1}>
            <Grid item container alignItems="center" sm={6} md={3} flexWrap="nowrap">
              <WO2ToggleSwitch
                checked={parameters.selectedReports.securityPerformance.excludeFrankingCredits}
                onChange={(e) =>
                  setSecurityPerformanceHidingOptions({
                    option: HidingOptionSecurityPerformance.FrankingCredits,
                    value: e.target.checked,
                  })
                }
                color="primary"
                name="hidingOptions"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
              <Typography variant="h4">Exclude Franking Credits</Typography>
            </Grid>
            <Grid item container alignItems="center" sm={6} md={3} flexWrap="nowrap">
              <WO2ToggleSwitch
                checked={parameters.selectedReports.securityPerformance.showAnnualisedReturns}
                onChange={(e) =>
                  setSecurityPerformanceHidingOptions({
                    option: HidingOptionSecurityPerformance.AnnualisedReturns,
                    value: e.target.checked,
                  })
                }
                color="primary"
                name="hidingOptions"
                inputProps={{ 'aria-label': 'primary checkbox' }}
                disabled={isDatesLessThanFullYear({
                  dateFrom: parameters?.selectedReports.securityPerformance?.fromDate,
                  dateTo: parameters?.selectedReports.securityPerformance?.toDate,
                  dateRangeType: parameters?.selectedReports.securityPerformance?.dateRangeType,
                })}
              />
              <Typography variant="h4">Show Annualised Returns</Typography>
            </Grid>
            <Grid item container alignItems="center" sm={6} md={3} flexWrap="nowrap">
              <WO2ToggleSwitch
                checked={parameters.selectedReports.securityPerformance.applyExclusionList}
                onChange={(e) =>
                  setSecurityPerformanceHidingOptions({
                    option: HidingOptionSecurityPerformance.ExclusionList,
                    value: e.target.checked,
                  })
                }
                color="primary"
                name="hidingOptions"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
              <Typography variant="h4">Apply Exclusion List</Typography>
            </Grid>
            <Grid item container alignItems="center" sm={6} md={3} flexWrap="nowrap">
              <WO2ToggleSwitch
                checked={parameters.selectedReports.securityPerformance.excludeCapitalIncomeReturns}
                onChange={(e) =>
                  setSecurityPerformanceHidingOptions({
                    option: HidingOptionSecurityPerformance.CapitalIncomeReturn,
                    value: e.target.checked,
                  })
                }
                color="primary"
                name="hidingOptions"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
              <Typography variant="h4">Exclude Capital/Income Returns</Typography>
            </Grid>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
