import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosRequestConfig } from 'axios';
import FileSaver from 'file-saver';
import { buildEncodedQueryString } from 'src/common/utils';
import api from '../../../../../app/api';
import { MainDetails } from '../../common/store/types';
import {
  CGTRecord,
  FetchReportParametersRequest,
  InitiateReportRequest,
  ReportGeneration,
  ReportingParameters,
  SavedParameter,
  SaveReportingParametersRequest,
  TempFileRef,
} from './types';

export enum ClientReportsActionTypes {
  FetchClientReport = '@@client/reportsV2/FetchReport',
  InitiateReportGeneration = '@@client/reportsV2/InitiateReportGeneration',
  FetchClientAccount = '@@client/details/reportsV2/Fetch',
  GetCGTRecordsForClient = '@@client/reportsV2/GetCGTRecordsForClient',
  GetReportById = '@@client/reportsV2/GetReportById',
  GetReportParametersList = '@@client/reportsV2/GetReportParametersList',
  GetReportParametersById = '@@client/reportsV2/GetReportParametersById',
  SaveReportParameters = '@@client/reportsV2/SaveReportParameters',
  SaveCoverPageFile = '@@client/reportsV2/SaveCoverPageFile',
  SaveAppendixFile = '@@client/reportsV2/SaveAppendixFile',
}

export enum ClientReportsApiEndpoints {
  fetchClientReport = '/reporting/DownloadReport',
  initiateReportGeneration = '/reporting/InitiateReportRequest',
  Fetch = '/customers/GetClientMainDetails',
  getReportById = '/reporting/GetReportById',
  getCGTRecords = '/customers/GetCGTRecords',
  getReportParametersList = '/reporting/GetReportParametersList',
  getReportParametersById = '/reporting/GetReportParametersById',
  saveReportParameters = '/reporting/SaveReportingParameters',
  saveCoverPageFile = '/reporting/SaveCoverPageFile',
  saveAppendixFile = '/reporting/SaveAppendixFile',
}

export const saveCoverPageFile = createAsyncThunk(
  ClientReportsActionTypes.SaveCoverPageFile,
  async (request: File, { rejectWithValue }) => {
    try {
      const form = new FormData();
      form.append('file', request);

      const response = await api.put<TempFileRef>(`${ClientReportsApiEndpoints.saveCoverPageFile}`, form);
      return response.data;
    } catch (error) {
      return rejectWithValue({ message: 'Could not save cover page file at this time.', variant: 'error' });
    }
  }
);

export const saveAppendixFile = createAsyncThunk(
  ClientReportsActionTypes.SaveAppendixFile,
  async (request: File, { rejectWithValue }) => {
    try {
      const form = new FormData();
      form.append('file', request);

      const response = await api.put<TempFileRef>(`${ClientReportsApiEndpoints.saveAppendixFile}`, form);
      return response.data;
    } catch (error) {
      return rejectWithValue({ message: 'Could not save appendix file at this time.', variant: 'error' });
    }
  }
);

export const fetchClientReport = createAsyncThunk(
  ClientReportsActionTypes.FetchClientReport,
  async (wrapper: ReportGeneration) => {
    const options: AxiosRequestConfig = {
      responseType: 'blob',
    };
    const queryString = buildEncodedQueryString({
      key: wrapper.key,
      clientId: wrapper.clientId,
    });
    const response = await api.get<BlobPart>(`${ClientReportsApiEndpoints.fetchClientReport}${queryString}`, options);

    const contentType = response.headers['content-type'];
    const fileExtension = contentType === 'application/pdf' ? 'pdf' : 'zip';
    const fileName = `${generateFileName(wrapper.isMergeReport, wrapper)}.${fileExtension}`;
    const data = new Blob([response.data], { type: `application/pdf` });
    FileSaver.saveAs(data, fileName, { autoBom: false });
    return 'success';
  }
);

export const initiateReportGeneration = createAsyncThunk(
  ClientReportsActionTypes.InitiateReportGeneration,
  async (request: InitiateReportRequest) => {
    const response = await api.post<string>(`${ClientReportsApiEndpoints.initiateReportGeneration}`, request);
    return response.data;
  }
);

export const saveReportParameters = createAsyncThunk(
  ClientReportsActionTypes.SaveReportParameters,
  async (request: SaveReportingParametersRequest) => {
    const response = await api.post<string>(`${ClientReportsApiEndpoints.saveReportParameters}`, request);
    return response.data;
  }
);

export const getReportStatus = createAsyncThunk(
  ClientReportsActionTypes.GetReportById,
  async (reportGeneration: ReportGeneration) => {
    const queryString = buildEncodedQueryString({
      requestId: reportGeneration.requestId,
      clientId: reportGeneration.clientId,
    });
    const response = await api.get<ReportGeneration>(`${ClientReportsApiEndpoints.getReportById}${queryString}`);
    return response.data;
  }
);

export const fetchReportParametersList = createAsyncThunk(
  ClientReportsActionTypes.GetReportParametersList,
  async (afslId: number) => {
    const queryString = buildEncodedQueryString({
      afslId: afslId,
    });
    const response = await api.get<SavedParameter[]>(
      `${ClientReportsApiEndpoints.getReportParametersList}${queryString}`
    );
    return response.data;
  }
);

export const getReportParametersById = createAsyncThunk(
  ClientReportsActionTypes.GetReportParametersById,
  async (request: FetchReportParametersRequest) => {
    const queryString = buildEncodedQueryString({
      requestId: request.requestId,
      afslId: request.afslId,
    });
    const response = await api.get<ReportingParameters>(
      `${ClientReportsApiEndpoints.getReportParametersById}${queryString}`
    );
    return response.data;
  }
);

export const fetchClientAccount = createAsyncThunk(
  ClientReportsActionTypes.FetchClientAccount,
  async (clientId: number) => {
    const queryString = buildEncodedQueryString({
      clientId,
    });
    const response = await api.get<MainDetails>(`${ClientReportsApiEndpoints.Fetch}${queryString}`);
    return response.data;
  }
);

export const getCGTRecordsForClient = createAsyncThunk(
  ClientReportsActionTypes.GetCGTRecordsForClient,
  async (clientId: number) => {
    const queryString = buildEncodedQueryString({
      clientId,
    });
    const response = await api.get<CGTRecord[]>(`${ClientReportsApiEndpoints.getCGTRecords}${queryString}`);
    return response.data;
  }
);

const generateFileName = (isMergeReport: boolean, wrapper: ReportGeneration) => {
  if (!isMergeReport) return `${wrapper.clientName}_Reports`;
  return wrapper.mergeReportOrder.length === 2
    ? `${wrapper.clientName}_${wrapper.reportName}_${wrapper.dateRange}`
    : `${wrapper.clientName}_Merged_Reports`;
};
