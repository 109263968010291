import React, { useEffect } from 'react';
import { Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import ProtectedRoute from 'src/ProtectedRoute';
import { CGTConfigurationContainer } from '../configuration/container';
import { Props } from '../container';
import { CapitalGainsLossesContainer } from '../overview/container';

export const CapitalGainsLossesSwitch = ({ clientId, fetchFinancialRecordsForClient }: Props): JSX.Element => {
  const match = useRouteMatch();
  const { pathname } = useLocation();

  useEffect(() => {
    if (!!clientId) {
      fetchFinancialRecordsForClient(clientId);
    }
  }, [clientId, fetchFinancialRecordsForClient]);

  return (
    <>
      <Switch>
        <Route exact path={`${match.path}/`} render={() => <Redirect to={`${pathname}/overview`} />} />
        <ProtectedRoute path={`${match.path}/overview`} component={CapitalGainsLossesContainer}></ProtectedRoute>
        <ProtectedRoute path={`${match.path}/configuration`} component={CGTConfigurationContainer}></ProtectedRoute>
      </Switch>
    </>
  );
};
