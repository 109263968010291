import { Breadcrumbs, Container, Link, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Props } from '../container';
import { Parameters } from './parameters';
import { Results } from './results';
import ValidationMessages from './validationMessages';

export const Edit = (props: Props & RouteComponentProps): JSX.Element => {
  const { isPreApproved, setIsPreApproved } = props;

  useEffect(() => {
    if (isPreApproved) {
      setIsPreApproved();
    }
  }, [isPreApproved, setIsPreApproved]);

  return (
    <Container style={{ paddingTop: '40px', paddingBottom: '40px' }}>
      <Typography variant="h2" style={{ display: 'inline-flex', width: '100%', height: '48px' }}>
        Bulk
      </Typography>
      <Breadcrumbs aria-label="breadcrumb">
        <Link color="inherit" href="/bulk/order">
          Bulk
        </Link>
        <Typography color="textPrimary">Order</Typography>
      </Breadcrumbs>
      <ValidationMessages {...props} />
      <Parameters {...props} />
      <Results {...props} />
    </Container>
  );
};
