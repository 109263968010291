import { createAsyncThunk } from '@reduxjs/toolkit';
import FileSaver from 'file-saver';
import { unparse } from 'papaparse';
import api from '../../../app/api';
import { buildEncodedQueryString } from '../../../common';
import {
  AfslViewModel,
  ConsolidatedHoldings,
  ConsolidatedSecurityHoldings,
  FetchClientListPayload,
  FetchConsolidatedHoldingsPayload,
} from './types';

export enum HoldingsActionTypes {
  FetchConsolidatedSecurityHoldings = '@@holdings/FetchConsolidatedSecurityHoldings',
  FetchConsolidatedHoldings = '@@holdings/FetchConsolidatedHoldings',
  FetchAfsls = '@@holdings/accountType/FetchAfsls',
  FetchSecurities = '@@holdings/GetAllSimpleSecurityList',
}

export enum HoldingsApiEndpoints {
  fetchConsolidatedSecurityHoldings = '/bff/getConsolidatedSecurityHoldings',
  fetchConsolidatedHoldings = '/bff/getConsolidatedHoldings',
  FetchAfsls = 'bff/GetAfslsPracticesAdvisersForUser',
  FetchSecurities = '/securities/GetAllSimpleSecurityList',
}

export const FetchConsolidatedSecurityHoldings = createAsyncThunk(
  HoldingsActionTypes.FetchConsolidatedSecurityHoldings,
  async (wrapper: FetchClientListPayload) => {
    let grouping = 'AssetClass';

    if (wrapper.primaryGrouping === 'Asset class, Security') {
      grouping = 'AssetClass';
    } else if (wrapper.primaryGrouping === 'Adviser, Portfolio') {
      grouping = 'Adviser';
    } else if (wrapper.primaryGrouping === 'Model, Security') {
      grouping = 'Model';
    }

    const queryString = buildEncodedQueryString({
      primaryGrouping: grouping,
      date: wrapper.date,
      isDataFromTriumph: wrapper.isDataFromTriumph,
    });
    const response = await api.get<ConsolidatedSecurityHoldings>(
      `${HoldingsApiEndpoints.fetchConsolidatedSecurityHoldings}${queryString}`
    );
    return response.data;
  }
);

export const FetchConsolidatedHoldings = createAsyncThunk(
  HoldingsActionTypes.FetchConsolidatedHoldings,
  async (wrapper: FetchConsolidatedHoldingsPayload) => {
    let queryString = buildEncodedQueryString({
      date: wrapper.date,    
      afslId: wrapper.afslId,
      practiceId: wrapper.practiceId,      
    });

    queryString += `&isDataFromTriumph=${wrapper.isDateFromTriumph}`
     wrapper.securityIds.forEach(m => queryString +=`&securityIds=${m}` );
     wrapper.licenseeIds.forEach(m => queryString +=`&licenseeIds=${m}` );
     wrapper.practiceIds.forEach(m => queryString +=`&practiceIds=${m}` );
     wrapper.adviserIds.forEach(m => queryString +=`&adviserIds=${m}` );

    queryString +=`&Fields=Afsl&Fields=Practice&Fields=Adviser&Fields=ClientName&Fields=ClientPortfolio&Fields=ModelCode&Fields=ModelName&Fields=SecurityCode&Fields=SecurityName&Fields=SecurityPrice&Fields=AssetClass&Fields=AccountNumber&Fields=Value&Fields=Units`;

    const response = await api.get<ConsolidatedHoldings>(
      `${HoldingsApiEndpoints.fetchConsolidatedHoldings}${queryString}`
    );
    const exportHolding: Record<string, unknown>[] = [];

    response.data.rows.forEach((item) => {
      if (item.fields) {
        exportHolding.push({
          Afsl: item.fields[0],
          Practice: item.fields[1],
          Adviser: item.fields[2],
          ClientName: item.fields[3],
          ClientPortfolio: item.fields[4],
          ModelCode: item.fields[5],
          ModelName: item.fields[6],
          SecurityCode: item.fields[7],
          SecurityName: item.fields[8],
          SecurityPrice: item.fields[9],
          AssetClass: item.fields[10],
          AccountNumber: item.fields[11],
          Units: item.fields[12],
          Value: item.fields[13],
        });
      }
    });

    if (exportHolding.length) {
      const csv = unparse(exportHolding);
      const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      FileSaver.saveAs(csvData, 'WO2_Consolidated_Holdings.csv');
    }

    return response.data;
  }
);

export const fetchAfsls = createAsyncThunk(HoldingsActionTypes.FetchAfsls, async () => {
  const response = await api.get<AfslViewModel>(`${HoldingsApiEndpoints.FetchAfsls}`);
  return response.data;
});

export const fetchSecurities = createAsyncThunk(HoldingsActionTypes.FetchSecurities, async () => {
  const response = await api.get(`${HoldingsApiEndpoints.FetchSecurities}`);
  return response.data;
});
