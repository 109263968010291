import { PagedRequest, PagedResult } from '../../../../store';

export interface ModelListState {
  parameters: ModelListParameters;
  strategyParameters: StrategyListParameters;
  models: PagedResult<ModelItem> | null;
  strategies: PagedResult<StrategyItem> | null;
}

export enum ModelOrStrategy {
  Model = 'Model',
  Strategy = 'Strategy',
}

export interface ModelListParameters {
  modelOrStrategy: ModelOrStrategy;
  pagination: PagedRequest;
  codeOrNameSearch: string | null;
}

export interface StrategyListParameters {
  pagination: PagedRequest;
  codeOrNameSearch: string | null;
}

export interface ModelItem {
  modelId: number;
  modelVersionId: number;
  code: string;
  name: string;
  version: string;
  type: string;
  status: string;
  method: string;
  description: string;
  topSecurityAllocations: ModelAllocation[];
  otherSecuritiesAllocation?: OtherSecuritiesAllocation;
  oneMonthReturn?: number;
}

export interface StrategyItem {
  strategyId: number;
  versionId: number;
  code: string;
  name: string;
  type: string;
  description: string;
  status: string;
  version: string;
}

export interface ModelAllocation {
  securityId: number;
  securityCode: string;
  securityName: string;
  value: number;
}

export interface OtherSecuritiesAllocation {
  code: string;
  name: string;
  value: number;
}

export interface fetchPagedResults<T> {
  results: PagedResult<T>;
  pagination: PagedRequest;
}
