import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../../reducers';
import { selectEntityDetails } from '../common/store/selectors';
import { DashboardComponent } from './components/DashboardComponent';

const mapStateToProps = (state: RootState) => ({
  entityDetails: selectEntityDetails(state),
});

const connector = connect(mapStateToProps);
export type Props = ConnectedProps<typeof connector>;

export const ClientDashboardContainer = connect(mapStateToProps)(DashboardComponent);
