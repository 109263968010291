import { SnackbarProvider } from '@dash/notistack';
import AdapterLuxon from '@mui/lab/AdapterLuxon';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { ThemeProvider } from '@mui/material/styles';
import { LicenseInfo } from '@mui/x-license-pro';
import React from 'react';
import { Router } from 'react-router';
import { ReportGenerationProgress } from 'src/features/clients/client/reports/components/ReportGenerationProgress';
import { ReportGenerationProgress as ReportGenerationProgressV2 } from 'src/features/clients/client/reportsV2/components/ReportGenerationProgress';
import { ConfirmationServiceProvider } from '../common/components/dialogs';
import { Notifier } from '../components/notifications/notifier';
import Navigation from '../features/layout/components/navigation/container';
import history from '../history';
import { theme } from '../themes';
import './App.css';
import Routes from './Routes';

LicenseInfo.setLicenseKey(
  '4b0bfa362126c5b3a59c1319a6ec384aT1JERVI6NDE4MzMsRVhQSVJZPTE2ODE1MTI0NzgwMDAsS0VZVkVSU0lPTj0x'
);

function App(): JSX.Element {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterLuxon}>
          <SnackbarProvider
            maxSnack={5}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
          >
            <Notifier />
            <ReportGenerationProgress />
            <ReportGenerationProgressV2 />
            <ConfirmationServiceProvider>
              <Router history={history}>
                <Navigation>
                  <Routes />
                </Navigation>
              </Router>
            </ConfirmationServiceProvider>
          </SnackbarProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
