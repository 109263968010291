import { createSelector } from '@reduxjs/toolkit';
import { selectSuperSimplifierDetails } from '../../store/selectors';

export const selectBeneficiaries = createSelector(
  selectSuperSimplifierDetails,
  (superSimplifierDetails) => superSimplifierDetails.beneficiaries
);
export const selectAccountClients = createSelector(
  selectBeneficiaries,
  (beneficiaries) => beneficiaries.accountClients
);

export const selectBeneficiariesRows = createSelector(
  selectBeneficiaries,
  (beneficiaries) => beneficiaries.beneficiaries
);
