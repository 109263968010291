import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';
import { DateRange } from '../../../../store';
import { fetchModelDetail, fetchModelPerformanceChartWithBenchmark, fetchModelPeriodicSummary } from './thunks';
import { BenchmarkItem, Frequency, ModelDetail, ModelPerformancechartWithBenchmark, ModelPerformanceState, ModelPeriodicSummary } from './types';

export const initialState: ModelPerformanceState = {
  parameters: {
    dates: { dateFrom: DateTime.now().minus({ years: 1 }).toISODate(), dateTo: DateTime.now().toISODate() },
    frequency: Frequency.Daily,
    modelBenchmarks: null,
    modelBenchmarkSelected: {
      id: 1014,
      code: 'XJO',
      name: 'S&P/ASX 200 Index',
    },
  },
  modelDetail: null,
  modelPeriodicSummary: null,
  modelPerformancechartWithBenchmark: null,
};

export const modelPerformanceSlice = createSlice({
  name: '@@model/Performance',
  initialState,
  reducers: {
    setDateParameter: (state, action: PayloadAction<DateRange>) => {
      state.parameters.dates = action.payload;
    },
    setFrequencyParameter: (state, action: PayloadAction<Frequency>) => {
      state.parameters.frequency = action.payload;
    },
    setBenchmarkParameter: (state, action: PayloadAction<BenchmarkItem | null>) => {
      state.parameters.modelBenchmarkSelected = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchModelDetail.fulfilled, (state, action: PayloadAction<ModelDetail>) => {
      state.modelDetail = action.payload;
    });
    builder.addCase(fetchModelPerformanceChartWithBenchmark.fulfilled, (state, action: PayloadAction<ModelPerformancechartWithBenchmark>) => {
      state.modelPerformancechartWithBenchmark = action.payload;
    });
    builder.addCase(fetchModelPeriodicSummary.fulfilled, (state, action: PayloadAction<ModelPeriodicSummary>) => {
      state.modelPeriodicSummary = action.payload;
    });
  },
});
