import { FilterOperator } from '../common';
import { LoadingProgress } from '../../../../common/store/types';
import { PagedRequest, PagedResult } from '../../../../store';

export enum FilterType {
  none,
  numeric,
  string,
  date,
}

export interface FilterValue {
  operator: FilterOperator;
  value: string;
}

export interface InlineServerSideEditBaseProps<T> {
  pagedRequest: PagedRequest;
  pagedResult?: PagedResult<T>;
  loadingProgress : LoadingProgress,
  csvDownload?: () => void;
  editId?: number | null;
  hideAllActions?: boolean;
  hideColumns?: string[];
  onSave: (item: T) => void;
  onDelete: (id: number) => void;
  onSelectEditId: (id: number | null | undefined) => void;
  onStartAddItem: (item: T) => void;
}