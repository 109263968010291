import Quill from 'quill';

export interface ImageDataProps {
  dataUrl: string;
  type: string;
  minify(options: {
    maxWidth: number;
    maxHeight: number;
    quality: number;
    handler: (quill: Quill, dataUrl: string, type: string, data: ImageData) => void;
  }): Promise<unknown>;
  toFile(filename: string): File | null;
  toBlob(): Blob;
  binaryStringToArrayBuffer(binary: string): ArrayBuffer;
  createBlob(parts: BlobPart[], properties: BlobPropertyBag | undefined): Blob;
}

export class ImageData {
  dataUrl: string;
  type: string;

  constructor(dataUrl: string, type: string) {
    this.dataUrl = dataUrl;
    this.type = type;
  }

  /* minify the image
   */
  minify(options: {
    maxWidth: number;
    maxHeight: number;
    quality: number;
    handler: (quill: Quill, dataUrl: string, type: string, data: ImageData) => void;
  }): Promise<unknown> {
    return new Promise((resolve, reject) => {
      const maxWidth = options.maxWidth || 800;
      const maxHeight = options.maxHeight || 800;
      const quality = options.quality || 0.8;
      if (!this.dataUrl) {
        return reject({ message: '[error] QuillImageDropAndPaste: Fail to minify the image, dataUrl should not be empty.' });
      }
      const image = new Image();
      image.onload = () => {
        const width = image.width;
        const height = image.height;
        if (width > height) {
          if (width > maxWidth) {
            image.height = (height * maxWidth) / width;
            image.width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            image.width = (width * maxHeight) / height;
            image.height = maxHeight;
          }
        }
        const canvas = document.createElement('canvas');
        canvas.width = image.width;
        canvas.height = image.height;
        const ctx = canvas.getContext('2d');
        ctx?.drawImage(image, 0, 0, image.width, image.height);
        const canvasType = this.type || 'image/png';
        const canvasDataUrl = canvas.toDataURL(canvasType, quality);
        resolve(new ImageData(canvasDataUrl, canvasType));
      };
      image.src = this.dataUrl;
    });
  }

  /* convert blob to file
   */
  toFile(filename: string): File | null {
    if (!window.File) {
      console.error('[error] QuillImageDropAndPaste: Your browser didnot support File API.');
      return null;
    }
    return new File([this.toBlob()], filename, { type: this.type });
  }

  /* convert dataURL to blob
   */
  toBlob(): Blob {
    const base64 = this.dataUrl.replace(/^[^,]+,/, '');
    const buff = this.binaryStringToArrayBuffer(atob(base64));
    return this.createBlob([buff], { type: this.type });
  }

  /* generate array buffer from binary string
   */
  binaryStringToArrayBuffer(binary: string): ArrayBuffer {
    const len = binary.length;
    const buffer = new ArrayBuffer(len);
    const arr = new Uint8Array(buffer);
    let i = -1;
    while (++i < len) arr[i] = binary.charCodeAt(i);
    return buffer;
  }

  /* create blob
   */
  createBlob(parts: BlobPart[] = [], properties: BlobPropertyBag | undefined = {}): Blob {
    if (typeof properties === 'string') properties = { type: properties };
    return new Blob(parts, properties);
  }
}

class ImageDropAndPaste {
  quill: Quill;
  options: {
    maxWidth: number;
    maxHeight: number;
    quality: number;
    handler: (quill: Quill, dataUrl: string, type: string, data: ImageData) => void;
  };
  static ImageData: typeof ImageData;

  constructor(
    quill: Quill,
    options: { maxWidth: number; maxHeight: number; quality: number; handler: (quill: Quill, dataUrl: string, type: string, data: ImageData) => void }
  ) {
    this.quill = quill;
    this.options = options;
    this.handleDrop = this.handleDrop.bind(this);
    this.handlePaste = this.handlePaste.bind(this);
    this.quill.root.addEventListener('drop', this.handleDrop, false);
    this.quill.root.addEventListener('paste', this.handlePaste, false);
  }

  /* handle image drop event
   */
  handleDrop(e: DragEvent): void {
    e.preventDefault();
    if (e.dataTransfer && e.dataTransfer.files && e.dataTransfer.files.length) {
      if (document.caretRangeFromPoint) {
        const selection = document.getSelection();
        const range = document.caretRangeFromPoint(e.clientX, e.clientY);
        if (selection && range) {
          selection.setBaseAndExtent(range.startContainer, range.startOffset, range.startContainer, range.startOffset);
        }
      }
      this.readFiles(
        e.dataTransfer.items,
        (dataUrl: string, type: string) => {
          type = type || 'image/png';
          if (typeof this.options.handler === 'function') {
            this.options.handler.call(this, this.quill, dataUrl, type, new ImageData(dataUrl, type));
          } else {
            this.insert.call(this, dataUrl);
          }
        },
        e
      );
    }
  }

  /* handle image paste event
   */
  handlePaste(e: ClipboardEvent): void {
    if (e.clipboardData && e.clipboardData.items && e.clipboardData.items.length) {
      this.readFiles(
        e.clipboardData.items,
        (dataUrl: string, type: string) => {
          type = type || 'image/png';
          if (typeof this.options.handler === 'function') {
            this.options.handler.call(this, this.quill, dataUrl, type, new ImageData(dataUrl, type));
          } else {
            this.insert(dataUrl);
          }
        },
        e
      );
    }
  }

  /* read the files
   */
  readFiles(files: DataTransferItemList, callback: (result: string, type: string) => void, e: Event): void {
    [].forEach.call(files, (file: DataTransferItem) => {
      const type = file.type;
      if (!type.match(/^image\/(gif|jpe?g|a?png|svg|webp|bmp)/i)) return;
      e.preventDefault();
      const reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => {
        if (e.target && e.target.result && typeof e.target.result === 'string') {
          callback(e.target.result, type);
        }
      };
      const blob = file.getAsFile ? file.getAsFile() : file;
      if (blob instanceof Blob) reader.readAsDataURL(blob);
    });
  }

  /* insert into the editor
   */
  insert(dataUrl: string): void {
    let index = (this.quill.getSelection() || {}).index;
    if (index && index < 0) {
      index = this.quill.getLength();
      this.quill.insertEmbed(index, 'image', dataUrl, 'user');
    }
  }
}

ImageDropAndPaste.ImageData = ImageData;

export default ImageDropAndPaste;
