import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PagedRequest } from '../../../../store';
import {
  completeWorkflow,
  discardWorkflow,
  fetchWorkflowList,
  resumeWorkflow,
  terminateWorkflow,
  uploadSuperMemberDataCsv,
  getPensionAccounts,
} from './thunks';
import { FetchPagedResults, UpdateWorkflowResponse, WorkflowItem, WorkflowListState } from './types';
export const initialState: WorkflowListState = {
  workflowError: undefined,
  selectedWorkflow: undefined,
  parameters: {
    pagination: {
      pageNumber: 1,
      pageSize: 25,
      queryFields: [
        {
          fieldName: 'createdOn',
          descendingSortDirection: true,
          hasSearchTerm: false,
          isSortTerm: true,
          searchTerm: '',
          operator: '',
        },
      ],
    },
  },
  workflows: null,
  importErrors: [],
  importUploadSuccess: false,
  isImporting: false,
  pensionAccounts: null
};

export const workflowListSlice = createSlice({
  name: '@@workflows/list',
  initialState,
  reducers: {
    setPaginationParameter: (state, action: PayloadAction<PagedRequest>) => {
      state.parameters.pagination = action.payload;
    },
    resetModelUploadSuccessStatus: (state, action: PayloadAction<boolean>) => {
      state.importUploadSuccess = action.payload;
    },
    setErrorOnFileImport: (state, action: PayloadAction<string[]>) => {
      state.importErrors = action.payload;
      state.importUploadSuccess = false;
      state.isImporting = false;
    },
    setSelectedWorkflow: (state, action: PayloadAction<WorkflowItem | undefined>) => {
      state.selectedWorkflow = action.payload;
    },
    setWorkflowError: (state, action: PayloadAction<string | undefined>) => {
      state.workflowError = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchWorkflowList.fulfilled, (state, action: PayloadAction<FetchPagedResults<WorkflowItem>>) => {
      state.parameters.pagination = action.payload.pagination;
      state.workflows = action.payload.results;
    });
    builder.addCase(terminateWorkflow.fulfilled, (state, action: PayloadAction<UpdateWorkflowResponse>) => {
      if (action.payload.success) {
        state.selectedWorkflow = action.payload.workflowItem;
      } else {
        state.workflowError = action.payload.message;
      }
    });
    builder.addCase(resumeWorkflow.fulfilled, (state, action: PayloadAction<UpdateWorkflowResponse>) => {
      if (action.payload.success) {
        state.selectedWorkflow = action.payload.workflowItem;
      } else {
        state.workflowError = action.payload.message;
      }
    });
    builder.addCase(discardWorkflow.fulfilled, (state, action: PayloadAction<UpdateWorkflowResponse>) => {
      if (action.payload.success) {
        state.selectedWorkflow = action.payload.workflowItem;
      } else {
        state.workflowError = action.payload.message;
      }
    });
    builder.addCase(completeWorkflow.fulfilled, (state, action: PayloadAction<UpdateWorkflowResponse>) => {
      if (action.payload.success) {
        state.selectedWorkflow = action.payload.workflowItem;
      } else {
        state.workflowError = action.payload.message;
      }
    });
    builder.addCase(uploadSuperMemberDataCsv.pending, (state) => {
      state.importErrors = [];
      state.importUploadSuccess = false;
      state.isImporting = true;
    });
    builder.addCase(uploadSuperMemberDataCsv.fulfilled, (state) => {
      state.importUploadSuccess = true;
      state.isImporting = false;
    });
    builder.addCase(uploadSuperMemberDataCsv.rejected, (state, action) => {
      if (action.payload) {
        state.importErrors = action.payload;
      }
      state.importUploadSuccess = false;
      state.isImporting = false;
    });
    builder.addCase(getPensionAccounts.fulfilled, (state, action) => {
      if (action.payload) {
        state.pensionAccounts = action.payload;
      }
    });
  },
});
