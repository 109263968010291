import { Autocomplete, Chip, Grid, Paper, TextField, Typography } from '@mui/material';
import { Field, Form, Formik, FormikProps } from 'formik';
import React, { useEffect, useState } from 'react';
import WO2Button from 'src/common/components/button/Button';
import * as yup from 'yup';
import { ProgressButton, ToggleButtonItem } from '../../../../../../../common/components';
import {
  FormikEnumerationSelect,
  FormikKeyboardDatePicker,
  FormikSelect,
  FormikSwitch,
  FormikTextField,
  FormikToggleButton,
} from '../../../../../../../common/components/formik';
import { LoadingProgress } from '../../../../../../../common/store/types';
import { moment, ServiceTypesEnum } from '../../../../../../../common/types';
import { InvestmentService, RebalanceTypeEnum } from '../../../store/common';
import { BenchmarkItem } from '../store/types';

export interface Props {
  investmentService: InvestmentService | null;
  benchmarks: BenchmarkItem[];
  loadingProgress: LoadingProgress;
  onSave: (investmentService: InvestmentService) => Promise<void>;
  savingProgress: LoadingProgress;
  isCollapsible?: boolean;
  startInEditMode?: boolean;
  hideEditControls?: boolean;
}

interface InvestmentServiceFormValues {
  investmentServiceId: number;
  investmentServiceVersionId: number;
  investmentServiceVersionNumber: number;
  investmentTemplate: string;
  portfolioName: string;
  portfolioInceptionDate: moment;
  minTradeValue: number;
  serviceType: string;
  primaryBenchmarkProductId: number | null;
  secondaryBenchmarkProductId: number | null;
  clientPortalBenchmarks: BenchmarkItem[];
  fortyFiveDayRule: boolean;
  oneYearRule: boolean;
  rebalanceTypeId: number;

  serviceTypeEnum: ServiceTypesEnum;
}

export const DetailsForm = ({
  benchmarks,
  investmentService,
  isCollapsible,
  startInEditMode,
  onSave,
  savingProgress,
  hideEditControls,
}: Props): JSX.Element => {
  const [editMode, setEditMode] = useState<boolean>(startInEditMode || false);

  const getRebalanceTypeToggleButtons = (rebalanceTypeId: number): ToggleButtonItem<number>[] => {
    return [
      {
        name: 'Monthly',
        value: RebalanceTypeEnum.Monthly.id,
        disabled: !editMode,
        selected: rebalanceTypeId === RebalanceTypeEnum.Monthly.id,
      },
      {
        name: 'Quarterly',
        value: RebalanceTypeEnum.Quarterly.id,
        disabled: !editMode,
        selected: rebalanceTypeId === RebalanceTypeEnum.Quarterly.id,
      },
      {
        name: 'Bi Yearly',
        value: RebalanceTypeEnum.BiYearly.id,
        disabled: !editMode,
        selected: rebalanceTypeId === RebalanceTypeEnum.BiYearly.id,
      },
      {
        name: 'Annual',
        value: RebalanceTypeEnum.Annual.id,
        disabled: !editMode,
        selected: rebalanceTypeId === RebalanceTypeEnum.Annual.id,
      },
    ];
  };

  const initialValues: InvestmentServiceFormValues = {
    investmentServiceId: 0,
    investmentServiceVersionId: 0,
    investmentServiceVersionNumber: 0,
    investmentTemplate: '',
    portfolioName: '',
    portfolioInceptionDate: '',
    minTradeValue: 0,
    serviceType: ServiceTypesEnum.IMA.name,
    serviceTypeEnum: ServiceTypesEnum.IMA,
    primaryBenchmarkProductId: null,
    secondaryBenchmarkProductId: null,
    clientPortalBenchmarks: [],
    fortyFiveDayRule: false,
    oneYearRule: false,
    rebalanceTypeId: 0,
  };

  const [formValues, setFormValues] = useState<InvestmentServiceFormValues>(initialValues);

  const updateFormValues = (details: InvestmentService) => {
    setFormValues({
      investmentServiceId: details.investmentServiceId || initialValues.investmentServiceId,
      investmentServiceVersionId: details.investmentServiceVersionId || initialValues.investmentServiceVersionId,
      investmentServiceVersionNumber: details.investmentServiceVersionNumber || initialValues.investmentServiceVersionNumber,
      investmentTemplate: details.investmentTemplate || initialValues.investmentTemplate,
      portfolioName: details.portfolioName || initialValues.portfolioName,
      portfolioInceptionDate: details.portfolioInceptionDate || initialValues.portfolioInceptionDate,
      minTradeValue: details.minTradeValue || initialValues.minTradeValue,
      serviceType: details.serviceType || initialValues.serviceType,
      serviceTypeEnum: ServiceTypesEnum.getByName(details.serviceType) || initialValues.serviceTypeEnum,
      primaryBenchmarkProductId: details.primaryBenchmarkProductId || initialValues.primaryBenchmarkProductId,
      secondaryBenchmarkProductId: details.secondaryBenchmarkProductId || initialValues.secondaryBenchmarkProductId,

      clientPortalBenchmarks: details.clientPortalBenchmarks || initialValues.clientPortalBenchmarks,

      fortyFiveDayRule: details.fortyFiveDayRule || initialValues.fortyFiveDayRule,
      oneYearRule: details.oneYearRule || initialValues.oneYearRule,
      rebalanceTypeId: details.rebalanceTypeId || initialValues.rebalanceTypeId,
    });
  };

  useEffect(() => {
    setEditMode(false);
    !!investmentService && updateFormValues(investmentService);
  }, [setFormValues, investmentService]);

  return (
    <>
      <Typography variant="h4" style={{ paddingBottom: '10px' }}>
        Overview
      </Typography>
      <Formik<InvestmentServiceFormValues>
        enableReinitialize={true}
        initialValues={formValues}
        onSubmit={(details) => {
          !!investmentService &&
            onSave({
              ...investmentService,
              ...details,
            }).then(() => {
              setEditMode(false);
            });
        }}
        validationSchema={yup.object({
          portfolioName: yup.string().required('Portfolio name is required'),
          investmentTemplate: yup.string(),
          serviceType: yup.string().required('Service type is required'),
          primaryBenchmarkProductId: yup.number().nullable(),
          secondaryBenchmarkProductId: yup.number().nullable(),
          clientPortalBenchmarks: yup.array().of(
            yup.object().shape({
              id: yup.number(),
            })
          ),
          minTradeValue: yup
            .number()
            .nullable()
            .typeError('Must be a valid amount')
            .required('Minimum Trade Value is required')
            .min(500, 'Minimum trade value must be greater than ASX minimum ($500)'),
          rebalanceTypeId: yup.number().min(1, 'Rebalance type is required'),
          fortyFiveDayRule: yup.string().nullable(),
          oneYearRule: yup.string().nullable(),
          portfolioInceptionDate: yup.date().nullable().required('Inception date is required').typeError('Must be a valid date'),
        })}
      >
        {(formikProps: FormikProps<InvestmentServiceFormValues>) => (
          <Form>
            <fieldset style={{ border: 'none', padding: '0' }}>
              <Paper elevation={2} style={{ padding: '20px', marginBottom: '30px' }}>
                <Grid container spacing={isCollapsible ? 1 : 2} justifyContent="flex-start">
                  <Grid item xs={isCollapsible ? 12 : 6}>
                    <Field component={FormikTextField} disabled={!editMode} label="NAME *" fullWidth name="portfolioName" />
                  </Grid>
                  <Grid item xs={isCollapsible ? 12 : 6}>
                    <Field
                      component={FormikEnumerationSelect}
                      type={ServiceTypesEnum}
                      name="serviceType"
                      showNone={false}
                      valueIsId={false}
                      disabled={!editMode}
                      label="SERVICE TYPE *"
                    />
                  </Grid>
                  <Grid item xs={isCollapsible ? 12 : 6}>
                    <Field component={FormikKeyboardDatePicker} name="portfolioInceptionDate" label="INCEPTION DATE *" fullWidth disabled={!editMode} />
                  </Grid>
                  <Grid item xs={isCollapsible ? 12 : 6}>
                    <Field
                      as={FormikSelect}
                      itemDisplayNameField={'name'}
                      fieldName="primaryBenchmarkProductId"
                      disabled={!editMode}
                      label="PRIMARY BENCHMARK"
                      data={benchmarks}
                    />
                  </Grid>
                  <Grid item xs={isCollapsible ? 12 : 6} style={{ paddingTop: '32px' }}>
                    <Field
                      as={FormikSelect}
                      itemDisplayNameField={'name'}
                      fieldName="secondaryBenchmarkProductId"
                      disabled={!editMode}
                      data={benchmarks}
                      label="SECONDARY BENCHMARK"
                    />
                  </Grid>
                  <Grid item xs={isCollapsible ? 12 : 6}>
                    <Autocomplete<BenchmarkItem, true>
                      multiple
                      data-testid="autocomplete_clientPortalBenchmarks"
                      value={benchmarks.filter((benchmark) => {
                        return !!formikProps.values.clientPortalBenchmarks.find((b) => b.id === benchmark.id);
                      })}
                      noOptionsText="No benchmarks to select"
                      options={benchmarks}
                      getOptionLabel={(benchmark: BenchmarkItem) => benchmark.name}
                      renderInput={(renderParams) => (
                        <TextField
                          {...renderParams}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          label="CLIENT PORTAL BENCHMARKS"
                          margin="normal"
                          variant="outlined"
                          fullWidth
                        />
                      )}
                      renderTags={(benchmarks, getTagProps) => {
                        return benchmarks.map((benchmark, index) => <Chip label={benchmark.name} {...getTagProps({ index })} key={index} />);
                      }}
                      onChange={(e: React.SyntheticEvent<Element, Event>, benchmarks: BenchmarkItem[]) => {
                        formikProps.setFieldValue('clientPortalBenchmarks', benchmarks);
                      }}
                      disabled={!editMode}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={isCollapsible ? 12 : 6}>
                    <Field component={FormikTextField} disabled={!editMode} label="MINIMUM TRADE VALUE ($) *" fullWidth name="minTradeValue" />
                  </Grid>
                  <Grid item xs={isCollapsible ? 12 : 6}>
                    <Field name="fortyFiveDayRule" component={FormikSwitch} label="APPLY 45 DAY RULE?" disabled={!editMode}></Field>
                    <Field name="oneYearRule" component={FormikSwitch} label="APPLY 1 YEAR RULE?" disabled={!editMode}></Field>
                  </Grid>
                  <Grid item xs={isCollapsible ? 12 : 6}>
                    <Field
                      component={FormikToggleButton}
                      fullWidth="true"
                      disabled={!editMode}
                      exclusive={true}
                      buttons={getRebalanceTypeToggleButtons(formikProps.values.rebalanceTypeId)}
                      name="rebalanceTypeId"
                      label="Rebalance type"
                    />
                  </Grid>
                </Grid>
                {!hideEditControls && (
                  <Grid item xs={12} container justifyContent="flex-end">
                    {!editMode && (
                      <WO2Button variant="contained" onClick={() => setEditMode(true)} style={{ marginLeft: '10px' }}>
                        Edit
                      </WO2Button>
                    )}
                    {editMode && !formikProps.dirty && (
                      <WO2Button
                        variant="outlined"
                        onClick={() => {
                          setEditMode(false);
                        }}
                        style={{ marginLeft: '10px' }}
                      >
                        Cancel
                      </WO2Button>
                    )}
                    {formikProps.dirty && editMode && (
                      <WO2Button
                        variant="outlined"
                        onClick={() => {
                          !!investmentService && updateFormValues(investmentService);
                          formikProps.setValues(formValues);
                          setEditMode(false);
                        }}
                        disabled={savingProgress.isLoading}
                        style={{ marginLeft: '10px' }}
                      >
                        Cancel
                      </WO2Button>
                    )}
                    {formikProps.dirty && editMode && (
                      <ProgressButton variant="contained" progress={savingProgress} onClick={() => formikProps.submitForm()} style={{ marginLeft: '10px' }}>
                        Save
                      </ProgressButton>
                    )}
                  </Grid>
                )}
              </Paper>
            </fieldset>
          </Form>
        )}
      </Formik>
    </>
  );
};
