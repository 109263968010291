import api from 'src/app/api';

export enum MoneysoftServiceApiEndpoints {
  FetchAdviserSigleSignOnToken = '/moneysoft/GetAdviserSingleSignOnToken',
}

export interface GetAdviserSingleSignOnTokenResponse {
  hasMoneysoftDataFeed: boolean;
  singleSignOnToken: string;
}

export const MoneysoftService = (() => {
  const MONEYSOFT_URL = process.env.REACT_APP_MONEYSOFT_URL;

  const GetMoneysoftUrl = async () => {
    const response = await api.post<GetAdviserSingleSignOnTokenResponse>(MoneysoftServiceApiEndpoints.FetchAdviserSigleSignOnToken, {});
    const data = response?.data;

    if (!!data && !!data.singleSignOnToken) {
      const url = `${MONEYSOFT_URL}?token=${data.singleSignOnToken}&source=${location.hostname}`;
      return url;
    }

    return null;
  };

  return {
    GetMoneysoftUrl: GetMoneysoftUrl,
  };
})();
