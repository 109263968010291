import { Search as SearchIcon } from '@mui/icons-material';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { FormControl, Grid, InputLabel, MenuItem, Typography } from '@mui/material';
import FileSaver from 'file-saver';
import { unparse } from 'papaparse';
import React, { useState } from 'react';
import { DateTimeFormat, getLocalDateTimeNow } from 'src/common';
import {
  AddTradePayload,
  ExportTrade,
  FetchSecurityRequestPayload,
  FetchSecuritySearchRequestPayload,
  GroupedByKey,
  ImportRequestPayload,
  MenuOptions,
  Rebalance,
  RebalanceKey,
  Security,
  SecuritySearchResults,
  Trade,
} from '../store/types';
import { WO2Select, WO2TextInput } from './../../../../../common';
import WO2Button from './../../../../../common/components/button/Button';
import Import from './import';
import QuickOrder from './quickOrder';

interface Props {
  groupedByKey: GroupedByKey;
  fetchRebalance: (payload: RebalanceKey) => void;
  setGroupedBy: (payload: GroupedByKey) => void;
  setFilter: (payload: string) => void;
  setClearAll: () => void;
  filter: string;
  rebalanceKey: RebalanceKey;
  importTrades: (payload: ImportRequestPayload) => void;
  isImporting: boolean;
  importErrors: string[];
  rebalance: Rebalance;
  fetchSecurities: (payload: FetchSecuritySearchRequestPayload) => void;
  fetchSecurity: (payload: FetchSecurityRequestPayload) => void;
  addTrade: (payload: AddTradePayload) => void;
  setClearGroupTrades: (payload: Trade[]) => void;
  setClearSecuritySearch: () => void;
  selectedSecurity: Security;
  securitySearchResults: SecuritySearchResults;
  selectedExportTrades: ExportTrade[];
}

export default function Filters(props: Props): JSX.Element {
  const { groupedByKey, setGroupedBy, setFilter, setClearAll, filter, selectedExportTrades, rebalance } = props;

  const [displayMenuOption, setMenuOption] = useState(MenuOptions.None);

  const doImportCustomOrders = () => {
    setMenuOption(MenuOptions.Import);
  };

  const doExportOrders = () => {
    if (selectedExportTrades && selectedExportTrades.length > 0) {
      const csv = unparse(selectedExportTrades);
      const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      FileSaver.saveAs(
        csvData,
        `WO2_Rebalance_${rebalance.clientName}_${getLocalDateTimeNow(DateTimeFormat.Filename)}.csv`
      );
    }
  };

  const doQuickOrder = () => {
    setMenuOption(MenuOptions.QuickOrder);
  };

  return (
    <Grid container sx={{ paddingTop: '20px' }}>
      <Grid item xs={11}>
        <FormControl variant="outlined" style={{ marginRight: '10px', width: '134px' }}>
          <InputLabel>GROUP BY</InputLabel>
          <WO2Select
            value={groupedByKey}
            onChange={(event) => {
              event.target.value &&
                event.target.value !== groupedByKey &&
                setGroupedBy(event.target.value as GroupedByKey);
            }}
            data-testid="groupBy"
            label={'GROUP BY'}
          >
            <MenuItem key={GroupedByKey.AssetClass} value={GroupedByKey.AssetClass}>
              {'Asset Class'}
            </MenuItem>
            <MenuItem key={GroupedByKey.Model} value={GroupedByKey.Model}>
              {'Model'}
            </MenuItem>
          </WO2Select>
        </FormControl>

        <WO2TextInput
          style={{ marginRight: '10px' }}
          placeholder={'Filter Security'}
          inputProps={{ 'aria-label': 'search' }}
          value={filter}
          onChange={(event) => {
            setFilter(event.target.value);
          }}
          InputProps={{ startAdornment: <SearchIcon style={{ margin: '0 10px' }}>search</SearchIcon> }}
          data-testid="search"
        />

        <WO2Button
          sx={{ color: '#4306FA' }}
          color={'inherit'}
          onClick={doExportOrders}
          value="Export Orders"
          startIcon={<FileUploadOutlinedIcon data-testid="exportOrders" />}
        >
          <Typography variant="h5" sx={{ color: '#A6A6A6', marginTop: '3px' }}>
            EXPORT ORDERS
          </Typography>
        </WO2Button>

        <WO2Button
          sx={{ color: '#4306FA' }}
          color={'inherit'}
          onClick={doImportCustomOrders}
          value="Import Custom Orders"
          startIcon={<FileDownloadOutlinedIcon />}
          data-testid="importCustomOrders"
        >
          <Typography variant="h5" sx={{ color: '#A6A6A6', marginTop: '3px' }}>
            IMPORT CUSTOM ORDERS
          </Typography>
        </WO2Button>

        <Import {...props} displayMenuOption={displayMenuOption} setMenuOption={setMenuOption} />

        <WO2Button
          sx={{ color: '#4306FA' }}
          onClick={doQuickOrder}
          value="Quick Order"
          startIcon={<AddRoundedIcon />}
          data-testid="quickOrder"
        >
          <Typography variant="h5" sx={{ color: '#A6A6A6', marginTop: '3px' }}>
            QUICK ORDER
          </Typography>
        </WO2Button>

        <QuickOrder {...props} displayMenuOption={displayMenuOption} setMenuOption={setMenuOption} />
      </Grid>
      <Grid item xs={1} container justifyContent="flex-end">
        <WO2Button
          sx={{ marginRight: '10px', marginLeft: '10px' }}
          variant={'outlined'}
          color={'inherit'}
          onClick={setClearAll}
          value="Clear all"
          startIcon={<CloseRoundedIcon />}
          data-testid="clearAll"
        >
          Clear all
        </WO2Button>
      </Grid>
    </Grid>
  );
}
