import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { progressSelectorFactory } from 'src/common/store/selectors/loadingProgressSelector';
import { RootState } from '../../../../reducers';
import { selectPrevNextRoutePaths } from '../store/routeSelectors';
import { selectClientAccount, selectId, selectTrustee } from '../store/selectors';
import { OnboardActionTypes, saveTrustee } from '../store/thunks';
import { Trustee } from './components/trustee';

const mapStateToProps = (state: RootState, routeComponentProps: RouteComponentProps) => ({
  id: selectId(state),
  clientAccount: selectClientAccount(state),
  trustee: selectTrustee(state),
  prevNextRoutePaths: selectPrevNextRoutePaths(state, routeComponentProps.location.pathname),
  saveProgress: progressSelectorFactory([OnboardActionTypes.SaveTrustee])(state),
});

const mapDispatchToProps = {
  saveTrustee,
};

interface MatchParams {
  id?: string;
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector> & RouteComponentProps<MatchParams>;
export const TrusteeContainer = connect(mapStateToProps, mapDispatchToProps)(Trustee);
