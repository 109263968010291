import { Card } from '@mui/material';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { DateTime } from 'luxon';
import React from 'react';
import { LoadingIndicator } from 'src/common/components/LoadingIndicator';
import { LoadingProgress } from 'src/common/store/types';
import { DateTimeFormat, formatPercentage, getLocalDateTime } from '../../../../common';
import { theme } from '../../../../themes';
import { fetchPerformanceDetailsPayload, ModelDashboardParameters, ModelDetail, PerformanceChartStartDateParameter, PerformanceDetails } from '../store/types';
import MonthlyPerformanceChartButtons, { IIntervalButton } from './MonthlyPerformanceChartButtons';

interface Props {
  loadingProgress: LoadingProgress;
  parameters: ModelDashboardParameters;
  fetchPerformanceDetails: (arg: fetchPerformanceDetailsPayload) => void;
  setChartDateParameter: (arg: PerformanceChartStartDateParameter) => void;
  modelDetail: ModelDetail | null;
  performanceDetails: PerformanceDetails | null;
}

export const MonthlyPerformanceChartComponent = (props: Props): JSX.Element => {
  const { setChartDateParameter, loadingProgress, fetchPerformanceDetails, performanceDetails } = props;
  const categories = performanceDetails?.seriesData?.map((i) => getLocalDateTime(i.date, DateTimeFormat.ChartMonthDate)) ?? [0];
  const monthly = performanceDetails?.seriesData?.map((i) => i.return) ?? [0];
  const monthlyValues = monthly.map((i) => ({ y: i, color: i > 0 ? theme.palette.graphBackground?.main : theme.palette.lightBlue?.main }));
  const cumulativeValues = performanceDetails?.seriesData?.map((i) => i.cumulativeReturn) ?? [0];

  const totalReturn = formatPercentage(cumulativeValues[cumulativeValues.length - 1]);

  const chartOptions = {
    chart: {
      renderTo: 'container',
      backgroundColor: 'transparent',
      plotBackgroundColor: 'transparent',
      height: '200px',
    },
    plotOptions: {
      series: {
        allowPointSelect: false,
        groupPadding: 0,
        pointPadding: 0.1,
        borderWidth: 0,
        color: theme.palette.primary.main,
      },
      column: {
        color: '#FF0000',
        negativeColor: '#0088FF',
      },
    },
    title: {
      text: null,
    },
    subtitle: {
      text: null,
    },
    xAxis: [
      {
        gridLineColor: 'transparent',
        lineColor: 'transparent',
        categories: categories,
        labels: {
          enabled: false,
        },
      },
    ],
    yAxis: [
      {
        title: { text: undefined },
        gridLineColor: 'transparent',
        lineColor: 'transparent',
        labels: {
          enabled: false,
        },
        plotLines: [
          {
            color: theme.palette.graphBackground?.main,
            width: 0.5,
            value: 0,
          },
        ],
      },
    ],
    tooltip: {
      shared: true,
      formatter: function (this: Highcharts.TooltipFormatterContextObject) {
        return this.points?.reduce(function (s: string, point: Highcharts.TooltipFormatterContextObject) {
          return s + '<br/><span style="color:' + point.color + '">\u25CF</span>  ' + point.series.name + ': ' + formatPercentage(point.y);
        }, '<b>' + this.x + '</b>');
      },
    },
    series: [
      {
        showInLegend: false,
        name: 'Monthly Return',
        type: 'column',
        data: monthlyValues,
        states: {
          inactive: {
            opacity: 1,
          },
        },
      },
      {
        color: theme.palette.primary.main,
        name: 'Cumulative Return',
        type: 'line',
        data: cumulativeValues,
        zIndex: 100,
        states: {
          inactive: {
            opacity: 1,
          },
        },
        marker: {
          enabled: false,
        },
      },
    ],
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
  };

  const intervalButtonClicked = (button: IIntervalButton) => {
    const modelVersionId = props.modelDetail?.modelVersionId;

    if (!!modelVersionId) {
      setChartDateParameter({ interval: button.name, startDate: button.startDate });
      fetchPerformanceDetails({
        modelVersionId: modelVersionId,
        startDate: button.startDate,
        endDate: DateTime.now().toISODate(),
      });
    }
  };

  return (
    <LoadingIndicator progress={loadingProgress}>
      <Card className={'Card'} square elevation={0} style={{ background: theme.palette.gradientHero?.main, padding: '30px 0' }}>
        <Container fixed>
          <Typography variant={'h4'} align={'center'}>
            Total Return {totalReturn}
          </Typography>

          <HighchartsReact highcharts={Highcharts} options={chartOptions} />
          <MonthlyPerformanceChartButtons {...props} intervalButtonClicked={intervalButtonClicked} />
        </Container>
      </Card>
    </LoadingIndicator>
  );
};
