import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';
import { SystemMinimumDate } from 'src/common/utils/dateFunctions';
import { fetchPortfolioDetail, fetchTemplates } from './thunks';
import { GroupingType, InvestmentServiceTemplate, OverviewState, PortfolioDetailResult, PortfolioParameters } from './types';

export const initialState: OverviewState = {
  parameters: {
    dates: { dateFrom: DateTime.fromISO(SystemMinimumDate).toISODate(), dateTo: DateTime.now().toISODate() },
    groupingType: GroupingType.AssetClass,
    showPositionsNotHeld: true,
  },
  templates: [],
};

export const overviewSlice = createSlice({
  name: '@@client/portfolio/overview',
  initialState,
  reducers: {
    setParameters: (state: OverviewState, action: PayloadAction<PortfolioParameters>) => {
      state.parameters = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPortfolioDetail.fulfilled, (state: OverviewState, action: PayloadAction<PortfolioDetailResult>) => {
      state.portfolioDetailResult = action.payload;
    });
    builder.addCase(fetchTemplates.fulfilled, (state: OverviewState, action: PayloadAction<InvestmentServiceTemplate[]>) => {
      state.templates = action.payload;
    });
  },
});
