import Check from '@mui/icons-material/Check';
import { Typography } from '@mui/material';
import { Field, FieldProps } from 'formik';
import React from 'react';
import { yupValidatePhoneNumberFormat } from 'src/common/components/formik';
import * as yup from 'yup';
import { InlineEditBaseProps, InlineEditColumn, InlineEditColumnSet, InlineEditDataTable } from '../../../../../../common/components/dataTable/clientSide';
import { FilterDataType } from '../../../../../../common/components/dataTable/types';
import { FormikEnumerationSelect, FormikNumberFormat, FormikSwitch } from '../../../../../../common/components/formik';
import { PhoneNumberType } from '../../store/enums';
import { PhoneNumber } from '../../store/types';

export type PhoneNumberEditTableProps = InlineEditBaseProps<PhoneNumber>;

export const PhoneNumberTable = (props: PhoneNumberEditTableProps): JSX.Element => {
  const { items, onSave, onDelete, editId, onSelectEditId, onStartAddItem, progress, hideAllActions } = props;

  const typeColumn = (): InlineEditColumnSet => {
    return {
      standardTemplate: function standardTemplate(dataIndex: number) {
        return <Typography variant="h5">{PhoneNumberType.getById(items[dataIndex]?.typeId)?.displayName}</Typography>;
      },
      editTemplate: function editTemplate() {
        return <Field component={FormikEnumerationSelect} type={PhoneNumberType} showNone={false} name="typeId" valueIsId={true} label="TYPE" fullWidth />;
      },
    };
  };

  const areaCodeColumn = (): InlineEditColumnSet => {
    return {
      standardTemplate: function standardTemplate(dataIndex: number) {
        return <Typography variant="h5">{items[dataIndex]?.areaCode}</Typography>;
      },
      editTemplate: function editTemplate() {
        return <Typography variant="h5"></Typography>;
      },
    };
  };

  const phoneNumberColumn = (): InlineEditColumnSet => {
    return {
      standardTemplate: function standardTemplate(dataIndex: number) {
        return <Typography variant="h5">{items[dataIndex]?.phoneNumber}</Typography>;
      },
      editTemplate: function editTemplate() {
        return (
          <Field name="phoneNumber" label="PHONE NUMBER" fullWidth>
            {(fieldProps: FieldProps) => {
              return (
                <FormikNumberFormat
                  formikFieldProps={fieldProps}
                  numberFormatProps={{
                    format: '## #### ####',
                    mask: '_',
                    placeholder: '__ ____ ____',
                    isNumericString: true,
                    name: 'phoneNumber',
                    label: 'PHONE NUMBER',
                  }}
                ></FormikNumberFormat>
              );
            }}
          </Field>
        );
      },
    };
  };

  const preferredColumn = (): InlineEditColumnSet => {
    return {
      standardTemplate: function standardTemplate(dataIndex: number) {
        return (
          <Typography variant="h5" align="center">
            {!!items[dataIndex]?.preferred && <Check></Check>}
          </Typography>
        );
      },
      editTemplate: function editTemplate() {
        return <Field name="preferred" component={FormikSwitch} label="PREFERRED" style={{ textAlign: 'center' }}></Field>;
      },
    };
  };

  const columns: InlineEditColumn[] = [
    {
      filterDataType: FilterDataType.string,
      textAlign: 'left',
      name: 'type',
      label: 'TYPE',
      templates: typeColumn(),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      filterDataType: FilterDataType.string,
      textAlign: 'left',
      name: 'areaCode',
      label: 'AREA CODE',
      templates: areaCodeColumn(),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      filterDataType: FilterDataType.string,
      textAlign: 'left',
      name: 'phoneNumber',
      label: 'PHONE NUMBER',
      templates: phoneNumberColumn(),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      textAlign: 'center',
      name: 'preferred',
      label: 'PREFERRED',
      templates: preferredColumn(),
      options: {
        filter: false,
        sort: true,
      },
    },
  ];

  const processAreaCode = (phoneNumber: PhoneNumber): PhoneNumber => {
    let pn = phoneNumber.phoneNumber;
    let ac = phoneNumber.areaCode;

    if (['02', '03', '07', '08'].includes(phoneNumber.phoneNumber.substr(0, 2))) {
      // split out areacode
      pn = phoneNumber.phoneNumber.substr(2);
      ac = phoneNumber.phoneNumber.substr(0, 2);
    }

    return {
      id: phoneNumber.id,
      areaCode: ac,
      countryCode: phoneNumber.countryCode,
      phoneNumber: pn,
      preferred: phoneNumber.preferred,
      typeId: phoneNumber.typeId,
    };
  };

  return (
    <div style={{ paddingTop: '20px' }}>
      <Typography variant="h4" gutterBottom>
        Phone numbers
      </Typography>
      <InlineEditDataTable<PhoneNumber>
        loadingProgress={progress}
        hideAllActions={!!hideAllActions}
        columns={editId === undefined ? columns : columns.filter((c) => c.name !== 'areaCode')}
        data={items.map((i) => {
          return {
            id: i.id,
            areaCode: '',
            countryCode: i.countryCode || '',
            phoneNumber: (i.areaCode || '') + i.phoneNumber,
            preferred: i.preferred,
            typeId: i.typeId,
          };
        })}
        options={{
          filter: true,
          pagination: true,
        }}
        deleteOptions={{
          dialogTitle: 'Are you sure you want to delete this phone number?',
          dialogDescription: 'This action is permanent and cannot be reverted',
        }}
        newItem={{
          id: null,
          areaCode: '',
          countryCode: '61',
          phoneNumber: '',
          preferred: false,
          typeId: PhoneNumberType.Business.id,
        }}
        onSave={(phoneNumber) => {
          onSave(processAreaCode(phoneNumber));
        }}
        onDelete={onDelete}
        onSelectEditId={onSelectEditId}
        onSetItemAdd={onStartAddItem}
        editId={editId}
        validationSchema={yup.object({
          phoneNumber: yup
            .string()
            .required('Phone number is required')
            .min(10, 'Must be 10 digits including area code')
            .max(10, 'Must be 10 digits including area code')
            .test('test-phoneNumber-format', 'Incorrect format', yupValidatePhoneNumberFormat),
          typeId: yup.number().required('Type is required'),
        })}
      ></InlineEditDataTable>
    </div>
  );
};
