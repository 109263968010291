import { Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import React from 'react';
import { SecurityItemWithPrice } from '../../../common/store/types';
import { Trade } from '../store/types';
import PreapprovedTrade from './preapprovedTrade';

interface Props {
  security: SecurityItemWithPrice | undefined;
  trades: Trade[];
  hasRebalanceEnhancedTrading: boolean;
  hasClientEditAdminOnlyPermission: boolean;
}

export default function PreapprovedTrades(props: Props): JSX.Element {
  const { security, trades, hasRebalanceEnhancedTrading, hasClientEditAdminOnlyPermission } = props;

  return (
    <Paper elevation={0} style={{ marginBottom: '40px', overflow: 'auto' }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell variant={'body'} style={{ padding: '10px', width: '40%' }}>
              <Typography variant="h3" color={'primary'}>
                {security?.code}
              </Typography>
              <Typography variant="h4" color={'textSecondary'}>
                {security?.name}
              </Typography>
            </TableCell>
            <TableCell variant={'body'} align="left"></TableCell>
            <TableCell variant={'body'} align="left"></TableCell>
            <TableCell variant={'body'} align="right"></TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant={'head'}>
              <Typography variant="h6" color="textSecondary">
                NAME
              </Typography>
            </TableCell>
            <TableCell variant={'head'} align="left">
              <Typography variant="h6" color="textSecondary">
                UNITS & PRICE
              </Typography>
            </TableCell>
            <TableCell variant={'head'} align="center">
              <Typography variant="h6" color="textSecondary">
                BUY/SELL
              </Typography>
            </TableCell>
            {(hasRebalanceEnhancedTrading || hasClientEditAdminOnlyPermission) && (
              <TableCell variant={'head'}>
                <Typography variant="h6" color="textSecondary">
                  ORDER TYPE
                </Typography>
              </TableCell>
            )}
            {(hasRebalanceEnhancedTrading || hasClientEditAdminOnlyPermission) && (
              <TableCell variant={'head'}>
                <Typography variant="h6" color="textSecondary">
                  EXPIRY
                </Typography>
              </TableCell>
            )}
            {(hasRebalanceEnhancedTrading || hasClientEditAdminOnlyPermission) && (
              <TableCell variant={'head'}>
                <Typography variant="h6" color="textSecondary">
                  EXPIRY DATE
                </Typography>
              </TableCell>
            )}
            <TableCell variant={'head'} align="right" style={{ width: '20%' }}>
              <Typography variant="h6" color="textSecondary">
                TOTAL
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {trades.map((trade: Trade, index: number) => (
            <PreapprovedTrade key={index} trade={trade} index={index} hasRebalanceEnhancedTrading hasClientEditAdminOnlyPermission />
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
}
