import { SuperMemberData, MemberRow, SuperMemberDataRequest, SuperMemberBeneficiariesRequest } from '../store/types';
import * as E from 'fp-ts/lib/Either';
import reporter from 'io-ts-reporters';
import { checkDuplicateMemberRecords } from './rules';
import { parse, ParseResult } from 'papaparse';

export const parseImportFile = (importFile: File): Promise<ParseResult<unknown>> => {
  return new Promise<ParseResult<unknown>>((resolve) => {
    parse(importFile, {
      header: true,
      dynamicTyping: true,
      skipEmptyLines: true,
      complete: function (results) {
        resolve(results);
      },
    });
  });
};

export const decodeData = (data: SuperMemberData[]): { membersData: SuperMemberData[]; errors: string[] } => {
  const errors: string[] = [];
  const membersData: SuperMemberData[] = [];
  data.forEach((row, index) => {
    const result = MemberRow.decode(row);

    if (E.isLeft(result)) {
      const error = reporter.report(result);
      errors.push(`row: ${index + 1} message: ${error.toString()}\n`);
    } else {
      membersData.push({
        MemberNumber: result.right.MemberNumber,
        BeneficiaryName1: result.right.BeneficiaryName1,
        BeneficiaryRelationship1: result.right.BeneficiaryRelationship1,
        BeneficiaryPercent1: result.right.BeneficiaryPercent1,
        BeneficiaryBinding1: result.right.BeneficiaryBinding1,
        BeneficiaryName2: result.right.BeneficiaryName2,
        BeneficiaryRelationship2: result.right.BeneficiaryRelationship2,
        BeneficiaryPercent2: result.right.BeneficiaryPercent2,
        BeneficiaryBinding2: result.right.BeneficiaryBinding2,
        BeneficiaryName3: result.right.BeneficiaryName3,
        BeneficiaryRelationship3: result.right.BeneficiaryRelationship1,
        BeneficiaryPercent3: result.right.BeneficiaryPercent3,
        BeneficiaryBinding3: result.right.BeneficiaryBinding3,
        BeneficiaryName4: result.right.BeneficiaryName4,
        BeneficiaryRelationship4: result.right.BeneficiaryRelationship4,
        BeneficiaryPercent4: result.right.BeneficiaryPercent4,
        BeneficiaryBinding4: result.right.BeneficiaryBinding4,
        BeneficiaryName5: result.right.BeneficiaryName5,
        BeneficiaryRelationship5: result.right.BeneficiaryRelationship5,
        BeneficiaryPercent5: result.right.BeneficiaryPercent5,
        BeneficiaryBinding5: result.right.BeneficiaryBinding5,
        BeneficiaryName6: result.right.BeneficiaryName6,
        BeneficiaryRelationship6: result.right.BeneficiaryRelationship6,
        BeneficiaryPercent6: result.right.BeneficiaryPercent6,
        BeneficiaryBinding6: result.right.BeneficiaryBinding6,
        BeneficiaryName7: result.right.BeneficiaryName7,
        BeneficiaryRelationship7: result.right.BeneficiaryRelationship7,
        BeneficiaryPercent7: result.right.BeneficiaryPercent7,
        BeneficiaryBinding7: result.right.BeneficiaryBinding7,
        BeneficiaryName8: result.right.BeneficiaryName8,
        BeneficiaryRelationship8: result.right.BeneficiaryRelationship8,
        BeneficiaryPercent8: result.right.BeneficiaryPercent8,
        BeneficiaryBinding8: result.right.BeneficiaryBinding8,
        BeneficiaryName9: result.right.BeneficiaryRelationship9,
        BeneficiaryRelationship9: result.right.BeneficiaryRelationship9,
        BeneficiaryPercent9: result.right.BeneficiaryPercent9,
        BeneficiaryBinding9: result.right.BeneficiaryBinding9,
        BeneficiaryName10: result.right.BeneficiaryName10,
        BeneficiaryRelationship10: result.right.BeneficiaryRelationship10,
        BeneficiaryPercent10: result.right.BeneficiaryPercent10,
        BeneficiaryBinding10: result.right.BeneficiaryBinding10,
        DeathPremium: result.right.DeathPremium,
        TBDPremium: result.right.TBDPremium,
        IncomeProtection: result.right.IncomeProtection,
        PensionPaymentValue: result.right.PensionPaymentValue,
        PensionPaymentFrequency: result.right.PensionPaymentFrequency,
        NextPensionPaymentDate: result.right.NextPensionPaymentDate,
        ConcessionalContribution: result.right.ConcessionalContribution,
        NonConcessionalContribution: result.right.NonConcessionalContribution,
        UnrestrictedNonPreserved: result.right.UnrestrictedNonPreserved,
        RestrictedNonPreserved: result.right.RestrictedNonPreserved,
        Preserved: result.right.Preserved,
        Taxable: result.right.Taxable,
        TaxFree: result.right.TaxFree,
        TaxLiability: result.right.TaxLiability,
      });
    }
  });
  return { membersData, errors };
};
export const validateData = (data: SuperMemberData[]): { memberData: SuperMemberData[]; errors: string[] } => {
  const errors: string[] = [] as string[];
  const memberData: SuperMemberData[] = data;

  const duplicateErrors = checkDuplicateMemberRecords(data);
  if (duplicateErrors !== '') {
    return { memberData: [], errors: [duplicateErrors] };
  }
  return { memberData, errors };
};

export const convertData = (data: SuperMemberData[]): SuperMemberDataRequest[] => {
  const result = [] as SuperMemberDataRequest[];

  data.forEach((member: SuperMemberData) => {
    const beneficiaries = [] as SuperMemberBeneficiariesRequest[];
    for (let i = 1; i <= 10; ++i) {
      type attributeKey = keyof typeof member;
      const nameAttribute: attributeKey = `BeneficiaryName${i}` as attributeKey;
      if (member[nameAttribute] !== null && member[nameAttribute] !== undefined && member[nameAttribute] !== '') {
        const beneficiary: SuperMemberBeneficiariesRequest = {
          BeneficiaryNumber: i,
          Name: member[nameAttribute] as string,
          Binding: member[`BeneficiaryBinding${i}` as attributeKey] as string | null | undefined,
          Relationship: member[`BeneficiaryRelationship${i}` as attributeKey] as string | null | undefined,
          Percent: member[`BeneficiaryPercent${i}` as attributeKey] as number | null | undefined,
        };
        beneficiaries.push(beneficiary);
      }
    }

    const convertedMember: SuperMemberDataRequest = {
      MemberNumber: member.MemberNumber,
      Beneficiaries: beneficiaries,
      BenefitStatement: {
        ConcessionalContribution: member.ConcessionalContribution,
        NonConcessionalContribution: member.NonConcessionalContribution,
        UnrestrictedNonPreserved: member.UnrestrictedNonPreserved,
        RestrictedNonPreserved: member.RestrictedNonPreserved,
        Preserved: member.Preserved,
        Taxable: member.Taxable,
        TaxFree: member.TaxFree,
        TaxLiability: member.TaxLiability,
      },
      Insurance: {
        DeathPremium: member.DeathPremium,
        TotalPermanentDisabilityPremium: member.TBDPremium,
        IncomeProtection: member.IncomeProtection,
        PensionPaymentValue: member.PensionPaymentValue,
        PensionPaymentFrequency: member.PensionPaymentFrequency,
        NextPensionPaymentDate: !!member.NextPensionPaymentDate ? new Date(Date.parse(member.NextPensionPaymentDate)) : null,
      },
    };
    result.push(convertedMember);
  });
  return result;
};
