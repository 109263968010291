import {
  AddchartRounded,
  BeachAccessRounded,
  FamilyRestroomOutlined,
  RequestQuoteRounded,
  SavingsRounded,
  SummarizeOutlined,
} from '@mui/icons-material';
import { ClientAccountSubType, ClientAccountType } from 'src/common/types';
import { MenuRoute } from 'src/features/clients/common/types';
import { BeneficiariesContainer } from './beneficaries/container';
import { BenefitStatementContainer } from './benefitStatement/container';
import { ContributionDetailsContainer } from './contributions/container';
import { InsuranceDetailContainer } from './insuranceDetail/container';
import { PensionDetailsContainer } from './pensionDetails/container';
import { TransactionsContainer } from './Transactions/container';

export const benefitsStatementRoute: MenuRoute = {
  label: 'Benefits Statement',
  menuIndex: 3.1,
  path: 'benefitsStatement',
  component: BenefitStatementContainer,
  icon: SavingsRounded,
  visible: () => true,
};

export const transactionsRoute: MenuRoute = {
  label: 'Transactions',
  menuIndex: 3.2,
  path: 'transactions',
  component: TransactionsContainer,
  icon: SummarizeOutlined,
  visible: () => true,
};

export const beneficiariesRoute: MenuRoute = {
  label: 'Beneficiaries',
  menuIndex: 3.3,
  path: 'beneficiaries',
  component: BeneficiariesContainer,
  icon: FamilyRestroomOutlined,
  visible: () => true,
};

export const pensionRoute: MenuRoute = {
  label: 'Pension Details',
  menuIndex: 3.4,
  path: 'pensionDetails',
  component: PensionDetailsContainer,
  icon: RequestQuoteRounded,
  visible: (accountType?: ClientAccountType, accountSubType?: ClientAccountSubType): boolean => {
    return accountType === ClientAccountType.Individual && accountSubType === ClientAccountSubType.Pension;
  },
};

export const insuranceRoute: MenuRoute = {
  label: 'Insurance',
  menuIndex: 3.5,
  path: 'insurance',
  component: InsuranceDetailContainer,
  icon: BeachAccessRounded,
  visible: () => true,
};

export const contributionsRoute: MenuRoute = {
  label: 'Contributions',
  menuIndex: 3.6,
  path: 'contributions',
  component: ContributionDetailsContainer,
  icon: AddchartRounded,
  visible: (accountType?: ClientAccountType, accountSubType?: ClientAccountSubType) => {
    return !(accountType === ClientAccountType.Individual && accountSubType === ClientAccountSubType.Pension);
  },
};

export const superSimplifierRoutes: MenuRoute[] | undefined = [
  benefitsStatementRoute,
  transactionsRoute,
  beneficiariesRoute,
  pensionRoute,
  insuranceRoute,
  contributionsRoute,
];
