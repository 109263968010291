import { Attachment, FileDownloadOutlined } from '@mui/icons-material';
import { Box, Chip, Fade, Grid, Link, Paper, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import { Field, Form, Formik, FormikProps } from 'formik';
import React, { useCallback, useRef, useState } from 'react';
import Dropzone from 'react-dropzone';
import * as yup from 'yup';
import { ToggleButtonItem } from '../../../../../../common';
import { WO2Modal } from '../../../../../../common/';
import {
  ButtonType,
  EditCancelSaveButtons,
  FormikKeyboardDatePicker,
  FormikSelect,
  FormikSwitch,
  FormikTextField,
  FormikToggleButton,
  Mode,
} from '../../../../../../common/components/formik';
import { toBase64 } from '../../../../../../common/utils';
import { useStyles } from '../../../../../../themes/index';
import { DocumentType } from '../../../common/store/types';
import { FeeConsentMethod, UploadFeeConsentValues } from '../store/types';

export interface UploadFeeConsentProps {
  isViewMode: boolean;
  isSuperOrPension: boolean;
  documentTypes: DocumentType[];
  onSave: (uploadFeeConsentValues: UploadFeeConsentValues) => void;
  handleCloseModal: () => void;
  downloadFeeConsent: () => void;
}

export const UploadFeeConsent = (props: UploadFeeConsentProps): JSX.Element => {
  const { isViewMode, isSuperOrPension, documentTypes, onSave, handleCloseModal, downloadFeeConsent: downloadSuperSimplifierFeeConsent } = props;

  const classes = useStyles();
  const [selectedFeeConsentTypeId, setSelectedFeeConsentTypeId] = useState<number | null>(null);

  const formRef = useRef<FormikProps<UploadFeeConsentValues>>(null);
  const formValues: UploadFeeConsentValues = {
    isSuperOrPension: isSuperOrPension,
    feeConsentTypeId: null,
    acknowledged: false,
    fileName: '',
    issueDate: null,
    expiryDate: null,
    typeId: null,
    name: '',
    file: null,
    base64Content: null,
  };

  const feeConsentTypeToggleButtons: ToggleButtonItem<number>[] = FeeConsentMethod.getArray().map((fee: FeeConsentMethod) => {
    return { name: fee.displayName, value: fee.id };
  });

  const handleCancelClick = useCallback(() => {
    handleCloseModal();
  }, [handleCloseModal]);

  return (
    <div>
      <WO2Modal
        MuiDialogProps={{
          open: true,
          onClose: handleCloseModal,
        }}
        title={<>{isSuperOrPension ? 'Upload fee consent' : 'Activate Fees'}</>}
        actions={[]}
        maxWidth={'md'}
      >
        <Fade in={true}>
          <Paper elevation={0}>
            <Formik<UploadFeeConsentValues>
              data-testid={'uploadFeeConsentForm'}
              enableReinitialize={true}
              initialValues={formValues}
              innerRef={formRef}
              validationSchema={yup.object({
                feeConsentTypeId: yup
                  .number()
                  .nullable()
                  .when('isSuperOrPension', {
                    is: (isSuperOrPension) => isSuperOrPension,
                    then: yup.number().required('Type required'),
                  }),
                acknowledged: yup.boolean().when(['feeConsentTypeId', 'isSuperOrPension'], {
                  is: (feeConsentTypeId, isSuperOrPension) => !isSuperOrPension || feeConsentTypeId === FeeConsentMethod.SendViaDocusign.id,
                  then: yup.boolean().oneOf([true], 'Acknowledgment required'),
                }),
                name: yup
                  .string()
                  .nullable()
                  .when('feeConsentTypeId', {
                    is: (feeConsentTypeId) => feeConsentTypeId === FeeConsentMethod.UploadDocument.id,
                    then: yup.string().required('Document Name required'),
                  }),
                typeId: yup
                  .number()
                  .nullable()
                  .when('feeConsentTypeId', {
                    is: (feeConsentTypeId) => feeConsentTypeId === FeeConsentMethod.UploadDocument.id,
                    then: yup.number().required('Document Type required'),
                  }),
                issueDate: yup
                  .date()
                  .nullable()
                  .when('feeConsentTypeId', {
                    is: (feeConsentTypeId) => feeConsentTypeId === FeeConsentMethod.UploadDocument.id,
                    then: yup.date().required('Issue Date required'),
                  }),
                expiryDate: yup
                  .date()
                  .nullable()
                  .when('feeConsentTypeId', {
                    is: (feeConsentTypeId) => feeConsentTypeId === FeeConsentMethod.UploadDocument.id,
                    then: yup.date().required('Expiry Date required'),
                  }),
                file: yup.mixed().when('feeConsentTypeId', {
                  is: (feeConsentTypeId) => feeConsentTypeId === FeeConsentMethod.UploadDocument.id,
                  then: yup.mixed().required('File required'),
                }),
              })}
              onSubmit={async (uploadDetails: UploadFeeConsentValues) => {
                onSave(uploadDetails);
                handleCloseModal();
              }}
            >
              {(formikProps: FormikProps<UploadFeeConsentValues>) => (
                <Form>
                  <fieldset style={{ border: 'none', margin: '0', padding: '0', pointerEvents: isViewMode ? 'none' : 'auto' }}>
                    {isSuperOrPension && (
                      <>
                        <Grid container style={{ margin: '30px 0 0 0' }}>
                          <Typography variant="body2">
                            Before change fees, you must upload a SOA, MDA agreement or other signed document evidencing the client&rsquo;s consent to the new
                            fees.
                          </Typography>
                        </Grid>
                        <Grid container style={{ margin: '30px 0 0 0' }}>
                          <Grid item xs={6}>
                            <Field
                              data-testid="feeConsentTypeIdInput"
                              exclusive={true}
                              component={FormikToggleButton}
                              buttons={feeConsentTypeToggleButtons}
                              formik={formikProps}
                              name={'feeConsentTypeId'}
                              onChangeHandler={(value: number) => {
                                setSelectedFeeConsentTypeId(value);
                              }}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Link
                              data-testid="downloadFormLink"
                              underline="none"
                              onClick={() => {
                                downloadSuperSimplifierFeeConsent();
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  flexWrap: 'wrap',
                                  justifyContent: 'right',
                                }}
                              >
                                <span style={{ paddingRight: '5px', paddingTop: '1px' }}>Download form </span>
                                <FileDownloadOutlined />
                              </div>
                            </Link>
                          </Grid>
                        </Grid>
                        {selectedFeeConsentTypeId === FeeConsentMethod.SendViaDocusign.id && (
                          <Grid container style={{ margin: '0 0 30px 0' }}>
                            <Stack direction="row" spacing={2}>
                              <Box width="50px" data-testid="docusignAcknowledgedInput">
                                <Field name="acknowledged" component={FormikSwitch}></Field>
                              </Box>
                              <Typography variant="body2">
                                I agree to send my client a pre-populated Super Simplifer fee consent form. I agree that all proposed fees will be included in
                                this document
                              </Typography>
                            </Stack>
                          </Grid>
                        )}
                        {selectedFeeConsentTypeId === FeeConsentMethod.UploadDocument.id && (
                          <Grid container>
                            <Grid item xs={12}>
                              <Dropzone
                                onDrop={async (files) => {
                                  // reset the name to the filename and set the file
                                  if (!!files && !!files[0]) {
                                    const file = files[0];
                                    await toBase64(file).then((base64Content) => {
                                      formikProps.setValues({ ...formikProps.values, file, name: file.name, fileName: file.name, base64Content });
                                      formikProps.validateForm();
                                    });
                                  }
                                }}
                              >
                                {({ getRootProps, getInputProps }) => (
                                  <div
                                    data-testid="dropzone"
                                    {...getRootProps({ className: classes.dropzone })}
                                    style={{
                                      borderColor: !!formikProps.errors.file ? 'red' : 'gray',
                                      marginTop: 10,
                                      marginBottom: 20,
                                      marginLeft: 0,
                                      marginRight: 0,
                                      minHeight: 80,
                                    }}
                                  >
                                    <input
                                      {...getInputProps({
                                        type: 'file',
                                      })}
                                    />
                                    {!!formikProps.values.file && (
                                      <Chip
                                        label={formikProps.values.file?.name}
                                        onDelete={() => {
                                          formikProps.setValues({ ...formikProps.values, file: null });
                                        }}
                                        icon={<Attachment />}
                                      />
                                    )}
                                    {!formikProps.values.file && (
                                      <p style={{ color: !!formikProps.errors.file ? 'red' : 'gray' }}>
                                        {'Drag and drop your file here, or click to select file'}
                                      </p>
                                    )}
                                  </div>
                                )}
                              </Dropzone>
                            </Grid>
                            <Grid item xs={6} style={{ paddingRight: '20px' }}>
                              <Grid data-testid="nameInput" item xs={12} style={{ marginBottom: '16px', minHeight: '70px' }}>
                                <Field name="name" component={FormikTextField} label="DOCUMENT NAME" fullWidth></Field>
                              </Grid>
                              <Grid data-testid="issueDateInput" item xs={12} style={{ marginBottom: '16px', minHeight: '70px' }}>
                                <Field component={FormikKeyboardDatePicker} name="issueDate" label="ISSUE DATE" fullWidth />
                              </Grid>
                            </Grid>
                            <Grid item xs={6}>
                              <Grid data-testid="typeIdInput" item xs={12} style={{ marginBottom: '16px', minHeight: '70px' }}>
                                <Field
                                  fieldName="typeId"
                                  as={FormikSelect}
                                  itemDisplayNameField="name"
                                  label="DOCUMENT TYPE"
                                  id="typeIdSelect"
                                  data={documentTypes}
                                  onChange={() => {
                                    return;
                                  }}
                                  fullWidth
                                ></Field>
                              </Grid>
                              <Grid data-testid="expiryDateInput" item xs={12} style={{ marginBottom: '16px', minHeight: '70px' }}>
                                <Field component={FormikKeyboardDatePicker} name="expiryDate" label="EXPIRY DATE" fullWidth />
                              </Grid>
                            </Grid>
                          </Grid>
                        )}
                      </>
                    )}
                    {!isSuperOrPension && (
                      <>
                        <Grid container style={{ margin: '30px 0 30px 0' }}>
                          <Typography variant="body2">Are you sure you want to activate this client&apos;s fees?</Typography>
                        </Grid>
                        <Grid container style={{ margin: '0 0 30px 0' }}>
                          <Stack direction="row" spacing={2}>
                            <Box width="50px" data-testid="activateAcknowledgedInput">
                              <Field name="acknowledged" component={FormikSwitch}></Field>
                            </Box>
                            <Typography variant="body2">
                              I confirm that have stored evidence of my client&apos;s consent for these fees to be deducted and can produce these records should
                              they be requested by Dash.
                            </Typography>
                          </Stack>
                        </Grid>
                      </>
                    )}
                  </fieldset>
                  <EditCancelSaveButtons
                    saveButtonText="Confirm"
                    hideSaveButtonIcon={true}
                    mode={Mode.CancelSave}
                    handleCancelClick={handleCancelClick}
                    disabledButtonTypes={isViewMode ? ButtonType.Save : undefined}
                  />
                </Form>
              )}
            </Formik>
          </Paper>
        </Fade>
      </WO2Modal>
    </div>
  );
};
