import { Route } from '../../store';
import { WorkflowListStateContainer } from './list/container';

export const workflowRoutes: Route[] = [
  {
    path: '/workflow/list',
    component: WorkflowListStateContainer,
    protected: true,
    exact: true,
  },
];
