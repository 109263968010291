import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ResultWrapper } from '../../benefitStatement/store/types';
import { fetchContributionDetails } from './thunks';
import { ContributionDetails, ContributionDetailsState } from './types';

export const initialState: ContributionDetailsState = {
  details: {
    responseData: {
      accountId: '',
      contributionsTax: 0,
      superGuarantee: 0,
      salarySacrifice: 0,
      award: 0,
      employerVoluntary: 0,
      coContribution: 0,
      otherFamilyAndFriend: 0,
      nonAssessableForeignFund: 0,
      assessableForeignFund: 0,
      cgtSmallBusRetirementExempt: 0,
      cgtSmallBus15yrExempt: 0,
      personalInjury: 0,
      directedTerminationPaymentTaxable: 0,
      memberNonConcessional: 0,
      memberConcessional: 0,
      spouseAndChild: 0,
      noTFNTax: 0,
      directedTerminationPaymentTaxFree: 0,
      insuranceProceeds: 0,
      firstHomeSaverAccount: 0,
      lowIncomeSuperContribution: 0,
      homeDownsize: 0,
      fhssRecontribution: 0,
      kiwiNonAssessableForeignFund: 0,
    },
  },
};

export const contributionDetailsSlice = createSlice({
  name: 'contributionDetailsSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchContributionDetails.fulfilled, (state, action: PayloadAction<ResultWrapper<ContributionDetails | null>>) => {
      state.details = action.payload;
    });
  },
});
