import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchModelDetails } from './thunks';
import { ModelDetails, ModelDetailsState } from './types';

export const initialState: ModelDetailsState = {
  modelDetails: null,
};

export const modelDetailsSlice = createSlice({
  name: '@@model/details',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchModelDetails.fulfilled, (state, action: PayloadAction<ModelDetails>) => {
      state.modelDetails = action.payload;
    });
  },
});
