import { FormControl, InputLabel, OutlinedInput } from '@mui/material';
import { default as React, useEffect, useRef, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useStyles } from '../../../themes/dataTableStyles';

interface Props {
  value: string;
  onChange: (value: string) => void;
}

export const NumericFilterValue = (props: Props): JSX.Element => {
  const classes = useStyles();
  const [labelWidth, setLabelWidth] = useState(0);
  const inputLabel: React.RefObject<HTMLLabelElement> | null | undefined = useRef(null);

  useEffect(() => {
    if (inputLabel && inputLabel.current) {
      setLabelWidth(inputLabel.current.offsetWidth);
    }
  }, []);

  return (
    <div>
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel ref={inputLabel} id="operator-select-label">
          Filter
        </InputLabel>
        <NumberFormat
          value={props.value}
          name="value"
          type="text"
          inputProps={{ labelwidth: labelWidth }}
          customInput={OutlinedInput}
          thousandSeparator
          onValueChange={(event) => {
            props.onChange(event.value);
          }}
        />
      </FormControl>
    </div>
  );
};
