import { Box, FormControlLabel } from '@mui/material';
import { WO2Checkbox } from 'src/common';
import { ClientReportsParameters, MdaReportTypes } from '../../store/types';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import React from 'react';
import DateRangePicker from 'src/common/components/DateRangePicker';
import { DateRange } from 'src/store';

export interface TaxInvoiceProps {
  parameters: ClientReportsParameters;
  setDateRangeTaxInvoiceParameter: ActionCreatorWithPayload<DateRange>;
  setTaxInvoiceSelectedParameter: ActionCreatorWithPayload<boolean, string>;
  createReportSortRequest: (isAdd: boolean, id: number, text: string) => void;
}

export const TaxInvoice = ({
  parameters,
  setTaxInvoiceSelectedParameter,
  setDateRangeTaxInvoiceParameter,
  createReportSortRequest,
}: TaxInvoiceProps) => {
  const handleClickTaxInvoiceSelect = () => {
    setTaxInvoiceSelectedParameter(!parameters.selectedReports?.taxInvoice.selected);
    createReportSortRequest(
      !parameters.selectedReports?.taxInvoice.selected,
      MdaReportTypes.TaxInvoice.id,
      MdaReportTypes.TaxInvoice.displayName
    );
  };
  const handleClickDateForTaxInvoice = (dates: DateRange) => {
    setDateRangeTaxInvoiceParameter(dates);
  };
  return (
    <Box ml={6} display="flex" justifyContent="space-between" mt={1} mb={1}>
      <FormControlLabel
        aria-label="Acknowledge"
        onClick={(event) => event.stopPropagation()}
        onFocus={(event) => event.stopPropagation()}
        control={
          <WO2Checkbox
            color="primary"
            checked={parameters.selectedReports?.taxInvoice.selected}
            onChangeHandler={handleClickTaxInvoiceSelect}
          />
        }
        label="Tax Invoice"
      />
      <DateRangePicker
        dateRange={{
          dateFrom: parameters?.selectedReports.taxInvoice?.fromDate,
          dateTo: parameters?.selectedReports.taxInvoice?.toDate,
          dateRangeType: parameters?.selectedReports.taxInvoice?.dateRangeType,
        }}
        setDateRange={handleClickDateForTaxInvoice}
        inceptionDate={parameters.inceptionDate}
      />
    </Box>
  );
};
