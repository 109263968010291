import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Client, EmailTemplate, Parameter } from '../../../common/store/types';
import { fetchClients, fetchEmailTemplates, fetchParameters } from './../../../common/store/thunks';
import { fetchUser } from './thunks';
import { EmailTemplateState, User } from './types';

export const initialState: EmailTemplateState = {
  parameters: [],
  topParameters: [],
  emailTemplates: [],
  emailTemplateResults: [],
  clients: [],
  topClients: [],
  sender: null,
};

export const sendSlice = createSlice({
  name: '@@bulk/email/send',
  initialState,
  reducers: {
    setClients: (state, action: PayloadAction<Client[]>) => {
      state.clients = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchParameters.fulfilled, (state, action: PayloadAction<Parameter[]>) => {
      state.parameters = action.payload;
    });
    builder.addCase(fetchEmailTemplates.fulfilled, (state, action: PayloadAction<EmailTemplate[]>) => {
      state.emailTemplates = action.payload;
    });
    builder.addCase(fetchClients.fulfilled, (state, action: PayloadAction<Client[]>) => {
      state.clients = action.payload;
    });
    builder.addCase(fetchUser.fulfilled, (state, action: PayloadAction<User>) => {
      state.sender = action.payload;
    });
  },
});
