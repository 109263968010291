import { connect, ConnectedProps } from 'react-redux';
import { progressSelectorFactory } from '../../../../../../common';
import { RootState } from '../../../../../../reducers';
import { selectClientId } from '../../../common/store/selectors';
import { selectCurrentInvestmentVersionId } from './../../store/common';
import { LinkedAccounts } from './components/linkedAccounts';
import { AccountsActionTypes, fetchAccounts, linkedAccountsSlice, saveAccounts, selectAccounts } from './store';

const mapStateToProps = (state: RootState) => ({
  clientId: selectClientId(state),
  investmentServiceVersionId: selectCurrentInvestmentVersionId(state),
  accounts: selectAccounts(state),
  loadingProgress: progressSelectorFactory([AccountsActionTypes.FetchAccounts])(state),
  savingProgress: progressSelectorFactory([AccountsActionTypes.SaveAccounts])(state),
});

const mapDispatchToProps = {
  ...linkedAccountsSlice.actions,
  fetchAccounts,
  saveAccounts,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector>;
export const AccountsContainer = connector(LinkedAccounts);
