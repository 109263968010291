import { MoreVertOutlined } from '@mui/icons-material';
import RefreshRounded from '@mui/icons-material/RefreshRounded';
import { Grid, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect } from 'react';
import {
  ClientNewAccountDetail,
  ClientNewMacquarieAccountDetailByClientIdPayload,
  DeleteAccountPayload,
  InitiateAccountApplicationPayload,
  InitiateExistingMacquarieAccountApplicationPayload,
  InitiateNewMacquarieAccountApplicationPayload,
} from 'src/features/clients/client/details/accounts/store/types';
import { theme } from 'src/themes';
import { LoadingProgress } from '../../store/types';
import { WO2Menu } from '../Menu';
import { DialogActionButton, WO2Modal } from '../Modal';
import { ClientSideDataTable } from '../dataTable/clientSide';
import { DatatableColumn, FilterDataType } from '../dataTable/types';
import { PendingAccountDetail } from './PendingAccountDetail';
import { AMMAccount } from './forms/ammAccount';
import { DeleteAccount } from './forms/deleteAccount';
import { DesktopBrokerAccount } from './forms/desktopBrokerAccount';
import { WO2ExternalAccount } from './forms/externalAccount';
import { MissingInformation } from './forms/missingInformation';
import { OpenMarketsAccount } from './forms/openMarketsAccount';
import { UXchangeAccount } from './forms/uXchangeAccount';
import { WO2UnlistedAccount } from './forms/unlistedAccount';
import {
  AccountInstitutionType,
  AccountType,
  AttachableCmaAccount,
  DetachPendingAccountPayload,
  ExternalAccount,
  ExternalInstitution,
  OtherAccountTypes,
  PendingAccount,
  PendingAccountStatus,
  PendingAccountWithActions,
  SelectedCmaAccount,
  UnlistedAccount,
  WO2PendingAccountActionType,
} from './types';
import { ADVISER_CONTACT_EMAIL } from 'src/common/utils/dashContactDetails';

export interface AccountProps {
  accounts: PendingAccountWithActions[];
  accountTypes?: AccountType[];
  attachableCmaAccounts: AttachableCmaAccount[];
  superSimplifierAccountStatus?: number;
  clientId?: number | null;
  fetchAccountTypes?: () => void;
  fetchPendingAccounts: (clientId: number) => void;
  initiateExistingMacquarieAccountApplication: (payload: InitiateExistingMacquarieAccountApplicationPayload) => void;
  initiateNewMacquarieAccountApplication: (payload: InitiateNewMacquarieAccountApplicationPayload) => void;
  initiateUxchangeworkflowAccountApplicationWorkflow: (payload: InitiateAccountApplicationPayload) => void;
  fetchClientNewMacquarieAccountDetailByClientId: (payload: ClientNewMacquarieAccountDetailByClientIdPayload) => void;
  deletePendingAccount: (payload: DeleteAccountPayload) => void;
  externalInstitutions?: ExternalInstitution[];
  hideAddButton?: boolean;
  hideAllActions?: boolean;
  id?: string;
  isReadOnly: boolean;
  loadingProgress: LoadingProgress;
  loadingTypesProgress?: LoadingProgress;
  onAddEdit?: (id: number | null | undefined) => void;
  onDetach?: (payload: DetachPendingAccountPayload) => void;
  onSaveUnlistedForm?: (account: UnlistedAccount) => void;
  onSaveExternal?: (account: ExternalAccount) => void;
  saveExternalAccountProgress?: LoadingProgress;
  saveUnlistedAccountProgress?: LoadingProgress;
  selectedAccount?: PendingAccount | null;
  submitAccountId: number | null;
  onOpenMarketsSubmit: (selectedCmaAccount: SelectedCmaAccount) => void;
  onAMMSubmit: (selectedCmaAccount: SelectedCmaAccount) => void;
  onDesktopBrokerSubmit: (selectedCmaAccount: SelectedCmaAccount) => void;
  onUXchangeSubmit: (selectedCmaAccount: InitiateAccountApplicationPayload) => void;
  deleteAccountId: number | null;
  onDeleteAccount: (selectedCmaAccount: AttachableCmaAccount) => void;
  onCloseSubmit?: () => void;
  onCloseDelete?: () => void;
  macquarieCashOnlinePortal: ClientNewAccountDetail;
}
export const useStyles = makeStyles(() => ({
  addToTable: {
    backgroundColor: theme.palette.primary.main,
    fontSize: '1.25rem !important',
    padding: '4px',
    margin: '12px',
    color: theme.palette.common.white,
    '& > span > svg': {
      fontSize: '1.25rem !important',
    },
  },
}));
export function WO2PendingAccounts({
  accounts,
  attachableCmaAccounts,
  superSimplifierAccountStatus,
  clientId,
  externalInstitutions,
  hideAddButton,
  hideAllActions,
  id,
  loadingProgress,
  onSaveExternal,
  onSaveUnlistedForm,
  saveExternalAccountProgress,
  saveUnlistedAccountProgress,
  submitAccountId,
  onOpenMarketsSubmit,
  onAMMSubmit,
  onDesktopBrokerSubmit,
  onCloseSubmit,
  onCloseDelete,
  fetchClientNewMacquarieAccountDetailByClientId,
  initiateUxchangeworkflowAccountApplicationWorkflow,
  initiateNewMacquarieAccountApplication,
  initiateExistingMacquarieAccountApplication,
  macquarieCashOnlinePortal,
  fetchPendingAccounts,
  deleteAccountId,
  deletePendingAccount,
}: AccountProps): JSX.Element {
  useEffect(() => {
    if (clientId) {
      fetchClientNewMacquarieAccountDetailByClientId({ clientId });
    }
  }, [clientId, fetchClientNewMacquarieAccountDetailByClientId]);

  const [selectedCreateFormState, selectCreateForm] = React.useState<number | string | null>(null);

  const submitDialogActionButtons: DialogActionButton[] = [
    {
      label: 'Close',
      onClickHandler: () => {
        !!onCloseSubmit && onCloseSubmit();
      },
      type: 'contained',
    },
  ];

  // columns
  const detailsColumn = (dataIndex: number): React.ReactNode => {
    return accounts.length > 0 && !!accounts[dataIndex] ? (
      <PendingAccountDetail isSuperSimplifier={!!superSimplifierAccountStatus} account={accounts[dataIndex]} />
    ) : (
      <></>
    );
  };

  const stateColumn = (dataIndex: number): React.ReactNode => {
    let stateText: string | null = '';
    if (!!accounts[dataIndex] && accounts[dataIndex].state !== null) {
      stateText = accounts[dataIndex].state;
    }
    return <Typography variant="h5">{stateText}</Typography>;
  };

  const statusMessage = (dataIndex: number): React.ReactNode => {
    let statusText: string | null = '';
    if (!!accounts[dataIndex] && accounts[dataIndex].statusMessage !== null) {
      statusText = accounts[dataIndex].statusMessage;
    }
    return <Typography variant="h5">{statusText}</Typography>;
  };

  const institutionId = accounts.find((x: PendingAccount) => x.id === String(submitAccountId))?.institutionId;
  const state = accounts.find((x: PendingAccount) => x.id === String(submitAccountId))?.state;
  const isDataStatusReady = accounts.find((x: PendingAccount) => x.id === String(submitAccountId))?.isDataStatusReady;
  const statusId = accounts.find((x: PendingAccount) => x.id === String(submitAccountId))?.statusId;
  const errors = accounts.find((x: PendingAccount) => x.id === String(submitAccountId))?.errors;
  const accountId = accounts.find((x: PendingAccount) => x.id === String(submitAccountId))?.id;

  if (
    institutionId === AccountInstitutionType.MacquarieCma.id &&
    state === 'New' &&
    statusId === PendingAccountStatus.getByName('Submitted')?.id
  ) {
  } else if (institutionId === AccountInstitutionType.MacquarieCma.id && ['New', 'Existing'].includes(state ?? '')) {
    submitDialogActionButtons.push({
      label: state === 'New' ? 'Submit' : 'Send',
      onClickHandler: () => {
        if (clientId && accountId) {
          if (state === 'New') {
            initiateNewMacquarieAccountApplication({ clientId, pendingAccountId: accountId });
          } else {
            initiateExistingMacquarieAccountApplication({ clientId, pendingAccountId: accountId });
          }
        }
        !!onCloseSubmit && onCloseSubmit();
      },
      type: 'contained',
    });
  }

  const actionsColumn = (dataIndex: number): React.ReactNode => {
    const actionsList = !!accounts[dataIndex]?.actions
      ? accounts[dataIndex]?.actions.map((action: WO2PendingAccountActionType) => ({
          icon: action.icon,
          label: action.label,
          onClick: () => {
            const account: PendingAccount = accounts[dataIndex];
            const property: keyof PendingAccount = action.name;

            if (account !== null && property !== null) {
              action.clickHandler(account[property] as number);
            }
          },
          testId: `accountAction_${action.label.replace(/\s/g, '')}_${dataIndex}`,
        }))
      : [];

    return (
      <WO2Menu
        testId={`accountActionsButton_${!!id ? id + '_' : ''}${dataIndex}`}
        buttonTitle="Client Account Actions"
        buttonIcon={<MoreVertOutlined color={accounts[dataIndex]?.actions.length ? 'primary' : 'disabled'} />}
        items={actionsList}
      />
    );
  };

  const onSaveUnlisted = (account: UnlistedAccount) => {
    selectCreateForm('CREATED');
    if (!!onSaveUnlistedForm) {
      onSaveUnlistedForm(account);
    }
  };

  const onSaveExternalAccount = (account: ExternalAccount) => {
    selectCreateForm('CREATED');
    if (!!onSaveExternal) {
      onSaveExternal(account);
    }
  };

  const addButton = (): React.ReactNode => (
    <Tooltip title="Refresh">
      <RefreshRounded
        style={{ cursor: 'pointer', color: '#757575' }}
        onClick={() => {
          if (!!clientId) {
            fetchPendingAccounts(clientId);
            fetchClientNewMacquarieAccountDetailByClientId({ clientId });
          }
        }}
      ></RefreshRounded>
    </Tooltip>
  );

  const columns: DatatableColumn[] = [
    {
      filterDataType: FilterDataType.string,
      name: 'accountName',
      label: 'ACCOUNT DETAILS',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex): React.ReactNode => detailsColumn(dataIndex),
      },
    },
    {
      name: 'stateStatus',
      label: 'TYPE',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex): React.ReactNode => stateColumn(dataIndex),
      },
    },
    {
      name: 'statusMessage',
      label: 'STATUS',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex): React.ReactNode => statusMessage(dataIndex),
      },
    },
    {
      name: 'actions',
      label: ' ',
      textAlign: 'right',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex: number): React.ReactNode => actionsColumn(dataIndex),
      },
    },
  ];

  let modalTitle = '';
  if (
    institutionId === AccountInstitutionType.DesktopBroker.id ||
    institutionId === AccountInstitutionType.Openmarkets.id ||
    institutionId === AccountInstitutionType.AMM.id
  ) {
    modalTitle = 'Contact account provider';
  } else if (institutionId === AccountInstitutionType.MacquarieCma.id && state === 'Existing') {
    modalTitle = 'Email populated form';
  } else if (
    institutionId === AccountInstitutionType.MacquarieCma.id &&
    state === 'New' &&
    isDataStatusReady &&
    statusId === PendingAccountStatus.getByName('Submitted')?.id
  ) {
    modalTitle = 'Leaving DASH';
  } else if (institutionId === AccountInstitutionType.MacquarieCma.id && state === 'New') {
    modalTitle = 'Application started';
  } else if (
    institutionId === AccountInstitutionType.ManagedFunds.id &&
    state === 'New' &&
    isDataStatusReady &&
    statusId === PendingAccountStatus.getByName('Proposed')?.id
  ) {
    modalTitle = 'Application started';
  }

  const hrefMacquarieCashOnlinePortal = macquarieCashOnlinePortal?.clientNewAccountDetails?.find(
    (c) => c.applicationId === String(submitAccountId).replace(/-/g, '')
  )?.resumeURL;

  return (
    <>
      <ClientSideDataTable
        loadingProgress={loadingProgress}
        columns={hideAllActions === true ? columns.filter((c) => c.name !== 'actions') : columns}
        data={accounts}
        options={{
          filter: false,
          pagination: false,
          viewColumns: false,
          customToolbar: () => !hideAddButton && !loadingProgress?.isLoading && addButton(),
        }}
        id={`${id}-accounts-datatable`}
      ></ClientSideDataTable>
      {!!externalInstitutions && externalInstitutions.length > 0 && (
        <WO2ExternalAccount
          saveProgress={saveExternalAccountProgress}
          onSave={onSaveExternalAccount}
          handleCloseModal={() => {
            selectCreateForm(null);
          }}
          externalInstitutions={externalInstitutions}
          isOpen={selectedCreateFormState === OtherAccountTypes.External}
        />
      )}
      {clientId && (
        <WO2UnlistedAccount
          saveProgress={saveUnlistedAccountProgress}
          onSave={onSaveUnlisted}
          handleCloseModal={() => {
            selectCreateForm(null);
          }}
          isOpen={selectedCreateFormState === AccountInstitutionType.Unlisted.name}
        />
      )}

      {institutionId === AccountInstitutionType.Openmarkets.id &&
      state === 'New' &&
      isDataStatusReady &&
      statusId === PendingAccountStatus.getByName('Proposed')?.id ? (
        <OpenMarketsAccount
          attachableCmaAccounts={attachableCmaAccounts}
          onClose={() => {
            !!onCloseSubmit && onCloseSubmit();
          }}
          onSubmit={(cmaAccount) => {
            onOpenMarketsSubmit(cmaAccount);
          }}
          superSimplifierAccountStatus={superSimplifierAccountStatus}
        ></OpenMarketsAccount>
      ) : institutionId === AccountInstitutionType.AMM.id &&
        state === 'New' &&
        isDataStatusReady &&
        statusId === PendingAccountStatus.getByName('Proposed')?.id ? (
        <AMMAccount
          attachableCmaAccounts={attachableCmaAccounts}
          onClose={() => {
            !!onCloseSubmit && onCloseSubmit();
          }}
          onSubmit={(cmaAccount) => {
            onAMMSubmit(cmaAccount);
          }}
        ></AMMAccount>
      ) : institutionId === AccountInstitutionType.DesktopBroker.id &&
        state === 'New' &&
        isDataStatusReady &&
        statusId === PendingAccountStatus.getByName('Proposed')?.id ? (
        <DesktopBrokerAccount
          attachableCmaAccounts={attachableCmaAccounts}
          onClose={() => {
            !!onCloseSubmit && onCloseSubmit();
          }}
          onSubmit={(cmaAccount) => {
            onDesktopBrokerSubmit(cmaAccount);
          }}
        ></DesktopBrokerAccount>
      ) : institutionId === AccountInstitutionType.ManagedFunds.id &&
        state === 'New' &&
        isDataStatusReady &&
        statusId === PendingAccountStatus.getByName('Proposed')?.id ? (
        <UXchangeAccount
          attachableCmaAccounts={attachableCmaAccounts}
          clientId={clientId}
          submitAccountId={submitAccountId}
          onClose={() => {
            !!onCloseSubmit && onCloseSubmit();
          }}
          onSubmit={(selectedCmaAccount: InitiateAccountApplicationPayload) => {
            initiateUxchangeworkflowAccountApplicationWorkflow(selectedCmaAccount);
          }}
        ></UXchangeAccount>
      ) : errors?.length ? (
        <MissingInformation
          errors={errors}
          onClose={() => {
            !!onCloseSubmit && onCloseSubmit();
          }}
        ></MissingInformation>
      ) : deleteAccountId ? (
        <DeleteAccount
          deleteAccountId={deleteAccountId}
          clientId={clientId}
          onCloseDelete={() => {
            !!onCloseDelete && onCloseDelete();
          }}
          onDelete={(account) => {
            if (deleteAccountId && clientId) {
              deletePendingAccount({ pendingAccountId: account.pendingAccountId, clientId });
            }
          }}
        ></DeleteAccount>
      ) : (
        <WO2Modal
          MuiDialogProps={{
            open: !!submitAccountId,
            onClose: () => {
              !!onCloseSubmit && onCloseSubmit();
            },
            onSubmit: () => {
              !!onCloseSubmit && onCloseSubmit();
            },
          }}
          title={<b>{modalTitle}</b>}
          actions={submitDialogActionButtons}
        >
          <>
            {institutionId === AccountInstitutionType.DesktopBroker.id && state === 'Existing' && (
              <div>
                <p>
                  Thank you for starting the linking process with DASH. To finalise linking, please send an email to{' '}
                  <a href="mailto:support@desktopbroker.com.au">support@desktopbroker.com.au</a> (cc{' '}
                  <a href={`mailto:${ADVISER_CONTACT_EMAIL}`}>{ADVISER_CONTACT_EMAIL}</a>) including the account
                  details.
                </p>

                <p>
                  For more detailed instructions, review the DASH academy guide:{' '}
                  <a
                    href="https://app.mdaoperator.com.au/Documents/DownLoad?documentId=441"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Instructions for Existing Desktop Broker accounts
                  </a>
                </p>
              </div>
            )}

            {institutionId === AccountInstitutionType.Openmarkets.id && state === 'Existing' && (
              <div>
                <p>
                  Thank you for starting the linking process with DASH. To finalise linking, please send an email to{' '}
                  <a href="mailto:service@openmarkets.com.au">service@openmarkets.com.au</a> (cc{' '}
                  <a href={`mailto:${ADVISER_CONTACT_EMAIL}`}>{ADVISER_CONTACT_EMAIL}</a>) including the account
                  details.
                </p>

                <p>
                  For more detailed instructions, review the DASH academy guide:{' '}
                  <a
                    href="https://app.mdaoperator.com.au/Documents/DownLoad?documentId=124356"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Instructions for Existing OpenMarkets accounts
                  </a>
                </p>
              </div>
            )}

            {institutionId === AccountInstitutionType.AMM.id && state === 'Existing' && (
              <div>
                <p>
                  Thank you for starting the linking process with DASH. To finalise linking, please send an email to{' '}
                  <a href="mailto:admin@moneymarket.com.au">admin@moneymarket.com.au</a> (cc{' '}
                  <a href={`mailto:${ADVISER_CONTACT_EMAIL}`}>{ADVISER_CONTACT_EMAIL}</a>) including the account
                  details.
                </p>

                <p>
                  For more detailed instructions, review the DASH academy guide:{' '}
                  <a
                    href="https://app.mdaoperator.com.au/Documents/DownLoad?documentId=428"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Instructions for Existing AMM accounts
                  </a>
                </p>
              </div>
            )}

            {institutionId === AccountInstitutionType.MacquarieCma.id && state === 'Existing' && isDataStatusReady && (
              <Grid container>
                <p>
                  This will automatically populate a Macquarie TPA form and email it to the address on your user record.
                </p>
                <p>Please check your email shortly to review the attached TPA form.</p>
              </Grid>
            )}

            {institutionId === AccountInstitutionType.MacquarieCma.id &&
              state === 'New' &&
              isDataStatusReady &&
              statusId === PendingAccountStatus.getByName('Proposed')?.id && (
                <div>
                  <Typography variant="h4" style={{ fontWeight: 700, paddingTop: '10px' }}>
                    Are you sure you want to create this account?
                  </Typography>

                  <p>
                    This will start a CMA application with Macquarie Bank. Please ensure the application information is
                    correct before proceeding.
                  </p>
                </div>
              )}

            {institutionId === AccountInstitutionType.MacquarieCma.id &&
              state === 'New' &&
              statusId === PendingAccountStatus.getByName('Submitted')?.id && (
                <div>
                  <p>The application can be accessed via the Macquarie portal.</p>
                  <p>
                    Clicking the link below will take you to the Macquarie Bank portal, you will be required to enter
                    your company code and last name.
                  </p>

                  <a
                    data-testid="linkNewMacquarieAccountPortal"
                    href={hrefMacquarieCashOnlinePortal}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Macquarie Cash Online Portal
                  </a>
                </div>
              )}
          </>
        </WO2Modal>
      )}
    </>
  );
}
