import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { selectHasClientEditAdminOnly, selectIsDataFromTriumph } from 'src/store/selectors';
import { progressSelectorFactory } from '../../../../common';
import { RootState } from '../../../../reducers';
import { selectAfslId, selectPortfoliosWithoutAll } from '../common/store/selectors';
import { ReportsComponent } from './components/ReportsComponent';
import {
  selectclientFinancialYears,
  selectParameters,
  selectReportGeneration,
  selectSavedParametersList,
} from './store/selectors';
import { reportsSlice } from './store/slice';
import {
  ClientReportsActionTypes,
  fetchClientAccount,
  fetchClientReport,
  fetchReportParametersList,
  getCGTRecordsForClient,
  getReportParametersById,
  initiateReportGeneration,
  saveReportParameters,
} from './store/thunks';

const actionTypes = [
  ClientReportsActionTypes.FetchClientReport,
  ClientReportsActionTypes.FetchClientAccount,
  ClientReportsActionTypes.InitiateReportGeneration,
];

const reportParametersActionTypes = [
  ClientReportsActionTypes.GetReportParametersList,
  ClientReportsActionTypes.GetReportParametersById,
  ClientReportsActionTypes.SaveReportParameters,
];

const mapStateToProps = (state: RootState) => ({
  parameters: selectParameters(state),
  clientPortfolios: selectPortfoliosWithoutAll(state),
  generateProgress: progressSelectorFactory(actionTypes)(state),
  generateProgressReportParameters: progressSelectorFactory(reportParametersActionTypes)(state),
  reportGeneration: selectReportGeneration(state),
  afslId: selectAfslId(state),
  savedParametersList: selectSavedParametersList(state),
  cgtRecords: selectclientFinancialYears(state),
  hasClientEditAdminOnlyPermission: selectHasClientEditAdminOnly(state),
  isDataFromTriumph: selectIsDataFromTriumph(state),
});

const mapDispatchToProps = {
  ...reportsSlice.actions,
  fetchClientReport,
  fetchClientAccount,
  initiateReportGeneration,
  fetchReportParametersList,
  saveReportParameters,
  getReportParametersById,
  getCGTRecordsForClient,
};

interface MatchParams {
  id?: string;
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector> & RouteComponentProps<MatchParams>;
export const ReportsContainer = connect(mapStateToProps, mapDispatchToProps)(ReportsComponent);
