import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { progressSelectorFactory } from 'src/common/store/selectors/loadingProgressSelector';
import { RootState } from '../../../../reducers';
import { selectPrevNextRoutePaths } from '../store/routeSelectors';
import { selectAccountTypeValues, selectAfsls, selectId } from '../store/selectors';
import { onboardSlice } from '../store/slice';
import { fetchAfsls, OnboardActionTypes, saveAccountTypeValues } from '../store/thunks';
import { AccountType } from './components/accountType';

const mapStateToProps = (state: RootState, routeComponentProps: RouteComponentProps<{ new: string }>) => {
  return {
    id: selectId(state),
    accountTypeValues: selectAccountTypeValues(state),
    prevNextRoutePaths: selectPrevNextRoutePaths(state, routeComponentProps.location.pathname),
    afsls: selectAfsls(state),
    saveProgress: progressSelectorFactory([OnboardActionTypes.SaveOnboarding])(state),
  };
};

const mapDispatchToProps = {
  ...onboardSlice.actions,
  fetchAfsls,
  saveAccountTypeValues,
};

interface MatchParams {
  id?: string;
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector> & RouteComponentProps<MatchParams>;
export const AccountTypeContainer = connect(mapStateToProps, mapDispatchToProps)(AccountType);
