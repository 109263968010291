import { Box, Grid, Typography } from '@mui/material';
import { Field, FieldProps, Formik, FormikProps } from 'formik';
import React from 'react';
import { ToggleButtonItem } from '../../../../../common';
import { FormikNumberFormat, FormikSwitch, FormikTextField, FormikToggleButton } from '../../../../../common/components/formik';
import { ClientAccountSubType } from '../../../../../common/types';
import { PaymentFrequency, PaymentLimitType, PaymentType } from '../../../client/common/store/enums';
import { inSpecieLabelText } from '../../../common/config/superSimplifier';
import { RolloverType } from '../../../common/enums';
import { SuperSimplifierDetails } from '../../store/types';
import { FirstPensionPaymentMonthYearPicker } from '../../../onboard/components/common/firstPensionPaymentMonthYearPicker';

export interface SuperSimplifierProps {
  superSimplifierDetails: SuperSimplifierDetails;
}

interface FormValues extends SuperSimplifierDetails {
  rollover: boolean;
  additionalRollover: boolean;
  additionalRolloverSecond: boolean;
}

export const SuperSimplifier = (props: SuperSimplifierProps): JSX.Element => {
  const { superSimplifierDetails } = props;

  const rolloverToggleButtons: ToggleButtonItem<boolean>[] = RolloverType.getArray().map((rollover: RolloverType) => {
    return { name: rollover.displayName, value: rollover.name === RolloverType.FullRollover.name };
  });

  const paymentFrequencyToggleButtons: ToggleButtonItem<number>[] = PaymentFrequency.getArray().map((paymentFrequency: PaymentFrequency) => {
    return { name: paymentFrequency.displayName, value: paymentFrequency.id };
  });

  const paymentLimitTypeToggleButtons: ToggleButtonItem<number>[] = PaymentLimitType.getArray().map((paymentLimit: PaymentLimitType) => {
    return { name: paymentLimit.displayName, value: paymentLimit.id };
  });

  const paymentTypeToggleButtons: ToggleButtonItem<number>[] = PaymentType.getArray().map((paymentType: PaymentType) => {
    return { name: paymentType.displayName, value: paymentType.id };
  });

  const initialFormValues: FormValues = {
    ...superSimplifierDetails,
    isResident: superSimplifierDetails.isResident ?? false,
    ageOver65: superSimplifierDetails.ageOver65 ?? false,
    ageOver60NotPaid: superSimplifierDetails.ageOver60NotPaid ?? false,
    ageNotOver60NotPaid: superSimplifierDetails.ageNotOver60NotPaid ?? false,
    reachedAge: superSimplifierDetails.reachedAge ?? false,
    atp: superSimplifierDetails.atp ?? false,
    paymentTypeId: superSimplifierDetails.paymentTypeId ?? PaymentType.Neither.id,
    paymentLimitTypeId: superSimplifierDetails.paymentLimitTypeId || PaymentLimitType.Other.id,
    inSpecie: superSimplifierDetails.inSpecie ?? false,
    inSpecieSecond: superSimplifierDetails.inSpecieSecond ?? false,
    inSpecieThird: superSimplifierDetails.inSpecieThird ?? false,
    rollover: !!superSimplifierDetails.fundName,
    additionalRollover: !!superSimplifierDetails.fundNameSecond,
    additionalRolloverSecond: !!superSimplifierDetails.fundNameSecond && !!superSimplifierDetails.fundNameThird,
  };

  return (
    <Box marginBottom={'40px'} width="100%" maxWidth="800px">
      <Typography variant="h4" gutterBottom>
        Super Simplifier
      </Typography>
      <Formik<FormValues>
        enableReinitialize={true}
        initialValues={initialFormValues}
        onSubmit={() => {
          /* VOID */
        }}
      >
        {(formikProps: FormikProps<FormValues>) => (
          <Box>
            <Box paddingBottom={'10px'} width={'280px'}>
              <Typography variant="h5" style={{ marginBottom: '20px' }}>
                Estimated Contributions
              </Typography>
              <Field name="contributionsAmount" label="ESTIMATED CONTRIBUTIONS PER ANNUM" fullWidth>
                {(fieldProps: FieldProps) => {
                  return (
                    <FormikNumberFormat
                      formikFieldProps={fieldProps}
                      numberFormatProps={{
                        placeholder: '$0.00',
                        isNumericString: true,
                        allowNegative: false,
                        decimalScale: 2,
                        thousandSeparator: true,
                        prefix: '$',
                        name: fieldProps.field.name,
                        label: 'ESTIMATED CONTRIBUTIONS PER ANNUM',
                        disabled: true,
                      }}
                      isFloatValue={true}
                      fullWidth={true}
                    />
                  );
                }}
              </Field>
            </Box>
            {formikProps.values.rollover && (
              <Box>
                <Typography variant="h5" style={{ margin: '10px 0' }}>
                  Rollover details
                </Typography>
                <Grid container>
                  <Grid item xs={12} style={{ opacity: 0.5, pointerEvents: 'none' }}>
                    <Field
                      exclusive={true}
                      component={FormikToggleButton}
                      buttons={rolloverToggleButtons}
                      formik={formikProps}
                      name={'fullRollover'}
                      style={{ minWidth: '380px' }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Grid item xs={12} style={{ minHeight: '70px', margin: '20px 10px 20px 0' }}>
                      <Field name="fundName" component={FormikTextField} showRequiredAsterisk={true} label="FUND NAME" disabled={true} fullWidth></Field>
                    </Grid>
                    <Grid item xs={12} style={{ minHeight: '70px', margin: '20px 10px 0 0' }}>
                      <Field name={'superAnnuationId'} component={FormikTextField} label="UNIQUE SUPERANNUATION ID" disabled={true} fullWidth></Field>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid item xs={12} style={{ minHeight: '70px', margin: '20px 0 20px 10px' }}>
                      <Field name={'memberNumber'} component={FormikTextField} label="MEMBER NUMBER" disabled={true} fullWidth></Field>
                    </Grid>
                    <Grid item xs={12} style={{ minHeight: '70px', margin: '20px 0 0 10px' }}>
                      <Field name="rolloverAmount" label="* ROLLOVER AMOUNT ($)" fullWidth>
                        {(fieldProps: FieldProps) => {
                          return (
                            <FormikNumberFormat
                              formikFieldProps={fieldProps}
                              numberFormatProps={{
                                placeholder: '',
                                isNumericString: true,
                                allowNegative: false,
                                decimalScale: 2,
                                thousandSeparator: true,
                                name: fieldProps.field.name,
                                label: 'ROLLOVER AMOUNT ($)',
                                disabled: true,
                              }}
                              isFloatValue={true}
                              showRequiredAsterisk={true}
                              fullWidth={true}
                            />
                          );
                        }}
                      </Field>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Field name={'inSpecie'} component={FormikSwitch} label={inSpecieLabelText} disabled={true}></Field>
                  </Grid>
                </Grid>
              </Box>
            )}
            {formikProps.values.additionalRollover && (
              <>
                <Typography variant="h5" style={{ margin: '30px 0 10px' }}>
                  Additional rollover from funds
                </Typography>
                <Grid container>
                  <Grid item xs={12} style={{ opacity: 0.5, pointerEvents: 'none' }}>
                    <Field
                      exclusive={true}
                      component={FormikToggleButton}
                      buttons={rolloverToggleButtons}
                      formik={formikProps}
                      name={'fullRolloverSecond'}
                      style={{ minWidth: '380px' }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Grid item xs={12} style={{ minHeight: '70px', margin: '20px 10px 20px 0' }}>
                      <Field
                        name={'fundNameSecond'}
                        component={FormikTextField}
                        showRequiredAsterisk={true}
                        label="FUND NAME"
                        disabled={true}
                        fullWidth
                      ></Field>
                    </Grid>
                    <Grid item xs={12} style={{ minHeight: '70px', margin: '20px 10px 0 0' }}>
                      <Field name={'superAnnuationIdSecond'} component={FormikTextField} label="UNIQUE SUPERANNUATION ID" disabled={true} fullWidth></Field>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid item xs={12} style={{ minHeight: '70px', margin: '20px 0 20px 10px' }}>
                      <Field name={'memberNumberSecond'} component={FormikTextField} label="MEMBER NUMBER" disabled={true} fullWidth></Field>
                    </Grid>
                    <Grid item xs={12} style={{ minHeight: '70px', margin: '20px 0 0 10px' }}>
                      <Field name="rolloverAmountSecond" label="* ROLLOVER AMOUNT ($)" fullWidth>
                        {(fieldProps: FieldProps) => {
                          return (
                            <FormikNumberFormat
                              formikFieldProps={fieldProps}
                              numberFormatProps={{
                                placeholder: '',
                                isNumericString: true,
                                allowNegative: false,
                                decimalScale: 2,
                                thousandSeparator: true,
                                name: fieldProps.field.name,
                                label: 'ROLLOVER AMOUNT ($)',
                                disabled: true,
                              }}
                              isFloatValue={true}
                              showRequiredAsterisk={true}
                              fullWidth={true}
                            />
                          );
                        }}
                      </Field>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Field name={'inSpecieSecond'} component={FormikSwitch} label={inSpecieLabelText} disabled={true}></Field>
                  </Grid>
                </Grid>
              </>
            )}
            {formikProps.values.additionalRolloverSecond && (
              <>
                <Typography variant="h5" style={{ margin: '30px 0 10px' }}>
                  Second additional rollover from funds
                </Typography>
                <Grid container>
                  <Grid item xs={12} style={{ opacity: 0.5, pointerEvents: 'none' }}>
                    <Field
                      exclusive={true}
                      component={FormikToggleButton}
                      buttons={rolloverToggleButtons}
                      formik={formikProps}
                      name={'fullRolloverThird'}
                      style={{ minWidth: '380px' }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Grid item xs={12} style={{ minHeight: '70px', margin: '20px 10px 20px 0' }}>
                      <Field name={'fundNameThird'} component={FormikTextField} showRequiredAsterisk={true} label="FUND NAME" disabled={true} fullWidth></Field>
                    </Grid>
                    <Grid item xs={12} style={{ minHeight: '70px', margin: '20px 10px 0 0' }}>
                      <Field name={'superAnnuationIdThird'} component={FormikTextField} label="UNIQUE SUPERANNUATION ID" disabled={true} fullWidth></Field>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid item xs={12} style={{ minHeight: '70px', margin: '20px 0 20px 10px' }}>
                      <Field name={'memberNumberThird'} component={FormikTextField} label="MEMBER NUMBER" disabled={true} fullWidth></Field>
                    </Grid>
                    <Grid item xs={12} style={{ minHeight: '70px', margin: '20px 0 0 10px' }}>
                      <Field name="rolloverAmountThird" label="ROLLOVER AMOUNT ($)" fullWidth>
                        {(fieldProps: FieldProps) => {
                          return (
                            <FormikNumberFormat
                              formikFieldProps={fieldProps}
                              numberFormatProps={{
                                placeholder: '',
                                isNumericString: true,
                                allowNegative: false,
                                decimalScale: 2,
                                thousandSeparator: true,
                                name: fieldProps.field.name,
                                label: 'ROLLOVER AMOUNT ($)',
                                disabled: true,
                              }}
                              isFloatValue={true}
                              showRequiredAsterisk={true}
                              fullWidth={true}
                            />
                          );
                        }}
                      </Field>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} style={{ marginTop: '10px' }}>
                    <Field name={'inSpecieThird'} component={FormikSwitch} label={inSpecieLabelText} disabled={true}></Field>
                  </Grid>
                </Grid>
              </>
            )}
            {superSimplifierDetails.subAccountTypeId === ClientAccountSubType.Super.id && (
              <Box marginTop="20px">
                <Typography variant="h5">Payment Type</Typography>
                <Field
                  component={FormikToggleButton}
                  exclusive={true}
                  buttons={paymentTypeToggleButtons}
                  formik={formikProps}
                  name={'paymentTypeId'}
                  style={{ minWidth: '600px', opacity: 0.5, pointerEvents: 'none' }}
                  fullWidth
                />
              </Box>
            )}
            {superSimplifierDetails.subAccountTypeId === ClientAccountSubType.Pension.id && (
              <>
                <Typography variant="h5" style={{ margin: '30px 0 10px' }}>
                  Pension details
                </Typography>
                <Grid container style={{ padding: '0' }}>
                  <Grid item xs={12}>
                    <Field
                      name={'isResident'}
                      component={FormikSwitch}
                      label="The member is an Australian citizen, New Zealand citizen or permanent resident"
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field name={'ageOver65'} component={FormikSwitch} label="The member is aged 65 or older" disabled={true} />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name={'ageOver60NotPaid'}
                      component={FormikSwitch}
                      label="After attaining age 60 the member ceased a paid employment arrangement and intends to never again be gainfully employed for more than 9 hours per week"
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name={'ageNotOver60NotPaid'}
                      component={FormikSwitch}
                      label="The member has reached preservation age but is under 60 and has ceased gainful employment. The member also intends to never again be gainfully employed for more than 9 hours per week"
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field name={'reachedAge'} component={FormikSwitch} label="The member has reached their preservation age" disabled={true} />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name={'atp'}
                      component={FormikSwitch}
                      label="The member has an existing ATP in another fund that they are transferring into this plan"
                      disabled={true}
                    />
                  </Grid>
                </Grid>
                <Typography variant="h5" style={{ margin: '30px 0 10px' }}>
                  Pension payment details
                </Typography>
                <Box>
                  <Typography variant="h6" style={{ marginTop: '20px' }}>
                    * FREQUENCY OF PAYMENTS
                  </Typography>
                  <Box style={{ opacity: 0.5, pointerEvents: 'none' }}>
                    <Field
                      exclusive={true}
                      component={FormikToggleButton}
                      buttons={paymentFrequencyToggleButtons}
                      formik={formikProps}
                      name={'paymentFrequencyId'}
                      style={{ minWidth: '480px', padding: '10px 0' }}
                      fullWidth
                    />
                    <Field
                      exclusive={true}
                      component={FormikToggleButton}
                      buttons={paymentLimitTypeToggleButtons}
                      formik={formikProps}
                      name={'paymentLimitTypeId'}
                      style={{ minWidth: '480px', padding: '10px 0' }}
                      fullWidth
                    />
                  </Box>
                  {formikProps.values.paymentLimitTypeId === PaymentLimitType.Other.id && (
                    <Box width="480px" margin="20px 0">
                      <Field name="paymentLimit" fullWidth>
                        {(fieldProps: FieldProps) => {
                          return (
                            <FormikNumberFormat
                              formikFieldProps={fieldProps}
                              numberFormatProps={{
                                placeholder: '',
                                isNumericString: true,
                                allowNegative: false,
                                decimalScale: 2,
                                thousandSeparator: true,
                                name: fieldProps.field.name,
                                label: 'OTHER AMOUNT ($)',
                                disabled: true,
                              }}
                              isFloatValue={true}
                              showRequiredAsterisk={true}
                              fullWidth={true}
                            />
                          );
                        }}
                      </Field>
                    </Box>
                  )}
                  <Typography variant="h5">* When should the first pension payment be made?</Typography>
                  <Box style={{ opacity: 0.5, pointerEvents: 'none' }}>
                      <Field
                        exclusive={true}
                        component={FirstPensionPaymentMonthYearPicker}
                        name={'firstPaymentDate'}
                        formik={formikProps}
                        style={{ padding: '10px 0' }}
                        fullWidth
                      />
                  </Box>
                </Box>
              </>
            )}
          </Box>
        )}
      </Formik>
    </Box>
  );
};
