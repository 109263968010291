import { Container, Grid } from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import { formatDollars } from '../../../../common';
import CardComponent from '../../../../common/components/CardComponent';
import { theme } from '../../../../themes';
import { Props } from '../container';
import { AssociationsTable } from './AssociationsTable';

export const AssociationsComponent = ({
  loadingProgress,
  fetchStrategyAssociations,
  strategyAssociations,
  isDataFromTriumph,
  match: { params },
}: Props): JSX.Element => {
  const fetch = useCallback(() => {
    if (params.strategyVersionId) {
      fetchStrategyAssociations({
        strategyVersionId: parseInt(params.strategyVersionId),
        isDataFromTriumph: isDataFromTriumph,
      });
    }
  }, [fetchStrategyAssociations, params.strategyVersionId, isDataFromTriumph]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return (
    <Container style={{ paddingTop: '20px', paddingBottom: '20px' }}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <CardComponent
            progress={loadingProgress}
            background={theme.palette.gradient1?.main}
            value={formatDollars(strategyAssociations?.fua)}
            subText={'FUA'}
          />
        </Grid>
        <Grid item xs={6}>
          <CardComponent
            progress={loadingProgress}
            background={theme.palette.gradient2?.main}
            value={strategyAssociations?.portfolioCount}
            subText={'Portfolios'}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{ marginTop: '24px' }}>
        <Grid item xs={12}>
          {!!strategyAssociations && !!strategyAssociations.portfolioExposures && (
            <AssociationsTable progress={loadingProgress} data={strategyAssociations?.portfolioExposures} />
          )}
        </Grid>
      </Grid>
    </Container>
  );
};
