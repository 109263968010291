import { createAsyncThunk } from '@reduxjs/toolkit';
import { buildEncodedQueryString, downloadFile } from 'src/common/utils';
import api from '../../../../../../app/api';
import {
  AdviceEstimatedFee,
  AdviceServiceModel,
  AFSLFeesListResponse,
  CreateClientFeeRequest,
  FeeForClientGroupModel,
  PendingFeesModel,
  UpdateClientFeeRequest,
  UploadFeeConsentValues,
} from './types';

export enum AdviceFeesActionTypes {
  LoadAdviceFees = '@@client/details/adviceFees/LoadAdviceFees',
  FetchActiveAdviceFees = '@@client/details/adviceFees/FetchActiveAdviceFees',
  FetchPendingAdviceFees = '@@client/details/adviceFees/FetchPendingAdviceFees',
  FetchAdviceServicesList = '@@client/details/adviceFees/FetchAdviceServicesList',
  DeleteAdviceFee = '@@client/details/adviceFees/CancelClientFee',
  RemovePendingFee = '@@client/details/adviceFees/CancelClientFee',
  CreateAdviceFee = '@@client/details/adviceFees/CreateAdviceFees',
  UpdateAdviceFee = '@@client/details/adviceFees/UpdateAdviceFees',
  UpdateSuperSimplifierClientAdviceServices = '@@client/details/adviceFees/UpdateSuperSimplifierClientAdviceServices',
  ActivateFeeApplicationDocusign = '@@client/details/adviceFees/ActivateFeeApplicationDocusign',
  ActivateFeeApplicationUpload = '@@client/details/adviceFees/ActivateFeeApplicationUpload',
  ActivateFeeRenewalDocusign = '@@client/details/adviceFees/ActivateFeeRenewalDocusign',
  ActivateFeeRenewalUpload = '@@client/details/adviceFees/ActivateFeeRenewalUpload',
  ActivateFeeRenewal = '@@client/details/adviceFees/ActivateFees',
  CancelDocusignFeesApplicationActivation = '@@client/details/adviceFees/CancelDocusignFeesApplicationActivation',
  CancelDocusignFeesRenewalActivation = '@@client/details/adviceFees/CancelDocusignFeesRenewalActivation',
  DownloadSuperSimplifierOngoingFeeConsentRenewal = '@@client/details/adviceFees/DownloadSuperSimplifierOngoingFeeConsentRenewal',
  FetchAFSLFeesList = '@@client/details/adviceFees/FetchAFSLFeesList',
  CreateFee = '@@client/details/adviceFees/CreateFee',
}

export enum AdviceFeesApiEndpoints {
  LoadAdviceFees = '/fees/LoadClientFees',
  FetchActiveAdviceFees = '/fees/GetFeesForClient',
  FetchPendingAdviceFees = '/fees/GetPendingFeesForClient',
  FetchAdviceServicesList = '/customers/GetAdviceServicesList',
  UpdateSuperSimplifierClientAdviceServices = '/customers/UpdateSuperSimplifierClientAdviceServices',
  DeleteAdviceFee = '/fees/CancelClientFee',
  RemovePendingFee = 'fees/CancelClientFee',
  CreateClientFee = '/fees/CreateClientFee',
  UpdateClientFee = '/fees/UpdateClientFee',
  SendApplicationDocusign = '/SuperSimplifierWorkflow/SendApplicationDocusign',
  UploadApplication = '/SuperSimplifierWorkflow/UploadApplication',
  SendFeeRenewalDocusign = '/SuperSimplifierWorkflow/SendFeeRenewalDocusign',
  UploadFeeRenewal = '/SuperSimplifierWorkflow/UploadFeeRenewal',
  ActivateFees = '/FeesWorkflow/InstantActivation',
  CancelDocusignFeesApplicationActivation = '/SuperSimplifierWorkflow/CancelApplicationDocusign',
  CancelDocusignFeesRenewalActivation = '/SuperSimplifierWorkflow/CancelFeeRenewalDocusign',
  DownloadSuperSimplifierApplicationFeeConsent = '/bff/GetSuperSimplifierApplication',
  DownloadSuperSimplifierOngoingFeeConsentRenewal = '/bff/GetSuperSimplifierOngoingFeeConsentRenewal',
  FetchAFSLFeesList = '/fees/GetAFSLFeesForAdviser',
}

export const loadAdviceFees = createAsyncThunk(
  AdviceFeesActionTypes.LoadAdviceFees,
  async (clientId: number, thunkApi) => {
    const queryString = buildEncodedQueryString({
      clientId,
    });
    return await api
      .post(`${AdviceFeesApiEndpoints.LoadAdviceFees}${queryString}`)
      .then(() => {
        thunkApi.dispatch(fetchPendingAdviceFees({ clientId }));
      })
      .catch(() => {
        return { message: 'Failed to import Advice Fees', variant: 'error' };
      });
  }
);

export const downloadSuperSimplifierApplicationFeeConsent = createAsyncThunk(
  AdviceFeesApiEndpoints.DownloadSuperSimplifierApplicationFeeConsent,
  async (payload: { clientId: number }) => {
    const queryString = buildEncodedQueryString({ clientId: payload.clientId });

    return api
      .get(`${AdviceFeesApiEndpoints.DownloadSuperSimplifierApplicationFeeConsent}${queryString}`, {
        responseType: 'blob',
      })
      .then((response) => {
        downloadFile(response.data, 'SuperSimplifierApplicationFeeConsentForm', 'pdf');

        return { message: 'Downloaded application fee consent form.' };
      })
      .catch(() => ({ message: 'Could not download application fee consent form at this time.', variant: 'error' }));
  }
);

export const downloadSuperSimplifierOngoingFeeConsentRenewal = createAsyncThunk(
  AdviceFeesApiEndpoints.DownloadSuperSimplifierOngoingFeeConsentRenewal,
  async (payload: { clientId: number }) => {
    const queryString = buildEncodedQueryString({ clientId: payload.clientId });

    return api
      .get(`${AdviceFeesApiEndpoints.DownloadSuperSimplifierOngoingFeeConsentRenewal}${queryString}`, {
        responseType: 'blob',
      })
      .then((response) => {
        downloadFile(response.data, 'SuperSimplifierOngoingFeeConsentRenewalForm', 'pdf');

        return { message: 'Downloaded ongoing fee consent renewal form.' };
      })
      .catch(() => ({
        message: 'Could not download ongoing fee consent renewal form at this time.',
        variant: 'error',
      }));
  }
);

export const updateSuperSimplifierClientAdviceService = createAsyncThunk(
  AdviceFeesActionTypes.UpdateSuperSimplifierClientAdviceServices,

  async (payload: { 
      clientId: number, 
      adviceServices: string, 
      adviceServicesOtherText?: string,
      saveQuietly?: boolean
    }, thunkApi) => {
    const request = {
      clientId: payload.clientId,
      adviceServices: payload.adviceServices,
      adviceServicesOtherText: payload.adviceServicesOtherText
    };
    return await api
      .post(`${AdviceFeesApiEndpoints.UpdateSuperSimplifierClientAdviceServices}`, request)
      .then(async () => {
        await thunkApi.dispatch(fetchAdviceServicesList({ clientId: request.clientId }));
        return !payload.saveQuietly ? { 
          message: 'Sucessfully updated Super Simplifier Client Advice Services' 
        } : {};
      })
      .catch(() => {
        return { message: 'Failed to update Super Simplifier Client Advice Services', variant: 'error' }
      });
  }
)

export const fetchAdviceServicesList = createAsyncThunk(
  AdviceFeesActionTypes.FetchAdviceServicesList,

  async (payload: { clientId: number }) => {
    const queryString = buildEncodedQueryString({
      clientId: payload.clientId,
    });
    const response = await api.get<AdviceServiceModel[]>(
      `${AdviceFeesApiEndpoints.FetchAdviceServicesList}${queryString}`
    );

    return { results: response.data };
  }
);

export const fetchActiveAdviceFees = createAsyncThunk(
  AdviceFeesActionTypes.FetchActiveAdviceFees,
  async (payload: { clientId: number }) => {
    const queryString = buildEncodedQueryString({
      clientId: payload.clientId,
    });
    const response = await api.get<FeeForClientGroupModel[]>(
      `${AdviceFeesApiEndpoints.FetchActiveAdviceFees}${queryString}`
    );
    return {
      results: response.data,
    };
  }
);

export const fetchPendingAdviceFees = createAsyncThunk(
  AdviceFeesActionTypes.FetchPendingAdviceFees,
  async (payload: { clientId: number }) => {
    const response = await api.post<PendingFeesModel>(
      `${AdviceFeesApiEndpoints.FetchPendingAdviceFees}?clientId=${payload.clientId}`
    );
    return {
      results: response.data,
    };
  }
);

export const activateFeeApplicationDocusign = createAsyncThunk(
  AdviceFeesActionTypes.ActivateFeeApplicationDocusign,
  async (
    payload: { clientId: number; isIndividualSuper: boolean; isIndividualPension: boolean; message: string },
    thunkApi
  ) => {
    const request = {
      clientId: payload.clientId,
      isIndividualSuper: payload.isIndividualSuper,
      isIndividualPension: payload.isIndividualPension,
      anchorString: '',
    };
    return await api
      .post(`${AdviceFeesApiEndpoints.SendApplicationDocusign}`, request)
      .then(() => {
        thunkApi.dispatch(fetchPendingAdviceFees({ clientId: payload.clientId }));
        return { message: payload.message };
      })
      .catch(() => {
        return { message: 'Failed to activate Advice Fees', variant: 'error' };
      });
  }
);

export const activateFeeApplicationUpload = createAsyncThunk(
  AdviceFeesActionTypes.ActivateFeeApplicationUpload,
  async (
    payload: {
      clientId: number;
      isIndividualSuper: boolean;
      isIndividualPension: boolean;
      feeChangeSetId: number;
      uploadFeeConsentValues: UploadFeeConsentValues;
      message: string;
    },
    thunkApi
  ) => {
    return await api
      .post(AdviceFeesApiEndpoints.UploadApplication, {
        clientId: payload.clientId,
        isIndividualSuper: payload.isIndividualSuper,
        isIndividualPension: payload.isIndividualPension,
        feeChangeSetId: payload.feeChangeSetId,
        signedApprovalDocument: payload.uploadFeeConsentValues.base64Content,
      })
      .then(() => {
        thunkApi.dispatch(fetchPendingAdviceFees({ clientId: payload.clientId }));
        thunkApi.dispatch(fetchActiveAdviceFees({ clientId: payload.clientId }));
        return { message: payload.message };
      })
      .catch(() => {
        return { message: 'Failed to activate Advice Fees', variant: 'error' };
      });
  }
);

export const activateFeeRenewalDocusign = createAsyncThunk(
  AdviceFeesActionTypes.ActivateFeeRenewalDocusign,
  async (payload: { clientId: number; message: string }, thunkApi) => {
    const request = {
      clientId: payload.clientId,
      anchorString: '',
    };
    return await api
      .post(`${AdviceFeesApiEndpoints.SendFeeRenewalDocusign}`, request)
      .then(() => {
        thunkApi.dispatch(fetchPendingAdviceFees({ clientId: payload.clientId }));
        return { message: payload.message };
      })
      .catch(() => {
        return { message: 'Failed to activate Advice Fees', variant: 'error' };
      });
  }
);

export const activateFeeRenewalUpload = createAsyncThunk(
  AdviceFeesActionTypes.ActivateFeeRenewalUpload,
  async (
    payload: {
      clientId: number;
      feeChangeSetId: number;
      uploadFeeConsentValues: UploadFeeConsentValues;
      message: string;
    },
    thunkApi
  ) => {
    return await api
      .post(AdviceFeesApiEndpoints.UploadFeeRenewal, {
        clientId: payload.clientId,
        feeChangeSetId: payload.feeChangeSetId,
        signedApprovalDocument: payload.uploadFeeConsentValues.base64Content,
      })
      .then(() => {
        thunkApi.dispatch(fetchPendingAdviceFees({ clientId: payload.clientId }));
        thunkApi.dispatch(fetchActiveAdviceFees({ clientId: payload.clientId }));
        return { message: payload.message };
      })
      .catch(() => {
        return { message: 'Failed to activate Advice Fees', variant: 'error' };
      });
  }
);

export const activateFees = createAsyncThunk(
  AdviceFeesActionTypes.ActivateFeeRenewal,
  async (payload: { clientId: number; feeChangeSetId: number; message: string }, thunkApi) => {
    return await api
      .post(AdviceFeesApiEndpoints.ActivateFees, {
        clientId: payload.clientId,
        feeChangeSetId: payload.feeChangeSetId,
      })
      .then(() => {
        thunkApi.dispatch(fetchPendingAdviceFees({ clientId: payload.clientId }));
        thunkApi.dispatch(fetchActiveAdviceFees({ clientId: payload.clientId }));
        return { message: payload.message };
      })
      .catch(() => {
        return { message: 'Failed to activate Advice Fees', variant: 'error' };
      });
  }
);

export const createAdviceFee = createAsyncThunk(
  AdviceFeesActionTypes.CreateAdviceFee,
  async (payload: { clientId: number; requests: CreateClientFeeRequest[]; message: string }, thunkApi) => {
    const promises = payload.requests.map((request) => api.post(AdviceFeesApiEndpoints.CreateClientFee, request));
    return await Promise.all(promises)
      .then(() => {
        thunkApi.dispatch(fetchPendingAdviceFees({ clientId: payload.clientId }));
        return { message: payload.message };
      })
      .catch(() => {
        return { message: 'failed to create Advice Fee', variant: 'error' };
      });
  }
);

export const createFee = createAsyncThunk(
  AdviceFeesActionTypes.CreateAdviceFee,
  async (payload: { clientId: number; feeId: number;}, thunkApi) => {
    console.log(payload.feeId);
    return await api.post(AdviceFeesApiEndpoints.CreateClientFee, {
      clientId: payload.clientId,
      feeId: payload.feeId,
      payerType: 'mda:client',
      feeConsentId: 2
      })
      .then(() => {
        thunkApi.dispatch(fetchPendingAdviceFees({ clientId: payload.clientId }));
        return { message: 'Advice Fee created' };
      })
      .catch(() => {
        return { message: 'failed to create Advice Fee', variant: 'error' };
      });
  }
);

export const updateAdviceFee = createAsyncThunk(
  AdviceFeesActionTypes.UpdateAdviceFee,
  async (payload: { clientId: number; requests: UpdateClientFeeRequest[]; message: string }, thunkApi) => {
    return await api
      .post(AdviceFeesApiEndpoints.UpdateClientFee, {
        clientId: payload.clientId,
        clientFees: payload.requests,
      })
      .then(() => {
        thunkApi.dispatch(fetchPendingAdviceFees({ clientId: payload.clientId }));
        return { message: payload.message };
      })
      .catch(() => {
        return { message: 'failed to update Advice Fee', variant: 'error' };
      });
  }
);

export const cancelDocusignFeesApplicationActivation = createAsyncThunk(
  AdviceFeesActionTypes.CancelDocusignFeesApplicationActivation,
  async (payload: { clientId: number; message: string }, thunkApi) => {
    return await api
      .post(AdviceFeesApiEndpoints.CancelDocusignFeesApplicationActivation, { clientId: payload.clientId })
      .then(() => {
        thunkApi.dispatch(fetchPendingAdviceFees({ clientId: payload.clientId }));
        return { message: payload.message };
      })
      .catch(() => {
        return { message: 'Failed to cancel Advice Fees activation', variant: 'error' };
      });
  }
);

export const cancelDocusignFeesRenewalActivation = createAsyncThunk(
  AdviceFeesActionTypes.CancelDocusignFeesRenewalActivation,
  async (payload: { clientId: number; message: string }, thunkApi) => {
    return await api
      .post(AdviceFeesApiEndpoints.CancelDocusignFeesRenewalActivation, { clientId: payload.clientId })
      .then(() => {
        thunkApi.dispatch(fetchPendingAdviceFees({ clientId: payload.clientId }));
        return { message: payload.message };
      })
      .catch(() => {
        return { message: 'Failed to cancel Advice Fees activation', variant: 'error' };
      });
  }
);

export const deleteAdviceFee = createAsyncThunk(
  AdviceFeesActionTypes.DeleteAdviceFee,
  async (payload: { adviceEstimatedFee: AdviceEstimatedFee; clientId: number; message: string }, thunkApi) => {
    const promise = api.post(AdviceFeesApiEndpoints.DeleteAdviceFee, {
            clientId: payload.clientId,
            FeeId: payload.adviceEstimatedFee.feeId,
          });

    return await promise
      .then(() => {
        thunkApi.dispatch(fetchPendingAdviceFees({ clientId: payload.clientId }));
        return { message: payload.message };
      })
      .catch(() => {
        return { message: 'Failed to delete Advice Fees', variant: 'error' };
      });
  }
);

export const removePendingFee = createAsyncThunk(
  AdviceFeesActionTypes.RemovePendingFee,
  async (payload: { adviceEstimatedFee: AdviceEstimatedFee; clientId: number; message: string }, thunkApi) => {
    const promise =
            api.post(AdviceFeesApiEndpoints.RemovePendingFee, {
              clientId: payload.clientId,
              FeeId: payload.adviceEstimatedFee.feeId,
            });
      
    return await promise
      .then(() => {
        thunkApi.dispatch(fetchPendingAdviceFees({ clientId: payload.clientId }));
        return { message: payload.message };
      })
      .catch(() => {
        return { message: 'Failed to remove Pending Fees', variant: 'error' };
      });
  }
);

export const fetchAFSLFeesList = createAsyncThunk(AdviceFeesActionTypes.FetchAFSLFeesList,   
  async (payload: {afslId: number}) => {
  const queryString = buildEncodedQueryString({
    afslId: payload.afslId,
  });
    const response = await api.get<AFSLFeesListResponse>(`${AdviceFeesApiEndpoints.FetchAFSLFeesList}${queryString}`);
    console.log(response.data);
    return response.data;
  });
