import { Box, Container, FormControl, Grid, InputLabel, MenuItem, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { useCallback, useEffect, useState } from 'react';
import PulseLoader from 'react-spinners/PulseLoader';
import { LoadingIndicator } from 'src/common/components';
import { DateTimeFormat, formatDollars, formatNumberCommaSeparated,
    formatPercentage, getLocalDateTime, WO2Select } from 'src/common';
import { ComponentAllocations, CompositionGroup, ModelCompositionMethod,
    ModelCompositionType } from '../store/types';
import { theme } from 'src/themes';
import { ModelVersion } from './../../common/store/types';
import { ModelVersionStatusType } from './../../store/types';
import { Props } from './../container';
import ActionsMenu from './ActionsMenu';

const chartColors = [
  '#2c82be','#429ff9','#423cff',
  '#0080ff','#000080','#008ecc',
  '#008081','#4482b4','#111e6c',
  '#1d2951','#dbecf8','#2c82be',
  '#429ff9','#423cff','#0080ff',
  '#000080','#008ecc','#008081',
  '#4482b4','#111e6c','#1d2951',
  '#dbecf8','#2c82be','#429ff9',
  '#423cff','#0080ff','#000080',
  '#008ecc','#008081','#4482b4',
  '#111e6c','#1d2951','#dbecf8'
];

export const CompositionComponent = ({
  currentModelVersionId,
  setSelectedGroupParameter,
  fetchCompositionDetail,
  fetchModelCompositionCSV,
  fetchModelVersions,
  versionLoadingIndicator,
  match: { params },
  modelDetail,
  modelGroupItems,
  modelUploadSuccess,
  modelVersions,
  importFixedAllocations,
  importWeightedAllocations,
  importErrors,
  isImporting,
  hasEditModelAccess,
  resetModelUploadSuccessStatus,
  parameters,
  setModelVersion
}: Props): JSX.Element => {
  let importAllocations = modelDetail?.type === ModelCompositionType.SMA ? importWeightedAllocations : importFixedAllocations;
  const callEndPoints = useCallback(() => {
    if (currentModelVersionId) {
      fetchCompositionDetail({ modelVersionId: currentModelVersionId });
    }
  }, [fetchCompositionDetail, currentModelVersionId]);
  useEffect(() => {
    const isLastModelVersionPending = modelVersions.length > 0 && modelVersions[modelVersions.length - 1].status === 'Pending' && modelUploadSuccess;
    if (isLastModelVersionPending) {
      const pendingModel = modelVersions[modelVersions.length - 1];

      importAllocations = modelDetail?.type === ModelCompositionType.SMA ? importWeightedAllocations : importFixedAllocations;

      if (!!pendingModel && !!params.modelId) {
        setModelVersion({
          modelId: parseInt(params.modelId, 10),
          modelVersion: pendingModel.version,
          modelStatus: pendingModel.status,
          modelVersionId: pendingModel.modelVersionId,
        });
        resetModelUploadSuccessStatus(false);
      }
    }
  }, [modelVersions, params.modelId, setModelVersion, resetModelUploadSuccessStatus, modelUploadSuccess]);
  useEffect(() => {
    callEndPoints();
  }, [callEndPoints, modelVersions]);

  const [isMenuOpen, setMenuState] = useState(false);
  const isComponentAllocationsEmpty = modelGroupItems.length === 0 && versionLoadingIndicator.isLoading === false;
  const isModelVersionActive = modelVersions
    .find((version) => version.modelVersionId === modelDetail?.modelVersionId)?.status === ModelVersionStatusType.active;
  const chartData = modelDetail?.groups
    .filter((i: CompositionGroup) => i.label !== 'ALL')
    .map((i: CompositionGroup, index: number) => ({
      y: Math.abs(i.weight / 100),
      name: i.label,
      color: chartColors[index],
      sliced: i.label === parameters.selectedGroup,
      selected: i.label === parameters.selectedGroup,
      events: {
        select: function () {
          setSelectedGroupParameter(i.label);
        },
        unselect: function () {
          setSelectedGroupParameter('ALL');
        },
      },
    }));
  const chartOptions = {
    chart: {
      type: 'pie',
    },
    allowPointSelect: true,
    title: {
      verticalAlign: 'middle',
      floating: true,
      text: '',
    },
    colors: chartColors,
    subtitle: {
      text: '',
    },
    plotOptions: {
      pie: {
        innerSize: '60%',
        depth: 45,
        tooltip: {
          pointFormatter: function (this: Highcharts.TooltipFormatterContextObject) {
            return `<span style="color:${this.color}">●</span> <b>${this.percentage?.toFixed(2)}%</b><br/>`;
          },
        },
      },
    },
    series: [
      {
        allowPointSelect: true,
        name: '',
        data: chartData,
        dataLabels: {
          enabled: false,
        },
      },
    ],
    credits: {
      enabled: false,
    },
  };
  const groupRows = modelDetail?.groups.map((row: CompositionGroup, index: number) => {
    return (
      <ListItem
        key={index}
        divider
        selected={row.label.toLowerCase() === parameters.selectedGroup.toLowerCase()}
        button
        onClick={() => {
          setSelectedGroupParameter(row.label);
        }}
      >
        <Grid container>
          <Grid item xs={2}>
            <Box style={{ backgroundColor: chartColors[index] }} marginTop={'5px'} display={'block'} width={'20px'} height={'20px'} borderRadius={'15px'}></Box>
          </Grid>
          <Grid item xs={5}>
            <Typography variant="h5">{row.label}</Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography variant="h6" align="right">
              {formatPercentage(row.weight)}
            </Typography>
          </Grid>
        </Grid>
      </ListItem>
    );
  });

  let rightTableRows: React.ReactNode = <></>;
  let rightTableHeader: React.ReactNode = <Grid container></Grid>;
  const method: string = modelDetail?.method === ModelCompositionMethod.Weighted ? ModelCompositionMethod.Weighted : ModelCompositionMethod.Fixed;

  const statusColor = (status: string) => {
    switch (status) {
      case 'Active':
      case 'Open':
        return 'secondary';
      case 'Inactive':
      case 'Sold':
      case 'Terminated':
      case 'Suspended':
      case 'Removed':
      case 'Closed':
        return 'error';
      default:
        return 'textPrimary';
    }
  };

  if (method === ModelCompositionMethod.Weighted && isModelVersionActive) {
    rightTableHeader = (
      <Grid container>
        <Grid item xs={1}>
          <Typography variant="h6" style={{ paddingLeft: '15px' }}>
            ASSET CLASS
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h6" style={{ paddingLeft: '8px' }}>
            NAME
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="h6" style={{ paddingRight: '10px' }}>
            TYPE
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography variant="h6">STATUS</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography variant="h6" align="right" style={{ paddingRight: '13px' }}>
            STATUS DATE
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography variant="h6" align="right" style={{ paddingRight: '10px' }}>
            UNITS & PRICE
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="h6" align="right" style={{ paddingRight: '10px' }}>
            VALUE
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography variant="h6" align="right" style={{ paddingRight: '13px' }}>
            TARGET ALLOCATION
          </Typography>
        </Grid>
      </Grid>
    );

    rightTableRows = modelGroupItems.map((row: ComponentAllocations, index: number) => {
      return (
        <ListItem key={index} divider>
          <Grid container>
            <Grid item xs={1}>
              <Typography variant={'h5'} style={{ paddingRight: '10px' }}>
                {row.assetClass}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant={'h5'} color={'primary'}>
                {row.componentCode}
              </Typography>
              <Typography variant={'h5'} color={'textSecondary'}>
                {row.componentName}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant={'h5'}>{row.componentType}</Typography>
              <Typography variant={'h5'} color={'textSecondary'}>
                {row.type}
              </Typography>
            </Grid>
            <Grid item xs={1} style={{ paddingLeft: '5px' }}>
              <Typography variant={'h5'} color={statusColor(row.status)}>
                {row.status}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography variant={'h5'} align="right">
                {getLocalDateTime(row.statusDate, DateTimeFormat.Short)}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography variant={'h5'} align="right">
                {formatNumberCommaSeparated(row.units)}
              </Typography>
              <Typography variant={'h5'} color={'textSecondary'} align="right">
                {formatDollars(row.unitPrice)}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant={'h5'} align="right">
                {formatDollars(row.value)}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography variant={'h5'} align="right">
                {formatPercentage(row.weight)}
              </Typography>
            </Grid>
          </Grid>
        </ListItem>
      );
    });
  } else {
    rightTableHeader = (
      <Grid container>
        <Grid item xs={2}>
          <Typography variant="h6" style={{ paddingLeft: '15px' }}>
            ASSET CLASS
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h6" style={{ paddingLeft: '8px' }}>
            NAME
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="h6" style={{ paddingRight: '10px' }}>
            TYPE
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography variant="h6">STATUS</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography variant="h6" align="right" style={{ paddingRight: '13px' }}>
            STATUS DATE
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="h6" align="right" style={{ paddingRight: '13px' }}>
            TARGET ALLOCATION
          </Typography>
        </Grid>
      </Grid>
    );

    rightTableRows = modelGroupItems.map((row: ComponentAllocations, index: number) => {
      return (
        <ListItem key={index} divider>
          <Grid container>
            <Grid item xs={2}>
              <Typography variant={'h5'}>{row.assetClass}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant={'h5'} color={'primary'}>
                {row.componentCode}
              </Typography>
              <Typography variant={'h5'} color={'textSecondary'}>
                {row.componentName}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant={'h5'}>{row.componentType}</Typography>
              <Typography variant={'h5'} color={'textSecondary'}>
                {row.type}
              </Typography>
            </Grid>
            <Grid item xs={1} style={{ paddingLeft: '5px' }}>
              <Typography variant={'h5'} color={statusColor(row.status)}>
                {row.status}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography variant={'h5'} align="right">
                {getLocalDateTime(row.statusDate, DateTimeFormat.Short)}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant={'h5'} align="right">
                {formatPercentage(row.weight)}
              </Typography>
            </Grid>
          </Grid>
        </ListItem>
      );
    });
  }

  return (
    <Container style={{ paddingTop: '20px', paddingBottom: '20px' }}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Card elevation={0}>
            <Grid container>
              <Typography variant="h3" align="left" style={{ padding: '10px' }}>
                {parameters.selectedGroup === 'ALL' ? 'All Asset Classes' : parameters.selectedGroup}
              </Typography>
            </Grid>
            <CardContent>
              <Grid item>
                <HighchartsReact highcharts={Highcharts} options={chartOptions} />
              </Grid>
              <Grid container>
                <Grid item xs={2}>
                  {' '}
                </Grid>
                <Grid item xs={5}>
                  <Typography variant="h6">ASSET CLASS</Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography variant={'h6'} style={{ textAlign: 'right', paddingRight: '15px' }}>
                    VALUE & WEIGHT
                  </Typography>
                </Grid>
              </Grid>
              {versionLoadingIndicator.isLoading && (
                <div
                  className="LoadingIndicator"
                  style={{
                    paddingTop: '14px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <PulseLoader size="12px" margin="5px" color={theme.palette.grey[400]} />
                </div>
              )}
              {!isComponentAllocationsEmpty && <List>{groupRows}</List>}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={8}>
          <Card elevation={0}>
            <CardContent>
              {modelDetail !== null && (
                <ActionsMenu
                  model={modelDetail}
                  hasEditModelAccess={hasEditModelAccess}
                  importAllocations={importAllocations}
                  importErrors={importErrors}
                  isImporting={isImporting}
                  isMenuOpen={isMenuOpen}
                  fetchModelCompositionCSV={fetchModelCompositionCSV}
                  isModelVersionActive={isModelVersionActive}
                  onUploadSuccessHandler={() => {
                    if (!!params.modelId) {
                      fetchModelVersions({ modelId: parseInt(params.modelId, 10) });
                      setMenuState(false);
                    }
                  }}
                  modelUploadSuccess={modelUploadSuccess}
                  setMenuState={setMenuState}
                />
              )}
              {!!modelDetail?.modelVersionId && !!modelVersions.length && (
                <Grid container>
                  <Grid item xs={12}>
                    <FormControl
                      variant="outlined"
                      fullWidth={true}
                      style={{
                        marginBottom: '24px',
                        width: '30%',
                      }}
                    >
                      <InputLabel>VERSION</InputLabel>
                      <WO2Select
                        defaultValue={currentModelVersionId}
                        value={modelDetail.modelVersionId}
                        label={'VERSION'}
                        fullWidth={true}
                        onChange={(event) => {
                          if (event.target.value !== null) {
                            const modelVersionId = Number(event.target.value);
                            fetchCompositionDetail({ modelVersionId });
                          }
                        }}
                      >
                        {modelVersions.map(({ version, status, modelVersionId }: ModelVersion) => (
                          <MenuItem key={`compositionComponentVersionSelector-${version}-${modelVersionId}`} value={modelVersionId}>
                            Version {version}
                            {!!status && status !== '' ? ` - ${status}` : ''}
                          </MenuItem>
                        ))}
                      </WO2Select>
                    </FormControl>
                  </Grid>
                </Grid>
              )}
              <LoadingIndicator progress={versionLoadingIndicator}>
                <>
                  {!isComponentAllocationsEmpty && rightTableHeader}
                  {versionLoadingIndicator.isLoading && (
                    <div
                      className="LoadingIndicator"
                      style={{
                        paddingTop: '14px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <PulseLoader size="12px" margin="5px" color={theme.palette.grey[400]} />
                    </div>
                  )}
                  {isComponentAllocationsEmpty ? (
                    <Box style={{ fontFamily: theme.typography.fontFamily, paddingTop: '20px', height: '15vh', textAlign: 'center' }}>
                      No allocations {`for this version of ${modelDetail?.name}.`}
                    </Box>
                  ) : (
                    <List>{rightTableRows}</List>
                  )}
                </>
              </LoadingIndicator>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};
