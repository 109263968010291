import { combineReducers } from '@reduxjs/toolkit';
import { securityAssociatedClientsSlice } from '../associatedClients/store/slice';
import { SecurityAssociatedClientsState } from '../associatedClients/store/types';
import { commonSlice } from '../common/store/slice';
import { SecurityCommonState } from '../common/store/types';
import { securityDashboardSlice } from '../dashboard/store/slice';
import { SecurityDashboardState } from '../dashboard/store/types';
import { securityDetailsSlice } from '../details/store/slice';
import { SecurityDetailsState } from '../details/store/types';
import { securityIncomeSlice } from '../income/store/slice';
import { SecurityIncomeState } from '../income/store/types';
import { securityListSlice } from '../list/store/slice';
import { SecurityListState } from '../list/store/types';
import { securityPerformanceSlice } from '../performance/store/slice';
import { SecurityPerformanceState } from '../performance/store/types';
import { securityPriceSlice } from '../price/store/slice';
import { SecurityPriceState } from '../price/store/types';

export type SecurityState = {
  common: SecurityCommonState;
  list: SecurityListState;
  price: SecurityPriceState;
  income: SecurityIncomeState;
  dashboard: SecurityDashboardState;
  associatedClients: SecurityAssociatedClientsState;
  performance: SecurityPerformanceState;
  details: SecurityDetailsState;
};

export const securityReducer = combineReducers<SecurityState>({
  common: commonSlice.reducer,
  list: securityListSlice.reducer,
  price: securityPriceSlice.reducer,
  income: securityIncomeSlice.reducer,
  dashboard: securityDashboardSlice.reducer,
  associatedClients: securityAssociatedClientsSlice.reducer,
  performance: securityPerformanceSlice.reducer,
  details: securityDetailsSlice.reducer,
});
