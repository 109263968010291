import { combineReducers } from '@reduxjs/toolkit';
import { checkoutSlice } from './checkout/store/slice';
import { editSlice } from './edit/store/slice';
import { searchSlice } from './search/store/slice';

export const orderReducer = combineReducers({
  search: searchSlice.reducer,
  edit: editSlice.reducer,
  checkout: checkoutSlice.reducer,
});
