import { ExpandMore } from '@mui/icons-material';
import { IconButton, Paper, TableCell, TableRow, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import React, { useState } from 'react';
import {
  CSVDataType,
  DateTimeFormat,
  convertToCSVFormat,
  downloadCsv,
  formatNumberCommaSeparatedForCSV,
  getLocalDateTime,
  negativeDollars,
  uuidv4,
  xlNumFormat,
} from 'src/common';
import { ClientSideDataTable } from 'src/common/components/dataTable/clientSide/clientSideDataTable';
import { DatatableColumn } from 'src/common/components/dataTable/types';
import {
  ExpandedRow,
  UnrealisedGainsLossesResultItemCSVRow,
  UnrealisedGainsLossesResultItemCSVRowWithLineItems,
  UnrealisedGainsLossesResultHeaderCSVRow,
  UnrealisedCapitalGainsLossesResult,
  UnrealisedParcelDetails,
  UnrealisedReportDetail,
} from '../store/types';
import { DateTime } from 'luxon';
import { ADVISER_CONTACT_EMAIL } from 'src/common/utils/dashContactDetails';

interface Props {
  results: UnrealisedCapitalGainsLossesResult | undefined;
  isLoading: boolean;
  hasErrors: boolean;
  generateUnrealisedCsv: boolean;
}

const useStyles = makeStyles(() => ({
  open: {
    transform: 'rotate(360deg)',
    transition: 'transform 0.25s',
  },
  close: {
    transform: ' rotate(270deg)',
    transition: 'transform 0.25s',
  },
}));

export default function CapitalUnrealisedGainsLossesTable(props: Props): JSX.Element {
  const { isLoading, hasErrors } = props;
  const rows: UnrealisedReportDetail[] = [];

  const [expanded, setExpanded] = useState<ExpandedRow>([]);
  const [open, setOpen] = useState(false);

  const serialiseUnrealisedData = (input: UnrealisedCapitalGainsLossesResult | undefined) => {
    if (!!input) {
      const securityDetailsItems = input.securityDetails && input.securityDetails.length ? input.securityDetails : [];
      securityDetailsItems.forEach((secDetails) => {
        const groupHeader: UnrealisedReportDetail = {
          ...(secDetails.securityTotals as UnrealisedReportDetail),
          isGroup: true,
          items: [],
        };

        secDetails.parcelDetails.forEach((parcelDetails) => {
          const body: UnrealisedReportDetail = {
            ...(parcelDetails as UnrealisedReportDetail),
            isGroup: false,
            items: [],
          };
          groupHeader.items.push(body);
        });
        rows.push(groupHeader);
      });
    }
  };

  serialiseUnrealisedData(props.results);

  function exportHandler(gainsLossesItems: UnrealisedParcelDetails[]) {
    const tree: UnrealisedGainsLossesResultHeaderCSVRow[] = [];

    function updateTree(
      node: UnrealisedGainsLossesResultItemCSVRow,
      groupName: string,
      groupSubName: string,
      isTotal: string
    ) {
      tree.push({
        isTotal: isTotal,
        securityCode: groupName,
        securityName: groupSubName,
        acquisitionDate: node.acquisitionDate
          ? DateTime.fromISO(node.acquisitionDate).toLocaleString(DateTime.DATE_SHORT)
          : 'N/A',
        units: node.units ? (xlNumFormat(node.units, 4) as string) : '',
        originalCostBase: formatNumberCommaSeparatedForCSV(node.originalCostBase),
        costBaseAdjustments: formatNumberCommaSeparatedForCSV(node.costBaseAdjustments),
        adjustedReducedCostbase: formatNumberCommaSeparatedForCSV(node.adjustedReducedCostbase),
        marketValue: formatNumberCommaSeparatedForCSV(node.marketValue),
        unrealisedGainLoss: negativeDollars(formatNumberCommaSeparatedForCSV(node.unrealisedGainLoss)) as
          | string
          | undefined,
        indexation: xlNumFormat(node.indexation) as string,
        discountable: xlNumFormat(node.discountable) as string,
        other: xlNumFormat(node.other) as string,
        capitalLoss: xlNumFormat(node.capitalLoss) as string,
      });
    }

    gainsLossesItems.forEach((row) => {
      const groupRow = row as UnrealisedGainsLossesResultItemCSVRowWithLineItems;
      groupRow.items.forEach((lineItemRow: UnrealisedParcelDetails) => {
        const lineItemRowForCSV = lineItemRow as UnrealisedGainsLossesResultItemCSVRow;
        updateTree(
          lineItemRowForCSV as UnrealisedGainsLossesResultItemCSVRow,
          groupRow.securityCode,
          groupRow.securityName,
          'N'
        );
      });
      updateTree(row as UnrealisedGainsLossesResultItemCSVRow, groupRow.securityCode, groupRow.securityName, 'Y');
    });

    const data: CSVDataType[] = convertToCSVFormat(tree);
    downloadCsv(data, `Capital_Gains_Losses_Unrealised_`);
  }

  if (props.generateUnrealisedCsv) {
    exportHandler(rows);
  }

  const gainsLossesHeader = (row: UnrealisedReportDetail, parentIndex: number, rowIndex: number) => {
    return (
      <React.Fragment key={uuidv4()}>
        <TableRow key={uuidv4()}>
          <TableCell style={{ width: '48px', maxWidth: '48px', padding: '0 0 0 5px' }}>
            <IconButton
              aria-label="expand"
              size="small"
              style={{ padding: '0' }}
              onClick={() => {
                setOpen(!open);
                if (expanded && expanded[parentIndex] && expanded[parentIndex].includes(rowIndex)) {
                  const update = expanded[parentIndex].filter((row) => row !== rowIndex);
                  setExpanded({ ...expanded, [parentIndex]: update });
                } else {
                  if (expanded[parentIndex]) {
                    const update = [...expanded[parentIndex].values(), rowIndex];
                    setExpanded({ ...expanded, [parentIndex]: update });
                  } else {
                    setExpanded({ ...expanded, [parentIndex]: [rowIndex] });
                  }
                }
              }}
            >
              <ExpandMore
                className={clsx(
                  !(expanded[parentIndex] && expanded[parentIndex].includes(rowIndex)) && classes.close,
                  expanded[parentIndex] && expanded[parentIndex].includes(rowIndex) && classes.open
                )}
                style={{ padding: 0 }}
              />
            </IconButton>
          </TableCell>
          <TableCell colSpan={2}>
            <Typography variant="h5" color={'primary'}>
              {row.securityCode}
            </Typography>
            <Typography variant="h5" color={'textSecondary'}>
              {row.securityName}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="h5" align="right">
              {xlNumFormat(row.units, 4)}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="h5" align="right">
              {formatNumberCommaSeparatedForCSV(row.originalCostBase)}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="h5" align="right">
              {formatNumberCommaSeparatedForCSV(row.costBaseAdjustments)}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="h5" align="right">
              {formatNumberCommaSeparatedForCSV(row.adjustedReducedCostbase)}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="h5" align="right">
              {formatNumberCommaSeparatedForCSV(row.marketValue)}
            </Typography>
          </TableCell>
          <TableCell align="left">
            <Typography
              variant="h5"
              align="right"
              color={!row.unrealisedGainLoss ? 'textPrimary' : row.unrealisedGainLoss < 0 ? 'error' : 'secondary'}
            >
              {negativeDollars(formatNumberCommaSeparatedForCSV(row.unrealisedGainLoss))}
            </Typography>
          </TableCell>
          <TableCell align="left">
            <Typography variant="h5" align="right">
              {xlNumFormat(row.indexation)}
            </Typography>
          </TableCell>
          <TableCell align="left">
            <Typography variant="h5" align="right">
              {xlNumFormat(row.discountable)}
            </Typography>
          </TableCell>
          <TableCell align="left">
            <Typography variant="h5" align="right">
              {xlNumFormat(row.other)}
            </Typography>
          </TableCell>
          <TableCell align="left">
            <Typography variant="h5" align="right">
              {xlNumFormat(row.capitalLoss)}
            </Typography>
          </TableCell>
        </TableRow>
        {row.items.map((childRow: UnrealisedReportDetail) => gainsLossesDetail(childRow, parentIndex, rowIndex, false))}
      </React.Fragment>
    );
  };

  const gainsLossesDetail = (row: UnrealisedReportDetail, parentIndex: number, key: number, visible: boolean) => {
    return (
      <TableRow
        key={uuidv4()}
        style={{
          visibility:
            (expanded[parentIndex] && expanded[parentIndex].includes(key)) || visible ? 'visible' : 'collapse',
        }}
      >
        <TableCell style={{ width: '48px', maxWidth: '48px' }}></TableCell>
        <TableCell style={{ width: '175px', maxWidth: '175px' }}>
          {getLocalDateTime(row.acquisitionDate, DateTimeFormat.Short)}
        </TableCell>
        <TableCell align="right">{xlNumFormat(row.units, 4)}</TableCell>
        <TableCell align="right">{formatNumberCommaSeparatedForCSV(row.originalCostBase)}</TableCell>
        <TableCell align="right">{xlNumFormat(row.costBaseAdjustments)}</TableCell>
        <TableCell align="right">{formatNumberCommaSeparatedForCSV(row.adjustedReducedCostbase)}</TableCell>
        <TableCell align="right">{formatNumberCommaSeparatedForCSV(row.marketValue)}</TableCell>
        <TableCell align="left">
          <Typography
            variant="h5"
            align="right"
            color={!row.unrealisedGainLoss ? 'textPrimary' : row.unrealisedGainLoss < 0 ? 'error' : 'secondary'}
          >
            {negativeDollars(formatNumberCommaSeparatedForCSV(row.unrealisedGainLoss))}
          </Typography>
        </TableCell>
        <TableCell align="right">{xlNumFormat(row.indexation)}</TableCell>
        <TableCell align="right">{xlNumFormat(row.discountable)}</TableCell>
        <TableCell align="right">{xlNumFormat(row.other)}</TableCell>
        <TableCell align="right">{xlNumFormat(row.capitalLoss)}</TableCell>
      </TableRow>
    );
  };

  const detailedColumn = (rowMeta: { dataIndex: number; rowIndex: number }): React.ReactNode => {
    return (
      <>
        {rows[rowMeta.dataIndex]?.items.map((item: UnrealisedReportDetail, index: number) => {
          if (item.isGroup) {
            return gainsLossesHeader(item, rowMeta.dataIndex, index);
          } else {
            return gainsLossesDetail(item, rowMeta.dataIndex, index, true);
          }
        })}
      </>
    );
  };

  const getSecurityDetailsColumn = (dataIndex: number): React.ReactNode => {
    return (
      <>
        <Typography variant="h5" color={'primary'}>
          {rows.length > 0 ? rows[dataIndex]?.securityCode : ''}
        </Typography>
        <Typography variant="h5" color={'textSecondary'}>
          {rows.length > 0 ? rows[dataIndex]?.securityName : ''}
        </Typography>
      </>
    );
  };

  const unitsColumn = (dataIndex: number): React.ReactNode => {
    return (
      <Typography variant="h5" align="right">
        {formatNumberCommaSeparatedForCSV(rows[dataIndex]?.units, 4)}
      </Typography>
    );
  };

  const originalCostBaseColumn = (dataIndex: number): React.ReactNode => {
    return (
      <Typography variant="h5" align="right">
        {rows.length > 0 ? formatNumberCommaSeparatedForCSV(rows[dataIndex]?.originalCostBase) : '0.00'}
      </Typography>
    );
  };

  const costBaseAdjustmentsColumn = (dataIndex: number): React.ReactNode => {
    return (
      <Typography variant="h5" align="right">
        {xlNumFormat(rows[dataIndex]?.costBaseAdjustments)}
      </Typography>
    );
  };

  const adjustedReducedCostbaseColumn = (dataIndex: number): React.ReactNode => {
    return (
      <Typography variant="h5" align="right">
        {rows.length > 0 ? formatNumberCommaSeparatedForCSV(rows[dataIndex]?.adjustedReducedCostbase) : '0.00'}
      </Typography>
    );
  };

  const marketValueColumn = (dataIndex: number): React.ReactNode => {
    return (
      <Typography variant="h5" align="right">
        {rows.length > 0 ? formatNumberCommaSeparatedForCSV(rows[dataIndex]?.marketValue) : ''}
      </Typography>
    );
  };

  const unrealisedGainLossColumn = (dataIndex: number): React.ReactNode => {
    const gainAndLoss = rows[dataIndex]?.unrealisedGainLoss;
    return (
      <>
        <Typography
          variant="h5"
          align="right"
          color={gainAndLoss == 0 || !gainAndLoss ? 'textPrimary' : gainAndLoss < 0 ? 'error' : 'secondary'}
        >
          {rows.length > 0
            ? negativeDollars(formatNumberCommaSeparatedForCSV(rows[dataIndex]?.unrealisedGainLoss))
            : ''}
        </Typography>
      </>
    );
  };

  const indexationColumn = (dataIndex: number): React.ReactNode => {
    return (
      <Typography variant="h5" align="right">
        {xlNumFormat(rows[dataIndex]?.indexation)}
      </Typography>
    );
  };

  const discountableColumn = (dataIndex: number): React.ReactNode => {
    return (
      <Typography variant="h5" align="right">
        {xlNumFormat(rows[dataIndex]?.discountable)}
      </Typography>
    );
  };

  const otherColumn = (dataIndex: number): React.ReactNode => {
    return (
      <Typography variant="h5" align="right">
        {xlNumFormat(rows[dataIndex]?.other)}
      </Typography>
    );
  };

  const capitalLossColumn = (dataIndex: number): React.ReactNode => {
    return (
      <Typography variant="h5" align="right">
        {xlNumFormat(rows[dataIndex]?.capitalLoss)}
      </Typography>
    );
  };

  const columns: DatatableColumn[] = [
    {
      name: 'ACQUISITIONDATE',
      label: 'ACQUISITION DATE',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex: number): React.ReactNode => getSecurityDetailsColumn(dataIndex),
      },
    },
    {
      name: 'UNITS',
      label: 'UNITS',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex: number): React.ReactNode => unitsColumn(dataIndex),
        setCellHeaderProps: () => {
          return {
            style: { textAlign: 'right' },
          };
        },
      },
    },
    {
      name: 'ORIGINALCOSTBASE',
      label: 'ORIGINAL COST BASE',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex: number): React.ReactNode => originalCostBaseColumn(dataIndex),
        setCellHeaderProps: () => {
          return {
            style: { textAlign: 'right' },
          };
        },
      },
    },
    {
      name: 'COSTBASEADJUSTMENTS',
      label: 'COST BASE ADJUSTMENTS',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex: number): React.ReactNode => costBaseAdjustmentsColumn(dataIndex),
        setCellHeaderProps: () => {
          return {
            style: { textAlign: 'right' },
          };
        },
      },
    },
    {
      name: 'ADJUSTEDCOSTBASE',
      label: 'ADJUSTED COST BASE',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex: number): React.ReactNode => adjustedReducedCostbaseColumn(dataIndex),
        setCellHeaderProps: () => {
          return {
            style: { textAlign: 'right' },
          };
        },
      },
    },
    {
      name: 'MARKETVALUE',
      label: 'MARKET VALUE',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex: number): React.ReactNode => marketValueColumn(dataIndex),
        setCellHeaderProps: () => {
          return {
            style: { textAlign: 'right' },
          };
        },
      },
    },
    {
      name: 'UNREALISEDGAINLOSS',
      label: 'UNREALISED GAIN/LOSS',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex: number): React.ReactNode => unrealisedGainLossColumn(dataIndex),
        setCellHeaderProps: () => {
          return {
            style: { textAlign: 'right' },
          };
        },
      },
    },
    {
      name: 'INDEXATION',
      label: 'INDEXATION',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex: number): React.ReactNode => indexationColumn(dataIndex),
        setCellHeaderProps: () => {
          return {
            style: { textAlign: 'right' },
          };
        },
      },
    },
    {
      name: 'DISCOUNTABLE',
      label: 'DISCOUNTABLE',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex: number): React.ReactNode => discountableColumn(dataIndex),
        setCellHeaderProps: () => {
          return {
            style: { textAlign: 'right' },
          };
        },
      },
    },
    {
      name: 'OTHER',
      label: 'OTHER',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex: number): React.ReactNode => otherColumn(dataIndex),
        setCellHeaderProps: () => {
          return {
            style: { textAlign: 'right' },
          };
        },
      },
    },
    {
      name: 'CAPITALLOSS',
      label: 'CAPITAL LOSS',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex: number): React.ReactNode => capitalLossColumn(dataIndex),
        setCellHeaderProps: () => {
          return {
            style: { textAlign: 'right' },
          };
        },
      },
    },
  ];

  const classes = useStyles();

  const noUnrealisedDataMsg =
    `There are no unrealised CGT assets for this account at the date selected. Please choose another date to view Unrealised Capital Gains and Losses as at that date. For any assistance, please contact our Adviser Services team on 1300 726 008 or ${ADVISER_CONTACT_EMAIL}`;

  return (
    <>
      <Paper elevation={0} style={{ marginBottom: '40px' }}>
        <ClientSideDataTable
          loadingProgress={{ isLoading, hasErrors }}
          columns={columns}
          data={rows ?? []}
          options={{
            filter: false,
            viewColumns: false,
            expandableRows: true,
            expandableRowsOnClick: true,
            renderExpandableRow: (_rowData, rowMeta) => detailedColumn(rowMeta),
          }}
          id="client-gainslosses-datatable"
          noDataLabel={noUnrealisedDataMsg}
        ></ClientSideDataTable>
      </Paper>
    </>
  );
}
