import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchDataFeeds } from './thunks';
import { DataFeed, UserSettingsState } from './types';

export const initialState: UserSettingsState = {
  dataFeeds: [],
  isDataFromTriumph: true,
  rebalanceFromTriumph: true,
};

export const userSettingsSlice = createSlice({
  name: '@@store/userSettings',
  initialState,
  reducers: {
    setDataFromTriumph: (state, action: PayloadAction<boolean>) => {
      state.isDataFromTriumph = action.payload;
    },
    setRebalanceFromTriumph: (state, action: PayloadAction<boolean>) => {
      state.rebalanceFromTriumph = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDataFeeds.fulfilled, (state, action: PayloadAction<DataFeed[]>) => {
      state.dataFeeds = action.payload;
    });
  },
});
