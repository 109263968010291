import { createSelector } from '@reduxjs/toolkit';
import { selectClientsState } from '../../store/selectors';

export const selectListState = createSelector(selectClientsState, (clientsState) => clientsState.list);
export const selectParameters = createSelector(selectListState, (listState) => listState.parameters);
export const selectTotals = createSelector(selectListState, (listState) => listState.totals);

export const selectClientListResults = createSelector(selectListState, (listState) => listState?.clients);

export const selectPagination = createSelector(selectParameters, (parameters) => parameters.pagination);

export const selectOnboardings = createSelector(selectListState, (list) => list.onboardings);
export const selectQuickOnboardingAfsls = createSelector(selectListState, (list) => list.quickOnboarding.afsls);
