import { FiberManualRecord as DotIcon } from '@mui/icons-material';
import { Box, Card, CardContent, CardHeader, Divider, Grid, ListItem, Tooltip, Typography } from '@mui/material';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { formatDollars, formatNumberCommaSeparated, formatPercentage } from '../../../../../common';
import { LoadingIndicator } from '../../../../../common/components/LoadingIndicator';
import { AssetGroup, CalculatedHoldingsForPortfolioItem } from '../store/types';
import WO2Button from './../../../../../common/components/button/Button';
import { Props } from './ClientPortfolioDetailsContainer';

interface LocalProps extends Props, RouteComponentProps {}

export const ClientPortfolioDetailsComponent = (props: LocalProps): JSX.Element => {
  const {
    isLoading,
    hasErrors,
    holdings,
    clientId,
    parameters,
    fetchClientPortfolioDetails,
    setSelectedHolding,
    selectSelectedHoldingItems,
    isDataFromTriumph,
  } = props;
  const [assetGroups, setAssetGroups] = useState<AssetGroup[]>([]);

  useEffect(() => {
    setAssetGroups(props.assetGroups || []);
  }, [props.assetGroups]);

  useEffect(() => {
    if (clientId && parameters?.selectedPortfolio) {
      fetchClientPortfolioDetails({ clientId, parameters, isDataFromTriumph });
    }
  }, [clientId, parameters, fetchClientPortfolioDetails, isDataFromTriumph]);

  const chartColors = [
    '#2c82be',
    '#429ff9',
    '#423cff',
    '#0080ff',
    '#000080',
    '#008ecc',
    '#008081',
    '#4482b4',
    '#111e6c',
    '#1d2951',
    '#dbecf8',
  ];

  const totalMarketValue = _.find(assetGroups, (i) => i.name === 'All Assets')?.value;

  const chartData = holdings.map((i, index) => ({
    y: i.currentWeightPercentage,
    name: i.groupName,
    color: chartColors[index],
    sliced: i.groupName === parameters.selectedHolding,
    selected: i.groupName === parameters.selectedHolding,
    events: {
      select: function (e: { target: { name: string | null } }) {
        setSelectedHolding(holdings.find((i) => i.groupName === e.target.name)?.groupName ?? null);
      },
      unselect: function () {
        setSelectedHolding(null);
      },
    },
  }));

  const chartText = `<div style="color:#000a12; font-family: 'AvenirHeavy'; font-weight: 400; font-size: 20px; line-height: 27px; height: 27px; width: 106px;">${formatDollars(
    totalMarketValue
  )}</div>`;

  const chartOptions = {
    chart: {
      type: 'pie',
    },
    allowPointSelect: true,
    title: {
      verticalAlign: 'middle',
      floating: true,
      text: chartText,
    },
    colors: chartColors,
    subtitle: {
      text: '',
    },
    plotOptions: {
      pie: {
        innerSize: '60%',
        depth: 45,
        tooltip: {
          pointFormatter: function (this: Highcharts.TooltipFormatterContextObject) {
            return `<span style="color:${this.color}">●</span> <b>${this.percentage?.toFixed(2)}%</b><br/>`;
          },
        },
      },
    },
    series: [
      {
        allowPointSelect: true,
        name: '',
        data: chartData,
        dataLabels: {
          enabled: false,
        },
      },
    ],
    credits: {
      enabled: false,
    },
  };

  const viewFullPortfolio = (clientId: number) => {
    props.history.push(`/client/${clientId}/portfolio`);
  };

  return (
    <Card elevation={0}>
      <CardHeader
        title={
          <Typography gutterBottom variant="h3">
            Portfolio
          </Typography>
        }
      />
      <Divider />
      <LoadingIndicator progress={{ isLoading, hasErrors }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={5}>
              <HighchartsReact highcharts={Highcharts} options={chartOptions} />
              <ListItem>
                <Grid container spacing={1}>
                  <Grid item xs={2}></Grid>
                  <Grid item xs={6}>
                    <Typography variant="h6" color="textPrimary" style={{ textTransform: 'uppercase' }}>
                      Asset &amp; Value
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      variant="h6"
                      color="textPrimary"
                      style={{ textTransform: 'uppercase', textAlign: 'right' }}
                    >
                      Total Return
                    </Typography>
                  </Grid>
                </Grid>
              </ListItem>
              <Divider />
              {assetGroups.map((group: AssetGroup, index: number) => (
                <ListItem
                  key={index}
                  divider
                  selected={
                    group.name === parameters.selectedHolding ||
                    (group.name === 'All Assets' && !parameters.selectedHolding)
                  }
                  button
                  onClick={() => {
                    group.name === parameters.selectedHolding || group.name === 'All Assets'
                      ? setSelectedHolding(null)
                      : setSelectedHolding(group.name);
                  }}
                >
                  <Grid container spacing={1} alignItems="center">
                    <Grid item xs={2}>
                      <DotIcon fontSize={'large'} htmlColor={chartColors[index]} />
                    </Grid>
                    <Grid item xs={6}>
                      <Tooltip title={group.name ?? ''} arrow>
                        <Typography noWrap variant={'h5'}>
                          {group.name}
                        </Typography>
                      </Tooltip>
                      <Typography noWrap variant={'h5'} color={'textSecondary'}>
                        {formatDollars(group.value)}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography
                        noWrap
                        variant={'h5'}
                        color={group.gainValue >= 0 ? 'secondary' : 'error'}
                        style={{ textAlign: 'right' }}
                      >
                        {formatDollars(group.gainValue)}
                      </Typography>
                      <Typography noWrap variant={'h6'} color={'textSecondary'} style={{ textAlign: 'right' }}>
                        {formatPercentage(group.gainPercentage)}
                      </Typography>
                    </Grid>
                  </Grid>
                </ListItem>
              ))}
            </Grid>
            <Grid item xs={7}>
              <ListItem disableGutters>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <Typography variant="h6" style={{ textTransform: 'uppercase' }}>
                      Featured Holdings
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="h6" style={{ textTransform: 'uppercase', textAlign: 'right' }}>
                      Units/Price
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="h6" style={{ textTransform: 'uppercase', textAlign: 'right' }}>
                      Value
                    </Typography>
                  </Grid>
                </Grid>
              </ListItem>
              <Divider />
              {selectSelectedHoldingItems
                .sort((a, b) => (b.currentValue || 0) - (a.currentValue || 0))
                .slice(0, 10)
                .map((holding: CalculatedHoldingsForPortfolioItem, index: number) => (
                  <ListItem key={index} divider disableGutters>
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <Tooltip title={holding.securityCode ?? ''} arrow>
                          <Typography noWrap variant={'h5'} color={'primary'}>
                            {holding.securityCode}
                          </Typography>
                        </Tooltip>
                        <Tooltip title={holding.securityName ?? ''} arrow>
                          <Typography noWrap variant={'h5'} color={'textSecondary'}>
                            {holding.securityName}
                          </Typography>
                        </Tooltip>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography noWrap variant={'h5'} color={'textPrimary'} style={{ textAlign: 'right' }}>
                          {formatNumberCommaSeparated(Math.round(holding.units || 0))}
                        </Typography>
                        <Typography noWrap variant={'h6'} color={'textSecondary'} style={{ textAlign: 'right' }}>
                          {formatDollars(holding.unitPrice)}
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography noWrap variant={'h5'} style={{ textAlign: 'right' }}>
                          {formatDollars(holding.currentValue)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </ListItem>
                ))}
              <Box paddingTop={'20px'} display={'flex'} justifyContent={'center'}>
                <WO2Button
                  className={'ShowMore'}
                  onClick={() => {
                    if (!!props.clientId) {
                      viewFullPortfolio(props.clientId);
                    }
                  }}
                >
                  View Full Portfolio
                </WO2Button>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </LoadingIndicator>
    </Card>
  );
};
