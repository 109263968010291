import { Box, Button, Paper, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { formatDollars, nameof } from 'src/common';
import { ClientSideDataTable } from 'src/common/components/dataTable/clientSide';
import { DatatableColumn } from 'src/common/components/dataTable/types';
import { Props } from '../resultsContainer';
import { OrderSearchResult } from '../store/types';
import GridOnIcon from '@mui/icons-material/GridOn';
import { WO2Menu } from 'src/common/components/Menu';
import ArrowDropDownSharpIcon from '@mui/icons-material/ArrowDropDownSharp';
import { CSVDataType, convertToCSVFormat, downloadCsv } from 'src/common';

export const Results = (props: Props): JSX.Element => {
  const [selected, setSelected] = useState<number[]>([]);
  const { results, setSelectedResults, history, selectedResults, isLoadingProgress } = props;
  const { path } = useRouteMatch();

  useEffect(() => {
    setSelected(selectedResults);
  }, [selectedResults]);

  const handleNext = () => {
    setSelectedResults(selected ?? []);
    history.push(`${path}/edit`);
  };

  const clientPortfolioNameColumn = (dataIndex: number, results: OrderSearchResult[]): React.ReactNode => (
    <>
      <Typography variant="h5">{results[dataIndex]?.clientName}</Typography>
      <Typography variant="h5" color={'textSecondary'}>
        {results[dataIndex]?.portfolioName}
      </Typography>
    </>
  );

  const typeColumn = (dataIndex: number, results: OrderSearchResult[]): React.ReactNode => (
    <>
      <Typography variant="h5">{results[dataIndex]?.clientType}</Typography>
      <Typography variant="h6" color={'textSecondary'}>
        {results[dataIndex]?.serviceType}
      </Typography>
    </>
  );

  const cashBalanceColumn = (dataIndex: number, results: OrderSearchResult[]): React.ReactNode => (
    <Typography variant="h5">{formatDollars(results[dataIndex]?.cashBalance)}</Typography>
  );

  const portfolioValueColumn = (dataIndex: number, results: OrderSearchResult[]): React.ReactNode => (
    <Typography variant="h5">{formatDollars(results[dataIndex]?.marketValue)}</Typography>
  );

  const getResultColumns = (results: OrderSearchResult[]): DatatableColumn[] => [
    {
      name: nameof((i: OrderSearchResult) => i.clientName),
      label: 'CLIENT & PORTFOLIO NAME',
      options: {
        filter: false,
        sort: true,
        customBodyRenderLite: (dataIndex: number) => clientPortfolioNameColumn(dataIndex, results),
      },
    },
    {
      name: nameof((i: OrderSearchResult) => i.clientType),
      label: 'TYPE',
      options: {
        filter: false,
        sort: true,
        customBodyRenderLite: (dataIndex) => typeColumn(dataIndex, results),
      },
    },
    {
      name: nameof((i: OrderSearchResult) => i.cashBalance),
      label: 'CASH BALANCE',
      textAlign: 'right',
      options: {
        filter: false,
        sort: true,
        customBodyRenderLite: (dataIndex) => cashBalanceColumn(dataIndex, results),
      },
    },
    {
      name: nameof((i: OrderSearchResult) => i.marketValue),
      label: 'PORTFOLIO VALUE',
      textAlign: 'right',
      options: {
        filter: false,
        sort: true,
        customBodyRenderLite: (dataIndex) => portfolioValueColumn(dataIndex, results),
      },
    },
  ];

  function generateBulkOrderCsv() {
    interface BulkOrder {
      'Client Name': string;
      'Portfolio Name': string;
      'Client Type': string;
      'Service Type': string;
      'Cash Balance': string;
      'Portfolio Value': string;
  }

    const csvRow: BulkOrder[] = [];

    results.forEach((row) => {
      csvRow.push({
        'Client Name': row.clientName,
        'Portfolio Name': row.portfolioName,
        'Client Type': row.clientType,
        'Service Type': row.serviceType,
        'Cash Balance': formatDollars(row.cashBalance),
        'Portfolio Value': formatDollars(row.marketValue),
      })
    });

    const data: CSVDataType[] = convertToCSVFormat(csvRow);

    downloadCsv(data, '_Bulk_Order');
  }

  const exportButtons = [
    {
      icon: <GridOnIcon />,
      label: 'Download Bulk Order Data',
      onClick: generateBulkOrderCsv,
      testId: 'downloadBulkOrderData',
    }
  ];

  return (
    <Paper elevation={0}>
      <Box display="flex" flexDirection="row" justifyContent="flex-end" padding="24px">
        <WO2Menu
          testId="export_button"
          buttonTitle="Export"
          buttonIcon={
            <div
              style={{
                paddingLeft: '10px',
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
                backgroundColor: 'blue',
                borderRadius: '25px',
                padding: '10px 10px 10px 20px',
                marginTop: '-7px',
              }}
            >
              <span style={{ color: '#ffffff', fontSize: '0.875rem' }}>EXPORT</span>
              <ArrowDropDownSharpIcon sx={{ color: '#ffffff' }} />
            </div>
          }
          items={exportButtons}
        />
        <Button
          disabled={selected.length <= 0}
          disableElevation
          color={'primary'}
          onClick={() => selected.length && handleNext()}
          variant="contained"
          id="next_button"
        >
          Next
        </Button>
      </Box>
      <ClientSideDataTable
        loadingProgress={isLoadingProgress}
        columns={getResultColumns(results)}
        data={results}
        options={{
          filter: false,
          selectableRows: 'multiple',
          selectableRowsHideCheckboxes: false,
          onRowSelectionChange: (currentRowsSelected, allRowsSelected, rowsSelected) => {
            setSelected(rowsSelected ?? []);
          },
          rowsSelected: selected,
        }}
        enablePagination
      />
      <Box display="flex" flexDirection="row" justifyContent="flex-end" padding="24px">
        <Button
          disabled={selected.length <= 0}
          disableElevation
          color={'primary'}
          onClick={() => selected.length && handleNext()}
          variant="contained"
        >
          Next
        </Button>
      </Box>
    </Paper>
  );
};
