import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';
import { DateRange } from '../../../../../../store';
import { fetchRealisedCapitalGainsAndLosses, fetchUnrealisedCapitalGainsAndLosses } from './thunks';
import { GainType, OverviewState, RealisedCapitalGainsLossesResult, UnrealisedCapitalGainsLossesResult } from './types';
import { SystemMinimumDate } from 'src/common/utils/dateFunctions';

export const initialState: OverviewState = {
  parameters: {
    gainType: GainType.Unrealised,
    dates: {
      dateFrom: DateTime.fromISO(SystemMinimumDate).toISODate(),
      dateTo: DateTime.now().toISODate(),
    },
    filter: '',
  },
  realisedResult: undefined,
  unrealisedResult: undefined,
};

export const overviewSlice = createSlice({
  name: '@@client/capitalGainsLosses/overview',
  initialState,
  reducers: {
    setGainTypeParameter: (state: OverviewState, action: PayloadAction<GainType>) => {
      state.parameters.gainType = action.payload;
    },
    setDateParameter: (state: OverviewState, action: PayloadAction<DateRange>) => {
      state.parameters.dates = action.payload;
    },
    setFilterParameter: (state: OverviewState, action: PayloadAction<string>) => {
      state.parameters.filter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchUnrealisedCapitalGainsAndLosses.fulfilled,
      (state, action: PayloadAction<UnrealisedCapitalGainsLossesResult>) => {
        state.unrealisedResult = action.payload;
      }
    );
    builder.addCase(
      fetchRealisedCapitalGainsAndLosses.fulfilled,
      (state, action: PayloadAction<RealisedCapitalGainsLossesResult>) => {
        state.realisedResult = action.payload;
      }
    );
  },
});
