import { ToggleButtonGroup as MuiToggleButtonGroup, ToggleButtonGroupProps } from '@mui/material/';
import * as React from 'react';
import { ToggleButtonItem, WO2ToggleButton } from './ToggleButton';

export interface WO2ToggleButtonGroupProps<T> {
  buttons: ToggleButtonItem<T>[];
  exclusive?: boolean;
  fullWidth?: boolean;
  id?: string;
  onChangeHandler: (value: T) => void;
  toggleButtonGroupMuiProps?: ToggleButtonGroupProps & {
    ariaLabel?: string;
  };
  value: T;
  style?: React.CSSProperties;
}

export const WO2ToggleButtonGroup = <T extends string | number | boolean>({
  buttons,
  exclusive,
  fullWidth,
  id,
  onChangeHandler,
  style,
  value,
  toggleButtonGroupMuiProps,
}: WO2ToggleButtonGroupProps<T>): JSX.Element => (
  <MuiToggleButtonGroup
    className={fullWidth ? 'Mui-flexInitial' : ''}
    exclusive={!!exclusive ? exclusive : true}
    value={value}
    onChange={(event, value: T) => onChangeHandler(value)}
    id={id}
    data-testid={id}
    {...toggleButtonGroupMuiProps}
    style={style}
  >
    {buttons.map(
      (button: ToggleButtonItem<T>): JSX.Element => (
        <WO2ToggleButton
          key={button.value.toString()}
          {...button}
          selected={!!button.selected ? button.selected : value === button.value}
          fullFlexBasis={!!fullWidth}
          onChangeHandler={(value: T) => onChangeHandler(value)}
        />
      )
    )}
  </MuiToggleButtonGroup>
);
