import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Box, Breadcrumbs, Grid, Typography } from '@mui/material';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React from 'react';
import { DateTimeFormat, formatDollars, formatPercentage, getLocalDateTime } from '../../../../../../common';
import { AssetGroup, AssetGroupItem, GainsLossesDetailItem } from '../store/types';

export interface Props {
  selectedSecurity: AssetGroupItem | null;
  gainsLossesData: GainsLossesDetailItem | undefined;
  selectGroup: (group: AssetGroup | null, index: number) => void;
}

export default function SecurityCard(props: Props): JSX.Element {
  const chartData = [
    {
      y: props.selectedSecurity?.currentWeightPercentage ?? 0,
      name: props.selectedSecurity?.securityCode,
      color: '#423cff',
      sliced: false,
      selected: false,
      events: {},
    },
    {
      y: 1 - (props.selectedSecurity?.currentWeightPercentage ?? 0),
      name: '',
      color: '#aebbd2',
      sliced: false,
      selected: false,
      events: {},
    },
  ];

  const chartText = `<div style="color:#000a12; font-family: 'AvenirHeavy'; font-weight: 400; font-size: 20px; line-height: 27px; height: 27px; width: 106px;">${formatPercentage(
    props.selectedSecurity?.currentWeightPercentage
  )}</div><br/><div style="color:rgba(0,0,0,0.6); font-family: 'AvenirMedium'; font-weight: 400; font-size: 10px; line-height: 20px; height: 20px; width: 83px; letter-spacing: 1.5px;">OF PORTFOLIO</div>`;

  const chartOptions2 = {
    chart: {
      type: 'pie',
    },
    allowPointSelect: true,
    title: {
      verticalAlign: 'middle',
      floating: true,
      text: chartText,
    },
    colors: 'chartColors',
    subtitle: {
      text: '',
    },
    plotOptions: {
      pie: {
        innerSize: '60%',
        depth: 45,
        tooltip: {
          pointFormatter: function (this: Highcharts.TooltipFormatterContextObject) {
            return `<span style="color:${this.color}">●</span> <b>${this.percentage?.toFixed(2)}%</b><br/>`;
          },
        },
      },
    },
    series: [
      {
        allowPointSelect: true,
        name: '',
        data: chartData,
        dataLabels: {
          enabled: false,
        },
      },
    ],
    credits: {
      enabled: false,
    },
  };

  const costBase = props.gainsLossesData ? props.gainsLossesData.costBase : 0;
  const unrealisedGainLoss = props.gainsLossesData ? props.gainsLossesData.gainLossValue : 0;

  return (
    <>
      <>
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
          <Typography color="textSecondary" onClick={() => props.selectGroup(null, 1)}>
            Portfolio
          </Typography>
          <Typography color="textSecondary">{props.selectedSecurity?.securityCode}</Typography>
        </Breadcrumbs>
      </>
      <HighchartsReact highcharts={Highcharts} options={chartOptions2} />
      <Typography variant="h5" align={'center'}>
        {props.selectedSecurity?.securityName}
      </Typography>
      <Typography variant="h2" align={'center'}>
        <Box paddingTop={'20px'} fontWeight={700}>
          {formatDollars(props.selectedSecurity?.unitPrice)} AUD
        </Box>
      </Typography>
      <Typography variant="h5" align={'center'}>
        As at {getLocalDateTime(props.selectedSecurity?.priceDate, DateTimeFormat.DayDate)}
      </Typography>
      <Grid container spacing={2} style={{ paddingTop: '20px', paddingBottom: '20px' }}>
        <Grid item xs={6}>
          <Typography variant={'h3'} align={'center'}>
            <Box fontWeight={700}>{formatDollars(costBase)}</Box>
          </Typography>
          <Typography variant={'h6'} align={'center'} style={{ textTransform: 'uppercase' }}>
            Total Cost Base
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant={'h3'} align={'center'}>
            <Box fontWeight={700}>{formatDollars(unrealisedGainLoss)}</Box>
          </Typography>
          <Typography variant={'h6'} align={'center'} style={{ textTransform: 'uppercase' }}>
            Unrealised Gain/Loss
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}
