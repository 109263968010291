import Grid from '@mui/material/Grid';
import 'date-fns';
import React from 'react';
import { formatDollarsScaled, formatPercentage } from '../../../../../common';
import CardComponent from '../../../../../common/components/CardComponent';
import { LoadingProgress } from '../../../../../common/store/types';
import { theme } from '../../../../../themes';
import { PerformanceChartResult } from '../store/types';

export interface Props {
  performanceChartProgress: LoadingProgress;
  performanceChartResults?: PerformanceChartResult | null;
}

export const PerformanceCards = (props: Props): JSX.Element => {
  const { performanceChartProgress, performanceChartResults } = props;

  return (
    <Grid container spacing={2} style={{ paddingTop: '20px', paddingBottom: '20px' }}>
      <Grid item xs={2}>
        <CardComponent
          progress={performanceChartProgress}
          background={theme.palette.gradient1?.main}
          value={formatDollarsScaled(performanceChartResults?.changeInCapitalValue)}
          subText={'Capital Return ($)'}
        />
      </Grid>
      <Grid item xs={2}>
        <CardComponent
          progress={performanceChartProgress}
          background={theme.palette.gradient2?.main}
          value={formatDollarsScaled(performanceChartResults?.changeInIncomeValue)}
          subText={'Income Return ($)'}
        />
      </Grid>
      <Grid item xs={2}>
        <CardComponent
          progress={performanceChartProgress}
          background={theme.palette.gradient1?.main}
          value={formatDollarsScaled(performanceChartResults?.changeInValue)}
          subText={'Total Return ($)'}
        />
      </Grid>
      <Grid item xs={2}>
        <CardComponent
          progress={performanceChartProgress}
          background={theme.palette.gradient2?.main}
          value={formatPercentage(performanceChartResults?.changeInCapitalValuePercentage)}
          subText={'Capital Return (%)'}
        />
      </Grid>
      <Grid item xs={2}>
        <CardComponent
          progress={performanceChartProgress}
          background={theme.palette.gradient1?.main}
          value={formatPercentage(performanceChartResults?.changeInIncomeValuePercentage)}
          subText={'Income Return (%)'}
        />
      </Grid>
      <Grid item xs={2}>
        <CardComponent
          progress={performanceChartProgress}
          background={theme.palette.gradient2?.main}
          value={formatPercentage(performanceChartResults?.changeInValuePercentage)}
          subText={'Total Return (%)'}
        />
      </Grid>
    </Grid>
  );
};
