import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../../app/api';
import { FetchModelCommonPayload, ModelVersions } from './types';

export enum ModelCommonActionTypes {
  FetchModelVersions = '@@model/common/FetchModelVersions',
  FetchModelCurrentStatus = '@@model/common/FetchModelCurrentStatus',
  FetchModelCurrentVersion = '@@model/common/FetchModelCurrentVersion',
  FetchModelCurrentId = '@@model/common/FetchModelCurrentId',
  FetchModelCurrent = '@@model/common/FetchModelCurrent',
  FetchEditModelAccess = '@@model/common/FetchEditModelAccess',
}

export enum ModelCommonApiEndpoints {
  fetchModelVersions = '/strategies/GetModelVersions',
  fetchHasEditModelAccess = '/strategies/GetHasEditModelAccess',
}

export const fetchModelVersions = createAsyncThunk(ModelCommonActionTypes.FetchModelVersions, async (wrapper: FetchModelCommonPayload) => {
  const response = await api.get<ModelVersions>(`${ModelCommonApiEndpoints.fetchModelVersions}?modelId=${wrapper.modelId}`);

  return response.data;
});

export const fetchHasEditModelAccess = createAsyncThunk(ModelCommonActionTypes.FetchEditModelAccess, async (wrapper: FetchModelCommonPayload) => {
  const response = await api.get<boolean>(`${ModelCommonApiEndpoints.fetchHasEditModelAccess}?modelId=${wrapper.modelId}`);

  return response.data;
});
