import { IconButton, Menu, MenuItem, MenuProps, Tooltip } from '@mui/material';
import { default as React } from 'react';
import { theme } from '../../themes';

export interface WO2MenuItem {
  icon?: React.ReactElement;
  isHidden?: boolean;
  label: string;
  onClick: () => void;
  testId: string;
  disabled?: boolean;
}
export interface WO2MenuProps {
  MUIProps?: MenuProps;
  items: WO2MenuItem[];
  buttonTitle: string;
  buttonIcon: React.ReactElement;
  showDivider?: boolean;
  testId: string;
}

export const WO2Menu = ({ MUIProps, items, buttonTitle, buttonIcon, showDivider, testId }: WO2MenuProps): JSX.Element => {
  const [actionsAnchorEl, setActionsAnchorEl] = React.useState<HTMLElement | null>(null);

  return (
    <>
      <Tooltip title={buttonTitle}>
        <IconButton
          data-testid={`menuActionButton_${testId}`}
          disableFocusRipple
          disableRipple
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            setActionsAnchorEl(event.currentTarget);
          }}
        >
          {buttonIcon}
        </IconButton>
      </Tooltip>
      <Menu
        {...MUIProps}
        anchorEl={actionsAnchorEl}
        open={Boolean(actionsAnchorEl)}
        onClose={() => {
          setActionsAnchorEl(null);
        }}
        elevation={0}
      >
        {items.map((item: WO2MenuItem, index: number): JSX.Element | undefined =>
          !item.isHidden ? (
            <MenuItem
              key={index}
              onClick={item.onClick}
              style={{ fontFamily: theme.typography.fontFamily, fontSize: theme.typography.fontSize, color: 'black' }}
              data-testid={`menuItem_${item.testId}`}
              disabled={item.disabled}
              divider={!!showDivider}
              onMouseUp={() => {
                setActionsAnchorEl(null);
              }}
            >
              {item.icon}
              &nbsp; {item.label}
            </MenuItem>
          ) : undefined
        )}
      </Menu>
    </>
  );
};
