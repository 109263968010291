import React, { useEffect, useState } from 'react';
import { Props } from '../container';
import { Box, Container, Typography } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { ProgressButton } from 'src/common';
import { ReportTemplateModal } from './ReportTemplateModal';
import { ReportTemplateColumns } from './ReportTemplateColumns';
import { ReportTemplate } from '../store';
import { ReportTemplateDeleteModal } from './ReportTemplateDeleteModal';

export const ReportTemplateComponent = ({
  fetchReportTemplates,
  templates,
  saveReportTemplateFile,
  saveReportTemplateFileProgress,
  fetchReportTemplatesProgress,
  downloadReportTemplateFile,
}: Props): JSX.Element => {
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [row, setRow] = useState<ReportTemplate | null>(null);

  const handleImport = (name: string, file?: File) => {
    const id = row?.id ?? '';
    saveReportTemplateFile({ file: file, name: name, id: id });
    setModalOpen(false);
  };

  const handleOpenEditModal = (row: ReportTemplate) => {
    setRow(row);
    setModalOpen(true);
  };

  const handleDownloadTemplate = (row: ReportTemplate) => {
    downloadReportTemplateFile(row);
  };

  const handleOpenDeleteModal = (row: ReportTemplate) => {
    setRow(row);
    setDeleteModalOpen(true);
  };

  const handleOpenModal = () => {
    setRow(null);
    setModalOpen(true);
  };

  const handleCloseModal = () => setModalOpen(false);
  const handleCloseDeleteModal = () => setDeleteModalOpen(false);

  useEffect(() => {
    fetchReportTemplates();
  }, []);

  return (
    <Box style={{ paddingTop: '20px' }}>
      <Container>
        <Box display="flex" flexDirection="column" gap={2}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h2">Templates</Typography>

            <ProgressButton
              color="primary"
              style={{ borderRadius: 24, height: 48 }}
              disableElevation
              disabled={saveReportTemplateFileProgress.isLoading}
              onClick={handleOpenModal}
              variant="contained"
            >
              ADD TEMPLATE
            </ProgressButton>
          </Box>

          <DataGridPro
            loading={fetchReportTemplatesProgress.isLoading || saveReportTemplateFileProgress.isLoading}
            autoHeight
            pagination
            disableSelectionOnClick
            columns={ReportTemplateColumns({
              setEditRow: handleOpenEditModal,
              setDeleteRow: handleOpenDeleteModal,
              downloadTemplate: handleDownloadTemplate,
            })}
            rows={templates}
          />
        </Box>
      </Container>

      <ReportTemplateModal row={row} isOpen={modalOpen} handleClose={handleCloseModal} handleImport={handleImport} />
      <ReportTemplateDeleteModal row={row} isOpen={deleteModalOpen} handleClose={handleCloseDeleteModal} />
    </Box>
  );
};
