export const rolloverAmountFieldText = 'We use this to calculate estimated advice fees.';

export const rolloverAmountTooltipText =
  "The rollover amount (or estimated rollover amount for full rollovers & in specie transfers) are needed in order to comply with the Corporations Act 2001 which requires fee recipients to obtain written consent that meets certain requirements before deducting ongoing fees from client's accounts. These requirements are set out in ASIC Corporations (Consents to Deductions - Ongoing Fee Arrangements) Instrument 2021/124.";

export const inSpecieLabelText = 'In specie transfer (Transfer assets to prevent sell down)';

export const removeRolloverTooltipText = 'Remove rollover';

export const maxContributionsAmountValue = 999999999;
export const maxRolloverAmountValue = 999999999;
