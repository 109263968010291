import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';
import { fetchMonthlyDashboardChart, fetchStrategyDashboard, fetchStrategyDetails } from './thunks';
import {
  MonthlyDashboardChart,
  PerformanceChartInterval,
  PerformanceChartStartDateParameter,
  StrategyDashboard,
  StrategyDashboardState,
  StrategyDetails,
} from './types';

export const initialState: StrategyDashboardState = {
  parameters: {
    performanceChartStartDate: {
      startDate: DateTime.now().minus({ months: 12 }).toISODate(),
      interval: PerformanceChartInterval.OneYear,
    },
  },
  strategyDashboard: null,
  strategyDetails: null,
  monthlyDashboardChart: null,
};

export const strategyDashboardSlice = createSlice({
  name: '@@strategy/Dashboard',
  initialState,
  reducers: {
    setChartDateParameter: (state, action: PayloadAction<PerformanceChartStartDateParameter>) => {
      state.parameters.performanceChartStartDate = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchStrategyDashboard.fulfilled, (state, action: PayloadAction<StrategyDashboard>) => {
      state.strategyDashboard = action.payload;
    });
    builder.addCase(fetchStrategyDetails.fulfilled, (state, action: PayloadAction<StrategyDetails>) => {
      state.strategyDetails = action.payload;
    });
    builder.addCase(fetchMonthlyDashboardChart.fulfilled, (state, action: PayloadAction<MonthlyDashboardChart>) => {
      state.monthlyDashboardChart = action.payload;
    });
  },
});
