import { moment } from '../types';

export const getAvatarText = (name: string | undefined): string => {
  let result = '';
  if (name) {
    result = name
      .replace(/[^A-Za-z0-9À-ÿ ]/gi, '') // taking care of accented characters as well
      .replace(/ +/gi, ' ') // replace multiple spaces to one
      .split(/ /) // break the name into parts
      .reduce((acc, item) => acc + item[0], '') // assemble an abbreviation from the parts
      .concat(name.substr(1)) // what if the name consist only one part
      .concat(name) // what if the name is only one character
      .substr(0, 2) // get the first two characters an initials
      .toUpperCase();
  }

  return result;
};

export const buildEncodedQueryString = (data: Record<string, unknown>): string => {
  const queryString = serialize(data, '');
  return !!queryString ? `?${queryString}` : '';
};

const serialize = (data: Record<string, unknown>, prefix: string): string => {
  const str: string[] = [];
  let prop;
  for (prop in data) {
    if (data.hasOwnProperty(prop)) {
      const key = prefix ? prefix + '.' + prop : prop;
      const val = data[prop];

      if (Array.isArray(val)) {
        val.forEach((child, index) => {
          const arrayPrefix = `${key}[${index}]`;
          str.push(typeof child === 'object' ? serialize(child, arrayPrefix) : encodeURIComponent(arrayPrefix) + '=' + encodeURIComponent(child));
        });
      } else {
        if (!!val !== null && val !== undefined && val !== '') {
          str.push(
            typeof val === 'object'
              ? serialize(val as Record<string, unknown>, key)
              : encodeURIComponent(key) + '=' + encodeURIComponent(val as string | number | boolean | moment)
          );
        }
      }
    }
  }
  return str.filter((val) => !!val).join('&');
};
