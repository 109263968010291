import { connect, ConnectedProps } from 'react-redux';
import { progressSelectorFactory } from 'src/common/store/selectors';
import { RootState } from 'src/store';
import { selectAfslId, selectClientId } from '../../../common/store/selectors';
import {
  fetchApprovedProducts,
  selectActiveInvestmentVersionId,
  selectAssetClasses,
  selectCurrentInvestmentVersionId,
  selectInvestmentService,
  selectSecurities,
} from './../../store/common';
import { Targets } from './components/targets';
import {
  ConfigurationsActionTypes,
  fetchConfigurations,
  fetchDefaultSecurities,
  fetchStrategicAllocations,
  saveConfigurations,
  saveDefaultSecurities,
  saveStrategicAllocations,
  selectCashAccountProduct,
  selectConfigurations,
  selectDefaultSecurities,
  selectStrategicAllocations,
  StrategicAllocationActionTypes,
  targetsSlice,
} from './store';

const mapStateToProps = (state: RootState) => ({
  clientId: selectClientId(state),
  configurations: selectConfigurations(state),
  currentInvestmentVersionId: selectCurrentInvestmentVersionId(state),
  activeInvestmentServiceVersionId: selectActiveInvestmentVersionId(state),
  investmentService: selectInvestmentService(state),
  loadingConfigurationsProgress: progressSelectorFactory([ConfigurationsActionTypes.FetchConfigurations])(state),
  savingConfigurationsProgress: progressSelectorFactory([ConfigurationsActionTypes.SaveConfigurations])(state),

  strategicAllocations: selectStrategicAllocations(state),
  loadingStrategicAllocationsProgress: progressSelectorFactory([
    StrategicAllocationActionTypes.FetchStrategicAllocations,
  ])(state),
  savingStrategicAllocationsProgress: progressSelectorFactory([
    StrategicAllocationActionTypes.UpdateStrategicAllocation,
  ])(state),

  securities: selectSecurities(state),
  assetClasses: selectAssetClasses(state),
  cashAccountProduct: selectCashAccountProduct(state),
  defaultSecurities: selectDefaultSecurities(state),
  afslId: selectAfslId(state),
});

const mapDispatchToProps = {
  ...targetsSlice.actions,

  fetchConfigurations,
  saveConfigurations,

  fetchStrategicAllocations,
  saveStrategicAllocations,

  fetchApprovedProducts,

  fetchDefaultSecurities,
  saveDefaultSecurities,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector>;
export const TargetsContainer = connector(Targets);
