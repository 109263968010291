import { connect, ConnectedProps } from 'react-redux';
import { progressSelectorFactory } from 'src/common';
import { RootState } from 'src/store';
import { selectClientId, selectInvestmentServiceId } from '../../../common/store/selectors';
import { ApprovedProductsActionTypes, fetchSecurities, selectApprovedSecurities, selectCurrentInvestmentVersionId } from './../../store/common';
import { Constraints } from './components/constraints';
import { ConstraintsActionTypes, constraintsSlice, fetchConstraintsAndExcludeRebalance, saveConstraintsAndExcludeRebalance, selectSecurityConstraints } from './store';

const mapStateToProps = (state: RootState) => ({
  clientId: selectClientId(state),
  currentInvestmentVersionId: selectCurrentInvestmentVersionId(state),
  securityConstraints: selectSecurityConstraints(state),
  isLoadingProgress: progressSelectorFactory([ConstraintsActionTypes.FetchInvestmentServiceConstraintsAndExcludeRebalance])(state),
  securities: selectApprovedSecurities(state),
  investmentServiceId : selectInvestmentServiceId(state),
  securitiesLoadingProgress: progressSelectorFactory([ApprovedProductsActionTypes.FetchApprovedProducts])(state),
  savingProgress: progressSelectorFactory([ConstraintsActionTypes.CreateOrUpdateInvestmentServiceConstraintsAndExcludeRebalance])(state),
});

const mapDispatchToProps = {
  ...constraintsSlice.actions,
  fetchConstraintsAndExcludeRebalance,
  fetchSecurities,
  saveConstraintsAndExcludeRebalance,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector>;
export const ConstraintsContainer = connector(Constraints);
