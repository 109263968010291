import { Typography } from '@mui/material';
import React from 'react';
import { ClientSideDataTable } from '../../../../../common/components/dataTable/clientSide';
import { DatatableColumn } from '../../../../../common/components/dataTable/types';
import { AddressType } from '../../../client/common/store/enums';
import { AddressDetails } from '../../../client/common/store/types';

export interface AddressTableProps {
  addresses: AddressDetails[];
  hideColumns?: string[];
}

export const AddressTable = (props: AddressTableProps): JSX.Element => {
  const { addresses, hideColumns } = props;
  const addressColumn = (dataIndex: number): React.ReactNode => {
    return (
      <>
        <Typography variant="h5">{addresses[dataIndex]?.line1 + (!!addresses[dataIndex]?.line2 ? ', ' + addresses[dataIndex]?.line2 : '')}</Typography>
        <Typography variant="h5">
          {addresses[dataIndex]?.suburb} {addresses[dataIndex]?.state} {addresses[dataIndex]?.postcode}
        </Typography>
      </>
    );
  };

  const typeColumn = (dataIndex: number): React.ReactNode => {
    const selectedAddress = props.addresses[dataIndex];

    return (
      <Typography variant="h5">
        {!!selectedAddress &&
          !!selectedAddress.addressTypeIds &&
          selectedAddress.addressTypeIds
            .map((id) => AddressType.getById(id)?.displayName)
            .sort((a, b) => (a || '').localeCompare(b || ''))
            .join(', ')}
      </Typography>
    );
  };

  let columns: DatatableColumn[] = [
    {
      name: 'address',
      label: 'ADDRESS',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex): React.ReactNode => addressColumn(dataIndex),
        setCellProps: () => {
          return {
            style: {
              borderBottom: 'none',
              width: '50%',
            },
          };
        },
      },
    },
    {
      name: 'type',
      label: 'TYPE',
      textAlign: 'left',
      options: {
        filter: false,
        sort: true,
        customBodyRenderLite: (dataIndex): React.ReactNode => typeColumn(dataIndex),
        setCellProps: () => {
          return {
            style: {
              borderBottom: 'none',
              width: '50%',
            },
          };
        },
      },
    },
  ];

  // remove columns needs to be hidden (exclude actions)
  if (!!hideColumns && hideColumns.length > 0) {
    columns = columns.filter(
      (column: DatatableColumn) => hideColumns.map((hideColumn: string) => hideColumn.toLowerCase()).indexOf(column.name.toLowerCase()) === -1
    );
  }

  return (
    <ClientSideDataTable
      loadingProgress={{ isLoading: false, hasErrors: false }}
      columns={columns}
      data={addresses}
      options={{
        filter: false,
      }}
    ></ClientSideDataTable>
  );
};
