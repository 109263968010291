import { Backdrop, Fade, Modal, Paper } from '@mui/material';
import { Form, Formik, FormikProps } from 'formik';
import React, { useEffect, useState } from 'react';
import { EditCancelSaveButtons, Mode } from 'src/common/components/formik/editCancelSaveButtons';
import { LoadingProgress } from 'src/common/store/types';
import { Role } from 'src/features/clients/client/common/store/types';
import * as yup from 'yup';
import { BeneficialOwnerRoleId, BeneficiaryRoleId, ContactDetails, DirectorCompanyOfficerRoleId, RolePercentageModel } from '../../../store/types';
import { RoleSelector } from '../edit/roleSelector';

interface EditRolesFormValues {
  contactId: number | null;
  roleIds: number[];
  isPrimary: boolean;

  beneficialOwnerPercentage: number | null;
  beneficiaryPercentage: number | null;
  beneficiaryRelationship: string;
  directorCompanyOfficerPercentage: number | null;
}

export interface EditRolesSavePayload {
  contactId: number;
  roleMappings: RolePercentageModel[];
  isPrimary: boolean;
}

export interface EditRolesProps {
  contact: ContactDetails;
  roles: Role[];
  rolesProgress: LoadingProgress;
  isOpen: boolean;
  saveProgress: LoadingProgress;
  primaryContactId?: number;
  onSave: (payload: EditRolesSavePayload) => void;
  handleCloseModal: () => void;
  fetchRoles: () => void;
}

export const EditRoles = ({
  contact,
  roles,
  isOpen,
  rolesProgress,
  saveProgress,
  primaryContactId,
  onSave,
  handleCloseModal,
  fetchRoles,
}: EditRolesProps): JSX.Element => {
  const [formValues, setFormValues] = useState<EditRolesFormValues>({
    contactId: null,
    roleIds: [],
    beneficialOwnerPercentage: null,
    beneficiaryPercentage: null,
    beneficiaryRelationship: '',
    directorCompanyOfficerPercentage: null,
    isPrimary: false,
  });

  useEffect(() => {
    fetchRoles();
  }, [fetchRoles]);

  useEffect(() => {
    const beneficialOwnerPercentage = contact.roles.find((r) => r.roleId === BeneficialOwnerRoleId)?.percent;
    const beneficiaryPercentage = contact.roles.find((r) => r.roleId === BeneficiaryRoleId)?.percent;
    const beneficiaryRelationship = contact.roles.find((r) => r.roleId === BeneficiaryRoleId)?.relationship || '';
    const directorCompanyOfficerPercentage = contact.roles.find((r) => r.roleId === DirectorCompanyOfficerRoleId)?.percent;

    setFormValues({
      contactId: contact.id,
      roleIds: contact.roles.map((r) => r.roleId),
      isPrimary: contact.isPrimary,

      beneficialOwnerPercentage: beneficialOwnerPercentage !== null && beneficialOwnerPercentage !== undefined ? beneficialOwnerPercentage : null,
      beneficiaryPercentage: beneficiaryPercentage !== null && beneficiaryPercentage !== undefined ? beneficiaryPercentage : null,
      beneficiaryRelationship,
      directorCompanyOfficerPercentage:
        directorCompanyOfficerPercentage !== null && directorCompanyOfficerPercentage !== undefined ? directorCompanyOfficerPercentage : null,
    });
  }, [setFormValues, contact]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={isOpen}
      onClose={handleCloseModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={isOpen}>
        <Paper
          elevation={0}
          style={{
            width: '800px',
            minHeight: '400px',
            maxHeight: '600px',
            padding: '40px 40px',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <Formik<EditRolesFormValues>
            initialValues={formValues}
            enableReinitialize={true}
            onSubmit={async (details: EditRolesFormValues) => {
              if (!!details.contactId) {
                const payload: EditRolesSavePayload = {
                  contactId: details.contactId,
                  isPrimary: details.isPrimary,
                  roleMappings: details.roleIds.map((id) => {
                    let percent: number | null;
                    let relationship = '';

                    switch (id) {
                      case BeneficialOwnerRoleId:
                        percent = details.beneficialOwnerPercentage;
                        break;
                      case BeneficiaryRoleId:
                        percent = details.beneficiaryPercentage;
                        relationship = details.beneficiaryRelationship;
                        break;
                      case DirectorCompanyOfficerRoleId:
                        percent = details.directorCompanyOfficerPercentage;
                        break;
                      default:
                        percent = null;
                    }
                    return {
                      roleId: id,
                      percent,
                      relationship,
                    };
                  }),
                };
                onSave(payload);
              }
            }}
            validationSchema={yup.object({
              contactId: yup.number().required(),
              roleIds: yup.array().of(yup.number()).required('At least one role is required'),

              isPrimary: yup.boolean(),
              beneficialOwnerPercentage: yup
                .number()
                .nullable()
                .when('roleIds', {
                  is: (roleIds: number[]) => roleIds.find((r) => r === BeneficialOwnerRoleId),
                  then: yup.number().max(100, 'Percentage cannot exceed 100%').required('Required'),
                }),
              beneficiaryPercentage: yup
                .number()
                .nullable()
                .when('roleIds', {
                  is: (roleIds: number[]) => roleIds.find((r) => r === BeneficiaryRoleId),
                  then: yup.number().max(100, 'Percentage cannot exceed 100%').required('Required'),
                }),
              directorCompanyOfficerPercentage: yup
                .number()
                .nullable()
                .when('roleIds', {
                  is: (roleIds: number[]) => roleIds.find((r) => r === DirectorCompanyOfficerRoleId),
                  then: yup.number().max(100, 'Percentage cannot exceed 100%').required('Required'),
                }),
            })}
          >
            {(formikProps: FormikProps<EditRolesFormValues>) => (
              <Form>
                <RoleSelector
                  label="ROLES"
                  roles={roles}
                  rolesLoadingProgress={rolesProgress}
                  contactId={contact?.id || undefined}
                  primaryContactId={primaryContactId || undefined}
                  roleIds={{ inputProps: formikProps.getFieldProps('roleIds'), helperProps: formikProps.getFieldHelpers('roleIds') }}
                  beneficialOwnerPercentage={{ inputProps: formikProps.getFieldProps('beneficialOwnerPercentage') }}
                  beneficiaryPercentage={{ inputProps: formikProps.getFieldProps('beneficiaryPercentage') }}
                  directorCompanyOfficerPercentage={{ inputProps: formikProps.getFieldProps('directorCompanyOfficerPercentage') }}
                  isPrimary={{ inputProps: formikProps.getFieldProps('isPrimary') }}
                  beneficiaryRelationship={{ inputProps: formikProps.getFieldProps('beneficiaryRelationship') }}
                  requiresAuthorisedSignatory={false}
                ></RoleSelector>
                <EditCancelSaveButtons mode={Mode.CancelSave} handleCancelClick={handleCloseModal} saveProgress={saveProgress}></EditCancelSaveButtons>
              </Form>
            )}
          </Formik>
        </Paper>
      </Fade>
    </Modal>
  );
};
