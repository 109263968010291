import { Typography } from '@mui/material';
import { Field, FieldProps } from 'formik';
import React from 'react';
import { NumberFormatValues } from 'react-number-format';
import * as yup from 'yup';
import { formatNumberCommaSeparated, formatPercentage } from '../../../../../../../common';
import {
  InlineEditBaseProps,
  InlineEditColumn,
  InlineEditColumnSet,
  InlineEditDataTable,
} from '../../../../../../../common/components/dataTable/clientSide';
import { FormikNumberFormat } from '../../../../../../../common/components/formik';
import { tieredValidations } from '../../../../../common/config/tieredValidations';
import { AdviceTieredFeeDetails } from '../../store/types';

interface Props {
  tieredValidatedError: number | null;
  isViewMode: boolean;
  isSelectedFee: boolean;
}

export type TieredEditTableProps = InlineEditBaseProps<AdviceTieredFeeDetails> & Props;

export const TieredTable = ({
  onSelectEditId,
  editId,
  progress,
  onStartAddItem,
  onSave,
  onDelete,
  items,
  tieredValidatedError,
  isViewMode,
  isSelectedFee,
}: TieredEditTableProps): JSX.Element => {
  const getNumberFormatPropsValues = (fieldName: string, isPercentageType: boolean, isFieldDisabled = false) => {
    const commonNumberFormatPropsValue = {
      isNumericString: true,
      allowNegative: false,
      decimalScale: 2,
      thousandSeparator: true,
      disabled: isFieldDisabled,
      name: fieldName,
      label: '',
      fullWidth: true,
    };

    return isPercentageType
      ? {
          ...commonNumberFormatPropsValue,
          placeholder: '0.0000%',
          suffix: '%',
          decimalScale: 4,
          isAllowed: (values: NumberFormatValues) =>
            !values.floatValue || (values.floatValue > 0 && values.floatValue <= 100),
        }
      : {
          ...commonNumberFormatPropsValue,
          placeholder: '$0.00',
          prefix: '$',
        };
  };

  const fromTemplate = (): InlineEditColumnSet => ({
    standardTemplate: function standardTemplate(dataIndex: number) {
      return <Typography variant={'h5'}>{`$${formatNumberCommaSeparated(items[dataIndex]?.from, 2)}`}</Typography>;
    },
    editTemplate: function editTemplate(dataIndex: number) {
      return (
        <Typography variant={'h5'} style={{ position: 'absolute', top: '32px' }}>{`$${formatNumberCommaSeparated(
          items[dataIndex]?.from,
          2
        )}`}</Typography>
      );
    },
  });

  const toTemplate = (): InlineEditColumnSet => ({
    standardTemplate: function standardTemplate(dataIndex: number) {
      return <Typography variant={'h5'}>{`$${formatNumberCommaSeparated(items[dataIndex]?.to, 2)}`}</Typography>;
    },
    editTemplate: function editTemplate(dataIndex: number) {
      return (
        <Field name="to" disabled label="" fullWidth value={items[dataIndex]?.to}>
          {(fieldProps: FieldProps) => {
            return (
              <FormikNumberFormat
                formikFieldProps={fieldProps}
                numberFormatProps={getNumberFormatPropsValues(fieldProps.field.name + dataIndex, false)}
                isFloatValue={true}
              />
            );
          }}
        </Field>
      );
    },
  });

  const amountTemplate = (): InlineEditColumnSet => ({
    standardTemplate: function standardTemplate(dataIndex: number) {
      return (
        <Typography variant={'h5'}>{`$${formatNumberCommaSeparated(items[dataIndex]?.amount ?? 0, 2)}`}</Typography>
      );
    },
    editTemplate: function editTemplate(dataIndex: number) {
      return (
        <Field name="amount" label="" fullWidth value={items[dataIndex]?.amount}>
          {(fieldProps: FieldProps) => {
            return (
              <FormikNumberFormat
                formikFieldProps={fieldProps}
                numberFormatProps={{
                  ...getNumberFormatPropsValues(fieldProps.field.name, false),
                  onValueChange: (val: NumberFormatValues) => {
                    if (val.floatValue !== undefined) {
                      // clear percentage value
                    }
                  },
                }}
                isFloatValue={true}
              />
            );
          }}
        </Field>
      );
    },
  });

  const percentageTemplate = (): InlineEditColumnSet => ({
    standardTemplate: function standardTemplate(dataIndex: number) {
      return (
        <Typography variant={'h5'}>
          {formatPercentage((items[dataIndex]?.percentage ?? 0) / 100, '0.0000%', 4)}
        </Typography>
      );
    },
    editTemplate: function editTemplate(dataIndex: number) {
      return (
        <Field name="percentage" label="" fullWidth value={items[dataIndex]?.percentage}>
          {(fieldProps: FieldProps) => {
            return (
              <FormikNumberFormat
                formikFieldProps={fieldProps}
                numberFormatProps={{
                  ...getNumberFormatPropsValues(fieldProps.field.name, true),
                  onValueChange: (val: NumberFormatValues) => {
                    if (val.floatValue !== undefined) {
                      // clear amount value
                    }
                  },
                }}
                isFloatValue={true}
              />
            );
          }}
        </Field>
      );
    },
  });

  const columns: InlineEditColumn[] = [
    {
      name: 'from',
      label: 'FROM',
      templates: fromTemplate(),
      verticalAlign: 'middle',
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => {
          return {
            style: { width: '22%' },
          };
        },
      },
    },
    {
      name: 'to',
      label: 'TO',
      templates: toTemplate(),
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => {
          return {
            style: { width: '22%' },
          };
        },
      },
    },
    {
      name: 'amount',
      label: 'AMOUNT ($) p.a',
      templates: amountTemplate(),
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => {
          return {
            style: { width: '20%' },
          };
        },
      },
    },
    {
      name: 'percentage',
      label: 'PERCENTAGE (%) p.a',
      templates: percentageTemplate(),
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => {
          return {
            style: { width: '18%' },
          };
        },
      },
    },
  ];

  return (
    <div
      style={{ width: '100%', minWidth: '780px', marginBottom: '40px', pointerEvents: isViewMode ? 'none' : 'auto' }}
    >
      <InlineEditDataTable<AdviceTieredFeeDetails>
        hideAllActions={true}
        hideToolBar={isSelectedFee}
        data={items}
        options={{
          filter: false,
          sort: false,
          viewColumns: false,
          pagination: true,
          rowsPerPage: 10,
        }}
        columns={columns}
        loadingProgress={progress}
        deleteOptions={{
          dialogTitle: 'Are you sure you want to delete this tiered item?',
          dialogDescription: 'This action is permanent and cannot be reverted',
        }}
        newItem={{
          feeId: 0,
          id: null,
          from:
            items.length > 0 && items[items.length - 1].to
              ? parseFloat(((items[items.length - 1].to ?? 0) + 0.01).toFixed(2))
              : 0,
          to: null,
          amount: null,
          percentage: null,
        }}
        onSave={onSave}
        onDelete={onDelete}
        canDelete={() => !isSelectedFee}
        onSelectEditId={onSelectEditId}
        onSetItemAdd={onStartAddItem}
        editId={editId}
        validationSchema={yup.object({
          from: yup.number(),
          to: yup
            .number()
            .typeError('Tier to value must be a number')
            .required('Tier to value is required')
            .moreThan(yup.ref('from') || 0, 'Tier to value must be greater than the from value'),
          amount: yup
            .number()
            .nullable()
            .test(
              'amount-percentage-fields',
              'Cannot set both dollar & percent per tier.',
              function (this: yup.TestContext): boolean {
                const percentageValue = this.parent.percentage as number;
                const amountValue = this.parent.amount as number;
                if (
                  (percentageValue === null && amountValue === null) ||
                  (percentageValue !== null && amountValue !== null)
                ) {
                  return false;
                }
                if (
                  (percentageValue !== null && amountValue === null) ||
                  (percentageValue === null && amountValue !== null)
                ) {
                  return true;
                }
                return true;
              }
            ),
          percentage: yup
            .number()
            .nullable()
            .test(
              'amount-percentage-fields',
              'Cannot set both dollar & percent per tier.',
              function (this: yup.TestContext): boolean {
                const percentageValue = this.parent.percentage as number;
                const amountValue = this.parent.amount as number;
                if (
                  (percentageValue === null && amountValue === null) ||
                  (percentageValue !== null && amountValue !== null)
                ) {
                  return false;
                }
                return true;
              }
            ),
        })}
      />
      {!!tieredValidatedError && (
        <Typography variant={'body1'} color={'error'} style={{ marginTop: '10px' }}>
          {tieredValidations.find((validation) => validation.errorType === tieredValidatedError)?.errorMessage}
        </Typography>
      )}
    </div>
  );
};
