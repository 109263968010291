import api from '../../../../../app/api';
import { buildEncodedQueryString } from '../../../../../common/utils/stringFunctions';
import {
  ApprovedProductSearchResults,
  Rebalance,
  RebalanceDeletionRequest,
  RebalanceValidationRequest,
  SecuritiesValidationRequest,
  Security,
  SecuritySearchResults,
  SecurityValidationResult,
  ValidateRebalanceSuccessPayload,
  WithdrawalDetailsRequestPayload,
} from './types';

enum rebalanceApis {
  GetPreapprovalRebalance = '/rebalancesmanual/getpreapprovalrebalance',
  GetApprovedSecuritiesByAfslId = '/approvedservices/getapprovedsecuritiesbyafslid',
  GetSecurityDetailsByIdAndAfslId = '/securities/getsecuritydetailsbyidandafslid',
  GetSecuritiesByCodeAndAfslId = '/securities/getsecuritiesbycodeandafslid',
  GetPreapprovalValidation = 'rebalancesmanual/getpreapprovalvalidation',
  GetApprovedProductSearch = '/investmentservices/GetPagedApprovedProductListByAfsl',
  Delete = 'rebalancesmanual/delete',
  GetWithdrawalDetails = 'rebalancesmanual/GetWithdrawlDetails',
}

export async function fetchRebalanceService(investmentServiceId: number, rebalanceId: string): Promise<Rebalance> {
  const queryString = buildEncodedQueryString({
    investmentserviceid: investmentServiceId,
    rebalanceid: rebalanceId,
  });
  const response = await api.get(`${rebalanceApis.GetPreapprovalRebalance}${queryString}`);
  return Promise.resolve<Rebalance>(response.data);
}

export async function fetchApprovedProductSearchService(
  afslId: number,
  subTypeId: number,
  search: string
): Promise<ApprovedProductSearchResults> {
  const payload = {
    afslId: afslId,
    subTypeId: subTypeId,
    pagedRequest: {
      pageNumber: 1,
      pageSize: 2000,
      queryFields: [
        {
          fieldName: 'code',
          hasSearchTerm: true,
          searchTerm: search,
          isSortTerm: true,
          descendingSortDirection: false,
        },
        {
          fieldName: 'name',
          hasSearchTerm: true,
          searchTerm: search,
          isSortTerm: true,
          descendingSortDirection: false,
        },
      ],
    },
  };
  const response = await api.post(`${rebalanceApis.GetApprovedProductSearch}`, payload);
  return Promise.resolve<ApprovedProductSearchResults>(response.data);
}

export async function fetchSecuritySearchService(afslId: number, search: string): Promise<SecuritySearchResults> {
  const payload = {
    afslId: afslId,
    pagedRequest: {
      pageNumber: 1,
      pageSize: 2000,
      queryFields: [
        {
          fieldName: 'securityname',
          hasSearchTerm: true,
          searchTerm: search,
          isSortTerm: true,
          descendingSortDirection: false,
        },
        {
          fieldName: 'securitycode',
          hasSearchTerm: true,
          searchTerm: search,
          isSortTerm: true,
          descendingSortDirection: false,
        },
      ],
    },
  };
  const response = await api.post(`${rebalanceApis.GetApprovedSecuritiesByAfslId}`, payload);
  return Promise.resolve<SecuritySearchResults>(response.data);
}

export async function fetchSecurityService(afslId: number, securityId: number): Promise<Security> {
  const queryString = buildEncodedQueryString({
    securityId: securityId,
    afslId: afslId,
  });
  const response = await api.get(`${rebalanceApis.GetSecurityDetailsByIdAndAfslId}${queryString}`);
  return Promise.resolve<Security>(response.data);
}

export async function validateSecuritiesService(
  securitiesValidationRequest: SecuritiesValidationRequest
): Promise<SecurityValidationResult> {
  const response = await api.post(rebalanceApis.GetSecuritiesByCodeAndAfslId, securitiesValidationRequest);
  return Promise.resolve<SecurityValidationResult>(response.data);
}

export async function validateRebalanceService(
  rebalanceValidationRequest: RebalanceValidationRequest
): Promise<ValidateRebalanceSuccessPayload> {
  const response = await api.post(rebalanceApis.GetPreapprovalValidation, rebalanceValidationRequest);
  return Promise.resolve<ValidateRebalanceSuccessPayload>({
    rebalanceValidationRequest: rebalanceValidationRequest,
    validationResult: response.data,
  });
}

export async function deleteRebalanceService(rebalanceDeletionRequest: RebalanceDeletionRequest): Promise<void> {
  await api.delete(rebalanceApis.Delete, {
    data: {
      investmentServiceRebalanceIds: rebalanceDeletionRequest.investmentServiceRebalanceIds,
      rebalanceId: rebalanceDeletionRequest.rebalanceId,
    },
  });
  return;
}

export async function fetchWithdrawalDetails(withdrawalDetailsRequestPayload: WithdrawalDetailsRequestPayload) {
  const response = await api.post(rebalanceApis.GetWithdrawalDetails, withdrawalDetailsRequestPayload);
  return Promise.resolve(response.data);
}
