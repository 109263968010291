import Cached from '@mui/icons-material/Cached';
import Download from '@mui/icons-material/Download';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Autocomplete,
  Box,
  Card,
  CardContent,
  Container,
  FormControlLabel,
  Grid,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import FileSaver from 'file-saver';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import {
  ProgressButton,
  SystemMinimumDate,
  ToggleButtonItem,
  WO2Checkbox,
  WO2ToggleButtonGroup,
  WO2ToggleSwitch,
} from '../../../../../common';
import DateRangePicker from '../../../../../common/components/DateRangePicker';
import ItemOrdering from '../../../../../common/components/ItemOrdering';
import { MdaReportTypes } from '../../../../../common/types/mdaReportType';
import { DateRange } from '../../../../../store/types/dateRange';
import { ClientPortfolio } from '../../common/store/types';
import { Props } from '../container';
import {
  GroupByPortfolioValuation,
  HidingOptions,
  Report,
  ReportAttachmentType,
  ReportNames,
  ReportToSort,
  SecondarySortByIncomeReceived,
  SortByIncomeReceived,
  SortByPortfolioValuation,
  SortByTransactions,
} from '../store/types';
import ReportingParameterSelect from './ReportingParameterSelect';
import { SaveReportingParameters } from './SaveReportingParameters';
import { UploadReportAttachment } from './UploadReportAttachment';
export const ReportsComponent = ({
  afslId,
  parameters,
  savedParametersList,
  reportGeneration,
  setDateParameter,
  setUseTriumphAsDataSourceParameter,
  setMergeReportsParameter,
  setIncludeLogoParameter,
  setCashTransactionsSelectedParameter,
  setExpensesSelectedParameter,
  setExpensesHidingOptionsParameter,
  setGainsLossesUnrealisedSelectedParameter,
  setGainsLossesRealisedSelectedParameter,
  setSecurityPerformanceSelectedParameter,
  setTransactionsSelectedParameter,
  setTransactionsAssetDescriptionHidingOptionsParameter,
  setTransactionsAccountsHidingOptionsParameter,
  setTransactionsSummaryHidingOptionsParameter,
  setTransactionsExcludeCashFilterOptionsParameter,
  setTransactionsIncludeDistributionFilterOptionsParameter,
  setTransactionsSortByParameter,
  setIncomeReceivedSelectedParameter,
  setIncomeReceivedExcludeCashFilterOptionsParameter,
  setIncomeReceivedSortByParameter,
  setIncomeReceivedSecondarySortByParameter,
  setPortfolioValuationSelectedParameter,
  setPortfolioValuationSortByParameter,
  setPortfolioValuationGroupByParameter,
  setPortfolioValuationSecurityTypeHidingOptionsParameter,
  setPortfolioValuationUnrealisedGainHidingOptionsParameter,
  setPortfolioValuationGicsHidingOptionsParameter,
  setPortfolioValuationCostBaseHidingOptionsParameter,
  setPortfolioValuationInvestmentServiceHidingOptionsParameter,
  setPortfolioValuationMarketHidingOptionsParameter,
  setPortfolioValuationAvgUnitCostHidingOptionsParameter,
  setAssetAllocationSelectedParameter,
  setAssetAllocationInvestmentProgramsParameter,
  setPortfolioValuationInvestmentProgramsParameter,
  setSelectedReportOrderParameter,
  setShowItemOderingModalParameter,
  setAsAtDateAssetAllocationParameter,
  setAsAtDateGainsLossesUnrealisedParameter,
  // cgtRecords,
  // setAsAtDateCapitalGainsAndLossesUnrealisedParameter,
  // setCapitalGainsAndLossesUnrealisedSelectedParameter,
  // setDateRangeCapitalGainsAndLossesRealisedParameter,
  // setCapitalGainsAndLossesRealisedSelectedParameter,
  // setFinalisedFinancialYearCapitalGainsAndLossesRealisedParameter,
  // setFinancialYearCapitalGainsAndLossesRealisedParameter,
  setReportDatePortfolioValuationParameter,
  setDateRangeIncomeReceivedParameter,
  setDateRangeCashTransactionsParameter,
  setDateRangeExpensesParameter,
  setDateRangeGainsLossesRealisedParameter,
  setDateRangeSecurityPerformanceParameter,
  setDateRangeTransactionsParameter,
  clientPortfolios,
  getCGTRecordsForClient,
  fetchClientAccount,
  initiateReportGeneration,
  match: { params },
  generateProgress,
  setIncludeAppendixParameter,
  setIncludeCoverLetterParameter,
  setCoverLetterParameter,
  setAppendixParameter,
  setReportGeneration,
  setReportingParameter,
  fetchReportParametersList,
  saveReportParameters,
  setSelectedReportingParameter,
  getReportParametersById,
  generateProgressReportParameters,
  isDataFromTriumph,
}: Props): JSX.Element => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [currentReportType, setCurrentReportType] = useState<ReportAttachmentType>(ReportAttachmentType.None);
  const [isSaveParametersOpen, setIsSaveParametersOpen] = useState(false);
  const [isDuplicateParameterName, setIsDuplicateParameterName] = useState(false);
  const { reportingParameter } = parameters;
  const [reportPayload, setReportPayload] = useState({} as Report);
  useEffect(() => {
    if (reportPayload?.clientId > 0) {
      setShowItemOderingModalParameter(false);
      !!reportingParameter?.isSaveReportParameters
        ? saveReportParameters(reportPayload)
        : initiateReportGeneration(reportPayload);
      setReportingParameter({
        ...reportingParameter,
        isSaveReportParameters: false,
      });
    }
  }, [reportPayload]);

  useEffect(() => {
    if (!!reportingParameter?.isSaveReportParameters) {
      handleClickGenerate();
    }
  }, [reportingParameter]);

  useEffect(() => {
    if (!parameters.selectedParameter) return;
    getReportParametersById({
      requestId: parameters.selectedParameter?.id,
      afslId: afslId ?? 0,
    });
  }, [parameters.selectedParameter]);

  const handleInvestmentProgramChange = (investmentProgram: ClientPortfolio[]) => {
    setAssetAllocationInvestmentProgramsParameter(investmentProgram);
  };

  const handlePortfolioValuationInvestmentProgramChange = (investmentProgram: ClientPortfolio[]) => {
    setPortfolioValuationInvestmentProgramsParameter(investmentProgram);
  };
  const mergeReportId = MdaReportTypes.MergeReport.id;

  const updateReportDates = (dates: DateRange) => {
    setDateParameter(dates);
  };

  const closeUploader = () => {
    setIsMenuOpen(false);
    currentReportType === ReportAttachmentType.CoverLetter
      ? setIncludeCoverLetterParameter(false)
      : setIncludeAppendixParameter(false);
  };

  const setFile = (file: File) => {
    setIsMenuOpen(false);
    currentReportType === ReportAttachmentType.CoverLetter ? setCoverLetterParameter(file) : setAppendixParameter(file);
  };

  useEffect(() => {
    const clientId = Number(params.id) ?? 0;
    setAssetAllocationInvestmentProgramsParameter([]);
    setPortfolioValuationInvestmentProgramsParameter([]);
    if (clientId > 0) {
      fetchClientAccount(clientId);
      getCGTRecordsForClient(clientId);
      if (afslId !== undefined && afslId > 0) fetchReportParametersList(afslId);
    }
  }, [fetchClientAccount, getCGTRecordsForClient, params.id, afslId]);

  const portfolioSort = (a: ClientPortfolio, b: ClientPortfolio) => {
    return a.investmentProgramId !== null && b.investmentProgramId && a.investmentProgramId < b.investmentProgramId
      ? -1
      : 1;
  };

  useEffect(() => {
    if (clientPortfolios.length === 0) {
      setAssetAllocationInvestmentProgramsParameter([]);
      setPortfolioValuationInvestmentProgramsParameter([]);
      return;
    }
    if (clientPortfolios.length > 0) {
      if (clientPortfolios.length === 1) {
        setAssetAllocationInvestmentProgramsParameter(clientPortfolios);
        return;
      }
      const sorted = clientPortfolios.sort((a, b) => portfolioSort(a, b));
      const defaultProgram: ClientPortfolio[] = [sorted[0]];
      setAssetAllocationInvestmentProgramsParameter(defaultProgram);
    }
  }, [clientPortfolios]);

  const createReportSortRequest = (isAdd: boolean, id: number, text: string) => {
    const input: ReportToSort = {
      id: id,
      text: text,
    };

    let result: ReportToSort[] = [];

    if (isAdd) {
      result = insertItem(parameters.reportsToSort, { item: input, index: parameters.reportsToSort.length });
      setSelectedReportOrderParameter(result);
    } else {
      const index = parameters.reportsToSort.findIndex((x) => x.id === input.id);
      if (index > -1) {
        result = removeItem(parameters.reportsToSort, { item: input, index: index });
        setSelectedReportOrderParameter(result);
      }
    }
  };

  const insertItem = (array: ReportToSort[], action: { item: ReportToSort; index: number }) => {
    const newArray = array.slice();
    newArray.splice(action.index, 0, action.item);
    return newArray;
  };

  const removeItem = (array: ReportToSort[], action: { item: ReportToSort; index: number }) => {
    const newArray = array.slice();
    newArray.splice(action.index, 1);
    return newArray;
  };

  const downloadFile = (reportType: ReportAttachmentType) => {
    if (reportType === ReportAttachmentType.CoverLetter) {
      if (parameters.coverLetter === undefined) return;
      FileSaver.saveAs(parameters.coverLetter, `${parameters.coverLetter?.name}`, { autoBom: false });
      return;
    }
    if (parameters.appendix === undefined) return;
    FileSaver.saveAs(parameters.appendix, `${parameters.appendix?.name}`, { autoBom: false });
  };

  useEffect(() => {
    setUseTriumphAsDataSourceParameter(!parameters.isDataFromTriumph);
  }, [isDataFromTriumph]);

  const handleClickMergeReports = () => {
    setMergeReportsParameter(!parameters.mergeReports);
  };
  const handleClickIncludeCoverLetter = () => {
    setIncludeCoverLetterParameter(!parameters.includeCoverLetter);
    setIsMenuOpen(!parameters.includeCoverLetter);
    setCurrentReportType(ReportAttachmentType.CoverLetter);
  };

  const handleClickIncludeAppendix = () => {
    setIncludeAppendixParameter(!parameters.includeAppendix);
    setIsMenuOpen(!parameters.includeAppendix);
    setCurrentReportType(ReportAttachmentType.Appendix);
  };

  const handleClickIncludeLogo = () => {
    setIncludeLogoParameter(!parameters.includeLogo);
  };

  const handleClickCashTransactionsSelect = () => {
    setCashTransactionsSelectedParameter(!parameters.selectedReports?.cashTransactions.selected);
    createReportSortRequest(
      !parameters.selectedReports?.cashTransactions.selected,
      MdaReportTypes.CashTransactions.id,
      MdaReportTypes.CashTransactions.displayName
    );
  };
  const handleClickExpensesSelect = () => {
    setExpensesSelectedParameter(!parameters.selectedReports?.expenses.selected);
    createReportSortRequest(
      !parameters.selectedReports?.expenses.selected,
      MdaReportTypes.Expenses.id,
      MdaReportTypes.Expenses.displayName
    );
  };

  const handleClickExpensesHidingOptions = () => {
    if (parameters.selectedReports?.expenses.hidingOptions === HidingOptions.selected) {
      setExpensesHidingOptionsParameter(HidingOptions.notSelected);
    } else {
      setExpensesHidingOptionsParameter(HidingOptions.selected);
    }
  };

  const handleClickGainsLossesUnrealisedSelect = () => {
    setGainsLossesUnrealisedSelectedParameter(!parameters.selectedReports?.gainsLossesUnrealised.selected);
    createReportSortRequest(
      !parameters.selectedReports?.gainsLossesUnrealised.selected,
      MdaReportTypes.GainsLossesUnrealised.id,
      MdaReportTypes.GainsLossesUnrealised.displayName
    );
  };

  const handleClickGainsLossesRealisedSelect = () => {
    setGainsLossesRealisedSelectedParameter(!parameters.selectedReports?.gainsLossesRealised.selected);
    createReportSortRequest(
      !parameters.selectedReports?.gainsLossesRealised.selected,
      MdaReportTypes.GainsLossesRealised.id,
      MdaReportTypes.GainsLossesRealised.displayName
    );
  };

  const handleClickSecurityPerformanceSelect = () => {
    setSecurityPerformanceSelectedParameter(!parameters.selectedReports?.securityPerformance.selected);
    createReportSortRequest(
      !parameters.selectedReports?.securityPerformance.selected,
      MdaReportTypes.SecurityPerformance.id,
      MdaReportTypes.SecurityPerformance.displayName
    );
  };

  const handleClickTransactionsSelect = () => {
    setTransactionsSelectedParameter(!parameters.selectedReports?.transactions.selected);
    createReportSortRequest(
      !parameters.selectedReports?.transactions.selected,
      MdaReportTypes.Transactions.id,
      MdaReportTypes.Transactions.displayName
    );
  };

  const handleClickTransactionsAssetDescriptionHidingOptions = () => {
    setTransactionsAssetDescriptionHidingOptionsParameter(
      !parameters.selectedReports?.transactions.hidingOptions.assetDescription
    );
  };

  const handleClickTransactionsAccountsHidingOptions = () => {
    setTransactionsAccountsHidingOptionsParameter(!parameters.selectedReports?.transactions.hidingOptions.accounts);
  };

  const handleClickTransactionsSummaryHidingOptions = () => {
    setTransactionsSummaryHidingOptionsParameter(!parameters.selectedReports?.transactions.hidingOptions.summary);
  };

  const handleClickTransactionsExcludeCashFilterOptions = () => {
    setTransactionsExcludeCashFilterOptionsParameter(!parameters.selectedReports?.transactions.filter.excludeCash);
    if (!parameters.selectedReports?.transactions.filter.excludeCash) {
      setTransactionsIncludeDistributionFilterOptionsParameter(
        !parameters.selectedReports?.transactions.filter.includeDistribution
      );
    }
    setTransactionsIncludeDistributionFilterOptionsParameter(false);
  };

  const handleClickTransactionsIncludeDistributionFilterOptions = () => {
    if (parameters.selectedReports?.transactions.filter.excludeCash) {
      setTransactionsIncludeDistributionFilterOptionsParameter(
        !parameters.selectedReports?.transactions.filter.includeDistribution
      );
    }
  };

  const handleClickIncomeReceivedSelect = () => {
    setIncomeReceivedSelectedParameter(!parameters.selectedReports?.incomeReceived.selected);
    createReportSortRequest(
      !parameters.selectedReports?.incomeReceived.selected,
      MdaReportTypes.IncomeReceived.id,
      MdaReportTypes.IncomeReceived.displayName
    );
  };

  const handleClickIncomeReceivedExcludeCashFilterOptions = () => {
    setIncomeReceivedExcludeCashFilterOptionsParameter(!parameters.selectedReports?.incomeReceived.filter.excludeCash);
  };

  const handleClickPortfolioValuationSelect = () => {
    setPortfolioValuationSelectedParameter(!parameters.selectedReports?.portfolioValuation.selected);
    createReportSortRequest(
      !parameters.selectedReports?.portfolioValuation.selected,
      MdaReportTypes.PortfolioValuation.id,
      MdaReportTypes.PortfolioValuation.displayName
    );
  };

  const handleClickPortfolioValuationSecurityTypeHidingOptions = () => {
    setPortfolioValuationSecurityTypeHidingOptionsParameter(
      !parameters.selectedReports?.portfolioValuation.hidingOptions.securityType
    );
  };

  const handleClickPortfolioValuationUnrealisedGainHidingOptions = () => {
    setPortfolioValuationUnrealisedGainHidingOptionsParameter(
      !parameters.selectedReports?.portfolioValuation.hidingOptions.unrealisedGain
    );
  };

  const handleClickPortfolioValuationAvgUnitCostHidingOptions = () => {
    setPortfolioValuationAvgUnitCostHidingOptionsParameter(
      !parameters.selectedReports?.portfolioValuation.hidingOptions.avgUnitCost
    );
  };
  const handleClickPortfolioValuationGicsHidingOptions = () => {
    setPortfolioValuationGicsHidingOptionsParameter(!parameters.selectedReports?.portfolioValuation.hidingOptions.gics);
  };

  const handleClickPortfolioValuationCostBaseHidingOptions = () => {
    setPortfolioValuationCostBaseHidingOptionsParameter(
      !parameters.selectedReports?.portfolioValuation.hidingOptions.costBase
    );
  };

  const handleClickPortfolioValuationInvestmentServiceHidingOptions = () => {
    setPortfolioValuationInvestmentServiceHidingOptionsParameter(
      !parameters.selectedReports?.portfolioValuation.hidingOptions.investmentService
    );
  };

  const handleClickPortfolioValuationMarketHidingOptions = () => {
    setPortfolioValuationMarketHidingOptionsParameter(
      !parameters.selectedReports?.portfolioValuation.hidingOptions.market
    );
  };

  const handleClickAssetAllocationSelect = () => {
    setAssetAllocationSelectedParameter(!parameters.selectedReports?.assetAllocation.selected);
    createReportSortRequest(
      !parameters.selectedReports?.assetAllocation.selected,
      MdaReportTypes.AssetAllocation.id,
      MdaReportTypes.AssetAllocation.displayName
    );
  };

  const handleClickDateForAssetAllocation = (dates: DateRange) => {
    setAsAtDateAssetAllocationParameter(dates);
  };

  const handleClickDateForGainsLossesUnrealised = (dates: DateRange) => {
    setAsAtDateGainsLossesUnrealisedParameter(dates);
  };
  // const handleClickCapitalGainsAndLossesUnrealisedSelect = (selected: boolean) => {
  //   setCapitalGainsAndLossesUnrealisedSelectedParameter(selected);
  //   createReportSortRequest(
  //     !parameters.selectedReports?.capitalGainsAndLossesUnrealised.selected,
  //     MdaReportTypes.CapitalGainsAndLossesUnrealised.id,
  //     MdaReportTypes.CapitalGainsAndLossesUnrealised.displayName
  //   );
  // };

  // const handleClickDateCapitalGainsAndLossesUnrealised = (dates: DateRange) => {
  //   setAsAtDateCapitalGainsAndLossesUnrealisedParameter(dates);
  // };

  // const handleClickDateRangeCapitalGainsAndLossesRealisedSelect = (dates: DateRange) => {
  //   setDateRangeCapitalGainsAndLossesRealisedParameter(dates);
  // };

  // const handleClickCapitalGainsAndLossesRealisedSelect = (selected: boolean) => {
  //   setCapitalGainsAndLossesRealisedSelectedParameter(selected);
  //   createReportSortRequest(
  //     !parameters.selectedReports?.capitalGainsAndLossesRealised.selected,
  //     MdaReportTypes.CapitalGainsAndLossesRealised.id,
  //     MdaReportTypes.CapitalGainsAndLossesRealised.displayName
  //   );
  // };

  // const handleClickFinalisedFinancialYearCapitalGainsAndLossesRealised = (selected: boolean): void | undefined => {
  //   setFinalisedFinancialYearCapitalGainsAndLossesRealisedParameter(selected);
  // };

  // const handleClickFinancialYearCapitalGainsAndLossesRealised = (yearCode: string) => {
  //   setFinancialYearCapitalGainsAndLossesRealisedParameter(yearCode);
  // };

  const handleClickDateForPortfolioValuation = (dates: DateRange) => {
    setReportDatePortfolioValuationParameter(dates);
  };

  const handleClickReportDateIncomeReceived = (dates: DateRange) => {
    setDateRangeIncomeReceivedParameter(dates);
  };

  const handleClickReportDateCashTransactions = (dates: DateRange) => {
    setDateRangeCashTransactionsParameter(dates);
  };

  const handleClickReportDateExpenses = (dates: DateRange) => {
    setDateRangeExpensesParameter(dates);
  };

  const handleClickReportDateGainsLossesRealised = (dates: DateRange) => {
    setDateRangeGainsLossesRealisedParameter(dates);
  };

  const handleClickReportDateSecurityPerformance = (dates: DateRange) => {
    setDateRangeSecurityPerformanceParameter(dates);
  };

  const handleClickReportDateTransactions = (dates: DateRange) => {
    setDateRangeTransactionsParameter(dates);
  };

  const handleClickGenerate = () => {
    if (parameters.mergeReports && parameters.reportsToSort.length > 1) {
      setShowItemOderingModalParameter(true);
    } else {
      setShowItemOderingModalParameter(false);
      generateReportParameters();
    }
  };

  const getFromDate = (reportSpecificDate: string, topLevelDate: string) => {
    return getDate(reportSpecificDate, topLevelDate, DateTime.fromISO(SystemMinimumDate).toISODate());
  };

  const getToDate = (reportSpecificDate: string, topLevelDate: string) => {
    return getDate(reportSpecificDate, topLevelDate, DateTime.now().toISODate());
  };

  const getDate = (reportSpecificDate: string, topLevelDate: string, defaultDate: string) => {
    return defaultDate === reportSpecificDate ? topLevelDate : reportSpecificDate;
  };

  const generateReportParameters = () => {
    setReportPayload({} as Report);

    let fromDate = '';
    let toDate = '';
    let asAtDate = '';
    let reportName = '';
    setShowItemOderingModalParameter(false);
    const payload: Report = {
      isDataFromTriumph: isDataFromTriumph,
      clientName: parameters.clientName,
      clientId: Number(params.id) ?? 0,
      fromDate: parameters.dates.dateFrom,
      toDate: parameters.dates.dateTo,
      dateRangeType: getDateRangeType(parameters.dates.dateRangeType, false),
      includeLogo: parameters.includeLogo,
      visibleToAfsl: parameters.reportingParameter?.visibleToAfsl,
      parameterName: parameters.reportingParameter?.parameterName,
      mergeReportOrder: [],
      cashTransactions: undefined,
      gainsLossesUnrealised: undefined,
      gainsLossesRealised: undefined,
      capitalGainsAndLossesRealised: undefined,
      capitalGainsAndLossesUnrealised: undefined,
      securityPerformance: undefined,
      expenses: undefined,
      transactions: undefined,
      incomeReceived: undefined,
      portfolioValuation: undefined,
      assetAllocation: undefined,
      includeCoverLetter: parameters.includeCoverLetter,
      includeAppendix: parameters.includeAppendix,
      coverLetter: parameters.coverLetter,
      appendix: parameters.appendix,
    };

    if (parameters.mergeReports && parameters.reportsToSort.length > 1) {
      payload.mergeReportOrder = [mergeReportId].concat(parameters.reportsToSort.map((report) => report.id));
    } else if (!parameters.mergeReports && parameters.reportsToSort.length == 1) {
      payload.mergeReportOrder = [mergeReportId].concat(parameters.reportsToSort.map((report) => report.id));
    } else if (parameters.mergeReports && parameters.reportsToSort.length == 1) {
      payload.mergeReportOrder = [mergeReportId].concat(parameters.reportsToSort.map((report) => report.id));
      handleClickMergeReports();
    }

    if (parameters.selectedReports.expenses.selected) {
      payload.expenses = {
        hidingOptions: parameters.selectedReports.expenses.hidingOptions,
        fromDate: getFromDate(parameters.selectedReports.expenses.dates.dateFrom, payload.fromDate),
        toDate: getToDate(parameters.selectedReports.expenses.dates.dateTo, payload.toDate),
        dateRangeType: getDateRangeType(parameters.selectedReports.expenses.dates.dateRangeType, false),
      };
      fromDate = payload.expenses?.fromDate;
      toDate = payload.expenses?.toDate;
      reportName = ReportNames.Expenses;
    }

    if (parameters.selectedReports.transactions.selected) {
      let hidingOptions = '';
      let filterOptions = '';
      if (parameters.selectedReports.transactions.hidingOptions.assetDescription) {
        if (hidingOptions === '') {
          hidingOptions = hidingOptions + 'AssetDescription';
        } else {
          hidingOptions = hidingOptions + '|AssetDescription';
        }
      }
      if (parameters.selectedReports.transactions.hidingOptions.accounts) {
        if (hidingOptions === '') {
          hidingOptions = hidingOptions + 'Accounts';
        } else {
          hidingOptions = hidingOptions + '|Accounts';
        }
      }
      if (parameters.selectedReports.transactions.hidingOptions.summary) {
        if (hidingOptions === '') {
          hidingOptions = hidingOptions + 'Summary';
        } else {
          hidingOptions = hidingOptions + '|Summary';
        }
      }
      if (parameters.selectedReports.transactions.filter.excludeCash) {
        if (filterOptions === '') {
          filterOptions = filterOptions + 'ExcludeCash';
        } else {
          filterOptions = filterOptions + '|ExcludeCash';
        }
      }
      if (parameters.selectedReports.transactions.filter.includeDistribution) {
        if (filterOptions === '') {
          filterOptions = filterOptions + 'IncludeDistribution';
        } else {
          filterOptions = filterOptions + '|IncludeDistribution';
        }
      }
      payload.transactions = {
        hidingOptions: hidingOptions,
        filteringOptions: filterOptions,
        sortBy: parameters.selectedReports.transactions.sortBy,
        fromDate: getFromDate(parameters.selectedReports.transactions.dates.dateFrom, payload.fromDate),
        toDate: getToDate(parameters.selectedReports.transactions.dates.dateTo, payload.toDate),
        dateRangeType: getDateRangeType(parameters.selectedReports.transactions.dates.dateRangeType, false),
      };
      fromDate = payload.transactions?.fromDate;
      toDate = payload.transactions?.toDate;
      reportName = ReportNames.Transactions;
    }

    if (parameters.selectedReports.incomeReceived.selected) {
      payload.incomeReceived = {
        filteringOptions: parameters.selectedReports.incomeReceived.filter.excludeCash ? 'ExcludeCash' : '',
        sortBy: parameters.selectedReports.incomeReceived.sortBy,
        thenSortBy: parameters.selectedReports.incomeReceived.secondarySortBy,
        fromDate: getFromDate(parameters.selectedReports.incomeReceived.dates.dateFrom, payload.fromDate),
        toDate: getToDate(parameters.selectedReports.incomeReceived.dates.dateTo, payload.toDate),
        dateRangeType: getDateRangeType(parameters.selectedReports.incomeReceived.dates.dateRangeType, false),
      };
      fromDate = payload.incomeReceived?.fromDate;
      toDate = payload.incomeReceived?.toDate;
      reportName = ReportNames.IncomeReceived;
    }

    if (parameters.selectedReports.portfolioValuation.selected) {
      let hidingOptions = '';

      if (parameters.selectedReports.portfolioValuation.hidingOptions.securityType) {
        if (hidingOptions === '') {
          hidingOptions = hidingOptions + 'SecurityType';
        } else {
          hidingOptions = hidingOptions + '|SecurityType';
        }
      }
      if (parameters.selectedReports.portfolioValuation.hidingOptions.securityCode) {
        if (hidingOptions === '') {
          hidingOptions = hidingOptions + 'SecurityCode';
        } else {
          hidingOptions = hidingOptions + '|SecurityCode';
        }
      }
      if (parameters.selectedReports.portfolioValuation.hidingOptions.unrealisedGain) {
        if (hidingOptions === '') {
          hidingOptions = hidingOptions + 'UnrealisedGain';
        } else {
          hidingOptions = hidingOptions + '|UnrealisedGain';
        }
      }
      if (parameters.selectedReports.portfolioValuation.hidingOptions.gics) {
        if (hidingOptions === '') {
          hidingOptions = hidingOptions + 'Gics';
        } else {
          hidingOptions = hidingOptions + '|Gics';
        }
      }
      if (parameters.selectedReports.portfolioValuation.hidingOptions.costBase) {
        if (hidingOptions === '') {
          hidingOptions = hidingOptions + 'CostBase';
        } else {
          hidingOptions = hidingOptions + '|CostBase';
        }
      }
      if (parameters.selectedReports.portfolioValuation.hidingOptions.investmentService) {
        if (hidingOptions === '') {
          hidingOptions = hidingOptions + 'InvestmentService';
        } else {
          hidingOptions = hidingOptions + '|InvestmentService';
        }
      }
      if (parameters.selectedReports.portfolioValuation.hidingOptions.market) {
        if (hidingOptions === '') {
          hidingOptions = hidingOptions + 'Market';
        } else {
          hidingOptions = hidingOptions + '|Market';
        }
      }
      if (parameters.selectedReports.portfolioValuation.hidingOptions.securityName) {
        if (hidingOptions === '') {
          hidingOptions = hidingOptions + 'SecurityName';
        } else {
          hidingOptions = hidingOptions + '|SecurityName';
        }
      }
      if (parameters.selectedReports.portfolioValuation.hidingOptions.avgUnitCost) {
        if (hidingOptions === '') {
          hidingOptions = hidingOptions + 'AvgUnitCost';
        } else {
          hidingOptions = hidingOptions + '|AvgUnitCost';
        }
      }

      const investmentProgramIds: number[] = [];
      if (parameters.selectedReports.portfolioValuation.portfolioIds) {
        parameters.selectedReports.portfolioValuation.portfolioIds.forEach((id) => {
          if (id && id.investmentProgramId) {
            investmentProgramIds.push(id.investmentProgramId);
          }
        });
      }

      payload.portfolioValuation = {
        hidingOptions: hidingOptions,
        sortBy: parameters.selectedReports.portfolioValuation.sortBy,
        groupBy: parameters.selectedReports.portfolioValuation.groupBy,
        asAtDate: getToDate(parameters.selectedReports.portfolioValuation.dates.dateTo, payload.toDate),
        asAtDateType: getDateRangeType(parameters.selectedReports.portfolioValuation.dates.dateRangeType, true),
        investmentProgramIds: investmentProgramIds,
      };

      asAtDate = payload.portfolioValuation?.asAtDate;
      reportName = ReportNames.PortfolioValuation;
    }

    if (parameters.selectedReports.assetAllocation.selected) {
      if (parameters.selectedReports.assetAllocation.investmentPrograms) {
        const investmentProgramIds: number[] = [];
        parameters.selectedReports.assetAllocation.investmentPrograms.forEach((id) => {
          if (id.investmentProgramId) {
            investmentProgramIds.push(id.investmentProgramId);
          }
        });

        payload.assetAllocation = {
          investmentProgramIds: investmentProgramIds,
          asAtDate: getToDate(parameters.selectedReports.assetAllocation.dates.dateTo, payload.toDate),
          asAtDateType: getDateRangeType(parameters.selectedReports.assetAllocation.dates.dateRangeType, true),
        };
      }
      asAtDate = payload.assetAllocation?.asAtDate !== undefined ? payload.assetAllocation?.asAtDate : '';
      reportName = ReportNames.AssetAllocation;
    }

    if (parameters.selectedReports.cashTransactions.selected) {
      payload.cashTransactions = {
        fromDate: getFromDate(parameters.selectedReports.cashTransactions.dates.dateFrom, payload.fromDate),
        toDate: getToDate(parameters.selectedReports.cashTransactions.dates.dateTo, payload.toDate),
        dateRangeType: getDateRangeType(parameters.selectedReports.cashTransactions.dates.dateRangeType, false),
      };
      fromDate = payload.cashTransactions?.fromDate;
      toDate = payload.cashTransactions?.toDate;
      reportName = ReportNames.CashTransactions;
    }

    if (parameters.selectedReports.gainsLossesRealised.selected) {
      payload.gainsLossesRealised = {
        fromDate: getFromDate(parameters.selectedReports.gainsLossesRealised.dates.dateFrom, payload.fromDate),
        toDate: getToDate(parameters.selectedReports.gainsLossesRealised.dates.dateTo, payload.toDate),
        dateRangeType: getDateRangeType(parameters.selectedReports.gainsLossesRealised.dates.dateRangeType, false),
      };
      fromDate = payload.gainsLossesRealised?.fromDate;
      toDate = payload.gainsLossesRealised?.toDate;
      reportName = ReportNames.GainsLossesRealised;
    }

    // if (parameters.selectedReports.capitalGainsAndLossesRealised.selected) {
    //   payload.capitalGainsAndLossesRealised = {
    //     fromDate: getFromDate(
    //       parameters.selectedReports.capitalGainsAndLossesRealised.dates.dateFrom,
    //       payload.fromDate
    //     ),
    //     toDate: getToDate(parameters.selectedReports.capitalGainsAndLossesRealised.dates.dateTo, payload.toDate),
    //     dateRangeType: getDateRangeType(
    //       parameters.selectedReports.capitalGainsAndLossesRealised.dates.dateRangeType,
    //       false
    //     ),
    //     finaliseFinancialYear: parameters.selectedReports.capitalGainsAndLossesRealised.finaliseFinancialYear,
    //     financialYearCode: parameters.selectedReports.capitalGainsAndLossesRealised.financialYearCode,
    //   };
    //   fromDate = payload.capitalGainsAndLossesRealised?.fromDate;
    //   toDate = payload.capitalGainsAndLossesRealised?.toDate;
    //   reportName = ReportNames.CapitalGainsAndLossesRealised;
    // }

    if (parameters.selectedReports.gainsLossesUnrealised.selected) {
      payload.gainsLossesUnrealised = {
        asAtDate: getToDate(parameters.selectedReports.gainsLossesUnrealised.dates.dateTo, payload.toDate),
        asAtDateType: getDateRangeType(parameters.selectedReports.gainsLossesUnrealised.dates.dateRangeType, true),
      };
      asAtDate = payload.gainsLossesUnrealised?.asAtDate;
      reportName = ReportNames.GainsLossesUnrealised;
    }

    // if (parameters.selectedReports.capitalGainsAndLossesUnrealised.selected) {
    //   payload.capitalGainsAndLossesUnrealised = {
    //     asAtDate: getToDate(parameters.selectedReports.capitalGainsAndLossesUnrealised.dates.dateTo, payload.toDate),
    //     asAtDateType: getDateRangeType(
    //       parameters.selectedReports.capitalGainsAndLossesUnrealised.dates.dateRangeType,
    //       true
    //     ),
    //   };
    //   asAtDate = payload.capitalGainsAndLossesUnrealised?.asAtDate;
    //   reportName = ReportNames.CapitalGainsAndLossesUnrealised;
    // }

    if (parameters.selectedReports.securityPerformance.selected) {
      payload.securityPerformance = {
        fromDate: getFromDate(parameters.selectedReports.securityPerformance.dates.dateFrom, payload.fromDate),
        toDate: getToDate(parameters.selectedReports.securityPerformance.dates.dateTo, payload.toDate),
        dateRangeType: getDateRangeType(parameters.selectedReports.securityPerformance.dates.dateRangeType, false),
      };
      fromDate = payload.securityPerformance?.fromDate;
      toDate = payload.securityPerformance?.toDate;
      reportName = ReportNames.SecurityPerformance;
    }
    let dateRange = `${fromDate}_${toDate}`;
    if (asAtDate !== '') dateRange = asAtDate;
    setReportGeneration({
      ...reportGeneration,
      isDataFromTriumph: payload.isDataFromTriumph,
      isMergeReport: payload.mergeReportOrder.findIndex((x) => x == MdaReportTypes.MergeReport.id) > -1,
      clientId: payload.clientId,
      clientName: payload.clientName,
      mergeReportOrder: payload.mergeReportOrder,
      dateRange: dateRange,
      reportName: reportName,
    });
    setReportPayload(payload);
  };

  let generateButtonDisabled = true;
  let saveReportParametersButtonDisabled = true;
  if (
    parameters.selectedReports.cashTransactions.selected ||
    parameters.selectedReports.expenses.selected ||
    parameters.selectedReports.gainsLossesUnrealised.selected ||
    parameters.selectedReports.gainsLossesRealised.selected ||
    // parameters.selectedReports.capitalGainsAndLossesRealised.selected ||
    // parameters.selectedReports.capitalGainsAndLossesUnrealised.selected ||
    parameters.selectedReports.securityPerformance.selected ||
    parameters.selectedReports.transactions.selected ||
    parameters.selectedReports.incomeReceived.selected ||
    parameters.selectedReports.portfolioValuation.selected
  ) {
    saveReportParametersButtonDisabled = false;
    generateButtonDisabled = false;
  }
  if (parameters.selectedReports.assetAllocation.selected) {
    saveReportParametersButtonDisabled = false;
    if (parameters.selectedReports.assetAllocation.investmentPrograms.length) {
      generateButtonDisabled = false;
    }
  }

  const getDateRangeType = (dateRangeType: string | null | undefined, isDateOnly: boolean) => {
    const defaultText = `Pre Defined ${isDateOnly ? 'Date' : 'Dates'}`;
    if (dateRangeType === null || dateRangeType === undefined || dateRangeType === '') return defaultText;
    return dateRangeType;
  };
  const sortByTransactionsToggleButtons: ToggleButtonItem<SortByTransactions>[] = [
    { name: 'Account', value: SortByTransactions.account },
    { name: 'Trade Date', value: SortByTransactions.tradeDate },
    { name: 'Security', value: SortByTransactions.security },
  ];

  const sortByIncomeReceivedToggleButtons: ToggleButtonItem<SortByIncomeReceived>[] = [
    {
      name: 'Account',
      value: SortByIncomeReceived.AccountFullInformation,
    },
    { name: 'Payment Date', value: SortByIncomeReceived.PaymentDate },
    { name: 'Income Type', value: SortByIncomeReceived.IncomeType },
  ];

  const secondarySortByIncomeReceivedToggleButtons: ToggleButtonItem<SecondarySortByIncomeReceived>[] = [
    {
      name: 'Date Ascending',
      value: SecondarySortByIncomeReceived.DateAscending,
    },
    {
      name: 'Date Descending',
      value: SecondarySortByIncomeReceived.DateDescending,
    },
  ];

  const setTransactionSortByParameter = (e: SortByTransactions) => {
    setTransactionsSortByParameter(e);
  };

  const handleSaveReportingParameters = (parameterName: string, visibleToAfsl: boolean) => {
    const isDuplicate = checkDuplicateParameterName(parameterName);
    if (!!isDuplicate) {
      setIsDuplicateParameterName(true);
      return;
    }
    setIsDuplicateParameterName(false);
    setIsSaveParametersOpen(false);
    setReportingParameter({
      isSaveReportParameters: true,
      parameterName: parameterName,
      visibleToAfsl: visibleToAfsl,
    });
  };

  const checkDuplicateParameterName = (parameterName: string) => {
    return (
      savedParametersList.filter((item) => item.name?.toLowerCase().trim() === parameterName.toLocaleLowerCase().trim())
        .length > 0
    );
  };

  const sortByPortfolioValuationToggleButtons: ToggleButtonItem<SortByPortfolioValuation>[] = [
    { name: 'Security Type', value: SortByPortfolioValuation.SecurityType },
    { name: 'Security Name', value: SortByPortfolioValuation.SecurityName },
    { name: 'GICS', value: SortByPortfolioValuation.Gics },
    { name: 'Portfolio', value: SortByPortfolioValuation.Portfolio },
    { name: 'Asset Class', value: SortByPortfolioValuation.AssetClass },
  ];

  const groupByPortfolioValuationToggleButtons: ToggleButtonItem<GroupByPortfolioValuation>[] = [
    { name: 'Asset Class', value: GroupByPortfolioValuation.AssetClass },
    { name: 'GICS', value: GroupByPortfolioValuation.Gics },
    {
      name: 'Portfolio',
      value: GroupByPortfolioValuation.InvestmentService,
    },
    { name: 'Model', value: GroupByPortfolioValuation.Model },
  ];

  return (
    <Container style={{ paddingTop: '20px' }}>
      <ItemOrdering
        itemsToSort={parameters.reportsToSort}
        setSelectedReport={setSelectedReportOrderParameter}
        setShowItemOrderingModal={setShowItemOderingModalParameter}
        generateReport={generateReportParameters}
        isOpen={parameters.showItemOrdering}
      />
      <Box display={'flex'} justifyContent={'space-between'}>
        <Box paddingRight={'10px'}>
          <Typography variant="h2" style={{ paddingLeft: '10px' }}>
            Reports
          </Typography>
        </Box>
        <Box display={'flex'} justifyContent={'space-between'}>
          {reportGeneration.isGenerating && <Alert severity="success">Report in progress</Alert>}
        </Box>
        <Box display={'flex'} justifyContent={'space-between'}>
          <Box paddingRight={'10px'} paddingTop={'15px'}>
            <Typography>Load From </Typography>
          </Box>
          <Box paddingRight={'10px'}>
            <ReportingParameterSelect
              items={savedParametersList}
              selectedParameter={parameters?.selectedParameter ?? null}
              setParameter={setSelectedReportingParameter}
            />
          </Box>
          <Box paddingRight={'10px'}>
            <ProgressButton
              color={'primary'}
              style={{ borderRadius: 24, height: 48 }}
              disableElevation
              disabled={saveReportParametersButtonDisabled}
              progress={generateProgressReportParameters}
              onClick={() => setIsSaveParametersOpen(true)}
              variant={'contained'}
              value="SaveReportingParameters"
            >
              Save Reporting Parameters
            </ProgressButton>
          </Box>
          <Box paddingRight={'10px'}>
            <ProgressButton
              progress={generateProgress}
              startIcon={Cached}
              disabled={generateButtonDisabled}
              style={{ borderRadius: 24, height: 48, width: 132 }}
              color={'primary'}
              onClick={() => {
                setReportingParameter({
                  ...reportingParameter,
                  isSaveReportParameters: false,
                });
                handleClickGenerate();
              }}
              variant="contained"
              id="search_button"
            >
              Generate
            </ProgressButton>
          </Box>
        </Box>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Card elevation={0}>
            <Grid container>
              <Typography variant="h3" align="left" style={{ padding: '10px' }}>
                Report Parameters
              </Typography>
            </Grid>
            <CardContent>
              <DateRangePicker
                dateRange={parameters?.dates}
                setDateRange={updateReportDates}
                inceptionDate={parameters.inceptionDate}
              />
              <Grid container style={{ paddingTop: '15px' }}>
                <WO2ToggleSwitch
                  checked={parameters.mergeReports}
                  onChange={handleClickMergeReports}
                  color="primary"
                  name="mergeReports"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
                <Typography variant="h4" style={{ paddingTop: '10px' }}>
                  Merge Reports
                </Typography>
              </Grid>
              <Grid container style={{ paddingTop: '10px' }}>
                <WO2ToggleSwitch
                  checked={parameters.includeLogo}
                  onChange={handleClickIncludeLogo}
                  color="primary"
                  name="includeLogo"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />{' '}
                <Typography variant="h4" style={{ paddingTop: '10px' }}>
                  Include Logo
                </Typography>
              </Grid>
              <Grid container style={{ paddingTop: '10px' }}>
                <WO2ToggleSwitch
                  checked={parameters.includeCoverLetter}
                  onChange={handleClickIncludeCoverLetter}
                  color="primary"
                  name="includeCoverPage"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />{' '}
                <Typography variant="h4" style={{ paddingTop: '10px' }}>
                  Cover Page
                </Typography>
                {parameters.includeCoverLetter && parameters.coverLetter !== undefined && (
                  <Tooltip
                    title="Download"
                    style={{ marginTop: '10px' }}
                    onClick={() => {
                      downloadFile(ReportAttachmentType.CoverLetter);
                    }}
                  >
                    <Download></Download>
                  </Tooltip>
                )}
              </Grid>
              <Grid container style={{ paddingTop: '10px' }}>
                <WO2ToggleSwitch
                  checked={parameters.includeAppendix}
                  onChange={handleClickIncludeAppendix}
                  color="primary"
                  name="includeAppendix"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />{' '}
                <Typography variant="h4" style={{ paddingTop: '10px' }}>
                  Appendix
                </Typography>
                {parameters.includeAppendix && parameters.appendix !== undefined && (
                  <Tooltip
                    title="Download"
                    style={{ marginTop: '10px' }}
                    onClick={() => {
                      downloadFile(ReportAttachmentType.Appendix);
                    }}
                  >
                    <Download></Download>
                  </Tooltip>
                )}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={9}>
          <Accordion elevation={0}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <FormControlLabel
                aria-label="Acknowledge"
                onClick={(event) => event.stopPropagation()}
                onFocus={(event) => event.stopPropagation()}
                control={
                  <WO2Checkbox
                    color="primary"
                    checked={parameters.selectedReports?.assetAllocation.selected}
                    onChangeHandler={handleClickAssetAllocationSelect}
                  />
                }
                label="Asset Allocation"
              />
            </AccordionSummary>

            <AccordionDetails>
              <DateRangePicker
                dateRange={parameters?.selectedReports.assetAllocation?.dates}
                setDateRange={handleClickDateForAssetAllocation}
                inceptionDate={'2022-09-01'}
                isDateOnly={true}
              />
              <Typography>from - {parameters?.selectedReports.assetAllocation?.dates.dateFrom}</Typography>
              <Typography>to - {parameters?.selectedReports.assetAllocation?.dates.dateTo}</Typography>
              <Autocomplete
                noOptionsText="No items to select"
                id="investmentservice-multiselect"
                multiple
                fullWidth
                value={parameters.selectedReports.assetAllocation.investmentPrograms}
                options={clientPortfolios}
                getOptionLabel={(option: ClientPortfolio) => option.investmentServiceName}
                onChange={(_e: React.SyntheticEvent<Element, Event>, value: ClientPortfolio[]) =>
                  handleInvestmentProgramChange(value)
                }
                renderInput={(params) => (
                  <TextField {...params} label="Select a Portfolio" margin="normal" variant="outlined" fullWidth />
                )}
                isOptionEqualToValue={(option: ClientPortfolio, value: ClientPortfolio) =>
                  option.investmentProgramId === value.investmentProgramId
                }
              />
            </AccordionDetails>
          </Accordion>

          <Accordion elevation={0}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <FormControlLabel
                aria-label="Acknowledge"
                onClick={(event) => event.stopPropagation()}
                onFocus={(event) => event.stopPropagation()}
                control={
                  <WO2Checkbox
                    color="primary"
                    checked={parameters.selectedReports?.cashTransactions.selected}
                    onChangeHandler={handleClickCashTransactionsSelect}
                  />
                }
                label="Cash Transactions"
              />
            </AccordionSummary>
            <AccordionDetails>
              <DateRangePicker
                dateRange={parameters?.selectedReports.cashTransactions?.dates}
                setDateRange={handleClickReportDateCashTransactions}
                inceptionDate={parameters.inceptionDate}
              />
              <Typography color="textSecondary">Cash Transactions</Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion elevation={0}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <FormControlLabel
                aria-label="Acknowledge"
                onClick={(event) => event.stopPropagation()}
                onFocus={(event) => event.stopPropagation()}
                control={
                  <WO2Checkbox
                    color="primary"
                    checked={parameters.selectedReports?.expenses.selected}
                    onChangeHandler={handleClickExpensesSelect}
                  />
                }
                label="Expenses"
              />
            </AccordionSummary>
            <AccordionDetails>
              <DateRangePicker
                dateRange={parameters?.selectedReports?.expenses?.dates}
                setDateRange={handleClickReportDateExpenses}
                inceptionDate={parameters.inceptionDate}
              />
              <Typography color="textSecondary">OPTIONS</Typography>
              <Grid container style={{ paddingTop: '10px' }}>
                <WO2ToggleSwitch
                  checked={parameters.selectedReports.expenses.hidingOptions === HidingOptions.selected ? true : false}
                  onChange={handleClickExpensesHidingOptions}
                  color="primary"
                  name="hidingOptions"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />{' '}
                <Typography variant="h4" style={{ paddingTop: '10px' }}>
                  Exclude Expense Type
                </Typography>
              </Grid>
            </AccordionDetails>
          </Accordion>

          <Accordion elevation={0}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <FormControlLabel
                aria-label="Acknowledge"
                onClick={(event) => event.stopPropagation()}
                onFocus={(event) => event.stopPropagation()}
                control={
                  <WO2Checkbox
                    color="primary"
                    checked={parameters.selectedReports?.gainsLossesRealised.selected}
                    onChangeHandler={handleClickGainsLossesRealisedSelect}
                  />
                }
                label="Gains and Losses (Realised)"
              />
            </AccordionSummary>
            <AccordionDetails>
              <DateRangePicker
                dateRange={parameters?.selectedReports.gainsLossesRealised?.dates}
                setDateRange={handleClickReportDateGainsLossesRealised}
                inceptionDate={parameters.inceptionDate}
              />
              <Typography color="textSecondary">Gains and Losses (Realised)</Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion elevation={0}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <FormControlLabel
                aria-label="Acknowledge"
                onClick={(event) => event.stopPropagation()}
                onFocus={(event) => event.stopPropagation()}
                control={
                  <WO2Checkbox
                    color="primary"
                    checked={parameters.selectedReports?.gainsLossesUnrealised.selected}
                    onChangeHandler={handleClickGainsLossesUnrealisedSelect}
                  />
                }
                label="Gains and Losses (Unrealised)"
              />
            </AccordionSummary>
            <AccordionDetails>
              <DateRangePicker
                dateRange={parameters?.selectedReports.gainsLossesUnrealised.dates}
                setDateRange={handleClickDateForGainsLossesUnrealised}
                isDateOnly={true}
              />
              <Typography color="textSecondary">Gains and Losses (Unrealised)</Typography>
            </AccordionDetails>
          </Accordion>

          {/* <Accordion elevation={0}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <FormControlLabel
                aria-label="Acknowledge"
                onClick={(event) => event.stopPropagation()}
                onFocus={(event) => event.stopPropagation()}
                control={
                  <WO2Checkbox
                    color="primary"
                    checked={parameters.selectedReports?.capitalGainsAndLossesRealised.selected}
                    onChangeHandler={handleClickCapitalGainsAndLossesRealisedSelect}
                  />
                }
                label="Capital Gains and Losses (Realised)"
              />
            </AccordionSummary>
            <AccordionDetails>
              <DateRangePicker
                dateRange={parameters?.selectedReports.capitalGainsAndLossesRealised?.dates}
                setDateRange={handleClickDateRangeCapitalGainsAndLossesRealisedSelect}
                inceptionDate={parameters.inceptionDate}
              />
              <Typography color="textSecondary">Capital Gains and Losses (Realised)</Typography>
              <br />
              <Typography color="textSecondary">Financial Year</Typography>
              <FormControl>
                <WO2Select
                  style={{ position: 'absolute', width: '200px' }}
                  value={
                    cgtRecords && cgtRecords.length > 0
                      ? parameters?.selectedReports.capitalGainsAndLossesRealised.financialYearCode ?? ''
                      : ''
                  }
                  displayEmpty
                  IconComponent={ExpandMoreIcon}
                  onChange={(event) =>
                    event.target.value === ''
                      ? handleClickFinancialYearCapitalGainsAndLossesRealised('')
                      : handleClickFinancialYearCapitalGainsAndLossesRealised(String(event.target.value))
                  }
                >
                  {cgtRecords.map((record: CGTRecord) => (
                    <MenuItem key={record.fyDescription} value={record.fyDescription}>
                      {record.fyDescription}
                    </MenuItem>
                  ))}
                </WO2Select>
              </FormControl>
              <br />
              <br />
              <FormControl>
                <FormControlLabel
                  aria-label="Acknowledge"
                  name="finaliseFinancialYear"
                  onClick={(event) => event.stopPropagation()}
                  onFocus={(event) => event.stopPropagation()}
                  control={
                    <WO2Checkbox
                      color="primary"
                      checked={parameters.selectedReports.capitalGainsAndLossesRealised.finaliseFinancialYear}
                      onChangeHandler={handleClickFinalisedFinancialYearCapitalGainsAndLossesRealised}
                    />
                  }
                  label="Finalise Financial Year"
                />
              </FormControl>
            </AccordionDetails>
          </Accordion> */}

          {/* <Accordion elevation={0}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <FormControlLabel
                aria-label="Acknowledge"
                onClick={(event) => event.stopPropagation()}
                onFocus={(event) => event.stopPropagation()}
                control={
                  <WO2Checkbox
                    color="primary"
                    checked={parameters.selectedReports?.capitalGainsAndLossesUnrealised.selected}
                    onChangeHandler={handleClickCapitalGainsAndLossesUnrealisedSelect}
                  />
                }
                label="Capital Gains and Losses (Unrealised)"
              />
            </AccordionSummary>
            <AccordionDetails>
              <DateRangePicker
                dateRange={parameters?.selectedReports.capitalGainsAndLossesUnrealised.dates}
                setDateRange={handleClickDateCapitalGainsAndLossesUnrealised}
                isDateOnly={true}
              />
              <Typography color="textSecondary">Gains and Losses (Unrealised)</Typography>
            </AccordionDetails>
          </Accordion> */}

          <Accordion elevation={0}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <FormControlLabel
                aria-label="Acknowledge"
                onClick={(event) => event.stopPropagation()}
                onFocus={(event) => event.stopPropagation()}
                control={
                  <WO2Checkbox
                    color="primary"
                    checked={parameters.selectedReports?.incomeReceived.selected}
                    onChangeHandler={handleClickIncomeReceivedSelect}
                  />
                }
                label="Income Received"
              />
            </AccordionSummary>
            <AccordionDetails>
              <DateRangePicker
                dateRange={parameters?.selectedReports.incomeReceived.dates}
                setDateRange={handleClickReportDateIncomeReceived}
                inceptionDate={parameters.inceptionDate}
              />
              <Typography color="textSecondary">Primary sort by</Typography>
              <WO2ToggleButtonGroup<SortByIncomeReceived>
                fullWidth
                buttons={sortByIncomeReceivedToggleButtons}
                value={parameters.selectedReports.incomeReceived.sortBy}
                onChangeHandler={(e) => setIncomeReceivedSortByParameter(e)}
                style={{ paddingBottom: '10px' }}
              />
              <Typography color="textSecondary">Secondary sort by</Typography>
              <WO2ToggleButtonGroup<SecondarySortByIncomeReceived>
                fullWidth
                buttons={secondarySortByIncomeReceivedToggleButtons}
                value={parameters.selectedReports.incomeReceived.secondarySortBy}
                onChangeHandler={(e) => setIncomeReceivedSecondarySortByParameter(e)}
                style={{ paddingBottom: '10px' }}
              />
              <Typography color="textSecondary">OPTIONS</Typography>
              <Grid container style={{ paddingTop: '10px' }}>
                <Grid container item xs={4}>
                  <WO2ToggleSwitch
                    checked={parameters.selectedReports.incomeReceived.filter.excludeCash}
                    onChange={handleClickIncomeReceivedExcludeCashFilterOptions}
                    color="primary"
                    name="hidingOptions"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />{' '}
                  <Typography variant="h4" style={{ paddingTop: '10px' }}>
                    Exclude Cash
                  </Typography>
                </Grid>
                <Grid container item xs={4}></Grid>
                <Grid container item xs={4}></Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>

          <Accordion elevation={0}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <FormControlLabel
                aria-label="Acknowledge"
                onClick={(event) => event.stopPropagation()}
                onFocus={(event) => event.stopPropagation()}
                control={
                  <WO2Checkbox
                    color="primary"
                    checked={parameters.selectedReports?.portfolioValuation.selected}
                    onChangeHandler={handleClickPortfolioValuationSelect}
                  />
                }
                label="Portfolio Valuation"
              />
            </AccordionSummary>
            <AccordionDetails>
              <DateRangePicker
                dateRange={parameters?.selectedReports.portfolioValuation.dates}
                setDateRange={handleClickDateForPortfolioValuation}
                isDateOnly={true}
              />
              <Typography color="textSecondary">Sort by</Typography>
              <WO2ToggleButtonGroup<SortByPortfolioValuation>
                buttons={sortByPortfolioValuationToggleButtons}
                value={parameters.selectedReports.portfolioValuation.sortBy}
                onChangeHandler={(e) => setPortfolioValuationSortByParameter(e)}
                style={{ paddingBottom: '10px' }}
                fullWidth
              />
              <Typography color="textSecondary">Group by</Typography>
              <WO2ToggleButtonGroup<GroupByPortfolioValuation>
                buttons={groupByPortfolioValuationToggleButtons}
                value={parameters.selectedReports.portfolioValuation.groupBy}
                onChangeHandler={(e) => setPortfolioValuationGroupByParameter(e)}
                style={{ paddingBottom: '10px' }}
                fullWidth
              />
              <Typography color="textSecondary">OPTIONS</Typography>
              <Grid container style={{ paddingTop: '10px' }}>
                <Grid container item xs={4}>
                  <WO2ToggleSwitch
                    checked={parameters.selectedReports.portfolioValuation.hidingOptions.costBase}
                    onChange={handleClickPortfolioValuationCostBaseHidingOptions}
                    color="primary"
                    name="hidingOptions"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />{' '}
                  <Typography variant="h4" style={{ paddingTop: '10px' }}>
                    Exclude Cost Base
                  </Typography>
                </Grid>
                <Grid container item xs={4}>
                  <WO2ToggleSwitch
                    checked={parameters.selectedReports.portfolioValuation.hidingOptions.market}
                    onChange={handleClickPortfolioValuationMarketHidingOptions}
                    color="primary"
                    name="hidingOptions"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />{' '}
                  <Typography variant="h4" style={{ paddingTop: '10px' }}>
                    Exclude Market
                  </Typography>
                </Grid>
                <Grid container item xs={4}>
                  <WO2ToggleSwitch
                    checked={parameters.selectedReports.portfolioValuation.hidingOptions.securityType}
                    onChange={handleClickPortfolioValuationSecurityTypeHidingOptions}
                    color="primary"
                    name="hidingOptions"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />{' '}
                  <Typography variant="h4" style={{ paddingTop: '10px' }}>
                    Exclude Security Type
                  </Typography>
                </Grid>
              </Grid>

              <Grid container style={{ paddingTop: '10px' }}>
                <Grid container item xs={4}>
                  <WO2ToggleSwitch
                    checked={parameters.selectedReports.portfolioValuation.hidingOptions.gics}
                    onChange={handleClickPortfolioValuationGicsHidingOptions}
                    color="primary"
                    name="hidingOptions"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    disabled={
                      parameters.selectedReports.portfolioValuation.sortBy === SortByPortfolioValuation.Gics ||
                      parameters.selectedReports.portfolioValuation.groupBy === GroupByPortfolioValuation.Gics
                    }
                  />{' '}
                  <Typography variant="h4" style={{ paddingTop: '10px' }}>
                    Exclude GICS
                  </Typography>
                </Grid>
                <Grid container item xs={4}>
                  <WO2ToggleSwitch
                    checked={parameters.selectedReports.portfolioValuation.hidingOptions.unrealisedGain}
                    onChange={handleClickPortfolioValuationUnrealisedGainHidingOptions}
                    color="primary"
                    name="hidingOptions"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />{' '}
                  <Typography variant="h4" style={{ paddingTop: '10px' }}>
                    Exclude Gains/Losses
                  </Typography>
                </Grid>
                <Grid container item xs={4}>
                  <WO2ToggleSwitch
                    checked={parameters.selectedReports.portfolioValuation.hidingOptions.investmentService}
                    onChange={handleClickPortfolioValuationInvestmentServiceHidingOptions}
                    color="primary"
                    name="hidingOptions"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    disabled={
                      parameters.selectedReports.portfolioValuation.sortBy === SortByPortfolioValuation.Portfolio ||
                      parameters.selectedReports.portfolioValuation.groupBy ===
                        GroupByPortfolioValuation.InvestmentService
                    }
                  />{' '}
                  <Typography variant="h4" style={{ paddingTop: '10px' }}>
                    Exclude Portfolio
                  </Typography>
                </Grid>
              </Grid>
              <Grid container style={{ paddingTop: '10px' }}>
                <Grid container item xs={4}>
                  <WO2ToggleSwitch
                    checked={parameters.selectedReports.portfolioValuation.hidingOptions.avgUnitCost}
                    onChange={handleClickPortfolioValuationAvgUnitCostHidingOptions}
                    color="primary"
                    name="hidingOptions"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />{' '}
                  <Typography variant="h4" style={{ paddingTop: '10px' }}>
                    Exclude Avg. Unit Cost
                  </Typography>
                </Grid>
              </Grid>
            </AccordionDetails>
            <Autocomplete
              noOptionsText="No items to select"
              id="portfoliovaluation-investmentservice-multiselect"
              multiple
              fullWidth
              value={parameters.selectedReports.portfolioValuation.portfolioIds}
              options={clientPortfolios}
              getOptionLabel={(option: ClientPortfolio) => option.investmentServiceName}
              onChange={(_e: React.SyntheticEvent<Element, Event>, value: ClientPortfolio[]) =>
                handlePortfolioValuationInvestmentProgramChange(value)
              }
              renderInput={(params) => (
                <TextField {...params} label="Select a Portfolio" margin="normal" variant="outlined" fullWidth />
              )}
              isOptionEqualToValue={(option: ClientPortfolio, value: ClientPortfolio) =>
                option.investmentProgramId === value.investmentProgramId
              }
            />
          </Accordion>

          <Accordion elevation={0}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <FormControlLabel
                aria-label="Acknowledge"
                onClick={(event) => event.stopPropagation()}
                onFocus={(event) => event.stopPropagation()}
                control={
                  <WO2Checkbox
                    color="primary"
                    checked={parameters.selectedReports?.securityPerformance.selected}
                    onChangeHandler={handleClickSecurityPerformanceSelect}
                  />
                }
                label="Security Performance"
              />
            </AccordionSummary>
            <AccordionDetails>
              <DateRangePicker
                dateRange={parameters?.selectedReports.securityPerformance?.dates}
                setDateRange={handleClickReportDateSecurityPerformance}
                inceptionDate={parameters.inceptionDate}
              />
              <Typography color="textSecondary">Security Performance</Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion elevation={0}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <FormControlLabel
                aria-label="Acknowledge"
                onClick={(event) => event.stopPropagation()}
                onFocus={(event) => event.stopPropagation()}
                control={
                  <WO2Checkbox
                    color="primary"
                    checked={parameters.selectedReports?.transactions.selected}
                    onChangeHandler={handleClickTransactionsSelect}
                  />
                }
                label="Transactions"
              />
            </AccordionSummary>
            <AccordionDetails>
              <DateRangePicker
                dateRange={parameters?.selectedReports.transactions?.dates}
                setDateRange={handleClickReportDateTransactions}
                inceptionDate={parameters.inceptionDate}
              />
              <Typography color="textSecondary">SORT BY</Typography>
              <WO2ToggleButtonGroup<SortByTransactions>
                buttons={sortByTransactionsToggleButtons}
                value={parameters.selectedReports.transactions.sortBy}
                onChangeHandler={(e) => setTransactionSortByParameter(e)}
                style={{ paddingBottom: '10px ' }}
                fullWidth
              />
              <Typography color="textSecondary">OPTIONS</Typography>
              <Grid container style={{ paddingTop: '10px' }}>
                <Grid container item xs={4}>
                  <WO2ToggleSwitch
                    checked={parameters.selectedReports.transactions.filter.excludeCash}
                    onChange={handleClickTransactionsExcludeCashFilterOptions}
                    color="primary"
                    name="hidingOptions"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />{' '}
                  <Typography variant="h4" style={{ paddingTop: '10px' }}>
                    Exclude Cash
                  </Typography>
                </Grid>
                <Grid container item xs={4}>
                  <WO2ToggleSwitch
                    checked={parameters.selectedReports.transactions.filter.includeDistribution}
                    onChange={handleClickTransactionsIncludeDistributionFilterOptions}
                    color="primary"
                    name="hidingOptions"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />{' '}
                  <Typography variant="h4" style={{ paddingTop: '10px' }}>
                    Include Distribution
                  </Typography>
                </Grid>
                <Grid container item xs={4}></Grid>
              </Grid>

              <Grid container style={{ paddingTop: '10px' }}>
                <Grid container item xs={4}>
                  <WO2ToggleSwitch
                    checked={parameters.selectedReports.transactions.hidingOptions.assetDescription}
                    onChange={handleClickTransactionsAssetDescriptionHidingOptions}
                    color="primary"
                    name="hidingOptions"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />{' '}
                  <Typography variant="h4" style={{ paddingTop: '10px' }}>
                    Exclude Asset Description
                  </Typography>
                </Grid>
                <Grid container item xs={4}>
                  <WO2ToggleSwitch
                    checked={parameters.selectedReports.transactions.hidingOptions.accounts}
                    onChange={handleClickTransactionsAccountsHidingOptions}
                    color="primary"
                    name="hidingOptions"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    disabled={parameters.selectedReports.transactions.sortBy === SortByTransactions.account}
                  />{' '}
                  <Typography variant="h4" style={{ paddingTop: '10px' }}>
                    Exclude Accounts
                  </Typography>
                </Grid>
                <Grid container item xs={4}>
                  <WO2ToggleSwitch
                    checked={parameters.selectedReports.transactions.hidingOptions.summary}
                    onChange={handleClickTransactionsSummaryHidingOptions}
                    color="primary"
                    name="hidingOptions"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />{' '}
                  <Typography variant="h4" style={{ paddingTop: '10px' }}>
                    Exclude Summary
                  </Typography>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
      <UploadReportAttachment
        isOpen={isMenuOpen}
        handleImport={setFile}
        handleClose={closeUploader}
        title={`Upload ${currentReportType}`}
      />
      <SaveReportingParameters
        isOpen={isSaveParametersOpen}
        handleSave={handleSaveReportingParameters}
        handleClose={() => {
          setIsSaveParametersOpen(false);
        }}
        isDuplicate={isDuplicateParameterName}
      />
    </Container>
  );
};
