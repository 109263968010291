import { Grid } from '@mui/material';
import { Field } from 'formik';
import React from 'react';
import { ToggleButtonItem } from 'src/common';
import { FormikToggleButton } from 'src/common/components/formik';
import { PensionType } from 'src/common/types';

export const PensionForm = (): JSX.Element => {
  const pensionTypeToggleButtons: ToggleButtonItem<number>[] = PensionType.getArray().map((pensionType: PensionType) => ({
    name: pensionType.displayName,
    value: pensionType.id,
  }));

  return (
    <>
      <Grid container style={{ marginTop: '24px' }}>
        <Grid item xs={12}>
          <Field component={FormikToggleButton} exclusive={true} buttons={pensionTypeToggleButtons} name="pensionTypeId" label="What is the pension type?" />
        </Grid>
      </Grid>
    </>
  );
};
