import { connect, ConnectedProps } from 'react-redux';
import { progressSelectorFactory } from 'src/common';
import { RootState } from 'src/reducers';
import { selectClientId } from '../../../common/store';
import { selectSuperMemberNumber } from '../store/selectors';
import { PensionDetailStatement } from './components/pensionDetailStatement';
import { selectPensionBankAccountDetails, selectPensionDetail } from './store/selectors';
import { pensionDetailSlice } from './store/slice';
import { fetchPensionBankDetailByClientId, fetchPensionDetailByAccountId, PensionDetailActionTypes } from './store/thunks';

const mapStateToProps = (state: RootState) => ({
  clientId: selectClientId(state),
  accountId: selectSuperMemberNumber(state),
  pensionDetails: selectPensionDetail(state),
  pensionBankDetails: selectPensionBankAccountDetails(state),
  loadingProgressPensionDetails: progressSelectorFactory([PensionDetailActionTypes.FetchPensionDetailByAccountId])(state),
  loadingProgressPensionBankDetails: progressSelectorFactory([PensionDetailActionTypes.FetchPensionDetailByAccountId])(state),
});

const mapDispatchToProps = {
  ...pensionDetailSlice.actions,
  fetchPensionDetailByAccountId,
  fetchPensionBankDetailByClientId,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector>;
export const PensionDetailsContainer = connector(PensionDetailStatement);
