import AddOutlined from '@mui/icons-material/Add';
import AssessmentIcon from '@mui/icons-material/Assessment';
import AssignmentIcon from '@mui/icons-material/Assignment';
import DonutLargeIcon from '@mui/icons-material/DonutLarge';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import TollIcon from '@mui/icons-material/Toll';
import ViewQuiltIcon from '@mui/icons-material/ViewQuilt';
import WidgetsIcon from '@mui/icons-material/Widgets';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import React from 'react';
import history from '../../../../history';
import { theme } from '../../../../themes';
import { MoneysoftService } from './MoneysoftService';
import { menuRoutes } from './routes';

const leftMenu = (locationPath: string, hasClientEditAdminOnlyPermission: boolean, hasMoneysoft: boolean): React.ReactNode => {
  const listItemStyle = {
    paddingTop: '10px',
    paddingBottom: '10px',
  };
  const selectedListItemStyle = {
    ...listItemStyle,
    backgroundColor: '#F1F5FF',
    borderRight: `8px solid ${theme.palette.primary.main}`,
  };
  const isRouteSelected = (url?: string): boolean => {
    if (!url || !locationPath) {
      return false;
    }

    const routePaths = url.split('/');
    const currentRoutePaths = locationPath.split('/');

    return routePaths && routePaths[1].toLowerCase() === currentRoutePaths[1].toLowerCase();
  };

  const MenuItem = ({ icon, text, url, onClick }: { icon: JSX.Element; text: string; url?: string; onClick?: () => void }): JSX.Element => {
    const isSelected = isRouteSelected(url);
    const removeSpaceRegex = /\s/g;
    const id = text.replace(removeSpaceRegex, '_').toLowerCase() + '_left_menu';

    return (
      <ListItem
        button
        id={id}
        style={isSelected ? selectedListItemStyle : listItemStyle}
        onClick={() => {
          if (!!onClick) {
            onClick();
          } else {
            !!url && history.push(url);
          }
        }}
        data-testid={id}
      >
        <ListItemIcon style={{ paddingLeft: '10px' }} className={isSelected ? 'IsSelected' : ''}>
          {icon}
        </ListItemIcon>
        <ListItemText primary={text} style={{ color: isSelected ? '#4306fa' : 'inherit' }} />
      </ListItem>
    );
  };
  const getIconColor = (url: string) => (url.toLowerCase() === locationPath.toLowerCase() ? 'inherit' : 'disabled');

  return (
    <>
      {process.env.REACT_APP_NONPROD_ENV === 'true' && (
        <>
          <MenuItem icon={<AddOutlined color="disabled" />} text="Add new client" data-testid="leftMenuItem_AddNewClient" />
          <MenuItem icon={<ViewQuiltIcon color="disabled" />} text="Dashboard" data-testid="leftMenuItem_Dashboard" />
        </>
      )}
      <MenuItem icon={<PersonIcon color={getIconColor(menuRoutes.clients)} />} text="Clients" url={menuRoutes.clients} data-testid="leftMenuItem_Clients" />

      {hasClientEditAdminOnlyPermission && (
        <MenuItem
          icon={<LibraryBooksIcon color={getIconColor(menuRoutes.templates)} />}
          text="Templates"
          url={menuRoutes.templates}
          data-testid="leftMenuItem_Templates"
        />
      )}

      <MenuItem
        icon={<DonutLargeIcon color={getIconColor(menuRoutes.holdings)} />}
        text="Holdings"
        url={menuRoutes.holdings}
        data-testid="leftMenuItem_Holdings"
      />
      <MenuItem icon={<TollIcon color={getIconColor(menuRoutes.model)} />} text="Models" url="/model/list" data-testid="leftMenuItem_Model" />
      <MenuItem
        icon={<AssessmentIcon color={getIconColor(menuRoutes.security)} />}
        text="Securities"
        url={menuRoutes.security}
        data-testid="leftMenuItem_Security"
      />

      <MenuItem
        icon={<WidgetsIcon color={getIconColor(menuRoutes.bulkFunctions)} />}
        text="Bulk Functions"
        url={menuRoutes.bulkFunctions}
        data-testid="leftMenuItem_BulkFunctions"
      />

      <MenuItem
        icon={<AssignmentIcon color={getIconColor(menuRoutes.workflow)} />}
        text="Tasks"
        url={menuRoutes.workflow}
        data-testid="leftMenuItem_Workflow"
      />

      {process.env.REACT_APP_NONPROD_ENV === 'true' && <MenuItem icon={<SettingsIcon color="disabled" />} text="Admin" data-testid="leftMenuItem_Admin" />}

      {hasMoneysoft && (
        <MenuItem
          icon={<ExitToAppIcon color={getIconColor('')} />}
          text="Moneysoft"
          data-testid="leftMenuItem_Moneysoft"
          onClick={async () => {
            const url = await MoneysoftService.GetMoneysoftUrl();
            if (!!url) {
              window.open(url, '_blank');
            }
          }}
        />
      )}
    </>
  );
};

export default (locationPath: string, hasClientEditAdminOnlyPermission: boolean, hasMoneysoft: boolean): React.ReactNode =>
  leftMenu(locationPath, hasClientEditAdminOnlyPermission, hasMoneysoft);
