import { FeeCalculationType } from '../enums';
import { EstimatedFeeValidation } from '../types';

export const estimatedFeeValidations: EstimatedFeeValidation[] = [
  {
    calculationTypeId: FeeCalculationType.OngoingAdviceFee.id,
    maxPercentage: 2.2,
    errorMessage: 'The total estimated ongoing advice fee per annum must not exceed 2.2% of the total estimated rollover and annual contribution.',
  },
  {
    calculationTypeId: FeeCalculationType.UpfrontAdviceFee.id,
    maxPercentage: 4.4,
    errorMessage: 'The total estimated upfront advice fee must not exceed 4.4% of the total estimated rollover.',
  },
  {
    calculationTypeId: FeeCalculationType.PortfolioManagementFee.id,
    maxPercentage: 1.65,
    errorMessage: 'The total estimated portfolio management fee must not exceed 1.65% of the total estimated rollover and annual contribution.',
  },
];
