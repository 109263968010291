import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../../app/api';
import { buildEncodedQueryString } from '../../../../common';
import {
  DailyPerformanceChart,
  FetchDailyPerformanceChartPayload,
  FetchMonthlyPerformanceChartPayload,
  FetchPeriodicSummaryPayload,
  FetchStrategyDashboardPayload,
  FetchStrategyDetailsPayload,
  MonthlyPerformanceChart,
  PeriodicSummary,
  StrategyDashboard,
  StrategyDetails,
} from './types';

export enum StrategyPerformanceActionTypes {
  FetchStrategyDashboard = '​@@strategy/GetStrategyDashboard',
  FetchStrategyDetails = '​@@strategy/GetStrategyDetails',
  FetchDailyPerformanceChart = '​@@strategy/GetDailyPerformanceChart',
  FetchMonthlyPerformanceChart = '​@@strategy/GetMonthlyPerformanceChart',
  FetchPeriodicSummary = '​@@strategy/GetPeriodicSummary',
}

export enum StrategyPerformanceApiEndpoints {
  fetchStrategyDashboard = '/bff/GetStrategyDashboard',
  fetchStrategyDetails = '/bff/GetStrategyDetails',
  fetchDailyPerformanceChart = '/performances/strategy/GetDailyPerformanceChart',
  fetchMonthlyPerformanceChart = '/performances/strategy/GetMonthlyPerformanceChart',
  fetchPeriodicSummary = '/performances/strategy/GetPeriodicSummary',
}

export const fetchStrategyDashboard = createAsyncThunk(
  StrategyPerformanceActionTypes.FetchStrategyDashboard,
  async (wrapper: FetchStrategyDashboardPayload) => {
    const response = await api.get<StrategyDashboard>(`${StrategyPerformanceApiEndpoints.fetchStrategyDashboard}?strategyId=${wrapper.strategyId}`);
    return response.data;
  }
);

export const fetchStrategyDetails = createAsyncThunk(StrategyPerformanceActionTypes.FetchStrategyDetails, async (wrapper: FetchStrategyDetailsPayload) => {
  const response = await api.get<StrategyDetails>(`${StrategyPerformanceApiEndpoints.fetchStrategyDetails}?strategyId=${wrapper.strategyId}`);
  return response.data;
});

export const fetchDailyPerformanceChart = createAsyncThunk(
  StrategyPerformanceActionTypes.FetchDailyPerformanceChart,
  async (wrapper: FetchDailyPerformanceChartPayload) => {
    const response = await api.get<DailyPerformanceChart>(
      `${StrategyPerformanceApiEndpoints.fetchDailyPerformanceChart}?strategyId=${wrapper.strategyId}&fromDate=${wrapper.fromDate}&toDate=${wrapper.toDate}`
    );
    return response.data;
  }
);

export const fetchMonthlyPerformanceChart = createAsyncThunk(
  StrategyPerformanceActionTypes.FetchMonthlyPerformanceChart,
  async (wrapper: FetchMonthlyPerformanceChartPayload) => {
    const response = await api.get<MonthlyPerformanceChart>(
      `${StrategyPerformanceApiEndpoints.fetchMonthlyPerformanceChart}?strategyId=${wrapper.strategyId}&fromDate=${wrapper.fromDate}&toDate=${wrapper.toDate}`
    );
    return response.data;
  }
);

export const fetchPeriodicSummary = createAsyncThunk(StrategyPerformanceActionTypes.FetchPeriodicSummary, async (wrapper: FetchPeriodicSummaryPayload) => {
  const queryString = buildEncodedQueryString({
    strategyId: wrapper.strategyId,
    benchmarkStrategyId: wrapper.benchmarkStrategyId,
    endDate: wrapper.endDate,
  });
  const response = await api.get<PeriodicSummary>(`${StrategyPerformanceApiEndpoints.fetchPeriodicSummary}${queryString}`);
  return response.data;
});
