import { Link as AttachContactIcon } from '@mui/icons-material';
import { Box, Chip, Grid, IconButton, Typography } from '@mui/material';
import { Field, Form, Formik, FormikProps } from 'formik';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { LoadingIndicator } from 'src/common';
import {
  FormikEnumerationSelect,
  FormikKeyboardDatePicker,
  FormikTextField,
  yupValidateAbn,
  yupValidateAcn,
  yupValidateTfn,
} from 'src/common/components/formik';
import { useDebounce } from 'src/common/hooks';
import { ClientAccountType, Title } from 'src/common/types';
import { AttachContact, AttachContactDialogPayload } from 'src/features/clients/client/common/components/contacts';
import { TfnExemptions } from 'src/features/clients/common/enums';
import * as yup from 'yup';
import { PageBackNextButtons } from '../../components/common/pageBackNextButtons';
import { AdditionalInformationValues } from '../../store/types';
import { Props } from '../container';
import { AccountTypeHeader } from './accountTypeHeader';
import { useStyles } from './additionalInfo.styles';

export const AdditionalInfo = (props: Props): JSX.Element => {
  const {
    id,
    history,
    accountTypeValues,
    prevNextRoutePaths,
    additionalInformationValues,
    setAdditionalInformationValues,
    saveAdditionalInfoValues,
    afslAuthorisation,
    contactsToAttach,
    loadingContactsToAttach,
    fetchContactsToAttach,
    fetchExistingContact,
    clearAttachedContact,
    loadingExistingContactProgress,
    saveProgress,
  } = props;

  const classes = useStyles();

  const [initialId] = useState<string | null>(id);

  const [contactSearchText, setContactSearchText] = useState<string>('');
  const onContactSearchTextChange = useDebounce<string>(contactSearchText, 500);

  const [attachContactIndex, setAttachContactIndex] = useState<number | undefined>();
  const [attachContactDialogOpen, setAttachContactDialogOpen] = useState<boolean>(false);

  useEffect(() => {
    if (initialId !== id) {
      // must have saved a new client via next button - navigate next
      history.push(prevNextRoutePaths.nextRoutePath + `?id=${id}`);
    }
  }, [initialId, id, prevNextRoutePaths, history]);

  useEffect(() => {
    if (onContactSearchTextChange && onContactSearchTextChange.length > 2) {
      fetchContactsToAttach({ adviserId: accountTypeValues?.advisor?.advisorId || undefined, searchText: onContactSearchTextChange });
    }
  }, [onContactSearchTextChange, fetchContactsToAttach]);

  const initialFormValues: AdditionalInformationValues = {
    contact: {
      titleId: null,
      firstName: '',
      middleName: '',
      lastName: '',
      dateOfBirth: null,
    },
    additionalContact: {
      titleId: null,
      firstName: '',
      middleName: '',
      lastName: '',
      dateOfBirth: null,
    },
    clientAccount: {
      name: '',
      abn: '',
      tfnExemptionId: null,
      tfn: '',
      acn: '',
    },
    superSimplifierDetails: { pensionTypeId: null },
  };

  const { accountTypeId } = accountTypeValues.clientAccount;

  const [formValues, setFormValues] = useState<AdditionalInformationValues>(initialFormValues);

  useEffect(() => {
    setFormValues({
      contact: additionalInformationValues.contact,
      additionalContact: additionalInformationValues.additionalContact,
      clientAccount: additionalInformationValues.clientAccount,
      superSimplifierDetails: {
        pensionTypeId: additionalInformationValues.superSimplifierDetails?.pensionTypeId,
      },
    });
  }, [additionalInformationValues, afslAuthorisation, setFormValues]);

  const onBackButtonClick = async (additionalInfo: AdditionalInformationValues) => {
    const { prevRoutePath } = prevNextRoutePaths;
    if (!!id) {
      // persist to backend
      await saveAdditionalInfoValues(additionalInfo);
      history.push(prevRoutePath + `?id=${id}`);
    } else {
      // just save to state - not backend
      await setAdditionalInformationValues(additionalInfo);
      if (prevRoutePath) {
        history.push(prevRoutePath);
      }
    }
  };

  const onAttachContact = (payload: AttachContactDialogPayload) => {
    if (attachContactIndex !== undefined && !!accountTypeValues.advisor?.advisorId) {
      fetchExistingContact({ index: attachContactIndex, contactId: payload.contactId, adviserId: accountTypeValues.advisor.advisorId, roles: [] }).then(() =>
        setAttachContactIndex(undefined)
      );
      setAttachContactDialogOpen(false);
    }
  };

  const onFetchContactsToAttach = (searchText: string) => {
    setContactSearchText(searchText);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <div className={classes.additionalInfo}>
          <Box>
            <Box style={{ paddingBottom: '10px', marginBottom: '30px' }}>
              <Typography variant="h7">Let us know about additional information relating to this account.</Typography>
            </Box>
            <Box>
              <Formik<AdditionalInformationValues>
                enableReinitialize={true}
                initialValues={formValues}
                onSubmit={async (additionalInfo) => {
                  await saveAdditionalInfoValues(additionalInfo);
                  if (!!id) {
                    history.push(prevNextRoutePaths.nextRoutePath + `?id=${id}`);
                  }
                }}
                validationSchema={yup.object({
                  contact: yup.object({
                    titleId: yup.number().nullable().required('Title is required'),
                    firstName: yup.string().required('First name is required'),
                    middleName: yup.string().nullable(),
                    lastName: yup.string().required('Last name is required'),
                    dateOfBirth: yup
                      .date()
                      .required('Date of birth is required')
                      .nullable()
                      .typeError('Date of birth must be a valid date')
                      .max(DateTime.now().toISODate(), 'Date of birth cannot be in the future'),
                  }),
                  additionalContact:
                    accountTypeId === ClientAccountType.Joint.id
                      ? yup.object({
                          titleId: yup.number().nullable().required('Title is required'),
                          firstName: yup.string().required('First name is required'),
                          middleName: yup.string().nullable(),
                          lastName: yup.string().required('Last name is required'),
                          dateOfBirth: yup
                            .date()
                            .required('Date of birth is required')
                            .nullable()
                            .typeError('Date of birth must be a valid date')
                            .max(DateTime.now().toISODate(), 'Date of birth cannot be in the future'),
                        })
                      : yup.object(),
                  clientAccount: yup.object({
                    name: yup.string().required('Entity name is required'),
                    tfn: yup
                      .string()
                      .nullable()
                      .test('test-tfn', 'Not a valid Tax File Number', function (this: yup.TestContext, tfn: string | null | undefined): boolean {
                        if (this.parent.tfnExemptionId) {
                          return (!!tfn && TfnExemptions.isValidExemptionCode(tfn)) || yupValidateTfn(tfn);
                        }

                        return !tfn || yupValidateTfn(tfn);
                      }),
                    tfnExemptionId: yup.number().nullable(),
                    abn: yup.string().nullable().test('test-abn', 'Not a valid ABN', yupValidateAbn),
                    acn: yup
                      .string()
                      .nullable()
                      .test('test-acn', 'Not a valid ACN', function (this: yup.TestContext, acn: string | null | undefined): boolean {
                        return !acn || accountTypeValues.clientAccount.accountTypeId !== ClientAccountType.Company.id || yupValidateAcn(acn);
                      }),
                  }),
                })}
              >
                {(formikProps: FormikProps<AdditionalInformationValues>) => (
                  <Form onSubmit={formikProps.handleSubmit}>
                    <Box className={'accountInfoSelect'}>
                      <AccountTypeHeader accountTypeValues={accountTypeValues}></AccountTypeHeader>
                      <fieldset>
                        <Grid container style={{ width: '390px' }}>
                          <Typography variant={'h8'}>Primary contact</Typography>
                          <LoadingIndicator progress={loadingExistingContactProgress}>
                            <Grid className={'selectPrimaryContact'}>
                              <Typography variant={'h4'}>
                                Use existing contact
                                <IconButton
                                  type="button"
                                  data-testid="attachContactButton"
                                  onClick={() => {
                                    setAttachContactIndex(0);
                                    setAttachContactDialogOpen(true);
                                  }}
                                >
                                  <AttachContactIcon />
                                </IconButton>
                                {!!formikProps.values.contact.contactId && (
                                  <Chip
                                    label={`Attached: ${formikProps.values.contact.firstName} ${formikProps.values.contact.lastName}`}
                                    onDelete={() => clearAttachedContact(0)}
                                    variant="outlined"
                                  />
                                )}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Field
                                component={FormikEnumerationSelect}
                                type={Title}
                                showRequiredAsterisk={true}
                                showNone={false}
                                valueIsId={true}
                                name="contact.titleId"
                                label="TITLE"
                                disabled={!!formikProps.values.contact.contactId}
                              />
                            </Grid>
                            <Grid item>
                              <Field
                                component={FormikTextField}
                                showRequiredAsterisk={true}
                                name="contact.firstName"
                                label="FIRST NAME"
                                fullWidth
                                disabled={!!formikProps.values.contact.contactId}
                                onUpdateField={(setFieldValue: (field: string, value: string) => void, setValue: (value: string) => void, value: string) => {
                                  const lastNameValue = formikProps.values.contact.lastName;
                                  setValue(value);
                                  if (accountTypeId === ClientAccountType.Individual.id && value.length > 0 && lastNameValue.length > 0) {
                                    // update entity name field
                                    setFieldValue('clientAccount.name', `${value} ${lastNameValue}`);
                                  }
                                }}
                              />
                            </Grid>
                            <Grid item>
                              <Field
                                component={FormikTextField}
                                name="contact.middleName"
                                label="MIDDLE NAME"
                                fullWidth
                                disabled={!!formikProps.values.contact.contactId}
                              />
                            </Grid>
                            <Grid item>
                              <Field
                                component={FormikTextField}
                                showRequiredAsterisk={true}
                                name="contact.lastName"
                                label="LAST NAME"
                                fullWidth
                                disabled={!!formikProps.values.contact.contactId}
                                onUpdateField={(setFieldValue: (field: string, value: string) => void, setValue: (value: string) => void, value: string) => {
                                  const firstNameValue = formikProps.values.contact.firstName;
                                  setValue(value);
                                  if (accountTypeId === ClientAccountType.Individual.id && value.length > 0 && firstNameValue.length > 0) {
                                    // update entity name field
                                    setFieldValue('clientAccount.name', `${firstNameValue} ${value}`);
                                  }
                                }}
                              />
                            </Grid>
                            <Grid item>
                              <Field
                                component={FormikKeyboardDatePicker}
                                showRequiredAsterisk={true}
                                variant="inline"
                                name="contact.dateOfBirth"
                                label="DATE OF BIRTH"
                                fullWidth
                                maxDate={DateTime.now()}
                                disabled={!!formikProps.values.contact.contactId}
                              />
                            </Grid>
                          </LoadingIndicator>
                        </Grid>
                      </fieldset>
                      {accountTypeId === ClientAccountType.Joint.id && (
                        <fieldset>
                          <Grid container style={{ width: '390px' }}>
                            <Typography variant={'h2'} style={{ fontSize: '20px', marginBottom: '0px', marginTop: '20px' }} gutterBottom>
                              Additional contact
                            </Typography>
                            <LoadingIndicator progress={loadingExistingContactProgress}>
                              <Grid className={'selectPrimaryContact'}>
                                <Typography variant={'h4'}>
                                  Use existing contact
                                  <IconButton
                                    data-testid="attachAdditionalContactButton"
                                    onClick={() => {
                                      setAttachContactIndex(1);
                                      setAttachContactDialogOpen(true);
                                    }}
                                  >
                                    <AttachContactIcon />
                                  </IconButton>
                                  {!!formikProps.values.additionalContact?.contactId && (
                                    <Chip
                                      label={`Attached: ${formikProps.values.additionalContact.firstName} ${formikProps.values.additionalContact.lastName}`}
                                      onDelete={() => clearAttachedContact(1)}
                                      variant="outlined"
                                    />
                                  )}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Field
                                  component={FormikEnumerationSelect}
                                  type={Title}
                                  showRequiredAsterisk={true}
                                  showNone={false}
                                  valueIsId={true}
                                  name="additionalContact.titleId"
                                  label="TITLE"
                                  disabled={!!formikProps.values.additionalContact?.contactId}
                                />
                              </Grid>
                              <Grid item>
                                <Field
                                  component={FormikTextField}
                                  showRequiredAsterisk={true}
                                  name="additionalContact.firstName"
                                  label="FIRST NAME"
                                  fullWidth
                                  disabled={!!formikProps.values.additionalContact?.contactId}
                                />
                              </Grid>
                              <Grid item>
                                <Field
                                  component={FormikTextField}
                                  name="additionalContact.middleName"
                                  label="MIDDLE NAME"
                                  fullWidth
                                  disabled={!!formikProps.values.additionalContact?.contactId}
                                />
                              </Grid>
                              <Grid item>
                                <Field
                                  component={FormikTextField}
                                  showRequiredAsterisk={true}
                                  name="additionalContact.lastName"
                                  label="LAST NAME"
                                  fullWidth
                                  disabled={!!formikProps.values.additionalContact?.contactId}
                                />
                              </Grid>
                              <Grid item>
                                <Field
                                  component={FormikKeyboardDatePicker}
                                  showRequiredAsterisk={true}
                                  variant="inline"
                                  name="additionalContact.dateOfBirth"
                                  label="DATE OF BIRTH"
                                  fullWidth
                                  maxDate={DateTime.now()}
                                  disabled={!!formikProps.values.additionalContact?.contactId}
                                />
                              </Grid>
                            </LoadingIndicator>
                          </Grid>
                        </fieldset>
                      )}
                      <fieldset className={'accountOtherInfo'}>
                        <Grid container className={'selectPrimaryContact'}>
                          <Typography variant={'h4'} gutterBottom>
                            Other information
                          </Typography>
                          <Grid item>
                            <Field component={FormikTextField} showRequiredAsterisk={true} name="clientAccount.name" label="ENTITY NAME" fullWidth />
                          </Grid>
                          {(accountTypeId === ClientAccountType.Company.id ||
                            accountTypeId === ClientAccountType.Trust.id ||
                            accountTypeId === ClientAccountType.Smsf.id) && (
                            <>
                              <Grid item>
                                <Field
                                  component={FormikEnumerationSelect}
                                  type={TfnExemptions}
                                  name="clientAccount.tfnExemptionId"
                                  label="TFN EXEMPTION"
                                  showNone={true}
                                  valueIsId={true}
                                  onChange={(value: number | null) => {
                                    if (value === null) {
                                      if (!!formikProps.values.clientAccount.tfn && TfnExemptions.isValidExemptionCode(formikProps.values.clientAccount.tfn)) {
                                        formikProps.setFieldValue('clientAccount.tfn', null);
                                      }
                                    } else {
                                      const tfnExemptionCode = TfnExemptions.getTfnExemptionCodeById(value);
                                      if (!!tfnExemptionCode) {
                                        formikProps.setFieldValue('clientAccount.tfn', tfnExemptionCode.code);
                                        formikProps.setFieldTouched('clientAccount.tfn', false, false);
                                      }
                                    }
                                    formikProps.setFieldTouched('clientAccount.tfnExemptionId', false, false);
                                  }}
                                  fullWidth
                                />
                              </Grid>
                              <Grid item>
                                <Field
                                  disabled={!!formikProps.values.clientAccount.tfnExemptionId}
                                  mask="999 999 999"
                                  component={FormikTextField}
                                  name="clientAccount.tfn"
                                  label="TAX FILE NUMBER (TFN)"
                                  fullWidth
                                  type="password"
                                  autocomplete="new-password"
                                />
                              </Grid>
                              <Grid item>
                                <Field mask="99 999 999 999" component={FormikTextField} name="clientAccount.abn" label="ABN" fullWidth />
                              </Grid>
                              {accountTypeValues.clientAccount.accountTypeId === 11 && (
                                <Grid item>
                                  <Field
                                    mask="999 999 999"
                                    component={FormikTextField}
                                    name="clientAccount.acn"
                                    label="AUSTRALIAN COMPANY NUMBER (ACN)"
                                    fullWidth
                                  />
                                </Grid>
                              )}
                            </>
                          )}
                        </Grid>
                      </fieldset>
                    </Box>
                    <PageBackNextButtons<AdditionalInformationValues>
                      progress={saveProgress}
                      formikProps={formikProps}
                      onNextButtonClick={() => formikProps.submitForm()}
                      onBackButtonClick={() => onBackButtonClick(formikProps.values)}
                      onQuitButtonClick={
                        !id
                          ? undefined
                          : async () => {
                              await saveAdditionalInfoValues(formikProps.values);
                              history.push('/client/list?mode=onboard');
                            }
                      }
                    />
                  </Form>
                )}
              </Formik>
            </Box>
          </Box>
          <AttachContact
            contacts={contactsToAttach}
            contactsProgress={loadingContactsToAttach}
            roles={[]}
            showRoles={false}
            loadingRoles={{ isLoading: false, hasErrors: false }}
            isOpen={attachContactDialogOpen}
            handleCloseModal={() => {
              setAttachContactIndex(undefined);
              setAttachContactDialogOpen(false);
            }}
            onSave={(payload: AttachContactDialogPayload) => onAttachContact(payload)}
            fetchRoles={() => []}
            fetchContactsToAttach={onFetchContactsToAttach}
          ></AttachContact>
        </div>
      </Grid>
    </Grid>
  );
};
