import { createSelector } from '@reduxjs/toolkit';
import _ from 'lodash';
import { RootState } from '../../../../../reducers';
import { isCashTrade } from '../utils';
import {
  ExpiryType,
  ExportTrade,
  GroupedByKey,
  GroupedTrades,
  PreApprovalTrade,
  RebalanceState,
  RebalanceValidationRequest,
  SecuritySearchResults,
  TradeAction,
  TradeMethod,
  ValidationResult,
} from './types';
import { getRebalanceSummary } from '../utils/getRebalanceSummary';

export const selectRebalanceKey = createSelector(
  (state: RootState) => state.rebalance.edit,
  (rebalanceEdit: RebalanceState) => rebalanceEdit.rebalanceKey
);

export const selectRebalance = createSelector(
  (state: RootState) => state.rebalance.edit,
  (rebalanceEdit: RebalanceState) => {
    return rebalanceEdit.rebalance;
  }
);

export const selectRebalanceValidationRequest = createSelector(
  (state: RootState) => state.rebalance.edit,
  (rebalanceEdit: RebalanceState) => {
    const preApprovalTrades = _.chain(rebalanceEdit.rebalance?.trades)
      .map(
        (trade) =>
          ({
            marketCode: trade.marketCode,
            targetValue: trade.targetValue,
            targetPercent: trade.targetPercent,
            securityType: trade.securityType,
            securityToleranceBand: trade.securityToleranceBand,
            securityName: trade.securityName,
            securityId: trade.securityId,
            securityCode: trade.securityCode,
            securityCategory: trade.securityCategory,
            productCode: trade.productCode,
            productName: trade.productName,
            modelCode: trade.modelCode,
            modelName: trade.modelName,
            unitPrice: trade.unitPrice,
            id: trade.id,
            calculatedTradeValue: trade.newCalculatedValue,
            calculatedTradeUnits: trade.newCalculatedUnits,
            currentPercent: trade.currentPercent,
            currentUnitPrice: trade.currentUnitPrice,
            currentUnitPriceTime: trade.currentUnitPriceTime,
            currentUnits: trade.currentUnits,
            currentValue: trade.currentValue,
            comment: trade.comment,
            assetClass: trade.assetClass,
            assetClassId: trade.assetClassId,
            tradeAction: trade.tradeAction,
            priceLimit: trade.priceLimit,
            priceType: trade.priceType,
            expiryDate: trade.expiryDate,
            expiryType: trade.expiryType,
          } as PreApprovalTrade)
      )
      .value();

    return {
      investmentServiceId: rebalanceEdit.rebalance.investmentServiceId,
      preApprovalTradeItems: preApprovalTrades,
      rebalanceId: rebalanceEdit.rebalance.rebalanceId,
      clientName: rebalanceEdit.rebalance.clientName,
      isClientApprovalRequired: rebalanceEdit.rebalance.isClientApprovalRequired ?? false,
    } as RebalanceValidationRequest;
  }
);

export const selectGroupedByKey = createSelector(
  (state: RootState) => state.rebalance.edit,
  (rebalanceEdit: RebalanceState) => rebalanceEdit.groupedByKey
);

export const selectGroupedFilteredTrades = createSelector(
  (state: RootState) => state.rebalance.edit,
  (rebalanceEdit: RebalanceState) => {
    if (rebalanceEdit.rebalance?.trades) {
      return _.chain(rebalanceEdit.rebalance.trades)
        .groupBy(rebalanceEdit.groupedByKey)
        .map(
          (value, key) =>
            ({
              groupedByKey: key,
              key:
                rebalanceEdit.groupedByKey === GroupedByKey.AssetClass
                  ? value.find((x) => x !== undefined)?.assetClass
                  : value.find((x) => x !== undefined)?.modelName ?? 'Direct',
              title:
                rebalanceEdit.groupedByKey === GroupedByKey.AssetClass
                  ? value.find((x) => x !== undefined)?.assetClass
                  : value.find((x) => x !== undefined)?.modelName ?? 'Direct',
              trades: rebalanceEdit.filter
                ? value
                    .filter(
                      (trade) =>
                        trade.securityCode.toUpperCase().includes(rebalanceEdit.filter.toUpperCase()) ||
                        trade.securityName.toUpperCase().includes(rebalanceEdit.filter.toUpperCase())
                    )
                    .sort((a, b) => a.securityCode.localeCompare(b.securityCode))
                : [...value].sort((a, b) => a.securityCode.localeCompare(b.securityCode)),
              totalCurrentPercent: value.reduce((prev, next) => prev + next.currentPercent, 0),
              totalCurrentValue: value.reduce((prev, next) => prev + next.currentValue, 0),
              totalTargetPercent: value.reduce((prev, next) => prev + next.targetPercent, 0),
              totalTargetValue: value.reduce((prev, next) => prev + next.targetValue, 0),
              totalProposedPercent: value.reduce((prev, next) => prev + next.proposedPercent, 0),
              totalProposedValue: value.reduce((prev, next) => prev + next.proposedValue, 0),
              totalNewCalculatedValue: value.reduce(
                (prev, next) =>
                  next.tradeAction === TradeAction.Buy || isCashTrade(next)
                    ? prev + next.newCalculatedValue
                    : prev - next.newCalculatedValue,
                0
              ),
              totalBuys: value.reduce(
                (prev, next) =>
                  next.tradeAction === TradeAction.Buy && !isCashTrade(next)
                    ? !isCashTrade(next)
                      ? prev + 1
                      : prev
                    : prev,
                0
              ),
              totalSells: value.reduce(
                (prev, next) =>
                  (next.tradeAction === TradeAction.Sell || next.tradeAction === TradeAction.All) && !isCashTrade(next)
                    ? !isCashTrade(next)
                      ? prev + 1
                      : prev
                    : prev,
                0
              ),
            } as GroupedTrades)
        )
        .sort((a, b) => a.title?.localeCompare(b.title))
        .value();
    }
    return [];
  }
);

export const selectFilter = createSelector(
  (state: RootState) => state.rebalance.edit,
  (rebalanceEdit: RebalanceState) => rebalanceEdit.filter
);

// ----------------------------------- Withdrawal section -------------------------------------
export const selectRebalanceMode = createSelector(
  (state: RootState) => state.rebalance.edit,
  (rebalanceEdit: RebalanceState) => rebalanceEdit.withdrawal.rebalanceMode
);
export const selectIgnoreMinimumTrade = createSelector(
  (state: RootState) => state.rebalance.edit,
  (rebalanceEdit: RebalanceState) => rebalanceEdit.withdrawal.ignoreMinimumTrade
);
export const selectSaleMethod = createSelector(
  (state: RootState) => state.rebalance.edit,
  (rebalanceEdit: RebalanceState) => rebalanceEdit.withdrawal.saleMethod
);
export const selectTargetCash = createSelector(
  (state: RootState) => state.rebalance.edit,
  (rebalanceEdit: RebalanceState) => rebalanceEdit.withdrawal.targetCash
);
export const selectWithdrawalMethod = createSelector(
  (state: RootState) => state.rebalance.edit,
  (rebalanceEdit: RebalanceState) => rebalanceEdit.withdrawal.withdrawalMethod
);
export const selectWithdrawCash = createSelector(
  (state: RootState) => state.rebalance.edit,
  (rebalanceEdit: RebalanceState) => rebalanceEdit.withdrawal.withdrawCash
);
export const selectReceivingAccount = createSelector(
  (state: RootState) => state.rebalance.edit,
  (rebalanceEdit: RebalanceState) => rebalanceEdit.withdrawal.receivingAccount
);
export const selectWithdrawValue = createSelector(
  (state: RootState) => state.rebalance.edit,
  (rebalanceEdit: RebalanceState) => rebalanceEdit.withdrawal.withdrawValue
);
export const selectWithdrawalDetailsData = createSelector(
  (state: RootState) => state.rebalance.edit,
  (rebalanceEdit: RebalanceState) => rebalanceEdit.withdrawal.withdrawalDetailsData
);
// --------------------------------- end Withdrawal section -----------------------------------

export const selectSummary = createSelector(
  (state: RootState) => state.rebalance.edit,
  (rebalanceEdit: RebalanceState) => {
    return getRebalanceSummary(rebalanceEdit);
  }
);

export const selectValidationMessages = createSelector(
  (state: RootState) => state.rebalance.edit,
  (rebalanceEdit: RebalanceState) => {
    return {
      errors: rebalanceEdit.validationMessages?.errors ?? [],
      information: rebalanceEdit.validationMessages?.information ?? [],
      warnings: rebalanceEdit.validationMessages?.warnings ?? [],
    } as ValidationResult;
  }
);

export const selectIsPreApproved = createSelector(
  (state: RootState) => state.rebalance.edit,
  (rebalanceEdit: RebalanceState) => {
    return rebalanceEdit.isPreApproved;
  }
);

export const selectSecurity = createSelector(
  (state: RootState) => state.rebalance.edit,
  (rebalanceEdit: RebalanceState) => rebalanceEdit.securitySearch?.security
);

export const selectImportErrors = createSelector(
  (state: RootState) => state.rebalance.edit,
  (rebalanceEdit: RebalanceState) => rebalanceEdit.importErrors
);

export const selectSecuritySearchResults = createSelector(
  (state: RootState) => state.rebalance.edit,
  (rebalanceEdit: RebalanceState) => {
    if (rebalanceEdit.securitySearch?.securitySearchResults && rebalanceEdit.rebalance?.trades) {
      return {
        ...rebalanceEdit.securitySearch.securitySearchResults,
        results: rebalanceEdit.securitySearch.securitySearchResults.results.filter((security) => {
          return !rebalanceEdit.rebalance.trades.some((trade) => trade.securityId === security.securityId);
        }),
      } as SecuritySearchResults;
    }
    return {} as SecuritySearchResults;
  }
);

export const selectExportTrades = createSelector(
  (state: RootState) => state.rebalance.edit,
  (rebalanceEdit: RebalanceState) => {
    if (rebalanceEdit.rebalance?.trades) {
      return rebalanceEdit.rebalance.trades.map((trade) => {
        let expiryType = 'Good For Day';
        switch (trade.expiryType) {
          case ExpiryType.GTC:
            expiryType = 'Good Till Cancelled';
            break;
          case ExpiryType.GTD:
            expiryType = 'Good Till Date';
            break;
          case ExpiryType.GFD:
            expiryType = 'Good For Day';
            break;
          default:
            expiryType = 'Good For Day';
            break;
        }
        return {
          tradeAction: trade.tradeAction === TradeAction.All ? 'SellAll' : trade.tradeAction,
          unitPrice: trade.unitPrice,
          currentUnitPrice: trade.currentUnitPrice,
          currentValue: trade.currentValue,
          currentUnits: trade.currentUnits,
          targetValue: trade.targetValue,
          comment: trade.comment,
          currentUnitPriceTime: trade.currentUnitPriceTime,
          securityCode: trade.securityCode,
          securityName: trade.securityName,
          assetClass: trade.assetClass,
          productCode: trade.productCode,
          productName: trade.productName,
          modelCode: trade.modelCode,
          modelName: trade.modelName,
          currentPercent: trade.currentPercent,
          targetPercent: trade.targetPercent,
          calculatedValue: trade.calculatedValue,
          calculatedUnits: trade.calculatedUnits,
          method: trade.method && trade.method === TradeMethod.Hash ? 'Units' : trade.method,
          amountToTrade: trade.amountToTrade,
          newCalculatedUnits: trade.newCalculatedUnits,
          newCalculatedValue: trade.newCalculatedValue,
          proposeValue: trade.proposedValue,
          proposedPercent: trade.proposedPercent,
          expiryDate: trade.expiryDate,
          expiryType: expiryType,
          priceLimit: trade.priceLimit,
          priceType: trade.priceType ? trade.priceType : 'Market',
        } as ExportTrade;
      });
    } else {
      return [] as ExportTrade[];
    }
  }
);
